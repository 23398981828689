import { Dispatch, SetStateAction, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import * as yup from 'yup'

import { Box, FormWrapper, Message, Modal, TextInput, useNotify } from '@cutover/react-ui'
import { queryClient } from 'main/query-client'
import { ApiError } from 'main/services/api'
import { useLanguage } from 'main/services/hooks'
import { ConnectSetting } from 'main/services/queries/types/connect'
import { useConnectSettingsCreate } from 'main/services/queries/use-connect-settings-query'

type CreateNewProjectModalType = {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

export const CreateNewProjectModal = ({ open, setOpen }: CreateNewProjectModalType) => {
  const { t } = useLanguage('connectSettings')
  const notify = useNotify()
  const [formErrors, setFormErrors] = useState<{ [key: string]: { message: string } }>({})
  const createMutation = useConnectSettingsCreate()
  const { isLoading: isCreateLoading } = createMutation

  const onSubmitCreateNewProjectModal = async (payload: ConnectSetting) => {
    createMutation.mutate(
      { payload },
      {
        onSuccess: () => {
          notify.success(t('modal.newSetting.toasters.success'))
          handleClose()

          queryClient.invalidateQueries(['connect-settings'])
        },
        onError: error => {
          const castedError = error as ApiError
          notify.error(castedError?.errors?.[0])
          handleClose()
        }
      }
    )
  }

  const validationSchema = yup.object().shape({
    label: yup
      .string()
      .required(t('modal.newSetting.formErrors.required'))
      .max(25, t('modal.newSetting.formErrors.maxLength'))
      .matches(/^[a-zA-Z0-9-]+$/, t('modal.newSetting.formErrors.containsInvalidChars'))
      .matches(/^[a-zA-Z][a-zA-Z0-9-]*$/, t('modal.newSetting.formErrors.startsWithInvalidChars'))
      .matches(/^(?!.*localhost).+$/i, t('modal.newSetting.formErrors.containsLocalhost'))
  })

  const methods = useForm<ConnectSetting>({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    reValidateMode: 'onChange'
  })

  const { control, register } = methods

  const handleClose = () => {
    setOpen(false)
    setFormErrors({})
    methods.reset({ label: '' })
  }

  const handleErrors = (error: any) => {
    setFormErrors(error)
  }

  const formatErrors = () => {
    return Object.values(formErrors)
      .map(error => error.message)
      .join(', ')
  }

  const isErrorFor = (property: string) => {
    return property in formErrors
  }

  return (
    <Modal
      open={open}
      title={t('modal.newSetting.title')}
      onClose={handleClose}
      onClickConfirm={methods.handleSubmit(onSubmitCreateNewProjectModal, handleErrors)}
      loading={isCreateLoading}
      loadingText={t('loading')}
    >
      <Message message={formatErrors() as string} type="error" />
      <Box>
        {
          <FormProvider {...methods}>
            <FormWrapper>
              <Controller
                name="connect_version"
                control={control}
                render={() => (
                  <TextInput {...register('label')} label={'Name'} required hasError={isErrorFor('label')} />
                )}
              />
            </FormWrapper>
          </FormProvider>
        }
      </Box>
    </Modal>
  )
}
