import { RunbooksDashboardPage } from 'main/components/dashboards/runbooks-dashboard-page'
import { ReactAngularConnector } from 'main/connectors/react-angular-connector'

export const RunbooksDashboardPageConnector = () => {
  return (
    <ReactAngularConnector mountEvent="show-runbooks-dashboard" unmountEvent="hide-runbooks-dashboard">
      <RunbooksDashboardPage />
    </ReactAngularConnector>
  )
}
