import { PaintBox } from '../node-map-types'

export const paintBox = ({ ctx, x, y, size, lineWidth, stroke, fill }: PaintBox) => {
  // regular square shape lines
  ctx.beginPath()
  ctx.moveTo(x - size, y - size)
  ctx.lineTo(x - size, y + size)
  ctx.lineTo(x + size, y + size)
  ctx.lineTo(x + size, y - size)
  ctx.closePath()

  ctx.lineWidth = lineWidth
  ctx.strokeStyle = stroke
  ctx.stroke()

  // white rectangle shape to cover the lines on left and right
  const whiteRectSizeX = size * 1.2
  const whiteRectSizeY = size * 0.6

  ctx.moveTo(x - whiteRectSizeX, y - whiteRectSizeY)
  ctx.lineTo(x - whiteRectSizeX, y + whiteRectSizeY)
  ctx.lineTo(x + whiteRectSizeX, y + whiteRectSizeY)
  ctx.lineTo(x + whiteRectSizeX, y - whiteRectSizeY)
  ctx.closePath()

  if (fill) {
    ctx.fillStyle = fill
    ctx.fill()
  }
}
