// @ts-nocheck
import { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { withPresenter } from 'app/Config/withPresenter'
import {
  HeaderPanelWrapper,
  PageWrapper,
  PageWrapperInner,
  CenterMainWrapper,
  MainPanelWrapper,
  EditPanelWrapper,
  GenericList
} from 'Components/Templates'
import { fluidPage } from 'Shared/HooksAndPresenters/FluidPage'
import { AccountEditSection } from './AccountEdit/AccountEditSection'
import AccountsPageHeadingSection from './AccountsPageHeading/AccountsPageHeadingSection'
import { AccountsPageModals } from './AccountsPageModals'
import { AddButton } from 'Components/Atoms'
import { AccountsPagePresenter } from './AccountsPagePresenter'

interface IAccountsPageComponentProps {
  presenter: AccountsPagePresenter
}

const AccountsPageComponent = observer<IAccountsPageComponentProps>(({ presenter }) => {
  const {
    editOpen,
    editLoading,
    editPanelClosedCallback,
    closePanel,
    listPresenter,
    loading,
    accountEditPresenter,
    addFormPresenter,
    editFormPresenter,
    accountVM,
    accountVMChangeFunction,
    resetSubmit,
    canCreate,
    angularJSSettingsPanelOpenState,
    checkEditPanelIsDirty,
    archiveModalOpenState,
    language
  } = presenter

  const {
    archiveModalPresenter,
    archiveConfirmName,
    cancelArchiveCallback,
    continueArchiveCallback,
    archiveResponseErrors
  } = accountEditPresenter

  const [accountAddModalOpenState, setAccountAddModalOpenState] = useState(false)
  const { showAddButton } = fluidPage(editOpen, closePanel, checkEditPanelIsDirty, angularJSSettingsPanelOpenState)

  useEffect(() => {
    accountVMChangeFunction()
  }, [accountVM])

  const addModalCallback = (modalOpen: boolean) => {
    resetSubmit()
    setAccountAddModalOpenState(modalOpen)
  }

  return (
    <PageWrapper>
      <PageWrapperInner>
        <HeaderPanelWrapper>
          <AccountsPageHeadingSection />
        </HeaderPanelWrapper>
        <CenterMainWrapper>
          <MainPanelWrapper filterPanelOpen={false} extraStyles={{ zIndex: 1 }} loading={loading}>
            <GenericList listPresenter={listPresenter} />
            {canCreate && showAddButton && (
              <AddButton
                action={() => {
                  setAccountAddModalOpenState(true)
                }}
                language={language}
              />
            )}
            <AccountsPageModals
              addModalOpenState={accountAddModalOpenState}
              setAddModalOpenState={addModalCallback}
              addFormPresenter={addFormPresenter}
              archiveModalPresenter={archiveModalPresenter}
              archiveConfirmName={archiveConfirmName}
              continueArchiveCallback={continueArchiveCallback}
              cancelArchiveCallback={cancelArchiveCallback}
              archiveResponseErrors={archiveResponseErrors}
              archiveModalOpenState={archiveModalOpenState}
              language={language}
            />
          </MainPanelWrapper>
        </CenterMainWrapper>
      </PageWrapperInner>
      <EditPanelWrapper isOpen={editOpen} loading={editLoading} panelCloseCallback={editPanelClosedCallback}>
        <AccountEditSection presenter={accountEditPresenter} editFormPresenter={editFormPresenter} />
      </EditPanelWrapper>
    </PageWrapper>
  )
})

export default withPresenter(AccountsPagePresenter)(AccountsPageComponent)
