import { useCallback, useRef } from 'react'
import { pick, pullAllWith } from 'lodash'
import {
  TransactionInterface_UNSTABLE,
  useRecoilCallback,
  useRecoilTransaction_UNSTABLE,
  useRecoilValue,
  useRecoilValueLoadable,
  useResetRecoilState,
  useSetRecoilState
} from 'recoil'
import { produce } from 'immer'

import { useNotify } from '@cutover/react-ui'
import {
  filteredTaskListDataState,
  filteredTaskListIdsState,
  filteredTasksState,
  isStreamPermittedState,
  isVersionCurrentState,
  isVersionEditable,
  newTaskStreamState,
  runbookIdState,
  runbookPermission,
  runbookVersionIdState,
  runbookVersionState,
  runbookViewState_INTERNAL,
  taskListCountState,
  taskListInternalIdLookupState,
  taskListLookupState,
  taskListResponseState_INTERNAL,
  taskListState,
  taskListTaskState,
  taskProgressionState,
  tasksPermission,
  teamIdToTaskRecord,
  teamsStateLookup
} from 'main/recoil/runbook'
import { getTasks } from 'main/services/queries/use-tasks'
import {
  RunbookResponse,
  RunbookTaskBulkCreateResponse,
  RunbookTaskBulkDeleteResponse,
  RunbookTaskBulkSkipResponse,
  RunbookTaskBulkUpdateResponse,
  RunbookTaskCreateResponse,
  RunbookTaskFinishResponse,
  RunbookTaskIntegrationResponse,
  RunbookTaskPasteResponse,
  RunbookTaskStartResponse,
  RunbookTaskUpdateResponse
} from 'main/services/api/data-providers/runbook-types'
import {
  updateAddNewComments,
  updateAllChangedTasks,
  updateCustomFieldsData,
  updateTasksAndVersion
} from './shared-updates'
import { RunbookChangedTask } from 'main/services/api/data-providers/runbook-types/runbook-shared-types'
import { taskEditUpdatedTaskId } from 'main/recoil/runbook/models/tasks/task-edit'
import { TaskModelType } from 'main/data-access/models'
import { TaskListTask } from 'main/services/queries/types'
import { useLanguage } from 'main/services/hooks'
import { useLoadingIdAdd, useLoadingIdRemove, useModalClose, useModalOpen, useModalValueCallback } from './runbook-view'
import { currentUserIdState } from 'main/recoil/current-user'
import { useTaskNotifications } from 'main/recoil/data-access'
import { finishTask, startTask, TaskFinishPayload, TaskStartPayload } from 'main/services/queries/use-task'
import { getServerErrorMessages } from 'main/services/api'

/* -------------------------------------------------------------------------- */
/*                                     Get                                    */
/* -------------------------------------------------------------------------- */

export const useGetTask: TaskModelType['useGet'] = identifier => {
  return useRecoilValue(taskListTaskState(identifier))
}

export const useGetTaskCallback: TaskModelType['useGetCallback'] = () => {
  return useRecoilCallback(
    ({ snapshot }) =>
      async identifier => {
        return await snapshot.getPromise(taskListTaskState(identifier))
      },
    []
  )
}

/* -------------------------------------------------------------------------- */
/*                                   Lookup                                   */
/* -------------------------------------------------------------------------- */

/* eslint-disable react-hooks/rules-of-hooks */
export const useGetTaskLookup: TaskModelType['useGetLookup'] = options => {
  const stabilizedOptions = useRef(options).current

  if (stabilizedOptions?.keyBy === 'internal_id') return useRecoilValue(taskListInternalIdLookupState)
  return useRecoilValue(taskListLookupState)
}
/* eslint-enable react-hooks/rules-of-hooks */

export const useGetTaskLookupCallback: TaskModelType['useGetLookupCallback'] = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      async options => {
        if (options?.keyBy === 'internal_id') return await snapshot.getPromise(taskListInternalIdLookupState)
        return await snapshot.getPromise(taskListLookupState)
      },
    []
  )

/* -------------------------------------------------------------------------- */
/*                               Lookup Loadable                              */
/* -------------------------------------------------------------------------- */

export const useGetTaskLookupLoadable: TaskModelType['useGetLookupLoadable'] = () =>
  useRecoilValueLoadable(taskListLookupState)

/* -------------------------------------------------------------------------- */
/*                                   Get All                                  */
/* -------------------------------------------------------------------------- */

/* eslint-disable react-hooks/rules-of-hooks */
export const useGetAllTasks: TaskModelType['useGetAll'] = options => {
  const stabilizedOptions = useRef(options).current

  if (stabilizedOptions?.scope === 'filtered') return useRecoilValue(filteredTasksState)
  if (stabilizedOptions?.scope === 'critical') {
    const taskLookup = useRecoilValue(taskListLookupState)
    const [, filterContext] = useRecoilValue(filteredTaskListDataState)
    return filterContext.criticalIds
      ? (Object.values(pick(taskLookup, filterContext.criticalIds)) as TaskListTask[])
      : []
  }
  return useRecoilValue(taskListState)
}
/* eslint-enable react-hooks/rules-of-hooks */

export const useGetAllTasksCallback: TaskModelType['useGetAllCallback'] = options =>
  useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        if (options?.scope === 'filtered') return await snapshot.getPromise(filteredTasksState)
        return await snapshot.getPromise(taskListState)
      },
    []
  )

/* -------------------------------------------------------------------------- */
/*                                 Get All By                                 */
/* -------------------------------------------------------------------------- */

/* eslint-disable react-hooks/rules-of-hooks */
export const useGetAllTasksBy: TaskModelType['useGetAllBy'] = getAllBy => {
  const stabilizedGetAllBy = useRef(getAllBy.runbook_team_id).current

  if (stabilizedGetAllBy) return useRecoilValue(teamIdToTaskRecord)[getAllBy.runbook_team_id]

  return useRecoilValue(taskListState)
}
/* eslint-enable react-hooks/rules-of-hooks */

export const useGetAllTasksByCallback: TaskModelType['useGetAllByCallback'] = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      async getAllBy => {
        if (getAllBy?.runbook_team_id) return (await snapshot.getPromise(teamIdToTaskRecord))[getAllBy.runbook_team_id]

        return await snapshot.getPromise(taskListState)
      },
    []
  )

/* -------------------------------------------------------------------------- */
/*                              Get All Loadable                              */
/* -------------------------------------------------------------------------- */

export const useGetAllTasksLoadable: TaskModelType['useGetAllLoadable'] = () => useRecoilValueLoadable(taskListState)
export const useGetAllTasksLoadableCallback: TaskModelType['useGetAllLoadableCallback'] = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      () =>
        snapshot.getLoadable(taskListState)
  )

/* -------------------------------------------------------------------------- */
/*                                   Reload                                   */
/* -------------------------------------------------------------------------- */

export const useReloadTasks: TaskModelType['useReload'] = () => {
  const resetTaskList = useResetRecoilState(taskListResponseState_INTERNAL)
  const setTaskList = useSetRecoilState(taskListResponseState_INTERNAL)
  const getRunbookId = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        return await snapshot.getPromise(runbookIdState)
      },
    []
  )
  const getRunbookVersionId = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        return await snapshot.getPromise(runbookVersionIdState)
      },
    []
  )

  return async () => {
    resetTaskList() // necessary to show loading state of list
    setTaskList(await getTasks(await getRunbookId(), await getRunbookVersionId()))
  }
}

export const useReloadTasksSync: TaskModelType['useReloadSync'] = () => () => {
  window.dispatchEvent(new CustomEvent<any>('refresh-data-store', { detail: { type: 'tasks' } }))
}
/* -------------------------------------------------------------------------- */
/*                                 Permissions                                */
/* -------------------------------------------------------------------------- */

export const useCanTask: TaskModelType['useCan'] = key => useRecoilValue(tasksPermission({ attribute: key }))

/* -------------------------------------------------------------------------- */
/*                                    Float                                   */
/* -------------------------------------------------------------------------- */

export const useTaskFloat: TaskModelType['useGetFloat'] = identifier => {
  const [, filterContext] = useRecoilValue(filteredTaskListDataState)
  return filterContext.floatLookup?.[identifier as number]
}

export const useTaskFloatCallback: TaskModelType['useGetFloatCallback'] = () =>
  useRecoilCallback(({ snapshot }) => async identifier => {
    const [, filterContext] = await snapshot.getPromise(filteredTaskListDataState)
    return filterContext.floatLookup?.[identifier as number]
  })

/* -------------------------------------------------------------------------- */
/*                                  Critical                                  */
/* -------------------------------------------------------------------------- */

export const useTaskIsCritical: TaskModelType['useGetIsCritical'] = identifier => {
  const [, context] = useRecoilValue(filteredTaskListDataState)
  return context.criticalIds?.includes(identifier as number) || false
}

export const useTaskIsCriticalCallback: TaskModelType['useGetIsCriticalCallback'] = () =>
  useRecoilCallback(({ snapshot }) => async identifier => {
    const [, context] = await snapshot.getPromise(filteredTaskListDataState)
    return context.criticalIds?.includes(identifier as number) || false
  })

/* -------------------------------------------------------------------------- */
/*                                     Ids                                    */
/* -------------------------------------------------------------------------- */

/* eslint-disable react-hooks/rules-of-hooks */
export const useTaskIds: TaskModelType['useGetIds'] = options => {
  const stabilizedOptions = useRef(options).current

  switch (stabilizedOptions?.scope) {
    case 'critical':
      const [, context] = useRecoilValue(filteredTaskListDataState)
      return context.criticalIds || ([] as number[])
    case 'filtered':
      return useRecoilValue(filteredTaskListIdsState)
    default:
      return useRecoilValue(taskListState).map(({ id }) => id)
  }
}
/* eslint-enable react-hooks/rules-of-hooks */

export const useTaskIdsCallback: TaskModelType['useGetIdsCallback'] = options =>
  useRecoilCallback(({ snapshot }) => async () => {
    switch (options?.scope) {
      case 'critical':
        const [, context] = await snapshot.getPromise(filteredTaskListDataState)
        return context.criticalIds || ([] as number[])
      case 'filtered':
        return await snapshot.getPromise(filteredTaskListIdsState)
      default:
        return (await snapshot.getPromise(taskListState)).map(({ id }) => id)
    }
  })

/* -------------------------------------------------------------------------- */
/*                                  Override                                  */
/* -------------------------------------------------------------------------- */

export const useGetTaskOverride: TaskModelType['useGetOverride'] = identifier =>
  useRecoilValue(taskProgressionState(identifier))?.override

export const useGetTaskOverrideCallback: TaskModelType['useGetOverrideCallback'] = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      async (identifier: number) =>
        (await snapshot.getPromise(taskProgressionState(identifier)))?.override
  )

/* -------------------------------------------------------------------------- */
/*                                    Count                                   */
/* -------------------------------------------------------------------------- */

/* eslint-disable react-hooks/rules-of-hooks */
export const useCountTasks: TaskModelType['useGetCount'] = opts => {
  const stabilizedOptions = useRef(opts).current

  switch (stabilizedOptions?.scope) {
    case 'filtered':
      return useRecoilValue(taskListCountState).filtered
    case 'critical':
      const [, context] = useRecoilValue(filteredTaskListDataState)
      return context?.criticalIds?.length || 0
    default:
      return useRecoilValue(taskListCountState).total
  }
}
/* eslint-enable react-hooks/rules-of-hooks */

export const useCountTasksCallback: TaskModelType['useGetCountCallback'] = opts =>
  useRecoilCallback(({ snapshot }) => async () => {
    switch (opts?.scope) {
      case 'filtered':
        return (await snapshot.getPromise(taskListCountState)).filtered
      case 'critical':
        const [, context] = await snapshot.getPromise(filteredTaskListDataState)
        return context?.criticalIds?.length || 0
      default:
        return (await snapshot.getPromise(taskListCountState)).total
    }
  })

/* -------------------------------------------------------------------------- */
/*                                 Can Action                                 */
/* -------------------------------------------------------------------------- */

// ERRORS
const TASK_ERRORS = {
  NO_ACTIVE_RUN: 'NO_ACTIVE_RUN',
  NO_PERMITTED_STREAM: 'NO_PERMITTED_STREAM',
  NO_TASK: 'NO_TASK',
  TASK_COMPLETE: 'TASK_COMPLETE',
  TASK_HAS_ERRORS: 'TASK_HAS_ERRORS',
  TASK_HAS_STARTED_SUCCESSORS: 'TASK_HAS_STARTED_SUCCESSORS',
  TASK_IS_LINKED_RUNBOOK: 'TASK_IS_LINKED_RUNBOOK',
  TASK_IS_LOADING: 'TASK_IS_LOADING',
  TASK_NOT_FINISHABLE: 'TASK_NOT_FINISHABLE',
  TASK_NOT_STARTABLE_OR_FINISHABLE: 'TASK_NOT_STARTABLE_OR_FINISHABLE',
  TASK_NOT_STARTABLE: 'TASK_NOT_STARTABLE',
  TASK_SKIPPED: 'TASK_SKIPPED',
  TASK_STAGE: 'TASK_STAGE',
  VERSION_NOT_EDITABLE: 'VERSION_NOT_EDITABLE',
  USER_NOT_ASSIGNED: 'USER_NOT_ASSIGNED',
  USER_NOT_PERMITTED: 'USER_NOT_PERMITTED'
}

const useHasStartedSuccessors = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      (taskId: number) => {
        const task = snapshot.getLoadable(taskListTaskState(taskId)).getValue()
        const allowedAddBelowStages = ['default', 'startable']
        for (const sId of task.successor_ids) {
          const successorTask = snapshot.getLoadable(taskListTaskState(sId)).getValue()
          if (!allowedAddBelowStages.includes(successorTask.stage)) return true
        }

        return false
      },
    []
  )

export const useCreateTaskPermission = () => {
  const getHasStartedSuccessors = useHasStartedSuccessors()

  return useRecoilCallback(
    ({ snapshot }) =>
      (fromTaskId: number) => {
        const previousTask = snapshot.getLoadable(taskListTaskState(fromTaskId)).getValue()

        const newTaskStreamId = !!snapshot
          .getLoadable(newTaskStreamState({ prevTaskStreamId: previousTask?.stream_id }))
          .getValue()

        if (!previousTask) return { can: false, error: TASK_ERRORS.NO_TASK }
        if (!newTaskStreamId) return { can: false, error: TASK_ERRORS.NO_PERMITTED_STREAM }
        if (!snapshot.getLoadable(isVersionEditable).getValue())
          return { can: false, error: TASK_ERRORS.VERSION_NOT_EDITABLE }

        const { loadingIds } = snapshot.getLoadable(runbookViewState_INTERNAL).getValue()
        const isLoading = loadingIds[previousTask.id]
        const can = !isLoading && !getHasStartedSuccessors(previousTask.id)

        return {
          can,
          error: can ? undefined : isLoading ? TASK_ERRORS.TASK_IS_LOADING : TASK_ERRORS.TASK_HAS_STARTED_SUCCESSORS
        }
      },
    [getHasStartedSuccessors]
  )
}

const useBulkSkipTasksPermission = () => {
  const getCanSkipTask = useSkipTaskPermission()

  return useRecoilCallback(
    () => (taskIds: number[]) => {
      return taskIds.reduce(
        (acc, id) => {
          const { can, error } = getCanSkipTask(id)
          if (can) return acc

          acc.can = false
          acc.errors[id] = { can: false, error: error as string }
          return acc
        },
        { can: true, errors: {} } as { can: boolean; errors: Record<number, { can: false; error: string }> }
      )
    },
    [getCanSkipTask]
  )
}

const useSkipTaskPermission = () => {
  return useRecoilCallback(({ snapshot }) => (taskId: number) => {
    const task = snapshot.getLoadable(taskListTaskState(taskId)).getValue()
    const can = task.stage === 'complete' || task.completion_type === 'complete_skipped'

    return {
      can,
      error: can ? undefined : task.stage === 'complete' ? TASK_ERRORS.TASK_COMPLETE : TASK_ERRORS.TASK_SKIPPED
    }
  })
}

export const useProgressTaskPermission = () => {
  const getStartPermission = useStartTaskPermission()
  const getFinishPermission = useFinishTaskPermission()

  return useRecoilCallback(
    ({ snapshot }) =>
      (taskId: number) => {
        const { loadingIds } = snapshot.getLoadable(runbookViewState_INTERNAL).getValue()
        if (loadingIds[taskId]) return { can: false, error: TASK_ERRORS.TASK_IS_LOADING }

        const { run } = snapshot.getLoadable(runbookVersionState).getValue()
        const isActiveRun = run?.mode === 'active'
        if (!isActiveRun) return { can: false, error: TASK_ERRORS.NO_ACTIVE_RUN }

        const task = snapshot.getLoadable(taskListTaskState(taskId)).getValue()

        if (task.stage === 'complete') return { can: false, error: TASK_ERRORS.TASK_COMPLETE }
        if (task.stage === 'default') return { can: false, error: TASK_ERRORS.TASK_NOT_STARTABLE_OR_FINISHABLE }
        if (task.stage === 'startable' && !getStartPermission(taskId).can)
          return { can: false, error: TASK_ERRORS.TASK_NOT_STARTABLE }
        if (task.stage === 'in-progress' && !getFinishPermission(taskId).can)
          return { can: false, error: TASK_ERRORS.TASK_NOT_FINISHABLE }
        if (task.errors?.length) return { can: false, error: TASK_ERRORS.TASK_HAS_ERRORS }

        return { can: true, error: undefined }
      },
    [getStartPermission, getFinishPermission]
  )
}

export const useDeleteTaskPermission = () => {
  return useRecoilCallback(
    ({ snapshot }) =>
      (taskId: number) => {
        const isRunbookEditable = snapshot.getLoadable(isVersionEditable).getValue()
        if (!isRunbookEditable) return { can: false, error: TASK_ERRORS.VERSION_NOT_EDITABLE }

        const task = snapshot.getLoadable(taskListTaskState(taskId)).getValue()
        if (!task) return { can: false, error: TASK_ERRORS.NO_TASK }
        const isTaskStreamPermitted = snapshot
          .getLoadable(isStreamPermittedState({ streamId: task.stream_id }))
          .getValue()
        if (!isTaskStreamPermitted) return { can: false, error: TASK_ERRORS.NO_PERMITTED_STREAM }
        if (!['default', 'startable'].includes(task.stage)) return { can: false, error: TASK_ERRORS.TASK_STAGE }
        return { can: true, error: undefined }
      },
    []
  )
}

export const useBulkDeleteTaskPermission = () => {
  const getCanDeleteTask = useDeleteTaskPermission()

  return useRecoilCallback(
    () => (taskIds: number[]) => {
      return taskIds.reduce(
        (acc, id) => {
          const { can, error } = getCanDeleteTask(id)
          if (can) return acc

          acc.can = false
          acc.errors[id] = { can: false, error: error as string }
          return acc
        },
        { can: true, errors: {} } as { can: boolean; errors: Record<number, { can: false; error: string }> }
      )
    },
    [getCanDeleteTask]
  )
}

export const useUpdateTaskPermission = useDeleteTaskPermission

export const useBulkUpdateTaskPermission = useBulkDeleteTaskPermission

export const useFinishTaskPermission = () => {
  const getIsTaskAdmin = useIsTaskAdmin()
  const getTaskUserIds = useTaskUserIds()

  return useRecoilCallback(
    ({ snapshot }) =>
      (taskId: number) => {
        const task = snapshot.getLoadable(taskListTaskState(taskId)).getValue()

        // base requirements
        if (task.stage !== 'in-progress') return { can: false, error: TASK_ERRORS.TASK_NOT_FINISHABLE }
        if (!snapshot.getLoadable(isVersionCurrentState).getValue())
          return { can: false, error: TASK_ERRORS.VERSION_NOT_EDITABLE }
        const { loadingIds } = snapshot.getLoadable(runbookViewState_INTERNAL).getValue()
        if (loadingIds[taskId]) return { can: false, error: TASK_ERRORS.TASK_IS_LOADING }

        if (!!task.linked_resource?.id) return { can: false, error: TASK_ERRORS.TASK_IS_LINKED_RUNBOOK }

        if (getIsTaskAdmin()) return { can: true }

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const currentUserId = snapshot.getLoadable(currentUserIdState).getValue()!
        const userIds = getTaskUserIds(taskId)
        if (!!currentUserId && !userIds.includes(currentUserId))
          return { can: false, error: TASK_ERRORS.USER_NOT_ASSIGNED }

        if (task.end_requirements === 'any_can_end') return { can: true }
        if (task.end_requirements === 'all_must_end' && !task.ended_user_ids?.includes(currentUserId))
          return { can: true }
        if (task.end_requirements === 'same_must_end' && task.started_user_ids?.includes(currentUserId))
          return { can: true }

        return { can: false, error: TASK_ERRORS.USER_NOT_PERMITTED }
      },
    [getIsTaskAdmin, getTaskUserIds]
  )
}

const useIsTaskAdmin = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      () => {
        return snapshot.getLoadable(runbookPermission({ attribute: 'update' })).getValue()
      },
    []
  )

const useTaskUserIds = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      (taskId: number) => {
        const task = snapshot.getLoadable(taskListTaskState(taskId)).getValue()
        const teamLookup = snapshot.getLoadable(teamsStateLookup).getValue()

        return task.runbook_team_ids.flatMap(teamId => teamLookup[teamId]?.user_ids ?? []).concat(task.user_ids)
      },
    []
  )

export const useActionTaskPermission = () => {
  const getIsTaskAdmin = useIsTaskAdmin()
  const getTaskUserIds = useTaskUserIds()
  return useRecoilCallback(
    ({ snapshot }) =>
      (taskId: number) => {
        const task = snapshot.getLoadable(taskListTaskState(taskId)).getValue()
        const isAdmin = getIsTaskAdmin()

        // base requirements
        if (!snapshot.getLoadable(isVersionCurrentState).getValue())
          return { can: false, error: TASK_ERRORS.VERSION_NOT_EDITABLE }
        const { loadingIds } = snapshot.getLoadable(runbookViewState_INTERNAL).getValue()
        if (loadingIds[taskId]) return { can: false, error: TASK_ERRORS.TASK_IS_LOADING }

        if (isAdmin) return { can: true }

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const currentUserId = snapshot.getLoadable(currentUserIdState).getValue()!
        const userIds = getTaskUserIds(taskId)
        if (!userIds.includes(currentUserId)) return { can: false, error: TASK_ERRORS.USER_NOT_ASSIGNED }

        if (task.start_requirements === 'any_can_start') return { can: true }
        if (!task.started_user_ids?.includes(currentUserId)) return { can: true }

        return { can: false, error: TASK_ERRORS.USER_NOT_PERMITTED }
      },
    [getIsTaskAdmin, getTaskUserIds]
  )
}

export const useStartTaskPermission = () => {
  const getCanActionTask = useActionTaskPermission()

  return useRecoilCallback(
    ({ snapshot }) =>
      (taskId: number) => {
        const task = snapshot.getLoadable(taskListTaskState(taskId)).getValue()

        const { can: canAction, error } = getCanActionTask(taskId)
        if (!canAction) return { can: canAction, error }

        const can = task.stage === 'startable'
        return { can, error: can ? undefined : TASK_ERRORS.TASK_NOT_STARTABLE }
      },
    [getCanActionTask]
  )
}

// @ts-ignore TODO: fix types
export const useActionTaskPermissionCallback: TaskModelType['usePermissionCallbackSync'] = action => {
  const getCreatePermission = useCreateTaskPermission()
  const getProgressPermission = useProgressTaskPermission()
  const getDeletePermission = useDeleteTaskPermission()
  const getUpdatePermission = useUpdateTaskPermission()
  const getFinishPermission = useFinishTaskPermission()
  const getStartPermission = useStartTaskPermission()
  const getActionPermission = useActionTaskPermission()
  const getSkipPermission = useSkipTaskPermission()
  const getBulkSkipPermission = useBulkSkipTasksPermission()
  const getBulkDeletePermission = useBulkDeleteTaskPermission()
  const getBulkUpdatePermission = useBulkUpdateTaskPermission()

  switch (action) {
    case 'progress':
      return getProgressPermission
    case 'create':
      return getCreatePermission
    case 'delete':
      return getDeletePermission
    case 'update':
      return getUpdatePermission
    case 'finish':
      return getFinishPermission
    case 'start':
      return getStartPermission
    case 'skip':
      return getSkipPermission
    case 'bulk_skip':
      return getBulkSkipPermission
    case 'bulk_delete':
      return getBulkDeletePermission
    case 'bulk_update':
      return getBulkUpdatePermission
    case undefined:
      return getActionPermission
    default:
      throw new Error(`TaskModel.usePermissionCallbackSync(${action}) not yet implemented`)
  }
}

/* -------------------------------------------------------------------------- */
/*                                   Action                                   */
/* -------------------------------------------------------------------------- */

export const useSkipTask = () => {
  const notify = useNotify()
  const { t } = useLanguage('tasks')
  const openModal = useModalOpen()
  const getCanSkip = useSkipTaskPermission()

  return useRecoilCallback(() => async (taskId: number) => {
    const canSkip = await getCanSkip(taskId)

    if (!canSkip) {
      notify.warning(t('list.unableToSkipWarning.message'), { title: t('list.unableToSkipWarning.title') })
    } else {
      openModal({ type: 'tasks-skip', id: [taskId] })
    }
  })
}

export const useBulkSkipTask = () => {
  const notify = useNotify()
  const { t } = useLanguage('tasks')
  const openModal = useModalOpen()
  const getCanBulkSkip = useBulkSkipTasksPermission()

  return useRecoilCallback(
    () => async (ids: number[]) => {
      const canBulkSkip = await getCanBulkSkip(ids)

      if (!canBulkSkip) {
        notify.warning(t('list.unableToSkipWarning.message'), { title: t('list.unableToSkipWarning.title') })
      } else {
        openModal({ type: 'tasks-skip', id: ids })
      }
    },
    [notify, t, openModal, getCanBulkSkip]
  )
}

export const useProgressTask = () => {
  const { t } = useLanguage('tasks')
  const modalValueCallback = useModalValueCallback()
  const closeModal = useModalClose()
  const loadingIdAdd = useLoadingIdAdd()
  const loadingIdRemove = useLoadingIdRemove()
  const processTaskStartResponse = useProcessTaskStartResponse()
  const processTaskFinishResponse = useProcessTaskFinishResponse()
  const notify = useNotify()
  const { taskStartNotification, taskFinishNotification } = useTaskNotifications()

  return useRecoilCallback(
    ({ snapshot }) =>
      async (id: number, payload: Partial<TaskStartPayload | TaskFinishPayload> = {}) => {
        const progressionState = await snapshot.getPromise(taskProgressionState(id))
        const { history } = await modalValueCallback()
        const runbookId = await snapshot.getPromise(runbookIdState)
        const runbookVersionId = await snapshot.getPromise(runbookVersionIdState)
        const override = !!history.find(modal => {
          if (modal.type === 'task-override-fixed-start') return true
          if (modal.type === 'task-override' && !progressionState?.override?.optional) return true
          return modal.type === 'task-override' && (modal.context as { override: boolean } | undefined)?.override
        })

        closeModal() // Important: don't move this above anything that uses the modalHistory otherwise it will clear it

        try {
          loadingIdAdd(id)
          const startable = progressionState?.stage === 'startable'
          const request = startable ? startTask : finishTask
          const response = await request({
            runbookId,
            runbookVersionId,
            taskId: id,
            payload: {
              override,
              field_values_attributes: [],
              selected_successor_ids: [],
              ...payload
            }
          })
          if (response) {
            if (startable) {
              processTaskStartResponse(response as RunbookTaskStartResponse)
              taskStartNotification(response as RunbookTaskStartResponse)
            } else {
              processTaskFinishResponse(response as RunbookTaskFinishResponse)
              taskFinishNotification(response as RunbookTaskFinishResponse)
            }
          }
          loadingIdRemove(id)
        } catch (e: any) {
          const errorMessage = getServerErrorMessages(
            e,
            t(
              progressionState?.stage === 'startable'
                ? 'taskActionModal.errorStartMessage'
                : 'taskActionModal.errorFinishMessage'
            )
          )[0]
          loadingIdRemove(id)
          notify.error(errorMessage, {
            title: t(
              progressionState?.stage === 'startable'
                ? 'taskActionModal.errorStartTitle'
                : 'taskActionModal.errorFinishTitle'
            )
          })
        }
      },
    [
      t,
      modalValueCallback,
      closeModal,
      loadingIdAdd,
      loadingIdRemove,
      processTaskFinishResponse,
      processTaskStartResponse,
      notify,
      taskStartNotification,
      taskFinishNotification
    ]
  )
}

export type TaskActionType = {
  progress: ReturnType<typeof useProgressTask>
  skip: ReturnType<typeof useSkipTask>
  bulk_skip: ReturnType<typeof useBulkSkipTask>
}

/* eslint-disable react-hooks/rules-of-hooks */
// @ts-ignore TODO: figure this out
export const useActionTask: TaskModelType['useAction'] = action => {
  const stabilizedAction = useRef(action).current

  switch (stabilizedAction) {
    case 'progress':
      return useProgressTask()
    case 'skip':
      return useSkipTask()
    case 'bulk_skip':
      return useBulkSkipTask()
    default:
      throw new Error(`${action} is not yet implemented in useOnAction`)
  }
}
/* eslint-enable react-hooks/rules-of-hooks */

/* -------------------------------------------------------------------------- */
/*                                   Actions                                  */
/* -------------------------------------------------------------------------- */

export const useHandleTaskAction: TaskModelType['useOnAction'] = () => {
  const processTaskUpdateResponse = useProcessTaskUpdateResponse()
  const processTaskIntegrationResponse = useProcessTaskIntegrationResponse()
  const processTaskStartResponse = useProcessTaskStartResponse()
  const processTaskFinishResponse = useProcessTaskFinishResponse()
  const processTaskCreateResponse = useProcessTaskCreateResponse()
  const processTaskBulkDeleteResponse = useProcessTaskBulkDeleteResponse()
  const processTaskBulkSkipResponse = useProcessTaskBulkSkipResponse()
  const processTaskBulkCreateResponse = useProcessTaskBulkCreateResponse()
  const processTaskPasteResponse = useProcessTaskPasteResponse()
  const processTaskBulkUpdateResponse = useProcessTasksBulkUpdateResponse()

  return useCallback(
    (response: RunbookResponse) => {
      // @ts-ignore version_data does not exist on all task response types (not quick_update) but this conditional is based on its presence value
      if (response.meta.version_data?.stage === 'complete') {
        window.dispatchEvent(new CustomEvent('refresh-data-store', { detail: { type: 'runbook-version' } }))
      }

      switch (response.meta.headers.request_method) {
        case 'create':
          processTaskCreateResponse(response as RunbookTaskCreateResponse)
          break
        case 'update':
          processTaskUpdateResponse(response as RunbookTaskUpdateResponse)
          break
        case 'start':
          processTaskStartResponse(response as RunbookTaskStartResponse)
          break
        case 'finish':
          processTaskFinishResponse(response as RunbookTaskFinishResponse)
          break
        case 'bulk_delete':
          processTaskBulkDeleteResponse(response as RunbookTaskBulkDeleteResponse)
          break
        case 'bulk_skip':
          processTaskBulkSkipResponse(response as RunbookTaskBulkSkipResponse)
          break
        case 'bulk_create':
          processTaskBulkCreateResponse(response as RunbookTaskBulkCreateResponse)
          break
        case 'bulk_update':
          processTaskBulkUpdateResponse(response as RunbookTaskBulkUpdateResponse)
          break
        case 'integration':
          processTaskIntegrationResponse(response as RunbookTaskIntegrationResponse)
          break
        case 'paste':
          processTaskPasteResponse(response as RunbookTaskPasteResponse)
        default:
          console.warn('Not yet handling Task request method: ', response.meta.headers.request_method)
          return
      }
    },
    [
      processTaskCreateResponse,
      processTaskStartResponse,
      processTaskFinishResponse,
      processTaskUpdateResponse,
      processTaskBulkDeleteResponse,
      processTaskBulkSkipResponse,
      processTaskBulkCreateResponse
    ]
  )
}

export const useProcessTaskUpdateResponse = () => {
  return useRecoilTransaction_UNSTABLE(
    transactionInterface => (response: RunbookTaskUpdateResponse) => {
      const { set } = transactionInterface
      set(taskEditUpdatedTaskId, response.task)

      if (response.meta.possible_changed_fields) {
        updateCustomFieldsData(transactionInterface)(response.meta.possible_changed_fields)
      }

      const versionData = response.meta.version_data
      updateTasksAndVersion(transactionInterface)({
        changedTasks: response.meta.changed_tasks,
        versionData: versionData
      })
    },
    []
  )
}

export const useProcessTaskIntegrationResponse = () => {
  return useRecoilTransaction_UNSTABLE(
    transactionInterface => (response: RunbookTaskIntegrationResponse) => {
      const { set } = transactionInterface
      set(taskEditUpdatedTaskId, response.task)

      const versionData = response.meta.version_data
      updateTasksAndVersion(transactionInterface)({
        changedTasks: response.meta.changed_tasks,
        task: response.task,
        versionData: versionData
      })
    },
    []
  )
}

export const useProcessTaskCreateResponse = () => {
  return useRecoilTransaction_UNSTABLE(
    transactionInterface => (response: RunbookTaskCreateResponse) => {
      const versionData = response.meta.version_data
      updateTasksAndVersion(transactionInterface)({
        changedTasks: response.meta.changed_tasks as RunbookChangedTask[],
        versionData: versionData
      })
    },
    []
  )
}

export const useProcessTaskStartResponse = () => {
  return useRecoilTransaction_UNSTABLE(
    transactionInterface => (response: RunbookTaskStartResponse) => {
      const versionData = response.meta.version_data
      updateTasksAndVersion(transactionInterface)({
        changedTasks: response.meta.changed_tasks,
        task: response.task,
        versionData: versionData
      })
    },
    []
  )
}

export const useProcessTaskFinishResponse = () => {
  return useRecoilCallback(
    ({ snapshot, set, reset }) =>
      async (response: RunbookTaskFinishResponse) => {
        const transactionInterface: TransactionInterface_UNSTABLE = {
          get: recoilValue => snapshot.getLoadable(recoilValue).getValue(),
          set,
          reset
        }

        const versionData = response.meta.version_data
        if (versionData?.stage === 'complete') {
          updateAllChangedTasks(transactionInterface)(response.meta.changed_tasks)

          // Dispatching custom events outside Recoil state management
          window.dispatchEvent(new CustomEvent('refresh-data-store', { detail: { type: 'runbook-version' } }))
          window.dispatchEvent(new CustomEvent('refresh-data-store', { detail: { type: 'runbook' } }))
        } else {
          updateTasksAndVersion(transactionInterface)({
            changedTasks: response.meta.changed_tasks,
            task: response.task,
            versionData: versionData
          })
        }
      },
    []
  )
}

// TODO: does not handle deleted_runbook_component_ids updates
export const useProcessTaskBulkDeleteResponse = () => {
  return useRecoilTransaction_UNSTABLE(
    transactionInterface => (response: RunbookTaskBulkDeleteResponse) => {
      const { set } = transactionInterface

      updateTasksAndVersion(transactionInterface)({
        changedTasks: response.meta.changed_tasks,
        versionData: response.meta.version_data
      })

      // performance-wise: make more sense to do this before or after updateTasksAndVersion
      set(taskListResponseState_INTERNAL, prevTaskResponse =>
        produce(prevTaskResponse, draftTaskResponse => {
          pullAllWith(
            draftTaskResponse.tasks,
            response.meta.deleted_task_ids,
            (task, deletedId) => task.id === deletedId
          )
        })
      )

      set(runbookViewState_INTERNAL, prevTaskResponse =>
        produce(prevTaskResponse, draftTaskResponse => {
          pullAllWith(draftTaskResponse.selectedIds, response.meta.deleted_task_ids)
        })
      )
    },
    []
  )
}

// Handles skipping either single or multiple tasks
// NOTE: keeping this separate because it has unhandled meta data
export const useProcessTaskBulkSkipResponse = () => {
  return useRecoilCallback(
    callbackInterface => (response: RunbookTaskBulkSkipResponse) => {
      const { transact_UNSTABLE } = callbackInterface

      transact_UNSTABLE(transactionInterface => {
        updateTasksAndVersion(transactionInterface)({
          changedTasks: response.meta.changed_tasks,
          versionData: response.meta.version_data
        })
      })

      updateAddNewComments(callbackInterface)({
        comments: response.meta.comments,
        requesterId: response.meta.headers.request_user_id
      })
    },
    []
  )
}

// NOTE: keeping this separate because it has unhandled `new_task_ids` in meta respose. If we do not have to handle
// this, can just use the `useProcessTaskUpdateResponse` handler
// TODO: does not handle new_task_ids updates: However, do we need to distinguish this if we're iterating over changed tasks and adding any tasks that aren't there to the task collection
export const useProcessTaskBulkCreateResponse = () => {
  return useRecoilTransaction_UNSTABLE(
    transactionInterface => (response: RunbookTaskBulkCreateResponse) => {
      updateTasksAndVersion(transactionInterface)({
        changedTasks: response.meta.changed_tasks,
        versionData: response.meta.version_data
      })
    },
    []
  )
}

export const useProcessTaskPasteResponse = () => {
  return useRecoilTransaction_UNSTABLE(transactionInterface => (response: RunbookTaskPasteResponse) => {
    updateTasksAndVersion(transactionInterface)({
      changedTasks: response.meta.changed_tasks,
      versionData: response.meta.version_data
    })
  })
}

export const useProcessTasksBulkUpdateResponse = () => {
  return useRecoilTransaction_UNSTABLE(transactionInterface => (response: RunbookTaskBulkUpdateResponse) => {
    updateTasksAndVersion(transactionInterface)({
      changedTasks: response.meta.changed_tasks,
      versionData: response.meta.version_data
    })
  })
}
