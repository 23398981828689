// @ts-nocheck
import { LoadingPageStyled } from './LoadingPageStyles'

export const LoadingPage = () => {
  return (
    <>
      <LoadingPageStyled>
        <div className="splash">
          <svg
            id="logo"
            version="1.1"
            className="alive"
            xmlns={'http://www.w3.org/2000/svg'}
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 300 300"
            enableBackground="new 0 0 300 300"
            xmlSpace="preserve"
          >
            <path
              className="animate-logo"
              d="M113.33 187.036C92.7447 166.583 92.7447 133.377 113.33 112.924L79.4652 79.2761C61.2614 97.4033 50 122.388 50 149.98C50 205.204 95.0454 250 150.626 250C178.436 250 203.582 238.811 221.786 220.724L187.921 187.076C167.336 207.53 133.956 207.53 113.33 187.036Z"
              fill="white"
            />
            <path
              className="animate-logo animate-logo-third"
              opacity="0.4"
              d="M150.626 50C146.67 50 142.755 50.2406 138.92 50.6818L250 134.179C242.412 86.4949 200.797 50 150.626 50Z"
              fill="white"
            />
            <path
              className="animate-logo animate-logo-second"
              opacity="0.7"
              d="M84.3895 74.7844L124.228 104.702C144.41 93.0319 170.686 95.759 187.962 112.924C201.604 126.479 206.165 145.609 201.725 162.934L241.564 192.892C244.551 186.635 246.892 180.018 248.547 173.12L127.336 52.7271C111.15 56.537 96.4985 64.237 84.3895 74.7844Z"
              fill="white"
            />
          </svg>
        </div>
      </LoadingPageStyled>
    </>
  )
}
