import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'

import { Accordion, AccordionPanel, Message } from '@cutover/react-ui'
import {
  DateTimePickerField,
  DurationPickerField,
  RadioboxGroupField,
  StreamSelectField,
  TextEditorField
} from 'main/components/shared/form'
import { useLanguage } from 'main/services/hooks'
import { TaskType } from 'main/services/queries/types'
import { TaskAdvancedSettings } from '../task-edit-panel/task-advanced-settings'
import { TaskSelectField } from 'main/components/shared/form/task-select'
import {
  CustomFieldsGroupsForm,
  CustomFieldsGroupsFormProps
} from 'main/components/shared/custom-field-form/custom-field-groups-form'
import { TasksBulkEditFormType } from './tasks-bulk-edit-panel'
import { TaskTypeSelectField } from './task-type-select-field'
import { AssignedUsersTeamsField } from './assigned-users-teams-field'
import { ConfigModel, StreamModel, TaskModel, TaskTypeModel } from 'main/data-access'

export const TasksBulkEditForm = ({
  hasMoreThanOneTaskTypeSelected,
  showFixedStartField,
  showFixedEndField,
  setTaskType,
  ...customFieldsProps
}: CustomFieldsGroupsFormProps & {
  setTaskType: (taskType?: TaskType) => void
  hasMoreThanOneTaskTypeSelected: boolean
  showFixedStartField: boolean
  showFixedEndField: boolean
}) => {
  const { t } = useLanguage('tasks')

  const streams = StreamModel.useGetAll({ scope: 'permitted' })
  const streamLookup = StreamModel.useGetLookup()
  const { taskLevels } = ConfigModel.useGet()
  const { watch } = useFormContext<TasksBulkEditFormType>()

  const taskTypeId = watch('task_type_id')
  const taskTypesLookup = TaskTypeModel.useGetLookup()
  const isTaskTypeAutoFinish = taskTypeId ? taskTypesLookup[taskTypeId]?.auto_finish : false

  useEffect(() => {
    if (taskTypeId) {
      const currentTaskType = taskTypesLookup[taskTypeId]
      setTaskType(currentTaskType)
    } else {
      setTaskType(undefined)
    }
  }, [taskTypeId])

  const tasks = TaskModel.useGetAll()

  return (
    <>
      <TaskTypeSelectField />
      {!isTaskTypeAutoFinish && (
        <DurationPickerField<TasksBulkEditFormType> label={t('editPanel.fields.duration.label')} name="duration" />
      )}
      {isTaskTypeAutoFinish && <Message type="info" message={t('bulkEditPanel.fields.duration.info')}></Message>}
      <StreamSelectField<TasksBulkEditFormType>
        label={t('editPanel.fields.stream.label')}
        streams={streams ?? []}
        name="stream_id"
        placeholder={t('editPanel.fields.stream.placeholder')}
        loading={streams === undefined}
      />
      <RadioboxGroupField<TasksBulkEditFormType>
        name="level"
        label={t('editPanel.fields.level.label')}
        direction="row"
        options={taskLevels.map(level => ({ value: level.id, label: level.name })).reverse()}
      />
      <Accordion>
        {hasMoreThanOneTaskTypeSelected ? (
          <AccordionPanel icon="custom-field" label={t('bulkEditPanel.fields.customFields.label')}>
            <Message type="info" message={t('bulkEditPanel.fields.customFields.info')}></Message>
          </AccordionPanel>
        ) : (
          <CustomFieldsGroupsForm {...customFieldsProps} />
        )}
        <AccordionPanel icon="info" label={t('editPanel.description.label')}>
          <TextEditorField<TasksBulkEditFormType> name="description" css="padding-bottom: 0px;" />
        </AccordionPanel>
        <AccordionPanel icon="time" label={t('editPanel.fields.timingDetails.accordionLabel')}>
          {!showFixedStartField && !showFixedEndField && (
            <Message type="info" message={t('bulkEditPanel.fields.timingDetails.info')}></Message>
          )}
          {showFixedStartField && (
            <DateTimePickerField<TasksBulkEditFormType>
              name="start_fixed"
              label={t('editPanel.fields.timingDetails.fixedStart')}
            />
          )}
          {showFixedEndField && (
            <DateTimePickerField<TasksBulkEditFormType>
              name="end_fixed"
              label={t('editPanel.fields.timingDetails.dueDate')}
            />
          )}
        </AccordionPanel>
        <AccordionPanel label={t('editPanel.fields.assignedUsersTeams.accordion')} icon="users">
          <AssignedUsersTeamsField />
          <RadioboxGroupField<TasksBulkEditFormType>
            name="user_modify_type"
            label={t('bulkEditPanel.fields.operationType.label')}
            direction="row"
            options={[
              { label: t('bulkEditPanel.fields.operationType.add'), value: 'add' },
              { label: t('bulkEditPanel.fields.operationType.replace'), value: 'replace' },
              { label: t('bulkEditPanel.fields.operationType.remove'), value: 'remove' }
            ]}
          />
        </AccordionPanel>
        <AccordionPanel label={t('editPanel.dependencies.header')} icon="predecessors">
          <TaskSelectField<TasksBulkEditFormType>
            label={t('editPanel.fields.predecessors.label')}
            name="predecessor_ids"
            tasks={tasks}
            placeholderValue={t('editPanel.fields.predecessors.placeholderValue')}
            placeholder={t('editPanel.fields.predecessors.placeholder')}
            required={false}
            taskTypeLookup={taskTypesLookup}
            streamLookup={streamLookup}
          />
          <RadioboxGroupField<TasksBulkEditFormType>
            name="predecessor_modify_type"
            label={t('bulkEditPanel.fields.operationType.label')}
            direction="row"
            options={[
              { label: t('bulkEditPanel.fields.operationType.add'), value: 'add' },
              { label: t('bulkEditPanel.fields.operationType.replace'), value: 'replace' },
              { label: t('bulkEditPanel.fields.operationType.remove'), value: 'remove' }
            ]}
          />
        </AccordionPanel>
        <TaskAdvancedSettings />
      </Accordion>
    </>
  )
}
