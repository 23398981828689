import { format } from 'date-fns'

import { CogRatio, NodeData, NodeMapState, PaintNodeEntryPoint } from './node-map-types'
import { StreamListStream, TaskListTask, TaskType } from 'main/services/queries/types'
import { BASE_UNIT } from './node-map-state'

export const prepareNode = ({
  task,
  filteredTaskIds,
  filterMode,
  streamLookup,
  taskTypeLookup,
  criticalPath,
  isUpdate = false
}: {
  task: TaskListTask
  filteredTaskIds: number[]
  filterMode: PaintNodeEntryPoint['filterMode']
  streamLookup: Record<number, StreamListStream>
  taskTypeLookup: Record<number, TaskType>
  criticalPath: number[]
  isUpdate?: boolean
}) => {
  const stream = streamLookup[task.stream_id]
  let nodeData: Partial<NodeData> = {
    label: `#${task.internal_id} ${task.name}`,
    metaLabel: stream.parent_id ? `${streamLookup[stream.parent_id].name}  &#8227; ${stream.name}` : stream.name,
    miniLabel: task.name,
    miniMetaLabel1: `#${task.internal_id} ${stream.name}`,
    miniMetaLabel2: `${format(task.start_display * 1000, 'd MMM HH:mm')} ${formatTimeDifference(task.duration)?.label}`,
    internalId: task.internal_id ?? null,
    stage: task.stage,
    level: task.level,
    // float: task.float, TODO: verify
    isCritical: criticalPath.includes(task.id),
    isCriticalTemp: criticalPath.includes(task.id),
    startFixed: task.start_fixed,
    start: task.start_display,
    end: task.end_display,
    duration: task.duration,
    highlight: filterMode === 'highlight' && filteredTaskIds.includes(task.id),
    scaleMod: 1,
    completionType: task.completion_type,
    color: stream.color,
    shape: taskTypeLookup[task.task_type_id].icon,
    hasLinkedTemplate: !!task.linked_resource?.id
  }

  if (!isUpdate) {
    nodeData.id = task.id
    nodeData.width = BASE_UNIT
    nodeData.height = BASE_UNIT
    nodeData.scale = 1 // for animations
  }

  return nodeData
}

export const formatTimeDifference = (duration: number | undefined) => {
  if (duration === undefined) return

  const totalSeconds = Math.abs(duration)

  const weeks = Math.floor(totalSeconds / (60 * 60 * 24 * 7))
  const days = Math.floor((totalSeconds % (60 * 60 * 24 * 7)) / (60 * 60 * 24))
  const hours = Math.floor((totalSeconds % (60 * 60 * 24)) / (60 * 60))
  const minutes = Math.floor((totalSeconds % (60 * 60)) / 60)
  const seconds = Math.floor(totalSeconds % 60)

  let precision: 'day' | 'hour' | 'minute' | 'second' = 'second'
  if (weeks > 0) precision = 'day'
  else if (days > 0) precision = 'hour'
  else if (hours > 0) precision = 'minute'
  else if (minutes > 0) precision = 'second'

  const formatUnit = (value: number, unit: string) => (value > 0 ? `${value}${unit} ` : '')

  let label = ''
  switch (precision) {
    case 'day':
      label = formatUnit(weeks, 'w') + formatUnit(days, 'd')
      break
    case 'hour':
      label = formatUnit(weeks, 'w') + formatUnit(days, 'd') + formatUnit(hours, 'h')
      break
    case 'minute':
      label = formatUnit(weeks, 'w') + formatUnit(days, 'd') + formatUnit(hours, 'h') + formatUnit(minutes, 'm')
      break
    case 'second':
      label =
        formatUnit(weeks, 'w') +
        formatUnit(days, 'd') +
        formatUnit(hours, 'h') +
        formatUnit(minutes, 'm') +
        formatUnit(seconds, 's')
      break
  }

  return {
    label: label.trim(),
    late: duration > 0
  }
}

export const generateColor = (state: NodeMapState) => {
  const ret = []
  if (state.colorCurrentIndex < 16777215) {
    ret.push(state.colorCurrentIndex & 0xff) // R
    ret.push((state.colorCurrentIndex & 0xff00) >> 8) // G
    ret.push((state.colorCurrentIndex & 0xff0000) >> 16) // B
    state.colorCurrentIndex += 1
  }
  return `rgb(${ret.join(',')})`
}

export const calculateCogRatios = (state: NodeMapState, cogAngle: number, toothAngle: number) => {
  if (state.cogRatios) {
    return state.cogRatios
  }

  const gapAngle = state.units.cogAngle * state.units.cogGapBreadth * 0.005
  const ratios: CogRatio[] = []

  for (let angle = 0; angle < state.units.turn; angle += cogAngle) {
    ratios.push({
      toothRatioX: Math.cos(angle - toothAngle),
      toothRatioY: Math.sin(angle - toothAngle),
      gapRatioX: Math.cos(angle + gapAngle),
      gapRatioY: Math.sin(angle + gapAngle)
    })
  }

  return (state.cogRatios = ratios)
}

export const lineCrossesViewport = (x1: number, y1: number, x2: number, y2: number, state: NodeMapState) => {
  if (inViewport(x1, y1, state) || inViewport(x2, y2, state)) {
    return true
  }
  for (var i = 0; i < state.viewport.edges.length; i++) {
    if (
      linesIntersect(
        x1,
        y1,
        x2,
        y2,
        state.viewport.edges[i][0],
        state.viewport.edges[i][1],
        state.viewport.edges[i][2],
        state.viewport.edges[i][3]
      )
    ) {
      return true
    }
  }
  return false
}

export const linesIntersect = (
  x1: number,
  y1: number,
  x2: number,
  y2: number,
  x3: number,
  y3: number,
  x4: number,
  y4: number
) => {
  //https://stackoverflow.com/questions/9043805/test-if-two-lines-intersect-javascript-function
  let det, gamma, lambda
  det = (x2 - x1) * (y4 - y3) - (x4 - x3) * (y2 - y1)
  if (det === 0) {
    return false
  } else {
    lambda = ((y4 - y3) * (x4 - x1) + (x3 - x4) * (y4 - y1)) / det
    gamma = ((y1 - y2) * (x4 - x1) + (x2 - x1) * (y4 - y1)) / det
    return 0 < lambda && lambda < 1 && 0 < gamma && gamma < 1
  }
}

export const inViewport = (x: number, y: number, state: NodeMapState) => {
  const margin = -1 * state.units.base
  if (
    x < state.viewport.internalOffsetX + margin ||
    x > state.viewport.internalOffsetX + state.viewport.internalWidth - margin / 2
  ) {
    return false
  }
  if (
    y < state.viewport.internalOffsetY + margin ||
    y > state.viewport.internalOffsetY + state.viewport.internalHeight - margin / 2
  ) {
    return false
  }
  return true
}
