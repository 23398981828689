import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const RunbookIntegrations = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 24 24" a11yTitle="RunbookIntegrations" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M19.302 21.499c1.033 0 2.17-.785 2.17-1.897l.028-8.635c0-.315-.404-.462-.645-.434-.227.026-.426.158-.496.368a.862.862 0 0 0-.028.274v4.453l-.001 3.537c0 .733-.409 1.143-1.138 1.143-4.677.002-9.354.002-14.032 0-.72 0-1.136-.41-1.136-1.127-.001-4.678 0-9.356.002-14.034 0-.131.016-.267.053-.393.143-.493.515-.731 1.084-.731h7.97c.066 0 .133 0 .199-.005.259-.02.464-.29.484-.55.023-.29-.209-.635-.526-.635H4.892c-1.226 0-2.057 1.158-2.057 2.216a45948.665 45948.665 0 0 1-.001 14.235c0 1.19 1.025 2.215 2.218 2.215 4.75.002 9.5.001 14.25 0Zm1.389-12.666c.604 0 .809-.419.809-.84V3.57c0-.441-.205-.736-.765-.736h-4.506c-.561 0-.732.428-.729.796.01 1.083.008 2.173.007 3.26v.002l-.002 1.197c0 .503.237.74.74.74l1.293-.001c1.051-.001 2.102-.003 3.153.006Zm-.46-2.402v1.17h-1.206a1 1 0 0 0 .475-.851c.289 0 .55-.123.732-.319Zm0-1.363v-.972h-.975a.996.996 0 0 1 .244.654c.289 0 .55.122.732.318Zm-2.487-.972h-.954v.95a.997.997 0 0 1 .71-.296c0-.25.092-.479.244-.654Zm-.954 2.358a.997.997 0 0 0 .71.296 1 1 0 0 0 .476.851H16.79V6.454Zm.46 5.579c.003-.388-.244-.713-.7-.707-.913.012-1.826.01-2.74.008l-1.066-.002h-.239v-.243c0-.36 0-.72-.002-1.082-.002-.901-.005-1.803.009-2.704.007-.49-.335-.718-.712-.715a400.8 400.8 0 0 1-3.195.005H7.307c-.487 0-.723.235-.723.72v9.222c0 .484.237.72.723.72h9.222c.475 0 .715-.238.716-.709v-1.41a320.75 320.75 0 0 1 .005-3.102v-.002ZM11.5 16H7.81l-.007-1.25c0-.062.002-.109.003-.162.002-.071.004-.154.004-.303v-.47L7.802 12.5H9c-.34.009.25 0 .25 0h2.25V16Zm-.181-4.685H7.802V7.808h3.517v3.507Zm4.709 4.723H12.51V12.53h3.517v3.507Z" fill="#7D7D81" />
  </StyledIcon>
))

RunbookIntegrations.displayName = 'RunbookIntegrations'

export { RunbookIntegrations }
