// @ts-nocheck

import styled from 'styled-components'
import { colors, px2rem } from 'app/Styles/Variables'

const HeaderPanel = styled.header`
  z-index: 3;
  width: 100%;
  min-height: ${px2rem(64)};
  max-height: ${px2rem(64)};
  background-color: ${colors.bg1} !important;
  position: relative !important;
  overflow: visible !important;
`

interface IHeaderPanelWrapperProps {
  extraStyles?: CSSProperties
  children: ReactNode
}

export const HeaderPanelWrapper = (props: IHeaderPanelWrapperProps) => (
  <HeaderPanel style={props.extraStyles}>{props.children}</HeaderPanel>
)
