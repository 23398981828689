// @ts-nocheck
import { observable, computed } from 'mobx'
import { container } from 'app/Config/IOC'
import { ButtonPresenter, PopoverPresenter } from 'Components/Molecules'
import { TaskTypesViewPresenter } from 'app/Settings/TaskTypes/TaskTypesPage/TaskTypesViewPresenter'
import { TableScrollerService } from 'Components/Organisms'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'
import { Types } from 'Gateways/Service/Types'
import { Router } from 'app/Routing/Router'
import { RoutingState } from 'app/Routing/RoutingState'
import { IGenericFilter } from 'app/Filters/FilterEntities'
import { FiltersRepository } from 'app/Filters/FiltersRepository'

export class TaskTypesPageMoreOptionsPopoverPresenter {
  private taskTypesViewPresenter: TaskTypesViewPresenter = container.get(TaskTypesViewPresenter)

  private tableScrollerService: TableScrollerService = container.get(TableScrollerService)

  public routingState: RoutingState = container.get(RoutingState)

  private router: Router = container.get(Router)

  public language: ILanguageService = container.get(Types.ILanguageService)

  private filtersRepository: FiltersRepository = container.get(FiltersRepository)

  @observable
  public reloadClicked: boolean = false

  @observable
  public reloadDataPopoverPresenter: PopoverPresenter = new PopoverPresenter().withPosition('bottom right')

  @observable
  public popoverOpenState: boolean = false

  public currentRoutingState: { routeId: string; params: any; query: string } = this.routingState.currentState

  public setPopoverOpenState = (popoverOpenState: boolean) => {
    this.popoverOpenState = popoverOpenState
  }

  @observable
  public displayArchivedTaskTypesPresenter: ButtonPresenter = new ButtonPresenter(
    this.language.get('common:headingSection:moreOptions:showArchivedButton'),
    () => {
      this.reloadClicked = !this.reloadClicked
      this.filtersRepository.toggleFilterValue(this.archivedFilter, 'true')
      this.router.refreshFilteredRoute()
      this.tableScrollerService.doScroll()
    }
  )
    .withIconName('trash-o')
    .withIconPosition('left')
    .withDisplayStyle('popover')

  @observable
  public displayUnArchivedTaskTypesPresenter: ButtonPresenter = new ButtonPresenter(
    this.language.get('common:headingSection:moreOptions:showUnArchivedButton'),
    () => {
      this.reloadClicked = !this.reloadClicked
      this.filtersRepository.toggleFilterValue(this.archivedFilter, '')
      this.router.refreshFilteredRoute()
      this.tableScrollerService.doScroll()
    }
  )
    .withIconName('undo')
    .withIconPosition('left')
    .withDisplayStyle('popover')

  @observable
  public reloadDataButtonPresenter: ButtonPresenter = new ButtonPresenter(
    this.language.get('common:headingSection:moreOptions:reloadButton'),
    async () => {
      this.reloadClicked = !this.reloadClicked
      this.router.changePageWithoutCheck(
        this.currentRoutingState.routeId,
        this.currentRoutingState.params,
        this.currentRoutingState.query
      )
      this.taskTypesViewPresenter.loadTaskTypes()
      this.tableScrollerService.doScroll()
    }
  )
    .withIconName('cycle')
    .withIconPosition('left')
    .withDisplayStyle('popover')

  @computed
  public get showArchivedTaskTypes(): boolean {
    return this.taskTypesViewPresenter.showArchivedTaskTypes
  }

  @computed
  public get archivedFilter(): IGenericFilter {
    return this.taskTypesViewPresenter.filterVM.find(filter => filter.key.reference === 'archived')
  }
}
