import { useEffect, useState } from 'react'
import { DragEndEvent, UniqueIdentifier } from '@dnd-kit/core'

import { Checkbox, Loader, Modal, SortableList } from '@cutover/react-ui'
import { SelectedApp } from './apps-types'
import { useLanguage } from 'main/services/hooks'

type AppsManagementModalProps = {
  isOpen: boolean
  onClose: () => void
  onClickConfirm: (availableApps: SelectedApp[] | undefined) => void
  availableApps?: SelectedApp[] | undefined
}

export const AppsManagementModal = ({ isOpen, onClose, onClickConfirm, availableApps }: AppsManagementModalProps) => {
  const [availableAppsState, setAvailableAppsState] = useState<SelectedApp[] | undefined>(undefined)
  const [isLoading, setIsLoading] = useState(availableApps === undefined)
  const { t } = useLanguage('apps')

  useEffect(() => {
    setAvailableAppsState(availableApps)

    if (availableApps) {
      setIsLoading(false)
    }
  }, [availableApps, isOpen])

  const handleAppSave = () => {
    onClickConfirm(availableAppsState)
    onClose()
  }

  const handleAppSelection = (app: SelectedApp) => {
    setAvailableAppsState(prevState => prevState?.map(a => (a.appId === app.appId ? { ...a, visible: !a.visible } : a)))
  }

  const handleAppOrder = (e: DragEndEvent, order: UniqueIdentifier[]) => {
    const itemsMap: { [key: string]: SelectedApp } | undefined = availableAppsState?.reduce((acc, app) => {
      acc[app.appId] = app
      return acc
    }, {} as { [key: string]: SelectedApp })

    const reorderedApps = order.map((id, index) => {
      const app = itemsMap?.[id]
      return { ...app, order: index + 1 }
    }) as SelectedApp[]

    setAvailableAppsState(reorderedApps)
  }

  // Note: This could make more sense as a sortable prop on a checkbox group in this case
  // To revisit once we decide how to make list items multiselectable
  return (
    <Modal open={isOpen} onClose={onClose} onClickConfirm={handleAppSave} title={t('manageApps')}>
      <p>{t('manageAppsIntroduction')}</p>
      {isLoading && <Loader />}
      {availableAppsState && availableAppsState.length === 0 ? <p>{t('manageAppsEmpty')}</p> : null}
      <SortableList
        onChange={handleAppOrder}
        listItems={availableAppsState?.map(app => {
          return {
            id: app.appId,
            size: 'small',
            title: app.name,
            onClick: () => handleAppSelection(app),
            prominence: app.visible ? 'high' : undefined,
            startComponent: (
              <Checkbox
                key={app.appId}
                id={`app-${app.appId}`}
                a11yTitle={app.name}
                checked={app.visible}
                css="margin-top: -4px !important;"
              />
            )
          }
        })}
      />
    </Modal>
  )
}
