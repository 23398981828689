import { memo } from 'react'

import { useLanguage } from 'main/services/hooks'
import { ToggleControl } from '../shared/toggle-control'
import { FilterAccordionPanel } from '../shared/filter-accordion-panel'
import {
  useFixedEndFilter,
  useFixedStartFilter,
  useHasCommentsFilter,
  useHasErrorsFilter,
  useHasPredecessorsFilter,
  useHasSuccessorsFilter,
  useLateFilter,
  useNowDate,
  useOverRunningFilter,
  useStartNotificationFilter
} from 'main/recoil/data-access'

export const OtherGroup = memo(() => {
  const { t } = useLanguage('common', { keyPrefix: 'filter' })
  const [startNotification] = useStartNotificationFilter()
  const [fixedStart] = useFixedStartFilter()
  const [fixedEnd] = useFixedEndFilter()
  const [hasComments] = useHasCommentsFilter()
  const [late] = useLateFilter()
  const [overRunning] = useOverRunningFilter()
  const [hasPredecessors] = useHasPredecessorsFilter()
  const [hasSuccessors] = useHasSuccessorsFilter()
  const [hasErrors] = useHasErrorsFilter()
  const { setNowDate } = useNowDate()

  const isApplied = [
    startNotification,
    fixedStart,
    fixedEnd,
    hasComments,
    late,
    overRunning,
    hasPredecessors,
    hasSuccessors,
    hasErrors
  ].some(filter => filter !== undefined)

  const toggleLateFiter = () => {
    setNowDate()
  }

  return (
    <FilterAccordionPanel label={t('other')} applied={isApplied}>
      <StartNotificationToggle />
      <FixedStartToggle />
      <FixedEndToggle />
      <HasCommentsToggle />
      <LateToggle onClick={toggleLateFiter} />
      <OverRunningToggle />
      <HasPredecessorsToggle />
      <HasSuccessorsToggle />
      <HasErrorsToggle />
    </FilterAccordionPanel>
  )
})

const StartNotificationToggle = () => {
  const [value, setValue] = useStartNotificationFilter()

  return <ToggleControl filterKey="sn" value={value} setValue={setValue} />
}

const FixedStartToggle = () => {
  const [value, setValue] = useFixedStartFilter()

  return <ToggleControl filterKey="fs" value={value} setValue={setValue} />
}
const FixedEndToggle = () => {
  const [value, setValue] = useFixedEndFilter()

  return <ToggleControl filterKey="fe" value={value} setValue={setValue} />
}

const HasCommentsToggle = () => {
  const [value, setValue] = useHasCommentsFilter()

  return <ToggleControl filterKey="c" value={value} setValue={setValue} />
}

const LateToggle = ({ onClick }: { onClick: () => void }) => {
  const [value, setValue] = useLateFilter()

  return <ToggleControl filterKey="l" value={value} setValue={setValue} onClick={onClick} />
}

const OverRunningToggle = () => {
  const [value, setValue] = useOverRunningFilter()

  return <ToggleControl filterKey="or" value={value} setValue={setValue} />
}

const HasPredecessorsToggle = () => {
  const [value, setValue] = useHasPredecessorsFilter()

  return <ToggleControl filterKey="hp" value={value} setValue={setValue} />
}

const HasSuccessorsToggle = () => {
  const [value, setValue] = useHasSuccessorsFilter()

  return <ToggleControl filterKey="hs" value={value} setValue={setValue} />
}

const HasErrorsToggle = () => {
  const [value, setValue] = useHasErrorsFilter()

  return <ToggleControl filterKey="he" value={value} setValue={setValue} />
}
