// @ts-nocheck
import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Box } from '@cutover/react-ui'
import { ErrorAlert, TextInput } from 'Components/Molecules'
import { FormInputGroup } from 'Shared/Components/Templates'
import { RadioButtons } from 'Shared/Components/Atoms'
import { AccountFormPresenter } from './AccountFormPresenter'

interface IAccountForm {
  presenter: AccountFormPresenter
  formType?: 'add' | 'edit'
}

export const AccountFormComponent = observer<IAccountForm>(({ presenter, formType }) => {
  const {
    errorAlertPresenter,
    submitted,
    formIsValid,
    titlePresenter,
    shortNamePresenter,
    descriptionPresenter,
    accountTypePresenter,
    roleTypesPresenter,
    formViewModel,
    readOnly,
    disableForm,
    enableForm
  } = presenter

  useEffect(() => {
    if (formViewModel.roleTypes) {
      roleTypesPresenter.withData(formViewModel.roleTypes)
    }
  }, [formViewModel.roleTypes])

  useEffect(() => {
    if (readOnly) {
      disableForm()
    } else {
      enableForm()
    }
  }, [readOnly])

  return (
    <form>
      {submitted && !formIsValid && <ErrorAlert presenter={errorAlertPresenter} formAlert />}
      <TextInput presenter={titlePresenter} parentIsFocused={formType === 'add'} formTopInputElement />
      <TextInput presenter={shortNamePresenter} />
      <TextInput presenter={descriptionPresenter} />
      <Box margin={{ top: '16px' }}>
        <RadioButtons presenter={accountTypePresenter} />
      </Box>
      <FormInputGroup presenter={roleTypesPresenter} />
    </form>
  )
})
