import { selectorFamily } from 'recoil'
import { fromUnixTime, isFuture } from 'date-fns'

import { taskListTaskState } from './task-list'
import { currentUserState } from 'main/recoil/current-user'
import { runbookPermissionsState, runbookState } from '../runbook/runbook'
import { runbookVersionMetaState } from '../runbook-version/runbook-version'
import { runState } from '../runbook-version/run'
import { CurrentUser } from 'main/services/queries/use-get-validate-token'
import { runbookTypeState } from '../account/runbook-types'

export type TaskProgressionState = {
  stage: 'startable' | 'finishable'
  override?: { type: 'fixed-start' | 'users-assigned'; optional: boolean }
}

export const taskProgressionState = selectorFamily<TaskProgressionState | undefined, number>({
  key: 'tasks:progression',
  get:
    id =>
    ({ get }) => {
      const currentUser = get(currentUserState) as CurrentUser
      const runbook = get(runbookState)
      const runbookPermissions = get(runbookPermissionsState)
      const { runbook_teams } = get(runbookVersionMetaState)
      const runbookType = get(runbookTypeState(runbook.runbook_type_id))
      const run = get(runState)
      const task = get(taskListTaskState(id))

      if (
        task.stage === 'startable' &&
        !!task.start_fixed &&
        isFuture(fromUnixTime(task.start_fixed)) &&
        run?.run_type !== 'rehearsal'
      )
        return {
          stage: 'startable',
          override: { type: 'fixed-start', optional: false }
        }

      const assignedTeams = runbook_teams.filter(t => task.runbook_team_ids.includes(t.id))
      const usersAssignedToTask = [...new Set([...task.user_ids, ...assignedTeams.flatMap(t => t.user_ids)])]
      const currentUserAssigned = usersAssignedToTask.includes(currentUser.id)
      const hasUpdatePermissions = !!runbookPermissions.update.length

      if ((runbook.stage !== 'active' && !runbookType.dynamic) || ['complete', 'default'].includes(task.stage))
        return undefined
      const canFinishTask = (() => {
        if (task.stage !== 'in-progress') return false
        if (task.linked_resource?.id) return false
        if (hasUpdatePermissions) return true
        if (!currentUserAssigned) return false
        if (task.end_requirements === 'any_can_end') return true
        if (task.end_requirements === 'all_must_end' && !task.ended_user_ids.includes(currentUser.id)) return true
        if (task.end_requirements === 'same_must_end' && task.started_user_ids.includes(currentUser.id)) return true
        return false
      })()
      const canStartTask = (() => {
        if (task.stage !== 'startable') return false
        if (hasUpdatePermissions || (!hasUpdatePermissions && run?.run_type === 'rehearsal')) return true
        if (!currentUserAssigned) return false
        if (task.start_requirements === 'any_can_start') return true
        if (!task.started_user_ids.includes(currentUser.id)) return true
        return false
      })()

      const anyUserCanAction =
        (task.stage === 'startable' && task.start_requirements === 'any_can_start') ||
        (task.stage === 'in-progress' && task.end_requirements === 'any_can_end')

      const currentUserHasActioned =
        (task.stage === 'startable' && task.started_user_ids.includes(currentUser.id)) ||
        (task.stage === 'in-progress' && task.ended_user_ids.includes(currentUser.id))

      if (hasUpdatePermissions) {
        if (
          usersAssignedToTask.length > 0 &&
          (!currentUserAssigned ||
            (currentUserAssigned && currentUserHasActioned) ||
            (currentUserAssigned &&
              task.stage === 'in-progress' &&
              task.end_requirements === 'same_must_end' &&
              !task.started_user_ids.includes(currentUser.id)))
        )
          return {
            stage: canStartTask ? 'startable' : 'finishable',
            override: { type: 'users-assigned', optional: false }
          }

        if (
          currentUserAssigned &&
          usersAssignedToTask.length > 0 &&
          !anyUserCanAction &&
          task.end_requirements !== 'same_must_end'
        )
          return {
            stage: canStartTask ? 'startable' : 'finishable',
            override: { type: 'users-assigned', optional: true }
          }
      }

      if (canFinishTask) return { stage: 'finishable' }
      if (canStartTask) return { stage: 'startable' }
      return undefined
    }
})
