import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const TaskTypes = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="TaskTypes" {...props}>
    <path d="M14 18.033V7h15v8.66l.67-1.16A4.938 4.938 0 0 1 32 12.41V6a2 2 0 0 0-2-2H13a2 2 0 0 0-2 2v12.54a14.96 14.96 0 0 1 3-.507ZM32.268 16c.77-1.333 2.694-1.333 3.464 0l10.392 18c.77 1.333-.192 3-1.732 3H29.461a14.96 14.96 0 0 0 .506-3H42.66L34 19l-4.987 8.638a14.941 14.941 0 0 0-1.652-3.138l4.907-8.5Zm-12.465 6a12.027 12.027 0 0 1 7.081 9.325A12.11 12.11 0 0 1 26.317 37C24.67 41.66 20.225 45 15 45 8.373 45 3 39.627 3 33c0-5.225 3.34-9.67 8-11.317A11.94 11.94 0 0 1 15 21c1.708 0 3.333.357 4.803 1Zm-8.016 2.59A9.003 9.003 0 0 0 6 33a9 9 0 1 0 13.127-8A8.95 8.95 0 0 0 15 24a8.98 8.98 0 0 0-3.213.59Z" fill="#000" />
  </StyledIcon>
))

TaskTypes.displayName = 'TaskTypes'

export { TaskTypes }
