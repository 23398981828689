/* eslint-disable react-hooks/rules-of-hooks */
import { useMemo, useRef } from 'react'
import { useRecoilCallback, useRecoilValue } from 'recoil'
import { produce } from 'immer'
import { filter, find } from 'lodash'

import { defaultSavedFilterState, savedFilterState, savedFilterStateLookup } from 'main/recoil/runbook'
import {
  RunbookFilterCreateResponse,
  RunbookFilterDestroyResponse,
  RunbookFilterSetAsDefaultResponse,
  RunbookFilterToggleGlobalResponse,
  SavedFilter
} from 'main/services/api/data-providers/runbook-types'
import { SavedFilterModelType } from 'main/data-access/models'
import { useActiveRunbookId, useActiveRunbookIdCallback } from './active-runbook'

/* -------------------------------------------------------------------------- */
/*                                     Get                                    */
/* -------------------------------------------------------------------------- */

export const useGetSavedFilter: SavedFilterModelType['useGet'] = (identifier: number) => {
  const lookup = useRecoilValue(savedFilterStateLookup)
  return lookup[identifier]
}

export const useGetSavedFilterCallback: SavedFilterModelType['useGetCallback'] = () =>
  useRecoilCallback(({ snapshot }) => async (identifier: number) => {
    const lookup = await snapshot.getPromise(savedFilterStateLookup)
    return lookup[identifier]
  })

/* -------------------------------------------------------------------------- */
/*                                     Get By                                 */
/* -------------------------------------------------------------------------- */

export const useGetBySavedFilter: SavedFilterModelType['useGetBy'] = getBy => {
  if (getBy.default) {
    return useRecoilValue(defaultSavedFilterState) as SavedFilter
  }
  return {} as SavedFilter
}

export const useGetBySavedFilterCallback: SavedFilterModelType['useGetByCallback'] = () =>
  useRecoilCallback(({ snapshot }) => async getBy => {
    if (getBy.default) {
      const defaultFilter = await snapshot.getPromise(defaultSavedFilterState)
      return defaultFilter as SavedFilter
    }
    return {} as SavedFilter
  })

/* -------------------------------------------------------------------------- */
/*                                  Get All                                   */
/* -------------------------------------------------------------------------- */

export const useGetAllSavedFilters: SavedFilterModelType['useGetAll'] = () => {
  return sortFiltersDefaultFirst(useRecoilValue(savedFilterState))
}

export const useGetAllSavedFiltersCallback: SavedFilterModelType['useGetAllCallback'] = () =>
  useRecoilCallback(({ snapshot }) => async () => {
    const filters = await snapshot.getPromise(savedFilterState)
    return sortFiltersDefaultFirst(filters)
  })

/* -------------------------------------------------------------------------- */
/*                                     Lookup                                 */
/* -------------------------------------------------------------------------- */

export const useGetSavedFilterLookup: SavedFilterModelType['useGetLookup'] = () => {
  return useRecoilValue(savedFilterStateLookup)
}

export const useGetSavedFilterLookupCallback: SavedFilterModelType['useGetLookupCallback'] = () => {
  return useRecoilCallback(({ snapshot }) => async () => {
    const savedFiltersLookup = await snapshot.getPromise(savedFilterStateLookup)
    return savedFiltersLookup
  })
}

/* -------------------------------------------------------------------------- */
/*                                 Get all by                                 */
/* -------------------------------------------------------------------------- */

export const useGetAllSavedFiltersBy: SavedFilterModelType['useGetAllBy'] = getBy => {
  const stabilizedGetBy = useRef(getBy).current
  const filters = useGetAllSavedFilters()
  return filter(filters, stabilizedGetBy) as SavedFilter[]
}

export const useGetAllSavedFiltersByCallback: SavedFilterModelType['useGetAllByCallback'] = () => {
  const getSavedFilters = useGetAllSavedFiltersCallback()
  return useRecoilCallback(() => async getBy => {
    const filters = await getSavedFilters()
    return filter(filters, getBy) as SavedFilter[]
  })
}

/* -------------------------------------------------------------------------- */
/*                                     Action                                 */
/* -------------------------------------------------------------------------- */

// @ts-ignore
export const useOnActionSavedFilter: SavedFilterModelType['useOnAction'] = action => {
  // Stabilize options so order of hooks never changes
  const stabilizedAction = useRef(action).current
  switch (stabilizedAction) {
    case 'create':
      return useProcessFilterCreateResponse()
    case 'destroy':
      return useProcessFilterDestroyResponse()
    case 'toggle_global':
      return useProcessFilterToggleGlobalResponse()
    case 'set_as_default':
      return useProcessFilterSetAsDefaultResponse()
    default:
      return handleNoMatchInSwitch(stabilizedAction, 'useOnAction')
  }
}

/* -------------------------------------------------------------------------- */
/*                               get PIR Filter                               */
/* -------------------------------------------------------------------------- */

export const useGetSavedPirFilter: SavedFilterModelType['useGetSavedPirFilter'] = () => {
  const runbookId = useActiveRunbookId()
  const filters = useGetAllSavedFilters()

  return useMemo(
    () =>
      find(filters, {
        resource_id: runbookId,
        resource_type: 'Runbook',
        name: `Runbook ${runbookId} PIR`
      }),
    [runbookId, filters]
  )
}

export const useGetSavedPirFilterCallback: SavedFilterModelType['useGetSavedPirFilterCallback'] = () => {
  const getFilters = useGetAllSavedFiltersCallback()
  const getRunbookId = useActiveRunbookIdCallback()

  return useRecoilCallback(() => async () => {
    const rbId = await getRunbookId()
    const filters = await getFilters()
    return find(filters, {
      resource_id: rbId,
      resource_type: 'Runbook',
      name: `Runbook ${rbId} PIR`
    })
  })
}

/* -------------------------------- Internal -------------------------------- */

const sortFiltersDefaultFirst = (f: SavedFilter[]) =>
  produce(f, draft => draft.sort((a, b) => (a.default && !b.default ? -1 : 0)))

const useProcessFilterCreateResponse = () =>
  useRecoilCallback(({ set, snapshot }) => async (response: RunbookFilterCreateResponse) => {
    const existingFilters = await snapshot.getPromise(savedFilterState)

    const updatedSavedFilters = produce(existingFilters, draft => {
      const existingFilter = draft.findIndex(f => f.id === response.filter.id)
      if (existingFilter >= 0) {
        draft[existingFilter] = response.filter as unknown as SavedFilter
      } else {
        draft.push(response.filter as unknown as SavedFilter)
      }
      return draft
    })

    set(savedFilterState, updatedSavedFilters)
  })

const useProcessFilterDestroyResponse = () =>
  useRecoilCallback(({ set, snapshot }) => async (response: RunbookFilterDestroyResponse) => {
    const existingFilters = await snapshot.getPromise(savedFilterState)
    const updatedSavedFilters = existingFilters.filter(f => f.id !== response.filter.id)

    set(savedFilterState, updatedSavedFilters)
  })

const useProcessFilterSetAsDefaultResponse = () =>
  useRecoilCallback(({ set, snapshot }) => async (response: RunbookFilterSetAsDefaultResponse) => {
    const existingFilters = await snapshot.getPromise(savedFilterState)
    const updatedSavedFilters = produce(existingFilters, draft => {
      const existingFilterIndex = draft.findIndex(f => f.id === response.filter.id)
      const existingFilter = draft[existingFilterIndex]

      if (existingFilter && existingFilter.default) {
        draft[existingFilterIndex].default = false
      } else if (existingFilter) {
        draft.forEach(f => (f.default = false))
        draft[existingFilterIndex].default = true
        draft[existingFilterIndex].global = true
      }
    })

    set(savedFilterState, updatedSavedFilters)
  })

const useProcessFilterToggleGlobalResponse = () =>
  useRecoilCallback(({ set, snapshot }) => async (response: RunbookFilterToggleGlobalResponse) => {
    const existingFilters = await snapshot.getPromise(savedFilterState)
    const updatedSavedFilters = existingFilters.map(f => ({
      ...f,
      global: f.id === response.filter.id ? !f.global : f.global
    }))

    set(savedFilterState, updatedSavedFilters)
  })

const handleNoMatchInSwitch = (type: string, fn: string): never => {
  throw new Error(`${type} in ${fn} not yet handled.`)
}
