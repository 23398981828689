// @ts-nocheck
import { computed, observable, Lambda, action } from 'mobx'
import { container } from 'app/Config/IOC'
import { TaskTypesViewPresenter } from 'app/Settings/TaskTypes/TaskTypesPage/TaskTypesViewPresenter'
import { GenericListPresenter } from 'Components/Templates'
import { TablePresenter, ITableColumnTypes, ISortParams } from 'Components/Organisms'
import { ITaskTypesListItem } from 'Shared/Entities/Types/TaskType/ITaskTypesListItem'
import { Types } from 'Gateways/Service/Types'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'

export class TaskTypeListPresenter implements GenericListPresenter {
  private taskTypesViewPresenter: TaskTypesViewPresenter = container.get(TaskTypesViewPresenter)

  private language: ILanguageService = container.get(Types.ILanguageService)

  @observable
  public tableName: string = 'task-types-list'

  public context: string = 'task type'

  @observable
  public disposers: Lambda[] = []

  public columns: ITableColumnTypes[] = [
    {
      accessor: 'name',
      name: this.language.get('taskTypes:list:tableHeadings:name'),
      width: 180,
      dataType: 'string',
      sortable: true
    },
    {
      accessor: 'accountName',
      name: this.language.get('taskTypes:list:tableHeadings:accountName'),
      width: 180,
      dataType: 'string',
      sortable: true
    },
    {
      accessor: 'default',
      name: this.language.get('taskTypes:list:tableHeadings:default'),
      width: 180,
      dataType: 'boolean',
      sortable: true
    }
  ]

  @computed
  public get selectedId(): number {
    return this.taskTypesViewPresenter.taskTypeVM ? this.taskTypesViewPresenter.taskTypeVM.id : null
  }

  @computed
  public get viewModel(): ITaskTypesListItem[] {
    return this.taskTypesViewPresenter.listVM
  }

  @computed
  public get loading(): boolean {
    return this.taskTypesViewPresenter.repositoryLoading
  }

  public clearAllFilter = () => {
    this.taskTypesViewPresenter.clearFilters()
  }

  public loadSort = (sortParams: ISortParams) => {
    this.taskTypesViewPresenter.sortParams = sortParams
    this.taskTypesViewPresenter.loadTaskTypes()
  }

  @computed
  public get sortParams(): ISortParams {
    return this.taskTypesViewPresenter.sortParams
  }

  @action
  public loadRowCallback = (taskTypeId: number) => {
    if (this.taskTypesViewPresenter.taskTypeVM && this.taskTypesViewPresenter.taskTypeVM.id === taskTypeId) {
      this.taskTypesViewPresenter.pageVM.editPanelOpen = false
    } else {
      this.taskTypesViewPresenter.loadTaskType(taskTypeId)
    }
  }

  public clearAllFilters = () => {
    this.taskTypesViewPresenter.clearFilters()
  }

  @computed
  public get totalCount() {
    return this.taskTypesViewPresenter.taskTypesFilteredTotal
  }

  @computed
  public get reloadList() {
    return this.taskTypesViewPresenter.reloadList
  }

  @observable
  public tablePresenter = new TablePresenter(this.viewModel, this.columns, this.tableName, this.viewModel.length)
    .withLoadSortCallback(this.loadSort)
    .withLoadRowCallback(this.loadRowCallback)
    .withVariableWidth()

  @computed
  public get filtersCount(): number {
    return this.taskTypesViewPresenter.filtersCount
  }
}
