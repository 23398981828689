import { useCallback } from 'react'
import { useRecoilCallback } from 'recoil'
import { produce } from 'immer'

import {
  RunbookCommentCreateResponse,
  RunbookCommentsGetResponse,
  RunbookCommentToggleFeaturedResponse,
  RunbookResponse
} from 'main/services/api/data-providers/runbook-types'
import { runbookCommentsResponseState_INTERNAL } from '../../runbook/models'
import { updateAddNewComments } from './shared-updates'
import { useCommentNotifications } from '../data-access-hooks__TEMPORARY'

export const useProcessRunbookCommentsResponse = () => {
  const processRunbookCommentCreateResponse = useProcessRunbookCommentCreateResponse()
  const processRunbookCommentFeaturedResponse = useProcessRunbookCommentFeaturedResponse()
  const { commentCreateNotification } = useCommentNotifications()

  return useCallback(
    (response: RunbookResponse) => {
      switch (response.meta.headers.request_method) {
        case 'create':
          commentCreateNotification(response as RunbookCommentCreateResponse)
          processRunbookCommentCreateResponse(response as RunbookCommentCreateResponse)
          break
        case 'toggle_featured':
          processRunbookCommentFeaturedResponse(response as RunbookCommentToggleFeaturedResponse)
          break
        default:
          return
      }
    },
    [processRunbookCommentCreateResponse, processRunbookCommentFeaturedResponse]
  )
}

export const useProcessRunbookCommentCreateResponse = () => {
  return useRecoilCallback(callbackInterface => (response: RunbookCommentCreateResponse) => {
    updateAddNewComments(callbackInterface)({
      comments: [response.comment],
      requesterId: response.meta.headers.request_user_id
    })
  })
}

export const useProcessRunbookCommentFeaturedResponse = () => {
  return useRecoilCallback(({ set }) => async (response: RunbookCommentToggleFeaturedResponse) => {
    set(runbookCommentsResponseState_INTERNAL, previousState =>
      produce(previousState, draft => {
        // If we dont' have comments loaded yet, they are not looking at a UI that requires that data so we don't
        // need to make and internal updates. When they open the comments panel or visit the dashboard, it will populate
        // the runbook comments store.
        if (draft) {
          const index = draft.comments.findIndex(comment => comment.id === response.comment.id)

          if (index !== -1) {
            draft.comments[index] = response.comment
          }
        }
      })
    )
  })
}

// Used in a single function which will hit this code only if the comments haven't already been loaded.
export const useProcessRunbookCommentsGetResponse = () => {
  return useRecoilCallback(({ set }) => async (response: RunbookCommentsGetResponse) => {
    set(runbookCommentsResponseState_INTERNAL, response)
  })
}
