import { Simplify } from 'type-fest'

import {
  useGetComment,
  useGetCommentCallback,
  useGetLoadableComments,
  useGetLoadableCommentsBy,
  useGetLoadableCommentsByCallback,
  useGetLoadableCommentsCallback,
  useGetRunbookComments,
  useGetRunbookCommentsCallback,
  useGetRunbookCommentsLookup,
  useGetRunbookCommentsLookupCallback,
  useGetRunbookCommentsPermission,
  useGetTaskComments,
  useGetTaskCommentsCallback,
  useReloadRunbookComments,
  useReloadRunbookCommentsSync
} from '../hooks'
import { RunbookCommentsPermissionsResponse } from 'main/services/api/data-providers/runbook-types'
import { RunbookComment } from 'main/services/api/data-providers/runbook-types/runbook-shared-types'
import {
  CollectionModelInterfaceBase,
  CollectionModelInterfaceWithCollectionAccessBy as WithCollectionAccessBy,
  CollectionModelInterfaceWithMultiLoadableAccess as WithMultiLoadableAccess,
  CollectionModelInterfaceWithMultiLoadableAccessBy as WithMultiLoadableAccessBy,
  CollectionModelInterfaceWithPermissions as WithPermissions,
  CollectionModelInterfaceWithReload as WithReload
} from './types'

type CommentCollectionAccessScope = 'featured'
type CommentCollectionAccessKeys = { taskInternalId: number }

export type CommentModelType = Simplify<
  CollectionModelInterfaceBase<RunbookComment, CommentCollectionAccessScope> &
    WithReload &
    WithPermissions<RunbookCommentsPermissionsResponse> &
    WithCollectionAccessBy<RunbookComment, CommentCollectionAccessKeys, CommentCollectionAccessScope> &
    WithMultiLoadableAccess<RunbookComment, CommentCollectionAccessScope> &
    WithMultiLoadableAccessBy<RunbookComment, CommentCollectionAccessKeys, CommentCollectionAccessScope>
>

export const CommentModel: CommentModelType = {
  useCan: useGetRunbookCommentsPermission,
  useGet: useGetComment,
  useGetCallback: useGetCommentCallback,
  useGetAll: useGetRunbookComments,
  useGetAllCallback: useGetRunbookCommentsCallback,
  useGetAllBy: useGetTaskComments,
  useGetAllByCallback: useGetTaskCommentsCallback,
  useGetAllLoadable: useGetLoadableComments,
  useGetAllLoadableCallback: useGetLoadableCommentsCallback,
  useGetAllLoadableBy: useGetLoadableCommentsBy,
  useGetAllLoadableByCallback: useGetLoadableCommentsByCallback,
  useGetLookup: useGetRunbookCommentsLookup,
  useGetLookupCallback: useGetRunbookCommentsLookupCallback,
  useReload: useReloadRunbookComments,
  useReloadSync: useReloadRunbookCommentsSync
}
