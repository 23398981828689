// @ts-nocheck
import styled from 'styled-components'
import { px2rem, colors } from 'app/Styles/Variables'
import { Checkbox, TimeControl } from 'Shared/Components/Molecules'
import { Icon } from 'Components/Atoms'

export const CheckboxStyled = styled(Checkbox)`
  margin-left: 0;
  margin-top: ${px2rem(10)};
`

export const OptionWrapper = styled.div`
  margin-left: ${px2rem(36)};
`
export const OptionHeader = styled.h3`
  font-weight: 500;
  font-size: ${px2rem(18)};
  line-height: 24px;
  color: ${colors.dark};
  margin-bottom: ${px2rem(10)};
`

export const OptionSubheader = styled.p`
  color: ${colors.dark};
  font-size: ${px2rem(15)};
  margin-top: ${px2rem(10)};
  margin-bottom: ${px2rem(10)};
`

interface IOptionDataWrapperProps {
  isSelected: boolean
}

export const OptionDataWrapper = styled.div`
  transition: all ease-out 300ms;
  transform-origin: top;
  overflow: hidden;
  padding: ${px2rem(10)} 0;
  margin-left: ${px2rem(64)};
  ${(props: IOptionDataWrapperProps) =>
    props.isSelected
      ? `
        opacity: 1;
        max-height: 100px;
      `
      : `
       opacity: 0;
       max-height: 0;
       `};
`

export const StartTimeWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.primaryGrey};
  border-bottom: 1px dashed ${colors.primaryGreyHoverBackgroundColor};
  padding-bottom: ${px2rem(3)};
  margin-top: ${px2rem(6)};
`
export const TimeControlStyled = styled(TimeControl)`
  margin-left: auto;
`

export const TimeIconStyled = styled(Icon)`
  font-size: 20px;
  width: ${px2rem(20)};
  margin-right: ${px2rem(8)};
  margin-bottom: ${px2rem(2)};
`

export interface IOptionLabels {
  plannedOptionHeader: string
  plannedOptionHeaderAlt?: string
  plannedOptionSubHeader: string
  shiftTimeOption: string
  startDateOptionHeader: string
  startDateOptionHeaderAlt?: string
  startDateOptionSubHeader: string
  defaultDateTime?: string
}
