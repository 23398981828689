import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Abandoned = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Abandoned" {...props}>
    <path d="M39.556 14.1 33.9 8.445l-9.9 9.9-9.9-9.9L8.445 14.1l9.9 9.9-9.9 9.899 5.657 5.657 9.9-9.9 9.899 9.9 5.657-5.657-9.9-9.9 9.9-9.9Z" fill="#000" />
  </StyledIcon>
))

Abandoned.displayName = 'Abandoned'

export { Abandoned }
