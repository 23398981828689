import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Nodemap = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Nodemap" {...props}>
    <path d="M24.17 12.048a6 6 0 1 0-2.121 2.121l2.708 2.71 2.122-2.122-2.71-2.71ZM19 12a3 3 0 1 1 0-6 3 3 0 0 1 0 6Zm20 3a6 6 0 1 0-5.17-2.952l-9.133 9.134a7.5 7.5 0 0 0-2.197 5.303v7.704a6 6 0 1 0 3 0v-7.704a4.5 4.5 0 0 1 1.318-3.182l9.134-9.134A5.97 5.97 0 0 0 39 15Zm0-3a3 3 0 1 1 0-6 3 3 0 0 1 0 6ZM27 40a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" fill="#000" /><path d="M45 29a6 6 0 1 1-11.17-3.048l-2.709-2.71 2.122-2.12 2.71 2.709A6 6 0 0 1 45 29Zm-3 0a3 3 0 1 0-6 0 3 3 0 0 0 6 0ZM9 29a6 6 0 1 0 0-12 6 6 0 0 0 0 12Zm0-3a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z" fill="#000" />
  </StyledIcon>
))

Nodemap.displayName = 'Nodemap'

export { Nodemap }
