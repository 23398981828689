import { ChangeEvent, Ref, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { format } from 'date-fns'
import { useMeasure, usePrevious } from 'react-use'
import { useMergeRefs } from 'use-callback-ref'

import {
  Avatar,
  Box,
  Button,
  FavoriteStar,
  IconButton,
  LoadingPanel,
  Pill,
  SpinnerIcon,
  Text,
  TextArea,
  themeColor,
  Tooltip,
  useLayoutUpdateEffect,
  useNotify
} from '@cutover/react-ui'
import { FormEditPanel } from 'main/components/shared/form'
import { toggleCommentFeatured, useRunbookCommentCreate } from 'main/services/queries/use-runbook-comments'
import { RunbookComment } from 'main/services/api/data-providers/runbook-types/runbook-shared-types'
import {
  useProcessRunbookCommentCreateResponse,
  useProcessRunbookCommentFeaturedResponse
} from 'main/recoil/data-access/updaters__TEMPORARY/runbook-comments-operations'
import { useLanguage } from 'main/services/hooks'
import { useSetActiveRightPanelState } from 'main/components/layout/right-panel'
import { ActiveRunbookModel, ActiveRunbookVersionModel, CommentModel, TaskModel } from 'main/data-access'

type CommentsFormProps = {
  onClose: () => void
  taskId?: number
  taskInternalId?: number
}

export const CommentsForm = ({ onClose, taskId, taskInternalId }: CommentsFormProps) => {
  const { t } = useLanguage('runbook', { keyPrefix: 'commentsPanel' })
  const [newComment, setNewComment] = useState('')
  const [isSubmitting, setSubmitting] = useState(false)
  const runbookId = ActiveRunbookModel.useId()
  const runbookVersionId = ActiveRunbookVersionModel.useId()

  const tasksByInternalIdLookup = TaskModel.useGetLookup({ keyBy: 'internal_id' })
  // TODO: If the runbook is current check for permissions should come from the backend
  const { is_current: isCurrent } = ActiveRunbookVersionModel.useGet()
  const canCreateComment = CommentModel.useCan('create') && isCurrent
  const { openRightPanel } = useSetActiveRightPanelState()
  const processRunbookCommentCreateResponse = useProcessRunbookCommentCreateResponse()
  const isTaskComments = taskId !== undefined
  const { state: allCommentsLoadingState, contents: allCommentContents } = CommentModel.useGetAllLoadable()
  const { state: taskCommentsLoadingState, contents: taskCommentContents } = CommentModel.useGetAllLoadableBy({
    taskInternalId: taskInternalId ?? 0
  })

  const taskComments = isTaskComments && taskCommentsLoadingState === 'hasValue' ? taskCommentContents : []
  const runbookComments = !isTaskComments && allCommentsLoadingState === 'hasValue' ? allCommentContents : []
  const comments = isTaskComments ? taskComments : runbookComments
  const isLoading = isTaskComments ? taskCommentsLoadingState === 'loading' : allCommentsLoadingState === 'loading'

  const commentCount = comments?.length || 0
  const prevCommentCount = usePrevious(isLoading ? undefined : commentCount)
  const createComment = useRunbookCommentCreate(runbookId, runbookVersionId, taskId)
  const previousCommentsTaskId = usePrevious(taskId)
  const commentsContainerRef = useRef<Element>(null)
  const [heightRef, { height: commentsContainerHeight }] = useMeasure()
  const [isInitialized, setIsInitialized] = useState(false)
  const notify = useNotify()
  const scrollContainerRef = useMergeRefs([commentsContainerRef, heightRef as any]) as Ref<HTMLElement>

  const handleKeyDown = async (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.code === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      if (newComment.trim().length > 0 && canCreateComment && !isSubmitting) {
        setSubmitting(true)
        try {
          const response = await createComment.mutateAsync({ comment: newComment.trim() })
          if (response) {
            processRunbookCommentCreateResponse(response)
            setNewComment('')
            setSubmitting(false)
          }
        } catch (e) {
          setSubmitting(false)
          setNewComment('')
          notify.error(t('createError'))
        }
      }
    }
  }

  // For active comments by the user or coming in from websockets
  // TODO: wouldn't this be bad if the user is scrolled up and not looking at the end of the feed while new comments come in?
  useLayoutUpdateEffect(() => {
    if (!isLoading && !isChangedTaskOrRunbookComments && prevCommentCount && commentCount > prevCommentCount) {
      commentsContainerRef.current?.lastElementChild?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [commentCount, prevCommentCount])

  // For initial view of a set of comments, scrolled to the bottom
  useLayoutEffect(() => {
    if (isInitialized || isLoading) return

    if (commentsContainerHeight > 0) {
      commentsContainerRef.current?.lastElementChild?.scrollIntoView({ behavior: 'instant' as ScrollBehavior })
      setIsInitialized(true)
    } else if (comments?.length === 0) {
      setIsInitialized(true)
    }
  }, [isInitialized, commentsContainerHeight])

  // Reset what is needed to compute and scroll to the bottom when changing between different comments views without closing the panel
  const isChangedTaskOrRunbookComments = taskId !== previousCommentsTaskId
  useEffect(() => setIsInitialized(false), [isChangedTaskOrRunbookComments])

  return (
    <FormEditPanel
      title={t('title')}
      onClose={onClose}
      formElementWrapper={false}
      loading={isLoading}
      onBack={taskId ? () => openRightPanel({ type: 'task-edit', taskId }) : undefined}
      headerItems={
        taskId
          ? [
              <Pill
                color="text-light"
                label={t('taskPillLabel', { taskInternalId })}
                suffix={
                  <IconButton
                    label={t('clearTask')}
                    disableTooltip
                    data-testid={'task-comments-pill-clear'}
                    icon="close"
                    size="small"
                    onClick={() => openRightPanel({ type: 'runbook-comments' })}
                  />
                }
              />
            ]
          : undefined
      }
      // NOTE: Needs style followup, or at least some info about why there are a bunch of wacky styles injected in the components below. This is all
      // a bit of a hack to get the textarea input in the footer to mirror the spacing of the footer with the action button by default, due to
      // 1. the textarea needing to expand the footer's height
      // 2. the textarea at time of noting this is non-standard in its inner form component structure
      // 3. there is built in spacing to take care of the 95% of cases where we want to have it automatically standardize the panel layout
      //    but where in this case having spacing on the content comment items, the bottom of the body of the panel, plus the top of the
      //    footer all result in too much space between the last comment and the top of the input.
      footer={
        canCreateComment && (
          <Box
            css={`
              position: relative;
              margin-bottom: -8px;
              > div {
                padding-top: 0;
              }
            `}
          >
            <TextArea
              plain
              placeholder={taskId ? t('addTaskComment') : t('addRunbookComment')}
              alwaysShowPlaceholder
              onKeyDown={handleKeyDown}
              value={newComment}
              onInput={(event: ChangeEvent<HTMLTextAreaElement>) => {
                setNewComment(event.target.value)
              }}
              css={`
                background: ${themeColor('bg-1')} !important;
                border-radius: 20px;
                padding: 8px 16px 0 16px !important;
                min-height: 40px !important;
                margin-bottom: 8px !important;
              `}
            />
            {newComment.length > 0 && (
              <Text
                size="10px"
                color="text-light"
                css={`
                  text-align: right;
                  position: absolute;
                  bottom: -6px;
                  right: 0;
                `}
              >
                {t('commentInputHint')}
              </Text>
            )}
            {/* TODO: When the textare layout is addressed, Should be using the built in input end component layout here */}
            {isSubmitting && (
              <SpinnerIcon color="text-light" css="position: absolute; top: 8px; right: 9px; z-index: 1" />
            )}
          </Box>
        )
      }
    >
      {/* Adding the key here so that the panel unmounts and the scroll container is reset */}
      <Box ref={scrollContainerRef} key={taskId}>
        {/* this is here right now in addition to using the loading prop on the panel because the comments load and
         *then* need to compute the space needed and scroll to the end before rendering */}
        {!isInitialized && (
          <>
            {/* This spacer is so that the loading indicator is in the same position as the panel's loading indicator which is offset by the footer height */}
            <Box height="72px" />
            <LoadingPanel />
          </>
        )}
        {comments?.map(comment => {
          const { task_internal_id } = comment
          const taskId = task_internal_id ? tasksByInternalIdLookup[task_internal_id]?.id : undefined

          return <CommentItem key={comment.id} comment={comment} taskId={taskId} />
        })}
      </Box>
    </FormEditPanel>
  )
}

const CommentItem = ({ comment, taskId }: { comment: RunbookComment; taskId?: number }) => {
  const { id, content, created_at: created, featured, author, task } = comment
  const { name, first_name, last_name, id: authorId, color } = author
  const { t } = useLanguage('runbook', { keyPrefix: 'commentsPanel' })
  const runbookId = ActiveRunbookModel.useId()
  const runbookVersionId = ActiveRunbookVersionModel.useId()
  // TODO: If the runbook is current check for permissions should come from the backend
  const { is_current: isCurrent } = ActiveRunbookVersionModel.useGet()
  const canCreateComment = CommentModel.useCan('create') && isCurrent
  const processRunbookCommentFeaturedResponse = useProcessRunbookCommentFeaturedResponse()
  const { openRightPanel } = useSetActiveRightPanelState()

  const toggleFavorite = async () => {
    if (canCreateComment) {
      const response = await toggleCommentFeatured({ runbookId, runbookVersionId, commentId: id })
      if (response) {
        processRunbookCommentFeaturedResponse(response)
      }
    }
  }

  return (
    <Box
      data-testid="runbook-comment-item"
      css={`
        &:last-of-type {
          pre {
            margin-bottom: -16px;
          }
        }
      `}
    >
      <Box direction="row">
        <Avatar subject={{ id: authorId, first_name, last_name, color }} size="small" />
        <Box flex={{ grow: 1 }} css="padding-left: 6px; width: 150px;">
          <Text truncate="tip">{name}</Text>
        </Box>
        <Box direction="row" flex={false} align="center" css="position: relative; top: -5px;">
          <Box css="position: relative; top: -2px;">
            <FavoriteStar isFavorite={featured} toggleFavorite={toggleFavorite} />
          </Box>
          <Text color="text-light" size="small" css="padding-left: 4px;">
            {format(created * 1000, 'd MMM HH:mm')}
          </Text>
        </Box>
      </Box>
      <Text
        as="pre"
        css={`
          font-family: inherit;
          color: ${themeColor('text-light')};
          font-size: 13px;
          margin-top: -10px;
          padding-left: 30px;
        `}
      >
        {taskId && (
          <Tooltip content={task?.name} placement="top">
            <Button
              color="primary"
              size="small"
              onClick={() => openRightPanel({ type: 'task-edit', taskId })}
              onKeyUp={e => {
                if (e.key === 'Enter') {
                  openRightPanel({ type: 'task-edit', taskId })
                }
              }}
              plain
            >
              {t('taskLink', { taskInternalId: task?.internal_id })}
            </Button>
          </Tooltip>
        )}
        {content}
      </Text>
    </Box>
  )
}
