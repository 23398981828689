import { useQuery } from 'react-query'

import { apiClient } from 'main/services/api'
import { ApiError } from 'main/services/api/http-gateway-adapter'
import { EmptyRoleType } from './types'

type RoleTypeResponse = {
  role_types: EmptyRoleType[]
  meta: any
}

export function useRoleTypes({ contextTypeId, enabled }: { contextTypeId: number; enabled: boolean }) {
  return useQuery<RoleTypeResponse, ApiError>(
    ['role-types', contextTypeId],
    async () => {
      const { data } = await apiClient.get<RoleTypeResponse>({
        url: 'role_types',
        params: { context_type_id: contextTypeId }
      })

      return data
    },
    { enabled: enabled }
  )
}
