import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Text } from 'grommet'
import { Controller, useForm } from 'react-hook-form'

import { Box, Checkbox, Form, Message, Modal, Select, TextInput, useNotify } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { CustomField } from 'main/services/queries/types'
import { useCustomFieldCreate } from 'main/services/queries/use-custom-fields'

type NewCustomFieldModalProps = {
  isOpen: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  integrationActionItemId?: number
  visibility?: number | undefined
  handleOnClose?: (customField: CustomField | undefined) => void
}

export const NewCustomFieldModal = ({
  isOpen,
  setOpen,
  integrationActionItemId,
  visibility,
  handleOnClose
}: NewCustomFieldModalProps) => {
  const { t } = useLanguage('customFields')
  const notify = useNotify()
  const customFieldMutation = useCustomFieldCreate()
  const [newCustomField, setNewCustomField] = useState<CustomField | undefined>(undefined)
  const [showDisplayName, setShowDisplayName] = useState<boolean>(false)
  const defaultValues = {
    name: '',
    display_name: '',
    field_type: null,
    apply_to: null,
    required: false,
    display_list: false,
    display_search: false,
    options: {
      transient: false,
      readonly: false,
      lock_name: false,
      hidden: false
    }
  }
  const methods = useForm({
    defaultValues: defaultValues
  })
  const { reset, register, control, formState } = methods

  const fieldTypeOptions = (): { label: string; value: string | number }[] => {
    return [{ label: 'Text', value: 'text' }]
  }

  const applyToOptions = (): { label: string; value: string | number }[] => {
    return [
      { label: 'Task Edit', value: 'task_edit' },
      { label: 'Runbook Edit', value: 'runbook_edit' }
    ]
  }

  const onFormSubmit = async (data: any) => {
    if (integrationActionItemId) {
      data.constraint = JSON.stringify({ integration_action_item_id: [integrationActionItemId] })
      data.field_type = data.field_type || 'text'
      if (!visibility || visibility === -1) {
        data.global = true
        data.account_id = undefined
      } else {
        data.global = false
        data.account_id = visibility
      }
    }

    customFieldMutation.mutate(data, {
      onSuccess: (response: CustomField) => {
        setNewCustomField(response)
        notify.success(t('add.notification.message'))
      },
      onError: () => {
        notify.error(t('errorMessage'))
      }
    })
  }

  const handleCloseModal = () => {
    handleOnClose && handleOnClose(newCustomField)
    setOpen(false)
  }

  const resetForm = () => {
    reset(defaultValues)
  }

  useEffect(() => {
    if (newCustomField && customFieldMutation.isSuccess) {
      setOpen(false)
      resetForm()
      handleCloseModal()
    }
  }, [customFieldMutation.isSuccess])

  const hasError = (key: string): boolean => {
    const index = customFieldMutation.error?.errorDetails?.findIndex(error => error.attribute === key)
    if (typeof index === 'number') {
      return index > -1
    }
    return false
  }

  return (
    <Modal
      title={t('add.title')}
      open={isOpen}
      loading={formState.isValidating || formState.isSubmitting || customFieldMutation.isLoading}
      confirmText={t('submitTextLabel')}
      confirmIcon={'add'}
      onClickConfirm={
        Object.keys(formState.errors).length > 0 || formState.isValidating || formState.isSubmitting
          ? undefined
          : methods.handleSubmit(onFormSubmit)
      }
      onClose={() => setOpen(false)}
    >
      <Form onSubmit={methods.handleSubmit(onFormSubmit)}>
        <Box>
          {customFieldMutation.error && <Message message={customFieldMutation.error.errors} type="error" />}
          <TextInput label={t('fields.name.label')} required={true} hasError={hasError('name')} {...register('name')} />
          {showDisplayName && (
            <TextInput
              label={t('fields.displayName.label')}
              required
              hasError={hasError('display_name')}
              {...register('display_name')}
            />
          )}
          <Controller
            control={control}
            name={'field_type'}
            render={({ field: { value, onChange, ref } }) => (
              <Select
                onChange={onChange}
                required
                hasError={hasError('field_type')}
                inputRef={ref}
                label={t('fields.type.label')}
                value={value || 'text'}
                options={fieldTypeOptions()}
              />
            )}
          />
          <Controller
            control={control}
            name={'apply_to'}
            render={({ field: { value, onChange, ref } }) => (
              <Select
                onChange={onChange}
                required
                hasError={hasError('apply_to')}
                inputRef={ref}
                label={t('fields.displayType.label')}
                value={value}
                options={applyToOptions()}
              />
            )}
          />
          <Box>
            <Text color="text-light" size="small">
              {t('fields.additionalSettings.labels.additionalSettings')}
            </Text>
            <Checkbox
              label={t('fields.additionalSettings.labels.lockName')}
              {...register('options.lock_name')}
              onChange={e => {
                setShowDisplayName(e.currentTarget.checked)
              }}
            />
            <Checkbox label={t('fields.additionalSettings.labels.listView')} {...register('display_list')} />
            <Checkbox label={t('fields.additionalSettings.labels.filters')} {...register('display_search')} />
            <Checkbox label={t('fields.additionalSettings.labels.required')} {...register('required')} />
            <Checkbox
              label={t('fields.additionalSettings.labels.transient')}
              {...register('options.transient')}
              helpText={t('fields.additionalSettings.helpText.transient')}
            />
            <Checkbox label={t('fields.additionalSettings.labels.readOnly')} {...register('options.readonly')} />
            <Checkbox
              label={t('fields.additionalSettings.labels.hidden')}
              {...register('options.hidden')}
              helpText={t('fields.additionalSettings.helpText.hidden')}
            />
          </Box>
        </Box>
      </Form>
    </Modal>
  )
}
