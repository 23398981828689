import { useState } from 'react'
import CSS from 'csstype'
import styled from 'styled-components/macro'

import { LegacyFlexItem as FlexItem, Tooltip } from '@cutover/react-ui'
import { colors, px2rem } from '../../utils'

type BarTableProps = {
  data: BarTableData
  media: 'screen' | 'email' | 'print'
  onRowClick?: (id: number) => void
  onSegmentClick?: (id: number, displayStage: string) => void // currently only used in Runbook Completion Widget
  mainLabelAsComponent?: boolean
}

export type BarTableData = {
  rowData: RowData[]
}

export type RowData = {
  id: number
  mainLabel: string | JSX.Element
  rowInfo: string
  additionalRowInfo?: { [key: string]: string } | null
  values: Values[]
  optionalComponent?: JSX.Element // optional component to the side (i.e. rag status component)
  status?: RagStatus
}

export type RagStatus = 'amber' | 'green' | 'refreshing' | 'red' | 'complete' | 'unknown' | 'off'

export type Values = {
  main?: boolean // if main bar, adds percentages overlay and puts a checkmark once 100%
  width: number
  color: string
  displayStage?: string
  tooltipText?: string
}

export const BarTable = ({ data, onRowClick, onSegmentClick, media }: BarTableProps) => {
  const { rowData } = data
  const [barsOnDisplay, setBarsOnDisplay] = useState(10) // initial number of rows to display.
  const [showMore, setShowMore] = useState(false)

  const handleShowMoreClick = () =>
    setBarsOnDisplay(
      rowData.length === barsOnDisplay
        ? rowData.length
        : rowData.length > barsOnDisplay
        ? barsOnDisplay + 10 < rowData.length
          ? barsOnDisplay + 10
          : rowData.length
        : rowData.length
    )

  const isScreen = media === 'screen'

  const rowItem = (
    rowData: RowData,
    rowItemIndex: number,
    onSegmentClick?: (id: number, displayStage: string) => void,
    onRowClick?: (id: number) => void
  ) => {
    const { mainLabel, rowInfo, additionalRowInfo, values, optionalComponent } = rowData
    // if optional component exists on the right, reduce width to 90%
    const segmentsWidth = optionalComponent ? 90 : 100
    const rowContainerStyles = {
      display: 'flex',
      flexDirection: `${isScreen ? 'row' : 'column'}` as const
    }
    // 0% segments are causing render issues with the border radius on the right end of bar
    const filteredValues = values.filter(value => value.width > 0)

    return (
      <RowContainer key={`r-${rowItemIndex}`} style={rowContainerStyles}>
        <RowLabel
          currentRowIndex={rowItemIndex}
          width={isScreen ? 50 : 100}
          onClick={() => onRowClick?.(rowData.id)}
          data-testid="bar-table-row-label"
          isSub={rowData ? true : false}
        >
          <MainLabel>
            {rowData.status && rowData.status !== 'off' && <RagIndicator status={rowData.status} />}
            <Tooltip content={mainLabel} placement="top" key={rowData.id} disableOnClickAsTriggerProp>
              {mainLabel}
            </Tooltip>
          </MainLabel>
          {isScreen && (
            <RowInfo>
              {rowInfo}&nbsp;
              {additionalRowInfo && (
                <AdditionalRowInfo color={additionalRowInfo?.color}>{additionalRowInfo.value}</AdditionalRowInfo>
              )}
            </RowInfo>
          )}
        </RowLabel>
        <SegmentsWrapper width={isScreen ? 50 : 100}>
          <Segments width={segmentsWidth}>
            {filteredValues.map((value, index) => {
              return value.main ? (
                <FlexItem
                  onClick={() => value.displayStage && onSegmentClick?.(rowData.id, value.displayStage)}
                  style={{
                    width: `${value.width}%`,
                    backgroundColor: value.color,
                    boxShadow: `0 0 0 ${px2rem(1)} ${colors.white}`,
                    lineHeight: '16px'
                  }}
                  key={`f-${index}`}
                >
                  <Tooltip content={value.tooltipText} placement="top" key={`f-${index}`} disableOnClickAsTriggerProp>
                    <div>
                      {value.width === 100 ? (
                        <IconWrapper>
                          <svg viewBox="0 0 32 32">
                            <path d="M27 4l-15 15-7-7-5 5 12 12 20-20z"></path>
                          </svg>
                        </IconWrapper>
                      ) : (
                        <Percentage>{`${Math.round(value.width)}%`}</Percentage>
                      )}
                    </div>
                  </Tooltip>
                </FlexItem>
              ) : (
                <FlexItem
                  onClick={() => value.displayStage && onSegmentClick?.(rowData.id, value.displayStage)}
                  style={{
                    width: `${value.width}%`,
                    backgroundColor: value.color,
                    boxShadow: `0 0 0 ${px2rem(1)} ${colors.white}`
                  }}
                  key={`f-${index}`}
                >
                  <Tooltip content={value.tooltipText} placement="top" key={`f-${index}`} disableOnClickAsTriggerProp>
                    <Percentage>&nbsp;</Percentage>
                  </Tooltip>
                </FlexItem>
              )
            })}
          </Segments>
          {optionalComponent && optionalComponent}
        </SegmentsWrapper>
      </RowContainer>
    )
  }

  return (
    <BarTableWrapper data-testid="bar-table">
      {rowData &&
        rowData.length > 0 &&
        rowData.slice(0, barsOnDisplay).map((row, index) => rowItem(row, index, onSegmentClick, onRowClick))}
      {rowData.length > barsOnDisplay && isScreen && (
        <ShowMore
          onClick={handleShowMoreClick}
          onMouseEnter={() => setShowMore(true)}
          onMouseLeave={() => setShowMore(false)}
          showMore={showMore}
        >
          Show more...
        </ShowMore>
      )}
    </BarTableWrapper>
  )
}

// styled component types

const RAG_STYLES_MAPPING: Record<string, CSS.Properties> = {
  red: {
    backgroundColor: colors.alertBright,
    boxShadow: '0 0 0 rgba(255, 51, 0, 0.4)',
    animation: 'dot-pulse-red 1s infinite'
  },
  amber: {
    backgroundColor: colors.warningBright,
    boxShadow: '0 0 0 rgba(255, 153, 0, 0.4)',
    animation: 'dot-pulse-amber 1.5s infinite'
  },
  green: {
    backgroundColor: colors.successBright,
    boxShadow: '0 0 0 rgba(151, 164, 170, 0.4)',
    animation: 'dot-pulse-green 2s infinite'
  },
  refreshing: {
    backgroundColor: 'rgba(0, 0, 255, 1)',
    boxShadow: '0 0 0 rgba(151, 164, 170, 0.4)',
    animation: 'dot-pulse-green 2s infinite'
  },
  complete: {
    backgroundColor: colors.success,
    boxShadow: '0 0 0 rgba(255, 153, 0, 0.4)',
    animation: 'dot-pulse-green 2s infinite'
  },
  unknown: {
    backgroundColor: 'rgba(151, 164, 170, 1)',
    boxShadow: '0 0 0 rgba(151, 164, 170, 0.4)',
    animation: 'dot-pulse-amber 1.5s infinite'
  }
}

const RagIndicator = styled.a<{ status: string }>`
  width: 12px;
  height: 12px;
  border-radius: 6px;
  display: inline-block;
  box-sizing: border-box;
  cursor: pointer;
  vertical-align: -1px;
  background-color: ${({ status }) => RAG_STYLES_MAPPING[status].backgroundColor};
  box-shadow: ${({ status }) => RAG_STYLES_MAPPING[status].boxShadow};
  animation: ${({ status }) => RAG_STYLES_MAPPING[status].animation};
`

type RowLabelProps = {
  width: number
  currentRowIndex: number
  isSub: boolean
}

type AdditionalRowInfoProps = {
  color: string
}

type SegmentWrapperProps = {
  width: number
}

type SegmentsProps = {
  width: number
}

// TODO: change to styled(Box) throughout the code
const RowContainer = styled.div`
  margin-right: ${px2rem(10)};
  padding: 8px;
  margin: 0 -8px;
  border-radius: 8px;
  &:hover {
    background-color: ${colors.bg1};
  }
`

const RowLabel = styled.div`
  display: flex;
  width: ${(props: RowLabelProps) => `${props.width}%`};
  justify-content: space-between;
  align-items: center;
  margin-right: ${px2rem(10)};
  cursor: pointer;
  color: ${colors.text}
  gap: ${px2rem(10)};
`

const MainLabel = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 500px;
`

const RowInfo = styled.div`
  display: flex;
  color: ${colors.textLight};
  flex-direction: row;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

const AdditionalRowInfo = styled.span`
  color: ${(props: AdditionalRowInfoProps) => props.color || colors.textLight};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

const SegmentsWrapper = styled.div`
  display: flex;
  align-items: center;
  width: ${(props: SegmentWrapperProps) => `${props.width}%`};
`

const Segments = styled.div`
  width: ${(props: SegmentsProps) => `${props.width}%`};
  display: flex;
  flex-wrap: wrap;
  background-color: ${colors.white};
  height: ${px2rem(16)};
  span {
    display: flex;
    justify-content: flex-end;
    font-size: ${px2rem(12)};
    color: ${colors.white};
    margin-right: ${px2rem(3)};
  }
  &&& {
    overflow: hidden;
    border-radius: 8px;
    -webkit-border-radius: 8px;
  }
`

const IconWrapper = styled.div`
  svg {
    float: right;
    width: 13px;
    height: 13px;
    stroke: ${colors.white};
    fill: ${colors.white};
  }
  margin-right: 5px;
`

const Percentage = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 1px;
`

const ShowMore = styled.div`
  display: flex;
  justify-content: center;
  color: ${(props: { showMore: boolean }) => (props.showMore ? colors.text : colors.textLight)};
  cursor: pointer;
`

const BarTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
  margin-top: 16px;
`
