import { createTaskFilterFunction, RunbookFilterType, TaskFilterContext } from './filters'
import { TaskListTask } from 'main/services/queries/types'

export const team = createTaskFilterFunction(
  'team',
  (task: TaskListTask, filters: RunbookFilterType, context: TaskFilterContext): boolean => {
    if (filters.team === undefined) return false

    const { runbookTeamIds = [], teamUserIds = [] } = context

    // @ts-ignore match on *any* team
    if (filters.team[0] === '*' && task.runbook_team_ids.length > 0) {
      return true
    }

    // match on no team
    if (filters.team.includes(0) && task.runbook_team_ids?.length === 0) {
      return true
    }

    // match on specific team
    if (hasIntersection(task.runbook_team_ids, runbookTeamIds)) {
      return true
    }

    // match on users in the matching teams
    if (filters.includeUsers && hasIntersection(task.user_ids, teamUserIds)) {
      return true
    }

    return false
  }
)

const hasIntersection = (a: any[], b: any[]) => {
  return a.some(item => b.includes(item))
}
