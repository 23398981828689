// @ts-nocheck
import 'reflect-metadata'
import { ILanguageService } from './ILanguageService'
import { injectable } from 'inversify'
import i18next from 'i18next'
import { NAMESPACES } from 'config/i18n'

@injectable()
export class FakeLanguageService implements ILanguageService {
  public loaded: boolean = true
  public mount(language: string) {
    if (process.env.NODE_ENV === 'test') {
      const Backend = require('i18next-fs-backend')

      return i18next.use(Backend).init({
        initImmediate: false,
        ns: NAMESPACES,
        defaultNS: 'common',
        debug: false,
        backend: {
          loadPath: 'src/Assets/locales/{{ns}}/en.json'
        }
      })
    }
    return Promise.resolve(() => {})
  }

  public get(key: string, options?: any): string {
    return i18next.t(key, options)
  }
}
