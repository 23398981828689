import { useMutation, useQuery } from 'react-query'

import { useSetPermissions } from './use-permissions'
import { apiClient } from 'main/services/api'
import { QueryKeys } from 'main/services/queries/query-keys'
import { SamlConfiguration } from 'main/services/queries/types'

type SamlConfigurationsResponse = {
  meta: {
    permissions: { [key: string]: number[] }
  }
  saml_configurations: SamlConfiguration[]
}

export function useSamlConfigurationQuery() {
  const setPermissions = useSetPermissions('saml-configs')

  return useQuery<SamlConfigurationsResponse, Error, SamlConfigurationsResponse>(
    [QueryKeys.SamlConfigurations],
    async () => {
      const { data } = await apiClient.get<SamlConfigurationsResponse>({
        url: 'saml_configurations'
      })
      return data
    },
    {
      onSuccess: data => {
        setPermissions(data.meta.permissions)
      }
    }
  )
}

type samlConfigurationResponse = {
  meta: {
    permissions: { [key: string]: number[] }
  }
  saml_configuration: SamlConfiguration
}

export function useSamlConfiguration(samlConfigurationId: number | undefined) {
  const setPermissions = useSetPermissions('saml-configs')

  return useQuery<samlConfigurationResponse, Error, samlConfigurationResponse>(
    [QueryKeys.SamlConfigurations, samlConfigurationId],
    async () => {
      const { data } = await apiClient.get<samlConfigurationResponse>({
        url: `saml_configurations/${samlConfigurationId}`
      })
      return data
    },
    {
      onSuccess: data => {
        setPermissions(data.meta.permissions)
      }
    }
  )
}

export function useCreateSamlConfigurationsMutation() {
  return useMutation<SamlConfiguration, Error, SamlConfiguration>(QueryKeys.SamlConfigurations, async settings => {
    const { data } = await apiClient.post<
      { saml_configuration: SamlConfiguration },
      { saml_configuration: SamlConfiguration }
    >({
      url: 'saml_configurations',
      data: { saml_configuration: settings }
    })

    // Our api client uses the indicated response type passed into the `put` type params but adds null | undefined right now.
    // This needs to be fixed.
    return data?.saml_configuration as SamlConfiguration
  })
}

export function useUpdateSamlConfigurationsMutation() {
  return useMutation<any, Error, { id: number; payload: any }>(
    ['saml-configs-patch-request'],
    async ({ id, payload }) => {
      const { data } = await apiClient.patch({
        url: `saml_configurations/${id}`,
        data: payload
      })

      return data
    }
  )
}

export function useDeleteSamlConfigurationsMutation() {
  return useMutation<any, Error, { id: number }>(['saml-configs-delete-request'], async ({ id }) => {
    const { data } = await apiClient.delete({
      url: `saml_configurations/${id}`
    })

    return data
  })
}

export const downloadServiceProviderMetadata = async () => {
  const { data } = await apiClient.get({
    url: 'saml_configurations/service_provider_metadata'
  })

  return data as string
}

export function useParseMetadataMutation() {
  return useMutation<any, Error, FormData>(['saml-configs-parse-metadata-request'], async (payload: FormData) => {
    const { data } = await apiClient.post({
      url: 'saml_configurations/parse_idp_metadata',
      data: payload,
      headers: { 'Content-Type': 'multipart/formdata' }
    })

    return data
  })
}
