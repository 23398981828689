// @ts-nocheck
import tinycolor2 from 'tinycolor2'
import { IColor } from 'Components/Molecules'
import { FieldOptions } from './FieldOptions'
import { FieldOptionsPresenter } from './FieldOptionsPresenter'
import { Option } from './Option'
import { OptionPresenter } from './OptionPresenter'
import { colors } from 'app/Styles/Variables'

export const defaultOptionColor: IColor = tinycolor2(colors.primary).toRgb()
export { FieldOptions, FieldOptionsPresenter, Option, OptionPresenter }
