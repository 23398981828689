// @ts-nocheck
import { computed, observable } from 'mobx'
import { injectable } from 'inversify'
import { container } from 'app/Config/IOC'
import { RoleTypesRepository } from 'app/Repositories/RoleType/RoleTypesRepository'
import { PillFilterPresenter } from 'Components/Molecules'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'
import { Types } from 'Gateways/Service/Types'

@injectable()
export class RoleTypesPageHeadingPresenter {
  private roleTypesRepository: RoleTypesRepository = container.get(RoleTypesRepository)
  private language: ILanguageService = container.get(Types.ILanguageService)

  @observable
  public contextPillPresenter = new PillFilterPresenter({
    group: this.language.get('roleTypes:filter:contextFilterPanel'),
    type: 'group'
  }).withTitle(this.language.get('roleTypes:filter:contextFilterPanel'))

  @computed
  public get filteredRoleTypeCount(): number {
    return this.roleTypesRepository.filteredRoleTypeTotal
  }

  @computed
  public get totalRoleTypeCount(): number {
    return this.roleTypesRepository.roleTypesTotal
  }
}
