import { CheckboxGroup } from '@cutover/react-ui'
import { useComponentPropsAndState } from '../apps-state'
import { AppComponentNodeProps, ContentNodeOption } from '../apps-types'

type CheckboxesFieldNodeProps = AppComponentNodeProps & {
  label: string
  name: string
  options: ContentNodeOption[]
  plain?: boolean
  selected: string[]
  readOnly?: boolean
  error?: boolean
}

export const CheckboxesFieldNode = ({ appId, resourceId, id, ...props }: CheckboxesFieldNodeProps) => {
  const { componentProps, state, setState } = useComponentPropsAndState(appId, resourceId, id, props)
  const { label, options, name, readOnly, plain = false, error } = componentProps as CheckboxesFieldNodeProps
  const values = (state ? state[name] : []) as string[]

  const handleValueChange = (value: string[]) => {
    setState({ name, value })
  }

  return (
    <CheckboxGroup
      plain={plain}
      readOnly={readOnly}
      name={name}
      options={options}
      value={values}
      label={label}
      onChange={(event: any) => handleValueChange(event?.value ?? [])}
      hasError={error}
    />
  )
}
