import { useEffect } from 'react'
import { saveAs } from 'file-saver'
import { Anchor } from 'grommet'
import parse from 'html-react-parser'
import { useForm } from 'react-hook-form'
import { useLocation } from 'react-router-dom'

import { Box, Checkbox, Form, Message, Modal, Text, useNotify } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import {
  useDownloadRoleMappingsCSVTemplate,
  useUploadRoleMappingsMutation
} from 'main/services/queries/use-upload-role-mappings-mutation'

type UploadMappingsModalProps = {
  modalOpen: boolean
  closeModal: () => void
}

export type SamlRoleMappingFormData = {
  files: FileList | never[]
  overwrite: boolean
}

export const UploadMappingsModal = ({ modalOpen, closeModal }: UploadMappingsModalProps) => {
  const location = useLocation()
  const { t } = useLanguage('roleMappings')
  const notify = useNotify()
  const { state } = location
  const { mutate, isLoading, isError, isSuccess, error, reset } = useUploadRoleMappingsMutation()
  const downloadImportCSVTemplateMutation = useDownloadRoleMappingsCSVTemplate()

  const defaultValues = {
    files: [],
    overwrite: false
  }

  const methods = useForm({
    defaultValues: defaultValues
  })

  const { register, handleSubmit } = methods

  useEffect(() => {
    if (isSuccess) {
      closeModal()
      methods.reset(defaultValues)
    }
  }, [isSuccess])

  const onFormSubmit = (data: SamlRoleMappingFormData) => {
    const formData = new FormData()
    formData.append('file', data.files ? data.files[0] : '')
    formData.append('overwrite', data.overwrite.toString())

    const mutationData = {
      saml_configuration_id: state?.samlConfigurationId,
      formData: formData
    }

    mutate(mutationData, {
      onSuccess: (response: any) => {
        if ('warnings' in response) {
          notify.warning(response.warnings, {
            title: t('successTitle')
          })
        } else {
          notify.success(t('uploadSuccessMessage'), {
            title: t('successTitle')
          })
        }
      }
    })
  }

  const downloadTemplate = () => {
    downloadImportCSVTemplateMutation.mutate(
      {},
      {
        onSuccess: (response: string) => {
          const file = new Blob([response], { type: 'text/csv' })
          saveAs(file, 'RoleMappingsTemplate.csv')
          notify.success(t('templateDownloadSuccess'))
        },
        onError: () => {
          notify.error(t('templateDownloadError'))
        }
      }
    )
  }

  return (
    <Modal
      title={t('uploadModalTitle')}
      open={modalOpen}
      onClickConfirm={handleSubmit(onFormSubmit)}
      onClose={() => {
        closeModal()
        reset()
      }}
      confirmText={t('uploadModalButtonText')}
      confirmIcon="upload"
      loading={isLoading}
    >
      {isError && (
        <Box margin={{ bottom: '12px' }}>
          <Message type="error" message={error?.errors} />
        </Box>
      )}
      <Text>
        {parse(t('uploadModalText'))} <Anchor onClick={downloadTemplate}>{t('downloadTemplate')}</Anchor>
      </Text>
      <Form>
        {/*TODO: Introduce proper react-ui file input component*/}
        <input
          type="file"
          css={`
            margin-top: 20px;
            margin-bottom: 14px;
          `}
          data-testid={'mappings-input'}
          {...register('files')}
        />
        <Checkbox {...register('overwrite')} label={t('uploadModalCheckboxLabel')} />
      </Form>
    </Modal>
  )
}
