import { createElement } from 'react'

import { components } from './components'

export function createComponent(id: string, props: Record<string, unknown>): React.ReactElement {
  const component = components.get(id)

  if (!component) {
    throw new Error(`Unknown React component identifier '${id}'.`)
  }

  return createElement(component, props)
}
