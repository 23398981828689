import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const HelpCircle = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="HelpCircle" {...props}>
    <path d="M29.053 14.65c1.474 1.16 2.447 2.918 2.447 5.164 0 3.081-2.107 4.523-3.542 5.505l-.11.075C26.283 26.467 25.5 27.09 25.5 28.5V30h-3v-1.5c0-3.081 2.107-4.523 3.542-5.505l.11-.075c1.565-1.073 2.348-1.695 2.348-3.106 0-1.296-.527-2.196-1.303-2.807C26.382 16.365 25.226 16 24 16s-2.382.365-3.197 1.007c-.776.611-1.303 1.51-1.303 2.807v1.5h-3v-1.5c0-2.246.973-4.003 2.447-5.164C20.382 13.52 22.226 13 24 13s3.618.52 5.053 1.65ZM25.5 35v-3h-3v3h3Z" fill="#000" /><path fillRule="evenodd" clipRule="evenodd" d="M24 42c9.941 0 18-8.059 18-18S33.941 6 24 6 6 14.059 6 24s8.059 18 18 18Zm0-3c-8.284 0-15-6.716-15-15 0-8.284 6.716-15 15-15 8.284 0 15 6.716 15 15 0 8.284-6.716 15-15 15Z" fill="#000" />
  </StyledIcon>
))

HelpCircle.displayName = 'HelpCircle'

export { HelpCircle }
