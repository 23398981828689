import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const BrandSocial = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 100 100" a11yTitle="BrandSocial" {...props}>
    <path opacity=".5" d="M64.334 71h-29l29 29V71Z" fill="#fff" /><path fill="#fff" d="M0 0h100v64.667H0z" />
  </StyledIcon>
))

BrandSocial.displayName = 'BrandSocial'

export { BrandSocial }
