import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const BrandTechOps = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 100 100" a11yTitle="BrandTechOps" {...props}>
    <path fill="#fff" d="M70.667 0H100v29.333H70.667z" /><path opacity=".5" fill="#fff" d="M35.333 35.333h29.333v29.333H35.333zM0 70.667h29.333V100H0z" />
  </StyledIcon>
))

BrandTechOps.displayName = 'BrandTechOps'

export { BrandTechOps }
