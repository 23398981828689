import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { MergeExclusive } from 'type-fest'

import { UserSelect } from '../form/user-select'
import { useInitialMount } from 'main/services/hooks'
import { User } from 'main/services/queries/types'

// Types
type RolesSelectSharedProps = {
  accountId: number | undefined
  label?: string
  required?: boolean
  hasError?: boolean
  inlineError?: string
  readOnly?: boolean
  disabled?: boolean
  tooltip?: string
  name?: string
  'data-testid'?: string
  a11yTitle?: string
}

// TODO: switch names -> Uncontrolled/Controlled
type RolesSelectControlledProps = RolesSelectSharedProps & {
  initialSelected?: User[]
  defaultSelected?: User[]
  onChange?: (val: User[] | undefined) => void
}
type RolesSelectUncontrolledProps = RolesSelectSharedProps & {
  selected: User[]
  onChange: (val: User[] | undefined) => void
}
type RolesSelectProps = MergeExclusive<
  RolesSelectControlledProps & { controlled?: true },
  RolesSelectUncontrolledProps & { controlled: false }
>

// Components
export const RolesSelect = forwardRef<{ reset: () => void } | undefined, RolesSelectProps>(
  ({ controlled = true, ...props }, ref) =>
    controlled ? (
      <RolesSelectControlled ref={ref} {...(props as RolesSelectControlledProps)} />
    ) : (
      <RolesSelectUncontrolled {...(props as RolesSelectUncontrolledProps)} />
    )
)

// should be RolesSelectUncontrolled - no controlled value/selected prop, only initial/default props)
export const RolesSelectControlled = forwardRef<{ reset: () => void } | undefined, RolesSelectControlledProps>(
  ({ initialSelected = [], defaultSelected = [], onChange, ...props }, ref) => {
    const isInitialMount = useInitialMount()
    const [selectedRecipients, setSelectedRecipients] = useState<User[] | undefined>(initialSelected)

    useEffect(() => {
      if (isInitialMount) return
      onChange?.(selectedRecipients)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRecipients])

    useImperativeHandle(
      ref,
      () => ({
        reset: () => {
          setSelectedRecipients(defaultSelected)
        }
      }),
      [defaultSelected]
    )

    return <RolesSelectUncontrolled selected={selectedRecipients || []} onChange={setSelectedRecipients} {...props} />
  }
)

// should be RolesSelectControlled ("controlled" via passing value (selected) prop)
export const RolesSelectUncontrolled = ({ accountId, tooltip, selected, ...props }: RolesSelectUncontrolledProps) => {
  return <UserSelect {...props} helpText={tooltip} accountId={accountId} value={selected} />
}
