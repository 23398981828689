import { Dispatch, SetStateAction } from 'react'

import { Box, Modal, SanitizedString, useNotify } from '@cutover/react-ui'
import { queryClient } from 'main/query-client'
import { useLanguage } from 'main/services/hooks'
import { useDeleteSamlConfigurationsMutation } from 'main/services/queries/use-saml-configurations-query'

type DeleteSamlConfigModalType = {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  samlId: number
  onSubmit: () => void
}

export const DeleteSamlConfigModal = ({ open, setOpen, samlId, onSubmit }: DeleteSamlConfigModalType) => {
  const notify = useNotify()
  const { t } = useLanguage('samlConfigurations')
  const deleteMutation = useDeleteSamlConfigurationsMutation()
  const { isLoading: isDeleteLoading } = deleteMutation

  const onSubmitDelete = async () => {
    deleteMutation.mutate(
      { id: samlId },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['saml-configurations'])
          notify.success(t('deleteSamlConfiguration.toasters.success'))
          closeDeleteSamlConfigForm()
          onSubmit()
        },
        onError: () => {
          notify.error(t('deleteSamlConfiguration.toasters.genericError'))
        }
      }
    )
  }
  const closeDeleteSamlConfigForm = () => {
    setOpen(false)
  }

  return (
    <Modal
      title={t('deleteSamlConfiguration.title')}
      open={open}
      onClose={closeDeleteSamlConfigForm}
      onClickConfirm={onSubmitDelete}
      loading={isDeleteLoading}
      confirmText="Delete"
      confirmIcon="trash-o"
      loadingText={t('samlConfigurations.loading')}
    >
      <Box>
        <SanitizedString input={t('deleteSamlConfiguration.body')} />
      </Box>
    </Modal>
  )
}
