import { useParams } from 'react-router-dom'
import { useRecoilCallback, useRecoilValue, useRecoilValueLoadable } from 'recoil'

import { dashboardState } from 'main/recoil/runbook/models/account/dashboards'

export const useGetActiveDashboard = () => {
  const { dashboardId } = useParams()

  return useRecoilValue(dashboardState(parseDashboardId(dashboardId)))
}

export const useGetActiveDashboardCallback = () => {
  const { dashboardId } = useParams()

  return useRecoilCallback(({ snapshot }) => async () => {
    return await snapshot.getPromise(dashboardState(parseDashboardId(dashboardId)))
  })
}

export const useGetActiveDashboardLoadable = () => {
  const { dashboardId } = useParams()

  return useRecoilValueLoadable(dashboardState(parseDashboardId(dashboardId)))
}

export const useGetActiveDashboardLoadableCallback = () => {
  const { dashboardId } = useParams()

  return useRecoilCallback(({ snapshot }) => () => {
    return snapshot.getLoadable(dashboardState(parseDashboardId(dashboardId)))
  })
}

export const useActiveDashboardId = () => {
  const { dashboardId } = useParams()

  return parseDashboardId(dashboardId)
}

// a bit unnecessary but here for consistency
export const useActiveDashboardIdCallback = () => {
  const { dashboardId } = useParams()

  return async () => parseDashboardId(dashboardId)
}

/* --------------------------------- Helpers -------------------------------- */
const parseDashboardId = (id?: string) => (id ? parseInt(id) : undefined)
