import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const BrandSnippet = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 100 100" a11yTitle="BrandSnippet" {...props}>
    <g clipPath="url(#a)" fill="#fff"><path opacity=".5" fillRule="evenodd" clipRule="evenodd" d="M64 36H0v64h64V36ZM32 82.516c8.017 0 14.516-6.5 14.516-14.516 0-8.017-6.5-14.516-14.516-14.516-8.017 0-14.515 6.5-14.515 14.516 0 8.017 6.498 14.516 14.515 14.516Z" /><circle cx="31.667" cy="14.667" r="14.667" /><circle cx="85.667" cy="67.667" r="14.667" /></g>
  </StyledIcon>
))

BrandSnippet.displayName = 'BrandSnippet'

export { BrandSnippet }
