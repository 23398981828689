import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Undo = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Undo" {...props}>
    <path d="M4.687 17.504 16 6.19l2.122 2.121L10.432 16H32c5.523 0 10 4.477 10 10s-4.477 10-10 10h-3v-3h3a7 7 0 1 0 0-14H10.425l7.697 7.696L16 28.817 4.687 17.504Z" fill="#000" />
  </StyledIcon>
))

Undo.displayName = 'Undo'

export { Undo }
