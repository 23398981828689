// @ts-nocheck
import { injectable } from 'inversify'
import { observable, action, computed } from 'mobx'
import queryString from 'query-string'
import { ButtonPresenter, CheckboxPresenter, DateTimePresenter, ErrorAlertPresenter } from 'Components/Molecules'
import { RadioButtonsPresenter, ModalPresenter } from 'Components/Atoms'
import { Types } from 'Gateways/Service/Types'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'
import { container } from 'app/Config/IOC'
import { eventManager } from 'event-manager'

import {
  setDate,
  cloneDate,
  getHours,
  getMinutes,
  getUtcString,
  initalizeDateWithDefaultTime
} from 'Shared/Helpers/DateService'

export interface IRunbookPM {
  timing_mode: 'scheduled' | 'unscheduled'
  copy_tasks: boolean
  copy_teams: boolean
  copy_users: boolean
  end_scheduled?: string
  event_name?: string
  name: string
  projectModifyType: string
  shift_time: boolean
  start_planned?: string
  timezone: string | null
  start_scheduled?: string
  is_template?: boolean
  template_status?: string
  currentTemplateVersionApprovalStatus: string
}

export interface IDialogData {
  modalTitle: string
  ngDialogId: string
  openPreviousModal: Function
  openStoredModal: Function
  runbook?: {
    id: string
    is_template: boolean
    name: string
    runbook_type_id: string
    selectedTimezone: string
    start_planned: string
    start_scheduled: string
    shift_time: boolean
  }
  runbookData: IRunbookPM
  runbookIds: string[]
}

interface IRunbookType {
  id: number
  iconName: string
  iconColor: string
  isIncident: boolean
}

@injectable()
export class AngularChooseRunbookStartTimePresenter {
  @observable
  public modalPresenter: ModalPresenter = null

  @observable
  public value: string = ''

  @observable
  public test: string = ''

  public language: ILanguageService = container.get(Types.ILanguageService)

  @observable
  public errorAlertPresenter: ErrorAlertPresenter = new ErrorAlertPresenter()

  @observable
  public startPlannedTime = initalizeDateWithDefaultTime()

  @observable
  public loading = false

  private closeModal = () => {
    this.modalPresenter && this.modalPresenter.onClose()
  }

  @observable
  public radioButtonPresenter = new RadioButtonsPresenter([
    this.language.get('runbooks:chooseRunbookStartTime:options:option1'),
    this.scheduledOption
  ])
    .withVertical()
    .withMiddleware((option: string) => {
      this.clear()
      if (this.isScheduled) {
        this.resetStartPlannedTime()
      } else {
        this.dateTimePresenter.reset()
        this.endTimePresenter.reset()
      }
      return option
    })

  @observable
  public dateTimePresenter = new DateTimePresenter(null).withLabel('Scheduled start').withIsRequired()

  @observable
  public endTimePresenter = new DateTimePresenter(null).withLabel('Scheduled end')

  @observable
  public incidentDateTimePresenter = new DateTimePresenter(new Date()).withLabel('Incident start date').withIsRequired()

  @observable
  public shiftTimePresenter = new CheckboxPresenter(
    this.language.get('runbooks:chooseRunbookStartTime:shiftTimeOption:label'),
    true
  )

  @action
  public handleTimeChange = (time: Date) => {
    this.startPlannedTime = time
  }

  @action
  public handleDateChange = (date: Date) => {
    this.startPlannedTime = setDate(cloneDate(this.startPlannedTime), {
      hours: getHours(date),
      minutes: getMinutes(date)
    })
  }

  @computed
  public get showScheduler() {
    return this.radioButtonPresenter.selectedOption
  }

  public get scheduledOption() {
    return this.language.get('runbooks:chooseRunbookStartTime:options:option2')
  }

  @computed
  public get isScheduled() {
    return this.radioButtonPresenter.selectedOption === this.scheduledOption
  }

  private addSelectedOptions = (newRunbook: IRunbookPM, shiftTime: boolean, runbookType: IRunbookType | undefined) => {
    newRunbook.timing_mode = this.isScheduled ? 'scheduled' : 'unscheduled'
    newRunbook.shift_time = shiftTime
    newRunbook.is_template = false

    if (this.isScheduled) {
      const dateTimePresenter = this.getSheduledDateTimePresenterForType(runbookType)
      const endTimePresenter = this.endTimePresenter

      newRunbook.start_scheduled = getUtcString(dateTimePresenter.value)
      newRunbook.end_scheduled = endTimePresenter.datePresenter.value ? getUtcString(endTimePresenter.value) : null
      newRunbook.start_planned = null
    } else {
      newRunbook.start_planned = getUtcString(this.startPlannedTime)
      newRunbook.start_scheduled = null

      // Planning mode means creating a template when dealing with incidents
      if (runbookType?.isIncident) {
        newRunbook.is_template = true
        newRunbook.template_status = 'template_draft'
      }
    }
    return newRunbook
  }

  public openPreviousCreateRunbookModal = (
    newRunbook: IRunbookPM,
    customFields: any,
    dialogData: IDialogData,
    runbookTypeId: string
  ) => {
    if (dialogData && dialogData.openStoredModal) {
      dialogData.openStoredModal(newRunbook, customFields)
    }
    //check to see if dialogData exists as this will define whether it's a template option or not
    else if (dialogData) {
      window.angularJS &&
        window.angularJS.openCreateRunbookFromTemplateModal &&
        window.angularJS.openCreateRunbookFromTemplateModal(newRunbook, dialogData, customFields)
    } else {
      window.angularJS &&
        window.angularJS.openCreateRunbookModal &&
        window.angularJS.openCreateRunbookModal('enterDetailsForm', runbookTypeId, newRunbook, customFields)
    }

    this.closeModal()
  }

  public isValid = (runbookType: IRunbookType | undefined) => {
    const dateTimePresenter = this.getSheduledDateTimePresenterForType(runbookType)

    if (this.isScheduled && !dateTimePresenter.datePresenter.isValid) {
      dateTimePresenter.datePresenter.isDirty = true
      this.errorAlertPresenter.setErrorMessages([
        this.language.get('runbooks:chooseRunbookStartTime:sheduleDateIsRequred')
      ])
      return false
    } else if (
      this.isScheduled &&
      dateTimePresenter.datePresenter.isValid &&
      this.endTimePresenter.datePresenter.value &&
      this.endTimePresenter.value < dateTimePresenter.value
    ) {
      this.endTimePresenter.datePresenter.isDirty = true
      this.errorAlertPresenter.setErrorMessages([
        this.language.get('runbooks:chooseRunbookStartTime:endDateMustBeAfterStart')
      ])
      return false
    } else {
      this.errorAlertPresenter.reset()
      return true
    }
  }

  @action
  public handleSubmit = async (
    newRunbook: IRunbookPM,
    customFields: any,
    dialogData: IDialogData,
    shiftTime: boolean,
    runbookType: IRunbookType | undefined
  ) => {
    if (this.isValid(runbookType)) {
      this.loading = true
      const runbookVM = this.addSelectedOptions(newRunbook, shiftTime, runbookType)

      // Save filters in local storage
      const searchString = window.location.href.split('?')[1]
      const searchObject = queryString.parse(searchString)
      localStorage.setItem('previousQuery', JSON.stringify(searchObject))

      // Create listener
      eventManager.once('runbook-create-complete', () => {
        this.clear()
        this.loading = false
        this.closeModal()
        eventManager.emit('reload-runbook-list')
      })

      let response

      if (dialogData) {
        response = await window.angularJS.duplicateRunbook(runbookVM, dialogData, customFields)
      } else {
        response = await window.angularJS.addNewRunbook(runbookVM, customFields)
      }

      if (response && response.hasOwnProperty('errors')) {
        this.loading = false
        this.errorAlertPresenter.setErrorMessages(response.errors)
        return
      }
    }
  }

  @action
  private resetStartPlannedTime = () => (this.startPlannedTime = initalizeDateWithDefaultTime())

  private getSheduledDateTimePresenterForType = (runbookType: IRunbookType | undefined): DateTimePresenter => {
    return runbookType?.isIncident ? this.incidentDateTimePresenter : this.dateTimePresenter
  }

  @action
  private clear = () => {
    this.errorAlertPresenter.reset()
    this.dateTimePresenter.reset()
    this.endTimePresenter.reset()
    this.resetStartPlannedTime()
  }

  public cancelButtonPresenter = new ButtonPresenter(this.language.get('common:cancelButton'), this.closeModal)
    .withDisplayStyle('secondary')
    .withIconName('dnd-forwardslash')
    .withTextTransform('Uppercase')

  @action
  public withProps = ({ modalPresenter }) => {
    this.modalPresenter = modalPresenter
    return this
  }
}
