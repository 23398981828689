import { useMutation } from 'react-query'

import { apiClient } from '../api'

export type TaskBulkCreateData = {
  task_data:
    | { task: { name: string | undefined; task_type_id: number }; linked_resource: { id: number; type: 'Runbook' } }[]
    | undefined
  create_type: 'sequence' | 'parallel'
}

export const useTasksBulkCreate = (runbookId: string, runbookVersionId: string, taskId: number) => {
  return useMutation('tasksBulkCreate', async (taskBulkCreateData: TaskBulkCreateData) => {
    const { data } = await apiClient.post({
      url: `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/tasks/bulk_create`,
      data: {
        base_task_id: taskId,
        ...taskBulkCreateData
      }
    })

    return data
  })
}
