// @ts-nocheck
import { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { getDuration, dateAsMilliseconds } from 'Shared/Helpers/DateService'

const TimerWrapper = styled.span`
  font-weight: 400;
  font-size: 1.6rem;
`
// TODO: Move these date functions to a new util when we decide how we want to do this.
const formatUnit = (unit: number) => {
  if (unit < 10) {
    return '0' + unit
  }

  return unit
}

const getTimerDisplayFromDuration = (durationInMS: number): string => {
  const duration = getDuration(durationInMS / 1000)
  let display = ''

  if (duration.weeks > 0) {
    display += formatUnit(duration.weeks) + 'w '
  }

  if (duration.days > 0) {
    display += formatUnit(duration.days) + 'd '
  }

  if (duration.hours > 0) {
    display += formatUnit(duration.hours) + 'h '
  }

  return display + formatUnit(duration.minutes) + ':' + formatUnit(duration.seconds)
}

const getCurrentDuration = (fromTime: Date, toTime?: Date) => {
  if (!toTime) return dateAsMilliseconds(new Date()) - dateAsMilliseconds(new Date(fromTime))
  return dateAsMilliseconds(new Date(toTime)) - dateAsMilliseconds(new Date(fromTime))
}

interface ITimerProps {
  fromTime?: Date
  toTime?: Date
}

export const Timer = observer<ITimerProps>(props => {
  let { fromTime = new Date(), toTime } = props

  const [duration, setDuration] = useState(getCurrentDuration(fromTime, toTime))
  const [timerDisplay, setTimerDisplay] = useState('')
  const interval = 1000

  // On load set intial duration and run set interval
  useEffect(() => {
    if (toTime) return

    const timer = setInterval(() => {
      setDuration(duration => duration + interval)
    }, interval)

    // Clear timeout if the component is unmounted
    return () => clearTimeout(timer)
  }, [])

  // Watch the duration and update the display
  useEffect(() => {
    setTimerDisplay(getTimerDisplayFromDuration(duration))
  }, [duration])

  return <TimerWrapper>{timerDisplay}</TimerWrapper>
})
