// @ts-nocheck
import { observable, action } from 'mobx'
import { injectable } from 'inversify'
import { GenericListRepository } from '../GenericListRepository'
import { RunbookTypesRepository } from 'app/Repositories/RunbookType/RunbookTypesRepository'
import { dateOrNull } from 'Shared/Helpers/DateService'
import { QueryStringBuilder } from 'Shared/Helpers/QueryStringBuilder'
import { container } from 'app/Config/IOC'
import { IBaseResponse } from 'Gateways/Service/IBaseResponse'
import { IRunbookPM } from 'Shared/Entities/Types/Runbooks/IRunbookPM'
import { IRunbookDTO } from 'Shared/Entities/Types/Runbooks/IRunbookDTO'
import {
  IRunbookFolderPM,
  IRunbookFolderDTO,
  ITeamPM,
  ITeamDTO,
  IEventPM,
  IEventDTO
} from 'Shared/Entities/Types/Runbooks/RunbookRepositoryMetaTypes'
import { TDownloadFormats } from 'Shared/Entities/Types/TDownloadFormats'
import { ISortParams } from 'Components/Organisms'
import { eventManager } from 'event-manager'
import { RunbookBulkDuplicateOptions } from 'Shared/Entities/Types/Runbooks/RunbookBulkDuplicateOptions'
import { RunbookBulkDuplicateDto } from 'Shared/Entities/Types/Runbooks/RunbookBulkDuplicateDto'

interface IFieldOptionCountMap {
  [key: string]: number
}

@injectable()
export class RunbooksRepository extends GenericListRepository {
  constructor() {
    super('meta')
  }

  @observable
  public runbooks: IRunbookPM[] = []

  @observable
  public runbookTypesRepository: RunbookTypesRepository = container.get(RunbookTypesRepository)

  @observable
  public runbookFoldersForFilters: IRunbookFolderPM[] = [] // for runbook folder (project) filter setup

  @observable
  public teamsForFilters: ITeamPM[] = [] // for teams filter setup

  @observable
  public eventsForFilters: IEventPM[] = []

  public fieldOptionCountMapForFilters: IFieldOptionCountMap

  public customFieldIdsForFilters: number[]

  @observable
  public offSet: number = 0

  @observable
  public downloading = false

  private sortParamsMapping: {} = {
    id: 'id',
    name: 'name',
    projectName: 'project_name',
    startScheduled: 'start_scheduled',
    startActual: 'start_actual',
    endPlanned: 'end_planned',
    endActual: 'end_actual',
    updatedAt: 'updated_at'
  }

  @observable
  public name: string = ''

  public isFirstCall: boolean = true

  protected queryStringBuilder: QueryStringBuilder = container.get(QueryStringBuilder)

  public preLoad = (params: { [key: string]: any }): string => {
    const { accountId, sortParams, offSet } = params
    const { accessor, sortDirection } = sortParams
    this.sortParams = sortParams
    this.offSet = offSet

    const queryParams: { [key: string]: any } = {
      accountId,
      sort_by: this.sortParamsMapping[accessor],
      sort_dir: sortDirection,
      offset: this.offSet,
      limit: '20'
    }

    if (this.isFirstCall) {
      queryParams.meta = true
      this.isFirstCall = false
    }
    this.registerBatchRequest()
    return 'runbooks?' + this.queryStringBuilder.getCustomQueryString(queryParams)
  }

  @action
  public postLoad = async (rawData: IBaseResponse) => {
    this.listTotal = rawData.body.meta.total_results_count
    this.filteredListTotal = rawData.body.meta.filtered_results_count

    /**
     Processing meta data for runbooks:
     Note that there will be adjustments/improvements made to meta to only include statistical data (runbook counts in this case)
     and pulling necessary data from respective endpoints (similar to how its done with custom_fields + field_option_count_map to build custom field filters)
     for now, we process folders, teams, events meta data to build filter related data
    */

    if (rawData.body.meta.projects) {
      this.processRunbookFoldersForFilters(rawData.body.meta.projects)
    }

    if (rawData.body.meta.teams) {
      this.processTeamsForFilters(rawData.body.meta.teams)
    }

    if (rawData.body.meta.events) {
      this.processEventsForFilters(rawData.body.meta.events)
    }

    if (rawData.body.meta.field_option_count_map) {
      this.fieldOptionCountMapForFilters = rawData.body.meta.field_option_count_map
    }

    if (rawData.body.meta.field_option_count_map) {
      this.customFieldIdsForFilters = rawData.body.meta.custom_field_ids
    }

    const newRunbooks = await this.getRunbooksFromAPIData(rawData)

    this.runbooks = this.offSet === 0 ? newRunbooks : this.runbooks.concat(newRunbooks)
  }

  public getBulkRunbookDuplicateDto = (runbookIds: number[], options: RunbookBulkDuplicateOptions) => {
    const {
      copyTasks,
      copyTeams,
      copyUsers,
      endScheduled,
      name,
      projectModifyType,
      project,
      projectId,
      shiftTime,
      startPlanned,
      startScheduled,
      suffix,
      timingMode
    } = options

    const optionsDto: RunbookBulkDuplicateDto = {
      options: {
        copy_tasks: copyTasks,
        copy_teams: copyTeams,
        copy_users: copyUsers,
        end_scheduled: endScheduled,
        name,
        projectModifyType,
        shift_time: shiftTime,
        start_planned: startPlanned,
        start_scheduled: startScheduled,
        suffix,
        timing_mode: timingMode
      },
      runbook_ids: runbookIds
    }

    if (projectModifyType === 'choose') {
      optionsDto.options.project_id = projectId
    }

    if (project) {
      optionsDto.options.project = {
        account_id: project.accountId,
        color: project.color,
        id: project.id,
        name: project.name,
        count: project.count
      }
    }

    return optionsDto
  }

  private getRunbooksFromAPIData = async (rawData: IBaseResponse): Promise<IRunbookPM[]> => {
    return rawData.body.runbooks.map((runbook: IRunbookDTO) => {
      return {
        id: runbook.id,
        admin: runbook.admin,
        name: runbook.name,
        projectName: runbook.project_name,
        runbookAdmins: runbook.runbook_admins,
        stage: runbook.stage,
        startScheduled: dateOrNull(runbook.start_scheduled),
        startActual: dateOrNull(runbook.start_actual),
        endPlanned: dateOrNull(runbook.end_planned),
        endActual: dateOrNull(runbook.end_actual),
        durationDisplay: runbook.duration_display,
        completion: runbook.completion + '%',
        tasksCount: runbook.tasks_count,
        streamsCount: runbook.streams_count,
        runType: runbook.run_type,
        eventName: runbook.event_name,
        isTemplate: runbook.is_template,
        templateStatus: runbook.template_status,
        templateNextReview: dateOrNull(runbook.template_next_review),
        runbookTypeName: this.getRunbookTypeName(runbook.runbook_type_id),
        copiesCount: runbook.copies_count,
        updatedAt: dateOrNull(runbook.updated_at),
        status: runbook.status,
        statusMessage: runbook.status_message,
        statusAuthor: runbook.status_author,
        statusUpdatedAt: dateOrNull(runbook.status_updated_at),
        accountId: runbook.account_id,
        fieldValues: runbook.field_values.map(fieldValue => {
          return {
            id: fieldValue.id,
            customFieldId: fieldValue.custom_field_id,
            fieldOptionId: fieldValue.field_option_id,
            remoteDataKeyValue: fieldValue.remote_data_key_value,
            value: fieldValue.value
          }
        }),
        endDiff: runbook.end_diff ? runbook.end_diff.value : null,
        early: runbook.end_diff ? runbook.end_diff.early : null,
        checked: false
      }
    })
  }

  protected downloadApiUrl(accountId: string, format: TDownloadFormats, sortParams: ISortParams) {
    const { accessor, sortDirection } = sortParams

    const queryString = this.queryStringBuilder.getCustomQueryString({
      accountId: accountId,
      sort_by: this.sortParamsMapping[accessor],
      sort_dir: sortDirection,
      fmt: format.toLowerCase()
    })
    return `runbooks?${queryString}`
  }

  @action
  public download = async (accountId: string, format: TDownloadFormats, sortParams: ISortParams) => {
    if (this.downloading) return
    this.downloading = true
    const response = await this.serviceGateway.get(
      this.downloadApiUrl(accountId, format, sortParams),
      {},
      {},
      { responseType: 'arraybuffer' }
    )
    this.downloading = false
    if (!response.success) throw new Error('Invalid download response received')
    return response.body
  }

  @action
  public bulkDuplicate = async (runbookIds: number[], options: RunbookBulkDuplicateOptions) => {
    this.loading = true
    const bulkDuplicateRunbooksDto = this.getBulkRunbookDuplicateDto(runbookIds, options)
    const responseDto = this.serviceGateway.patch('runbooks/bulk_duplicate', bulkDuplicateRunbooksDto)

    this.loading = false
    return responseDto
  }

  private getRunbookTypeName = (runbookTypeId: number) => {
    const { runbookTypes } = this.runbookTypesRepository

    if (runbookTypes && runbookTypes.length > 0) {
      const runbookType = runbookTypes.find(runbookType => runbookType.id === runbookTypeId)
      return runbookType && runbookType.name
    }
    return null
  }

  private processRunbookFoldersForFilters = (runbookFolders: IRunbookFolderDTO[]) => {
    this.runbookFoldersForFilters = runbookFolders
      .map((runbookFolder: IRunbookFolderDTO): IRunbookFolderPM => {
        return {
          accountId: runbookFolder.account_id,
          color: runbookFolder.color,
          id: runbookFolder.id,
          name: runbookFolder.name,
          count: runbookFolder.count
        }
      })
      // sort alphabetically
      .sort((folderA, folderB) => folderA.name.localeCompare(folderB.name))
  }

  private processTeamsForFilters = (teams: ITeamDTO[]) => {
    this.teamsForFilters = teams.map(({ id, color, name, count }: ITeamDTO): ITeamPM => ({ id, color, name, count }))
  }

  private processEventsForFilters = (events: IEventDTO[]) => {
    this.eventsForFilters = events.map(
      ({ id, color, name, count }: IEventDTO): IEventPM => ({ id, color, name, count })
    )
  }

  @action
  public archive = async (runbookIdsToArchive: number[]) => {
    this.loading = true
    const payload = { runbook_ids: runbookIdsToArchive }
    const responseDto = await this.serviceGateway.patch('runbooks/bulk_archive', payload)

    this.loading = false
    return responseDto
  }

  @action
  public clear = () => {
    this.clearInherited()
    this.runbooks = []
  }
}
