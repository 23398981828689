// @ts-nocheck
import { observable, computed, action } from 'mobx'
import { ButtonPresenter, PopoverPresenter } from 'Components/Molecules'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'
import { container } from 'app/Config/IOC'
import { Types } from 'Gateways/Service/Types'
import { CustomFieldsViewPresenter } from 'app/Settings/CustomFields/CustomFieldsPage/CustomFieldsViewPresenter'
import { TableScrollerService } from 'Components/Organisms'
import { IGenericFilter } from 'app/Filters/FilterEntities'
import { FiltersRepository } from 'app/Filters/FiltersRepository'
import { Router } from 'app/Routing/Router'

export class CustomFieldPageHeadingExtraOptionsPresenter {
  private customFieldViewPresenter: CustomFieldsViewPresenter = container.get(CustomFieldsViewPresenter)
  public language: ILanguageService = container.get(Types.ILanguageService)
  private tableScrollerService: TableScrollerService = container.get(TableScrollerService)

  @observable
  public router: Router = container.get(Router)

  @observable
  public filtersRepository: FiltersRepository = container.get(FiltersRepository)

  @observable
  public optionsPopoverPresenter = new PopoverPresenter().withPosition('bottom right')

  @observable
  public optionsOpen: boolean = false

  @computed
  public get archiveFilter(): IGenericFilter {
    return this.filtersRepository.pageFilters.find(filter => filter.key.reference === 'archived')
  }

  @action
  public setOptionsOpen = (open: boolean) => {
    this.optionsOpen = open
  }

  public processArchive = () => {
    this.filtersRepository.toggleFilterValue(this.archiveFilter, 'true')
    this.router.refreshFilteredRoute()
    this.setOptionsOpen(false)
  }

  public processUnArchive = () => {
    this.filtersRepository.toggleFilterValue(this.archiveFilter, '')
    this.router.refreshFilteredRoute()
    this.setOptionsOpen(false)
  }

  public processReload = () => {
    this.customFieldViewPresenter.loadList()
    this.tableScrollerService.doScroll()
    this.setOptionsOpen(false)
  }

  @observable
  public showArchivedButton = new ButtonPresenter(
    this.language.get('common:headingSection:moreOptions:showArchivedButton'),
    this.processArchive
  )
    .withIconName('trash-o')
    .withIconPosition('left')
    .withDisplayStyle('popover')

  @observable
  public showUnArchivedButton = new ButtonPresenter(
    this.language.get('common:headingSection:moreOptions:showUnArchivedButton'),
    this.processUnArchive
  )
    .withIconName('undo')
    .withIconPosition('left')
    .withDisplayStyle('popover')

  @observable
  public reloadButton = new ButtonPresenter(this.language.get('common:reloadButton'), this.processReload)
    .withIconName('cycle')
    .withIconPosition('left')
    .withDisplayStyle('popover')

  @computed
  public get showingArchivedCustomFields(): boolean {
    if (!this.archiveFilter) return false
    if (!this.filtersRepository.selectedFilters) return false

    const archiveSelected = this.filtersRepository.selectedFilters.find(filter => filter.key.reference === 'archived')
    return !!archiveSelected
  }
}
