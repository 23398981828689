import { useRef } from 'react'

export const useScrollIntoView = () => {
  const ref = useRef<HTMLHtmlElement>(null)

  const scrollIntoView = () => {
    if (ref.current && ref.current.scrollIntoView) {
      ref.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  return { ref, scrollIntoView }
}
