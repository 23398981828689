export function getFileNameFromResponse(contentDispositionHeader: string) {
  const filenameRegex = /filename[^;\n]*=(UTF-\d['"]*)?((['"]).*?[.]$\2|[^;\n]*)?/gi
  const match = filenameRegex.exec(contentDispositionHeader)

  if (!match || !match[2]) {
    return ''
  }

  // File names are URI-encoded, so we need to decode them beforehand
  return decodeURIComponent(match[2].replace(/"/g, ''))
}
