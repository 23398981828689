// @ts-nocheck
import { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { colors, breakpoints, px2rem } from 'app/Styles/Variables'
import { withPresenter } from 'app/Config/withPresenter'
import { UserEditHistorySection } from 'app/Access/Users/UsersPage/UsersEdit/UserEditHistorySection'
import { UserEditPresenter } from 'app/Access/Users/UsersPage/UsersEdit/UserEditPresenter'
import { UserEditResendInviteSection } from 'app/Access/Users/UsersPage/UsersEdit/UserEditResendInviteSection'
import { UserEditRolesSection } from 'app/Access/Users/UsersPage/UsersEdit/UserEditRolesSection'
import { UserEditSettingsSection } from 'app/Access/Users/UsersPage/UsersEdit/UserEditSettingsSection'
import { UserEditUnlockSection } from 'app/Access/Users/UsersPage/UsersEdit/UserEditUnlockSection'
import { UserEditUserAppTokensSection } from 'app/Access/Users/UsersPage/UsersEdit/UserEditUserAppTokensSection'
import { UserEditOauthClientSessionsSection } from 'app/Access/Users/UsersPage/UsersEdit/UserEditOauthClientSessionsSection'
import { Accordion, ErrorAlert, Button, DateTime, PhoneNumber, TextInput } from 'Components/Molecules'
import { Heading, Modal, ModalPresenter } from 'Components/Atoms'
import { EditPanelFormWrapper } from 'Components/Templates'
import SanitizedString from 'Shared/Helpers/SanitizedString'

const AccordionWrapper = styled.div`
  border-top: ${px2rem(1)} solid ${colors.primaryGreyBackgroundColor};
`

const ConfirmationWrapper = styled.div`
  padding: ${px2rem(20)};
`

const ConfirmationTextWrapper = styled.p``

const FormWrapper = styled.div`
  margin-bottom: ${px2rem(16)};
  padding-bottom: ${px2rem(16)};
`

const AlertWrapper = styled.div``

const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  > div {
    flex: 1;
  }
  @media ${breakpoints.gtMd} {
    > div {
      flex: 0 1 auto;
      width: 100%;
    }
  }
  @media screen and (max-width: 459px) {
    > div {
      flex: 0 1 auto;
      width: 100%;
    }
  }
`

const LeftContainer = styled.div`
  width: 100%;
  margin-right: ${px2rem(8)};
`

const RightContainer = styled.div`
  width: 100%;
  margin-left: ${px2rem(8)};
`

const ButtonContainer = styled.div`
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const InlineInputValidator = styled.div`
  font-size: 13px;
  position: absolute;
  top: ${px2rem(-12)};
  right: 0px;
  ${(props: { hasError: boolean }) => (props.hasError ? 'color: ' + colors.error + ';' : '')}
`
const InlinePhoneValidator = styled(InlineInputValidator)`
  color: ${colors.error};
`

const DateTimeStyled = styled(DateTime)`
  margin-top: ${px2rem(24)};
`

interface IUserEditComponentProps {
  presenter: UserEditPresenter
}

const UserEditSection = observer<IUserEditComponentProps>(({ presenter }) => {
  const {
    firstNamePresenter,
    lastNamePresenter,
    nickNamePresenter,
    mobileNumberPresenter,
    emailPresenter,
    memorableWordPresenter,
    mode,
    archiveTooltip,
    close,
    save,
    reset,
    archiveButtonCallback,
    confirmModalPresenter,
    editingCurrentUser,
    panelHeading,
    includeSSOId,
    uniqueIdPresenter,
    isSubmitted,
    errorAlertPresenter,
    triggerScroll,
    setTriggerScroll,
    userAppTokensPresenter,
    userOauthClientSessionsPresenter,
    userRolesPresenter,
    invitePresenter,
    unlockUserPresenter,
    settingsPresenter,
    rolesResetOpenState,
    cancelResetButtonPresenter,
    continueResetButtonPresenter,
    canArchiveUser,
    cancelArchiveButtonPresenter,
    continueArchiveButtonPresenter,
    archiveOpenState,
    archiveConfirmName,
    userHistoryPresenter,
    memorableWordMessage,
    checkFormIsValid,
    additionalInfoPresenter,
    jobTitlePresenter,
    bioPresenter,
    statisticsPresenter,
    loginCountPresenter,
    createdAtPresenter,
    invitationSentAtPresenter,
    showInvitationSentAt,
    invitationAcceptedAtPresenter,
    showInvitationAcceptedAt,
    latestLoginPresenter,
    canViewAppToken,
    showOauthClientSessions,
    canUnlockUser,
    canResendInvite,
    toggleMemorableWordPresenter,
    editPanelSaving,
    setArchiveState,
    language,
    setRolesResetOpenState,
    countryCodeValidator,
    phoneNumberValidator
  } = presenter

  const [isWordFocused, setWordFocused] = useState(false)
  const [customValidatorHasError, setCustomValidatorHasError] = useState(false)

  const focusWordHandler = () => {
    setWordFocused(true)
  }

  const blurWordHandler = () => {
    setWordFocused(false)
  }

  useEffect(() => {
    setCustomValidatorHasError(!memorableWordPresenter.isValid && memorableWordPresenter.isDirty)
  }, [memorableWordPresenter.isDirty, memorableWordPresenter.isValid])

  const rolesResetPresenter = new ModalPresenter()
  const archivePresenter = new ModalPresenter()

  useEffect(() => {
    if (rolesResetOpenState === true) {
      rolesResetPresenter.onOpen()
    }
  }, [rolesResetOpenState])

  useEffect(() => {
    if (archiveOpenState === true) {
      archivePresenter.onOpen()
    }
  }, [archiveOpenState])

  //  useEffect(() => {
  //   if (mobileNumberPresenter.getNumber !== '') {
  //     toggleMemorableWordPresenter(memorableWordPresenter.isDirty)
  //   } else {
  //     toggleMemorableWordPresenter(memorableWordPresenter.isDirty)
  //   }
  // }, [mobileNumberPresenter.getNumber])

  const customValidator = (isWordFocused || customValidatorHasError) && (
    <InlineInputValidator hasError={customValidatorHasError}>{memorableWordMessage}</InlineInputValidator>
  )
  const countryCodeValidationRender = <InlinePhoneValidator>{countryCodeValidator}</InlinePhoneValidator>
  const phoneNumberValidationRender = <InlinePhoneValidator>{phoneNumberValidator}</InlinePhoneValidator>
  const validationComponents = [countryCodeValidationRender, phoneNumberValidationRender]

  return (
    <EditPanelFormWrapper
      heading={panelHeading}
      mode={mode}
      showArchive={canArchiveUser}
      archiveTooltip={archiveTooltip}
      closeCallback={close}
      saveCallback={save}
      resetCallback={reset}
      archiveCallback={archiveButtonCallback}
      editPanelSaving={editPanelSaving}
      confirmModalPresenter={confirmModalPresenter}
      triggerScroll={triggerScroll}
      setTriggerScroll={setTriggerScroll}
    >
      <FormWrapper>
        <form>
          {isSubmitted && !checkFormIsValid && (
            <AlertWrapper>
              <ErrorAlert presenter={errorAlertPresenter} />
            </AlertWrapper>
          )}
          <FormRow>
            <LeftContainer>
              <TextInput presenter={firstNamePresenter} formTopInputElement />
            </LeftContainer>
            <RightContainer>
              <TextInput presenter={lastNamePresenter} formTopInputElement />
            </RightContainer>
          </FormRow>
          <TextInput presenter={nickNamePresenter} />
          {includeSSOId && <TextInput presenter={uniqueIdPresenter} />}
          <PhoneNumber presenter={mobileNumberPresenter} validationComponents={validationComponents} />
          <TextInput presenter={emailPresenter} />
          {editingCurrentUser && mobileNumberPresenter.getNumber !== '' && (
            <TextInput
              presenter={memorableWordPresenter}
              onFocus={focusWordHandler}
              onBlur={blurWordHandler}
              customSuffixRender={customValidator}
            />
          )}
        </form>
        {canUnlockUser && <UserEditUnlockSection presenter={unlockUserPresenter} />}
        {canResendInvite && <UserEditResendInviteSection presenter={invitePresenter} />}
      </FormWrapper>
      <AccordionWrapper>
        <UserEditRolesSection presenter={userRolesPresenter} language={language} />
        <UserEditHistorySection presenter={userHistoryPresenter} />
        <UserEditSettingsSection presenter={settingsPresenter} />
        <Accordion presenter={additionalInfoPresenter}>
          <TextInput presenter={jobTitlePresenter} />
          <TextInput presenter={bioPresenter} />
        </Accordion>
        <Accordion presenter={statisticsPresenter}>
          <TextInput presenter={loginCountPresenter} isShrunk={true} />
          <DateTimeStyled presenter={createdAtPresenter} />
          {showInvitationSentAt && <DateTimeStyled presenter={invitationSentAtPresenter} />}
          {showInvitationAcceptedAt && <DateTimeStyled presenter={invitationAcceptedAtPresenter} />}
          <DateTimeStyled presenter={latestLoginPresenter} />
        </Accordion>
        {canViewAppToken && <UserEditUserAppTokensSection presenter={userAppTokensPresenter} />}
        {showOauthClientSessions && <UserEditOauthClientSessionsSection presenter={userOauthClientSessionsPresenter} />}
      </AccordionWrapper>
      <Modal presenter={rolesResetPresenter.withOnCloseExtra(() => setRolesResetOpenState(false))}>
        <ConfirmationWrapper>
          <Heading>{language.get('users:edit:roles:confirmation')}</Heading>
          <p>{language.get('users:edit:roles:resetRoles')}</p>
          <ButtonContainer>
            <Button presenter={cancelResetButtonPresenter} />
            <Button presenter={continueResetButtonPresenter} />
          </ButtonContainer>
        </ConfirmationWrapper>
      </Modal>
      <Modal presenter={archivePresenter.withOnCloseExtra(() => setArchiveState(false))}>
        <ConfirmationWrapper>
          <Heading>{language.get('users:edit:archive:confirmationTitle')}</Heading>
          <ConfirmationTextWrapper>
            <SanitizedString
              input={language.get('users:edit:archive:confirmation', {
                archiveConfirmName
              })}
            />
          </ConfirmationTextWrapper>
          <ButtonContainer>
            <Button presenter={cancelArchiveButtonPresenter} />
            <Button presenter={continueArchiveButtonPresenter} />
          </ButtonContainer>
        </ConfirmationWrapper>
      </Modal>
    </EditPanelFormWrapper>
  )
})

export default withPresenter(UserEditPresenter)(UserEditSection)
