// @ts-nocheck
import { observable, computed, action } from 'mobx'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'
import { container } from 'app/Config/IOC'
import { Types } from 'Gateways/Service/Types'
import { GenericFormPresenter } from '@logicroom/validator'
import { AccountVisibilityPresenter } from 'Components/Organisms'
import {
  ErrorAlertPresenter,
  TextInputPresenter,
  CheckboxPresenter,
  SelectPresenter,
  TSelectOptionTypes
} from 'Components/Molecules'
import { IApprovalFlowDTO } from 'app/Shared/Entities/Types/ApprovalFlow/IApprovalFlowDTO'

export interface IFormViewModel {
  name: string
  description: string
  account?: string
  approvalFlowId: number
  availableApprovalFlows: IApprovalFlowDTO[]
  disabled: boolean
  disabledName: boolean
  disabledCheckboxDisabled: boolean
  disabledGlobal: boolean
  disabledDynamic: boolean
  disabledAccount: boolean
  dynamic: boolean
  enableRto: boolean
  restrictCreateToTemplates: boolean
  incident: boolean
}

export class RunbookTypeFormPresenter {
  private language: ILanguageService = container.get(Types.ILanguageService)

  @observable
  public viewModel: IFormViewModel = {
    name: '',
    description: '',
    disabled: false,
    disabledName: false,
    disabledCheckboxDisabled: false,
    disabledGlobal: false,
    disabledAccount: false,
    dynamic: false,
    incident: false,
    enableRto: false,
    restrictCreateToTemplates: false,
    approvalFlowId: null,
    availableApprovalFlows: []
  }

  @observable
  public visibilityAccounts: string[] = []

  @observable
  public approvalFlows: TSelectOptionTypes[] = []

  @observable
  public unavailableNames: string[] = []

  @observable
  public errorAlertPresenter: ErrorAlertPresenter = new ErrorAlertPresenter()

  @observable
  public submitted: boolean = false

  private dirtyCallback: () => void

  @observable
  public namePresenter: TextInputPresenter = new TextInputPresenter()
    .withLabel(this.language.get('runbookTypes:fields:name:label'))
    .isRequiredTrimWhiteSpace(this.language.get('runbookTypes:fields:name:errors:required'))
    .withMiddleware((value: string) => {
      this.viewModel.name = value

      if (this.dirtyCallback) {
        this.dirtyCallback()
      }

      return value
    })

  @observable
  public approvalFlowPresenter: SelectPresenter = new SelectPresenter(this.language)
    .withLabel(this.language.get('runbookTypes:fields:approvalFlow:label'))
    .withMiddleware((value: any) => {
      this.viewModel.approvalFlowId = value
      if (this.dirtyCallback) {
        this.dirtyCallback()
      }

      return value
    })

  @observable
  public descriptionPresenter: TextInputPresenter = new TextInputPresenter()
    .withLabel(this.language.get('runbookTypes:fields:description:label'))
    .isMultiline()
    .ofMaxLength(55)
    .isRequiredTrimWhiteSpace(this.language.get('runbookTypes:fields:description:errors:required'))
    .withMiddleware((value: string) => {
      this.viewModel.description = value

      if (this.dirtyCallback) {
        this.dirtyCallback()
      }

      return value
    })

  @observable
  public visibilityPresenter: AccountVisibilityPresenter = new AccountVisibilityPresenter()

  @observable
  public disabledCheckboxPresenter: CheckboxPresenter = new CheckboxPresenter(
    this.language.get('runbookTypes:fields:additionalSettings:checkboxLabels:disabled:label')
  ).withMiddleware((value: boolean) => {
    this.viewModel.disabled = value

    if (this.dirtyCallback) {
      this.dirtyCallback()
    }
    return value
  })

  @observable
  public editForm: GenericFormPresenter = new GenericFormPresenter()
    .addFormInput(this.namePresenter)
    .addFormInput(this.descriptionPresenter)

  @observable
  public dynamicCheckboxPresenter: CheckboxPresenter = new CheckboxPresenter(
    this.language.get('runbookTypes:fields:additionalSettings:checkboxLabels:dynamic:label')
  )
    .withHelpText(this.language.get('runbookTypes:fields:additionalSettings:checkboxLabels:dynamic:helpText'))
    .withMiddleware((value: boolean) => {
      this.viewModel.dynamic = value

      if (this.dirtyCallback) {
        this.dirtyCallback()
      }

      return value
    })

  @observable
  public enableRtoCheckboxPresenter: CheckboxPresenter = new CheckboxPresenter(
    this.language.get('runbookTypes:fields:additionalSettings:checkboxLabels:enableRto:label')
  )
    .withHelpText(this.language.get('runbookTypes:fields:additionalSettings:checkboxLabels:enableRto:helpText'))
    .withMiddleware((value: boolean) => {
      this.viewModel.enableRto = value

      if (this.dirtyCallback) {
        this.dirtyCallback()
      }

      return value
    })

  @observable
  public incidentCheckboxPresenter: CheckboxPresenter = new CheckboxPresenter(
    this.language.get('runbookTypes:fields:additionalSettings:checkboxLabels:incident:label')
  )
    .withHelpText(this.language.get('runbookTypes:fields:additionalSettings:checkboxLabels:incident:helpText'))
    .withMiddleware((value: boolean) => {
      this.viewModel.incident = value

      if (this.dirtyCallback) {
        this.dirtyCallback()
      }

      return value
    })

  @observable
  public restrictToTemplateCheckboxPresenter: CheckboxPresenter = new CheckboxPresenter(
    this.language.get('runbookTypes:fields:additionalSettings:checkboxLabels:restrictCreateToTemplates:label')
  )
    .withHelpText(
      this.language.get('runbookTypes:fields:additionalSettings:checkboxLabels:restrictCreateToTemplates:helpText')
    )
    .withMiddleware((value: boolean) => {
      this.viewModel.restrictCreateToTemplates = value

      if (this.dirtyCallback) {
        this.dirtyCallback()
      }

      return value
    })

  @action
  public processSubmission = (): { result: boolean; viewModel: IFormViewModel } => {
    this.submitted = true
    this.editForm.serverErrors = []
    this.viewModel.account =
      this.visibilityPresenter.viewModel.type === 'account' ? this.visibilityPresenter.viewModel.account : null
    this.dynamicCheckboxPresenter.value = this.viewModel.dynamic
    this.enableRtoCheckboxPresenter.value = this.viewModel.enableRto
    this.restrictToTemplateCheckboxPresenter.value = this.viewModel.restrictCreateToTemplates
    this.incidentCheckboxPresenter.value = this.viewModel.incident
    if (this.approvalFlowPresenter.isDirty == true && this.viewModel.approvalFlowId == undefined) {
      this.approvalFlowPresenter.value = this.viewModel.approvalFlowId = null
    } else {
      this.approvalFlowPresenter.value = this.viewModel.approvalFlowId
    }

    if (!this.formIsValid) {
      this.namePresenter.isDirty = true
      this.descriptionPresenter.isDirty = true
      if (this.editForm.errorMessages.length > 0) {
        this.errorAlertPresenter.setErrorMessages([this.editForm.errorMessages[0]])
      }
      return {
        result: false,
        viewModel: this.viewModel
      }
    }

    return {
      result: true,
      viewModel: this.viewModel
    }
  }

  @computed
  public get formIsValid(): boolean {
    return this.editForm.isValid
  }

  @computed
  public get isSnippet(): boolean {
    return this.viewModel.name === 'Snippet'
  }

  @computed
  public get approvalFlowData(): TSelectOptionTypes[] {
    let flows = this.viewModel.availableApprovalFlows
    return flows.map(flow => {
      return { label: flow.name, value: flow.id }
    })
  }

  @action
  private processViewModel = () => {
    this.namePresenter.value = this.viewModel.name
    this.descriptionPresenter.value = this.viewModel.description
    this.disabledCheckboxPresenter.value = this.viewModel.disabled
    this.approvalFlowPresenter.withOptions(this.approvalFlowData)
    this.approvalFlowPresenter.value = this.viewModel.approvalFlowId
    this.visibilityPresenter.withViewModel({
      type: this.viewModel.account ? 'account' : 'global',
      account: this.viewModel.account ? this.viewModel.account : null,
      disabledGlobal: this.viewModel.disabledGlobal,
      disabledAccount: this.viewModel.disabledAccount
    })
    this.namePresenter.disabled = this.viewModel.disabledName
    this.descriptionPresenter.disabled = this.isSnippet
    this.approvalFlowPresenter.disabled = this.isSnippet

    // Dynamic should always be on for incident runbooks...
    this.dynamicCheckboxPresenter.value = this.viewModel.incident || this.viewModel.dynamic
    // ...and not editable
    this.dynamicCheckboxPresenter.disabled = this.viewModel.incident || this.viewModel.disabledDynamic || this.isSnippet

    this.incidentCheckboxPresenter.value = this.viewModel.incident
    this.enableRtoCheckboxPresenter.value = this.viewModel.enableRto
    this.restrictToTemplateCheckboxPresenter.value = this.viewModel.restrictCreateToTemplates
    this.restrictToTemplateCheckboxPresenter.disabled = this.viewModel.incident || this.isSnippet

    // Changing an existing runbook type (and runbooks) to incident
    // will lead to undefined behaviour, because the IncidentCreator
    // event handler is triggered only after a runbook is created
    this.incidentCheckboxPresenter.disabled = true
    this.disabledCheckboxPresenter.disabled = this.viewModel.disabledCheckboxDisabled || this.isSnippet
  }

  @action
  public withDirtyCallback = (dirtyCallback: () => void) => {
    this.dirtyCallback = dirtyCallback
    this.visibilityPresenter.withDirtyCallback(dirtyCallback)
    return this
  }

  @action
  public withVisibilityAccounts = (visibilityAccounts: string[]) => {
    this.visibilityAccounts = visibilityAccounts
    this.visibilityPresenter.withAccounts(this.visibilityAccounts)
    return this
  }

  @action
  public withUnavailableNames = (unavailableNames: string[]) => {
    this.unavailableNames = unavailableNames
    this.namePresenter.isUnique(this.unavailableNames, this.language.get('runbookTypes:fields:name:errors:notUnique'))
    return this
  }

  @action
  public withViewModel = (viewModel: IFormViewModel) => {
    this.viewModel = viewModel
    this.processViewModel()
    return this
  }

  @action
  public refresh = () => {
    this.reset()
    this.processViewModel()
  }

  @action
  public reset = () => {
    this.submitted = false
    this.namePresenter.reset()
    this.descriptionPresenter.reset()
    this.visibilityPresenter.reset()
    this.dynamicCheckboxPresenter.reset()
    this.enableRtoCheckboxPresenter.reset()
    this.restrictToTemplateCheckboxPresenter.reset()
    this.incidentCheckboxPresenter.reset()
    this.disabledCheckboxPresenter.reset()
    this.approvalFlowPresenter.reset()
    this.editForm.reset()
  }
}
