// @ts-nocheck
import { SvgIcon } from '../SvgIcon/'
import styled from 'styled-components'
import { px2rem, colors } from 'app/Styles/Variables'
import { TDisplaySvgIconTypes } from './TDisplaySvgIconTypes'
import { TDisplaySvgIcons } from './TDisplaySvgIcons'

interface IDisplaySvgIconWrapperProps {
  color: string
  type: TDisplaySvgIconTypes
  size: number
  borderWidth: number
}

const DisplaySvgIconWrapper = styled.div<IDisplaySvgIconWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${props => px2rem(props.size)};
  height: ${props => px2rem(props.size)};
  box-sizing: border-box;
  border-radius: 8px;
  background-color: ${props => (props.type === 'normal' ? props.color : colors.white)};
  border-width: ${props => px2rem(props.borderWidth)};
  border-color: ${props => props.color};
  border-style: ${props => (props.type === 'draft' ? 'dashed' : 'solid')};
`

interface IDisplaySvgIconProps {
  name?: TDisplaySvgIcons
  color?: string
  size?: number
  type?: TDisplaySvgIconTypes
  className?: string
}

export const DisplaySvgIcon = (props: IDisplaySvgIconProps) => {
  const { name, color, size = 40, type = 'normal', className } = props
  const iconName = name || 'getting-started'
  const iconColor = color || colors.primary
  const svgIconSize = Math.round(size * 5) / 10
  const borderWidth = Math.round(size * 0.075)

  const svgColor = type === 'normal' ? colors.white : iconColor
  const path = `svg/sprite.svg#icon-${iconName}`
  const iePath = `svg/icons/${iconName}.svg`

  return (
    <DisplaySvgIconWrapper size={size} type={type} borderWidth={borderWidth} color={iconColor} className={className}>
      <SvgIcon
        path={path}
        iePath={iePath}
        width={svgIconSize}
        height={svgIconSize}
        color={svgColor}
        label={`${iconName} svg icon`}
      />
    </DisplaySvgIconWrapper>
  )
}
