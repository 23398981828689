// @ts-nocheck
import { useState } from 'react'
import styled, { css } from 'styled-components'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import { v4 } from 'uuid'
import { MultiSelectPresenter } from './MultiSelectPresenter'
import { MultiSelectStyle } from 'app/Styles/Common'
import { DropdownIndicator } from './DropdownIndicator'
import { Option } from './Option'
import { MultiSelectItem } from './MultiSelectItem'
import { sort } from 'Components/Organisms'
import { HelpText, TextInput, TextInputPresenter } from 'Components/Molecules'
import { Icon } from 'Components/Atoms'
import { colors, fontSizes, px2rem } from 'app/Styles/Variables'
import { observer } from 'mobx-react-lite'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'

interface IWrapperProps {
  color: string
  inline: boolean
  customSize: string
}

const WithSelectWrapper = styled.div`
  position: relative;
  overflow: visible;
  ${(props: IWrapperProps) =>
    !props.inline &&
    css`
      width: 100%;
      border-bottom: ${px2rem(1)} dashed ${props.color};
      padding-top: ${px2rem(12)};
      margin-bottom: ${px2rem(16)};
    `}

  ${(props: IWrapperProps) => props.customSize && `width: ${props.customSize};`}
`

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: ${px2rem(24)};
  min-height: ${px2rem(32)};
  max-height: ${px2rem(32)};
`

const IconWrapper = styled.div`
  position: absolute;
  left: 0;
`

interface ILabelProps {
  color: string
  isShrunk: boolean
  hasPrefix: boolean
}

const Label = styled.label`
  transition: all 0.15s cubic-bezier(0.35, 0, 0.25, 1);
  white-space: nowrap;
  position: absolute;
  z-index: -2;

  color: ${(props: ILabelProps) => props.color};

  font-size: ${(props: ILabelProps) => (props.isShrunk ? fontSizes.sm : fontSizes.m)};
  font-weight: ${(props: ILabelProps) => (props.isShrunk ? '500' : '400')};

  top: ${(props: ILabelProps) => (props.isShrunk ? 0 : `${px2rem(18)}`)};
  left: ${(props: ILabelProps) => (props.hasPrefix && !props.isShrunk ? `${px2rem(32)}` : '0')};
`

interface IGenericLabelProps {
  color: string
}

const GenericLabel = styled.label`
  font-size: ${fontSizes.sm};
  color: ${(props: IGenericLabelProps) => props.color};
  top: 0;
  left: 0;
`

interface IShowAllButtonProps {
  color: string
  hoverColor: string
}

const ShowAllButton = styled.a`
  display: block;
  font-size: 0.75em;
  text-decoration: underline;
  width: 100%;
  text-align: center;
  cursor: pointer;
  color: ${(props: IShowAllButtonProps) => props.color};
  &:hover {
    color: ${(props: IShowAllButtonProps) => props.hoverColor};
  }
`

function createAdditionalOption(
  allowOptionAddition: boolean,
  addToOptions: (value: string) => void,
  language: ILanguageService
) {
  let additionalOption

  if (allowOptionAddition && allowOptionAddition === true) {
    const textInput = new TextInputPresenter().withIconPrefix('add-to-list')
    textInput.label = language.get('components:molecules:multiSelectDefaults:addNewOption')
    additionalOption = <TextInput presenter={textInput} handleEnter={addToOptions} />
  }
  return additionalOption
}

function sortOptions(options: any[], labelProperty: string, max?: number): any[] {
  const sorted = sort(options, labelProperty, 'string', 'asc')
  if (max === undefined) return sorted
  return sorted.slice(0, max)
}

interface IMultiSelectProps {
  presenter: MultiSelectPresenter
  customSize?: string
  getFocused?: (focused: boolean) => void
  autoFocusOnMount?: boolean
}

export const MultiSelect = observer<IMultiSelectProps>(({ autoFocusOnMount, customSize, getFocused, presenter }) => {
  // export const MultiSelect = ({ autoFocusOnMount, customSize, getFocused, presenter }: IMultiSelectProps) => {
  const {
    value,
    options,
    label,
    selectedOptions,
    deselectOption,
    selectOption,
    allowOptionAddition,
    isValid,
    isDirty,
    required,
    helpText,
    placeholder,
    iconPrefix,
    loading,
    inlineComponent,
    refresh,
    availableOptions,
    asyncOptionsGetter,
    minSearchCharacters,
    handleAsyncOptionsRequest,
    labelProperty,
    valueProperty,
    itemComponent,
    hiddenOptionAddedCallback,
    showAllLimit,
    disabled,
    showSearchMessage,
    showDropdownIndicator,
    language
  } = presenter

  const [isFocused, setFocused] = useState(false)
  const [id] = useState(v4())
  const [inputString, setInputString] = useState('')
  const [showAll, setShowAll] = useState(false)

  const customSelectOption = (option: any) => {
    selectOption(option)
    if (!showAll && selectedOptions.length > showAllLimit) hiddenOptionAddedCallback(option[labelProperty])
  }

  const addToOptions = (value: string) => {
    presenter.addNewOptionWithValue(value)
  }

  const blurHandler = () => {
    setFocused(!isFocused)
    getFocused && getFocused(!isFocused)
  }

  const focusHandler = () => {
    setFocused(!isFocused)
    getFocused && getFocused(!isFocused)
  }

  const getSelectedWrapperForOption = (option: any) => {
    const selected = presenter.optionSelected(option)
    const props = {
      selected,
      option,
      deselectOption,
      selectOption,
      labelProperty,
      valueProperty,
      disabled
    }
    if (itemComponent) return itemComponent(props)
    return MultiSelectItem(props)
  }

  let additionalOption = createAdditionalOption(allowOptionAddition, addToOptions, language)
  let color = isFocused ? colors.primary : colors.textLighter
  let lineColor = isFocused ? colors.primary : colors.primaryGreyHoverBackgroundColor

  if (!isValid && isDirty) {
    color = colors.error
  }

  const isShrunk = !!(isFocused || value !== undefined || selectedOptions.length > 0) || !!autoFocusOnMount
  const hasPrefix = !!iconPrefix
  let labelRender = ''
  if (label.length > 0) {
    labelRender = required && label ? `${label} *` : label
    if (selectedOptions.length > 0) labelRender += ` (${selectedOptions.length})`
  }

  const ReactSelect = props => (asyncOptionsGetter ? <AsyncSelect {...props} /> : <Select {...props} />)

  const helpTextRender = helpText ? <HelpText text={helpText} /> : null

  const LoadingIndicator = props => {
    return <Icon name="spinner" />
  }

  const noOptionsMessage = () => {
    if (showSearchMessage) {
      if (asyncOptionsGetter) {
        if (inputString.length < minSearchCharacters) {
          return language.get('components:molecules:multiSelectDefaults:underMinLength', {
            interpolation: { escapeValue: true },
            minSearchCharacters
          })
        } else {
          return language.get('components:molecules:multiSelectDefaults:noMatchingResults')
        }
      } else {
        return language.get('components:molecules:multiSelectDefaults:noOptions')
      }
    }
    return null
  }

  return (
    <div>
      {options && options.length < 10 && !asyncOptionsGetter ? (
        <>
          {
            <GenericLabel color={color} htmlFor={id}>
              {label}
            </GenericLabel>
          }
          {sortOptions(options, labelProperty).map(option => getSelectedWrapperForOption(option))}
        </>
      ) : (
        <WithSelectWrapper color={lineColor} inline={inlineComponent} customSize={customSize}>
          <Label color={color} htmlFor={id} isShrunk={isShrunk} hasPrefix={hasPrefix}>
            {labelRender}
          </Label>
          {sortOptions(selectedOptions, labelProperty, showAll ? undefined : showAllLimit).map(option =>
            getSelectedWrapperForOption(option)
          )}
          {selectedOptions.length > showAllLimit && !showAll && (
            <ShowAllButton
              onClick={() => {
                setShowAll(true)
              }}
              color={colors.textLighter}
              hoverColor={colors.primary}
            >
              Show All
            </ShowAllButton>
          )}
          {(!disabled || options.length === 0) && (
            <InputWrapper>
              {iconPrefix && (
                <IconWrapper>
                  <Icon name={iconPrefix} color={color} />
                </IconWrapper>
              )}
              {ReactSelect({
                key: `force_refresh_${refresh}`,
                components: {
                  DropdownIndicator: () => (showDropdownIndicator ? DropdownIndicator({ color, loading }) : null),
                  Option: props =>
                    Option({
                      ...props,
                      keyword: inputString,
                      highlightKeyword: true
                    }),
                  LoadingIndicator
                },
                options: availableOptions,
                loadOptions: handleAsyncOptionsRequest,
                onInputChange: value => setInputString(value),
                inputValue: inputString,
                placeholder: isShrunk ? placeholder : '',
                noOptionsMessage: noOptionsMessage,
                onChange: customSelectOption,
                onBlur: blurHandler,
                onFocus: focusHandler,
                getOptionLabel: option => `${option[labelProperty]}`,
                value: options.find(option => option[valueProperty] === value),
                styles: MultiSelectStyle,
                autoFocus: autoFocusOnMount,
                isDisabled: disabled,
                iconPrefix,
                multiSelect: true
              })}
              {helpTextRender}
            </InputWrapper>
          )}
        </WithSelectWrapper>
      )}
      {additionalOption}
    </div>
  )
})

export const MultiSelectRef = (props: IMultiSelectProps): ReactElement<IMultiSelectProps> => <MultiSelect {...props} />
