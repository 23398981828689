import { unescape } from 'lodash'
import { useInfiniteQuery } from 'react-query'

import { MyTask, RunbookTeam, User } from './types/my-tasks'
import { apiClient, ApiError } from 'main/services/api/api-client'
import { QueryKeys } from 'main/services/queries/query-keys'

type ServerParams = {
  sort_by: string
  sort_dir: string
  limit: number
  offset: number
}

export type MyTasksResponseMeta = {
  filtered_results_count: number
  total_results_count: number
  teams: RunbookTeam[] | undefined
  users: User[] | undefined
}

export type MyTasksResponse = {
  meta: MyTasksResponseMeta
  tasks: MyTask[]
}

type MyTasksFilter = 'run_type' | 'active' | 'q'

const DEFAULT_LIMIT = 40

const DEFAULTS = {
  sort_by: 'stage',
  sort_dir: 'desc',
  limit: DEFAULT_LIMIT
}

export function useMyTasks({ params = {} }: { params?: Partial<Record<MyTasksFilter, any> & ServerParams> }) {
  const { q, ...restParams } = params
  const serverParams = { ...DEFAULTS, ...restParams, query: q }

  const getMyTasks = async (offset: number = 0) => {
    const { data } = await apiClient.get<MyTasksResponse>({
      url: 'my-tasks',
      params: { ...serverParams, offset }
    })

    data.tasks = data.tasks.map(task => {
      return {
        ...task,
        name: unescape(task.name),
        runbook_name: unescape(task.runbook_name)
      }
    })

    return data
  }

  return useInfiniteQuery<MyTasksResponse, ApiError>(
    [QueryKeys.MyTasks, serverParams],
    ({ pageParam = 0 }) => getMyTasks(pageParam),
    {
      getNextPageParam: (_lastGroup, allGroups) => {
        const totalResultCount = allGroups[0]?.meta?.filtered_results_count
        const lastOffset = allGroups.length * serverParams.limit

        return totalResultCount > serverParams.limit && totalResultCount > lastOffset
          ? lastOffset + serverParams.limit
          : undefined
      }
    }
  )
}
