import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useSearchParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'

import { Box, Button, colors, Heading, IconButton, Select, TextInput } from '@cutover/react-ui'
import { CustomFieldOptionType } from './integration-form-setting-fields'
import { NewCustomFieldModal } from '../../custom-fields/new-custom-field-modal'
import { useLanguage } from 'main/services/hooks'
import { CustomField } from 'main/services/queries/types'

export type MappingRowProps = {
  options: CustomFieldOptionType[]
  mappingRowKey: string
}

export type CustomFieldMapping = {
  responsePath: string
  targetCustomField: string | number
}

export type ResponseMappingProps = {
  customFieldOptions: CustomFieldOptionType[]
  value: { [rowKey: string]: CustomFieldMapping }
  onChange: (value: { [rowKey: string]: CustomFieldMapping }) => void
}

export const ResponseMapping = ({ customFieldOptions, onChange, value }: ResponseMappingProps) => {
  const [rows, setRows] = useState<{ [rowKey: string]: CustomFieldMapping }>({})
  const { t } = useLanguage('integrationSettings', { keyPrefix: 'responseMapping' })
  const [searchParams] = useSearchParams()
  const integrationActionItemId = searchParams.get('integration_action_item_id')
  const { getValues } = useFormContext()
  const visibility = getValues('additional_settings.visibility')
  const [newCustomFieldModalOpen, setNewCustomFieldModalOpen] = useState<boolean>(false)
  const addRow = () => {
    const newRows = Object.assign({}, rows)
    const key = uuidv4()
    newRows[key] = { responsePath: '', targetCustomField: '' }
    setRows(newRows)
  }

  const removeRow = (key: string) => {
    const newRows = Object.assign({}, rows)
    delete newRows[key]
    setRows(newRows)
    onChange(newRows)
  }

  const updateResponseFieldValue = (key: string, value: string) => {
    rows[key].responsePath = value
  }

  const updateTargetCustomFieldValue = (key: string, value: string | number) => {
    const newRows = Object.assign({}, rows)
    newRows[key].targetCustomField = value
    setRows(newRows)
    onChange(newRows)
  }

  const updateFromNewCustomField = (newCustomField: CustomField | undefined) => {
    if (newCustomField) {
      customFieldOptions.push({ label: newCustomField.name, value: newCustomField.id })
    }
  }

  const handleChange = () => {
    onChange(rows)
  }

  const onCreateNewCustomField = () => {
    setNewCustomFieldModalOpen(true)
  }

  useEffect(() => {
    if (value) {
      setRows(value)
    } else {
      setRows({})
    }
  }, [value])

  const MappingRow = ({ options, mappingRowKey }: MappingRowProps) => {
    function filterSelectedOptions() {
      const customFieldIdsToRemove: number[] = []
      value &&
        Object.entries(value).map(([k, v]) => {
          if (k !== mappingRowKey) {
            customFieldIdsToRemove.push(v.targetCustomField as number)
          }
        })
      return options.filter(option => !customFieldIdsToRemove.includes(option.value as number))
    }

    return (
      <Box
        align="center"
        direction="row"
        gap="8px"
        css={`
          > div {
            flex: 1;
          }
        `}
      >
        <TextInput
          name={'response-field'}
          onChange={event => {
            updateResponseFieldValue(mappingRowKey, event.target.value)
          }}
          onBlur={handleChange}
          defaultValue={rows[mappingRowKey].responsePath}
        />

        <Select
          a11yTitle="Target Custom Field"
          onChange={value => {
            value && updateTargetCustomFieldValue(mappingRowKey, value)
          }}
          value={rows[mappingRowKey].targetCustomField}
          options={filterSelectedOptions()}
        />

        <IconButton
          icon={'close'}
          label="Remove row"
          size="medium"
          onClick={() => removeRow(mappingRowKey)}
          css={`
            flex: 0;
          `}
        />
      </Box>
    )
  }

  return (
    <Box>
      {integrationActionItemId && (
        <NewCustomFieldModal
          isOpen={newCustomFieldModalOpen}
          setOpen={setNewCustomFieldModalOpen}
          integrationActionItemId={parseInt(integrationActionItemId)}
          visibility={visibility}
          handleOnClose={updateFromNewCustomField}
        />
      )}
      <Box
        direction="row"
        gap="8px"
        pad={{ right: '40px' }}
        css={`
          > * {
            flex: 1;
            min-width: 0;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        `}
      >
        <Heading
          css={`
            color: ${colors.textLight};
            font-size: 13px;
            font-weight: 400;
          `}
        >
          {t('responsePath')}
        </Heading>
        <Heading
          css={`
            color: ${colors.textLight};
            font-size: 13px;
            font-weight: 400;
          `}
        >
          {t('targetCustomField')}
        </Heading>
      </Box>
      {Object.keys(rows).map(id => (
        <MappingRow mappingRowKey={id} options={customFieldOptions} key={id} />
      ))}
      <Box margin={{ bottom: '8px', top: Object.keys(rows).length ? '0' : '8px' }} direction="row" gap="small">
        <Button size="medium" secondary icon="add" label={t('common:addNewRow')} onClick={addRow} />
        {integrationActionItemId && (
          <Button
            size="medium"
            tertiary
            icon="add"
            label={t('createNewCustomField')}
            onClick={onCreateNewCustomField}
          />
        )}
      </Box>
    </Box>
  )
}
