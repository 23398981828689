import { useRecoilCallback, useRecoilValue, useRecoilValueLoadable } from 'recoil'

import { accountState } from 'main/recoil/runbook'
import { ActiveAccountModelType } from 'main/data-access/models'

export const useGetActiveAccount: ActiveAccountModelType['useGet'] = () => {
  return useRecoilValue(accountState)
}

export const useGetActiveAccountCallback: ActiveAccountModelType['useGetCallback'] = () => {
  return useRecoilCallback(
    ({ snapshot }) =>
      async () =>
        await snapshot.getPromise(accountState)
  )
}

export const useActiveAccountLoadableValue: ActiveAccountModelType['useGetLoadable'] = () => {
  return useRecoilValueLoadable(accountState)
}

export const useActiveAccountLoadableCallback: ActiveAccountModelType['useGetLoadableCallback'] = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      () =>
        snapshot.getLoadable(accountState)
  )

export const useId: ActiveAccountModelType['useId'] = () => {
  const { id } = useGetActiveAccount()
  return id
}

export const useIdCallback: ActiveAccountModelType['useIdCallback'] = () => {
  return useRecoilCallback(
    ({ snapshot }) =>
      async () =>
        (await snapshot.getPromise(accountState)).id
  )
}
