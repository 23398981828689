// @ts-nocheck
import { observable, action } from 'mobx'
import { injectable } from 'inversify'

@injectable()
export class UserAppTokenApplicationPresenter {
  @observable
  public continued: boolean = false

  @observable
  public submitSucceeded: boolean = false

  @observable
  public submitted: boolean = false

  @action
  public setContinued = (val: boolean) => {
    this.continued = val
  }

  @action
  public setSubmitSucceeded = (val: boolean) => {
    this.submitSucceeded = val
  }

  @action
  public setSubmitted = (val: boolean) => {
    this.submitted = val
  }

  @action
  public reset() {
    this.continued = false
    this.submitSucceeded = false
    this.submitted = false
  }
}
