export type ComponentFilter = {
  resourceType?: string
  resourceId?: number
}

export type Filter = {
  resource_type?: string
  resource_id?: number
  value?: string
}

export type Component = {
  id: number
  filters?: Filter
  settings?: any
}

export type ComponentDetails = {
  componentId?: number
  components: Component[]
}

export function getFilter({ componentId, components = [] }: ComponentDetails): ComponentFilter {
  if (!componentId) {
    return { resourceType: undefined, resourceId: undefined }
  }

  const component = components.find(c => c.id === componentId)
  if (!component) {
    return { resourceType: undefined, resourceId: undefined }
  }

  return { resourceType: component.filters?.resource_type || '', resourceId: component.filters?.resource_id }
}

export function getSettings<T>({ componentId, components = [] }: ComponentDetails): T | undefined {
  const component = componentId ? components.find(c => c.id === componentId) : null
  return component?.settings
}
