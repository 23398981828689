// @ts-nocheck

import Popup from 'reactjs-popup'
import { observer } from 'mobx-react-lite'
import { Icon } from 'Components/Atoms/Icon'
import { colors, px2rem } from 'app/Styles/Variables'
import styled from 'styled-components'
import { PopoverPresenter } from './PopoverPresenter'
import { PopupPosition } from 'reactjs-popup/dist/types'
import { ToolTip } from 'Components/Molecules/ToolTip'
import { TToolTipPosition } from 'Components/Molecules/ToolTip/TToolTipPosition'

interface IPopoverProps {
  open: boolean
  controlledOpen?: boolean
  type: 'customContent' | 'shortcutList'
  content: any
  presenter?: PopoverPresenter
  opacity?: number
  position?: PopupPosition
  tooltipPosition?: TToolTipPosition
  customTrigger?: JSX.Element
  wrapperExtraStyles?: CSSProperties
  customTriggerCallback?: () => void
  moreOptionsToolTipText?: string
}

interface IProps {
  index?: number
  length?: number
}

const PopoverWrapper = styled.span`
  display: block;
  height: 100%;
`

const StyledPopup = styled(Popup)`
  &-content {
    border-radius: ${px2rem(8)};
    border: none !important;
    width: auto !important;
    box-shadow: 0 ${px2rem(6)} ${px2rem(24)} 0 ${colors.primaryGreyShadow};
    background-color: ${colors.white} !important;
    padding: ${px2rem(10)} !important;
  }
  &-arrow {
    color: ${colors.white};
  }
  &-overlay {
    opacity: 0 !important;
  }
`

const ShortcutGroup = styled.div`
  border-bottom: ${(props: IProps) =>
    props.index + 1 < props.length ? `${px2rem(1)} solid ${colors.textLightest}` : 'none'};
`

const ShortcutItemWrapper = styled.div`
  cursor: pointer;
  &:hover {
    background-color: ${colors.textFaint};
  }
`

const ShortcutItem = styled.div`
  padding: ${px2rem(5)};
`

const ShortcutText = styled.span`
  padding-left: ${px2rem(8)};
  padding-right: ${px2rem(5)};
  color: ${colors.textLighter};
  line-height: 1.5;
  position: 'relative';
  top: -3px;
`

const ShortcutCommand = styled.span`
  float: right;
  color: ${colors.textLightest};
  line-height: 1.5;
`

const isMacintosh = () => {
  return navigator.platform.indexOf('Mac') > -1
}

const isWindows = () => {
  return navigator.platform.indexOf('Win') > -1
}

const TriggerSpan = styled.span`
  > i {
    color: ${colors.secondaryGrey};
    &:hover {
      color: ${colors.primaryGreyHover};
    }
  }
`

const BaseTrigger = ({ triggerFunction, moreOptionsToolTipText, tooltipPosition }) => (
  <ToolTip
    message={moreOptionsToolTipText}
    trigger={
      <span aria-label={moreOptionsToolTipText}>
        <InnerTrigger customTriggerCallback={triggerFunction} />
      </span>
    }
    position={tooltipPosition}
    oneLineTooltip
    nested
  />
)

const InnerTrigger = ({ customTriggerCallback }: any) => (
  <TriggerSpan tabIndex={0}>
    <Icon
      name="more-vertical"
      extraStyles={{ cursor: 'pointer' }}
      size={px2rem(24)}
      onClick={() => customTriggerCallback()}
    />
  </TriggerSpan>
)

export const Popover = observer<IPopoverProps>(
  (props, ref) => {
    let {
      open,
      controlledOpen,
      type,
      content,
      presenter,
      customTrigger,
      wrapperExtraStyles,
      customTriggerCallback,
      moreOptionsToolTipText,
      tooltipPosition = 'bottom center'
    } = props

    const opacity = presenter?.opacity || props.opacity
    const position = presenter?.position || props.position

    const triggerFunction = customTriggerCallback ? customTriggerCallback : () => null

    return (
      <PopoverWrapper style={wrapperExtraStyles}>
        <StyledPopup
          ref={ref}
          overlayStyle={{
            color: 'red',
            backgroundColor: colors.textLightest,
            opacity: opacity
          }}
          trigger={
            customTrigger ? (
              customTrigger
            ) : moreOptionsToolTipText ? (
              <span>
                <BaseTrigger
                  triggerFunction={triggerFunction}
                  moreOptionsToolTipText={moreOptionsToolTipText}
                  tooltipPosition={tooltipPosition}
                />
              </span>
            ) : (
              <span>
                <InnerTrigger triggerFunction={triggerFunction} />
              </span>
            )
          }
          defaultOpen={open}
          open={controlledOpen}
          position={position}
        >
          <>
            {type === 'customContent' && <div>{content}</div>}
            {type === 'shortcutList' && (
              <>
                {content.map((value, index) => {
                  return (
                    <ShortcutGroup key={index} index={index} length={content.length}>
                      {value.map((value, index) => {
                        return (
                          <ShortcutItemWrapper key={index}>
                            <ShortcutItem key={index}>
                              <Icon name={value.iconName} color={colors.textLighter} size={'20px'} />
                              <ShortcutText>{value.text}</ShortcutText>
                              <ShortcutCommand>
                                {value.command && isMacintosh() && <>⌘ + {value.command}</>}
                                {value.command && isWindows() && <>CTRL + {value.command}</>}
                              </ShortcutCommand>
                            </ShortcutItem>
                          </ShortcutItemWrapper>
                        )
                      })}
                    </ShortcutGroup>
                  )
                })}
              </>
            )}
          </>
        </StyledPopup>
      </PopoverWrapper>
    )
  },
  { forwardRef: true }
)
