import queryString from 'query-string'
import { useLocation, useMatch } from 'react-router-dom'

import { SubHeaderButton, SubHeaderButtonGroup } from 'main/components/layout/shared/sub-header/sub-header-button'
import { useLanguage } from 'main/services/hooks'

export type DisplayType = 'list' | 'timeline' | 'table'

export const ViewNavigation = ({ templateType }: { templateType?: 'off' | 'snippet' | 'default' }) => {
  const { t } = useLanguage('runbooks', { keyPrefix: 'workspaceViewToggle' })
  const location = useLocation()
  const displayMatch = useMatch('/app/:accountId/runbooks/:displayType')
  const currentPage = displayMatch?.params.displayType as DisplayType | undefined
  const accountId = displayMatch?.params.accountId

  const getSearch = (navPage: string) => {
    let searchObject = queryString.parse(location.search)

    if (navPage === 'timeline') {
      searchObject.group = 'folder'
    } else {
      delete searchObject.group
      delete searchObject.spotlight
    }

    return queryString.stringify(searchObject)
  }

  return (
    <SubHeaderButtonGroup>
      <SubHeaderButton
        label={t('list')}
        active={currentPage === 'list'}
        data-testid="list"
        id="view-toggle-list"
        navTo={`/app/${accountId}/runbooks/list?${getSearch('list')}`}
      />
      {(!templateType || templateType === 'off') && (
        <SubHeaderButton
          label={t('timeline')}
          active={currentPage === 'timeline'}
          data-testid="timeline"
          id="view-toggle-timeline"
          navTo={`/app/${accountId}/runbooks/timeline?${getSearch('timeline')}`}
        />
      )}
      <SubHeaderButton
        label={t('table')}
        active={currentPage === 'table'}
        data-testid="table"
        id="view-toggle-table"
        navTo={`/app/${accountId}/runbooks/table?${getSearch('table')}`}
      />
    </SubHeaderButtonGroup>
  )
}
