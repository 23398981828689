import styled from 'styled-components/macro'

import { breakpoints } from '../../utils'

export const PageWrapper = styled.main`
  position: relative !important;
  height: 100%;
  width: 100%;
  display: flex;
  top: 0 !important;
  flex-direction: row;
  overflow: hidden;

  @media ${breakpoints.sm} {
    flex-direction: column;
  }
`
