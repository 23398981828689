import { useState } from 'react'
import { format as formatDate } from 'date-fns'
import styled from 'styled-components'

import { colors } from '@cutover/react-ui'
import { RunbookData } from './types'

type RunbookSummaryItemProps = {
  runbook: RunbookData
}

const DATE_DISPLAY_FORMAT = 'd MMM yyyy hh:mm'

export function RunbooksSummaryItem({ runbook }: RunbookSummaryItemProps) {
  return (
    <RunbookItem data-testid="runbook-summary-item">
      <ItemHeader>
        <span>{`#${runbook.id}`}&nbsp;</span>
        <ItemTitle>{`${runbook.name}`}&nbsp;</ItemTitle>
        {runbook.admin && (
          <span>
            &nbsp;
            <AdminIndicator />
            &nbsp;
          </span>
        )}
      </ItemHeader>
      <ItemContent>
        <span>{`${runbook.account_name} · ${runbook.project_name}`}</span>
        {runbook.start_display && runbook.end_display && (
          <span>{`${formatDate(new Date(runbook.start_display * 1000), DATE_DISPLAY_FORMAT)} · ${formatDate(
            new Date(runbook.end_display * 1000),
            DATE_DISPLAY_FORMAT
          )}`}</span>
        )}
      </ItemContent>
      <ItemLine lineColor={runbook.color || colors.primaryGreyHover} />
    </RunbookItem>
  )
}

const AdminIndicator = () => {
  const [showFull, setShowFull] = useState(false)

  return (
    <AdminText expanded={showFull} onMouseEnter={() => setShowFull(true)} onMouseLeave={() => setShowFull(false)}>
      [<span>{showFull ? 'ADMIN' : 'A'}</span>]
    </AdminText>
  )
}

const AdminText = styled.span<{ expanded: boolean }>`
  font-size: 16px;
  font-weight: 300;
  color: ${props => (props.expanded ? colors.primaryGreyHover : colors.secondaryGrey)} !important;
  > span {
    margin: 0 2px;
    font-size: 14px;
    color: ${props => (props.expanded ? colors.primaryGreyHover : colors.secondaryGrey)} !important;
  }
`

const RunbookItem = styled.div`
  display: flex;
  padding-bottom: 9px;
  flex-direction: column;
  cursor: pointer;
`

const ItemLine = styled.div<{ lineColor: string }>`
  background-color: ${({ lineColor }) => lineColor};
  height: 4px;
  border-radius: 2px;
  opacity: 0.5;
  ${RunbookItem}:hover & {
    opacity: 1;
  }
`

const ItemTitle = styled.span`
  font-weight: 400;
  color: ${colors.primaryGreyHover};

  ${RunbookItem}:hover & {
    color: ${colors.text};
  }
`

const ItemHeader = styled.span`
  font-size: 14px;
  display: flex;
  color: ${colors.primaryGreyHover};
  font-weight: 300;
`

const ItemContent = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  color: ${colors.primaryGrey};
  margin-bottom: 3px;
`
