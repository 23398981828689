import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const BrandCollaborationAndCommunications = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 100 101" a11yTitle="BrandCollaborationAndCommunications" {...props}>
    <g clipPath="url(#a)" fill="#fff"><circle opacity=".5" cx="50" cy="15" r="14.667" /><circle cx="14.667" cy="15" r="14.667" /><circle opacity=".5" cx="14.667" cy="50.333" r="14.667" /><circle cx="14.667" cy="85.666" r="14.667" /><circle opacity=".5" cx="50" cy="85.666" r="14.667" /><circle cx="85.333" cy="85.666" r="14.667" /><circle opacity=".5" cx="85.333" cy="50.333" r="14.667" /><circle cx="50" cy="50.333" r="14.667" /><circle cx="85.333" cy="15" r="14.667" /></g>
  </StyledIcon>
))

BrandCollaborationAndCommunications.displayName = 'BrandCollaborationAndCommunications'

export { BrandCollaborationAndCommunications }
