import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Emoji = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Emoji" {...props}>
    <path d="M29 21a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Zm-7.5-2.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0ZM24 33a11.98 11.98 0 0 0 9.445-4.597l-2.38-1.827A8.984 8.984 0 0 1 24 30a8.987 8.987 0 0 1-7.214-3.618l-2.38 1.827A11.982 11.982 0 0 0 24 33Z" fill="#000" /><path fillRule="evenodd" clipRule="evenodd" d="M44 24c0 11.046-8.954 20-20 20S4 35.046 4 24 12.954 4 24 4s20 8.954 20 20ZM7 24c0 9.389 7.611 17 17 17s17-7.611 17-17S33.389 7 24 7 7 14.611 7 24Z" fill="#000" />
  </StyledIcon>
))

Emoji.displayName = 'Emoji'

export { Emoji }
