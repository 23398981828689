import styled from 'styled-components/macro'

export const CenterMainWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
`
