import { ThemeType as GrommetThemeType } from 'grommet/themes'
import { SetRequired } from 'type-fest'

import { CollapseIcon, ExpandIcon } from '../grommet/accordion/accordion-panel'

type GrommetTheme = SetRequired<GrommetThemeType, 'accordion'>
type GrommetAccordion = GrommetTheme['accordion']

export const accordion: GrommetAccordion = {
  border: {
    color: 'bg-2'
  },
  hover: {
    heading: {
      color: 'text'
    }
  },
  icons: {
    collapse: () => <CollapseIcon />,
    expand: () => <ExpandIcon />
  }
}
