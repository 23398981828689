// @ts-nocheck
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import styled from 'styled-components'
import { Button, ButtonPresenter } from 'Components/Molecules/Button'
import { Heading } from 'Components/Atoms/Heading'
import { ILanguageService } from 'Helpers/Language/ILanguageService'
import { ModalPresenter, Modal } from 'Components/Atoms/Modal'
import { px2rem } from 'app/Styles/Variables'

const ButtonContainer = styled.div`
  padding-top: ${px2rem(20)};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const SingleButtonContainer = styled.div`
  padding-top: ${px2rem(20)};
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

interface IAddGenericWithResultModalComponentProps {
  presenter: ModalPresenter
  title: string
  cancelCallback?: () => void
  headingStyles?: any
  viewPresenter: any
  language: ILanguageService
}

export const AddGenericWithResultModalComponent = observer<IAddGenericWithResultModalComponentProps>(
  ({ presenter, title, cancelCallback, children, headingStyles, viewPresenter, language }) => {
    const {
      addModalSubmitButtonIcon,
      addModalSubmitButtonLoading,
      addModalSubmitButtonText,
      setContinued,
      setSubmitted,
      submitSucceeded
    } = viewPresenter
    presenter.fixedHeader = true

    const cancel = () => {
      presenter.onClose()

      if (cancelCallback) {
        cancelCallback()
      }
    }

    const submitCallback = async () => {
      setSubmitted(true)
    }

    const continueCallback = async () => {
      setContinued(true)
    }

    useEffect(() => {
      submitButtonPresenter.initialText = addModalSubmitButtonText
    }, [addModalSubmitButtonText])

    useEffect(() => {
      submitButtonPresenter.iconName = addModalSubmitButtonIcon
    }, [addModalSubmitButtonIcon])

    useEffect(() => {
      submitButtonPresenter.isLoading = addModalSubmitButtonLoading
    }, [addModalSubmitButtonLoading])

    const cancelButtonPresenter = new ButtonPresenter(language.get('common:cancelButton'), cancel)
      .withDisplayStyle('secondary')
      .withIconName('dnd-forwardslash')
      .withTextTransform('Uppercase')

    const submitButtonPresenter = new ButtonPresenter(language.get('common:createButton'), () => {
      submitCallback()
    })
      .withDisplayStyle('primary')
      .withIconName('add')
      .withTextTransform('Uppercase')

    const continueButtonPresenter = new ButtonPresenter(language.get('common:continueButton'), () => {
      continueCallback()
    })
      .withDisplayStyle('primary')
      .withIconName('chevron-right')
      .withTextTransform('Uppercase')

    return (
      <Modal
        presenter={presenter.withOnCloseExtra(() => cancelCallback && cancelCallback())}
        fixedHeader
        showClose={!submitSucceeded}
      >
        <>
          <Heading extraStyles={headingStyles}>{title}</Heading>
        </>
        <>{children}</>
        <footer>
          {submitSucceeded ? (
            <SingleButtonContainer>
              <Button presenter={continueButtonPresenter} type={'button'} />
            </SingleButtonContainer>
          ) : (
            <ButtonContainer>
              <Button presenter={cancelButtonPresenter} type={'button'} />
              <Button presenter={submitButtonPresenter} type={'submit'} />
            </ButtonContainer>
          )}
        </footer>
      </Modal>
    )
  }
)
