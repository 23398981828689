import { TaskListTask } from 'main/services/queries/types'
import { RunbookFilterType } from 'main/services/tasks/filtering'
import { PaintNodeEntryPoint, ViewportDimensions } from './node-map-types'

export const INFO_DIALOG_WIDTH = 320
export const BASE_UNIT = 10

export const nodeMapState = () => {
  const baseUnit = BASE_UNIT
  const state = {
    units: {
      baseLineWidth: 1,
      base: baseUnit,
      square: baseUnit / 2,
      turn: 2 * Math.PI,
      circle: baseUnit / 1.772,
      diamond: Math.sqrt((baseUnit * baseUnit) / 2),
      triangleBase: Math.sqrt((2 * baseUnit * baseUnit) / 0.866),
      triangleMid: 0,
      triangleExt: 0,
      hexagonEdge: baseUnit * 0.6204,
      hexagonAlt: 0,
      cogTeeth: 7,
      cogOuterRadius: baseUnit / 1.25,
      cogInnerRadius: baseUnit / 1.55,
      cogToothBreadth: baseUnit * 4,
      cogGapBreadth: baseUnit * 2,
      cogAngle: 0
    },
    devicePixelRatio: window.devicePixelRatio ? window.devicePixelRatio : 1,
    maxSizeMultiplier: 2,
    minSizeMultiplier: 0.5,
    view: 'tree',
    animationDuration: 500,
    display: {
      textColor: '22,22,29',
      textColorMid: '111,111,115',
      textColorLight: '111,111,115',
      edgeDefaultColor: '169,169,171',
      edgeDefaultOpacity: 1
    },
    width: 0,
    height: 0,
    viewport: {
      edges: [],
      width: 0,
      height: 0,
      ratio: 1,
      internalWidth: 0,
      internalHeight: 0,
      internalOffsetX: 0,
      internalOffsetY: 0,
      offsetX: 0,
      offsetY: 0
    },
    offsetX: 0,
    offsetY: 0,
    graphWidth: 0,
    graphHeight: 0,
    miniCanvasArea: 20000,
    miniCanvasWidth: 0,
    miniCanvasHeight: 0,
    miniCanvasScale: 1,

    /** hovered graph object */
    hoverObject: null as { type: string; id: number | string } | null,
    /** active graph object */
    activeObject: null as { type: string; id: number | string } | null,
    /** task (node) selected for view in task edit panel */
    selectedObject: null as { type: string; id: number | string } | null,

    mouse: { x: null, y: null },
    colorToObjMap: {} as { [x: string | number]: { type: string; id: number | string } },
    colorCurrentIndex: 1,
    margin: 20,
    transform: { scale: 1, offsetX: 0, offsetY: 0, fitScale: 0 },
    cogRatios: null as {}[] | null,
    mouseDownCoords: null as { x: number; y: number } | null,
    selectedEdges: [] as number[],
    isAnimating: false,
    selectedTaskId: null as number | null,

    /** Initialization props */
    initialized: false,
    tasks: [] as TaskListTask[],
    filterState: {} as RunbookFilterType,
    filterMode: 'default' as PaintNodeEntryPoint['filterMode'],
    dimensions: {} as ViewportDimensions
  }
  state.units.triangleMid = (state.units.triangleBase / 2) * Math.tan((30 * Math.PI) / 180)
  state.units.triangleExt = Math.sqrt(Math.pow(state.units.triangleMid, 2) + Math.pow(state.units.triangleBase / 2, 2))
  state.units.hexagonAlt = state.units.hexagonEdge / 2 / Math.tan((30 * Math.PI) / 180)
  state.units.cogAngle = state.units.turn / (state.units.cogTeeth * 2)

  return state
}
