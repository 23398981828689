import { injectable } from 'inversify'
import { observable, action } from 'mobx'
import { IBaseResponse } from 'Gateways/Service/IBaseResponse'
import { GenericListRepository } from './GenericListRepository'
import { ICustomFieldGroupPM } from 'Shared/Entities/Types/CustomFieldGroup/ICustomFieldGroupPM'
import { CustomFieldGroup as ICustomFieldGroupDTO } from 'Shared/Entities/Types/DTOs'

@injectable()
export class CustomFieldGroupsRepository extends GenericListRepository {
  protected url = 'custom_field_groups'

  @observable
  public customFieldGroups: ICustomFieldGroupPM[] = []

  constructor() {
    super('meta')
  }

  public postLoad = (rawData: IBaseResponse) => {
    this.listTotal = rawData.body.meta.total_results

    if (this.offSet === 0) {
      this.customFieldGroups = this.getCustomFieldGroupsFromAPIData(rawData)
    } else {
      this.customFieldGroups = this.customFieldGroups.concat(this.getCustomFieldGroupsFromAPIData(rawData))
    }
  }

  private getCustomFieldGroupsFromAPIData = (rawData: IBaseResponse): ICustomFieldGroupPM[] => {
    return rawData.body.custom_field_groups.map((cfGroupDto: ICustomFieldGroupDTO) => {
      return {
        id: cfGroupDto.id,
        name: cfGroupDto.name,
        numberOfCustomFields: cfGroupDto.custom_field_count,
        customFieldIds: cfGroupDto.custom_field_ids,
        default: cfGroupDto.default
      }
    })
  }

  @action
  public processCustomFieldGroups = (rawData: IBaseResponse) => {
    this.customFieldGroups = this.getCustomFieldGroupsFromAPIData(rawData)
  }

  @action
  public clear = () => {
    this.clearInherited()
    this.customFieldGroups = []
  }
}
