import React from 'react'
import ReactDOMClient from 'react-dom/client'
import singleSpaReact from 'single-spa-react'

import { bindClearReactContainer } from '../app/Shared/Helpers/SPASharedStateService'
import { ReactAppLegacy } from '../react-app-legacy'
import { clearContainer, container, createContainer } from 'app/Config/IOC'
import { CurrentPagePresenter } from 'app/Routing/CurrentPage/CurrentPagePresenter'

bindClearReactContainer()

const lifecycles = singleSpaReact({
  // @ts-ignore
  React,
  // @ts-ignore
  ReactDOMClient,
  rootComponent: ReactAppLegacy,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  domElementGetter: () => document.getElementById('cutover-react-legacy')!
})

export default {
  bootstrap: lifecycles.bootstrap,
  mount: async (props: any) => {
    await preMount()
    lifecycles.mount(props)
  },
  unmount: async (props: any) => {
    await lifecycles.unmount(props)
    postUnmount()
  }
}

async function preMount() {
  await createContainer()
  container.get(CurrentPagePresenter).bootstrap(false)
}

async function postUnmount() {
  clearContainer()
}
