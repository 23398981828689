// @ts-nocheck
import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { withPresenter } from 'app/Config/withPresenter'
import { CustomFieldGroupsPagePresenter } from './CustomFieldGroupsPagePresenter'
import CustomFieldGroupHeadingSection from 'app/Settings/CustomFieldGroups/CustomFieldGroupsPage/CustomFieldGroupHeading/CustomFieldGroupHeadingSection'
import { CustomFieldGroupsPageModals } from 'app/Settings/CustomFieldGroups/CustomFieldGroupsPage/CustomFieldGroupsPageModals'
import CustomFieldGroupEditSection from 'app/Settings/CustomFieldGroups/CustomFieldGroupsPage/CustomFieldGroupEdit/CustomFieldGroupEditSection'
import {
  PageWrapper,
  PageWrapperInner,
  HeaderPanelWrapper,
  MainPanelWrapper,
  EditPanelWrapper,
  GenericList,
  CenterMainWrapper,
  SectionNavPanelWrapper
} from 'Shared/Components/Templates'
import { AddButton } from 'Shared/Components/Atoms'
import { CustomFieldSectionNav } from 'app/Settings/CustomFields/CustomFieldSectionNav'
import { ViewModelType } from 'Shared/Presenters/GenericAdminViewPresenter'
import { Submitted } from 'Shared/Entities/Enums/Submitted'
import { fluidPage } from 'Shared/HooksAndPresenters/FluidPage'

interface ICustomFieldGroupPageProps {
  presenter: CustomFieldGroupsPagePresenter
}

const CustomFieldGroupsPage = observer<ICustomFieldGroupPageProps>(props => {
  const {
    // list
    listSectionPresenter,

    // Add
    addFormPresenter,
    launchAddSuccessToaster,
    resetForm,

    // Edit
    editFormPresenter,
    editOpen,
    editPanelLoading,
    clearEditPanel,
    editRecordVMChangeFunction,
    editRecordVM,
    launchEditSuccessToaster,

    // Archive
    setArchiveResponseErrors,
    archiveState,
    setArchiveState,
    archivePostSuccessCallback,
    archiveModalOpenState,
    archiveConfirmName,
    archiveModalPresenter,
    resetArchive,
    archiveResponseErrors,
    setEditClosed,
    checkEditPanelIsDirty,
    angularJSSettingsPanelOpenState,
    canCreate,
    language
  } = props.presenter

  useEffect(() => {
    if (editRecordVM) {
      editRecordVMChangeFunction()
    }
  }, [editRecordVM])

  const [addModalOpenState, setAddModalOpenState] = useState(false)

  const { showAddButton } = fluidPage(editOpen, setEditClosed, checkEditPanelIsDirty, angularJSSettingsPanelOpenState)

  return (
    <PageWrapper>
      <PageWrapperInner>
        <HeaderPanelWrapper>
          <CustomFieldGroupHeadingSection />
        </HeaderPanelWrapper>
        <CenterMainWrapper>
          <MainPanelWrapper filterPanelOpen={false} extraStyles={{ zIndex: 1 }} loading={false}>
            <GenericList listPresenter={listSectionPresenter} />
            {showAddButton && canCreate && (
              <AddButton
                action={() => {
                  setAddModalOpenState(true)
                }}
                language={language}
              />
            )}

            <CustomFieldGroupsPageModals
              addFormPresenter={addFormPresenter}
              addModalOpenState={addModalOpenState}
              setAddModalOpenState={(modalOpen: boolean) => {
                setAddModalOpenState(modalOpen)
              }}
              addPostSuccessCallback={() => {
                launchAddSuccessToaster()
                resetForm(ViewModelType.Add)
              }}
              addFormCancelCallback={() => {
                resetForm(ViewModelType.Add)
              }}
              archiveModalOpenState={archiveModalOpenState}
              archiveConfirmName={archiveConfirmName}
              archiveModalPresenter={archiveModalPresenter}
              cancelArchiveCallback={resetArchive}
              continueArchiveCallback={() => {
                setArchiveState(Submitted.SubmittedAndConfirmed)
              }}
              archiveResponseErrors={archiveResponseErrors}
              language={language}
            />
          </MainPanelWrapper>
        </CenterMainWrapper>
      </PageWrapperInner>
      <EditPanelWrapper
        isOpen={editOpen}
        loading={editPanelLoading}
        withSectionNav={true}
        panelCloseCallback={clearEditPanel}
      >
        <CustomFieldGroupEditSection
          editFormPresenter={editFormPresenter}
          setArchiveResponseErrors={setArchiveResponseErrors}
          archiveState={archiveState}
          setArchiveState={setArchiveState}
          archivePostSuccessCallback={archivePostSuccessCallback}
          editPostSuccessCallback={() => {
            launchEditSuccessToaster()
          }}
        />
      </EditPanelWrapper>
    </PageWrapper>
  )
})

export default withPresenter(CustomFieldGroupsPagePresenter)(CustomFieldGroupsPage)
