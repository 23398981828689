import { sortBy } from 'lodash'
import { UseFormReturn } from 'react-hook-form'

import { Box, TeamListItem } from '@cutover/react-ui'
import { Role, RunbookUserFormType, RunbookUserTeam } from '../types'

type TeamListProps = {
  teams: RunbookUserTeam[]
  remove: (index?: number | number[]) => void
  isReadonly?: boolean
  methods: UseFormReturn<RunbookUserFormType>
  openReassignModal: () => void
}

export function TeamList({ teams, remove, isReadonly, methods, openReassignModal }: TeamListProps) {
  // Keep track of useFieldArray indexes when sorting
  // See https://github.com/react-hook-form/react-hook-form/discussions/4264
  const sortedTeams = sortBy(teams, t => t.name)
  const indexes = new Map(teams.map(({ id }, index) => [id, index]))
  const roleValue = methods.watch('role')

  const removeTeam = (teamId: number) => {
    if (teams.length === 1 && roleValue === Role.RunbookTeamMember) {
      openReassignModal()
    } else {
      remove(indexes.get(teamId))
    }
  }

  return (
    <Box as="ul" css="margin: 0; padding: 0">
      {sortedTeams.map((team, index) => {
        if (!team.name || !team.color) {
          return null
        }

        return (
          <Box
            as="li"
            key={team.id}
            css={`
              list-style-type: none;
              margin-bottom: 5px};
            `}
          >
            <TeamListItem
              id={team.id}
              size="small"
              usersCount={team.usersCount}
              name={team.name}
              linked={team.linked}
              color={team.color}
              onClickRemove={isReadonly || team.linked ? undefined : removeTeam}
              data-testid="team-item"
            />
            <input
              type="hidden"
              defaultValue={team.id}
              key={team.id}
              {...methods.register(`teams.${index}.id`, {
                valueAsNumber: true
              })}
            />
          </Box>
        )
      })}
    </Box>
  )
}
