// NOTE in case of problems, another looser url checker is:
// https://github.com/segmentio/is-url/tree/master

const urlRegex =
  /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/

export const isUrl = (string: string) => {
  if (typeof string !== 'string') {
    return false
  }

  if (string.indexOf(' ') >= 0) {
    return false
  }

  var match = string.match(urlRegex)
  return match
}
