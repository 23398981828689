import { useMutation, useQuery } from 'react-query'

import { apiClient } from 'main/services/api'
import { QueryKeys } from 'main/services/queries/query-keys'
import { SystemParameter } from './types/system-parameters'

type SystemParametersResponse = {
  system_parameters: SystemParameter[]
}

export function useSystemParametersQuery() {
  return useQuery<SystemParametersResponse, Error, SystemParametersResponse>([QueryKeys.SystemParameters], async () => {
    const { data } = await apiClient.get<SystemParametersResponse>({
      url: 'system_parameters'
    })
    return data
  })
}

type SystemParameterResponse = {
  system_parameter: SystemParameter
}

export type SystemParameterPayload = {
  name: string
  value: string
}

export function useSystemParameter(systemParameterId: number | undefined) {
  return useQuery<SystemParameterResponse, Error, SystemParameterResponse>(
    [QueryKeys.SystemParameters, systemParameterId],
    async () => {
      const { data } = await apiClient.get<SystemParameterResponse>({
        url: `system_parameters/${systemParameterId}`
      })
      return data
    }
  )
}

export function useSystemParametersCreate() {
  return useMutation<SystemParameterResponse, Error, { payload: SystemParameterPayload }>(
    [QueryKeys.SystemParameters],
    async ({ payload }: { payload: SystemParameterPayload }) => {
      const response = await apiClient.post<SystemParameterPayload, SystemParameterResponse>({
        url: 'system_parameters',
        data: payload
      })

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return response.data!
    }
  )
}

export function useDeleteSystemParametersMutation() {
  return useMutation<any, Error, { id: number }>(['system-parameters-delete-request'], async ({ id }) => {
    const { data } = await apiClient.delete({
      url: `system_parameters/${id}`
    })

    return data
  })
}

export function useUpdateSystemParametersMutation() {
  return useMutation<any, Error, { id: number; payload: any }>(
    [QueryKeys.SystemParameters],
    async ({ id, payload }) => {
      const { data } = await apiClient.patch({
        url: `system_parameters/${id}`,
        data: payload
      })

      return data
    }
  )
}
