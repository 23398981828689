import { useMemo } from 'react'
import queryString from 'query-string'

import { AllFilters, FILTER_DEFS } from './filter-types'
import { handlePercentEncoding } from 'main/recoil/shared/recoil-sync/recoil-sync-component'

export type FilterValue = string | string[] | number | number[] | object | boolean | undefined

export const EXCLUDE_KEYS_FROM_RUNBOOKS_SERVER_QUERY = ['group', 'spotlight']

// INPUT: project=15&project=13&f=%7B"27":"*","31":%5B0,22%5D,"41":%5B43%5D%7D&status=off&status=red&sort_by=updated_at&sort_dir=desc&late=1
//
// OUTPUT: project[]=15&project[]=13&f[27]=*&f[31][]=0&f[31][]=22&f[41][]=43&status[]=off&status[]=red&sort_by=updated_at&sort_dir=desc&late=true
export const browserQueryStringToServerQueryString = ({
  query,
  overrides = {},
  defaults = {},
  excludeKeys = []
}: {
  query: string
  overrides?: object
  defaults?: object
  excludeKeys?: string[]
}) => {
  return serverQueryObjectToServerQueryString({
    queryObject: browserQueryStringToServerQueryObject({ query, overrides, defaults }),
    excludeKeys
  })
}

export const useBrowserQueryStringToServerQueryString = ({
  query,
  overrides = {},
  defaults = {}
}: {
  query: string
  overrides?: object
  defaults?: object
}) => {
  return useMemo(
    () => browserQueryStringToServerQueryString({ query, overrides, defaults }),
    [query, overrides, defaults]
  )
}

// INPUT: project=15&project=13&f=%7B"27":"*","31":%5B0,22%5D,"41":%5B43%5D%7D&status=off&status=red&sort_by=updated_at&sort_dir=desc&late=1
//
// OUTPUT: {
//   f: {
//     27: '*',
//     31: [0, 22],
//     41: [43]
//   },
//   project: [15, 13],
//   late: true,
//   sort_by: 'updated_at',
//   sort_dir: 'desc',
//   status: ['off', 'red']
// }

export const browserQueryStringToServerQueryObject = ({
  query,
  overrides = {},
  defaults = {}
}: {
  query: string
  overrides?: object
  defaults?: object
}) => {
  let parsedString = queryString.parse(query, { parseNumbers: true })
  parsedString = { ...defaults, ...parsedString, ...overrides }
  return transformQueryStringValueTypes(parsedString as any)
}

export const useBrowserQueryStringToServerQueryObject = ({
  query,
  overrides = {},
  defaults = {}
}: {
  query: string
  overrides?: object
  defaults?: object
}) => {
  return useMemo(
    () => browserQueryStringToServerQueryObject({ query, overrides, defaults }),
    [query, overrides, defaults]
  )
}

// INPUT: {
//   f: {
//     27: '*',
//     31: [0, 22],
//     41: [43]
//   },
//   project: [15, 13],
//   late: true,
//   sort_by: 'updated_at',
//   sort_dir: 'desc',
//   status: ['off', 'red']
// }
//
// OUTPUT: project=15&project=13&f=%7B"27":"*","31":%5B0,22%5D,"41":%5B43%5D%7D&status=off&status=red&sort_by=updated_at&sort_dir=desc&late=1
export const serverQueryObjectToBrowserQueryString = ({
  queryObject,
  defaults = {},
  overrides = {},
  excludeKeys = []
}: {
  queryObject: object
  defaults?: object
  overrides?: object
  excludeKeys?: string[]
}) => {
  const fullObject = { ...defaults, ...queryObject, ...overrides } as Record<string, any>

  if (excludeKeys.length) {
    excludeKeys.forEach(key => {
      delete fullObject[key]
    })
  }
  for (const p in fullObject) {
    const property = p as AllFilters
    if (FILTER_DEFS?.[property]?.type === 'bool') {
      fullObject[property] = fullObject[property] ? 1 : 0
    }
  }
  const { f, cp, ...restFilters } = fullObject

  let fFilter: { [x: string]: string | number[] } = {}
  if (f && f instanceof Map) {
    f.forEach((value: string | number[], key: string) => {
      if (value instanceof Array) {
        fFilter[key] = value
      } else {
        fFilter[key] = encodeURIComponent(value as string)
      }
    })
  } else {
    fFilter = f
  }

  const cfString = f ? `f=${JSON.stringify(fFilter)}` : ''
  const cpString = cp ? `cp=${JSON.stringify(cp)}` : ''
  const paramString = queryString.stringify(restFilters)

  const combinedString = [
    ...(paramString ? [paramString] : []),
    ...(cfString ? [cfString] : []),
    ...(cpString ? [cpString] : [])
  ].join('&')

  return combinedString ? `?${combinedString}` : ''
}

export const useServerQueryObjectToBrowserQueryString = ({
  queryObject,
  defaults = {},
  overrides = {},
  excludeKeys = []
}: {
  queryObject: object
  defaults?: object
  overrides?: object
  excludeKeys?: string[]
}) => {
  return useMemo(
    () => serverQueryObjectToBrowserQueryString({ queryObject, defaults, overrides, excludeKeys }),
    [queryObject, defaults, overrides, excludeKeys]
  )
}

// INPUT: {
//   f: {
//     27: '*',
//     31: [0, 22],
//     41: [43]
//   },
//   project: [15, 13],
//   late: true,
//   sort_by: 'updated_at',
//   sort_dir: 'desc',
//   status: ['off', 'red']
// }
//
// project[]=15&project[]=13&f[27]=*&f[31][]=0&f[31][]=22&f[41][]=43&status[]=off&status[]=red&sort_by=updated_at&sort_dir=desc&late=true
export const serverQueryObjectToServerQueryString = ({
  queryObject,
  defaults = {},
  overrides = {},
  excludeKeys = []
}: {
  queryObject: object
  defaults?: object
  overrides?: object
  excludeKeys?: string[]
}) => {
  const fullObject = { ...defaults, ...queryObject, ...overrides } as Record<string, any>

  if (excludeKeys.length) {
    excludeKeys.forEach(key => {
      delete fullObject[key]
    })
  }

  const { f, cp, ...shallowParams } = fullObject

  const cfString = f ? transformJSONQueryParamToServerParam('f', f) : ''
  const cpString = cp ? transformJSONQueryParamToServerParam('cp', cp) : ''
  const paramString = queryString.stringify(shallowParams, { arrayFormat: 'bracket' })

  const combinedString = [
    ...(paramString ? [paramString] : []),
    ...(cfString ? [cfString] : []),
    ...(cpString ? [cpString] : [])
  ].join('&')

  return combinedString ? `?${combinedString}` : ''
}

export const useServerQueryObjectToServerQueryString = ({
  queryObject,
  defaults = {},
  overrides = {},
  excludeKeys = []
}: {
  queryObject: object
  defaults?: object
  overrides?: object
  excludeKeys?: string[]
}) => {
  return useMemo(
    () => serverQueryObjectToServerQueryString({ queryObject, defaults, overrides, excludeKeys }),
    [queryObject, defaults, overrides, excludeKeys]
  )
}

export function getQueryFromSavedView(queryObject: any) {
  const userQueryObject = { ...queryObject }

  if (userQueryObject.q?.toString().trim().startsWith('#')) {
    userQueryObject.runbook_id = (userQueryObject.q.replace(/#|\s/g, '').split(',') as string[]).reduce((acc, next) => {
      const parsedId = parseInt(next)
      if (!Number.isNaN(parsedId)) {
        acc.push(parsedId)
      }
      return acc
    }, [] as number[])

    delete userQueryObject.q
  }

  return userQueryObject
}

export const getSavedViewQueryString = (queryObject: any = {}) => {
  // WARNING: 'q' can be a number
  const searchQuery = typeof queryObject['q'] !== 'undefined' ? String(queryObject['q']) : undefined
  const viewQueryString = serverQueryObjectToBrowserQueryString({
    queryObject,
    excludeKeys: [
      'accountId',
      'runbookId',
      'projectId',
      'runbookVersionId',
      'dashboardId',
      'disable_components',
      '_display',
      'accountSlug',
      'forceReload',
      ...(searchQuery ? ['q'] : [])
    ]
  })

  const queryStringWithSearch = searchQuery
    ? viewQueryString + (viewQueryString.includes('?') ? '&q=' : '?q=') + decodeURI(handlePercentEncoding(searchQuery))
    : viewQueryString

  return queryStringWithSearch
}

export const getSavedViewURL = (queryObject: any) => {
  const viewQueryString = getSavedViewQueryString(queryObject)

  const { accountSlug, runbookId, runbookVersionId, dashboardId } = queryObject
  const navPath =
    queryObject._display === 'runbook'
      ? `/app/${accountSlug}/runbooks/${runbookId}/${runbookVersionId}/tasks/list${encodeURI(viewQueryString)}`
      : dashboardId !== undefined
      ? `/app/${accountSlug}/runbooks/dashboard/${dashboardId}${encodeURI(viewQueryString)}`
      : `/app/${accountSlug}/runbooks/${queryObject._display}${encodeURI(viewQueryString)}`

  return navPath
}
// INTERNAL HELPERS

export function transformParam(param: AllFilters, val: string | string[] | number | number[]): FilterValue {
  if (!FILTER_DEFS[param]) {
    return val
  }
  if (val === undefined || val === null) {
    // @ts-ignore TODO: determine i this is OK
    return null
  }

  switch (FILTER_DEFS[param].type) {
    case 'int':
      if (FILTER_DEFS[param].array) {
        return (Array.isArray(val) ? val : [val]) as number[]
      } else {
        return val as number
      }
    case 'bool':
      if (FILTER_DEFS[param].array) {
        return Array.isArray(val) ? val.map(v => Boolean(v)) : [Boolean(val)]
      } else {
        return Boolean(val)
      }
    case 'json':
      if (Array.isArray(val)) {
        let parsed = {}
        val.forEach(v => {
          parsed = Object.assign(parsed, JSON.parse(v as string))
        })
        return parsed as object
      } else {
        return JSON.parse(decodeURIComponent(val as string)) as object
      }
    case 'string':
      if (FILTER_DEFS[param].array) {
        return (Array.isArray(val) ? val : [val]) as string[]
      } else {
        return val as string
      }
  }
}

export function transformQueryStringValueTypes(params: Record<AllFilters, any>) {
  for (const p in params) {
    const property = p as AllFilters
    params[property] = transformParam(property, params[property])
  }
  return params as Record<string, FilterValue>
}

function transformJSONQueryParamToServerParam(param: string, json: object) {
  let serverParam = ''

  Object.keys(json).forEach(key => {
    // @ts-ignore
    if (Array.isArray(json[key])) {
      // @ts-ignore we know it's an array at this point
      json[key].forEach(value => {
        serverParam += `${param}[${key}][]=${value}&`
      })
    } else {
      // @ts-ignore
      serverParam += `${param}[${key}]=${json[key]}&`
    }
  })

  serverParam = serverParam.substring(0, serverParam.length - 1)

  return serverParam
}
