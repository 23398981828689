// @ts-nocheck
import { observable, action } from 'mobx'
import { inject, injectable } from 'inversify'
import { Types } from 'Gateways/Service/Types'
import { IServiceGateway } from 'Gateways/Service/IServiceGateway'

interface IRunbookUserDTO {
  archived: boolean
  color: string
  handle: string
  id: number
  is_admin: boolean
  name: string
  not_in_account: boolean
  online: boolean
  status: string
}

export interface IRunbookUserPM {
  name: string
  color: string
  handle: string
  online: boolean
  isAdmin: boolean
  archived: boolean
  notInAccount: boolean
}

@injectable()
export class RunbookVersionRepository {
  @inject(Types.IServiceGateway)
  public serviceGateway: IServiceGateway

  @observable
  public runbookUsers: IRunbookUserPM[] = []

  @observable
  public runbookId: number

  @observable
  public runbookVersionId: number = null

  @action
  public load = async (runbookId: number, runbookVersionId: number) => {
    if (!runbookId || !runbookVersionId) return

    this.runbookId = runbookId
    this.runbookVersionId = runbookVersionId

    const apiUrl = `runbooks/${this.runbookId}/runbook_versions/${this.runbookVersionId}`
    const rawData = await this.serviceGateway.get(apiUrl)

    if (rawData.success && rawData.body.hasOwnProperty('meta') && rawData.body.meta.hasOwnProperty('users')) {
      this.mapUsersToPM(rawData.body.meta.users)
    }
  }

  @action
  public mapUsersToPM = (users: IRunbookUserDTO[]) => {
    this.runbookUsers = users.map((user: IRunbookUserDTO) => ({
      name: user.name,
      color: user.color,
      handle: user.handle,
      online: user.online,
      isAdmin: user.is_admin,
      archived: user.archived,
      notInAccount: user.not_in_account
    }))
  }
}
