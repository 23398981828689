import { Box, Message, MessageType } from '@cutover/react-ui'
import { useComponentProps } from '../apps-state'
import { AppComponentNodeProps } from '../apps-types'

type AlertNodeProps = AppComponentNodeProps & {
  kind: MessageType
  value: string | string[]
}

export const AlertNode = ({ appId, resourceId, id, ...props }: AlertNodeProps) => {
  const { kind = 'info', value = '' } = useComponentProps(appId, resourceId, id, props) as AlertNodeProps

  return (
    <Box
      css={`
        margin-bottom: 16px;
      `}
    >
      <Message type={kind} message={value} />
    </Box>
  )
}
