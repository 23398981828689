import { useRef, useState } from 'react'

import { Box, colors, Icon, Text } from '@cutover/react-ui'

export const LoginWarningMessage = ({ message, onClick }: { message: string; onClick: () => void }) => {
  const checkboxRef = useRef<HTMLInputElement>(null)
  const [checked, setChecked] = useState(false)

  const toggleCheck = () => {
    if (checkboxRef.current) {
      checkboxRef.current.checked = !checked
    }
    setChecked(!checked)
    onClick()
  }

  return (
    <Box
      direction="row"
      onClick={toggleCheck}
      css={`
        background-color: ${colors.primaryGreyBackgroundColor};
        border-radius: 8px;
        margin-top: 12px;
      `}
    >
      <Box css="position:relative; top: 18px; left: 14px; height: 18px">
        <Icon icon={checked ? 'checkbox-checked' : 'checkbox'} color={checked ? 'primary' : 'text'} />
      </Box>
      <Box css="padding: 16px 14px 16px 40px;">
        <Text color={colors.textLight}>{message}</Text>
      </Box>
    </Box>
  )
}
