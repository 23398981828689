import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Bookmark = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Bookmark" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="m24 35 10 7V10a4 4 0 0 0-4-4H18a4 4 0 0 0-4 4v32l10-7Zm-7 1.238 7-4.9 7 4.9V10a1 1 0 0 0-1-1H18a1 1 0 0 0-1 1v26.238Z" fill="#000" />
  </StyledIcon>
))

Bookmark.displayName = 'Bookmark'

export { Bookmark }
