import { ReactNode } from 'react'

import { ListItem } from '../list'

export type CardProps = {
  type?: 'small'
  selected?: boolean
  title: string
  titleSuffix?: string
  subtitle?: string
  onClick?: () => void
  onClickRemove?: (id: number) => void
  active?: boolean
  startComponent?: ReactNode
  endComponents?: ReactNode[]
  'data-testid'?: string
  a11yTitle?: string
  disabled?: boolean
  role?: string
  key?: string | number
}

// This component wraps multiple subtypes of cards. Currently we only have the Small Card but more are coming soon
export const Card = ({ type, ...props }: CardProps) => {
  if (type === 'small') {
    return <CardSmall {...props} />
  }
  return <CardSmall {...props} />
}

const CardSmall = ({
  title,
  titleSuffix,
  subtitle,
  onClick,
  startComponent,
  endComponents,
  disabled,
  active,
  'data-testid': testId,
  a11yTitle,
  role,
  selected
}: CardProps) => {
  return (
    <ListItem
      selected={selected}
      startComponent={startComponent}
      endComponents={endComponents}
      onClick={onClick}
      size="large"
      border={true}
      disabled={disabled}
      a11yTitle={a11yTitle ?? title}
      title={title}
      titleSuffix={titleSuffix}
      subTitle={subtitle}
      active={active}
      data-testid={testId}
      role={role}
    />
  )
}
