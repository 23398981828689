// @ts-nocheck
import { observable, action } from 'mobx'
import { CheckboxPresenter } from '.'

export class CheckboxListPresenter {
  @observable
  public selectedViews: string[]

  @observable
  public parentCheckboxPresenter: CheckboxPresenter = null

  @observable
  public childrenCheckboxPresenters: CheckboxPresenter[] = []

  constructor(
    parentCheckboxLabel: string,
    chidlrenCheckboxLabels: string[],
    selectedViews: string[],
    disabled: boolean
  ) {
    this.selectedViews = selectedViews
    this.initializeParentCheckbox(parentCheckboxLabel, disabled)
    this.initializeChildrenCheckboxes(chidlrenCheckboxLabels, disabled)
  }

  @action
  public initializeParentCheckbox = (parentCheckboxLabel: string, disabled: boolean) => {
    if (this.selectedViews) {
      const value = this.selectedViews && this.selectedViews.includes(parentCheckboxLabel) ? true : false
      this.parentCheckboxPresenter = new CheckboxPresenter(parentCheckboxLabel, value).withDisabled(disabled)
    }
  }

  @action
  public initializeChildrenCheckboxes = (childrenCheckboxLabels: string[], disabled) => {
    if (childrenCheckboxLabels && this.selectedViews) {
      this.childrenCheckboxPresenters = childrenCheckboxLabels.map((childLabel: string) => {
        const value = this.selectedViews && this.selectedViews.includes(childLabel) ? true : false
        return new CheckboxPresenter(childLabel, value).withDisabled(disabled)
      })
    }
  }

  public withParentCheckboxMiddleware = (middleware: (value: boolean) => boolean): this => {
    this.parentCheckboxPresenter.withMiddleware(middleware)
    return this
  }

  public withChildCheckboxMiddleware = (middleware: (value: boolean) => boolean): this => {
    this.childrenCheckboxPresenters.forEach(childCheckboxPresenter => {
      childCheckboxPresenter.withMiddleware(middleware)
    })
    return this
  }
}
