// @ts-nocheck
import styled from 'styled-components'
import { px2rem } from 'app/Styles/Variables'

interface IOptionDetailsWrapperProps {
  show?: boolean
}

export const OptionDetailsWrapper = styled.div`
  display: ${(props: IOptionDetailsWrapperProps) => (props.show ? 'block' : 'none')};

  > div {
    padding: ${px2rem(4)} 0;
  }
`
