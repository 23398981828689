import { memo } from 'react'

import { RenderMultiSelectOptionProps, resolveColor, TeamListItem, UserListItem, useTheme } from '@cutover/react-ui'
import { RecipientType } from './types'

type SelectedItemProps = RenderMultiSelectOptionProps<RecipientType> & {
  item: RecipientType
  disabled?: boolean
}

// TODO: update with User/Team list items
// useMemo is necessary here because the tooltip internally uses a timeout so we
// need to avoid triggering a re-render of the item when it is being removed
export const RecipientListItem = memo(
  ({ item: recipient, onDeselect, highlighted, selected, disabled }: SelectedItemProps) => {
    const theme = useTheme()
    if (recipient.type === 'team') {
      return (
        <TeamListItem
          id={recipient.id}
          size="small"
          usersCount={recipient.users_count}
          name={recipient.name}
          color={recipient.color}
          draggable={false}
          onClickRemove={!disabled && onDeselect ? () => onDeselect(recipient) : undefined}
          disabled={disabled}
        />
      )
    } else {
      const { status } = recipient
      const userStatus =
        status === 'active'
          ? 'active'
          : status === 'archived' || recipient.archived
          ? 'archived'
          : recipient.not_in_account
          ? 'not_in_account'
          : status === 'invited'
          ? 'invited'
          : undefined
      const hasError = status === 'invalid' || status === 'not_allowed'
      return (
        <UserListItem
          id={recipient.id ?? -1} // TODO: could make id optional on UeerListItem if certain status or hasError?
          size="small"
          // @ts-ignore Will update this when we create a transformer for all the user types to one structure
          firstName={recipient.firstName ?? recipient.first_name}
          // @ts-ignore Will update this when we create a transformer for all the user types to one structure
          lastName={recipient.lastName ?? recipient.last_name}
          online={recipient.online}
          color={hasError ? resolveColor('text-disabled', theme) : recipient.color}
          active={highlighted}
          onClickRemove={!disabled && onDeselect ? () => onDeselect(recipient) : undefined}
          data-testid={`user-select-list-item-${selected ? 'selected' : 'unselected'}`}
          disabled={disabled}
          status={userStatus}
          hasError={hasError}
          handle={recipient.handle}
          titleSuffix={status === 'not_allowed' ? '(Not allowed)' : status === 'invalid' ? '(Invalid)' : undefined}
          draggable={false}
        />
      )
    }
  }
)
