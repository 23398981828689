import { ThemeType as GrommetThemeType } from 'grommet/themes'
import { SetRequired } from 'type-fest'

type GrommetTheme = SetRequired<GrommetThemeType, 'text'>
type GrommetText = GrommetTheme['text']

export const text: Partial<GrommetText> = {
  small: {
    size: '13px',
    height: '18.2px'
  },
  medium: {
    height: '21px',
    size: '15px'
  },
  large: {
    size: '15px'
  }
}

export type ThemeText = typeof text
