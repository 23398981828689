import { useMutation } from 'react-query'

import { apiClient } from '../api'
import { ApiError } from 'main/services/api/http-gateway-adapter'

type BulkArchiveRunbooksRequestType = {
  runbook_ids: number[]
}

export function useBulkArchiveRunbooks(runbook_ids: number[]) {
  return useMutation<unknown, ApiError, BulkArchiveRunbooksRequestType>(
    [...runbook_ids.map(runbookId => `bulk-archive-runbooks-${runbookId}`)],
    async payload => {
      await apiClient.patch({
        url: 'runbooks/bulk_archive',
        data: payload
      })
    }
  )
}
