// @ts-nocheck
import { observable, action } from 'mobx'
import { PermissableRepository } from 'Shared/Repositories/PermissableRepository'
import { IBaseResponse } from 'Gateways/Service/IBaseResponse'

export interface IRecord {
  id?: number
}

export interface IRecordDTO {
  id: number
}

export abstract class GenericRecordRepository extends PermissableRepository {
  @observable
  public selectedId: number = null

  protected recordDTO: IRecordDTO

  @observable
  public recordForEdit: IRecord = null

  public abstract saveRecord: (viewModel) => Promise<IBaseResponse>
  public abstract createRecord: (viewModel) => Promise<IBaseResponse>
  protected abstract url: string

  public deleteRecord = async (recordId: number) => {
    return await this.serviceGateway.delete(this.url + '/' + recordId)
  }

  constructor(key: string) {
    super(key)
  }

  @action
  public clearRecordForEdit = () => {
    this.recordForEdit = null
    this.selectedId = null
  }
}
