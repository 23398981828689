import { DashboardComponentProps, MrdDonutData } from '../../types'
import { BrowserWidget, EmailWidget } from '../dashboard-donut-widget'

type LatenessWidgetProps = DashboardComponentProps<MrdDonutData>

export const LatenessWidget = (props: LatenessWidgetProps) => {
  const { media } = props

  switch (media) {
    case 'screen':
      return <BrowserWidget {...props} />
    case 'email':
      return <EmailWidget {...props} />
    case 'print':
      return <EmailWidget {...props} />
  }
}
