// @ts-nocheck
import { injectable } from 'inversify'
import { CheckboxPresenter } from 'Components/Molecules'
import { observable, computed } from 'mobx'
import { IRole } from 'Shared/Entities/Types/RoleType/IRole'

@injectable()
export class RolePresenter {
  @observable
  public checkboxPresenter: CheckboxPresenter

  constructor(checkboxLabel: string, helpText?: string) {
    this.checkboxPresenter = new CheckboxPresenter(checkboxLabel).withHelpText(helpText)
  }

  public reset = () => {
    this.checkboxPresenter.reset()
  }

  @computed
  public get isDirty(): boolean {
    return this.checkboxPresenter.isDirty
  }

  public setDisabled = (disabled: boolean) => {
    this.checkboxPresenter.disabled = disabled
  }

  public prePopulate = (role: IRole) => {
    this.checkboxPresenter.value = true
  }
}
