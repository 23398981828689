import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const UserList = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="UserList" {...props}>
    <path d="M24 16a7 7 0 1 1-14 0 7 7 0 0 1 14 0Zm-3 0a4 4 0 1 0-8 0 4 4 0 0 0 8 0Zm20-2H27v3h14v-3Zm0 9H27v3h14v-3Zm0 9h-8v3h8v-3Zm-24-6c6.29 0 11.45 4.84 11.959 11 .027.33.041.663.041 1a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2c0-.337.014-.67.041-1C5.55 30.84 10.71 26 17 26Zm0 3a9.001 9.001 0 0 0-8.945 8h17.89A9 9 0 0 0 17 29Z" fill="#000" />
  </StyledIcon>
))

UserList.displayName = 'UserList'

export { UserList }
