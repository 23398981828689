import { MouseEvent, SyntheticEvent } from 'react'

import { Box, Icon, Text } from '@cutover/react-ui'
import {
  getRecipientString,
  getScheduleString
} from 'main/components/dashboards/runbook-dashboard-schedule/runbook-dashboard-schedule-formatter'
import { Frequency } from 'main/components/dashboards/runbook-dashboard-schedule/runbook-dashboard-schedule-types'
import { useLanguage } from 'main/services/hooks'

export type RunbooksDashboardScheduleItemProps = {
  title: string
  numberOfRecipients: number
  scheduleType: Frequency
  scheduleTime: string
  days?: number[]
  timezone: string
  filter?: string | undefined
  onClick?: (event: MouseEvent<HTMLDivElement>) => void
  onDeleteClick?: (event: SyntheticEvent) => void
}

export const RunbooksDashboardScheduleItem = ({
  title,
  numberOfRecipients,
  scheduleType,
  scheduleTime,
  days,
  timezone,
  filter,
  onClick,
  onDeleteClick
}: RunbooksDashboardScheduleItemProps) => {
  const { t } = useLanguage('dashboard', { keyPrefix: 'scheduleTile' })

  return (
    <Box
      direction="column"
      gap="6px"
      pad="16px"
      role="button"
      onClick={onClick}
      background="bg-1"
      round="xsmall"
      css="cursor: pointer"
    >
      <Box align="center" justify="between" direction="row">
        <Text weight="bold">{title}</Text>
        <Icon
          css="margin-right: -4px; margin-top: -3px;"
          color="text-light"
          icon="delete"
          size="small"
          onClick={onDeleteClick}
          data-testid="delete-managed-schedule-button"
        />
      </Box>
      <Text>
        {getRecipientString(numberOfRecipients)}, {getScheduleString(scheduleType, scheduleTime, timezone, days)}
      </Text>
      <Box>
        {filter ? (
          <>
            <Text weight="bold" size="small">
              {t('filter')}:{' '}
            </Text>
            <Text>{filter}</Text>
          </>
        ) : (
          <Text weight="bold" size="small">
            {t('noFilter')}
          </Text>
        )}
      </Box>
    </Box>
  )
}
