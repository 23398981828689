// @ts-nocheck
import { container } from 'app/Config/IOC'
import { injectable, postConstruct } from 'inversify'
import { observable, computed, action, Lambda, observe } from 'mobx'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'
import { Types } from 'Gateways/Service/Types'
import { RoutingState } from 'app/Routing/RoutingState'
import { Router } from 'app/Routing/Router'
import { FiltersRepository } from 'app/Filters/FiltersRepository'
import { IPageVM } from 'app/Shared/Entities/IPageVM'
import { IBaseResponse } from 'Gateways/Service/IBaseResponse'
import { ITaskTypesListItem } from 'Shared/Entities/Types/TaskType/ITaskTypesListItem'
import { TaskTypesRepository } from 'app/Repositories/TaskType/TaskTypesRepository'
import { TaskTypeRepository } from 'app/Repositories/TaskType/TaskTypeRepository'
import { ITaskTypeVM } from 'Shared/Entities/Types/TaskType/ITaskTypeVM'
import { ITaskTypePM } from 'Shared/Entities/Types/TaskType/ITaskTypePM'
import { ITaskTypeNewPM } from 'Shared/Entities/Types/TaskType/ITaskTypeNewPM'
import { AccountsRepository } from 'app/Repositories/Account/AccountsRepository'
import { CustomFieldsRepository } from 'app/Repositories/CustomField/CustomFieldsRepository'
import { ISortParams } from 'Components/Organisms'
import { setReactDirty } from 'Shared/Helpers/SPASharedStateService'
import { toggleAnimateCutoverLogo } from 'Shared/Helpers/NavLogoAnimation'
import { IGenericFilter } from 'app/Filters/FilterEntities'
import { FeatureFlagService } from 'Shared/Helpers/FeatureFlagService'

@injectable()
export class TaskTypesViewPresenter {
  private accountsRepository: AccountsRepository = container.get(AccountsRepository)
  private filtersRepository: FiltersRepository = container.get(FiltersRepository)
  private taskTypesRepository: TaskTypesRepository = container.get(TaskTypesRepository)
  private taskTypeRepository: TaskTypeRepository = container.get(TaskTypeRepository)
  private customFieldsRepository: CustomFieldsRepository = container.get(CustomFieldsRepository)
  private routingState: RoutingState = container.get(RoutingState)
  private router: Router = container.get(Router)
  private language: ILanguageService = container.get(Types.ILanguageService)
  private featureFlagService: FeatureFlagService = container.get(FeatureFlagService)

  @postConstruct()
  public init() {
    this.load()
    this.router.registerRouteLeaveCallback({ routeId: 'taskTypes', callback: this.clear })
    this.router.registerRouteEnterCallback({ routeId: 'taskTypes', callback: this.load })
  }

  @action
  private load = async () => {
    this.loading = true
    toggleAnimateCutoverLogo(true)
    this.router.setCheckBeforeAction(() => {
      return this.pageVM.editPanelDirty
    })
    await this.loadAccounts()
    this.buildFilterDependencies()
    this.buildAccountDependencies()
    await this.loadTaskTypes()
    this.setObservers()
    this.showArchivedTaskTypes = this.routingState.currentState.query.includes('archived=1')
    toggleAnimateCutoverLogo(false)
    this.loading = false
  }

  @action
  private clear = () => {
    this.filterVM = []
    this.pageVM.editPanelOpen = false
    this.filtersRepository.clearPathFilters()
    this.clearDisposers()
    setTimeout(() => this.clearEditPanel(), 500)
  }

  @computed
  public get totalTaskTypes(): number {
    return this.listVM.length
  }

  @observable
  public pageVM: IPageVM = {
    editPanelLoading: false,
    editPanelOpen: false,
    editPanelDirty: false,
    filterPanelLoading: true,
    archiveModalOpenState: false
  }

  @observable
  public filterVM: IGenericFilter[] = []

  @observable
  public accountsVM: { [key: number]: string } = {}

  public settingsVM: { [key: string]: string } = {
    highlight: this.language.get('taskTypes:filter:filterGroupSettings:labels:highlight'),
    auto_start: this.language.get('taskTypes:filter:filterGroupSettings:labels:autoStart'),
    auto_finish: this.language.get('taskTypes:filter:filterGroupSettings:labels:autoFinish'),
    conditional_progression: this.language.get('taskTypes:filter:filterGroupSettings:labels:conditionalProgression'),
    enable_start_fixed: this.language.get('taskTypes:filter:filterGroupSettings:labels:enableStartFixed'),
    enable_end_fixed: this.language.get('taskTypes:filter:filterGroupSettings:labels:enableEndFixed')
  }

  public settingsVMInverse: { [key: string]: string } = {
    highlight: this.language.get('taskTypes:filter:filterGroupSettings:labelsInverse:highlight'),
    auto_start: this.language.get('taskTypes:filter:filterGroupSettings:labelsInverse:autoStart'),
    auto_finish: this.language.get('taskTypes:filter:filterGroupSettings:labelsInverse:autoFinish'),
    conditional_progression: this.language.get(
      'taskTypes:filter:filterGroupSettings:labelsInverse:conditionalProgression'
    ),
    enable_start_fixed: this.language.get('taskTypes:filter:filterGroupSettings:labelsInverse:enableStartFixed'),
    enable_end_fixed: this.language.get('taskTypes:filter:filterGroupSettings:labelsInverse:enableEndFixed')
  }

  public otherVM: { [key: string]: string } = {
    default: this.language.get('taskTypes:filter:filterGroupOther:labels:default')
  }
  public otherVMInverse: { [key: string]: string } = {
    default: this.language.get('taskTypes:filter:filterGroupOther:labelsInverse:default')
  }

  @observable
  public loadingFilters = false

  @observable
  public submitted: boolean = false

  @observable
  public loading: boolean = false

  @observable
  public reloadList: boolean = false

  @observable
  public showArchivedTaskTypes = false

  @observable
  public disposers: Lambda[] = []

  @action
  public setSubmitted = (val: boolean) => {
    this.submitted = val
  }

  @observable
  public sortParams: ISortParams = {
    accessor: 'name',
    dataType: 'string',
    sortDirection: 'asc'
  }

  public loadAccounts = async () => {
    await this.accountsRepository.safeLoadPermittedAccounts({ resource: 'task-type' })
  }

  public loadTaskTypes = async () => {
    return await this.taskTypesRepository.loadData({ sortParams: this.sortParams })
  }

  public loadTaskType = (taskId: number) => {
    if (this.checkEditPanelIsDirty()) return
    this.taskTypeRepository.loadData({ id: taskId })
  }

  private setObservers = () => {
    const loadingObserver = observe(this.taskTypeRepository, 'loading', () => {
      this.pageVM.editPanelLoading = this.taskTypeRepository.loading
    })
    const editObserver = observe(this.taskTypeRepository, 'taskTypeForEdit', () => {
      if (this.taskTypeRepository.taskTypeForEdit) {
        this.pageVM.editPanelOpen = true
      }
    })

    const queryObserverDisposer = observe(this.routingState.currentState, 'query', async () => {
      if (this.routingState.currentState.routeId === 'taskTypes') {
        this.showArchivedTaskTypes = this.routingState.currentState.query.includes('archived=1')
        this.taskTypesRepository.loadData()
      }
    })

    const formIsDirtyObserverDisposer = observe(this.pageVM, 'editPanelDirty', () => {
      setReactDirty(this.pageVM.editPanelDirty)
    })

    this.disposers = [loadingObserver, editObserver, queryObserverDisposer, formIsDirtyObserverDisposer]
  }

  @action
  public buildFilterDependencies = () => {
    const filters: IGenericFilter[] = []
    this.pageVM.filterPanelLoading = false

    filters.push({
      key: {
        reference: 'global',
        resourceId: 'true'
      },
      type: 'boolean',
      label: this.language.get('common:filterSection:filters:Global:title'),
      group: this.language.get('taskTypes:filter:filterGroupVisibility:title'),
      urlTrueValueOverride: 'true',
      groupPills: true
    })

    // account filters
    this.accountsRepository.permittedAccounts.forEach(account => {
      filters.push({
        key: {
          reference: 'account_id',
          resourceId: account.id.toString()
        },
        type: 'resource',
        label: account.name,
        group: this.language.get('taskTypes:filter:filterGroupVisibility:title'),
        groupPills: true
      })
    })

    // settings filters
    this.settingsVM.linkable = this.language.get('taskTypes:filter:filterGroupSettings:labels:linkable')
    this.settingsVMInverse.linkable = this.language.get('taskTypes:filter:filterGroupSettings:labelsInverse:linkable')

    for (let [key] of Object.entries(this.settingsVM)) {
      filters.push({
        key: {
          reference: key
        },
        type: 'boolean',
        label: this.getLabelForSettingReference(key, false),
        group: this.language.get('taskTypes:filter:filterGroupSettings:title'),
        groupPills: true,
        canBeInverse: true,
        inverseLabel: this.getLabelForSettingReference(key, true)
      })
    }

    // default filter
    filters.push({
      key: {
        reference: 'default'
      },
      type: 'boolean',
      label: this.getLabelForOtherReference('default', false),
      group: this.language.get('taskTypes:filter:filterGroupOther:title'),
      groupPills: true,
      canBeInverse: true,
      inverseLabel: this.getLabelForOtherReference('default', true)
    })

    // creating a filter to show archived taskTypes
    filters.push({
      key: {
        reference: 'archived'
      },
      type: 'boolean'
    })

    this.filterVM = filters
    this.filtersRepository.setPageFilters(this.filterVM)
  }

  @action
  private clearDisposers = () => {
    this.disposers.forEach(disposer => disposer())
    this.disposers = []
  }

  @computed
  public get listVM(): ITaskTypesListItem[] {
    if (this.taskTypesRepository.taskTypes && this.taskTypesRepository.taskTypes.length > 0) {
      return this.taskTypesRepository.taskTypes.map((taskTypePM: ITaskTypePM) => {
        return {
          id: taskTypePM.id,
          name: taskTypePM.name,
          default: taskTypePM.default,
          accountName: taskTypePM.accountName,
          accountId: taskTypePM.accountId,
          global: taskTypePM.global
        }
      })
    }
    return []
  }

  public getSelectedFilters = (): IGenericFilter[] => {
    return this.filtersRepository.selectedFilters
  }

  public setPageVMValue = (key: string, value: boolean) => {
    this.pageVM[key] = value
  }

  public clearFilters = () => {
    let filterReferencesToIgnore: string[]
    if (this.showArchivedTaskTypes) filterReferencesToIgnore = ['archived']
    this.filtersRepository.clearPathFilters(filterReferencesToIgnore)
    this.router.refreshFilteredRoute()
  }

  public getLabelForAccountResource = (reference: string, resourceId: string): string => {
    if (resourceId === 'true') return this.language.get('taskTypes:filter:filterGlobal:title')
    return this.accountsVM[resourceId]
  }

  public getLabelForSettingReference = (reference: string, inverse: boolean): string => {
    return inverse ? this.settingsVMInverse[reference] : this.settingsVM[reference]
  }

  public getLabelForOtherReference = (reference: string, inverse: boolean): string => {
    return inverse ? this.otherVMInverse[reference] : this.otherVM[reference]
  }

  @computed
  public get repositoryLoading(): boolean {
    return this.taskTypesRepository.loading || this.loading
  }

  @computed
  public get taskTypesTotal(): number {
    return this.taskTypesRepository.taskTypesTotal
  }

  @computed
  public get taskTypesFilteredTotal(): number {
    return this.taskTypesRepository.taskTypesFilteredTotal
  }

  @computed
  public get taskTypeVM(): ITaskTypeVM {
    const taskTypePM = this.taskTypeRepository.taskTypeForEdit
    if (!taskTypePM) return null
    return taskTypePM
  }

  @action
  public updateTaskTypePm = (key: string, value: any) => {
    this.taskTypeRepository.taskTypeForEdit[key] = value
  }

  @action
  public buildAccountDependencies = () => {
    let accountLookup = {}

    this.accountsRepository.permittedAccounts.forEach(account => {
      accountLookup[account.id] = account.name
    })

    this.pageVM.filterPanelLoading = false
    this.accountsVM = accountLookup
  }

  public resetTaskTypeForEdit = () => {
    this.taskTypeRepository.setProgrammersModel()
  }

  public refreshTaskTypePm = () => {
    this.taskTypeRepository.refreshTaskTypeForEdit()
  }

  public setTaskTypeCreateProperty = (key: string, value: any) => {
    this.taskTypeRepository.taskTypeForCreation[key] = value
  }

  @computed
  public get taskTypeLoading(): boolean {
    return this.taskTypeRepository.loading
  }

  public saveTaskType = async (): Promise<IBaseResponse> => {
    toggleAnimateCutoverLogo(true)
    const response = await this.taskTypeRepository.saveTaskType()
    this.customFieldsRepository.resetTaskTypes()
    toggleAnimateCutoverLogo(false)
    return response
  }

  public createTaskType = async (taskType: ITaskTypeNewPM): Promise<IBaseResponse> => {
    toggleAnimateCutoverLogo(true)
    const response = await this.taskTypeRepository.createTaskType(taskType)
    this.customFieldsRepository.resetTaskTypes()
    toggleAnimateCutoverLogo(false)
    return response
  }

  public archiveTaskType = async () => {
    toggleAnimateCutoverLogo(true)
    const response = await this.taskTypeRepository.archive()
    this.customFieldsRepository.resetTaskTypes()
    toggleAnimateCutoverLogo(false)
    return response
  }

  @action
  public setArchiveModalOpenState = (changedState: boolean) => {
    this.pageVM.archiveModalOpenState = changedState
  }

  public getAccountIdFromVisibility = (account: string): number => {
    let foundAccountId
    for (const accountId in this.accountsVM) {
      const accountName = this.accountsVM[accountId]
      if (accountName === account) foundAccountId = parseInt(accountId, 10)
    }

    return foundAccountId
  }

  public getFirstAccount = (): number => {
    for (const accountId in this.accountsVM) {
      return parseInt(accountId, 10)
    }
  }

  public can = (permissionType: string) => {
    if (permissionType === 'update') {
      return this.taskTypeRepository.can(permissionType)
    } else if (permissionType === 'destroy') {
      return this.taskTypeRepository.can(permissionType)
    }
    return this.taskTypesRepository.can(permissionType)
  }

  public clearEditPanel = () => {
    this.taskTypeRepository.clearTaskTypeForEdit()
  }

  public checkEditPanelIsDirty = (): boolean => {
    if (this.pageVM.editPanelDirty) {
      return true
    }
    return false
  }

  @computed
  public get filtersCount(): number {
    return this.filtersRepository.selectedFilters.length
  }
}
