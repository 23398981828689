import { LegacyBox as Box, colors } from '@cutover/react-ui'
import { Chart } from './chart'
import { Duration } from './duration'
import { formatDuration, formatDurationDelta } from './format-duration'
import { NoDataAvailable } from './no-data-available'
import { DurationDelta, TaskDuration } from './types'

export type AverageRunbookDurationConnectedProps = {
  onEvent: (payload: any) => void
  name: string
  values: TaskDuration[]
}

export function AverageRunbookDurationConnected(props: AverageRunbookDurationConnectedProps) {
  const data: TaskDuration[] = props.values
  const task = data && data.length > 0 ? data[0] : undefined

  return (
    <Chart
      title={props.name}
      titleKey="dashboard:averageRunbookDuration:title"
      render={() => {
        const formattedDuration = formatDuration(task?.duration)

        if (!formattedDuration) {
          return <NoDataAvailable />
        }

        return <AverageRunbookDuration duration={formattedDuration} delta={task?.delta} />
      }}
    />
  )
}

type AverageRunbookDurationProps = {
  duration: string
  delta?: DurationDelta
}

function AverageRunbookDuration({ duration, delta }: AverageRunbookDurationProps) {
  const formattedDelta = formatDurationDelta(delta)

  return (
    <Box
      css={`
        display: flex;
        align-items: center;
        justify-content: center;
        height: 140px;
        background-color: ${colors.secondaryHover};
        border-radius: 8px;
      `}
    >
      <Duration duration={duration} delta={formattedDelta} />
    </Box>
  )
}
