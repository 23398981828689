// @ts-nocheck
import { useEffect } from 'react'
import { FilterDisclosurePanelPresenter } from './FilterDisclosurePanelPresenter'
import { observer } from 'mobx-react-lite'
import { Accordion } from 'Components/Molecules'
import { Checkbox } from 'Shared/Components/Molecules/Checkbox'

interface IFilterDisclosurePanelProps {
  presenter: FilterDisclosurePanelPresenter
}

export const FilterDisclosurePanel = observer(({ presenter }: IFilterDisclosurePanelProps) => {
  if (!presenter) return
  const { accordionPresenter, checkboxPresenters, updateAccordionState, selectedFiltersLength, filterPanelType } =
    presenter

  useEffect(() => {
    updateAccordionState()
  }, [selectedFiltersLength])

  const filterPanelDisplay = (panelType: string) => {
    switch (panelType) {
      case 'checkbox':
        return checkboxPresenters.map(presenter => (
          <Checkbox key={`${presenter.label.replace(/\s/g, '')}`} presenter={presenter} />
        ))
    }
  }

  return <Accordion presenter={accordionPresenter}>{filterPanelDisplay(filterPanelType)}</Accordion>
})
