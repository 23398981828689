import { useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { Banner, BannerProps, MessageType, useNotify } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { useBannerLayout } from 'main/components/layout/layout-hooks'
import { useCreateNewRunbookVersion } from 'main/services/queries/use-runbook-versions'
import { useNavigateToCurrentVersionAlias } from 'main/recoil/shared/nav-utils'
import {
  ActiveAccountModel,
  ActiveDashboardModel,
  ActiveRunbookModel,
  ActiveRunbookVersionModel,
  ActiveRunModel,
  DashboardModel,
  RunbookViewModel
} from 'main/data-access'
import { useRouting } from 'main/services/routing'

export const RunbookBanner = () => {
  const navigate = useNavigate()
  const routes = useRouting()

  const { is_template: isTemplate } = ActiveRunbookModel.useGet()

  const {
    stage,
    approval_status: approvalStatus,
    id,
    runbook_id: runbookId,
    is_current: isCurrent
  } = ActiveRunbookVersionModel.useGet()
  const run = ActiveRunModel.useGet()
  const getDashboard = DashboardModel.useGetByCallback()
  const { slug: accountSlug } = ActiveAccountModel.useGet()
  const activeDashboard = ActiveDashboardModel.useGet()

  const processRunbookVersionCreateResponse = ActiveRunbookVersionModel.useOnAction('create')
  const canCreateRunbookVersion = ActiveRunbookVersionModel.useCan('create')
  const { can: showPIRLink } = RunbookViewModel.usePermission('show:pir-link')

  const canResumeRun = ActiveRunModel.useCan('resume')
  const navigateToCurrentVersion = useNavigateToCurrentVersionAlias()

  const { mutate: createVersion } = useCreateNewRunbookVersion(runbookId)

  const openModal = RunbookViewModel.useAction('modal:open')
  const { showBannerLayout, hideBannerLayout } = useBannerLayout()
  const { t } = useLanguage('runbook', { keyPrefix: 'banner' })
  const notify = useNotify()
  const runType = run?.run_type

  const handleSaveVersion = async () => {
    createVersion(
      { base_version_id: id },
      {
        onSuccess: response => {
          processRunbookVersionCreateResponse(response)
        },
        onError: () => notify.error(t('createVersionError'))
      }
    )
  }

  const banner: BannerProps | undefined = useMemo(():
    | {
        message: string
        type: MessageType
        linkText?: string
        icon: boolean
        onClick?: any
        linkTextSecondary?: string
        onClickSecondary?: any
      }
    | undefined => {
    if (!isCurrent) {
      return {
        type: 'idea',
        message: t('message.oldVersion'),
        linkText: canCreateRunbookVersion
          ? isTemplate
            ? t('linkText.createNewTemplateVersion')
            : t('linkText.restoreVersion')
          : undefined,
        onClick: canCreateRunbookVersion ? handleSaveVersion : undefined,
        linkTextSecondary: t('linkText.goToCurrentVersion'),
        onClickSecondary: navigateToCurrentVersion,
        icon: false
      }
    }
    if (approvalStatus === 'pending') {
      return {
        message: t('message.draft'),
        type: 'idea',
        linkText: t('linkText.draft'),
        onClick: () => {
          console.log('approve template')
        },
        icon: false
      }
    }
    switch (stage) {
      case 'complete':
        if (activeDashboard?.key === 'pir') return
        if (runType === 'live') {
          return {
            message: t('message.complete'),
            type: 'idea',
            icon: false,
            linkText: showPIRLink ? t('linkText.viewPir') : undefined,
            onClick: showPIRLink
              ? async () => {
                  const dashboard = await getDashboard({ key: 'pir' })
                  if (!dashboard) return

                  navigate(routes.toDashboard({ accountSlug, runbookId, versionId: id, dashboardId: dashboard.id }))
                }
              : undefined
          }
        }
        if (runType === 'rehearsal') {
          return {
            message: t('message.rehearsalComplete'),
            type: 'info',
            linkText: canCreateRunbookVersion ? t('linkText.rehearsalComplete') : undefined,
            onClick: canCreateRunbookVersion ? handleSaveVersion : undefined,
            icon: false
          }
        }
      case 'cancelled':
        if (runType === 'live') {
          return {
            message: t('message.cancelled'),
            type: 'error',
            icon: true
          }
        }
      case 'paused':
        return {
          message: t('message.paused'),
          type: 'info',
          linkText: !!canResumeRun ? t('linkText.paused') : undefined,
          onClick: () => {
            openModal({ type: 'run-resume' })
          },
          icon: false
        }
      default:
        undefined
    }
  }, [
    showPIRLink,
    stage,
    runType,
    approvalStatus,
    t,
    openModal,
    canCreateRunbookVersion,
    handleSaveVersion,
    navigateToCurrentVersion
  ])

  useEffect(() => {
    // Reduces height of InnerContent so there is space for banner below
    if (banner) {
      showBannerLayout()
    } else {
      hideBannerLayout()
    }
    return () => {
      hideBannerLayout()
    }
  }, [banner])

  return (
    <>
      {banner ? (
        <Banner
          message={banner.message}
          type={banner.type}
          linkText={banner.linkText}
          icon={banner.icon}
          onClick={banner.onClick}
          linkTextSecondary={banner.linkTextSecondary}
          onClickSecondary={banner.onClickSecondary}
          children={banner.children}
        />
      ) : undefined}
    </>
  )
}
