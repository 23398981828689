import { Duration, getDuration, getDurationInDays } from './date-utils'
import { ColorProp } from '../theme'

// Must be in descending order of quantity size
const DURATION_MAP: Record<keyof Duration, string> = {
  years: 'y',
  months: 'mo',
  weeks: 'w',
  days: 'd',
  hours: 'h',
  minutes: 'm',
  seconds: 's'
}

/**
 * Formats a duration in the format `Xy Xmo Xw Xd Xh Xm Xs`.
 * Precision determines how many segments of time are included.
 */
export const duration = (amount: number, precision: number = 2) => {
  const durr = getDuration(Math.abs(amount))

  let formatted = ''
  let numAdded = 0

  Object.keys(DURATION_MAP).forEach(q => {
    const quantity = q as keyof Duration
    const count = durr[quantity]

    if (typeof count === 'number' && count > 0 && numAdded < precision) {
      formatted += formatted === '' ? '' : ' '
      formatted += `${count}${DURATION_MAP[quantity]}`
      numAdded += 1
    }
  })

  return formatted
}

type ElapsedDaysPrecision = 'days' | 'minutes' | 'seconds'

/**
 * Formats a duration in days in the format `XXd [HH:MM[:SS]]`.
 * Precision determines whether `HH:MM` or `HH:MM:SS` are included.
 */
export const elapsedDays = (amount: number, precision: ElapsedDaysPrecision = 'minutes') => {
  const durr = getDurationInDays(Math.abs(amount))

  let formatted = ''

  formatted += `${durr.days || 0}d `

  if (precision === 'days') {
    return formatted
  }

  const hours = durr.hours || 0
  formatted += `${hours < 10 ? '0' : ' '}${hours}:`

  const minutes = durr.minutes || 0
  formatted += `${minutes < 10 ? '0' : ''}${minutes}`

  if (precision === 'seconds') {
    const seconds = durr.seconds || 0
    formatted += `:${seconds < 10 ? '0' : ''}${seconds}`
  }

  return formatted
}

export const zeroPad = (num: number | undefined) => String(num).padStart(2, '0')
export const formatTimeDiff = (diff: {
  start: number
  end: number
}): {
  label?: string
  color?: ColorProp
  duration?: Duration
} => {
  if (!diff) return {}

  const label = duration(diff.end - diff.start)
  const isLate = diff.start > diff.end

  return {
    label: label ? `${isLate ? '+' : '-'}${label}` : '',
    color: isLate ? 'warning' : 'success'
  }
}

/**
 * Turns a duration string, eg '4d21h' or '3.5h' etc into a number of seconds
 */
export const durationParser = (durationString: string): number => {
  const regex = /(\d+(\.\d+)?)([dhms])/g
  let totalSeconds = 0
  let match

  while ((match = regex.exec(durationString)) !== null) {
    const value = parseFloat(match[1])
    const unit = match[3]

    switch (unit) {
      case 'd':
        totalSeconds += value * 24 * 60 * 60
        break
      case 'h':
        totalSeconds += value * 60 * 60
        break
      case 'm':
        totalSeconds += value * 60
        break
      case 's':
        totalSeconds += value
        break
    }
  }

  return totalSeconds
}
