import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Swap = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Swap" {...props}>
    <path d="m32.414 4 10.8 10.799-10.8 10.799-2.121-2.121 7.178-7.178H15v-3h22.47l-7.177-7.178L32.414 4ZM14.799 43.799 4 32.999l10.799-10.798 2.121 2.121L9.743 31.5h22.47v3H9.743l7.177 7.178-2.121 2.12Z" fill="#000" />
  </StyledIcon>
))

Swap.displayName = 'Swap'

export { Swap }
