import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const BrandAnalyze = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 100 100" a11yTitle="BrandAnalyze" {...props}>
    <g clipPath="url(#a)" fill="#fff"><path d="M0 35h29.333v64.667H0V35Zm35.333 35.333h29.334v29.334H35.333V70.333Z" opacity=".5" /><path d="M70.667 0H100v100H70.667V0Z" /></g>
  </StyledIcon>
))

BrandAnalyze.displayName = 'BrandAnalyze'

export { BrandAnalyze }
