import { useQuery, UseQueryOptions } from 'react-query'

import { queryClient as staticQueryClient } from 'main/query-client'
import { apiClient, apiClientCompat, ApiError } from 'main/services/api'
import { QueryKeys } from 'main/services/queries/query-keys'
import {
  Account,
  CustomField,
  CustomFieldGroup,
  Dashboard,
  RunbookTypeType,
  TaskType,
  User
} from 'main/services/queries/types'
import { ConfigModel } from 'main/data-access'

export type GetAccountResponseType = {
  account: Account
  meta: AccountResponseMeta
}

export type AccountResponseMeta = {
  permissions: {
    [key: string]: number[] | AccountViewPermissionsType
  }
  headers: {
    request_offset: number
  }
  task_types: TaskType[]
  runbook_types: RunbookTypeType[]
  custom_fields: CustomField[]
  dashboards: Dashboard[]
  custom_field_users: Pick<
    User,
    | 'archived'
    | 'avatar'
    | 'color'
    | 'first_name'
    | 'handle'
    | 'has_role_on_runbook'
    | 'id'
    | 'is_admin'
    | 'last_name'
    | 'name'
    | 'not_in_account'
    | 'online'
    | 'status'
    | 'subject_type'
  >[]
  custom_field_groups: CustomFieldGroup[]
  // TODO: add these
  // event_types
}

type AccountViewPermissionsType = any // TODO: type better

export const useGetAccountData = (
  { slug }: { slug?: string },
  options: UseQueryOptions<GetAccountResponseType, ApiError> = {}
) => {
  const isReactRunbookEnabled = ConfigModel.useIsFeatureEnabled('react_runbook')
  const enabled = (options.enabled ?? true) && !!slug
  return useQuery<GetAccountResponseType, ApiError>(
    [QueryKeys.Accounts, 'subdomain', slug],
    async () => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return getAccount(slug!, isReactRunbookEnabled ? (apiClientCompat as typeof apiClient) : undefined)
    },
    {
      enabled,
      ...options
    }
  )
}

let controller: AbortController | undefined

const getAccount = async (slug: string, customClient?: typeof apiClient) => {
  if (controller) controller.abort()
  controller = new AbortController()

  const signal = controller.signal
  const client = customClient || apiClient

  const { data } = await client.get<GetAccountResponseType>({
    url: `accounts/subdomain/${slug}`,
    headers: {
      // TODO: consolidate headers type for api-client. Currently conflicting because the headers type in
      // AuthServiceGateway is { [key: string]: string } but we want to allow numbers etc. (for example the case below)
      // @ts-ignore
      clientTime: new Date().getTime()
    },
    config: !!customClient
      ? {
          // @ts-ignore
          signal
        }
      : undefined
  })

  return data
}

// Using with recoil runbook work right now to easily support abort controller
export const fetchAccountQuery = async (slug: string) => {
  return staticQueryClient.fetchQuery({
    queryKey: [QueryKeys.Accounts, 'subdomain', slug],
    queryFn: () => getAccount(slug, apiClientCompat as any)
  })
}
