import { TaskListTask } from 'main/services/queries/types'

export type CriticalPathOptions = {
  from: number | null
  to: number
  float: number
}

type FloatLookupProps = Record<number, number>
/**
 * Best-effort TypeScript port from the original AngularJS version
 * @param filterData Use { from: null, to: 0, float: 0 } to get the absolute critical path
 * @returns Array of task ids within the critical path
 */
export const buildCriticalPath = (
  tasks: (TaskListTask & { float?: number })[],
  filterData: CriticalPathOptions
): { taskIds: number[]; floatLookup: FloatLookupProps } => {
  // 1. if an endpoint is specified, use that
  //    if not, find the tail tasks (within float threshold of latest end),
  //    could be more than 1
  // 2. for each of the tail tasks, generate the CP

  let i = 0
  // Was 0, but now date might be before Unix Epoch, take start calc back to before the big bang:
  // eslint-disable-next-line @typescript-eslint/no-loss-of-precision
  let latestEndDisplay = -99999999999999999999

  const isActiveAndWithinEndTime = (task: TaskListTask): boolean => {
    return latestEndDisplay - task.end_display <= filterData.float && task.stage !== 'complete'
  }

  // Note: surely recoil already provides 'lookup' functionality, should not have to rebuild one here
  // Note: since task predecessors/successor ids are actual ids, not internal_ids, we also need an ID lookup
  // These do not belong here since are used in lots of places
  let taskInternalIdToIdLookup = {} as Record<number, number>
  let taskLookup = {} as Record<number, any>

  // Note we could pass in taskLookup here instead of rebuilding, but leaving for now since we need the extra 'float' property
  for (i = 0; i < tasks.length; i++) {
    taskInternalIdToIdLookup[tasks[i].internal_id] = tasks[i].id
    taskLookup[tasks[i].id] = {
      id: tasks[i].id,
      internal_id: tasks[i].internal_id,
      stage: tasks[i].stage,
      predecessor_ids: tasks[i].predecessor_ids,
      successor_ids: tasks[i].successor_ids,
      start_display: tasks[i].start_display,
      end_display: tasks[i].end_display,
      float: 0
    }
  }

  const findByInternalId = (internalId: number) => {
    return taskLookup[taskInternalIdToIdLookup[internalId]]
  }

  const findById = (id: number) => {
    return taskLookup[id]
  }

  const getCalculationStartPointIds = (startInternalId: number): number[] => {
    if (startInternalId === 0) {
      // Show critical path for the plan
      // Find the tasks that have the latest end_display
      let tailTasksIds: number[] = []
      for (i = 0; i < tasks.length; i++) {
        if (!tasks[i].successor_ids.length) {
          // Tasks at the end don't have successors
          if (tasks[i].end_display > latestEndDisplay) {
            latestEndDisplay = tasks[i].end_display
          }
          tailTasksIds.push(tasks[i].id)
        }
      }

      let tasksWithEndDisplayWithinThreshold = []
      for (i = 0; i < tailTasksIds.length; i++) {
        let tailTask = findById(tailTasksIds[i])
        if (isActiveAndWithinEndTime(tailTask)) {
          tasksWithEndDisplayWithinThreshold.push(tailTasksIds[i])
          tailTask.float = latestEndDisplay - tailTask.end_display
        }
      }

      return tasksWithEndDisplayWithinThreshold
    } else {
      const task = findByInternalId(startInternalId)
      return task && task.stage !== 'complete' ? [task.id] : []
    }
  }

  const calculationStartPointTaskIds = getCalculationStartPointIds(filterData.to)
  const taskIds: number[] = []
  const iterator = (taskId: number) => {
    taskIds.push(taskId)

    let currentTask = findById(taskId)
    if (!currentTask) return

    let i = 0,
      n = 0

    // Was 0, but now date might be before Unix Epoch, take start calc back to before the big bang:
    // eslint-disable-next-line @typescript-eslint/no-loss-of-precision
    let latestPredEnd = -99999999999999999999

    let latestPreds = []

    // Collect latest ending predecessors
    for (i = 0, n = currentTask.predecessor_ids.length; i < n; i++) {
      let pred = findById(currentTask.predecessor_ids[i])
      if (pred && pred.end_display > latestPredEnd) {
        latestPredEnd = pred.end_display
        latestPreds = []
        latestPreds.push(pred)
      } else if (pred && pred.end_display === latestPredEnd) {
        latestPreds.push(pred)
      }
    }

    for (i = 0, n = latestPreds.length; i < n; i++) {
      let task = latestPreds[i]
      if (!task) continue
      let predFloat = currentTask.start_display - task.end_display
      let totalFloat = (currentTask.float || 0) + predFloat
      if (task.stage !== 'complete' && taskIds.indexOf(task.id) === -1) {
        task.float = totalFloat
        iterator(task.id)
      } else {
        // Visited
        if ((task.float || 0) < totalFloat) {
          task.float = totalFloat
        }
        continue // Don't need to continue up visited paths
      }
    }
  }

  for (i = 0; i < calculationStartPointTaskIds.length; i++) {
    iterator(calculationStartPointTaskIds[i])
  }

  // Since we can't modify original task object like in angular, build a float lookup
  const floatLookup = {} as FloatLookupProps

  for (const key in taskLookup) {
    if (taskLookup[key].float > 0) {
      floatLookup[key] = taskLookup[key].float
    }
  }

  return { taskIds, floatLookup }
}

export const findAllPredecessorIds = (
  fromTaskInternalId: number,
  tasks: TaskListTask[],
  taskLookup: { [key: number]: TaskListTask }
): number[] => {
  const taskIds: number[] = []

  const _collectTaskIds = (id: number) => {
    if (!id || taskIds.indexOf(id) !== -1) {
      return
    }
    const task = taskLookup[id]
    if (!task) {
      return taskIds
    }
    taskIds.push(task.id)
    for (var i = 0; i < task.predecessor_ids.length; i++) {
      _collectTaskIds(task.predecessor_ids[i])
    }
  }

  // Note: we should have an internalId to id lookup. But because the below only happens once here, it is ok
  const startTask = tasks.find(item => item.internal_id === fromTaskInternalId)
  if (!startTask) {
    return taskIds
  }
  _collectTaskIds(startTask.id)
  return taskIds
}
