import { useState } from 'react'

import { IconName, TextInput } from '@cutover/react-ui'
import { useComponentPropsAndState } from '../apps-state'
import { AppComponentNodeProps } from '../apps-types'

type TextFieldNodeProps = AppComponentNodeProps & {
  value: string
  label: string
  name: string
  placeholder: string
  icon?: IconName
  readOnly?: boolean
  error?: boolean
}

export const TextFieldNode = ({ appId, resourceId, id, ...props }: TextFieldNodeProps) => {
  const { componentProps, setState } = useComponentPropsAndState(appId, resourceId, id, props)
  const { value: initialValue, label, name, placeholder, icon, readOnly, error } = componentProps as TextFieldNodeProps

  const [value, setValue] = useState(initialValue)

  const updateFieldValue = (value: string) => {
    setValue(value)
    setState({ name, value })
  }

  return (
    <TextInput
      placeholder={placeholder}
      value={value}
      icon={icon}
      label={label ?? ''}
      onChange={e => updateFieldValue(e.target.value)}
      readOnly={readOnly}
      hasError={error}
    />
  )
}
