import { useMutation } from 'react-query'

import {
  ChangeRequest,
  ChangeRequestCreatePayload
} from 'main/components/runbook/pages/task-list-ng-connector/change-requests/change-request-types'
import { apiClient } from 'main/services/api/api-client'

export function useChangeRequestCreate() {
  return useMutation(async (payload: ChangeRequestCreatePayload) => {
    const { data } = await apiClient.post<ChangeRequestCreatePayload, { change_requests: ChangeRequest[] }>({
      url: `runbooks/${payload.runbook_id}/change_requests`,
      data: payload
    })

    return data?.change_requests as ChangeRequest[]
  })
}
