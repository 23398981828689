import { useCallback } from 'react'
import { format as formatDate, fromUnixTime } from 'date-fns'
import { eventManager } from 'event-manager'
import { NavLink, useNavigate } from 'react-router-dom'

import {
  Avatar,
  ColumnConfig,
  duration,
  IconButton,
  NoResourceFound,
  resolveColor,
  Table,
  Text,
  useTheme
} from '@cutover/react-ui'
import { useClearFilterParams } from 'main/components/shared/filter/filter-provider'
import { useMyTasksData } from 'main/services/api/data-providers/my-tasks/my-tasks-data'
import { useLanguage } from 'main/services/hooks'
import { MyTask } from 'main/services/queries/types/my-tasks'
import { useRouting } from 'main/services/routing/hooks'
import { ConfigModel } from 'main/data-access'

export const MyTasksTable = () => {
  const { t } = useLanguage('myWork', { keyPrefix: 'table.fields' })
  const navigate = useNavigate()
  const { isFetchingNextPage, tasks, fetchNextPage, isLoading, userLookup, teamLookup } = useMyTasksData()
  const isReactRunbookEnabled = ConfigModel.useIsFeatureEnabled('react_runbook')
  const { toRunbook } = useRouting()
  const now = Math.floor(Date.now() / 1000)
  const theme = useTheme()

  const sanitizeTaskStage = (stage: string) => {
    if (stage === 'default') {
      return <Text>{t('upcoming')}</Text>
    } else if (stage === 'in-progress') {
      return <Text>{t('inProgress')}</Text>
    } else {
      return stage.charAt(0).toUpperCase() + stage.slice(1)
    }
  }

  const startDisplayTooltip = (stage: string) => {
    if (stage === 'in-progress') {
      return t('forecastFinish')
    } else if (stage === 'default') {
      return t('plannedStart')
    } else {
      return t('forecastStart')
    }
  }

  const calculateActionDue = (stage: string, start_latest_planned: number, end_latest_planned: number) => {
    if (stage === 'in-progress') {
      return (
        <Text>
          {now - end_latest_planned > 0 ? (
            <>
              <span>{t('now')}</span>
              <span style={{ color: resolveColor('message-warning', theme) }}>
                {' '}
                (+{duration(now - end_latest_planned)})
              </span>
            </>
          ) : (
            <>
              <span>{formatDate(fromUnixTime(end_latest_planned), 'EE dd MMM HH:mm')}</span>
              <span style={{ color: resolveColor('message-success', theme) }}>
                {' '}
                (-{duration(now - end_latest_planned)})
              </span>
            </>
          )}
        </Text>
      )
    } else {
      return (
        <Text>
          {now - start_latest_planned > 0 ? (
            <>
              <span>{t('now')}</span>
              <span style={{ color: resolveColor('message-warning', theme) }}>
                {' '}
                (+{duration(now - start_latest_planned)})
              </span>
            </>
          ) : (
            <>
              <span>{formatDate(fromUnixTime(start_latest_planned), 'EE dd MMM HH:mm')}</span>
              <span style={{ color: resolveColor('message-success', theme) }}>
                {' '}
                (-{duration(now - start_latest_planned)})
              </span>
            </>
          )}
        </Text>
      )
    }
  }

  const assignedUsersTeams = (user_ids: number[], runbook_team_ids: number[]) => {
    if (!userLookup || !teamLookup) {
      return []
    }

    const users = user_ids.map(id => userLookup[id])
    const teams = runbook_team_ids.map(id => teamLookup[id])

    return [...users, ...teams]
  }

  const columns: ColumnConfig<MyTask>[] = [
    {
      property: 'name',
      header: t('name'),
      size: '250px',
      sortable: true
    },
    {
      property: 'runbook_name',
      header: t('runbookName'),
      render: (datum: MyTask) => (
        <Text>
          <NavLink
            to={toRunbook({ accountSlug: datum.account_slug, runbookId: datum.runbook_id })}
            css={{ textDecoration: 'none', color: 'inherit' }}
            onClick={event => event.stopPropagation()}
          >
            {datum.runbook_name}
          </NavLink>
        </Text>
      ),
      size: 'small',
      sortable: true
    },
    {
      property: 'stage',
      header: t('status'),
      render: (datum: MyTask) => sanitizeTaskStage(datum.stage),
      size: '70px',
      sortable: true
    },
    {
      property: 'start_latest_planned',
      header: t('actionDue'),
      render: (datum: MyTask) =>
        datum.start_latest_planned ? (
          <Text tip={startDisplayTooltip(datum.stage)}>
            {calculateActionDue(datum.stage, datum.start_latest_planned, datum.end_latest_planned)}
          </Text>
        ) : null,
      size: 'small',
      sortable: true
    },
    {
      property: 'description',
      render: (datum: MyTask) =>
        datum.description ? (
          <IconButton
            data-testid="description-icon"
            label={t('descriptionTooltip')}
            icon="page-text"
            onClick={() => {}}
          />
        ) : null,
      size: '40px',
      sortable: false
    },
    {
      property: 'assigned',
      render: (datum: MyTask) =>
        datum.user_ids && datum.runbook_team_ids ? (
          <Avatar
            subject={assignedUsersTeams(datum.user_ids, datum.runbook_team_ids)}
            tooltip
            tooltipPlacement="bottom"
          />
        ) : null,
      size: '40px',
      sortable: false
    }
  ]

  const handleClickRow = useCallback(({ datum }: { datum: MyTask }) => {
    eventManager.emit('user-navigated-from-task')
    navigate(
      toRunbook({
        accountSlug: datum.account_slug,
        runbookId: datum.runbook_id,
        params: isReactRunbookEnabled ? '' : `?task=${datum.internal_id}`
      })
    )
  }, [])

  const clearAll = useClearFilterParams()

  return (
    <>
      {!isLoading && tasks?.length === 0 ? (
        <NoResourceFound context="task" clearAllFilters={() => clearAll()} />
      ) : (
        <Table
          columns={columns}
          data={tasks ?? undefined}
          onClickRow={handleClickRow}
          onMore={fetchNextPage}
          isLoadingMore={isFetchingNextPage}
          isLoading={isLoading}
        />
      )}
    </>
  )
}
