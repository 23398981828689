import { CSSProperties, useCallback, useEffect, useMemo } from 'react'
import {
  Column,
  ColumnInstance,
  TableInstance,
  TableOptions,
  useBlockLayout,
  useSortBy,
  UseSortByColumnProps,
  UseSortByInstanceProps,
  UseSortByOptions,
  useTable
} from 'react-table'
import { FixedSizeList } from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'
import styled from 'styled-components/macro'

import { LegacyTableBase } from './legacy-table-base'
import { LegacyTableColumnConfig, LegacyTableRowIdType, LegacyTableSortingRule } from './legacy-table-types'
import { Icon } from '../../icon'
import { transition } from '../../utils'

/**
 * @deprecated Use Table instead
 */
export type LegacyTableProps<TRowType extends object> = {
  data: TRowType[]
  /** see `table.stories.tsx` file / comment for detailed instructions on column object setup */
  // https://react-table.tanstack.com/docs/api/useTable#column-options
  // Note that we are using handful of react-table column options (e.g. we don't allow nested columns)
  // label is header of the column
  // add highlightColor in columns config to highlight an entire column (including headers)
  // add rednerCell to render custom JSX into a cell value (see CustomContent example)
  columns: Record<LegacyTableRowIdType<TRowType>, LegacyTableColumnConfig<TRowType>>
  /** Whether sorting is done client side or server side */
  sort?: 'client' | 'server'
  /** Array of sortable columns */
  sortable?: LegacyTableSortingRule<TRowType>[]
  /** Initially sorted column*/
  sorted?: Required<LegacyTableSortingRule<TRowType>>
  /** Row click callback function*/
  onClickRow?: (row: TRowType) => void
  /** Sort click callback function*/
  onClickSort?: (id: LegacyTableRowIdType<TRowType>, dir: 'asc' | 'desc') => void
  /** Add to enable virtualized rows / infinite scrolling */
  infinite?: boolean
  /** height of the table */
  height?: number
  /** *Only applicable to infinite scrolling implementation via `react-query` (`useInfiniteQuery` props)*
   * Please see `InfiniteScrollLoading` story implementation for details
   */
  hasNextPage?: boolean
  /** *Only applicable to infinite scrolling implementation via `react-query` (`useInfiniteQuery` props)* */
  isNextPageLoading?: boolean
  /** *Only applicable to infinite scrolling implementation via `react-query` (`useInfiniteQuery` props)* */
  loadNextPage?: (startIndex: number, stopIndex: number) => void
  /** *Only applicable to infinite scrolling implementation* request batch size number */
  batchSize?: number
}

type ColumnType<TRowType extends object> = ColumnInstance<TRowType> &
  UseSortByColumnProps<TRowType> &
  Column<TRowType> & {
    highlightColor?: string
  }

export function LegacyTable<TRowType extends object>({
  columns: columnMap,
  data,
  onClickRow,
  onClickSort,
  sortable = [],
  sorted,
  sort = 'server',
  infinite = false,
  height,
  hasNextPage,
  isNextPageLoading,
  loadNextPage,
  batchSize = 40
}: LegacyTableProps<TRowType>) {
  const scrollBarSize = useMemo(() => scrollbarWidth(), [])
  const columns = useMemo(() => {
    const sortableColumnIds = sortable.map(s => s.id)

    return Object.entries(columnMap).map(([key, config]) => {
      const accessorKey = key as keyof typeof columnMap
      const { label, renderCell, accessor: passedAccessor, ...colProps } = config
      const colId = !passedAccessor || typeof passedAccessor === 'function' ? colProps.id ?? accessorKey : accessorKey
      const accessor = (passedAccessor ?? accessorKey) as any

      const col: Column<TRowType> & UseSortByOptions<TRowType> = {
        disableSortBy: Boolean(colId && !sortableColumnIds.includes(colId)),
        accessor,
        Header: label,
        ...colProps
      }

      if (renderCell) {
        col['Cell'] = renderCell
      }

      return col
    })
  }, [columnMap])

  const options: TableOptions<TRowType> & UseSortByOptions<TRowType> = {
    columns,
    data,
    manualSortBy: sort === 'server'
  }

  const table = useTable<TRowType>(options, useBlockLayout, useSortBy) as TableInstance<TRowType> &
    UseSortByInstanceProps<TRowType>

  const { getTableProps, getTableBodyProps, flatHeaders, rows, totalColumnsWidth, prepareRow } = table
  const loadMoreItems = isNextPageLoading
    ? () => {}
    : (startIndex: number, endIndex: number) => {
        loadNextPage?.(startIndex, endIndex)
      }
  const isItemLoaded = (index: number) => index < data.length && data[index] !== null
  const itemCount = hasNextPage ? data.length + batchSize : data.length

  useEffect(() => {
    if (sorted) {
      table.setSortBy([sorted])
    }
  }, [])

  const RenderRow = useCallback(
    ({ index, style }: { index: number; style?: CSSProperties }) => {
      const row = rows[index]
      prepareRow(row)
      const isClickable = Boolean(onClickRow)

      return (
        <LegacyTableBase.Row
          role={isClickable ? 'button' : undefined}
          tabIndex={isClickable ? 0 : undefined}
          onKeyPress={
            isClickable
              ? evt => {
                  console.log(evt)
                  if (evt.key === 'Enter') {
                    onClickRow?.(row.original)
                  }
                }
              : undefined
          }
          onClick={isClickable ? () => onClickRow?.(row.original) : undefined}
          {...row.getRowProps({
            style
          })}
        >
          {row.cells.map(cell => {
            const column = cell.column as ColumnType<TRowType>
            return (
              <LegacyTableBase.Cell highlight={column.highlightColor} {...cell.getCellProps()}>
                <div>{cell.render('Cell')}</div>
              </LegacyTableBase.Cell>
            )
          })}
        </LegacyTableBase.Row>
      )
    },
    [prepareRow, rows]
  )

  const handleClickSortBy = (column: ColumnInstance<TRowType> & UseSortByColumnProps<TRowType>) => {
    if (sort === 'server') {
      const initialSortDir = column.isSortedDesc ?? true ? 'asc' : 'desc'
      onClickSort?.(column.id, initialSortDir)
      table.setSortBy([{ id: column.id, desc: initialSortDir === 'desc' }])
    }
  }

  return (
    <LegacyTableBase {...getTableProps()}>
      <LegacyTableBase.Head tabIndex={-1}>
        <LegacyTableBase.Row style={{ width: totalColumnsWidth }}>
          {flatHeaders.map(col => {
            const column = col as ColumnType<TRowType>
            const isSortable = column.canSort
            const isSorted = column.isSorted
            const columnHeaderProps =
              sort === 'client'
                ? column.getHeaderProps([
                    { style: { backgroundColor: column.highlightColor } },
                    column.getSortByToggleProps()
                  ])
                : column.getHeaderProps([{ style: { backgroundColor: column.highlightColor } }])
            return (
              <LegacyTableBase.Header
                isSorted={isSorted}
                role={isSortable ? 'button' : undefined}
                tabIndex={isSortable ? 0 : undefined}
                onKeyPress={
                  isSortable
                    ? evt => {
                        if (evt.key === 'Enter') {
                          handleClickSortBy(column)
                        }
                      }
                    : undefined
                }
                onClick={isSortable ? () => handleClickSortBy(column) : undefined}
                {...columnHeaderProps}
              >
                <div>{column.render('Header')}</div>
                {isSorted ? (
                  <Icon icon={column.isSortedDesc ? 'arrow-down' : 'arrow-up'} color="primary" />
                ) : column.canSort ? (
                  <InitialSortArrow onClick={() => handleClickSortBy(column)} color="text-light" />
                ) : null}
              </LegacyTableBase.Header>
            )
          })}
        </LegacyTableBase.Row>
      </LegacyTableBase.Head>

      <LegacyTableBase.Body {...getTableBodyProps()}>
        {infinite ? (
          <InfiniteLoader isItemLoaded={isItemLoaded} itemCount={itemCount} loadMoreItems={loadMoreItems}>
            {({ onItemsRendered, ref }) => (
              <FixedSizeList
                height={height || 400}
                itemCount={rows.length}
                itemSize={40}
                width={totalColumnsWidth + scrollBarSize}
                ref={ref}
                onItemsRendered={onItemsRendered}
              >
                {RenderRow}
              </FixedSizeList>
            )}
          </InfiniteLoader>
        ) : (
          rows.map((r, i) => {
            return RenderRow({ index: i, style: {} })
          })
        )}
      </LegacyTableBase.Body>
    </LegacyTableBase>
  )
}

const InitialSortArrow = styled(Icon).attrs({
  icon: 'arrow-forward'
})`
  cursor: pointer;
  opacity: 0;
  ${transition('fast', 'opacity')};
  ${LegacyTableBase.Header}:hover & {
    opacity: 1;
  }
`

const scrollbarWidth = () => {
  // thanks too https://davidwalsh.name/detect-scrollbar-width
  const scrollDiv = document.createElement('div')
  scrollDiv.setAttribute('style', 'width: 100px; height: 100px; overflow: scroll; position:absolute; top:-9999px;')
  document.body.appendChild(scrollDiv)
  const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth
  document.body.removeChild(scrollDiv)
  return scrollbarWidth
}
