import { createContext, ReactNode } from 'react'
import { union } from 'lodash'

import { LoadingPage } from 'main/components/authentication/loading-page'
import { GlobalConfigType } from 'main/services/hooks'
import { useGetConfigs } from 'main/services/queries/use-get-configs'

export const GlobalConfigContext = createContext<GlobalConfigType>({} as unknown as GlobalConfigType)

type GlobalConfigProviderProps = {
  children: ReactNode
  config?: GlobalConfigType
}

export const GlobalConfigProvider = (props: GlobalConfigProviderProps) => {
  const { data, isLoading, isError } = useGetConfigs({ enabled: !props.config })
  const config = data || props.config

  // Allow enabling of feature flags via localStorage
  // Example: localStorage.setItem('enabledFeaturesFrontend', JSON.stringify(['react_runbook', 'feature2']))
  // To remove, use localStorage.removeItem('enabledFeaturesFrontend')
  if (config?.enabledFeatures && localStorage.getItem('enabledFeaturesFrontend')) {
    const localEnabledFeatures = JSON.parse(localStorage.getItem('enabledFeaturesFrontend') || '')
    console.warn(
      `!!!\n\tWARNING: You have enabled features via localStorage key "enabledFeaturesFrontend":\n\t${localEnabledFeatures}\n\n\tNote that these feature flags are only turned on in the frontend, if this flag is required in the backend you need to enable this via Instance Settings.\n!!!`
    )
    config.enabledFeatures = union(config.enabledFeatures, localEnabledFeatures)
  }

  return isLoading ? (
    <LoadingPage />
  ) : isError ? (
    <h1>Something went wrong.</h1>
  ) : config ? (
    <GlobalConfigContext.Provider value={config}>{props.children}</GlobalConfigContext.Provider>
  ) : null
}
