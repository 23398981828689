import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Key = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Key" {...props}>
    <path d="M33 19a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" fill="#000" /><path d="M21.629 18.344 9.255 30.717 9.258 38h7.775v-3.747l2.122-2.121h5.656v-2.829l3.064-3.063A10.003 10.003 0 0 0 38.6 24c3.905-3.905 3.905-10.237 0-14.142-3.905-3.905-10.237-3.905-14.142 0a9.985 9.985 0 0 0-2.829 8.486Zm5.512 4.387-5.33 5.33v1.07h-3.899l-3.879 3.88V35h-1.776l-.001-3.041L24.81 19.405l-.21-1.483a7 7 0 1 1 4.37 5.525l-1.828-.716Z" fill="#000" />
  </StyledIcon>
))

Key.displayName = 'Key'

export { Key }
