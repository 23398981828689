// @ts-nocheck
import { inject, injectable, postConstruct } from 'inversify'
import { GenericFormPresenter } from '@logicroom/validator'
import { computed, observable, observe, action, Lambda } from 'mobx'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'
import { Types } from 'Gateways/Service/Types'
import { AuthenticationRepository } from 'app/Authentication/AuthenticationRepository'
import { ConfigsRepository } from 'Configs/ConfigsRepository'
import { RoutingState } from 'app/Routing/RoutingState'
import { UserRepository } from 'app/Repositories/User/UserRepository'
import { UsersRepository } from 'app/Repositories/User/UsersRepository'
import { UserEditUserAppTokensPresenter } from 'app/Access/Users/UsersPage/UsersEdit/UserEditUserAppTokensPresenter'
import { UserEditOauthClientSessionsPresenter } from 'app/Access/Users/UsersPage/UsersEdit/UserEditOauthClientSessionsPresenter'
import { UserEditHistoryPresenter } from 'app/Access/Users/UsersPage/UsersEdit/UserEditHistoryPresenter'
import { UserEditResendInvitePresenter } from 'app/Access/Users/UsersPage/UsersEdit/UserEditResendInvitePresenter'
import { UserEditRolesPresenter } from 'app/Access/Users/UsersPage/UsersEdit/UserEditRolesPresenter'
import { UserEditSettingsPresenter } from 'app/Access/Users/UsersPage/UsersEdit/UserEditSettingsPresenter'
import { UserEditUnlockPresenter } from 'app/Access/Users/UsersPage/UsersEdit/UserEditUnlockPresenter'
import { ModalPresenter } from 'Components/Atoms'
import {
  AccordionPresenter,
  ErrorAlertPresenter,
  ButtonPresenter,
  DateTimePresenter,
  PhoneNumberPresenter,
  TextInputPresenter,
  ToasterService
} from 'Components/Molecules'
import { IUserPM } from 'Shared/Entities/Types/IUserPM'
import { TMode } from 'Components/Templates'
import { toggleAnimateCutoverLogo } from 'Shared/Helpers/NavLogoAnimation'
import { container } from 'app/Config/IOC'
import { RouteUpdater } from 'app/Routing/RouteUpdater'

@injectable()
export class UserEditPresenter {
  @inject(UserRepository)
  private userRepository: UserRepository

  @inject(UsersRepository)
  private usersRepository: UsersRepository

  @inject(ConfigsRepository)
  private configsRepository: ConfigsRepository

  @inject(AuthenticationRepository)
  private authenticationRepository: AuthenticationRepository

  @inject(ToasterService)
  private toasterGateway: ToasterService

  @inject(RoutingState)
  public routingState: RoutingState

  @inject(RouteUpdater)
  public routeUpdater: RouteUpdater

  @postConstruct()
  public bindClearFunction() {
    this.routeUpdater.registerRouteLeaveCallback({ routeId: 'users', callback: this.editPanelAfterCloseFunction })
  }

  public language: ILanguageService = container.get(Types.ILanguageService)

  @observable
  public roles: any = []

  @observable
  public errorAlertPresenter: ErrorAlertPresenter = new ErrorAlertPresenter()

  @observable
  public invitePresenter = new UserEditResendInvitePresenter()

  @observable
  public confirmModalPresenter: ModalPresenter = new ModalPresenter()

  public archiveTooltip: string = this.language.get('users:delete:tooltip')

  @observable
  public settingsPresenter = new UserEditSettingsPresenter()

  @observable
  public firstNamePresenter = new TextInputPresenter()
    .withLabel(this.language.get('users:form:firstName'))
    .isRequiredTrimWhiteSpace()

  @observable
  public lastNamePresenter = new TextInputPresenter()
    .withLabel(this.language.get('users:form:lastName'))
    .isRequiredTrimWhiteSpace()

  @observable
  public nickNamePresenter = new TextInputPresenter()
    .withIconPrefix('email')
    .withLabel(this.language.get('users:form:nickname:label'))
    .withHelpText(this.language.get('users:form:nickname:helpText'))
    .isRequiredTrimWhiteSpace()

  @observable
  public uniqueIdPresenter = new TextInputPresenter()
    .withLabel(this.language.get('users:form:uniqueId:label'))
    .isRequiredTrimWhiteSpace(this.language.get('users:form:uniqueId:isRequired'))
    .withHelpText(this.language.get('users:form:uniqueId:helpText'))

  @observable
  // @ts-ignore
  public mobileNumberPresenter = new PhoneNumberPresenter(this.language, this.configsRepository.countries || [])
    .withPhoneIcon()
    .withLabel(this.language.get('users:form:mobileNumber'))

  @observable
  public emailPresenter = new TextInputPresenter().withLabel(this.language.get('users:form:email')).isDisabled()

  @observable
  public memorableWordPresenter = new TextInputPresenter()
    .withLabel(this.language.get('users:form:memorableWord:label'))
    .isRequiredTrimWhiteSpace()
    .mustHaveMinLength(4)
    .withHelpText(this.language.get('users:form:memorableWord:helpText'))

  @observable
  public userAppTokensPresenter = new UserEditUserAppTokensPresenter()

  @observable
  public userOauthClientSessionsPresenter = new UserEditOauthClientSessionsPresenter()

  @observable
  public userRolesPresenter = new UserEditRolesPresenter()

  @observable
  public unlockUserPresenter = new UserEditUnlockPresenter()

  @observable
  public jobTitlePresenter = new TextInputPresenter().withLabel(this.language.get('users:edit:additionalInfo:jobTitle'))

  @observable
  public bioPresenter = new TextInputPresenter().withLabel(this.language.get('users:edit:additionalInfo:bio'))

  @observable
  public additionalInfoPresenter = new AccordionPresenter(
    this.language.get('users:edit:additionalInfo:title')
  ).withLeftIconName('info')

  @observable
  public statisticsPresenter = new AccordionPresenter(
    this.language.get('users:edit:statistics:title')
  ).withLeftIconName('statistics')

  @observable
  public loginCountPresenter = new TextInputPresenter()
    .withLabel(this.language.get('users:edit:statistics:loginCount'))
    .isDisabled()

  @observable
  public createdAtPresenter = new DateTimePresenter(null)
    .withLabel(this.language.get('users:edit:statistics:createdAt'))
    .isDisabled()

  @observable
  public invitationSentAtPresenter = new DateTimePresenter(null)
    .withLabel(this.language.get('users:edit:statistics:invitationSentAt'))
    .isDisabled()

  @observable
  public showInvitationSentAt: boolean = false

  @observable
  public invitationAcceptedAtPresenter = new DateTimePresenter(null)
    .withLabel(this.language.get('users:edit:statistics:invitationAcceptedAt'))
    .isDisabled()

  @observable
  public showInvitationAcceptedAt: boolean = false

  @observable
  public latestLoginPresenter = new DateTimePresenter(null)
    .withLabel(this.language.get('users:edit:statistics:latestLogin'))
    .isDisabled()

  @observable
  public userHistoryPresenter = new UserEditHistoryPresenter()

  @action
  public setRolesResetOpenState = (state: boolean) => {
    this.rolesResetOpenState = state
  }

  @observable
  public cancelResetButtonPresenter = new ButtonPresenter(this.language.get('common:cancelButton'), () => {
    this.rolesResetOpenState = false
  })
    .withDisplayStyle('secondary')
    .withIconName('dnd-forwardslash')
    .withTextTransform('Uppercase')

  @observable
  public continueResetButtonPresenter = new ButtonPresenter(this.language.get('common:continueButton'), () => {
    this.userRolesPresenter.confirmedReset = true
    this.rolesResetOpenState = false
    this.save(true, true)
  })
    .withDisplayStyle('primary')
    .withIconName('chevron-right')
    .withTextTransform('Uppercase')

  @action
  public archiveButtonCallback = () => {
    this.archiveOpenState = true
  }

  @observable
  public cancelArchiveButtonPresenter = new ButtonPresenter(this.language.get('common:cancelButton'), () => {
    this.archiveOpenState = false
  })
    .withDisplayStyle('secondary')
    .withIconName('dnd-forwardslash')

  @observable
  public continueArchiveButtonPresenter = new ButtonPresenter(this.language.get('common:archiveButton'), () => {
    this.userEditPanelClearing = true
    this.archive()
  })
    .withDisplayStyle('primary')
    .withIconName('trash-o')

  @observable
  public includeSSOId = false

  @observable
  public isSubmitted = false

  @observable
  public triggerScroll = false

  @observable
  public rolesResetOpenState = false

  @observable
  public userEditPanelClearing = false

  @observable
  public archiveOpenState = false

  @observable
  public editPanelSaving: boolean = false

  @observable
  public oauthClientSessions: any = []

  @action
  public setTriggerScroll = (trigger: boolean) => {
    this.triggerScroll = trigger
  }

  @observable
  public editForm = new GenericFormPresenter()
    .addFormInput(this.firstNamePresenter)
    .addFormInput(this.lastNamePresenter)
    .addFormInput(this.nickNamePresenter)
    .addFormInput(this.emailPresenter)
    .addFormInput(this.bioPresenter)
    .addFormInput(this.jobTitlePresenter)

  @action
  private setEditableUser = (user: IUserPM) => {
    this.firstNamePresenter.value = user.firstName || ''
    this.firstNamePresenter.disabled = !this.canUpdateUser
    this.lastNamePresenter.value = user.lastName || ''
    this.lastNamePresenter.disabled = !this.canUpdateUser
    this.nickNamePresenter.value = user.nickName || ''
    this.nickNamePresenter.disabled = !this.canUpdateUser
    this.mobileNumberPresenter.setCountryCode(user.countryCode || '')
    this.mobileNumberPresenter.setNumber(user.mobileNumber || '')
    this.mobileNumberPresenter.setDisabled(!this.canUpdateUser)
    this.emailPresenter.value = user.email || ''
    this.memorableWordPresenter.value = user.secretKey || ''
    this.memorableWordPresenter.disabled = !this.canUpdateUser
    this.uniqueIdPresenter.value = user.uniqueId || ''
    this.uniqueIdPresenter.disabled = this.editingCurrentUser || !this.canUpdateUser
    this.userAppTokensPresenter.setUser(user)
    this.userAppTokensPresenter.setDisabled(!this.canUpdateUser)
    this.userOauthClientSessionsPresenter.setUser(user)
    this.userRolesPresenter.setEditableUser(user)
    this.userRolesPresenter.setDisabled(this.editingCurrentUser || !this.canUpdateUser)
    this.settingsPresenter.setEditableUser(user)
    this.settingsPresenter.setDisabled(this.editingCurrentUser || !this.canUpdateUser)
    this.settingsPresenter.setDisabledCommunicationPrefs(
      this.editingCurrentUser || !this.canUpdateUser || user.provider === 'none'
    )
    this.bioPresenter.value = user.description || ''
    this.bioPresenter.disabled = !this.canUpdateUser
    this.jobTitlePresenter.value = user.jobTitle || ''
    this.jobTitlePresenter.disabled = !this.canUpdateUser
    this.loginCountPresenter.value = user.loginCount ? user.loginCount.toString() : '0'
    this.showInvitationSentAt = user.invitationSentAt ? true : false
    this.showInvitationAcceptedAt = user.invitationAcceptedAt ? true : false

    this.updateDateTimeDate(this.createdAtPresenter, user.createdAt)
    this.updateDateTimeDate(this.invitationSentAtPresenter, user.invitationSentAt)
    this.updateDateTimeDate(this.invitationAcceptedAtPresenter, user.invitationAcceptedAt)
    this.updateDateTimeDate(this.latestLoginPresenter, user.currentSignInAt)
  }

  private updateDateTimeDate = (dateTimePresenter: DateTimePresenter, newValue: Date) => {
    dateTimePresenter.isEnabled()

    if (newValue === null) {
      dateTimePresenter.initialiseComponent(null)
    } else {
      dateTimePresenter.handleDateChange(newValue)
    }

    dateTimePresenter.isDisabled()
  }

  @action
  private clearEditableUser = () => {
    this.firstNamePresenter.value = ''
    this.lastNamePresenter.value = ''
    this.nickNamePresenter.value = ''
    this.mobileNumberPresenter.reset()
    this.emailPresenter.value = ''
    this.memorableWordPresenter.value = ''
    this.uniqueIdPresenter.value = ''
    this.jobTitlePresenter.value = ''
    this.bioPresenter.value = ''
    this.userAppTokensPresenter.reset()
  }

  @computed
  public get formIsDirty(): boolean {
    const firstName = this.firstNamePresenter.isDirty
    const lastName = this.lastNamePresenter.isDirty
    const nickname = this.nickNamePresenter.isDirty
    const mobileNumber = this.mobileNumberPresenter.isDirty
    const email = this.emailPresenter.isDirty
    const bio = this.bioPresenter.isDirty
    const jobTitle = this.jobTitlePresenter.isDirty
    const settings = this.settingsPresenter.isDirty
    const roles = this.userRolesPresenter.isDirty

    let memorableWord = false
    if (this.editingCurrentUser) memorableWord = this.memorableWordPresenter.isDirty
    let uniqueId = false
    if (this.includeSSOId) uniqueId = this.uniqueIdPresenter.isDirty
    return (
      firstName ||
      lastName ||
      nickname ||
      mobileNumber ||
      email ||
      memorableWord ||
      uniqueId ||
      bio ||
      jobTitle ||
      settings ||
      roles
    )
  }

  @observable
  public userRepositoryObserserverDisposer: Lambda = null

  @action
  public load = async () => {
    this.userRepositoryObserserverDisposer = observe(this.userRepository, 'userForEdit', obj => {
      this.invitePresenter.reset()
      if (this.userRepository.userForEdit) {
        this.isSubmitted = false
        this.resetAlertPresenter()

        const canUpdate = this.userRepository.permissions.update[0]
        this.userOauthClientSessionsPresenter.canDeleteOauthClientSession = Boolean(canUpdate)

        this.setEditableUser(this.userRepository.userForEdit)
      } else {
        this.clearEditableUser()
      }
    })

    if (this.mobileNumberPresenter.countries.length === 0 && this.configsRepository.countries.length > 0) {
      this.mobileNumberPresenter = new PhoneNumberPresenter(this.language, this.configsRepository.countries)
        .withPhoneIcon()
        .withLabel(this.language.get('users:form:memorableWord:label'))
    }

    if (this.configsRepository.oauthUniqueId && this.configsRepository.oauthActive) {
      this.includeSSOId = true
      this.editForm.addFormInput(this.uniqueIdPresenter)
    }

    await this.userRolesPresenter.load()
  }

  private clearDisposers = () => {
    this.userRepositoryObserserverDisposer()
  }

  public clear = () => {
    this.close()
    this.clearDisposers()
  }

  @computed
  public get editingCurrentUser() {
    if (!this.userRepository.userForEdit) return false
    return this.authenticationRepository.user.id === this.userRepository.userForEdit.id
  }

  @computed
  public get mode(): TMode {
    if (this.userRepository.loading) return 'loading'
    if (this.userEditPanelClearing) return 'panel-closed'
    if (this.userLoaded) {
      return this.formIsDirty ? 'panel-open-confirm' : 'panel-open'
    }
    return 'panel-closed'
  }

  @computed
  public get canArchiveUser(): boolean {
    return !this.editingCurrentUser && this.userRepository.can('destroy')
  }

  @computed
  public get canUnlockUser(): boolean {
    return this.userRepository.can('unlock')
  }

  @computed
  public get canResendInvite(): boolean {
    return this.userRepository.can('update')
  }

  @computed
  public get canViewAppToken(): boolean {
    return this.authenticationRepository.canView('user_app_tokens')
  }

  @computed
  public get showOauthClientSessions(): boolean {
    return this.userOauthClientSessionsPresenter.user?.oauthClientSessions?.length > 0
  }

  @computed
  public get canUpdateUser(): boolean {
    return this.userRepository.can('update')
  }

  @action
  private resetAlertPresenter = () => {
    this.errorAlertPresenter.setErrorMessages([])
  }

  public reset = (closeAccordions = true) => {
    this.resetForm(closeAccordions)
    if (this.userRepository.userForEdit) {
      this.setEditableUser(this.userRepository.userForEdit)
    }
  }

  @action
  public close = () => {
    this.userEditPanelClearing = true
  }

  @action
  public editPanelAfterCloseFunction = () => {
    this.resetForm()
    this.userRepository.resetUserToEdit()
    this.userRepository.clearSelectedId()
    this.userEditPanelClearing = false
  }

  @action
  public resetForm = (closeAccordions: boolean = true) => {
    this.isSubmitted = false
    this.editForm.reset()

    let allFields = [
      this.firstNamePresenter,
      this.lastNamePresenter,
      this.nickNamePresenter,
      this.uniqueIdPresenter,
      this.memorableWordPresenter,
      this.bioPresenter,
      this.jobTitlePresenter,
      this.emailPresenter
    ]

    // TODO: Investigate why valid is false after reset for touched fields
    allFields.forEach(fieldPresenter => {
      fieldPresenter.reset()
      fieldPresenter.isDirty = false
      fieldPresenter.isValid = true
    })

    if (closeAccordions) {
      const accordianPresenters = [
        this.additionalInfoPresenter,
        this.statisticsPresenter,
        this.userAppTokensPresenter,
        this.userOauthClientSessionsPresenter,
        this.userRolesPresenter,
        this.userHistoryPresenter,
        this.settingsPresenter
      ]

      accordianPresenters.forEach(presenter => presenter.closeAccordion())
    }

    this.settingsPresenter.reset()
    this.userAppTokensPresenter.reset()
    this.userOauthClientSessionsPresenter.reset()
    this.userRolesPresenter.reset()
    this.mobileNumberPresenter.reset()
    this.resetAlertPresenter()
  }

  @computed
  public get checkFormIsValid() {
    const mainFormValid = this.editForm.isValid && this.mobileNumberPresenter.isValid
    if (this.editingCurrentUser) {
      if (
        this.mobileNumberPresenter.isValid &&
        (this.memorableWordPresenter.value.length === 0 || this.memorableWordPresenter.value.length < 4)
      ) {
        return false
      }
      return mainFormValid && this.memorableWordPresenter.isValid
    }
    return mainFormValid
  }

  @action
  public setArchiveState = (state: boolean) => {
    this.archiveOpenState = state
  }

  @action
  private setFormDirty() {
    this.firstNamePresenter.isDirty = true
    this.lastNamePresenter.isDirty = true
    this.nickNamePresenter.isDirty = true
    this.emailPresenter.isDirty = true
    this.uniqueIdPresenter.isDirty = true
    this.mobileNumberPresenter.setDirty()
    if (this.editingCurrentUser) this.memorableWordPresenter.isDirty = true
  }

  @action
  public save = async (reload?: boolean, closeOnSave = false) => {
    toggleAnimateCutoverLogo(true)
    this.editPanelSaving = true
    this.isSubmitted = true
    this.editForm.serverErrors = []
    this.resetAlertPresenter()

    if (!this.checkFormIsValid) {
      const specificErrorMessages = !this.mobileNumberPresenter.isValid
        ? [this.language.get('common:mobileNumberInvalidOnForm')]
        : this.memorableWordPresenter.value === ''
        ? [this.language.get('common:memorableWordRequired')]
        : []

      this.errorAlertPresenter.setErrorMessages([this.language.get('common:formInvalid'), ...specificErrorMessages])

      this.setFormDirty()
      this.setTriggerScroll(true)
      toggleAnimateCutoverLogo(false)
      this.editPanelSaving = false
      return
    } else if (this.userRolesPresenter.isReset && !this.userRolesPresenter.confirmedReset) {
      this.rolesResetOpenState = true
      this.editPanelSaving = false
      toggleAnimateCutoverLogo(false)
      return
    }

    const userBeingArchived = this.userRolesPresenter.confirmedReset
    const loginOptionBeingChanged = this.settingsPresenter.loginOptionPresenter.isDirty

    const user = {
      firstName: this.firstNamePresenter.value,
      lastName: this.lastNamePresenter.value,
      nickName: this.nickNamePresenter.value,
      email: this.emailPresenter.value || null,
      countryCode: this.mobileNumberPresenter.getCountryCode,
      mobileNumber: this.mobileNumberPresenter.getNumber || null,
      secretKey: this.memorableWordPresenter.value || undefined,
      uniqueId: this.uniqueIdPresenter.value.trim() || undefined,
      roles: this.userRolesPresenter.roleTypePresenter.selectedRoles,
      prefEmail: this.settingsPresenter.emailPreferencePresenter.value,
      htmlEmailPrefs: this.settingsPresenter.htmlEmailPreferencePresenter.value,
      acceptSMS: this.settingsPresenter.smsPreferencePresenter.value,
      description: this.bioPresenter.value,
      jobTitle: this.jobTitlePresenter.value,
      provider: this.settingsPresenter.providerValue
    }
    const response = await this.userRepository.saveUser(user)
    if (response.success) {
      this.reset(false)
      if (reload) await this.usersRepository.loadData({})
      this.doToaster(userBeingArchived, loginOptionBeingChanged)

      if (closeOnSave) {
        this.close()
      }
    } else {
      this.editForm.reset()
      this.setEditableUser(this.userRepository.userForEdit)
      this.editForm.serverErrors.push(...response.errorMessages)
      this.errorAlertPresenter.setErrorMessages([...response.errorMessages])
      this.setTriggerScroll(true)
    }
    toggleAnimateCutoverLogo(false)
    this.editPanelSaving = false
  }

  @action
  public archive = async () => {
    toggleAnimateCutoverLogo(true)
    const response = await this.userRepository.archive()

    if (response.success) {
      this.userRepository.resetUserToEdit()
      this.usersRepository.loadData({})
      const title = this.language.get('common:notification:successTitle')
      const notification = this.language.get('users:edit:archiveNotification:message')
      this.toasterGateway.pop(title, notification, 'success')
    }

    this.userEditPanelClearing = false
    this.archiveOpenState = false
    toggleAnimateCutoverLogo(false)
  }

  public doToaster = (userBeingArchived: boolean, loginOptionBeingChanged: boolean) => {
    const invitationAccepted =
      this.userRepository.userForEdit && this.userRepository.userForEdit.invitationAcceptedAt !== null

    let title = this.language.get('users:edit:saveUserNotifications:success')
    if (userBeingArchived) {
      const notification = this.language.get('users:edit:saveUserNotifications:lastRoleRemovedNotification')
      this.toasterGateway.pop(title, notification, 'success')
    } else if (loginOptionBeingChanged && invitationAccepted) {
      const notification = this.language.get('users:edit:saveUserNotifications:hasInvitationAccepted:logInOptionChange')
      this.toasterGateway.pop(title, notification, 'success')
    } else if (loginOptionBeingChanged) {
      title = this.language.get('users:edit:saveUserNotifications:logInOptionChange:title')
      const noneUser = this.userRepository.userForEdit.provider === 'none'
      const notification = noneUser
        ? ''
        : this.language.get('users:edit:saveUserNotifications:logInOptionChange:message')
      this.toasterGateway.pop(title, notification, 'success')
    } else {
      let notification = invitationAccepted
        ? this.language.get('users:edit:saveUserNotifications:invitationAccepted')
        : this.language.get('users:edit:saveUserNotifications:invitationNotAccepted')
      this.toasterGateway.pop(title, notification, 'success')
    }
  }

  @computed
  private get userLoaded() {
    return this.userRepository.userForEdit !== null
  }

  @computed
  public get panelHeading() {
    return this.editingCurrentUser
      ? this.language.get('users:edit:heading:currentUser')
      : this.language.get('users:edit:heading:otherUser')
  }

  @computed
  public get archiveConfirmName(): string {
    if (this.userRepository.userForEdit) {
      return this.userRepository.userForEdit.name
    }

    return ''
  }

  @computed
  public get memorableWordMessage(): string {
    if (!this.editingCurrentUser) return ''
    let currentWord = this.memorableWordPresenter.value
    currentWord = currentWord.replace(/\s/g, '')

    if (this.memorableWordPresenter.isDirty && currentWord.length < 4) {
      return this.language.get('users:edit:memorableWord:minimumFourChars')
    } else {
      return this.language.get('users:edit:memorableWord:passwordValidator')
    }
  }

  @action
  public toggleMemorableWordPresenter = (boolean: boolean) => {
    this.memorableWordPresenter.isValid = boolean
    this.memorableWordPresenter.isDirty = !boolean
  }

  @computed
  public get countryCodeValidator(): string {
    return !this.mobileNumberPresenter.countryPresenter.value
      ? this.language.get('userVerify:form:mobileNumber:requiresCountryCode')
      : ''
  }

  @computed
  public get phoneNumberValidator(): string {
    return this.mobileNumberPresenter.numberPresenter.isValid
      ? this.language.get('userVerify:form:mobileNumber:hasMinimumLength')
      : ''
  }
}
