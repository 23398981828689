// @ts-nocheck
import { observable, action } from 'mobx'
import { AlertMultiPresenter, AlertSinglePresenter } from '.'

export class ErrorAlertPresenter {
  @observable
  public errorMessages: string[] = []

  @observable
  public multiAlertPresenter = new AlertMultiPresenter()
    .withIconName('report-problem')
    .withState('error')
    .withMessages([])

  @observable
  public singleAlertPresenter = new AlertSinglePresenter().withIconName('report-problem').withState('error')

  @action
  public setErrorMessages = (errorMessages: string[]) => {
    if (errorMessages.length === 1) {
      this.singleAlertPresenter.withMessage(errorMessages[0])
    } else {
      const messages = errorMessages.map((message, index) => {
        return { key: index, message }
      })
      this.multiAlertPresenter.withMessages(messages)
    }

    this.errorMessages = errorMessages
  }

  @action
  public reset = () => {
    this.errorMessages = []
  }
}
