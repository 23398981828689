import { useState } from 'react'
import styled from 'styled-components'
import parse from 'html-react-parser'
import { Anchor } from 'grommet'

import { Box, Icon, Text, themeColor } from '@cutover/react-ui'
import { formatActivityDetailsValue, renderMessageMarkup } from './activity-helper'
import { ActivityVM } from 'main/services/queries/use-activities'
import { StyledLink } from './activity-header'
import { useAppsTimezoneValue } from 'main/recoil/data-access'

type ActivityDetailsProps = {
  activity: ActivityVM
  index: number
  scrollToActivity: (index: number) => void
  grouped: boolean
}

const DEFAULT_CHANGES_LINES: number = 1
const MIN_CHANGES_LINES: number = 2
export const DEFAULT_MESSAGE_CHARACTERS: number = 800

export const ActivityDetails = ({ activity, index, scrollToActivity, grouped }: ActivityDetailsProps) => {
  const { changes, display } = activity
  const timezone = useAppsTimezoneValue()
  const [showMore, setShowMore] = useState(true)
  const isChangesMinLines = changes && changes?.length > MIN_CHANGES_LINES
  const commentIsDeleted = !!activity.display?.reason
  const changesList = showMore && isChangesMinLines ? changes?.slice(0, DEFAULT_CHANGES_LINES) : changes
  const message = showMore ? display?.message?.substring(0, DEFAULT_MESSAGE_CHARACTERS) : display?.message
  const showToggle = isChangesMinLines || (display?.message && display?.message?.length > DEFAULT_MESSAGE_CHARACTERS)

  return (
    <ActivityDetailsWrapper flex={false} align="start" justify="start" featured={activity.featured}>
      {message && (
        <CommentWrapper grouped={grouped}>
          <Text
            color={commentIsDeleted ? 'text-disabled' : 'text-light'}
            css={`
              white-space: pre-wrap;
              line-height: 20px;
              font-style: ${commentIsDeleted ? 'italic' : ''};
            `}
          >
            {commentIsDeleted
              ? `Comment deleted (Reason: ${activity.display?.reason})`
              : parse(renderMessageMarkup(message) as string)}
          </Text>
        </CommentWrapper>
      )}
      {display?.attachments &&
        display?.attachments.map((attachment, index) => (
          <Anchor
            data-testid={`attachment-${activity.id}-${attachment.name}`}
            key={index}
            href={attachment.url}
            gap={'xxsmall'}
            weight={'normal'}
            size={'medium'}
            css={'outline: none;'}
            icon={<Icon icon="open-new" size="small" />}
            label={
              <Text
                css={`
                  &:hover {
                    text-decoration: underline;
                  }
                `}
              >
                {attachment.name}
              </Text>
            }
            target="_blank"
            rel="noopener noreferrer"
          />
        ))}
      {changesList?.map((param, key) => (
        <ChangesListWrapper
          alignContent="start"
          key={key}
          direction="row"
          pad={{ vertical: '2px' }}
          data-testid={`changes-list-activity-${activity.id}`}
          gap="small"
        >
          {param.field && (
            <Box alignSelf="start" width="150px" css="flex: 0 0 auto;">
              <Text truncate="tip" color="text-light" weight={600}>
                {param.field}
              </Text>
            </Box>
          )}
          {param.value && (
            <Box alignSelf="start" css="flex: 1;">
              <Text color="text-light">{parse(formatActivityDetailsValue(param.value, timezone))}</Text>
            </Box>
          )}
        </ChangesListWrapper>
      ))}
      {showToggle && (
        <Box
          onClick={() => {
            setShowMore(!showMore)
            scrollToActivity(index)
          }}
          pad={{ vertical: 'xxsmall' }}
        >
          <StyledLink>{showMore ? '+ Show more' : '- Show less'}</StyledLink>
        </Box>
      )}
    </ActivityDetailsWrapper>
  )
}

const ActivityDetailsWrapper = styled(Box)<{ featured: boolean }>`
  border-left: solid 2px ${props => (props.featured ? themeColor('star') : themeColor('bg-2-alpha'))};
  margin-left: 59px;
  padding: 0 23px;
`
const CommentWrapper = styled(Box)<{ grouped: boolean }>`
  padding: ${props => (props.grouped ? '6px' : '0')} 0 6px 0;
`

const ChangesListWrapper = styled(Box)`
  p {
    margin: 0;
  }
`
