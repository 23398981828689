import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const AlertDecrease = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="AlertDecrease" {...props}>
    <path d="m45.157 10.086 2.121 2.121-7.778 7.778-7.778-7.778 2.121-2.121 4.228 4.228V3h3v11.172l4.086-4.086ZM20.5 17.278h3v12h-3v-12Zm3 15h-3v3h3v-3Z" fill="#000" /><path d="M19.33 7.61c1.187-2.055 4.154-2.055 5.34 0l16.912 29.293c1.187 2.056-.296 4.625-2.67 4.625H5.088c-2.374 0-3.857-2.57-2.67-4.625L19.33 7.611Zm18.716 29.335-15.18-26.293a1 1 0 0 0-1.732 0L5.954 36.945a1 1 0 0 0 .866 1.5h30.36a1 1 0 0 0 .866-1.5Z" fill="#000" />
  </StyledIcon>
))

AlertDecrease.displayName = 'AlertDecrease'

export { AlertDecrease }
