// @ts-nocheck
import styled, { css } from 'styled-components'
import { ItemType } from './ParentChildCheckboxPresenter'
import { Icon } from 'Components/Atoms/Icon'
import { colors, px2rem } from 'app/Styles/Variables'
import { observer } from 'mobx-react-lite'
import { KeyCodeLookup } from 'app/Shared/Constants/Keycodes'

interface IStyledCheckboxProps {
  value: boolean
  disabled: boolean
  includeCheckedAndFocusedStyle?: boolean
}

const StyledCheckbox = styled.div`
  cursor: ${(props: IStyledCheckboxProps) => (props.disabled ? 'default' : 'pointer')};
  position: relative;
  margin-right: ${px2rem(8)};
  width: ${px2rem(18)};
  height: ${px2rem(18)};
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;
  outline: none;
  box-sizing: border-box;
  border-style: solid;
  border-radius: 4px;
  border-width: 1.5px;

  border-color: ${(props: IStyledCheckboxProps) => (props.value ? 'transparent' : colors.textLight)};
  background-color: ${(props: IStyledCheckboxProps) =>
    props.value ? (props.disabled ? colors.textDisabled : colors.primary) : ''};

  &:focus {
    ::after {
      left: -10px;
      top: -9px;
      right: -9px;
      bottom: -10px;
      opacity: 1;
    }

    ${(props: IStyledCheckboxProps) => {
      if (props.value && props.includeCheckedAndFocusedStyle) {
        return css`
          ::before {
            content: '';
            border-radius: ${px2rem(100)};
            height: ${px2rem(32)};
            width: ${px2rem(32)};
            position: absolute;
            top: -8px;
            left: -9px;
            background-color: ${colors.bgLight};
            z-index: -1;
          }
        `
      }
    }};
  }
`

interface IItemTypeComponentProps {
  itemType: ItemType
  onChange: any
  value: boolean
  handleClick?: (itemType: ItemType) => void
  disabled?: boolean
  includeCheckedAndFocusedStyle?: boolean
  id: string
  title: string
  placeholderText?: string
}

const Input = styled.input`
  &::placeholder {
    opacity: 0.5;
  }
  &:focus {
    outline-width: 0;
  }
`

export const ItemTypeComponent = observer<IItemTypeComponentProps>(props => {
  let { itemType, onChange, value, disabled, includeCheckedAndFocusedStyle, id, title, placeholderText } = props

  const handleChange = event => {
    event && event.stopPropagation()
    onChange(!value)
  }

  const handleKeyDown = key => {
    if (key.keyCode === KeyCodeLookup.space) {
      handleChange(key.event)
    }
  }

  return (
    <>
      {(() => {
        switch (itemType) {
          case ItemType.checkBox:
            return (
              <StyledCheckbox
                role="checkbox"
                value={value}
                aria-checked={value}
                onClick={handleChange}
                tabIndex={0}
                onKeyDown={handleKeyDown}
                disabled={disabled}
                includeCheckedAndFocusedStyle={includeCheckedAndFocusedStyle}
                id={id}
                title={title}
              >
                {value && <Icon name="check" color="white" extraStyles={{ marginTop: '-2px' }} />}
              </StyledCheckbox>
            )
          case ItemType.field:
            return (
              <span>
                <Icon name="add-to-list" size="20px" extraStyles={{ marginRight: 10 }} />
              </span>
            )
          case ItemType.input:
            return (
              <span>
                <Input
                  name="add-to-list"
                  style={{ marginRight: 10, border: 'none' }}
                  placeholder={placeholderText || ''}
                />
              </span>
            )
        }
      })()}
    </>
  )
})
