// @ts-nocheck
import { observable } from 'mobx'
import { container } from 'app/Config/IOC'
import { FilterDisclosurePanelPresenter } from 'Components/Molecules'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'
import { Types } from 'Gateways/Service/Types'

export class RoleTypesPageFilterPanelPresenter {
  private language: ILanguageService = container.get(Types.ILanguageService)

  @observable
  public contextPresenter = new FilterDisclosurePanelPresenter(
    this.language.get('roleTypes:filter:contextFilterPanel'),
    this.language.get('roleTypes:filter:contextFilterPanel')
  ).withCollapsed(false)
}
