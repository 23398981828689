// @ts-nocheck
import { injectable } from 'inversify'
import { container } from 'app/Config/IOC'
import { observable, computed } from 'mobx'
import { RunbookTypesViewPresenter } from 'app/Settings/RunbookTypes/RunbookTypesPage/RunbookTypesViewPresenter'
import { PillFilterPresenter, SearchBoxPresenter } from 'Components/Molecules'
import { RunbookTypesPageMoreOptionsPopoverPresenter } from './RunbookTypesPageMoreOptionsPopoverPresenter'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'
import { Types } from 'Gateways/Service/Types'

@injectable()
export class RunbookTypesPageHeadingPresenter {
  private runbookTypesViewPresenter: RunbookTypesViewPresenter = container.get(RunbookTypesViewPresenter)

  @observable
  public searchBoxPresenter: SearchBoxPresenter = new SearchBoxPresenter()

  @observable
  public runbookTypesPageMoreOptionsPopoverPresenter: RunbookTypesPageMoreOptionsPopoverPresenter =
    new RunbookTypesPageMoreOptionsPopoverPresenter()

  private language: ILanguageService = container.get(Types.ILanguageService)

  @computed
  public get filterVMLength(): number {
    return this.runbookTypesViewPresenter.filterVM.length
  }

  @computed
  public get runbookTypesTotal(): number {
    return this.runbookTypesViewPresenter.runbookTypesTotal
  }

  @computed
  public get runbookTypesFilteredTotal(): number {
    return this.runbookTypesViewPresenter.runbookTypesFilteredTotal
  }

  @observable
  public visibilityPillsPresenter = new PillFilterPresenter({
    group: this.language.get('common:filterSection:filterGroups:Visibility:title'),
    type: 'group'
  }).withTitle(this.language.get('common:filterSection:filterGroups:Visibility:title'))

  @observable
  public searchPillPresenter = new PillFilterPresenter({ type: 'single', key: { reference: 'query' } }).withTitle(
    this.language.get('common:headingSection:searchBox:pillLabel')
  )

  @computed
  public get pillCount(): number {
    return this.visibilityPillsPresenter.filterPills.length + this.searchPillPresenter.filterPills.length
  }

  @computed
  public get showClearFilterButton(): boolean {
    return this.pillCount > 1
  }

  public clearFilters = () => {
    this.searchBoxPresenter.clear()
    this.runbookTypesViewPresenter.clearFilters()
  }

  @computed
  public get showArchivedRunbookTypes(): boolean {
    return this.runbookTypesViewPresenter.showArchivedRunbookTypes
  }
}
