import { Children, createElement, RefObject, useEffect, useMemo, useState } from 'react'
import { AccordionExtendedProps, Accordion as GrommetAccordion } from 'grommet'
import { MergeExclusive } from 'type-fest'

import { useShowOpenedPanel } from './use-show-opened-panel'

type AccordionControlledProps = Omit<AccordionExtendedProps, 'animate'> & {
  iconColor?: string
  scrollContainer?: RefObject<HTMLElement> | string
}
type AccordionUncontrolledProps = Omit<AccordionControlledProps, 'activeIndex'> & {
  initialActiveIndex?: number | number[]
  disabledIndices?: number[]
}
type AccordionProps = MergeExclusive<AccordionUncontrolledProps, AccordionControlledProps & { controlled: true }> & {
  scrollContainer?: RefObject<HTMLElement> | string
}

export const Accordion = ({ controlled, ...props }: AccordionProps & { controlled?: boolean }) =>
  controlled ? <AccordionControlled {...props} /> : <AccordionUncontrolled {...props} />

const AccordionUncontrolled = ({
  children,
  onActive,
  initialActiveIndex = [],
  disabledIndices = [],
  ...props
}: AccordionUncontrolledProps) => {
  const initialActiveIndices = useMemo(
    () =>
      Array.isArray(initialActiveIndex) || initialActiveIndex === undefined ? initialActiveIndex : [initialActiveIndex],
    [initialActiveIndex]
  )
  const [activeIndices, setActiveIndices] = useState(initialActiveIndices)

  const includesDisabledPanel = (indexes: number[]) =>
    disabledIndices.length > 0 && indexes.filter(i => disabledIndices.includes(i)).length > 0

  const handleActive: typeof onActive = newActiveIndex => {
    if (includesDisabledPanel(newActiveIndex)) {
      setActiveIndices(prev => prev.filter(i => !disabledIndices.includes(i)))
      return
    }
    onActive?.(newActiveIndex)
    setActiveIndices(newActiveIndex)
  }

  useEffect(() => {
    if (includesDisabledPanel(activeIndices)) {
      setActiveIndices(prev => prev.filter(i => !disabledIndices.includes(i)))
    }
  }, [disabledIndices])

  return (
    <AccordionControlled {...props} activeIndex={activeIndices} onActive={handleActive}>
      {children}
    </AccordionControlled>
  )
}

const AccordionControlled = ({
  children,
  onActive,
  activeIndex,
  iconColor,
  multiple = true,
  scrollContainer,
  ...props
}: AccordionControlledProps) => {
  const [ref, setRef] = useState<HTMLDivElement | null>(null)
  useShowOpenedPanel({ scrollEl: scrollContainer, accordionEl: ref })

  return (
    <GrommetAccordion ref={setRef} activeIndex={activeIndex} onActive={onActive} multiple={multiple} {...props}>
      {Children.toArray(children)
        .filter(Boolean)
        .map((child: any, i) =>
          child
            ? createElement(child.type, {
                iconColor,
                ...child.props,
                key: i,
                className: 'accordion-panel'
              })
            : null
        )}
    </GrommetAccordion>
  )
}
