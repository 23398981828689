import { useCallback, useMemo } from 'react'
import { format } from 'date-fns'

import { ColumnConfig, Table, Text } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { useSystemParametersQuery } from 'main/services/queries/use-system-parameters-query'
import { SystemParameter } from 'main/services/queries/types/system-parameters'
import { useCloseRightPanelOnMount, useSetActiveRightPanelState } from 'main/components/layout/right-panel'

export const SystemParametersListPage = () => {
  const { t } = useLanguage('systemParameters')
  useCloseRightPanelOnMount()

  const { openRightPanel } = useSetActiveRightPanelState()
  const { data } = useSystemParametersQuery()
  const items = data?.system_parameters || []

  const systemParameterColumns: ColumnConfig<SystemParameter>[] = useMemo(() => {
    const columns = [
      {
        header: t('table.columnHeaders.display_name'),
        property: 'display_name',
        sortable: true
      },
      {
        header: t('table.columnHeaders.name'),
        property: 'name',
        sortable: true
      },
      {
        header: t('table.columnHeaders.value'),
        property: 'value',
        sortable: true
      },
      {
        header: t('table.columnHeaders.lastUpdated'),
        property: 'updated_at',
        sortable: true,
        render: (datum: SystemParameter) => {
          return <Text>{format(new Date(datum.updated_at), 'E, do MMM yyyy HH:mm')}</Text>
        }
      },
      {
        header: t('table.columnHeaders.createdAt'),
        property: 'created_at',
        sortable: true,
        render: (datum: SystemParameter) => {
          return <Text>{format(new Date(datum.created_at), 'E, do MMM yyyy HH:mm')}</Text>
        }
      }
    ]
    return columns
  }, [])

  const handleClickRow = useCallback(
    ({ datum }: { datum: SystemParameter }) => {
      openRightPanel({ type: 'system-parameters-edit', systemParameterId: datum?.id as number })
    },
    [openRightPanel]
  )

  return <Table columns={systemParameterColumns} data={items} onClickRow={handleClickRow} />
}
