import { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { Box, Button, colors, Heading, IconButton, Select, TextInput } from '@cutover/react-ui'
import { CustomFieldOptionType } from './integration-form-setting-fields'
import { useLanguage } from 'main/services/hooks'

export type ConditionRowProps = {
  options: CustomFieldOptionType[]
  conditionRowKey: string
}

export type PollingConditionMapping = {
  responsePath: string
  targetCustomField: string | number
  conditionValue: string
}

export type PollingConditionMappingProps = {
  customFieldOptions: CustomFieldOptionType[]
  value: { [rowKey: string]: PollingConditionMapping }
  onChange: (value: { [rowKey: string]: PollingConditionMapping }) => void
}

export const PollingConditionMapping = ({ customFieldOptions, onChange, value }: PollingConditionMappingProps) => {
  const [rows, setRows] = useState<{ [rowKey: string]: PollingConditionMapping }>({})
  const { t } = useLanguage('integrationSettings', { keyPrefix: 'responseMapping' })

  const addRow = () => {
    const newRows = Object.assign({}, rows)
    newRows[uuidv4()] = { responsePath: '', targetCustomField: '', conditionValue: '' }
    setRows(newRows)
  }

  const removeRow = (key: string) => {
    const newRows = Object.assign({}, rows)
    delete newRows[key]
    setRows(newRows)
    onChange(newRows)
  }

  const updateResponsePathValue = (key: string, value: string) => {
    rows[key].responsePath = value
  }

  const updateTargetCustomFieldValue = (key: string, value: string | number) => {
    const newRows = Object.assign({}, rows)
    newRows[key].targetCustomField = value
    setRows(newRows)
    onChange(newRows)
  }

  const updatePollingConditionValue = (key: string, value: string) => {
    rows[key].conditionValue = value
  }

  const handleChange = () => {
    onChange(rows)
  }

  useEffect(() => {
    if (value) {
      setRows(value)
    } else {
      setRows({})
    }
  }, [value])

  const ConditionRow = ({ options, conditionRowKey }: ConditionRowProps) => {
    return (
      <Box
        align="center"
        direction="row"
        gap="8px"
        css={`
          > div {
            flex: 1;
          }
        `}
      >
        <TextInput
          name={'response-path'}
          onChange={event => {
            updateResponsePathValue(conditionRowKey, event.target.value)
          }}
          onBlur={handleChange}
          defaultValue={rows[conditionRowKey].responsePath}
        />

        <Select
          value={rows[conditionRowKey].targetCustomField}
          onChange={value => {
            value && updateTargetCustomFieldValue(conditionRowKey, value)
          }}
          options={options}
        />

        <TextInput
          name={'response-value'}
          onChange={event => {
            updatePollingConditionValue(conditionRowKey, event.target.value)
          }}
          onBlur={handleChange}
          defaultValue={rows[conditionRowKey].conditionValue}
        />
        <IconButton
          label="Remove row"
          icon="close"
          size="medium"
          onClick={() => removeRow(conditionRowKey)}
          css={`
            flex: 0;
          `}
        />
      </Box>
    )
  }

  return (
    <Box>
      <Box
        direction="row"
        gap="8px"
        pad={{ right: '40px' }}
        css={`
          > * {
            flex: 1;
            min-width: 0;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        `}
      >
        <Heading
          css={`
            color: ${colors.textLight};
            font-size: 13px;
            font-weight: 400;
          `}
        >
          {t('responsePath')}
        </Heading>
        <Heading
          css={`
            color: ${colors.textLight};
            font-size: 13px;
            font-weight: 400;
          `}
        >
          {t('targetCustomField')}
        </Heading>
        <Heading
          css={`
            color: ${colors.textLight};
            font-size: 13px;
            font-weight: 400;
          `}
        >
          {t('conditionValue')}
        </Heading>
      </Box>
      {Object.keys(rows).map(id => (
        <ConditionRow conditionRowKey={id} options={customFieldOptions} key={id} />
      ))}
      <Box margin={{ bottom: '8px', top: Object.keys(rows).length ? '0' : '8px' }} direction="row" gap="small">
        <Button size="medium" secondary icon="add" label={t('common:addNewRow')} onClick={addRow} />
      </Box>
    </Box>
  )
}
