import { ReactNode } from 'react'

import { ItemSize } from './size-config'
import { ListItem } from '..'

// Internal component used to render UserListItem and TeamListItem

export type PeopleListItemLayoutProps = {
  id: number
  size?: ItemSize
  title: string
  titleSuffix?: string
  subtitle?: string
  onClick?: () => void
  onClickRemove?: (id: number) => void
  avatar: ReactNode
  active?: boolean
  hasError?: boolean
  endComponents?: ReactNode[]
  prominence?: 'high' | 'default' | 'low'
  'data-testid'?: string
  a11yTitle?: string
  disabled?: boolean
}

export const PeopleListItemLayout = ({
  id,
  size = 'medium',
  title,
  titleSuffix,
  subtitle,
  onClick,
  onClickRemove,
  endComponents,
  disabled,
  active,
  hasError,
  prominence = 'default',
  avatar,
  'data-testid': testId,
  a11yTitle
}: PeopleListItemLayoutProps) => {
  const handleClickRemoveItem = onClickRemove ? () => onClickRemove(id) : undefined

  return (
    <ListItem
      size={size}
      startComponent={avatar}
      endComponents={endComponents}
      onClick={onClick}
      disabled={disabled}
      onClickRemove={handleClickRemoveItem}
      title={title}
      titleSuffix={titleSuffix}
      hasError={hasError}
      subTitle={subtitle}
      a11yTitle={a11yTitle ?? title}
      active={active}
      prominence={prominence}
      data-testid={testId || 'people-item-name'}
    />
  )
}
