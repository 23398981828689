// @ts-nocheck
import { useState } from 'react'
import Select from 'react-select'
import styled from 'styled-components'
import { colors, px2rem } from 'app/Styles/Variables'
import { MultiSelectStyle } from 'app/Styles/Common'
import { Icon } from 'Components/Atoms'
import { MultiSelectWithCapabilitiesItem } from './MultiSelectWithCapabilitiesItem'
import { MultiSelectWithCapabilitiesPresenter } from '.'
import { observer } from 'mobx-react-lite'
import { SELECT_MENU_PORTAL } from 'app/Shared/Helpers/DOM'
import { scrollToView } from 'Shared/HooksAndPresenters/ScrollToView'

interface MultiSelectWithCapabilitiesProps {
  getFocused?: (focused: boolean) => void
  presenter: MultiSelectWithCapabilitiesPresenter
}

const WithSelectWrapper = styled.div`
  border-bottom: 1px dashed ${colors.primaryGreyHoverBackgroundColor};
`

const SelectLineWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`

const SelectComponentWrapper = styled.div`
  width: 100%;
  flex: 1;
  padding-left: -10px;
`

const LabelContainer = styled.div`
  font-size: 13px;
  line-height: 12px;
  color: ${colors.textLight};
  font-weight: 500;
  transition: none;
`

export const MultiSelectWithCapabilities = observer<MultiSelectWithCapabilitiesProps>(({ getFocused, presenter }) => {
  const {
    value,
    selectedOptions,
    deselectOption,
    selectOption,
    placeholder,
    markAsDirty,
    availableOptions,
    globalOptionId,
    contextId,
    label,
    inModal,
    triggerScroll,
    setTriggerScroll,
    showLabelOnLoad,
    disabled,
    language
  } = presenter

  const [isFocused, setFocused] = useState(false)
  const [showLabel, setShowLabel] = useState(showLabelOnLoad)

  const customSelectOption = option => {
    selectOption(option)
  }

  const blurHandler = () => {
    setFocused(!isFocused)
    getFocused && getFocused(!isFocused)
    markAsDirty()
  }

  const focusHandler = () => {
    setFocused(!isFocused)
    setShowLabel(true)
    getFocused && getFocused(!isFocused)
  }

  const bottomRef = scrollToView(triggerScroll, setTriggerScroll)

  let color = isFocused ? colors.primary : colors.textLighter
  const menuContainer = inModal ? document.getElementById(SELECT_MENU_PORTAL) : null

  return (
    <WithSelectWrapper>
      {showLabel ? <LabelContainer>{label}</LabelContainer> : null}
      {selectedOptions.map(option => {
        if (inModal) {
          option.selectPresenter.withInModal()
        }

        return (
          <MultiSelectWithCapabilitiesItem
            option={option}
            deselectOption={deselectOption}
            globalOptionId={globalOptionId}
            contextId={contextId}
            key={`${option.label}-${option.value}`}
            disabled={disabled}
            language={language}
          />
        )
      })}
      <div ref={bottomRef}></div>

      <SelectLineWrapper>
        <Icon name="add-to-list" extraStyles={{ lineHeight: '38px' }} color={colors.primaryGrey} />
        <SelectComponentWrapper>
          <Select
            menuPortalTarget={menuContainer}
            components={{
              DropdownIndicator: () => (
                <Icon
                  name="chevron-right"
                  rotation={90}
                  color={color}
                  extraStyles={{ cursor: 'pointer', position: 'absolute', right: '0', top: px2rem(5) }}
                />
              )
            }}
            options={availableOptions}
            placeholder={placeholder}
            onChange={customSelectOption}
            onBlur={blurHandler}
            onFocus={focusHandler}
            value={value}
            styles={MultiSelectStyle}
            isDisabled={disabled}
            maxMenuHeight={140}
          />
        </SelectComponentWrapper>
      </SelectLineWrapper>
    </WithSelectWrapper>
  )
})
