// @ts-nocheck
import { observer } from 'mobx-react-lite'
import { UserEditRolesPresenter } from './UserEditRolesPresenter'
import { Accordion, RoleTypes } from 'Components/Molecules'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'

interface IUserEditRolesComponent {
  presenter: UserEditRolesPresenter
  language: ILanguageService
}

export const UserEditRolesSection = observer<IUserEditRolesComponent>(({ presenter, language }) => {
  const { roleTypePresenter, accordionPresenter } = presenter

  return (
    <>
      <Accordion presenter={accordionPresenter}>
        {roleTypePresenter.loadingRoleTypes ? (
          language.get('common:loading')
        ) : (
          <RoleTypes presenter={roleTypePresenter} />
        )}
      </Accordion>
    </>
  )
})
