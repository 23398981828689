// @ts-nocheck
export function roleTypeDefaultViewPermissionHierarchy() {
  return {
    'Global settings': ['Task types', 'Custom fields', 'Dashboards', 'Runbook types', 'Workspaces'],
    'Access management': ['Users', 'Role types'],
    'Workspace settings': ['Settings', 'Folders', 'Centralised teams']
  }
}

export function roleTypeViewPermissionHierarchyMapping() {
  return {
    global_settings: {
      name: 'Global settings',
      children: ['Task types', 'Custom Fields', 'Dashboards', 'Runbook types', 'Workspaces']
    },
    instance_settings: {
      name: 'Instance Settings'
    },
    task_types: {
      name: 'Task types'
    },
    custom_fields: {
      name: 'Custom fields'
    },
    dashboards: {
      name: 'Dashboards'
    },
    runbook_types: {
      name: 'Runbook types'
    },
    accounts: {
      name: 'Workspaces'
    },
    access: {
      name: 'Access management',
      children: ['Users', 'Roles', 'Role types']
    },
    users: {
      name: 'Users'
    },
    roles: {
      name: 'Roles'
    },
    role_types: {
      name: 'Role types'
    },
    settings: {
      name: 'Workspace settings',
      children: ['Settings', 'Folders', 'Centralised teams']
    },
    integrations: {
      name: 'Integrations'
    },
    analytics: {
      name: 'Analytics'
    },
    user_app_tokens: {
      name: 'User App Tokens'
    },
    field_groups: {
      name: 'Field groups'
    },
    folders: {
      name: 'Folders'
    },
    teams: {
      name: 'Centralised teams'
    },
    'Global settings': {
      name: 'global_settings',
      children: ['task_types', 'custom_fields', 'dashboards', 'runbook_types', 'accounts']
    },
    'Task types': {
      name: 'task_types'
    },
    'Custom fields': {
      name: 'custom_fields'
    },
    Dashboards: {
      name: 'dashboards'
    },
    'Runbook types': {
      name: 'runbook_types'
    },
    Workspaces: {
      name: 'accounts'
    },
    'Access management': {
      name: 'access',
      children: ['users', 'roles', 'role_types']
    },
    Users: {
      name: 'users'
    },
    Roles: {
      name: 'roles'
    },
    'Role types': {
      name: 'role_types'
    },
    'Workspace settings': {
      name: 'settings',
      children: ['settings', 'folders', 'centralised_teams']
    },
    Folders: {
      name: 'folders'
    },
    'Centralised teams': {
      name: 'teams'
    }
  }
}

export default { roleTypeDefaultViewPermissionHierarchy, roleTypeViewPermissionHierarchyMapping }
