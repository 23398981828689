import { DashboardComponentProps } from '../../types'
import { MrdDashboardWidget } from '../mrd-dashboard-widget'
import {
  DurationDelta,
  DurationDisplay,
  formatDuration,
  formatDurationDelta,
  NoDataAvailable,
  TaskDuration,
  Tile
} from 'main/components/dashboards/widgets/account/common'

type ParticularTaskAverageDurationWidgetData = {
  name: string
  values: TaskDuration[]
}

type ParticularTaskAverageDurationWidgetProps = DashboardComponentProps<ParticularTaskAverageDurationWidgetData>

export const ParticularTaskAverageDurationWidget = ({ data }: ParticularTaskAverageDurationWidgetProps) => {
  const [value] = data.values
  const formattedDuration = value && value.duration ? formatDuration(value.duration) : undefined

  return (
    <MrdDashboardWidget title={data.name}>
      {!formattedDuration ? (
        <NoDataAvailable />
      ) : (
        <ParticularTaskAverageDuration
          taskTitle={value.name || data.name}
          duration={formattedDuration}
          delta={value.delta}
        />
      )}
    </MrdDashboardWidget>
  )
}

export type ParticularTaskAverageDurationProps = {
  taskTitle: string
  duration: string
  delta?: DurationDelta
}

export const ParticularTaskAverageDuration = ({ taskTitle, duration, delta }: ParticularTaskAverageDurationProps) => {
  const formattedDelta = formatDurationDelta(delta)

  return (
    <Tile title={taskTitle}>
      <DurationDisplay duration={duration} delta={formattedDelta} />
    </Tile>
  )
}
