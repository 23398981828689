// @ts-nocheck
import { observer } from 'mobx-react-lite'
import { withPresenter } from 'app/Config/withPresenter'
import { EditPanelFormWrapper } from 'Components/Templates'
import { RunbookTypeEditPresenter } from './RunbookTypeEditPresenter'
import { RunbookTypeFormPresenter } from 'app/Settings/RunbookTypes/RunbookTypesPage/RunbookTypeForm/RunbookTypeFormPresenter'
import { RunbookTypeFormComponent } from 'app/Settings/RunbookTypes/RunbookTypesPage/RunbookTypeForm/RunbookTypeFormComponent'

interface IRunbookTypeListComponentProps {
  presenter: RunbookTypeEditPresenter
  editFormPresenter: RunbookTypeFormPresenter
}

const RunbookTypeEditSection = observer<IRunbookTypeListComponentProps>(({ presenter, editFormPresenter }) => {
  const {
    sectionTitle,
    mode,
    closeCallback,
    save,
    reset,
    archiveButtonCallback,
    tooltipMessage,
    canArchiveRunbookType,
    editPanelSaving,
    confirmModalPresenter,
    triggerScroll,
    setTriggerScroll
  } = presenter

  presenter.withRunbookTypeFormPresenter(editFormPresenter)

  return (
    <EditPanelFormWrapper
      heading={sectionTitle}
      mode={mode}
      showArchive={canArchiveRunbookType}
      singleLineArchiveTooltip
      closeCallback={closeCallback}
      saveCallback={save}
      resetCallback={reset}
      archiveCallback={archiveButtonCallback}
      archiveTooltip={tooltipMessage}
      editPanelSaving={editPanelSaving}
      confirmModalPresenter={confirmModalPresenter}
      triggerScroll={triggerScroll}
      setTriggerScroll={setTriggerScroll}
    >
      <RunbookTypeFormComponent presenter={editFormPresenter} formType="edit" />
    </EditPanelFormWrapper>
  )
})

export default withPresenter(RunbookTypeEditPresenter)(RunbookTypeEditSection)
