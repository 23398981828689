// @ts-nocheck
import { observable, computed } from 'mobx'
import debounce from 'debounce-promise'
import { GenericFormInputPresenter } from '@logicroom/validator'
import { IMultiSelectItemProps } from './IMultiSelectItemProps'
import { MultiSelectUserItem } from './MultiSelectUserItem'
import { TIcons } from 'Components/Atoms'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'

export class MultiSelectPresenter extends GenericFormInputPresenter<string | number> {
  @observable
  public options: any[] = []

  @observable
  public language: ILanguageService

  @observable
  public label = ''

  @observable
  public selectedOptions: any[] = []

  @observable
  public allowOptionAddition: boolean = false

  @observable
  public asyncOptionsGetter: (inputText: string) => Promise<any[]>

  @observable
  public minSearchCharacters: number = 3

  @observable
  public loading: boolean = false

  @observable
  public inlineComponent? = false

  @observable
  public refresh: number = 1

  @observable
  public labelProperty: string = 'label'

  @observable
  public valueProperty: string = 'value'

  @observable
  public itemComponent: (props: IMultiSelectItemProps) => React.ReactElement

  @observable
  public placeholder: string

  @observable
  public helpText: string

  @observable
  public iconPrefix?: TIcons

  @observable
  public showDropdownIndicator?: boolean = false

  @observable
  public showSearchMessage?: boolean = false

  @observable
  public hiddenOptionAddedCallback: (optionLabel: string) => void

  @observable
  public showAllLimit: number = 10

  constructor(language: ILanguageService, initialValue?: string | number) {
    super(initialValue)
    this.language = language
    this.placeholder = this.language.get('components:molecules:multiSelectDefaults:searchPlaceholder')
  }

  private internallyHandleAsyncOptionsRequest = async (inputText: string): Promise<any[]> => {
    if (inputText.length < this.minSearchCharacters) {
      return Promise.resolve([])
    }
    this.loading = true
    const result = await this.asyncOptionsGetter(inputText).then(result => {
      return result.filter(option => !this.optionSelected(option))
    })
    this.loading = false
    return result
  }

  public handleAsyncOptionsRequest = debounce(this.internallyHandleAsyncOptionsRequest, 250)

  public withAsyncOptionsGetter = (getter: (inputText: string) => Promise<any[]>) => {
    this.asyncOptionsGetter = getter
    return this
  }

  public withMinSearchCharacters = (minSearchCharacters: number) => {
    this.minSearchCharacters = minSearchCharacters
    return this
  }

  public withOptions? = (options: any[]) => {
    this.options.push(...options)
    return this
  }

  public withOption = (option: any) => {
    this.options.push(option)
    return this
  }

  public markAsDirty = () => {
    this.isDirty = true
  }

  public addNewOptionWithValue? = (value: any) => {
    let option = {}
    option[this.valueProperty] = value
    option[this.labelProperty] = value
    this.options.push(option)
    return this
  }

  public withSelectedOptions? = (selectedOptions: any[]) => {
    this.selectedOptions = [...selectedOptions]
    return this
  }

  public withAllowOptionAddition(allowOptionAddition: boolean) {
    this.allowOptionAddition = allowOptionAddition
    return this
  }

  public withLabelProperty(labelProperty: string) {
    this.labelProperty = labelProperty
    return this
  }

  public withValueProperty(valueProperty: string) {
    this.valueProperty = valueProperty
    return this
  }

  public withItemComponent(itemComponent: (props: IMultiSelectItemProps) => React.ReactElement) {
    this.itemComponent = itemComponent
    return this
  }

  public withPlaceholder(placeholder: string) {
    this.placeholder = placeholder
    return this
  }

  public withUserSearchConfig(asyncUserSearch: (inputText: string) => Promise<any[]>) {
    this.withAsyncOptionsGetter(asyncUserSearch)
    this.withItemComponent(MultiSelectUserItem)
    this.withLabelProperty('name')
    this.withValueProperty('id')
    this.withPlaceholder(this.language.get('components:molecules:multiSelectDefaults:userSearchPlaceholder'))
    this.withIcon('person-add')
    return this
  }

  public withDropdownIndicator? = () => {
    this.showDropdownIndicator = true
    return this
  }

  public withSearchMessage? = () => {
    this.showSearchMessage = true
    return this
  }

  public withHelpText? = (helpText: string) => {
    this.helpText = helpText
    return this
  }

  public withLabel? = (label: string) => {
    this.label = label
    return this
  }

  public withIcon? = (icon: TIcons) => {
    this.iconPrefix = icon
    return this
  }

  public withHiddenOptionAddedCallback? = (callback: (optionLabel: string) => void) => {
    this.hiddenOptionAddedCallback = callback
    return this
  }

  public withShowAllLimit(limit: number) {
    this.showAllLimit = limit
    return this
  }

  public asInlineComponent? = () => {
    this.inlineComponent = true
    return this
  }

  @computed
  public get availableOptions(): any[] {
    let availableOptions = [...this.options]

    if (this.selectedOptions.length === 0) {
      return availableOptions
    }

    this.selectedOptions.map(option => {
      const index = availableOptions.findIndex(availableOption => {
        return availableOption[this.valueProperty] === option[this.valueProperty]
      })
      availableOptions.splice(index, 1)
    })

    return availableOptions
  }

  public deselectOption = (option: any) => {
    this.markAsDirty()
    const index = this.selectedOptions.findIndex(selectedOption => {
      return selectedOption[this.valueProperty] === option[this.valueProperty]
    })
    this.selectedOptions.splice(index, 1)
  }

  public selectOption = (option: any) => {
    this.markAsDirty()
    this.refresh++
    if (this.optionSelected(option)) return
    this.selectedOptions.push(option)
  }

  public optionSelected = (option: any) => {
    const index = this.selectedOptions.findIndex(selectedOption => {
      return selectedOption[this.valueProperty] === option[this.valueProperty]
    })
    return index > -1
  }
}
