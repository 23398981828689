import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const RunbookEdited = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="RunbookEdited" {...props}>
    <path d="M18.182 29.87h6.364L45.052 9.364 38.688 3 18.182 23.506v6.364Zm3-3v-2.12L38.688 7.243l2.121 2.121L23.303 26.87h-2.121ZM14 6h10v3H14a5 5 0 0 0-5 5v1H6v-1a8 8 0 0 1 8-8Z" fill="#000" /><path d="M6 33h3v1a5 5 0 0 0 5 5h20a5 5 0 0 0 5-5V24h3v10a8 8 0 0 1-8 8H14a8 8 0 0 1-8-8v-1Zm6-14H3v3h9v-3Zm0 7H3v3h9v-3Z" fill="#000" />
  </StyledIcon>
))

RunbookEdited.displayName = 'RunbookEdited'

export { RunbookEdited }
