import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const GraphBar = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="GraphBar" {...props}>
    <path d="M22.5 42.5V26h3v16.5h-3ZM6 33v9.5h3V33H6Zm33 9.5V6h3v36.5h-3ZM30.75 17v25.5h3V17h-3Zm-16.5 0v25.5h3V17h-3Z" fill="#000" />
  </StyledIcon>
))

GraphBar.displayName = 'GraphBar'

export { GraphBar }
