// @ts-nocheck

import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { Checkbox, CheckboxListPresenter } from '.'

const Wrapper = styled.div`
  display: block;
  min-height: 18px;
  min-width: 18px;
  line-height: 23px;
  padding: 3px 0px;
`

const ChildWrapper = styled.div`
  padding-left: 24px;
`

interface ICheckboxListProps {
  presenter: CheckboxListPresenter
  extraStyles?: CSSProperties
}

export const CheckboxList = observer<ICheckboxListProps>(({ extraStyles, presenter }) => {
  const { parentCheckboxPresenter, childrenCheckboxPresenters } = presenter

  const childrenCheckboxes = childrenCheckboxPresenters.map((childCheckboxPresenter, index) => {
    return <Checkbox presenter={childCheckboxPresenter} key={index} />
  })

  return (
    <Wrapper>
      <Checkbox presenter={parentCheckboxPresenter} />
      {childrenCheckboxes && <ChildWrapper style={extraStyles}>{childrenCheckboxes}</ChildWrapper>}
    </Wrapper>
  )
})
