import { apiClient } from 'main/services/api/api-client'

export type AuditEventsExportCsvRequestType = {
  file_name: string
  audit_type: string
}

export const downloadEventsLogExport = async () => {
  return await apiClient.get({
    url: 'download_audit_csv',
    params: {
      audit_type: 'integrations'
    }
  })
}
