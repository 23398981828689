// @ts-nocheck
import 'reflect-metadata'
import { computed, observable, action, observe } from 'mobx'
import { container } from 'app/Config/IOC'
import { inject, injectable, postConstruct } from 'inversify'
import { FiltersRepository } from 'app/Filters/FiltersRepository'
import { RoleTypesRepository } from 'app/Repositories/RoleType/RoleTypesRepository'
import { Router } from 'app/Routing/Router'
import { RoleTypeEditPresenter } from './RoleTypeEdit/RoleTypeEditPresenter'
import { RoleTypeListPresenter } from './RoleTypeList/RoleTypeListPresenter'
import { RoleTypesPageFilterPanelPresenter } from './RoleTypesPageFilterPanel/RoleTypesPageFilterPanelPresenter'
import { RoutingState } from 'app/Routing/RoutingState'
import { setReactDirty } from 'app/Shared/Helpers/SPASharedStateService'
import { CurrentPagePresenter } from 'app/Routing/CurrentPage/CurrentPagePresenter'
import { toggleAnimateCutoverLogo } from 'Shared/Helpers/NavLogoAnimation'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'
import { Types } from 'Gateways/Service/Types'
import { RouteUpdater } from 'app/Routing/RouteUpdater'
import { IGenericFilter } from 'app/Filters/FilterEntities'
@injectable()
export class RoleTypesPagePresenter {
  private roleTypesRepository: RoleTypesRepository = container.get(RoleTypesRepository)

  public filtersRepository: FiltersRepository = container.get(FiltersRepository)

  @inject(RoutingState)
  public routingState: RoutingState

  @inject(RouteUpdater)
  public routeUpdater: RouteUpdater

  private router: Router = container.get(Router)

  public language: ILanguageService = container.get(Types.ILanguageService)

  @observable
  public roleTypeEditPresenter = new RoleTypeEditPresenter()

  @observable
  public roleTypesPageFilterPanelPresenter = new RoleTypesPageFilterPanelPresenter()

  @observable
  public roleTypeListPresenter = new RoleTypeListPresenter()

  @observable
  private disposers: any[] = []

  @observable
  public loading: boolean = false

  @computed
  public get angularJSSettingsPanelOpenState(): boolean {
    return container.get(CurrentPagePresenter).angularJSSettingsPanelOpenState
  }

  @postConstruct()
  public init() {
    this.load()
    this.router.registerRouteLeaveCallback({ routeId: 'roleTypes', callback: this.clear })
    this.router.registerRouteEnterCallback({ routeId: 'roleTypes', callback: this.load })
  }

  private load = () => {
    this.setupObservers()
    this.loadData()
    this.roleTypeListPresenter
      .withClosePanelCallback(this.closePanel)
      .withcheckEditPanelIsDirtyCallback(this.checkEditPanelIsDirty)
  }

  @action
  private loadData = async () => {
    this.loading = true
    toggleAnimateCutoverLogo(true)
    this.roleTypesRepository.clearRoleData()
    await this.roleTypesRepository.safeLoad({})
    this.router.setCheckBeforeAction(this.checkEditPanelIsDirty)
    toggleAnimateCutoverLogo(false)
    this.loading = false
  }

  @action
  private setupObservers = () => {
    const queryObserverDisposer = observe(this.routingState.currentState, 'query', () => {
      if (this.routingState.currentState.routeId === 'roleTypes') {
        this.roleTypesRepository.safeLoad({})
      }
    })

    this.disposers.push(queryObserverDisposer)

    const formIsDirtyObserverDisposer = observe(this.roleTypeEditPresenter, 'formIsDirty', () => {
      setReactDirty(this.roleTypeEditPresenter.formIsDirty)
    })

    this.disposers.push(formIsDirtyObserverDisposer)

    const roleTypesDisposer = observe(
      this.roleTypesRepository,
      'roleTypesTotal',
      () => {
        if (this.roleTypesRepository.checkRolesLoaded()) {
          this.registerFilters()
        }
      },
      true
    )

    this.disposers.push(roleTypesDisposer)
  }

  public clear = () => {
    this.filtersRepository.clearPathFilters()
    this.clearDisposers()
    this.roleTypeEditPresenter.clear()
    this.roleTypeListPresenter.clearDisposers()
  }

  @action
  public clearDisposers = () => {
    if (this.disposers.length === 0) return
    this.disposers.forEach(disposer => disposer())
    this.disposers = []
  }

  @computed
  public get editOpen() {
    return this.roleTypeEditPresenter.mode !== 'panel-closed'
  }

  @computed
  public get editLoading() {
    return this.roleTypeEditPresenter.mode === 'loading'
  }

  @observable
  public editPanelClosedCallback = () => {
    this.roleTypeEditPresenter.editPanelAfterCloseFunction()
  }

  public closePanel = () => {
    this.roleTypeEditPresenter.close()
  }

  public checkEditPanelIsDirty = (): boolean => {
    if (this.roleTypeEditPresenter.formIsDirty) {
      return true
    }
    return false
  }

  @computed
  public get canCreateRoleType(): boolean {
    return this.roleTypesRepository.can('create')
  }

  private registerFilters = () => {
    const filters: IGenericFilter[] = []
    this.roleTypesRepository.roleTypesContext.forEach(context => {
      filters.push({
        key: {
          reference: 'context_type_id',
          resourceId: context.contextTypeId.toString()
        },
        type: 'resource',
        label: context.contextTypeName,
        group: this.language.get('roleTypes:filter:contextFilterPanel'),
        groupPills: true
      })
    })

    filters.sort((a, b): number => {
      const labelA = a.label.toUpperCase()
      const labelB = b.label.toUpperCase()

      let comparison = 0
      if (labelA > labelB) {
        comparison = 1
      } else if (labelA < labelB) {
        comparison = -1
      }

      return comparison
    })

    this.filtersRepository.setPageFilters(filters)
  }
}
