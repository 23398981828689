// @ts-nocheck
import { observer } from 'mobx-react-lite'
import styled, { css } from 'styled-components'
import { colors } from 'app/Styles/Variables'
import { PillContextPresenter } from './PillContextPresenter'
import { IColorHex, IColor } from 'Components/Molecules'

interface IPillContextComponentContentProps {
  color: IColor | IColorHex
}

const Pill = styled.span`
  border-radius: 12px;
  padding: 0 6px;

  ${(props: IPillContextComponentContentProps) => {
    if (props.color) {
      let typedCol = null
      if (props.color.hasOwnProperty('code')) {
        typedCol = props.color as IColorHex
        return css`
          color: #fff;
          border: 1px solid #${typedCol.code};
          background-color: #${typedCol.code};
        `
      } else {
        typedCol = props.color as IColor
        return css`
          color: #fff;
          border: 1px solid rgba(${typedCol.r}, ${typedCol.g}, ${typedCol.b}, ${typedCol.a});
          background-color: rgba(${typedCol.r}, ${typedCol.g}, ${typedCol.b}, ${typedCol.a});
        `
      }
    } else {
      return css`
        color: ${colors.primaryGrey};
        border: 1px solid ${colors.primaryGreyBackgroundColor};
        background-color: ${colors.primaryGreyBackgroundColor};
      `
    }
  }};

  font-size: 13px;
  line-height: 17px;
`

interface IPillContextProps {
  presenter: PillContextPresenter
}

export const PillContext = observer<IPillContextProps>(({ presenter }) => {
  const { context, title, content, color } = presenter

  return <Pill color={color}>{`${context} - ${title} - ${content}`}</Pill>
})
