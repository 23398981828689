import { useCallback, useMemo } from 'react'

import { ColumnConfig, Table, Text } from '@cutover/react-ui'
import { useCloseRightPanelOnMount, useToggleRightPanel } from 'main/components/layout/right-panel'
import { useLanguage } from 'main/services/hooks'
import { DataSource } from 'main/services/queries/types'
import { useDataSourcesQuery } from 'main/services/queries/use-data-sources'
import { usePermissions } from 'main/services/queries/use-permissions'

export const DataSourcesList = () => {
  const { t } = useLanguage('dataSources')
  const { data } = useDataSourcesQuery()
  const items = data?.data_sources || []

  const permissions = usePermissions('data-sources')
  const canRead = permissions('show')
  const toggleDataSourcePanel = useToggleRightPanel('data-sources-edit')
  useCloseRightPanelOnMount()

  const dataSourcesColumns: ColumnConfig<DataSource>[] = useMemo(() => {
    const columns = [
      {
        header: t('dataSources.table.columnHeaders.id'),
        property: 'id',
        sortable: true,
        render: (datum: DataSource) => <Text> {datum.id} </Text>
      },
      {
        header: t('dataSources.table.columnHeaders.name'),
        property: 'name',
        sortable: true
      },
      {
        header: t('dataSources.table.columnHeaders.url'),
        property: 'url',
        sortable: true
      }
    ]

    return columns
  }, [])

  const handleClickRow = useCallback(
    ({ datum }: { datum: DataSource }) => {
      if (canRead) {
        toggleDataSourcePanel({ dataSourceId: datum?.id as number })
      }
    },
    [toggleDataSourcePanel, canRead]
  )

  return <Table columns={dataSourcesColumns} data={items} onClickRow={handleClickRow} />
}
