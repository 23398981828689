// @ts-nocheck
import { injectable } from 'inversify'
import { container } from 'app/Config/IOC'
import { observable, action } from 'mobx'
import { Types } from 'Gateways/Service/Types'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'
import { ErrorAlertPresenter } from 'Shared/Components/Molecules'
import { TFieldOptionTypes } from 'Shared/Components/Organisms/FieldOptions/TFieldOptionTypes'
import { GenericFormPresenter } from '@logicroom/validator'

interface IGenericViewPresenter {
  submitted: boolean
}

@injectable()
export abstract class GenericPageFormPresenter {
  public language: ILanguageService = container.get(Types.ILanguageService)

  @observable
  protected viewModelMiddleware: (fieldName: number, value: string) => void

  @observable
  protected viewModelOptionsMiddleware: (fieldName: number, options: TFieldOptionTypes[]) => void

  @observable
  protected genericCheckboxMiddleware: (fieldName: number, label: string, value: boolean, context?: number) => void

  @observable
  protected dirtyCallback: () => void

  @observable
  protected resetCallback: () => void

  @observable
  public submitted: boolean = false

  @observable
  public errorAlertPresenter: ErrorAlertPresenter = new ErrorAlertPresenter()

  @observable
  public readOnly: boolean = false

  @observable
  public onSubmit: () => void

  protected abstract viewPresenter: IGenericViewPresenter
  protected abstract triggerFieldErrors: () => void
  protected abstract preSubmission?: () => void
  public abstract isFormValid: () => boolean
  public abstract inflateFormWithVM: (viewModel) => void
  public abstract reset: () => void
  public abstract applyReadonly: (readOnly: boolean) => void
  public abstract formValidator?: GenericFormPresenter

  @action
  public processSubmission = () => {
    if (this.preSubmission) {
      this.preSubmission()
    }

    this.submitted = true

    if (!this.isFormValid()) {
      this.triggerFieldErrors()
      this.errorAlertPresenter.setErrorMessages([this.language.get('common:formInvalid')])
      this.viewPresenter.submitted = false
    }
  }

  @action
  public processServerErrors = (errors: string[]) => {
    this.errorAlertPresenter.setErrorMessages(errors)
  }

  @action
  public withViewModelMiddleware = (viewModelMiddleware: (fieldName: number, value: string) => void) => {
    this.viewModelMiddleware = viewModelMiddleware
    return this
  }

  @action
  public withViewModelOptionsMiddleware = (
    viewModelOptionsMiddleware: (fieldName: number, options: TFieldOptionTypes[]) => void
  ) => {
    this.viewModelOptionsMiddleware = viewModelOptionsMiddleware
    return this
  }

  @action
  public withGenericCheckboxMiddleware = (
    genericCheckboxMiddleware: (fieldName: number, label: string, value: boolean, context?: number) => void
  ) => {
    this.genericCheckboxMiddleware = genericCheckboxMiddleware
    return this
  }

  @action
  public withDirtyCallback = (dirtyCallback: () => void) => {
    this.dirtyCallback = dirtyCallback
    return this
  }

  @action
  public withResetCallback = (resetCallback: () => void) => {
    this.resetCallback = resetCallback
    return this
  }

  @action
  public withOnSubmit = (onSubmit: () => void) => {
    this.onSubmit = onSubmit
    return this
  }
}
