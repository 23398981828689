import { useEffect, useState } from 'react'
import { eventManager } from 'event-manager'
import { useParams } from 'react-router-dom'

import { LinkTemplateSelectModal } from './link-template/link-template-select/link-template-select-modal'
import { LinkTemplateSelectWizard } from './link-template/link-template-select/link-template-select-wizard'
import { UnlinkResourceConfirmationModal } from './link-template/unlink-resource-confirmation-modal'
import { PauseRunbookModal, ResumeRunbookModal, StartRunbookModal } from './run-modals'
import { ShareRunbookDashboardModal } from 'main/components/dashboards/runbook-dashboard/share-runbook-dashboard-modal'
import { OpenShareRunbookDashboardData } from 'main/components/dashboards/runbook-dashboard/share-runbook-dashboard-types'
import { IntegrationDebugModalFromEvent } from 'main/components/integrations/integration-debug-modal-from-event'
import { CreateChangeTaskModal } from 'main/components/runbook/pages/task-list-ng-connector/change-requests/create-change-task-modal'
import { CreateOrLinkChangeRequestModal } from 'main/components/runbook/pages/task-list-ng-connector/change-requests/create-or-link-change-request-modal'
import { TaskListTask, TimezoneType } from 'main/services/queries/types'

type LinkTemplateData = {
  accountId: string
  task?: TaskListTask
  action: 'create' | 'update'
  multiselect?: boolean
  runbookId: string
  runbookVersionId: string
}

type LinkedTaskData = {
  unlinkResource: UnlinkResource[]
  actionMessage: string
}

type UnlinkResource = {
  unlinkName: string
  isTemplate: boolean
}

export type RunActionType = 'start' | 'pause' | 'resume' | null

export type RunModalData =
  | {
      runActionType: 'start'
      runbookVersionId: string
      templateType: 'default' | 'snippet'
      hasParentRunbook: boolean
      isLiveRunInParentRunbook: boolean
      isScheduled: boolean
      autoStart: boolean
      startScheduled: number
      timezone: TimezoneType
    }
  | {
      runActionType: 'pause'
      runId: string
      templateType: 'default' | 'snippet'
      isParentRunbook: boolean
      runType: 'rehearsal' | 'live'
      isRunCommsOff: boolean
    }
  | {
      runActionType: 'resume'
      runbookVersionId: string
      runId: string
      templateType: 'default' | 'snippet'
      isParentRunbook: boolean
      runType: 'rehearsal' | 'live'
      isRunCommsOff: boolean
    }

// TODO: run modals are still tied to events fired from angular. We should investigate promoting recoil to be accessible
// regardless of feature flag so we can obtain state information directly in the modals instead of passing down as props.
export const RunbookModals = () => {
  const [shareDashboardData, setShareDashboardData] = useState<OpenShareRunbookDashboardData | null>(null)
  const [linkTemplateData, setLinkTemplateData] = useState<LinkTemplateData | null>(null)
  const [unlinkResourceModalData, setUnlinkResourceModalData] = useState<LinkedTaskData | null>(null)
  const [runModalData, setRunModalData] = useState<RunModalData | null>(null)
  const [changeRequestType, setChangeRequestType] = useState<string | null>(null)
  const [parentChangeRequestId, setParentChangeRequestId] = useState<number | null>(null)
  const [parentChangeRequestExternalId, setParentChangeRequestExternalId] = useState<string | null>(null)
  const [isCreateOrLinkChangeRequestModalOpen, setCreateOrLinkChangeRequestModalOpen] = useState(false)
  const [isCreateChangeTaskModalOpen, setCreateChangeTaskModalOpen] = useState(false)

  const handleOpenShareDashboardModal = (data: OpenShareRunbookDashboardData) => setShareDashboardData(data)

  const handleOpenLinkTemplateModal = (data: LinkTemplateData) => setLinkTemplateData(data)

  const handleOpenUnlinkResourceModal = (data: LinkedTaskData) => {
    if (data) setUnlinkResourceModalData(data)
  }

  const { runbookId } = useParams()

  const handleOpenRunModal = (data: RunModalData) => {
    setRunModalData(data)
  }

  const renderRunModal = () => {
    switch (runModalData?.runActionType) {
      case 'start':
        return (
          runbookId && (
            <StartRunbookModal
              onClose={() => setRunModalData(null)}
              runbookVersionId={runModalData.runbookVersionId}
              runbookId={runbookId}
              templateType={runModalData.templateType}
              hasParentRunbook={runModalData.hasParentRunbook}
              isLiveRunInParentRunbook={runModalData.isLiveRunInParentRunbook}
              isScheduled={runModalData.isScheduled}
              autoStart={runModalData.autoStart}
              startScheduled={runModalData.startScheduled}
              timezone={runModalData.timezone}
            />
          )
        )
      case 'pause':
        return (
          runbookId && (
            <PauseRunbookModal
              onClose={() => setRunModalData(null)}
              runbookId={runbookId}
              runId={runModalData.runId}
              templateType={runModalData.templateType}
              isParentRunbook={runModalData.isParentRunbook}
              runType={runModalData.runType}
              isRunCommsOff={runModalData.isRunCommsOff}
            />
          )
        )
      case 'resume':
        return (
          runbookId && (
            <ResumeRunbookModal
              onClose={() => setRunModalData(null)}
              runId={runModalData.runId}
              runbookVersionId={runModalData.runbookVersionId}
              runbookId={runbookId}
              templateType={runModalData.templateType}
              isParentRunbook={runModalData.isParentRunbook}
              runType={runModalData.runType}
              isRunCommsOff={runModalData.isRunCommsOff}
            />
          )
        )
      default:
        return null
    }
  }

  const handleOpenCreateOrLinkChangeRequestModal = ({
    changeRequestType
  }: {
    runbookId: number
    changeRequestType: string
  }) => {
    setChangeRequestType(changeRequestType)
    setCreateOrLinkChangeRequestModalOpen(true)
  }

  const handleOpenCreateChangeTaskModal = ({
    changeRequestType,
    parentChangeRequestId,
    parentChangeRequestExternalId
  }: {
    runbookId: number
    changeRequestType: string
    parentChangeRequestId: number
    parentChangeRequestExternalId: string
  }) => {
    setChangeRequestType(changeRequestType)
    setParentChangeRequestId(parentChangeRequestId)
    setParentChangeRequestExternalId(parentChangeRequestExternalId)
    setCreateChangeTaskModalOpen(true)
  }

  const handleCloseCreateOrLinkChangeRequestModal = () => {
    setCreateOrLinkChangeRequestModalOpen(false)
  }

  const handleCloseCreateChangeTaskModal = () => {
    setCreateChangeTaskModalOpen(false)
  }

  useEffect(() => {
    eventManager.on('share-runbook-dashboard-modal-open', handleOpenShareDashboardModal)
    eventManager.on('linked-template-modal-open', handleOpenLinkTemplateModal)
    eventManager.on('unlink-resource-confirmation-modal', handleOpenUnlinkResourceModal)
    eventManager.on('open-runbook-run-modal', handleOpenRunModal)
    eventManager.on('open-create-or-link-change-request-modal', handleOpenCreateOrLinkChangeRequestModal)
    eventManager.on('open-create-change-task-modal', handleOpenCreateChangeTaskModal)
    return () => {
      eventManager.off('share-runbook-dashboard-modal-open', handleOpenShareDashboardModal)
      eventManager.off('linked-template-modal-open', handleOpenLinkTemplateModal)
      eventManager.off('unlink-resource-confirmation-modal', handleOpenUnlinkResourceModal)
      eventManager.off('open-runbook-run-modal', handleOpenRunModal)
      eventManager.off('open-create-or-link-change-request-modal', handleOpenCreateOrLinkChangeRequestModal)
      eventManager.off('open-create-change-task-modal', handleOpenCreateChangeTaskModal)
    }
  }, [])

  return (
    <>
      {shareDashboardData && (
        <ShareRunbookDashboardModal onClose={() => setShareDashboardData(null)} {...shareDashboardData} />
      )}

      {linkTemplateData &&
        (linkTemplateData.multiselect && linkTemplateData.task ? (
          <LinkTemplateSelectWizard
            onClose={() => setLinkTemplateData(null)}
            accountId={linkTemplateData.accountId}
            onSubmit={() => {}}
            task={linkTemplateData.task}
            runbookId={linkTemplateData.runbookId}
            runbookVersionId={linkTemplateData.runbookVersionId}
          />
        ) : (
          <LinkTemplateSelectModal
            onClose={() => setLinkTemplateData(null)}
            accountId={linkTemplateData.accountId}
            runbookId={linkTemplateData.runbookId}
            onSubmit={template => {
              switch (linkTemplateData.action) {
                case 'update':
                  eventManager.emit('task-panel-angular-update', {
                    linked_resource: { id: template.id, type: 'Runbook', meta: template }
                  })
                  break
                case 'create':
                  eventManager.emit('new-task-angular-update', {
                    task: linkTemplateData.task,
                    linked_resource: { id: template.id, type: 'Runbook', meta: template }
                  })
                  break
              }
              setLinkTemplateData(null)
            }}
          />
        ))}

      {unlinkResourceModalData && (
        <UnlinkResourceConfirmationModal
          unlinkResource={unlinkResourceModalData.unlinkResource}
          onSubmit={() => {
            if (unlinkResourceModalData.actionMessage == 'bulk-edit-task') {
              eventManager.emit('bulk-unlink-resource-angular-update')
            } else if (unlinkResourceModalData.actionMessage == 'edit-task') {
              eventManager.emit('unlink-resource-angular-update')
            }
            setUnlinkResourceModalData(null)
          }}
          onClose={() => {
            setUnlinkResourceModalData(null)
          }}
        ></UnlinkResourceConfirmationModal>
      )}

      {runModalData && runbookId && renderRunModal()}

      {isCreateOrLinkChangeRequestModalOpen && (
        <CreateOrLinkChangeRequestModal
          open={isCreateOrLinkChangeRequestModalOpen}
          runbookId={runbookId}
          changeRequestType={changeRequestType}
          handleCloseModal={handleCloseCreateOrLinkChangeRequestModal}
        />
      )}

      {isCreateChangeTaskModalOpen && (
        <CreateChangeTaskModal
          open={isCreateChangeTaskModalOpen}
          runbookId={runbookId}
          changeRequestType={changeRequestType}
          parentChangeRequestId={parentChangeRequestId}
          parentChangeRequestExternalId={parentChangeRequestExternalId}
          handleCloseModal={handleCloseCreateChangeTaskModal}
        />
      )}
      <IntegrationDebugModalFromEvent />
    </>
  )
}
