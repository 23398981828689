import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Settings = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Settings" {...props}>
    <path d="M23.794 32.055c4.265 0 7.7-3.613 7.7-8.04 0-4.428-3.435-8.04-7.7-8.04-4.265 0-7.7 3.612-7.7 8.04 0 4.427 3.435 8.04 7.7 8.04Zm4.633-8.04c0 2.697-2.087 4.86-4.633 4.86-2.547 0-4.634-2.163-4.634-4.86 0-2.698 2.087-4.86 4.634-4.86 2.546 0 4.633 2.162 4.633 4.86Z" fill="#000" /><path d="m38.18 8.58-4.955 2.092a15.747 15.747 0 0 0-3.245-1.978l-.748-5.531-.003-.014C29.114 2.52 28.584 2 27.906 2h-8.222c-.676 0-1.211.52-1.314 1.155l-.75 5.539a15.364 15.364 0 0 0-3.246 1.977L9.42 8.581l-.007-.004a1.306 1.306 0 0 0-1.626.597l-4.111 7.45a1.41 1.41 0 0 0 .314 1.75l4.215 3.458a17.423 17.423 0 0 0-.127 1.968c0 .67.054 1.326.127 1.968l-4.21 3.455-.001.001a1.405 1.405 0 0 0-.32 1.75l4.113 7.452c.328.588 1.02.85 1.633.594l4.955-2.092c.996.782 2.08 1.452 3.245 1.978l.749 5.531.001.008c.103.634.638 1.155 1.314 1.155h8.222c.676 0 1.21-.52 1.313-1.155l.75-5.539a15.366 15.366 0 0 0 3.247-1.977l4.953 2.09.007.004a1.306 1.306 0 0 0 1.626-.597l4.111-7.45a1.41 1.41 0 0 0-.314-1.75l-4.214-3.458a17.63 17.63 0 0 0 .126-1.968c0-.67-.053-1.326-.126-1.968l4.22-3.455.002-.001a1.405 1.405 0 0 0 .318-1.75l-4.112-7.452c-.328-.588-1.02-.85-1.633-.594Zm2.337 8.273-4.387 3.591.192 1.613c.075.635.122 1.202.122 1.743 0 .56-.049 2.784-.122 3.404l-.02.165 4.204 3.378-2.607 4.725-5.174-2.184-1.26 1.024a12.327 12.327 0 0 1-2.843 1.733l-1.448.633-.777 5.742h-5.204l-.777-5.742-1.449-.633a12.708 12.708 0 0 1-2.856-1.744l-1.258-1.009-5.162 2.18-2.61-4.727 4.378-3.591-.191-1.61a14.827 14.827 0 0 1-.123-1.744c0-.541.047-1.108.123-1.743l.19-1.61-4.376-3.592 2.609-4.727 5.173 2.184 1.26-1.024a12.325 12.325 0 0 1 2.843-1.733l1.449-.633.777-5.742h5.214l.777 5.742 1.449.633a12.708 12.708 0 0 1 2.856 1.744l1.258 1.008 5.163-2.179 2.607 4.725Z" fill="#000" />
  </StyledIcon>
))

Settings.displayName = 'Settings'

export { Settings }
