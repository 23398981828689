// @ts-nocheck
import { injectable } from 'inversify'
import { observable, computed, action } from 'mobx'
import { SearchBoxPresenter, ButtonPresenter } from 'Components/Molecules'
import { RunbookTemplatesRepository } from 'app/Repositories/Runbooks/RunbookTemplatesRepository'
import { AccountRunbooksRepository } from 'app/Repositories/Runbooks/AccountRunbooksRepository'
import { ModalPresenter } from 'Components/Atoms'
import { RoutingState } from 'app/Routing/RoutingState'
import { Types } from 'Gateways/Service/Types'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'
import { IRunbookTemplatePM } from 'Shared/Entities/Types/Runbooks/IRunbookTemplatePM'
import { container } from 'app/Config/IOC'
import { CheckboxPresenter } from 'Components/Molecules'

@injectable()
export class AngularChooseRunbookTemplatePresenter {
  @observable
  private runbookTypeId: string = null

  @observable
  public modalPresenter: ModalPresenter = null

  protected routingState: RoutingState = container.get(RoutingState)

  // Note: this is the account subdomain in the URL
  public accountId: string = this.routingState.currentState.params.accountId

  @observable
  public value: string = ''

  @observable
  public onlyApproved: boolean = true

  @observable
  private offSet: number = 0

  @observable
  public runbookTemplatesRepository: RunbookTemplatesRepository = container.get(RunbookTemplatesRepository)

  @observable
  public accountRunbooksRepository: AccountRunbooksRepository = container.get(AccountRunbooksRepository)

  @observable
  public onlyApprovedCheckboxPresenter: CheckboxPresenter = new CheckboxPresenter(
    'Only display approved templates?',
    true
  ).withMiddleware((value: boolean) => {
    this.onlyApproved = !this.onlyApproved
    this.loadRunbookTemplateList()
    return value
  })

  public language: ILanguageService = container.get(Types.ILanguageService)

  @action
  public loadRunbookTemplateList = async (offSet: number = 0) => {
    this.offSet = offSet

    await this.runbookTemplatesRepository.loadData({
      accountId: this.accountId,
      value: this.value,
      offSet: this.offSet,
      runbookTypeId: this.runbookTypeId,
      onlyApproved: this.onlyApproved
    })
  }

  @action
  public loadAccountRunbookList = async () => {
    await this.accountRunbooksRepository.loadData({
      accountId: this.accountId,
      value: this.value
    })
  }

  @action
  public loadRunbookTemplateListOnSearch = async (value?: string) => {
    this.value = value
    this.offSet = 0
    await this.loadRunbookTemplateList()

    const runbookTemplates = this.runbookTemplatesRepository.runbookTemplates

    if (runbookTemplates.length === 0) {
      await this.loadAccountRunbookList()
    }
  }

  @computed
  public get intialRunbookTemplatesLoading() {
    return (this.runbookTemplatesRepository.loading || this.accountRunbooksRepository.loading) && this.offSet === 0
  }

  public searchBoxPresenter: SearchBoxPresenter = new SearchBoxPresenter().withCustomChangeFunction(
    this.loadRunbookTemplateListOnSearch
  )

  public closeModal = () => {
    this.clear()
    this.modalPresenter && this.modalPresenter.onClose()
  }

  public openNextCreateRunbookModal = () => {
    window.angularJS &&
      window.angularJS.openCreateRunbookModal &&
      window.angularJS.openCreateRunbookModal('enterDetailsForm', this.runbookTypeId)

    this.closeModal()
  }

  public openCreateRunbookFromTemplateModal = (runbookTemplate: IRunbookTemplatePM) => {
    window.angularJS &&
      window.angularJS.openCreateRunbookFromTemplateModal &&
      window.angularJS.openCreateRunbookFromTemplateModal(runbookTemplate)
    this.closeModal()
  }

  public createBlankRunbookButtonPresenter: ButtonPresenter = new ButtonPresenter(
    this.language.get('runbooks:chooseRunbookTemplate:createBlankRunbookButton'),
    this.openNextCreateRunbookModal
  )
    .withIconName('chevron-right')
    .withDisplayStyle('primary')

  public cancelButtonPresenter = new ButtonPresenter(this.language.get('common:cancelButton'), this.closeModal)
    .withDisplayStyle('secondary')
    .withIconName('dnd-forwardslash')
    .withTextTransform('Uppercase')

  @action
  public withProps = ({ modalPresenter, runbookType }) => {
    this.runbookTypeId = runbookType && runbookType.id
    this.modalPresenter = modalPresenter

    if (runbookType.restrictCreateToTemplates) {
      this.createBlankRunbookButtonPresenter.isHidden(true)
    }

    return this
  }

  private clear = () => {
    this.runbookTemplatesRepository.clear()
    this.accountRunbooksRepository.clear()
  }
}
