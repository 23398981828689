import { createContext, ReactNode, useCallback, useContext, useMemo } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import { useIsReactMigrationWorkspaceMatch } from 'single-spa/route-matchers'

import {
  browserQueryStringToServerQueryObject,
  FilterValue,
  serverQueryObjectToBrowserQueryString
} from './filter-params'

type FilterContextType = {
  filters: ReturnType<typeof browserQueryStringToServerQueryObject>
}

export const FilterContext = createContext<FilterContextType>({
  filters: {}
})

export const FilterProvider = ({ children }: { children: ReactNode }) => {
  const { search } = useLocation()
  const isReactMigrationWorkspaceMatch = useIsReactMigrationWorkspaceMatch()
  const filters = useMemo(() => {
    if (isReactMigrationWorkspaceMatch) {
      return browserQueryStringToServerQueryObject({ query: search })
    } else {
      return {}
    }
  }, [isReactMigrationWorkspaceMatch, search])

  return <FilterContext.Provider value={{ filters }}>{children}</FilterContext.Provider>
}

export const useFilters = () => {
  const { filters } = useContext(FilterContext)

  return { filters }
}

export const useSetFilterParams = () => {
  const { filters } = useFilters()
  const [, setSearchParams] = useSearchParams()

  const setFilterParams: (newFilters: Record<string, FilterValue>) => void = useCallback(
    newFilters => {
      setSearchParams(
        serverQueryObjectToBrowserQueryString({
          queryObject: filters,
          overrides: newFilters
        })
      )
    },
    [filters, setSearchParams]
  )
  return setFilterParams
}

export const useClearFilterParams = () => {
  const [, setSearchParams] = useSearchParams()

  const clearFilterParams = useCallback(
    ({ newFilters }: { newFilters?: Record<string, FilterValue> } = {}) => {
      !newFilters?.['archived'] && delete newFilters?.['archived']
      setSearchParams(!newFilters ? {} : serverQueryObjectToBrowserQueryString({ queryObject: newFilters }))
    },
    [setSearchParams]
  )

  return clearFilterParams
}

export const useClearFilterParam = () => {
  const { filters } = useFilters()
  const [, setSearchParams] = useSearchParams()

  const clearFilterParam = useCallback(
    (key: string) => {
      setSearchParams(serverQueryObjectToBrowserQueryString({ queryObject: filters, excludeKeys: [key] }))
    },
    [filters, setSearchParams]
  )

  return clearFilterParam
}

export function useFilter<T extends string | number | boolean | object | string[] | number[]>(key: string) {
  const { filters } = useFilters()

  const value = filters[key]

  return (value ?? null) as T | null
}
