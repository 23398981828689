import { ReactNode } from 'react'

import { LegacyBox as Box, colors, LegacyText as Text } from '@cutover/react-ui'

export type TileProps = {
  title?: string
  children: ReactNode
  center?: boolean
  variant?: 'primary' | 'secondary'
}

export function Tile({ title, children, center = true, variant = 'primary' }: TileProps) {
  return (
    <Box
      css={`
        position: relative;
        display: flex;
        align-items: ${center ? 'center' : 'flex-start'};
        justify-content: center;
        height: 140px;
        background-color: ${variant === 'primary' ? colors.secondaryHover : colors.white};
        border-radius: 8px;
      `}
    >
      {children}
      {title ? (
        <Box
          css={`
            position: absolute;
            width: 100%;
            left: 0;
            top: 10px;
          `}
        >
          <Text
            css={`
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              max-height: 2.2rem;
              text-overflow: ellipsis;
              margin: 0 auto;
              max-width: 80%;
              text-align: center;
            `}
            title={title}
            size={14}
            weight={700}
            color={colors.textLight}
          >
            {title}
          </Text>
        </Box>
      ) : null}
    </Box>
  )
}
