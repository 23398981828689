import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const ChevronLeft = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="ChevronLeft" {...props}>
    <path d="M30.799 38.598 16 23.799 30.799 9l2.121 2.121L20.243 23.8 32.92 36.477l-2.12 2.121Z" fill="#000" />
  </StyledIcon>
))

ChevronLeft.displayName = 'ChevronLeft'

export { ChevronLeft }
