import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Search = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Search" {...props}>
    <path d="M26.358 28.48A11.948 11.948 0 0 1 19 31c-6.627 0-12-5.373-12-12S12.373 7 19 7s12 5.373 12 12c0 2.773-.94 5.326-2.52 7.358l13.505 13.506-2.121 2.121L26.359 28.48ZM28 19a9 9 0 1 0-18 0 9 9 0 0 0 18 0Z" fill="#000" />
  </StyledIcon>
))

Search.displayName = 'Search'

export { Search }
