/* eslint-disable @typescript-eslint/explicit-member-accessibility */
// @ts-nocheck
import { unescape } from 'lodash'

import { Axis, TimeAxis } from './timeline-axis'

/*
 * IMPORTANT! Notice the top-level typescript and eslint ignores. These exist because this
 * code is copied over from the angular omniview controller (event timeline). Only the minimum
 * changes necessary were made to remove angular lifecycle dependencies for usage here.
 */

export class ItemPosition {
  itemId: number = null
  yValue: any = null
  x: number = null
  y: number = null
  px: number = null
  py: number = null
  tx: number = null
  ty: number = null
  transition: number = null
  width: number = null
  height: number = null
  entering: boolean = false
  leaving: boolean = false

  constructor(item: Item, yValue: AxisValue) {
    this.itemId = item.id
    this.yValue = yValue.id
    this.x = 0 // current x
    this.y = 0 // current y
    this.px = 0 // previous x
    this.py = 0 // previous y
    this.tx = 0 // target x
    this.ty = 0 // target y
    this.transition = 1 // decimal, 1 = transition complete
    this.width = 0
    this.height = 0
    this.entering = false
    this.leaving = false
  }
}

export class Item {
  id: number = null
  internalId: number = null
  name: string = null
  displayName: string = null
  editable: boolean = false
  color: string = null
  shape: 'default' | 'diamond' = 'default'
  xValues: any[] = []
  yValues: any[] = []
  spotlight: any
  verticalFillColor: string = null
  positions: any
  width: number
  displayWidth: number
  spotlightWidth: number
  filterHighlight: boolean = false
  statusColor: string
  predecessorIds: number[]
  successorIds: number[]
  predsNotVisible: number[] = []
  succsNotVisible: number[] = []
  progress: number = 0
  stage: number = null

  constructor(
    itemRaw: any = null,
    xAxis: Axis | TimeAxis,
    yAxis: Axis | TimeAxis,
    spotlight: any = null,
    viewport: any = null
  ) {
    this.id = itemRaw.id
    this.positions = {}
    this.internalId = itemRaw.internal_id ? itemRaw.internal_id : itemRaw.id

    this.update(itemRaw, xAxis, yAxis, spotlight, viewport)
  }

  update(itemRaw: any, xAxis: Axis | TimeAxis, yAxis: Axis | TimeAxis, spotlight: any, viewport: any): void {
    this.name = unescape(itemRaw.name)
    this.displayName = '#' + this.internalId + ' ' + this.name
    this.color = itemRaw.color
    this.shape = itemRaw.shape
    this.statusColor = itemRaw.statusColor
    this.editable = itemRaw.editable
    this.verticalFillColor = itemRaw.verticalFillColor
    this.spotlight = spotlight
    this.predecessorIds = itemRaw.predecessor_ids
    this.successorIds = itemRaw.successor_ids
    this.filterHighlight = itemRaw.highlight
    this.stage = itemRaw.stage

    // Calc properties for current selected axes
    // Eg if yAxis = stream, this will be [stream_id]
    this.xValues = xAxis.setItemValues(itemRaw)
    this.yValues = yAxis.setItemValues(itemRaw)

    // need to work out width here so we can use in layout
    this.width = xAxis.calcWidth(itemRaw, viewport)

    if (xAxis.dataType === 'time') {
      var minWidth = 140
      var spotlightWidth = 0
      if (this.spotlight) {
        for (var i = 0; i < this.spotlight.length; i++) {
          spotlightWidth += this.spotlight[i].name.length + 4
        }
        spotlightWidth = spotlightWidth * 7.2 // px per char
      }
      this.spotlightWidth = spotlightWidth
      this.displayWidth = Math.max(this.width, minWidth + spotlightWidth)
    } else {
      this.spotlightWidth = 0
      this.displayWidth = this.width
    }
  }
}
