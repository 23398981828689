// @ts-nocheck

import styled from 'styled-components'
import { transition, colors, breakpoints } from 'app/Styles/Variables'
import { LoadingPanel } from 'Shared/Components/Templates'

const MainPanelSection = styled.section`
  background-color: ${colors.white};
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 0px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  margin: 0 16px;
  ${transition('medium')}
  overflow: hidden;
  z-index: 1;

  left: ${(props: { filterPanelOpen: boolean }) => (props.filterPanelOpen ? '280px' : '0px')};

  @media ${breakpoints.sm} {
    left: 0px;
  }

  @media ${breakpoints.md} {
    left: 0px;
  }
`

interface IMainPanelWrapperProps {
  filterPanelOpen?: boolean
  extraStyles?: CSSProperties
  children: ReactNode
  loading?: boolean
}

export const MainPanelWrapper = (props: IMainPanelWrapperProps) => (
  <MainPanelSection filterPanelOpen={props.filterPanelOpen} style={props.extraStyles}>
    {props.loading && <LoadingPanel overlay={true} />}
    {props.children}
  </MainPanelSection>
)
