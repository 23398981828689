import styled, { keyframes } from 'styled-components'

import { resolveColor, Text, ThemeType } from '@cutover/react-ui'

export const SubHeaderResultsText = ({ value }: { value: string; flash?: boolean }) => {
  return <BlinkingText>{value}</BlinkingText>
}

const blink = ({ theme }: { theme: ThemeType }) => keyframes`
  0% {
    color: ${resolveColor('text-light', theme)};
  }
  50% {
    color: ${resolveColor('primary', theme)};
  }
  100% {
    color: ${resolveColor('text-light', theme)};
  }
`

const BlinkingText = styled(Text).attrs(() => ({
  color: 'text-light',
  truncate: true
}))<{ flash?: boolean }>`
  animation: ${blink} 750ms 1 ease-in-out;
`
