import { useEffect, useState } from 'react'

import { IconButton, IconName, Menu, MenuListItem, useNotify } from '@cutover/react-ui'
import { useLanguage, useUserWebsocket } from 'main/services/hooks'
import { usePermissions } from 'main/services/queries/use-permissions'
import { downloadEventsLogExport } from 'main/services/queries/use-audit-events'
import { SubHeader } from 'main/components/layout/shared/sub-header'
import { ExportEventLogsDownloadResponse } from 'main/services/api/data-providers/user/user-channel-response-types'
import { saveFile } from 'Shared/Helpers'

export const IntegrationSettingsSubHeader = () => {
  const [menuIcon, setMenuIcon] = useState<IconName>('more-vertical')
  const notify = useNotify()
  const { listen } = useUserWebsocket()
  const { t } = useLanguage('integrationSettings')
  const permissions = usePermissions('integrations')
  const canDownloadAudit = permissions('download_audit')

  const handleSaveFileResponse = (data: ExportEventLogsDownloadResponse) => {
    if (data?.meta?.headers?.request_method === 'download_file') {
      if (!data.errors) {
        fetch(data.uri)
          .then(function (res) {
            return res.blob()
          })
          .then(function (blob) {
            saveFile(blob, 'text/csv', 'IntegrationsAuditLog.csv')
            setMenuIcon('check')
            notify.success(t('modal.exportModal.confirmation'), {
              title: t('modal.exportModal.title')
            })
          })
      } else {
        notify.error(t('modal.exportModal.emptyEventAudit'), {
          title: t('modal.exportModal.title')
        })
      }
      setTimeout(() => {
        setMenuIcon('more-vertical')
      }, 1500)
    }
  }

  const exportEventsLog = async () => {
    setMenuIcon('spinner')
    try {
      await downloadEventsLogExport()
    } catch (error: any) {
      notify.error(error.errors),
        {
          title: t('modal.exportModal.title')
        }
    }
    setTimeout(() => {
      setMenuIcon('more-vertical')
    }, 1500)
  }

  useEffect(() => {
    listen(data => handleSaveFileResponse(data as ExportEventLogsDownloadResponse))
  }, [])

  return (
    <SubHeader.Items>
      <SubHeader.LeftItems></SubHeader.LeftItems>
      <SubHeader.RightItems>
        {canDownloadAudit && (
          <Menu
            trigger={
              <IconButton
                tertiary
                data-testid="sub-header-options-menu"
                label="More options"
                disableTooltip
                icon={menuIcon}
              />
            }
          >
            <MenuListItem
              label={t('mainPanelSubHeader.moreOptions.downloadAudit')}
              key={t('mainPanelSubHeader.moreOptions.downloadAudit')}
              data-testid="download-audit-option"
              icon="download"
              onClick={exportEventsLog}
            />
          </Menu>
        )}
      </SubHeader.RightItems>
    </SubHeader.Items>
  )
}
