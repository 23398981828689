// @ts-nocheck
import { components, OptionProps } from 'react-select'

interface IOptionProps {
  keyword?: string
  highlightKeyword?: boolean
  label: string
}

const splitOnKeyword = (value: string, keyword: string): string[] => {
  const re = new RegExp(keyword, 'i')
  const result = re.exec(value)
  if (!result || keyword.length === 0) return [value]
  return [
    value.slice(0, result.index),
    value.slice(result.index, result.index + keyword.length),
    value.slice(result.index + keyword.length, value.length)
  ]
}

export const Option = (props: IOptionProps & OptionProps<any>) => {
  const { label, keyword, highlightKeyword } = props
  if (!highlightKeyword) {
    return <components.Option {...props}>{label}</components.Option>
  } else {
    const splitLabel = splitOnKeyword(label, keyword)
    return (
      <components.Option {...props}>
        {splitLabel[0]}
        <strong>{splitLabel[1]}</strong>
        {splitLabel[2]}
      </components.Option>
    )
  }
}
