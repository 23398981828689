import { keyBy } from 'lodash'
import { selector, selectorFamily } from 'recoil'

import { runbookVersionMetaState } from './runbook-version'
import {} from 'main/services/queries/use-runbook-versions'
import { RunbookTeam } from 'main/services/queries/types'

export const teamsState = selector<RunbookTeam[]>({
  key: 'teams:list',
  get: ({ get }) => {
    return get(runbookVersionMetaState).runbook_teams
  }
})

export const teamsStateLookup = selector<Record<RunbookTeam['id'], RunbookTeam>>({
  key: 'teams:lookup',
  get: ({ get }) => {
    return keyBy(get(teamsState), 'id')
  }
})

export const teamState = selectorFamily<RunbookTeam, number>({
  key: 'tasks:id',
  get:
    id =>
    ({ get }) =>
      get(teamsStateLookup)[id]
})
