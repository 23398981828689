// @ts-nocheck
import { observable, action, computed } from 'mobx'
import { IFilterKey, IGenericFilter, ISelectedFilter } from 'app/Filters/FilterEntities'
import { FiltersRepository } from 'app/Filters/FiltersRepository'
import { container } from 'app/Config/IOC'
import debounce from 'debounce-promise'
import { Router } from 'app/Routing/Router'

export class SearchBoxPresenter {
  @observable
  private filterRepository: FiltersRepository = container.get(FiltersRepository)

  private router: Router = container.get(Router)

  private customChangeFunction: Function = null

  @computed
  private get pageFilter(): IGenericFilter {
    return this.filterRepository.pageFilters.find(filter => {
      return filter.key.reference === this.key.reference
    })
  }

  @computed
  private get selectedFilter(): ISelectedFilter {
    return (
      this.filterRepository &&
      this.filterRepository.selectedFilters.find(filter => {
        return filter.key.reference === this.key.reference
      })
    )
  }

  @observable
  private key: IFilterKey = { reference: 'query' }

  @observable
  public value: string = ''

  private changeFunction = () => {
    if (this.customChangeFunction) {
      this.customChangeFunction(this.value)
    } else {
      this.filterRepository && this.filterRepository.toggleFilterValue(this.pageFilter, this.value)
      this.router.refreshFilteredRoute()
    }
  }

  @observable
  private debounceFunction: () => void = debounce(this.changeFunction, 500)

  @computed
  public get filterValue(): string {
    if (this.selectedFilter) return this.selectedFilter.value as string
    return ''
  }

  @action
  public onChange = (value: string, update = true) => {
    this.value = value
    if (update) {
      const df = this.debounceFunction
      df()
    }
  }

  @action
  public withDebounceTime = (debounceTime: number) => {
    this.debounceFunction = debounce(this.changeFunction, debounceTime)
    return this
  }

  @action
  public withFilterKey = (filterKey: IFilterKey) => {
    this.key = filterKey
    return this
  }

  @action
  public withCustomChangeFunction = (customChangeFunction: Function) => {
    this.customChangeFunction = customChangeFunction
    return this
  }

  @action
  public clear = () => {
    this.value = ''
  }
}
