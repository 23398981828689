import { ComponentType, forwardRef, ReactElement, SVGAttributes } from 'react'
import { colorStyle } from 'grommet-styles'
import styled, { css, DefaultTheme } from 'styled-components'

export const BASE_THEME = {
  theme: {
    global: {
      colors: {
        icon: '#666666'
      }
    },
    icon: {
      size: {
        small: '12px',
        medium: '24px',
        large: '48px',
        xlarge: '96px'
      }
    }
  }
}

const colorCss = css<{ color?: string }>`
  ${props => colorStyle('fill', props.color || props.theme.global.colors.icon, props.theme)}
  ${props => colorStyle('stroke', props.color || props.theme.global.colors.icon, props.theme)}

  g {
    fill: inherit;
    stroke: inherit;
  }

  *:not([stroke]) {
    &[fill='none'] {
      stroke-width: 0;
    }
  }

  *[stroke*='#'],
  *[STROKE*='#'] {
    stroke: inherit;
    fill: none;
  }

  *[fill-rule],
  *[FILL-RULE],
  *[fill*='#'],
  *[FILL*='#'] {
    fill: inherit;
    stroke: none;
  }
`

type IconInnerProps = {
  a11yTitle?: string
  color?: string
  size?: 'small' | 'medium' | 'large' | 'xlarge' | string
  theme?: DefaultTheme
}

export type Icon = ReactElement<IconInnerProps & JSX.IntrinsicElements['svg']>

const IconInner = forwardRef<SVGSVGElement, IconInnerProps & SVGAttributes<SVGSVGElement>>(
  ({ a11yTitle, color: _color, size: _size, theme: _theme, ...rest }, ref) => (
    <svg ref={ref} aria-label={a11yTitle} {...rest} />
  )
)
IconInner.displayName = 'Icon'

// @ts-ignore
const parseMetricToNum = (string: string) => parseFloat(string.match(/\d+(\.\d+)?/), 10)

const StyledIcon = styled(IconInner)<SVGAttributes<SVGSVGElement>>`
  display: inline-block;
  flex: 0 0 auto;

  ${({ size = 'medium', theme, viewBox }) => {
    const [, , ws, hs] = (viewBox || '0 0 24 24').split(' ')
    const w = parseInt(ws)
    const h = parseInt(hs)
    const scale = w / h
    // @ts-ignore
    const dimension = parseMetricToNum(theme.icon.size[size] || size)

    if (w < h) {
      return `
      width: ${dimension}px;
      height: ${dimension / scale}px;
    `
    }
    if (h < w) {
      return `
      width: ${dimension * scale}px;
      height: ${dimension}px;
    `
    }
    return `
      width: ${dimension}px;
      height: ${dimension}px;
    `
  }}
  ${({ color }) => color !== 'plain' && colorCss}
  ${({ theme }) => theme && theme.icon.extend}
`

StyledIcon.defaultProps = {}
Object.setPrototypeOf(StyledIcon.defaultProps, BASE_THEME)

type StyledIconProps = IconInnerProps & SVGAttributes<SVGSVGElement>

interface SvgIconProps {
  a11yTitle?: string
  color?: string
  size?: 'small' | 'medium' | 'large' | 'xlarge' | string
}

type SvgIcon = ComponentType<SvgIconProps & JSX.IntrinsicElements['svg']>

export { StyledIcon, StyledIconProps, SvgIconProps, SvgIcon }
