import { useNavigate, useParams } from 'react-router-dom'

import { LegacyText as Text } from '@cutover/react-ui'
import { DashboardComponentProps } from '../../types'
import { MrdDashboardWidget } from '../mrd-dashboard-widget'
import {
  Duration,
  formatDuration,
  NoDataAvailable,
  Table,
  Tile
} from 'main/components/dashboards/widgets/account/common'
import { useRouting } from 'main/services/routing/hooks'

type RunbookDuration = {
  id: number
  name: string
  duration: Duration
}

type LongestDurationRunbooksWidgetData = {
  name: string
  values: RunbookDuration[]
}

export type LongestDurationRunbookWidgetProps = DashboardComponentProps<LongestDurationRunbooksWidgetData>

export const LongestDurationRunbooksWidget = (props: LongestDurationRunbookWidgetProps) => {
  switch (props.media) {
    case 'screen':
      return <BrowserWidget {...props} />
    case 'email':
      return <ServerSideWidget {...props} />
    case 'print':
      return <ServerSideWidget {...props} />
  }
}

const BrowserWidget = ({ data }: LongestDurationRunbookWidgetProps) => {
  const { accountId } = useParams()
  const { toRunbook } = useRouting()
  const navigate = useNavigate()

  const runbookDurations: RunbookDuration[] = data.values

  return (
    <MrdDashboardWidget title={data.name}>
      {runbookDurations?.length === 0 ? (
        <NoDataAvailable />
      ) : (
        <LongestDurationRunbooks
          runbookNameTitle={'Runbook name'}
          durationTitle={'Duration'}
          runbookDurations={runbookDurations}
          onRunbookSelected={id => {
            navigate(toRunbook({ accountSlug: accountId as string, runbookId: id }))
          }}
        />
      )}
    </MrdDashboardWidget>
  )
}

const ServerSideWidget = ({ data }: LongestDurationRunbookWidgetProps) => {
  const runbookDurations: RunbookDuration[] = data.values

  return (
    <MrdDashboardWidget title={data.name}>
      {runbookDurations?.length === 0 ? (
        <NoDataAvailable />
      ) : (
        <LongestDurationRunbooks
          runbookNameTitle={'Runbook name'}
          durationTitle={'Duration'}
          runbookDurations={runbookDurations}
          onRunbookSelected={_id => {}}
        />
      )}
    </MrdDashboardWidget>
  )
}

export type LongestDurationRunbooksProps = {
  runbookNameTitle?: string
  durationTitle?: string
  runbookDurations: RunbookDuration[]
  onRunbookSelected: (id: number) => void
}

export function LongestDurationRunbooks({
  runbookNameTitle = 'Runbook name',
  durationTitle = 'Duration',
  runbookDurations,
  onRunbookSelected
}: LongestDurationRunbooksProps) {
  const runbooks = runbookDurations.map(runbook => ({ ...runbook, duration: formatDuration(runbook.duration) }))

  return (
    <Tile variant="secondary" center={false}>
      <Table
        headers={[runbookNameTitle, durationTitle]}
        data={runbooks}
        renderRowCells={rd => [
          <Text title={rd.name} truncate>
            {rd.name}
          </Text>,
          rd.duration
        ]}
        onRowSelected={id => typeof id === 'number' && onRunbookSelected(id)}
      />
    </Tile>
  )
}
