// @ts-nocheck
import styled, { ThemeProvider } from 'styled-components'
import { Icon } from 'Components/Atoms/Icon'
import ReactHtmlParser from 'react-html-parser'
import { backgroundColor, textColor, themeColors } from 'app/Shared/Themes/Variables'
import { observer } from 'mobx-react-lite'
import { px2rem } from 'app/Styles/Variables'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: ${px2rem(14)};
  padding: ${px2rem(2)};
  color: ${textColor};
  background-color: ${backgroundColor};
`

const IconContainer = styled.div``
const Message = styled.div`
  padding: ${px2rem(6)};
`

export const AlertSingle = observer((props: any) => {
  const { iconName, iconSize, message, theme } = props.presenter

  let IconRender = () => <Icon name={iconName} size={iconSize} color={themeColors[theme].color} />

  return (
    <ThemeProvider theme={{ mode: theme }}>
      <Container>
        <IconContainer>{iconName && <IconRender />} </IconContainer>
        <Message>{ReactHtmlParser(message)}</Message>
      </Container>
    </ThemeProvider>
  )
})
