import { colors, LegacyFlex as Flex, LegacyText as Text } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'

export type DurationProps = {
  duration: string
  delta?: {
    duration: string
    percentage: number
  } | null
}

export function Duration({ duration, delta }: DurationProps) {
  return (
    <Flex gap="10px" flexDirection="column" justifyContent="center" alignItems="center">
      <Text title={duration} size={32} height="2rem" weight={300} color={colors.primaryGreyHover} truncate>
        {duration}
      </Text>
      {delta && delta.duration && delta.percentage !== 0 ? (
        <DurationDelta duration={delta.duration} percentage={delta.percentage} />
      ) : null}
    </Flex>
  )
}

type DurationDeltaProps = {
  duration: string
  percentage: number
}

function DurationDelta({ duration, percentage }: DurationDeltaProps) {
  const { t } = useLanguage()

  if (percentage >= 0) {
    return (
      <Text color={colors.error}>
        {duration} {t('dashboard:chart:positiveDelta')}
      </Text>
    )
  }

  return (
    <Text color="green">
      {duration} {t('dashboard:chart:negativeDelta')}
    </Text>
  )
}
