import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Alert = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Alert" {...props}>
    <path d="M22.5 17.21h3v12h-3v-12Zm3 15h-3v3h3v-3Z" fill="#000" /><path d="M26.67 7.542c-1.187-2.056-4.154-2.056-5.34 0L4.418 36.834c-1.187 2.056.296 4.625 2.67 4.625h33.824c2.374 0 3.857-2.57 2.67-4.625L26.67 7.542Zm12.51 30.834H8.82a1 1 0 0 1-.866-1.5l15.18-26.293a1 1 0 0 1 1.732 0l15.18 26.293a1 1 0 0 1-.866 1.5Z" fill="#000" />
  </StyledIcon>
))

Alert.displayName = 'Alert'

export { Alert }
