import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const CodeBlock = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="CodeBlock" {...props}>
    <path d="M28.243 32.485 36.728 24l-8.485-8.485-2.122 2.121L32.485 24l-6.364 6.364 2.122 2.121Zm-6.364-2.121L15.515 24l6.364-6.364-2.122-2.121L11.272 24l8.485 8.485 2.122-2.121Z" fill="#000" /><path fillRule="evenodd" clipRule="evenodd" d="M6 14a8 8 0 0 1 8-8h20a8 8 0 0 1 8 8v20a8 8 0 0 1-8 8H14a8 8 0 0 1-8-8V14Zm33 0a5 5 0 0 0-5-5H14a5 5 0 0 0-5 5v20a5 5 0 0 0 5 5h20a5 5 0 0 0 5-5V14Z" fill="#000" />
  </StyledIcon>
))

CodeBlock.displayName = 'CodeBlock'

export { CodeBlock }
