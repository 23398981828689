// @ts-nocheck
import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { colors, px2rem } from 'app/Styles/Variables'
import { Icon } from 'Components/Atoms'
import { Select, SelectPresenter } from 'Components/Molecules'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'

interface IMultiSelectWithCapabilitiesItemProps {
  option: { value: string | number; label: string; selectPresenter: SelectPresenter }
  deselectOption: (option) => void
  globalOptionId: string
  contextId: number
  disabled?: boolean
  language: ILanguageService
}

const SelectedItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  color: ${colors.text};
  padding: ${px2rem(4)} 0;
`

const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  position: relative;
`

const SelectWrapper = styled.div`
  display: inline-block;
  border-bottom: 1px dashed ${colors.primaryGreyHoverBackgroundColor};
  width: 48%;
`

const LabelWrapper = styled.div`
  position: absolute;
  right: 0;
  display: inline-block;
  line-height: 31px;
  width: 48%;
  border-bottom: 1px dashed ${colors.primaryGreyHoverBackgroundColor};
  margin-top: 1px;
  margin-left: 4px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
  padding: 0 3px;
`

const RemoveButtonWrapper = styled.div`
  display: flex;
  padding: 0 1px;
  align-items: center;
  cursor: pointer;
`

export const MultiSelectWithCapabilitiesItem = (props: IMultiSelectWithCapabilitiesItemProps) => {
  const { deselectOption, option, globalOptionId, contextId, disabled, language } = props
  const { value, label, selectPresenter } = option
  const [localLabel, setLocalLabel] = useState(label)

  const updateLabel = val => {
    const valueAsString = value as string

    if (globalOptionId === value) {
      setLocalLabel(language.get('components:molecules:multiSelectCapabilities:globalLabel'))
    } else if (val === 3) {
      setLocalLabel(`${label}s`)
    } else if (contextId === parseInt(valueAsString, 10)) {
      setLocalLabel(`The ${label.toLowerCase()}`)
    } else {
      setLocalLabel(`All ${label.toLowerCase()}s`)
    }
  }

  useEffect(() => {
    selectPresenter.withMiddleware(val => {
      updateLabel(val)
      return val
    })
  }, [])

  useEffect(() => {
    updateLabel(selectPresenter.value)
  }, [contextId])

  return (
    <SelectedItemWrapper>
      <ContentWrapper>
        <SelectWrapper>
          <Select presenter={selectPresenter} notClearable={true} maxMenuHeight={160} />
        </SelectWrapper>
        <LabelWrapper>{localLabel}</LabelWrapper>
      </ContentWrapper>
      <RemoveButtonWrapper
        onClick={e => {
          e.preventDefault()
          if (disabled) return
          deselectOption(option)
        }}
      >
        <Icon name="close" color={colors.secondaryGrey} />
      </RemoveButtonWrapper>
    </SelectedItemWrapper>
  )
}
