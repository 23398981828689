// @ts-nocheck
import { useEffect } from 'react'
import tinycolor from 'tinycolor2'
import { IColor } from 'Components/Molecules'
import { isColorDefault } from 'Shared/Helpers/Color'

export const colorToHex = (color: IColor, setIconColor: (color: string) => void) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (!isColorDefault(color)) {
      setIconColor(tinycolor(color).toHexString())
    }
  }, [color])
}
