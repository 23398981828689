// @ts-nocheck
import dompurify from 'dompurify'

interface IProps {
  input: string
  extraStyles?: CSSProperties
}

/** @deprecated do not use this component from the app folder, refactor/use the component from react-ui instead **/
const SanitizedString = ({ input, extraStyles }: IProps) => {
  const sanitize = (input: string) =>
    dompurify.sanitize(input, { ALLOWED_TAGS: ['b', 'strong', 'br', 'li', 'ul', 'p'] })
  // Note: this is the only place in the codebase in which dangerouslySetInnerHTML is allowed and is strictly sanitized
  return <span style={extraStyles} dangerouslySetInnerHTML={{ __html: sanitize(input) }} />
}

export default SanitizedString
