import { useEffect } from 'react'
import { Controller, FieldValues, Path, useFormContext } from 'react-hook-form'
import styled from 'styled-components'
import { get } from 'lodash'

import { Box, RadioboxGroup, TextArea, themeColor, themeEdgeSize } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { FieldProps } from '../../form'

// TODO: should not get disabled and readOnly directly, should be done via schema..
export type RagStatusFieldsProps<TFieldValues extends FieldValues> = FieldProps<{}, TFieldValues> & {
  disabled?: boolean
  readOnly?: boolean
  statusMessageFieldName: Path<TFieldValues>
}

export const RagStatusFields = <TFieldValues extends FieldValues>({
  statusMessageFieldName,
  ...props
}: RagStatusFieldsProps<TFieldValues>) => {
  const { t } = useLanguage('runbooks', { keyPrefix: 'fields' })

  const formContext = useFormContext<TFieldValues>()

  const status = formContext.watch(props.name)

  useEffect(() => {
    if (status === 'off') {
      formContext.setValue(statusMessageFieldName, '' as any)
    }
  }, [status])

  return (
    <>
      <Controller
        name={props.name}
        control={formContext.control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <RagGroupWrapper>
            <RadioboxGroup
              {...props}
              hasError={!!error}
              inlineError={typeof props.inlineError === 'string' ? props.inlineError : error?.message}
              label={t('runbookStatus.label')}
              direction="row"
              options={[
                { label: 'Off', value: 'off' },
                { label: 'Red', value: 'red' },
                { label: 'Amber', value: 'amber' },
                { label: 'Green', value: 'green' }
              ]}
              onChange={onChange}
              value={value}
            />
          </RagGroupWrapper>
        )}
      />
      {status && status !== 'off' && (
        <TextArea
          disabled={props.disabled} // TODO: distinguish between disabled and readOnly
          readOnly={props.readOnly}
          required
          {...formContext.register(statusMessageFieldName as any, { required: true })}
          hasError={!!get(formContext.formState.errors, statusMessageFieldName)}
          label={t('statusMessage.label')}
        />
      )}
    </>
  )
}

// TODO:  put this styled component in a different location
// it's  being used by runbooks-bulk-edit-form and runbook-edit-form
const RagGroupWrapper = styled(Box)`
  input + div {
    gap: ${themeEdgeSize('xxsmall')};
  }
  input[value='off'] + div {
    &:hover svg,
    svg {
      fill: ${themeColor('text-light')};
    }
  }

  div.text-content-wrap {
    padding: 0 2px !important;
  }

  input[value='red'] + div {
    &:hover svg,
    svg {
      fill: ${themeColor('rag-red')};
    }
  }

  input[value='amber'] + div {
    &:hover svg,
    svg {
      fill: ${themeColor('rag-amber')};
    }
  }

  input[value='green'] + div {
    &:hover svg,
    svg {
      fill: ${themeColor('rag-green')};
    }
  }
`
