// @ts-nocheck
import styled from 'styled-components'
import { colors } from 'app/Styles/Variables'
import { Icon } from 'Components/Atoms/Icon'

const OverlayWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${({ color }) => color};
  opacity: 0.6;
  text-align: center;
  z-index: 999;
`

const FixedWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${({ color }) => color};
  text-align: center;
  z-index: 999;
`

const IconWrapper = styled.div`
  position: absolute;
  top: calc(50% - 40px);
  left: 0;
  right: 0;
  z-index: 999;
  i {
    width: 100%;
  }
`

interface ILoadingPanelProps {
  overlay?: boolean
  backgroundColor?: string
  className?: string
}

/** @deprecated do not use this component from the app folder, use the component from react-ui instead **/
export const LoadingPanel = (props: ILoadingPanelProps) => {
  const { overlay = false, backgroundColor = colors.white, className } = props

  const IconRender = () => (
    <IconWrapper data-testid="loading">
      <Icon name="spinner" size={'80px'} color={colors.primaryGreyBackgroundColor} />
    </IconWrapper>
  )

  return overlay ? (
    <OverlayWrapper color={backgroundColor} className={className}>
      <IconRender />
    </OverlayWrapper>
  ) : (
    <FixedWrapper color={backgroundColor} className={className}>
      <IconRender />
    </FixedWrapper>
  )
}
