import { mapValues } from 'lodash'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import {
  IntegrationConnectionFormType,
  IntegrationSettingFormType
} from 'main/components/settings/integration-settings/integrations/integration-setting-types'
import { apiClient, ApiError } from 'main/services/api'
import { QueryKeys } from 'main/services/queries/query-keys'
import { IntegrationSetting, IntegrationSettingsResponse } from 'main/services/queries/types'
import { useSetPermissions } from './use-permissions'
import { IntegrationActionItemsImportType } from 'main/components/settings/integration-settings/integration-action-items-import-modal'
import { IntegrationActionItemsExportType } from 'main/components/settings/integration-settings/integration-action-items-bulk-export-modal'

export function useIntegrationSettingsQuery() {
  const setPermissions = useSetPermissions('integrations')

  return useQuery<IntegrationSettingsResponse, Error, IntegrationSettingsResponse>(
    [QueryKeys.IntegrationSettings],
    async () => {
      const { data } = await apiClient.get<IntegrationSettingsResponse>({
        url: 'integration_settings'
      })
      return data ?? []
    },
    {
      onSuccess: data => {
        setPermissions(data.meta.permissions)
      }
    }
  )
}

export function useIntegrationSettingCreate() {
  const queryClient = useQueryClient()

  return useMutation<IntegrationSetting, Error, IntegrationConnectionFormType>(
    [QueryKeys.IntegrationSettings],
    async values => {
      const response = await apiClient.post<{ integration_setting: IntegrationConnectionFormType }, IntegrationSetting>(
        {
          url: 'integration_settings/',
          data: { integration_setting: values }
        }
      )
      // TODO: Fix this when API client type is fixed
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return response.data!
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries([QueryKeys.IntegrationSettings])
      }
    }
  )
}

export function useIntegrationSettingArchive(id: number) {
  const queryClient = useQueryClient()

  const mutationFn = async () => {
    const response = await apiClient.delete<IntegrationSetting>({
      url: `integration_settings/${id}`
    })
    return response.data
  }

  return useMutation(mutationFn, {
    onMutate: async () => {
      await queryClient.cancelQueries([QueryKeys.IntegrationSettings])

      const previousIntegrationSettings = queryClient.getQueryData<IntegrationSettingsResponse>([
        QueryKeys.IntegrationSettings
      ])

      const updatedIntegrationSettings =
        previousIntegrationSettings?.integration_settings.map(item => {
          return {
            ...item,
            ...(item.id === id
              ? {
                  archived: true
                }
              : {})
          }
        }) ?? []

      if (previousIntegrationSettings && updatedIntegrationSettings) {
        queryClient.setQueryData<IntegrationSettingsResponse>([QueryKeys.IntegrationSettings], {
          ...previousIntegrationSettings,
          integration_settings: updatedIntegrationSettings
        })
      }

      return { previousIntegrationSettings }
    },

    onError: (context: { previousIntegrationSettings: IntegrationSettingsResponse }) => {
      if (context.previousIntegrationSettings) {
        queryClient.setQueryData<IntegrationSettingsResponse>(
          [QueryKeys.IntegrationSettings],
          context.previousIntegrationSettings
        )
      }
    },

    onSettled: () => {
      queryClient.invalidateQueries([QueryKeys.IntegrationSettings])
    }
  })
}

export function useIntegrationSettingsUpdate(id: number) {
  const queryClient = useQueryClient()

  const mutationFn = async (values: IntegrationSettingFormType) =>
    apiClient.put<IntegrationSettingFormType, IntegrationSetting>({
      url: `integration_settings/${id}`,
      data: values
    })

  return useMutation(mutationFn, {
    onMutate: async values => {
      await queryClient.cancelQueries([QueryKeys.IntegrationSettings])

      const previousIntegrationSettings = queryClient.getQueryData<IntegrationSettingsResponse>([
        QueryKeys.IntegrationSettings
      ])

      const updatedIntegrationSettings =
        previousIntegrationSettings?.integration_settings.map(item => {
          return {
            ...item,
            ...(item.id === values.id
              ? {
                  name: values.name ?? '',
                  image_url: values.image_url,
                  settings: mapValues(item.settings, (val, key) => ({
                    ...val,
                    value: values.settings[key]
                  }))
                }
              : {})
          }
        }) ?? []

      if (previousIntegrationSettings && updatedIntegrationSettings) {
        queryClient.setQueryData<IntegrationSettingsResponse>([QueryKeys.IntegrationSettings], {
          ...previousIntegrationSettings,
          integration_settings: updatedIntegrationSettings
        })
      }

      return { previousIntegrationSettings }
    },

    onError: (context: { previousIntegrationSettings: IntegrationSettingsResponse }) => {
      if (context.previousIntegrationSettings) {
        queryClient.setQueryData<IntegrationSettingsResponse>(
          [QueryKeys.IntegrationSettings],
          context.previousIntegrationSettings
        )
      }
    },

    onSettled: () => {
      queryClient.invalidateQueries([QueryKeys.IntegrationSettings])
    }
  })
}

// Simple API call as we don't want to store the token in the query cache
export async function fetchIntegrationSettingWebhookAuthToken(id: number) {
  const response = await apiClient.get({
    url: `integration_settings/${id}/webhook_auth_token`
  })
  return response.data as string
}

export function useIntegrationActionItemsImportMutation() {
  const queryClient = useQueryClient()

  return useMutation<IntegrationSetting, ApiError, IntegrationActionItemsImportType>(
    [QueryKeys.RoleMappings],
    async (args: IntegrationActionItemsImportType): Promise<any> => {
      const response = await apiClient.post<FormData, IntegrationSetting>({
        url: `integration_settings/${args.id}/import_integration_action_items`,
        data: args.formData,
        headers: { 'Content-Type': 'multipart/formdata' }
      })

      return response?.data
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([QueryKeys.IntegrationSettings])
      },
      onSettled: () => {
        queryClient.invalidateQueries([QueryKeys.IntegrationActionItems])
      }
    }
  )
}

export function useIntegrationActionItemsExport() {
  return useMutation<any, ApiError, IntegrationActionItemsExportType>(
    ['integration-action-items-export'],
    async (args: IntegrationActionItemsExportType) => {
      const { data } = await apiClient.post({
        url: `integration_settings/${args.id}/export_integration_action_items`,
        data: { iai_ids: args.selectedIntegrationActionItemsId }
      })

      return data
    }
  )
}
