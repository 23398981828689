// @ts-nocheck
import { container } from 'app/Config/IOC'
import { injectable } from 'inversify'
import { observable, computed, action } from 'mobx'
import { RoleWithExpiryPresenter, RolePresenter } from './'
import { AccountsRepository } from 'app/Repositories/Account/AccountsRepository'
import { RoleTypesRepository } from 'app/Repositories/RoleType/RoleTypesRepository'
import { IRoleType } from 'Shared/Entities/Types/RoleType/IRoleType'
import { IRole } from 'Shared/Entities/Types/RoleType/IRole'
import { IAccount } from 'Shared/Entities/Types/Account/IAccount'
import { TRole } from './TRole'
import { TDisplayType } from './TDisplayType'
import { sort } from 'Components/Organisms'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'
import { Types } from 'Gateways/Service/Types'

export interface IRoleTypePresenter {
  roleTypeId: number
  presenter: RoleWithExpiryPresenter | RolePresenter
  type: TRole
  children?: IRoleTypePresenter[]
  accountId?: number
  disabled?: boolean
}

interface IRoleTypeAccount {
  id: number
  name: string
  permissions: {
    create?: boolean
    show?: boolean
  }
}

interface IRoleTypeShort {
  description: string
  contextTypeName: string
  id: number
  name: string
}

@injectable()
export class RoleTypesPresenter {
  private static readonly CONTEXT_TYPE_NAME_ACCOUNT = 'Workspace'

  private roleTypesRepository = container.get(RoleTypesRepository)
  private accountsRepository = container.get(AccountsRepository)
  public language: ILanguageService = container.get(Types.ILanguageService)

  @observable
  public roleTypePresenters: IRoleTypePresenter[] = []

  public displayType: string

  @observable
  public loadingRoleTypes: boolean

  private loadRoleTypes = async () => {
    await this.roleTypesRepository.safeLoad({ display: this.displayType })
  }

  private allowedAccountPermissions: string[]

  @computed
  public get roleTypes(): IRoleTypeShort[] {
    return this.roleTypesRepository.roleTypes.map((roleType: IRoleType) => {
      return {
        description: roleType.description,
        id: roleType.id,
        name: roleType.name,
        contextTypeName: roleType.contextTypeName
      }
    })
  }

  private loadAccounts = async () => {
    await this.accountsRepository.safeLoadPermittedAccounts({ resource: this.displayType })
    await this.accountsRepository.safeLoadAccounts()
  }

  @computed
  public get accounts(): IRoleTypeAccount[] {
    const { accounts, permittedAccounts } = this.accountsRepository
    const sortedAccounts = sort(accounts, 'name', 'string', 'asc')
    const permittedAccountIds = permittedAccounts.map(({ id }) => id)

    return sortedAccounts.map(({ id, name }: IAccount) => ({
      id,
      name,
      permissions: {
        create: permittedAccountIds.includes(id),
        show: true
      }
    }))
  }

  @action
  private init = async narrow => {
    if (
      this.roleTypesRepository.checkRolesLoaded(this.displayType) &&
      this.accountsRepository.isPermittedAccountsLoaded(this.displayType) &&
      this.accountsRepository.accounts.length > 0
    ) {
      this.setRoleTypePresenters(narrow)
      this.loadingRoleTypes = false
      return
    }

    this.loadingRoleTypes = true
    await Promise.all([this.loadRoleTypes(), this.loadAccounts()])
    this.setRoleTypePresenters(narrow)
    this.loadingRoleTypes = false
  }

  constructor(displayType: TDisplayType, narrow: boolean = false, allowedAccountPermissions = ['create']) {
    this.displayType = displayType
    this.allowedAccountPermissions = allowedAccountPermissions
    this.init(narrow)
  }

  public setRoleTypePresenters(narrow) {
    const roleTypePresenters = []

    this.roleTypes.forEach((roleType: any) => {
      const hasChildren = this.roleTypeHasChildren(roleType)
      const parentPresenter = hasChildren
        ? new RolePresenter(roleType.name, roleType.description)
        : new RoleWithExpiryPresenter(this.language, roleType.name, roleType.description, narrow)
      const roleTypePresenter = {
        roleTypeId: roleType.id,
        presenter: parentPresenter,
        type: hasChildren ? 'default' : 'expiry',
        children: []
      }

      if (hasChildren) {
        roleTypePresenter.presenter.checkboxPresenter.withMiddleware(newValue => {
          // Uncheck all of the children
          if (newValue === false) {
            roleTypePresenter.children.map((roleType: IRoleTypePresenter) => {
              roleType.presenter.checkboxPresenter.onChange(false)
            })
          }

          return newValue
        })

        //  when newValue false you return false otherwise you check whether the other checkboxes are true or false
        const noSelectedChildren = (newValue: boolean, accountId: number) => {
          let selectChildren = roleTypePresenter.children.filter(child =>
            child.accountId === accountId ? newValue : child.presenter.checkboxPresenter.value
          )

          return selectChildren.length === 0
        }

        this.accounts.forEach((account: IRoleTypeAccount) => {
          if (this.isAccountAllowed(account)) {
            const disabled = !account.permissions.create

            const childRoleTypePresenter = {
              roleTypeId: roleType.id,
              presenter: new RoleWithExpiryPresenter(this.language, account.name, '', narrow),
              type: 'expiry',
              accountId: account.id,
              disabled
            }

            childRoleTypePresenter.presenter.checkboxPresenter.withMiddleware(newValue => {
              if (noSelectedChildren(newValue, account.id)) roleTypePresenter.presenter.checkboxPresenter.value = false
              return newValue
            })

            if (disabled) {
              childRoleTypePresenter.presenter.checkboxPresenter.withDisabled(disabled)
            }

            roleTypePresenter.children.push(childRoleTypePresenter)
          }
        })
      }

      roleTypePresenters.push(roleTypePresenter)
    })

    this.roleTypePresenters = roleTypePresenters
  }

  private roleTypeHasChildren(roleType: IRoleType) {
    return roleType.contextTypeName === RoleTypesPresenter.CONTEXT_TYPE_NAME_ACCOUNT
  }

  public reset = () => {
    this.roleTypePresenters.forEach((roleTypePresenter: IRoleTypePresenter) => {
      roleTypePresenter.presenter.reset()

      if (roleTypePresenter.children && roleTypePresenter.children.length > 0) {
        roleTypePresenter.children.forEach((childRoleTypePresenter: IRoleTypePresenter) => {
          childRoleTypePresenter.presenter.reset()
        })
      }
    })
  }

  public prepopulateRoles = (roles: IRole[]) => {
    roles.forEach(role => {
      const selectedRoleTypes = this.roleTypePresenters.filter((roleTypePresenter: IRoleTypePresenter) => {
        return roleTypePresenter.roleTypeId === role.roleType.id
      })

      if (selectedRoleTypes.length > 0) {
        selectedRoleTypes.forEach(selectedRole => {
          selectedRole.presenter.prePopulate(role)

          // Child needs to be checked
          if (role.resourceId) {
            const selectedChildren = selectedRole.children.filter(
              childPresenter => role.resourceId === childPresenter.accountId
            )

            selectedChildren.forEach(childPresenter => {
              childPresenter.presenter.prePopulate(role)
            })
          }
        })
      }
    })
  }

  @computed
  public get selectedRoles(): IRole[] {
    const roles = []

    // Locate selected parents
    const selectedParents = this.roleTypePresenters.filter(roleTypePresenter => {
      return roleTypePresenter.presenter.checkboxPresenter.value === true
    })

    selectedParents.forEach(roleTypePresenter => {
      const parentRoleType = this.roleTypes.filter(roleType => {
        return roleType.id === roleTypePresenter.roleTypeId
      })[0]

      // Create roles at children level
      if (roleTypePresenter.children && roleTypePresenter.children.length > 0) {
        roleTypePresenter.children.forEach(childRoleTypeCheckbox => {
          if (childRoleTypeCheckbox.presenter.checkboxPresenter.value === true) {
            roles.push(this.getRoleForSelected(parentRoleType, roleTypePresenter, childRoleTypeCheckbox))
          }
        })
      } else {
        roles.push(this.getRoleForSelected(parentRoleType, roleTypePresenter))
      }
    })

    return roles
  }

  @computed
  public get isDirty(): boolean {
    const dirtyCheckboxes = this.roleTypePresenters.filter(roleTypePresenter => {
      if (roleTypePresenter.presenter.isDirty === true) {
        return true
      } else if (roleTypePresenter.children.length > 0) {
        const dirtyChildren = roleTypePresenter.children.filter(
          childPresenter => childPresenter.presenter.isDirty === true
        )

        return dirtyChildren.length > 0
      }
    })

    return dirtyCheckboxes.length > 0
  }

  private getRoleForSelected = (
    parentRoleType: IRoleTypeShort,
    roleTypePresenter: IRoleTypePresenter,
    childRoleTypePresenter?: IRoleTypePresenter
  ): any => {
    let role = {
      roleType: parentRoleType,
      displayType: this.displayType,
      resourceId: null,
      resourceType: null,
      expiresAt: null
    }

    if (childRoleTypePresenter) {
      role.resourceId = childRoleTypePresenter.accountId
      role.resourceType = 'Account'

      const childPresenter = childRoleTypePresenter.presenter as RoleWithExpiryPresenter
      role.expiresAt = this.getExpiryDateFromPresenter(childPresenter)
    } else if (roleTypePresenter.type === 'expiry') {
      const parentPresenter = roleTypePresenter.presenter as RoleWithExpiryPresenter
      role.expiresAt = this.getExpiryDateFromPresenter(parentPresenter)
    }

    return role
  }

  private getExpiryDateFromPresenter = (presenter: RoleWithExpiryPresenter) => {
    let expiresAt = null

    if (presenter.dateTimePresenter.controlState === 'populated') {
      expiresAt = presenter.dateTimePresenter.getValueInServiceFormat()
    }

    return expiresAt
  }

  private isAccountAllowed(account: IRoleTypeAccount) {
    return this.allowedAccountPermissions.some(
      allowedAccountPermission => account.permissions[allowedAccountPermission]
    )
  }

  public setDisabled = (disabled: boolean) => {
    this.roleTypePresenters.forEach(roleTypePresenter => {
      roleTypePresenter.presenter.setDisabled(disabled)

      if (roleTypePresenter.children.length > 0) {
        roleTypePresenter.children.forEach(childPresenter => {
          childPresenter.presenter.setDisabled(disabled || childPresenter.disabled)
        })
      }
    })
  }
}
