// @ts-nocheck
import { observable, action, computed } from 'mobx'
import { container } from 'app/Config/IOC'
import { Types } from 'Gateways/Service/Types'
import {
  TextInputPresenter,
  MultiSelectRef,
  MultiSelectPresenter,
  ToasterService,
  ErrorAlertPresenter
} from 'Components/Molecules'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'
import { IRoleType } from 'Shared/Entities/Types/RoleType/IRoleType'
import { AccountsPageViewPresenter } from 'app/Settings/Accounts/AccountsPage/AccountsPageViewPresenter'
import { GenericFormPresenter } from '@logicroom/validator'
import { RadioButtonsPresenter } from 'Shared/Components/Atoms'
import { FormInputGroupPresenter } from 'Shared/Components/Templates'
import { IAccount } from 'Shared/Entities/Types/Account/IAccount'
import { IAccountRoleType } from 'app/Shared/Entities/Types/Account/IAccountRoleType'

interface IFormViewModel {
  roleTypes: IRoleType[]
}

export class AccountFormPresenter {
  private language: ILanguageService = container.get(Types.ILanguageService)
  public toasterService: ToasterService = container.get(ToasterService)
  private accountsPageViewPresenter: AccountsPageViewPresenter = container.get(AccountsPageViewPresenter)

  @observable
  public recordViewModel: IAccount = {
    title: '',
    slug: '',
    type: '',
    description: '',
    roleTypes: []
  }

  @observable
  public formViewModel: IFormViewModel = {
    roleTypes: []
  }

  @observable
  public readOnly: boolean = false

  @observable
  public titlePresenter: TextInputPresenter = new TextInputPresenter()
    .withLabel(this.language.get('accounts:form:fieldTitle:label'))
    .isRequired(this.language.get('accounts:form:fieldTitle:errors:required'))

  @observable
  public shortNamePresenter: TextInputPresenter = new TextInputPresenter()
    .withLabel(this.language.get('accounts:form:fieldShortName:label'))
    .isRequired('accounts:form:fieldShortName:errors:required')

  @observable
  public descriptionPresenter: TextInputPresenter = new TextInputPresenter()
    .withLabel(this.language.get('accounts:form:fieldDescription:label'))
    .isMultiline()

  @computed
  public get accountTypePresenter() {
    return new RadioButtonsPresenter(this.accountsPageViewPresenter.accountTypes.map(accountType => accountType.name))
      .withLabel(this.language.get('accounts:form:fieldAccountType:label'))
      .withVertical()
  }

  private roleTypePresenterLookup: { [key: number]: MultiSelectPresenter } = {}

  private createRoleTypesPresenter = (roleType: IRoleType | IAccountRoleType): MultiSelectPresenter => {
    const presenter = new MultiSelectPresenter(this.language)
      .withUserSearchConfig(this.accountsPageViewPresenter.loadUserSearchConfig())
      .withLabel(roleType.name)
      .withHelpText(roleType.description)

    if (roleType.hasOwnProperty('users')) {
      const roleTypeWithUsers = roleType as IAccountRoleType
      presenter.withOptions(roleTypeWithUsers.users || []).withSelectedOptions(roleTypeWithUsers.users || [])
    } else {
      presenter.withHiddenOptionAddedCallback(label => {
        this.toasterService.pop(null, this.language.get('common:multiSelectDefaults:userAddedSuccessfully'), 'success')
      })
    }

    presenter.disabled = this.readOnly

    this.roleTypePresenterLookup[roleType.id] = presenter
    this.accountForm.addFormInput(presenter)
    return presenter
  }

  @observable
  public roleTypesPresenter: FormInputGroupPresenter<MultiSelectPresenter, IRoleType> = new FormInputGroupPresenter<
    MultiSelectPresenter,
    IRoleType
  >(MultiSelectRef, this.createRoleTypesPresenter)

  @observable
  public accountForm: GenericFormPresenter = new GenericFormPresenter()
    .addFormInput(this.titlePresenter)
    .addFormInput(this.shortNamePresenter)
    .addFormInput(this.descriptionPresenter)

  @observable
  public errorAlertPresenter: ErrorAlertPresenter = new ErrorAlertPresenter()

  @observable
  public submitted: boolean = false

  public loadFormViewModel = async () => {
    if (!this.accountsPageViewPresenter.roleTypesVM.length) {
      await this.accountsPageViewPresenter.loadRoleTypes()
    }

    this.formViewModel.roleTypes = this.accountsPageViewPresenter.roleTypesVM
  }

  constructor() {
    this.loadFormViewModel()
  }

  @action
  public processSubmission = (): { result: boolean; recordViewModel: IAccount } => {
    this.submitted = true
    this.accountForm.serverErrors = []
    this.updateViewModel()

    if (!this.formIsValid) {
      this.triggerFieldErrors()
      this.errorAlertPresenter.setErrorMessages([this.language.get('common:formInvalid')])

      return {
        result: false,
        recordViewModel: this.recordViewModel
      }
    }

    return {
      result: true,
      recordViewModel: this.recordViewModel
    }
  }

  @action
  private updateViewModel = () => {
    this.recordViewModel.title = this.titlePresenter.value
    this.recordViewModel.slug = this.shortNamePresenter.value
    this.recordViewModel.description = this.descriptionPresenter.value
    this.recordViewModel.type = this.accountTypePresenter.selectedOption
    this.recordViewModel.roleTypes = this.roleTypesPresenter.items.map(roleTypeItem => ({
      id: roleTypeItem.data.id,
      name: roleTypeItem.data.name,
      description: roleTypeItem.data.description,
      contextTypeId: roleTypeItem.data.contextTypeId,
      users: roleTypeItem.presenter.selectedOptions
    }))
  }

  @action
  private populateFormWithRecordViewModel = () => {
    this.titlePresenter.value = this.recordViewModel.title || ''
    this.shortNamePresenter.value = this.recordViewModel.slug || ''
    this.descriptionPresenter.value = this.recordViewModel.description || ''
    this.accountTypePresenter.selectedOption = this.recordViewModel.type || ''

    const roleTypesSorted = this.recordViewModel.roleTypes.sort(
      (roleTypeA: IAccountRoleType, roleTypeB: IAccountRoleType) => roleTypeA.name.localeCompare(roleTypeB.name)
    )

    this.roleTypesPresenter.withData([...roleTypesSorted])
  }

  @computed
  public get formIsValid(): boolean {
    return this.accountForm.isValid
  }

  @action
  public withRecordViewModel = (recordViewModel: IAccount) => {
    this.recordViewModel = JSON.parse(JSON.stringify(recordViewModel))
    this.populateFormWithRecordViewModel()
    return this
  }

  @action
  public withReadOnly = (readOnly: boolean) => {
    this.readOnly = readOnly
    return this
  }

  @action
  public refresh = () => {
    this.reset()
    this.populateFormWithRecordViewModel()
  }

  @action
  public disableForm = () => {
    this.getAllTextInputs().forEach((input: TextInputPresenter) => {
      input.isDisabled()
    })
    this.setRoleTypeDisabled()
  }

  @action
  public enableForm = () => {
    this.getAllTextInputs().forEach((input: TextInputPresenter) => {
      input.disabled = false
    })
    this.setRoleTypeDisabled()
  }

  private setRoleTypeDisabled = () => {
    Object.keys(this.roleTypePresenterLookup).forEach(typeId => {
      const selectPresenter = this.roleTypePresenterLookup[typeId] as MultiSelectPresenter
      selectPresenter.disabled = this.readOnly
    })
  }

  private getAllTextInputs = (): TextInputPresenter[] => {
    return [this.titlePresenter, this.shortNamePresenter, this.descriptionPresenter]
  }

  @action
  public reset = () => {
    this.accountsPageViewPresenter.pageVM.editPanelDirty = false
    this.submitted = false
    this.accountTypePresenter.isDirty = false

    if (this.roleTypesPresenter) {
      this.roleTypesPresenter.items.forEach(item => {
        item.presenter.selectedOptions = []
        item.presenter.isDirty = false
        item.presenter.isValid = true
      })
    }

    this.accountForm.reset()
  }

  @action
  private triggerFieldErrors = () => {
    this.getAllTextInputs().forEach((input: TextInputPresenter) => {
      input.isDirty = true
    })
  }
}
