import { useState } from 'react'

import { PeoplePanel } from 'main/components/runbook/right-panels/people-panel/people-panel'
import {
  Permissions,
  Runbook,
  SelectedTeam,
  SelectedUser,
  Stream,
  ValidationRunbookTeam
} from 'main/components/runbook/right-panels/people-panel/types'
import { ReactAngularConnector, useConnectedEvent } from 'main/connectors/react-angular-connector'

type PeoplePanelScreen = 'users-and-teams' | 'user-details' | 'team-details'

type PeoplePanelState = {
  accountId: number | null
  selectedTab: string | null
  runbook: Runbook | null
  runbookVersionId: number | null
  isCurrentVersion: boolean | null
  permissions: Permissions | null
  runbookTeams: ValidationRunbookTeam[] | null
  streams: Stream[] | null
  screen: PeoplePanelScreen | null
  selectedUser: SelectedUser | undefined | null
  selectedTeam: SelectedTeam | undefined | null
}

export const PeoplePanelConnector = () => {
  const [state, setState] = useState<PeoplePanelState>({
    accountId: null,
    selectedTab: null,
    runbook: null,
    runbookVersionId: null,
    isCurrentVersion: null,
    permissions: null,
    runbookTeams: null,
    streams: null,
    screen: null,
    selectedUser: null,
    selectedTeam: null
  })

  useConnectedEvent('runbook-streams-updated', (eventProps: { streams: Stream[] }) => {
    // logically this doesn't make sense with the arg type since the args says that streams must be present
    // TODO: figure out the correct typing and conditional check that should happen
    if (eventProps.streams) {
      setState(prevState => ({
        ...prevState,
        streams: eventProps.streams
      }))
    }
  })

  const handleOpenPanel = ({
    accountId,
    runbook,
    runbookVersionId,
    isCurrentVersion,
    permissions,
    selectedTab,
    runbookTeams,
    streams,
    selectedUser,
    selectedTeam
  }: {
    accountId: number
    runbook: Runbook
    runbookVersionId: number
    isCurrentVersion: boolean
    permissions: Permissions
    selectedTab: string
    runbookTeams: ValidationRunbookTeam[]
    streams: Stream[]
    selectedUser: SelectedUser
    selectedTeam: SelectedTeam
  }) => {
    setState({
      accountId,
      selectedTab,
      runbook,
      runbookVersionId,
      isCurrentVersion,
      permissions,
      runbookTeams,
      streams,
      selectedUser,
      selectedTeam,
      screen: (selectedTeam && 'team-details') || (selectedUser && 'user-details') || 'users-and-teams'
    })
  }

  const setSelectedTab = (tab: string) => {
    setState(prevState => ({
      ...prevState,
      selectedTab: tab
    }))
  }

  return (
    <ReactAngularConnector
      mountEvent="open-runbook-people-panel"
      unmountEvent="runbook-people-panel-close"
      onMount={handleOpenPanel}
    >
      {state.runbook &&
        state.runbookVersionId &&
        state.isCurrentVersion !== null &&
        state.accountId &&
        state.selectedTab &&
        state.runbookTeams &&
        state.permissions &&
        state.screen && (
          <PeoplePanel
            accountId={state.accountId}
            runbook={state.runbook}
            runbookVersionId={state.runbookVersionId}
            isCurrentVersion={state.isCurrentVersion}
            permissions={state.permissions}
            selectedTab={state.selectedTab}
            onTabSelect={setSelectedTab}
            runbookTeams={state.runbookTeams}
            streams={state.streams || []}
            selectedUser={state.selectedUser ?? undefined}
            selectedTeam={state.selectedTeam ?? undefined}
            screen={state.screen}
          />
        )}
    </ReactAngularConnector>
  )
}
