import { IconButton } from '@cutover/react-ui'
import { RunTypeToggle } from './run-type-toggle'
import { Search } from './search'
import { StatusToggle } from './status-toggle'
import { SubHeader, SubHeaderResultsText } from '../../layout/shared/sub-header'
import { useFilters } from 'main/components/shared/filter/filter-provider'
import { queryClient } from 'main/query-client'
import { useMyTasksData } from 'main/services/api/data-providers/my-tasks/my-tasks-data'
import { useLanguage } from 'main/services/hooks'

export const Subheader = () => {
  const { t } = useLanguage('myWork', { keyPrefix: 'subHeader' })
  const { meta } = useMyTasksData()
  const { filters } = useFilters()
  const handleReload = () => {
    queryClient.refetchQueries({ queryKey: ['my-tasks', filters] })
  }

  return (
    <SubHeader.Items>
      <SubHeader.LeftItems>
        <Search />
        <RunTypeToggle />
        <StatusToggle />
        {meta ? (
          <SubHeaderResultsText
            value={t('results', { amount: `${meta.filtered_results_count}/${meta.total_results_count}` })}
          />
        ) : null}
      </SubHeader.LeftItems>
      <SubHeader.RightItems>
        <IconButton icon="refresh" label="Refresh" onClick={handleReload} />
      </SubHeader.RightItems>
    </SubHeader.Items>
  )
}
