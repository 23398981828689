// @ts-nocheck
import { observable, action } from 'mobx'
import { injectable } from 'inversify'

@injectable()
export class RoleTypesApplicationPresenter {
  @observable
  public submitted: boolean = false

  @action
  public setSubmitted = (val: boolean) => {
    this.submitted = val
  }
}
