import { ReactNode } from 'react'
import styled from 'styled-components/macro'

import { Box, LoadingPanel } from '../../layout'
import { themeColor } from '../../theme'
import { Text } from '../../typography'

export type ContentWrapperProps = {
  hasHeader?: boolean
  loading?: boolean
  error?: boolean
  errorMessage?: string
  children?: ReactNode
  id?: string
  'aria-labelledby'?: string
}

export const ContentWrapper = ({
  hasHeader,
  loading,
  error,
  errorMessage,
  children,
  ...restProps
}: ContentWrapperProps) => {
  return (
    <ContentWrapperContent hasHeader={hasHeader} {...restProps}>
      {loading ? (
        <LoadingPanel overlay />
      ) : error ? (
        <Text as="p">{errorMessage || 'Something went wrong'}</Text>
      ) : (
        children
      )}
    </ContentWrapperContent>
  )
}

const ContentWrapperContent = styled(Box)<{ hasHeader?: boolean }>`
  padding: 1rem;
  margin: 0 16px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  background-color: ${themeColor('bg')};
  position: absolute;
  top: 0;
  margin-top: ${props => (props.hasHeader ? '64px' : '16px')};
  bottom: 0;
  left: 0;
  right: 0;
  overflow-x: hidden;
  overflow-y: auto;
  .dashboards-main & {
    margin-top: 0;
  } // hack to show correct top margin on RB dashboards page. Integration settings area is only view that ever uses 64
`
