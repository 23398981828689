import { ReactNode, useMemo } from 'react'
import { ColorType } from 'grommet/utils'
import { MergeExclusive } from 'type-fest'

import { Box } from '../layout/box/box'
import { ColorProp } from '../theme/color'
import { resolveColor, useTheme } from '../theme/grommet-utils'
import { Text } from '../typography/text/text'

const LABELED_BADGE_MIN_SIZE = 18
const UNLABELED_BADGE_SIZE = 8

const DEFAULT_BADGE_ANCHOR_ORIGIN: BadgeAnchorOrigin = {
  vertical: 'top',
  horizontal: 'right'
}

// could extend this to support badges that are centerd on either axis (e.g., right side, centered vertically)
type BadgeAnchorOrigin = {
  vertical: 'top' | 'bottom'
  horizontal: 'left' | 'right'
}

type SemanticColorTypes = 'primary' | 'alert' | 'dark' | 'light'

type ColorOrType = MergeExclusive<
  { type: SemanticColorTypes },
  { color: ColorProp | string; textColor?: ColorProp | string }
>
type ChildVariantType = MergeExclusive<{ anchor?: BadgeAnchorOrigin; children: ReactNode }, {}>

export type BadgeProps = {
  label?: number
  max?: number
  overlap?: 'round' | 'rectangular' | false
  itemHeight?: string
  itemWidth?: string
  'data-testid'?: string
  'aria-hidden'?: boolean
} & ColorOrType &
  ChildVariantType

export const Badge = ({ max = 2, 'data-testid': dataTestId, ...props }: BadgeProps) => {
  const theme = useTheme()

  const label = useMemo(() => {
    const maxCount = Math.pow(10, max) - 1
    return props.label === undefined ? props.label : props.label <= maxCount ? String(props.label) : `${maxCount}+`
  }, [max, props.label])

  const [bgColor, fgColor] = useMemo(() => {
    let bg,
      fg = 'text' as ColorProp
    if (props.type) {
      bg = `badge-${props.type}-bg` as ColorProp
      fg = `badge-${props.type}-fg` as ColorProp
      return [resolveColor(bg, theme), resolveColor(fg, theme)]
    } else {
      bg = props.color as ColorProp
      fg = props.textColor as ColorProp
      return [bg, fg]
    }
  }, [props, theme])

  const badgeContent = (
    <Box
      align="center"
      justify="center"
      aria-hidden={props['aria-hidden']}
      round={`${LABELED_BADGE_MIN_SIZE / 2}px`}
      height={label ? `${LABELED_BADGE_MIN_SIZE}px` : `${UNLABELED_BADGE_SIZE}px`}
      pad={{ horizontal: label ? 'xxsmall' : undefined }}
      width={{
        min: label ? `${LABELED_BADGE_MIN_SIZE}px` : undefined,
        width: !label ? `${UNLABELED_BADGE_SIZE}px` : undefined
      }}
      background={bgColor as ColorProp}
      data-testid={dataTestId}
      css={`
        pointer-events: none;
        span {
          color: ${fgColor} !important;
        }
      `}
    >
      {label && (
        <Text size="11px" css="line-height: 1" color={fgColor as ColorType}>
          {label}
        </Text>
      )}
    </Box>
  )

  if (!props.children) {
    return badgeContent
  } else {
    const anchor = props.anchor ?? DEFAULT_BADGE_ANCHOR_ORIGIN
    const overlap = props.overlap ?? 'rectangular'
    const offset = props.overlap === false ? '0' : overlap === 'round' ? '14%' : '1px'

    return (
      <Box css="position: relative" height={props.itemHeight} width={props.itemWidth}>
        {props.children}
        <Box
          css={`
            z-index: 2;
            position: absolute;
            ${anchor.vertical}: ${offset};
            ${anchor.horizontal}: ${offset};
            transform: ${props.overlap !== false && 'scale(1) translate(50%, -50%)'};
          `}
        >
          {badgeContent}
        </Box>
      </Box>
    )
  }
}
