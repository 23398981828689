import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const MoreVertical = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="MoreVertical" {...props}>
    <path d="M24 12a3 3 0 1 1 0-6 3 3 0 0 1 0 6Zm0 15a3 3 0 1 1 0-6 3 3 0 0 1 0 6Zm0 15a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z" fill="#000" />
  </StyledIcon>
))

MoreVertical.displayName = 'MoreVertical'

export { MoreVertical }
