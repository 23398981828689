import { useContext } from 'react'

import { LegacyText as Text } from '@cutover/react-ui'
import { DashboardComponentProps } from '../../types'
import { MrdDashboardWidget } from '../mrd-dashboard-widget'
import { getFilterParams } from '../mrd-filter-util'
import { DashboardContext } from 'main/components/dashboards/runbooks-dashboard-page'
import {
  Duration,
  formatDuration,
  NoDataAvailable,
  Table,
  Tile
} from 'main/components/dashboards/widgets/account/common'
import { useAngularRightSidePanel } from 'main/services/hooks'

type TaskDuration = {
  name: string
  duration: Duration
  runbook_ids: number[]
}

type LongestAverageTaskDurationsData = {
  name: string
  values: TaskDuration[]
}

export type LongestAverageTaskDurationsWidgetProps = DashboardComponentProps<LongestAverageTaskDurationsData>

export const LongestAverageTaskDurationsWidget = ({ data }: LongestAverageTaskDurationsWidgetProps) => {
  const { accountId, dashboardId, filterParams } = useContext(DashboardContext)
  const { openAngularSidePanel } = useAngularRightSidePanel()

  const taskDurations: TaskDuration[] = data.values

  return (
    <MrdDashboardWidget title={data.name}>
      {taskDurations.length === 0 ? (
        <NoDataAvailable />
      ) : (
        <LongestAverageTaskDurations
          taskNameTitle={'Task name'}
          averageDurationTitle={'Average duration'}
          taskDurations={taskDurations}
          onTaskSelected={id => {
            const runbookIds = taskDurations.find(t => t.name === id)?.runbook_ids ?? []

            if (runbookIds.length === 0) {
              return
            }

            const contentId = {
              accountId,
              dashboardId,
              ...getFilterParams(filterParams),
              stage: ['complete'],
              run_type: ['live'],
              runbook_id: runbookIds,
              subFilterKey: 'runbook_id',
              subFilterValue: runbookIds
            }

            openAngularSidePanel({ content: 'filtered-changes', contentId })
          }}
        />
      )}
    </MrdDashboardWidget>
  )
}

export type LongestAverageTaskDurationsProps = {
  taskNameTitle: string
  averageDurationTitle: string
  taskDurations: TaskDuration[]
  onTaskSelected?: (id: string) => void
}

export const LongestAverageTaskDurations = ({
  taskNameTitle,
  averageDurationTitle,
  taskDurations
}: LongestAverageTaskDurationsProps) => {
  const tasks = (taskDurations ?? []).map(task => ({ ...task, id: task.name, duration: formatDuration(task.duration) }))

  return (
    <Tile variant="secondary" center={false}>
      <Table
        headers={[taskNameTitle, averageDurationTitle]}
        data={tasks}
        renderRowCells={rd => [
          <Text title={rd.name} truncate>
            {rd.name}
          </Text>,
          rd.duration
        ]}
      />
    </Tile>
  )
}
