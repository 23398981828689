import { keyBy, unescape } from 'lodash'
import { selector } from 'recoil'

import {
  CustomField,
  CustomFieldApplyToSlug,
  CustomFieldGroup,
  CustomFieldUser,
  FieldOption
} from 'main/services/queries/types'
import { accountMetaState } from './account'

export type ExtendedCustomField = CustomField & {
  field_type_name: string
  apply_to_name: string
  apply_to_slug: CustomFieldApplyToSlug
}

export const accountCustomFieldsState = selector<ExtendedCustomField[]>({
  key: 'account-custom-fields',
  get: ({ get }) => {
    const customFields = get(accountMetaState).custom_fields

    return customFields.map(cf => {
      let constraint = cf.constraint

      try {
        constraint = JSON.parse(constraint)
      } catch (e) {}

      return {
        ...cf,
        constraint,
        name: unescape(cf.name),
        field_type_name: cf.field_type.name,
        apply_to_name: cf.apply_to?.name,
        apply_to_slug: cf.apply_to?.slug
      }
    })
  }
})

export const customFieldLookups = selector({
  key: 'custom-field-lookups',
  get: ({ get }) => {
    const customFields = get(accountCustomFieldsState)

    const optionsLookup = customFields.reduce((acc, cf) => {
      cf.field_options?.forEach(fo => (acc[fo.id] = fo))
      return acc
    }, {} as Record<number, FieldOption>)

    return {
      fields: keyBy(customFields, 'id'),
      options: optionsLookup
    }
  }
})

export const customFieldOptionsState = selector({
  key: 'custom-field-options',
  get: ({ get }) => {
    const customFields = get(accountCustomFieldsState)

    const options = customFields.flatMap(cf => cf.field_options || [])
    return options
  }
})

export const customFieldOptionsLookup = selector({
  key: 'custom-field-options-lookup',
  get: ({ get }) => {
    const { options } = get(customFieldLookups)
    return options
  }
})

export const customFieldLookup = selector({
  key: 'custom-field-lookup',
  get: ({ get }) => {
    const { fields } = get(customFieldLookups)
    return fields
  }
})

export const accountCustomFieldUsers = selector<CustomFieldUser[]>({
  key: 'account-custom-field-users',
  get: ({ get }) => {
    return get(accountMetaState).custom_field_users
  }
})

export const customFieldUserLookup = selector({
  key: 'custom-field-user-lookup',
  get: ({ get }) => {
    const customFieldUsers = get(accountCustomFieldUsers)
    return keyBy(customFieldUsers ?? [], 'id')
  }
})

export const accountCustomFieldGroupsState = selector<CustomFieldGroup[]>({
  key: 'account-custom-field-groups',
  get: ({ get }) => {
    return get(accountMetaState).custom_field_groups
  }
})

export const customFieldGroupsLookup = selector({
  key: 'custom-field-groups-lookup',
  get: ({ get }) => {
    const customFieldGroups = get(accountCustomFieldGroupsState)

    return keyBy(customFieldGroups ?? [], 'id')
  }
})
