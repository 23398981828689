// @ts-nocheck
import theme from 'styled-theming'
import { colors } from 'app/Styles/Variables'

export const themeColors = {
  primary: {
    color: colors.primaryGrey,
    backgroundColor: colors.primaryGreyBackgroundColor
  },
  secondary: {
    color: colors.secondary,
    backgroundColor: colors.primaryGreyBackgroundColor
  },
  success: {
    color: colors.success,
    backgroundColor: colors.successBackgroundColor
  },
  warning: {
    color: colors.primaryGreyHover,
    backgroundColor: colors.primaryGreyHoverBackgroundColor
  },
  danger: {
    color: colors.error,
    backgroundColor: colors.errorBackgroundColor
  },
  info: {
    color: colors.primaryAltDark,
    backgroundColor: colors.bgHighlight
  },
  light: {
    color: colors.textLightest,
    backgroundColor: colors.bgLightest
  },
  dark: {
    color: colors.dark,
    backgroundColor: colors.bgDark
  }
}

export const backgroundColor = theme('mode', {
  primary: themeColors.primary.backgroundColor,
  secondary: themeColors.secondary.backgroundColor,
  success: themeColors.success.backgroundColor,
  warning: themeColors.warning.backgroundColor,
  danger: themeColors.danger.backgroundColor,
  info: themeColors.info.backgroundColor,
  light: themeColors.light.backgroundColor,
  dark: themeColors.dark.backgroundColor
})

export const textColor = theme('mode', {
  primary: themeColors.primary.color,
  secondary: themeColors.secondary.color,
  success: themeColors.success.color,
  warning: themeColors.warning.color,
  danger: themeColors.danger.color,
  info: themeColors.info.color,
  light: themeColors.light.color,
  dark: themeColors.dark.color
})
