import { useMemo } from 'react'
import { RadioButtonGroup, RadioButtonGroupExtendedProps } from 'grommet'
import styled from 'styled-components/macro'

import { radioBoxFocusCss, RadioboxItem } from '../../atoms/radiobox'

type RadioboxListProps = {
  readOnly?: boolean
  required?: boolean
  disabled?: boolean
  name?: string
  valueKey?: string
  labelKey?: string
  direction?: 'row' | 'column'
  value?: RadioButtonGroupExtendedProps['value']
  defaultValue?: RadioButtonGroupExtendedProps['defaultValue']
  onChange?: RadioButtonGroupExtendedProps['onChange']
  options: RadioButtonGroupExtendedProps['options']
  onFocus?: RadioButtonGroupExtendedProps['onFocus']
  onBlur?: RadioButtonGroupExtendedProps['onBlur']
}
/*
 * This is a stripped down RadioboxGroup without the FormField wrapper
 * for use in the filter panel => filter-group-checkbox.tsx
 */
export const RadioboxList = ({
  readOnly,
  disabled,
  name,
  direction = 'column',
  labelKey = 'label',
  ...props
}: RadioboxListProps) => {
  const initialValue = useMemo(() => props.defaultValue ?? props.value, [])
  const handleReadOnlyChange: RadioButtonGroupExtendedProps['onChange'] = event => {
    event?.preventDefault()
  }

  if (direction === 'row' && props.options.length > 4) {
    console.warn('RadioboxList: When using a row direction, the number of options should known and 4 or less.')
  }

  return (
    <StyledRadioButtonGroup
      {...props}
      name={name ?? ''}
      direction={direction}
      wrap
      gap={direction === 'column' ? '0' : 'xsmall'}
      onChange={readOnly ? handleReadOnlyChange : props.onChange}
      value={readOnly ? initialValue : props.value}
      disabled={disabled}
    >
      {(option: any, { checked, hover: _hover }: { checked: boolean; hover?: boolean }) => {
        return (
          <RadioboxItem
            label={typeof option !== 'object' ? option : option[labelKey]}
            disabled={disabled || (typeof option === 'object' && option.disabled)}
            checked={checked}
          />
        )
      }}
    </StyledRadioButtonGroup>
  )
}

const StyledRadioButtonGroup = styled(RadioButtonGroup)`
  ${radioBoxFocusCss}
`
