import { useQuery } from 'react-query'

import { apiClient, ApiError } from '../api'
import { QueryKeys } from './query-keys'

export type CustomPromptType = {
  name: string
  description: string
  key: string
}

type CustomPromptsType = CustomPromptType[]

type ImprovementPromptsResponseType = {
  runbook_improvement_prompts: CustomPromptsType
}

type ChangeType = {
  type: 'add' | 'modify'
  resource: string
  id?: string
  attributes: Object
}

export type SuggestionType = {
  headline: string
  suggestionDetails: string
  changes: ChangeType
}

export type SuggestionsResponseType = { suggestions: SuggestionType[] }

export function useRunbookGenerateSuggestions(runbookId: number | null, prompt: string, options: any) {
  return useQuery<SuggestionsResponseType, ApiError>(
    QueryKeys.RunbookGenerateSuggestions,
    async () => {
      const { data } = await apiClient.get<SuggestionsResponseType>({
        url: `runbooks/${runbookId}/generate_suggestions`,
        params: {
          prompt: prompt
        }
      })

      return data
    },
    options
  )
}

export function useRunbookImprovementPrompts() {
  return useQuery<CustomPromptsType, ApiError>(QueryKeys.RunbookImprovementPrompts, async () => {
    const { data } = await apiClient.get<ImprovementPromptsResponseType>({
      url: 'runbooks/improvement_prompts'
    })

    return data.runbook_improvement_prompts
  })
}
