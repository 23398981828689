import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import parse from 'html-react-parser'

import { Box, Form, Message, Modal, Select, Text, useNotify } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { IntegrationSetting } from 'main/services/queries/types'
import { useIntegrationActionItemsImportMutation } from 'main/services/queries/use-integration-settings'
import { useAccountsQuery } from 'main/services/queries/use-accounts'

type IntegrationActionItemsImportModalProps = {
  integrationActionItemsImportModalOpen: boolean
  integrationSetting: IntegrationSetting
  onIntegrationActionItemsImportModalClose: () => void
}

export type IntegrationActionItemsImportType = {
  id: number
  formData: FormData
}

export type IntegrationActionItemsImportFormData = {
  visibility: number
  files: FileList | never[]
}

export const IntegrationActionItemsImportModal = ({
  integrationActionItemsImportModalOpen,
  integrationSetting,
  onIntegrationActionItemsImportModalClose
}: IntegrationActionItemsImportModalProps) => {
  const { t } = useLanguage('integrationSettings')
  const notify = useNotify()
  const navigate = useNavigate()
  const { data: accountsData } = useAccountsQuery()
  const accounts = accountsData?.accounts
  const { mutateAsync, isLoading, isError, isSuccess, error, reset } = useIntegrationActionItemsImportMutation()

  const defaultValues = {
    files: [],
    visibility: -1,
    overwrite: false
  }

  const methods = useForm({
    defaultValues: defaultValues
  })

  const accountOptions =
    accounts?.map(account => ({
      label: account.name,
      value: account.id
    })) ?? []
  accountOptions.unshift({
    label: 'Global',
    value: -1
  })

  const { register, control, handleSubmit } = methods

  const onActionItemImportModalConfirm = async (data: IntegrationActionItemsImportFormData) => {
    const formData = new FormData()
    formData.append('file', data.files ? data.files[0] : '')
    if (data.visibility === -1) {
      formData.append('global', 'true')
      formData.append('account_id', '')
    } else {
      formData.append('global', 'false')
      formData.append('account_id', `${data.visibility}`)
    }

    mutateAsync({ id: integrationSetting.id, formData: formData })
      .then(() => {
        notify.success(t('actionItemsImportModal.actionConfirmation'))
        notify.warning(t('actionItemsImportModal.actionWarning'), { title: 'NOTE' })
        navigate(`../integration_connections?setting_id=${integrationSetting.id}`)
      })
      .catch(() => {
        notify.error(t('actionItemsImportModal.actionError'))
      })
  }

  useEffect(() => {
    if (isSuccess) {
      onIntegrationActionItemsImportModalClose()
      methods.reset(defaultValues)
    }
  }, [isSuccess])

  return (
    <Modal
      open={integrationActionItemsImportModalOpen}
      onClose={() => {
        onIntegrationActionItemsImportModalClose()
        reset()
      }}
      onClickConfirm={handleSubmit(onActionItemImportModalConfirm)}
      title={t('actionItemsImportModal.header')}
      confirmText={t('actionItemsImportModal.confirmButton')}
      confirmIcon="import"
      loading={isLoading}
    >
      {isError && (
        <Box margin={{ bottom: '12px' }}>
          <Message type="error" message={error?.errors?.join(', ')} />
        </Box>
      )}
      <Box margin={{ bottom: '12px' }}>
        <Text>{parse(t('actionItemsImportModal.modalMessage'))}</Text>
      </Box>
      <Form>
        <Controller
          name={'visibility'}
          control={control}
          render={({ field: { onChange, value, ref } }) => (
            <Select
              onChange={onChange}
              required
              inputRef={ref}
              label={t('actionItemsImportModal.visibility.label')}
              value={value}
              options={accountOptions}
            />
          )}
        />
        {/*TODO: Introduce proper react-ui file input component*/}
        <input
          type="file"
          accept=".yml"
          css={`
            margin-top: 20px;
            margin-bottom: 14px;
          `}
          data-testid={'action-items-import-input'}
          {...register('files')}
        />
      </Form>
    </Modal>
  )
}
