// @ts-nocheck
import { container } from 'app/Config/IOC'
import { computed, observable, observe, action, Lambda } from 'mobx'
import { Types } from 'Gateways/Service/Types'
import { ILanguageService } from 'Helpers/Language/ILanguageService'
import { IRoleTypeEntry } from './IRoleTypeEntry'
import { ITableColumnTypes, TablePresenter, ISortParams, sort } from 'Components/Organisms'
import { RoleTypeRepository } from 'app/Repositories/RoleType/RoleTypeRepository'
import { RoleTypesRepository } from 'app/Repositories/RoleType/RoleTypesRepository'
import { Router } from 'app/Routing/Router'

export class RoleTypeListPresenter {
  private roleTypesRepository: RoleTypesRepository = container.get(RoleTypesRepository)

  private roleTypeRepository: RoleTypeRepository = container.get(RoleTypeRepository)

  private router: Router = container.get(Router)

  private language: ILanguageService = container.get(Types.ILanguageService)

  public sortedVm: any[] = []

  @observable
  public selectedId: number = null

  @observable
  public editPanelIsDirty = false

  @observable
  public columns: ITableColumnTypes[] = [
    {
      accessor: 'name',
      name: this.language.get('roleTypes:list:tableHeaders:name'),
      width: 180,
      dataType: 'string',
      sortable: true
    },
    {
      accessor: 'contextTypeName',
      name: this.language.get('roleTypes:list:tableHeaders:context'),
      width: 180,
      dataType: 'string',
      sortable: true
    },
    {
      accessor: 'userCount',
      name: this.language.get('roleTypes:list:tableHeaders:userCount'),
      width: 180,
      dataType: 'hyperlinkOrString',
      sortable: true,
      hyperlinkOptions: {
        href: '',
        target: '_self',
        customHyperlinkFunction: (id, contextTypeId) => {
          if (contextTypeId <= 3) {
            this.router.goToPageId('users', '', 'role_type=' + id)
          }
        },
        dataType: 'number'
      }
    }
  ]

  @computed
  public get viewModel(): IRoleTypeEntry[] {
    const retData = this.roleTypesRepository.filteredRoleTypes.map(roleType => {
      return {
        id: roleType.id,
        name: roleType.name,
        contextTypeName: roleType.contextTypeName,
        notify: roleType.notify,
        enabled: roleType.enabled,
        userCount: roleType.usersCount,
        description: roleType.description,
        contextTypeId: roleType.contextTypeId
      }
    })
    return retData
  }

  private closePanelCallback = () => null

  private checkEditPanelIsDirtyCallback = () => null

  public disposers: Lambda[] = []

  @action
  public clearDisposers = () => {
    if (this.disposers.length === 0) return
    this.disposers.forEach(disposer => disposer())
    this.disposers = []
  }

  constructor() {
    this.tablePresenter.sortDirection = 'asc'
    this.tablePresenter.sortedHeader = 'contextTypeName'

    const filteredRoleTypeTotalObserverDisposer = observe(
      this.roleTypesRepository,
      'filteredRoleTypeTotal',
      () => {
        if (this.roleTypesRepository.checkRolesLoaded()) {
          this.tablePresenter.sortHandler({ accessor: this.tablePresenter.sortedHeader, dataType: 'string' }, true)
        }
      },
      true
    )

    this.disposers.push(filteredRoleTypeTotalObserverDisposer)

    const roleTypesObserverDisposer = observe(
      this.roleTypesRepository,
      'roleTypes',
      obj => {
        this.tablePresenter.initNewData(this.viewModel, this.roleTypesRepository.filteredRoleTypeTotal)
        this.tablePresenter.sortHandler({ accessor: this.tablePresenter.sortedHeader, dataType: 'string' }, true)
      },
      true
    )

    this.disposers.push(roleTypesObserverDisposer)
  }

  public loadSort = (sortParams: ISortParams) => {
    let data = sort(this.viewModel, 'name', sortParams.dataType, sortParams.sortDirection)
    this.sortedVm = sort(data, sortParams.accessor, sortParams.dataType, sortParams.sortDirection)
    this.tablePresenter.initNewData(this.sortedVm, this.roleTypesRepository.filteredRoleTypeTotal)
  }

  @action
  public loadRoleType = async (roleTypeId: number) => {
    if (this.checkEditPanelIsDirtyCallback()) return

    if (this.roleTypeRepository.roleTypeForEdit && this.roleTypeRepository.roleTypeForEdit.id === roleTypeId) {
      this.roleTypeRepository.clearSelectedId()
      this.closePanelCallback()
    } else {
      this.selectedId = roleTypeId
      await this.roleTypeRepository.loadData({ roleTypeId })
    }
  }

  @action
  public withClosePanelCallback = (callback: () => void) => {
    this.closePanelCallback = callback
    return this
  }

  @action
  public withcheckEditPanelIsDirtyCallback = (callback: () => void) => {
    this.checkEditPanelIsDirtyCallback = callback
    return this
  }

  @observable
  public tablePresenter = new TablePresenter(
    this.viewModel,
    this.columns,
    'role-types-list',
    this.roleTypesRepository.filteredRoleTypeTotal
  )
    .withLoadSortCallback(this.loadSort)
    .withLoadRowCallback(this.loadRoleType)
    .withVariableWidth()
}
