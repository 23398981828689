// @ts-nocheck
import { injectable } from 'inversify'
import { computed, action, observable } from 'mobx'
import { setReactDirty } from 'Shared/Helpers/SPASharedStateService'
import { GenericAdminViewPresenter } from 'Shared/Presenters/GenericAdminViewPresenter'
import { GenericPageFormPresenter } from './GenericPageFormPresenter'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'
import { container } from 'app/Config/IOC'
import { Types } from 'Gateways/Service/Types'
import { TMode } from 'Shared/Components/Templates/PageWrappers/TMode'
import { Submitted } from 'Shared/Entities/Enums/Submitted'

@injectable()
export abstract class GenericPageEditPresenter {
  protected abstract viewPresenter: GenericAdminViewPresenter
  protected abstract formPresenter: GenericPageFormPresenter

  protected language: ILanguageService = container.get(Types.ILanguageService)
  protected editPostSuccessCallback: () => void
  protected submitResetCallback?: () => void
  protected setArchiveState: (state: Submitted) => void
  protected setArchiveResponseErrors: (errors: string[]) => void
  protected archivePostSuccessCallback: () => void

  @observable
  public editPanelSaving: boolean = false

  @observable
  public triggerScroll = false

  @action
  public setTriggerScroll = (trigger: boolean) => {
    this.triggerScroll = trigger
  }

  @computed
  public get mode(): TMode {
    if (this.viewPresenter.loadingRecord) return 'loading'
    if (this.viewPresenter.editRecordVM !== null) {
      return this.viewPresenter.pageVM.editPanelDirty ? 'panel-open-confirm' : 'panel-open'
    }

    return 'panel-closed'
  }

  @computed
  public get canArchive(): boolean {
    return this.viewPresenter.canArchive
  }

  @action
  public save = async () => {
    this.editPanelSaving = true
    this.formPresenter.processSubmission()

    if (!this.formPresenter.isFormValid()) {
      this.editPanelSaving = false
      this.setTriggerScroll(true)
      this.submitReset()
      return
    }

    const response = await this.viewPresenter.saveRecord()
    this.editPanelSaving = false

    if (!response.success) {
      if (!response.errorMessages) {
        this.submitReset()
        return
      }

      if (this.formPresenter.formValidator) {
        this.formPresenter.formValidator.serverErrors = response.errorMessages
      }

      this.formPresenter.processServerErrors(response.errorMessages)
      this.setTriggerScroll(true)

      this.submitReset()
      return
    }

    if (this.editPostSuccessCallback) {
      this.editPostSuccessCallback()
    }

    this.submitReset()
    this.setEditPanelClean()
    await this.viewPresenter.loadList()
  }

  private submitReset = () => {
    if (this.submitResetCallback) {
      this.submitResetCallback()
    }
  }

  @action
  public reset = () => {
    this.formPresenter.reset()
    this.viewPresenter.inflateEditRecordVM()
  }

  public archive = async () => {
    const response = await this.viewPresenter.deleteRecord()

    if (!response.success && this.setArchiveResponseErrors) {
      this.setArchiveResponseErrors(response.errorMessages)
      return
    }

    if (this.archivePostSuccessCallback) {
      this.archivePostSuccessCallback()
    }
  }

  @action
  protected setEditPanelDirty = () => {
    setReactDirty(true)
    this.viewPresenter.pageVM.editPanelDirty = true
  }

  @action
  protected setEditPanelClean = () => {
    setReactDirty(false)
    this.viewPresenter.pageVM.editPanelDirty = false
  }

  @action
  public close = () => {
    this.viewPresenter.pageVM.editPanelOpen = false
  }

  @action
  public archiveButtonCallback = () => {
    if (this.setArchiveState) {
      this.setArchiveState(Submitted.Submitted)
    }

    this.viewPresenter.pageVM.archiveModalOpenState = true
  }

  // Edit setters
  public withEditPostSuccessCallback = (postSuccessCallback: () => void) => {
    this.editPostSuccessCallback = postSuccessCallback
    return this
  }

  // Archive setters
  public withArchiveSetters = (
    setArchiveState: (state: Submitted) => void,
    archivePostSuccessCallback: () => void,
    setArchiveResponseErrors: (errors: string[]) => void
  ) => {
    this.withSetArchiveState(setArchiveState)
    this.withArchivePostSuccessCallback(archivePostSuccessCallback)
    this.withSetArchiveResponseErrors(setArchiveResponseErrors)
    return this
  }

  public withSetArchiveState = (setArchiveState: (state: Submitted) => void) => {
    this.setArchiveState = setArchiveState
    return this
  }

  public withSetArchiveResponseErrors = (setArchiveResponseErrors: (errors: string[]) => void) => {
    this.setArchiveResponseErrors = setArchiveResponseErrors
    return this
  }

  public withArchivePostSuccessCallback = (archivePostSuccessCallback: () => void) => {
    this.archivePostSuccessCallback = archivePostSuccessCallback
    return this
  }
}
