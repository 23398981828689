import { fromUnixTime, isDate, isValid } from 'date-fns'

import { Box, DateTimePicker } from '@cutover/react-ui'
import { FilterGroupExtraOptions } from './filter-group-extra-options'
import { getFilterSelections } from '../filter-helpers'
import {
  FilterGroupDate as FilterGroupDateType,
  FilterOptionCheckbox,
  FilterOptionDate,
  SelectedFilters
} from '../filter-types'

// gotta do this because it's unix from the server for non-cf fields, a string from saved views, and
// a date object from CFs
export const getDateValue = (value: any): Date | undefined => {
  switch (typeof value) {
    case 'number':
      if (value === 0) return undefined
      return isValid(value) ? fromUnixTime(value) : undefined
    case 'string':
      if (value === '*') return undefined
      const date = new Date(value)
      return isDate(date) ? date : undefined
    case 'object': // date types are strictly objects
      return isDate(value) ? value : undefined
    default:
      return undefined
  }
}
export const FilterGroupDate = ({
  filter,
  onChange,
  selected = {},
  withNoValueOption,
  withAnyValueOption
}: {
  filter: FilterGroupDateType
  onChange: (option: FilterOptionDate | FilterOptionCheckbox) => void
  selected?: SelectedFilters
  withNoValueOption?: boolean
  withAnyValueOption?: boolean
}) => (
  <Box gap="medium">
    {filter.options.map((option, x) => {
      const selection = getFilterSelections(selected, filter, option)
      const value = Array.isArray(selection) ? selection[x] : selection

      return (
        <DateTimePicker
          key={`${filter.title}-${option.label}`}
          value={getDateValue(value) || null}
          label={option.label}
          onChange={date => onChange({ ...option, value: date ? date : undefined })}
        />
      )
    })}
    {(withNoValueOption || withAnyValueOption) && filter.hasOwnProperty('slug') && (
      <FilterGroupExtraOptions
        onChange={(_, option) => onChange(option)}
        selected={getFilterSelections(selected, filter)}
        withAnyValueOption={withAnyValueOption}
        withNoValueOption={withNoValueOption}
      />
    )}
  </Box>
)
