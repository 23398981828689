import { PaintHexagon } from '../node-map-types'

export const paintHexagon = ({ ctx, x, y, size, size2, lineWidth, stroke, fill }: PaintHexagon) => {
  const halfSize = size / 2

  ctx.beginPath()
  ctx.moveTo(x - size2, y - halfSize)
  ctx.lineTo(x, y - size)
  ctx.lineTo(x + size2, y - halfSize)
  ctx.lineTo(x + size2, y + halfSize)
  ctx.lineTo(x, y + size)
  ctx.lineTo(x - size2, y + halfSize)
  ctx.closePath()

  ctx.lineWidth = lineWidth
  ctx.strokeStyle = stroke
  ctx.stroke()

  if (fill) {
    ctx.fillStyle = fill
    ctx.fill()
  }
}
