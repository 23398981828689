// @ts-nocheck
import UserAddSection from 'app/Access/Users/UsersPage/UsersAdd/UserAddSection'
import { container } from 'app/Config/IOC'
import { GenericAddModal } from 'Components/Atoms'
import { UsersViewPresenter } from './UsersViewPresenter'
import { addSectionHooks } from 'Shared/HooksAndPresenters/AddEditSection'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'

interface IUserPageModalProps {
  userAddModalOpenState: boolean
  setUserAddModalOpenState: (bool: boolean) => void
  language: ILanguageService
}

export const UserPageModals = (props: IUserPageModalProps) => {
  const { userAddModalOpenState, setUserAddModalOpenState, language } = props

  const { addModalPresenter } = addSectionHooks(setUserAddModalOpenState, userAddModalOpenState)

  const usersViewPresenter = container.get(UsersViewPresenter)

  return (
    <>
      <GenericAddModal
        presenter={addModalPresenter}
        viewPresenter={usersViewPresenter}
        title={language.get('users:modals:newUser:title')}
        cancelCallback={() => {
          setUserAddModalOpenState(false)
        }}
        language={language}
      >
        <UserAddSection
          postSuccessCallback={() => {
            addModalPresenter.onClose()
            setUserAddModalOpenState(false)
          }}
          cancelCallback={() => {
            addModalPresenter.onClose()
            setUserAddModalOpenState(false)
          }}
        />
      </GenericAddModal>
    </>
  )
}
