// @ts-nocheck
import {
  OptionWrapper,
  OptionHeader,
  OptionSubheader,
  OptionDataWrapper,
  IOptionLabels
} from './StartTimeOptionsStyled'
import { DateTime, DateTimePresenter } from 'Shared/Components/Molecules'

export const getIncidentStartTimeOptions = (
  labels: IOptionLabels,
  dateTimePresenter: DateTimePresenter,
  isRunbookTemplate: boolean
) => {
  const options = {}

  if (!isRunbookTemplate) {
    options[labels.plannedOptionHeader] = {
      renderOption: () => (
        <OptionWrapper>
          <OptionHeader>{labels.plannedOptionHeaderAlt || labels.plannedOptionHeader}</OptionHeader>
          <OptionSubheader>{labels.plannedOptionSubHeader}</OptionSubheader>
        </OptionWrapper>
      ),
      renderOptionData: () => <></>
    }
  }

  options[labels.startDateOptionHeader] = {
    renderOption: () => (
      <OptionWrapper>
        <OptionHeader>{labels.startDateOptionHeaderAlt || labels.startDateOptionHeader}</OptionHeader>
        <OptionSubheader>{labels.startDateOptionSubHeader}</OptionSubheader>
      </OptionWrapper>
    ),
    renderOptionData: (isSelected: boolean) => {
      return (
        <OptionDataWrapper isSelected={isSelected}>
          <DateTime presenter={dateTimePresenter} maxDate={new Date()} />
        </OptionDataWrapper>
      )
    }
  }

  return options
}
