import { CustomField, FieldValue } from 'main/services/queries/types'

export const buildDefaultFieldValues = (
  customFields: CustomField[],
  groupedCustomFields: Record<number, CustomField[]>,
  fieldValues: Record<CustomField['id'], any> = {},
  integrationGroupedCustomFields?: Record<number, CustomField[]>
) => {
  const defaultFieldValues: { [key: number]: Partial<FieldValue> } = {}
  customFields.forEach(cf => {
    const value = cf.field_type.slug === 'textarea' ? '' : null
    defaultFieldValues[cf.id] = {
      value: cf.default_value || value,
      field_option_id: null
    }
  })

  Object.values(groupedCustomFields).forEach(group => {
    group.forEach(cf => {
      const value = cf.field_type.slug === 'textarea' ? '' : null
      defaultFieldValues[cf.id] = {
        value: cf.default_value || value,
        field_option_id: null
      }
    })
  })

  if (integrationGroupedCustomFields) {
    Object.values(integrationGroupedCustomFields).forEach(group => {
      group.forEach(cf => {
        const value = cf.field_type.slug === 'textarea' ? '' : null
        defaultFieldValues[cf.id] = {
          value: cf.default_value || value,
          field_option_id: null
        }
      })
    })
  }

  // If we have provided fieldValues, add these into defaultFieldValues
  // If a field value was provided but no default field value was found, we will
  // not add this to the defaultFieldValues. This ensures that we don't add new
  // keys to our defaultFieldValues.
  for (const [key, value] of Object.entries(fieldValues)) {
    if (defaultFieldValues.hasOwnProperty(key)) {
      defaultFieldValues[key as unknown as number] = value
    }
  }

  return defaultFieldValues
}
