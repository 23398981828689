import { useQuery } from 'react-query'

import { apiClient } from 'main/services/api'
import { ApiError } from 'main/services/api/http-gateway-adapter'

export type AccountFolder = {
  id: string
  name: string
}

type AccountFolderResponse = {
  id: string
  name: string
  account_title?: string
}

// TODO: update, see https://cutover.atlassian.net/browse/CUXP-2005
// TODO: not sure what the above ticket is referring to as written by someone who no longer works here
// but this query is in need of refactoring to either make it part of `use-folders.ts` or make it specific to
// account folders
// Also would be prudent to remove the data mapping before the return and just return the data from the API
export function useAccountFolders({ accountId }: { accountId?: number }) {
  return useQuery<AccountFolder[], ApiError>(['account-folders', accountId], async () => {
    if (!accountId) {
      const { data } = await apiClient.get<{ projects: AccountFolderResponse[] }>({
        url: 'projects'
      })

      return data.projects.map(folder => ({
        id: folder.id,
        name: folder.account_title ? `${folder.name} (${folder.account_title})` : folder.name
      }))
    }

    const { data } = await apiClient.get<{ projects: AccountFolderResponse[] }>({
      url: `accounts/${accountId}/projects`
    })

    return data.projects.map(folder => ({ id: folder.id, name: folder.name }))
  })
}
