/* eslint-disable react-hooks/rules-of-hooks */
// @ts-nocheck
import { useRef, useEffect } from 'react'

export const scrollToView = (triggerScroll: boolean, setTriggerScroll: (boolean: boolean) => void) => {
  const scrollRef = useRef(null)
  useEffect(() => {
    if (triggerScroll) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth' })
      setTriggerScroll(false)
    }
  }, [triggerScroll])

  return scrollRef
}
