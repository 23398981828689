import { v4 as uuidv4 } from 'uuid'

import { AppsComponent } from './apps-component'
import { ContentNode } from './apps-types'

type AppsComponentParserProps = {
  content: ContentNode[]
  appId: string
  resourceId?: string
  onClick?: () => void
}

export const AppsComponentParser = ({ content, appId, resourceId }: AppsComponentParserProps) => {
  return (
    <>
      {content.map(node => {
        const nodeKey = `${node.type}-${uuidv4()}`
        if (node.content && node.content.length > 0 && node.type !== 'scroll') {
          return (
            <AppsComponent node={node} key={nodeKey} appId={appId} resourceId={resourceId}>
              <AppsComponentParser content={node.content} appId={appId} resourceId={resourceId} />
            </AppsComponent>
          )
        } else {
          return <AppsComponent node={node} key={nodeKey} appId={appId} resourceId={resourceId} />
        }
      })}
    </>
  )
}
