import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const VisibilityShow = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="VisibilityShow" {...props}>
    <path d="M32 24a8 8 0 1 1-16 0 8 8 0 0 1 16 0Zm-3 0a5 5 0 1 0-10 0 5 5 0 0 0 10 0Z" fill="#000" /><path d="M24 11.5c8.342 0 15.45 5.184 18.334 12.5C39.45 31.316 32.34 36.5 24 36.5c-8.334 0-15.45-5.184-18.334-12.5 2.884-7.316 10-12.5 18.334-12.5Zm0 22c6.638 0 12.364-3.875 15.063-9.5-2.698-5.625-8.424-9.5-15.063-9.5-6.632 0-12.364 3.875-15.063 9.5 2.699 5.625 8.431 9.5 15.063 9.5Z" fill="#000" />
  </StyledIcon>
))

VisibilityShow.displayName = 'VisibilityShow'

export { VisibilityShow }
