// @ts-nocheck
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { colors, px2rem } from 'app/Styles/Variables'
import { Button, Popover } from 'Components/Molecules'
import { CustomFieldPageHeadingExtraOptionsPresenter } from './CustomFieldPageHeadingExtraOptionsPresenter'

const BorderedButtonWrapper = styled.div`
  border-top: ${px2rem(1)} solid ${colors.primaryGreyBackgroundColor};
`

interface ICustomFieldPageHeadingExtraOptionsComponent {
  presenter: CustomFieldPageHeadingExtraOptionsPresenter
}

export const CustomFieldPageHeadingExtraOptionsComponent = observer<ICustomFieldPageHeadingExtraOptionsComponent>(
  ({ presenter }) => {
    const {
      optionsPopoverPresenter,
      showArchivedButton,
      showUnArchivedButton,
      showingArchivedCustomFields,
      reloadButton,
      optionsOpen,
      setOptionsOpen,
      language
    } = presenter

    return (
      <>
        <Popover
          presenter={optionsPopoverPresenter}
          open={false}
          controlledOpen={optionsOpen}
          customTriggerCallback={() => {
            setOptionsOpen(true)
          }}
          moreOptionsToolTipText={language.get('common:moreOptions')}
          type="customContent"
          content={
            <>
              <>
                {showingArchivedCustomFields ? (
                  <Button presenter={showUnArchivedButton} extraStyles={{ width: px2rem(280) }} />
                ) : (
                  <Button presenter={showArchivedButton} extraStyles={{ width: px2rem(280) }} />
                )}
              </>
              <BorderedButtonWrapper>
                <Button presenter={reloadButton} extraStyles={{ width: px2rem(280) }} />
              </BorderedButtonWrapper>
            </>
          }
          wrapperExtraStyles={{ marginTop: px2rem(20), display: 'inline-block' }}
        />
      </>
    )
  }
)
