import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const BrandMAndA = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 100 101" a11yTitle="BrandMAndA" {...props}>
    <g clipPath="url(#a)" fill="#fff"><path opacity=".5" d="M47 47.333v-47l-47 47h47Zm6 6v47l47-47H53Z" /><path d="M53 47.333h47l-47-47v47Zm-6 6H0l47 47v-47Z" /></g>
  </StyledIcon>
))

BrandMAndA.displayName = 'BrandMAndA'

export { BrandMAndA }
