export type UserResponse = {
  id?: number
  name: string
  first_name: string
  last_name: string
  online: boolean | null
  handle: string
  color: string
  is_admin: boolean | null
  not_in_account: boolean | null
  archived: boolean
  status: 'active' | 'invited' | 'not_allowed' | 'archived' | 'invalid' | 'external'
}

export type RunbookTeamResponse = {
  id: number
  name: string
  color: string
  linked: boolean
  users_count: number
}

export type TeamResponse = {
  id: number
  name: string
  color: string
  highlight: boolean
  users_count: number
  account_id: number
}

export type UserRecipient = UserResponse & {
  type: 'user'
}

export type TeamRecipient = {
  id: number
  name: string
  color: string
  users_count: number
  type: 'team'
}

export type RecipientType = UserRecipient | TeamRecipient

export function isUserRecipient(arg: any): arg is UserRecipient {
  return arg.hasOwnProperty('handle')
}
