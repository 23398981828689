// @ts-nocheck
import { observer } from 'mobx-react-lite'
import { CustomFieldGroupFormPresenter } from './CustomFieldGroupFormPresenter'
import { ErrorAlert } from 'Shared/Components/Molecules/Alert/ErrorAlert'
import { TextInput } from 'Shared/Components/Molecules/TextInput/TextInput'
import { Form } from 'Shared/Components/Atoms/Form/Form'

interface ICustomFieldGroupForm {
  presenter: CustomFieldGroupFormPresenter
  formType?: 'add' | 'edit'
}

export const CustomFieldGroupFormComponent = observer<ICustomFieldGroupForm>(({ presenter, formType }) => {
  const { submitted, formIsValid, errorAlertPresenter, namePresenter, onSubmit } = presenter

  return (
    <Form onSubmit={onSubmit}>
      {submitted && !formIsValid && <ErrorAlert presenter={errorAlertPresenter} formAlert />}
      <TextInput presenter={namePresenter} parentIsFocused={formType === 'add'} formTopInputElement />
    </Form>
  )
})
