// @ts-nocheck
import 'reflect-metadata'
import { injectable, postConstruct, inject } from 'inversify'
import { observable, action, computed, observe } from 'mobx'
import { IGenericFilter, ISelectedFilter } from './FilterEntities'
import { StringFilterHelpers } from './FilterHelpers/StringFilterHelpers'
import { RoutingState } from 'app/Routing/RoutingState'
import { BooleanFilterHelpers } from './FilterHelpers/BooleanFilterHelpers'
import { ResourceFilterHelpers } from './FilterHelpers/ResourceFilterHelpers'
import { SavedFiltersRepository } from './SavedFiltersRepository'

@injectable()
export class FiltersRepository {
  @postConstruct()
  public init() {
    observe(this.routingState, 'pathFilters', () => {
      this.loadingPath = true
      this.pathFilters = this.routingState.pathFilters
      this.loadingPath = false
    })
  }

  @inject(RoutingState)
  private routingState: RoutingState

  public stringFilterHelper = new StringFilterHelpers()
  public booleanFilterHelper = new BooleanFilterHelpers()
  public resourceFilterHelper = new ResourceFilterHelpers()

  @observable
  public pageFilters: IGenericFilter[] = []

  private findPageFilter = (genericFilter: IGenericFilter): { filter: IGenericFilter; index: number } => {
    let returnFilter: { filter: IGenericFilter; index: number }
    this.pageFilters.forEach((filter, index) => {
      if (filter.key.reference === genericFilter.key.reference) {
        if (
          (genericFilter.type === 'resource' &&
            genericFilter.key.resourceId &&
            filter.key.resourceId === genericFilter.key.resourceId) ||
          genericFilter.type !== 'resource'
        ) {
          returnFilter = { filter, index }
        }
      }
    })
    return returnFilter
  }

  @observable
  public pathFilters: { key: string; value: string }[] = []

  private findPathFilter = (
    genericFilter: IGenericFilter
  ): { filter: { key: string; value: string }; index: number } => {
    let returnFilter: { filter: { key: string; value: string }; index: number }
    this.pathFilters.forEach((filter, index) => {
      if (filter.key === genericFilter.key.reference) {
        if (
          genericFilter.type === 'resource' &&
          this.resourceFilterHelper.checkPageAndPathFiltersMatch(genericFilter, filter)
        ) {
          returnFilter = { filter, index }
        }
        if (genericFilter.type !== 'resource') {
          returnFilter = { filter, index }
        }
      }
    })
    return returnFilter
  }

  @computed
  public get selectedFilters(): ISelectedFilter[] {
    const localSelectedFilters: ISelectedFilter[] = []
    this.pageFilters.forEach(pageFilter => {
      const pathFilter = this.findPathFilter(pageFilter)
      if (!pathFilter) return
      let stringValue: string
      switch (pageFilter.type) {
        case 'boolean':
          stringValue = this.booleanFilterHelper.getFilterValue(pageFilter, pathFilter.filter)
          break
        case 'resource':
          stringValue = this.resourceFilterHelper.getFilterValue(pageFilter, pathFilter.filter)
          break
        case 'string':
          stringValue = this.stringFilterHelper.getFilterValue(pageFilter, pathFilter.filter)
          break
        case 'date':
          stringValue = this.stringFilterHelper.getFilterValue(pageFilter, pathFilter.filter)
          break
      }
      if (stringValue) {
        localSelectedFilters.push({
          key: pageFilter.key,
          value: stringValue,
          type: pageFilter.type,
          group: pageFilter.group || undefined,
          groupPills: pageFilter.groupPills || undefined,
          urlTrueValueOverride: pageFilter.urlTrueValueOverride || undefined
        })
      }
    })
    return localSelectedFilters
  }

  @computed
  public get urlString(): string {
    let urlString = ''
    this.selectedFilters.forEach((selectedFilter, index) => {
      if (index !== 0) urlString = `${urlString}&`
      let stringValue: string
      switch (selectedFilter.type) {
        case 'boolean':
          stringValue = this.booleanFilterHelper.serialiseForUrl(selectedFilter)
          break
        case 'resource':
          stringValue = this.resourceFilterHelper.serialiseForUrl(selectedFilter)
          break
        case 'string':
          stringValue = this.stringFilterHelper.serialiseForUrl(selectedFilter)
          break
        case 'date':
          stringValue = this.stringFilterHelper.serialiseForUrl(selectedFilter)
          break
      }
      if (stringValue) {
        urlString = `${urlString}${stringValue}`
      }
    })

    return urlString
  }

  @computed
  public get apiString(): string {
    let apiString = ''
    this.selectedFilters.forEach((selectedFilter, index) => {
      if (index !== 0) apiString = `${apiString}&`
      let stringValue: string
      switch (selectedFilter.type) {
        case 'boolean':
          stringValue = this.booleanFilterHelper.serialiseForApi(selectedFilter)
          break
        case 'resource':
          stringValue = this.resourceFilterHelper.serialiseForApi(selectedFilter)
          break
        case 'string':
          stringValue = this.stringFilterHelper.serialiseForApi(selectedFilter)
          break
        case 'date':
          stringValue = this.stringFilterHelper.serialiseForApi(selectedFilter)
          break
      }
      if (stringValue) {
        apiString = `${apiString}${stringValue}`
      }
    })

    return apiString
  }

  @observable
  public loadingPath: boolean = false

  @action
  public setPageFilters = (filters: IGenericFilter[]) => {
    this.pageFilters = filters
  }

  @action
  public clearPageFilters = () => {
    this.pageFilters = []
  }

  @action
  public clearPathFilters = (filtersToExclude: string[] = []) => {
    if (filtersToExclude.length === 0) {
      this.pathFilters = []
      return
    }

    this.pathFilters = this.pathFilters.filter(filter => {
      return filtersToExclude.indexOf(filter.key) !== -1
    })
  }

  private getUrlValue = (selectedFilter: IGenericFilter, newValue: string): string => {
    switch (selectedFilter.type) {
      case 'boolean':
        return this.booleanFilterHelper.getValueForUrl(selectedFilter, newValue)
      case 'resource':
        return this.resourceFilterHelper.getValueForUrl(selectedFilter, newValue)
      case 'string':
        return this.stringFilterHelper.getValueForUrl(selectedFilter, newValue)
      case 'date':
        return this.stringFilterHelper.getValueForUrl(selectedFilter, newValue)
    }
  }

  @action
  public toggleFilterWithoutSideEffects = (selectedFilter: IGenericFilter, value?: string) => {
    const pathFilter = this.findPathFilter(selectedFilter)
    if (pathFilter) {
      if (value === undefined) {
        const newFilters = [...this.pathFilters]
        newFilters.splice(pathFilter.index, 1)
        this.pathFilters = newFilters
      } else {
        const newFilters = [...this.pathFilters]
        newFilters.splice(pathFilter.index, 1)
        newFilters.push({ key: selectedFilter.key.reference, value: this.getUrlValue(selectedFilter, value) })
        this.pathFilters = newFilters
      }
    } else {
      if (value === undefined) return
      const newFilter = this.findPageFilter(selectedFilter)
      if (newFilter) {
        const newFilters = [...this.pathFilters]
        newFilters.push({
          key: selectedFilter.key.reference,
          value: this.getUrlValue(selectedFilter, value)
        })
        this.pathFilters = newFilters
      }
    }
    return
  }

  public toggleFilterValue = (genericFilter: IGenericFilter, value?: string) => {
    if (genericFilter && genericFilter.onChangeSideEffect) genericFilter.onChangeSideEffect(genericFilter, value)
    this.toggleFilterWithoutSideEffects(genericFilter, value)
  }

  // Saved Filters
  @inject(SavedFiltersRepository)
  private savedFiltersRepository: SavedFiltersRepository

  @computed
  public get savedFilters() {
    return this.savedFiltersRepository.savedFilters
  }

  public clearSavedFilters = () => {
    this.savedFiltersRepository.clearSavedFilters()
  }

  public loadSavedFilters = async () => {
    return this.savedFiltersRepository.loadSavedFilters()
  }

  public save = (filterName: string) => {
    return this.savedFiltersRepository.save(filterName, this.apiString)
  }

  public deleteFilter = async filterId => {
    await this.savedFiltersRepository.delete(filterId)
  }
}
