import { colors, LegacyText as Text } from '@cutover/react-ui'
import { Tile } from './tile'

export const NoDataAvailable = () => {
  return (
    <Tile>
      <Text weight={700} color={colors.textLight}>
        No Data Available.
      </Text>
    </Tile>
  )
}
