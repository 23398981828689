// @ts-nocheck
import { ILanguageService } from 'Helpers/Language/ILanguageService'
import { container } from 'app/Config/IOC'
import { Types } from 'Gateways/Service/Types'
import { observable, computed, action } from 'mobx'
import {
  TextInputPresenter,
  ErrorAlertPresenter,
  PhoneNumberPresenter,
  ButtonPresenter,
  CheckboxPresenter
} from 'Components/Molecules'
import { GenericFormPresenter } from '@logicroom/validator'
import { checkValueForAbsenceOfWhiteSpace } from 'Helpers/InputCustomRules'
import { ConfigsRepository } from 'Configs/ConfigsRepository'
import { UserVerifyViewPresenter } from 'app/UserVerify/UserVerifyPage/UserVerifyViewPresenter'
import { ICountry } from 'Components/Molecules/PhoneNumber/ICountry'

export class UserVerifyFormPresenter {
  private language: ILanguageService = container.get(Types.ILanguageService)

  private configsRepository = container.get(ConfigsRepository)

  private userVerifyViewPresenter: UserVerifyViewPresenter = container.get(UserVerifyViewPresenter)

  @observable
  public clientLogoPath = this.configsRepository.loginLogo

  @observable
  public countries: ICountry[] = this.configsRepository.countries || []

  @observable
  public submitted: boolean = false

  @observable
  public responseErrors: string[] = []

  @observable
  public firstNamePresenter: TextInputPresenter = new TextInputPresenter()
    .withLabel(this.language.get('userVerify:form:firstName:label'))
    .isRequiredTrimWhiteSpace(this.language.get('userVerify:form:firstName:isRequired'))
    .withMiddleware((value: string) => {
      this.userVerifyViewPresenter.updateViewModel('firstName', value)
      return value
    })

  @observable
  public lastNamePresenter: TextInputPresenter = new TextInputPresenter()
    .withLabel(this.language.get('userVerify:form:lastName:label'))
    .isRequiredTrimWhiteSpace(this.language.get('userVerify:form:lastName:isRequired'))
    .withMiddleware((value: string) => {
      this.userVerifyViewPresenter.updateViewModel('lastName', value)
      return value
    })

  @observable
  public emailPresenter = new TextInputPresenter()
    .withLabel(this.language.get('userVerify:form:email:label'))
    .isDisabled()
    .withMiddleware((value: string) => {
      this.userVerifyViewPresenter.updateViewModel('email', value)
      return value
    })
    .addCustomRule(checkValueForAbsenceOfWhiteSpace, this.language.get('userVerify:form:password:hasMinimumCharacters'))

  @observable
  public mobileNumberPresenter = new PhoneNumberPresenter(this.language, this.configsRepository.countries || [])
    .withPhoneIcon()
    .withLabel(this.language.get('userVerify:form:mobileNumber:label'))
    .withHelpText(this.language.get('userVerify:form:mobileNumber:helpText'))
    .withNumberMiddleware((value: string) => {
      if (value === '') {
        this.memorableWordPresenter.value = ''
        this.memorableWordPresenter.isDirty = false
        this.userVerifyViewPresenter.updateViewModel('memorableWord', value)
      }
      this.userVerifyViewPresenter.updateViewModel('mobileNumber', value)
      return value
    })
    .withCountryCodeMiddleware((value: string | number) => {
      const countryCode = this.countries.find(country => country.name === value).code
      this.userVerifyViewPresenter.updateViewModel('countryCode', countryCode)
      return value
    })

  @observable
  public memorableWordPresenter = new TextInputPresenter()
    .withLabel(this.language.get('userVerify:form:memorableWord:label'))
    .isRequiredTrimWhiteSpace(this.language.get('userVerify:form:memorableWord:isRequired'))
    .mustHaveMinLength(4, this.language.get('userVerify:form:memorableWord:hasMinimumCharacters'))
    .withHelpText(this.language.get('userVerify:form:memorableWord:helpText'))
    .withMiddleware((value: string) => {
      this.userVerifyViewPresenter.updateViewModel('memorableWord', value)
      return value
    })
    .addCustomRule(
      checkValueForAbsenceOfWhiteSpace,
      this.language.get('userVerify:form:memorableWord:hasMinimumCharacters')
    )

  @observable
  public passwordPresenter = new TextInputPresenter()
    .withLabel(this.language.get('userVerify:form:password:label'))
    .isRequiredTrimWhiteSpace(this.language.get('userVerify:form:password:isRequired'))
    .mustHaveMinLength(8, this.language.get('userVerify:form:password:hasMinimumCharacters'))
    .withType('password')
    .withMiddleware((value: string) => {
      this.userVerifyViewPresenter.updateViewModel('password', value)
      return value
    })
    .addCustomRule(checkValueForAbsenceOfWhiteSpace, this.language.get('userVerify:form:password:hasMinimumCharacters'))

  @observable
  public passwordConfirmationPresenter = new TextInputPresenter()
    .withLabel(this.language.get('userVerify:form:passwordConfirmation:label'))
    .isRequiredTrimWhiteSpace(this.language.get('userVerify:form:passwordConfirmation:isRequired'))
    .withType('password')
    .withMiddleware((value: string) => {
      this.userVerifyViewPresenter.updateViewModel('passwordConfirmation', value)
      return value
    })
    .addCustomRule(
      checkValueForAbsenceOfWhiteSpace,
      this.language.get('userVerify:form:passwordConfirmation:hasMinimumCharacters')
    )

  @observable
  public loginWarningMessage: string = this.userVerifyViewPresenter.loginWarningMessage

  @observable
  public isWarningAgreedPresenter = new CheckboxPresenter(this.loginWarningMessage)
    .withAlignmentPosition('top')
    .withHtmlLabel(true)
    .withMiddleware((value: boolean) => {
      this.isWarningAgreed = value
      return value
    })

  @observable
  public isWarningAgreed: boolean = !this.loginWarningMessage

  @computed
  public get showIsWarningAgreed(): boolean {
    return !!this.loginWarningMessage && this.userToVerifyLoginType !== 'email'
  }

  @action
  public submit = async () => {
    this.submitted = true
    if (this.mobileNumberPresenter.getCountryCode !== '') {
      this.userVerifyViewPresenter.viewModel.countryCode = this.mobileNumberPresenter.getCountryCode
    }
    if (this.userVerifyViewPresenter.viewModel.countryCode === '' && this.mobileNumberPresenter.getNumber !== '') {
      this.mobileNumberPresenter.countryPresenter.onChange(this.mobileNumberPresenter.countryPresenter.value)
    }

    const formVM = this.userVerifyViewPresenter.viewModel
    const response = await this.userVerifyViewPresenter.submit(formVM)

    if (response.success) {
      if (response.samlAuthRequest) return 'redirection saml auth request to Idp provider'
      //@ts-ignore
      window.angularJS.angularStateChange('login')
    } else {
      this.responseErrors = response.errorMessages
      this.errorAlertPresenter.setErrorMessages(this.responseErrors)
    }
  }

  @observable
  public submitButtonPresenter = new ButtonPresenter(this.language.get('userVerify:form:submitButton'), this.submit)
    .withDisplayStyle('anchored')
    .withIconName('arrow-forward')
    .withIconPosition('right')

  @observable
  public userVerifyForm: GenericFormPresenter = new GenericFormPresenter()
    .addFormInput(this.firstNamePresenter)
    .addFormInput(this.lastNamePresenter)
    .addFormInput(this.mobileNumberPresenter)

  @observable
  public errorAlertPresenter: ErrorAlertPresenter = new ErrorAlertPresenter()

  @computed
  public get formIsValid(): boolean {
    let phoneNumberValidState: boolean
    let memorableWordPresenterValidState: boolean
    let passwordPresenterValidState: boolean
    let passwordConfirmationPresenterValidState: boolean
    const formValid = this.userVerifyForm.isValid

    phoneNumberValidState =
      !this.mobileNumberPresenter.numberPresenter.value || this.mobileNumberPresenter.getNumber.length >= 7

    if (this.mobileNumberPresenter.getNumber !== '') {
      memorableWordPresenterValidState = this.memorableWordPresenter.isValid && this.memorableWordPresenter.value !== ''
    } else {
      memorableWordPresenterValidState = true
    }

    if (this.userToVerifyLoginType === 'email') {
      passwordPresenterValidState = this.passwordPresenter.isValid
      passwordConfirmationPresenterValidState = this.passwordConfirmationPresenter.isValid
    } else {
      passwordPresenterValidState = true
      passwordConfirmationPresenterValidState = true
    }

    return (
      formValid &&
      phoneNumberValidState &&
      memorableWordPresenterValidState &&
      passwordPresenterValidState &&
      passwordConfirmationPresenterValidState
    )
  }

  @computed
  public get userToVerifyVM() {
    return this.userVerifyViewPresenter.viewModel
  }

  @action
  public userToVerifyVMChangeFunction = () => {
    const vm = this.userVerifyViewPresenter.viewModel

    if (vm.firstName !== this.firstNamePresenter.value) this.firstNamePresenter.value = vm.firstName
    if (vm.lastName !== this.lastNamePresenter.value) this.lastNamePresenter.value = vm.lastName
    if (vm.email !== this.emailPresenter.value) this.emailPresenter.value = vm.email
    if (vm.countryCode && vm.countryCode !== this.mobileNumberPresenter.countryPresenter.value)
      this.mobileNumberPresenter.countryPresenter.value = vm.countryCode
    if (vm.mobileNumber !== this.mobileNumberPresenter.numberPresenter.value)
      this.mobileNumberPresenter.numberPresenter.value = vm.mobileNumber
  }

  @computed
  public get hasResponseErrors(): boolean {
    return this.responseErrors.length > 0
  }

  @computed
  public get userToVerifyLoginType(): string {
    return this.userVerifyViewPresenter.userToVerifyProvider
  }

  @computed
  public get inviteMessage(): string {
    return this.userVerifyViewPresenter.inviteMessage
  }
  @computed
  public get countryCodeValidator(): string {
    return !this.mobileNumberPresenter.countryPresenter.value
      ? this.language.get('userVerify:form:mobileNumber:requiresCountryCode')
      : ''
  }

  @computed
  public get phoneNumberValidator(): string {
    return this.mobileNumberPresenter.numberPresenter.isValid
      ? this.language.get('userVerify:form:mobileNumber:hasMinimumLength')
      : ''
  }
  @computed
  public get memorableWordValidator(): string {
    return !this.memorableWordPresenter.isValid && this.memorableWordPresenter.isDirty
      ? this.language.get('userVerify:form:memorableWord:hasMinimumCharacters')
      : ''
  }

  @computed
  public get passwordValidator(): string {
    return !this.passwordPresenter.isValid && this.passwordPresenter.isDirty
      ? this.language.get('userVerify:form:password:hasMinimumCharacters')
      : ''
  }

  @computed
  public get passwordConfirmationValidator(): string {
    return !this.passwordConfirmationPresenter.isValid && this.passwordConfirmationPresenter.isDirty
      ? this.language.get('userVerify:form:passwordConfirmation:passwordMustMatch')
      : ''
  }

  @computed
  public get passwordMatch(): boolean {
    return this.passwordPresenter.value === this.passwordConfirmationPresenter.value
  }

  @action
  public togglePasswordConfirmationPresenter = (boolean: boolean) => {
    this.passwordConfirmationPresenter.isDirty = boolean
    this.passwordConfirmationPresenter.isValid = !boolean
  }

  @action
  public toggleSubmitButtonDisabled = (disabled: boolean) => {
    this.submitButtonPresenter.disabled = disabled
  }
}
