import { unescape } from 'lodash'
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'

import { QueryKeys } from './query-keys'
import { apiClient, ApiError } from 'main/services/api/api-client'

const PERMITTED_RESOURCES = ['runbook'] as const

export type PermittedResourceType = (typeof PERMITTED_RESOURCES)[number]

export type PermittedProject = {
  id: number
  account_id: number
  archived: boolean
  color: string
  created_at: string
  description: string | null
  disabled: boolean
  key: string
  name: string
  parent_id: number | null
  runbooks_count: number
  updated_at: string
}

export type RunbookPermittedResource = {
  projects: PermittedProject[]
}

// Add additional conditional return types before the unknown as other types of permitted resources are added
export type PermittedResourceResponse<T extends PermittedResourceType> = T extends 'runbook'
  ? RunbookPermittedResource
  : unknown

type UsePermittedResourceReturnType<T extends PermittedResourceType> = UseQueryResult<
  PermittedResourceResponse<T>,
  ApiError
>

export function usePermittedResources<T extends PermittedResourceType>(
  { resource, accountId }: { resource: T; accountId?: number },
  options: UseQueryOptions<PermittedResourceResponse<T>, ApiError> = { enabled: true }
): UsePermittedResourceReturnType<T> {
  return useQuery<PermittedResourceResponse<T>, ApiError>(
    [QueryKeys.Accounts, accountId, 'permitted_resources', { resource }],
    async () => {
      const { data } = await apiClient.get<PermittedResourceResponse<T>>({
        url: `accounts/${accountId}/permitted_resources`,
        params: {
          resource
        }
      })

      return {
        projects: data.projects.map(project => ({
          ...project,
          name: unescape(project.name)
        }))
      }
    },
    {
      ...options,
      enabled: options.enabled && !!accountId
    }
  )
}

export const getPermittedResources = async <T extends PermittedResourceType>({
  resource,
  accountId
}: {
  resource: PermittedResourceType
  accountId?: number
}) => {
  const { data } = await apiClient.get<PermittedResourceResponse<T>>({
    url: `accounts/${accountId}/permitted_resources`,
    params: {
      resource
    }
  })

  return data
}
