// @ts-nocheck
import { observable, computed, toJS } from 'mobx'
import { SelectPresenter, TextInputPresenter, TToolTipPosition } from 'Components/Molecules'
import { ICountry } from './ICountry'
import { BrowserHostGateway } from 'Gateways/Window/BrowserHostGateway'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'

function orderByNameAsc(a: ICountry, b: ICountry) {
  // Use toUpperCase() to ignore character casing
  const nameA = a.name.toUpperCase()
  const nameB = b.name.toUpperCase()

  let comparison = 0
  if (nameA > nameB) {
    comparison = 1
  } else if (nameA < nameB) {
    comparison = -1
  }
  return comparison
}

export class PhoneNumberPresenter {
  @observable
  public numberMiddleware: (value: string) => string

  @observable
  public countryCodeMiddleware: (value: string | number) => string | number

  @observable
  public numberPresenter = new TextInputPresenter().mustBeNumber().withMiddleware(value => {
    let newValue = value
    if (this.numberMiddleware) {
      newValue = this.numberMiddleware(value)
    }
    return newValue
  })

  @observable
  public countryPresenter: SelectPresenter

  @observable
  public countries: ICountry[] = []

  @observable
  private currentCountry: ICountry = { name: '', dial: '', code: '' }

  private browserHostGateway: BrowserHostGateway = new BrowserHostGateway()

  @observable
  public helpText: string

  @observable
  public disabled: boolean = false

  @computed
  private get getOrderedCountries(): any[] {
    if (!this.countries) return []
    const orderedCountries = this.countries
    return toJS(orderedCountries).sort(orderByNameAsc)
  }

  constructor(language: ILanguageService, countryList: ICountry[]) {
    this.countryPresenter = new SelectPresenter(language).withMiddleware(value => {
      let newValue = value
      if (this.countryCodeMiddleware) {
        newValue = this.countryCodeMiddleware(value)
      }
      return newValue
    })
    if (countryList.length > 0) {
      this.countries = countryList
      const currentCountry = this.setCountry(countryList)
      if (currentCountry) this.currentCountry = currentCountry
    }
    this.countryPresenter.value = this.currentCountry.name
    this.countryPresenter.options = this.getOrderedCountries.map(country => ({
      value: country.name,
      label: `${country.dial} ${country.name}`
    }))
  }

  public getBroswerLanguageCode = () => {
    const rawLanguageCode: string = this.browserHostGateway.getHostLanguage()
    return rawLanguageCode.split('-')[1]
  }

  private setCountry = (countryList: ICountry[]) => {
    const defaultCountry = { name: '', dial: '', code: '' }
    const browserLanguageCode = this.getBroswerLanguageCode()
    const broswerCountry = countryList.find(country => country.code === browserLanguageCode)
    return broswerCountry ? broswerCountry : defaultCountry
  }

  @computed
  public get selectedCountry() {
    if (!this.countries) return { name: '', dial: '', code: '' }
    const selectedCountry = this.countries.find(country => country.name === this.countryPresenter.value)
    return selectedCountry || { name: '', dial: '', code: '' }
  }

  @computed
  public get getCountryCode() {
    const countryObject = this.countries.find(country => country.name === this.countryPresenter.value)
    return countryObject?.code || ''
  }

  public setCountryCode = (code: string) => {
    const countryObject = code ? this.countries.find(country => country.code === code) : null
    // if (countryObject) {
    //   this.countryPresenter.value = countryObject
    //     ? countryObject.name
    //     : this.countries.find(country => country.code === code).name
    // } else {
    this.countryPresenter.value = countryObject?.name || ''
    // }
  }

  @computed
  public get getNumber() {
    return this.numberPresenter.value
  }

  public setNumber = (number: string) => {
    this.numberPresenter.value = number
  }

  @computed
  public get isDirty() {
    return this.numberPresenter.isDirty || this.countryPresenter.isDirty
  }

  public setDirty = () => {
    this.numberPresenter.isDirty = true
    this.countryPresenter.isDirty = true
  }

  @computed
  public get isValid() {
    if (!this.countryPresenter.value && this.numberPresenter.value) return false
    if (this.numberPresenter.value && this.numberPresenter.value.length < 7) return false
    return this.numberPresenter.isValid && this.countryPresenter.isValid
  }

  public reset = () => {
    this.numberPresenter.reset()
    this.countryPresenter.reset()
    this.countryPresenter.value = this.currentCountry.name
    this.numberPresenter.value = ''
  }

  public withPhoneIcon = () => {
    this.numberPresenter.withIconPrefix('phone')
    return this
  }

  public withLabel = (label: string) => {
    this.numberPresenter.withLabel(label)
    this.countryPresenter.label = label
    return this
  }

  public setDisabled = (disabled: boolean) => {
    this.numberPresenter.disabled = disabled
    this.countryPresenter.disabled = disabled
    this.disabled = disabled
  }

  public withHelpText = (helpText: string) => {
    this.numberPresenter.withHelpText(helpText)
    return this
  }

  public withHelpTextToolTipPosition = (position: TToolTipPosition) => {
    this.numberPresenter.withHelpTextToolTipPosition(position)
    return this
  }

  public withNumberMiddleware = (middleWareFunction: (value: string) => string) => {
    this.numberMiddleware = middleWareFunction
    return this
  }

  public withCountryCodeMiddleware = (middleWareFunction: (value: string | number) => string | number) => {
    this.countryCodeMiddleware = middleWareFunction
    return this
  }
}
