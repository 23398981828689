import { ThemeType as GrommetThemeType } from 'grommet/themes'
import { css } from 'styled-components/macro'
import { SetRequired } from 'type-fest'

type GrommetTheme = SetRequired<GrommetThemeType, 'button'>
type GrommetButton = GrommetTheme['button']

export const button: GrommetButton = {
  extend: css`
    outline-offset: 2px !important;
  `
}
