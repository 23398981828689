import { colors, LegacyText as Text } from '@cutover/react-ui'
import { Tile } from './tile'
import { useLanguage } from 'main/services/hooks'

export function NoDataAvailable() {
  const { translate } = useLanguage()

  return (
    <Tile>
      <Text weight={700} color={colors.textLight}>
        {translate('dashboard:chart:emptyDataMessage')}
      </Text>
    </Tile>
  )
}
