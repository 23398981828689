// @ts-nocheck
import { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { withPresenter } from 'app/Config/withPresenter'
import { AccessSectionNav } from 'app/Access/AccessSectionNav'
import { UserPagePresenter } from 'app/Access/Users/UsersPage/UsersPagePresenter'
import { UserPageModals } from 'app/Access/Users/UsersPage/UsersPageModals'
import UserEditSection from 'app/Access/Users/UsersPage/UsersEdit/UserEditSection'
import UserPageFilterPanelSection from 'app/Access/Users/UsersPage/UsersPageFilterPanel/UserPageFilterPanelSection'
import UserPageHeadingComponent from 'app/Access/Users/UsersPage/UsersPageHeading/UserPageHeadingSection'
import { AddButton } from 'Components/Atoms/AddButton'
import { fluidPage } from 'Shared/HooksAndPresenters/FluidPage'
import {
  HeaderPanelWrapper,
  SectionNavPanelWrapper,
  PageWrapper,
  PageWrapperInner,
  CenterMainWrapper,
  FilterPanelWrapper,
  MainPanelWrapper,
  EditPanelWrapper,
  GenericList
} from 'Components/Templates'

interface IUserPageComponentProps {
  presenter: UserPagePresenter
}

const UserPageComponent = observer<IUserPageComponentProps>(props => {
  const {
    routeUpdater,
    routingState,
    editOpen,
    closePanel,
    editLoading,
    filterPanelLoading,
    editPanelClosedCallback,
    canCreateUser,
    usersPageListPresenter,
    usersDataLoading,
    checkEditPanelIsDirty,
    angularJSSettingsPanelOpenState,
    language
  } = props.presenter

  const [userAddModalOpenState, setUserAddModalOpenState] = useState(false)

  const { showAddButton, filterPanelOpen, setFilterPanelOpenState } = fluidPage(
    editOpen,
    closePanel,
    checkEditPanelIsDirty,
    angularJSSettingsPanelOpenState
  )

  const userAddHandler = () => {
    if (checkEditPanelIsDirty()) return
    setUserAddModalOpenState(true)
  }

  return (
    <PageWrapper>
      <PageWrapperInner>
        <HeaderPanelWrapper>
          <UserPageHeadingComponent filterOpen={filterPanelOpen} setFilterOpen={setFilterPanelOpenState} />
        </HeaderPanelWrapper>
        <CenterMainWrapper>
          <FilterPanelWrapper filterPanelOpen={filterPanelOpen} loading={filterPanelLoading}>
            {filterPanelOpen ? <UserPageFilterPanelSection /> : ''}
          </FilterPanelWrapper>
          <MainPanelWrapper filterPanelOpen={filterPanelOpen} loading={usersDataLoading}>
            <GenericList listPresenter={usersPageListPresenter} />
            {canCreateUser && showAddButton && (
              <AddButton
                action={() => {
                  userAddHandler()
                }}
                ariaLabel={language.get('common:ariaLabels:addUser')}
                language={language}
              />
            )}

            <UserPageModals
              userAddModalOpenState={userAddModalOpenState}
              setUserAddModalOpenState={setUserAddModalOpenState}
              language={language}
            />
          </MainPanelWrapper>
        </CenterMainWrapper>
      </PageWrapperInner>
      <EditPanelWrapper
        isOpen={editOpen}
        loading={editLoading}
        withSectionNav={true}
        panelCloseCallback={editPanelClosedCallback}
      >
        <UserEditSection />
      </EditPanelWrapper>
    </PageWrapper>
  )
})

export default withPresenter(UserPagePresenter)(UserPageComponent)
