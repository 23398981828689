import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const ChevronDown = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="ChevronDown" {...props}>
    <path d="m39 18.121-14.799 14.8-14.799-14.8L11.523 16l12.678 12.678L36.88 16 39 18.121Z" fill="#000" />
  </StyledIcon>
))

ChevronDown.displayName = 'ChevronDown'

export { ChevronDown }
