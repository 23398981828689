// @ts-nocheck
import { injectable } from 'inversify'
import { container } from 'app/Config/IOC'
import { computed, observable, action } from 'mobx'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'
import { Types } from 'Gateways/Service/Types'
import { TMode } from 'Components/Templates'
import { RunbookTypesViewPresenter } from 'app/Settings/RunbookTypes/RunbookTypesPage/RunbookTypesViewPresenter'
import { RunbookTypeFormPresenter } from 'app/Settings/RunbookTypes/RunbookTypesPage/RunbookTypeForm/RunbookTypeFormPresenter'
import { RunbookTypeRepository } from 'app/Repositories/RunbookType/RunbookTypeRepository'
import { ModalPresenter } from 'Components/Atoms'
import { ToasterService } from 'Components/Molecules'

@injectable()
export class RunbookTypeEditPresenter {
  private runbookTypesViewPresenter: RunbookTypesViewPresenter = container.get(RunbookTypesViewPresenter)

  private runbookTypeRepository: RunbookTypeRepository = container.get(RunbookTypeRepository)

  public language: ILanguageService = container.get(Types.ILanguageService)

  public sectionTitle: string = this.language.get('runbookTypes:editSection:title')

  @observable
  public runbookTypeFormPresenter: RunbookTypeFormPresenter

  private toasterGateway: ToasterService = container.get(ToasterService)

  @observable
  public archiveModalPresenter = new ModalPresenter()

  @observable
  public responseErrors: string[] = []

  public tooltipMessage: string = this.language.get('runbookTypes:editSection:archive:tooltip')

  @observable
  public editPanelSaving: boolean = false

  @observable
  public confirmModalPresenter: ModalPresenter = new ModalPresenter()

  @observable
  public triggerScroll = false

  @action
  public setTriggerScroll = (trigger: boolean) => {
    this.triggerScroll = trigger
  }

  @action
  public archiveButtonCallback = () => {
    this.runbookTypesViewPresenter.pageVM.archiveModalOpenState = true
  }

  @computed
  public get mode(): TMode {
    if (this.runbookTypesViewPresenter.runbookTypeLoading) return 'loading'
    if (this.runbookTypesViewPresenter.runbookTypeVM !== null) {
      return this.runbookTypesViewPresenter.pageVM.editPanelDirty ? 'panel-open-confirm' : 'panel-open'
    }

    return 'panel-closed'
  }

  @action
  public closeCallback = () => {
    this.runbookTypesViewPresenter.pageVM.editPanelOpen = false
  }

  @action
  public save = async () => {
    this.editPanelSaving = true
    const result = this.runbookTypeFormPresenter.processSubmission()
    if (result.result) {
      const formVm = result.viewModel
      this.runbookTypesViewPresenter.updateRunbookTypePm('name', formVm.name)
      this.runbookTypesViewPresenter.updateRunbookTypePm('description', formVm.description)
      this.runbookTypesViewPresenter.updateRunbookTypePm('visibilityType', formVm.account ? 'account' : 'global')
      this.runbookTypesViewPresenter.updateRunbookTypePm('dynamic', formVm.dynamic)
      this.runbookTypesViewPresenter.updateRunbookTypePm('enableRto', formVm.enableRto)
      this.runbookTypesViewPresenter.updateRunbookTypePm('restrictCreateToTemplates', formVm.restrictCreateToTemplates)
      this.runbookTypesViewPresenter.updateRunbookTypePm('incident', formVm.incident)
      this.runbookTypesViewPresenter.updateRunbookTypePm('disabled', formVm.disabled)
      this.runbookTypesViewPresenter.updateRunbookTypePm('approvalFlowId', formVm.approvalFlowId)

      if (formVm.account) {
        this.runbookTypesViewPresenter.updateRunbookTypePm(
          'accountId',
          this.runbookTypesViewPresenter.getAccountIdFromVisibility(formVm.account)
        )
      }

      this.runbookTypesViewPresenter.refreshRunbookTypePm()

      const response = await this.runbookTypesViewPresenter.saveRunbookType()
      if (!response.success) {
        if (response.errorMessages) {
          this.runbookTypeFormPresenter.editForm.serverErrors = response.errorMessages
          this.runbookTypeFormPresenter.errorAlertPresenter.setErrorMessages([...response.errorMessages])
        }
        this.setTriggerScroll(true)
        this.runbookTypesViewPresenter.submitted = false
      } else {
        this.toasterGateway.pop(
          this.language.get('runbookTypes:editSection:notification:title'),
          this.language.get('runbookTypes:editSection:notification:message'),
          'success'
        )
        this.runbookTypesViewPresenter.loadRunbookTypes()
        this.runbookTypesViewPresenter.pageVM.editPanelDirty = false
        this.runbookTypeFormPresenter.refresh()
      }
    } else {
      this.setTriggerScroll(true)
      this.runbookTypesViewPresenter.submitted = false
    }
    this.editPanelSaving = false
  }

  @action
  public archive = async () => {
    const response = await this.runbookTypesViewPresenter.archiveRunbookType()
    if (response.success) {
      this.runbookTypesViewPresenter.pageVM.archiveModalOpenState = false
      this.runbookTypesViewPresenter.pageVM.editPanelOpen = false
      this.runbookTypesViewPresenter.loadRunbookTypes()
      const title = this.language.get('runbookTypes:editSection:archive:toaster:title')
      const notification = this.language.get('runbookTypes:editSection:archive:toaster:message')
      this.toasterGateway.pop(title, notification, 'success')
    } else if (!response.success) {
      this.responseErrors = response.body.errors
    }
  }

  @action
  public withRunbookTypeFormPresenter = (runbookTypeFormPresenter: RunbookTypeFormPresenter) => {
    this.runbookTypeFormPresenter = runbookTypeFormPresenter
    return this
  }

  @action
  public reset = () => {
    this.runbookTypesViewPresenter.pageVM.editPanelDirty = false
    this.runbookTypeFormPresenter.reset()
    this.runbookTypesViewPresenter.resetRunbookTypeForEdit()
  }

  @computed
  public get archiveConfirmName(): string {
    return this.runbookTypeRepository.runbookTypeForEdit ? this.runbookTypeRepository.runbookTypeForEdit.name : ''
  }

  @action
  public cancelArchiveCallback = () => {
    this.runbookTypesViewPresenter.pageVM.archiveModalOpenState = false

    if (this.responseErrors.length > 0) {
      this.responseErrors = []
    }
  }

  public continueArchiveCallback = async () => {
    await this.archive()
  }

  @computed
  public get canArchiveRunbookType(): boolean {
    return false
  }
}
