import { atom, selector } from 'recoil'

import { CurrentUser } from 'main/services/queries/use-get-validate-token'

export const currentUserState = atom<CurrentUser | null>({
  key: 'current-user',
  default: null
})

export const currentUserIdState = selector({
  key: 'current-user:id',
  get: ({ get }) => {
    const user = get(currentUserState)
    return user?.id
  }
})
