import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const BrandAuditAndDiscover = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 100 100" a11yTitle="BrandAuditAndDiscover" {...props}>
    <g clipPath="url(#a)" fill="#fff"><path opacity=".5" d="m22.794 54.296 22.91 22.91-22.91 22.91-22.91-22.91z" /><path d="M89.44 61.065c-13.946 13.946-36.558 13.946-50.505 0-13.947-13.947-13.947-36.56 0-50.506 13.947-13.947 36.559-13.947 50.505 0 13.947 13.947 13.947 36.559 0 50.506ZM47.413 19.036c-9.265 9.265-9.265 24.287 0 33.552s24.287 9.265 33.552 0 9.265-24.287 0-33.552-24.287-9.265-33.552 0Z" /><circle opacity=".5" cx="64.3" cy="36.102" transform="rotate(90 64.3 36.102)" r="13.518" /></g>
  </StyledIcon>
))

BrandAuditAndDiscover.displayName = 'BrandAuditAndDiscover'

export { BrandAuditAndDiscover }
