import { useEffect, useMemo, useRef, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { isEmpty } from 'lodash'
import { FormProvider, useForm } from 'react-hook-form'
import { useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { MergeDeep } from 'type-fest'
import * as yup from 'yup'

import { EditPanel, Message, useNotify } from '@cutover/react-ui'
import { useRightPanelTypeState } from 'main/components/layout/right-panel'
import { useCustomFieldForm } from 'main/components/shared/custom-field-form'
import { useFilter } from 'main/components/shared/filter/filter-provider'
import { RunbooksBulkEditForm } from 'main/components/workspace/right-panels/runbooks-bulk-edit-form'
import { useAccount, useAccountCustomFields } from 'main/services/api/data-providers/account/account-data'
import { useWorkspaceData } from 'main/services/api/data-providers/workspace'
import { useLanguage, useUserWebsocket } from 'main/services/hooks'
import { FieldValue } from 'main/services/queries/types'
import {
  AttributeModifyType,
  RoleModifyType,
  RunbooksBulkEditPayload,
  useRunbooksBulkEdit
} from 'main/services/queries/use-bulk-edit-runbooks'
import { usePermittedResources } from 'main/services/queries/use-permitted-resources'
import {
  PrepareBulkEditRunbooksResponse,
  usePrepareBulkEditRunbooks
} from 'main/services/queries/use-prepare-bulk-edit-runbooks'

export type RunbooksBulkEditFormType = MergeDeep<
  RunbooksBulkEditPayload,
  {
    changes: {
      field_values?: Record<number, FieldValue>
    }
  }
>
// could put edit-runbook in this directory too -- or in panels for runbook space since you can get it in either
// (neither are where it is currently)
export const RunbooksBulkEditPanel = () => {
  const [{ runbookIds }, { closeRightPanel }] = useRightPanelTypeState('runbooks-bulk-edit')

  return <>{runbookIds && <BulkEditRunbooks runbookIds={runbookIds} onClose={closeRightPanel} />}</>
}

const BulkEditRunbooks = ({
  runbookIds,
  onClose
}: {
  runbookIds: number[]
  onClose?: () => void
  prepareData?: any
}) => {
  const [bulkUpdatePrepData, setBulkUpdatePrepData] = useState<PrepareBulkEditRunbooksResponse | null>(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [serverErrors, setServerErrors] = useState<string[]>([])
  const notify = useNotify()
  const { t } = useLanguage('runbooks', { keyPrefix: 'bulkEditPanel' })
  const { accountId: accountSlug } = useParams<{ accountId?: string }>()
  const queryClient = useQueryClient()
  const { runbookLookup, runbooks } = useWorkspaceData()
  const { listen } = useUserWebsocket()
  const filter = useFilter('template_type')
  const templateType = (Array.isArray(filter) ? filter[0] : undefined) as string
  const selectedRunbooks = runbooks?.filter(runbook => runbookIds.includes(runbook.id))
  const lockDestinationProject = selectedRunbooks?.some(
    runbook => runbook.settings_lock_template_copies_to_folder && runbook.template_type === 'off'
  )

  const runbookTypeIds = useMemo(
    () => [...new Set(runbookIds.map(runbookId => runbookLookup?.[runbookId]?.runbook_type_id))],
    [runbookIds, runbookLookup]
  )

  const { customFieldsLookup } = useAccountCustomFields()
  const {
    data: { customFields },
    buildFieldValuesAttributesRequestData,
    fieldValueValidation
  } = useCustomFieldForm({
    applyToSlugs: ['runbook_edit', 'runbook_add_edit'],
    customFieldsLookup,
    alwaysNotRequired: true,
    constraintContext: runbookTypeIds.length === 1 ? { runbook_type_id: runbookTypeIds[0] } : {}
  })

  const editableCustomFields = useMemo(
    () =>
      runbookTypeIds.length === 1
        ? customFields?.filter(
            customField =>
              !['MultiSearchableCustomField', 'SearchableCustomField', 'DependentCustomField'].includes(
                customField.type
              )
          )
        : [],
    [customFields, runbookTypeIds]
  )

  // prepare_bulk_update
  const { mutate: prepareBulkUpdate, isLoading: isPreparingBulkUpdate } = usePrepareBulkEditRunbooks({
    onSuccess: data => setBulkUpdatePrepData(data)
  })

  // get permitted projects
  const { account } = useAccount()
  const { data: permittedProjectsData, isLoading: isLoadingPermittedProjects } = usePermittedResources({
    resource: 'runbook',
    accountId: account?.id
  })

  // bulk_update mutation
  const { mutateAsync: bulkUpdateRunbooks } = useRunbooksBulkEdit()

  const defaultValues = useMemo(
    () => ({
      changes: {
        name: '',
        name_modify_type: 'replace' as AttributeModifyType,
        project_id: null,
        status: null,
        status_message: '',
        roles: [],
        description: '',
        description_modify_type: 'replace' as AttributeModifyType,
        field_values: {}
      },
      runbook_ids: runbookIds
    }),
    [runbookIds]
  )

  const methods = useForm<RunbooksBulkEditFormType>({
    defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        changes: yup.object().shape({
          name: yup.string().notRequired(),
          name_modify_type: yup.string().notRequired(),
          project_id: yup.number().notRequired(),
          status: yup.string().notRequired(),
          status_message: yup.string().when('status', {
            is: (status: RunbooksBulkEditPayload['changes']['status']) => !!status && status !== 'off',
            then: () => yup.string().required()
          }),
          roles: yup
            .array()
            .of(
              yup.object().shape({
                role_type_id: yup.number().notRequired(),
                subject_id: yup.number().notRequired(),
                subject_type: yup.string().notRequired()
              })
            )
            .notRequired(),
          description: yup.string().notRequired(),
          description_modify_type: yup.string().notRequired(),
          field_values: fieldValueValidation
        })
      })
    )
  })

  const { formState, reset, handleSubmit } = methods

  const submitBulkEditForm = async (data: RunbooksBulkEditFormType) => {
    setIsSubmitting(true)
    // clean up changes before patch
    const fieldValuesAttrubuteRequestData = buildFieldValuesAttributesRequestData(data.changes.field_values)

    const changes = {
      name: data.changes.name || undefined,
      name_modify_type: !!data.changes.name ? data.changes.name_modify_type : undefined,
      project_id: data.changes.project_id || undefined,
      roles: !!data.changes.roles && data.changes.roles.length > 0 ? data.changes.roles : undefined,
      role_modify_type: !!data.changes.roles && data.changes.roles.length > 0 ? ('add' as RoleModifyType) : undefined,
      status: data.changes.status || undefined,
      status_message: !!data.changes.status && data.changes.status_message ? data.changes.status_message : undefined,
      description: data.changes.description || undefined,
      description_modify_type: !!data.changes.description ? data.changes.description_modify_type : undefined,
      field_values_attributes:
        !!fieldValuesAttrubuteRequestData && fieldValuesAttrubuteRequestData.length
          ? fieldValuesAttrubuteRequestData
          : undefined,
      field_value: undefined
    }

    bulkUpdateRunbooks({
      changes,
      runbook_ids: data.runbook_ids
    })
  }

  const handleOnSubmit = () => {
    handleSubmit(submitBulkEditForm)()
  }

  const rolesSelectRef = useRef<{ reset: () => void }>(null)

  const handleOnReset = () => {
    reset(defaultValues)
    rolesSelectRef.current?.reset()
  }

  useEffect(() => {
    prepareBulkUpdate({ runbook_ids: runbookIds })
    reset(defaultValues)
    listen(data => handleBulkUpdateResponse(data))
  }, [runbookIds])

  // handle websocket response
  // TODO: need to update UserChannelResponse type so it can be used here
  const handleBulkUpdateResponse = (data: any) => {
    if (data?.meta?.headers?.request_method === 'runbooks_bulk_update') {
      setIsSubmitting(false)
      reset(defaultValues)
      if (data?.errors && data.errors.length) {
        // display error(s) on top of the form
        setServerErrors(data.errors)
      } else {
        notify.success(data?.messages[0])
        // close panel and invalidate runbooks query to refresh list
        onClose?.()
        queryClient.invalidateQueries([accountSlug, 'runbooks'])
      }
    }
  }

  const serverErrorMessages = useMemo(
    () => <>{serverErrors.length > 0 && <Message message={serverErrors} type="error" />}</>,
    [serverErrors]
  )

  const isDirty = formState.isDirty && !isEmpty(formState.dirtyFields)
  const isError = !isEmpty(formState.errors)
  const formStateHasStatusMessageError = !!formState.errors.changes?.status_message

  return (
    <>
      {runbookIds !== null && !isPreparingBulkUpdate && (
        <FormProvider {...methods}>
          <EditPanel
            onClose={onClose}
            title={t('header', {
              count: runbookIds.length,
              type: templateType === 'default' ? 'templates' : templateType === 'snippet' ? 'snippets' : 'runbooks'
            })}
            isDirty={isDirty}
            isError={isError}
            onReset={() => handleOnReset()}
            onSubmit={handleOnSubmit}
            isSubmitting={isSubmitting && isEmpty(formState.errors)}
            loading={isLoadingPermittedProjects}
          >
            {!!bulkUpdatePrepData &&
              (bulkUpdatePrepData.errors && bulkUpdatePrepData.errors.length ? (
                <Message message={bulkUpdatePrepData.errors[0]} type="error" />
              ) : (
                permittedProjectsData?.projects &&
                account?.id && (
                  <>
                    {serverErrorMessages}
                    {formStateHasStatusMessageError && <Message message={t('formIsInvalid')} type="error" />}
                    <RunbooksBulkEditForm
                      lockDestinationProject={lockDestinationProject}
                      projects={permittedProjectsData.projects}
                      bulkEditPrepData={bulkUpdatePrepData}
                      accountId={account.id}
                      rolesSelectRef={rolesSelectRef}
                      customFields={editableCustomFields}
                      runbooksAreSameType={runbookTypeIds.length === 1}
                      templateType={templateType}
                      disabled={isSubmitting && isEmpty(formState.errors)}
                    />
                  </>
                )
              ))}
          </EditPanel>
        </FormProvider>
      )}
    </>
  )
}
