import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const RowAddBefore = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="RowAddBefore" {...props}>
    <path d="M22.5 3h3v6.5H32v3h-6.5V19h-3v-6.5H16v-3h6.5V3Z" fill="#000" /><path fillRule="evenodd" clipRule="evenodd" d="M6 18h3v4.5h30V18h3v16a8 8 0 0 1-8 8H14a8 8 0 0 1-8-8V18Zm16.5 7.5H9V34a5 5 0 0 0 5 5h8.5V25.5Zm3 13.5H34a5 5 0 0 0 5-5v-8.5H25.5V39Z" fill="#000" />
  </StyledIcon>
))

RowAddBefore.displayName = 'RowAddBefore'

export { RowAddBefore }
