import { useMutation, useQueryClient } from 'react-query'

import { ActivitiesResponse, ActivityVM, getCacheKey } from './use-activities'
import { apiClient } from 'main/services/api/api-client'

type UpdateActivityResponse = {
  activity: ActivityVM
}

type UpdateActivityPayload = {
  activity: ActivityVM
}

const getUrlForActivity = (activity: ActivityVM) => `activities/${activity.id}`

export function useUpdateActivityMutation() {
  const queryClient = useQueryClient()

  return useMutation<UpdateActivityResponse, Error, UpdateActivityPayload>(
    'activity-update',
    async (activityPayload: UpdateActivityPayload) => {
      const { data } = await apiClient.put<UpdateActivityPayload, UpdateActivityResponse>({
        url: getUrlForActivity(activityPayload.activity),
        data: activityPayload
      })

      return data as UpdateActivityResponse
    },
    {
      onSuccess: response => {
        queryClient.setQueryData<ActivitiesResponse>(
          getCacheKey(),
          // @ts-ignore
          // So the type definition allows for currentActivities to be ActivitiesResponse or undefined but
          // doesn't allow for the return to be undefined
          (currentActivities: { pages: ActivitiesResponse[] } | undefined) => {
            if (!currentActivities) return currentActivities

            let activityFound = false

            currentActivities.pages.some((cachResponse: ActivitiesResponse) => {
              const cachedActivity = cachResponse.activities.find(
                (item: ActivityVM) => item.id === response.activity.id
              )

              if (cachedActivity) {
                cachedActivity.featured = response.activity.featured
                activityFound = true
              }

              return activityFound
            })

            return currentActivities
          }
        )
        queryClient.removeQueries('/activities?recipient_type=Runbook', { inactive: true })
      }
    }
  )
}
