import { ReactNode, useState } from 'react'
import Cookies from 'js-cookie'
import { useMatch, useNavigate, useSearchParams } from 'react-router-dom'

import { LoadingPage } from 'main/components/authentication/loading-page'
import { useGetValidateTokenWithCookie } from 'main/services/queries/use-get-validate-token'

type VerifyActiveSessionProps = {
  children: ReactNode
}

export const VerifyActiveSession = ({ children }: VerifyActiveSessionProps) => {
  const navigate = useNavigate()
  const [urlSearchParams] = useSearchParams()
  const [resetPasswordSuccess] = useState(localStorage.getItem('resetPasswordSuccess'))
  const [userVerifySuccess] = useState(localStorage.getItem('userVerifySuccess'))
  const resetPasswordParam = urlSearchParams.get('reset_password')
  const resetPage = useMatch({ path: '/resetpassword', end: false })

  const { isLoading } = useGetValidateTokenWithCookie({
    onError: () => {
      Cookies.remove('auth_headers')
      const isResetPage = resetPage?.pathname.includes('resetpassword')
      if (isResetPage) {
        navigate('/login')
      }
    },
    onSuccess: () => {
      if (userVerifySuccess) {
        Cookies.remove('auth_headers')
      } else if (!resetPasswordParam && !resetPasswordSuccess) {
        navigate('/app/my-cutover')
      }
    }
  })

  return isLoading ? <LoadingPage /> : <>{children}</>
}
