// @ts-nocheck
import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { colors, px2rem } from 'app/Styles/Variables'
import { TaskTypesPageMoreOptionsPopoverPresenter } from 'app/Settings/TaskTypes/TaskTypesPage/TaskTypesPageHeading/TaskTypesPageMoreOptionsPopoverPresenter'
import { Button, Popover } from 'Components/Molecules'

const BorderedButtonWrapper = styled.div`
  border-top: 1px solid ${colors.primaryGreyBackgroundColor};
`

interface ITaskTypesPageHeadingExportPopoverComponent {
  presenter: TaskTypesPageMoreOptionsPopoverPresenter
}

export const TaskTypesPageMoreOptionsPopoverComponent = observer<ITaskTypesPageHeadingExportPopoverComponent>(
  ({ presenter }) => {
    const {
      reloadDataPopoverPresenter,
      reloadDataButtonPresenter,
      popoverOpenState,
      setPopoverOpenState,
      reloadClicked,
      displayArchivedTaskTypesPresenter,
      displayUnArchivedTaskTypesPresenter,
      showArchivedTaskTypes,
      language
    } = presenter

    const togglePopoverOpen = () => {
      setPopoverOpenState(!popoverOpenState)
    }

    useEffect(() => {
      if (popoverOpenState) setPopoverOpenState(false)
    }, [reloadClicked])

    return (
      <>
        <Popover
          presenter={reloadDataPopoverPresenter}
          open={false}
          controlledOpen={popoverOpenState}
          type="customContent"
          content={
            <>
              <>
                {showArchivedTaskTypes ? (
                  <Button presenter={displayUnArchivedTaskTypesPresenter} extraStyles={{ width: `${px2rem(280)}` }} />
                ) : (
                  <Button presenter={displayArchivedTaskTypesPresenter} extraStyles={{ width: `${px2rem(280)}` }} />
                )}
              </>
              <BorderedButtonWrapper>
                <Button presenter={reloadDataButtonPresenter} extraStyles={{ width: `${px2rem(280)}` }} />
              </BorderedButtonWrapper>
            </>
          }
          customTriggerCallback={togglePopoverOpen}
          moreOptionsToolTipText={language.get('common:moreOptions')}
          wrapperExtraStyles={{ marginTop: `${px2rem(20)}`, display: 'inline-block' }}
        />
      </>
    )
  }
)
