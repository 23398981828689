// @ts-nocheck
import { useState, useEffect } from 'react'
import { container } from 'app/Config/IOC'
import { ConfirmMode, CustomFieldsViewPresenter } from './CustomFieldsViewPresenter'
import { Modal, GenericAddModal } from 'Components/Atoms'
import { EditPanelArchiveModal } from 'Components/Templates'
import { IPageModalProps } from 'app/Shared/Entities/IPageModalProps'
import CustomFieldAddSection from './CustomFieldAdd/CustomFieldAddSection'
import { confirmSectionHooks, addSectionHooks } from 'Shared/HooksAndPresenters/AddEditSection'
import { EditPanelConfirmModal } from 'Shared/Components/Templates/EditPanelConfirmModal/EditPanelConfirmModal'
import { Submitted } from 'Shared/Entities/Enums/Submitted'

interface ICustomFieldsPageModals extends IPageModalProps {
  confirmMode: ConfirmMode
}

export const CustomFieldsPageModals = (props: ICustomFieldsPageModals) => {
  const {
    addModalOpenState,
    addFormPresenter,
    addFormCancelCallback,
    setAddModalOpenState,
    archiveModalOpenState,
    archiveConfirmName,
    cancelArchiveCallback,
    continueArchiveCallback,
    archiveResponseErrors,
    editSubmittedState,
    setEditSubmittedState,
    addPostSuccessCallback,
    archiveModalPresenter,
    language,
    confirmMode
  } = props

  const customFieldsViewPresenter = container.get(CustomFieldsViewPresenter)

  const { addModalPresenter } = addSectionHooks(setAddModalOpenState, addModalOpenState, addFormPresenter)
  const { confirmModalPresenter } = confirmSectionHooks(editSubmittedState)

  const [confirmTitle, setConfirmTitle] = useState('')
  const [confirmMessage, setConfirmMessage] = useState('')

  useEffect(() => {
    if (archiveModalOpenState) {
      archiveModalPresenter.onOpen()
    } else {
      archiveModalPresenter.onClose()
    }
  }, [archiveModalOpenState])

  useEffect(() => {
    if (confirmMode === ConfirmMode.Delete) {
      setConfirmTitle(language.get('customFields:edit:deleteConfirmationTitle'))
      setConfirmMessage(language.get('customFields:edit:deleteConfirmation'))
    } else {
      setConfirmTitle(language.get('common:confirmTitle'))
      setConfirmMessage(language.get('customFields:edit:confirmation'))
    }
  }, [confirmMode])

  return (
    <>
      <GenericAddModal
        title={language.get('customFields:add:title')}
        presenter={addModalPresenter}
        cancelCallback={() => {
          setAddModalOpenState(false)
          addFormCancelCallback()
        }}
        viewPresenter={customFieldsViewPresenter}
        language={language}
      >
        <CustomFieldAddSection
          postSuccessCallback={() => {
            addModalPresenter.onClose()
            setAddModalOpenState(false)
            addPostSuccessCallback()
          }}
          addFormPresenter={addFormPresenter}
        />
      </GenericAddModal>

      <Modal presenter={archiveModalPresenter.withOnCloseExtra(cancelArchiveCallback)}>
        <EditPanelArchiveModal
          archiveConfirmName={archiveConfirmName}
          cancelCallback={cancelArchiveCallback}
          continueCallback={continueArchiveCallback}
          responseErrors={archiveResponseErrors}
          modalTitle={language.get('customFields:archive:modal:title')}
          modalMessage={language.get('customFields:archive:modal:message')}
        />
      </Modal>
      <Modal presenter={confirmModalPresenter.withOnCloseExtra(() => setEditSubmittedState(Submitted.Unsubmitted))}>
        <EditPanelConfirmModal
          modalTitle={confirmTitle}
          modalMessage={confirmMessage}
          continueCallback={() => {
            setEditSubmittedState(Submitted.SubmittedAndConfirmed)
          }}
          cancelCallback={() => {
            setEditSubmittedState(Submitted.Unsubmitted)
          }}
        />
      </Modal>
    </>
  )
}
