// @ts-nocheck

import styled from 'styled-components'

const CenterPanel = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 100%;
  width: 100%;
`

interface ICenterMainWrapperProps {
  extraStyles?: CSSProperties
  children: ReactNode
}

export const CenterMainWrapper = (props: ICenterMainWrapperProps) => (
  <CenterPanel style={props.extraStyles}>{props.children}</CenterPanel>
)
