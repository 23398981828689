import { BarTable, colors } from '@cutover/react-ui'
import { DashboardMediaType } from '../../../widgets/types'
import { generateBarValues } from '../bar-table-widget-utils'
import { MrdDashboardWidget } from '../mrd-dashboard-widget'

export type RunbookListWidgetProps = {
  media: DashboardMediaType
  data: RunbookListWidgetData
}

export type RunbookListWidgetData = {
  errors?: string[]
  filters?: any
  id: number
  name: string
  notes?: any
  type: string
  width?: number
  values: RunbookListValues[]
}

type RunbookListValues = {
  id: number
  name: string
  account_id: number
  account_slug: string
  status: 'red' | 'amber' | 'green' | 'off'
  status_message: string | null
  stage: string
  end_display: number
  start_display: number
  end_diff: EndDiff
  streams_count: number
  complete: number
  in_progress: number
  startable: number
  total: number
  default: number
}

type EndDiff = {
  value: string
  early: boolean
}

export const RunbookListWidget = ({ media, data: { name, errors, notes, values } }: RunbookListWidgetProps) => {
  return (
    <MrdDashboardWidget title={name} errors={errors} notes={notes} fullWidth>
      <BarTable data={{ rowData: convertValuesToRowDataFormat(values) }} media={media} />
    </MrdDashboardWidget>
  )
}

// convert values into row data
const convertValuesToRowDataFormat = (values: RunbookListValues[]) => {
  const rowData = values.map(value => {
    const { name, complete, in_progress, startable, total, end_diff, stage, end_display, id, status } = value

    const durationValue = end_diff.value
    const durationColor = end_diff.early ? colors.success : colors.warning

    return {
      id,
      mainLabel: name,
      rowInfo: getEndDateString(stage, end_display),
      additionalRowInfo: stage === 'cancelled' ? null : { value: durationValue, color: durationColor },
      values: generateBarValues({
        bars: [
          { count: complete, tooltip: 'complete' },
          { count: in_progress, tooltip: 'in progress' },
          { count: startable, tooltip: 'startable' },
          { count: value.default, tooltip: 'not yet startable' }
        ],
        total,
        context: 'task',
        barColors: [colors.primary, colors.primaryLight, colors.bg, colors.bgLight],
        displayPercentBarIndex: 0,
        includePercentageInTooltip: true
      }),
      status: status
    }
  })
  return rowData
}

const getEndDateString = (stage: string, endDateInSeconds: number): string => {
  let statusString: string = ''
  if (stage === 'cancelled') {
    statusString = 'Cancelled'
  } else if (stage === 'active' || stage === 'paused') {
    statusString = 'Due'
  } else if (stage === 'complete') {
    statusString = 'Completed'
  }
  // quick js way of cropping out date info we need
  const dateString = new Date(endDateInSeconds * 1000).toString().slice(4, 21)
  return `${statusString} ${dateString}`
}
