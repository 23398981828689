import { useQuery, UseQueryOptions } from 'react-query'
import { AxiosError } from 'axios'

import { QueryKeys } from 'main/services/queries/query-keys'
import { apiClientCompat } from '../api'

export type ValidatedObject = { id: number; name: string; linked_runbook_id?: number; internal_id?: number }

export type ValidatedError = {
  type: 'string'
  label: string
  count: number
  objects: ValidatedObject[]
}
export type ValidatedErrors = {
  [key: string]: ValidatedError
}
export type ValidatedWarning = ValidatedError
export type ValidatedWarnings = {
  [key: string]: ValidatedWarning
}

export type ValidateRunbookErrorResponse = {
  errors: ValidatedErrors
  status: boolean
  warnings: ValidatedWarnings
}

export function useValidateRunbook(
  runbookId?: string | number,
  runbookVersionId?: string | number,
  options: UseQueryOptions<{}, AxiosError> = {}
) {
  return useQuery<{}, AxiosError>(
    [QueryKeys.Runbooks, String(runbookId), QueryKeys.RunbookVersions, String(runbookVersionId), 'validate'],
    async () => {
      const data = await apiClientCompat.get({
        url: `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/validate`
      })

      return data
    },
    {
      ...options,
      enabled: !!runbookId && !!runbookVersionId && options.enabled
    }
  )
}
