import { useState } from 'react'

import { AddSnippetModal } from 'main/components/runbook/modals/snippets/add-snippet/add-snippet-modal'
import { ReactAngularConnector } from 'main/connectors/react-angular-connector'

type AddSnippetState = {
  taskId: number | null
  streamName: string | null
}

export const AddSnippetConnector = () => {
  const [state, setState] = useState<AddSnippetState>({
    taskId: null,
    streamName: null
  })

  const handleOnMount = ({ taskId, streamName }: AddSnippetState) => {
    setState({
      taskId,
      streamName
    })
  }

  return (
    <ReactAngularConnector mountEvent="show-add-snippet" unmountEvent="hide-add-snippet" onMount={handleOnMount}>
      {state.taskId && state.streamName && <AddSnippetModal taskId={state.taskId} />}
    </ReactAngularConnector>
  )
}
