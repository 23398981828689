import { ReactNode } from 'react'
import { PolymorphicComponent } from 'react-polymorphic-box'
import styled, { css } from 'styled-components/macro'

import { px2rem } from '../../utils'

export type LegacyTextProps = {
  as?: 'p' | 'span' | 'em' | 'strong' | 'label'
  weight?: string | number
  color?: string
  size?: number
  truncate?: boolean
  block?: boolean
  height?: number | string
  children?: ReactNode
  transform?: 'capitalize' | 'lowercase' | 'uppercase'
  whiteSpace?: 'normal' | 'nowrap' | 'pre' | 'pre-wrap' | 'pre-line' | 'break-spaces'
  align?: 'start' | 'end' | 'left' | 'right' | 'center' | 'justify' | 'match-parent'
  underline?: boolean
}

const defaultElement = 'span'

/**
 * @deprecated Use new text component
 */
export const LegacyText: PolymorphicComponent<LegacyTextProps, typeof defaultElement> = styled(
  defaultElement
)<LegacyTextProps>`
  line-height: ${({ height }) => (height === undefined ? '1.125rem' : height)};
  display: ${({ block }) => block && 'block'};
  font-weight: ${({ weight }) => weight};
  color: ${({ color }) => color};
  font-size: ${({ size }) => typeof size === 'number' && px2rem(size)};
  text-transform: ${({ transform }) => transform};
  white-space: ${({ whiteSpace }) => whiteSpace};
  text-align: ${({ align }) => align};
  text-decoration: ${({ underline }) => underline && 'underline'};
  text-underline-offset: ${({ underline }) => underline && '2px'};

  ${({ truncate }) =>
    truncate &&
    css`
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `};
`
