import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Dislike = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Dislike" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M27.768 42.083a.505.505 0 0 0 .473-.2.452.452 0 0 0 .068-.382l-2.067-7.58a3.5 3.5 0 0 1 3.377-4.42H40a.5.5 0 0 0 .5-.5v-4.34a.5.5 0 0 0-.098-.298l-9.008-12.16a.5.5 0 0 0-.401-.203H17.5v18.224l.138.102c.487.36 1.162.882 1.924 1.534 1.512 1.292 3.425 3.143 4.881 5.3a62.422 62.422 0 0 1 2.824 4.615c.097.175.28.291.5.308ZM14.5 29.5V12h-5a.5.5 0 0 0-.5.5V29a.5.5 0 0 0 .5.5h5Zm1.028 3a32.64 32.64 0 0 1 2.085 1.64c1.413 1.208 3.1 2.857 4.344 4.7a59.417 59.417 0 0 1 2.686 4.39c1.228 2.214 4.484 2.573 6.039.396a3.453 3.453 0 0 0 .521-2.915l-2.067-7.58a.5.5 0 0 1 .483-.63H40a3.5 3.5 0 0 0 3.5-3.5v-4.34a3.5 3.5 0 0 0-.688-2.084l-9.007-12.16A3.5 3.5 0 0 0 30.993 9H9.5A3.5 3.5 0 0 0 6 12.5V29a3.5 3.5 0 0 0 3.5 3.5h6.028Z" fill="#000" />
  </StyledIcon>
))

Dislike.displayName = 'Dislike'

export { Dislike }
