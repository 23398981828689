import { useAsyncList } from '@react-stately/data'
import { LoadingState } from '@react-types/shared'

export type UsePagedListPageLoadProps = {
  signal: AbortSignal
  cursor: string | undefined
  filterText: string | undefined
}

export type UsePagedListPageLoadResultProps<T> = {
  items: T[]
  cursor: string | undefined
}

export type UsePagedListProps<T> = {
  load: (props: UsePagedListPageLoadProps) => Promise<UsePagedListPageLoadResultProps<T>>
  initialItems?: T[]
}

export type UsePagedListResult<T> = {
  items: T[]
  inputValue: string
  onInputChange: (value: string) => void
  loadingState: LoadingState
  onLoadMore: () => void
}

export function usePagedList<T>(props: UsePagedListProps<T>): UsePagedListResult<T> {
  const list = useAsyncList<T>({
    async load({ signal, cursor, filterText }) {
      if (!filterText && props.initialItems) {
        return {
          items: props.initialItems
        }
      }

      return props.load({ signal, cursor, filterText })
    }
  })

  return {
    items: list.items,
    inputValue: list.filterText,
    onInputChange: list.setFilterText,
    loadingState: list.loadingState,
    onLoadMore: list.loadMore
  }
}
