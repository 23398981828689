import { useMutation, UseMutationOptions, useQuery } from 'react-query'

import { QueryKeys } from './query-keys'
import {
  UserSetting,
  UserSettingsResponse
} from 'main/components/dashboards/runbook-dashboard-schedule/runbook-dashboard-schedule-types'
import { apiClient } from 'main/services/api/api-client'
import { ApiError } from 'main/services/api/http-gateway-adapter'

export const useRunbookDashboardSchedulesQuery = ({
  runbookId,
  dashboardId
}: {
  runbookId?: string | number
  dashboardId?: string | number
}) => {
  return useQuery<UserSettingsResponse, ApiError>(
    ['user_settings', runbookId, dashboardId],
    async () => {
      const { data } = await apiClient.get<UserSettingsResponse>({
        url: `user_settings?data[runbook_id]=${runbookId}&resource_type=Dashboard&resource_id=${dashboardId}`
      })

      return data
    },
    { enabled: runbookId !== undefined && dashboardId !== undefined }
  )
}

export const useRunbooksDashboardSchedulesQuery = ({
  dashboardId,
  accountId
}: {
  dashboardId?: string | number
  accountId?: string | number
}) => {
  const params = {
    resource_type: 'Dashboard',
    resource_id: dashboardId,
    'data[account_id]': accountId
  }

  return useQuery<UserSettingsResponse, ApiError>(
    [QueryKeys.UserSettings, params],
    async () => {
      const { data } = await apiClient.get<UserSettingsResponse>({
        url: 'user_settings',
        params
      })

      return data
    },
    { enabled: dashboardId !== undefined }
  )
}

type UserSettingDeleteResponse = {
  user_setting: UserSetting
}

export const useDeleteRunbookDashboardScheduleMutation = (
  scheduleId: number,
  options: UseMutationOptions<UserSettingDeleteResponse, Error>
) => {
  return useMutation<UserSettingDeleteResponse, Error>(
    'user_settings',
    async () => {
      const { data } = await apiClient.delete({
        url: `user_settings/${scheduleId}`
      })

      return data as UserSettingDeleteResponse
    },
    options
  )
}
