import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const BrandEnergy = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 100 100" a11yTitle="BrandEnergy" {...props}>
    <g clipPath="url(#a)" fill="#fff"><path d="M56 46.334v-47l-47 47h47Z" /><path opacity=".5" d="M43.667 54v47l47-47h-47Z" /></g>
  </StyledIcon>
))

BrandEnergy.displayName = 'BrandEnergy'

export { BrandEnergy }
