import { useMutation } from 'react-query'

import { apiClient } from 'main/services/api'
import { RunbookPersonCreateResponse } from 'main/services/api/data-providers/runbook-types'

export type UserPayload = { id: number; role: string }

export type RunbookTeamPayload = {
  team_id?: number
  name: string
  linked: boolean
  user_ids?: Array<number>
  type?: 'team'
}

export type RunbookPeoplePayload = {
  users?: Array<UserPayload>
  runbook_teams?: Array<RunbookTeamPayload>
}

export const useRunbookPeopleCreate = (runbookId: number, runbookVersionId: number) => {
  return useMutation<RunbookPersonCreateResponse, unknown, RunbookPeoplePayload, unknown>(
    'runbookPeople',
    async (payload: RunbookPeoplePayload) => {
      const { data } = await apiClient.post({
        url: `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/runbook_people`,
        data: payload
      })

      return data as RunbookPersonCreateResponse
    }
  )
}
