// @ts-nocheck
import { injectable } from 'inversify'
import Navigo from 'navigo'
import { IRouterGateway } from './IRouterGateway'

@injectable()
export class RouterGateway implements IRouterGateway {
  private navigo

  public registerRoutes = async routeConfig => {
    if (this.navigo) return new Promise(resolve => setTimeout(resolve, 0))
    let root = null
    let useHash = true
    let hash = '#'
    this.navigo = new Navigo(root, useHash, hash)
    this.navigo
      .on(routeConfig)
      .notFound(() => {})
      .resolve()
    return new Promise(resolve => setTimeout(resolve, 0))
  }

  public unload = () => {
    this.navigo && this.navigo.destroy()
  }

  public goToPath = async (pathname: string) => {
    this.navigo && this.navigo.navigate(pathname)
  }

  public lastRouteResolved = () => {
    if (this.navigo) {
      return this.navigo.lastRouteResolved()
    }
  }

  public resolve = () => {
    this.navigo && this.navigo.resolve()
  }
}
