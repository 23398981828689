import { createContext, ReactNode, useCallback, useContext, useState } from 'react'
import { RequireAtLeastOne } from 'type-fest'

import { RunbookSubHeaderDefaultLeft, RunbookSubHeaderDefaultRight } from './runbook-sub-header-default-components'

type Content = { left?: ReactNode; right?: ReactNode }

/** A hook to set the left and right content of the runbook subheader. Can be used in cases when the subheader is
 * dependent on the context of components lower in the component tree. An example of this might be showing
 * submit/cancel buttons in the subheader for a form within the page when it's dirty.
 */
const RunbookSubHeaderContentContext = createContext<{
  subHeaderContent?: Content
  addSubHeaderContent: (content: RequireAtLeastOne<Content, 'left' | 'right'>) => void
  resetSubHeaderContent: () => void
}>({ subHeaderContent: {}, addSubHeaderContent: () => undefined, resetSubHeaderContent: () => {} })

export const RunbookSubHeaderContextProvider = ({ children }: { children: ReactNode }) => {
  const [subHeaderContent, setSubHeaderContent] = useState<Content>({
    left: <RunbookSubHeaderDefaultLeft />,
    right: <RunbookSubHeaderDefaultRight />
  })

  /** Adds subheader content to the left, right or both
   * @param content - An object with left|right properties that are both ReactNodes
   */
  const addSubHeaderContent = useCallback(
    (content: RequireAtLeastOne<Content, 'left' | 'right'>) => {
      setSubHeaderContent(prev => ({ ...prev, ...content }))
    },
    [setSubHeaderContent]
  )

  /** Resets subheader content to the default state */
  const resetSubHeaderContent = useCallback(
    () => setSubHeaderContent({ left: <RunbookSubHeaderDefaultLeft />, right: <RunbookSubHeaderDefaultRight /> }),
    [setSubHeaderContent]
  )

  return (
    <RunbookSubHeaderContentContext.Provider value={{ subHeaderContent, addSubHeaderContent, resetSubHeaderContent }}>
      {children}
    </RunbookSubHeaderContentContext.Provider>
  )
}

export const useRunbookSubHeader = () => {
  return useContext(RunbookSubHeaderContentContext)
}
