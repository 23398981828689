import { ThemeType as GrommetThemeType } from 'grommet'
import { css } from 'styled-components/macro'
import { SetRequired } from 'type-fest'

type GrommetTheme = SetRequired<GrommetThemeType, 'textInput'>
type GrommetTextInput = GrommetTheme['textInput']

export const textInput: GrommetTextInput = {
  extend: css`
    font-weight: normal;
    font-size: 15px;

    &[disabled] {
      opacity: 1;
    }
  `,
  suggestions: {
    extend: css`
      padding: 8px;

      li > button {
        border-radius: 4px;
      }
    `
  }
}
