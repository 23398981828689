import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const PageGraph = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="PageGraph" {...props}>
    <path d="M15 22h3v15h-3V22Zm7.5 7h3v8h-3v-8Zm7.5-3h3v11h-3V26Z" fill="#000" /><path d="M8 12a8 8 0 0 1 8-8h8.686a8 8 0 0 1 5.657 2.343l7.314 7.314A8 8 0 0 1 40 19.314V36a8 8 0 0 1-8 8H16a8 8 0 0 1-8-8V12Zm29 7.314c0-.105-.003-.21-.01-.314H30a5.5 5.5 0 0 1-5.5-5.5V7H16a5 5 0 0 0-5 5v24a5 5 0 0 0 5 5h16a5 5 0 0 0 5-5V19.314ZM35.744 16a5.072 5.072 0 0 0-.209-.222l-7.313-7.314a4.994 4.994 0 0 0-.722-.597V13.5A2.5 2.5 0 0 0 30 16h5.744Z" fill="#000" />
  </StyledIcon>
))

PageGraph.displayName = 'PageGraph'

export { PageGraph }
