// @ts-nocheck

import styled from 'styled-components'
import { colors, px2rem } from 'app/Styles/Variables'

const NoSessionPageWrapperStyled = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow-y: auto;
  background-color: ${colors.primary};
  padding: ${px2rem(24)} ${px2rem(16)} ${px2rem(50)};
`

interface INoSessionPageWrapperProps {
  extraStyles?: CSSProperties
  children: ReactNode
}

export const NoSessionPageWrapper = (props: INoSessionPageWrapperProps) => (
  <NoSessionPageWrapperStyled style={props.extraStyles}>{props.children}</NoSessionPageWrapperStyled>
)
