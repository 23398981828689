import { ThemeType as GrommetThemeType } from 'grommet/themes'
import { SetRequired } from 'type-fest'

import { Icon } from '../icon'

// Exports the type and value for the grommet theme's icon key

type GrommetTheme = SetRequired<GrommetThemeType, 'spinner'>
type GrommetSpinner = GrommetTheme['spinner']

export const spinner: Partial<GrommetSpinner> = {
  icon: <Icon icon="spinner" />,
  size: {
    medium: '40px'
  }
}
