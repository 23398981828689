import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Radar = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Radar" {...props}>
    <path d="M22.5 6.062a18.24 18.24 0 0 1 3 0v15.34A2.999 2.999 0 0 1 24 27a3 3 0 0 1-1.5-5.599V16.65a7.5 7.5 0 1 0 8.057 3.707L33.18 18.9A10.449 10.449 0 0 1 34.5 24c0 5.799-4.701 10.5-10.5 10.5S13.5 29.799 13.5 24c0-5.29 3.912-9.666 9-10.394V9.074C14.92 9.827 9 16.222 9 24c0 8.284 6.716 15 15 15 8.284 0 15-6.716 15-15 0-2.644-.684-5.129-1.885-7.286l2.623-1.457A17.92 17.92 0 0 1 42 24c0 9.941-8.059 18-18 18S6 33.941 6 24c0-9.436 7.262-17.176 16.5-17.938.001 0 0 0 0 0Z" fill="#000" />
  </StyledIcon>
))

Radar.displayName = 'Radar'

export { Radar }
