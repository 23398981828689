import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const CheckDashed = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="CheckDashed" {...props}>
    <path d="m6.94 20.9-2.122 2.122 6.364 6.364 2.122-2.122L6.94 20.9Zm9.192 9.193-2.121 2.12 4.95 4.95 5.656-5.656-2.121-2.122-3.535 3.536-2.829-2.828Zm17.678-7.779-6.364 6.364-2.122-2.12 6.364-6.365 2.122 2.121Zm2.828-2.828 6.364-6.364-2.121-2.121-6.364 6.364 2.121 2.121Z" fill="#000" />
  </StyledIcon>
))

CheckDashed.displayName = 'CheckDashed'

export { CheckDashed }
