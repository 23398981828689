import { Outlet } from 'react-router-dom'

import { DuplicateSnippetsConnector } from 'main/connectors/duplicate-snippets-connector'

export const RunbookListPage = () => {
  return (
    <>
      <DuplicateSnippetsConnector />
      <Outlet />
    </>
  )
}
