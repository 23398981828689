import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const BrandTechDeliveryAndRelease = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 100 101" a11yTitle="BrandTechDeliveryAndRelease" {...props}>
    <path d="M100 47V0L53 47h47Z" fill="#fff" /><path opacity=".5" d="M64.514 2.486a50 50 0 0 1 16.343 8.505l-21.12 21.12a20.653 20.653 0 0 0-13.768-2.041A20.66 20.66 0 0 0 50 70.993v29.34A50 50 0 1 1 64.514 2.486Z" fill="#fff" />
  </StyledIcon>
))

BrandTechDeliveryAndRelease.displayName = 'BrandTechDeliveryAndRelease'

export { BrandTechDeliveryAndRelease }
