import { ReactElement, ReactNode } from 'react'
import { PolymorphicComponent } from 'react-polymorphic-box'
import styled, { css } from 'styled-components/macro'

import { FlexItemProps } from '..'
import { px2rem } from '../../utils'

type FlexChild = ReactElement<FlexItemProps> | boolean | null | undefined

export type LegacyFlexProps = {
  width?: string | number
  height?: string | number
  flexDirection?: 'row' | 'column' | 'row-reverse' | 'column-reverse' // Default 'row'
  flexWrap?: 'nowrap' | 'wrap' | 'wrap-reverse' // Default 'nowrap'
  justifyContent?: 'flex-start' | 'space-between' | 'space-around' | 'center' | 'flex-end' // Default 'flex-start'
  alignItems?: 'flex-start' | 'flex-end' | 'center' | 'stretch' | 'baseline' // Default 'flex-start'
  alignContent?: 'normal' | 'flex-start' | 'flex-end' | 'center' | 'stretch' | 'space-between' | 'space-around' // Default 'normal'
  inline?: boolean
  children: FlexChild | FlexChild[] | ReactNode
  gap?: string | number
}

const defaultElement = 'div'

/**
 * @deprecated Use grommet box exported as Box (https://v2.grommet.io/box)
 */
export const LegacyFlex: PolymorphicComponent<
  LegacyFlexProps, // Merged with props from the underlying element type
  typeof defaultElement
> = styled(defaultElement)<LegacyFlexProps>`
  display: ${({ inline }) => (inline ? 'inline-flex' : 'flex')};
  gap: ${({ gap }) => (typeof gap === 'number' ? px2rem(gap) : gap)};
  ${({ flexDirection }) => flexDirection && `flex-direction: ${flexDirection};`}
  ${({ flexWrap }) => flexWrap && `flex-wrap: ${flexWrap};`}
  ${({ justifyContent }) => justifyContent && `justify-content: ${justifyContent};`}
  ${({ alignItems }) => alignItems && `align-items: ${alignItems};`}
  ${({ alignContent }) => alignContent && `align-content: ${alignContent};`}
  ${({ width }) =>
    typeof width !== 'undefined' &&
    css`
      width: ${typeof width === 'number' ? px2rem(width) : width};
    `}
  ${({ height }) =>
    typeof height !== 'undefined' &&
    css`
      height: ${typeof height === 'number' ? px2rem(height) : height};
    `}
`
