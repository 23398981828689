import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const TaskTypeCog100 = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="TaskTypeCog100" {...props}>
    <path d="m9.73 10.191 3.966 1.407A16.23 16.23 0 0 1 18.708 8.7l.765-4.139A20.05 20.05 0 0 1 24.197 4c1.628 0 3.21.194 4.724.561l.765 4.14a16.231 16.231 0 0 1 5.012 2.897l3.967-1.407a19.979 19.979 0 0 1 4.73 8.18l-3.205 2.734c.169.94.257 1.907.257 2.895s-.088 1.955-.257 2.895l3.204 2.734a19.98 19.98 0 0 1-4.73 8.18l-3.966-1.407a16.23 16.23 0 0 1-5.012 2.898l-.765 4.139a20.05 20.05 0 0 1-4.724.561 20.05 20.05 0 0 1-4.724-.561l-.765-4.14a16.231 16.231 0 0 1-5.012-2.897L9.73 37.809A19.978 19.978 0 0 1 5 29.629l3.204-2.734a16.348 16.348 0 0 1 0-5.79L5 18.371c.91-3.107 2.553-5.9 4.73-8.18ZM12.411 28.4l-2.834 2.417c.4.855.872 1.669 1.41 2.435l3.514-1.246 1.7 1.44a12.336 12.336 0 0 0 3.815 2.207l2.097.753.676 3.66a16.385 16.385 0 0 0 2.814 0l.677-3.66 2.097-.753a12.356 12.356 0 0 0 3.815-2.206l1.7-1.441 3.514 1.246c.537-.766 1.01-1.58 1.409-2.435L35.982 28.4l.394-2.19a12.473 12.473 0 0 0 0-4.418l-.394-2.19 2.834-2.417a16.12 16.12 0 0 0-1.41-2.435l-3.513 1.246-1.7-1.44a12.356 12.356 0 0 0-3.815-2.207l-2.097-.753-.677-3.66a16.386 16.386 0 0 0-2.814 0l-.676 3.66-2.097.753a12.356 12.356 0 0 0-3.815 2.206l-1.7 1.441-3.514-1.246a16.12 16.12 0 0 0-1.41 2.435l2.834 2.417-.394 2.19a12.473 12.473 0 0 0 0 4.418l.394 2.19Z" fill="#000" />
  </StyledIcon>
))

TaskTypeCog100.displayName = 'TaskTypeCog100'

export { TaskTypeCog100 }
