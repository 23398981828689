import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const BrandFinancialServices = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 100 100" a11yTitle="BrandFinancialServices" {...props}>
    <g clipPath="url(#a)" fill="#fff"><path d="M100 0v29.333H0V0z" /><path opacity=".5" d="M64.666 35.334v29.333H0V35.334zM29.334 70.666V100H0V70.666z" /></g>
  </StyledIcon>
))

BrandFinancialServices.displayName = 'BrandFinancialServices'

export { BrandFinancialServices }
