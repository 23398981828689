import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Server = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Server" {...props}>
    <path d="M34 11a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm-8 0a3 3 0 1 0 0 6 3 3 0 0 0 0-6Z" fill="#000" /><path d="M6 12a6 6 0 0 1 6-6h24a6 6 0 0 1 6 6v4a6 6 0 0 1-6 6H12a6 6 0 0 1-6-6v-4Zm6-3a3 3 0 0 0-3 3v4a3 3 0 0 0 3 3h24a3 3 0 0 0 3-3v-4a3 3 0 0 0-3-3H12Zm19 25a3 3 0 1 1 6 0 3 3 0 0 1-6 0Zm-8 0a3 3 0 1 1 6 0 3 3 0 0 1-6 0Z" fill="#000" /><path d="M6 32a6 6 0 0 1 6-6h24a6 6 0 0 1 6 6v4a6 6 0 0 1-6 6H12a6 6 0 0 1-6-6v-4Zm6-3a3 3 0 0 0-3 3v4a3 3 0 0 0 3 3h24a3 3 0 0 0 3-3v-4a3 3 0 0 0-3-3H12Z" fill="#000" />
  </StyledIcon>
))

Server.displayName = 'Server'

export { Server }
