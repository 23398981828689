// @ts-nocheck
import styled, { css } from 'styled-components'
import { colors, px2rem } from 'app/Styles/Variables'

interface INavWrapper {
  active: boolean
}

const NavWrapper = styled.div`
  position: relative;
  ${(props: INavWrapper) => {
    if (props.active) {
      return css`
        ::before {
          content: '';
          display: block;
          width: 0;
          height: 0;
          border: inset ${px2rem(10)};
          border-color: transparent ${colors.primaryGreyHoverBackgroundColor} transparent transparent;
          border-right-style: solid;
          top: ${px2rem(22)};
          position: absolute;
          right: ${px2rem(-1)};
          left: auto;
          margin-left: 0;
        }
        ::after {
          content: '';
          display: block;
          width: 0;
          height: 0;
          border: inset ${px2rem(8)};
          border-color: transparent ${colors.white} transparent transparent;
          border-right-style: solid;
          top: ${px2rem(24)};
          position: absolute;
          right: ${px2rem(-1)};
          left: auto;
          margin-left: 0;
        }
      `
    }
  }};
`

export const SectionNavWrapper = ({ active, children }) => <NavWrapper active={active}>{children}</NavWrapper>
