import { ReactNode } from 'react'

import { LegacyBox as Box, Button, colors, LegacyFlex as Flex, Heading, IconButton } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'

type SchedulePanelHeaderProps = {
  isDirty: boolean
  onBack?: () => void
  onCancel?: () => void
  onClose: () => void
  onSave?: () => void
  title: string
  onAdd?: () => void
}
const SchedulePanelHeader = ({
  isDirty,
  onBack,
  onCancel,
  onClose,
  onSave,
  title,
  onAdd
}: SchedulePanelHeaderProps) => {
  const { t } = useLanguage()

  return (
    <Box
      padding={16}
      height={64}
      css={`
        border-bottom: 1px solid ${colors.primaryGreyBackgroundColor};
      `}
    >
      <Flex justifyContent="space-between" alignItems="center" height="100%">
        <Flex gap={2} alignItems="center">
          {!!onBack && (
            <IconButton
              tertiary
              label={t('common:back')}
              icon="arrow-back"
              onClick={onBack}
              data-testid="back-to-schedule-panel-button"
            />
          )}

          <Heading
            as="h3"
            weight={700}
            css={`
              font-size: 18px;
            `}
          >
            {title}
          </Heading>
        </Flex>

        <Flex>
          {!!onAdd && (
            <IconButton
              tertiary
              label={t('dashboard:shareSchedulePanel:addSchedule')}
              icon="add"
              onClick={onAdd}
              data-testid="add-managed-schedule-button"
            />
          )}
          {isDirty && !!onCancel && !!onSave ? (
            <>
              <Button tertiary label={t('common:resetButton')} onClick={onCancel} />
              <Button primary label={t('common:saveButton')} type="submit" onClick={onSave} />
            </>
          ) : (
            <IconButton
              tertiary
              label={t('common:close')}
              icon="close"
              onClick={onClose}
              data-testid="close-managed-schedule-panel-button"
            />
          )}
        </Flex>
      </Flex>
    </Box>
  )
}

export const SchedulePanelContent = ({ children, ...props }: { children: ReactNode } & SchedulePanelHeaderProps) => (
  <>
    <SchedulePanelHeader {...props} />
    <Box padding={16} css="overflow-y: scroll" data-testid="scheduled-list-panel">
      {children}
    </Box>
  </>
)
