// @ts-nocheck
import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { observe } from 'mobx'
import { colors } from 'app/Styles/Variables'
import { withPresenter } from 'app/Config/withPresenter'
import { observer } from 'mobx-react-lite'
import { Icon } from 'Components/Atoms'
import { Accordion, ToolTip } from 'Components/Molecules'
import { SavedFiltersPresenter } from './SavedFiltersPresenter'

const FlexContainer = styled.div`
  border-right: ${(props: IProps) => {
    if (props.isFilter) {
      return 'solid 3px #2A55C3'
    }
  }};
  background-color: ${(props: IProps) => {
    if (props.isFilter) {
      return colors.bgLightest
    }
  }};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 0px 2px 8px;
`

const FlexLeft = styled.div`
  color: ${colors.textLighter};
  padding-top: 6px;
  padding-bottom: 6px;
  flex: 1;
`

const FlexRight = styled.div`
  width: 40px;
`

const Link = styled.a`
  display: inline-block;
  width: 100%;
  color: ${colors.textLighter};
  &:hover {
    color: ${colors.dark};
  }
`

const DeleteIcon = styled.div`
  color: ${colors.textLighter};
  cursor: pointer;
  position: relative;
  width: 20px;
  margin: auto !important;
  &:hover {
    color: ${colors.dark};
  }
`

interface IProps {
  isFilter: boolean
}

interface ISavedFiltersProps {
  presenter: SavedFiltersPresenter
}

const SaveFiltersComponent = observer<ISavedFiltersProps>(props => {
  const { filters, deleteFilter, accordionPresenter, language, router, filtersRepository, routeUpdater, routingState } =
    props.presenter
  const currentQuery = window.location.hash.split('#')[1].split('?')[1]
  const [currentUrlQuery, setCurrentUrlQuery] = useState(currentQuery)

  useEffect(() => {
    const dispose = observe(routingState.currentState, 'query', routerQuery => {
      setCurrentUrlQuery(routerQuery.newValue)
    })
    return () => dispose()
  }, [])

  const decodeHtml = (rawHTML: string) => {
    let parser = new DOMParser()
    let dom = parser.parseFromString(rawHTML, 'text/html')
    return dom.body.textContent
  }

  return (
    <div>
      {filtersRepository.savedFilters.length > 0 && (
        <Accordion
          extraStyles={{ paddingLeft: 5, paddingRight: 0, paddingBottom: 20, paddingTop: 5 }}
          presenter={accordionPresenter}
        >
          <div>
            {filters.map((filterVm, index) => {
              let searchPath =
                routeUpdater.findRoute(routingState.currentState.routeId).routeDef.path + '?' + filterVm.urlQuery
              let isFilter = currentUrlQuery === filterVm.urlQuery
              return (
                <FlexContainer key={index} isFilter={isFilter}>
                  <FlexLeft>
                    <Link
                      href={window.location.origin + '/#' + searchPath}
                      onClick={e => {
                        e.preventDefault()
                        const currentParamsFromRoute = routingState.currentState.params
                        router.changePageWithoutCheck('users', currentParamsFromRoute, filterVm.urlQuery)
                      }}
                    >
                      {decodeHtml(filterVm.name)}
                    </Link>
                  </FlexLeft>
                  <FlexRight>
                    <ToolTip
                      handlerType={'mouseenter'}
                      message={language.get('saveFilters:deleteFilterTooltip')}
                      trigger={
                        <DeleteIcon>
                          <Icon
                            name={'close'}
                            onClick={() => {
                              deleteFilter(filterVm.id)
                            }}
                          />
                        </DeleteIcon>
                      }
                      position={'top center'}
                      oneLineTooltip={true}
                    />
                  </FlexRight>
                </FlexContainer>
              )
            })}
          </div>
        </Accordion>
      )}
    </div>
  )
})

export default withPresenter(SavedFiltersPresenter)(SaveFiltersComponent)
