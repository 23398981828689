import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const UserEdit = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="UserEdit" {...props}>
    <path d="M24 16a7 7 0 1 1-14 0 7 7 0 0 1 14 0Zm-3 0a4 4 0 1 0-8 0 4 4 0 0 0 8 0Zm7.959 21c.027.33.041.663.041 1v2H7a2 2 0 0 1-2-2c0-.337.014-.67.041-1C5.55 30.84 10.71 26 17 26c.337 0 .67.014 1 .041v3.014A9.001 9.001 0 0 0 8.055 37h20.904Z" fill="#000" /><path d="M27.395 34H21v-6.395L39.605 9 46 15.395 27.395 34Zm-3.38-5.146v2.131h2.131l15.59-15.59-2.13-2.132-15.591 15.59Z" fill="#000" />
  </StyledIcon>
))

UserEdit.displayName = 'UserEdit'

export { UserEdit }
