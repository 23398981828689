import { AccordionPanel, DateTimePicker, TextInput } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'

type TaskOtherDetailsProps = {
  iconColor: string
  createdBy?: string
  createdAt: number
  updatedAt: number
  timezone: string | null
}

export const TaskOtherDetails = ({ iconColor, createdBy, createdAt, updatedAt, timezone }: TaskOtherDetailsProps) => {
  const { t } = useLanguage('tasks', { keyPrefix: 'editPanel.otherDetails' })

  return (
    <AccordionPanel label={t('accordionLabel')} iconColor={iconColor} icon="info">
      <TextInput label={t('createdBy')} value={createdBy} readOnly />
      <DateTimePicker
        label={`${t('createdAt')}`}
        timezone={timezone}
        value={new Date(createdAt * 1000)}
        readOnly
        onChange={() => {}}
      />
      <DateTimePicker
        label={`${t('updatedAt')}`}
        timezone={timezone}
        value={new Date(updatedAt * 1000)}
        readOnly
        onChange={() => {}}
      />
    </AccordionPanel>
  )
}
