// @ts-nocheck
import { injectable } from 'inversify'
import { container } from 'app/Config/IOC'
import { computed, observable } from 'mobx'
import { RunbookUsersViewPresenter } from '../RunbookUsersViewPresenter'
import { RunbookUsersPageHeadingOptionsPresenter } from './RunbookUsersPageHeadingOptionsPresenter'
import { Types } from 'Gateways/Service/Types'
import { PillFilterPresenter, SearchBoxPresenter } from 'Components/Molecules'
import { ILanguageService } from 'Helpers/Language/ILanguageService'

@injectable()
export class RunbookUsersPageHeadingPresenter {
  private runbookUsersViewPresenter: RunbookUsersViewPresenter = container.get(RunbookUsersViewPresenter)

  @observable
  public language: ILanguageService = container.get(Types.ILanguageService)

  @observable
  public searchBoxPresenter = new SearchBoxPresenter()

  @observable
  public searchPillPresenter = new PillFilterPresenter({ type: 'single', key: { reference: 'query' } }).withTitle(
    this.language.get('runbookUsers:filters:search:title')
  )

  @observable
  public optionsPresenter: RunbookUsersPageHeadingOptionsPresenter = new RunbookUsersPageHeadingOptionsPresenter()

  @computed
  public get totalUserCount(): number {
    return this.runbookUsersViewPresenter.listTotal
  }

  @computed
  public get filteredUserCount(): number {
    return this.runbookUsersViewPresenter.filteredListTotal
  }

  @computed
  public get title(): string {
    if (!this.runbookUsersViewPresenter.runbookTemplateType) {
      return null
    }

    return this.runbookUsersViewPresenter.runbookTemplateType === 'snippet'
      ? this.language.get('runbookUsers:heading:snippetTitle')
      : this.language.get('runbookUsers:heading:title')
  }
}
