import { useContext } from 'react'

import { GlobalConfigContext } from 'main/context/global-config-provider'
import { GlobalConfigType } from 'main/services/hooks'

export const useGetConfig = () => useContext(GlobalConfigContext)

export function useIsFeatureEnabled(feature: GlobalConfigType['enabledFeatures'][number]) {
  const globalConfig = useContext(GlobalConfigContext)
  return globalConfig.enabledFeatures.includes(feature)
}
