import { Simplify } from 'type-fest'

import {
  CollectionModelInterfaceBase,
  ModelCollectionAccessScope,
  ModelSingleAccessBy,
  CollectionModelIntefaceWithLookupLoadable as WithLookupLoadable,
  CollectionModelInterfaceWithSingleAccessBy as WithSingleAccessBy
} from './types'
import { Dashboard } from 'main/services/queries/types'
import {
  useGetAllDashboards,
  useGetAllDashboardsCallback,
  useGetDashboard,
  useGetDashboardBy,
  useGetDashboardByCallback,
  useGetDashboardCallback,
  useGetDashboardLookup,
  useGetDashboardLookupCallback,
  useGetDashboardLookupLoadable
} from '../hooks'

type DashboardModelSingleAccessBy = ModelSingleAccessBy<Pick<Dashboard, 'key'>>
type StreamCollectionAccessScope = ModelCollectionAccessScope<'pages' | 'dashboard'>
export type DashboardModelType = Simplify<
  CollectionModelInterfaceBase<Dashboard, StreamCollectionAccessScope, 'id' | 'key'> &
    WithLookupLoadable<Dashboard> &
    WithSingleAccessBy<Dashboard, DashboardModelSingleAccessBy>
>

export const DashboardModel: DashboardModelType = {
  useGet: useGetDashboard,
  useGetCallback: useGetDashboardCallback,

  useGetBy: useGetDashboardBy,
  useGetByCallback: useGetDashboardByCallback,

  useGetAll: useGetAllDashboards,
  useGetAllCallback: useGetAllDashboardsCallback,

  useGetLookup: useGetDashboardLookup,
  useGetLookupCallback: useGetDashboardLookupCallback,
  useGetLookupLoadable: useGetDashboardLookupLoadable
}
