import { keyBy } from 'lodash'
import { selector, selectorFamily } from 'recoil'

import { accountMetaState } from './account'
import { Dashboard } from 'main/services/queries/types'

export const dashboardsState = selector<Dashboard[]>({
  key: 'dashboards',
  get: ({ get }) => get(accountMetaState).dashboards
})

export const dashboardsLookupState = selector<Record<number, Dashboard>>({
  key: 'dashboards:lookup:byId',
  get: ({ get }) => keyBy(get(accountMetaState).dashboards, 'id')
})

export const dashboardsLookupByKeyState = selector<Record<string, Dashboard>>({
  key: 'dashboards:lookup:byKey',
  get: ({ get }) => keyBy(get(accountMetaState).dashboards, 'key')
})

export const dashboardState = selectorFamily<Dashboard | undefined, number | undefined>({
  key: 'dashboard:id',
  get:
    (id?: number) =>
    ({ get }) => {
      if (!id) return
      return get(dashboardsLookupState)[id]
    }
})
