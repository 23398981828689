import { getUnixTime, isDate } from 'date-fns'

import { getFilterSelections } from './get-filter-selections'
import { FilterGroup, FilterOption, FilterOptionCheckbox, SelectedFilters } from '../filter-types'

// TODO: refactor this so it's a switch on the type of filter i.e. late: boolean, team: array etc
// to make it less horrible
const processFilterChange = (
  selected: SelectedFilters = {},
  filter: FilterGroup,
  option?: FilterOption,
  // Temporary new argument while refactoring ongoing. Overrides logic here with new value from component
  override?: string[] | string | number | number[]
): SelectedFilters => {
  const selections = getFilterSelections(selected, filter, option)

  switch (filter.type) {
    // TODO refactor to switch on slug/RunbooksFilter rather than filter type
    case 'hierarchy':
      return { ...selected, [filter.slug as string]: override }
    case 'checkbox':
    case 'select':
      if (!option) break
      if (filter.customFieldId && filter.slug) {
        const isSelected = (selections || []).includes(option.value)
        const newSelections = isSelected
          ? (selections as string[]).filter(s => s !== option.value)
          : [...(selections || []), option.value]
        const cfs = { ...((selected[filter.slug] as Object) || {}), [filter.customFieldId]: newSelections }
        return { ...selected, [filter.slug]: cfs } as SelectedFilters
      }

      if (option.slug) {
        const { options } = option as FilterOptionCheckbox

        if (options && options) {
          return { ...selected, [option.slug]: option.value } as SelectedFilters
        }

        const isSelected = selections !== undefined
        return { ...selected, [option.slug]: isSelected ? undefined : option.value } as SelectedFilters
      }

      if (filter.slug) {
        const selection = typeof selections === 'string' ? [selections] : selections
        const isSelected = (selection || []).includes(option.value)
        const newSelections = isSelected
          ? (selection as string[]).filter(s => s !== option.value)
          : [...(selection || []), option.value]
        return { ...selected, [filter.slug]: newSelections }
      }

      break

    case 'date':
      if (!option) break
      if (filter.customFieldId && filter.slug) {
        let newSelections
        if (option.value === '*' || option.value === 0) {
          newSelections = selections === option.value ? undefined : option.value
        } else {
          const indexOfOption = filter.options.findIndex(o => o.label === option.label)
          const dateOptions = Array.isArray(selections) ? selections : new Array(filter.options.length).fill(null)
          newSelections = dateOptions.map((o, i) => {
            if (i === indexOfOption) return option.value || null
            return o
          })
          if (newSelections.filter(Boolean).length === 0) newSelections = undefined
        }
        const cfs = { ...((selected[filter.slug] as Object) || {}), [filter.customFieldId]: newSelections }
        return { ...selected, [filter.slug]: cfs } as SelectedFilters
      }

      if (option.slug) {
        return {
          ...selected,
          [option.slug]: option.value ? getUnixTime(option.value as Date) : undefined
        }
      }
      break

    case 'text':
      if (!option) break
      if (!filter.customFieldId || !filter.slug) return selected
      let newValue = option.value

      if (newValue === '*' || newValue === 0) {
        newValue = selections === newValue ? undefined : newValue
      }
      const cfs = { ...((selected[filter.slug] as Object) || {}), [filter.customFieldId]: newValue }
      return { ...selected, [filter.slug]: cfs } as SelectedFilters
  }

  return selected
}

export function removeUnusedProperties(obj: SelectedFilters) {
  for (var key in obj) {
    if (obj[key] === undefined) {
      delete obj[key]
      continue
    }
    if (obj[key] && typeof obj[key] === 'object' && !isDate(obj[key])) {
      removeUnusedProperties(obj[key] as SelectedFilters)
      if (!Object.keys(obj[key] as string).length) {
        delete obj[key]
      }
    }
  }
  return obj
}

export const handleFilterChange = (
  selected: SelectedFilters = {},
  filter: FilterGroup,
  option?: FilterOption,
  override?: string[] | string | number[] | number
) => {
  const newFilters = filter ? processFilterChange(selected, filter, option, override) : selected
  removeUnusedProperties(newFilters)
  return newFilters
}
