import { forwardRef } from 'react'
import { Box as GrommetBox, BoxProps as GrommetBoxProps } from 'grommet'
import styled from 'styled-components/macro'
import { Except, LiteralUnion, SetRequired } from 'type-fest'

import { resolveColor, ThemeType, transformBackgroundType, transformBorderType, useTheme } from '../../theme'
import { BoxProps as BaseBoxProps } from '../../theme/types'

export type BoxProps = Except<BaseBoxProps, 'gap'> & {
  // type this as an edge size and look up its pixel value in via the theme
  gap?: LiteralUnion<keyof SetRequired<ThemeType['global'], 'edgeSize'>['edgeSize'], string> // could create a {custom: string} type so its not completely restrictive
}

// This would be a new Box component that supports named theme vales e.g., "primary", "small".
// Color related props support hinting. our current box in the app space currently.
// Could create a compatibility layer and call that LegacyBox or something...
export const Box = forwardRef<HTMLElement, BoxProps>(({ responsive = false, ...boxProps }, ref) => {
  const theme = useTheme()

  return <StyledBox ref={ref} {...transformBoxProps({ responsive, ...boxProps }, theme)} />
})

const StyledBox = styled(GrommetBox)`
  outline: none;
  &:focus-visible {
    outline: none;
    box-shadow: ${({ theme }) =>
      `0px 0px 0px 2px ${resolveColor('bg', theme)}, 0px 0px 0px 4px ${resolveColor('primary', theme)}`};
  }
`

function transformBoxProps({ border, background, ...restBoxProps }: BoxProps, theme: ThemeType): GrommetBoxProps {
  const overrides: Pick<GrommetBoxProps, 'background' | 'border'> = {}

  if (border) {
    overrides.border = transformBorderType(border, theme)
  }

  if (background) {
    overrides.background = transformBackgroundType(background, theme)
  }

  return {
    ...restBoxProps,
    ...overrides
  }
}
