// @ts-nocheck
import { observer } from 'mobx-react-lite'
import { RolePresenter } from './RolePresenter'
import styled from 'styled-components'
import { Checkbox } from 'Components/Molecules'

const CheckboxWrapper = styled.div`
  position: relative;
`

interface IRoleProps {
  presenter: RolePresenter
}

export const Role = observer<IRoleProps>(({ presenter }) => {
  const { checkboxPresenter } = presenter

  return (
    <CheckboxWrapper>
      <Checkbox presenter={checkboxPresenter} />
    </CheckboxWrapper>
  )
})
