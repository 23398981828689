import { createTaskFilterFunction, RunbookFilterType, TaskFilterContext } from './filters'
import { TaskListTask } from 'main/services/queries/types'

export const milestone = createTaskFilterFunction(
  'm',
  (task: TaskListTask, filters: RunbookFilterType, context: TaskFilterContext): boolean => {
    if (Object.prototype.hasOwnProperty.call(context, 'milestoneId')) {
      return filters.m ? task.task_type_id === context.milestoneId : task.task_type_id !== context.milestoneId
    }
    // It's a very edge case, but is it ok to return false (=no tasks)
    // if the milestone type id is not provided?
    return false
  }
)
