import * as Sentry from '@sentry/react'
import { useQuery, UseQueryOptions } from 'react-query'

import { toCamelCase } from '@cutover/api'
import { apiClient_UNSTABLE } from 'main/services/api'
import { queryClient as staticQueryClient } from 'main/query-client'
import { FeatureFlag, GlobalConfigResponse, GlobalConfigType } from 'main/services/hooks'

export function useGetConfigs(opts: UseQueryOptions<GlobalConfigType, Error> = {}) {
  return useQuery<GlobalConfigType, Error, GlobalConfigType>('configs', getConfigs, {
    staleTime: Infinity,
    cacheTime: Infinity,
    ...opts
  })
}

// Using with recoil will also populate the query client
export const fetchConfigQuery = async () => {
  return staticQueryClient.fetchQuery({
    queryKey: 'configs',
    queryFn: getConfigs,
    staleTime: Infinity,
    cacheTime: Infinity
  })
}

// feature flags included here will be added as tags in Sentry when enabled
const SENTRY_FEATURE_TAGS = ['react_runbook', 'react_workspace', 'react_login'] as FeatureFlag[]

// Setting the Sentry tags in this function so that it occurs regardless of whether the
// configs are accessed via recoil or the hook wrapping react-query. This is only called
// once on app load by one or the other.
const getConfigs = async () => {
  // TODO remove case conversion, it's an antipattern and we should use whatever the
  // backend returns.
  // Currently we made this a special case where the type is converted keys to cameCase. We didn't
  // consider at the time that some keys actually represent frontend values, like url params, level_1, etc.
  const { data } = toCamelCase(await apiClient_UNSTABLE.get<GlobalConfigResponse>('configs'))

  try {
    const { enabledFeatures } = data

    SENTRY_FEATURE_TAGS.forEach(feature => {
      // we don't want to set anything when false to avoid cluttering the tag list
      if (enabledFeatures.includes(feature)) {
        Sentry.setTag(feature, true)
      }
    })
  } catch (e) {
    console.warn('error setting config tags', e)
  }
  return data
}
