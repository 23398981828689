import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const ChevronRight = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="ChevronRight" {...props}>
    <path d="m18.121 9 14.8 14.799-14.8 14.799L16 36.477l12.678-12.678L16 11.121 18.121 9Z" fill="#000" />
  </StyledIcon>
))

ChevronRight.displayName = 'ChevronRight'

export { ChevronRight }
