import { FolderListFolder } from 'main/services/queries/types'

export function getFolderAxis(initialProjects: FolderListFolder[]) {
  const projects = initialProjects as (FolderListFolder & { pad: boolean; children: FolderListFolder[] })[]
  //copy project processing logic from angular runbooks_model.js.
  //To be moved to a global transformer rather than here just for timeline

  // We want a resulting array that looks like:
  // A parent
  //  1 child of A
  //  2 child of A
  // B another parent
  // C another parent
  //  child of C
  // we also want to nest the children in a children array
  // We need both linear and nested for the FE currently. Could be done on API

  //Step 1 - order the whole array alphabetically
  projects.sort(function (a, b) {
    var A = a.name.toLowerCase()
    var B = b.name.toLowerCase()
    if (A < B) {
      return -1
    } else if (A > B) {
      return 1
    } else {
      return 0
    }
  })

  //step 2 - prepare object
  for (var i = 0; i < projects.length; i++) {
    projects[i].children = []
    projects[i].pad = false
  }

  //Step 3 - create an new Array, grabbing each parent then its children
  var sortedHierarchy = []
  for (i = 0; i < projects.length; i++) {
    if (!projects[i].parent_id) {
      //top level
      sortedHierarchy.push(projects[i])
      //Now, find its children
      for (var c = 0; c < projects.length; c++) {
        if (projects[c].parent_id === projects[i].id) {
          projects[c].pad = true
          sortedHierarchy.push(projects[c])
          projects[i].children.push(projects[c])
        }
      }
    }
  }

  return {
    name: 'Folder',
    dataType: 'discrete',
    values: sortedHierarchy,
    attribute: 'project_id'
  }
}

export function getStatusAxis() {
  return {
    name: 'Status',
    dataType: 'discrete',
    attribute: 'status',
    values: [
      {
        id: 'off' as const,
        slug: 'off' as const,
        name: 'Off' as const,
        label: 'Off' as const,
        order: 1,
        color: 'rgb(216,221,223)'
      },
      {
        id: 'red' as const,
        slug: 'red' as const,
        name: 'Red' as const,
        label: 'Red' as const,
        order: 2,
        color: 'rgba(255,64,64,1)'
      },
      {
        id: 'amber' as const,
        slug: 'amber' as const,
        name: 'Amber' as const,
        label: 'Amber' as const,
        order: 3,
        color: 'rgba(255,170,21,1)'
      },
      {
        id: 'green' as const,
        slug: 'green' as const,
        name: 'Green' as const,
        label: 'Green' as const,
        order: 4,
        color: 'rgba(0,199,129,1)'
      }
    ]
  }
}

export function getTimeAxis() {
  return { name: 'Time', dataType: 'time', attributeStart: 'start_display', attributeEnd: 'end_display' }
}
