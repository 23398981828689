import { toCamelCase } from '@cutover/api'
import { AppsPayload } from './apps-types'
import { apiClient } from 'main/services/api'
import { RunbookIntegration, RunbookIntegrationActionItem } from 'main/services/queries/types'
import {
  RunbookIntegrationsResponse,
  RunbookIntegrationsResponseSnakeCase
} from 'main/services/queries/use-runbook-integrations'
import { useSetAppsConnectionLost } from 'main/recoil/data-access'

export type AvailableIntegrationsResponse = {
  integration_links: RunbookIntegration[]
}

type IntegrationActionItemResponse = {
  integration_action_item: RunbookIntegrationActionItem
}

export const usePostAppEvents = () => {
  const setConnectionLost = useSetAppsConnectionLost()

  return async (payload: AppsPayload) => {
    try {
      const data = await apiClient.post({
        url: 'app_events',
        data: { ...payload, session_id: renderBrowserHashFromStorage() }
      })
      return data
    } catch {
      setConnectionLost(true)
    }
  }
}

export function renderBrowserHashFromStorage() {
  let browserHash = window.sessionStorage.getItem('browserHash')

  if (!browserHash) {
    browserHash = Math.random().toString(14).slice(2)
    window.sessionStorage.setItem('browserHash', browserHash)
  }

  return browserHash
}

export const getAvailableIntegrations = async (runbookId: string): Promise<RunbookIntegrationsResponse> => {
  // TODO refactor. Converting case is not a pattern that should be copied.
  // Favour the snake case response that is returned from the API
  const { data } = toCamelCase(
    await apiClient.get<RunbookIntegrationsResponseSnakeCase>({
      url: `runbooks/${runbookId}/runbook_versions/current_version/integrations`
    })
  )

  // This type conversion shows an underlying issue with our type declarations.
  // We blanket converse all keys to camel case, but should be more specific, eg Custom Fields
  // are using snake case whereas RunbookIntegration uses camel case.
  // This has been how it's worked up till now, but this should be properly typed in favour
  // of snake case everywhere for consistency.
  return data as unknown as RunbookIntegrationsResponse
}

export const getIntegrationActionItem = async (
  integrationActionItemId: string
): Promise<IntegrationActionItemResponse> => {
  const { data } = await apiClient.get<IntegrationActionItemResponse>({
    url: `integration_action_items/${integrationActionItemId}`
  })
  return data
}
