import { KeyboardEvent, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useCombobox } from 'downshift'
import debounce from 'debounce-promise'
import { matchSorter } from 'match-sorter'
import { useMergeRefs } from 'use-callback-ref'
import { isEqual } from 'lodash'
import { ThemeContext } from 'grommet'

import { MenuListItemHeader } from '../../../menu'
import { SelectBase } from './internal/select-base'
import { MultiSelectProps } from './multi-select'
import { SelectInputEndItems, SelectOptionListItem } from './shared-components'
import { ItemCreateInput } from '../../../item-create-input'
import { Box } from '../../../layout'
import { SelectListItem } from '../select-list-item'
import { useUpdateEffect } from '../../../utilities'

const DEFAULT_ASYNC_DEBOUNCE_TIME = 300

export type RenderSelectOptionProps = {
  selected?: boolean
  /** either via hover or keyboard -- doing one loses the other. */
  highlighted?: boolean
}

export type SelectProps<Option extends Record<any, any>, Value = Option> = {
  'data-testid'?: string
  clearable?: boolean
  debug?: boolean
  defaultValue?: Value
  headerKey?: string
  labelKey?: string
  loading?: boolean
  loadOptionsDebounceTime?: number
  noBottomPadding?: boolean
  onChange?: (value?: Value) => void
  onCreateOption?: (value: string) => void
  onKeyDown?: (e: KeyboardEvent) => void
  optionToString?: (option: Option) => string
  /** For full custom render of what appears in the dropdown list */
  renderOption?: (option: Option, renderProps: RenderSelectOptionProps) => ReactNode
  value?: Value
  valueFromString?: (value: string) => Value
  valueKey?: string
  valueToString?: (value: Value) => string
} & Omit<
  MultiSelectProps<Option>,
  'onChange' | 'renderOption' | 'value' | 'defaultValue' | 'data-testid' | 'valueKey' | 'labelKey'
>

type SelectInnerProps<Option extends Record<any, any>> = SelectProps<Option> & {
  defaultIndex?: number
}

export function Select<Option extends Record<any, any>, Value = Option>({
  defaultValue,
  valueKey = 'value',
  labelKey = 'label',
  headerKey = 'header',
  initialInputText,
  onChange,
  loadOptions,
  options,
  loadOptionsDebounceTime = DEFAULT_ASYNC_DEBOUNCE_TIME,
  ...props
}: SelectProps<Option, Value>) {
  const { value } = props
  const isAsync = !!loadOptions // async is always uncontrolled and always calls onChange with the entire value's option
  const isControlled = !isAsync && !defaultValue

  const getOptionValue = useCallback((option: Option) => option[valueKey], [valueKey])
  const getValueOption = useCallback((val: any) => options?.find(opt => getOptionValue(opt) === val), [options])
  const getValueIndex = useCallback((val: any) => options?.findIndex(opt => getOptionValue(opt) === val), [options])

  const initialValOption = useMemo(() => {
    if (!defaultValue && !value) return undefined

    const val = defaultValue ?? value

    if (options?.length) {
      if (typeof defaultValue === 'object') {
        return options.find(option => isEqual(option, defaultValue))
      } else {
        return options?.find(opt => getOptionValue(opt) === defaultValue)
      }
    } else {
      return val
    }
  }, [])

  const handleUncontrolledChange = useCallback(
    (uncontrolledVal?: Option) => {
      // passes back the whole option
      if (isAsync || !isControlled) {
        onChange?.(uncontrolledVal)
      } else {
        const nextVal = uncontrolledVal ? getOptionValue(uncontrolledVal) : null
        onChange?.(nextVal)
      }
    },
    [getOptionValue]
  )

  return (
    <SelectInner
      {...props}
      options={options}
      // @ts-ignore
      value={!isControlled ? undefined : value ? getValueOption(value) : null}
      // @ts-ignore
      defaultValue={initialValOption}
      onChange={handleUncontrolledChange}
      headerKey={headerKey}
      valueKey={valueKey}
      labelKey={labelKey}
      initialInputText={initialInputText}
      defaultIndex={getValueIndex(value)}
      loadOptions={loadOptions}
      loadOptionsDebounceTime={loadOptionsDebounceTime}
    />
  )
}

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
function SelectInner<Option extends Record<any, any>>({
  'data-testid': dataTestId,
  a11yTitle,
  autoFocus,
  debug,
  defaultIndex,
  defaultValue,
  disabled,
  filterKeys = ['label', 'name', 'description'], // ? is this appropriate for a default?
  hasError,
  headerKey = 'header',
  helpText: tooltipText,
  icon,
  inlineError,
  inputRef: externalInputRef,
  label,
  labelKey = 'label',
  loading: loadingExternal,
  loadOptions,
  loadOptionsDebounceTime = DEFAULT_ASYNC_DEBOUNCE_TIME,
  menuRoot,
  name,
  noBottomPadding,
  onBlur,
  onChange,
  onCreateOption,
  onInputChange,
  onKeyDown,
  options,
  optionToString,
  placeholder = 'Start typing...',
  readOnly,
  renderOption: customRenderFunction,
  required,
  truncate = true,
  value,
  ...props
}: SelectInnerProps<Option>) {
  const isAsync = !!loadOptions
  const minChars = props.minChars ?? (isAsync ? 1 : 0)
  const minCharsForEmptyMessage = minChars || 1
  const clearable = props.clearable === false || required ? false : true
  const [loading, setLoading] = useState(loadingExternal)
  const isLoading = loading || loadingExternal
  const [defaultAsyncOptions, setDefaultAsyncOptions] = useState<Option[] | undefined>(
    isAsync && defaultValue ? [defaultValue] : undefined
  )
  const emptyMessage = props.emptyMessage === null ? null : props.emptyMessage ?? 'No results found'
  const [canShowEmptyMessage, setCanShowEmptyMessage] = useState(false)
  const [inputItems, setInputItems] = useState(defaultAsyncOptions ?? options ?? [])
  const localInputRef = useRef<HTMLInputElement>(null)
  const inputRef = useMergeRefs(externalInputRef ? [externalInputRef, localInputRef] : [localInputRef])
  const loadingTimeoutRef = useRef<NodeJS.Timer | null>(null)

  const itemToString = (item: Option | null): string => {
    if (!item) return ''
    return optionToString ? optionToString?.(item) : item[labelKey] ?? ''
  }

  useUpdateEffect(() => {
    setInputItems(options ?? [])
  }, [options])

  const debouncedAsyncLoadOptionss = loadOptions
    ? debounce(loadOptions, loadOptionsDebounceTime)
    : () => Promise.resolve([])

  const loadAsyncOptions = useCallback(async (query: string) => {
    if (loadingTimeoutRef.current) clearTimeout(loadingTimeoutRef.current)

    const newOptions = await debouncedAsyncLoadOptionss(query)
    setInputItems(newOptions)

    if (query.length < minCharsForEmptyMessage) {
      setCanShowEmptyMessage(false)
    }

    loadingTimeoutRef.current = setTimeout(() => {
      setLoading(false)

      if (!newOptions.length) {
        setCanShowEmptyMessage(true)
      }
    }, loadOptionsDebounceTime - 10)
    return newOptions
  }, [])

  useEffect(() => {
    if (loadingExternal !== loading) setLoading(loadingExternal ?? false)
  }, [loadingExternal])

  const {
    isOpen,
    selectItem,
    getLabelProps,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    closeMenu,
    selectedItem,
    inputValue,
    setInputValue
  } = useCombobox({
    selectedItem: value,
    items: inputItems,
    // NOTE: defaultHighlightedIndex is fix for https://cutover.atlassian.net/browse/CFE-1761.
    //       We might not need it after downshift is upgrated to v9.
    defaultHighlightedIndex: defaultIndex,
    defaultSelectedItem: defaultValue,
    initialSelectedItem: defaultValue,
    selectedItemChanged: (selectedItem, prevSelectedItem) => {
      const hasChanged = selectedItem !== prevSelectedItem

      if (isAsync) {
        setInputItems(selectedItem ? [selectedItem] : [])
      }

      return hasChanged
    },
    itemToString,
    onSelectedItemChange: changes => {
      const { selectedItem } = changes
      const nextItem = selectedItem

      onChange?.(nextItem ?? undefined)

      if (isAsync) {
        setDefaultAsyncOptions(selectedItem ? [selectedItem] : undefined)
        setInputItems(selectedItem ? [selectedItem] : [])
      }
      inputRef.current?.blur()
    },

    onInputValueChange: ({ inputValue = '', isOpen }) => {
      onInputChange?.(inputValue)

      // TODO: perhaps cleanup logic
      if (!isAsync) {
        if (isOpen) {
          if (inputValue.length >= minCharsForEmptyMessage) {
            setCanShowEmptyMessage(true)
            const filteredItems = matchSorter(options ?? [], inputValue, {
              keys: filterKeys,
              threshold: matchSorter.rankings.CONTAINS
            })

            setInputItems(filteredItems)
          } else {
            setInputItems(options ?? [])
          }
        } else {
          setInputItems(options ?? [])
        }
      } else if (inputValue.length >= minChars) {
        setLoading(true)
        loadAsyncOptions(inputValue)
      } else {
        setCanShowEmptyMessage(false)
        setInputItems([])
      }
    },

    onIsOpenChange: ({ isOpen, selectedItem }) => {
      if (!isAsync) return

      if (!isOpen) {
        setInputItems(selectedItem ? [selectedItem] : [])
      }
    },
    // Reducers MUST STAY PURE: There may be nothing referenced from outside this function
    stateReducer: (state, actionAndChanges) => {
      const { changes: proposedChanges, type } = actionAndChanges

      const nextHighlightedIndex =
        proposedChanges.highlightedIndex === -1
          ? state.highlightedIndex === -1
            ? 0
            : state.highlightedIndex
          : proposedChanges.highlightedIndex

      let result = {
        ...proposedChanges,
        highlightedIndex: nextHighlightedIndex,
        isOpen:
          disabled || readOnly || (isAsync && (proposedChanges.inputValue ?? '').length < minChars)
            ? false
            : proposedChanges.isOpen
      }

      switch (type) {
        case useCombobox.stateChangeTypes.InputFocus:
          result = {
            ...result,
            isOpen: !isAsync && proposedChanges.isOpen && !disabled && !readOnly,
            inputValue: disabled || readOnly ? state.inputValue : ''
          }
          break
        case useCombobox.stateChangeTypes.FunctionOpenMenu:
          result = {
            ...result,
            isOpen: proposedChanges.isOpen && !disabled && !readOnly,
            inputValue: disabled || readOnly ? state.inputValue : ''
          }
          break
        case useCombobox.stateChangeTypes.InputBlur:
          result = {
            ...result,
            highlightedIndex: proposedChanges.isOpen ? nextHighlightedIndex : -1,
            isOpen: false,
            // clicking tab to blur updates the selected item to the highlighted index for some reason, so this and the selectedItem value set prevent that from happening
            inputValue:
              state.isOpen || isAsync
                ? itemToString(state.selectedItem)
                : proposedChanges.selectedItem
                ? itemToString(proposedChanges.selectedItem)
                : '',
            selectedItem:
              !state.selectedItem && proposedChanges.selectedItem ? state.selectedItem : proposedChanges.selectedItem
          }
          break
        case useCombobox.stateChangeTypes.FunctionCloseMenu:
          result = {
            ...result,
            inputValue: isAsync
              ? proposedChanges.inputValue
              : proposedChanges.selectedItem
              ? itemToString(proposedChanges.selectedItem)
              : ''
          }
          break
        case useCombobox.stateChangeTypes.ControlledPropUpdatedSelectedItem:
          result = {
            ...result,
            isOpen: false
          }
          break
      }

      if (debug && !type.startsWith('__item_mouse') && !type.startsWith('__menu_mouse')) {
        console.group(type)
        console.table(result)
        console.groupEnd()
      }

      return result
    }
  })

  const renderOption = useCallback(
    (option: Option, renderProps: RenderSelectOptionProps) => {
      return (
        customRenderFunction?.(option, renderProps) ?? (
          <SelectListItem
            prefix={option.icon}
            label={option[labelKey]}
            selected={!!renderProps.selected}
            highlighted={!!renderProps.highlighted}
            level={option.level}
            disabled={option.disabled}
          />
        )
      )
    },
    [customRenderFunction, labelKey]
  )

  const handleClickClear = useCallback(() => {
    onChange?.(undefined)
    selectItem(null)
    setInputValue('')
    inputRef.current?.blur()
  }, [onChange])

  const itemsToShowInList = isAsync ? inputItems.filter(option => option !== selectedItem) : inputItems

  return (
    <ThemeContext.Extend value={{ global: { drop: { zIndex: 10001 } } }}>
      <SelectBase
        ref={inputRef}
        menuRoot={menuRoot}
        isOpen={!disabled && !readOnly && isOpen}
        inputValue={inputValue}
        disabled={disabled}
        value={selectedItem}
        data-testid={dataTestId}
        getMenuProps={ref => getMenuProps({ ref })}
        onKeyDown={onKeyDown}
        getInputProps={ref =>
          getInputProps({
            placeholder,
            readOnly,
            required,
            ref,
            disabled,
            autoFocus,
            name,
            onBlur: e => {
              if (isOpen) closeMenu()

              if (isAsync && selectedItem) {
                setInputItems([selectedItem])
                setInputValue(itemToString(selectedItem))
              }

              onBlur?.(e)
            },
            'aria-label': a11yTitle ?? label
          })
        }
        icon={icon}
        label={label}
        hasError={hasError}
        noBottomPadding={noBottomPadding}
        inlineError={inlineError}
        tooltipText={tooltipText}
        a11yTitle={a11yTitle}
        shrinkLabel={!!selectedItem}
        labelProps={getLabelProps({
          'aria-label': a11yTitle ?? label
        })}
        truncate={truncate}
        onCreateOption={onCreateOption}
        endComponent={
          <SelectInputEndItems
            clearable={clearable}
            onClickClear={handleClickClear}
            isLoading={isLoading}
            disabled={disabled}
            readOnly={readOnly}
            hasInputValue={!!inputValue}
          />
        }
      >
        {itemsToShowInList.length ? (
          itemsToShowInList.map((option, index) =>
            option[headerKey] ? (
              <MenuListItemHeader
                key={option[headerKey]}
                label={option[headerKey]}
                data-testid={`select-list-item-header-${option[headerKey]}`}
              />
            ) : (
              <SelectOptionListItem
                {...getItemProps({
                  item: option,
                  index,
                  isSelected: selectedItem === option,
                  disabled: option.disabled
                })}
                data-testid={`select-list-item-option-${option[labelKey]}`}
                key={`${option[labelKey]}-${index}`}
                a11yTitle={optionToString?.(option) ?? option[labelKey] ?? undefined}
              >
                {renderOption(option, {
                  selected: isEqual(option, selectedItem),
                  highlighted: index === highlightedIndex
                })}
              </SelectOptionListItem>
            )
          )
        ) : canShowEmptyMessage ? (
          <SelectOptionListItem>
            <SelectListItem label={emptyMessage ?? ''} selected={false} highlighted={false} />
          </SelectOptionListItem>
        ) : null}
      </SelectBase>
      {!disabled && !readOnly && onCreateOption && (
        <Box margin={{ bottom: '13.5px' }}>
          <ItemCreateInput onCreateItem={onCreateOption} />
        </Box>
      )}
    </ThemeContext.Extend>
  )
}
