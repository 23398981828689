import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const BrandResilience = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 100 100" a11yTitle="BrandResilience" {...props}>
    <g clipPath="url(#a)" fill="#fff"><path d="M50 100.107a49.955 49.955 0 0 0 19.134-3.81 49.995 49.995 0 0 0 16.221-10.85 50.057 50.057 0 0 0 10.839-16.24A50.1 50.1 0 0 0 85.355 14.66a49.996 49.996 0 0 0-16.22-10.85A49.954 49.954 0 0 0 50 0v100.107Z" /><path opacity=".5" d="M50 50.053 0 0v100.107l50-50.054Z" /></g>
  </StyledIcon>
))

BrandResilience.displayName = 'BrandResilience'

export { BrandResilience }
