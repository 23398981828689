import { KeyboardEvent } from 'react'
import { FieldError, UseFormReturn } from 'react-hook-form'

import { Box, Message, TextInput } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'

type EnterSnippetDetailsProps = {
  methods: UseFormReturn<FormValues>
  onClickContinue: () => void
}

type FormValues = {
  name: string
}

export const EnterSnippetDetails = ({ methods, onClickContinue }: EnterSnippetDetailsProps) => {
  const { t } = useLanguage('runbooks')

  const formErrors = methods.formState?.errors
  const formErrorMessages = Object.values(formErrors)
    ?.map(error => (error as FieldError).message)
    .filter(Boolean) as string[]

  const errors = formErrorMessages

  return (
    <Box direction="column">
      <Message type="error" message={errors} />
      <Box margin={{ top: errors.length === 0 ? '0px' : '16px' }}>
        <TextInput
          {...methods.register('name')}
          label={t('enterSnippetDetails.fields.name.label')}
          required
          hasError={!!formErrors?.name}
          autoFocus
          onKeyDown={(event: KeyboardEvent<HTMLInputElement>) => {
            if (event.code === 'Enter') {
              onClickContinue()
            }
          }}
        />
      </Box>
    </Box>
  )
}
