import { memo, useCallback } from 'react'
import styled from 'styled-components'

import { Checkbox, IconButton } from '@cutover/react-ui'
import { useClearFilterState } from 'main/recoil/data-access'
import { useLanguage } from 'main/services/hooks'
import { RunbookFilterType } from 'main/services/tasks/filtering'

export const ToggleControl = memo(
  ({
    value,
    setValue,
    filterKey,
    onClick
  }: {
    value?: boolean
    setValue: any
    filterKey: keyof RunbookFilterType
    onClick?: () => void
  }) => {
    const { t } = useLanguage('common', { keyPrefix: 'filter' })
    const clearValue = useClearFilterState(filterKey)

    const handleClickCheck = useCallback(
      () => (value === undefined ? setValue(true) : clearValue()),
      [value, clearValue, setValue]
    )
    const handleClickReverse = useCallback(() => setValue(!value), [value, setValue])

    return (
      <Checkbox
        label={value === false ? t(`runbook.${filterKey}`, { context: 'reversed' }) : t(`runbook.${filterKey}`)}
        checked={value !== undefined}
        onClick={() => {
          handleClickCheck()
          onClick?.()
        }}
        suffix={
          value !== undefined ? (
            <ReversedIcon
              reversed={value === false}
              icon="swap"
              size="small"
              tipPlacement="top"
              label={t('filterByOpposite')}
              isActive={value === false}
              onClick={handleClickReverse}
            />
          ) : undefined
        }
      />
    )
  }
)

const ReversedIcon = styled(IconButton)<{ reversed: boolean }>`
  transform: rotate(${({ reversed }) => (reversed ? 180 : 0)}deg);
  transition: transform 400ms cubic-bezier(0.35, 0, 0.25, 1);
`
