import { RefObject, useMemo, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { Accordion, AccordionPanel, Box, Message, RadioboxGroup, Select, TextArea, TextInput } from '@cutover/react-ui'
import { CustomFieldForm } from 'main/components/shared/custom-field-form'
import { useFolderOptions } from 'main/components/shared/form/use-folder-options'
import { RolesSelect } from 'main/components/shared/runbook-edit/roles-select'
import { RagStatusFields } from 'main/components/shared/runbook-edit/runbook-edit-form/rag-status-fields'
import { RunbooksBulkEditFormType } from 'main/components/workspace/right-panels/runbooks-bulk-edit-panel'
import { useLanguage } from 'main/services/hooks'
import { CustomField, User } from 'main/services/queries/types'
import { PermittedProject } from 'main/services/queries/use-permitted-resources'
import { PrepareBulkEditRunbooksResponse } from 'main/services/queries/use-prepare-bulk-edit-runbooks'

// TODO: Can this form never be readOnly? There is no prop for this state.
export type RunbooksBulkEditFormProps = {
  projects: PermittedProject[]
  bulkEditPrepData: PrepareBulkEditRunbooksResponse
  accountId: number
  rolesSelectRef?: RefObject<{ reset: () => void }>
  displayCustomFieldsSection?: boolean
  customFields: CustomField[]
  runbooksAreSameType: boolean
  templateType?: string | undefined
  disabled?: boolean
  lockDestinationProject?: boolean
}

export const RunbooksBulkEditForm = ({
  projects,
  bulkEditPrepData,
  accountId,
  rolesSelectRef,
  customFields,
  runbooksAreSameType,
  templateType,
  disabled,
  lockDestinationProject = false
}: RunbooksBulkEditFormProps) => {
  const { t } = useLanguage('runbooks', { keyPrefix: 'fields' })
  const { register, control, formState, watch } = useFormContext<RunbooksBulkEditFormType>()
  const readOnly = disabled // TODO: implement readonly
  const flattenedFolderHierarchy = useFolderOptions({ folders: projects })
  const { editable_attrs } = bulkEditPrepData

  const canEditName = editable_attrs?.includes('name')
  const canEditFolder = editable_attrs?.includes('project')
  const canEditStatus = editable_attrs?.includes('status')
  const canEditDescription = editable_attrs?.includes('description')
  const canEditRoles = editable_attrs?.includes('roles')
  const canEditCustomFields = editable_attrs?.includes('custom_fields')

  const isSnippet = templateType === 'snippet'
  const isRunbook = templateType === undefined
  const showFolderFieldComponent = canEditFolder && projects.length > 0 && !isSnippet

  const name = watch('changes.name')
  const description = watch('changes.description')

  const nameModifyTypeField = (
    <Controller
      name="changes.name_modify_type"
      control={control}
      defaultValue="replace"
      render={({ field: { name, onChange, value, onBlur, ref } }) => (
        <RadioboxGroup
          name={name}
          hasError={!!formState.errors.changes?.name_modify_type}
          label={t('operationType.label')}
          direction="row"
          disabled={disabled}
          onChange={onChange}
          value={value}
          onBlur={onBlur}
          ref={ref}
          options={[
            { id: 'replace', value: 'replace', label: t('operationType.radioOptions.replace') },
            { id: 'prefix', value: 'prefix', label: t('operationType.radioOptions.prefix') },
            { id: 'suffix', value: 'suffix', label: t('operationType.radioOptions.suffix') }
          ]}
        />
      )}
    />
  )

  const nameField = useMemo(
    () => (
      <>
        <TextInput
          label={t('runbooksTitle.label', { type: isSnippet ? 'Snippets' : isRunbook ? 'Runbooks' : 'Templates' })}
          autoFocus
          hasError={!!formState.errors.changes?.name}
          {...register('changes.name')}
          disabled={disabled}
        />
        {!!name && nameModifyTypeField}
      </>
    ),
    [name, nameModifyTypeField, disabled]
  )

  const folderField = (
    <Controller
      name={'changes.project_id'}
      control={control}
      render={({ field: { onChange, value, ref }, fieldState: { error }, formState: { defaultValues } }) => (
        <Select
          labelKey="name"
          clearable
          valueKey="id"
          icon="folder-open"
          optionToString={option => option.name_hierarchy ?? option.name}
          defaultValue={defaultValues?.changes?.project_id ?? null}
          filterKeys={['name']}
          label={t('folder.label')}
          name="changes.project_id"
          hasError={!!error}
          options={flattenedFolderHierarchy}
          onChange={onChange}
          value={value}
          inputRef={ref}
          disabled={disabled}
        />
      )}
    />
  )

  const lockDestinationProjectNote = (
    <Box style={{ marginBottom: '20px' }}>
      <Message message={t('folder.info.locked')} type="info" data-testid="folder-locked-note" />
    </Box>
  )

  // Create a cache so that when the accordion is collapsed we don't lose the user data for new users
  // that are loaded asynchronously and did not previously persist anywhere while the form is editing.
  const [cachedValue, setCachedValue] = useState<Record<number, User[]>>(() => {
    return bulkEditPrepData.role_types.reduce((mapping: Record<number, User[]>, roleType) => {
      return {
        ...mapping,
        [roleType.id]: roleType.users
      }
    }, {} as Record<number, User[]>)
  })

  const rolesSection = useMemo(
    () => (
      <AccordionPanel label={t('roles.label')} icon="user-roles" iconColor="text-light">
        <Controller
          name="changes.roles"
          control={control}
          render={({ field: { onChange } }) => {
            return (
              <>
                {bulkEditPrepData.role_types.map(roleType => (
                  <RolesSelect
                    ref={rolesSelectRef}
                    a11yTitle={roleType.name}
                    key={roleType.id}
                    accountId={accountId}
                    label={roleType.name}
                    defaultSelected={roleType.users}
                    initialSelected={cachedValue[roleType.id]}
                    disabled={disabled}
                    onChange={recipients => {
                      const users = recipients?.map(recipient => ({
                        role_type_id: roleType.id,
                        subject_id: recipient.id,
                        subject_type: 'User'
                      }))

                      // set the cache so that can set to previous value when the accordion expands
                      setCachedValue(prev => ({
                        ...prev,
                        [roleType.id]: recipients ?? []
                      }))

                      onChange(users)
                    }}
                  />
                ))}
              </>
            )
          }}
        />

        {/* message that always displays: the users selected above are added to any existing roles */}
        <Message
          message={
            t('bulkEditFieldMessage.existingRoles.partialText.usersSelectedAbove') +
            ' <b>' +
            t('bulkEditFieldMessage.existingRoles.partialText.added') +
            '</b> ' +
            t('bulkEditFieldMessage.existingRoles.partialText.anyExistingRoles')
          }
        />
      </AccordionPanel>
    ),
    [t, control, bulkEditPrepData.role_types, rolesSelectRef, accountId, cachedValue, disabled]
  )

  const customFieldsSection = useMemo(
    () => (
      <AccordionPanel label={t('customFields.accordionLabel')} icon="custom-field" iconColor="text-light">
        {runbooksAreSameType ? (
          customFields && customFields.length ? (
            <CustomFieldForm
              errors={formState.errors.changes?.field_values}
              namePrefix="changes."
              customFields={customFields}
              customFieldUsers={[]}
              disabled={disabled}
              alwaysNotRequired
            />
          ) : (
            // message when there are no editable custom fields
            <Message
              message={
                '<b>' +
                t('bulkEditFieldMessage.noCustomFields.partialText.note') +
                '</b> ' +
                t('bulkEditFieldMessage.noCustomFields.partialText.content')
              }
            />
          )
        ) : (
          // message if the runbooks are not the same type
          <Message
            message={
              '<b>' +
              t('bulkEditFieldMessage.notSameRunbookType.partialText.note') +
              '</b> ' +
              t('bulkEditFieldMessage.notSameRunbookType.partialText.content')
            }
          />
        )}
      </AccordionPanel>
    ),
    [runbooksAreSameType, customFields]
  )

  const descriptionModifyTypeField = (
    <Controller
      name="changes.description_modify_type"
      control={control}
      defaultValue="replace"
      render={({ field: { name, onChange, value, onBlur, ref } }) => (
        <RadioboxGroup
          name={name}
          hasError={!!formState.errors.changes?.description_modify_type}
          label={t('operationType.label')}
          direction="row"
          disabled={disabled}
          onChange={onChange}
          value={value}
          onBlur={onBlur}
          ref={ref}
          options={[
            { label: t('operationType.radioOptions.replace'), value: 'replace' },
            { label: t('operationType.radioOptions.prefix'), value: 'prefix' },
            { label: t('operationType.radioOptions.suffix'), value: 'suffix' }
          ]}
        />
      )}
    />
  )

  const descriptionSection = useMemo(
    () => (
      <AccordionPanel label={t('description.accordionLabel')} icon="info" iconColor="text-light">
        <TextArea
          {...register('changes.description', {
            disabled
          })}
          hasError={!!formState.errors.changes?.description}
          label={t('otherDetails.description.label')}
        />
        {!!description && descriptionModifyTypeField}
      </AccordionPanel>
    ),
    [description, descriptionModifyTypeField, disabled]
  )

  return (
    <>
      {canEditName && nameField}
      {showFolderFieldComponent && !lockDestinationProject && folderField}
      {showFolderFieldComponent && lockDestinationProject && lockDestinationProjectNote}
      {canEditStatus && isRunbook && (
        <RagStatusFields<RunbooksBulkEditFormType>
          name="changes.status"
          statusMessageFieldName="changes.status_message"
          disabled={disabled}
          readOnly={readOnly}
        />
      )}
      <Accordion>
        {canEditRoles && rolesSection}
        {canEditCustomFields && !isSnippet && customFieldsSection}
        {canEditDescription && descriptionSection}
      </Accordion>
    </>
  )
}
