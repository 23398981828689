import { useState } from 'react'

import { Modal } from '@cutover/react-ui'
import { CreateChangeRequestFormWithRunbookWebsocket } from './create-change-request-form'
import { useLanguage } from 'main/services/hooks'

type CreateChangeTaskModalProps = {
  open: boolean
  runbookId: string | undefined
  changeRequestType: string | null
  parentChangeRequestId: number | null
  parentChangeRequestExternalId: string | null
  handleCloseModal: () => void
}

export const CreateChangeTaskModal = ({
  open,
  runbookId,
  changeRequestType,
  parentChangeRequestId,
  parentChangeRequestExternalId,
  handleCloseModal
}: CreateChangeTaskModalProps) => {
  const { t } = useLanguage('changeRequests', { keyPrefix: 'modal' })
  const [onClickConfirm, setOnClickConfirm] = useState<(() => void) | undefined>(undefined)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [changeTaskId, setChangeTaskId] = useState<number | undefined>(undefined)

  const handleChangeTaskCreate = (id: number) => {
    setChangeTaskId(id)
  }

  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      loading={isSubmitting}
      title={t('title_task')}
      onClickConfirm={onClickConfirm}
    >
      {runbookId && changeRequestType && parentChangeRequestId && parentChangeRequestExternalId && (
        <>
          {open ? (
            <CreateChangeRequestFormWithRunbookWebsocket
              setOnClickConfirm={setOnClickConfirm}
              setSubmitting={setIsSubmitting}
              runbookId={runbookId}
              changeRequestType={changeRequestType}
              parentChangeRequestId={parentChangeRequestId}
              parentChangeRequestExternalId={parentChangeRequestExternalId}
              onSuccess={handleCloseModal}
              onChangeRequestCreate={handleChangeTaskCreate}
              changeRequestId={changeTaskId}
            />
          ) : null}
        </>
      )}
    </Modal>
  )
}
