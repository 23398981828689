// @ts-nocheck
import styled from 'styled-components'
import { px2rem } from 'app/Styles/Variables'
import { observer } from 'mobx-react-lite'
import { RoleTypeEditPresenter } from './RoleTypeEditPresenter'
import { AlertSingle, Checkbox, MultiSelectWithCapabilities, Select, TextInput } from 'Components/Molecules'
import { colors } from 'app/Styles/Variables'
import { RoleTypeViewPermissionComponent } from './RoleTypeViewPermissionComponent'
import { EditPanelFormWrapper } from 'Components/Templates'

const SectionWrapper = styled.div`
  display: flex;
  border-bottom: ${px2rem(1)} solid ${colors.primaryGreyBackgroundColor};
`

const FormWrapper = styled.div``

const AlertWrapper = styled.div`
  margin-bottom: ${px2rem(24)};
`

const MultiSelectWithCapabilitiesComponentWrapper = styled.div`
  margin-top: ${px2rem(16)};
`

const OptionsWrapper = styled.div`
  position: relative;
`

const OptionsHeadingWrapper = styled.div`
  font-size: ${px2rem(13)};
  line-height: ${px2rem(12)};
  color: ${colors.textLight};
  font-weight: 500;
  transition: none;
  padding-bottom: ${px2rem(2)};
  padding-top: ${px2rem(20)};
`

interface IRoleTypeEditComponentProps {
  presenter: RoleTypeEditPresenter
}

export const RoleTypeEditSection = observer<IRoleTypeEditComponentProps>(props => {
  const {
    panelHeading,
    namePresenter,
    descriptionPresenter,
    archiveTooltip,
    mode,
    close,
    save,
    reset,
    archiveButtonCallback,
    confirmModalPresenter,
    alertPresenter,
    checkFormIsValid,
    isSubmitted,
    contextTypeNamePresenter,
    triggerScroll,
    setTriggerScroll,
    capabilitiesPresenter,
    viewPermissionPresenter,
    editableFromContextPresenter,
    notifyPresenter,
    optionsHeading,
    canArchiveRoleType,
    editPanelSaving
  } = props.presenter

  const showPermissions = contextTypeNamePresenter.value === 'Global' || contextTypeNamePresenter.value === 'Account'

  return (
    <EditPanelFormWrapper
      heading={panelHeading}
      mode={mode}
      showArchive={canArchiveRoleType}
      archiveTooltip={archiveTooltip}
      closeCallback={close}
      saveCallback={save}
      resetCallback={reset}
      archiveCallback={archiveButtonCallback}
      editPanelSaving={editPanelSaving}
      confirmModalPresenter={confirmModalPresenter}
      triggerScroll={triggerScroll}
      setTriggerScroll={setTriggerScroll}
    >
      <FormWrapper>
        <form>
          {isSubmitted && !checkFormIsValid && (
            <AlertWrapper>
              <AlertSingle presenter={alertPresenter} />
            </AlertWrapper>
          )}
          <TextInput presenter={namePresenter} formTopInputElement />
          <TextInput presenter={descriptionPresenter} extraStyles={{ marginBottom: px2rem(16) }} />
          <Select presenter={contextTypeNamePresenter} extraStyles={{ marginBottom: '0' }} />
        </form>
      </FormWrapper>
      <MultiSelectWithCapabilitiesComponentWrapper>
        <MultiSelectWithCapabilities presenter={capabilitiesPresenter} />
      </MultiSelectWithCapabilitiesComponentWrapper>
      {showPermissions && <RoleTypeViewPermissionComponent presenter={viewPermissionPresenter} />}
      <SectionWrapper>
        <OptionsWrapper>
          <OptionsHeadingWrapper>{optionsHeading}</OptionsHeadingWrapper>
          <Checkbox presenter={editableFromContextPresenter} />
          <Checkbox presenter={notifyPresenter} />
        </OptionsWrapper>
      </SectionWrapper>
    </EditPanelFormWrapper>
  )
})
