// @ts-nocheck
import { injectable, inject } from 'inversify'
import { computed, observable } from 'mobx'
import 'reflect-metadata'
import { AuthenticationRepository } from 'app/Authentication/AuthenticationRepository'
import { ConfigsRepository } from 'Configs/ConfigsRepository'
import { RoutingState } from 'app/Routing/RoutingState'
import { Router } from 'app/Routing/Router'

@injectable()
export class CurrentPagePresenter {
  @inject(AuthenticationRepository)
  private authenticationRepository: AuthenticationRepository

  @inject(Router)
  public router: Router

  @inject(RoutingState)
  private routingState: RoutingState

  @inject(ConfigsRepository)
  private configsRepository: ConfigsRepository

  @observable
  public loading: boolean = true

  @observable
  public angularJSSettingsPanelOpenState: boolean = false

  public bootstrap = async (recoverSession: boolean) => {
    if (recoverSession) {
      // TODO: Remove call to configsRepository once all react pages realy on ConfigsContext.
      await Promise.all([this.authenticationRepository.recoverSession(), this.configsRepository.load()])
    } else {
      await this.configsRepository.load()
    }

    this.router.registerRoutes()
    this.loading = false
  }
}
