import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const BrandGettingStarted = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 100 101" a11yTitle="BrandGettingStarted" {...props}>
    <g clipPath="url(#a)" fill="#fff"><path opacity=".5" d="M47 .757a50 50 0 1 0 52.91 52.91H70.441A20.66 20.66 0 1 1 47 30.226V.756Z" /><path d="M53 30.226V.756a50 50 0 0 1 46.91 46.91H70.44A20.657 20.657 0 0 0 53 30.227Z" /></g>
  </StyledIcon>
))

BrandGettingStarted.displayName = 'BrandGettingStarted'

export { BrandGettingStarted }
