import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Pin = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Pin" {...props}>
    <path d="m28.318 6.865 8.358 4.825-2.442 1.613c-.324.214-.594.5-.788.836L27.59 24.285a2.55 2.55 0 0 0-.091 2.353c1.424 3.047.892 5.617.326 7.077L13.67 25.544c.98-1.22 2.94-2.966 6.291-3.256a2.55 2.55 0 0 0 1.993-1.256l5.858-10.145a2.5 2.5 0 0 0 .33-1.1l.175-2.922Zm.8-3.003c-1.61-.93-3.634.16-3.746 2.015l-.217 3.612-5.676 9.831c-5 .515-7.661 3.55-8.71 5.109-.84 1.25-.269 2.748.823 3.379l6.359 3.67-5.25 9.094 2.598 1.5 5.25-9.094 6.353 3.669c1.093.63 2.676.376 3.338-.977.827-1.688 2.123-5.51.07-10.098l5.675-9.83 3.02-1.994c1.55-1.024 1.481-3.322-.128-4.251l-9.76-5.635Z" fill="#000" />
  </StyledIcon>
))

Pin.displayName = 'Pin'

export { Pin }
