import { AxiosError } from 'axios'

import { ApiError } from './http-gateway-adapter'

export const getServerErrorMessages = (error: AxiosError | ApiError, defaultMessage = 'An error occurred') => {
  try {
    let errors: string[] | undefined = []

    // @ts-ignore would have to type the AxiosError object for this to resolve and we don't care what response
    // type it came from, just need error strings
    errors = error instanceof ApiError ? error.errors : error.response?.data?.errors

    return errors?.length ? errors : [error.message || defaultMessage]
  } catch {
    return [defaultMessage]
  }
}
