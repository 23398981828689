import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Like = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Like" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M20.232 5.917a.505.505 0 0 0-.473.2.452.452 0 0 0-.068.382l2.067 7.58a3.5 3.5 0 0 1-3.377 4.42H8a.5.5 0 0 0-.5.5v4.34a.5.5 0 0 0 .098.298l9.008 12.16a.5.5 0 0 0 .401.203H30.5V17.776l-.138-.102a35.736 35.736 0 0 1-1.924-1.534c-1.512-1.292-3.425-3.143-4.881-5.3a62.432 62.432 0 0 1-2.824-4.615.615.615 0 0 0-.5-.308ZM33.5 18.5V36h5a.5.5 0 0 0 .5-.5V19a.5.5 0 0 0-.5-.5h-5Zm-1.028-3a32.69 32.69 0 0 1-2.085-1.64c-1.413-1.208-3.1-2.857-4.344-4.7a59.425 59.425 0 0 1-2.686-4.39c-1.228-2.214-4.484-2.573-6.039-.396a3.452 3.452 0 0 0-.521 2.915l2.067 7.58a.5.5 0 0 1-.483.63H8A3.5 3.5 0 0 0 4.5 19v4.34c0 .751.241 1.481.688 2.084l9.007 12.16A3.5 3.5 0 0 0 17.008 39H38.5a3.5 3.5 0 0 0 3.5-3.5V19a3.5 3.5 0 0 0-3.5-3.5h-6.028Z" fill="#000" />
  </StyledIcon>
))

Like.displayName = 'Like'

export { Like }
