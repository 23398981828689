import { lazy, Suspense } from 'react'

import { CodeEditorProps } from './types'
import { LoadingPanel } from '../../layout'

// We're lazy loading the code editor and exporting this version instead
// to improve code splitting and reduce production bundle size
const CodeEditorLazy = lazy(() => import('./code-editor'))

export const CodeEditor = (props: CodeEditorProps) => {
  return (
    <Suspense fallback={<LoadingPanel />}>
      <CodeEditorLazy {...props} />
    </Suspense>
  )
}
