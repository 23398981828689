import { useRef } from 'react'
import { useRecoilCallback, useRecoilValue } from 'recoil'

import { taskListTaskState, teamsState, teamsStateLookup, teamState, usersTeamsPermissions } from 'main/recoil/runbook'
import { RunbookTeamsPermissionsResponse } from 'main/services/queries/use-runbook-versions'
import { RunbookTeamModelType } from 'main/data-access/models'
import { useGetTask } from './task'

export const useTeamValue: RunbookTeamModelType['useGet'] = id => useRecoilValue(teamState(id))

export const useTeamValueCallback: RunbookTeamModelType['useGetCallback'] = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      async (id: number) =>
        await snapshot.getPromise(teamState(id))
  )

export const useAllTeamsValue: RunbookTeamModelType['useGetAll'] = () => {
  return useRecoilValue(teamsState)
}

export const useAllTeamsValueCallback: RunbookTeamModelType['useGetAllCallback'] = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      async () =>
        await snapshot.getPromise(teamsState)
  )

export const useGetAllTeamsBy: RunbookTeamModelType['useGetAllBy'] = getAllBy => {
  /* eslint-disable react-hooks/rules-of-hooks */
  // stabilize taskId so hooks are never called in different order
  const stableTaskId = useRef(getAllBy.taskId).current

  if (typeof stableTaskId === 'number') return useAllTeamsByTaskId(stableTaskId)
  return useAllTeamsValue()
  /* eslint-enable react-hooks/rules-of-hooks */
}

export const useGetAllTeamsByCallback: RunbookTeamModelType['useGetAllByCallback'] = () =>
  useRecoilCallback(({ snapshot }) => async getAllBy => {
    const lookup = await snapshot.getPromise(teamsStateLookup)
    const teamIds = (await snapshot.getPromise(taskListTaskState(getAllBy.taskId))).runbook_team_ids
    return teamIds?.map(id => lookup[id])
  })

export const useRunbookTeamsLookup: RunbookTeamModelType['useGetLookup'] = () => useRecoilValue(teamsStateLookup)

export const useRunbookTeamsLookupCallback: RunbookTeamModelType['useGetLookupCallback'] = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      async () =>
        await snapshot.getPromise(teamsStateLookup)
  )

export const useCanRunbookteam: RunbookTeamModelType['useCan'] = permission => {
  return useRunbookTeamsPermission({ attribute: permission })
}

/* -------------------------------- Internal -------------------------------- */

const useRunbookTeamsPermission = <TKey extends keyof RunbookTeamsPermissionsResponse>({
  attribute
}: {
  attribute: TKey
}) => {
  return useRecoilValue(usersTeamsPermissions({ attribute }))
}

const useAllTeamsByTaskId = (taskId: number) => {
  const lookup = useRecoilValue(teamsStateLookup)
  const { runbook_team_ids } = useGetTask(taskId)

  return runbook_team_ids?.map(id => lookup[id])
}
