// @ts-nocheck
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'
import { container } from 'app/Config/IOC'
import { Types } from 'Gateways/Service/Types'
import { colors } from 'app/Styles/Variables'
import { HeadingTitle, HeaderFilterIcon } from 'Components/Templates'
import { RunbookTypesPageHeadingPresenter } from './RunbookTypesPageHeadingPresenter'
import { ClearFilter, SearchBox, PillFilter } from 'Components/Molecules'
import { RunbookTypesPageMoreOptionsPopoverComponent } from './RunbookTypesPageMoreOptionsPopoverComponent'
import { px2rem } from 'app/Styles/Variables'
import { buildFractionString } from 'Shared/HooksAndPresenters/HeadingSection'

const HeaderWrapper = styled.div`
  width: 100%;
  height: 100%;
`

const RightIconContainer = styled.div`
  z-index: 2;
  position: absolute;
  padding-right: ${px2rem(50)};
  padding-left: ${px2rem(6)};
  top: 0;
  right: 0;
  background-color: ${colors.bg1};
  box-shadow: -10px 0px 40px 10px ${colors.bg1};
`

interface IRunbookTypesPageHeadingComponentProps {
  filterOpen: boolean
  setFilterOpen: (val: boolean) => void
  presenter: RunbookTypesPageHeadingPresenter
  loading?: boolean
}

export const RunbookTypesPageHeadingSection = observer<IRunbookTypesPageHeadingComponentProps>(
  ({ presenter, filterOpen, setFilterOpen, loading }) => {
    const {
      searchBoxPresenter,
      runbookTypesTotal,
      runbookTypesFilteredTotal,
      clearFilters,
      showClearFilterButton,
      runbookTypesPageMoreOptionsPopoverPresenter,
      showArchivedRunbookTypes,
      visibilityPillsPresenter,
      searchPillPresenter
    } = presenter

    const { countString } = buildFractionString(runbookTypesFilteredTotal, runbookTypesTotal)
    const language: ILanguageService = container.get(Types.ILanguageService)

    const Pills = () => {
      return (
        <>
          <PillFilter presenter={visibilityPillsPresenter} />
          <PillFilter presenter={searchPillPresenter} />
        </>
      )
    }

    const headingTitle = showArchivedRunbookTypes
      ? language.get('runbookTypes:headingSection:title:archived')
      : language.get('runbookTypes:headingSection:title:default')

    return (
      <HeaderWrapper>
        <HeaderFilterIcon filterOpen={filterOpen} setFilterOpen={setFilterOpen} />
        <HeadingTitle title={headingTitle} count={!loading && countString} pills={<>{Pills()}</>} />
        <RightIconContainer>
          <ClearFilter clearFilters={clearFilters} showClearFilterButton={showClearFilterButton} language={language} />
          <RunbookTypesPageMoreOptionsPopoverComponent presenter={runbookTypesPageMoreOptionsPopoverPresenter} />
          <SearchBox
            presenter={searchBoxPresenter}
            searchContent={language.get('runbookTypes:headingSection:searchContent')}
            language={language}
          />
        </RightIconContainer>
      </HeaderWrapper>
    )
  }
)
