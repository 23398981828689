import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Calendar = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Calendar" {...props}>
    <path d="M29 12V3h-3v9h3Z" fill="#000" /><path d="M39 17.5V14a5 5 0 0 0-5-5h-1V6h1a8 8 0 0 1 8 8v20a8 8 0 0 1-8 8H14a8 8 0 0 1-8-8V14a8 8 0 0 1 8-8h1v3h-1a5 5 0 0 0-5 5v3.5h30Zm0 11v-8h-8v8h8Zm-11 0v-8h-8v8h8Zm-11 0v-8H9v8h8Zm0 3H9V34a5 5 0 0 0 5 5h3v-7.5Zm3 7.5h8v-7.5h-8V39Zm14 0a5 5 0 0 0 5-5v-2.5h-8V39h3Z" fill="#000" /><path d="M22 3v9h-3V3h3Z" fill="#000" />
  </StyledIcon>
))

Calendar.displayName = 'Calendar'

export { Calendar }
