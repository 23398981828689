import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const VolumeMute = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="VolumeMute" {...props}>
    <path d="M13.139 28.714H9v-9.428h4.139L21 11.425v25.15l-7.861-7.86ZM22.714 42A1.288 1.288 0 0 0 24 40.714V7.286a1.284 1.284 0 0 0-2.195-.909l-9.909 9.909h-4.61c-.71 0-1.286.576-1.286 1.286v12.857c0 .71.576 1.285 1.286 1.285h4.61l9.91 9.91c.245.245.574.376.908.376Zm14.907-17.5 4.95 4.95-2.121 2.121-4.95-4.95-4.95 4.95-2.121-2.121 4.95-4.95-4.95-4.95 2.121-2.121 4.95 4.95 4.95-4.95 2.121 2.121-4.95 4.95Z" fill="#000" />
  </StyledIcon>
))

VolumeMute.displayName = 'VolumeMute'

export { VolumeMute }
