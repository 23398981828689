import {
  FrontEndUserSettingIntegrationActionItemsType,
  FrontendUserSettingType,
  RunbookIntegration
} from 'main/services/queries/types'
import { getAvailableIntegrations } from './apps-api'
import { SelectedApp } from './apps-types'
import {
  getFrontendUserRunbookSetting,
  updateFrontendUserSetting
} from 'main/services/queries/use-frontend-user-setting'
import { UserSettingsResponse } from 'main/services/api/data-providers/user/user-channel-response-types'

const APP_INTEGRATION_ACTION = 'Integrations::Apps::MountPoint'

export const getAvailableAppsWithUserSettings = async (
  runbookId: string,
  postAppEvents: any
): Promise<SelectedApp[]> => {
  const apps: SelectedApp[] = []
  const { user_settings }: UserSettingsResponse = await getFrontendUserRunbookSetting(runbookId)
  const { integrationLinks } = await getAvailableIntegrations(runbookId)
  const integrationActionItems = user_settings[user_settings.length - 1]?.data?.integration_action_items?.reduce(
    (acc: any, item) => {
      acc[item.id] = { order: item.order, visible: item.visible }
      return acc
    },
    {}
  )

  integrationLinks.map((link: RunbookIntegration) => {
    if (link.id && link.integrationActionItem?.integrationAction === APP_INTEGRATION_ACTION) {
      if (link.integrationActionItem.settings.location === 'Panel') {
        const savedItem = (integrationActionItems && integrationActionItems[link.id]) || {}
        const app: SelectedApp = {
          id: link.id,
          appId: link.integrationActionItem.key,
          resourceId: runbookId,
          order: savedItem.order || link.order + 100,
          name: link.integrationActionItem.name,
          visible: savedItem.visible !== undefined ? savedItem.visible : true
        }
        apps.push(app)
      } else if (link.integrationActionItem.settings.location === 'Header') {
        postAppEvents({
          runbook_id: runbookId,
          app_id: link.integrationActionItem.key
        })
      }
    }
  })
  apps.sort((a, b) => a.order - b.order)
  apps.map((app, index) => {
    app.order = index + 1
  })

  return apps
}

export const saveAvailableAppsInUserSettings = async (
  runbookId: string | undefined,
  apps: SelectedApp[] | undefined
) => {
  if (!runbookId || !apps) return

  const savedApps: FrontEndUserSettingIntegrationActionItemsType[] = []
  let order = 0
  apps.map((app: SelectedApp) => {
    const savedApp: FrontEndUserSettingIntegrationActionItemsType = {
      id: app.id,
      order: (order += 1),
      visible: app.visible
    }
    savedApps.push(savedApp)
  })

  const { user_settings }: UserSettingsResponse = await getFrontendUserRunbookSetting(runbookId)
  const payload: FrontendUserSettingType = {
    id: user_settings[0]?.id,
    type: 'RunbookUserSetting',
    resource_id: parseInt(runbookId),
    resource_type: 'Runbook',
    data: {
      integration_action_items: savedApps
    }
  }

  await updateFrontendUserSetting(payload)
}
