import { useInfiniteQuery, UseInfiniteQueryOptions } from 'react-query'

import { getFolderAxis, getStatusAxis, getTimeAxis } from './axis-definitions'
import { AxisDefs, RunbookData } from './types'
import {
  browserQueryStringToServerQueryObject,
  browserQueryStringToServerQueryString,
  EXCLUDE_KEYS_FROM_RUNBOOKS_SERVER_QUERY
} from 'main/components/shared/filter/filter-params'
import { apiClient } from 'main/services/api/api-client'
import { ApiError } from 'main/services/api/http-gateway-adapter'
import { RunbooksResponseMetaFull } from 'main/services/queries/use-runbooks'

const LIMIT = 200

type TimelinePageParam = {
  offset?: number
  direction: 'prev' | 'next' | 'initial'
  loadOrder: number
}

export type PagedTimelineRunbooks = {
  meta: RunbooksResponseMetaFull
  axisDefs: AxisDefs
  runbooks: RunbookData[]
  pagination: Required<TimelinePageParam>
}

const FIXED_PARAMS = {
  meta: true, //NOTE this should only be true the first time
  t: false
}

export function useTimelineRunbooksQuery(
  search: string,
  options: UseInfiniteQueryOptions<PagedTimelineRunbooks, ApiError> & {
    accountId: string
    starts_after: number
  }
) {
  const { accountId, starts_after, ...opts } = options

  const getTimelineRunbooks = async ({ offset, direction, loadOrder }: TimelinePageParam) => {
    const actualOffset = offset === undefined ? undefined : offset < 0 ? 0 : offset
    let actualLimit = LIMIT

    if (offset !== undefined && offset < 0) {
      // offset here is negative so this is less than the default limit and happens when
      // the offset returned by the api request that initializes the inifinite query returns an offset
      // less than the default offset. In that case we would need the previous page call to return
      // an offset of 0 and a limit of that initial offset.
      actualLimit = LIMIT + offset
    }

    const paramsString = browserQueryStringToServerQueryString({
      query: search,
      defaults: {
        accountId,
        starts_after
      },
      overrides: {
        ...FIXED_PARAMS,
        offset: actualOffset,
        limit: actualLimit
      },
      excludeKeys: EXCLUDE_KEYS_FROM_RUNBOOKS_SERVER_QUERY
    })

    const resp = await apiClient.get<PagedTimelineRunbooks>({
      url: `runbooks${paramsString}`
    })

    const data = resp.data
    const { runbooks, meta } = data

    // TODO: can we get the color from the backend?
    runbooks.forEach(rb => {
      const folder = meta.projects.find((project: any) => project.id === rb.project_id)
      rb.color = folder?.color
    })

    // This should always exist when the api client is responds with success.
    // TODO: handle when does not respond with success...
    const result = {
      runbooks,
      pagination: {
        offset: offset ?? meta.offset,
        direction,
        loadOrder: loadOrder ?? 1
      },
      meta,
      axisDefs: {
        folder: getFolderAxis(data.meta.projects),
        status: getStatusAxis(),
        time: getTimeAxis()
      }
    }

    return result
  }

  return useInfiniteQuery<PagedTimelineRunbooks, ApiError>(
    [
      'runbooks',
      'timeline',
      browserQueryStringToServerQueryObject({
        query: search,
        defaults: {
          accountId,
          starts_after
        },
        overrides: FIXED_PARAMS
      })
    ],
    ({ pageParam = { offset: undefined, direction: 'initial' } }) => getTimelineRunbooks(pageParam),
    {
      getNextPageParam: (lastPage, allPages) => {
        const currentOffset = lastPage.meta.offset
        const nextOffset = currentOffset + LIMIT
        if (nextOffset < lastPage.meta.total_results_count) {
          return { offset: nextOffset, direction: 'next', loadOrder: allPages.length + 1 }
        }
      },
      getPreviousPageParam: (firstGroup, allPages) => {
        const firstGroupOffset = firstGroup.meta.offset
        if (firstGroupOffset === 0) return
        return { offset: firstGroupOffset - LIMIT, direction: 'prev', loadOrder: allPages.length + 1 }
      },
      staleTime: 0,
      cacheTime: 0,
      keepPreviousData: true,
      ...(opts ?? {})
    }
  )
}
