import styled from 'styled-components/macro'

import { colors, px2rem, transition } from '../../utils'

type TableProps = { width?: string; role?: string }
const Table = styled.div.attrs<TableProps, { role: string }>(props => ({
  role: props.role || 'table'
}))<TableProps>`
  color: ${colors.primaryGreyHover};
  font-size: ${px2rem(16)};
  width: ${({ width }) => width};
`

type RowProps = { role?: string; width?: string }
const Row = styled.div.attrs<RowProps, { role: string }>(props => ({ role: props.role || 'row' }))<RowProps>`
  width: ${({ width }) => width};
`

type HeaderProps = { role?: string; width?: string; highlight?: string; isSorted?: boolean }
const Header = styled.div.attrs<HeaderProps, { role: string }>(props => ({
  role: props.role || 'columnheader'
}))<HeaderProps>`
  align-items: center;
  background-color: ${({ highlight }) => highlight || 'white'};
  color: ${({ isSorted }) => (isSorted ? colors.primary : colors.text)};
  cursor: ${({ onClick, onKeyPress }) => (!!onClick || !!onKeyPress) && 'pointer'};
  display: flex !important;
  font-weight: 700;
  padding: ${px2rem(8)};
  width: ${({ width }) => width};
`

type CellProps = { highlight?: string; role?: string; width?: string }
const Cell = styled.div.attrs<CellProps, { role: string }>(props => ({ role: props.role || 'cell' }))<CellProps>`
  background-color: ${({ highlight }) => highlight};
  width: ${({ width }) => width};
`

type HeadProps = { role?: string }
const Head = styled.div.attrs<HeadProps, { role: string }>(props => ({ role: props.role || 'rowgroup' }))`
  ${Row} {
    display: flex;
    background-color: white;
    min-height: ${px2rem(40)};
  }
`

type BodyProps = { role?: string }
const Body = styled.div.attrs<BodyProps, { role: string }>(props => ({
  role: props.role || 'rowgroup'
}))`
  ${Row} {
    display: flex;
    ${transition()};
    min-height: ${px2rem(40)};
    align-items: center;

    &:nth-child(odd) {
      background-color: ${colors.bg1};
    }

    &:hover {
      background-color: ${colors.bg2};
    }

    &:first-of-type {
      border-top: 1px solid ${colors.primaryGreyBackgroundColor};
    }
  }

  ${Cell} {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    min-height: ${px2rem(40)};
    padding: ${px2rem(8)};
  }
`

type TableBaseType = typeof Table & {
  Row: typeof Row
  Header: typeof Header
  Cell: typeof Cell
  Head: typeof Head
  Body: typeof Body
}

/**
 * @deprecated Use Table or implement new via Grommet's Table (not DataTable) component
 */
const LegacyTableBase: TableBaseType = Object.assign(Table, {
  Row,
  Header,
  Cell,
  Head,
  Body
})

export { LegacyTableBase }
