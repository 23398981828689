// @ts-nocheck
import { observable } from 'mobx'
import { PillPresenter } from './PillPresenter'
import { TIcons } from 'Components/Atoms'

export class OldPillFilterPresenter extends PillPresenter {
  @observable
  public iconName?: TIcons

  @observable
  public filterLevel: number = 1

  @observable
  public filter

  public withIconName? = iconName => {
    this.iconName = iconName
    return this
  }

  public withFilterLevel? = filterLevel => {
    this.filterLevel = filterLevel
    return this
  }

  public withFilter? = filter => {
    this.filter = filter
    return this
  }
}
