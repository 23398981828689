import { createContext, ReactNode, useMemo } from 'react'
import { keyBy } from 'lodash'

import { useFilters } from 'main/components/shared/filter/filter-provider'
import { MyTask, RunbookTeam, User } from 'main/services/queries/types/my-tasks'
import { MyTasksResponseMeta, useMyTasks } from 'main/services/queries/use-my-tasks'

type MyTasksDataType = {
  isLoading?: boolean
  isError?: boolean
  isFetchingNextPage?: boolean
  hasNextPage?: boolean
  fetchNextPage?: () => Promise<any>
  tasks: MyTask[] | undefined
  meta: MyTasksResponseMeta | undefined
  users: User[] | undefined
  userLookup: Record<number, User> | undefined
  teams: RunbookTeam[] | undefined
  teamLookup: Record<number, RunbookTeam> | undefined
}

export const MyTasksDataContext = createContext<MyTasksDataType>({
  isLoading: false,
  isError: false,
  isFetchingNextPage: false,
  hasNextPage: false,
  users: undefined,
  teams: undefined,
  userLookup: undefined,
  teamLookup: undefined,
  tasks: undefined,
  meta: undefined
})

export const MyTasksDataProvider = ({ children }: { children: ReactNode }) => {
  const { filters } = useFilters()

  const { isFetchingNextPage, data, hasNextPage, fetchNextPage, isLoading, isError } = useMyTasks({
    params: filters
  })

  const users = data?.pages?.[0]?.meta.users
  const userLookup = useMemo(() => (users ? keyBy(users ?? [], 'id') : undefined), [users])
  const teams = data?.pages?.[0]?.meta.teams
  const teamLookup = useMemo(() => (teams ? keyBy(teams ?? [], 'id') : undefined), [teams])

  return (
    <MyTasksDataContext.Provider
      value={{
        isLoading,
        isError,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        tasks: data?.pages.flatMap(page => page.tasks),
        meta: data?.pages?.[0]?.meta,
        users,
        teams,
        userLookup,
        teamLookup
      }}
    >
      {children}
    </MyTasksDataContext.Provider>
  )
}
