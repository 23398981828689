import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Tag = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Tag" {...props}>
    <path d="M34.949 13.071a3 3 0 1 0-4.243 4.243 3 3 0 0 0 4.243-4.243Z" fill="#000" /><path fillRule="evenodd" clipRule="evenodd" d="M7.372 26.506 27.878 6H42.02v14.142L21.514 40.648a4 4 0 0 1-5.657 0l-8.485-8.485a4 4 0 0 1 0-5.657Zm10.606 12.02-8.485-8.484a1 1 0 0 1 0-1.415L29.12 9h9.9v9.9L19.392 38.526a1 1 0 0 1-1.414 0Z" fill="#000" />
  </StyledIcon>
))

Tag.displayName = 'Tag'

export { Tag }
