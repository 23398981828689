import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const BrandPlatformImplementation = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 100 101" a11yTitle="BrandPlatformImplementation" {...props}>
    <g clipPath="url(#a)" fill="#fff"><path d="M20.667 50.664C20.667 39.258 11.417 30.017 0 30v41.333c11.417-.016 20.667-9.263 20.667-20.67Z" /><path opacity=".5" d="M50 100.667a50 50 0 0 0 0-100v29.34a20.66 20.66 0 0 1 0 41.32v29.34Z" /></g>
  </StyledIcon>
))

BrandPlatformImplementation.displayName = 'BrandPlatformImplementation'

export { BrandPlatformImplementation }
