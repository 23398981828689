import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Minimise = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Minimise" {...props}>
    <path d="M42 22.5H6v3h36v-3Z" fill="#000" />
  </StyledIcon>
))

Minimise.displayName = 'Minimise'

export { Minimise }
