import { useMutation, useQueryClient } from 'react-query'

import { UserRecipient, UserResponse } from '../../components/recipients-multiselect/types'
import { apiClient } from 'main/services/api'

export const useBulkCreateUserMutation = () => {
  const queryClient = useQueryClient()

  return useMutation<UserRecipient[], Error, any>(
    'bulk-create-user-mutation',
    async (args: { accountId: number; query: string }) => {
      const { data } = await apiClient.post<{ query: string }, { users: UserResponse[] }>({
        url: `users/list?account_id=${args.accountId}`,
        data: { query: args.query }
      })

      if (data) {
        return data.users.map(user => ({
          ...user,
          type: 'user'
        }))
      } else {
        return []
      }
    },
    {
      onSuccess: () => {
        // does this not happen automatically?
        queryClient.invalidateQueries('bulk-create-user-mutation')
      }
    }
  )
}
