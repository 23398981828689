import { memo } from 'react'

import { IconButton } from '../../../button'

export type SelectEndItemsProps = {
  onClickClear?: any
  isLoading?: boolean
  disabled?: boolean
  readOnly?: boolean
  clearable?: boolean
  hasInputValue: boolean
  async?: boolean
}

export const InputClearButton = memo(({ onClick }: { onClick: any }) => {
  return (
    <IconButton
      onKeyDownCapture={e => {
        if (e.key === 'Enter' || e.key === 'Space') {
          e.stopPropagation()
          e.preventDefault()
          e.nativeEvent.stopImmediatePropagation()
          onClick()
        }
      }}
      label="clear"
      disableTooltip
      size="small"
      icon="close"
      color="text-light"
      onClick={onClick}
    />
  )
})
