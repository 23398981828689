import { AccordionPanel } from '@cutover/react-ui'
import { TaskSelectField } from 'main/components/shared/form/task-select'
import { TaskEditFormType } from './task-edit-form'
import { useLanguage } from 'main/services/hooks'
import { TaskModel } from 'main/data-access/models'
import { StreamListStream, TaskType } from 'main/services/queries/types'

type TaskEditDependenciesPanelProps = {
  taskTypeLookup: Record<string, TaskType>
  streamLookup: Record<string, StreamListStream>
  iconColor: string
  successorIds: number[]
  predecessorIds: number[]
  possiblePredecessorsIds: number[]
  readOnly: boolean
}

export const TaskEditDependenciesPanel = ({
  taskTypeLookup,
  streamLookup,
  iconColor,
  successorIds,
  predecessorIds,
  possiblePredecessorsIds,
  readOnly
}: TaskEditDependenciesPanelProps) => {
  const { t } = useLanguage('tasks', { keyPrefix: 'editPanel' })
  const taskLookup = TaskModel.useGetLookup()
  const successorTasks = successorIds.map(id => taskLookup[id])
  const predecessorTasks = predecessorIds.map(id => taskLookup[id])
  const possiblePredecessorsTasks = possiblePredecessorsIds.map(id => taskLookup[id])

  return (
    <AccordionPanel label={t('dependencies.header')} icon="predecessors" iconColor={iconColor}>
      <TaskSelectField<TaskEditFormType>
        label={t('fields.predecessors.label')}
        name="predecessor_ids"
        tasks={readOnly ? predecessorTasks : possiblePredecessorsTasks}
        taskTypeLookup={taskTypeLookup}
        streamLookup={streamLookup}
        placeholderValue={t('fields.predecessors.placeholderValue')}
        placeholder={t('fields.predecessors.placeholder')}
        required={false}
      />
      <TaskSelectField<TaskEditFormType>
        label={t('fields.successors.label')}
        name="successor_ids"
        placeholder={t('fields.predecessors.placeholder')}
        placeholderValue={t('fields.successors.placeholderValue')}
        tasks={successorTasks}
        taskTypeLookup={taskTypeLookup}
        streamLookup={streamLookup}
        readOnly
        required={false}
      />
    </AccordionPanel>
  )
}
