import { Box, Icon, Modal, Text } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'

type LinkableTaskTypeProps = {
  unlinkResource: UnlinkResource[]
  onSubmit: () => void
  onClose: () => void
}
type UnlinkResource = {
  unlinkName: string
  isTemplate: boolean
}

export const UnlinkResourceConfirmationModal = ({ unlinkResource, onSubmit, onClose }: LinkableTaskTypeProps) => {
  const { t } = useLanguage('runbook', { keyPrefix: 'linkableTaskTypeModal' })
  return (
    <Modal
      open
      title={t('title')}
      confirmText={t('save')}
      confirmIcon="arrow-forward"
      cancelText={t('cancel')}
      onClickConfirm={onSubmit}
      onClose={onClose}
    >
      <Text>{t('message')}</Text>
      {/* adding an id here so that we can reference in angular and prevent the save changes modal from opening */}
      <Box direction="column" margin={{ top: '8px' }} id="unlink-confirmation-modal">
        {unlinkResource.map(resource => (
          <Box direction="row" key={resource.unlinkName}>
            <Icon icon={resource.isTemplate ? 'template' : 'runbook'} color="primary" />
            <Text css="padding-left: 4px; overflow: hidden; text-overflow: ellipsis; padding-top: 2px; white-space: nowrap;">
              {resource.unlinkName}
            </Text>
          </Box>
        ))}
      </Box>
    </Modal>
  )
}
