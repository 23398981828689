// @ts-nocheck
import { injectable } from 'inversify'
import { observable, action } from 'mobx'
import { IUserToVerifyDTO } from 'Shared/Entities/Types/UserVerify/IUserToVerifyDTO'
import { IUserToVerifyPM } from 'Shared/Entities/Types/UserVerify/IUserToVerifyPM'
import { Types } from 'Gateways/Service/Types'
import { IServiceGateway } from 'Gateways/Service/IServiceGateway'
import { ErrorAlertPresenter } from 'Components/Molecules'
import { container } from 'app/Config/IOC'
import { IUserToVerifySubmitDTO } from 'Shared/Entities/Types/UserVerify/IUserToVerifySubmitDTO'
import { RoutingState } from 'app/Routing/RoutingState'
import { forceAngularJSLogOut, userVerifySSO } from 'app/Shared/Helpers/SPASharedStateService'

@injectable()
export class UserVerifyRepository {
  @observable
  public userToVerify: IUserToVerifyPM = null

  public serviceGateway: IServiceGateway = container.get(Types.IServiceGateway)

  private routingState: RoutingState = container.get(RoutingState)

  @observable
  public invitationToken: string = ''

  @observable
  public errorAlertPresenter: ErrorAlertPresenter = new ErrorAlertPresenter()

  public verifyUserByInviteToken = async () => {
    this.setInvitationTokenFromUrl()
    const url = 'users/by_invite_token?invitation_token=' + this.invitationToken
    const response = await this.serviceGateway.get(url)
    if (response.success) {
      const userToVerify: IUserToVerifyDTO = response.body.user
      if (!response.body.user) {
        forceAngularJSLogOut()
        return
      }
      this.setUserToVerifyPm(userToVerify)
    }
  }

  @action
  private setUserToVerifyPm = (userToVerify: IUserToVerifyDTO) => {
    this.userToVerify = {
      id: userToVerify.id,
      firstName: userToVerify.first_name,
      lastName: userToVerify.last_name,
      email: userToVerify.email,
      uniqueId: userToVerify.unique_id || '',
      provider: userToVerify.provider,
      countryCode: userToVerify.country_code || '',
      mobileNumber: userToVerify.mobile_number || '',
      invitationToken: this.invitationToken,
      memorableWord: userToVerify.secret_key || '',
      password: userToVerify.password || '',
      passwordConfirmation: userToVerify.password_confirmation || ''
    }
  }

  public submit = async (userToVerify: IUserToVerifyPM) => {
    const userToVerifyDTO: IUserToVerifySubmitDTO = {
      id: userToVerify.id,
      first_name: userToVerify.firstName,
      last_name: userToVerify.lastName,
      input_email: userToVerify.email,
      unique_id: userToVerify.uniqueId || '',
      country_code: userToVerify.countryCode || '',
      mobile_number: userToVerify.mobileNumber || '',
      invitation_token: this.invitationToken || '',
      secret_key: userToVerify.memorableWord || '',
      password: userToVerify.password || undefined,
      password_confirmation: userToVerify.passwordConfirmation || undefined,
      provider: userToVerify.provider
    }

    if (userToVerifyDTO.provider === 'saml') {
      userVerifySSO(userToVerifyDTO)
      return { success: true, samlAuthRequest: true }
    } else {
      return await this.serviceGateway.put('auth/invitation', userToVerifyDTO)
    }
  }

  @action
  public setInvitationTokenFromUrl = () => {
    if (this.routingState.currentState.routeId === 'userVerify') {
      const pathFilters: { [key: string]: string }[] = this.routingState.pathFilters
      this.invitationToken = pathFilters[0].value
    }
  }
}
