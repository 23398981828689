import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Drag = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Drag" {...props}>
    <path d="M17.5 14a3 3 0 1 1 0-6 3 3 0 0 1 0 6Zm0 13a3 3 0 1 1 0-6 3 3 0 0 1 0 6Zm-3 10a3 3 0 1 0 6 0 3 3 0 0 0-6 0Zm16-23a3 3 0 1 1 0-6 3 3 0 0 1 0 6Zm0 13a3 3 0 1 1 0-6 3 3 0 0 1 0 6Zm-3 10a3 3 0 1 0 6 0 3 3 0 0 0-6 0Z" fill="#000" />
  </StyledIcon>
))

Drag.displayName = 'Drag'

export { Drag }
