import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Check = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Check" {...props}>
    <path d="m4.816 23.02 2.122-2.12 12.02 12.02L40.879 11 43 13.121 18.96 37.163 4.816 23.021Z" fill="#000" />
  </StyledIcon>
))

Check.displayName = 'Check'

export { Check }
