import { useMemo } from 'react'
import { format as formatDate } from 'date-fns'
import { sortBy } from 'lodash'

import { BarChartData } from '@cutover/react-ui'
import { INTERVAL_FORMAT_CODE_MAP } from './helpers'
import { TimeIntervalType } from '../types'

type DataGroupMapping<T extends Record<string, unknown>> = Partial<
  Record<keyof Omit<T, 'interval' | 'timestamp'>, string>
>

// use hook for client side rendering code
export function useTimeIntervalBarChart<
  T extends Record<string, unknown> & { timestamp: string; interval: TimeIntervalType }
>(data: T[], mapping: DataGroupMapping<T>): BarChartData[] {
  return useMemo(() => getTimeIntervalBarChart(data, mapping), [data])
}

// use directly for anything requiring SSR
export function getTimeIntervalBarChart<
  T extends Record<string, unknown> & { timestamp: string; interval: TimeIntervalType }
>(data: T[], mapping: DataGroupMapping<T>): BarChartData[] {
  const { interval: _interval, timestamp: _timestamp, ...mapped } = mapping

  return sortBy(data, d => new Date(d.timestamp)).map(d => {
    const item: BarChartData = {
      category: {
        name: d.interval,
        value: formatDate(new Date(d.timestamp), INTERVAL_FORMAT_CODE_MAP[d.interval])
      },
      values: []
    }

    for (const [attribute, group] of Object.entries(mapped)) {
      if (group) {
        item.values.push({
          group,
          value: d[attribute]
        })
      }
    }

    return item
  })
}
