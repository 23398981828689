import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const BrandRelease = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 100 101" a11yTitle="BrandRelease" {...props}>
    <g clipPath="url(#a)" fill="#fff"><path d="M99.647.824H35.463l64.184 64.184V.824ZM67.48 33.04H29.1l38.38 38.38V33.04Z" /><path opacity=".5" d="M.092 100.518h64.185L.092 36.334v64.184Z" /></g>
  </StyledIcon>
))

BrandRelease.displayName = 'BrandRelease'

export { BrandRelease }
