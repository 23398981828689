// @ts-nocheck
import { injectable, inject } from 'inversify'
import { observable, when } from 'mobx'
import { unescape } from 'lodash'
import { PermissableRepository } from 'app/Shared/Repositories/PermissableRepository'
import { IBaseResponse } from 'Gateways/Service/IBaseResponse'
import { IRunbookTypePM } from 'app/Shared/Entities/Types/RunbookType/IRunbookTypePM'
import { QueryStringBuilder } from 'Shared/Helpers/QueryStringBuilder'
import { TDisplaySvgIcons } from 'Components/Atoms'
import { IApprovalFlowDTO } from 'Shared/Entities/Types/ApprovalFlow/IApprovalFlowDTO'

export interface IRunbookTypeDTO {
  id: number
  icon: string
  icon_name: TDisplaySvgIcons
  icon_color: string
  name: string
  description: string
  default: boolean
  archived: boolean
  global: boolean
  account_id: number
  account_name: string
  dynamic: boolean
  incident: boolean
  disabled: boolean
  enable_rto: boolean
  available_approval_flows: IApprovalFlowDTO[]
  approval_flow_id: number
}

@injectable()
export class RunbookTypesRepository extends PermissableRepository {
  @inject(QueryStringBuilder)
  private queryStringBuilder: QueryStringBuilder

  @observable
  public runbookTypesTotal: number

  @observable
  public runbookTypesFilteredTotal: number

  @observable
  public runbookTypes: IRunbookTypePM[] = []

  @observable
  public availableApprovalFlows: [] = []

  constructor() {
    super('meta')
  }

  public preLoad = (args: { [key: string]: any }): string => {
    let sortParams: { [key: string]: any } = null
    if (args && args.sortParams !== null) sortParams = args.sortParams
    const url = 'runbook_types' + this.queryStringBuilder.getQueryString(null, sortParams)
    return url
  }

  public postLoad = (rawData: IBaseResponse) => {
    this.runbookTypesTotal = rawData.body.meta.total_results
    this.runbookTypesFilteredTotal = rawData.body.meta.filtered_results
    this.availableApprovalFlows = rawData.body.meta.available_approval_flows
    this.processRunbookTypes(rawData)
  }

  private getRunbookTypesFromAPIData = (rawData: IBaseResponse) => {
    return rawData.body.runbook_types.map((rbTypeDto: IRunbookTypeDTO): IRunbookTypePM => {
      return {
        id: rbTypeDto.id,
        icon: rbTypeDto.icon,
        iconName: rbTypeDto.icon_name,
        iconColor: rbTypeDto.icon_color,
        name: unescape(rbTypeDto.name),
        description: rbTypeDto.description,
        default: rbTypeDto.default,
        archived: rbTypeDto.archived,
        accountName: rbTypeDto.account_name,
        accountId: rbTypeDto.account_id || 0,
        visibilityType: rbTypeDto.account_id ? 'account' : 'global',
        dynamic: rbTypeDto.dynamic,
        incident: rbTypeDto.incident,
        disabled: rbTypeDto.disabled,
        enableRto: rbTypeDto.enable_rto,
        approvalFlowId: rbTypeDto.approval_flow_id
      }
    })
  }

  public processRunbookTypes = (rawData: IBaseResponse) => {
    this.runbookTypes = this.getRunbookTypesFromAPIData(rawData)
  }

  public getRunbookTypesForAccount = (accountId: number) => {
    return this.runbookTypes.filter(runbookType => runbookType.accountId === 0 || runbookType.accountId === accountId)
  }

  public safeLoad = async () => {
    if (this.runbookTypes.length > 0) return

    if (this.loading) {
      await when(() => !this.loading)
      return this.safeLoad()
    } else {
      await this.loadData()
    }
  }
}
