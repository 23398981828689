import { Dispatch, SetStateAction, useState } from 'react'

import { Box, Loader, Modal, TextInput, useNotify } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { DataSource } from 'main/services/queries/types'
import { useRefresheDataSourceMutation } from 'main/services/queries/use-data-sources'
import { ApiError } from 'main/services/api'

type RefreshDataSourceModalType = {
  dataSource: DataSource
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

export const RefreshDataSourceModal = ({ dataSource, open, setOpen }: RefreshDataSourceModalType) => {
  const notify = useNotify()
  const { t } = useLanguage('dataSources')
  const [input, setInput] = useState('')
  const refreshMutation = useRefresheDataSourceMutation()
  const { isLoading: isRefreshLoading } = refreshMutation

  const handleClose = () => {
    setOpen(false)
    setInput('')
  }

  const handleSubmit = () => {
    const data = { id: dataSource.id as number, query_string: input }
    refreshMutation.mutate(data, {
      onSuccess: response => {
        if (!response) {
          return
        }

        if (response.total_added > 0) {
          notify.success(
            t('dataSources.modals.refreshDataSource.toasters.success', {
              count: response.total_added
            })
          )
          handleClose()
        } else {
          notify.warning(t('dataSources.modals.refreshDataSource.toasters.warning'), { title: 'Datasource Refresh' })
        }
      },
      onError: error => {
        const refreshError = error as ApiError
        notify.error(
          t('dataSources.modals.refreshDataSource.toasters.error', { error: refreshError.errors.join(', ') })
        )
      }
    })
  }

  return (
    <Modal
      open={open}
      onClickConfirm={handleSubmit}
      onClose={handleClose}
      title={t('dataSources.modals.refreshDataSource.title', { name: dataSource.name })}
      confirmIcon={'refresh'}
      confirmText={t('dataSources.modals.refreshDataSource.confirmLabel')}
      loading={isRefreshLoading}
    >
      <Box>
        <TextInput
          name={'query_string'}
          onInput={e => setInput(e.currentTarget.value)}
          required
          icon={'search'}
          placeholder={t('dataSources.formData.query.placeholder')}
          label={t('dataSources.formData.query.label', { interpolation: { skipOnVariables: true } })}
        />
        {isRefreshLoading && (
          <>
            <Loader />
          </>
        )}
      </Box>
    </Modal>
  )
}
