// @ts-nocheck
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { colors, px2rem } from 'app/Styles/Variables'
import { Button, Popover } from 'Components/Molecules'
import { RunbookUsersPageHeadingOptionsPresenter } from './RunbookUsersPageHeadingOptionsPresenter'
import { Modal } from 'Components/Atoms'
import { ExportUsersModal } from 'Components/Templates'

const BorderedButtonWrapper = styled.div`
  border-top: ${px2rem(1)} solid ${colors.primaryGreyBackgroundColor};
`

interface IRunbookUsersPageHeadingExtraOptionsComponent {
  presenter: RunbookUsersPageHeadingOptionsPresenter
}

export const RunbookUsersPageHeadingOptionsComponent = observer<IRunbookUsersPageHeadingExtraOptionsComponent>(
  ({ presenter }) => {
    const {
      optionsPopoverPresenter,
      exportModalOpenButton,
      exportModalPresenter,
      exportUsersModalPresenter,
      reloadButton,
      optionsOpen,
      setOptionsOpen,
      language
    } = presenter

    const moreOptionsToolTipText = language.get('common:moreOptions')

    return (
      <>
        <Popover
          presenter={optionsPopoverPresenter}
          open={false}
          controlledOpen={optionsOpen}
          moreOptionsToolTipText={moreOptionsToolTipText}
          customTriggerCallback={() => {
            setOptionsOpen(true)
          }}
          type="customContent"
          content={
            <>
              <Button presenter={exportModalOpenButton} extraStyles={{ width: px2rem(280) }} />

              <BorderedButtonWrapper>
                <Button presenter={reloadButton} extraStyles={{ width: px2rem(280) }} />
              </BorderedButtonWrapper>
            </>
          }
          wrapperExtraStyles={{ marginTop: px2rem(20), display: 'inline-block' }}
        />
        <Modal presenter={exportModalPresenter}>
          <ExportUsersModal presenter={exportUsersModalPresenter} />
        </Modal>
      </>
    )
  }
)
