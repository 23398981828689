// @ts-nocheck
import { observable, when, computed, action } from 'mobx'
import { container } from 'app/Config/IOC'
import { UserRepository } from 'app/Repositories/User/UserRepository'
import { AccordionPresenter, RoleTypesPresenter } from 'Components/Molecules'
import { IUserPM } from 'Shared/Entities/Types/IUserPM'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'
import { Types } from 'Gateways/Service/Types'

export class UserEditRolesPresenter {
  @observable
  private userRepository: UserRepository = container.get(UserRepository)

  public langauge: ILanguageService = container.get(Types.ILanguageService)

  @observable
  public roleTypePresenter = new RoleTypesPresenter('user', true, ['create', 'show'])

  @observable
  public accordionPresenter = new AccordionPresenter(this.langauge.get('users:edit:roles:label')).withLeftIconName(
    'lock'
  )

  @observable
  public confirmedReset = false

  public load = async () => {
    await when(() => this.roleTypePresenter.roleTypePresenters.length > 0)
  }

  @action
  public reset = () => {
    this.roleTypePresenter.reset()
    this.confirmedReset = false
  }

  @action
  public closeAccordion = () => {
    this.accordionPresenter.closeAccordion()
  }

  public setEditableUser = (user: IUserPM) => {
    this.reset()

    if (user.roles.length > 0) {
      this.roleTypePresenter.prepopulateRoles(user.roles)
    }
  }

  @computed
  public get isDirty(): boolean {
    return this.roleTypePresenter.isDirty
  }

  @computed
  public get isReset(): boolean {
    return this.roleTypePresenter.selectedRoles.length === 0 && this.userRepository.userForEdit.roles.length > 0
  }

  public setDisabled = (disabled: boolean) => {
    this.roleTypePresenter.setDisabled(disabled)
  }
}
