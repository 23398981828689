import { colors, LegacyFlex as Flex, LegacyText as Text } from '@cutover/react-ui'

export type DurationDisplayProps = {
  duration: string
  delta?: {
    duration: string
    percentage: number
  } | null
}

export const DurationDisplay = ({ duration, delta }: DurationDisplayProps) => {
  return (
    <Flex gap="10px" flexDirection="column" justifyContent="center" alignItems="center">
      <Text title={duration} size={32} height="2rem" weight={300} color={colors.primaryGreyHover} truncate>
        {duration}
      </Text>
      {delta && delta.duration && delta.percentage !== 0 ? (
        <DurationDelta duration={delta.duration} percentage={delta.percentage} />
      ) : null}
    </Flex>
  )
}

type DurationDeltaProps = {
  duration: string
  percentage: number
}

const DurationDelta = ({ duration, percentage }: DurationDeltaProps) => {
  if (percentage >= 0) {
    return (
      <Text color={colors.error}>
        {duration} {'longer than planned'}
      </Text>
    )
  }

  return (
    <Text color="green">
      {duration} {'shorter than planned'}
    </Text>
  )
}
