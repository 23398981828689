import { ReactNode, useEffect, useState } from 'react'
import { ConnectorEvents, ConnectorMountEvents, eventManager } from 'event-manager'
import ReactDOM from 'react-dom'

type AngularReactConnectorProps<
  TMountEvent extends keyof ConnectorMountEvents,
  TUnmountEvent extends keyof ConnectorEvents
> = {
  mountEvent: TMountEvent
  unmountEvent: TUnmountEvent
  onMount?: (args: ConnectorMountEvents[TMountEvent]) => void
  onUnmount?: () => void
  children: ReactNode
}

export function ReactAngularConnector<
  TMountEvent extends keyof ConnectorMountEvents,
  TUnmountEvent extends keyof ConnectorEvents
>({ mountEvent, unmountEvent, onMount, onUnmount, children }: AngularReactConnectorProps<TMountEvent, TUnmountEvent>) {
  const [container, setContainer] = useState<HTMLElement | null>(null)

  useEffect(() => {
    const handleConnect: typeof onMount = args => {
      setContainer(args.elementForMount)
      onMount?.(args)
    }
    const handleDisconnect: typeof onUnmount = () => {
      setContainer(null)
      onUnmount?.()
    }

    eventManager.on(mountEvent, handleConnect)
    eventManager.on(unmountEvent, handleDisconnect)

    return () => {
      eventManager.off(mountEvent, handleConnect)
      eventManager.off(unmountEvent, handleDisconnect)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <>{container && ReactDOM.createPortal(children, container)}</>
}

export function useConnectedEvent<TConnectorEvent extends keyof ConnectorEvents>(
  event: TConnectorEvent,
  handler: (args: ConnectorEvents[TConnectorEvent]) => void
) {
  useEffect(() => {
    eventManager.on(event, handler)

    return () => {
      eventManager.off(event, handler)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
