import { useContext } from 'react'

import { DonutChart, InteractiveDonutChart } from '@cutover/react-ui'
import { MrdDashboardWidget } from './mrd-dashboard-widget'
import { getFilterParams } from './mrd-filter-util'
import { getDonutChartData, useDonutChart } from './use-donut-chart'
import { useAngularRightSidePanel } from '../../../../services/hooks'
import { DashboardContext } from '../../runbooks-dashboard-page'
import { DashboardComponentProps, MrdDonutData } from '../types'
import { useSetActiveRightPanelState } from 'main/components/layout/right-panel'
import { ConfigModel } from 'main/data-access'

// ONLY BECAUSE OF LATENESS - very inconsistent / no pattern way of handling subFilterKey and subFilterValue
const LATENESS_SUB_FILTER_KEY_MAPPING: Record<
  string,
  { latenessSubFilterKey: string; latenessSubFilterValue: string | boolean }
> = {
  ontime: { latenessSubFilterKey: 'ontime', latenessSubFilterValue: true },
  late: { latenessSubFilterKey: 'latelessthan', latenessSubFilterValue: '3600' },
  vlate: { latenessSubFilterKey: 'latemorethan', latenessSubFilterValue: '3600' },
  other: { latenessSubFilterKey: 'display_stage', latenessSubFilterValue: 'planning' }
}

const getFilterValueFromSubfilter = (subFilter: string | number) => {
  // if string that is an array e.g. '76,77,78'
  if (typeof subFilter === 'string' && subFilter.includes(',')) {
    return subFilter.split(',')
  }

  // e.g. f[44]: [75]
  return [subFilter]
}

// Note: centralized wrappers for mrd dashboard donuts
// interactive for browser & simple donut for server
export const BrowserWidget = ({ data }: DashboardComponentProps<MrdDonutData>) => {
  const { accountId, dashboardId, filterParams } = useContext(DashboardContext)
  const { filter_key: filterKey, filters, threshold } = data
  const isReactWorkspaceEnabled = ConfigModel.useIsFeatureEnabled('react_workspace')

  let additionalFilters: { [key: string]: string | string[] } = {}

  if (filters) {
    additionalFilters =
      filters.resource_type === 'CustomField'
        ? { [`f[${filters.resource_id}]`]: filters.value }
        : filters.resource_type === 'Stage'
        ? { display_stage: [filters.value] }
        : { [filters.resource_type.toLowerCase()]: filters.value }
  }

  const { openAngularSidePanel } = useAngularRightSidePanel()
  const { openRightPanel } = useSetActiveRightPanelState()

  const handleSegmentClick = (segmentNode: any) => {
    let subFilterKey = filterKey
    let subFilterValue = segmentNode.id

    if (data.resource_type == 'CustomField') {
      subFilterKey = `f[${data.filter_key}]`
      subFilterValue = subFilterValue === 'not_set' ? 0 : subFilterValue
    }

    if (data.type === 'lateness') {
      const { latenessSubFilterKey, latenessSubFilterValue } = LATENESS_SUB_FILTER_KEY_MAPPING[segmentNode.id]

      if (latenessSubFilterKey === 'latelessthan' || latenessSubFilterKey === 'latemorethan') {
        subFilterValue = threshold ? threshold : latenessSubFilterValue // if threshold provided, use it, otherwise just use default
      } else {
        subFilterValue = latenessSubFilterValue
      }

      subFilterKey = latenessSubFilterKey
    }

    const contentId = {
      accountId,
      dashboardId,
      template_type: 'off',
      ...getFilterParams(filterParams),
      ...{ [subFilterKey]: getFilterValueFromSubfilter(subFilterValue) },
      subFilterValue: subFilterValue,
      subFilterKey: subFilterKey,
      ...additionalFilters
    }

    if (isReactWorkspaceEnabled) {
      openRightPanel({ type: 'dashboard-runbook-list', params: contentId })
    } else {
      openAngularSidePanel({ content: 'filtered-changes', contentId })
    }
  }

  const { name, total, values, notes, errors } = useDonutChart(data)
  return (
    <MrdDashboardWidget title={name} notes={notes} errors={errors}>
      <InteractiveDonutChart
        total
        centerValue={total}
        data={values}
        height={125}
        onSegmentClick={handleSegmentClick}
        hover
      />
    </MrdDashboardWidget>
  )
}

export const EmailWidget = ({ data }: DashboardComponentProps<MrdDonutData>) => {
  const { name, total, values } = getDonutChartData(data)

  return (
    <MrdDashboardWidget title={name}>
      <DonutChart total centerValue={total} data={values} height={125} />
    </MrdDashboardWidget>
  )
}

export const PrintWidget = ({ data }: DashboardComponentProps<MrdDonutData>) => {
  const { name, total, values } = getDonutChartData(data)

  return (
    <MrdDashboardWidget title={name}>
      <DonutChart total centerValue={total} data={values} height={125} />
    </MrdDashboardWidget>
  )
}
