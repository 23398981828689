// @ts-nocheck
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { Box } from '@cutover/react-ui'
import { px2rem } from 'app/Styles/Variables'
import { UserEditSettingsPresenter } from 'app/Access/Users/UsersPage/UsersEdit/UserEditSettingsPresenter'
import { Accordion, Checkbox } from 'Components/Molecules'
import { RadioButtons } from 'Components/Atoms'

interface IUserEditSettingsComponentProps {
  presenter: UserEditSettingsPresenter
}

export const UserEditSettingsSection = observer<IUserEditSettingsComponentProps>(({ presenter }) => {
  const {
    accordionPresenter,
    showHtmlPreference,
    loginOptionPresenter,
    smsPreferencePresenter,
    emailPreferencePresenter,
    htmlEmailPreferencePresenter
  } = presenter

  return (
    <>
      <Accordion presenter={accordionPresenter}>
        {<RadioButtons presenter={loginOptionPresenter} />}
        <Box margin={{ top: '16px' }}>
          <Checkbox presenter={smsPreferencePresenter} />
          <Checkbox presenter={emailPreferencePresenter} />
          {showHtmlPreference && <Checkbox presenter={htmlEmailPreferencePresenter} />}
        </Box>
      </Accordion>
    </>
  )
})
