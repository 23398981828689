// @ts-nocheck
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { withPresenter } from 'app/Config/withPresenter'
import { RoleTypesPageHeadingPresenter } from './RoleTypesPageHeadingPresenter'
import { HeadingTitle, HeaderFilterIcon } from 'Components/Templates'
import { PillFilter } from 'Components/Molecules'
import { buildFractionString } from 'Shared/HooksAndPresenters/HeadingSection'
import { container } from 'app/Config/IOC'
import { Types } from 'Gateways/Service/Types'
import { ILanguageService } from 'Helpers/Language/ILanguageService'

const HeaderWrapper = styled.div`
  width: 100%;
  height: 100%;
`

interface IRoleTypesPageHeadingComponentProps {
  filterOpen: boolean
  setFilterOpen: (val: boolean) => void
  presenter: RoleTypesPageHeadingPresenter
}

const RoleTypesPageHeadingSection = observer<IRoleTypesPageHeadingComponentProps>(
  ({ presenter, filterOpen, setFilterOpen }) => {
    const { filteredRoleTypeCount, totalRoleTypeCount, contextPillPresenter } = presenter

    const { countString } = buildFractionString(filteredRoleTypeCount, totalRoleTypeCount)

    const contextPill = contextPillPresenter && <PillFilter presenter={contextPillPresenter} />

    const language: ILanguageService = container.get(Types.ILanguageService)

    return (
      <HeaderWrapper>
        <HeaderFilterIcon filterOpen={filterOpen} setFilterOpen={setFilterOpen} />
        <HeadingTitle title={language.get('roleTypes:heading.title')} count={countString} pills={contextPill} />
      </HeaderWrapper>
    )
  }
)

export default withPresenter(RoleTypesPageHeadingPresenter)(RoleTypesPageHeadingSection)
