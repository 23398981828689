import { Fragment, KeyboardEvent, memo, useCallback } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import styled from 'styled-components/macro'

import { baseTabButtonStyles, tabActiveButtonStyles, tabInactiveButtonStyles } from './tab-styles'
import { Badge, BadgeProps } from '../badge'
import { Button, IconButton } from '../button'
import { Icon, IconName } from '../icon'
import { Box } from '../layout/box'
import { Menu, MenuListItem, MenuListItemProps } from '../menu'
import { Pill } from '../pill'
import { themeEdgeSize } from '../theme'
import { Text } from '../typography'

type TabProps = {
  active?: boolean
  hasError?: boolean
  isNew?: boolean
  onClick?: () => void
  linkTo?: string
  maxWidth?: number
  label: string
  badge?: BadgeProps
  icon?: IconName
  menuItems?: MenuListItemProps[]
  'aria-controls'?: string
  id?: string
}

export type TabItem = TabProps

export const Tab = memo(
  ({ active, hasError, isNew, onClick, linkTo, maxWidth, label, badge, icon, menuItems, ...props }: TabProps) => {
    const navigate = useNavigate()

    const handleKeydownLink = useCallback(
      (event: KeyboardEvent<HTMLAnchorElement>) => {
        if (event.key === 'Enter' || event.key === 'Space' || event.key === ' ') {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          navigate(linkTo!)
        }
        return true
      },
      [navigate]
    )

    // you can stil pass a click handler if it's a link for additional functionality
    const handleClickLink = useCallback(() => {
      onClick?.()
      // need to return true for the link to continue when also adding cilck handler
      return true
    }, [onClick])

    const itemLabel = (
      <Box direction="row" align="center" gap="xxsmall" height="34px">
        {icon && <Icon icon={icon} size="medium" color={active ? 'primary' : 'text-light'} aria-hidden="true" />}

        <Text
          truncate="tip"
          aria-label={label}
          css={`
            max-width: ${maxWidth ? maxWidth + 'px' : undefined};
            padding: 0 ${themeEdgeSize('xxsmall')};
            padding-right: ${menuItems && '0'};
          `}
        >
          {label}
        </Text>
        {isNew && <Pill label="New" size="small" color="feature-pink" />}
        {hasError && <Icon icon="alert" size="medium" color="error" />}
      </Box>
    )

    const content =
      !!menuItems && !!linkTo ? (
        <Link
          id={props.id}
          aria-controls={props['aria-controls']}
          onClick={onClick ? handleClickLink : undefined}
          onKeyDown={handleKeydownLink}
          to={linkTo}
          role="tab"
          aria-selected={!!active}
          css={`
            text-decoration: none;
          `}
        >
          <TabText active={active}>
            {itemLabel}
            <Menu
              trigger={
                <IconButton
                  icon="caret-down"
                  label="Menu"
                  size="medium"
                  disableTooltip
                  onClick={e => {
                    e.stopPropagation()
                    e.preventDefault()
                  }}
                  css="margin: 0 -4px 0 -4px;"
                />
              }
            >
              {menuItems.map(menuItem => (
                <MenuListItem
                  key={menuItem.label}
                  icon={menuItem.icon}
                  label={menuItem.label}
                  selected={menuItem.selected}
                  onClick={e => {
                    e.syntheticEvent.stopPropagation()
                    e.syntheticEvent.preventDefault()
                    menuItem.onClick?.(e)
                  }}
                />
              ))}
            </Menu>
          </TabText>
        </Link>
      ) : !!menuItems ? (
        <Menu
          trigger={
            <Box
              aria-selected={!!active}
              role="tab"
              direction="row"
              gap="8px"
              align="center"
              height="100%"
              flex={{ shrink: 0 }}
              aria-controls={props['aria-controls']}
              id={props.id}
            >
              <TabText active={active} css="padding-right: 4px">
                {itemLabel}
                <Icon aria-hidden icon="caret-down" size="small" color={active ? 'primary' : 'text-light'} />
              </TabText>
            </Box>
          }
        >
          {menuItems.map(menuItem => (
            <MenuListItem
              key={menuItem.label}
              icon={menuItem.icon}
              label={menuItem.label}
              selected={menuItem.selected}
              onClick={menuItem.onClick}
            />
          ))}
        </Menu>
      ) : !!linkTo ? (
        <Link
          id={props.id}
          aria-controls={props['aria-controls']}
          onClick={onClick ? handleClickLink : undefined}
          onKeyDown={handleKeydownLink}
          to={linkTo}
          role="tab"
          aria-selected={!!active}
          css={`
            text-decoration: none;
          `}
        >
          <TabText active={active}>{itemLabel}</TabText>
        </Link>
      ) : (
        <TabButton
          id={props.id}
          aria-controls={props['aria-controls']}
          active={active}
          onClick={onClick}
          label={itemLabel}
        />
      )

    return (
      <>
        {badge ? (
          <Badge key={label} {...badge}>
            {content}
          </Badge>
        ) : (
          <Fragment key={label}>{content}</Fragment>
        )}
      </>
    )
  }
)

export const TabButton = styled(Button).attrs(props => ({
  plain: true,
  role: 'tab',
  'aria-selected': !!props.active
}))<{ active?: boolean }>`
  ${baseTabButtonStyles}
  ${({ active }) => (active ? tabActiveButtonStyles : tabInactiveButtonStyles)}
`

export const TabText = styled(Text)<{ active?: boolean }>`
  ${baseTabButtonStyles}
  display: flex;
  gap: 4px;
  align-items: center;
  height: 100%;
  ${({ active }) => (active ? tabActiveButtonStyles : tabInactiveButtonStyles)}
`
