import { useMutation, UseMutationOptions } from 'react-query'

import { apiClient, ApiError } from '../api'
import { RoleType } from 'main/services/queries/types'

export type PrepareBulkEditRunbooksResponse = {
  editable_attrs: string[]
  errors: string[]
  ids_count: number
  in_run_ids: number[]
  role_types: RoleType[]
  unpermitted_runbook_ids: number[]
}

type PrepareBulkEditRunbooksPayload = {
  runbook_ids: number[]
}

export const usePrepareBulkEditRunbooks = (
  options: UseMutationOptions<PrepareBulkEditRunbooksResponse, ApiError, PrepareBulkEditRunbooksPayload> = {}
) => {
  return useMutation<PrepareBulkEditRunbooksResponse, ApiError, PrepareBulkEditRunbooksPayload>(
    'prepare-bulk-update',
    async payload => {
      const { data } = await apiClient.patch<PrepareBulkEditRunbooksPayload, PrepareBulkEditRunbooksResponse>({
        url: 'runbooks/prepare_bulk_update',
        data: payload // runbookIds
      })
      return data as PrepareBulkEditRunbooksResponse
    },
    options
  )
}
