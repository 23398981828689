import { FocusEventHandler, memo, Ref, useCallback, useEffect, useRef, useState } from 'react'
import { debounce } from 'lodash'
import { useMergeRefs } from 'use-callback-ref'

import { Box, duration as durationFormatter, ListItem, MultiSelect, Text, useNotify } from '@cutover/react-ui'
import {
  SearchableCustomFieldOption,
  SearchableCustomFieldResponse
} from 'main/services/api/data-providers/user/user-channel-response-types'
import { useLanguage, useUserWebsocket } from 'main/services/hooks'
import { CustomField } from 'main/services/queries/types'
import {
  DataSourceRefreshRequestType,
  DataSourceSearchFormType,
  useRefreshDataSourceMutation,
  useSearchDataSourceMutation
} from 'main/services/queries/use-data-sources'

const DEBOUNCE_TIME_MILIS = 300
const MIN_CHARS = 1

// TODO: check ms graph handling in searchable_custom_field_display_directive.js line 219

type SearchableCustomFieldProps = {
  customField: CustomField
  value?: SearchableCustomFieldOption[]
  multiSelect?: boolean
  onChange: (value?: SearchableCustomFieldOption[]) => void
  hasError?: boolean
  inlineError?: string
  required?: boolean
  disabled?: boolean
  readOnly?: boolean
  inputRef?: Ref<HTMLInputElement>
  onBlur?: FocusEventHandler<HTMLInputElement>
  taskId?: number | string
  hideDependentFields?: boolean
}

export const SearchableCustomField = ({ value, onChange, multiSelect, ...props }: SearchableCustomFieldProps) => {
  const [selectValue, setSelectValue] = useState<SearchableCustomFieldOption[] | undefined>(value ?? [])

  useEffect(() => {
    setSelectValue(value?.filter(item => !item.destroy) ?? [])
  }, [value])

  // TODO: kept as it was for now but we shouldn't be mutating these items
  const updateItemsForDestroy = (selectedItems?: SearchableCustomFieldOption[]) => {
    const selectedItemPrimaryKeys = selectedItems?.map(item => item.primaryKey) ?? []
    const items = selectedItems ?? []

    value?.forEach(item => {
      const shouldDestroy = !selectedItemPrimaryKeys.includes(item.primaryKey) && item.id

      if (shouldDestroy) {
        item.destroy = true
        items.push(item)
      }
    })

    return items
  }

  return (
    <CustomSelect
      {...props}
      value={selectValue}
      onChange={val => {
        const nextVal = multiSelect ? val : !val?.length ? [] : [val[val.length - 1]]
        onChange?.(updateItemsForDestroy(nextVal))
      }}
      multiSelect={multiSelect}
    />
  )
}

const CustomSelect = memo(
  ({
    customField,
    value = [],
    multiSelect = false,
    onChange,
    hasError,
    inputRef: forwardedRef,
    inlineError,
    disabled,
    readOnly,
    required,
    onBlur,
    taskId,
    hideDependentFields = false
  }: SearchableCustomFieldProps) => {
    const { t } = useLanguage('customFields')
    const localRef = useRef<HTMLInputElement>(null)
    const inputRef = useMergeRefs(forwardedRef ? [localRef, forwardedRef] : [localRef])
    const [loading, setLoading] = useState(false)
    const [canShowNoResults, setCanShowNoResults] = useState(false)
    const [inputValue, setInputValue] = useState('')
    const [refreshedAtString, setRefreshedAtString] = useState('')
    const [refreshButtonText, setRefreshButtonText] = useState<string>(t('edit.searchableCustomField.refresh'))
    const [options, setOptions] = useState<SearchableCustomFieldOption[]>([])
    const search = useSearchDataSourceMutation()
    const refresh = useRefreshDataSourceMutation()
    const { listen } = useUserWebsocket()
    const notify = useNotify()
    const showRefreshMessage = value.length > 0 && value[0].updatedAt

    const updateData = async (query: string) => {
      const isEmpty = query?.trim()?.length === 0

      if (isEmpty) {
        setOptions([])
        return
      }

      const data: DataSourceSearchFormType = {
        customFieldId: customField.id,
        query: query,
        taskId: Number(taskId)
      }

      search.mutate(data, {
        onError: () => notify.error(t('edit.notification.refreshError'))
      })
    }

    // TODO this function would likely be useful elsewhere, so should move to a central location
    // Note: this generates a compact duration distance with single unit precision, eg 'Updated 4h ago'
    const setFormattedRefreshedAt = useCallback((date: Date) => {
      let refreshedAtString = ''
      const now = new Date()
      const secondsDiff = (now.getTime() - date.getTime()) / 1000
      if (secondsDiff < 60) {
        refreshedAtString = t('edit.searchableCustomField.dateDistanceNow')
      } else {
        refreshedAtString = t('edit.searchableCustomField.dateDistance', {
          distance: durationFormatter(secondsDiff, 1)
        })
      }
      setRefreshedAtString(refreshedAtString)
    }, [])

    const handleInputChange = debounce((input: string) => {
      if (input.length < MIN_CHARS) {
        setCanShowNoResults(false)
      } else {
        setLoading(true)
      }
      setInputValue(input)
      updateData(input)
    }, DEBOUNCE_TIME_MILIS)

    const handleRefresh = () => {
      setRefreshButtonText(t('edit.searchableCustomField.refreshing'))
      const data: DataSourceRefreshRequestType = {
        customFieldId: customField.id,
        values: []
      }
      value.map(option => {
        if (option.id) {
          data.values.push({
            query: option.primaryKey,
            field_value_id: option.id
          })
        }
      })

      refresh.mutate(data, {
        onSuccess: () => {
          setFormattedRefreshedAt(new Date())
          notify.success(t('edit.notification.refreshSuccess'))
          setRefreshButtonText(t('edit.searchableCustomField.refresh'))
        },
        onError: () => {
          notify.error(t('edit.notification.refreshError'))
          setRefreshButtonText(t('edit.searchableCustomField.refresh'))
        }
      })
    }

    const unsetLoading = debounce(
      () => {
        setLoading(false)
        setCanShowNoResults(true)
      },
      DEBOUNCE_TIME_MILIS / 2,
      { leading: true }
    )

    useEffect(() => {
      const handleUserChannelUpdate = (data: SearchableCustomFieldResponse) => {
        const results = data?.results?.map(d => {
          return {
            primaryKey: d.values[customField.display_name || customField.name],
            dataSourceValueId: d.id,
            values: d.values
          }
        })
        const searchableFieldId = data.meta.headers.searchable_field_id
        if (Number(searchableFieldId) === customField.id) {
          setOptions(results ?? [])
        } else {
          setOptions([])
        }
        unsetLoading()
      }

      setFormattedRefreshedAt(value[0]?.updatedAt ? new Date(value[0]?.updatedAt) : new Date())

      listen(data => {
        handleUserChannelUpdate(data as SearchableCustomFieldResponse)
      })
    }, [])

    return (
      <Box
        css={`
          [aria-label='close'] {
            display: none;
          }
        `}
      >
        <MultiSelect
          label={customField.display_name || customField.name}
          inlineError={inlineError}
          hasError={hasError}
          required={required}
          onInputChange={value => {
            setCanShowNoResults(false)
            handleInputChange(value)
          }}
          options={options}
          value={value}
          single={!multiSelect}
          icon="search"
          onChange={onChange}
          onBlur={onBlur}
          resetInputOnBlur
          inputRef={inputRef}
          loading={loading}
          disabled={disabled}
          minChars={MIN_CHARS}
          emptyMessage={canShowNoResults ? t('edit.searchableCustomField.noResults') : null}
          valueKey="primaryKey"
          labelSuffix={showRefreshMessage ? refreshedAtString : undefined}
          onClickLabelSuffixButton={showRefreshMessage ? handleRefresh : undefined}
          labelSuffixButtonText={refreshButtonText}
          readOnly={readOnly}
          placeholder={
            !inputValue && (value?.length ?? 0 > 0)
              ? `Start typing ${customField.display_name || customField.name}…`
              : undefined
          }
          renderOption={(opt, { selected, highlighted, renderLocation, onDeselect }) => {
            const dependentValues = (key: string, { [key]: _, ...rest }) => rest
            const itemData = Object.entries(
              dependentValues((customField.display_name || customField.name) ?? '', opt.values)
            ).sort((a, b) => a[0].localeCompare(b[0]))
            const handleRemove = onDeselect ? () => onDeselect(opt) : undefined

            const extraContent = itemData
              .filter(([key]: [key: string, value: string]) => key !== customField.name)
              .map(([key, value]: [key: string, value: string]) => (
                <Text tag="div" key={key} size="13px" color="text-light" css="text-wrap: wrap; padding: 2px 0">
                  <strong>{key}</strong>: {value}
                </Text>
              ))

            // Generate a subtitle from the first 2 key/value pairs.
            // Note: this is not ideal since we have no measure of priority. Should make this customisable
            const subTitle = itemData.length > 0 ? itemData[0][1] : undefined

            // Note: These are currently the only multiselect items that have a grey bg when selected.
            // If this is going to continue, need to define a rule/reason why as can't be randomly different
            return (
              <ListItem
                onClickRemove={handleRemove}
                active={highlighted || selected}
                size={hideDependentFields ? 'medium' : 'large'}
                title={opt.primaryKey}
                subTitle={!hideDependentFields ? subTitle : undefined}
                prominence="high"
                expandable={!hideDependentFields}
                expandableContent={!hideDependentFields ? <>{extraContent}</> : undefined}
                css={`
                  margin-bottom: ${renderLocation === 'above' ? '1px' : undefined};
                `}
              />
            )
          }}
        />
      </Box>
    )
  }
)
