import { useCallback } from 'react'
import { mapValues } from 'lodash'
import { useQuery, useQueryClient } from 'react-query'

import { CurrentUserModel } from 'main/data-access'

type PermissionsScope = string
type CurrentUserPermissions = Record<PermissionsScope, { [permission: string]: boolean }>
type PermissionsMeta = Record<string, number[]>

/**
 * @example
 *   const permissions = usePermissions('accounts')
 *   permissions('create') // => true/false
 */
export const usePermissions = (scope: PermissionsScope) => {
  const { data: permissions } = usePermissionsQuery()

  return useCallback(
    (permission: string) => {
      return permissions?.[scope]?.[permission] ?? false
    },
    [permissions]
  )
}

/**
 * @example
 *   const setPermissions = useSetPermissions('accounts')
 *   // ... then in the query's onSuccess:
 *   setPermissions(data.meta.permissions)
 */
export const useSetPermissions = (scope: PermissionsScope) => {
  const currentUser = CurrentUserModel.useGet()
  const queryClient = useQueryClient()

  return (meta: PermissionsMeta) => {
    const existingPermissions =
      queryClient.getQueryData<CurrentUserPermissions>(['permissions', String(currentUser.id)]) ?? {}
    // TODO: if this is always just a single element array then we can just reach in and grab the first vs iterating with includes
    const scopedPermission = mapValues(meta, ids => ids.includes(currentUser.id))

    queryClient.setQueryData<CurrentUserPermissions>(['permissions', String(currentUser.id)], {
      ...existingPermissions,
      [scope]: scopedPermission
    })
  }
}

// Make this a query so we can subscribe to updates from useSetPermissions
const usePermissionsQuery = () => {
  const currentUserId = CurrentUserModel.useId()
  const queryClient = useQueryClient()

  return useQuery(['permissions', String(currentUserId)], () => {
    return queryClient.getQueryData<CurrentUserPermissions>(['permissions', String(currentUserId)])
  })
}
