import { memo } from 'react'
import styled, { keyframes } from 'styled-components/macro'
import { LiteralUnion } from 'type-fest'

import { IconProps as BaseIconProps, ICON_NAME_MAP, IconName } from '@cutover/icons'
import { ThemeType, useTheme } from '../theme'
import { ColorProp } from '../theme/color'

export type { IconName } from '@cutover/icons'

export type IconProps = Omit<BaseIconProps, 'size' | 'color'> & {
  icon: IconName
  size?: LiteralUnion<keyof ThemeType['icon']['size'], string>
  color?: LiteralUnion<ColorProp, string>
  className?: string
}

export const Icon = memo(({ icon, size = 'large', color, ...props }: IconProps) => {
  const theme = useTheme()
  const Component = ICON_NAME_MAP[icon]

  if (!ICON_NAME_MAP.hasOwnProperty(icon)) {
    console.warn(
      `Icon: icon ${icon} does not exist in the icon map. Make sure it is added properly to the @cutover/icons package and the generator script is run`
    )
    return null
  } else {
    return Component ? (
      <Component
        aria-hidden
        role="presentation"
        focusable="false"
        {...props}
        size={size}
        theme={theme}
        color={color as any}
      />
    ) : null
  }
})

const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`

export const SpinnerIcon = styled(Icon).attrs(() => ({ icon: 'spinner' }))`
  animation: ${spin} 0.75s linear infinite;
`
