import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const GroupY = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="GroupY" {...props}>
    <path d="M24 17a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm0 10a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" fill="#000" /><path d="M15 14a9 9 0 1 1 18 0v10a9 9 0 1 1-18 0V14Zm15 0a6 6 0 0 0-12 0v10a6 6 0 0 0 12 0V14Zm-3 26a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" fill="#000" />
  </StyledIcon>
))

GroupY.displayName = 'GroupY'

export { GroupY }
