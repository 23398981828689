import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const RunbookCompleted = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="RunbookCompleted" {...props}>
    <path d="M14 9h20a5 5 0 0 1 5 5v20a5 5 0 0 1-5 5H14a5 5 0 0 1-5-5v-1H6v1a8 8 0 0 0 8 8h20a8 8 0 0 0 8-8V14a8 8 0 0 0-8-8H14a8 8 0 0 0-8 8v1h3v-1a5 5 0 0 1 5-5Z" fill="#000" /><path d="M3 19h9v3H3v-3Zm9 7H3v3h9v-3Zm4.121-3.515L14 24.607l7.485 7.485 13.97-13.97L33.336 16l-11.85 11.85-5.364-5.365Z" fill="#000" />
  </StyledIcon>
))

RunbookCompleted.displayName = 'RunbookCompleted'

export { RunbookCompleted }
