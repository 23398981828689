import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const BracketPlus = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="BracketPlus" {...props}>
    <path d="M14 6h2v3h-2a5 5 0 0 0-5 5v20a5 5 0 0 0 5 5h2v3h-2a8 8 0 0 1-8-8V14a8 8 0 0 1 8-8Zm20 33h-2v3h2a8 8 0 0 0 8-8V14a8 8 0 0 0-8-8h-2v3h2a5 5 0 0 1 5 5v20a5 5 0 0 1-5 5Z" fill="#000" /><path d="M25.5 15.5h-3v7h-7v3h7v7h3v-7h7v-3h-7v-7Z" fill="#000" />
  </StyledIcon>
))

BracketPlus.displayName = 'BracketPlus'

export { BracketPlus }
