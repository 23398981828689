import { ReactElement, ReactNode } from 'react'

import { LegacyBox as Box, colors } from '@cutover/react-ui'

export type TableProps<TData extends { id: string | number }> = {
  headers: string[]
  data: TData[]
  renderHeader?: (value: string, index: number) => ReactElement | null
  renderRowCells: (data: TData, index: number) => (ReactNode | null)[]
  onRowSelected?: (id: string | number) => void
}

export function Table<TData extends { id: string | number }>({
  headers,
  data,
  renderHeader = (v, i) => <th key={i}>{v}</th>,
  renderRowCells,
  onRowSelected
}: TableProps<TData>) {
  const border = '2px solid #e5e5e5' // TODO: colors not in palette

  return (
    <Box
      as="table"
      css={{
        width: '100%',
        fontSize: '15px',
        color: '#6e7687', // TODO: colors not in palette
        tableLayout: 'fixed',
        borderCollapse: 'separate',
        borderSpacing: 0,
        'tr th, tr td': {
          borderRight: border,
          borderBottom: border,
          padding: '6px 16px'
        },
        'tbody tr:last-child td': {
          borderBottom: 'none'
        },
        'tr th:last-child, tr td:last-child': {
          borderRight: 'none'
        },
        'tr th': {
          fontWeight: 700,
          background: '#edeef2', // TODO: colors not in palette
          textAlign: 'left'
        },
        'tr td': {
          padding: '8px 16px'
        },
        'tbody tr': {
          backgroundColor: colors.secondaryHover,
          cursor: onRowSelected ? 'pointer' : undefined,
          ':hover': {
            backgroundColor: onRowSelected ? colors.listGreyHover : undefined
          }
        },
        'tr:first-child th:first-child': {
          borderTopLeftRadius: '6px'
        },
        'tr:first-child th:last-child': {
          borderTopRightRadius: '6px'
        },
        'tr:last-child td:first-child': {
          borderBottomLeftRadius: '6px'
        },
        'tr:last-child td:last-child': {
          borderBottomRightRadius: '6px'
        }
      }}
    >
      <thead>
        <tr>{headers.map(renderHeader)}</tr>
      </thead>
      <tbody>
        {data.map((datum, index) => (
          <tr key={datum.id} onClick={() => onRowSelected?.(datum.id)}>
            {renderRowCells(datum, index)
              .filter(Boolean)
              .map((cell, cellIndex) => (
                <td key={cellIndex}>{cell}</td>
              ))}
          </tr>
        ))}
      </tbody>
    </Box>
  )
}
