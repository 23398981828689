// @ts-nocheck
import 'reflect-metadata'
import { injectable } from 'inversify'
import { observable } from 'mobx'
import { Types } from 'Gateways/Service/Types'
import { IRouterGateway } from './IRouterGateway'
import { container } from '../Config/IOC'
import { FiltersRepository } from 'app/Filters/FiltersRepository'
import { RouteUpdater } from './RouteUpdater'
import { RouteRegistrar } from './RouteRegistrar'

@injectable()
export class Router {
  private filtersRepository: FiltersRepository = container.get(FiltersRepository)

  private routeUpdater: RouteUpdater = container.get(RouteUpdater)

  private routeRegistrar: RouteRegistrar = container.get(RouteRegistrar)

  private routerGateway: IRouterGateway = container.get(Types.IRouterGateway)

  public registerRoutes = () => {
    this.routeRegistrar.registerRoutes()
  }

  public goToId = async (routeId: string, params?: any, query?: any) => {
    let routePath = this.routeUpdater.findRoute(routeId).routeDef.path

    if (query) {
      routePath = routePath + '?' + query
    }

    this.routerGateway.goToPath(routePath)
  }

  // Note: at some point we need to factor this into goToID (to handle dynamic routes via params)
  public goToCustomPath = (customPath: string) => this.routerGateway.goToPath(customPath)

  public changePageWithoutCheck = async (routeId: string, params?: any, query?: any) => {
    this.goToId(routeId, params, query)
  }

  public refreshFilteredRoute = () => {
    const resolvedRoute = this.routerGateway.lastRouteResolved()
    let routePath = resolvedRoute && resolvedRoute.url

    const query = this.filtersRepository.urlString

    if (query) {
      routePath = routePath + '?' + query
    }

    this.routerGateway.goToPath(routePath)
  }

  public goToPageId = async (routeId: string, params?: any, query?: any) => {
    if (this.checkBeforeAction()) return
    this.changePageWithoutCheck(routeId, params, query)
  }

  @observable
  public checkBeforeAction: () => boolean = () => {
    return false
  }

  public clearCheckBeforeAction = () => {
    this.checkBeforeAction = () => false
  }

  public setCheckBeforeAction = (functionCall: () => boolean): void => {
    this.clearCheckBeforeAction()
    this.checkBeforeAction = functionCall
  }

  public registerRouteEnterCallback = (enterCallbackObject: { routeId: string; callback: () => void }) => {
    this.routeUpdater.registerRouteEnterCallback(enterCallbackObject)
  }

  public registerRouteLeaveCallback = (leaveCallbackObject: { routeId: string; callback: () => void }) => {
    this.routeUpdater.registerRouteLeaveCallback(leaveCallbackObject)
  }

  public updateCurrentRoute = (routeId: string, params?: any, query?: any, source?: string) => {
    this.routeUpdater.updateCurrentRoute(routeId, params, query, source)
  }
}
