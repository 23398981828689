import { useQuery } from 'react-query'

import { DynamicFormFieldsConfig } from 'main/components/shared'
import { apiClient } from 'main/services/api/api-client'
import { QueryKeys } from 'main/services/queries/query-keys'

export function useChangeRequestFormQuery(
  runbookId: string,
  changeRequestType: string,
  parentChangeRequestId: number | undefined,
  parentChangeRequestExternalId: string | undefined
) {
  const params = {
    change_request_type: changeRequestType,
    parent_change_request_id: parentChangeRequestId,
    parent_change_request_external_id: parentChangeRequestExternalId
  }
  return useQuery(
    [QueryKeys.Runbooks, String(runbookId), QueryKeys.ChangeRequests, 'form_config', params],
    async () => {
      const { data } = await apiClient.get<DynamicFormFieldsConfig>({
        url: `runbooks/${runbookId}/change_requests/form_config`,
        params: params
      })
      return data
    }
  )
}
