// @ts-nocheck
import { container } from 'app/Config/IOC'

export function withPresenter(Presenter: any) {
  return (Component: any) => {
    return props => {
      const presenter = container.get(Presenter)

      const derivedProps = presenter.withProps ? { presenter: presenter.withProps(props) } : { presenter }
      return <Component {...props} {...derivedProps} />
    }
  }
}
