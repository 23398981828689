// @ts-nocheck
import { observable, action, observe, computed } from 'mobx'
import { injectable } from 'inversify'
import { container } from 'app/Config/IOC'
import { RadioButtonsPresenter } from 'Components/Atoms'
import { CheckboxListPresenter, CheckboxPresenter } from 'Components/Molecules'
import { Types } from 'Gateways/Service/Types'
import { ILanguageService } from 'Helpers/Language/ILanguageService'

@injectable()
export class RoleTypeViewPermissionPresenter {
  private language: ILanguageService = container.get(Types.ILanguageService)

  @observable
  public viewPermissionOptionsPresenter: RadioButtonsPresenter = null

  @observable
  public viewsHierarchy: { [key: string]: string[] } = {}

  @observable
  public selectedViews = []

  @observable
  public globalViewsPresenter: CheckboxListPresenter = null

  @observable
  public accessManagementPresenter: CheckboxListPresenter = null

  @observable
  public accountViewsPresenter: CheckboxListPresenter = null

  public globalViewsHeading = this.language.get('roleTypes:edit:viewPermissionComponent:globalViewsHeading')

  public accountViewsHeading = this.language.get('roleTypes:edit:viewPermissionComponent:accountViewsHeading')

  @observable
  public show: boolean = false

  @action
  public load = (
    viewsHierarchy: { [key: string]: any },
    selectedViews: string[],
    viewPermissionType: string,
    disabled: boolean
  ) => {
    this.viewPermissionOptionsPresenter = new RadioButtonsPresenter(
      [
        this.language.get('roleTypes:edit:viewPermissionComponent:radioButtonsLabels:viewNone'),
        this.language.get('roleTypes:edit:viewPermissionComponent:radioButtonsLabels:viewAll'),
        this.language.get('roleTypes:edit:viewPermissionComponent:radioButtonsLabels:viewCustom')
      ],
      viewPermissionType
    )
      .withLabel(this.language.get('roleTypes:edit.viewPermissionComponent.componentLabel'))
      .withDisabled(disabled)

    this.viewsHierarchy = viewsHierarchy
    this.selectedViews = selectedViews
    if (
      viewPermissionType === this.language.get('roleTypes:edit:viewPermissionComponent:radioButtonsLabels:viewCustom')
    ) {
      this.show = true
    }
    this.initViewPresenter(disabled)

    observe(this.viewPermissionOptionsPresenter, 'selectedOption', () => {
      this.show =
        this.viewPermissionOptionsPresenter.selectedOption ===
        this.language.get('roleTypes:edit:viewPermissionComponent:radioButtonsLabels:viewCustom')
    })
  }

  @action
  private parentCheckboxMiddleware = (checkboxList: CheckboxListPresenter, val: boolean) => {
    if (!val)
      checkboxList.childrenCheckboxPresenters.map(childrenCheckboxPresenters => {
        childrenCheckboxPresenters.value = false
      })
  }

  @action
  private childCheckboxMiddleware = (checkboxList: CheckboxListPresenter, val: boolean) => {
    const childrenCount = checkboxList.childrenCheckboxPresenters.filter(child => child.value).length
    if (childrenCount === 0 && val) {
      checkboxList.parentCheckboxPresenter.value = true
    }
    if (childrenCount === 1 && !val) {
      checkboxList.parentCheckboxPresenter.value = false
    }
  }

  private initViewPresenter = (disabled: boolean) => {
    this.globalViewsPresenter = new CheckboxListPresenter(
      Object.keys(this.viewsHierarchy)[0],
      Object.values(this.viewsHierarchy)[0],
      this.selectedViews,
      disabled
    )
      .withParentCheckboxMiddleware(val => {
        this.parentCheckboxMiddleware(this.globalViewsPresenter, val)
        return val
      })
      .withChildCheckboxMiddleware(val => {
        this.childCheckboxMiddleware(this.globalViewsPresenter, val)
        return val
      })
    this.accessManagementPresenter = new CheckboxListPresenter(
      Object.keys(this.viewsHierarchy)[1],
      Object.values(this.viewsHierarchy)[1],
      this.selectedViews,
      disabled
    )
      .withParentCheckboxMiddleware(val => {
        this.parentCheckboxMiddleware(this.accessManagementPresenter, val)
        return val
      })
      .withChildCheckboxMiddleware(val => {
        this.childCheckboxMiddleware(this.accessManagementPresenter, val)
        return val
      })
    this.accountViewsPresenter = new CheckboxListPresenter(
      Object.keys(this.viewsHierarchy)[2],
      Object.values(this.viewsHierarchy)[2],
      this.selectedViews,
      disabled
    )
      .withParentCheckboxMiddleware(val => {
        this.parentCheckboxMiddleware(this.accountViewsPresenter, val)
        return val
      })
      .withChildCheckboxMiddleware(val => {
        this.childCheckboxMiddleware(this.accountViewsPresenter, val)
        return val
      })
  }

  @computed
  public get isDirty(): boolean {
    const presenters = [this.globalViewsPresenter, this.accessManagementPresenter, this.accountViewsPresenter]

    if (this.viewPermissionOptionsPresenter && this.viewPermissionOptionsPresenter.isDirty) return true

    let isDirty = false
    presenters.forEach(presenter => {
      if (presenter && presenter.parentCheckboxPresenter && presenter.childrenCheckboxPresenters) {
        if (presenter.parentCheckboxPresenter.isDirty) isDirty = true
        presenter.childrenCheckboxPresenters.forEach((childCheckboxPresenter: CheckboxPresenter) => {
          if (childCheckboxPresenter.isDirty) isDirty = true
        })
      }
    })
    return isDirty
  }
}
