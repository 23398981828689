import { format } from 'date-fns'

import { Avatar, Box, Icon, Text, Tooltip } from '@cutover/react-ui'

export type CommentItemProps = {
  name: string
  color: string
  content: string
  task: { id: number; name: string; internal_id: number } | null
  createdAt: number
}

export function CommentItem({ name, color, content, task, createdAt }: CommentItemProps) {
  return (
    <Box direction="row" gap="8px" data-testid="comments-summary" flex={{ grow: 1 }}>
      <Box flex={{ grow: 0 }}>
        <Avatar subject={{ name, color }} />
      </Box>
      <Box direction="column" flex={{ grow: 1 }}>
        <Box direction="row" justify="between">
          <Text tag="div" data-testid="dashboard-user-item-name" color="text" truncate>
            {name}
          </Text>
          <Box flex={{ grow: 0 }} direction="row" gap="8px">
            <Icon icon="star-filled" color="star" size="small" />
            <Text color="text-light">{` ${format(createdAt * 1000, 'd MMM HH:mm')}`}</Text>
          </Box>
        </Box>
        <Box direction="row" justify="between">
          <Text
            as="pre"
            data-test-id="task-comment"
            color="text-light"
            css={`
              font-family: inherit;
              margin-top: 0;
              font-size: 13px;
            `}
          >
            {task && (
              <Tooltip content={task.name} placement="top-start">
                <Text color="primary" css="font-size: 13px">
                  Task #{task.internal_id}:{' '}
                </Text>
              </Tooltip>
            )}
            {content}
          </Text>
        </Box>
      </Box>
    </Box>
  )
}
