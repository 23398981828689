import { memo, ReactNode } from 'react'

import { StreamEditPanel, TaskEditPanel } from './right-panels'
import { UserOrTeamDetailsPanel } from './right-panels/user-team-details-panel'
import { RunbookFilter } from './runbook-filter/runbook-filter'
import { RunbookHeader } from './runbook-header/runbook-header'
import { RunbookRightNavigation } from './runbook-right-navigation/runbook-right-navigation'
import { RunbookSubHeader } from './runbook-sub-header/runbook-sub-header'
import { RunbookBanner } from '../layout/runbook-banner'
import { Layout } from 'main/components/layout'
import { RunbookCopiesPanel } from '../workspace/right-panels'
import { RunbookEditPanelTaskList } from './right-panels/runbook-edit-panel-task-list'
import { useDefaultLayout } from 'main/components/layout/layout-hooks'
import { useActiveRightPanelValueType, useCloseRightPanelOnMount } from 'main/components/layout/right-panel'
import { AiSuggestionsPanel } from './right-panels/ai-suggestions/ai-suggestions-panel'
import { CommentsPanel } from './right-panels/comments-panel/comments-panel'
import { TasksBulkEditPanel } from './right-panels/tasks-bulk-edit-panel/tasks-bulk-edit-panel'
import { RunbookProgressionModals } from './modals/runbook-progression-modals'
import { RunbookDashboardSchedulePanelWrapper as RunbookDashboardSchedulePanel } from './right-panels/dashboard-schedule/runbook-dashboard-schedule-panel-wrapper'

export const RunbookLayout = memo(({ children }: { children?: ReactNode }) => {
  useCloseRightPanelOnMount({ triggerAngular: true })

  useDefaultLayout({
    filterPanel: true,
    subHeader: true,
    rightNav: true
  })

  return (
    <>
      <Layout
        header={<RunbookHeader />}
        subHeader={<RunbookSubHeader />}
        banner={<RunbookBanner />}
        filter={<RunbookFilter />}
        rightNavigation={<RunbookRightNavigation />}
        rightPanels={<RightPanels />}
      >
        {children}
      </Layout>
      <RunbookProgressionModals />
    </>
  )
})

const RightPanels = memo(() => {
  const panelType = useActiveRightPanelValueType()

  switch (panelType) {
    case 'stream-edit':
      return <StreamEditPanel />
    case 'task-edit':
      return <TaskEditPanel />
    case 'tasks-bulk-edit':
      return <TasksBulkEditPanel />
    case 'runbook-edit':
      return <RunbookEditPanelTaskList />
    case 'runbook-copies':
      return <RunbookCopiesPanel />
    case 'user-team-details-edit':
      return <UserOrTeamDetailsPanel />
    case 'ai-suggestions-list':
      return <AiSuggestionsPanel />
    case 'runbook-comments':
      return <CommentsPanel />
    case 'runbook-dashboard-schedule':
      return <RunbookDashboardSchedulePanel />
    default:
      return null
  }
})
