import { ReactNode, useCallback, useEffect, useState } from 'react'
import { eventManager } from 'event-manager'
import { useParams } from 'react-router-dom'

import { useSetAppHeaderView, useSetAppsComponentProps, useSetAppViews } from 'main/recoil/data-access'
import { operations, useComponentPropsStateHandler } from './apps-component-props-helper'
import { AppsChannelResponse } from 'main/components/apps/apps-types'
import { useWebsockets } from 'main/services/hooks'
import { ActiveRunbookModel, CurrentUserModel } from 'main/data-access'

const APPS_RESOURCE_CHANNEL_NAME = 'AppResourceChannel'

export const AppsResourceChannelSubscriberReact = ({ children }: { children: ReactNode }) => {
  const canUpdateRunbook = ActiveRunbookModel.useCan('update')
  const { is_template: isTemplate } = ActiveRunbookModel.useGet()
  const hasApps: boolean = false

  return (
    <AppsResourceChannelSubscriber canUpdateRunbook={canUpdateRunbook} isTemplate={isTemplate} hasApps={hasApps}>
      {children}
    </AppsResourceChannelSubscriber>
  )
}

export const AppsResourceChannelSubscriberAngular = ({ children }: { children: ReactNode }) => {
  const [isTemplate, setIsTemplate] = useState<boolean | undefined>(false)
  const [hasApps, setHasApps] = useState<boolean>(false)
  const [runbookPermissions, setRunbookPermissions] = useState<{ [x: string]: number[] } | undefined>(undefined)
  const { id: currentUserId } = CurrentUserModel.useGet()

  useEffect(() => {
    const handleRunbookData = ({
      permissions,
      runbook
    }: {
      permissions: { [x: string]: number[] }
      runbook: { is_template: boolean; has_apps: boolean }
    }) => {
      setRunbookPermissions(permissions)
      setIsTemplate(runbook.is_template)
      setHasApps(runbook.has_apps)
    }
    eventManager.on('runbook-data', handleRunbookData)

    return () => {
      eventManager.off('runbook-data', handleRunbookData)
    }
  }, [])

  return (
    <AppsResourceChannelSubscriber
      isTemplate={isTemplate}
      canUpdateRunbook={runbookPermissions?.update?.includes(currentUserId)}
      hasApps={hasApps}
    >
      {children}
    </AppsResourceChannelSubscriber>
  )
}

export const AppsResourceChannelSubscriber = ({
  isTemplate,
  canUpdateRunbook,
  children,
  hasApps
}: {
  isTemplate?: boolean
  canUpdateRunbook?: boolean
  children: ReactNode
  hasApps: boolean
}) => {
  const componentPropsStateHandler = useComponentPropsStateHandler()
  const setAppViews = useSetAppViews()
  const setHeaderApp = useSetAppHeaderView()
  const setComponentProps = useSetAppsComponentProps()
  const websockets = useWebsockets()
  const { runbookId } = useParams()

  const updateAppViewsState = useCallback(
    (response: AppsChannelResponse) => {
      const appId = response.app_id
      const resourceId = response.resource_id
      const view = response.view
      const type = view.type
      const context = `${resourceId}-${appId}`

      componentPropsStateHandler({ response, context, setComponentProps })

      if (!operations.includes(type)) {
        switch (type) {
          case 'header':
            setHeaderApp({ view, appId, resourceId })
            break
          case 'panel':
            setAppViews(views => {
              const existingView = views[context]
              if (existingView.allow_bulk_refresh) {
                const updatedView = {
                  ...response.view,
                  appId,
                  resourceId,
                  visible: existingView.visible,
                  order: existingView?.order
                }
                return { ...views, [context]: updatedView }
              } else {
                return views
              }
            })
            break
          default:
            console.warn(`App resource channel does not accept ${type} type nodes.`)
        }
      }
    },
    [componentPropsStateHandler, setComponentProps, setHeaderApp, setAppViews]
  )

  const findAppsResourceChannelSubscription = (id: string) => {
    return websockets.findExistingSubscription(APPS_RESOURCE_CHANNEL_NAME, id)
  }

  const subscribeForRunbook = (id: string) => {
    if (!findAppsResourceChannelSubscription(id)) {
      websockets.subscribe(APPS_RESOURCE_CHANNEL_NAME, id, {
        received: data => {
          updateAppViewsState(data.response)
        }
      })
    }
  }

  useEffect(() => {
    if (hasApps && runbookId && !isTemplate) {
      const capability = canUpdateRunbook ? 'edit' : 'read'
      const id = `${runbookId}:${capability}`

      subscribeForRunbook(id)

      return () => {
        findAppsResourceChannelSubscription(id)?.unsubscribe()
      }
    }
  }, [runbookId, isTemplate, hasApps])

  return <>{children}</>
}
