import { ChangeEvent, FocusEventHandler, MouseEvent as ReactMouseEvent, useMemo, useState } from 'react'
import { CheckBoxExtendedProps, CheckBoxType } from 'grommet'
import styled from 'styled-components/macro'

import {
  Box,
  Button,
  Checkbox,
  CheckboxProps,
  focusRingOutlineCss,
  Icon,
  IconName,
  ItemCreateInput,
  ItemCreateInputProps,
  themeColor
} from '@cutover/react-ui'

const LABEL_OFFSET_FROM_INPUT = -92
const LABEL_OFFSET_FROM_INPUT_WITH_TOGGLE = -68

type CheckboxChild = Omit<CheckBoxType, 'children' | 'ref'> &
  Partial<CheckboxProps> & { initiallyOpen?: boolean; itemCreateInputProps?: ItemCreateInputProps }
type RecursiveCheckboxChild = CheckboxChild & { children?: RecursiveCheckboxChild[] }
type RecursiveCheckboxChildren = RecursiveCheckboxChild[]
type SharedCheckboxHierarchyGroupProps = {
  hasError?: boolean
  readOnly?: boolean
  required?: boolean
  disabled?: boolean
  label?: string
  tooltipText?: string
  icon?: IconName
  inlineError?: string
  name?: string
  /** The key in the option object that determines the checkbox value. Defaults to 'value'. */
  valueKey?: string
  /** The key in the option object that determines the label of the checkbox. Defaults to 'label'. */
  labelKey?: string
  withTooltipLabel?: boolean
  options: RecursiveCheckboxChildren
  onFocus?: FocusEventHandler<HTMLDivElement>
  onBlur?: FocusEventHandler<HTMLDivElement>
  itemCreateInputProps?: ItemCreateInputProps
  onEditClick?: (e: ReactMouseEvent, option: CheckBoxExtendedProps) => void
  removeAllOthersOnLabelClick?: boolean
  value?: (string | number)[]
}
type CheckboxHierarchyGroupProps = SharedCheckboxHierarchyGroupProps & {
  onChange: (options: (string | number)[]) => void
}
type CheckboxHierarchyInnerProps = SharedCheckboxHierarchyGroupProps & {
  onChange: (
    e: ChangeEvent<HTMLInputElement>,
    option: RecursiveCheckboxChild,
    parents: RecursiveCheckboxChildren
  ) => void
}

export const CheckboxHierarchyGroup = ({
  onChange,
  removeAllOthersOnLabelClick,
  valueKey = 'value',
  value = [],
  options,
  ...props
}: CheckboxHierarchyGroupProps) => {
  // Builds a record of parents to children and children to parents to save looping through options on every click
  const { parentToChildrenValues, childToParentsValues } = useMemo(() => {
    const parentToChildrenValues: Record<string, string[]> = {}
    const childToParentsValues: Record<string, string[]> = {}

    const buildChildrenRecursive = (next: RecursiveCheckboxChild, prev: string[] = []) => {
      childToParentsValues[next[valueKey]] = prev
      parentToChildrenValues[next[valueKey]] = []

      prev.forEach(p => (parentToChildrenValues[p] = [next[valueKey], ...(parentToChildrenValues[p] || [])]))
      if (next.children) next.children.forEach(child => buildChildrenRecursive(child, [next[valueKey], ...prev]))
    }

    options.forEach(o => o.children?.forEach(child => buildChildrenRecursive(child, [o[valueKey]])))
    return { parentToChildrenValues, childToParentsValues }
  }, [options])

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>, option: RecursiveCheckboxChild) => {
    // NOTE: the below is a creative solution to mimick the angular behaviour of clicking on a stream label to navigate just to that stream, removing others.
    // Basically, e.target on checkbox row is the 0x0 checkbox input elem (since wrapped by label elem)
    // So we compare the clicked co-ordinates to that (accounting for whether substream), to determine if the user is clicking on the label
    const rect = e.target.getBoundingClientRect()
    const toggleButton = e.target.parentNode?.querySelector('button[data-testid$="-expand-button"]')
    const isChild = e.target.closest('.checkbox-group-hierarchy-level-2')
    const offset = toggleButton || isChild ? LABEL_OFFSET_FROM_INPUT_WITH_TOGGLE : LABEL_OFFSET_FROM_INPUT
    const clickedXPosWithinRow = (e.nativeEvent as MouseEvent).clientX - rect.left
    if (removeAllOthersOnLabelClick && clickedXPosWithinRow > offset) {
      onChange(value.length === 1 && value[0] === option[valueKey] ? [] : [option[valueKey]])
    } else if (e.target.checked) {
      // add new option and deselect any children (they are technically not selected but will show as selected in the UI)
      const selected = [...value, option[valueKey]].filter(
        s => !(parentToChildrenValues[option[valueKey]] || []).includes(s)
      )
      onChange(selected)
    } else {
      const parentSelected = (childToParentsValues[option[valueKey]] || []).some(r => value.includes(r))
      const directParent = childToParentsValues[option[valueKey]] && childToParentsValues[option[valueKey]][0]

      // add in all siblings (direct parent's child values) if parent is selected (this will add in the unselected child but is filtered out on next line)
      const selected = (
        parentSelected && directParent ? [...parentToChildrenValues[directParent], ...value] : value
      ).filter(
        value =>
          // unselect parents and actual clicked checkbox
          value !== option[valueKey] && !(childToParentsValues[option[valueKey]] || []).includes(value as string)
      )
      onChange(selected)
    }
  }

  return (
    <CheckboxHierarchyInner
      value={value}
      valueKey={valueKey}
      onChange={handleCheckboxChange}
      options={options}
      {...props}
    />
  )
}

const CheckboxHierarchyInner = ({
  disabled,
  readOnly,
  options = [],
  onEditClick,
  itemCreateInputProps,
  ...props
}: CheckboxHierarchyInnerProps) => {
  return (
    <>
      {options.map(o => displayChildOptions({ option: o, readOnly, disabled, onEditClick, ...props }))}
      {itemCreateInputProps && <ItemCreateInput level={1} {...itemCreateInputProps} />}
    </>
  )
}

type CheckboxHierarchyOptionProps = {
  option: RecursiveCheckboxChild
  parents?: RecursiveCheckboxChildren
  isParentSelected?: boolean
  level?: number
} & Pick<
  CheckboxHierarchyInnerProps,
  'valueKey' | 'labelKey' | 'onChange' | 'readOnly' | 'disabled' | 'value' | 'withTooltipLabel' | 'onEditClick'
>

const CheckboxHierarchyOption = ({
  option,
  value = [],
  valueKey = 'value',
  labelKey = 'label',
  onChange,
  parents = [],
  readOnly,
  disabled,
  isParentSelected,
  onEditClick,
  level = 1,
  ...props
}: CheckboxHierarchyOptionProps) => {
  const [expanded, setExpanded] = useState(option.initiallyOpen || false)
  const isSelected = isParentSelected || value.includes(option[valueKey])
  const { children, ...optionWithoutChildren } = option

  return (
    <CheckboxHierarchyOptionWrapper key={option[valueKey]} className={`checkbox-group-hierarchy-level-${level}`}>
      {/* @ts-ignore */}
      <Checkbox
        level={level}
        checked={isSelected}
        disabled={disabled || option.disabled}
        onChange={readOnly ? undefined : e => onChange?.(e, option, parents)}
        prefix={
          option.itemCreateInputProps?.onCreateItem || (option.children && option.children.length > 0) ? (
            <ShowChildToggle data-testid={`${option.label}-expand-button`} onClick={() => setExpanded(!expanded)}>
              <ShowChildBracketIcon
                color={expanded || (option.children && option.children.length > 0) ? 'text-light' : 'text-disabled'}
              />
              <ShowChildCancelIcon
                color={expanded || (option.children && option.children.length > 0) ? 'text-light' : 'text-disabled'}
                $rotated={expanded}
              />
            </ShowChildToggle>
          ) : undefined
        }
        label={option[labelKey]}
        value={option[valueKey]}
        editIconProps={
          option.editIconProps || onEditClick
            ? {
                onClick: onEditClick,
                ...(option.editIconProps || {})
              }
            : undefined
        }
        {...optionWithoutChildren}
      />

      {expanded && (
        <Box direction="column" width="100%">
          {(children || []).map(child =>
            displayChildOptions({
              option: child,
              valueKey,
              labelKey,
              onChange,
              value,
              parents: [...parents, option],
              isParentSelected: isSelected,
              readOnly,
              disabled,
              onEditClick,
              level: level + 1,
              ...props
            })
          )}
          {option.itemCreateInputProps?.onCreateItem && (
            <ItemCreateInput {...option.itemCreateInputProps} level={level + 1} />
          )}
        </Box>
      )}
    </CheckboxHierarchyOptionWrapper>
  )
}

const displayChildOptions = ({ valueKey = 'value', ...props }: CheckboxHierarchyOptionProps) => (
  <CheckboxHierarchyOption key={props.option[valueKey]} valueKey={valueKey} {...props} />
)

const ShowChildToggle = styled(Button).attrs({ plain: true })`
  &:focus-visible {
    ${focusRingOutlineCss}
    border-radius: 6px;
    outline-offset: -1px !important;
    box-shadow: none !important;
  }

  align-items: center;
  display: flex;
  height: 24px;
  justify-content: center;
  width: 24px;
  position: relative;
`
const ShowChildBracketIcon = styled(Icon).attrs({ icon: 'bracket' })``
const ShowChildCancelIcon = styled(Icon).attrs({ size: '14px', icon: 'close' })<{
  $rotated: boolean
}>`
  position: absolute;
  transform: ${({ $rotated }) => ($rotated ? 'rotate(0deg)' : 'rotate(-45deg)')};
  transition: transform 0.3s;
`

const CheckboxHierarchyOptionWrapper = styled(Box)`
  flex-shrink: 0;
  &:hover,
  &:focus-within {
    ${ShowChildBracketIcon} {
      fill: ${themeColor('text-light')};
    }

    ${ShowChildCancelIcon} {
      fill: ${themeColor('text-light')};
    }
  }
`
