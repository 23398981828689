import EventEmitter from 'events'

type EventName = string
type EventPayload = Record<string, unknown>

// Remove the keys from a type where the value is undefined
type WithoutUndefinedValues<Type> = {
  [Property in keyof Type as Type[Property] extends undefined ? never : Property]: Type[Property]
}

type WithOnlyUndefinedValues<Type> = Omit<Type, keyof WithoutUndefinedValues<Type>>

export function createEventManager<T extends Record<EventName, EventPayload | undefined>>() {
  const eventEmitter = new EventEmitter()
  function emit<E extends keyof WithOnlyUndefinedValues<T>>(event: E): void
  // @ts-ignore
  function emit<E extends keyof WithoutUndefinedValues<T>>(event: E, payload: WithoutUndefinedValues<T>[E]): void
  function emit<E extends keyof T>(
    event: E,
    payload?: T[E] extends keyof WithoutUndefinedValues<T> ? T[E] : never
  ): void {
    eventEmitter.emit(String(event), payload)
  }

  function on<E extends keyof T>(event: E, callback: (data: T[E]) => void): void {
    eventEmitter.on(String(event), callback)
  }

  function once<E extends keyof T>(event: E, callback: (data: T[E]) => void): void {
    eventEmitter.once(String(event), callback)
  }

  function off<E extends keyof T>(event: E, callback: (data: T[E]) => void): void {
    eventEmitter.off(String(event), callback)
  }

  return {
    emit,
    on,
    off,
    once
  }
}
