// @ts-nocheck

import { observer } from 'mobx-react-lite'
import styled, { css } from 'styled-components'
import { colors } from 'app/Styles/Variables'
import { TAnchorTagDisplayStyle } from './TAnchorTagDisplayStyle'
import { TAnchorTagTargetAttributes } from './TAnchorTagTargetAttributes'
import { AnchorTagPresenter } from './AnchorTagPresenter'
import { Icon } from 'Components/Atoms/Icon'

interface IStyledAnchorTagProps {
  href: string
  displayStyle: TAnchorTagDisplayStyle
  target: TAnchorTagTargetAttributes
  preventDefault?: boolean
  customFunction?: () => void
  text?: string
}

const StyledAnchorTagBasic = css`
  cursor: pointer;
  font-weight: 700;
  color: ${colors.primaryGrey};
  display: inline-block;
  margin-right: 12px;
  font-size: 18px;
  text-transform: uppercase;
  white-space: nowrap;
  text-decoration: none;
  &:hover {
    color: ${colors.primary};
  }
`

const StyledAnchorTagButton = css`
  background-color: ${colors.primary};
  line-height: 34px;
  color: white;
  padding: 0 16px;
  border-radius: 20px;
  font-weight: 400;
  font-size: 15px;
  border: 1px solid ${colors.primary};
  &:hover {
    background-color: ${colors.primaryHover};
    color: white;
  }
`

const StyledAnchorTag = styled.a`
  ${(props: IStyledAnchorTagProps) => {
    switch (props.displayStyle) {
      case 'anchor':
        return StyledAnchorTagBasic
      case 'button':
        return [StyledAnchorTagBasic, StyledAnchorTagButton]
    }
  }}
`

interface IAnchorTagProps {
  presenter: AnchorTagPresenter
  extraStyles?: CSSProperties
  className?: string
}

export const AnchorTag = observer<IAnchorTagProps>(({ presenter, extraStyles, className }) => {
  const { href, target, displayStyle, preventDefault, iconName, text, customFunction, stopPropagation } = presenter

  const handleClick = (event: SyntheticEvent) => {
    if (preventDefault) {
      event.preventDefault()
    }
    if (stopPropagation) {
      event.stopPropagation()
    }
    if (customFunction) {
      customFunction()
    }
  }

  let IconRender = () => <Icon name={iconName} />

  return (
    <StyledAnchorTag
      href={href}
      target={target}
      preventDefault={preventDefault}
      displayStyle={displayStyle}
      onClick={handleClick}
      text={text}
      style={extraStyles}
      className={className}
    >
      {iconName && <IconRender />} {text}
    </StyledAnchorTag>
  )
})
