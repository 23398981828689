// @ts-nocheck
import { ISelectedFilter, IGenericFilter } from 'app/Filters/FilterEntities'

export class StringFilterHelpers {
  public getFilterValue = (_genericFilter: IGenericFilter, pathFilter: { key: string; value: string }): string => {
    return decodeURIComponent(pathFilter.value)
  }

  public getValueForUrl = (_selectedFilter: IGenericFilter, newValue: string): string => {
    return encodeURIComponent(newValue)
  }

  public serialiseForUrl = (selectedFilter: ISelectedFilter): string => {
    return `${selectedFilter.key.reference}=${encodeURIComponent(selectedFilter.value)}`
  }

  public serialiseForApi = (selectedFilter: ISelectedFilter): string => {
    return `${selectedFilter.key.reference}=${encodeURIComponent(selectedFilter.value)}`
  }
}
