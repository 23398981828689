// @ts-nocheck

import styled from 'styled-components'
import { px2rem } from 'app/Styles/Variables'

const Logo = styled.div`
  height: ${px2rem(72)};
  display: inline-block;
  width: 100%;
  text-align: center;
`

interface ILogoWrapperProps {
  extraStyles?: CSSProperties
  children: ReactNode
}

export const LogoWrapper = (props: ILogoWrapperProps) => {
  return <Logo style={props.extraStyles}>{props.children}</Logo>
}
