// @ts-nocheck
import { container } from 'app/Config/IOC'
import { injectable } from 'inversify'
import { computed, observable } from 'mobx'
import { CustomFieldsViewPresenter } from 'app/Settings/CustomFields/CustomFieldsPage/CustomFieldsViewPresenter'
import { PillFilterPresenter, SearchBoxPresenter } from 'Components/Molecules'
import { CustomFieldPageHeadingExtraOptionsPresenter } from './CustomFieldPageHeadingExtraOptionsPresenter'
import { GenericPageHeadingPresenter } from 'Shared/Presenters/GenericPageHeadingPresenter'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'
import { Types } from 'Gateways/Service/Types'

@injectable()
export class CustomFieldPageHeadingPresenter extends GenericPageHeadingPresenter {
  protected viewPresenter: CustomFieldsViewPresenter = container.get(CustomFieldsViewPresenter)
  private language: ILanguageService = container.get(Types.ILanguageService)

  @observable
  public visibilityPillsPresenter = new PillFilterPresenter({
    group: this.language.get('customFields:filters:visibility:title'),
    type: 'group'
  }).withTitle(this.language.get('customFields:filters:visibility:title'))

  @observable
  public fieldTypePillsPresenter = new PillFilterPresenter({
    group: this.language.get('customFields:filters:fieldType:title'),
    type: 'group'
  }).withTitle(this.language.get('customFields:filters:fieldType:title'))

  @observable
  public contextPillsPresenter = new PillFilterPresenter({
    group: this.language.get('customFields:filters:context:title'),
    type: 'group'
  }).withTitle(this.language.get('customFields:filters:context:title'))

  @observable
  public groupPillsPresenter = new PillFilterPresenter({
    group: this.language.get('customFields:filters:groups:title'),
    type: 'group'
  }).withTitle(this.language.get('customFields:filters:groups:title'))

  @observable
  public otherPillsPresenter = new PillFilterPresenter({
    group: this.language.get('customFields:filters:other:title'),
    type: 'group'
  })

  @observable
  public searchPillPresenter = new PillFilterPresenter({ type: 'single', key: { reference: 'query' } }).withTitle(
    this.language.get('common:headingSection:searchBox:pillLabel')
  )

  @observable
  public searchBoxPresenter: SearchBoxPresenter = new SearchBoxPresenter()

  @observable
  public extraOptionsPresenter: CustomFieldPageHeadingExtraOptionsPresenter =
    new CustomFieldPageHeadingExtraOptionsPresenter()

  @computed
  public get filteredCustomFieldCount(): number {
    return this.viewPresenter.filteredListTotal
  }

  @computed
  public get pillCount(): number {
    return (
      this.visibilityPillsPresenter.filterPills.length +
      this.fieldTypePillsPresenter.filterPills.length +
      this.contextPillsPresenter.filterPills.length +
      this.otherPillsPresenter.filterPills.length +
      this.searchPillPresenter.filterPills.length +
      this.groupPillsPresenter.filterPills.length
    )
  }

  @computed
  public get showClearFilterButton(): boolean {
    return this.pillCount > 1
  }

  public clearFilters = () => {
    this.searchBoxPresenter.clear()
    this.viewPresenter.clearFilters()
  }
}
