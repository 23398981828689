import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Integrations = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Integrations" {...props}>
    <path d="M10.564 34.414a16.981 16.981 0 0 0 3.98 3.719l-1.669 2.494a19.978 19.978 0 0 1-4.683-4.375c-6.766-8.73-5.175-21.293 3.556-28.06 8.73-6.766 21.293-5.175 28.06 3.556 6.766 8.73 5.175 21.293-3.556 28.06C29.884 44.742 22.555 41.505 22.5 34H22a8 8 0 0 1-8-8v-4h3v4a5 5 0 0 0 5 5h4a5 5 0 0 0 5-5v-4h3v4a8 8 0 0 1-8 8h-.5s-.015 2.127 1 3.436c1.975 2.548 6.045 1.45 7.914 0 7.421-5.751 8.774-16.43 3.022-23.85-5.751-7.421-16.43-8.774-23.85-3.022-7.421 5.751-8.774 16.43-3.022 23.85Z" fill="#000" /><path d="M19 14h3v8h-3v-8Zm10 0h-3v8h3v-8Z" fill="#000" />
  </StyledIcon>
))

Integrations.displayName = 'Integrations'

export { Integrations }
