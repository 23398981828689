import { createContext, ReactNode, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { eventManager } from 'event-manager'

export type GlobalLoading = {
  isLoading: boolean
  setLoading: (id: string) => void
  clearLoading: (id: string) => void
}

export const LoadingStateContext = createContext<GlobalLoading>({
  isLoading: false,
  setLoading: () => {},
  clearLoading: () => {}
})

export const LoadingStateProvider = ({ children }: { children: ReactNode }) => {
  const loaders = useRef<
    Record<
      string,
      {
        isLoading?: boolean
        timeoutId?: ReturnType<typeof setTimeout> | undefined
      }
    >
  >({
    angular: {
      isLoading: false
    }
  })
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const handleAngularTriggerLoading = ({ loading }: { loading: boolean }) => {
      if (loading) {
        loaders.current.angular.timeoutId = setTimeout(() => {
          loaders.current.angular.isLoading = true
          setIsLoading(true)
        }, 500)
      } else {
        if (loaders.current.angular?.timeoutId) {
          clearTimeout(loaders.current.angular.timeoutId)
        }

        loaders.current.angular = {
          isLoading: false
        }

        setIsLoading(Object.values(loaders.current).some(value => value.isLoading))
      }
    }

    eventManager.on('angular-toggle-loading', handleAngularTriggerLoading)

    return () => {
      eventManager.off('angular-toggle-loading', handleAngularTriggerLoading)
      if (loaders.current.angular.timeoutId) clearTimeout(loaders.current.angular.timeoutId)
    }
  }, [])

  const setLoading = useCallback(
    (id: string) => {
      loaders.current[id].timeoutId = setTimeout(() => {
        loaders.current[id].isLoading = true
        setIsLoading(true)
      })
    },
    [loaders.current]
  )

  const clearLoading = useCallback(
    (id: string) => {
      const existingLoader = loaders.current[id].timeoutId

      if (existingLoader) {
        clearTimeout(existingLoader)
      }

      loaders.current[id].isLoading = false

      setIsLoading(Object.values(loaders.current).some(value => value.isLoading))
    },
    [loaders.current]
  )

  return (
    <LoadingStateContext.Provider
      value={{
        isLoading,
        setLoading,
        clearLoading
      }}
    >
      {children}
    </LoadingStateContext.Provider>
  )
}

export const useLoadingState = () => {
  return useContext(LoadingStateContext)
}
