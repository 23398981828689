// @ts-nocheck
import { injectable } from 'inversify'
import { container } from 'app/Config/IOC'
import { observable, computed } from 'mobx'
import { CustomFieldGroupFormPresenter } from 'app/Settings/CustomFieldGroups/CustomFieldGroupsPage/CustomFieldGroupForm/CustomFieldGroupFormPresenter'
import { CustomFieldGroupViewPresenter } from './CustomFieldGroupViewPresenter'
import { GenericAdminPagePresenter } from 'Shared/Presenters/GenericAdminPagePresenter'
import { CustomFieldGroupListPresenter } from './CustomFieldGroupList/CustomFieldGroupListPresenter'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'
import { Types } from 'Gateways/Service/Types'

@injectable()
export class CustomFieldGroupsPagePresenter extends GenericAdminPagePresenter {
  protected viewPresenter: CustomFieldGroupViewPresenter = container.get(CustomFieldGroupViewPresenter)

  public language: ILanguageService = container.get(Types.ILanguageService)

  @observable
  public addFormPresenter: CustomFieldGroupFormPresenter

  @observable
  public editFormPresenter: CustomFieldGroupFormPresenter

  @observable
  public listSectionPresenter: CustomFieldGroupListPresenter = new CustomFieldGroupListPresenter()

  constructor() {
    super()

    this.addFormPresenter = new CustomFieldGroupFormPresenter().withViewModelMiddleware(
      this.viewPresenter.updateAddViewModel
    )
    this.editFormPresenter = new CustomFieldGroupFormPresenter().withViewModelMiddleware(
      this.viewPresenter.updateEditViewModel
    )
  }

  @computed
  public get archiveConfirmName(): string {
    if (!this.viewPresenter.editRecordVM) return ''
    return this.viewPresenter.editRecordVM.name
  }

  public launchAddSuccessToaster = () => {
    this.launchSuccessToaster(this.language.get('customFieldGroups:add:notification:message'))
  }

  public launchEditSuccessToaster = () => {
    this.launchSuccessToaster(this.language.get('customFieldGroups:edit:notification:message'))
  }

  public launchArchiveSuccessToaster = () => {
    this.launchSuccessToaster(this.language.get('customFieldGroups:archive:notification:message'))
  }
}
