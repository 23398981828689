import { useRef, useState } from 'react'
import { useClickAway } from 'react-use'
import { Controller } from 'react-hook-form'

import { Box, Icon, TextEditor } from '@cutover/react-ui'
import { DashboardComponent } from '../../types'
import { MrdDashboardWidget } from '../../account/mrd-dashboard-widget'

export const ContentWidget = ({ data }: { data: DashboardComponent }) => {
  const containerRef = useRef(null)
  useClickAway(containerRef, () => {
    const el = document.getElementsByClassName('react-modal-open')
    if (!el.length) setReadOnly(true)
  })
  const { width } = data
  const [showEditIcon, setShowEditIcon] = useState(false)
  const [readOnly, setReadOnly] = useState(true)
  const customFieldId = data.settings.custom_field_id || {}
  const customField = data.custom_fields.find(field => field.id === customFieldId)
  const label = customField?.display_name || customField?.name
  const fullWidth = width === 100 || !width

  return (
    <>
      {customField && (
        <MrdDashboardWidget title={label ?? ''} fullWidth={fullWidth}>
          {showEditIcon && (
            <Box css="position: relative;">
              <Icon
                icon="edit"
                size="medium"
                css={`
                  position: absolute;
                  right: 0px;
                  top: -38px;
                `}
              />
            </Box>
          )}
          <Box onMouseOver={() => setShowEditIcon(true)} onMouseLeave={() => setShowEditIcon(false)}>
            <Box ref={containerRef} onClick={() => setReadOnly(false)}>
              <Controller
                name={`${customField.id}`}
                render={({ field: { value, onChange } }) => (
                  <TextEditor readOnly={readOnly} value={value} onChange={onChange} />
                )}
              />
            </Box>
          </Box>
        </MrdDashboardWidget>
      )}
    </>
  )
}
