// @ts-nocheck
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { colors, transitions } from 'app/Styles/Variables'
import { withPresenter } from 'app/Config/withPresenter'
import { UserPageHeadingPresenter } from 'app/Access/Users/UsersPage/UsersPageHeading/UserPageHeadingPresenter'
import { UserPageHeadingExportPopoverComponent } from 'app/Access/Users/UsersPage/UsersPageHeading/UserPageHeadingExportPopoverComponent'
import SaveFilterComponent from 'app/Filters/SaveFilters/SaveFilterComponent'
import { HeadingTitle, HeaderFilterIcon } from 'Components/Templates'
import { Icon, Modal, ModalPresenter } from 'Components/Atoms'
import { SearchBox, ToolTip, PillFilter } from 'Components/Molecules'
import { buildFractionString } from 'Shared/HooksAndPresenters/HeadingSection'

const HeaderWrapper = styled.div`
  width: 100%;
  height: 100%;
`

const RightIconContainer = styled.div`
  z-index: 2;
  position: absolute;
  padding-right: 50px;
  padding-left: 6px;
  top: 0;
  right: 0;
  background-color: ${colors.bg1};
  box-shadow: -10px 0px 40px 10px ${colors.bg1};
`

const extraRightIconStyles = {
  display: 'inline-block',
  lineHeight: '32px',
  marginTop: '16px',
  fontSize: '24px',
  transition: transitions.mediumBezier,
  cursor: 'pointer'
}

const IconInner = styled.span`
  cursor: pointer;
  > i {
    color: ${colors.secondaryGrey};
    &:hover {
      color: ${colors.primaryGreyHover};
    }
  }
`

interface IUserPageHeadingProps {
  filterOpen: boolean
  setFilterOpen: (filterOpen: boolean) => void
  presenter: UserPageHeadingPresenter
}

const UserPageHeadingSection = observer<IUserPageHeadingProps>(({ filterOpen, setFilterOpen, presenter }) => {
  const {
    totalUserCount,
    filteredUserCount,
    containsFilters,
    searchBoxPresenter,
    exportPopoverPresenter,
    showClearFilterButton,
    clearFilters,
    accountPillsPresenter,
    roleTypesPillsPresenter,
    communicationPillsPresenter,
    accessPillsPresenter,
    searchPillPresenter,
    language
  } = presenter

  const { countString } = buildFractionString(filteredUserCount, totalUserCount)

  const Pills = () => {
    return (
      <>
        <PillFilter presenter={accountPillsPresenter} />
        <PillFilter presenter={roleTypesPillsPresenter} />
        <PillFilter presenter={communicationPillsPresenter} />
        <PillFilter presenter={accessPillsPresenter} />
        <PillFilter presenter={searchPillPresenter} />
      </>
    )
  }

  const SaveFilterIcon = () => (
    <Icon
      name="save"
      onClick={() => {
        modalPresenter.onOpen()
      }}
      extraStyles={extraRightIconStyles}
    />
  )

  const SaveFilter = () => (
    <ToolTip
      message={language.get('common:headingSection:filters:saveFilter')}
      trigger={<IconInner>{containsFilters && <SaveFilterIcon />}</IconInner>}
      position="bottom center"
      oneLineTooltip={true}
    />
  )

  const ClearFilterIcon = () => (
    <Icon
      name="dnd-forwardslash"
      onClick={() => {
        clearFilters()
      }}
      extraStyles={extraRightIconStyles}
    />
  )

  const ClearFilterButton = () => (
    <ToolTip
      message={language.get('common:headingSection:filters:clearAllFilters')}
      trigger={<IconInner>{showClearFilterButton && <ClearFilterIcon />}</IconInner>}
      position="bottom center"
      oneLineTooltip={true}
    />
  )

  const modalPresenter = new ModalPresenter()

  return (
    <HeaderWrapper>
      <HeaderFilterIcon filterOpen={filterOpen} setFilterOpen={setFilterOpen} />
      <HeadingTitle
        title={language.get('users:heading:userPageHeadingPanelTitle')}
        count={countString}
        pills={Pills()}
      />
      <RightIconContainer>
        <SaveFilter />
        <ClearFilterButton />
        <UserPageHeadingExportPopoverComponent presenter={exportPopoverPresenter} />
        <SearchBox
          presenter={searchBoxPresenter}
          onFocus={() => {
            exportPopoverPresenter.setPopoverOpen(false)
          }}
          searchContent={language.get('users:heading:userPageHeadingPanelTitle')}
          language={language}
        />
      </RightIconContainer>
      <Modal presenter={modalPresenter}>
        <SaveFilterComponent
          postSuccessCallback={() => {
            modalPresenter.onClose()
          }}
          cancelCallback={() => {
            modalPresenter.onClose()
          }}
        ></SaveFilterComponent>
      </Modal>
    </HeaderWrapper>
  )
})

export default withPresenter(UserPageHeadingPresenter)(UserPageHeadingSection)
