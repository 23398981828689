import 'react-loading-skeleton/dist/skeleton.css'
import { useMemo } from 'react'
import sample from 'lodash/sample'
import Skeleton from 'react-loading-skeleton'

import { resolveColor, useTheme } from '../../theme'
import { Box } from '../box'

// [header, subheader] length percentage of item width to give some variation in the skeleton length
const HEADER_LENGTH_VARIATION = [
  [0.8, 0.4],
  [0.95, 0.3],
  [0.6, 0.25]
]

export const ListItemSkeletonLoader = ({ sizeIndex }: { sizeIndex?: number }) => {
  const theme = useTheme()

  const widthSizes = useMemo(
    () => (sizeIndex !== undefined ? HEADER_LENGTH_VARIATION[sizeIndex % 3] : sample(HEADER_LENGTH_VARIATION)),
    []
  )

  // TODO: ensure color matches with angular and/or design
  const colorConfig = {
    baseColor: resolveColor('bg-1', theme),
    highlightColor: resolveColor('bg-2', theme)
  }

  return (
    <Box height="52px" direction="row" gap="small" pad="6px" align="center">
      <Skeleton height={40} width={40} style={{ borderRadius: theme.global.edgeSize.small }} {...colorConfig} />
      <Box
        justify="center"
        fill
        css={`
          > span {
            display: flex;
            .react-loading-skeleton {
              align-self: center;
            }
          }
        `}
      >
        <Skeleton height={13} width={`${100 * (!!widthSizes ? widthSizes[0] : 0.5)}%`} {...colorConfig} />
        <Skeleton height={13} width={`${100 * (!!widthSizes ? widthSizes[1] : 0.2)}%`} {...colorConfig} />
      </Box>
    </Box>
  )
}
