// @ts-nocheck
import { IWebSocketGateway } from './IWebSocketGateway'
import { injectable } from 'inversify'

@injectable()
export class FakeWebSocketGateway implements IWebSocketGateway {
  public connected = false

  public get disconnected(): boolean {
    return !this.connected
  }

  public connect() {
    this.connected = true
  }

  public disconnect() {
    this.connected = false
  }

  public subscribe(channel: string, id?: any) {}

  public unsubscribe(channel: string, id?: any) {}
}
