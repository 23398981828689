import { useState } from 'react'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { Button, ButtonPresenter, ToolTip } from 'Components/Molecules'
import { Icon } from 'Shared/Components/Atoms'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'

const RefreshButtonWrapper = styled.div``

interface IRefreshButton {
  tooltipMessage: string
  refreshCallback: () => Promise<void>
  singleLineRefreshTooltip?: boolean
  language: ILanguageService
}

export const RefreshButton = observer<IRefreshButton>(
  ({ tooltipMessage, refreshCallback, singleLineRefreshTooltip, language }) => {
    const [loading, setLoading] = useState(false)

    const refreshCallbackWrapper = async () => {
      setLoading(true)
      await refreshCallback()
      setLoading(false)
    }
    const refreshButtonPresenter = new ButtonPresenter('', () => {
      refreshCallbackWrapper()
    })
      .withIconName('refresh')
      .withDisplayStyle('secondary-invert')
      .withAriaLabel(language.get('common:ariaLabels:refresh'))

    return (
      <>
        <ToolTip
          oneLineTooltip={singleLineRefreshTooltip}
          message={tooltipMessage}
          trigger={
            loading ? (
              <Icon name="spinner" />
            ) : (
              <RefreshButtonWrapper>
                <Button presenter={refreshButtonPresenter} />
              </RefreshButtonWrapper>
            )
          }
          position="left center"
        />
      </>
    )
  }
)
