import { Box, Message, Modal } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { IntegrationActionItem } from 'main/services/queries/types'

type IntegrationArchiveModalProps = {
  isArchiveModalOpen: boolean
  title?: string
  isConnection?: boolean
  actionItems?: IntegrationActionItem[]
  onArchiveModalClose: () => void
  onArchiveModalConfirm: () => void
}

export const IntegrationArchiveModal = ({
  isArchiveModalOpen,
  title,
  isConnection = false,
  actionItems,
  onArchiveModalClose,
  onArchiveModalConfirm
}: IntegrationArchiveModalProps) => {
  const { t } = useLanguage('integrationSettings')

  return (
    <Modal
      open={isArchiveModalOpen}
      onClose={onArchiveModalClose}
      onClickConfirm={onArchiveModalConfirm}
      title={isConnection ? t('archiveModal.archiveIntegrationHeader') : t('archiveModal.archiveActionHeader')}
      confirmText={t('archiveModal.archiveConfirmButton')}
      confirmIcon="trash-o"
    >
      {isConnection ? (
        <Box>
          <Box as="span">
            {t('archiveModal.archiveConnectionConfirm')}
            <strong>{title}</strong>?
          </Box>
          {actionItems && actionItems.length > 0 && (
            <Box as="span">{t('archiveModal.archiveConnectionConfirmComplement')}</Box>
          )}
        </Box>
      ) : (
        <Box
          css={`
            margin-bottom: 24px;
          `}
        >
          <Box as="span">
            {t('archiveModal.archiveActionConfirm')}
            <strong>{title}</strong>?
          </Box>
          <Box as="span">{t('archiveModal.archiveActionConfirmComplement')}</Box>
        </Box>
      )}
      {isConnection && actionItems && actionItems.length > 0 && (
        <Box
          as="ul"
          css={`
            margin: 12px 0 24px 24px;
            font-size: 14px;
          `}
        >
          {actionItems.map(action => {
            return (
              <Box as="li" key={action.id}>
                {action.name}
              </Box>
            )
          })}
        </Box>
      )}
      {(!isConnection || (actionItems && actionItems.length > 0)) && (
        <Message type="info" message={t('archiveModal.archiveInfo')} />
      )}
    </Modal>
  )
}
