import { MouseEventHandler, ReactNode } from 'react'

import { CenterMainWrapper } from './center-main-wrapper'
import { ContentWrapper } from './content-wrapper'
import { MainPanelHeader } from './main-panel-header'
import { MainPanelWrapper } from './main-panel-wrapper'
import { PageWrapper } from './page-wrapper'
import { PageWrapperInner } from './page-wrapper-inner'
import { FloatingActionButton } from '../../button/button'

export type PageProps = {
  header?: JSX.Element | string
  loading?: boolean
  error?: boolean
  errorMessage?: string
  filterPanelOpen?: boolean
  sidebarPanel?: JSX.Element
  children?: ReactNode
  onClickAdd?: MouseEventHandler<HTMLButtonElement>
}

export const Page = ({
  header,
  loading,
  error,
  errorMessage,
  children,
  filterPanelOpen,
  sidebarPanel,
  onClickAdd
}: PageProps) => {
  const headerContent =
    typeof header === 'string' ? (
      <MainPanelHeader title={header} />
    ) : header ? (
      <MainPanelHeader>{header}</MainPanelHeader>
    ) : null

  return (
    <PageWrapper>
      <PageWrapperInner>
        <CenterMainWrapper>
          <MainPanelWrapper filterPanelOpen={filterPanelOpen} loading={loading}>
            {headerContent}
            <ContentWrapper hasHeader={!!headerContent} error={error} errorMessage={errorMessage}>
              {children}
            </ContentWrapper>
            {/* @ts-expect-error remove this after fixing the button event handler type */}
            {onClickAdd && <FloatingActionButton onClick={onClickAdd} />}
          </MainPanelWrapper>
        </CenterMainWrapper>
      </PageWrapperInner>
      {sidebarPanel}
    </PageWrapper>
  )
}
