import { useQuery, UseQueryOptions } from 'react-query'

import { apiClient } from 'main/services/api'
import { ApiError } from 'main/services/api/http-gateway-adapter'

export type AccountRunbookType = {
  id: string
  name: string
}

function useRunbookTypesAll(options: UseQueryOptions<AccountRunbookType[], ApiError> = {}) {
  return useQuery<AccountRunbookType[], ApiError>(
    ['runbook-types'],
    async () => {
      const { data } = await apiClient.get<{ runbook_types: AccountRunbookType[] }>({
        url: 'runbook_types'
      })

      return data.runbook_types
    },
    options
  )
}

function useRunbookTypesGlobal(options: UseQueryOptions<AccountRunbookType[], ApiError> = {}) {
  return useQuery<AccountRunbookType[], ApiError>(
    ['runbook-types-global'],
    async () => {
      const { data } = await apiClient.get<{ runbook_types: AccountRunbookType[] }>({
        url: 'runbook_types',
        params: {
          global: true
        }
      })

      return data.runbook_types
    },
    options
  )
}

function useRunbookTypesByAccount(accountId: number, options: UseQueryOptions<AccountRunbookType[], ApiError> = {}) {
  return useQuery<AccountRunbookType[], ApiError>(
    ['runbook-types-account', accountId],
    async () => {
      const { data } = await apiClient.get<{ runbook_types: AccountRunbookType[] }>({
        url: 'runbook_types',
        params: {
          account_id: [accountId]
        }
      })

      return data.runbook_types
    },
    options
  )
}

export function useRunbookTypes({ accountId }: { accountId?: number }) {
  const isAccount = accountId != null
  const runbookTypesAll = useRunbookTypesAll({ enabled: !isAccount })
  const runbookTypesByAccount = useRunbookTypesByAccount(accountId ?? 0, { enabled: isAccount })
  const runbookTypesGlobal = useRunbookTypesGlobal({ enabled: isAccount })

  if (!isAccount) {
    return runbookTypesAll
  }

  return {
    isLoading: runbookTypesByAccount.isLoading || runbookTypesGlobal.isLoading,
    isError: runbookTypesByAccount.isError || runbookTypesGlobal.isError,
    error: runbookTypesByAccount.error ?? runbookTypesGlobal.error,
    isSuccess: runbookTypesByAccount.isSuccess && runbookTypesGlobal.isSuccess,
    data:
      runbookTypesByAccount.isSuccess && runbookTypesGlobal.isSuccess
        ? (runbookTypesByAccount.data ?? []).concat(runbookTypesGlobal.data ?? [])
        : []
  }
}
