import { Card, Grid, Icon, Modal, Text } from '@cutover/react-ui'
import { ConfigModel } from 'main/data-access'
import { useLanguage } from 'main/services/hooks'

type IntegrationDocumentationModalType = {
  open: boolean
  onClose: () => void
}

type IntegrationDocumentationCardType = {
  heading: string
  imageSrc?: string
  subText?: string
  url: string
}

const PREBUILT_INTEGRATION_DOCS = [
  { name: 'jira', url: 'https://developer.cutover.com/example-integrations/jira-ticket', imgFileName: 'Jira.png' },
  {
    name: 'azureDevops',
    url: 'https://developer.cutover.com/example-integrations/ado-run-pipeline',
    imgFileName: 'AzureDevops.png'
  },
  {
    name: 'serviceNow',
    url: 'https://developer.cutover.com/example-integrations/servicenow-change-request',
    imgFileName: 'ServiceNow.png'
  },
  {
    name: 'ansible',
    url: 'https://developer.cutover.com/example-integrations/ansible-job-template',
    imgFileName: 'Ansible.png'
  },
  {
    name: 'msTeams',
    url: 'https://developer.cutover.com/example-integrations/ms-teams-post-to-channel',
    imgFileName: 'MicrosoftTeams.png'
  },
  {
    name: 'viewMore',
    url: 'https://developer.cutover.com/example-integrations'
  }
]

export const IntegrationDocumentationModal = ({ open, onClose }: IntegrationDocumentationModalType) => {
  const { cutoverApiFullPath } = ConfigModel.useGet()
  const { t } = useLanguage('integrationSettings', { keyPrefix: 'documentationModal' })

  return (
    <Modal open={open} onClose={onClose} title={t('heading')} hideFooter>
      <Card
        title={t('customIntegration.text')}
        subtitle={t('customIntegration.subText')}
        startComponent={<Icon icon="info" color="message-info" />}
        endComponents={[openNewIcon]}
        onClick={() => window.open('https://developer.cutover.com/create-an-integration', '_newtab')}
      />
      <Text weight="bold" margin={{ top: 'large', horizontal: 'xsmall' }}>
        {t('subHeading')}
      </Text>
      <Grid minWidth={200}>
        {PREBUILT_INTEGRATION_DOCS.map(integration => {
          return (
            <IntegrationDocumentationCard
              key={integration.name}
              heading={t(`${integration.name}.heading`)}
              imageSrc={
                integration.imgFileName
                  ? `${cutoverApiFullPath}/img/integrations/${integration.imgFileName}`
                  : undefined
              }
              subText={t(`${integration.name}.subText`, '')}
              url={integration.url}
            />
          )
        })}
      </Grid>
    </Modal>
  )
}

const IntegrationDocumentationCard = ({ heading, imageSrc, subText, url }: IntegrationDocumentationCardType) => {
  const imageOrFolderIcon = imageSrc ? (
    <img src={imageSrc} height="42px" alt={`${heading} logo`} />
  ) : (
    <Icon size="large" icon="folder-open" color="message-info" />
  )

  return (
    <Card
      title={heading}
      startComponent={imageOrFolderIcon}
      endComponents={[openNewIcon]}
      subtitle={subText}
      onClick={() => window.open(url, '_newtab')}
    />
  )
}

const openNewIcon = <Icon icon="open-new" color="message-info" />
