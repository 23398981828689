// @ts-nocheck
import { container, resetContainer } from 'app/Config/IOC'
import { CurrentPagePresenter } from 'app/Routing/CurrentPage/CurrentPagePresenter'

// Flag for AngularJS to know that React has dirty changes (form has left over data)
export const setReactDirty = (dirty: boolean) => {
  // @ts-ignore
  window.reactAppDirty = dirty
}

// Delegates handling of errors from APIs to AngularJS
export const handleErrorResponse = (response: any) => {
  try {
    // @ts-ignore
    if (window.angularJS) {
      window.angularJS.handleError(response)
    }
  } catch (error) {
    console.warn(error)
  }
}

export const forceAngularJSLogOut = () => {
  // @ts-ignore
  window.angularJS.logOut()
}

export const userVerifySSO = user => {
  // @ts-ignore
  window.angularJS.verifySSO(user)
}

export const bindClearReactContainer = () => {
  // @ts-ignore
  window.clearReactContainer = resetContainer
}

export const setAngularJSSettingsPanelOpenState = (state: boolean) => {
  container.get(CurrentPagePresenter).angularJSSettingsPanelOpenState = state
}

export const closeAngularSettingsPanel = () => {
  // @ts-ignore
  window.angularJS.settingsPanelClose()
}
