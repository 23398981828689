import { forwardRef, InputHTMLAttributes } from 'react'
import styled from 'styled-components/macro'

import { theme } from '../theme/theme-deprecated'

export type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  id?: string
  name?: string
  type: InputTypes
}

export type InputTypes =
  | 'button'
  | 'checkbox'
  | 'color'
  | 'date'
  | 'datetime-local'
  | 'email'
  | 'file'
  | 'hidden'
  | 'image'
  | 'month'
  | 'number'
  | 'password'
  | 'radio'
  | 'range'
  | 'reset'
  | 'search'
  | 'submit'
  | 'tel'
  | 'text'
  | 'time'
  | 'url'
  | 'week'

export const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  return <InputStyled {...props} ref={ref} />
})

const InputStyled = styled.input<InputProps>`
  ${({ type, disabled, width }: InputProps) =>
    (type === 'text' || type === 'number' || type === 'password' || type === 'email') &&
    `
    color: ${disabled ? theme.formField.input.disabled?.color : theme.formField.input.color};
    border: none;
    outline: none;
    flex-grow: 1;
    width: ${width ? `${width}px` : '100%'};
    background-color: transparent;
  `}
`
