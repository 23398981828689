import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const CommandPrompt = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="CommandPrompt" {...props}>
    <path d="M15.121 32 13 29.879l5.778-5.779L13 18.323l2.121-2.121 7.9 7.9L15.12 32ZM34 30H23v3h11v-3Z" fill="#000" /><path fillRule="evenodd" clipRule="evenodd" d="M14 6a8 8 0 0 0-8 8v20a8 8 0 0 0 8 8h20a8 8 0 0 0 8-8V14a8 8 0 0 0-8-8H14Zm20 3a5 5 0 0 1 5 5v20a5 5 0 0 1-5 5H14a5 5 0 0 1-5-5V14a5 5 0 0 1 5-5h20Z" fill="#000" />
  </StyledIcon>
))

CommandPrompt.displayName = 'CommandPrompt'

export { CommandPrompt }
