import { Editor, Transforms } from 'slate'

import { getParentBlock } from '../utils'

export const insertExitBreak = (editor: Editor) => {
  Transforms.splitNodes(editor, {
    always: true
  })
  Transforms.setNodes(editor, { type: 'paragraph' })
}

// Add new empty paragraph after a table
const insertTableBreak = (editor: Editor) => {
  const { selection } = editor
  if (!selection) {
    return false
  }

  // Insert empty paragraph after the selection's parent UL or OL
  Transforms.insertNodes(
    editor,
    { type: 'paragraph', children: [{ text: '' }] },
    { at: [selection.anchor.path[0] + 1], select: true }
  )
}

export const withCustomBreak = (editor: Editor) => {
  const { insertBreak } = editor

  editor.insertSoftBreak = () => {
    editor.insertText('\n')
  }

  editor.insertBreak = () => {
    const currentBlock = getParentBlock(editor)
    const currentBlockType = currentBlock ? currentBlock.type : ''
    const alwaysExitBreakBlocks = ['quote', 'message', 'heading', 'image', 'code']
    const listBlocks = ['numbered-list', 'bulleted-list']
    if (alwaysExitBreakBlocks.includes(currentBlockType)) {
      insertExitBreak(editor)
      return
    } else if (listBlocks.includes(currentBlockType)) {
      // List plugin deals with this
      return
    } else if (currentBlockType === 'table') {
      insertTableBreak(editor)
      return
    }

    // If no conditions match, revert to default
    insertBreak()
  }

  return editor
}
