// @ts-nocheck
import { Modal, GenericAddModal, ModalPresenter } from 'Components/Atoms'
import { RoleTypeAddSection } from './RoleTypeAdd/RoleTypeAddSection'
import { RoleTypesApplicationPresenter } from './RoleTypesApplicationPresenter'
import { EditPanelArchiveModal } from 'Components/Templates'
import { addSectionHooks } from 'Shared/HooksAndPresenters/AddEditSection'
import { withInjection } from 'Helpers/InjectionHelpers'
import { Types } from 'Gateways/Service/Types'
import { ILanguageService } from 'Helpers/Language/ILanguageService'

interface IRolesPageModalProps {
  rolesAddModalOpenState: boolean
  setRolesAddModalOpenState: (bool: boolean) => void
  archiveModalPresenter: ModalPresenter
  archiveConfirmName: string
  cancelArchiveCallback: () => void
  continueArchiveCallback: () => void
  responseErrors: string[]
  language: ILanguageService
  roleTypesApplicationPresenter: RoleTypesApplicationPresenter
}

export const RoleTypesPageModalsComp = (props: IRolesPageModalProps) => {
  const {
    rolesAddModalOpenState,
    setRolesAddModalOpenState,
    archiveModalPresenter,
    archiveConfirmName,
    cancelArchiveCallback,
    continueArchiveCallback,
    responseErrors,
    language
  } = props

  const { addModalPresenter } = addSectionHooks(setRolesAddModalOpenState, rolesAddModalOpenState)

  return (
    <>
      <GenericAddModal
        title={language.get('roleTypes:add:title')}
        presenter={addModalPresenter}
        cancelCallback={() => {
          setRolesAddModalOpenState(false)
        }}
        viewPresenter={props.roleTypesApplicationPresenter}
        language={language}
      >
        <RoleTypeAddSection
          postSuccessCallback={() => {
            addModalPresenter.onClose()
            setRolesAddModalOpenState(false)
          }}
        />
      </GenericAddModal>

      <Modal presenter={archiveModalPresenter.withOnCloseExtra(cancelArchiveCallback)}>
        <EditPanelArchiveModal
          archiveConfirmName={archiveConfirmName}
          cancelCallback={cancelArchiveCallback}
          continueCallback={continueArchiveCallback}
          responseErrors={responseErrors}
          modalTitle={language.get('roleTypes:delete:confirmationTitle')}
          modalMessage={language.get('roleTypes:delete:confirmation')}
        />
      </Modal>
    </>
  )
}

export const RoleTypesPageModals = withInjection({
  language: Types.ILanguageService,
  roleTypesApplicationPresenter: RoleTypesApplicationPresenter
})(RoleTypesPageModalsComp)
