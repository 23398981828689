import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Logout = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Logout" {...props}>
    <path d="M14 6h10v3H14a5 5 0 0 0-5 5v20a5 5 0 0 0 5 5h10v3H14a8 8 0 0 1-8-8V14a8 8 0 0 1 8-8Z" fill="#000" /><path d="m31.504 13.504 10.8 10.8-10.8 10.798-2.121-2.121 7.178-7.178H14.09v-3h22.47l-7.178-7.178 2.121-2.12Z" fill="#000" />
  </StyledIcon>
))

Logout.displayName = 'Logout'

export { Logout }
