// @ts-nocheck
import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { colors, px2rem } from 'app/Styles/Variables'
import { UserPageHeadingExportPopoverPresenter } from 'app/Access/Users/UsersPage/UsersPageHeading/UserPageHeadingExportPopoverPresenter'
import { ExportUsersModal } from 'Components/Templates'
import { Button, Popover } from 'Components/Molecules'
import { Modal } from 'Components/Atoms'

const BorderedButtonWrapper = styled.div`
  border-top: 1px solid ${colors.primaryGreyBackgroundColor};
`

interface IUsersPageHeadingExportPopoverComponent {
  presenter: UserPageHeadingExportPopoverPresenter
}

export const UserPageHeadingExportPopoverComponent = observer<IUsersPageHeadingExportPopoverComponent>(
  ({ presenter }) => {
    const {
      downloadPopoverPresenter,
      exportModalOpenButton,
      reloadDataButtonPresenter,
      exportModalPresenter,
      exportUsersModalPresenter,
      reloadClicked,
      popoverOpen,
      setPopoverOpen,
      language
    } = presenter

    const togglePopoverOpen = () => {
      setPopoverOpen(!popoverOpen)
    }
    useEffect(() => {
      if (exportModalPresenter.isOpen && popoverOpen) setPopoverOpen(false)
    }, [exportModalPresenter.isOpen])

    useEffect(() => {
      if (popoverOpen) setPopoverOpen(false)
    }, [reloadClicked])

    return (
      <>
        <Popover
          presenter={downloadPopoverPresenter}
          open={false}
          controlledOpen={popoverOpen}
          type="customContent"
          content={
            <>
              <>
                <Button presenter={exportModalOpenButton} extraStyles={{ width: '280px' }} />
              </>
              <BorderedButtonWrapper>
                <Button presenter={reloadDataButtonPresenter} extraStyles={{ width: '280px' }} />
              </BorderedButtonWrapper>
            </>
          }
          moreOptionsToolTipText={language.get('common:moreOptions')}
          wrapperExtraStyles={{ marginTop: px2rem(20), display: 'inline-block' }}
          customTriggerCallback={togglePopoverOpen}
        />
        <Modal presenter={exportModalPresenter}>
          <ExportUsersModal presenter={exportUsersModalPresenter} />
        </Modal>
      </>
    )
  }
)
