// @ts-nocheck
import styled from 'styled-components'
import { px2rem, colors } from 'app/Styles/Variables'

export const LoadingPageStyled = styled.div`
  #select-menu-portal {
    z-index: 10;
    position: absolute;
  }

  .splash {
    visibility: visible;
    background: ${colors.primary};
    color: white;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
    position: absolute;
    text-align: center;
    opacity: 1;
    -webkit-transition: opacity 0.4s, visibility 0.4s cubic-bezier(0.35, 0, 0.25, 1);
    -ms-transition: opacity 0.4s, visibility 0.4s cubic-bezier(0.35, 0, 0.25, 1);
    transition: opacity 0.4s, visibility 0.4s cubic-bezier(0.35, 0, 0.25, 1);
  }

  .splash.loaded {
    visibility: hidden;
    opacity: 0;
  }

  .splash svg {
    position: absolute;
    top: 50%;
    left: 50%;
    fill: #ffffff;

    margin-left: ${px2rem(-70)};
    margin-top: ${px2rem(-70)};
    width: ${px2rem(140)};
    height: ${px2rem(140)};
  }

  @-webkit-keyframes logo-animation {
    0% {
      opacity: 0.2;
    }
    20% {
      opacity: 1;
    }
    100% {
      opacity: 0.2;
    }
  }

  @-moz-keyframes logo-animation {
    0% {
      opacity: 0.2;
    }
    20% {
      opacity: 1;
    }
    100% {
      opacity: 0.2;
    }
  }

  @-o-keyframes logo-animation {
    0% {
      opacity: 0.2;
    }
    20% {
      opacity: 1;
    }
    100% {
      opacity: 0.2;
    }
  }

  @keyframes logo-animation {
    0% {
      opacity: 0.2;
    }
    20% {
      opacity: 1;
    }
    100% {
      opacity: 0.2;
    }
  }

  #logo {
    &.alive {
      .animate-logo {
        animation-name: logo-animation;
        animation-duration: 1.4s;
        animation-iteration-count: infinite;
        animation-fill-mode: both;
      }
      .animate-logo-second {
        animation-delay: 0.2s;
      }
      .animate-logo-third {
        animation-delay: 0.4s;
      }
    }
  }
`
