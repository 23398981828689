import { calculateCogRatios } from '../node-map-utils'
import { CogRatio, PaintCog } from '../node-map-types'

export const paintCog = ({
  ctx,
  x,
  y,
  innerRadius,
  outerRadius,
  cogAngle,
  state,
  lineWidth,
  stroke,
  fill
}: PaintCog) => {
  // This method works by first moving to a start point on the edge of the first cog tooth and then
  // iterating over an array of precalculated ratios which are used to calculate subsequent points on the cog.
  var toothAngle = state.units.cogAngle * state.units.cogToothBreadth * 0.005
  var cogRatios = calculateCogRatios(state, cogAngle, toothAngle) as CogRatio[]
  var outToIn = true
  ctx.moveTo(x + outerRadius * Math.cos(toothAngle), y + outerRadius * Math.sin(toothAngle))
  ctx.beginPath()

  for (let ratiosIndex = 0; ratiosIndex < cogRatios.length; ratiosIndex += 1) {
    const ratio = cogRatios[ratiosIndex] as CogRatio
    const { toothRatioX, toothRatioY, gapRatioX, gapRatioY } = ratio
    if (outToIn) {
      ctx.lineTo(x + innerRadius * toothRatioX, y + innerRadius * toothRatioY)
      ctx.lineTo(x + outerRadius * gapRatioX, y + outerRadius * gapRatioY)
    } else {
      ctx.lineTo(x + outerRadius * toothRatioX, y + outerRadius * toothRatioY)
      ctx.lineTo(x + innerRadius * gapRatioX, y + innerRadius * gapRatioY)
    }
    outToIn = !outToIn
  }
  ctx.closePath()

  ctx.lineWidth = lineWidth
  ctx.strokeStyle = stroke
  ctx.stroke()

  if (fill) {
    ctx.fillStyle = fill
    ctx.fill()
  }
}
