import { ReactNode } from 'react'
import { useMount } from 'react-use'

import { toCamelCase } from '@cutover/api'
import { ContentNode } from './apps-types'
import {
  AlertNode,
  AvatarNode,
  ButtonNode,
  CheckboxesFieldNode,
  DateTimeFieldNode,
  DividerNode,
  FlexColumnNode,
  FlexRowNode,
  IconNode,
  LineItemNode,
  ListItemNode,
  MenuNode,
  MultiSelectFieldNode,
  PageNode,
  PanelNode,
  PillNode,
  RadiosFieldNode,
  ScrollNode,
  SearchBarNode,
  SingleSelectFieldNode,
  TabGroupNode,
  TextAreaFieldNode,
  TextEditorNode,
  TextFieldNode,
  TextNode,
  TriggerNode
} from './nodes'
import { useAppsFieldValuesWithContextState } from 'main/recoil/data-access'

const NODES = {
  avatar: AvatarNode,
  divider: DividerNode,
  tabGroup: TabGroupNode,
  page: PageNode,
  panel: PanelNode,
  pill: PillNode,
  textEditor: TextEditorNode,
  button: ButtonNode,
  text: TextNode,
  trigger: TriggerNode,
  listItem: ListItemNode,
  textField: TextFieldNode,
  dateTimeField: DateTimeFieldNode,
  searchBar: SearchBarNode,
  singleSelectField: SingleSelectFieldNode,
  multiSelectField: MultiSelectFieldNode,
  scroll: ScrollNode,
  menu: MenuNode,
  textAreaField: TextAreaFieldNode,
  lineItem: LineItemNode,
  flexRow: FlexRowNode,
  flexColumn: FlexColumnNode,
  icon: IconNode,
  checkboxesField: CheckboxesFieldNode,
  radiosField: RadiosFieldNode,
  alert: AlertNode
} as { [x: string]: any }

type AppsComponentProps = {
  node: ContentNode
  children?: ReactNode
  appId?: string
  resourceId?: string
}

export const AppsComponent = (allProps: AppsComponentProps) => {
  const { node, children, appId, resourceId } = allProps
  const { type, content, id, ...props } = node
  const context = `${resourceId}-${appId}`
  const [fieldValue, setFieldValue] = useAppsFieldValuesWithContextState(context)

  useMount(() => {
    if (node.name) {
      const value = node.selected ? node.selected : node.value ? node.value : ''
      if (fieldValue && fieldValue[node.name]) return
      setFieldValue({ name: node.name, value })
    }
  })

  const Component = NODES[toCamelCase(type)]

  if (!Component) {
    if (type !== 'modal') {
      console.warn(`"${type}" is not a valid app node.`)
    }
    return null
  }

  if (content) {
    return (
      <Component {...toCamelCase({ ...props, content, type })} id={id} appId={appId} resourceId={resourceId}>
        {children}
      </Component>
    )
  } else {
    return <Component {...toCamelCase({ ...props, content, type })} id={id} appId={appId} resourceId={resourceId} />
  }
}
