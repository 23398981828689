import styled from 'styled-components/macro'

import { Box } from '../../../../layout'

export const SelectFieldBox = styled(Box)`
  width: 100%;
  position: relative;

  // FIXME: figure out where this adjustment should live.
  input[type='text'] {
    padding-left: 0;
  }
`
