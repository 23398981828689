import { useMutation } from 'react-query'

import {
  CreateEmailScheduleParams,
  UpdateUserSettingParams,
  UserSettingResponse,
  UserSettingsResponse
} from 'main/components/dashboards/runbook-dashboard-schedule/runbook-dashboard-schedule-types'
import { queryClient } from 'main/query-client'
import { apiClient } from 'main/services/api'

export type CreateUserSettingParams = Omit<UpdateUserSettingParams, 'id'>

type CreateEmailScheduleResponse = {
  user_setting: CreateEmailScheduleParams & {
    id: number
    meta: { recipient_count: number }
    user_id: number
  }
}

/**
 * Inserts a new user settings item in the settings list
 * with respect for the order (alpha by name asc)
 * @param data The settings list
 * @param item The settings element
 */
const insertUserSettingSorted = (data: UserSettingResponse[], item: CreateEmailScheduleParams) => {
  const index = data.findIndex(el => {
    if (el.data.schedules[0].name && item.data.schedules[0].name) {
      return el.data.schedules[0]?.name > item.data.schedules[0]?.name
    }
    return false
  })
  let start = index == -1 ? data.length : index
  data.splice(start, 0, item as UserSettingResponse)
}

export const useCreateEmailScheduleUserSetting = () => {
  return useMutation(
    'create-email-schedule-user-setting',
    async (email: CreateEmailScheduleParams) => {
      return await apiClient.post<CreateEmailScheduleParams, CreateEmailScheduleResponse>({
        url: 'user_settings',
        data: email
      })
    },
    {
      onSuccess: response => {
        const runbookId = response.data?.user_setting.data.runbook_id
        const dashboardId = response.data?.user_setting.resource_id
        queryClient.setQueryData<UserSettingsResponse>(
          ['user_settings', runbookId, dashboardId],
          // @ts-ignore
          (data: UserSettingsResponse | undefined) => {
            if (!data || !response.data) return data

            insertUserSettingSorted(data.user_settings, response.data.user_setting)

            return data
          }
        )
      }
    }
  )
}

export const useUpdateEmailScheduleUserSetting = () => {
  return useMutation('update-email-schedule-user-setting', async (email: UpdateUserSettingParams) => {
    return await apiClient.put<UpdateUserSettingParams, { user_setting: UserSettingResponse }>({
      url: `user_settings/${email.id}`,
      data: email
    })
  })
}
