import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Paste = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Paste" {...props}>
    <path d="M26 3v3h-6V3h6ZM3 9h3v6H3V9Zm29 13v7h7v3h-7v7h-3v-7h-7v-3h7v-7h3Z" fill="#000" /><path d="M37 16a8 8 0 0 1 8 8v13a8 8 0 0 1-8 8H24a8 8 0 0 1-8-8V24a8 8 0 0 1 8-8h13Zm5 8a5 5 0 0 0-5-5H24a5 5 0 0 0-5 5v13a5 5 0 0 0 5 5h13a5 5 0 0 0 5-5V24ZM32 9h-3v4h3V9ZM6 20H3v6h3v-6Zm9-17v3H9V3h6Zm-2 29v-3H9v3h4Z" fill="#000" />
  </StyledIcon>
))

Paste.displayName = 'Paste'

export { Paste }
