import { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'

import { Modal } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { TextEditorField } from 'main/components/shared/form'

export const TaskEditDescriptionModal = ({
  initialValue,
  readOnly,
  onClose,
  onClickConfirm
}: {
  initialValue?: string | null
  readOnly: boolean
  onClose: () => void
  onClickConfirm: (value: string) => void
}) => {
  const { watch } = useFormContext()
  const descriptionModalValue = watch('description')
  const initialVal = useMemo(() => initialValue, [])

  const { t } = useLanguage('tasks', { keyPrefix: 'editPanel' })

  return (
    <Modal
      title={t('fields.description.modalTitle')}
      confirmText={t('fields.description.modalButton')}
      open
      onClose={onClose}
      onClickConfirm={() => onClickConfirm(descriptionModalValue)}
    >
      <TextEditorField
        key={initialVal ?? ''}
        name="description"
        label={t('fields.description.label')}
        readOnly={readOnly}
        plain
      />
    </Modal>
  )
}
