import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const TaskTypeComms = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="TaskTypeComms" {...props}>
    <path d="M42 24c0 9.941-8.059 18-18 18S6 33.941 6 24 14.059 6 24 6s18 8.059 18 18Zm-2 0c0-8.837-7.163-16-16-16S8 15.163 8 24s7.163 16 16 16 16-7.163 16-16Z" fill="#000" /><path d="M2 23.855A21.93 21.93 0 0 1 8.415 8.327L9.83 9.741A19.937 19.937 0 0 0 4 23.855c0 5.56 2.269 10.59 5.93 14.215l-1.414 1.414A21.932 21.932 0 0 1 2 23.855Zm42 0a19.937 19.937 0 0 0-5.83-14.114l1.415-1.414A21.93 21.93 0 0 1 46 23.855a21.932 21.932 0 0 1-6.516 15.629l-1.415-1.414A19.939 19.939 0 0 0 44 23.855Z" fill="#000" />
  </StyledIcon>
))

TaskTypeComms.displayName = 'TaskTypeComms'

export { TaskTypeComms }
