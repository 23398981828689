import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const RadioboxChecked = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="RadioboxChecked" {...props}>
    <path d="M24 33a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z" fill="#000" /><path d="M42 24c0 9.941-8.059 18-18 18S6 33.941 6 24 14.059 6 24 6s18 8.059 18 18Zm-3 0c0-8.284-6.716-15-15-15-8.284 0-15 6.716-15 15 0 8.284 6.716 15 15 15 8.284 0 15-6.716 15-15Z" fill="#000" />
  </StyledIcon>
))

RadioboxChecked.displayName = 'RadioboxChecked'

export { RadioboxChecked }
