import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const CommentDelete = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="CommentDelete" {...props}>
    <path d="m31.071 16.05-2.121-2.12L24 18.879l-4.95-4.95-2.121 2.121 4.95 4.95-4.95 4.95 2.121 2.121 4.95-4.95 4.95 4.95 2.121-2.121-4.95-4.95 4.95-4.95Z" fill="#000" /><path d="M32 36v10L18.667 36H14a8 8 0 0 1-8-8V14a8 8 0 0 1 8-8h20a8 8 0 0 1 8 8v14a8 8 0 0 1-8 8h-2Zm-18-3h5.667L29 40v-7h5a5 5 0 0 0 5-5V14a5 5 0 0 0-5-5H14a5 5 0 0 0-5 5v14a5 5 0 0 0 5 5Z" fill="#000" />
  </StyledIcon>
))

CommentDelete.displayName = 'CommentDelete'

export { CommentDelete }
