// @ts-nocheck
import { injectable } from 'inversify'
import { observable, computed, action } from 'mobx'
import { GenericPageFormPresenter } from 'app/Shared/Presenters/GenericPageFormPresenter'
import { GenericAdminViewPresenter } from 'Shared/Presenters/GenericAdminViewPresenter'

@injectable()
export abstract class GenericPageAddPresenter {
  protected abstract viewPresenter: GenericAdminViewPresenter
  public abstract formPresenter: GenericPageFormPresenter

  @action
  public save = async () => {
    this.formPresenter.processSubmission()

    if (!this.formPresenter.isFormValid()) {
      return
    }

    const response = await this.viewPresenter.createRecord()

    if (!response.success) {
      this.formPresenter.formValidator.serverErrors = response.errorMessages
      this.formPresenter.processServerErrors(response.errorMessages)
      this.viewPresenter.submitted = false
      return
    } else {
      if (this.postSuccessCall) {
        this.postSuccessCall()
      }

      await this.viewPresenter.loadList()
    }
  }

  @observable
  public postSuccessCall?: () => void

  @computed
  public get formSubmitted(): boolean {
    return this.viewPresenter.submitted
  }

  @action
  public withPostSuccessCall = (postSuccessCall: () => void) => {
    this.postSuccessCall = postSuccessCall
    return this
  }
}
