// @ts-nocheck
import { observable, action } from 'mobx'
import { injectable } from 'inversify'
import { GenericListRepository } from 'app/Repositories/GenericListRepository'
import { QueryStringBuilder } from 'Shared/Helpers/QueryStringBuilder'
import { container } from 'app/Config/IOC'
import { IBaseResponse } from 'Gateways/Service/IBaseResponse'
import { IAccountRunbookPM } from 'Shared/Entities/Types/Runbooks/IAccountRunbookPM'
import { IAccountRunbookDTO } from 'Shared/Entities/Types/Runbooks/IAccountRunbookDTO'

@injectable()
export class AccountRunbooksRepository extends GenericListRepository {
  constructor() {
    super('meta')
  }

  @observable
  public accountRunbooks: IAccountRunbookPM[] = []

  @observable
  public batchLoadNumber: number = 10

  protected queryStringBuilder: QueryStringBuilder = container.get(QueryStringBuilder)

  public preLoad = (params: { [key: string]: any }): string => {
    const { accountId, value } = params

    const queryParams: { [key: string]: any } = {
      accountId,
      offset: 0,
      limit: this.batchLoadNumber
    }

    const query = value ? `&q=${value}` : ''

    return `runbooks?${this.queryStringBuilder.getCustomQueryString(queryParams)}${query}`
  }

  @action
  public postLoad = (rawData: IBaseResponse) => {
    this.accountRunbooks = this.getAccountRunbooksFromAPIData(rawData)
  }

  private getAccountRunbooksFromAPIData = (rawData: IBaseResponse): IAccountRunbookPM[] => {
    return rawData.body.runbooks.map((runbook: IAccountRunbookDTO) => {
      return {
        id: runbook.id,
        name: runbook.name
      }
    })
  }

  @action
  public clear = () => {
    this.clearInherited()
    this.accountRunbooks = []
  }
}
