import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const TaskEdited = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="TaskEdited" {...props}>
    <path d="M24.546 29.87h-6.364v-6.364L38.688 3l6.364 6.364L24.546 29.87Zm-1.243-3L40.81 9.364l-2.121-2.121-17.506 17.506v2.121h2.121Z" fill="#000" /><path d="M39 24a15.02 15.02 0 0 0-.497-3.844l2.389-2.389A17.963 17.963 0 0 1 42 24c0 9.941-8.059 18-18 18S6 33.941 6 24 14.059 6 24 6c2.22 0 4.346.402 6.309 1.137l-2.383 2.382A15.02 15.02 0 0 0 24 9C15.716 9 9 15.716 9 24c0 8.284 6.716 15 15 15 8.284 0 15-6.716 15-15Z" fill="#000" />
  </StyledIcon>
))

TaskEdited.displayName = 'TaskEdited'

export { TaskEdited }
