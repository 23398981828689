import { createContext, ReactNode, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useInitialMount } from '@cutover/react-ui'
import { RunbookResponse } from 'main/services/api/data-providers/runbook-types'
import { useWebsockets } from 'main/services/hooks'

const RUNBOOK_CHANNEL_NAME = 'RunbookChannel'

export const RunbookChannelContext = createContext<RunbookResponse | null>(null)

/** @deprecated Add handlers to the RunbookChannelSubscriber */
export const RunbookChannelProvider = ({ children }: { children: ReactNode }) => {
  const websockets = useWebsockets()
  const { runbookId } = useParams()
  const initialMount = useInitialMount()

  const [runbookChannelResponse, setRunbookChannelResponse] = useState(null)

  useEffect(() => {
    let ws: ActionCable.Channel | undefined
    if (runbookId && initialMount) {
      ws = websockets.subscribe(RUNBOOK_CHANNEL_NAME, runbookId, {
        received: (response: RunbookResponse | string) => {
          const data = typeof response === 'string' ? JSON.parse(response) : response
          setRunbookChannelResponse(data)
        }
      })
    }

    return () => {
      ws?.unsubscribe?.()
    }
  }, [])

  return <RunbookChannelContext.Provider value={runbookChannelResponse}>{children}</RunbookChannelContext.Provider>
}
