import { useEffect, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { eventManager } from 'event-manager'
import queryString from 'query-string'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import * as yup from 'yup'

import { Box, CheckboxesField, DayTimePicker, Message, Modal, RunbookTypeIcon, Text } from '@cutover/react-ui'
import { RunbookType } from 'main/components/settings/runbook-types/use-runbook-type'
import { CheckboxFieldControlled, DateTimePickerField, RadioboxGroupField } from 'main/components/shared/form'
import { DialogData, Runbook } from 'main/connectors/choose-start-time-runbook-connector'
import { useLanguage } from 'main/services/hooks'
import { CustomField } from 'main/services/queries/types/custom-fields'

type ChooseStartTimeModalProps = {
  customFields: CustomField[]
  dialogData: DialogData
  runbook: Runbook
  runbookType: RunbookType
}

type FormValues = {
  timing_mode: 'scheduled' | 'unscheduled'
  start_scheduled?: Date
  start_planned?: Date
  end_planned?: Date
  end_scheduled?: Date
  shift_time: boolean
}

export const ChooseStartTimeModal = ({ customFields, dialogData, runbook, runbookType }: ChooseStartTimeModalProps) => {
  const { t } = useLanguage('runbooks', { keyPrefix: 'duplicateRunbookModal' })
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [responseErrors, setResponseErrors] = useState<string[] | undefined>(undefined)

  const methods = useForm<FormValues>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(
      yup.object().shape({
        timing_mode: yup.string().oneOf(['scheduled', 'unscheduled']).required(),
        start_scheduled: yup.date().when(['timing_mode'], {
          is: (timing_mode: 'scheduled' | 'unscheduled') => timing_mode === 'scheduled',
          then: schema => schema.required(),
          otherwise: schema => schema.notRequired()
        }),
        start_planned: yup.date(),
        end_scheduled: yup.date().notRequired(),
        shift_time: yup.boolean()
      })
    ),
    defaultValues: {
      shift_time: true,
      timing_mode: 'unscheduled'
    }
  })

  const {
    handleSubmit,
    control,
    watch,
    clearErrors,
    formState: { errors },
    setValue
  } = methods

  const timingModeOption = watch('timing_mode')

  const closeModal = () => {
    eventManager.emit('close-start-time-runbook-modal')
  }

  const initialStartPlanned = (): Date => {
    const date = new Date()
    date.setHours(0, 0, 0, 0)
    return date
  }

  useEffect(() => {
    if (timingModeOption === 'unscheduled') {
      clearErrors('start_scheduled')

      setValue('start_planned', initialStartPlanned(), {
        shouldValidate: true,
        shouldTouch: true
      })
    }
  }, [timingModeOption])

  const onFormSubmit = async (data: FormValues) => {
    setIsLoading(true)
    runbook.timing_mode = data.timing_mode
    runbook.shift_time = data.shift_time
    runbook.is_template = false

    if (data.timing_mode == 'scheduled') {
      runbook.start_scheduled = data.start_scheduled
      runbook.end_scheduled = data.end_scheduled
      runbook.start_planned = null
    } else {
      runbook.start_planned = data.start_planned
      runbook.start_scheduled = null
      runbook.end_scheduled = null
    }

    // Save filters in local storage
    const searchString = window.location.href.split('?')[1]
    const searchObject = queryString.parse(searchString)
    localStorage.setItem('previousQuery', JSON.stringify(searchObject))

    let response

    // @ts-ignore
    response = await window.angularJS.duplicateRunbook(runbook, dialogData, customFields)

    if (response && response.hasOwnProperty('errors')) {
      setResponseErrors(response.errors)
    } else {
      closeModal()
    }
    setIsLoading(false)
  }

  const openPreviousCreateRunbookModal = () => {
    if (dialogData && dialogData.openStoredModal) {
      dialogData.openStoredModal(runbook, customFields, true)
    }
    closeModal()
  }

  return (
    <Modal
      title={t('secondStepTitle')}
      open={true}
      onClose={closeModal}
      titlePrefix={
        runbookType && (
          <RunbookTypeIcon
            icon={runbookType.iconName || 'getting-started'}
            color={runbookType.iconColor || 'primary'}
          />
        )
      }
      loadingText={t('common:savingText')}
      confirmText={t('common:createButton')}
      confirmIcon="add"
      onClickConfirm={handleSubmit(onFormSubmit)}
      onClickBack={openPreviousCreateRunbookModal}
      loading={isLoading}
    >
      <FormProvider {...methods}>
        {(Object.keys(errors).length > 0 || responseErrors) && (
          <Box margin={{ bottom: '16px' }}>
            <Message type="error" message={responseErrors ?? t('common:formInvalid')} />
          </Box>
        )}
        <Box direction="column">
          <RadioboxGroupField<FormValues>
            name="timing_mode"
            label={t('fields.timingMode.label')}
            direction="row"
            options={[
              { label: t('fields.timingMode.unscheduled'), value: 'unscheduled' },
              { label: t('fields.timingMode.scheduled'), value: 'scheduled' }
            ]}
          />
          {timingModeOption === 'scheduled' && (
            <DateTimePickerField<FormValues>
              name="start_scheduled"
              label={t('fields.startScheduled.label')}
              required
              fixed
              fixedWidth
            />
          )}
          <Text
            size="small"
            css="text-decoration-line: underline; cursor: pointer; margin-bottom: 10px"
            role="button"
            onClick={() => setShowAdvancedOptions(!showAdvancedOptions)}
            tabIndex={0}
            onKeyDown={e => {
              if (e.code === 'Enter') {
                setShowAdvancedOptions(!showAdvancedOptions)
              }
            }}
          >
            {showAdvancedOptions ? t('advancedOptions', { action: 'Hide' }) : t('advancedOptions', { action: 'Show' })}
          </Text>
          {showAdvancedOptions && (
            <>
              {timingModeOption === 'scheduled' ? (
                <DateTimePickerField<FormValues>
                  name="end_scheduled"
                  label={t('fields.endScheduled.label')}
                  fixed
                  fixedWidth
                />
              ) : (
                <Controller
                  name={'start_planned'}
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <DayTimePicker
                        dayZeroStartValue={value ? new Date(value) : new Date()}
                        onChange={date => (!!date ? onChange(date.toISOString()) : onChange(null))}
                        disableDayPickerOnly
                        value={!!value ? new Date(value) : new Date()}
                      />
                    )
                  }}
                />
              )}
              <CheckboxesField label={t('fields.shiftTime.label')}>
                <CheckboxFieldControlled<FormValues> name="shift_time" label={t('fields.shiftTime.helpText')} />
              </CheckboxesField>
            </>
          )}
        </Box>
      </FormProvider>
    </Modal>
  )
}
