// @ts-nocheck
import { useState, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import styled, { createGlobalStyle } from 'styled-components'
import ReactModal from 'react-modal'
import { px2rem } from 'app/Styles/Variables'
import { Button } from 'Components/Molecules/Button'
import { ModalPresenter } from './ModalPresenter'

const Wrapper = styled.div``
const Content = styled.div``
const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${px2rem(24)};
`

interface ICloseButtonWrapper {
  closeButtonHasDeepPosition?: boolean
}
const CloseButtonWrapper = styled.div<ICloseButtonWrapper>`
  position: absolute;
  right: ${({ closeButtonHasDeepPosition }) => (closeButtonHasDeepPosition ? px2rem(24) : px2rem(12))};
  top: ${({ closeButtonHasDeepPosition }) => (closeButtonHasDeepPosition ? px2rem(30) : px2rem(16))};
`
const Header = styled.div`
  width: 100%;
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

interface IContentProps {
  maxContainerHeight: number
}

const ContentWrapper = styled.div`
  flex: 1 1 auto;
  position: relative;
  overflow-y: auto;
  width: 100%;
  padding-right: ${px2rem(16)};

  max-height: ${({ maxContainerHeight }: IContentProps) => (maxContainerHeight ? px2rem(maxContainerHeight) : '50vh')};
  @media only screen and (max-height: 548px) {
    max-height: 50vh;
  }
`
const Footer = styled.div`
  width: 100%;
  flex-shrink: 0;
  bottom: 0;
`
const ClassBasedStyles = createGlobalStyle`
  .ReactModal__Overlay {

  }

  .ReactModal__Content {
    z-index: 6;
    width: ${px2rem(540)};
    max-height: 95%;
    max-width: 95%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 ${px2rem(6)} ${px2rem(24)} 0 rgba(151,164,170,.6);
  }

  .ReactModal__Content--after-open {
  }

  .ReactModal__Overlay--after-open{
    z-index: 6;
  }

  .ReactModal__Overlay--before-close{
  }
`
interface IGradientDivProps {
  showGradient: boolean
  stickyFooter: boolean
}

const GradientDiv = styled.div`
  position: absolute;
  pointer-events: none;
  height: ${px2rem(48)};
  bottom: ${({ stickyFooter }: IGradientDivProps) => (stickyFooter ? px2rem(74) : px2rem(16))};
  ${({ showGradient }: IGradientDivProps) =>
    showGradient
      ? `
 width: ${px2rem(508)};
 background-image: linear-gradient(rgba(255,255,255,0), rgba(255,255,255,1));
 `
      : 'opacity: 100%'}
`

interface IModalProps {
  presenter: ModalPresenter
  rootElement?: string
  fixedHeader?: boolean
  stickyFooter?: boolean
  displayMoreGradient?: boolean
  showClose?: boolean
  shouldReturnFocusAfterClose?: boolean
  topOffset?: number
  customWidth?: number
  closeButtonHasDeepPosition?: boolean
  customContentStyles?: { [key: string]: string }
}

export const Modal = observer<IModalProps>(props => {
  const {
    presenter,
    children,
    rootElement,
    stickyFooter,
    displayMoreGradient,
    showClose = true,
    shouldReturnFocusAfterClose = true,
    topOffset,
    customWidth,
    closeButtonHasDeepPosition,
    customContentStyles = {}
  } = props
  const { isOpen, onClose, fixedHeader, maxContainerHeight, closeButtonPresenter } = presenter
  const [showGradient, setShowGradient] = useState(false)
  const ref = useRef(null)
  const appElement = rootElement ? rootElement : 'cutover-react'

  ReactModal.setAppElement(document.getElementById(appElement))

  const modalContent = (
    <>
      <ClassBasedStyles />
      <Wrapper>
        <Content>{children}</Content>
      </Wrapper>
    </>
  )

  const toggleShowGradient = () => {
    const currentRef = ref.current

    if (currentRef) {
      if (currentRef.scrollHeight - currentRef.scrollTop >= currentRef.clientHeight + 21) {
        setShowGradient(true)
      } else {
        setShowGradient(false)
      }
    }
  }

  const onFocus = _e => {
    toggleShowGradient()
  }

  const onScroll = _e => {
    toggleShowGradient()
  }

  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.08)'
    },
    content: {
      top: topOffset ? px2rem(topOffset) : '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      padding: '0px',
      borderRadius: px2rem(16),
      border: '0',
      overflow: 'visible',
      width: customWidth && px2rem(customWidth),
      ...customContentStyles
    }
  }

  const fixedHeaderModalContent = (
    <>
      <ClassBasedStyles />
      <ModalContent>
        <Header>{children && children[0]}</Header>
        <ContentWrapper maxContainerHeight={maxContainerHeight} onFocus={onFocus} onScroll={onScroll} ref={ref}>
          <Content>{children && children[1]}</Content>
          {!stickyFooter && <Footer>{children && children[2]}</Footer>}
        </ContentWrapper>
        {stickyFooter && <Footer>{children && children[2]}</Footer>}
        {displayMoreGradient && <GradientDiv showGradient={showGradient} stickyFooter={stickyFooter} />}
      </ModalContent>
    </>
  )

  return (
    <div
      onKeyUp={event => {
        if (event.key === 'Escape') onClose()
      }}
      role="presentation"
    >
      <ReactModal
        isOpen={isOpen}
        style={customStyles}
        shouldReturnFocusAfterClose={shouldReturnFocusAfterClose}
        aria={{
          labelledby: presenter.ariaLabelLedBy
        }}
      >
        {showClose ? (
          <CloseButtonWrapper closeButtonHasDeepPosition={closeButtonHasDeepPosition}>
            <Button presenter={closeButtonPresenter} />
          </CloseButtonWrapper>
        ) : null}

        {fixedHeader ? fixedHeaderModalContent : modalContent}
      </ReactModal>
    </div>
  )
})
