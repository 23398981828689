import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Send = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Send" {...props}>
    <path d="M42.814 25.752c1.381-.76 1.381-2.745 0-3.504L10.662 4.564c-1.618-.89-3.475.7-2.844 2.436l5.934 16.317a2 2 0 0 1 0 1.366L7.817 41c-.63 1.736 1.226 3.326 2.844 2.436l32.152-17.684Zm-26.243-3.46L11.547 8.473l25.5 14.026H16.643a4.925 4.925 0 0 0-.071-.209Zm.07 3.208h20.407L11.547 39.526l5.024-13.817a4.27 4.27 0 0 0 .07-.209Z" fill="#000" />
  </StyledIcon>
))

Send.displayName = 'Send'

export { Send }
