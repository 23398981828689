import { CustomFieldGroup } from 'main/services/queries/types'
import {
  useGetCustomFieldGroup,
  useGetCustomFieldGroupCallback,
  useGetCustomFieldGroupLookup,
  useGetCustomFieldGroupLookupCallback,
  useGetCustomFieldGroups,
  useGetCustomFieldGroupsCallback
} from '../hooks'
import { CollectionModelInterfaceBase } from './types'

export type CustomFieldGroupModelType = CollectionModelInterfaceBase<CustomFieldGroup>

export const CustomFieldGroupModel: CustomFieldGroupModelType = {
  useGet: useGetCustomFieldGroup,
  useGetCallback: useGetCustomFieldGroupCallback,

  useGetAll: useGetCustomFieldGroups,
  useGetAllCallback: useGetCustomFieldGroupsCallback,

  useGetLookup: useGetCustomFieldGroupLookup,
  useGetLookupCallback: useGetCustomFieldGroupLookupCallback
}
