// @ts-nocheck
import { AlertPresenter } from './AlertPresenter'
import { observable, action, computed } from 'mobx'

// Not sure if this is the best place for this?
export interface IMessage {
  key: number
  message: string
}

export class AlertMultiPresenter extends AlertPresenter {
  @observable
  public messages: IMessage[] = null

  @observable
  public customSummary: string

  public stateMessage = {
    success: {
      type: 'action',
      description: 'completed'
    },
    error: {
      type: 'warning',
      description: 'found'
    }
  }

  @action
  public withCustomSummary(customSummary: string) {
    this.customSummary = customSummary
    return this
  }

  @action
  public withMessages(messages: IMessage[]) {
    this.messages = messages
    return this
  }

  @computed
  public get messageSummary() {
    if (this.customSummary) {
      return this.customSummary
    }

    let summary = ''
    if (this.messages) {
      const messageType =
        this.messages.length === 1 ? this.stateMessage[this.state].type : this.stateMessage[this.state].type + 's'
      summary = `${this.messages.length.toString()} ${messageType} ${this.stateMessage[this.state].description}`
    }
    return summary
  }
}
