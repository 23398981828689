// @ts-nocheck
import { container } from 'app/Config/IOC'
import { injectable } from 'inversify'
import { computed, observable, action } from 'mobx'
import { CustomFieldsViewPresenter } from 'app/Settings/CustomFields/CustomFieldsPage/CustomFieldsViewPresenter'
import { TablePresenter, ITableColumnTypes, ISortParams } from 'Components/Organisms'
import { ICustomFieldTypeForTable } from 'Shared/Entities/Types/CustomField/ICustomFieldTypeForTable'
import { GenericListPresenter } from 'Components/Templates'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'
import { Types } from 'Gateways/Service/Types'

@injectable()
export class CustomFieldListPresenter implements GenericListPresenter {
  @observable
  public viewPresenter: CustomFieldsViewPresenter = container.get(CustomFieldsViewPresenter)

  public language: ILanguageService = container.get(Types.ILanguageService)

  @observable
  public columns: ITableColumnTypes[] = [
    {
      accessor: 'name',
      name: this.language.get('customFields:list:tableHeadings:name'),
      width: 180,
      dataType: 'string',
      sortable: true
    },
    {
      accessor: 'accountName',
      name: this.language.get('customFields:list:tableHeadings:accountName'),
      width: 180,
      dataType: 'string',
      sortable: true
    },
    {
      accessor: 'type',
      name: this.language.get('customFields:list:tableHeadings:type'),
      width: 180,
      dataType: 'string',
      sortable: true
    },
    {
      accessor: 'displaysOn',
      name: this.language.get('customFields:list:tableHeadings:displaysOn'),
      width: 180,
      dataType: 'string',
      sortable: true
    },
    {
      accessor: 'default',
      name: this.language.get('customFields:list:tableHeadings:default'),
      width: 180,
      dataType: 'boolean',
      sortable: true
    }
  ]

  public tableName: string = 'custom-field-list'

  public context: string = 'custom field'

  @computed
  public get sortParams(): ISortParams {
    return this.viewPresenter.sortParams
  }

  @computed
  public get selectedId(): number {
    if (!this.viewPresenter.editRecordVM) return null
    return this.viewPresenter.editRecordVM.id
  }

  public loadMore = (startIndex, stopIndex) => {
    this.viewPresenter.loadMoreCustomFields(startIndex)
  }

  public loadSort = (sortParams: ISortParams) => {
    this.viewPresenter.loadSort(sortParams)
  }

  @action
  public loadRowCallback = async (rowId: number) => {
    if (this.viewPresenter.checkEditPanelIsDirty()) return

    if (this.viewPresenter.editRecordVM && this.viewPresenter.editRecordVM.id === rowId) {
      this.viewPresenter.pageVM.editPanelOpen = false
      return
    }

    await this.viewPresenter.loadRecord(rowId)
  }

  public clearAllFilters = () => {
    this.viewPresenter.clearFilters()
  }

  public get loading(): boolean {
    return this.viewPresenter.customFieldsLoading
  }

  @computed
  public get viewModel(): ICustomFieldTypeForTable[] {
    return this.viewPresenter.listVM
  }

  @computed
  public get totalCount() {
    return this.viewPresenter.filteredListTotal
  }

  @computed
  public get reloadList() {
    return this.viewPresenter.reloadList
  }

  public tablePresenter = new TablePresenter(this.viewModel, this.columns, this.tableName, this.totalCount)
    .withLoadMoreCallback(this.loadMore)
    .withLoadSortCallback(this.loadSort)
    .withLoadRowCallback(this.loadRowCallback)
    .withVariableWidth()

  @computed
  public get filtersCount(): number {
    return this.viewPresenter.filtersCount
  }
}
