import { AverageRunbookDurationConnected } from 'main/components/runbook/pages/dashboard/average-runbook-duration'
import { AverageRunbookDurationOverTimeConnected } from 'main/components/runbook/pages/dashboard/average-runbook-duration-over-time'
import { DashboardComponentFilterByConnected } from 'main/components/runbook/pages/dashboard/dashboard-component-filter-by'
import { LongestAverageTaskDurationSingleConnected } from 'main/components/runbook/pages/dashboard/longest-average-task-duration-single'
import { LongestAverageTaskDurationsConnected } from 'main/components/runbook/pages/dashboard/longest-average-task-durations'
import { LongestDurationRunbooksConnected } from 'main/components/runbook/pages/dashboard/longest-duration-runbooks'
import { ParticularTaskAverageDurationConnected } from 'main/components/runbook/pages/dashboard/particular-task-average-duration'
import { ParticularTaskAverageDurationNameFilterConnected } from 'main/components/runbook/pages/dashboard/particular-task-average-duration-name-filter'
import { RunbookCountByTypeOverTimeConnected } from 'main/components/runbook/pages/dashboard/runbook-count-by-type-over-time'

export const components = new Map<string, (props: any) => React.ReactElement>()

// The following registrations allow React components to be rendered within AngularJS via the react-component directive
// Favour rewriting entire pages in React, or using the existing react migration service mechanism instead
// if you only require a single React component within an AngularJS page.

// Add the additional React component registration here, id (unique) followed by React component to render
// The component will be passed props specified on the AngularJS react-component directive,
// and an onEvent prop for AngularJS to handle events within the React component, for example:

// <react-component id="foo" component-id="average-runbook-duration-over-time" props="..." on-event="..."></react-component>

// Note that the id above must be a unique id per react-component instance on the page, the component-id is the id of the registered React component.

components.set('average-runbook-duration-over-time', AverageRunbookDurationOverTimeConnected)
components.set('average-runbook-duration', AverageRunbookDurationConnected)
components.set('runbook-count-by-type-over-time', RunbookCountByTypeOverTimeConnected)
components.set('longest-duration-runbooks', LongestDurationRunbooksConnected)
components.set('longest-average-task-durations', LongestAverageTaskDurationsConnected)
components.set('longest-average-task-duration-single', LongestAverageTaskDurationSingleConnected)
components.set('dashboard-component-filter-by', DashboardComponentFilterByConnected)
components.set('particular-task-average-duration', ParticularTaskAverageDurationConnected)
components.set('particular-task-average-duration-name-filter', ParticularTaskAverageDurationNameFilterConnected)
