import { selectorFamily } from 'recoil'

import { appComponentProps } from '../apps-atoms'

export const componentPropsWithContextAndId = selectorFamily({
  key: 'ComponentProps',
  get:
    ({ context, id }: { context: string; id: string }) =>
    ({ get }) => {
      if (id) {
        return get(appComponentProps)[context]?.[id]
      }
    }
})
