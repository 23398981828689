// @ts-nocheck
import { observable, computed } from 'mobx'
import { getDifference } from 'Shared/Helpers/DateService'
import { injectable } from 'inversify'
import { DateTimePresenter } from 'Components/Molecules'
import { RolePresenter } from './RolePresenter'
import { colors } from 'app/Styles/Variables'
import { IRole } from 'Shared/Entities/Types/RoleType/IRole'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'

@injectable()
export class RoleWithExpiryPresenter extends RolePresenter {
  @observable
  public dateTimePresenter: DateTimePresenter

  @observable
  public hasExpired: boolean

  @observable
  public showDatePicker = false

  @observable
  public helpText: string

  constructor(language: ILanguageService, checkboxLabel, helpText: string = '', narrow: boolean = false) {
    super(checkboxLabel, helpText)
    this.checkboxPresenter
      .withEventIconText(language.get('components:molecules:roleTypes:checkboxLabel'))
      .withOneLineTooltip()
      .withEventIcon('history')
      .withEventIconColor(colors.bgLight)
      .withMiddleware(newValue => {
        newValue ? this.checkboxPresenter.showEventIcon() : this.checkboxPresenter.hideEventIcon()
        return newValue
      })
      .withEventIconEvent(() => {
        this.setShowDatePickers(!this.showDatePicker)
      })

    this.dateTimePresenter = new DateTimePresenter(null)
      .withMiddleware(newValue => {
        this.setHasExpired(newValue)

        this.checkboxPresenter.withEventIconColor(this.getColourForExpiryStatus())
        this.checkboxPresenter.showEventIcon()
        return newValue
      })
      .withClear()
      .withOnClear(() => {
        this.checkboxPresenter.withEventIconColor(colors.primaryGrey)
      })
      .withLabel(language.get('components:molecules:roleTypes:dateTimeLabel'))
      .withNarrow(narrow)
  }

  public withDateTimePresenter = (dateTimePresenter: DateTimePresenter) => {
    this.dateTimePresenter = dateTimePresenter
    return this
  }

  public setShowDatePickers = (showDatePicker: boolean) => {
    this.showDatePicker = showDatePicker
    return this
  }

  public getColourForExpiryStatus = () => {
    if (this.dateTimePresenter.controlState !== 'populated') {
      return colors.primaryGrey
    }

    return this.hasExpired ? colors.warningOrange : colors.success
  }

  private setHasExpired(expiryDate) {
    const diff = getDifference(new Date(), expiryDate, 'milliseconds')
    this.hasExpired = diff >= 0 ? true : false

    return
  }

  public reset = () => {
    this.showDatePicker = false
    this.checkboxPresenter.reset()
    this.checkboxPresenter.hideEventIcon()
    this.checkboxPresenter.withEventIconColor(colors.bgLight)
    this.dateTimePresenter.reset()
  }

  @computed
  public get isDirty(): boolean {
    return this.checkboxPresenter.isDirty || this.dateTimePresenter.isDirty
  }

  public setDisabled = (disabled: boolean) => {
    this.checkboxPresenter.disabled = disabled

    if (disabled) {
      this.dateTimePresenter.isDisabled()
    } else {
      this.dateTimePresenter.isEnabled()
    }
  }

  public prePopulate = (role: IRole) => {
    this.checkboxPresenter.value = true

    if (role.expiresAt) {
      if (role.expiresAt < new Date()) this.hasExpired = true
      this.dateTimePresenter.initialiseComponent(new Date(role.expiresAt))
      this.checkboxPresenter.showEventIcon()
      this.checkboxPresenter.withEventIconColor(this.getColourForExpiryStatus())
    }
  }

  @computed
  public get showDatePickerIcon(): boolean {
    return this.checkboxPresenter.value && this.dateTimePresenter.controlState === 'populated'
  }
}
