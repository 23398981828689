import { LoadingPanel } from '@cutover/react-ui'
import { DataSourcesEdit } from './data-source-edit'
import { useRightPanelTypeState } from 'main/components/layout/right-panel'
import { useDataSource } from 'main/services/queries/use-data-sources'

export const DataSourcesEditPanel = () => {
  const [{ dataSourceId }, { closeRightPanel }] = useRightPanelTypeState('data-sources-edit')
  const { data } = useDataSource(dataSourceId ?? null)

  return dataSourceId && data?.data_source ? (
    <DataSourcesEdit dataSource={data.data_source} onClose={closeRightPanel} key={dataSourceId} />
  ) : (
    <LoadingPanel />
  )
}
