import { useCallback, useEffect, useState } from 'react'

export const useRovingTabIndex = ({ selector = 'button' }: { selector?: string } = {}) => {
  const [toolbar, setToolbar] = useState<HTMLElement | null>(null)

  const getButtons = useCallback(() => {
    const buttons = Array.from(
      toolbar?.querySelectorAll(`${selector}:not([data-roving-tabindex-ignore="true"])`) ?? []
    ) as HTMLElement[]
    const visibleButtons = buttons.filter(button => {
      const computedStyle = getComputedStyle(button)
      return computedStyle.display !== 'none'
    })
    return visibleButtons
  }, [toolbar])

  const activate = useCallback(
    (item: HTMLElement) => {
      const buttons = getButtons()
      buttons?.forEach(btn => (btn.tabIndex = -1))

      item.tabIndex = 0
      item.focus()
    },
    [getButtons]
  )

  const handleClick = useCallback(
    (event: MouseEvent) => {
      if (!toolbar) return
      if (!event.target) return
      const buttons = getButtons()
      if (buttons.indexOf(event.target as any) === -1) {
        return
      }

      activate(event.target as HTMLElement)
    },
    [activate, getButtons, toolbar]
  )

  const focusNextItem = useCallback(() => {
    const item = document.activeElement
    const buttons = getButtons()
    const index = buttons.indexOf(item as any)
    if (index === buttons.length - 1) return

    activate(buttons[index + 1])
  }, [activate, getButtons])

  const focusPreviousItem = useCallback(() => {
    const item = document.activeElement
    const buttons = getButtons()
    const index = buttons.indexOf(item as any)
    if (index === 0) return

    activate(buttons[index - 1])
  }, [activate, getButtons])

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      switch (event.key) {
        case 'ArrowRight':
          event.preventDefault()
          focusNextItem()
          break
        case 'ArrowLeft':
          event.preventDefault()
          focusPreviousItem()
          break
      }
    },
    [focusNextItem, focusPreviousItem]
  )

  useEffect(() => {
    if (toolbar) {
      const buttons = getButtons()
      toolbar?.removeEventListener('keydown', handleKeyDown)
      toolbar?.removeEventListener('click', handleClick)

      toolbar.addEventListener('keydown', handleKeyDown)
      toolbar.addEventListener('click', handleClick)

      buttons.forEach(btn => (btn.tabIndex = -1))
      buttons[0].tabIndex = 0
    }

    return () => {
      toolbar?.removeEventListener('keydown', handleKeyDown)
      toolbar?.removeEventListener('click', handleClick)
    }
  }, [getButtons, handleClick, handleKeyDown, toolbar])

  return { setToolbarElement: setToolbar }
}
