import { Dispatch, SetStateAction } from 'react'

import { Box, Modal, SanitizedString } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'

type ArchiveDataSourceModalType = {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  dataSourceName: string
}

export const UnableToArchiveDatasourceModal = ({ open, setOpen, dataSourceName }: ArchiveDataSourceModalType) => {
  const { t } = useLanguage('dataSources')

  const closeUnableToArchiveDataSourceModal = () => {
    setOpen(false)
  }

  return (
    <Modal
      title={t('dataSources.modals.unableToArchiveDataSource.title')}
      open={open}
      onClose={closeUnableToArchiveDataSourceModal}
      onClickConfirm={closeUnableToArchiveDataSourceModal}
      confirmText="Close"
      confirmIcon="close"
      hasCancelButton={false}
    >
      <Box>
        <SanitizedString input={t('dataSources.modals.unableToArchiveDataSource.body', { name: dataSourceName })} />
      </Box>
    </Modal>
  )
}
