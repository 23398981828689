// @ts-nocheck
import { container } from 'app/Config/IOC'
import { injectable } from 'inversify'
import { GenericPageHeadingPresenter } from 'Shared/Presenters/GenericPageHeadingPresenter'
import { CustomFieldGroupViewPresenter } from '../CustomFieldGroupViewPresenter'

@injectable()
export class CustomFieldGroupHeadingPresenter extends GenericPageHeadingPresenter {
  protected viewPresenter: CustomFieldGroupViewPresenter = container.get(CustomFieldGroupViewPresenter)
}
