// @ts-nocheck
import { useState, useCallback, useRef, useEffect } from 'react'
import { transition } from 'app/Styles/Variables'

type AccordionState = 'open' | 'closed'
type ChevronRotation = 0 | 90

const collapseTransition = transition(undefined, 'height')

/** @deprecated do not use this component from the app folder, refactor/use the component from react-ui instead **/
export const useCollapseContent = (contentRef, collapsed = false) => {
  const animationFrameDisposal = useRef<number | null>(null)

  const [accordionState, setAccordionState] = useState<AccordionState>(collapsed ? 'closed' : 'open')
  const [chevronRotation, setChevronRotation] = useState<ChevronRotation>(collapsed ? 0 : 90)

  const handleClose = useCallback(() => {
    if (!contentRef.current) return
    const { scrollHeight } = contentRef.current
    let animationFrame
    contentRef.current.style.transition = ''
    animationFrame = requestAnimationFrame(() => {
      if (!contentRef.current) return
      setChevronRotation(0)
      contentRef.current.style.height = scrollHeight + 'px'
      contentRef.current.style.transition = collapseTransition.toString()
      animationFrame = requestAnimationFrame(() => {
        if (!contentRef.current) return
        contentRef.current.style.height = '0'
        setAccordionState('closed')
      })
    })
    animationFrameDisposal.current = animationFrame
  }, [])

  const handleOpen = useCallback(() => {
    if (!contentRef.current) return
    const { scrollHeight } = contentRef.current
    contentRef.current.style.height = scrollHeight + 'px'
    setChevronRotation(90)
    const handler = () => {
      if (!contentRef.current) return
      contentRef.current.removeEventListener('transitionend', handler)
      contentRef.current.style.height = 'initial'
      setAccordionState('open')
    }
    contentRef.current.addEventListener('transitionend', handler)
  }, [])

  const handleClick = useCallback(() => {
    switch (accordionState) {
      case 'open':
        handleClose()
        break
      case 'closed':
        handleOpen()
        break
    }
  }, [accordionState, handleOpen, handleClose])

  useEffect(() => {
    if (collapsed) {
      handleClose()
    }
    return () => {
      if (animationFrameDisposal.current) {
        cancelAnimationFrame(animationFrameDisposal.current)
      }
    }
  }, [collapsed, handleClose])

  return { handleClick, accordionState, chevronRotation }
}
