// @ts-nocheck

import styled from 'styled-components'
import { DisplaySvgIcon, TDisplaySvgIcons, TDisplaySvgIconTypes } from 'Shared/Components/Atoms/DisplaySvgIcon'

export interface IconButtonProps extends Record<string, unknown> {
  id: TDisplaySvgIcons
  color: string
  selected: boolean
  onClick: MouseEventHandler<HTMLButtonElement>
  type?: TDisplaySvgIconTypes
}

interface IconWrapperProps {
  color: string
  selected: boolean
}

const IconWrapper = styled.button<IconWrapperProps>(({ color, selected }) => ({
  cursor: 'pointer',
  margin: '0.2rem',
  background: 'none',
  color: 'inherit',
  border: 'none',
  padding: 0,
  font: 'inherit',
  outline: 'inherit',
  div: {
    borderColor: selected ? color : 'transparent'
  },
  ':hover,:active,:focus': { div: { transition: 'border-color ease 0.1s', borderColor: color } }
}))

export function IconButton({ id, color, selected, onClick, type = 'invert' }: IconButtonProps) {
  return (
    <IconWrapper onClick={onClick} color={color} selected={selected}>
      <DisplaySvgIcon name={id} type={type} color={color} />
    </IconWrapper>
  )
}
