import { PolymorphicComponent } from 'react-polymorphic-box'
import styled from 'styled-components/macro'

export type FlexItemProps = {
  order?: number // Default 0
  flexGrow?: number // Default 0
  flexShrink?: number // Default 1
  flexBasis?: string | 'auto' // Default 'auto', possible values in %, px, rem or other size units
  flex?: string // Shorthand property for flex-grow, flex-shrink, and flex-basis. Preferred use.
  alignSelf?: 'auto' | 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch' // Default 'auto'
}

const defaultElement = 'div'

/**
 * @deprecated Use grommet box exported as Box (https://v2.grommet.io/box)
 */
export const LegacyFlexItem: PolymorphicComponent<
  FlexItemProps, // Merged with props from the underlying element type
  typeof defaultElement
> = styled(defaultElement)<FlexItemProps>`
  ${({ order }) => order && `order: ${order};`}
  ${({ flexGrow }) => flexGrow && `flex-grow: ${flexGrow};`}
  ${({ flexShrink }) => flexShrink && `flex-shrink: ${flexShrink};`}
  ${({ flexBasis }) => flexBasis && `flex-basis: ${flexBasis};`}
  ${({ flex }) => flex && `flex: ${flex};`}
  ${({ alignSelf }) => alignSelf && `align-self: ${alignSelf};`}
`
