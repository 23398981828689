// @ts-nocheck
import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { EditPanelFormWrapper } from 'Components/Templates'
import { AccountEditPresenter } from 'app/Settings/Accounts/AccountsPage/AccountEdit/AccountEditPresenter'
import { AccountFormPresenter } from 'app/Settings/Accounts/AccountsPage/AccountForm/AccountFormPresenter'
import { AccountFormComponent } from 'app/Settings/Accounts/AccountsPage/AccountForm/AccountFormComponent'

interface IAccountEditComponentProps {
  presenter: AccountEditPresenter
  editFormPresenter: AccountFormPresenter
}

export const AccountEditSection = observer<IAccountEditComponentProps>(({ presenter, editFormPresenter }) => {
  const {
    title,
    mode,
    canArchive,
    archiveTooltip,
    closeCallback,
    save,
    reset,
    archiveButtonCallback,
    formIsDirty,
    setEditPanelDirty,
    editPanelSaving,
    confirmModalPresenter,
    triggerScroll,
    setTriggerScroll
  } = presenter

  presenter.withEditFormPresenter(editFormPresenter)

  useEffect(() => {
    if (formIsDirty === true) {
      setEditPanelDirty(true)
      return
    }
    setEditPanelDirty(false)
  }, [formIsDirty])

  return (
    <EditPanelFormWrapper
      heading={title}
      mode={mode}
      showArchive={canArchive}
      archiveTooltip={archiveTooltip}
      singleLineArchiveTooltip
      closeCallback={closeCallback}
      saveCallback={save}
      resetCallback={reset}
      archiveCallback={archiveButtonCallback}
      editPanelSaving={editPanelSaving}
      confirmModalPresenter={confirmModalPresenter}
      triggerScroll={triggerScroll}
      setTriggerScroll={setTriggerScroll}
    >
      <AccountFormComponent presenter={editFormPresenter} />
    </EditPanelFormWrapper>
  )
})
