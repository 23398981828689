// @ts-nocheck
import { injectable, postConstruct } from 'inversify'
import { container } from 'app/Config/IOC'
import { observable, Lambda, action, computed, observe } from 'mobx'
import { IPageVM } from 'Shared/Entities/IPageVM'
import { ISortParams } from 'Shared/Components/Organisms'
import { Router } from 'app/Routing/Router'
import { RoutingState } from 'app/Routing/RoutingState'
import { GenericRecordRepository } from 'app/Repositories/GenericRecordRepository'
import { toggleAnimateCutoverLogo } from 'Shared/Helpers/NavLogoAnimation'
import { IBaseResponse } from 'Gateways/Service/IBaseResponse'
import { GenericListRepository } from 'app/Repositories/GenericListRepository'
import { FiltersRepository } from 'app/Filters/FiltersRepository'

export enum ViewModelType {
  Add,
  Edit
}

@injectable()
export abstract class GenericAdminViewPresenter {
  protected router: Router = container.get(Router)
  protected routingState: RoutingState = container.get(RoutingState)
  public filtersRepository: FiltersRepository = container.get(FiltersRepository)

  @observable
  public loading = false

  @computed
  public get loadingRecord(): boolean {
    return this.recordRepository.loading
  }

  @observable
  public pageVM: IPageVM = {
    editPanelLoading: false,
    editPanelOpen: false,
    editPanelDirty: false,
    filterPanelOpen: true,
    filterPanelLoading: true,
    editPanelClearing: false,
    editLoading: false,
    archiveModalOpenState: false
  }

  @observable
  public submitted: boolean = false

  @observable
  public observers: Lambda[] = []

  @observable
  public dependenciesLoaded: boolean = false

  // Properties
  protected abstract routeId: string
  protected abstract recordRepository: GenericRecordRepository
  public abstract listRepository: GenericListRepository

  @observable
  public abstract addRecordVM

  @observable
  public abstract editRecordVM
  public abstract sortParams: ISortParams

  // Methods
  protected abstract getDefaultVM: () => any
  protected abstract getProgrammersModelForRecord(recordVM)
  public abstract inflateEditRecordVM: () => void
  protected abstract setObservers: () => void
  protected abstract loadDependencies: () => Promise<void>
  public abstract loadList: (params?) => {}
  public abstract recordForEditReadOnly: () => boolean

  protected customClear: () => void = () => {
    // to avoid child class having to implement
  }

  @computed
  public get filtersCount(): number {
    return this.filtersRepository.selectedFilters.length
  }

  @postConstruct()
  public init() {
    this.load()
    this.router.registerRouteLeaveCallback({ routeId: this.routeId, callback: this.clear })
    this.router.registerRouteEnterCallback({ routeId: this.routeId, callback: this.load })
  }

  @action
  public load = async () => {
    this.loading = true
    toggleAnimateCutoverLogo(true)
    this.setObservers()
    this.router.setCheckBeforeAction(() => {
      return this.pageVM.editPanelDirty
    })
    await this.loadDependencies()
    toggleAnimateCutoverLogo(false)
    this.loading = false
    this.dependenciesLoaded = true
  }

  @action
  private clear = () => {
    // Allow child class to have a custom clear function
    if (this.customClear) {
      this.customClear()
    }

    this.pageVM.editPanelOpen = false
    this.listRepository.clear()
    this.filtersRepository.clearPathFilters()
    this.clearObservers()
    setTimeout(() => {
      this.clearEditPanel()
      this.editRecordVM = null
    }, 500)
  }

  @action
  private clearObservers = () => {
    this.observers.forEach(disposer => disposer())
    this.observers = []
  }

  @action
  public setPageVMValue = (key: string, value: boolean) => {
    this.pageVM[key] = value
  }

  @action
  public resetViewModel = (viewModelType: ViewModelType) => {
    viewModelType === ViewModelType.Add
      ? (this.addRecordVM = this.getDefaultVM())
      : (this.editRecordVM = this.getDefaultVM())
  }

  public checkEditPanelIsDirty = (): boolean => {
    if (this.pageVM.editPanelDirty) {
      return true
    }
    return false
  }

  @action
  public loadRecord = async (recordId: number) => {
    if (this.checkEditPanelIsDirty()) return
    await this.recordRepository.loadData({ id: recordId })
    this.pageVM.editPanelOpen = true
  }

  public saveRecord = async (): Promise<IBaseResponse> => {
    toggleAnimateCutoverLogo(true)
    const response = await this.recordRepository.saveRecord(this.getProgrammersModelForRecord(this.editRecordVM))
    toggleAnimateCutoverLogo(false)
    return response
  }

  public createRecord = async (): Promise<IBaseResponse> => {
    toggleAnimateCutoverLogo(true)
    const response = await this.recordRepository.createRecord(this.getProgrammersModelForRecord(this.addRecordVM))
    toggleAnimateCutoverLogo(false)
    return response
  }

  public deleteRecord = async (): Promise<IBaseResponse> => {
    toggleAnimateCutoverLogo(true)
    const response = await this.recordRepository.deleteRecord(this.editRecordVM.id)
    toggleAnimateCutoverLogo(false)
    return response
  }

  @computed
  public get listTotal(): number {
    return this.listRepository.listTotal
  }

  @computed
  public get filteredListTotal(): number {
    return this.listRepository.filteredListTotal
  }

  public clearEditPanel = () => {
    this.recordRepository && this.recordRepository.clearRecordForEdit()
  }

  public clearFilters = () => {
    this.filtersRepository.clearPathFilters()
    this.router.refreshFilteredRoute()
  }

  @action
  public setSubmitted = (submitted: boolean) => {
    this.submitted = submitted
  }

  @computed
  public get canCreate(): boolean {
    return this.can('create')
  }

  @computed
  public get canUpdate(): boolean {
    if (!this.editRecordVM) {
      return false
    }

    return this.can('update')
  }

  @computed
  public get canArchive(): boolean {
    if (this.editRecordVM) {
      return this.can('destroy') && !this.editRecordVM.default && !this.editRecordVM.archived
    }
    return false
  }

  public can = (permissionType: string) => {
    if (['update', 'destroy'].includes(permissionType)) {
      return this.recordRepository.can(permissionType)
    }
    return this.listRepository.can(permissionType)
  }

  public getRecordLoadingObserver = () => {
    return observe(this.recordRepository, 'loading', () => {
      this.pageVM.editPanelLoading = this.recordRepository.loading
    })
  }

  public getRecordToEditObserver = () => {
    return observe(this.recordRepository, 'recordForEdit', () => {
      this.inflateEditRecordVM()
    })
  }
}
