// @ts-nocheck
import { useRef, useState, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { transition, breakpoints, colors, px2rem } from 'app/Styles/Variables'
import { LoadingPanel } from 'Shared/Components/Templates/LoadingPanel/LoadingPanel'
import { eventManager } from '../../../../../event-manager'

interface IEditPanelSectionProps {
  withSectionNav: boolean
  isOpen: boolean
  isHidden: boolean
}

const EditPanelSection = styled.section`
  display: ${(props: IEditPanelSectionProps) => (props.isHidden ? 'none' : 'initial')};

  box-sizing: border-box;
  background-color: ${colors.white};
  ${transition('medium')}

  position: absolute;
  bottom: 0;
  z-index: 4;
  top: 0;
  left: 0;
  right: 0;
  ${(props: IEditPanelSectionProps) =>
    props.isOpen ? 'transform: translate3d(0%, 0, 0);' : 'transform: translate3d(0%,100%,0);'};

  @media ${breakpoints.md} {
    transform: ${(props: IEditPanelSectionProps) =>
      props.isOpen ? 'translate3d(0%, 0, 0);' : 'translate3d(100%,0%,0);'};
    left: ${(props: IEditPanelSectionProps) => (props.withSectionNav ? `${px2rem(64)};` : '0px;')};
  }

  @media ${breakpoints.gtMd} {
    position: relative;
    transform: ${(props: IEditPanelSectionProps) =>
      props.isOpen ? 'translate3d(0%, 0, 0);' : 'translate3d(100%,0%,0);'};
    border-left: 1px solid ${colors.primaryGreyHoverBackgroundColor};
    min-width: ${(props: IEditPanelSectionProps) => (props.isOpen ? `${px2rem(320)}` : '0px')};
    max-width: ${(props: IEditPanelSectionProps) => (props.isOpen ? `${px2rem(320)}` : '0px')};
  }
`

type PanelOpenState = 'opening' | 'closing' | 'open' | 'closed'

interface IEditPanelWrapperProps {
  isOpen?: boolean
  loading?: boolean
  withSectionNav?: boolean
  extraStyles?: CSSProperties
  children: ReactNode
  panelCloseCallback?: () => void
}

export const EditPanelWrapper = ({
  isOpen,
  loading,
  withSectionNav,
  extraStyles,
  children,
  panelCloseCallback
}: IEditPanelWrapperProps) => {
  const [panelOpenState, setPanelOpenState] = useState<PanelOpenState>('closed')
  const panelTimeoutRef = useRef<null | ReturnType<typeof setTimeout>>(null)

  const handleOpenPanel = useCallback(() => {
    setPanelOpenState('opening')
    panelTimeoutRef.current = setTimeout(() => {
      setPanelOpenState('open')
      panelTimeoutRef.current = setTimeout(() => {
        eventManager.emit('right-panel-opened')
      }, 400)
    }, 100)
  }, [setPanelOpenState])

  const handleClosePanel = useCallback(() => {
    setPanelOpenState('closing')
    panelTimeoutRef.current = setTimeout(() => {
      setPanelOpenState('closed')
      eventManager.emit('right-panel-closed')
      panelCloseCallback?.()
    }, 500)
  }, [panelCloseCallback])

  useEffect(() => {
    eventManager.on('close-right-panel', handleClosePanel)
    eventManager.on('open-right-panel', handleOpenPanel)

    return () => {
      eventManager.off('close-right-panel', handleClosePanel)
      eventManager.off('open-right-panel', handleOpenPanel)
      clearTimeout(panelTimeoutRef.current)
    }
  }, [])

  useEffect(() => {
    // When the panel open/close is triggered through props
    if (isOpen) {
      eventManager.emit('open-right-panel')
    } else {
      eventManager.emit('close-right-panel')
    }
  }, [isOpen])

  return (
    <EditPanelSection
      isOpen={panelOpenState === 'open'}
      isHidden={panelOpenState === 'closed'}
      withSectionNav={withSectionNav}
      style={extraStyles}
    >
      {loading && <LoadingPanel />}
      {children}
    </EditPanelSection>
  )
}
