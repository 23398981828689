// @ts-nocheck
import { container } from 'app/Config/IOC'
import { useEffect } from 'react'
import { addSectionHooks } from 'Shared/HooksAndPresenters/AddEditSection'
import { GenericAddModal, Modal } from 'Shared/Components/Atoms'
import { EditPanelArchiveModal } from 'Shared/Components/Templates'
import { IPageModalProps } from 'app/Shared/Entities/IPageModalProps'
import { CustomFieldGroupViewPresenter } from './CustomFieldGroupViewPresenter'
import CustomFieldGroupAddSection from './CustomFieldGroupAdd/CustomFieldGroupAddSection'

export const CustomFieldGroupsPageModals = (props: IPageModalProps) => {
  const {
    addModalOpenState,
    setAddModalOpenState,
    addFormPresenter,
    addPostSuccessCallback,
    addFormCancelCallback,
    archiveModalPresenter,
    archiveResponseErrors,
    archiveConfirmName,
    cancelArchiveCallback,
    continueArchiveCallback,
    archiveModalOpenState,
    language
  } = props

  const customFieldGroupViewPresenter = container.get(CustomFieldGroupViewPresenter)

  const { addModalPresenter } = addSectionHooks(setAddModalOpenState, addModalOpenState, addFormPresenter)

  useEffect(() => {
    if (archiveModalOpenState) {
      archiveModalPresenter.onOpen()
    } else {
      archiveModalPresenter.onClose()
    }
  }, [archiveModalOpenState])

  return (
    <>
      <GenericAddModal
        title={language.get('customFieldGroups:add:title')}
        presenter={addModalPresenter}
        cancelCallback={() => {
          setAddModalOpenState(false)
          addFormCancelCallback()
        }}
        viewPresenter={customFieldGroupViewPresenter}
        language={language}
      >
        <CustomFieldGroupAddSection
          postSuccessCallback={() => {
            addModalPresenter.onClose()
            setAddModalOpenState(false)
            addPostSuccessCallback()
          }}
          addFormPresenter={addFormPresenter}
        />
      </GenericAddModal>
      <Modal presenter={archiveModalPresenter.withOnCloseExtra(cancelArchiveCallback)}>
        <EditPanelArchiveModal
          archiveConfirmName={archiveConfirmName}
          cancelCallback={cancelArchiveCallback}
          continueCallback={continueArchiveCallback}
          responseErrors={archiveResponseErrors}
          modalTitle={language.get('customFieldGroups:archive:modal:title')}
          modalMessage={language.get('customFieldGroups:archive:modal:message')}
        />
      </Modal>
    </>
  )
}
