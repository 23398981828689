import { useCallback, useRef } from 'react'
import { eventManager } from 'event-manager'
import { debounce, isEqual } from 'lodash'

import { TimelineController } from './timeline-controller'
import { Changes, TimelineOptions } from './types'

// TODO: Type naming could be improved
type ControllerOptions = Partial<TimelineOptions>
type ControllerChanges = Changes<ControllerOptions>

//===================== logic extracted to hooks ================================
export function useTimeline({ mountEl }: { mountEl?: Element | null }) {
  const timelineControllerRef = useRef<TimelineController | null>(null)

  const initTimeline = useCallback(
    (opts: Partial<TimelineOptions>) => {
      let debouncedResize: ReturnType<typeof debounce> | undefined

      if (!timelineControllerRef.current && mountEl) {
        timelineControllerRef.current = new TimelineController(mountEl, opts)

        debouncedResize = debounce(timelineControllerRef.current.onResize, 500)

        window.addEventListener('resize', debouncedResize)
        eventManager.on('angular-toggle-panel', timelineControllerRef.current.onResize)
      }

      return () => {
        if (timelineControllerRef.current) {
          timelineControllerRef.current.cleanup()
          eventManager.off('angular-toggle-panel', timelineControllerRef.current.onResize)

          if (debouncedResize) {
            window.removeEventListener('resize', debouncedResize)
          }
        }
      }
    },
    [mountEl]
  )

  const updateTimeline = useCallback((options: Partial<TimelineOptions>) => {
    if (!timelineControllerRef.current) return

    const changes = calculateChanges({
      prevControllerOptions: timelineControllerRef.current,
      nextControllerOptions: options
    })

    if (options && changes) {
      for (const property in changes) {
        // @ts-ignore
        timelineControllerRef.current[property] = changes[property].currentValue
      }

      timelineControllerRef.current.onChanges(changes)
    }
  }, [])

  return { timelineControllerRef, updateTimeline, initTimeline }
}

function calculateChanges({
  prevControllerOptions,
  nextControllerOptions = {}
}: {
  prevControllerOptions: TimelineOptions
  nextControllerOptions?: Partial<TimelineOptions>
}) {
  const keys = Object.keys(nextControllerOptions) as (keyof ControllerOptions)[]

  const changes = keys.reduce<ControllerChanges>((changes, option) => {
    let prevVal = prevControllerOptions[option] as any
    const currVal = nextControllerOptions[option] as any

    if (!isEqual(prevVal, currVal)) {
      changes[option] = {
        // @ts-ignore
        previousValue: prevVal,
        // @ts-ignore
        currentValue: currVal
      }
    }

    return changes
  }, {})

  if (Object.keys(changes).length) {
    return changes
  }
}
