import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const CommentAdd = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="CommentAdd" {...props}>
    <path d="M22.5 12.5h3v7h7v3h-7v7h-3v-7h-7v-3h7v-7Z" fill="#000" /><path d="M32 46V36h2a8 8 0 0 0 8-8V14a8 8 0 0 0-8-8H14a8 8 0 0 0-8 8v14a8 8 0 0 0 8 8h4.667L32 46ZM19.667 33H14a5 5 0 0 1-5-5V14a5 5 0 0 1 5-5h20a5 5 0 0 1 5 5v14a5 5 0 0 1-5 5h-5v7l-9.333-7Z" fill="#000" />
  </StyledIcon>
))

CommentAdd.displayName = 'CommentAdd'

export { CommentAdd }
