import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const AlignCenter = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="AlignCenter" {...props}>
    <path d="M6 9h36v3H6V9Zm10 13.5h17v3H16v-3ZM38 36H11v3h27v-3Z" fill="#000" />
  </StyledIcon>
))

AlignCenter.displayName = 'AlignCenter'

export { AlignCenter }
