import { Account } from 'main/services/queries/types'
import {
  useActiveAccountLoadableCallback,
  useActiveAccountLoadableValue,
  useGetActiveAccount,
  useGetActiveAccountCallback,
  useId,
  useIdCallback
} from 'main/data-access/hooks'
import { ActiveModelInterfaceBase } from './types'

export type ActiveAccountModelType = ActiveModelInterfaceBase<Account>

export const ActiveAccountModel: Omit<ActiveAccountModelType, 'useCan'> = {
  useGet: useGetActiveAccount,
  useGetCallback: useGetActiveAccountCallback,

  useGetLoadable: useActiveAccountLoadableValue,
  useGetLoadableCallback: useActiveAccountLoadableCallback,

  useId,
  useIdCallback
}
