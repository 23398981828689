import * as yup from 'yup'

import { customFieldValidation } from 'main/components/shared/custom-field-form'

export type CreateRunbookFormType = yup.InferType<ReturnType<typeof buildCreateRunbookSchema>>
export const buildCreateRunbookSchema = (fieldValueValidation: ReturnType<typeof customFieldValidation>) => {
  return yup.object({
    _step: yup.number().oneOf([1, 2]),
    create_from_template: yup.string().oneOf(['true', 'false']).required(), // Long standing issue with radioboxes not handling bools
    base_template: yup.object({
      id: yup.number(),
      name: yup.string(),
      project: yup.object({
        id: yup.number()
      })
    }),
    runbook: yup.object({
      name: yup.string().required(),
      account_id: yup.number().required(),
      runbook_type_id: yup.number().required(),
      project_id: yup.number(),
      is_template: yup.bool().required(),
      timezone: yup.string(),
      runbook_versions_attributes: yup.array().of(
        yup.object({
          start_scheduled: yup.date().notRequired(),
          end_scheduled: yup.date().notRequired()
        })
      )
    }),
    field_values: fieldValueValidation,
    role_types: yup.array().of(
      yup.object({
        id: yup.number(),
        description: yup.string().nullable(),
        name: yup.string(),
        users: yup.array().of(
          yup.object({
            role_id: yup.number(),
            id: yup.number()
          })
        )
      })
    ),
    shift_time: yup.bool()
  })
}
