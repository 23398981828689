import { ThemeType as GrommetThemeType } from 'grommet/themes'
import { SetRequired } from 'type-fest'

type GrommetTheme = SetRequired<GrommetThemeType, 'heading'>
type GrommetHeading = Partial<GrommetTheme['heading']>

export const heading: GrommetHeading = {
  color: 'text',
  level: {
    4: {
      font: {
        weight: 500
      },
      medium: {
        size: '18px'
      }
    },
    5: {
      font: {
        weight: 500
      },
      medium: {
        size: '13px'
      }
    }
  }
}
