// @ts-nocheck
import 'reflect-metadata'
import { container } from 'app/Config/IOC'
import { injectable } from 'inversify'
import { computed, observable, action } from 'mobx'
import { AccountsPageViewPresenter } from './AccountsPageViewPresenter'
import { AccountListPresenter } from './AccountList/AccountListPresenter'
import { AccountEditPresenter } from './AccountEdit/AccountEditPresenter'
import { AccountFormPresenter } from './AccountForm/AccountFormPresenter'
import { IAccount } from 'Shared/Entities/Types/Account/IAccount'
import { CurrentPagePresenter } from 'app/Routing/CurrentPage/CurrentPagePresenter'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'
import { Types } from 'Gateways/Service/Types'

@injectable()
export class AccountsPagePresenter {
  private accountsPageViewPresenter: AccountsPageViewPresenter = container.get(AccountsPageViewPresenter)

  @observable
  public addFormPresenter: AccountFormPresenter = new AccountFormPresenter()

  @observable
  public editFormPresenter: AccountFormPresenter = new AccountFormPresenter()

  public language: ILanguageService = container.get(Types.ILanguageService)
  public listPresenter: AccountListPresenter = new AccountListPresenter()

  public accountEditPresenter: AccountEditPresenter = new AccountEditPresenter()

  @computed
  public get angularJSSettingsPanelOpenState(): boolean {
    return container.get(CurrentPagePresenter).angularJSSettingsPanelOpenState
  }

  @computed
  public get loading(): boolean {
    return this.accountsPageViewPresenter.repositoryLoading
  }

  @computed
  public get accountVM(): IAccount {
    return this.accountsPageViewPresenter.accountVM
  }

  @computed
  public get archiveModalOpenState(): boolean {
    return this.accountsPageViewPresenter.pageVM.archiveModalOpenState
  }

  @computed
  public get canCreate(): boolean {
    return this.accountsPageViewPresenter.can('create')
  }

  public accountVMChangeFunction = () => {
    if (this.accountVM) {
      this.editFormPresenter
        .withRecordViewModel(this.accountVM)
        .withReadOnly(!this.accountsPageViewPresenter.can('update'))
    }
  }

  public clearAllFilter = () => {
    this.accountsPageViewPresenter.clearFilters()
  }

  @computed
  public get editOpen(): boolean {
    return this.accountsPageViewPresenter.pageVM.editPanelOpen
  }

  @computed
  public get editLoading(): boolean {
    return this.accountsPageViewPresenter.pageVM.editLoading
  }

  public checkEditPanelIsDirty = (): boolean => {
    return this.accountsPageViewPresenter.checkEditPanelIsDirty()
  }

  public editPanelClosedCallback = (): void => {
    this.accountsPageViewPresenter.editPanelAfterCloseFunction()
  }

  public closePanel = (): void => {
    this.accountsPageViewPresenter.close()
  }

  @action
  public resetSubmit = () => {
    this.accountsPageViewPresenter.submitted = false
  }
}
