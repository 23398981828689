import styled, { css } from 'styled-components'
import { fontSizes, px2rem, transition } from 'app/Styles/Variables'

interface IFormFieldLabelProps {
  color: string
  isShrunk: boolean
  hasPrefix: boolean
  absolute?: boolean
}

export const FormFieldLabel = styled.label`
  transition: ${transition('xfast')};
  white-space: nowrap;
  color: ${(props: IFormFieldLabelProps) => props.color};
  font-size: ${(props: IFormFieldLabelProps) => (props.isShrunk ? fontSizes.sm : fontSizes.m)};
  cursor: text;
  font-weight: ${(props: IFormFieldLabelProps) => (props.isShrunk ? 500 : 400)};

  ${(props: IFormFieldLabelProps) => {
    if (props.absolute) {
      return css`
        position: absolute;
        top: ${(props: IFormFieldLabelProps) => (props.isShrunk ? px2rem(-12) : px2rem(8))};
        left: ${(props: IFormFieldLabelProps) => (props.hasPrefix && !props.isShrunk ? px2rem(30) : '0')};
      `
    }
  }}
`
