import { LegacyTableBase as Table } from '@cutover/react-ui'
import { DashboardUserItem } from './dashboard-user-item'
import { MrdDashboardWidget } from '../../../widgets/account/mrd-dashboard-widget'
import { DashboardComponent, DashboardMediaType } from '../../types'

export type RunbookAdminWidgetProps = {
  media: DashboardMediaType
  data: DashboardComponent
}

export const RunbookAdminWidget = ({ data }: RunbookAdminWidgetProps) => {
  const { width, name } = data
  const fullWidth = width === 100 || !width

  // i18n cannot be used for the title, as this widget is reused in email contexts where useLanguage is unsupported and leads to errors.
  return (
    <MrdDashboardWidget title={name && name.trim().length > 0 ? name : 'Runbook Admins'} fullWidth={fullWidth}>
      <RunbookAdminWidgetContent componentData={data}></RunbookAdminWidgetContent>
    </MrdDashboardWidget>
  )
}

export const RunbookAdminWidgetContent = ({ componentData }: { componentData: DashboardComponent }) => {
  const admins = componentData?.users.filter(
    user => user.is_admin === true && user.archived === false && user.status === 'active'
  )
  return (
    <>
      <Table width="100%" style={{ cursor: 'default' }}>
        <Table.Head>
          {admins?.map(user => (
            <Table.Row key={`user${user.id}`}>
              <DashboardUserItem name={user.name} color={user.color || ''} handle={user.handle} />
            </Table.Row>
          ))}
        </Table.Head>
      </Table>
    </>
  )
}
