import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const RemoveRedundant = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="RemoveRedundant" {...props}>
    <path d="M23.884 13.486A6.008 6.008 0 0 1 20.5 15.81v16.378a6.011 6.011 0 0 1 3.612 2.669l4.623-1.423a2.5 2.5 0 0 0 1.765-2.39V30h3v1.046a5.5 5.5 0 0 1-3.883 5.256l-4.623 1.423A6 6 0 1 1 17.5 32.19V15.811a6 6 0 1 1 7.461-5.125l5.013 1.928a5.5 5.5 0 0 1 3.526 5.133V18h-3v-.253a2.5 2.5 0 0 0-1.603-2.333l-5.013-1.928ZM22 10a3 3 0 1 0-6 0 3 3 0 0 0 6 0Zm0 28a3 3 0 1 0-6 0 3 3 0 0 0 6 0Z" fill="#000" /><path d="m34.192 24.071 4.95 4.95-2.121 2.121-4.95-4.95-4.95 4.95L25 29.021l4.95-4.95-4.95-4.95L27.121 17l4.95 4.95 4.95-4.95 2.121 2.121-4.95 4.95Z" fill="#000" />
  </StyledIcon>
))

RemoveRedundant.displayName = 'RemoveRedundant'

export { RemoveRedundant }
