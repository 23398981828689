import { Dispatch, SetStateAction, useState } from 'react'

import { Message, Modal, Text, TextInput } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { ActivityVM, useDeleteCommentActivityMutation } from 'main/services/queries/use-activities'

type DeleteCommentModalProps = {
  activity?: ActivityVM
  setDeleteCommentActivity: Dispatch<SetStateAction<ActivityVM | undefined>>
}

export const DeleteCommentModal = ({ activity, setDeleteCommentActivity }: DeleteCommentModalProps) => {
  const { t } = useLanguage('activities')
  const deleteCommentMutation = useDeleteCommentActivityMutation()
  const [deletionReason, setDeletionReason] = useState<string>('')
  const [showError, setShowError] = useState<boolean>(false)

  const handleOnModalClose = () => {
    setDeleteCommentActivity(undefined)
    setShowError(false)
    setDeletionReason('')
  }

  const deleteComment = async () => {
    if (activity?.display?.comment_id) {
      deleteCommentMutation.mutate({ commentId: activity.display.comment_id, reason: deletionReason })
    }
    handleOnModalClose()
  }

  const renderModalContent = () => (
    <>
      {showError && <Message message={t('feed.deleteConfirm.error')} type="error" />}
      <Text>{t('feed.deleteConfirm.text')}</Text>
      <TextInput
        label={t('feed.deleteConfirm.inputLabel')}
        onChange={e => setDeletionReason(e.target.value)}
        hasError={showError}
        required
      />
    </>
  )

  return (
    <Modal
      data-testid={`confirm-deletion-${activity?.id}`}
      open={!!activity}
      onClickConfirm={() => (!!deletionReason ? deleteComment() : setShowError(true))}
      onClose={() => handleOnModalClose()}
      title={t('feed.deleteConfirm.title')}
      dismissible={true}
      hasCancelButton={true}
      focusConfirmButton={true}
      confirmText={t('feed.deleteConfirm.button')}
      confirmIcon="check"
    >
      {renderModalContent()}
    </Modal>
  )
}
