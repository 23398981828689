import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const ArrowUp = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="ArrowUp" {...props}>
    <path d="m4 24.586 19.799-19.8 19.799 19.8-2.121 2.121L25.299 10.53V42h-3V10.53L6.121 26.706 4 24.586Z" fill="#000" />
  </StyledIcon>
))

ArrowUp.displayName = 'ArrowUp'

export { ArrowUp }
