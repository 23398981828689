import { useState } from 'react'
import { eventManager } from 'event-manager'

import { SelectSnippet } from 'main/components/runbook/modals/snippets/start-snippet-task-type/select-snippet'
import { ReactAngularConnector } from 'main/connectors/react-angular-connector'
import { LinkedSnippet } from 'main/services/queries/types'
import { SnippetListSnippet } from 'main/services/queries/use-snippets'

type StartSnippetTaskTypeState = {
  linkedSnippets: LinkedSnippet[] | null
}

export const StartSnippetTaskTypeConnector = () => {
  const [state, setState] = useState<StartSnippetTaskTypeState>({ linkedSnippets: null })

  const handleOnMount = ({ linkedSnippets }: { linkedSnippets: LinkedSnippet[] }) => {
    setState({ linkedSnippets })
  }

  const handleOnUnmount = () => {
    setState({ linkedSnippets: null })
  }

  const onSelectSnippet = (snippet?: SnippetListSnippet | LinkedSnippet) => {
    eventManager.emit('select-snippet-for-snippet-task-type', { selectedSnippetId: snippet?.id || null })
  }

  return (
    <ReactAngularConnector
      mountEvent="show-start-snippet-task-type"
      unmountEvent="hide-start-snippet-task-type"
      onMount={handleOnMount}
      onUnmount={handleOnUnmount}
    >
      {state.linkedSnippets && (
        <SelectSnippet withTopMargin onSelect={onSelectSnippet} linkedSnippets={state.linkedSnippets} />
      )}
    </ReactAngularConnector>
  )
}
