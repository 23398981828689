import { useRef } from 'react'
import { useRecoilCallback, useRecoilTransaction_UNSTABLE, useRecoilValue, useRecoilValueLoadable } from 'recoil'
import { produce } from 'immer'
import { extend } from 'lodash'

import { useNotify } from '@cutover/react-ui'
import { getRunbookVersion as getRunbookVersionRequest } from 'main/services/queries/use-runbook-versions'
import { useLanguage } from 'main/services/hooks'
import {
  runbookIdState,
  runbookResponseState_INTERNAL,
  runbookState,
  runbookVersionIdState,
  runbookVersionPermission,
  runbookVersionResponseState_INTERNAL,
  runbookVersionState
} from 'main/recoil/runbook'
import {
  RunbookVersionConvertToTemplateResponse,
  RunbookVersionCreateResponse,
  RunbookVersionImportResponse
} from 'main/services/api/data-providers/runbook-types'
import { updateCurrentVersion } from './shared-updates'
import { Run, RunbookTemplateType } from 'main/services/queries/types'
import { useNavigateUpdateRunbookVersion } from 'main/services/routing'
import { ActiveRunbookVersionModelType } from 'main/data-access/models'
import { useReloadTasks } from './task'

const handleUnknownKey = (type: string, fnName: string): never => {
  throw new Error(`Unknown argument passed to ${fnName}: ${type}`)
}

/* -------------------------------------------------------------------------- */
/*                                     Get                                    */
/* -------------------------------------------------------------------------- */

export const useGetRunbookVersion: ActiveRunbookVersionModelType['useGet'] = () => {
  return useRecoilValue(runbookVersionState)
}

export const useGetRunbookVersionCallback: ActiveRunbookVersionModelType['useGetCallback'] = () =>
  useRecoilCallback(({ snapshot }) => async () => {
    return await snapshot.getPromise(runbookVersionState)
  })

/* -------------------------------------------------------------------------- */
/*                                     Id                                     */
/* -------------------------------------------------------------------------- */

export const useGetRunbookVersionId: ActiveRunbookVersionModelType['useId'] = () => {
  return useRecoilValue(runbookVersionState).id
}

export const useGetRunbookVersionIdCallback: ActiveRunbookVersionModelType['useIdCallback'] = () =>
  useRecoilCallback(({ snapshot }) => async () => {
    return (await snapshot.getPromise(runbookVersionState)).id
  })

/* -------------------------------------------------------------------------- */
/*                                  Loadable                                  */
/* -------------------------------------------------------------------------- */

export const useGetRunbookVersionLoadable: ActiveRunbookVersionModelType['useGetLoadable'] = () =>
  useRecoilValueLoadable(runbookVersionState)

export const useGetRunbookVersionLoadableCallback: ActiveRunbookVersionModelType['useGetLoadableCallback'] = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      () =>
        snapshot.getLoadable(runbookVersionState)
  )

/* -------------------------------------------------------------------------- */
/*                                     Can                                    */
/* -------------------------------------------------------------------------- */
export const useCanRunbookVersion: ActiveRunbookVersionModelType['useCan'] = key => {
  return useRecoilValue(runbookVersionPermission({ attribute: key }))
}

/* -------------------------------------------------------------------------- */
/*                                   Reload                                   */
/* -------------------------------------------------------------------------- */

export const useReloadRunbookVersion: ActiveRunbookVersionModelType['useReload'] = () =>
  useRecoilCallback(({ snapshot, set }) => async () => {
    const rbId = await snapshot.getPromise(runbookIdState)
    const rbvId = await snapshot.getPromise(runbookVersionIdState)

    set(runbookVersionResponseState_INTERNAL, await getRunbookVersionRequest(rbId, rbvId))
  })

export const useReloadRunbookVersionSync: ActiveRunbookVersionModelType['useReloadSync'] = () => () => {
  window.dispatchEvent(new CustomEvent<any>('refresh-data-store', { detail: { type: 'runbook-version' } }))
}

/* -------------------------------------------------------------------------- */
/*                                   Action                                   */
/* -------------------------------------------------------------------------- */

const RUNBOOK_VERSION_ERRORS = {
  NOT_PLANNING_MODE: 'NOT_PLANNING_MODE',
  ALREADY_TEMPLATE: 'ALREADY_TEMPLATE',
  NO_PERMISSION: 'NO_PERMISSION'
}

const canConvertToTemplate = (templateType: RunbookTemplateType, run: Run | null) => {
  const isPlanning = !['rehearsal', 'live'].includes(run?.run_type || '')
  if (!isPlanning) return { can: false, error: RUNBOOK_VERSION_ERRORS.NOT_PLANNING_MODE }
  if (templateType !== 'off') return { can: false, error: RUNBOOK_VERSION_ERRORS.ALREADY_TEMPLATE }
  return { can: true, error: undefined }
}

export const useCanActionRunbookVersion: ActiveRunbookVersionModelType['usePermission'] = action => {
  const stabilisedAction = useRef(action).current

  switch (stabilisedAction) {
    case 'convert_to_template':
      /* eslint-disable react-hooks/rules-of-hooks */
      const { template_type } = useRecoilValue(runbookState)
      const { run } = useGetRunbookVersion()

      return canConvertToTemplate(template_type, run)
    case 'runbook_version_import':
      const canImport = useCanRunbookVersion('import')
      return { can: canImport, error: canImport ? undefined : RUNBOOK_VERSION_ERRORS.NO_PERMISSION }
    case 'create':
      // TODO: WARNING: not yet implemented as a feature at time of writing; this hasn't been double checked.
      const canCreate = useCanRunbookVersion('create')
      return { can: canCreate, error: canCreate ? undefined : RUNBOOK_VERSION_ERRORS.NO_PERMISSION }
    default:
      return handleUnknownKey(stabilisedAction, 'canActionRunbookVersion')
    /* eslint-enable react-hooks/rules-of-hooks */
  }
}

export const useCanActionRunbookVersionCallback: ActiveRunbookVersionModelType['usePermissionCallback'] = action => {
  const runbookVersionValueCb = useGetRunbookVersionCallback()

  return useRecoilCallback(({ snapshot }) => async () => {
    switch (action) {
      case 'convert_to_template':
        const rbv = await runbookVersionValueCb()
        const rb = await snapshot.getPromise(runbookState)
        return canConvertToTemplate(rb.template_type, rbv.run)
      case 'create':
        // TODO: WARNING: not yet implemented as a feature at time of writing; this hasn't been double checked.
        const canCreate = await snapshot.getPromise(runbookVersionPermission({ attribute: 'create' }))
        return { can: canCreate, error: canCreate ? undefined : RUNBOOK_VERSION_ERRORS.NO_PERMISSION }
      case 'runbook_version_import':
        const canImport = await snapshot.getPromise(runbookVersionPermission({ attribute: 'import' }))
        return { can: canImport, error: canImport ? undefined : RUNBOOK_VERSION_ERRORS.NO_PERMISSION }
      default:
        return handleUnknownKey(action, 'canActionRunbookVersionCallback')
    }
  })
}

// TODO: fix types
// @ts-ignore
export const useOnActionRunbookVersion: ActiveRunbookVersionModelType['useOnAction'] = action => {
  const processCreate = useProcessRunbookVersionCreateResponse()
  const processConvertToTemplate = useProcessRunbookVersionConvertToTemplateResponse()
  const processCsvImport = useProcessRunbookVersionCsvImportResponse()

  switch (action) {
    case 'create':
      return processCreate
    case 'convert_to_template':
      return processConvertToTemplate
    case 'runbook_version_import':
      return processCsvImport
  }
}

const useProcessRunbookVersionCreateResponse = () => {
  const navigateToNewVersion = useNavigateUpdateRunbookVersion()
  const notify = useNotify()
  const { t } = useLanguage('runbook')

  const process = useRecoilTransaction_UNSTABLE(transactionInterface => (response: RunbookVersionCreateResponse) => {
    const { set } = transactionInterface

    updateCurrentVersion(transactionInterface)(response.runbook_version)

    set(runbookVersionResponseState_INTERNAL, prevRunbookVersionResponse =>
      produce(prevRunbookVersionResponse, draftRunbookVersionResponse => {
        extend(draftRunbookVersionResponse.runbook_version, response.runbook_version)
      })
    )

    notify.success(t('versionSaved'))
  })

  return (response: RunbookVersionCreateResponse) => {
    process(response)
    const currentVersionId = (response as RunbookVersionCreateResponse).runbook_version.id
    navigateToNewVersion(currentVersionId)
    notify.success(t('versionSaved'))
  }
}

const useProcessRunbookVersionConvertToTemplateResponse = () => {
  return useRecoilTransaction_UNSTABLE(transactionInterface => (response: RunbookVersionConvertToTemplateResponse) => {
    const { set } = transactionInterface

    set(runbookResponseState_INTERNAL, prevRunbookResponse =>
      produce(prevRunbookResponse, draftRunbookResponse => {
        draftRunbookResponse.runbook.is_template = response.meta.runbook_is_template
        draftRunbookResponse.runbook.template_status = response.meta.runbook_template_status
        if (response.meta.runbook_is_template) draftRunbookResponse.runbook.template_type = 'default'
      })
    )

    set(runbookVersionResponseState_INTERNAL, prevRunbookVersionResponse =>
      produce(prevRunbookVersionResponse, draftRunbookVersionResponse => {
        extend(draftRunbookVersionResponse.runbook_version, response.runbook_version)
        extend(draftRunbookVersionResponse.meta.permissions, response.meta.permissions)
      })
    )
  })
}

const useProcessRunbookVersionCsvImportResponse = () => {
  const refetchRunbookVersion = useReloadRunbookVersion()
  // TODO: replace with new task hook when completed
  const refetchTasks = useReloadTasks()

  // FIXME: seems wrong. we don't updeate the runbook state's current_version and runbook_version_id
  return useRecoilCallback(({ set }) => async (data: RunbookVersionImportResponse) => {
    set(runbookVersionResponseState_INTERNAL, prevRunbookVersionResponse =>
      produce(prevRunbookVersionResponse, draftRunbookVersionResponse => {
        extend(draftRunbookVersionResponse.runbook_version, data.runbook_version)
      })
    )

    await refetchRunbookVersion()
    await refetchTasks()
  })
}
