// @ts-nocheck
import { observer } from 'mobx-react-lite'
import { RemoteOptionsPresenter } from './RemoteOptionsPresenter'
import { GenericOptions } from './GenericOptions'

interface IRemoteOptionProps {
  presenter: RemoteOptionsPresenter
}

export const RemoteOptions = observer<IRemoteOptionProps>(props => {
  const { presenter } = props

  return <GenericOptions presenter={presenter} />
})
