import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const UserRoles = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="UserRoles" {...props}>
    <path d="M24 16a7 7 0 1 1-14 0 7 7 0 0 1 14 0Zm-3 0a4 4 0 1 0-8 0 4 4 0 0 0 8 0Zm7.959 21C28.45 30.84 23.29 26 17 26S5.55 30.84 5.041 37c-.027.33-.041.663-.041 1a2 2 0 0 0 2 2h20a2 2 0 0 0 2-2c0-.337-.014-.67-.041-1Zm-3.014 0H8.055a9.001 9.001 0 0 1 17.89 0ZM30 16a3 3 0 0 0-3 3v6a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3v-6a3 3 0 0 0-3-3v-4a5 5 0 0 0-10 0v4Zm10 9H30v-6h10v6Zm-7-9v-4a2 2 0 1 1 4 0v4h-4Z" fill="#000" />
  </StyledIcon>
))

UserRoles.displayName = 'UserRoles'

export { UserRoles }
