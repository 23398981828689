// @ts-nocheck
import { FieldOptionsPresenter } from './FieldOptionsPresenter'
import { observer } from 'mobx-react-lite'
import { GenericOptions } from './GenericOptions'

interface IFieldOptionProps {
  presenter: FieldOptionsPresenter
}

export const FieldOptions = observer<IFieldOptionProps>(props => {
  const { presenter } = props

  return <GenericOptions presenter={presenter} />
})
