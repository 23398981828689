import { useState } from 'react'

import { Box, IconButton, IconName, Menu, MenuListItem, useNotify } from '@cutover/react-ui'
import { SubHeader } from 'main/components/layout/shared/sub-header'
import { useLanguage } from 'main/services/hooks'
import {
  downloadServiceProviderMetadata,
  useSamlConfigurationQuery
} from 'main/services/queries/use-saml-configurations-query'
import { saveFile } from 'Shared/Helpers'
import { usePermissions } from 'main/services/queries/use-permissions'

export const SamlConfigurationsSubHeader = () => {
  const { t } = useLanguage('samlConfigurations')
  const notify = useNotify()
  const permissions = usePermissions('saml-configs')
  const canDownloadServiceProviderMetadata = permissions('service_provider_metadata')
  const [menuIcon, setMenuIcon] = useState<IconName>('more-vertical')
  const { data } = useSamlConfigurationQuery()

  const onDownloadServiceProviderMetadata = async () => {
    setMenuIcon('spinner')

    downloadServiceProviderMetadata()
      .then((data: string) => {
        saveFile(data, 'application/x-yaml', 'cutover-sp-metadata.xml')
        notify.success(t('exportServiceProviderMetadata.success'))
      })
      .catch(error => {
        notify.error(error.errors),
          {
            title: t('exportServiceProviderMetadata.title')
          }
      })
      .finally(() => {
        setMenuIcon('more-vertical')
      })
  }

  return (
    <SubHeader.Items>
      <SubHeader.LeftItems>
        <Box flex="grow" justify="center">
          {`${data?.saml_configurations?.length} ${t('samlConfigsSubHeader.configurations')}`}
        </Box>
      </SubHeader.LeftItems>
      <SubHeader.RightItems>
        {canDownloadServiceProviderMetadata && (
          <Menu
            trigger={
              <IconButton
                tertiary
                data-testid="sub-header-options-menu"
                label="More options"
                disableTooltip
                icon={menuIcon}
              />
            }
          >
            <MenuListItem
              label={t('mainPanelSubHeader.moreOptions.downloadServiceProviderMetadata')}
              key={t('mainPanelSubHeader.moreOptions.downloadServiceProviderMetadata')}
              data-testid="download-sp-metadata"
              icon="download"
              onClick={onDownloadServiceProviderMetadata}
            />
          </Menu>
        )}
      </SubHeader.RightItems>
    </SubHeader.Items>
  )
}
