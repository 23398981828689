import { PaintDiamond } from '../node-map-types'

export const paintDiamond = ({ ctx, x, y, size, lineWidth, stroke, fill }: PaintDiamond) => {
  ctx.beginPath()
  ctx.moveTo(x - size, y)
  ctx.lineTo(x, y + size)
  ctx.lineTo(x + size, y)
  ctx.lineTo(x, y - size)
  ctx.closePath()

  ctx.lineWidth = lineWidth
  ctx.strokeStyle = stroke
  ctx.stroke()

  if (fill) {
    ctx.fillStyle = fill
    ctx.fill()
  }
}
