import { memo, useMemo } from 'react'
import { LiteralUnion } from 'type-fest'

import { BrandIconProps as BaseBrandIconProps, BRAND_ICON_NAME_MAP, BrandIconName } from '@cutover/icons'
import { useTheme } from '../theme'

export type BrandIconProps = BaseBrandIconProps & { icon: LiteralUnion<BrandIconName, string> }

export const BrandIcon = memo(({ icon, size, ...props }: BrandIconProps) => {
  const theme = useTheme()
  const iconName = icon ?? 'm-and-a'
  const cleanedIconName = useMemo(() => (iconName as string).replace(/_/g, '-') as BrandIconName, [iconName])
  if (!BRAND_ICON_NAME_MAP.hasOwnProperty(cleanedIconName)) {
    console.warn(
      `BrandIcon: icon ${iconName} does not exist in the icon map. Make sure it is added properly to the @cutover/icons package and the generator script is run`
    )
  }
  const IconComponent = BRAND_ICON_NAME_MAP[cleanedIconName] || BRAND_ICON_NAME_MAP['getting-started']

  return <IconComponent {...props} size={size} theme={theme} />
})
