import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Italic = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Italic" {...props}>
    <path d="M21 7.5h12v3h-4.522l-5.925 27H27v3H15v-3h4.522l5.925-27H21v-3Z" fill="#000" />
  </StyledIcon>
))

Italic.displayName = 'Italic'

export { Italic }
