import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const HighlightMode = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="HighlightMode" {...props}>
    <path d="M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm24 24a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm-9-15a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" fill="#000" /><path d="M32 24a8 8 0 1 1-16 0 8 8 0 0 1 16 0Zm-3 0a5 5 0 1 0-10 0 5 5 0 0 0 10 0Z" fill="#000" />
  </StyledIcon>
))

HighlightMode.displayName = 'HighlightMode'

export { HighlightMode }
