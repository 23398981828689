import { selectorFamily } from 'recoil'

import { appListItemOpenState } from '../apps-atoms'
import { AppListItemOpenState } from 'main/components/apps/apps-types'

export const listItemOpenWithContextAndId = selectorFamily({
  key: 'ListItemOpen',
  get:
    ({ context, id }: { context: string; id: string }) =>
    ({ get }) => {
      return get(appListItemOpenState)[context]?.[id]
    },
  set:
    ({ context, id }: { context: string; id: string }) =>
    ({ set, get }, open) => {
      const prevListItems = get(appListItemOpenState)
      const existingListItems = prevListItems[context]
      const updatedListItems = { ...existingListItems, [id]: open }
      const updatedState = { ...prevListItems, [context]: { ...updatedListItems } }
      set(appListItemOpenState, updatedState as AppListItemOpenState)
    }
})
