import { observer } from 'mobx-react-lite'
import { Button, ButtonPresenter } from 'Components/Molecules'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'

interface IResetButton {
  resetCallback: () => void
  language: ILanguageService
}

export const ResetButton = observer<IResetButton>(({ resetCallback, language }) => {
  const resetButtonPresenter = new ButtonPresenter(language.get('common:resetButton'), resetCallback)
    .withDisplayStyle('secondary-invert')
    .withTextTransform('Lowercase')

  return <Button presenter={resetButtonPresenter} />
})
