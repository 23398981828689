import { useMemo } from 'react'
import { add as addToDate, getUnixTime as toUnix } from 'date-fns'
import { sortBy } from 'lodash'

import { colors } from '@cutover/react-ui'
import { RunbookShowRunbook, TaskListTask } from 'main/services/queries/types'

export type TaskCompletionChartData = {
  tasks: TaskListTask[]
  runbook: RunbookShowRunbook
}

export const useTaskCompletionChartData = (data: TaskCompletionChartData) => {
  const now = useMemo(() => new Date(), [])

  return useMemo(() => getTaskCompletionChartData(data, now), [data])
}

export const getTaskCompletionChartData = ({ tasks, runbook }: TaskCompletionChartData, now: Date = new Date()) => {
  // 1. build chart varibales based on tasks data
  const taskTotal = tasks.length

  const earliestStartDisplay = sortBy(tasks, task => task.start_display)[0].start_display
  const earliestStartPlanned = sortBy(tasks, task => task.start_latest_planned)[0].start_latest_planned

  const chartStart =
    earliestStartPlanned && earliestStartPlanned < earliestStartDisplay ? earliestStartPlanned : earliestStartDisplay

  const latestEndDisplay = sortBy(tasks, task => task.end_display).reverse()[0].end_display
  const latestEndPlanned = tasks.reduce((latestEndPlanned, task) => {
    const endPlanned = task.start_latest_planned ? task.start_latest_planned + task.duration : undefined
    if (endPlanned) {
      const endLatestPlanned = task.end_fixed && task.end_fixed > endPlanned ? task.end_fixed : endPlanned
      if (endLatestPlanned > latestEndPlanned) latestEndPlanned = endLatestPlanned
    }
    return latestEndPlanned
  }, -Infinity)

  const chartEnd = latestEndPlanned > latestEndDisplay ? latestEndPlanned : latestEndDisplay

  const chartDuration = chartEnd - chartStart
  const chartInterval = chartDuration / 20 // we're displaying 21 data points, so 20 interval increments from chart starting point

  // 2. build 21 data points for chart display (even if there are 3 tasks data, chart will be plotted with 21 data points)
  // Note: revisit and maybe refactor this part - most of the logic from angular (burn_chart_directive.js)
  const chartData: { date: Date; unix: number; plannedCount: number; actualCount: number; forecastCount: number }[] = []
  for (let dataPoint = 0; dataPoint <= 20; dataPoint += 1) {
    const thisDate = new Date(chartStart * 1000)
    const interval = dataPoint * chartInterval
    const date = addToDate(thisDate, { seconds: interval })
    chartData.push({ date, unix: toUnix(date), plannedCount: 0, actualCount: 0, forecastCount: 0 })
  }

  // 3. loop through tasks / chart data to fill counts for each 21 data point
  for (const task of tasks) {
    for (const dataPoint of chartData) {
      if (task.start_latest_planned !== null) {
        if (task.start_latest_planned + task.duration <= dataPoint.unix) {
          dataPoint.plannedCount += 1
        }
        if (task.stage === 'complete' && task.end_display && task.end_display <= dataPoint.unix) {
          dataPoint.actualCount += 1
        }
        if (task.end_display && task.end_display <= dataPoint.unix) {
          dataPoint.forecastCount += 1
        }
      }
    }
  }

  // 4-7 from populated chartData (21 data points) build nivo compatible arrays for planned, actual, forecast + extras

  // 4.1. build actual data points from consolidated chartData
  let actualDataPoints: { x: Date; y: number | null }[] = []
  let stopActual: boolean = false

  for (const dataPoint of chartData) {
    if (!stopActual) {
      if (new Date(dataPoint.unix * 1000) <= new Date(now) && runbook.stage !== 'planning') {
        actualDataPoints.push({ x: dataPoint.date, y: dataPoint.actualCount })
      } else if (dataPoint.actualCount === taskTotal) {
        // once actual data point reaches, no need to plot after that
        actualDataPoints.push({ x: dataPoint.date, y: dataPoint.actualCount })
        stopActual = true
      }
    } else {
      // if stopActual point is reached, no longer need to plot
      break
    }
  }

  // 4.1.1. actual data points to include today's data point if the runbook is still active
  //        to indicate where it stands in the today's marker (vertical line)
  if (runbook.stage !== 'planning' && actualDataPoints.length > 0 && actualDataPoints.length < 21) {
    actualDataPoints.push({ x: now, y: chartData[chartData.length - 1].actualCount })
  }

  // need to sort it by x(date) to put today's dataPoint in the right place
  const updatedActualDataPoints = sortBy(actualDataPoints, dataPoint => dataPoint.x)

  // 4.2 build planned data points from consolidated chartData
  const plannedDataPoints: { x: Date; y: number | null }[] = []
  let stopPlanned: boolean = false
  for (const dataPoint of chartData) {
    if (!stopPlanned) {
      if (dataPoint.plannedCount < taskTotal) {
        plannedDataPoints.push({ x: dataPoint.date, y: dataPoint.plannedCount })
      } else if (dataPoint.plannedCount === taskTotal) {
        plannedDataPoints.push({ x: dataPoint.date, y: dataPoint.plannedCount })
        stopPlanned = true
      }
    } else {
      // if stopPlanned point is reached, no longer need to plot
      break
    }
  }

  // 4.3  build forecast data points from consolidated chartData
  const forecastDataPoints: { x: Date; y: number | null }[] =
    updatedActualDataPoints.length > 0 &&
    updatedActualDataPoints.length < 21 &&
    updatedActualDataPoints[updatedActualDataPoints.length - 1].y === taskTotal
      ? []
      : chartData
          .map(dataPoint =>
            dataPoint.unix > toUnix(now) && runbook.stage !== 'planning'
              ? { x: dataPoint.date, y: dataPoint.forecastCount }
              : { x: dataPoint.date, y: null }
          )
          .filter(dataPoint => dataPoint.y !== null)

  // 5. chart data point to connect actual and forecast
  // Note: may rethink this, but this is needed to connect the forecast line and the actual line
  const endActual = updatedActualDataPoints[updatedActualDataPoints.length - 1]
  const startForecast = forecastDataPoints.length
    ? forecastDataPoints[0]
    : { x: new Date(chartEnd * 1000), y: taskTotal }

  const actualForecastConnectingPoints =
    endActual && startForecast && typeof endActual.y === 'number' && endActual.y < taskTotal
      ? [endActual, startForecast]
      : []

  // 6. chart data point to connect actual and today's forcast
  // this is to connect today's forecast point (in the marker) with the actual data point (today)
  const todayOrChartEndDataPoint = now < new Date(chartEnd * 1000) ? now : new Date(chartEnd * 1000)
  const plannedDataPointsBeforeToday = plannedDataPoints.filter(dataPoint => dataPoint.x < todayOrChartEndDataPoint)
  const todayForecast =
    runbook.stage !== 'complete' && endActual
      ? { x: todayOrChartEndDataPoint, y: plannedDataPointsBeforeToday[plannedDataPointsBeforeToday.length - 1].y }
      : null

  // 7. active data points (combination of forecast, actual, planned or all 3 active points to display filled circle symbol)
  const activePointsToDisplayFilledCircle = []
  if (endActual) activePointsToDisplayFilledCircle.push(endActual)
  if (todayForecast) activePointsToDisplayFilledCircle.push(todayForecast)
  activePointsToDisplayFilledCircle.push(plannedDataPoints[plannedDataPoints.length - 1])

  return {
    lineChartData: [
      ...(actualForecastConnectingPoints.length
        ? [{ id: 'actual-forecast', color: colors.primary, data: actualForecastConnectingPoints }]
        : []),
      ...(todayForecast
        ? [{ id: 'actual-forecast-today', color: colors.bgDark, data: [endActual, todayForecast] }]
        : []),
      { id: 'actual', color: colors.primary, data: updatedActualDataPoints },
      { id: 'planned', color: colors.bgDark, data: plannedDataPoints },
      { id: 'forecast', color: colors.primary, data: forecastDataPoints }
    ],
    latestEndPlanned,
    latestEndDisplay,
    chartStart,
    chartEnd,
    activePointsToDisplayFilledCircle,
    now
  }
}
