import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const ChevronUp = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="ChevronUp" {...props}>
    <path d="M9 30.799 23.799 16l14.799 14.799-2.121 2.121-12.678-12.677L11.121 32.92 9 30.8Z" fill="#000" />
  </StyledIcon>
))

ChevronUp.displayName = 'ChevronUp'

export { ChevronUp }
