import { Dispatch, SetStateAction, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import * as yup from 'yup'

import { Box, FormWrapper, Message, Modal, Select, useNotify } from '@cutover/react-ui'
import { LicenseKeyOutput } from '../helpers/license-key-output'
import { queryClient } from 'main/query-client'
import { ApiError } from 'main/services/api'
import { useLanguage } from 'main/services/hooks'
import { ConnectSetting, ConnectStatus } from 'main/services/queries/types/connect'
import { useConnectSettingsPatch, useFetchConnectVersions } from 'main/services/queries/use-connect-settings-query'

type ActivateProjectModalType = {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  item: ConnectSetting | undefined
}

export const ActivateProjectModal = ({ open, setOpen, item }: ActivateProjectModalType) => {
  const { t } = useLanguage('connectSettings')
  const notify = useNotify()
  const [formErrors, setFormErrors] = useState<{ [key: string]: { message: string } }>({})
  const [connectJarCommand, setConnectJarCommand] = useState('')
  const patchMutation = useConnectSettingsPatch()
  const { isLoading: isPatchLoading, isSuccess } = patchMutation
  const { data: versions, isLoading: isFetchLoading } = useFetchConnectVersions()

  const writeConnectJarCommand = (key: string, pass: string, connectVersion: string) => {
    const jarCmd =
      'java \\' +
      `\n-Dconnect.license.secret=${pass} \\` +
      '\n-Dconnect.license.key=\\' +
      `\n${key} \\` +
      `\n-jar CutoverConnect-${connectVersion}.jar`
    setConnectJarCommand(jarCmd)
  }

  const onSubmitActivateModal = async (formData: ConnectSetting) => {
    setFormErrors({})

    if (isSuccess) {
      return handleCloseActivateModal()
    }

    const setting = {
      ...item,
      ...formData
    }

    const payload = {
      status: 'Active' as ConnectStatus,
      additional_operations: {
        request_full_package: {
          connect_version: setting?.connect_version
        },
        generate_license_key: {}
      }
    }

    patchMutation.mutate(
      { id: setting?.id, payload },
      {
        onSuccess: data => {
          let url = data.meta.additional_operation_results.request_full_package.url
          window.open(url)

          let key = data.meta.additional_operation_results.generate_license_key.license_key
          let pass = data.meta.additional_operation_results.generate_license_key.passphrase

          notify.success(t('modal.activate.toasters.success'))

          queryClient.invalidateQueries(['connect-settings'])
          writeConnectJarCommand(key, pass, setting?.connect_version)
        },
        onError: error => {
          const castedError = error as ApiError
          notify.error(castedError?.errors?.[0])
          setOpen(false)
        }
      }
    )
  }

  const validationSchema = yup.object().shape({
    connect_version: yup.string().required(t('modal.activate.formErrors.required'))
  })

  const methods = useForm<ConnectSetting>({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    reValidateMode: 'onChange'
  })

  const { control } = methods

  const handleCloseActivateModal = () => {
    methods.reset()
    setOpen(false)
    setFormErrors({})
    setConnectJarCommand('')
    patchMutation.reset()
  }

  const handleErrors = (error: any) => {
    setFormErrors(error)
  }

  const formatErrors = () => {
    return Object.values(formErrors)
      .map(error => error.message)
      .join(', ')
  }

  const isErrorFor = (property: string) => {
    return property in formErrors
  }

  return (
    <Modal
      open={open}
      title={t('modal.activate.title')}
      onClose={handleCloseActivateModal}
      onClickConfirm={methods.handleSubmit(onSubmitActivateModal, handleErrors)}
      loading={isPatchLoading}
      loadingText={t('loading')}
      confirmText={isSuccess ? t('modal.activate.continueButton') : t('modal.activate.downloadButton')}
    >
      <Message message={formatErrors() as string} type="error" />
      <Box height={{ min: '175px' }}>
        {
          <FormProvider {...methods}>
            <FormWrapper>
              <Controller
                name="connect_version"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    options={versions}
                    value={value}
                    label={t('modal.activate.selectLabel')}
                    required
                    hasError={isErrorFor('connect_version')}
                    onChange={onChange}
                    disabled={isPatchLoading}
                    loading={isFetchLoading}
                  />
                )}
              />
            </FormWrapper>
          </FormProvider>
        }
        {isSuccess && <LicenseKeyOutput command={connectJarCommand} />}
      </Box>
    </Modal>
  )
}
