import { LegacyText as Text } from '@cutover/react-ui'
import { Chart } from './chart'
import { Duration, formatDuration } from './format-duration'
import { NoDataAvailable } from './no-data-available'
import { Table } from './table'
import { Tile } from './tile'

type RunbookDuration = {
  id: number
  name: string
  duration: Duration
}

export type LongestDurationRunbooksConnectedProps = {
  name?: string
  values: RunbookDuration[]
  onEvent: (payload: any) => void
}

export function LongestDurationRunbooksConnected({ name, values, onEvent }: LongestDurationRunbooksConnectedProps) {
  const runbookDurations: RunbookDuration[] = values

  return (
    <Chart
      title={name}
      titleKey="dashboard:longestDurationRunbooks:title"
      render={({ translate }) => {
        if (values?.length === 0) {
          return <NoDataAvailable />
        }

        return (
          <LongestDurationRunbooks
            runbookNameTitle={translate('dashboard:longestDurationRunbooks:runbookNameTitle')}
            durationTitle={translate('dashboard:longestDurationRunbooks:durationTitle')}
            runbookDurations={runbookDurations}
            onRunbookSelected={id =>
              onEvent({
                runbook: {
                  id
                }
              })
            }
          />
        )
      }}
    />
  )
}

export type LongestDurationRunbooksProps = {
  runbookNameTitle?: string
  durationTitle?: string
  runbookDurations: RunbookDuration[]
  onRunbookSelected: (id: number) => void
}

export function LongestDurationRunbooks({
  runbookNameTitle = 'Runbook name',
  durationTitle = 'Duration',
  runbookDurations,
  onRunbookSelected
}: LongestDurationRunbooksProps) {
  const runbooks = runbookDurations.map(runbook => ({ ...runbook, duration: formatDuration(runbook.duration) }))

  return (
    <Tile variant="secondary" center={false}>
      <Table
        headers={[runbookNameTitle, durationTitle]}
        data={runbooks}
        renderRowCells={rd => [
          <Text title={rd.name} truncate>
            {rd.name}
          </Text>,
          rd.duration
        ]}
        onRowSelected={id => typeof id === 'number' && onRunbookSelected(id)}
      />
    </Tile>
  )
}
