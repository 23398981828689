import { useFormContext } from 'react-hook-form'

import { UserTeamMultiSelect } from 'main/components/shared/form/user-team-select'
import { useRunbookVersionUsers, useUsersLookupState } from 'main/recoil/data-access'
import { TasksBulkEditFormType } from './tasks-bulk-edit-panel'
import { RunbookTeamModel } from 'main/data-access'
import { useLanguage } from 'main/services/hooks'

export const AssignedUsersTeamsField = () => {
  const { watch, setValue } = useFormContext<TasksBulkEditFormType>()
  const { t } = useLanguage('tasks')

  const userIds = watch('user_ids')
  const runbookTeamIds = watch('runbook_team_ids')

  const teams = RunbookTeamModel.useGetAll()
  const users = useRunbookVersionUsers()
  const teamsLookup = RunbookTeamModel.useGetLookup()
  const usersLookup = useUsersLookupState()

  const assignedUsers = userIds?.length ? userIds.map(id => usersLookup[id]) : []
  const assignedTeams = runbookTeamIds?.length ? runbookTeamIds.map(id => teamsLookup[id]) : []
  const setUsersAndTeamsValue = (value?: { userIds?: number[]; runbookTeamIds?: number[] }) => {
    if (value) {
      setValue('user_ids', value.userIds, { shouldDirty: true, shouldTouch: true })
      setValue('runbook_team_ids', value.runbookTeamIds, { shouldDirty: true, shouldTouch: true })
    }
  }

  return (
    <UserTeamMultiSelect
      possibleUsersTeams={[...teams, ...users]}
      setUsersAndTeamsValue={setUsersAndTeamsValue}
      placeholder={t('editPanel.fields.assignedUsersTeams.placeholder')}
      label={t('editPanel.fields.assignedUsersTeams.label')}
      selectedUsers={assignedUsers}
      selectedTeams={assignedTeams}
    />
  )
}
