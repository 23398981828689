import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const TaskTypeRunbook100 = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="TaskTypeRunbook100" {...props}>
    <path d="M36 14v20a2 2 0 0 1-2 2H14a2 2 0 0 1-2-2v-1.5H8V34a6 6 0 0 0 6 6h20a6 6 0 0 0 6-6V14a6 6 0 0 0-6-6H14a6 6 0 0 0-6 6v1.5h4V14a2 2 0 0 1 2-2h20a2 2 0 0 1 2 2ZM6 18.5h8v4H6v-4Zm8 7H6v4h8v-4Z" fill="#000" />
  </StyledIcon>
))

TaskTypeRunbook100.displayName = 'TaskTypeRunbook100'

export { TaskTypeRunbook100 }
