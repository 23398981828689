import { DependencyList, EffectCallback, useEffect, useLayoutEffect } from 'react'

import { useInitialMount } from './use-initial-mount'

export const useUpdateEffect = (effect: EffectCallback, deps?: DependencyList | undefined) => {
  const isInitialMount = useInitialMount()

  useEffect(() => {
    if (isInitialMount) return

    return effect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
}

export const useLayoutUpdateEffect = (effect: EffectCallback, deps?: DependencyList | undefined) => {
  const isInitialMount = useInitialMount()

  useLayoutEffect(() => {
    if (isInitialMount) return

    return effect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
}
