// @ts-nocheck
import { ISelectedFilter, IGenericFilter } from 'app/Filters/FilterEntities'

export class ResourceFilterHelpers {
  public getFilterValue = (_genericFilter: IGenericFilter, _pathFilter: { key: string; value: string }): string => {
    return 'true'
  }

  public getValueForUrl = (selectedFilter: IGenericFilter, _newValue: string): string => {
    if (selectedFilter.urlTrueValueOverride) {
      return selectedFilter.urlTrueValueOverride
    }
    return selectedFilter.key.resourceId
  }

  public serialiseForUrl = (selectedFilter: ISelectedFilter): string => {
    if (selectedFilter.urlTrueValueOverride) {
      return `${selectedFilter.key.reference}=${selectedFilter.urlTrueValueOverride}`
    }
    return `${selectedFilter.key.reference}=${selectedFilter.key.resourceId}`
  }

  public serialiseForApi = (selectedFilter: ISelectedFilter): string => {
    return `${selectedFilter.key.reference}[]=${selectedFilter.key.resourceId}`
  }

  public checkPageAndPathFiltersMatch = (
    pageFilter: IGenericFilter,
    pathFilter: { key: string; value: string }
  ): boolean => {
    if (!pageFilter.key.resourceId) return false
    if (pageFilter.urlTrueValueOverride && pageFilter.urlTrueValueOverride === pathFilter.value) {
      return true
    }
    if (!pageFilter.urlTrueValueOverride && pageFilter.key.resourceId === pathFilter.value) {
      return true
    }
    return false
  }

  public getResourceFilterLookup = (filters: IGenericFilter[]): { [key: string]: string[] } => {
    let lookup = {}

    filters.forEach((filter: IGenericFilter) => {
      if (!lookup.hasOwnProperty(filter.key.reference)) {
        lookup[filter.key.reference] = []
      }

      lookup[filter.key.reference].push(filter.key.resourceId)
    })

    return lookup
  }
}
