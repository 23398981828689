import camelCaseKeys from 'camelcase-keys'
import { camelCase, snakeCase } from 'lodash'
import snakeCaseKeys from 'snakecase-keys'

import { CamelToSnakeCaseNested, SnakeToCamelCaseNested } from '@cutover/utility-types'

export function toCamelCase(data: string): string
export function toCamelCase<T extends object>(data: T): SnakeToCamelCaseNested<T>
export function toCamelCase<T extends undefined>(data: T): void
export function toCamelCase<T extends object | undefined>(data: T) {
  if (typeof data === 'string') {
    return camelCase(data)
  } else if (data) {
    return camelCaseKeys(data, { deep: true })
  }
}

export function toSnakeCase(data: string): string
export function toSnakeCase<T extends object>(data: T): CamelToSnakeCaseNested<T>
export function toSnakeCase<T extends undefined>(data: T): void
export function toSnakeCase<T extends object | undefined>(data: T) {
  if (typeof data === 'string') {
    return snakeCase(data)
  } else if (data) {
    return snakeCaseKeys(data, { deep: true })
  }
}
