import { useRef } from 'react'
import { useFocusRing } from '@react-aria/focus'
import { useListBox, useOption } from '@react-aria/listbox'
import { mergeProps } from '@react-aria/utils'
import { Item } from '@react-stately/collections'
import { useListState } from '@react-stately/list'
import { useMatch } from 'react-router-dom'

import { Box } from '@cutover/react-ui'
import { useSidebarNavigate } from '../hooks'
import { useSidebarNavContext } from '../nav-context'
import { NavItem, NavItemInfoText } from '../nav-item'
import { RunbookListRunbook as Runbook } from 'main/services/queries/types'
import { useRouting } from 'main/services/routing/hooks'

export const SectionSearchResults = ({
  runbooks,
  hasProcessedSearchResults
}: {
  runbooks: Runbook[]
  hasProcessedSearchResults: boolean
}) => {
  return (
    <Box
      css={`
        display: block;
      `}
      fill="vertical"
      data-testid="nav-search-results"
    >
      {runbooks.length === 0 ? (
        hasProcessedSearchResults ? (
          <NavItemInfoText level={0} css="padding: 0 4px;">
            No results found
          </NavItemInfoText>
        ) : (
          <NavItemInfoText level={0} css="padding: 0 4px;">
            Search runbooks, templates and snippets across all of your workspaces
          </NavItemInfoText>
        )
      ) : (
        <ResultsListBox>
          {runbooks.map(runbook => {
            // @ts-ignore
            return <Item runbook={runbook} textValue={runbook.name} key={runbook.id} />
          })}
        </ResultsListBox>
      )}
    </Box>
  )
}

const ResultsListBox = (props: any) => {
  const state = useListState({ selectionMode: 'single', ...props })
  const ref = useRef<HTMLDivElement>(null)
  const { listBoxProps } = useListBox({ ...props, 'aria-label': 'Search Results' }, state, ref)

  return (
    <Box {...(listBoxProps as any)} ref={ref}>
      {[...state.collection].map(item => (
        <NavItemSearchResult item={item} key={item.key} runbook={item.props?.runbook as Runbook} state={state} />
      ))}
    </Box>
  )
}

const NavItemSearchResult = ({ runbook, item, state }: { runbook: Runbook; item: any; state: any }) => {
  const navigate = useSidebarNavigate()
  const { leftSidebarSize, toggleLeftSidebar } = useSidebarNavContext()
  const match = useMatch({ path: '/app/:accountSlug/runbooks/:runbookId/current_version', end: false })
  const isActive = match?.params.runbookId === String(runbook.id) && match?.params.accountSlug === runbook.account_slug
  const { toRunbook } = useRouting()
  const link = toRunbook({ accountSlug: runbook.account_slug, runbookId: runbook.id })

  const ref = useRef<HTMLDivElement>(null)
  const { optionProps } = useOption({ key: item.key }, state, ref)
  const { isFocusVisible, focusProps } = useFocusRing()

  const handleClickResult = () => {
    if (!isActive) {
      navigate(link)
    } else if (leftSidebarSize === 'full') {
      toggleLeftSidebar()
    }
  }

  return (
    <NavItem
      {...(mergeProps(optionProps, focusProps) as any)}
      ref={ref}
      key={runbook.id}
      isFocusVisible={isFocusVisible}
      isActive={isActive}
      icon={runbook.is_template ? 'template' : runbook.template_type === 'snippet' ? 'snippet' : 'runbook'}
      label={runbook.name}
      onClick={() => handleClickResult()}
    />
  )
}
