// @ts-nocheck
import { container } from 'app/Config/IOC'
import { computed, observable, observe, action, Lambda } from 'mobx'
import {
  AlertSinglePresenter,
  CheckboxListPresenter,
  CheckboxPresenter,
  MultiSelectWithCapabilitiesPresenter,
  SelectPresenter,
  TextInputPresenter,
  ToasterService
} from 'Components/Molecules'
import { GenericFormPresenter } from '@logicroom/validator'
import { ModalPresenter } from 'Components/Atoms'
import { TMode } from 'Components/Templates'
import { RoleTypeViewPermissionPresenter } from 'app/Access/RoleTypes/RoleTypesPage/RoleTypeEdit/RoleTypeViewPermissionPresenter'
import { RoleTypeRepository } from 'app/Repositories/RoleType/RoleTypeRepository'
import { RoleTypesRepository } from 'app/Repositories/RoleType/RoleTypesRepository'
import { ConfigsRepository } from 'Configs/ConfigsRepository'
import {
  roleTypeDefaultViewPermissionHierarchy,
  roleTypeViewPermissionHierarchyMapping
} from './RoleTypeViewPermissionHierarchy'
import { Types } from 'Gateways/Service/Types'
import { ILanguageService } from 'Helpers/Language/ILanguageService'
import { toggleAnimateCutoverLogo } from 'Shared/Helpers/NavLogoAnimation'

interface ICapabilityOption {
  capability_type_id: number
  resource_type_id: number
  id?: number
  _destroy?: boolean
}

export class RoleTypeEditPresenter {
  private defaultViewPermissionHierarchy = roleTypeDefaultViewPermissionHierarchy()

  private viewPermissionHierarchyMapping = roleTypeViewPermissionHierarchyMapping()

  private roleTypeRepository: RoleTypeRepository = container.get(RoleTypeRepository)

  private roleTypesRepository: RoleTypesRepository = container.get(RoleTypesRepository)

  private toasterGateway: ToasterService = container.get(ToasterService)

  private configsRepository: ConfigsRepository = container.get(ConfigsRepository)

  private language: ILanguageService = container.get(Types.ILanguageService)

  @observable
  public roleTypes: any = []

  @observable
  public contextList: any = []

  @observable
  public isSubmitted = false

  @observable
  public triggerScroll = false

  @observable
  public roleTypeEditPanelClearing = false

  @observable
  public panelHeading = this.language.get('roleTypes:edit:title')

  @observable
  public optionsHeading = this.language.get('roleTypes:edit:options:title')

  public archiveTooltip: string = this.language.get('roleTypes:delete:tooltip')

  @observable
  public archiveModalPresenter: ModalPresenter = new ModalPresenter()

  @observable
  public confirmModalPresenter: ModalPresenter = new ModalPresenter()

  @observable
  public responseErrors: string[] = []

  @observable
  public capabilitiesPresenter: MultiSelectWithCapabilitiesPresenter = new MultiSelectWithCapabilitiesPresenter(
    'resource_type_id',
    'capability_type_id',
    1,
    this.language
  ).withShowLabelOnLoad()

  @observable
  public resourceTypes: { label: string; value: string }[] = []

  @observable
  public capabilityTypes: { label: string; value: string }[] = []

  @observable
  public editableFromContextPresenter: CheckboxPresenter = new CheckboxPresenter(
    this.language.get('roleTypes:edit:options:editableFromContext')
  )

  @observable
  public notifyPresenter: CheckboxPresenter = new CheckboxPresenter(
    this.language.get('roleTypes:edit:options:notfiesAdminsWhenAdded')
  )

  @observable
  public viewPermissionPresenter: RoleTypeViewPermissionPresenter = new RoleTypeViewPermissionPresenter()

  @observable
  public editPanelSaving: boolean = false

  @action
  public close = () => {
    this.roleTypeEditPanelClearing = true
  }

  @action
  public setTriggerScroll = (trigger: boolean) => {
    this.triggerScroll = trigger
  }

  @action
  private setFormDirty() {
    this.namePresenter.isDirty = true
    this.descriptionPresenter.isDirty = true
    this.editableFromContextPresenter.isDirty = true
    this.notifyPresenter.isDirty = true
  }

  @action
  public archive = async () => {
    toggleAnimateCutoverLogo(true)
    const response = await this.roleTypeRepository.archive()

    if (response.success) {
      this.roleTypesRepository.removeFromList(this.roleTypeRepository.roleTypeForEdit.id)
      this.roleTypeEditPanelClearing = true
      this.roleTypeRepository.resetRoleTypeToEdit()

      const title = this.language.get('common:notification:successTitle')
      const notification = this.language.get('roleTypes:delete:toaster:message')

      this.toasterGateway.pop(title, notification, 'success')
      this.roleTypeEditPanelClearing = false
      this.archiveModalPresenter.onClose()
    } else {
      this.responseErrors = response.errorMessages
    }
    toggleAnimateCutoverLogo(false)
  }

  public doToaster = () => {
    this.toasterGateway.pop(
      this.language.get('common:notification:successTitle'),
      this.language.get('roleTypes:edit.toaster.message'),
      'success'
    )
  }

  @action
  public save = async () => {
    toggleAnimateCutoverLogo(true)
    this.editPanelSaving = true
    this.isSubmitted = true
    this.editForm.serverErrors = []
    if (!this.checkFormIsValid) {
      this.alertPresenter.message = this.language.get('common:formInvalid')
      this.setFormDirty()
      this.setTriggerScroll(true)
      toggleAnimateCutoverLogo(false)
      this.editPanelSaving = false
      return
    }

    const viewType = this.viewPermissionTypeMapping(
      this.viewPermissionPresenter.viewPermissionOptionsPresenter.selectedOption
    )

    const roleType = {
      id: this.roleTypeRepository.roleTypeForEdit.id,
      contextTypeName: this.contextTypeNamePresenter.value,
      name: this.namePresenter.value,
      description: this.descriptionPresenter.value,
      capabilities: this.buildSaveCapabilitiesOptions(),
      viewPermissionType: viewType,
      notify: this.notifyPresenter.value,
      editableFromContext: this.editableFromContextPresenter.value,
      viewPermission:
        viewType === 'view_custom'
          ? this.getViewPermissionData(
              this.viewPermissionPresenter.globalViewsPresenter,
              this.viewPermissionPresenter.accessManagementPresenter,
              this.viewPermissionPresenter.accountViewsPresenter
            )
          : null
    }

    const response = await this.roleTypeRepository.saveRoleType(roleType)
    if (response.success) {
      this.reset()
      this.doToaster()
    } else {
      this.editForm.reset()
      this.setEditableRoleType(this.roleTypeRepository.roleTypeForEdit)
      this.editForm.serverErrors.push(...response.errorMessages)
      this.alertPresenter.withMessage(response.errorMessages[0])
      this.setTriggerScroll(true)
    }
    toggleAnimateCutoverLogo(false)
    this.editPanelSaving = false
  }

  public resetForm = () => {
    this.isSubmitted = false
    this.editForm.reset()

    let allFields = [
      this.namePresenter,
      this.descriptionPresenter,
      this.contextTypeNamePresenter,
      this.notifyPresenter,
      this.editableFromContextPresenter
    ]

    // TODO: Investigate why is valid is false after reset for touched fields
    allFields.forEach(fieldPresenter => {
      fieldPresenter.reset()
      fieldPresenter.isDirty = false
      fieldPresenter.isValid = true
    })

    this.alertPresenter.message = ''
  }

  public reset = () => {
    this.resetForm()
    this.setEditableRoleType(this.roleTypeRepository.roleTypeForEdit)
  }

  @action
  public archiveButtonCallback = () => {
    if (this.responseErrors.length > 0) {
      this.responseErrors = []
    }

    this.archiveModalPresenter.onOpen()
  }

  @observable
  public namePresenter = new TextInputPresenter()
    .withLabel(this.language.get('roleTypes:form:fieldName:label'))
    .isRequiredTrimWhiteSpace()

  @observable
  public descriptionPresenter = new TextInputPresenter()
    .withLabel(this.language.get('roleTypes:form:fieldDescription:label'))
    .isMultiline()

  @observable
  public contextTypeNamePresenter = new SelectPresenter(this.language)
    .isDisabled()
    .withLabel(this.language.get('roleTypes:form:fieldContext:label'))
    .isRequired()

  @observable
  public alertPresenter = new AlertSinglePresenter().withIconName('report-problem').withState('error')

  @observable
  public editForm = new GenericFormPresenter().addFormInput(this.namePresenter).addFormInput(this.descriptionPresenter)

  @computed
  public get mode(): TMode {
    if (this.roleTypeRepository.loading) return 'loading'
    if (this.roleTypeEditPanelClearing) return 'panel-closed'
    if (this.roleTypeRepository.roleTypeForEdit !== null) {
      return this.formIsDirty ? 'panel-open-confirm' : 'panel-open'
    }
    if (this.responseErrors.length > 0) return 'panel-open-archive'

    return 'panel-closed'
  }

  @action
  public editPanelAfterCloseFunction = () => {
    this.resetForm()
    this.roleTypeRepository.roleTypeForEdit = null
    this.roleTypeEditPanelClearing = false
  }

  @action
  public cancelArchiveCallback = () => {
    this.archiveModalPresenter.onClose()
  }

  public continueArchiveCallback = async () => {
    await this.archive()
  }

  @computed
  public get checkFormIsValid() {
    return this.editForm.isValid
  }

  @action
  private setEditableRoleType = roleType => {
    this.namePresenter.value = roleType.name || ''
    this.namePresenter.disabled = !this.canUpdateRoleType
    this.descriptionPresenter.value = roleType.description || ''
    this.descriptionPresenter.disabled = !this.canUpdateRoleType
    this.contextTypeNamePresenter.withOptions([{ label: roleType.contextTypeName, value: roleType.contextTypeName }])
    this.contextTypeNamePresenter.value = roleType.contextTypeName
    this.capabilitiesPresenter = new MultiSelectWithCapabilitiesPresenter(
      'resource_type_id',
      'capability_type_id',
      roleType.contextTypeId,
      this.language,
      !this.canUpdateRoleType
    )
      .withPlaceholderText(this.language.get('roleTypes:form:fieldCapabilities:label'))
      .withOptions(this.resourceTypes)
      .withCapabilities(this.capabilityTypes)
      .withSelectedOptions(roleType.capabilities)
      .withShowLabelOnLoad()
    this.notifyPresenter.value = roleType.notify
    this.notifyPresenter.disabled = !this.canUpdateRoleType
    this.editableFromContextPresenter.value = roleType.editableFromContext
    this.editableFromContextPresenter.disabled = !this.canUpdateRoleType
    this.viewPermissionPresenter.load(
      this.defaultViewPermissionHierarchy,
      this.viewPermissionMapping(roleType.viewPermission),
      this.viewPermissionTypeMapping(roleType.viewPermissionType),
      !this.canUpdateRoleType
    )
  }

  @action
  private clearEditableRoleType = () => {
    this.namePresenter.value = ''
    this.descriptionPresenter.value = ''
    this.notifyPresenter.reset()
    this.editableFromContextPresenter.reset()
  }

  constructor() {
    this.configsRepository.setResourceContexts()
    this.resourceTypes = this.configsRepository.getConfigAsLabelValue('resourceTypes')
    this.capabilityTypes = this.configsRepository.getConfigAsLabelValue('capabilityTypes')
    this.setupDisposer()
  }

  public clear = () => {
    this.clearDisposers()
    this.roleTypeRepository.clear()
  }

  public clearDisposers = () => {
    if (this.disposers.length > 0) {
      this.disposers.forEach(disposer => {
        disposer()
      })
    }
  }

  @observable
  public disposers: Lambda[] = []

  @action
  public setupDisposer = () => {
    if (this.disposers.length > 0) return
    const roleTypeEditChangeDisposer = observe(this.roleTypeRepository, 'roleTypeForEdit', () => {
      if (this.roleTypeRepository.roleTypeForEdit) {
        this.setEditableRoleType(this.roleTypeRepository.roleTypeForEdit)
        if (this.roleTypeRepository.roleTypeForEdit.viewPermissionType !== 'view_custom')
          this.viewPermissionPresenter.show = false
      } else {
        this.clearEditableRoleType()
      }
    })

    this.disposers.push(roleTypeEditChangeDisposer)
  }

  @computed
  public get formIsDirty(): boolean {
    const name = this.namePresenter.isDirty
    const description = this.descriptionPresenter.isDirty
    const notify = this.notifyPresenter.isDirty
    const editableFromContext = this.editableFromContextPresenter.isDirty
    const viewPermissionPresenter = this.viewPermissionPresenter.isDirty
    const capabilityPresenter = this.capabilitiesPresenter.isDirty

    return name || description || notify || editableFromContext || viewPermissionPresenter || capabilityPresenter
  }

  @computed
  public get archiveConfirmName(): string {
    if (this.roleTypeRepository.roleTypeForEdit) {
      return this.roleTypeRepository.roleTypeForEdit.name
    }

    return ''
  }

  private viewPermissionMapping = (viewPermission: string) => {
    if (viewPermission === null) return []
    let parsedViewPermission = viewPermission ? JSON.parse(viewPermission) : []
    return parsedViewPermission.map((viewPermission: string) => {
      return this.viewPermissionHierarchyMapping[viewPermission].name
    })
  }

  private getViewPermissionData = (
    globalViewsPresenter: CheckboxListPresenter,
    accessManagementPresenter: CheckboxListPresenter,
    accountViewsPresenter: CheckboxListPresenter
  ) => {
    const viewPresenters = [globalViewsPresenter, accessManagementPresenter, accountViewsPresenter]
    let checkboxLabels = []

    viewPresenters.forEach(viewPresenter => {
      if (viewPresenter.childrenCheckboxPresenters.filter(child => child.value).length === 0) return
      if (viewPresenter.parentCheckboxPresenter.value) {
        checkboxLabels.push(viewPresenter.parentCheckboxPresenter.label)
      }
      viewPresenter.childrenCheckboxPresenters.forEach(childrenCheckbox => {
        if (childrenCheckbox.value) {
          checkboxLabels.push(childrenCheckbox.label)
        }
      })
    })
    let viewPermissionData = checkboxLabels.map(checkboxLabel => {
      return this.viewPermissionHierarchyMapping[checkboxLabel].name
    })
    let viewPermissionDataString = JSON.stringify(viewPermissionData)
    return viewPermissionDataString
  }

  private viewPermissionTypeMapping = (viewPermissionTypes: string) => {
    switch (viewPermissionTypes) {
      case 'view_none':
        return 'None'
      case 'view_all':
        return 'All'
      case 'view_custom':
        return 'Custom...'
      case 'None':
        return 'view_none'
      case 'All':
        return 'view_all'
      case 'Custom...':
        return 'view_custom'
    }
  }

  @computed
  public get canArchiveRoleType(): boolean {
    return this.roleTypeRepository.can('destroy')
  }

  @computed
  public get canUpdateRoleType(): boolean {
    return this.roleTypeRepository.can('update')
  }

  private buildSaveCapabilitiesOptions = (): ICapabilityOption[] => {
    const newValues: ICapabilityOption[] = this.capabilitiesPresenter.values.slice() as any[]
    const oldValues: ICapabilityOption[] = this.roleTypeRepository.roleTypeForEdit.capabilities.slice() as any[]

    const newArray: ICapabilityOption[] = newValues.map(option => {
      if (option.id) return option
      const oldOption: ICapabilityOption = oldValues.find(
        value => option.capability_type_id && value.resource_type_id === option.resource_type_id
      )
      if (oldOption) {
        return {
          ...option,
          id: oldOption.id
        }
      }
      return option
    })

    oldValues.forEach(option => {
      const deleteOption = newValues.find(
        value =>
          value.capability_type_id === option.capability_type_id && value.resource_type_id === option.resource_type_id
      )
      if (!deleteOption) {
        newArray.push({
          _destroy: true,
          capability_type_id: option.capability_type_id,
          resource_type_id: option.resource_type_id,
          id: option.id
        })
      }
    })

    return newArray
  }
}
