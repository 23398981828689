export interface ApiResponse<T> {
  isLoading: boolean
  isError: boolean
  error: Error | null | undefined
  isSuccess: boolean
  data: T | null | undefined
}

export enum HttpMethod {
  Get = 'get',
  Post = 'post',
  Put = 'put',
  Patch = 'patch',
  Delete = 'delete'
}

export type RequestParams = Record<string, string | string[] | number | number[] | boolean | null | undefined>

export type RequestConfiguration = {
  url?: string
  method?: HttpMethod
  baseURL?: string
  headers?: Record<string, string>
  params?: RequestParams
  config?: {
    responseType?: 'arraybuffer'
  }
  // @deprecated This option will be removed in favour of standard error handling from axios
  errorResponseProperty?: 'errors' | null
}

export type RequestOptions<TData> = RequestConfiguration & {
  data?: TData | null | undefined
}

export type Response<TData> = {
  data?: TData | null | undefined
  status: number
  statusText?: string
  headers?: Record<string, unknown>
}

export type RequestWithoutBodyOptions = {
  url: string
  params?: RequestParams
  headers?: Record<string, string>
  config?: {
    responseType?: 'arraybuffer'
  }
  // @deprecated This option will be removed in favour of standard error handling from axios
  errorResponseProperty?: 'errors' | null
}

export type RequestWithBodyOptions<TRequestData> = RequestWithoutBodyOptions & {
  data?: TRequestData
}

export type ApiClient = {
  request: <TRequestData, TResponseData>(options: RequestOptions<TRequestData>) => Promise<Response<TResponseData>>
  get: <TResponseData>(options: RequestWithoutBodyOptions) => Promise<Response<TResponseData> & { data: TResponseData }>
  post: <TRequestData, TResponseData>(options: RequestWithBodyOptions<TRequestData>) => Promise<Response<TResponseData>>
  put: <TRequestData, TResponseData>(options: RequestWithBodyOptions<TRequestData>) => Promise<Response<TResponseData>>
  patch: <TRequestData, TResponseData>(
    options: RequestWithBodyOptions<TRequestData>
  ) => Promise<Response<TResponseData>>
  delete: <TResponseData>(options: RequestWithoutBodyOptions) => Promise<Response<TResponseData>>
}

export interface CreateApiClientOptions {
  defaultConfiguration?: RequestConfiguration
  configuration?: () => Promise<RequestConfiguration>
  requestAdapter: <TRequestData, TResponseData>(
    options: RequestOptions<TRequestData>
  ) => Promise<Response<TResponseData>>
}
