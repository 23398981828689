import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const RowDelete = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="RowDelete" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M6 30h3v-4.5h30V30h3V14a8 8 0 0 0-8-8H14a8 8 0 0 0-8 8v16Zm16.5-7.5H9V14a5 5 0 0 1 5-5h8.5v13.5Zm3-13.5H34a5 5 0 0 1 5 5v8.5H25.5V9Z" fill="#000" /><path d="m30.718 41.596-2.122 2.122L24 39.12l-4.596 4.596-2.122-2.12L21.88 37l-4.597-4.596 2.122-2.122L24 34.88l4.596-4.596 2.122 2.12L26.12 37l4.597 4.596Z" fill="#000" />
  </StyledIcon>
))

RowDelete.displayName = 'RowDelete'

export { RowDelete }
