import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Timezones = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Timezones" {...props}>
    <path d="M8.382 36.495c3.424 4.274 8.566 7.112 14.38 7.467C20.5 41 18.781 37.69 18 33c-.5-3-.576-3.447-.576-3.447A35.761 35.761 0 0 1 17 24c0-1.956.151-3.821.424-5.553 2.06.358 4.272.553 6.576.553 1.449 0 2.862-.077 4.223-.223 0 0 3.16-.402 5.287-.978 2.01-.544 3.811-1.255 5.331-2.096.75 1.34 1.325 2.79 1.692 4.323a15.065 15.065 0 0 1 3.43 2.735C43.322 12.293 34.63 4 24 4c-6.319 0-11.953 2.93-15.618 7.505A19.908 19.908 0 0 0 4 24a19.908 19.908 0 0 0 4.382 12.495Zm2.478-1.708c.11-.059.223-.117.34-.175 1.13-.565 2.443-1.063 3.904-1.469.68 2.643 1.646 4.943 2.816 6.738a17.04 17.04 0 0 1-7.06-5.094Zm22.036-19.93c-.68-2.643-1.646-4.943-2.816-6.738a17.04 17.04 0 0 1 7.06 5.094c-.11.059-.223.117-.34.175-1.13.565-2.443 1.063-3.904 1.469Zm-2.93.65A35.537 35.537 0 0 1 24 16c-2.113 0-4.119-.177-5.966-.492.425-1.623.962-3.075 1.578-4.308C21.262 7.9 22.987 7 24 7s2.738.9 4.388 4.2c.616 1.233 1.153 2.684 1.578 4.308Zm-14.862-.65c-1.461-.406-2.775-.904-3.905-1.47a11.982 11.982 0 0 1-.34-.174A17.04 17.04 0 0 1 17.92 8.12c-1.17 1.795-2.136 4.095-2.816 6.738Zm-5.945.846c1.52.841 3.322 1.552 5.33 2.096A38.684 38.684 0 0 0 14 24c0 2.164.172 4.248.49 6.2-2.01.545-3.811 1.256-5.331 2.097A16.923 16.923 0 0 1 7 24c0-3.013.784-5.843 2.159-8.297Z" fill="#000" /><path d="M34.5 32.379V27h-3v6.621l3.94 3.94 2.12-2.122-3.06-3.06Z" fill="#000" /><path d="M41.738 24.775A11.959 11.959 0 0 0 32 21.04a11.94 11.94 0 0 0-3 .642C24.34 23.33 21 27.775 21 33c0 6.627 5.373 12 12 12 5.225 0 9.67-3.34 11.317-8A11.94 11.94 0 0 0 45 33a12.11 12.11 0 0 0-.155-1.932 11.916 11.916 0 0 0-1.196-3.604 12.03 12.03 0 0 0-1.911-2.69ZM24 33a9.003 9.003 0 0 1 5.787-8.41A8.98 8.98 0 0 1 33 24a9 9 0 0 1 9 9 9 9 0 1 1-18 0Z" fill="#000" />
  </StyledIcon>
))

Timezones.displayName = 'Timezones'

export { Timezones }
