import { SubHeaderButton, SubHeaderButtonGroup } from 'main/components/layout/shared/sub-header/sub-header-button'
import { useClearFilterParam, useFilter, useSetFilterParams } from 'main/components/shared/filter/filter-provider'
import { useLanguage } from 'main/services/hooks'

export const StatusToggle = () => {
  const { t } = useLanguage('myWork', { keyPrefix: 'statusToggleOptions' })
  const setFilterParams = useSetFilterParams()
  const clearFilterParam = useClearFilterParam()
  const viewingStatus = useFilter<boolean | string>('active')

  const handleClickOption = (status?: string) => {
    // clicked the single active status in toggle (or clicked all and not viewing a status); don't do anything
    if ((viewingStatus && viewingStatus === status) || (!viewingStatus && !status)) {
      return
    }

    if (status) {
      setFilterParams({ active: [status] })
    } else {
      clearFilterParam('active')
    }
  }

  return (
    <SubHeaderButtonGroup>
      <SubHeaderButton label={t('all')} active={!viewingStatus} onClick={() => handleClickOption()} />
      <SubHeaderButton
        label={t('active')}
        active={viewingStatus === 'true'}
        onClick={() => handleClickOption('true')}
      />
      <SubHeaderButton
        label={t('upcoming')}
        active={viewingStatus === 'false'}
        onClick={() => handleClickOption('false')}
      />
    </SubHeaderButtonGroup>
  )
}
