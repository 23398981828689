import { MouseEventHandler } from 'react'

import { Box, Icon, IconName, Text } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'

export type CreateOrLinkChangeReequestMenuProps = {
  onClickLink: () => void
  onClickCreate: () => void
}

export const CreateOrLinkChangeRequestMenu = ({ onClickLink, onClickCreate }: CreateOrLinkChangeReequestMenuProps) => {
  const { t } = useLanguage('changeRequests', { keyPrefix: 'modal.menuItem' })

  return (
    <Box direction="row" gap="large">
      <Box basis="50%">
        <ModalMenuCardButton
          title={t('title', { context: 'link' })}
          text={t('text', { context: 'link' })}
          onClick={onClickLink}
          icon="link"
        />
      </Box>
      <Box basis="50%">
        <ModalMenuCardButton
          title={t('title', { context: 'create' })}
          text={t('text', { context: 'create' })}
          onClick={onClickCreate}
          icon="add"
        />
      </Box>
    </Box>
  )
}

type ModalMenuCardButtonProps = {
  icon: IconName
  title: string
  text: string
  onClick?: MouseEventHandler
}

// TODO: these should use the BoxItem (new design system) react-ui component when available.
const ModalMenuCardButton = ({ icon, title, text, onClick }: ModalMenuCardButtonProps) => {
  return (
    <Box
      role="button"
      gap="small"
      alignContent="center"
      pad="small"
      background="bg-1"
      onClick={onClick}
      aria-label={title}
      width="100%"
      height="100%"
      hoverIndicator="bg-2"
      css={`
        border-radius: 16px;
        cursor: pointer;
      `}
    >
      <Box
        height="xxsmall"
        width="xxsmall"
        justify="center"
        alignSelf="center"
        align="center"
        css={`
          // Color of the change request runbook type that is not very easily accessible here.
          // In the new design system this will be a secondary icon style (light blue background,
          // blue icon), so not concerned about hard coding this for now.
          background-color: rgba(44, 228, 157, 1);
          min-height: 40px;
          min-width: 40px;
          border-radius: 100%;
        `}
      >
        <Icon icon={icon} size="medium" color="white" />
      </Box>
      <Text textAlign="center" weight={500} size="medium" color="text-default">
        {title}
      </Text>
      <Text textAlign="center" color="text-light" size="small">
        {text}
      </Text>
    </Box>
  )
}
