import { useMutation } from 'react-query'

import { apiClient } from 'main/services/api'

export type ValidateMergePayload = {
  runbook_ids: number[]
  target_runbook_id?: number | string | null
  task_parent_id_to_link_to?: number
}

type RunbookMergeResponse = {
  result: 'success' | 'warning'
  total: number
}

type GenericMergeResult = {
  data: { [key: string]: number }
  result: 'success' | 'warning' // errors are returned on a 422
  total: number
}

export type ValidateMergeResponse = {
  runbooks: RunbookMergeResponse
  streams: GenericMergeResult
  tasks: GenericMergeResult
  teams: GenericMergeResult
}

export const validateMerge = async (payload: ValidateMergePayload) => {
  const { data } = await apiClient.patch<ValidateMergePayload, ValidateMergeResponse>({
    url: 'runbooks/validate_merge',
    data: payload
  })

  return data as ValidateMergeResponse
}

export const useValidateMerge = () => {
  return useMutation<ValidateMergeResponse, Error, ValidateMergePayload>('validate-merge', validateMerge)
}
