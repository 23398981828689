import { active } from './active'
import { assigned } from './assigned'
import { completionType } from './completion-type'
import { critical } from './critical'
import { criticalToHere } from './critical-to-here'
import { custom } from './custom'
import { dateFrom } from './date-from'
import { dateTo } from './date-to'
import { endRequirements } from './end-requirements'
import { RunbookFilterType, TaskFilterContext, TaskFilterFunction } from './filters'
import { fixedEnd } from './fixed-end'
import { fixedStart } from './fixed-start'
import { hasComments } from './has-comments'
import { hasErrors } from './has-errors'
import { hasPredecessors } from './has-predecessors'
import { hasSuccessors } from './has-successors'
import { late } from './late'
import { level } from './level'
import { milestone } from './milestone'
import { myTasks } from './my-tasks'
import { overRunning } from './over-running'
import { predecessorsToHere } from './predecessors-to-here'
import { query } from './query'
import { runbookComponent } from './runbook-component'
import { stage } from './stage'
import { startNotification } from './start-notification'
import { startRequirements } from './start-requirements'
import { startWithin } from './start-within'
import { stream } from './stream'
import { team } from './team'
import { type } from './type'
import { user } from './user'
import { TaskListTask } from 'main/services/queries/types'

const filterMap: { [key: string]: TaskFilterFunction } = {
  a: assigned,
  at: active,
  c: hasComments,
  critical: critical,
  critical_to_here: criticalToHere,
  ct: completionType,
  dd: startWithin,
  df: dateFrom,
  dt: dateTo,
  er: endRequirements,
  f: custom,
  fe: fixedEnd,
  fs: fixedStart,
  he: hasErrors,
  hp: hasPredecessors,
  hs: hasSuccessors,
  l: late,
  lv: level,
  m: milestone,
  mt: myTasks,
  or: overRunning,
  predecessors_to_here: predecessorsToHere,
  q: query,
  rbc: runbookComponent,
  sn: startNotification,
  sr: startRequirements,
  stage: stage,
  stream: stream,
  team: team,
  type: type,
  user: user
}

/**
 * Builds an array with only the functions that match
 * the provided filters
 * @param filters The filter payload
 * @returns an array of filter functions
 */
export const buildFilterPipeline = (filters: RunbookFilterType): TaskFilterFunction[] => {
  let functions = []
  for (let key in filters) {
    if (typeof filterMap[key] === 'function') {
      functions.push(filterMap[key])
    }
  }
  return functions
}

/**
 * Apply the filter pipeline to the provided tasks
 * and returns only the ones that match the given filters
 * @param tasks The tasks to filter
 * @param filters The filters to apply
 * @param context Optional context data required by some filters
 * @returns
 */
export const applyFilterPipeline = (
  tasks: TaskListTask[],
  filters: RunbookFilterType,
  context: TaskFilterContext = {}
): TaskListTask[] => {
  const { f: cfFilters = {}, ...restFilters } = filters
  const filterFunctions = buildFilterPipeline(restFilters)

  if (tasks.length > 0 && filterFunctions.length > 0) {
    for (let i = 0; i < filterFunctions.length; i++) {
      tasks = filterFunctions[i].call(null, tasks, filters, context)
      if (tasks.length === 0) break
    }
  }

  /**
   * Custom field filters are bundled as an object in the 'f' filter, keyed by custom field ID.
   * e.g. f: { 101: 'query', 102: 'another query' }
   *
   * In order to handle each of those filters as a separate group, such that they are
   * applied as a logical AND, the 'f' object entries are applied independently.
   * e.g. { 101: 'query' } AND { 102: 'another query' }
   */
  Object.entries(cfFilters).forEach(([key, value]) => {
    if (tasks.length > 0) {
      tasks = custom(tasks, { f: { [key]: value } }, context)
    }
  })

  return tasks
}
