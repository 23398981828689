// @ts-nocheck
import { ButtonPresenter } from 'Components/Molecules'
import { container } from 'app/Config/IOC'
import { observable } from 'mobx'
import { ILanguageService } from 'Helpers/Language/ILanguageService'
import { Types } from 'Gateways/Service/Types'

export class EditPanelDirtyConfirmModalPresenter {
  public language: ILanguageService = container.get(Types.ILanguageService)

  private onCancel: () => void

  private onReset: () => void

  private onSave: () => void

  constructor(onCancel: () => void, onReset: () => void, onSave: () => void) {
    this.onCancel = onCancel
    this.onReset = onReset
    this.onSave = onSave
  }

  @observable
  public cancelButtonPresenter = new ButtonPresenter(
    this.language.get('common:editDirtyModal:cancelButton'),
    // @ts-ignore
    this.onCancel
  )
    .withDisplayStyle('secondary')
    .withIconName('dnd-forwardslash')
    .withTextTransform('Uppercase')

  @observable
  public resetButtonPresenter = new ButtonPresenter(
    this.language.get('common:editDirtyModal:resetButton'),
    // @ts-ignore
    this.onReset
  )
    .withSmallText(this.language.get('common:editDirtyModal:resetButtonSmall'))
    .withDisplayStyle('secondary')
    .withIconName('undo')
    .withTextTransform('Uppercase')

  @observable
  // @ts-ignore
  public saveButtonPresenter = new ButtonPresenter(this.language.get('common:editDirtyModal:saveButton'), this.onSave)
    .withIconName('arrow-forward')
    .withTextTransform('Uppercase')
}
