export const defaultColors = [
  'rgb(45,85,195)',
  'rgb(230,124,80)',
  'rgb(80,230,168)',
  'rgb(81,80,230)',
  'rgb(123,230,80)',
  'rgb(230,80,167)',
  'rgb(80,211,230)',
  'rgb(230,205,80)',
  'rgb(162,80,230)',
  'rgb(80,230,118)',
  'rgb(230,80,86)',
  'rgb(80,130,230)',
  'rgb(173,230,80)',
  'rgb(230,80,217)',
  'rgb(80,230,199)',
  'rgb(230,155,80)',
  'rgb(112,80,230)',
  'rgb(92,230,80)',
  'rgb(230,80,136)',
  'rgb(80,180,230)',
  'rgb(223,230,80)',
  'rgb(193,80,230)',
  'rgb(80,230,149)',
  'rgb(230,105,80)',
  'rgb(80,99,230)',
  'rgb(142,230,80)',
  'rgb(230,80,186)',
  'rgb(80,230,230)',
  'rgb(230,186,80)',
  'rgb(143,80,230)',
  'rgb(80,230,99)'
]
