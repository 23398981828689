import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const History = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="History" {...props}>
    <path d="M39.5 24c0 8.284-6.716 15-15 15-5.129 0-9.657-2.574-12.362-6.501l-2.472 1.7C12.912 38.91 18.346 42 24.5 42c9.941 0 18-8.059 18-18s-8.059-18-18-18C15.407 6 7.89 12.742 6.672 21.5H2l6 6 6-6H9.707C10.897 14.406 17.067 9 24.5 9c8.284 0 15 6.716 15 15ZM26 11.5h-3V24c0 .502.25.97.668 1.248l8.748 5.832 1.664-2.496L26 23.197V11.5Z" fill="#000" />
  </StyledIcon>
))

History.displayName = 'History'

export { History }
