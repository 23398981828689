// @ts-nocheck

import styled from 'styled-components'
import { px2rem } from 'app/Styles/Variables'

const Form = styled.div`
  width: 100%;
  margin: ${px2rem(8)} auto;
  box-sizing: border-box;
  width: ${px2rem(320)};
  text-align: 'center';
`

interface IFormWrapperProps {
  extraStyles?: CSSProperties
  children: ReactNode
}

export const FormWrapper = (props: IFormWrapperProps) => <Form style={props.extraStyles}>{props.children}</Form>
