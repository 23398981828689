import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const BrandIpo = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 100 101" a11yTitle="BrandIpo" {...props}>
    <g clipPath="url(#a)" fill="#fff"><path d="M100 .333H53l47 47v-47Zm-100 100h47l-47-47v47Z" /><path d="M0 .333v47l47-47H0Zm100 100v-47l-47 47h47Zm-35-50c0 8.284-6.716 15-15 15-8.284 0-15-6.716-15-15 0-8.284 6.716-15 15-15 8.284 0 15 6.716 15 15Z" opacity=".5" /></g>
  </StyledIcon>
))

BrandIpo.displayName = 'BrandIpo'

export { BrandIpo }
