import { nivoTheme } from './nivo-theme'
import { colors } from '../../utils'

// NOTE: Nivo does not type the props for layers; we could do it manually at some point.

/**
 * A higher order function to create the layer function in order to capture the string we want to display as
 * the label
 */
export const createChartYAxisLabel: (label: string) => (props: any) => JSX.Element = (label: string) => {
  return () => {
    return (
      <text
        transform={'translate(-30,-20)'}
        style={{
          fontFamily: nivoTheme?.fontFamily,
          fontSize: `${nivoTheme?.fontSize}px`,
          fill: colors.text,
          fontWeight: 400
        }}
      >
        {label}
      </text>
    )
  }
}
