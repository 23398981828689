import { useFieldArray, UseFormReturn } from 'react-hook-form'
import styled from 'styled-components'

import { Box, Message, Text, UserListItem, UserListItemSelectedRole } from '@cutover/react-ui'
import { TeamList } from './team-list'
import { RunbookUserFormType, Stream } from '../types'
import { RunbookUser } from '../use-runbook-user'
import { useLanguage } from 'main/services/hooks'
import { getAvailableRoleItems } from '../shared'

type UserFormProps = {
  user: RunbookUser
  allStreams: Stream[]
  methods: UseFormReturn<RunbookUserFormType>
  isDisabled: boolean
  errorMessage: string | null
  openReassignModal: () => void
  templateType?: string
}

export function UserForm({
  user,
  allStreams,
  methods,
  isDisabled,
  errorMessage,
  openReassignModal,
  templateType
}: UserFormProps) {
  const { t } = useLanguage()

  const { fields: teams, remove } = useFieldArray<RunbookUserFormType, 'teams'>({
    control: methods.control,
    name: 'teams',
    keyName: 'key' as 'id'
  })

  // const { fields: streams } = useFieldArray<RunbookUserFormType, 'streams'>({
  //   control: methods.control,
  //   name: 'streams'
  // })

  //defined in user-form AND user-list
  const onRoleSelect = (roleItem: UserListItemSelectedRole) => {
    methods.setValue('role', roleItem.role)

    const streams = roleItem.streamIds

    if (roleItem.streamIds) {
      methods.setValue('streams', streams, {
        shouldDirty: true
      })
    } else {
      methods.setValue('streams', [], {
        shouldDirty: true
      })
    }
  }

  return (
    <Box
      as="form"
      aria-label="form" //because a11yTitle had no effect here?
      css={`
        flex-grow: 1;
        display: flex;
        flex-direction: column;
      `}
    >
      {errorMessage && (
        <Box margin={{ bottom: '16px' }}>
          <Message type="error" message={errorMessage} />
        </Box>
      )}

      <UserListItem
        size="medium"
        id={user.id}
        name={user.name}
        online={user.online}
        color={user.color}
        status={user.status}
        disabled={isDisabled || !user.canUpdate}
        role={
          {
            role: user.role,
            ...(user.streamIds && user.streamIds.length && { streamIds: user.streamIds })
          } as UserListItemSelectedRole
        }
        availableRoles={getAvailableRoleItems(teams.length > 0, templateType, allStreams)}
        onRoleSelect={onRoleSelect}
      />

      <FullHeightBox margin={{ top: '16px' }}>
        <Text as="h4" size="small" weight={500} color="text-light">
          {t('runbook:peoplePanel:teams:title')}
        </Text>
        {teams?.length > 0 ? (
          <TeamList
            teams={teams}
            remove={remove}
            isReadonly={!user.canUpdate || isDisabled}
            methods={methods}
            openReassignModal={openReassignModal}
          />
        ) : (
          <Box align="center">
            <Text>{t('runbook:peoplePanel:users:noTeams')}</Text>
          </Box>
        )}
      </FullHeightBox>
    </Box>
  )
}

const FullHeightBox = styled(Box)`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`
