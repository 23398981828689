import { css } from 'styled-components/macro'

import { themeColor } from '../theme'

export const baseTabButtonStyles = css`
  font-size: 15px;
  padding: 0 4px;
  line-height: 34px;
  background-color: ${themeColor('bg')};
`

export const tabActiveButtonStyles = css`
  font-weight: 600;
  letter-spacing: -0.15px;
  color: ${themeColor('primary')};
  border-bottom: 4px solid ${themeColor('primary')};
`

export const tabInactiveButtonStyles = css`
  color: ${themeColor('text-light')};
  border-bottom: 4px solid ${themeColor('bg')};

  &:hover {
    color: ${themeColor('text')};
    svg {
      fill: ${themeColor('text')};
    }
  }
`
