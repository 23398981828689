import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const CheckSolid = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="CheckSolid" {...props}>
    <path d="m5.066 24.539 5.657-5.657 8.486 8.485L37.593 8.982l5.657 5.657-24.04 24.042L5.066 24.539Z" fill="#000" />
  </StyledIcon>
))

CheckSolid.displayName = 'CheckSolid'

export { CheckSolid }
