// @ts-nocheck
import { injectable, inject } from 'inversify'
import { observable, action } from 'mobx'
import { Types } from 'Gateways/Service/Types'
import { IServiceGateway } from 'Gateways/Service/IServiceGateway'

@injectable()
export class RunbookTeamRepository {
  @inject(Types.IServiceGateway)
  public serviceGateway: IServiceGateway

  @observable
  public loading: boolean = false

  @action
  public createRunbookTeam = async ({
    centralTeamId = null,
    runbookId,
    runbookVersionId,
    name,
    linked = false,
    userIds = null
  }: {
    centralTeamId?: number
    runbookId: number
    runbookVersionId: number
    name: string
    linked?: boolean
    userIds?: number[]
  }) => {
    const payload = {
      runbook_team: {
        team: {
          id: centralTeamId,
          name
        },
        linked,
        import_users: !linked,
        user_ids: userIds
      }
    }

    const apiUrl = `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/runbook_teams`

    this.loading = true
    const response = await this.serviceGateway.post(apiUrl, payload)
    this.loading = false
    return response
  }
}
