import { Dispatch, SetStateAction } from 'react'

import { Box, Modal, SanitizedString, useNotify } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { queryClient } from 'main/query-client'
import { useDeleteSystemParametersMutation } from 'main/services/queries/use-system-parameters-query'
import { QueryKeys } from 'main/services/queries/query-keys'

type DeleteSystemParameterModalType = {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  systemParameterId: number
  onSubmit: () => void
}

export const DeleteSystemParameterModal = ({
  open,
  setOpen,
  systemParameterId,
  onSubmit
}: DeleteSystemParameterModalType) => {
  const notify = useNotify()
  const { t } = useLanguage('systemParameters')
  const deleteMutation = useDeleteSystemParametersMutation()
  const { isLoading: isDeleteLoading } = deleteMutation

  const onSubmitDelete = async () => {
    deleteMutation.mutate(
      { id: systemParameterId },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([QueryKeys.SystemParameters])
          notify.success(t('toasters.success'))
          closeForm()
          onSubmit()
        },
        onError: () => {
          notify.error(t('toasters.genericError'))
        }
      }
    )
  }
  const closeForm = () => {
    setOpen(false)
  }

  return (
    <Modal
      title={t('modals.delete.title')}
      open={open}
      onClose={closeForm}
      onClickConfirm={onSubmitDelete}
      loading={isDeleteLoading}
      confirmText={t('modals.delete.confirmText')}
      confirmIcon="trash-o"
      loadingText={t('loading')}
    >
      <Box>
        <SanitizedString input={t('modals.delete.body')} />
      </Box>
    </Modal>
  )
}
