import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Bracket = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Bracket" {...props}>
    <path d="M16 6h-2a8 8 0 0 0-8 8v20a8 8 0 0 0 8 8h2v-3h-2a5 5 0 0 1-5-5V14a5 5 0 0 1 5-5h2V6Zm16 33h2a5 5 0 0 0 5-5V14a5 5 0 0 0-5-5h-2V6h2a8 8 0 0 1 8 8v20a8 8 0 0 1-8 8h-2v-3Z" fill="#000" />
  </StyledIcon>
))

Bracket.displayName = 'Bracket'

export { Bracket }
