import { Editor, Range, Element as SlateElement, Transforms } from 'slate'

import { BulletedListElement, CustomElementTypes, NumberedListElement } from '../text-editor-types'
import { getParentBlock } from '../utils'

// Note: due to nature of being used within forms and other elements, we only have a single level of heading
const SHORTCUTS: Record<string, CustomElementTypes> = {
  '-': 'list-item',
  '1.': 'list-item',
  '>': 'quote',
  '#': 'heading',
  '##': 'heading',
  '###': 'heading',
  '####': 'heading',
  '#####': 'heading',
  '######': 'heading'
}

export const withAutoFormat = (editor: Editor) => {
  const { insertText } = editor

  editor.insertText = text => {
    const { selection } = editor
    const currentBlock = getParentBlock(editor)
    const currentBlockType = currentBlock ? currentBlock.type : ''
    const allowAutoFormat = currentBlockType === 'paragraph'
    if (allowAutoFormat && text.endsWith(' ') && selection && Range.isCollapsed(selection)) {
      const { anchor } = selection
      const block = Editor.above(editor, {
        match: n => SlateElement.isElement(n) && Editor.isBlock(editor, n)
      })
      const path = block ? block[1] : []
      const start = Editor.start(editor, path)
      const range = { anchor, focus: start }
      const beforeText = Editor.string(editor, range) + text.slice(0, -1)
      const autoformatType = SHORTCUTS[beforeText]

      if (autoformatType) {
        Transforms.select(editor, range)

        if (!Range.isCollapsed(range)) Transforms.delete(editor)

        Editor.withoutNormalizing(editor, () => {
          const newProperties: Partial<SlateElement> = { type: autoformatType }
          Transforms.setNodes<SlateElement>(editor, newProperties, {
            match: n => SlateElement.isElement(n) && Editor.isBlock(editor, n)
          })

          if (autoformatType !== 'list-item') return

          const list: BulletedListElement | NumberedListElement = {
            type: beforeText === '-' ? 'bulleted-list' : 'numbered-list',
            children: []
          }
          Transforms.wrapNodes(editor, list, {
            match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'list-item'
          })
        })

        return
      }
    }

    insertText(text)
  }

  return editor
}
