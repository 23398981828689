import { createTaskFilterFunction, RunbookFilterType, TaskFilterContext } from './filters'
import { TaskListTask } from 'main/services/queries/types'

export const predecessorsToHere = createTaskFilterFunction(
  'predecessors_to_here',
  (task: TaskListTask, filters: RunbookFilterType, context: TaskFilterContext): boolean => {
    if (
      Object.prototype.hasOwnProperty.call(context, 'predecessorsToHereIds') &&
      context.predecessorsToHereIds instanceof Array
    ) {
      return context.predecessorsToHereIds?.includes(task.id)
    }

    return false
  }
)
