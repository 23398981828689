import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Heading = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Heading" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M6 10a4 4 0 0 1 4-4h28a4 4 0 0 1 4 4v6a4 4 0 0 1-4 4H10a4 4 0 0 1-4-4v-6Zm4-1h28a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1H10a1 1 0 0 1-1-1v-6a1 1 0 0 1 1-1Z" fill="#000" /><path d="M6 28h36v3H6v-3Zm24 11v3H6v-3h24Z" fill="#000" />
  </StyledIcon>
))

Heading.displayName = 'Heading'

export { Heading }
