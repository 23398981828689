import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const BrandChangeExecution = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 100 101" a11yTitle="BrandChangeExecution" {...props}>
    <g clipPath="url(#a)" fill="#fff"><path opacity=".5" d="M100 100.333v-47l-47 47h47Zm0-100H53l47 47v-47Z" /><path fillRule="evenodd" clipRule="evenodd" d="M0 100.333v-100l50 50-50 50Z" /></g>
  </StyledIcon>
))

BrandChangeExecution.displayName = 'BrandChangeExecution'

export { BrandChangeExecution }
