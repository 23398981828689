import { ThemeType as GrommetThemeType } from 'grommet/themes'
import { css, keyframes } from 'styled-components/macro'
import { Merge, SetRequired } from 'type-fest'

import { GROMMET_COLORS, THEME_COLORS, ThemeColors } from './color'

// Exports the type and value for the grommet theme's global key

type GrommetTheme = SetRequired<GrommetThemeType, 'global'>
type GrommetGlobal = SetRequired<
  GrommetTheme['global'],
  'colors' | 'focus' | 'font' | 'edgeSize' | 'breakpoints' | 'elevation'
>

export declare type ThemeGlobal = Merge<GrommetGlobal, { colors: ThemeColors }>

const dropKeyFrames = keyframes`
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
`

export const global: ThemeGlobal = {
  colors: { ...GROMMET_COLORS, ...THEME_COLORS } as ThemeColors,
  focus: {
    border: undefined,
    outline: {
      color: 'primary',
      size: '2px'
    },
    shadow: undefined
  },
  font: {
    family:
      "Inter, Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
    // NOTE: font-size is not yet determined. The Figma uses 15 Inter mostly but the app currently is moved to Inter 16. Need to decide on
    // what new design components should look like given they will be mixed with the existing UI during transition. If this
    // means 16px font, they all need to be updated in Figma.
    size: '16px',
    height: '21px' // this is associated with 15px (i.e., 15px font size 1.4em => 21 line height), do we want it still 21 if we go to 15?
  },
  breakpoints: {
    small: {
      value: 599
    },
    medium: {
      value: 831
    },
    large: {
      value: 1151
    },
    xlarge: {
      value: 1471
    }
  },
  edgeSize: {
    xxsmall: '4px',
    xsmall: '8px',
    small: '12px',
    medium: '16px',
    large: '24px',
    xlarge: '32px'
  },
  elevation: {
    light: {
      none: 'none',
      xsmall: '0px 1px 3px rgba(0, 0, 0, 0.08), 0px 1px 2px rgba(0, 0, 0, 0.04)',
      small: '0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.08)',
      medium: '0px 2px 6px -2px rgba(0, 0, 0, 0.06), 0px 8px 12px -2px rgba(0, 0, 0, 0.08)',
      large: '0px 4px 8px -2px rgba(0, 0, 0, 0.04), 0px 16px 24px -4px rgba(0, 0, 0, 0.08)',
      xlarge: '0px 24px 40px -4px rgba(0, 0, 0, 0.08), 0px 6px 16px -2px rgba(0, 0, 0, 0.04)'
    },
    dark: {
      none: 'none',
      xsmall: '0px 1px 3px rgba(0,0,0, 0.16), 0px 1px 2px rgba(0,0,0, 0.08)',
      small: '0px 2px 4px -1px rgba(0,0,0, 0.12), 0px 4px 6px -1px rgba(0,0,0, 0.16)',
      medium: '0px 2px 6px -2px rgba(0,0,0, 0.12), 0px 8px 12px -2px rgba(0,0,0, 0.16)',
      large: '0px 4px 8px -2px rgba(0,0,0, 0.08), 0px 16px 24px -4px rgba(0,0,0, 0.16)',
      xlarge: '0px 24px 40px -4px rgba(0,0,0, 0.16), 0px 6px 16px -2px rgba(0,0,0, 0.08)'
    }
  },
  drop: {
    zIndex: 10000,
    // @ts-ignore
    extend: ({ theme }: { theme: any }) => {
      return css`
        border-radius: ${theme.global?.edgeSize?.xsmall};
        animation-name: ${dropKeyFrames};
        animation-duration: 0.3s;
      `
    }
  }
}
