// @ts-nocheck
import { observable, action } from 'mobx'
import { container } from 'app/Config/IOC'
import { Types } from 'Gateways/Service/Types'
import { OptionPresenter } from './OptionPresenter'
import { TextInputPresenter } from 'Shared/Components/Molecules/TextInput/TextInputPresenter'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'
import { CheckboxPresenter } from 'Shared/Components/Molecules/Checkbox/CheckboxPresenter'
import { RemoteOptionsPresenter } from './RemoteOptionsPresenter'

export class RemoteOptionPresenter extends OptionPresenter {
  public language: ILanguageService = container.get(Types.ILanguageService)

  @observable
  public fieldOptionsPresenter: RemoteOptionsPresenter

  @observable
  public displayNamePresenter: TextInputPresenter = new TextInputPresenter()
    .withLabel(this.language.get('common:organisms:remoteOption:displayNameLabel'))
    .isRequiredTrimWhiteSpace(this.language.get('common:organisms:remoteOption:errors:displayNameRequired'))
    .withMiddleware((value: string) => {
      this.fieldOptionsPresenter.setDisplayName(this.guid, value)

      if (this.dirtyCallback) {
        this.dirtyCallback()
      }

      return value
    })

  @observable
  public showLock: boolean = false

  @action
  public withShowLock = (showLock: boolean) => {
    this.showLock = showLock
    return this
  }

  protected checkboxUpdateMiddleWare = (newValue: boolean): any => {
    this.fieldOptionsPresenter.setFilterable(this.guid, newValue)

    if (this.dirtyCallback) {
      this.dirtyCallback()
    }

    return newValue
  }

  public checkboxPresenter: CheckboxPresenter = new CheckboxPresenter(
    this.language.get('common:organisms:remoteOption:filterable')
  ).withMiddleware(this.checkboxUpdateMiddleWare)

  @action
  public withFieldOptionsPresenter = (presenter: RemoteOptionsPresenter) => {
    this.fieldOptionsPresenter = presenter
    return this
  }
}
