import { ThemeType as GrommetThemeType } from 'grommet/themes'
import styled, { css } from 'styled-components/macro'
import { SetRequired } from 'type-fest'

import { focusRingInsetCss } from './global-styles'
import { Icon } from '../icon'
import { Box } from '../layout/box/box'

type GrommetTheme = SetRequired<GrommetThemeType, 'dataTable'>
type GrommetDataTable = Partial<GrommetTheme['dataTable']>

const HeaderIcon = styled(props => <Icon {...props} />)``
const HeaderIconContainer = styled(props => <Box {...props} />)`
  max-height: 15px;
`

export const dataTable: GrommetDataTable = {
  header: {
    font: {
      // @ts-ignore grommet typed this incorrectly as string. number works properly and string gives a console error
      weight: 600
    },
    // @ts-ignore grommet typed this incorrectly
    extend: css`
      white-space: nowrap;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      > div {
        justify-content: space-between;
      }
      &:focus-visible {
        ${focusRingInsetCss}
      }
    `
  },
  primary: {
    weight: '400'
  },
  pinned: {
    header: {
      background: 'bg'
    },
    body: {
      background: 'bg'
    }
  },
  icons: {
    sortable: () => {
      return (
        <HeaderIconContainer>
          <HeaderIcon
            data-testid="sort-column"
            a11yTitle="Sort column"
            color="text-light"
            icon="chevron-right"
            size="small"
          />
        </HeaderIconContainer>
      )
    },
    ascending: () => (
      <Icon
        data-testid="sort-column-descending"
        aria-sort="descending"
        icon="chevron-down"
        color="primary"
        size="small"
      />
    ),
    descending: () => (
      <Icon data-testid="sort-column-ascending" aria-sort="ascending" color="primary" icon="chevron-up" size="small" />
    )
  }
}
