import { DashboardComponentProps } from '../../types'
import { MrdDashboardWidget } from '../mrd-dashboard-widget'
import {
  DurationDelta,
  DurationDisplay,
  formatDuration,
  formatDurationDelta,
  NoDataAvailable,
  TaskDuration,
  Tile
} from 'main/components/dashboards/widgets/account/common'

type LongestAverageTaskDurationSingleWidgetData = {
  name: string
  values: TaskDuration[]
}

export type LongestAverageTaskDurationSingleWidgetProps =
  DashboardComponentProps<LongestAverageTaskDurationSingleWidgetData>

export const LongestAverageTaskDurationSingleWidget = ({ data }: LongestAverageTaskDurationSingleWidgetProps) => {
  const [value]: TaskDuration[] = data.values
  const formattedDuration = value && value.duration ? formatDuration(value.duration) : undefined

  return (
    <MrdDashboardWidget title={data.name}>
      {!formattedDuration ? (
        <NoDataAvailable />
      ) : (
        <LongestAverageTaskDurationSingle
          taskTitle={value.name || data.name}
          duration={formattedDuration}
          delta={value.delta}
        />
      )}
    </MrdDashboardWidget>
  )
}

export type LongestAverageTaskDurationSingleProps = {
  taskTitle: string
  duration: string
  delta?: DurationDelta
}

export const LongestAverageTaskDurationSingle = ({
  taskTitle,
  duration,
  delta
}: LongestAverageTaskDurationSingleProps) => {
  const formattedDelta = formatDurationDelta(delta)

  return (
    <Tile title={taskTitle}>
      <DurationDisplay duration={duration} delta={formattedDelta} />
    </Tile>
  )
}
