// @ts-nocheck
import { injectable } from 'inversify'
import { container } from 'app/Config/IOC'
import { action, observable, computed } from 'mobx'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'
import { Types } from 'Gateways/Service/Types'
import { TaskTypeFormPresenter } from 'app/Settings/TaskTypes/TaskTypesPage/TaskTypeForm/TaskTypeFormPresenter'
import { TaskTypesViewPresenter } from 'app/Settings/TaskTypes/TaskTypesPage/TaskTypesViewPresenter'
import { ToasterService } from 'Components/Molecules'

@injectable()
export class TaskTypeAddPresenter {
  private taskTypesViewPresenter: TaskTypesViewPresenter = container.get(TaskTypesViewPresenter)

  private toasterService: ToasterService = container.get(ToasterService)

  private language: ILanguageService = container.get(Types.ILanguageService)

  @observable
  public taskTypeFormPresenter: TaskTypeFormPresenter

  @observable
  public postSuccessCall?: () => void

  @computed
  public get formSubmitted(): boolean {
    return this.taskTypesViewPresenter.submitted
  }

  public saveFunction = () => {
    if (this.formSubmitted) this.save()
  }

  @action
  public withPostSuccessCall = (call: () => void) => {
    this.postSuccessCall = call
    return this
  }

  @action
  public withTaskTypeFormPresenter = (taskTypeFormPresenter: TaskTypeFormPresenter) => {
    this.taskTypeFormPresenter = taskTypeFormPresenter
    return this
  }

  @action
  public save = async () => {
    const result = this.taskTypeFormPresenter.processSubmission()

    const response = await this.taskTypesViewPresenter.createTaskType({
      name: result.viewModel.name,
      global: result.viewModel.global,
      accountId: result.viewModel.global
        ? null
        : this.taskTypesViewPresenter.getAccountIdFromVisibility(result.viewModel.account),
      defaultDuration: result.viewModel.defaultDuration,
      highlight: result.viewModel.highlight,
      autoStart: result.viewModel.autoStart,
      autoFinish: result.viewModel.autoFinish,
      conditionalProgression: result.viewModel.conditionalProgression,
      enableStartFixed: result.viewModel.enableStartFixed,
      enableEndFixed: result.viewModel.enableEndFixed,
      linkable: result.viewModel.linkable
    })

    if (!response.success) {
      if (response.errorMessages) {
        this.taskTypeFormPresenter.editForm.serverErrors = response.errorMessages
        this.taskTypeFormPresenter.errorAlertPresenter.setErrorMessages([...response.errorMessages])
      }
      this.taskTypesViewPresenter.submitted = false
    } else {
      this.toasterService.pop(
        this.language.get('taskTypes:add:notification:title'),
        this.language.get('taskTypes:add:notification:notification'),
        'success'
      )

      if (this.postSuccessCall) {
        this.postSuccessCall()
      }

      this.taskTypesViewPresenter.loadTaskTypes()
    }
  }
}
