import { useState, useEffect, Fragment } from 'react'
import { CurrentPagePresenter } from './CurrentPagePresenter'
import styled from 'styled-components'
import { withPresenter } from 'app/Config/withPresenter'
import AccountsPage from 'app/Settings/Accounts/AccountsPage/AccountsPage'
import TaskTypesPage from 'app/Settings/TaskTypes/TaskTypesPage/TaskTypesPage'
import RunbookTypesPage from 'app/Settings/RunbookTypes/RunbookTypesPage/RunbookTypesPage'
import UserPageComponent from 'app/Access/Users/UsersPage/UsersPage'
import { RoleTypesPage } from 'app/Access/RoleTypes/RoleTypesPage/RoleTypesPage'
import { LoadingPanel } from 'Components/Templates'
import UserVerifyPage from 'app/UserVerify/UserVerifyPage/UserVerifyPage'
import { LoadingPage } from 'Components/Templates/PageWrappers/LoadingPage'
import { observer } from 'mobx-react-lite'
import CustomFieldsPage from 'app/Settings/CustomFields/CustomFieldsPage/CustomFieldsPage'
import CustomFieldGroupsPage from 'app/Settings/CustomFieldGroups/CustomFieldGroupsPage/CustomFieldGroupsPage'
import RunbooksPage from 'app/Account/Runbooks/RunbooksPage/RunbooksPage'
import RunbookUsersPage from 'app/Account/Runbooks/RunbookPage/RunbookUsersPage/RunbookUsersPage'
import { RunbookUsersPageConnector } from 'main/connectors/runbook-users-page-connector'

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`
const externalUrl = (): boolean => {
  const externalUrls = ['#/user-verify']
  return externalUrls.includes(window.location.hash.split('?')[0])
}

const CurrentPageComponent = observer((props: any) => {
  const { loading, router } = props.presenter
  const [currentRouteId, setCurrentRouteId] = useState<string | null>(null)

  useEffect(() => {
    // We are now mounting and unmounting the legacy app based on whether it is needed for a given route.
    // This is causing some weird inconsistencies with the legacy app's router being able to identify the
    // current route.This forces the router to resolve and can only be done in the component due to initial
    // availability of the route and route state in the presenter.
    const resolveRouterLocation = () => {
      router.routerGateway.resolve()
      const lastResolved = router.routerGateway.lastRouteResolved()

      if (lastResolved) {
        setCurrentRouteId(lastResolved.name)
      }
    }

    resolveRouterLocation()

    window.addEventListener('single-spa:routing-event', resolveRouterLocation)

    return () => {
      window.removeEventListener('single-spa:routing-event', resolveRouterLocation)
    }
  }, [])

  const renderedComponents = [
    {
      id: 'users',
      component: <UserPageComponent />
    },
    {
      id: 'roleTypes',
      component: <RoleTypesPage />
    },
    {
      id: 'taskTypes',
      component: <TaskTypesPage />
    },
    {
      id: 'runbookTypes',
      component: <RunbookTypesPage />
    },
    {
      id: 'customFields',
      component: <CustomFieldsPage />
    },
    {
      id: 'customFieldGroups',
      component: <CustomFieldGroupsPage />
    },
    {
      id: 'accounts',
      component: <AccountsPage />
    },
    {
      id: 'events',
      component: <Fragment />
    },
    // Runbooks page
    {
      id: 'runbooksDashboard',
      component: <RunbooksPage pageKey="dashboard" />
    },
    {
      id: 'runbooksList',
      component: <RunbooksPage pageKey="list" />
    },
    {
      id: 'runbooksTable',
      component: <RunbooksPage pageKey="table" />
    },
    {
      id: 'runbooksTimeline',
      component: <RunbooksPage pageKey="timeline" />
    },
    {
      id: 'userVerify',
      component: <UserVerifyPage />
    }
  ]

  // It's possible that mobx is doing something with memoization via the `observer` HOC which is interfering with
  // our react modals on angular pages. React modals work properly on the first page where they render, but then do
  // not open after navigating to another page where we were previously assigning the say `key` prop in the
  // renderedComponents array. Ensuring the content for each separate route has a unique key seems to fix the issue.
  const content = (
    <Fragment key={currentRouteId}>
      {renderedComponents.find(component => currentRouteId === component.id)?.component}
    </Fragment>
  )

  return (
    <Wrapper>
      {/* Connectors need to be inserted high in the tree because they must be available to listen to content mount events from angular asap */}
      <RunbookUsersPageConnector>
        <RunbookUsersPage />
      </RunbookUsersPageConnector>
      {loading ? externalUrl() ? <LoadingPage /> : <LoadingPanel /> : content}
    </Wrapper>
  )
})

export default withPresenter(CurrentPagePresenter)(CurrentPageComponent)
