import { ReactElement, useMemo } from 'react'

import { RecipientType } from './types'
import { useUsersAndTeamsListQuery } from 'main/services/queries/use-users-and-teams-query'

type RecipientTypeProviderProps = {
  users?: (number | string)[]
  teams?: number[]
  runbookId?: number
  runbookVersionId?: number
  render: (props: { isLoading: boolean; recipients: RecipientType[]; isError: boolean }) => ReactElement
}

export const RecipientTypeProvider = ({
  render,
  users = [],
  teams = [],
  runbookId,
  runbookVersionId
}: RecipientTypeProviderProps) => {
  const {
    data: rawData,
    isLoading,
    isError
  } = useUsersAndTeamsListQuery(
    {
      users,
      teams
    },
    runbookId,
    runbookVersionId
  )

  const recipients = useMemo(() => {
    if (!rawData) return []
    return [
      ...rawData.users.map(d => ({
        ...d,
        type: 'user'
      })),
      ...rawData.teams.map(d => ({
        ...d,
        type: 'team'
      }))
    ] as RecipientType[]
  }, [rawData])

  return render({ isLoading, recipients, isError })
}
