// @ts-nocheck
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { FilterGroupPresenter } from './FilterGroupPresenter'
import { Accordion, Checkbox, OldCheckboxFilterPresenter } from 'Components/Molecules'

const FilterWrapper = styled.div``
const Wrapper = styled.div``

interface IFilterGroupProps {
  presenter: FilterGroupPresenter<OldCheckboxFilterPresenter>
}

export const FilterGroup = observer<IFilterGroupProps>(({ presenter }) => {
  if (!presenter) return
  const { accordionPresenter, filters } = presenter

  const filterComponents = []

  filters.forEach((filterCheckbox, index) => {
    filterComponents.push(
      <FilterWrapper key={index}>
        <Checkbox presenter={filterCheckbox.checkboxPresenter} />
      </FilterWrapper>
    )
  })

  return (
    <Wrapper>
      {accordionPresenter && filters && <Accordion presenter={accordionPresenter}>{filterComponents}</Accordion>}
    </Wrapper>
  )
})
