// @ts-nocheck
import { observable, computed, action } from 'mobx'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'
import { Types } from 'Gateways/Service/Types'
import { container } from 'app/Config/IOC'
import { GenericFormPresenter } from '@logicroom/validator'
import { AccountVisibilityPresenter } from 'Components/Organisms'
import { CheckboxPresenter, DurationPresenter, TextInputPresenter, ErrorAlertPresenter } from 'Components/Molecules'
import { RadioButtonsPresenter } from 'Components/Atoms'
import { FeatureFlagService } from 'Shared/Helpers/FeatureFlagService'

export interface IFormViewModel {
  name: string
  account?: string
  global?: boolean
  disabledName: boolean
  disabledGlobal: boolean
  disabledAccount: boolean
  defaultDuration: number
  disabledDefaultDuration: boolean
  disabledHighlight: boolean
  highlight: boolean
  autoStart: boolean
  disabledAutoStart: boolean
  autoFinish: boolean
  disabledAutoFinish: boolean
  conditionalProgression: boolean
  disabledConditionalProgression: boolean
  comms?: string
  enableStartFixed: boolean
  enableEndFixed: boolean
  linkable: boolean
  disabledLinkable: boolean
  disabledEnableStartFixed: boolean
  disabledEnableEndFixed: boolean
}

export class TaskTypeFormPresenter {
  private language: ILanguageService = container.get(Types.ILanguageService)
  private featureFlagService: FeatureFlagService = container.get(FeatureFlagService)

  @observable
  public viewModel: IFormViewModel = {
    name: '',
    disabledName: false,
    disabledGlobal: false,
    disabledAccount: false,
    disabledDefaultDuration: false,
    defaultDuration: 3600,
    highlight: false,
    disabledHighlight: false,
    autoStart: false,
    disabledAutoStart: false,
    autoFinish: false,
    disabledAutoFinish: false,
    conditionalProgression: false,
    disabledConditionalProgression: false,
    comms: '',
    enableStartFixed: false,
    enableEndFixed: false,
    linkable: false,
    disabledLinkable: false,
    disabledEnableStartFixed: false,
    disabledEnableEndFixed: false
  }

  @observable
  public additionalSettingsHeading: string = this.language.get('taskTypes:fields:additionalSettings:label')

  @observable
  public visibilityAccounts: string[] = []

  @observable
  public errorAlertPresenter: ErrorAlertPresenter = new ErrorAlertPresenter()

  @observable
  public submitted: boolean = false

  @computed
  public get isAutoFinish(): boolean {
    return this.autoFinishPresenter.value ? true : false
  }

  private dirtyCallback: () => void

  @computed
  public get isCommsAvailable(): boolean {
    return this.viewModel.comms !== '' ? true : false
  }

  @observable
  public namePresenter: TextInputPresenter = new TextInputPresenter()
    .withLabel(this.language.get('taskTypes:fields:name:label'))
    .isRequiredTrimWhiteSpace()
    .withMiddleware((value: string) => {
      this.viewModel.name = value

      if (this.dirtyCallback) {
        this.dirtyCallback()
      }

      return value
    })

  @observable
  public visibilityPresenter: AccountVisibilityPresenter = new AccountVisibilityPresenter()

  @observable
  public defaultDurationPresenter: DurationPresenter = new DurationPresenter(this.viewModel.defaultDuration)
    .withLabel(this.language.get('taskTypes:fields:defaultDuration:label'))
    .withMiddleware((value: number) => {
      this.viewModel.defaultDuration = value

      if (this.dirtyCallback) {
        this.dirtyCallback()
      }

      return value
    })

  @observable
  public highlightPresenter: CheckboxPresenter = new CheckboxPresenter(
    this.language.get('taskTypes:fields:additionalSettings:checkboxLabels:bold')
  )
    .withHelpText(this.language.get('taskTypes:fields:additionalSettings:helpTexts:bold'))
    .withMiddleware((value: boolean) => {
      this.viewModel.highlight = value

      if (this.dirtyCallback) {
        this.dirtyCallback()
      }
      return value
    })

  @observable
  public autoStartPresenter: CheckboxPresenter = new CheckboxPresenter(
    this.language.get('taskTypes:fields:additionalSettings:checkboxLabels:autoStart')
  )
    .withHelpText(this.language.get('taskTypes:fields:additionalSettings:helpTexts:autoStart'))
    .withMiddleware((value: boolean) => {
      this.viewModel.autoStart = value

      if (this.dirtyCallback) {
        this.dirtyCallback()
      }
      return value
    })

  @observable
  public autoFinishPresenter: CheckboxPresenter = new CheckboxPresenter(
    this.language.get('taskTypes:fields:additionalSettings:checkboxLabels:autoFinish')
  )
    .withHelpText(this.language.get('taskTypes:fields:additionalSettings:helpTexts:autoFinish'))
    .withMiddleware((value: boolean) => {
      this.viewModel.autoFinish = value

      if (this.dirtyCallback) {
        this.dirtyCallback()
      }
      return value
    })

  @observable
  public conditionalProgressionPresenter: CheckboxPresenter = new CheckboxPresenter(
    this.language.get('taskTypes:fields:additionalSettings:checkboxLabels:conditionalProgression')
  )
    .withHelpText(this.language.get('taskTypes:fields:additionalSettings:helpTexts:conditionalProgression'))
    .withMiddleware((value: boolean) => {
      this.viewModel.conditionalProgression = value

      if (this.dirtyCallback) {
        this.dirtyCallback()
      }

      return value
    })

  @observable
  public enableStartFixedPresenter: CheckboxPresenter = new CheckboxPresenter(
    this.language.get('taskTypes:fields:additionalSettings:checkboxLabels:enableStartFixed')
  )
    .withHelpText(this.language.get('taskTypes:fields:additionalSettings:helpTexts:enableStartFixed'))
    .withMiddleware((value: boolean) => {
      this.viewModel.enableStartFixed = value

      if (this.dirtyCallback) {
        this.dirtyCallback()
      }
      return value
    })

  @observable
  public enableEndFixedPresenter: CheckboxPresenter = new CheckboxPresenter(
    this.language.get('taskTypes:fields:additionalSettings:checkboxLabels:enableEndFixed')
  )
    .withHelpText(this.language.get('taskTypes:fields:additionalSettings:helpTexts:enableEndFixed'))
    .withMiddleware((value: boolean) => {
      this.viewModel.enableEndFixed = value

      if (this.dirtyCallback) {
        this.dirtyCallback()
      }
      return value
    })

  @observable
  public linkablePresenter: CheckboxPresenter = new CheckboxPresenter(
    this.language.get('taskTypes:fields:additionalSettings:checkboxLabels:linkable')
  )
    .withHelpText(this.language.get('taskTypes:fields:additionalSettings:helpTexts:linkable'))
    .withMiddleware((value: boolean) => {
      this.viewModel.linkable = value

      if (this.dirtyCallback) {
        this.dirtyCallback()
      }
      return value
    })

  @observable
  public commsRadioPresenter: RadioButtonsPresenter = new RadioButtonsPresenter([
    this.language.get('taskTypes:fields:comms:radioButtonsLabels:off'),
    this.language.get('taskTypes:fields:comms:radioButtonsLabels:email'),
    this.language.get('taskTypes:fields:comms:radioButtonsLabels:sms'),
    this.language.get('taskTypes:fields:comms:radioButtonsLabels:call')
  ])
    .withLabel(this.language.get('taskTypes:fields:comms:label'))
    .withDisabledOptions([
      this.language.get('taskTypes:fields:comms:radioButtonsLabels:off'),
      this.language.get('taskTypes:fields:comms:radioButtonsLabels:email'),
      this.language.get('taskTypes:fields:comms:radioButtonsLabels:sms'),
      this.language.get('taskTypes:fields:comms:radioButtonsLabels:call')
    ])

  @action
  private setSelectedComms = () => {
    switch (this.viewModel.comms) {
      case 'comms_sms':
        this.commsRadioPresenter.selectedOption = this.language.get('taskTypes:fields:comms:radioButtonsLabels:sms')
        break
      case 'comms_email':
        this.commsRadioPresenter.selectedOption = this.language.get('taskTypes:fields:comms:radioButtonsLabels:email')
        break
      case 'comms_call':
        this.commsRadioPresenter.selectedOption = this.language.get('taskTypes:fields:comms:radioButtonsLabels:call')
        break
      case 'comms_off':
        this.commsRadioPresenter.selectedOption = this.language.get('taskTypes:fields:comms:radioButtonsLabels:off')
        break
    }
  }

  @observable
  public editForm: GenericFormPresenter = new GenericFormPresenter().addFormInput(this.namePresenter)

  @action
  public processSubmission = (): { viewModel: IFormViewModel } => {
    this.submitted = true
    this.editForm.serverErrors = []
    this.viewModel.name = this.namePresenter.value
    this.viewModel.account =
      this.visibilityPresenter.viewModel.type === 'account' ? this.visibilityPresenter.viewModel.account : null
    this.viewModel.global = this.visibilityPresenter.viewModel.type === 'global' ? true : false
    this.viewModel.defaultDuration = this.defaultDurationPresenter.value
    this.viewModel.highlight = this.highlightPresenter.value
    this.viewModel.autoStart = this.autoStartPresenter.value
    this.viewModel.autoFinish = this.autoFinishPresenter.value
    this.viewModel.conditionalProgression = this.conditionalProgressionPresenter.value
    this.viewModel.enableStartFixed = this.enableStartFixedPresenter.value
    this.viewModel.enableEndFixed = this.enableEndFixedPresenter.value
    this.viewModel.linkable = this.linkablePresenter.value

    if (!this.formIsValid) {
      this.namePresenter.isDirty = true
      this.defaultDurationPresenter.isDirty = true
      this.highlightPresenter.isDirty = true
      this.autoStartPresenter.isDirty = true
      this.autoFinishPresenter.isDirty = true
      this.conditionalProgressionPresenter.isDirty = true
      this.enableStartFixedPresenter.isDirty = true
      this.enableEndFixedPresenter.isDirty = true
      this.linkablePresenter.isDirty = true
    }
    return {
      viewModel: this.viewModel
    }
  }

  @computed
  public get formIsValid(): boolean {
    return this.editForm.isValid
  }

  @action
  private processViewModel = () => {
    this.namePresenter.value = this.viewModel.name

    this.visibilityPresenter.withViewModel({
      type: this.viewModel.global ? 'global' : 'account',
      account: this.viewModel.global ? null : this.viewModel.account,
      disabledGlobal: this.viewModel.disabledGlobal,
      disabledAccount: this.viewModel.disabledAccount
    })

    this.namePresenter.disabled = this.viewModel.disabledName
    this.defaultDurationPresenter.disabled = this.viewModel.disabledDefaultDuration
    this.defaultDurationPresenter.value = this.viewModel.defaultDuration
    this.highlightPresenter.value = this.viewModel.highlight
    this.highlightPresenter.disabled = this.viewModel.disabledHighlight
    this.autoStartPresenter.value = this.viewModel.autoStart
    this.autoStartPresenter.disabled = this.viewModel.disabledAutoStart
    this.autoFinishPresenter.value = this.viewModel.autoFinish
    this.autoFinishPresenter.disabled = this.viewModel.disabledAutoFinish
    this.conditionalProgressionPresenter.value = this.viewModel.conditionalProgression
    this.conditionalProgressionPresenter.disabled = this.viewModel.disabledConditionalProgression
    this.enableStartFixedPresenter.value = this.viewModel.enableStartFixed
    this.enableEndFixedPresenter.value = this.viewModel.enableEndFixed
    this.enableStartFixedPresenter.disabled = this.viewModel.disabledEnableStartFixed
    this.enableEndFixedPresenter.disabled = this.viewModel.disabledEnableEndFixed
    this.linkablePresenter.value = this.viewModel.linkable
    this.linkablePresenter.disabled = this.viewModel.disabledLinkable

    this.setSelectedComms()
  }

  @action
  public withDirtyCallback = (dirtyCallback: () => void) => {
    this.dirtyCallback = dirtyCallback
    this.visibilityPresenter.withDirtyCallback(dirtyCallback)
    return this
  }

  @action
  public withVisibilityAccounts = (visibilityAccounts: string[]) => {
    this.visibilityAccounts = visibilityAccounts
    this.visibilityPresenter.withAccounts(this.visibilityAccounts)
    return this
  }

  @action
  public withViewModel = (viewModel: IFormViewModel) => {
    this.viewModel = viewModel
    this.processViewModel()
    return this
  }

  @action
  public refresh = () => {
    this.reset()
    this.processViewModel()
  }

  @action
  public reset = () => {
    this.submitted = false
    this.namePresenter.reset()
    this.visibilityPresenter.reset()
    this.defaultDurationPresenter.reset()
    this.highlightPresenter.reset()
    this.autoStartPresenter.reset()
    this.autoFinishPresenter.reset()
    this.conditionalProgressionPresenter.reset()
    this.commsRadioPresenter.reset()
    this.enableStartFixedPresenter.reset()
    this.enableEndFixedPresenter.reset()
    this.linkablePresenter.reset()
    this.editForm.reset()
  }
}
