import styled from 'styled-components/macro'

import { Box } from '@cutover/react-ui'

export type TaskIconConnectorProps = {
  dotted?: boolean
  color: string
  bold?: boolean
  prevConnectorLong?: boolean
  prevConnectorShort?: boolean
  nextConnectorLong?: boolean
  nextConnectorShort?: boolean
}

export const TaskIconConnector = (props: (TaskIconConnectorProps & { up: true }) | { up: false; color: string }) =>
  props.up ? (
    <TaskIconUpConnector {...(props as TaskIconConnectorProps)} />
  ) : (
    <TaskIconDownConnector {...(props as TaskIconConnectorProps)} />
  )

const TaskIconUpConnector = styled(Box)<TaskIconConnectorProps>`
  position: absolute;
  width: 2px;
  height: ${props =>
    props.nextConnectorLong && props.prevConnectorLong
      ? '21px'
      : props.nextConnectorShort && props.prevConnectorShort
      ? '15px'
      : props.nextConnectorLong || props.prevConnectorLong
      ? '19px'
      : '17px'};
  top: ${props => (props.prevConnectorShort ? '-33px' : props.prevConnectorLong ? '-37px' : '-35px')};
  left: ${props => (props.bold ? '-2px' : '-1px')};
  border-left: ${props => `${props.color} ${props.dotted ? 'dotted' : 'solid'} ${props.bold ? '4px' : '2px'};`};
`

const TaskIconDownConnector = styled(Box).attrs<TaskIconConnectorProps>(props => ({
  border: { side: 'left', color: props.color, size: '2px' },
  width: '2px',
  height: '19px'
}))`
  position: relative;
  top: 26px;
`
