// @ts-nocheck
import { observable, action, computed } from 'mobx'
import { injectable } from 'inversify'
import { container } from 'app/Config/IOC'
import { Types } from 'Gateways/Service/Types'
import { ILanguageService } from 'Helpers/Language/ILanguageService'
import { ToasterService } from 'Components/Molecules'
import { ErrorAlertPresenter } from 'Components/Molecules'
import { AccountsPageViewPresenter } from 'app/Settings/Accounts/AccountsPage/AccountsPageViewPresenter'
import { AccountFormPresenter } from 'app/Settings/Accounts/AccountsPage/AccountForm/AccountFormPresenter'
import { FeatureFlagService } from 'Shared/Helpers/FeatureFlagService'
import { eventManager } from 'event-manager'

@injectable()
export class AccountAddPresenter {
  private accountsPageViewPresenter: AccountsPageViewPresenter = container.get(AccountsPageViewPresenter)
  public toasterService: ToasterService = container.get(ToasterService)
  private featureFlagService: FeatureFlagService = container.get(FeatureFlagService)
  private language: ILanguageService = container.get(Types.ILanguageService)

  @observable
  public formPresenter: AccountFormPresenter = null

  @observable
  public errorAlertPresenter: ErrorAlertPresenter = new ErrorAlertPresenter()

  @observable
  public postSuccessCall?: () => void

  @observable
  public cancelCallback?: () => void

  @computed
  public get formSubmitted(): boolean {
    return this.accountsPageViewPresenter.submitted
  }

  @action
  public withPostSuccessCall = (call: () => void) => {
    this.postSuccessCall = call
    return this
  }

  @action
  public withCancelCallback = (call: () => void) => {
    this.cancelCallback = call
    return this
  }

  @action
  public submit = async () => {
    const formResponse = this.formPresenter.processSubmission()
    this.formPresenter.accountForm.serverErrors = []

    if (formResponse.result) {
      this.accountsPageViewPresenter.addModalSubmitButtonLoading = true
      const response = await this.accountsPageViewPresenter.addAccount(formResponse.recordViewModel)
      this.accountsPageViewPresenter.addModalSubmitButtonLoading = false

      if (response.success) {
        if (this.featureFlagService.isEnabled('react_workspace')) {
          eventManager.emit('trigger-react-refetch-workspaces')
        }
        if (this.postSuccessCall) this.postSuccessCall()
        await this.accountsPageViewPresenter.loadAccounts()
        const title = this.language.get('accounts:add:toaster:title')
        const notification = this.language.get('accounts:add:toaster:notification')
        this.toasterService.pop(title, notification, 'success')
      } else {
        this.formPresenter.accountForm.serverErrors.push(...response.errorMessages)
        this.formPresenter.errorAlertPresenter.setErrorMessages([...response.errorMessages])
        this.accountsPageViewPresenter.setSubmitted(false)
      }
    } else {
      this.accountsPageViewPresenter.setSubmitted(false)
    }
  }

  public cancel = async () => {
    if (this.cancelCallback) this.cancelCallback()
  }

  @action
  public withFormPresenter = (formPresenter: AccountFormPresenter) => {
    this.formPresenter = formPresenter
    return this
  }
}
