// @ts-nocheck
import { GenericFilter } from 'app/Filters/GenericFilter'
import { computed, observable, observe, action } from 'mobx'

export class GenericBooleanFilter extends GenericFilter {
  @observable
  public canBeInverse = false

  @observable
  public isInverse = false

  @observable
  private urlInverseString: string = '0'

  @observable
  public valueInUrl: { key: string; value: string } = null

  @action
  public select = (filter: { key: string; value: string }) => {
    this.valueInUrl = filter
    this.isPopulated = true
    if (this.canBeInverse && filter.value === this.urlInverseString) {
      this.isInverse = true
    } else {
      this.isInverse = false
    }
  }

  @action
  public deselect = () => {
    this.valueInUrl = null
    this.isPopulated = false
    this.isInverse = false
  }

  public match = (filter: { key: string; value: string }) => {
    if (filter.key === this.reference) return true
    return false
  }

  @computed
  public get serialiseForUrl(): string {
    if (this.hideFromTheUrl) return ''
    if (this.isPopulated && this.isInverse) {
      return `${this.reference}=0`
    }
    if (this.isPopulated) {
      return `${this.reference}=1`
    }
    return ''
  }

  @computed
  public get serialiseForApi(): string {
    if (this.isPopulated && this.isInverse) {
      return `${this.reference}=false`
    }
    if (this.isPopulated) {
      return `${this.reference}=true`
    }
    return ''
  }

  @computed
  public get serialiseForComparitor(): string {
    if (this.isPopulated) {
      if (this.isInverse) {
        return 'false'
      } else {
        return 'true'
      }
    }

    return null
  }

  public middlewareFunction = (value: boolean) => {
    this.onChangeCallback && this.onChangeCallback(this.reference)
    this.isPopulated = value
    this.refreshFilteredRoute()
    return value
  }

  constructor({ reference }: { reference: string }) {
    super({ reference })

    observe(this, 'canBeInverse', () => {
      this.isPopulated = !!this.valueInUrl
      if (this.canBeInverse && this.valueInUrl && this.valueInUrl.value === this.urlInverseString) {
        this.isInverse = true
      }
    })
  }

  public filterCanBeInverse = ({ inverseTrueString }: { inverseTrueString: string }) => {
    this.canBeInverse = true
    this.urlInverseString = inverseTrueString
    return this
  }

  public compareMatch = (comparitor: string): boolean => {
    return comparitor === this.reference && this.isPopulated
  }
}
