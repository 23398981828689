import { ReactNode } from 'react'

import { Heading } from '../../typography'
import { px2rem } from '../../utils'
import { Box } from '../box'

export type MainPanelHeaderProps = {
  title?: string
  children?: ReactNode
}

export const MainPanelHeader = ({ title, children }: MainPanelHeaderProps) => {
  return (
    <Box
      css={`
        padding: 12px 16px;
        min-height: 40px;
      `}
    >
      {title && (
        <Heading
          as="h3"
          weight={400}
          css={`
            font-size: ${px2rem(22)};
            line-height: 40px;
          `}
        >
          {title}
        </Heading>
      )}
      {children}
    </Box>
  )
}
