import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Import = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Import" {...props}>
    <path d="m24 34.213 10.799-10.799-2.121-2.121L25.5 28.47V6h-3v22.47l-7.178-7.177-2.121 2.121L24 34.214Z" fill="#000" /><path d="M6 34.607v-5h3v5a5 5 0 0 0 5 5h20a5 5 0 0 0 5-5v-5h3v5a8 8 0 0 1-8 8H14a8 8 0 0 1-8-8Z" fill="#000" />
  </StyledIcon>
))

Import.displayName = 'Import'

export { Import }
