// @ts-nocheck
import { injectable } from 'inversify'
import { observable, action } from 'mobx'

import { ButtonPresenter } from 'Components/Molecules/Button'
import { container } from 'app/Config/IOC'
import { ILanguageService } from 'Helpers/Language/ILanguageService'
import { IOauthClientSession } from 'app/Repositories/User/UserOauthClientSessionRepository'
import { Types } from 'Gateways/Service/Types'

@injectable()
export class UserOauthClientSessionPresenter {
  public language: ILanguageService = container.get(Types.ILanguageService)

  @observable
  public buttonPresenter = new ButtonPresenter('', async () => {
    await this.revokeClientSessionAction(this.clientSession.userId, this.clientSession.id)
  })
    .withIconName('trash-o')
    .withDisplayStyle('secondary-invert')
    .withAriaLabel(this.language.get('users:edit:oauth:delete'))

  @observable
  public clientSession: IOauthClientSession

  @observable
  public canDeleteOauthClientSession: boolean

  @observable
  public revokeClientSessionAction: (userId: number, clientSessionId: number) => void

  constructor(
    clientSession: IOauthClientSession,
    canDeleteOauthClientSession: boolean,
    revokeClientSessionAction: (userId: number, clientSessionId: number) => void = () => {}
  ) {
    this.clientSession = clientSession
    this.canDeleteOauthClientSession = canDeleteOauthClientSession
    this.revokeClientSessionAction = revokeClientSessionAction
  }
}
