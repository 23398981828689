// @ts-nocheck
import { injectable } from 'inversify'
import { Types } from 'Gateways/Service/Types'
import { IServiceGateway } from 'Gateways/Service/IServiceGateway'
import { container } from 'app/Config/IOC'

@injectable()
export class FieldOptionRepository {
  public serviceGateway: IServiceGateway = container.get(Types.IServiceGateway)

  public url = 'field_options'

  public createRecord = async newFieldOption => {
    return await this.serviceGateway.post(this.url, newFieldOption)
  }
}
