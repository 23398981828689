import styled from 'styled-components/macro'
import { LiteralUnion } from 'type-fest'

import { Box, BoxProps } from '../../layout'
import { themeColor } from '../../theme'
import { ColorProp } from '../../theme/color'

export type ListItemBoxProps = Omit<BoxProps, 'color' | 'border'> & {
  selected?: boolean
  size?: 'small' | 'medium' | 'large'
  border?: boolean
  expandable?: boolean
  active?: boolean
  // TODO: is there better prop for this -- are tehre a preset of types/colors? etc */
  color?: LiteralUnion<ColorProp, string>
  level?: number
}

const LEVEL_INDENT_MULTIPLIER = 30

export const ListItemContainer = styled(Box).attrs((props: ListItemBoxProps) => ({
  round: '8px',
  direction: 'column',
  flex: false,
  width: props.width ?? '100%',
  pad: props.size === 'large' ? '8px' : '4px',
  height: props.size === 'small' ? '32px' : props.size === 'medium' ? '40px' : '56px',
  gap: props.size !== 'small' ? 'xxsmall' : undefined,
  justify: 'center'
}))<ListItemBoxProps>`
  color: ${props => props.color && themeColor(props.color)};

  ${props =>
    props.level &&
    props.level > 1 &&
    `
    padding-left: ${LEVEL_INDENT_MULTIPLIER * (props.level - 1)}px;
  `}

  border: ${props => props.border && '1px solid ' + themeColor('bg-3')};
  height: ${props => (props.expandable ? 'unset' : undefined)};
  &:hover {
    border: ${props => props.border && '1px solid ' + themeColor('bg-3')};
  }
`

export const ListItemEndBox = styled(Box).attrs((props: ListItemBoxProps) => ({
  flex: false,
  direction: 'row',
  gap: props.size === 'small' ? 'xsmall' : 'small'
}))<ListItemBoxProps>``

const calcPadding = ({
  size,
  level,
  hasStartComponent
}: {
  size?: 'small' | 'medium' | 'large'
  level: number
  hasStartComponent: boolean
}) => {
  const levelIndent = LEVEL_INDENT_MULTIPLIER * (level - 1)
  let iconIndent = 0
  if (hasStartComponent) {
    iconIndent = size === 'large' ? 46 : size === 'medium' ? 38 : 30
  }
  const generalIndent = 28
  return levelIndent + iconIndent + generalIndent
}

export const ListItemExpandableContent = styled(Box)<{
  size?: 'small' | 'medium' | 'large'
  level: number
  hasStartComponent: boolean
}>`
  div span {
    font-size: 13px !important;
  }
  padding-left: ${props =>
    calcPadding({ size: props.size, level: props.level, hasStartComponent: props.hasStartComponent })}px;
`
