import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const BrandChangePlanning = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 100 101" a11yTitle="BrandChangePlanning" {...props}>
    <g clipPath="url(#a)" fill="#fff"><path opacity=".5" d="M47 53.333H0l47 47v-47Z" /><path d="M100 53.333H53l47 47v-47Zm-53-53H0l47 47v-47Z" /></g>
  </StyledIcon>
))

BrandChangePlanning.displayName = 'BrandChangePlanning'

export { BrandChangePlanning }
