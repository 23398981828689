import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const BrandFailover = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 100 100" a11yTitle="BrandFailover" {...props}>
    <g clipPath="url(#a)" fill="#fff"><path opacity=".5" d="M45.667 33.667 66.408 54.41l-45.726 45.726L-.06 79.394z" /><path d="M70.667 0H100v100H70.667z" /></g>
  </StyledIcon>
))

BrandFailover.displayName = 'BrandFailover'

export { BrandFailover }
