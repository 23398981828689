import { createTaskFilterFunction, RunbookFilterType, TaskFilterContext } from './filters'
import { TaskListTask } from 'main/services/queries/types'

// Could be written more concisely but like this to help document the logic
export const myTasks = createTaskFilterFunction(
  'mt',
  (task: TaskListTask, filters: RunbookFilterType, context: TaskFilterContext): boolean => {
    if (filters.mt === undefined) return false
    if (!context.currentUserId || !context.currentUserRunbookTeamIds) return false

    if (
      filters.mt === true &&
      (task.user_ids.includes(context.currentUserId) ||
        hasIntersection(task.runbook_team_ids, context.currentUserRunbookTeamIds))
    ) {
      return true
    } else if (
      filters.mt === false &&
      !task.user_ids.includes(context.currentUserId) &&
      !hasIntersection(task.runbook_team_ids, context.currentUserRunbookTeamIds)
    ) {
      return true
    }

    return false
  }
)

const hasIntersection = (a: any[], b: any[]) => {
  return a.some(item => b.includes(item))
}
