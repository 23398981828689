import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const BrandPartnerships = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 100 101" a11yTitle="BrandPartnerships" {...props}>
    <g clipPath="url(#a)" fill="#fff"><circle transform="matrix(-1 0 0 1 14.667 50.667)" r="14.667" /><circle opacity=".5" transform="matrix(-1 0 0 1 85.333 50.667)" r="14.667" /><circle opacity=".5" transform="matrix(-1 0 0 1 50 15.334)" r="14.667" /><path d="M0 71.334h29.333v29.333H0z" /><path opacity=".5" d="M70.666 71.334h29.333v29.333H70.666zM35.333 36.001h29.333v29.333H35.333z" /></g>
  </StyledIcon>
))

BrandPartnerships.displayName = 'BrandPartnerships'

export { BrandPartnerships }
