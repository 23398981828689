import { format } from 'date-fns'

import { Day, Frequency } from './runbook-dashboard-schedule-types'
import { getUtcToZonedTime, timezoneToObject } from 'main/services/timezone-util'

export const getRecipientString = (numberOfRecipients: number): string => {
  return `${numberOfRecipients} recipient${numberOfRecipients === 1 ? '' : 's'}`
}

export const getScheduleString = (
  scheduleType: Frequency,
  scheduleTime: string,
  timezone: string,
  days?: number[]
): string => {
  const timeDisplay = getTimeDisplay(scheduleTime, timezone)

  if (scheduleType === 'once') {
    return `${getDateDisplay(scheduleTime)} ${timeDisplay}`
  }

  if (scheduleType === 'daily') {
    return `Everyday ${timeDisplay}`
  }

  if (scheduleType === 'weekly') {
    if (days) {
      return `${getDaysDisplay(days)} ${timeDisplay}`
    }

    return `Weekly ${timeDisplay}`
  }

  return timeDisplay
}

const WEEK_DAY_NUMBER_MAP: Record<number, Day> = {
  0: 'Sun',
  1: 'Mon',
  2: 'Tue',
  3: 'Wed',
  4: 'Thu',
  5: 'Fri',
  6: 'Sat'
}

const getTimeDisplay = (scheduleTime: string, timezone: string): string => {
  // Check the schedule time
  if (isNaN(Date.parse(scheduleTime))) {
    return ''
  }

  // We store the time with date which is normally 1970-01-01
  // this logic swaps this out for today's date to ensure we don't
  // see strange GMT+1 issues
  const todaySchedule = format(Date.now(), 'yyyy-MM-dd')
  const todayScheduleTime = format(new Date(scheduleTime), 'HH:mm')
  const formattedDate = `${todaySchedule} ${todayScheduleTime}`

  // https://date-fns.org/v2.28.0/docs/format
  const scheduleTimeFormatted = format(getUtcToZonedTime(formattedDate, timezone), 'h:mmaaa')
  const timezoneDetails = timezoneToObject(timezone, new Date(formattedDate.replace(/-/g, '/')))

  return `at ${scheduleTimeFormatted} ${timezoneDetails && timezoneDetails.tzAbbreviation}`
}

const convertDaysToString = (days: number[]): Day[] => {
  return days.map((dayNum: number) => WEEK_DAY_NUMBER_MAP[dayNum]) ?? []
}

const getDaysDisplay = (days: number[]): string => {
  const determiner = 'Every'
  const stringDays: Day[] = convertDaysToString(days)

  if (stringDays.length === 1) {
    return `${determiner} ${stringDays[0]}`
  }

  if (stringDays.length === 2) {
    return `${determiner} ${stringDays.join(' and ')}`
  }

  return `${determiner} ${stringDays.join(', ')}`
}

const getDateDisplay = (scheduleTime: string): string => {
  const scheduleDate = Date.parse(scheduleTime)

  // Check the schedule time
  if (isNaN(scheduleDate)) {
    return ''
  }

  return format(scheduleDate, 'do MMM yyyy')
}
