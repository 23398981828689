import { Heading, Text } from 'grommet'

import { Box, IconButton, SanitizedString, themeColor, useNotify } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'

export const LicenseKeyOutput = (command: { command: string }) => {
  const { t } = useLanguage('connectSettings')
  const notify = useNotify()
  return (
    <Box>
      <Heading level={4}>{t('modal.activate.header')}</Heading>
      <Text>
        <SanitizedString input={t('modal.activate.infoText')} />
      </Text>
      <Box
        css={`
          width: 100%;
          position: relative;
        `}
      >
        <Box
          as="code"
          css={`
            font-size: 0.75rem;
            overflow-wrap: break-word;
            width: 100%;
            background-color: ${themeColor('bg-2')};
            padding: 2px 64px 8px 8px;
            border-radius: 4px;
            max-height: 150px;
            overflow: auto;
          `}
        >
          <IconButton
            label="copy"
            disableTooltip
            icon="copy"
            css={`
              position: absolute;
              right: 8px;
              top: 4px;
            `}
            onClick={async () => {
              try {
                await navigator.clipboard.writeText(JSON.parse(JSON.stringify(command)).command)
                notify.success(t('modal.copySuccess'))
              } catch (err) {
                notify.success(t('modal.copyError'))
              }
            }}
          />
          <SanitizedString input={JSON.parse(JSON.stringify(command)).command} />
        </Box>
      </Box>
    </Box>
  )
}
