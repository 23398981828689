import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Subscript = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Subscript" {...props}>
    <path d="m25.839 12.839 2.121 2.121-8.838 8.839 8.838 8.839-2.121 2.121L17 25.92l-8.839 8.84-2.121-2.122 8.839-8.839L6.04 14.96l2.121-2.121 8.84 8.839 8.838-8.84Zm6.374 26.377v2.528H42.98V38.91h-5.895v-.092l1.74-1.605c1.056-.919 1.87-1.707 2.443-2.365.578-.658.978-1.26 1.2-1.804a4.322 4.322 0 0 0 .334-1.648c0-.87-.225-1.636-.674-2.294-.445-.663-1.075-1.179-1.89-1.548-.814-.37-1.773-.554-2.876-.554-1.07 0-2.01.197-2.82.59-.805.388-1.43.942-1.875 1.661-.445.72-.667 1.575-.667 2.564h3.33c0-.44.084-.814.25-1.122.17-.308.407-.542.71-.703a2.17 2.17 0 0 1 1.058-.249c.398 0 .75.079 1.058.235.308.156.55.379.724.667.176.284.263.628.263 1.03 0 .36-.073.694-.22 1.002-.147.303-.36.606-.64.909-.279.298-.617.627-1.015.987l-5.305 4.645Z" fill="#000" />
  </StyledIcon>
))

Subscript.displayName = 'Subscript'

export { Subscript }
