import { memo, useEffect, useState } from 'react'

import { Accordion, Box } from '@cutover/react-ui'
import { ExtendedCustomField } from 'main/recoil/runbook/models/account/custom-fields'
import { CustomFieldMultiSelectControl } from 'main/recoil/shared/filters/controls/custom-field-multi-select-control'
import { FilterAccordionPanel } from './shared/filter-accordion-panel'
import {
  CompletionGroup,
  DateGroup,
  GroupsHeader,
  LevelGroup,
  OtherGroup,
  PermissionsGroup,
  QuickFiltersGroup,
  SnippetsGroup,
  StageGroup,
  StreamGroup,
  TaskTypeGroup,
  TeamGroup,
  UserGroup
} from './groups'
import { CustomFieldDateSelectControl, CustomFieldTextSearchControl } from 'main/recoil/shared/filters/controls'
import { SavedFiltersGroup } from './groups/saved-filters-group'
import { isSearchableCustomField } from 'main/services/tasks/filtering'
import { useFilterPanelOpenState } from 'main/components/layout/layout-hooks'
import { IDS as LAYOUT_IDS } from 'main/components/layout/layout-constants'
import {
  useCustomFieldAppliedFiltersState,
  useHasFiltersState,
  useHasSnippets,
  useRunbookFilterCustomFieldsState
} from 'main/recoil/data-access'
import { SavedFilterModel } from 'main/data-access'

export const RunbookFilter = () => {
  // We need to defer rendering the content of the panel on first it opens so that the filter
  // panel open aanimation can complete before the content renders. This is needed for our
  // text component to track properly the available space and know whether it should truncate
  // with a tooltip for overflows.
  const isFilterPanelOpen = useFilterPanelOpenState()
  const [initialOpen, setInitialOpen] = useState<boolean | null>(isFilterPanelOpen)
  const hasFilters = useHasFiltersState()
  const hasSavedFilters = SavedFilterModel.useGetAll().length > 0
  const hasSnippets = useHasSnippets()

  useEffect(() => {
    if (isFilterPanelOpen) setInitialOpen(true)
  }, [isFilterPanelOpen])

  return (
    <>
      {initialOpen && (
        <RunbookFilterInner
          hasFilters={hasFilters}
          hasSavedFilters={hasSavedFilters}
          hasSnippets={hasSnippets}
          hasOpened={!!initialOpen}
        />
      )}
    </>
  )
}

export const RunbookFilterInner = memo(
  ({
    hasOpened,
    hasSavedFilters,
    hasFilters,
    hasSnippets
  }: {
    hasOpened: boolean
    hasFilters: boolean
    hasSavedFilters: boolean
    hasSnippets: boolean
  }) => {
    const customFields = useRunbookFilterCustomFieldsState()
    const customFieldFilters = useCustomFieldAppliedFiltersState()

    const initialActiveIndex = hasSavedFilters ? [1, 2, 3] : [1, 2]

    return (
      <Box overflow={{ vertical: 'auto' }} pad={{ top: 'xxsmall', left: 'small', right: 'xxsmall' }}>
        {hasOpened && (
          <Accordion
            scrollContainer={LAYOUT_IDS.FILTER_PANEL}
            initialActiveIndex={initialActiveIndex}
            disabledIndices={hasFilters ? undefined : [0]}
          >
            <GroupsHeader key="header" />
            {hasSavedFilters && <SavedFiltersGroup key="saved-filters" />}
            {hasSnippets && <SnippetsGroup key="snippets" />}
            <StreamGroup key="streams" />
            <QuickFiltersGroup key="quick-filters" />
            <TeamGroup key="team" />
            <UserGroup key="user" />
            <TaskTypeGroup key="task-type" />
            <LevelGroup key="level" />
            <StageGroup key="stage" />
            <CompletionGroup key="completion" />
            <PermissionsGroup key="permissions" />
            <DateGroup key="date" />
            <OtherGroup key="other" />
            {customFields.map(customField => {
              return (
                <CustomFieldGroup
                  customField={customField}
                  applied={customFieldFilters && customField.id in customFieldFilters}
                  key={customField.id}
                />
              )
            })}
          </Accordion>
        )}
      </Box>
    )
  }
)

const CustomFieldGroup = memo(({ customField, applied }: { customField: ExtendedCustomField; applied?: boolean }) => {
  return (
    <FilterAccordionPanel applied={applied} label={customField.display_name ?? customField.name} key={customField.id}>
      {customField.field_type.slug === 'datetime' ? (
        <CustomFieldDateSelectControl id={customField.id} />
      ) : customField.field_type.select_type !== 'none' || isSearchableCustomField(customField.type) ? (
        <CustomFieldMultiSelectControl id={customField.id} />
      ) : customField.field_type.slug === 'text' || customField.field_type.slug === 'textarea' ? (
        <CustomFieldTextSearchControl id={customField.id} />
      ) : null}
    </FilterAccordionPanel>
  )
})
