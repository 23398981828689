import { FieldOption } from 'main/services/queries/types'
import {
  useGetFieldOption,
  useGetFieldOptionCallback,
  useGetFieldOptionLookup,
  useGetFieldOptionLookupCallback,
  useGetFieldOptions,
  useGetFieldOptionsCallback
} from '../hooks'
import { CollectionModelInterfaceBase } from './types'

export type FieldOptionModelType = CollectionModelInterfaceBase<FieldOption>

export const FieldOptionModel: FieldOptionModelType = {
  useGet: useGetFieldOption,
  useGetCallback: useGetFieldOptionCallback,

  useGetAll: useGetFieldOptions,
  useGetAllCallback: useGetFieldOptionsCallback,

  useGetLookup: useGetFieldOptionLookup,
  useGetLookupCallback: useGetFieldOptionLookupCallback
}
