// @ts-nocheck
import styled from 'styled-components'
import { Icon, DisplaySvgIcon, TDisplaySvgIcons } from 'Components/Atoms'
import { observer } from 'mobx-react-lite'
import { px2rem, colors, fontSizes } from 'app/Styles/Variables'
import { truncate } from 'app/Styles/Utils'

const CountWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  background-color: ${colors.secondaryHover};
  height: ${px2rem(40)};
  padding: 0 ${px2rem(12)};
  border-radius: ${px2rem(24)};
`

const Wrapper = styled.div`
  display: flex;
  padding: ${px2rem(12)} ${px2rem(16)};
  border-radius: ${px2rem(16)};
  cursor: pointer;
  &:hover {
    background-color: ${colors.secondaryHover};
    ${CountWrapper} {
      background-color: ${colors.white};
    }
  }
`

const DisplaySvgIconStyled = styled(DisplaySvgIcon)`
  margin-right: ${px2rem(16)};
  flex-shrink: 0;
`

const NameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: ${px2rem(10)};
  min-width: 0; // It needs for Name truncate works properly inside flexbox
`

const Name = styled.div`
  font-size: ${fontSizes.m};
  font-weight: 500;
  color: ${colors.black};
  ${truncate};
`

const Description = styled.div`
  font-size: ${fontSizes.sm};
  color: ${colors.text};
  ${truncate};
`

const CountIconStyled = styled(Icon)`
  margin-right: ${px2rem(8)};
`

const Count = styled.div`
  color: ${colors.text};
`

interface IRunbookTemplateElementProps {
  name: string
  count?: number
  iconName: TDisplaySvgIcons
  iconColor: string
  id: number
  templateStatus: string
  tasksCount: number
  projectName: string
  onClick: () => void
}

export const AngularRunbookTemplateElement = observer<IRunbookTemplateElementProps>(props => {
  const { name, count = null, iconName, iconColor, templateStatus, id, tasksCount, projectName, onClick } = props

  const description = `#${id} \u2022 ${templateStatus} \u2022 ${projectName} \u2022 ${tasksCount} tasks`

  return (
    <Wrapper onClick={onClick} role="listitem" aria-label={name}>
      <DisplaySvgIconStyled name={iconName} color={iconColor} type="draft" />
      <NameWrapper>
        <Name>{name}</Name>
        <Description>{description}</Description>
      </NameWrapper>
      {count !== null ? (
        <CountWrapper>
          <CountIconStyled name={'copies'} />
          <Count>{count}</Count>
        </CountWrapper>
      ) : null}
    </Wrapper>
  )
})
