import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const GroupX = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="GroupX" {...props}>
    <path d="M17 24a3 3 0 1 0-6 0 3 3 0 0 0 6 0Zm10 0a3 3 0 1 0-6 0 3 3 0 0 0 6 0Z" fill="#000" /><path d="M14 33a9 9 0 1 1 0-18h10a9 9 0 1 1 0 18H14Zm0-15a6 6 0 0 0 0 12h10a6 6 0 0 0 0-12H14Zm26 3a3 3 0 1 1 0 6 3 3 0 0 1 0-6Z" fill="#000" />
  </StyledIcon>
))

GroupX.displayName = 'GroupX'

export { GroupX }
