import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Notifications = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Notifications" {...props}>
    <path d="M30.22 7.346A.38.38 0 0 1 30 7a6 6 0 0 0-12 0 .38.38 0 0 1-.22.346C12.6 9.71 9 14.934 9 21v9a1 1 0 0 1-1 1 4 4 0 0 0 0 8h32a4 4 0 0 0 0-8 1 1 0 0 1-1-1v-9c0-6.066-3.6-11.29-8.78-13.654ZM24 4a3 3 0 0 1 3 3 3.38 3.38 0 0 0 1.975 3.076A12.003 12.003 0 0 1 36 21v9a4 4 0 0 0 4 4 1 1 0 1 1 0 2H8a1 1 0 1 1 0-2 4 4 0 0 0 4-4v-9c0-4.848 2.875-9.03 7.025-10.924A3.38 3.38 0 0 0 21 7a3 3 0 0 1 3-3Zm0 43a6 6 0 0 1-6-6h3a3 3 0 1 0 6 0h3a6 6 0 0 1-6 6Z" fill="#000" />
  </StyledIcon>
))

Notifications.displayName = 'Notifications'

export { Notifications }
