// @ts-nocheck
import { injectable } from 'inversify'
import { GenericRecordRepository, IRecordDTO } from './GenericRecordRepository'
import { ICustomFieldGroupPM } from 'Shared/Entities/Types/CustomFieldGroup/ICustomFieldGroupPM'
import { observable, action } from 'mobx'

interface ICustomFieldGroupDTO {
  id?: number
  name: string
  default?: boolean
}

interface IExistingCustomFieldGroupDTO extends IRecordDTO {
  name: string
  default?: boolean
}

@injectable()
export class CustomFieldGroupRepository extends GenericRecordRepository {
  protected url: string = 'custom_field_groups'

  protected recordDTO: IExistingCustomFieldGroupDTO

  @observable
  public recordForEdit: ICustomFieldGroupPM = null

  constructor() {
    super('custom_field_group')
  }

  public preLoad = (args: { [key: string]: any }) => {
    return this.url + '/' + args.id
  }

  public saveRecord = async programmersModel => {
    const result = await this.serviceGateway.put(this.url + '/' + programmersModel.id, {
      custom_field_group: this.getDtoFromProgrammersModel(programmersModel)
    })
    if (result.success) {
      this.postLoad(result)
    }
    return result
  }

  public createRecord = async programmersModel => {
    return await this.serviceGateway.post(this.url, {
      custom_field_group: this.getDtoFromProgrammersModel(programmersModel)
    })
  }

  @action
  public postLoad = rawData => {
    const record = rawData.body.custom_field_group
    this.recordDTO = record
    this.selectedId = record.id
    this.setProgrammersModel()
  }

  @action
  public setProgrammersModel = () => {
    this.recordForEdit = {
      id: this.recordDTO.id,
      name: unescape(this.recordDTO.name),
      default: this.recordDTO.default
    }
  }

  private getDtoFromProgrammersModel = (programmersModel: ICustomFieldGroupPM): ICustomFieldGroupDTO => {
    const dto: ICustomFieldGroupDTO = {
      name: programmersModel.name
    }

    if (programmersModel.id) {
      dto.id = programmersModel.id
    }

    return dto
  }
}
