// @ts-nocheck
import { injectable } from 'inversify'
import { observable, action } from 'mobx'

import { ButtonPresenter } from 'Components/Molecules/Button'
import { container } from 'app/Config/IOC'
import { ILanguageService } from 'Helpers/Language/ILanguageService'
import { IUserAppToken } from 'app/Repositories/User/UserAppTokenRepository'
import { Types } from 'Gateways/Service/Types'

@injectable()
export class UserAppTokenPresenter {
  public language: ILanguageService = container.get(Types.ILanguageService)

  @observable
  public buttonPresenter = new ButtonPresenter('', () => {
    this.revokeOpenState = true
  })
    .withIconName('trash-o')
    .withDisplayStyle('secondary-invert')
    .withAriaLabel(this.language.get('common:ariaLabels:revoke'))

  @action
  public setRevokeOpenState = (state: false) => {
    this.revokeOpenState = state
  }

  @observable
  public cancelRevokeButtonPresenter = new ButtonPresenter(this.language.get('common:cancel'), () => {
    this.revokeOpenState = false
  })
    .withDisplayStyle('secondary')
    .withIconName('dnd-forwardslash')
    .withTextTransform('Uppercase')

  @observable
  public confirmRevokeButtonPresenter = new ButtonPresenter(this.language.get('common:revoke'), async () => {
    await this.revokeTokenAction(this.token.id)
    this.revokeOpenState = false
  })
    .withDisplayStyle('primary')
    .withIconName('trash-o')
    .withTextTransform('Uppercase')

  @observable
  public revokeOpenState = false

  @observable
  public canRevokeToken: boolean = false

  @observable
  public token: IUserAppToken

  @observable
  public revokeTokenAction: (tokenId: number) => void

  constructor(
    token: IUserAppToken,
    canRevokeToken: boolean = false,
    revokeTokenAction: (tokenId: number) => void = () => {}
  ) {
    this.token = token
    this.canRevokeToken = canRevokeToken
    this.revokeTokenAction = revokeTokenAction
  }
}
