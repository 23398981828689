import { useCallback, useEffect, useState } from 'react'
import { eventManager } from 'event-manager'
import { FormProvider, useForm } from 'react-hook-form'

import { Box, FormWrapper, Message } from '@cutover/react-ui'
import { DynamicFormFields, DynamicFormFieldsConfig, DynamicFormInput, generateYupSchema } from 'main/components/shared'
import { RunbookChangeRequestsResponse } from 'main/services/api/data-providers/runbook-types'
import { RunbookChannelProvider } from 'main/services/api/websocket-providers'
import { useLanguage, useRunbookWebsocket } from 'main/services/hooks'
import { useChangeRequestCreate } from 'main/services/queries/use-change-request-create'
import { useChangeRequestFormQuery } from 'main/services/queries/use-change-request-form-query'
import { useRunbookTasksQuery } from 'main/services/queries/use-runbook-tasks-query'

export type CreateChangeRequestFormProps = {
  setOnClickConfirm: (onSubmit: (() => void) | undefined) => void
  setSubmitting: (submitting: boolean) => void
  onSuccess?: () => void
  onError?: () => void
  onChangeRequestCreate: (id: number) => void
  runbookId: string
  changeRequestType: string
  parentChangeRequestId?: number | undefined
  parentChangeRequestExternalId?: string | undefined
  changeRequestId: number | undefined
}

export const CreateChangeRequestFormWithRunbookWebsocket = (props: CreateChangeRequestFormProps) => {
  return (
    <RunbookChannelProvider>
      <CreateChangeRequestForm {...props} />
    </RunbookChannelProvider>
  )
}

const CreateChangeRequestForm = ({
  setOnClickConfirm,
  setSubmitting,
  onSuccess,
  onError,
  runbookId,
  changeRequestType,
  parentChangeRequestId,
  parentChangeRequestExternalId,
  onChangeRequestCreate,
  changeRequestId
}: CreateChangeRequestFormProps) => {
  const {
    isLoading,
    data: changeRequestFormData,
    isError
  } = useChangeRequestFormQuery(runbookId, changeRequestType, parentChangeRequestId, parentChangeRequestExternalId)
  const { data: taskArray } = useRunbookTasksQuery(runbookId)
  const [selectableTaskArray, setSelectableTaskArray] = useState<{} | undefined>(undefined)
  const createMutation = useChangeRequestCreate()
  const [apiError, setApiError] = useState<string | null>(null)
  const [formError, setFormError] = useState<string | null>(null)
  const { listen } = useRunbookWebsocket()
  const { t } = useLanguage('changeRequests', { keyPrefix: 'modal' })

  const methods = useForm<DynamicFormFieldsConfig>({
    mode: 'onSubmit',
    resolver: changeRequestFormData && generateYupSchema(changeRequestFormData)
  })

  const {
    formState: { isValid },
    handleSubmit
  } = methods

  const displayErrorOrClose = useCallback(
    (data: RunbookChangeRequestsResponse) => {
      if (
        data?.change_requests &&
        data?.meta?.description === 'change_requests_response' &&
        data?.change_requests?.[0]?.id === changeRequestId
      ) {
        setSubmitting(false)
        const change_request = data.change_requests[0]
        if (change_request.current_state === 'error') {
          setApiError(change_request.error)
        } else {
          eventManager.emit('change-request-created', { changeRequests: change_request })
          onSuccess?.()
        }
      }
    },
    [changeRequestId]
  )

  const onClickConfirm = (input: DynamicFormInput) => {
    setSubmitting(true)
    const data = { runbook_id: runbookId, change_request: { input: input, metadata: changeRequestFormData?.metadata } }
    createMutation.mutate(data, {
      onSuccess: data => {
        onChangeRequestCreate(data[0].id)
      },
      onError: () => {
        setApiError(t('create_error'))
        onError?.()
      }
    })
  }

  useEffect(() => {
    const selectableTaskArray = taskArray?.map(task => {
      return {
        label: task.name,
        value: task.id
      }
    })
    setSelectableTaskArray(selectableTaskArray)
  }, [taskArray])

  useEffect(() => {
    if (changeRequestFormData?.errors && changeRequestFormData?.errors.length > 0) {
      const errors = changeRequestFormData?.errors.toString().match(/"(.*?)"/g)
      if (errors) {
        let full_error = ''
        errors.forEach(error => {
          error = error.split('"')[1]
          const error_message = error.split('[')[0] + ':<br>'
          const errors_list = error
            .substring(error.indexOf('[') + 1, error.lastIndexOf(']'))
            .split(':')
            .join('\u2022 ')
            .replace(', ', '<br>')
          full_error += error_message + errors_list + '<br>'
        })
        setFormError(full_error)
      }
    }
    setOnClickConfirm(() => handleSubmit(onClickConfirm))
  }, [changeRequestFormData])

  useEffect(() => {
    listen(data => displayErrorOrClose(data as RunbookChangeRequestsResponse))
  }, [displayErrorOrClose])

  useEffect(() => {
    setApiError(null)
  }, [isValid])

  return (
    <Box>
      <FormProvider {...methods}>
        <form>
          {isLoading || !selectableTaskArray ? (
            <div>Loading...</div>
          ) : isError || formError ? (
            <Message type="error" message={formError || 'Unable to load dynamic form'} />
          ) : (
            <FormWrapper>
              {apiError ? (
                <Box margin={{ bottom: 'medium' }}>
                  <Message type="error" message={apiError} />
                </Box>
              ) : null}
              <Box
                gap="small"
                css={`
                  margin-bottom: 50px;
                `}
              >
                <DynamicFormFields elements={changeRequestFormData?.elements} taskArray={selectableTaskArray} />
              </Box>
            </FormWrapper>
          )}
        </form>
      </FormProvider>
    </Box>
  )
}
