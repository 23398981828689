// @ts-nocheck
import 'reflect-metadata'
import { computed, observable, action } from 'mobx'

export class TotalDataItemCounter {
  @observable
  public totalDataItemsFromApi: number

  @observable
  public totalDataItemsFromDataArray: number

  @observable
  public totalFilteredUsersCount: number

  @action
  public update(dataLength: number, totalUsersCount: number) {
    this.totalDataItemsFromDataArray = dataLength
    this.totalDataItemsFromApi = totalUsersCount
  }

  @computed
  public get count(): number {
    let totalDataItems = 0
    if (this.totalFilteredUsersCount < (this.totalDataItemsFromApi || this.totalDataItemsFromDataArray)) {
      totalDataItems = this.totalFilteredUsersCount
    } else {
      totalDataItems = this.totalDataItemsFromApi ? this.totalDataItemsFromApi : this.totalDataItemsFromDataArray
    }
    return totalDataItems
  }
}
