import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Image = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Image" {...props}>
    <path d="M19 16a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm-7 14.879v4.242l5-5 3 3 8-8 7.94 7.94L36 33v-4.122l-8-8-8 8-3-3-5 5Z" fill="#000" /><path d="M14 6a8 8 0 0 0-8 8v20a8 8 0 0 0 8 8h20a8 8 0 0 0 8-8V14a8 8 0 0 0-8-8H14Zm20 3a5 5 0 0 1 5 5v20a5 5 0 0 1-5 5H14a5 5 0 0 1-5-5V14a5 5 0 0 1 5-5h20Z" fill="#000" />
  </StyledIcon>
))

Image.displayName = 'Image'

export { Image }
