import { RequireAtLeastOne, Simplify } from 'type-fest'

import { SavedFilter } from 'main/services/api/data-providers/runbook-types'
import {
  useGetAllSavedFilters,
  useGetAllSavedFiltersBy,
  useGetAllSavedFiltersByCallback,
  useGetAllSavedFiltersCallback,
  useGetBySavedFilter,
  useGetBySavedFilterCallback,
  useGetSavedFilter,
  useGetSavedFilterCallback,
  useGetSavedFilterLookup,
  useGetSavedFilterLookupCallback,
  useGetSavedPirFilter,
  useGetSavedPirFilterCallback,
  useOnActionSavedFilter
} from '../hooks'
import {
  CollectionModelInterfaceBase,
  ModelSingleAccessBy,
  CollectionModelInterfaceWithActions as WithActions,
  CollectionModelInterfaceWithCollectionAccessBy as WithCollectionAccessBy,
  CollectionModelInterfaceWithSingleAccessBy as WithSingleAccessBy
} from './types'

type SavedFilterSingleAccessBy = ModelSingleAccessBy<Pick<SavedFilter, 'default'>>
type SavedFilterCollectionAccessBy = RequireAtLeastOne<SavedFilter>

export type SavedFilterModelType = Simplify<
  CollectionModelInterfaceBase<SavedFilter> &
    WithSingleAccessBy<SavedFilter, SavedFilterSingleAccessBy> &
    Omit<WithActions<'Filter'>, 'usePermission' | 'usePermissionCallback'> &
    WithCollectionAccessBy<SavedFilter, SavedFilterCollectionAccessBy>
> & {
  useGetSavedPirFilter: () => SavedFilter | undefined
  useGetSavedPirFilterCallback: () => () => Promise<SavedFilter | undefined>
}

export const SavedFilterModel: SavedFilterModelType = {
  useGet: useGetSavedFilter,
  useGetCallback: useGetSavedFilterCallback,

  useGetBy: useGetBySavedFilter,
  useGetByCallback: useGetBySavedFilterCallback,

  useGetAll: useGetAllSavedFilters,
  useGetAllCallback: useGetAllSavedFiltersCallback,

  useGetAllBy: useGetAllSavedFiltersBy,
  useGetAllByCallback: useGetAllSavedFiltersByCallback,

  useGetLookup: useGetSavedFilterLookup,
  useGetLookupCallback: useGetSavedFilterLookupCallback,

  useOnAction: useOnActionSavedFilter,

  useGetSavedPirFilter,
  useGetSavedPirFilterCallback
}
