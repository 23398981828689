import { unescape } from 'lodash'

import { apiClient_UNSTABLE } from '../api'
import {
  RunbookStreamCreateResponse,
  RunbookStreamDestroyResponse,
  RunbookStreamShowResponse,
  RunbookStreamUpdateResponse
} from '../api/data-providers/runbook-types'
import { RagStatus, Role } from './types'

/* ------------------------------- Get Stream ------------------------------- */

type StreamGetProps = {
  runbookId: number | string
  runbookVersionId: number | string
  streamId: number | string
}

export const getStream = async ({ runbookId, runbookVersionId, streamId }: StreamGetProps) => {
  const { data } = await apiClient_UNSTABLE.get<RunbookStreamShowResponse>(
    `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/streams/${streamId}`
  )

  data.stream.name = unescape(data.stream.name)

  return data
}

/* ------------------------------ Create Stream ----------------------------- */

type StreamCreateProps = {
  runbookId: number | string
  runbookVersionId: number | string
}

export type StreamCreatePayload = {
  stream: {
    name: string
    parent_id: string | null
  }
}

export const createStream = async ({
  runbookId,
  runbookVersionId,
  ...payload
}: StreamCreateProps & StreamCreatePayload) => {
  const { data } = await apiClient_UNSTABLE.post<RunbookStreamCreateResponse>(
    `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/streams`,
    payload
  )

  return data
}

/* ------------------------------ Update Stream ----------------------------- */

type StreamUpdateProps = {
  runbookId: number
  runbookVersionId: number
  streamId: number
}

export type StreamUpdatePayload = {
  stream: {
    // color: string | null // TODO
    default_task_type_id?: number | null
    description?: string | null
    name: string
    roles: Role[]
    status: RagStatus
    status_message?: string | null
  }
  users_to_remove_from_runbook?: number[]
}

export const updateStream = async ({
  runbookId,
  runbookVersionId,
  streamId,
  ...payload
}: StreamUpdateProps & StreamUpdatePayload) => {
  const { data } = await apiClient_UNSTABLE.put<RunbookStreamUpdateResponse>(
    `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/streams/${streamId}`,
    payload
  )
  // TODO: look into this.processUsers (role_types.model.js) - here and elswhere
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return data!
}

/* ------------------------------ Delete Stream ----------------------------- */

type StreamDeleteProps = {
  runbookId: number
  runbookVersionId: number
  streamId: number
}

export type StreamDeletePayload = {
  new_stream_id?: number | null
}

export const deleteStream = async ({
  runbookId,
  runbookVersionId,
  streamId,
  ...payload
}: StreamDeleteProps & StreamDeletePayload) => {
  const { data } = await apiClient_UNSTABLE.patch<RunbookStreamDestroyResponse>(
    `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/streams/${streamId}`,
    payload
  )
  return data
}
