// @ts-nocheck
import { injectable } from 'inversify'
import { container } from 'app/Config/IOC'
import { computed, observable, action } from 'mobx'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'
import { Types } from 'Gateways/Service/Types'
import { TMode } from 'Components/Templates'
import { TaskTypesViewPresenter } from 'app/Settings/TaskTypes/TaskTypesPage/TaskTypesViewPresenter'
import { TaskTypeFormPresenter } from 'app/Settings/TaskTypes/TaskTypesPage/TaskTypeForm/TaskTypeFormPresenter'
import { ModalPresenter } from 'Components/Atoms'
import { ToasterService } from 'Components/Molecules'

@injectable()
export class TaskTypeEditPresenter {
  private taskTypesViewPresenter: TaskTypesViewPresenter = container.get(TaskTypesViewPresenter)

  @observable
  public responseErrors: string[] = []

  private language: ILanguageService = container.get(Types.ILanguageService)

  public title: string = this.language.get('taskTypes:edit:title')

  public archiveTooltip: string = this.language.get('taskTypes:edit:archiveTooltip')

  @observable
  public taskTypeFormPresenter: TaskTypeFormPresenter

  @observable
  public taskTypeEditPanelClearing = false

  @observable
  public archiveOpenState: boolean = false

  @observable
  public editPanelSaving: boolean = false

  private toasterService: ToasterService = container.get(ToasterService)

  @observable
  public archiveModalPresenter: ModalPresenter = new ModalPresenter()

  @observable
  public confirmModalPresenter: ModalPresenter = new ModalPresenter()

  @observable
  public triggerScroll = false

  @action
  public setTriggerScroll = (trigger: boolean) => {
    this.triggerScroll = trigger
  }

  public archiveButtonCallback = () => {
    this.taskTypesViewPresenter.pageVM.archiveModalOpenState = true
  }

  @computed
  public get mode(): TMode {
    if (this.taskTypesViewPresenter.taskTypeLoading) return 'loading'
    if (this.taskTypeEditPanelClearing) return 'panel-closed'
    if (this.taskTypesViewPresenter.taskTypeVM !== null) {
      return this.taskTypesViewPresenter.pageVM.editPanelDirty ? 'panel-open-confirm' : 'panel-open'
    }

    return 'panel-closed'
  }

  @computed
  public get canArchiveTaskType(): boolean {
    if (this.taskTypesViewPresenter.taskTypeVM !== null) {
      return (
        this.taskTypesViewPresenter.can('destroy') &&
        !this.taskTypesViewPresenter.taskTypeVM.default &&
        !this.taskTypesViewPresenter.taskTypeVM.archived
      )
    }
    return false
  }

  @action
  public closeCallback = () => {
    this.taskTypesViewPresenter.pageVM.editPanelOpen = false
  }

  @action
  public save = async () => {
    this.editPanelSaving = true
    const result = this.taskTypeFormPresenter.processSubmission()
    const formVM = result.viewModel

    this.taskTypesViewPresenter.updateTaskTypePm('name', formVM.name)
    this.taskTypesViewPresenter.updateTaskTypePm('visibilityType', formVM.account ? 'account' : 'global')

    if (formVM.account) {
      this.taskTypesViewPresenter.updateTaskTypePm(
        'accountId',
        this.taskTypesViewPresenter.getAccountIdFromVisibility(formVM.account)
      )
    }

    this.taskTypesViewPresenter.updateTaskTypePm('global', formVM.global ? true : false)
    this.taskTypesViewPresenter.updateTaskTypePm('defaultDuration', formVM.defaultDuration)
    this.taskTypesViewPresenter.updateTaskTypePm('highlight', formVM.highlight)
    this.taskTypesViewPresenter.updateTaskTypePm('autoStart', formVM.autoStart)
    this.taskTypesViewPresenter.updateTaskTypePm('autoFinish', formVM.autoFinish)
    this.taskTypesViewPresenter.updateTaskTypePm('conditionalProgression', formVM.conditionalProgression)
    this.taskTypesViewPresenter.updateTaskTypePm('enableStartFixed', formVM.enableStartFixed)
    this.taskTypesViewPresenter.updateTaskTypePm('enableEndFixed', formVM.enableEndFixed)
    this.taskTypesViewPresenter.updateTaskTypePm('linkable', formVM.linkable)
    this.taskTypesViewPresenter.refreshTaskTypePm()

    const response = await this.taskTypesViewPresenter.saveTaskType()
    if (!response.success) {
      if (response.errorMessages) {
        this.taskTypeFormPresenter.editForm.serverErrors = response.errorMessages
        this.taskTypeFormPresenter.errorAlertPresenter.setErrorMessages([...response.errorMessages])
      }
      this.taskTypesViewPresenter.submitted = false
      this.setTriggerScroll(true)
    } else {
      this.toasterService.pop(
        this.language.get('taskTypes:edit:notification:title'),
        this.language.get('taskTypes:edit:notification:notification'),
        'success'
      )
      this.taskTypesViewPresenter.loadTaskTypes()
      this.taskTypesViewPresenter.pageVM.editPanelDirty = false
      this.taskTypeFormPresenter.refresh()
    }
    this.editPanelSaving = false
  }

  @action
  public archive = async () => {
    const response = await this.taskTypesViewPresenter.archiveTaskType()
    if (!response.success) {
      this.responseErrors = response.body.errors
    } else {
      this.toasterService.pop(
        this.language.get('taskTypes:archive:notification:title'),
        this.language.get('taskTypes:archive:notification:notification'),
        'success'
      )
      this.taskTypeEditPanelClearing = false
      this.taskTypesViewPresenter.pageVM.archiveModalOpenState = false
      this.taskTypesViewPresenter.pageVM.editPanelOpen = false
      this.taskTypesViewPresenter.loadTaskTypes()
    }
  }

  @computed
  public get archiveConfirmName(): string {
    return this.taskTypesViewPresenter.taskTypeVM ? this.taskTypesViewPresenter.taskTypeVM.name : ''
  }

  @action
  public cancelArchiveCallback = () => {
    this.taskTypesViewPresenter.pageVM.archiveModalOpenState = false
    this.taskTypesViewPresenter.pageVM.editPanelOpen = false

    if (this.responseErrors.length > 0) {
      this.responseErrors = []
    }
  }

  public continueArchiveCallback = async () => {
    await this.archive()
  }

  @action
  public withTaskTypeFormPresenter = (taskTypeFormPresenter: TaskTypeFormPresenter) => {
    this.taskTypeFormPresenter = taskTypeFormPresenter
    return this
  }

  @action
  public reset = () => {
    this.taskTypesViewPresenter.pageVM.editPanelDirty = false
    this.taskTypeFormPresenter.reset()
    this.taskTypesViewPresenter.resetTaskTypeForEdit()
  }
}
