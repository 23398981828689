// @ts-nocheck

import styled from 'styled-components'

const PageWrapperInnerMain = styled.div`
  top: 0px;
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`

interface IPageWrapperInnerProps {
  extraStyles?: CSSProperties
  children: ReactNode
}

export const PageWrapperInner = (props: IPageWrapperInnerProps) => (
  <PageWrapperInnerMain style={props.extraStyles}>{props.children}</PageWrapperInnerMain>
)
