import { FrontendUserSettingType } from './types'
import { UserSettingsResponse, UserSettingUpdateResponse } from '../api/data-providers/user/user-channel-response-types'
import { apiClient } from 'main/services/api'

type FrontendUserSettingData = {
  id: number
  type: string
  data: { [x: string]: any }
}

export type FrontendUserSettingResponse = FrontendUserSettingData & {
  meta: {
    recipient_count: number
  }
}

export const getFrontendUserRunbookSetting = async (runbookId: string) => {
  const { data } = await apiClient['get']<UserSettingsResponse>({
    url: `user_settings?resource_id=${runbookId}&resource_type=Runbook`
  })
  return data
}

export const updateFrontendUserSetting = async (setting: FrontendUserSettingType) => {
  const { data } = await apiClient[setting.id ? 'patch' : 'post']<FrontendUserSettingType, UserSettingUpdateResponse>({
    url: setting.id ? `user_settings/${setting.id}` : 'user_settings',
    data: setting
  })
  return data
}
