import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Timeline = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Timeline" {...props}>
    <path d="M10.5 6a4.5 4.5 0 0 0 0 9h12a4.5 4.5 0 1 0 0-9h-12Zm12 3a1.5 1.5 0 0 1 0 3h-12a1.5 1.5 0 0 1 0-3h12Zm-2 10.5a4.5 4.5 0 1 0 0 9h17a4.5 4.5 0 1 0 0-9h-17Zm17 3a1.5 1.5 0 0 1 0 3h-17a1.5 1.5 0 0 1 0-3h17ZM11 37.5a4.5 4.5 0 0 1 4.5-4.5h11a4.5 4.5 0 1 1 0 9h-11a4.5 4.5 0 0 1-4.5-4.5Zm4.5-1.5a1.5 1.5 0 0 0 0 3h11a1.5 1.5 0 0 0 0-3h-11Z" fill="#000" />
  </StyledIcon>
))

Timeline.displayName = 'Timeline'

export { Timeline }
