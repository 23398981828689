// @ts-nocheck
import { IFilterKey, ISelectedFilter, IGenericFilter, TFilterType } from 'app/Filters/FilterEntities'
import { observable, computed } from 'mobx'
import { FiltersRepository } from 'app/Filters/FiltersRepository'
import { container } from 'app/Config/IOC'
import { GenericFormInputPresenter } from '@logicroom/validator'
import { TIcons } from 'Components/Atoms'
import { TToolTipPosition, ItemType, IAlignmentPosition } from 'Components/Molecules'
import { colors } from 'app/Styles/Variables'
import { Router } from 'app/Routing/Router'
import { TableScrollerService } from 'Shared/Components/Organisms'
import { Types } from 'Gateways/Service/Types'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'

export class CheckBoxFilterPresenter extends GenericFormInputPresenter<boolean> {
  constructor(key: IFilterKey) {
    super(false)
    this.filterKey = key

    // Some of the older pages are re-rendering after the value is reset to true e.g. task type admin page
    if (this.selectedFilter) {
      this.value = true
    }

    this.withMiddleware(newValue => {
      if (newValue.toString() === 'true') {
        this.filtersRepository.toggleFilterValue(this.pageFilter, 'true')
      }
      if (newValue.toString() === 'false') {
        this.isInverse = false
        this.filtersRepository.toggleFilterValue(this.pageFilter)
      }
      this.router.refreshFilteredRoute()
      const tableScrollerService: TableScrollerService = container.get(TableScrollerService)
      if (tableScrollerService.doScroll) tableScrollerService.doScroll()
      return newValue
    })
  }

  @observable
  public filtersRepository: FiltersRepository = container.get(FiltersRepository)

  public language: ILanguageService = container.get(Types.ILanguageService)

  @observable
  public router: Router = container.get(Router)

  @observable
  public isInverse: boolean = false

  @observable
  private filterKey: IFilterKey

  @observable
  public helpText?: string

  @observable
  public helpTextIcon?: TIcons

  @observable
  public itemType: ItemType = ItemType.checkBox

  public eventIconText: string = this.language.get('components:molecules:checkboxFilter:eventIconText')

  public eventIconTextPosition: TToolTipPosition = 'top center'

  public inputPlaceHolder: string

  public alignmentPosition: IAlignmentPosition = 'center'

  public hasHtmlLabel: boolean = false

  public radioButtonsPresenter = null

  public eventIconEvent = () => {
    this.toggleInverse()
  }

  @observable
  public oneLineTooltip: boolean = true

  @computed
  public get pageFilter(): IGenericFilter {
    return this.filtersRepository.pageFilters.find(filter => this.filterFinder(filter.key, filter.type))
  }

  @computed
  public get selectedFilter(): ISelectedFilter {
    return this.filtersRepository.selectedFilters.find(filter => this.filterFinder(filter.key, filter.type))
  }

  @computed
  public get filterValue(): boolean {
    if (this.selectedFilter && this.selectedFilter.value === 'true') return true
    if (this.selectedFilter && this.selectedFilter.value === 'false') return false
    return null
  }

  @computed
  public get label(): string {
    return this.pageFilter && this.pageFilter.canBeInverse && this.isInverse
      ? this.pageFilter.inverseLabel
      : this.pageFilter?.label
  }

  @computed
  public get eventIcon(): TIcons {
    return this.pageFilter && this.pageFilter.canBeInverse ? 'swap' : null
  }

  @computed
  public get eventIconVisible(): boolean {
    return this.value
  }

  @computed
  public get eventIconRotation(): number {
    return this.pageFilter && this.pageFilter.canBeInverse && this.isInverse ? 180 : 0
  }

  @computed
  public get eventIconColor(): string {
    return this.pageFilter && this.pageFilter.canBeInverse && this.isInverse ? colors.primary : colors.bgLight
  }

  @computed
  public get showLabel() {
    return this.itemType === ItemType.field || this.itemType === ItemType.checkBox
  }

  private filterFinder = (filterKey: IFilterKey, filterType: TFilterType) => {
    if (filterType === 'boolean' && filterKey.reference === this.filterKey.reference) {
      return true
    }
    if (
      filterType === 'resource' &&
      this.filterKey.reference === filterKey.reference &&
      this.filterKey.resourceId &&
      this.filterKey.resourceId === filterKey.resourceId
    ) {
      return true
    }
  }

  public toggleInverse = () => {
    this.isInverse = !this.isInverse
    if (this.isInverse) {
      this.filtersRepository.toggleFilterValue(this.pageFilter, 'false')
    } else {
      this.filtersRepository.toggleFilterValue(this.pageFilter, 'true')
    }
    this.router.refreshFilteredRoute()
  }

  public updateFromRepository = () => {
    const value = this.selectedFilter && this.selectedFilter.value
    const canBeInverse = this.pageFilter?.canBeInverse // optional chaining to fix settings menu error
    if (value === 'true') {
      this.value = true
      this.isInverse = false
      return
    }
    if (value === 'false' && canBeInverse) {
      this.value = true
      this.isInverse = true
      return
    }
    this.value = false
    this.isInverse = false
  }
}
