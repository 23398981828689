// @ts-nocheck
import { injectable, postConstruct } from 'inversify'
import { action, observable, observe, computed, Lambda } from 'mobx'
import { container } from 'app/Config/IOC'
import { IAccountEntry } from 'Shared/Entities/Types/Account/IAccountEntry'
import { IAccount } from 'Shared/Entities/Types/Account/IAccount'
import { IRoleType } from 'Shared/Entities/Types/RoleType/IRoleType'
import { Types } from 'Gateways/Service/Types'
import { ILanguageService } from 'Helpers/Language/ILanguageService'
import { ISortParams, sort } from 'Components/Organisms'
import { setReactDirty } from 'app/Shared/Helpers/SPASharedStateService'
import { AccountsRepository } from 'app/Repositories/Account/AccountsRepository'
import { AccountRepository } from 'app/Repositories/Account/AccountRepository'
import { UsersRepository } from 'app/Repositories/User/UsersRepository'
import { RoleTypesRepository } from 'app/Repositories/RoleType/RoleTypesRepository'
import { Router } from 'app/Routing/Router'
import { IAccountType } from 'app/Shared/Entities/Types/Account/IAccountType'
import { IPageVM } from 'Shared/Entities/IPageVM'
import { toggleAnimateCutoverLogo } from 'Shared/Helpers/NavLogoAnimation'

@injectable()
export class AccountsPageViewPresenter {
  private accountsRepository: AccountsRepository = container.get(AccountsRepository)
  private accountRepository: AccountRepository = container.get(AccountRepository)
  private usersRepository: UsersRepository = container.get(UsersRepository)
  private roleTypesRepository: RoleTypesRepository = container.get(RoleTypesRepository)
  private language: ILanguageService = container.get(Types.ILanguageService)
  public router: Router = container.get(Router)

  @observable
  public accountsVM: { [key: number]: string } = {}

  @observable
  private disposers: Lambda[] = []

  @observable
  public roleTypesVM: IRoleType[] = []

  @observable
  public repositoryLoading = false

  @observable
  public reloadList = false

  @observable
  public submitted: boolean = false

  @observable
  public addModalSubmitButtonLoading = false

  @observable
  public addModalSubmitButtonDisabled = false

  @action
  public clearDisposers = (): void => {
    this.disposers.forEach(disposer => disposer())
    this.disposers = []
  }

  @observable
  public pageVM: IPageVM = {
    editPanelOpen: false,
    editPanelDirty: false,
    editPanelClearing: false,
    editLoading: false,
    editPanelLoading: false,
    archiveModalOpenState: false
  }

  @observable
  public sortParams: ISortParams = {
    accessor: 'name',
    dataType: 'string',
    sortDirection: 'asc'
  }

  @postConstruct()
  public init() {
    this.load()
    this.router.registerRouteLeaveCallback({ routeId: 'accounts', callback: this.clear })
    this.router.registerRouteEnterCallback({ routeId: 'accounts', callback: this.load })
  }

  private clear = () => {
    this.clearDisposers()
    setTimeout(() => this.editPanelAfterCloseFunction(), 500)
  }

  public loadAccounts = async () => {
    await this.accountsRepository.loadData({ sortParams: this.sortParams })
  }

  public loadAccount = async (accountId: number) => {
    if (this.checkEditPanelIsDirty()) return
    await this.accountRepository.loadAccount(accountId)
  }

  public loadUserSearchConfig = (): ((query: any) => Promise<any>) => this.usersRepository.listUsers

  public loadRoleTypes = async () => {
    await this.roleTypesRepository.safeLoad({})
    this.roleTypesVM = this.roleTypesRepository.roleTypes
      .filter(roleType => roleType.contextTypeName === 'Workspace')
      .sort((roleTypeA, roleTypeB) => roleTypeA.name.localeCompare(roleTypeB.name))
  }

  public load = async () => {
    this.repositoryLoading = true
    toggleAnimateCutoverLogo(true)
    this.router.setCheckBeforeAction(this.checkEditPanelIsDirty)
    await this.loadAccounts()
    this.setObservers()
    this.repositoryLoading = false
    toggleAnimateCutoverLogo(false)
  }

  private setObservers = () => {
    const accountDisposer = observe(this.accountRepository, 'accountForEdit', () => {
      if (this.accountRepository.accountForEdit) {
        this.pageVM.editPanelOpen = true
      }
    })

    const accountsDisposer = observe(
      this.accountsRepository,
      'accounts',
      () => {
        if (this.accountsRepository.accounts.length > 0) {
          this.buildAccountDependencies()
        }
      },
      true
    )

    const formIsDirtyObserverDisposer = observe(this.pageVM, 'editPanelDirty', () => {
      setReactDirty(this.pageVM.editPanelDirty)
    })

    this.disposers = [accountDisposer, accountsDisposer, formIsDirtyObserverDisposer]
  }

  @action
  public setPageVMValue = (key: string, value: boolean): void => {
    this.pageVM[key] = value
  }

  @action
  public buildAccountDependencies = (): void => {
    let accountLookup: { [key: number]: string } = {}

    this.accountsRepository.accounts.forEach(account => {
      accountLookup[account.id] = account.name
    })

    this.accountsVM = accountLookup
  }

  @computed
  public get totalAccounts(): number {
    return this.accountsRepository.accountsTotal
  }

  @computed
  public get totalFilteredAccounts(): number {
    return this.accountsRepository.accountsFilteredTotal
  }

  @computed
  public get listVM(): IAccountEntry[] {
    return this.accountsRepository.accounts && this.accountsRepository.accounts.length > 0
      ? (sort(
          this.accountsRepository.accounts,
          this.sortParams.accessor,
          this.sortParams.dataType,
          this.sortParams.sortDirection
        ) as IAccountEntry[])
      : []
  }

  @computed
  public get accountVM(): IAccount {
    return this.accountRepository.accountForEdit
  }

  @computed
  public get accountTypes(): IAccountType[] {
    return this.accountsRepository.accountTypes
  }

  public clearFilters = (): void => {
    this.router.refreshFilteredRoute()
  }

  public getLabelForResource = (reference: string, resourceId: string): string => {
    return resourceId === '0' ? this.language.get('accounts:filter:global') : this.accountsVM[resourceId]
  }

  @computed
  public get accountLoading(): boolean {
    return this.accountRepository.loading
  }

  public saveAccount = async (accountToBeSaved: IAccount) => {
    toggleAnimateCutoverLogo(true)
    const response = await this.accountRepository.saveAccount(accountToBeSaved)
    toggleAnimateCutoverLogo(false)
    return response
  }

  public addAccount = async (newAccount: IAccount) => {
    toggleAnimateCutoverLogo(true)
    const response = await this.accountRepository.addAccount(newAccount)
    toggleAnimateCutoverLogo(false)
    return response
  }

  public archiveAccount = async () => {
    toggleAnimateCutoverLogo(true)
    const accountToBeDeleted = this.accountVM.id
    this.clearAccountForEdit()
    const response = await this.accountRepository.archive(accountToBeDeleted)
    toggleAnimateCutoverLogo(false)
    return response
  }

  @action
  public clearAccountForEdit = (): void => {
    this.accountRepository.clearSelectedId()
    this.accountRepository.accountForEdit = null
    this.pageVM.editPanelOpen = false
    this.pageVM.editPanelDirty = false
  }

  @action
  public editPanelAfterCloseFunction = (): void => {
    this.clearAccountForEdit()
    this.pageVM.editPanelClearing = false
  }

  @action
  public close = (): void => {
    if (this.checkEditPanelIsDirty()) return
    this.pageVM.editPanelClearing = true
    this.editPanelAfterCloseFunction()
  }

  public checkEditPanelIsDirty = (): boolean => {
    if (this.pageVM.editPanelDirty) {
      return true
    }
    return false
  }

  public can = (permissionType: string) => {
    if (permissionType === 'update' || permissionType === 'archive') {
      return this.accountRepository.can(permissionType)
    }
    return this.accountsRepository.can(permissionType)
  }

  @action
  public setSubmitted = (val: boolean) => {
    this.submitted = val
  }
}
