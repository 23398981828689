import { useEffect, useState } from 'react'
import { flatMap } from 'lodash'
import styled from 'styled-components/macro'

import { LegacyBox as Box, LegacyFlex as Flex, LoadingPanel, px2rem, SearchBar } from '@cutover/react-ui'
import { SnippetList } from './snippet-list'
import { SnippetsNotFound } from '../shared/snippets-not-found'
import { useLanguage } from 'main/services/hooks'
import { SnippetListSnippet, useSnippets } from 'main/services/queries/use-snippets'

type SelectSnippetProps = {
  accountId: string
  selectSnippet: (snippet: SnippetListSnippet) => void
}

export const SelectSnippet = ({ accountId, selectSnippet }: SelectSnippetProps) => {
  const [searchValue, setSearchValue] = useState('')
  const [isSearchCleared, setIsSearchCleared] = useState(false)
  const [atBottom, setAtBottom] = useState(false)

  const { isFetching, isFetchingNextPage, data, hasNextPage, fetchNextPage } = useSnippets(accountId, {
    offset: 0,
    limit: 20,
    q: searchValue,
    has_tasks: true,
    template_status: 'template_approved',
    sort_dir: 'desc',
    fields: ['id', 'name', 'tasks_count']
  })
  const snippets = data ? flatMap(data.pages, r => r.snippets) : []
  const totalResults = searchValue ? (data ? data.pages[0].meta.total_results : null) : null

  const { t } = useLanguage('runbook', { keyPrefix: 'snippetListModal' })

  const clearSearch = () => {
    setIsSearchCleared(true)
  }

  useEffect(() => {
    if (isSearchCleared) {
      setIsSearchCleared(false)
    }
  }, [searchValue])

  return (
    <Content flexDirection="column" atBottom={atBottom}>
      <SearchBar
        onSearch={setSearchValue}
        isSearchCleared={isSearchCleared}
        placeholder={t('searchPlaceholder')}
        open
        width="100%"
        suffix={
          totalResults !== null
            ? () => <Box css="font-size: 15px">{t('resultsCount', { count: totalResults })}</Box>
            : undefined
        }
      />
      <Box css="position: relative; flex-grow: 1">
        {snippets && snippets.length > 0 ? (
          <SnippetList
            snippets={snippets}
            hasNextPage={hasNextPage}
            isFetchingNextPage={isFetchingNextPage}
            fetchNextPage={fetchNextPage}
            selectSnippet={selectSnippet}
            setAtBottom={setAtBottom}
          />
        ) : isFetching ? (
          <LoadingPanel />
        ) : (
          <SnippetsNotFound search={searchValue} clearSearch={clearSearch} />
        )}
      </Box>
    </Content>
  )
}

const Content = styled(Flex)<{ atBottom: boolean }>`
  height: ${px2rem(367)};
  position: relative;
  ${({ atBottom }) =>
    !atBottom &&
    `
  &::after {
    content: '';
    position: absolute;
    bottom: ${px2rem(18)};
    left: 0;
    width: 100%;
    height: ${px2rem(40)};
    background: linear-gradient(
      to top,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0.8) 50%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  `}
`
