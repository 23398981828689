import { eventManager } from 'event-manager'
import { Component, ReactElement, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { AppProps as SSPAAppProps } from 'single-spa'

import { createComponent } from './main/components/portals/component-factory'

type State = {
  hasError: boolean
}

// This ReactPortalComponents component is rendered via a single-spa application
// within a div defined in the root HTML. It listens for mount/unmount/update events from
// a react-component AngularJS directive and renders the associated React component by component-id via React portals.
// This allows multiple React components to be rendered within the same AngularJS page, without requiring a change to the AngularJS build process.
// Other techniques should be favoured above this one.
// If possible favour rewriting entire pages in React, or using the existing react migration service/CustomPageComponent/react-div mechanism that
// allows a single React component to be rendered on an AngularJS page. See the frontend/js/services/README.md for more information.
export class ReactPortalComponents extends Component<SSPAAppProps, State> {
  public state: State = {
    hasError: false
  }

  public static getDerivedStateFromError() {
    return { hasError: true }
  }

  public componentDidCatch() {}

  public render() {
    if (this.state.hasError) {
      return null
    }

    return <ReactPortalComponentsInner />
  }
}

type ComponentPayload = {
  id: string
  componentId: string
  element: HTMLElement
  props: Record<string, unknown>
  onEvent: (data: unknown) => void
}

type Portal = {
  component: ReactElement
  element: HTMLElement
}

function ReactPortalComponentsInner() {
  const [portals, setPortals] = useState<Map<string, Portal>>(new Map())

  const handleComponentMount = ({ id, componentId, element, props, onEvent }: ComponentPayload) => {
    if (portals.has(id)) {
      throw new Error(`The component with id '${id}' is already mounted. Ensure each React component id is unique.`)
    }

    portals.set(id, { component: createComponent(componentId, { ...props, onEvent }), element })
    setPortals(new Map(portals))
  }

  const handleComponentUnmount = ({ id }: ComponentPayload) => {
    portals.delete(id)
    setPortals(new Map(portals))
  }

  const handleComponentUpdate = ({ id, componentId, element, props, onEvent }: ComponentPayload) => {
    portals.set(id, { component: createComponent(componentId, { ...props, onEvent }), element })
    setPortals(new Map(portals))
  }

  useEffect(() => {
    eventManager.on('react-portal-component-mount', handleComponentMount)
    eventManager.on('react-portal-component-unmount', handleComponentUnmount)
    eventManager.on('react-portal-component-update', handleComponentUpdate)

    return () => {
      eventManager.off('react-portal-component-mount', handleComponentMount)
      eventManager.off('react-portal-component-unmount', handleComponentUnmount)
      eventManager.off('react-portal-component-update', handleComponentUpdate)
    }
  }, [])

  if (portals.size === 0) {
    return null
  }

  return <>{Array.from(portals.values()).map(v => createPortal(v.component, v.element))}</>
}
