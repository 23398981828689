// @ts-nocheck
import { px2rem } from 'app/Styles/Variables'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { Draggable as DraggableComponent } from 'react-beautiful-dnd'
import { DraggablePresenter } from './DraggablePresenter'

interface IDragHandle {
  disabled?: boolean
}

const DragContainer = styled.div`
  display: flex;
  align-items: flex-start;
  padding: ${px2rem(2)} 0;
  position: static !important;
`

const DragHandle = styled.div<IDragHandle>`
  width: ${px2rem(15)};
  height: ${px2rem(28)};
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAIklEQVQIW2M8c+bMfwYgMDExYQTRIMCIVRAmi0zDtRAUBADX8ggG58ninQAAAABJRU5ErkJggg==)
    repeat;
  margin-right: ${px2rem(8)};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'move')} !important;
`

interface IDraggable {
  presenter: DraggablePresenter
  index: number
  disabled?: boolean
}

export const Draggable = observer<IDraggable>(({ presenter, index, disabled }) => {
  const { id, getContent, draggableConfig } = presenter

  return (
    <>
      {disabled ? (
        <DragContainer key={id}>
          <DragHandle disabled={disabled} />
          {getContent(draggableConfig)}
        </DragContainer>
      ) : (
        <DraggableComponent key={id} draggableId={id} index={index}>
          {(provided, snapshot) => (
            <DragContainer ref={provided.innerRef} {...provided.draggableProps} isDragging={snapshot.isDragging}>
              <DragHandle {...provided.dragHandleProps} />
              {getContent(draggableConfig)}
            </DragContainer>
          )}
        </DraggableComponent>
      )}
    </>
  )
})
