// @ts-nocheck
import { observable, computed, action, toJS } from 'mobx'
import { SelectPresenter, TSelectOptionTypes } from 'Components/Molecules'
import { container } from 'app/Config/IOC'
import { ConfigsRepository } from 'Configs/ConfigsRepository'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'

export class MultiSelectWithCapabilitiesPresenter {
  private configsRepository: ConfigsRepository = container.get(ConfigsRepository)

  public language: ILanguageService

  @observable
  public value = null

  @observable
  public optionReference: string

  @observable
  public allOptions: TSelectOptionTypes[] = []

  @observable
  public allSelectedOptions = []

  @observable
  public selectedOptions = []

  @observable
  public placeholder: string

  @observable
  public localDirty: boolean = false

  @observable
  public showLabelOnLoad: boolean = false

  @action
  public markAsDirty = () => {
    this.localDirty = true
  }

  @observable
  public capabilityReference: string

  @observable
  public capabilityOptions: TSelectOptionTypes[] = []

  @observable
  public globalOptionId: string = '1'

  @observable
  public contextId: number

  @observable
  public triggerScroll: boolean = false

  @observable
  public inModal = false

  @observable
  public disabled: boolean = false

  constructor(
    optionRef: string,
    capabilityRef: string,
    contextId: number,
    language: ILanguageService,
    disabled?: boolean
  ) {
    this.optionReference = optionRef
    this.capabilityReference = capabilityRef
    this.contextId = contextId
    this.language = language
    this.placeholder = this.language.get('components:molecules:multiSelectCapabilities:defaultPlaceholder')
    if (disabled) this.disabled = disabled
  }

  // internal functions

  @computed
  public get availableOptions() {
    let availableOptions = [...toJS(this.allOptions)]

    if (this.selectedOptions.length === 0) {
      return availableOptions
    }

    this.selectedOptions.map(option => {
      const index = availableOptions.findIndex(availableOption => {
        return availableOption.value === option.value
      })
      availableOptions.splice(index, 1)
    })

    return availableOptions
  }

  @computed
  public get label() {
    const resourceTypes = this.configsRepository.getConfigAsLabelValue('resourceTypes')
    const foundContexts = resourceTypes.filter(resourceType => resourceType.value === this.contextId.toString())
    const context = foundContexts[0]
    let label = ''

    if (context.label === 'Global') {
      label = this.language.get('components:molecules:multiSelectCapabilities:capabilities:global')
    } else {
      label = this.language.get('components:molecules:multiSelectCapabilities:capabilities:context', {
        interpolation: { escapeValue: true },
        context: context.label.toLowerCase()
      })
    }

    return label
  }

  private availableCapabilities = optionValue => {
    if (optionValue === this.contextId) {
      return this.capabilityOptions.filter(capability => {
        return capability.value === '1' || capability.value === '2'
      })
    }
    return this.capabilityOptions
  }

  @action
  public deselectOption = option => {
    this.markAsDirty()
    const newSelectedOptions = [...toJS(this.selectedOptions)]
    const index = this.selectedOptions.findIndex(selectedOption => {
      return selectedOption.value === option.value
    })
    newSelectedOptions.splice(index, 1)
    this.selectedOptions = newSelectedOptions
  }

  @action
  public selectOption = option => {
    this.markAsDirty()
    const newSelectedOptions = this.selectedOptions.length === 0 ? [] : [...toJS(this.selectedOptions)]
    newSelectedOptions.push({
      ...option,
      selectPresenter: new SelectPresenter(this.language, '1')
        .withOptions(this.availableCapabilities(option.value))
        .asInlineComponent()
        .withDisabled(this.disabled)
    })
    this.selectedOptions = newSelectedOptions
    this.value = null
    if (this.inModal) this.setTriggerScroll(true)
  }

  @action
  public setTriggerScroll = (trigger: boolean) => {
    this.triggerScroll = trigger
  }

  @computed
  public get values() {
    return this.selectedOptions.map(selectedOption => {
      let returnObj = {
        [this.optionReference]: parseInt(selectedOption.value),
        [this.capabilityReference]: parseInt(selectedOption.selectPresenter.value)
      }
      if (selectedOption.id) {
        returnObj.id = selectedOption.id
      }

      return returnObj
    })
  }

  @computed
  public get isDirty(): boolean {
    if (this.localDirty) return true
    const dirtySelects = this.selectedOptions.filter(option => option.selectPresenter.isDirty)
    if (dirtySelects.length > 0) return true
    return false
  }

  @action
  public withOptions = (options: TSelectOptionTypes[]) => {
    this.allOptions = options
    return this
  }

  @action
  public withCapabilities = (capabilities: TSelectOptionTypes[]) => {
    this.capabilityOptions = capabilities
    return this
  }

  @action
  public withSelectedOptions = (selectedOptions: { [index: string]: string | number }[]) => {
    let newSelectedOptions = []
    this.allSelectedOptions = selectedOptions

    toJS(selectedOptions).forEach(option => {
      const singleOption: any = toJS(this.allOptions).find(allOption => {
        return allOption.value === option[this.optionReference].toString()
      })
      singleOption.selectPresenter = new SelectPresenter(this.language, option[this.capabilityReference].toString())
        .withOptions(this.availableCapabilities(option[this.optionReference].toString()))
        .asInlineComponent()
        .withDisabled(this.disabled)
      singleOption.id = option.id
      if (singleOption) newSelectedOptions.push(singleOption)
    })

    this.selectedOptions = newSelectedOptions
    return this
  }

  @action
  public withPlaceholderText = (text: string) => {
    this.placeholder = text
    return this
  }

  @action
  public withInModal = () => {
    this.inModal = true
    return this
  }

  @action
  public withShowLabelOnLoad = () => {
    this.showLabelOnLoad = true
    return this
  }
}
