import { CustomFieldDisplayType, FieldValue, TaskListTask } from 'main/services/queries/types'
import { createTaskFilterFunction, RunbookFilterType, TaskFilterContext } from './filters'
import { urlValToArray } from 'main/recoil/shared/filters/controls/custom-field-text-search-control'
import { hasNone, isAny } from 'main/recoil/shared/filters/controls/any-or-none-fields'

/**
 * Parses a custom field query string into a JSON object
 * The query string in the browser can be: f=%7B"1":%5B1%5D,"295":"xyz"%7D
 * Decoded with decodeURIComponent becomes: "{\"1\":[1],\"295\":\"xyz\"}"
 * Parsed into JSON (JSON.parse(decodeURIComponent(...)) becomes:
 * {
 *   "1": [
 *     1
 *   ],
 *   "295": "xyz"
 * }
 * The key is the custom field id, the value is the current value, which can be
 *  - string for text and textarea fields
 *  - array for multi select fields (drop down or checkboxes)
 */
const parseFilterParams = (f: string): { [key: string]: unknown } => {
  return JSON.parse(decodeURIComponent(f))
}

/**
 * Checks if a field has a valid value to compare
 * @param field The current custom field value object
 * @param fieldType The type of the current field
 * @returns boolean
 */
const fieldHasValue = (field: Partial<FieldValue>, fieldType: string): boolean => {
  if (fieldType === 'checkboxes') {
    return field.value !== '[]'
  } else if (
    fieldType === 'text' ||
    fieldType === 'textarea' ||
    fieldType === 'datetime' ||
    fieldType === 'searchable'
  ) {
    return field.value !== ''
  } else if (fieldType === 'endpoint') {
    return field.value !== undefined && field.value !== null
  } else {
    return !!field.field_option_id
  }
}

export const isSearchableCustomField = (type: string) => {
  return ['SearchableCustomField', 'MultiSearchableCustomField', 'DependentCustomField'].includes(type)
}

export const isMultiSelectControl = (slug: CustomFieldDisplayType) => {
  const multiSelectTypes: CustomFieldDisplayType[] = [
    'checkboxes',
    'select_menu',
    'radiobox',
    'select_menu',
    'user_select',
    'searchable',
    'multi_searchable',
    'task_picker'
  ]
  return multiSelectTypes.includes(slug)
}

export const isDateControl = (slug: CustomFieldDisplayType) => {
  return slug === 'datetime'
}

const isTextControl = (slug: CustomFieldDisplayType) => {
  const textTypes: CustomFieldDisplayType[] = ['text', 'textarea', 'endpoint']
  return textTypes.includes(slug)
}

const checkboxesValueIncludes = (values: string, value: number) => {
  try {
    const parsedValues = JSON.parse(values)
    return parsedValues.includes(value)
  } catch (e) {
    return false
  }
}

/**
 * The custom field filter is an object with values keyed by custom field ID.
 * This function is called separately on each of this object's entries so that the
 * filters can be applied as independent filter groups, using a logical AND.
 * See filter-map.ts for how the 'f' filter is iterated and applied separately.
 *
 * @returns true if the custom field matches
 */
export const custom = createTaskFilterFunction(
  'f',
  (task: TaskListTask, filters: RunbookFilterType, context: TaskFilterContext): boolean => {
    if (filters.f === undefined) return false
    if (!context.customFields) return false
    // if we're coming from recoil state, f is already parsed. if we're coming from dashboard components, f comes in as a string
    const params = typeof filters.f === 'object' ? filters.f : parseFilterParams(filters.f as unknown as string) // If we are here f exists

    // We're only ever handling a single { custom_field_id: value } entry
    let [key, value] = Object.entries(params)[0]
    const cf = context.customFieldLookup?.[Number(key)]
    if (!cf) return false // guard, although might never be a case

    const isSearchable = isSearchableCustomField(cf.type)
    const fieldType = isSearchable ? 'searchable' : cf.field_type.slug
    const isText = isTextControl(cf.field_type.slug)
    const isDate = isDateControl(fieldType)

    const fieldValues = context.fieldValuesLookup?.[task.id][parseInt(key)]

    if (fieldValues && isAny(value)) {
      if (fieldHasValue(fieldValues[0], fieldType)) {
        return true
      }
    } else if (hasNone(value)) {
      if (!fieldValues || (fieldValues && !fieldHasValue(fieldValues[0], fieldType))) {
        return true
      }
    }

    if (fieldValues && fieldHasValue(fieldValues[0], fieldType)) {
      if (value !== null && Array.isArray(value)) {
        if (isDate) {
          // filter is an array of from, to with null in place of empty
          const [from, to] = urlValToArray(value as any)

          if (from && to && new Date(from) > new Date(to)) {
            console.warn('from date is greater than to date')
            return false
          }

          const fieldDate = new Date(fieldValues[0].value ?? 0)

          if (from && to) return fieldDate >= new Date(from) && fieldDate <= new Date(to)
          if (from) return fieldDate >= new Date(from)
          if (to) return fieldDate <= new Date(to)
        }
        // @ts-ignore
        else if (fieldValues.some(fv => value.includes(fv.field_option_id))) {
          return true
        } else if (
          fieldType === 'checkboxes' &&
          value.filter(q => checkboxesValueIncludes(fieldValues[0].value ?? '', q)).length > 0
        ) {
          return true
        } else if (isText && !isSearchable && fieldValues[0].value) {
          // Where field is of text type and value is an array, then "no value set" is selected (e.g. value = ['query', 0]).
          const filteredValue = value.filter(v => v !== 0)
          return patternMatcher(filteredValue[0], fieldValues[0].value)
        }
      } else if (
        isText &&
        value !== 0 &&
        fieldValues[0].value &&
        fieldValues[0].value.length > 0 &&
        patternMatcher(value as string, fieldValues[0].value)
      ) {
        return true
      }
    }

    return false
  }
)

function patternMatcher(pattern: string, text: string) {
  try {
    const escapedPattern = escapeRegExp(pattern)
    return new RegExp(escapedPattern, 'gi').test(text)
  } catch {
    return text.includes(pattern)
  }
}

function escapeRegExp(pattern: string) {
  return pattern.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
}
