// @ts-nocheck
import { useState, useEffect, useRef } from 'react'
import { Icon } from 'Components/Atoms'
import styled, { css } from 'styled-components'
import { colors, px2rem } from 'app/Styles/Variables'
import { observer } from 'mobx-react-lite'
import { SearchBoxPresenter } from './SearchBoxPresenter'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'

const Wrapper = styled.div`
  z-index: 3;

  ${({ isExpandable, searchOpen }: ContainerProps) =>
    isExpandable
      ? css`
          position: absolute;
          top: ${px2rem(12)};
          right: ${px2rem(12)};
          left: auto;
          width: ${searchOpen ? px2rem(300) : px2rem(32)};
        `
      : css`
          position: relative;
        `}
  height: ${px2rem(40)};
  transition: all 0.25s cubic-bezier(0.35, 0, 0.25, 1);
`
const SearchToggleButton = styled.button`
  padding-right: ${px2rem(10)};
  position: absolute;
  top: ${px2rem(0)};
  left: ${(props: { searchOpen: boolean }) => (props.searchOpen ? px2rem(4) : px2rem(0))};
  right: ${px2rem(0)};
  bottom: ${px2rem(0)};
  z-index: 4;
  line-height: ${px2rem(32)};
  font-size: ${px2rem(24)};
  width: ${px2rem(32)};
  margin-top: ${px2rem(6)};
  cursor: pointer;
  padding: 0;
  > i {
    ${(props: { searchOpen: boolean }) =>
      props.searchOpen
        ? `color: ${colors.primary};`
        : `
    cursor: pointer;
    color: ${colors.secondaryGrey};
    &:hover {
      color: ${colors.primaryGreyHover};
    }`}
  }
`

interface ContainerProps {
  searchOpen: boolean
  isExpandable: boolean
}

const Input = styled.input`
  position: absolute;
  overflow: visible;
  padding-left: ${px2rem(40)};
  background-color: ${colors.bg2};
  border: 0;
  box-sizing: border-box;
  font-size: ${px2rem(16)};
  height: ${px2rem(40)};
  line-height: ${px2rem(40)};
  border-radius: ${px2rem(20)};
  width: 100%;
  display: block;
`

const CloseSearchButon = styled.button`
  line-height: ${px2rem(40)};
  position: absolute;
  z-index: 4;
  opacity: 1;
  font-size: ${px2rem(24)};
  width: ${px2rem(32)};
  display: ${(props: ContainerProps) => (props.isExpandable && !props.searchOpen ? 'none' : 'block-inline')};
  position: absolute;
  top: ${px2rem(0)};
  bottom: ${px2rem(0)};
  left: auto;
  right: ${px2rem(4)};
  cursor: pointer;
  padding: 0;
  > i {
    &:hover {
      color: ${colors.primaryGreyHover};
    }
  }
`

interface ISearchBox {
  presenter: SearchBoxPresenter
  onFocus?: () => void
  searchContent?: string
  language: ILanguageService
  maxLength?: number
  isExpandable?: boolean
  hasCloseButton?: boolean
  placeholder?: string
  inputExtraStyles?: CSSProperties
  disabled?: boolean
  autoFocus?: boolean
}

export const SearchBox = observer<ISearchBox>(props => {
  const { value, onChange, filterValue } = props.presenter
  const {
    onFocus,
    searchContent,
    language,
    maxLength,
    isExpandable = true,
    hasCloseButton = true,
    placeholder = `${language.get('common:headingSection:searchBox:searchFor')}...`,
    inputExtraStyles = {},
    disabled = false,
    autoFocus
  } = props

  const [searchOpen, setSearchOpen] = useState(false)
  const ref = useRef(null)

  useEffect(() => {
    onChange(filterValue as string, false)
  }, [filterValue])

  const changeHandler = event => {
    onChange(event.target.value)
  }

  const processSearchClick = () => {
    isExpandable && setSearchOpen(!searchOpen)
  }

  useEffect(() => {
    if (searchOpen) {
      ref.current.focus()

      if (onFocus) {
        onFocus()
      }
    }
  }, [searchOpen])

  return (
    <Wrapper searchOpen={searchOpen} isExpandable={isExpandable} aria-expanded={searchOpen} role="button">
      <SearchToggleButton
        searchOpen={searchOpen}
        onClick={processSearchClick}
        aria-label={language.get('common:headingSection:searchBox:toggleSearch')}
      >
        <Icon name="search" extraStyles={{ fontSize: '24px' }} />
      </SearchToggleButton>
      <Input
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={autoFocus}
        value={value}
        onChange={changeHandler}
        type="text"
        id="search-runbook-templates"
        autoComplete="off"
        ref={ref}
        placeholder={placeholder}
        role="searchbox"
        aria-label={`${language.get('common:headingSection:searchBox:searchFor')}${
          searchContent ? ` ${searchContent}` : '...'
        }`}
        maxLength={maxLength}
        style={{ display: !searchOpen && isExpandable ? 'none' : 'block', ...inputExtraStyles }}
        aria-hidden={!searchOpen}
        disabled={disabled}
      />
      {hasCloseButton && (
        <CloseSearchButon
          searchOpen={searchOpen}
          isExpandable={isExpandable}
          onClick={processSearchClick}
          aria-hidden={!searchOpen}
          aria-label={language.get('common:headingSection:searchBox:closeSearch')}
        >
          <Icon name="close" color={colors.secondaryGrey} extraStyles={{ fontSize: '24px' }} />
        </CloseSearchButon>
      )}
    </Wrapper>
  )
})
