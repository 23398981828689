import { useEffect, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { eventManager } from 'event-manager'
import { useForm, useFormState } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'

import { Box, Button, Form, Message, TextInput } from '@cutover/react-ui'
import { AuthenticationFormWrapper } from './authentication-form-wrapper'
import { LoginPageOption } from './login-page'
import { FooterLinkText, LoggedOutHeading } from './login-styled'
import { LoginWarningMessage } from './login-warning-message'
import { useLanguage } from 'main/services/hooks'
import { LoginPayload, useLogin } from 'main/services/queries/use-login'
import { ConfigModel } from 'main/data-access'

export const EmailForm = ({
  handleLoginPageOption,
  loginWarningMessage
}: {
  handleLoginPageOption: (option: LoginPageOption) => void
  loginWarningMessage: string
}) => {
  const [logoutState] = useState<{ success?: string[]; error?: string[] }>(
    JSON.parse(localStorage.getItem('reactLogout') ?? '{}')
  )
  const { oauthActive } = ConfigModel.useGet()
  const [showRedirectMessage] = useState(localStorage.getItem('showRedirectMessage'))
  const [resetPasswordSuccess] = useState(localStorage.getItem('resetPasswordSuccess'))
  const [userVerifySuccess] = useState(localStorage.getItem('userVerifySuccess'))
  const [reactForbidden] = useState(localStorage.getItem('reactForbidden'))
  const [blockLogin, setBlockLogin] = useState(Boolean(loginWarningMessage))
  const [serverError, setServerError] = useState('')
  const { t } = useLanguage('authentication')
  const navigate = useNavigate()

  const { register, handleSubmit, control } = useForm<LoginPayload>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    resolver: yupResolver(
      yup.object({
        email: yup.string().email('incompleteForm').required('incompleteForm'),
        password: yup.string().required('incompleteForm')
      })
    ),
    shouldFocusError: false
  })

  const { errors } = useFormState({ control })
  const login = useLogin({
    onSuccess: user => {
      eventManager.emit('login', user)
      const redirect = sessionStorage.getItem('reactLoginRedirect')
      sessionStorage.removeItem('reactLoginRedirect')
      navigate(redirect || '/app/my-cutover')
    },
    onError: () => {
      setServerError('loginError.invalidCredentials')
    }
  })

  const handleLogin = (data: LoginPayload) => {
    if (serverError) setServerError('')
    login.mutate(data)
  }

  const toggleBlockLogin = () => {
    setBlockLogin(!blockLogin)
  }

  useEffect(() => {
    localStorage.removeItem('reactLogout')
  }, [logoutState])

  useEffect(() => {
    localStorage.removeItem('showRedirectMessage')
  }, [showRedirectMessage])

  useEffect(() => {
    localStorage.removeItem('reactForbidden')
  }, [reactForbidden])

  useEffect(() => {
    localStorage.removeItem('userVerifySuccess')
  }, [userVerifySuccess])

  useEffect(() => {
    localStorage.removeItem('resetPasswordSuccess')
  }, [resetPasswordSuccess])

  return (
    <>
      <Box width="320px">
        <LoggedOutHeading>{t('loginHeading')}</LoggedOutHeading>
        <Form onSubmit={handleSubmit(handleLogin)}>
          <AuthenticationFormWrapper>
            {reactForbidden && <Message message={t('forbiddenMessage')} type="error" margin />}
            {showRedirectMessage && <Message message={t('redirectMessage')} type="error" margin />}
            {logoutState?.success?.includes('user-updated') && (
              <Message message={t('userUpdatedMessage')} type="success" margin />
            )}
            {logoutState?.success?.includes('logout') && <Message message={t('logOutMessage')} type="success" margin />}
            {(Boolean(errors.email) || Boolean(errors.password) || serverError) && (
              <Message
                type="error"
                message={t(errors.email?.message || errors.password?.message || serverError)}
                margin
              />
            )}
            {Boolean(resetPasswordSuccess) && <Message type="success" message={t('passwordResetSuccess')} margin />}
            {userVerifySuccess && <Message message={t('userVerifyMessage')} type="success" margin />}
            {Boolean(loginWarningMessage) && (
              <LoginWarningMessage onClick={toggleBlockLogin} message={loginWarningMessage} />
            )}
            <TextInput
              {...register('email')}
              hasError={Boolean(errors.email)}
              required
              label={t('emailInputLabel')}
              data-testid="email-input"
              tooltipText={t('emailTooltip')}
              autoComplete="email"
            />
            <TextInput
              {...register('password')}
              hasError={Boolean(errors.password)}
              required
              label={t('passwordInputLabel')}
              type="password"
              data-testid="password-input"
              tooltipText={t('passwordTooltip')}
              autoComplete="current-password"
            />
            <Box margin={{ top: 'small' }}>
              <Button
                primary
                type="submit"
                data-testid="submit-button"
                full
                label={t('submitLogin')}
                loadingLabel={t('submittingLogin')}
                loading={login.isLoading}
                icon="arrow-forward"
                disabled={blockLogin}
              />
            </Box>
          </AuthenticationFormWrapper>
        </Form>
      </Box>
      <Box
        direction="row"
        gap="medium"
        alignSelf="center"
        margin={{ top: 'medium' }}
        css={`
          text-align: center;
        `}
      >
        <FooterLinkText onClick={() => navigate('/forgotpassword')}>{t('forgotPasswordLink')}</FooterLinkText>
        {oauthActive && <FooterLinkText onClick={() => handleLoginPageOption('sso')}>{t('ssoLink')}</FooterLinkText>}
      </Box>
    </>
  )
}
