import { useCallback, useEffect, useState } from 'react'
import { useEffectOnce } from 'react-use'

import { useMutationObserver } from 'main/services/hooks'

/*
  Default layout settings

  use this hook in layout files to set the defaults. If the layout also wraps views which do not follow this default
  pattern, use the specific hooks for that layout component to show/hide for specific route views.

  NOTE: booleans here mean that the layout has these components at all, not their current visibility in
  a case where they do exist.
*/
export type SetDefaultLayoutProps = {
  filterPanel: boolean
  subHeader: boolean
  rightNav: boolean
}

export const useDefaultLayout = ({ filterPanel, subHeader, rightNav }: SetDefaultLayoutProps) => {
  useEffectOnce(() => {
    const root = document.getElementById('page-root')
    if (!root) return

    setRightPanelLayoutClosed(root)
    if (!filterPanel) root.classList.add('filter-panel-disabled')
    if (!subHeader) root.classList.add('sub-header-disabled')
    if (!rightNav) root.classList.add('right-nav-disabled')

    return () => {
      setRightPanelLayoutClosed(root)
      root.classList.remove('filter-panel-disabled')
      root.classList.remove('sub-header-disabled')
      root.classList.remove('right-nav-disabled')
    }
  })
}

/*
  Right side navigation

  page navigation that appears to the right of the right side panel in non-mobile and on the bottom in mobile
*/
export const useRightNavLayout = () => {
  const enableRightNav = () => {
    const root = document.getElementById('page-root')
    if (!root) return

    root.classList.remove('right-nav-disabled')
  }

  const disableRightNav = () => {
    const root = document.getElementById('page-root')
    if (!root) return

    root.classList.add('right-nav-disabled')
  }

  return { enableRightNav, disableRightNav }
}

export const useHideRightNav = () => {
  const { disableRightNav, enableRightNav } = useRightNavLayout()

  useEffectOnce(() => {
    disableRightNav()

    return () => enableRightNav()
  })
}

export const useShowRightNav = () => {
  const { disableRightNav, enableRightNav } = useRightNavLayout()

  useEffectOnce(() => {
    enableRightNav()

    return () => disableRightNav()
  })
}

/*
  Sub header

  appears below the main header
*/
export const useSubHeaderLayout = () => {
  const enableSubHeader = () => {
    const root = document.getElementById('page-root')
    if (!root) return

    root.classList.remove('sub-header-disabled')
  }

  const disableSubHeader = () => {
    const root = document.getElementById('page-root')
    if (!root) return

    root.classList.add('sub-header-disabled')
  }

  return { enableSubHeader, disableSubHeader }
}

export const useDisableSubHeader = () => {
  const { disableSubHeader, enableSubHeader } = useSubHeaderLayout()

  useEffectOnce(() => {
    disableSubHeader()

    return () => enableSubHeader()
  })
}

export const useEnableSubHeader = () => {
  const { disableSubHeader, enableSubHeader } = useSubHeaderLayout()

  useEffectOnce(() => {
    enableSubHeader()

    return () => disableSubHeader()
  })
}

/*
  Filter panel

  appears to the left of the main content
*/
export const useToggleFilterPanel = ({ onClose, onOpen }: { onClose?: () => void; onOpen?: () => void } = {}) => {
  return useCallback(
    (open?: boolean) => {
      const root = document.getElementById('page-root')
      const filterPanel = document.getElementById('layout-filter-panel')
      if (!root || !filterPanel) return

      const shouldOpen = open === undefined ? !root.classList.contains('filter-panel-open') : open

      if (shouldOpen) {
        onOpen?.()
        root.classList.add('filter-panel-open')
        filterPanel.classList.add('open')
        return true
      } else {
        onClose?.()
        root.classList.remove('filter-panel-open')
        filterPanel.classList.remove('open')
        return false
      }
    },
    [onClose, onOpen]
  )
}

export const useFilterPanelLayout = () => {
  const enableFilterPanel = () => {
    const root = document.getElementById('page-root')
    if (!root) return

    root.classList.remove('filter-panel-disabled')
  }

  const disableFilterPanel = () => {
    const root = document.getElementById('page-root')
    if (!root) return

    root.classList.add('filter-panel-disabled')
  }

  return { enableFilterPanel, disableFilterPanel }
}

export const useDisableFilterPanel = () => {
  const { disableFilterPanel, enableFilterPanel } = useFilterPanelLayout()

  useEffectOnce(() => {
    disableFilterPanel()

    return () => enableFilterPanel()
  })
}

export const useEnableFilterPanel = () => {
  const { disableFilterPanel, enableFilterPanel } = useFilterPanelLayout()

  useEffectOnce(() => {
    enableFilterPanel()

    return () => disableFilterPanel()
  })
}

export const useFilterPanelOpenState = () => {
  const root = document.getElementById('page-root')
  const [filterPanelElement, setFilterPanelElement] = useState<HTMLElement | null>(null)
  const [isOpen, setIsOpen] = useState(() => {
    if (!root) return false

    return root.classList.contains('filter-panel-open')
  })

  useEffect(() => {
    if (!root) return
    if (!filterPanelElement) setFilterPanelElement(document.getElementById('layout-filter-panel'))

    root.classList.contains('filter-panel-open') ? setIsOpen(true) : setIsOpen(false)
  }, [root])

  useMutationObserver(
    filterPanelElement,
    mutations => {
      for (const mutation of mutations) {
        if (mutation.attributeName === 'class') {
          ;(mutation.target as HTMLElement).classList.contains('open') ? setIsOpen(true) : setIsOpen(false)
        }
      }
    },
    { childList: false, attributes: true }
  )

  return isOpen
}

export const useToggleRightPanelLayout = () => {
  return useCallback(toggleRightPanelLayout, [])
}

/**
 * Used for toggling the right panel where a hook can't be used,
 * such as in the righPanelState recoil selector.
 */
export const toggleRightPanelLayout = (open: boolean) => {
  const root = document.getElementById('page-root')
  const rightPanel = document.getElementById('layout-right-panel')

  if (!root) return

  if (open) {
    setRightPanelLayoutOpen(root, rightPanel)
  } else {
    setRightPanelLayoutClosed(root, rightPanel)
  }
}

export const useRightPanelLayoutOpenState = () => {
  const [isOpen, setIsOpen] = useState(() => {
    const root = document.getElementById('page-root')
    if (!root) return false

    return root.classList.contains('right-panel-open')
  })

  useMutationObserver(
    document.getElementById('layout-right-panel'),
    mutations => {
      for (const mutation of mutations) {
        if (mutation.attributeName === 'class') {
          ;(mutation.target as HTMLElement).classList.contains('open') ? setIsOpen(true) : setIsOpen(false)
        }
      }
    },
    { childList: false, attributes: true }
  )

  return isOpen
}

/*
  Banner
*/
/** Shows or hides banner space underneath page inner content */
export const useBannerLayout = () => {
  const showBannerLayout = () => {
    const root = document.getElementById('page-root')
    if (!root) return

    root.classList.add('show-banner')
  }

  const hideBannerLayout = () => {
    const root = document.getElementById('page-root')
    if (!root) return

    root.classList.remove('show-banner')
  }

  return { showBannerLayout, hideBannerLayout }
}

const setRightPanelLayoutClosed = (rootEl: HTMLElement, rightPanelEl?: HTMLElement | null) => {
  rootEl.classList.remove('right-panel-open')
  const panelEl = rightPanelEl || document.getElementById('layout-right-panel')
  panelEl?.classList.remove('open')
}

const setRightPanelLayoutOpen = (rootEl: HTMLElement, rightPanelEl?: HTMLElement | null) => {
  rootEl.classList.add('right-panel-open')
  const panelEl = rightPanelEl || document.getElementById('layout-right-panel')
  panelEl?.classList.add('open')
}
