// @ts-nocheck
import styled from 'styled-components'
import { RoleTypesPageFilterPanelPresenter } from './RoleTypesPageFilterPanelPresenter'
import { observer } from 'mobx-react-lite'
import { FilterDisclosurePanel } from 'Components/Molecules'

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: auto;
  box-sizing: border-box;
`

const FilterWrapper = styled.div`
  margin: 0 0 56px 16px;
`

interface IRoleTypesPageFilterPanelComponentProps {
  presenter: RoleTypesPageFilterPanelPresenter
}

export const RoleTypesPageFilterPanelSection = observer<IRoleTypesPageFilterPanelComponentProps>(props => {
  const { contextPresenter } = props.presenter

  return (
    <Wrapper>
      <FilterWrapper>
        <FilterDisclosurePanel presenter={contextPresenter} />
      </FilterWrapper>
    </Wrapper>
  )
})
