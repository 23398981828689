import { deepmerge } from '@cutover/deepmerge'
import { processRequestData, processResponseData } from './process-data'
import {
  ApiClient,
  CreateApiClientOptions,
  HttpMethod,
  RequestOptions,
  RequestWithBodyOptions,
  RequestWithoutBodyOptions,
  Response
} from './types'

export function createApiClient({
  defaultConfiguration,
  configuration,
  requestAdapter
}: CreateApiClientOptions): ApiClient {
  async function request<TRequestData, TResponseData>(
    options: RequestOptions<TRequestData>
  ): Promise<Response<TResponseData>> {
    const requestOptions = deepmerge<RequestOptions<TRequestData>>(
      defaultConfiguration,
      configuration ? await configuration() : undefined,
      options
    )

    return requestAdapter<TRequestData, TResponseData>(requestOptions)
  }

  const makeRequest =
    (method: HttpMethod) =>
    async <TRequestData, TResponseData>({
      url,
      data,
      errorResponseProperty,
      params,
      config,
      headers = {}
    }: RequestWithBodyOptions<TRequestData>) => {
      const response = await request<TRequestData, TResponseData>({
        url,
        method,
        headers,
        data: processRequestData({
          data
        }),
        params,
        config,
        errorResponseProperty
      })

      if (config && config.responseType === 'arraybuffer') {
        return response
      }

      return {
        ...response,
        data: processResponseData({
          data: response.data
        })
      }
    }

  return {
    request,
    get: async <TResponseData>(options: RequestWithoutBodyOptions) => {
      const requester = makeRequest(HttpMethod.Get)
      const response = await requester<never, TResponseData>(options)

      if (!response.data) {
        throw new Error('No response data for a GET request.')
      }

      return {
        ...response,
        data: response.data as TResponseData
      }
    },
    post: makeRequest(HttpMethod.Post),
    put: makeRequest(HttpMethod.Put),
    patch: makeRequest(HttpMethod.Patch),
    delete: makeRequest(HttpMethod.Delete)
  }
}
