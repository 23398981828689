import { Simplify } from 'type-fest'

import { RunbookVersion } from 'main/services/queries/types'
import {
  useCanActionRunbookVersion,
  useCanActionRunbookVersionCallback,
  useCanRunbookVersion,
  useGetRunbookVersion,
  useGetRunbookVersionCallback,
  useGetRunbookVersionId,
  useGetRunbookVersionIdCallback,
  useGetRunbookVersionLoadable,
  useGetRunbookVersionLoadableCallback,
  useOnActionRunbookVersion,
  useReloadRunbookVersion,
  useReloadRunbookVersionSync
} from '../hooks'
import {
  ActiveModelInterfaceBase,
  ActiveModelInterfaceWithActions as WithActions,
  ActiveModelInterfaceWithReload as WithReload
} from './types'
import { RunbookVersionPermissionsResponse } from 'main/services/queries/use-runbook-versions'

export type ActiveRunbookVersionModelType = Simplify<
  ActiveModelInterfaceBase<RunbookVersion, RunbookVersionPermissionsResponse> &
    WithActions<RunbookVersion, 'RunbookVersion', 'convert_to_template' | 'create' | 'runbook_version_import'> &
    WithReload
>

export const ActiveRunbookVersionModel: ActiveRunbookVersionModelType = {
  useGet: useGetRunbookVersion,
  useGetCallback: useGetRunbookVersionCallback,

  useGetLoadable: useGetRunbookVersionLoadable,
  useGetLoadableCallback: useGetRunbookVersionLoadableCallback,

  useCan: useCanRunbookVersion,

  useOnAction: useOnActionRunbookVersion,

  usePermission: useCanActionRunbookVersion,
  usePermissionCallback: useCanActionRunbookVersionCallback,

  useId: useGetRunbookVersionId,
  useIdCallback: useGetRunbookVersionIdCallback,

  useReload: useReloadRunbookVersion,
  useReloadSync: useReloadRunbookVersionSync
}
