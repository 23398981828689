import { ReactNode } from 'react'

import { Box } from '@cutover/react-ui'
import { useComponentProps } from '../apps-state'
import { AppComponentNodeProps } from '../apps-types'

type LineItemNodeProps = AppComponentNodeProps & {
  children: ReactNode
}

export const LineItemNode = ({ appId, resourceId, id, ...props }: LineItemNodeProps) => {
  const { children } = useComponentProps(appId, resourceId, id, props) as LineItemNodeProps

  return (
    <Box direction="row" gap="xsmall" align="center">
      {children}
    </Box>
  )
}
