import { memo, ReactNode, useEffect, useState } from 'react'

import { Modal } from '@cutover/react-ui'
import { usePostAppEvents } from './apps-api'
import { AppsComponentParser } from './apps-component-parser'
import { AppModalViewState } from './apps-types'
import {
  useAppsFieldValuesCallback,
  useAppsModalLoadingState,
  useResetAppsModalState,
  useSetAppsFieldValues
} from 'main/recoil/data-access'

type AppsModalProps = {
  modal: AppModalViewState
}

export const AppsModal = memo(({ modal }: AppsModalProps) => {
  const [modalContent, setModalContent] = useState<ReactNode | null>(null)
  const resetModalState = useResetAppsModalState()
  const postAppEvents = usePostAppEvents()
  const { resourceId, appId, view, open } = modal
  const [loading, setLoading] = useAppsModalLoadingState()

  const {
    back_arrow: backArrow,
    block_dismissal: blockDismissal,
    hide_footer: hideFooter,
    content,
    button
  } = view ?? {}
  const context = `${resourceId}-${appId}`
  const getfieldValues = useAppsFieldValuesCallback()
  const setAppFieldValues = useSetAppsFieldValues()

  const handleModalActions = async (button: string) => {
    const values = await getfieldValues()
    const payload = {
      app_id: appId,
      runbook_id: resourceId,
      events: view ? [view] : undefined,
      state: { ...values[context], ...{ _modal_button: button } }
    }
    setLoading(true)
    postAppEvents(payload)
  }

  /**
   * Optimizes modal rendering by updating the content only when a relevant change is detected.
   *
   * NOTE: This workaround is specific to app modals due to the current architecture,
   * where the React AppsContainer is mounted from multiple locations in the Angular codebase.
   *
   * This is a temporary solution, intended to be removed after the Incident runbook
   * completes its migration to React.
   */

  useEffect(() => {
    const modalContent = <AppsComponentParser content={content ?? []} appId={appId} resourceId={resourceId} />
    setModalContent(modalContent)
  }, [modal])

  return (
    <>
      {view ? (
        <Modal
          onClickBack={backArrow ? () => handleModalActions('back') : undefined}
          dismissible={!blockDismissal}
          hasCancelButton={!blockDismissal}
          confirmText={button?.value}
          confirmIcon={button?.icon}
          hideFooter={hideFooter}
          loading={loading}
          onClickConfirm={() => {
            handleModalActions('confirm')
          }}
          onAfterClose={() => {
            resetModalState()
            setAppFieldValues(prevValues => {
              return { ...prevValues, [context]: {} }
            })
          }}
          open={open}
          title={view.header?.title || ''}
        >
          {content && !!content.length && modalContent}
        </Modal>
      ) : null}
    </>
  )
})
