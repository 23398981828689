// @ts-nocheck
import styled from 'styled-components'
import { Heading } from 'Components/Atoms'
import { Button, ButtonPresenter } from 'Components/Molecules'
import { container } from 'app/Config/IOC'
import { Types } from 'Gateways/Service/Types'
import { ILanguageService } from 'Helpers/Language/ILanguageService'
import { px2rem } from 'app/Styles/Variables'

const Wrapper = styled.div`
  padding: ${px2rem(20)};
`

const ButtonContainer = styled.div`
  padding-top: ${px2rem(20)};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

interface IEditPanelConfirmModal {
  modalTitle?: string
  modalMessage: string
  continueCallback: () => void
  cancelCallback: () => void
}

export const EditPanelConfirmModal = (props: IEditPanelConfirmModal) => {
  const language: ILanguageService = container.get(Types.ILanguageService)

  const { modalTitle, modalMessage, continueCallback, cancelCallback } = props

  const title = modalTitle ? modalTitle : language.get('common:confirmTitle')

  const continueButtonPresenter = new ButtonPresenter(language.get('common:continueButton'), continueCallback)
    .withDisplayStyle('primary')
    .withIconName('chevron-right')
    .withTextTransform('Uppercase')

  const cancelButtonPresenter = new ButtonPresenter(language.get('common:cancelButton'), cancelCallback)
    .withDisplayStyle('secondary')
    .withIconName('dnd-forwardslash')
    .withTextTransform('Uppercase')

  return (
    <Wrapper>
      <Heading>{title}</Heading>
      <p>{modalMessage}</p>
      <ButtonContainer>
        <Button presenter={cancelButtonPresenter} />
        <Button presenter={continueButtonPresenter} />
      </ButtonContainer>
    </Wrapper>
  )
}
