import { SyntheticEvent, useState } from 'react'
import { useQueryClient } from 'react-query'

import { Box, colors, IconButton, Modal, Text } from '@cutover/react-ui'
import { getRecipientString, getScheduleString } from './runbook-dashboard-schedule-formatter'
import { RunbookDashboardScheduleProps, UserSettingsResponse } from './runbook-dashboard-schedule-types'
import { useLanguage } from 'main/services/hooks'
import { useDeleteRunbookDashboardScheduleMutation } from 'main/services/queries/use-email-schedule-user-settings'

export const RunbookDashboardSchedule = ({
  id,
  title,
  numberOfRecipients,
  scheduleType,
  scheduleTime,
  days,
  timezone,
  filter,
  onClick
}: RunbookDashboardScheduleProps) => {
  const client = useQueryClient()
  const { translate } = useLanguage()

  const [isDeleteScheduleModalOpen, setDeleteScheduleModalOpen] = useState(false)

  const handleDeleteModalOpen = (e: SyntheticEvent) => {
    e.stopPropagation()
    setDeleteScheduleModalOpen(true)
  }

  const handleDeleteModalClose = () => {
    setDeleteScheduleModalOpen(false)
  }

  const mutation = useDeleteRunbookDashboardScheduleMutation(id, {
    onSuccess: () => {
      client.setQueriesData<UserSettingsResponse | undefined>(['user_settings'], cache => {
        if (!cache) {
          return
        }
        const previousValue = cache.user_settings
        const removeDeleted = previousValue.filter(schedule => schedule.id !== id)
        cache.user_settings = removeDeleted

        return cache
      })
    }
  })

  return (
    <>
      <Box
        direction="column"
        gap="6px"
        role="button"
        onClick={onClick}
        css={`
          background-color: ${colors.secondaryHover};
          border-radius: 6px;
          cursor: pointer;
          padding: 16px;
        `}
      >
        <Box align="center" direction="row" justify="between">
          <Text weight={600}>{title}</Text>
          <IconButton
            css={`
              margin-right: -16px;
              margin-top: -3px;
            `}
            icon="delete"
            label="Delete"
            size="medium"
            onClick={handleDeleteModalOpen}
            data-testid="delete-managed-schedule-button"
          />
        </Box>
        <Text size="13px">
          {getRecipientString(numberOfRecipients)}, {getScheduleString(scheduleType, scheduleTime, timezone, days)}
        </Text>
        <Box>
          {filter ? (
            <>
              <Text weight={500} size="13px">
                {translate('dashboard:scheduleTile:filter')}:{' '}
              </Text>
              <Text size="13px">{filter}</Text>
            </>
          ) : (
            <Text weight={500} size="13px">
              {translate('dashboard:scheduleTile:noFilter')}
            </Text>
          )}
        </Box>
      </Box>

      <Modal
        title={translate('dashboard:manageScheduleModal:deleteScheduleTitle')}
        open={isDeleteScheduleModalOpen}
        onClose={handleDeleteModalClose}
        confirmText={translate('dashboard:manageScheduleModal:delete')}
        onClickConfirm={() => mutation.mutate()}
        loading={mutation.isLoading}
      >
        <p>{translate('dashboard:manageScheduleModal:deleteScheduleMessage')}</p>
      </Modal>
    </>
  )
}
