// @ts-nocheck
import { IColor } from 'Shared/Components/Molecules'
import tinycolor from 'tinycolor2'
import { toJS } from 'mobx'
import { defaultOptionColor } from 'Shared/Components/Organisms/FieldOptions'

const getColorFromRgbString = (rgbString: string): IColor => {
  // Example of expected DTO string 'rgb(230,80,217)' or 'rgb(230,80,217,0.232)'
  let colorParts = rgbString.replace('rgb(', '')
  colorParts = colorParts.replace(')', '')
  let rgba = colorParts.split(',')

  return {
    r: parseInt(rgba[0], 10),
    g: parseInt(rgba[1], 10),
    b: parseInt(rgba[2], 10),
    a: rgba[3] ? parseInt(rgba[3], 10) : 1
  }
}

export const getColorFromDTOColor = (DTOColor: string): IColor => {
  if (DTOColor) {
    // Hex color detected
    if (DTOColor[0] === '#') {
      return tinycolor(DTOColor).toRgb()
    }

    return getColorFromRgbString(DTOColor)
  }
}

export const isColorDefault = (color: IColor): boolean => {
  return JSON.stringify(toJS(color)) === JSON.stringify(defaultOptionColor)
}
