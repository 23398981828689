// @ts-nocheck
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { UserEditResendInvitePresenter } from './UserEditResendInvitePresenter'
import { Button } from 'Components/Molecules'

const ButtonWrapper = styled.div`
  margin-top: 12px;
`

interface IUserEditResendInviteComponentProps {
  presenter: UserEditResendInvitePresenter
}

export const UserEditResendInviteSection = observer<IUserEditResendInviteComponentProps>(({ presenter }) => {
  const { showInvite, buttonPresenter } = presenter

  return (
    <>
      {showInvite && (
        <ButtonWrapper>
          <Button presenter={buttonPresenter} extraStyles={{ width: '100%' }} />
        </ButtonWrapper>
      )}
    </>
  )
})
