// @ts-nocheck

import { ColorPickerPresenter } from '.'
import styled from 'styled-components'
import { Popover, TextInput } from 'Components/Molecules'
import { observer } from 'mobx-react-lite'
import { px2rem } from 'app/Styles/Variables'
import { Icon } from 'Shared/Components/Atoms'

const Wrapper = styled.div`
  position: relative;
`

const PickerContent = styled.div`
  max-width: ${px2rem(128)};
  display: flex;
  flex-wrap: wrap;
`

const ColorSquare = styled.div`
  width: ${px2rem(16)};
  height: ${px2rem(16)};
  display: inline-block;
  flex-grow: 0;
  flex-shrink: 0;
  cursor: pointer;
`

const PopoverWrapper = styled.span`
  flex: 0;
  margin-left: ${px2rem(-2)};
  padding-right: ${px2rem(2)};
`

const IconInner = styled.span``

interface IColorPicker {
  presenter: ColorPickerPresenter
  textInputStyle?: CSSProperties
}

export const ColorPicker = observer<IColorPicker>(({ presenter, textInputStyle }) => {
  const { textPresenter, popoverPresenter, open, togglePicker, availableColors, rgbaString } = presenter

  const clickHandler = color => {
    color.onClick()
    togglePicker()
  }

  const customPrefixIcon = (
    <PopoverWrapper>
      <Popover
        open={open}
        type={'customContent'}
        content={
          <PickerContent>
            {availableColors.map(color => (
              <ColorSquare
                key={color.value}
                style={{ backgroundColor: color.value }}
                onClick={() => {
                  clickHandler(color)
                }}
              />
            ))}
          </PickerContent>
        }
        presenter={popoverPresenter}
        customTrigger={
          <IconInner>
            <Icon name="format-color-fill" color={rgbaString} extraStyles={{ cursor: 'pointer' }} size={px2rem(18)} />
          </IconInner>
        }
      />
    </PopoverWrapper>
  )

  return (
    <Wrapper>
      <TextInput presenter={textPresenter} customPrefixRender={customPrefixIcon} extraStyles={textInputStyle} />
    </Wrapper>
  )
})
