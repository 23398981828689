// @ts-nocheck
import { observable, computed } from 'mobx'
import { UserVerifyFormPresenter } from './UserVerifyForm/UserVerifyFormPresenter'
import { injectable } from 'inversify'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'
import { container } from 'app/Config/IOC'
import { Types } from 'Gateways/Service/Types'
import { UserVerifyViewPresenter } from './UserVerifyViewPresenter'

@injectable()
export class UserVerifyPagePresenter {
  @observable
  public userVerifyFormPresenter: UserVerifyFormPresenter = new UserVerifyFormPresenter()

  private userVerifyViewPresenter: UserVerifyViewPresenter = container.get(UserVerifyViewPresenter)

  private language: ILanguageService = container.get(Types.ILanguageService)

  public cutoverLoginLogoPath: string = '/branding/cutover/cutover-logo.png'
  public cutoverLogoAltText: string = 'cutover-logo'
  public clientLogoAltText: string = 'client-logo'

  @observable
  public changeFunctionLoadingState = false

  @observable
  public heading: string = this.language.get('userVerify:heading:title')

  @computed
  public get displayClientLogoPath(): string {
    return this.userVerifyViewPresenter.displayClientLogoPath
  }

  @computed
  public get loading(): boolean {
    return this.userVerifyViewPresenter.loading || this.changeFunctionLoadingState
  }
}
