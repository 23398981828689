import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Menu = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Menu" {...props}>
    <path d="M6 22.5h36v3H6v-3ZM6 37h36v3H6v-3ZM6 8h36v3H6V8Z" fill="#000" />
  </StyledIcon>
))

Menu.displayName = 'Menu'

export { Menu }
