// @ts-nocheck
import { useState, useEffect, useRef } from 'react'
import Popup from 'reactjs-popup'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { px2rem, windowSizes, colors } from 'app/Styles/Variables'
import { TToolTipPosition } from './TToolTipPosition'
import { TPopupOn } from './TPopupOn'

interface ITooltipProps {
  position?: TToolTipPosition
  message: string
  trigger: any
  oneLineTooltip?: boolean
  handlerType?: string
  nested?: boolean
}

const PopoverWrapper = styled.span``

const StyledPopup = styled(Popup)`
  &-content {
    background-color: ${colors.black} !important;
    color: ${colors.white} !important;
    font-size: ${px2rem(14)};
    line-height: ${px2rem(23)};
    padding: ${px2rem(0)} ${px2rem(5)};
    max-width: ${px2rem(200)};
    border-radius: ${px2rem(4)};
    border: ${px2rem(1)} solid ${colors.black};
  }
  &-arrow {
    color: ${colors.black} !important;
  }
`

export const ToolTip = observer<ITooltipProps>(props => {
  let { message, trigger, position, oneLineTooltip, handlerType, nested } = props

  const [isLocalPosition, setLocalPosition] = useState(position || 'left center')
  const [isOnAttribute, setOnAttribute] = useState<TPopupOn>('hover')
  const [isOpen, setIsOpen] = useState(false)
  const node = useRef()

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < windowSizes.md) {
        setLocalPosition('left center')
        setOnAttribute('click')
      } else {
        setLocalPosition(position || 'left center')
        setOnAttribute('hover')
      }
    }
    handleResize()

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    const handle = handlerType ? handlerType : 'click'
    window.addEventListener(handle, handleClick)
    return () => {
      window.removeEventListener(handle, handleClick)
    }
  }, [])

  const handleClick = (e: Event) => {
    if (node.current?.contains(e.target)) {
      setIsOpen(!isOpen)
      return
    }
  }

  let contentStyle: CSSProperties = oneLineTooltip ? { whiteSpace: 'nowrap' } : {}

  return (
    <PopoverWrapper ref={node}>
      <StyledPopup
        trigger={trigger}
        position={isLocalPosition}
        on={isOnAttribute}
        contentStyle={{ width: oneLineTooltip ? 'null' : '100%' }}
        open={isOpen}
        nested={nested}
        keepTooltipInside
      >
        <div className="content" style={{ ...contentStyle, textAlign: 'center' }}>
          {message}
        </div>
      </StyledPopup>
    </PopoverWrapper>
  )
})
