import { useRef, useState } from 'react'
import { VirtuosoHandle } from 'react-virtuoso'

import { Box, InfiniteList } from '@cutover/react-ui'
import { SnippetItem } from '../shared/snippet-info'
import { SnippetListSnippet } from 'main/services/queries/use-snippets'
import { LinkedSnippet } from 'main/services/queries/types'

type SnippetListProps = {
  onSelect: (snippet?: SnippetListSnippet | LinkedSnippet) => void
  snippets: SnippetListSnippet[] | LinkedSnippet[]
  hasNextPage?: boolean
  isFetchingNextPage: boolean
  fetchNextPage: () => Promise<any>
  setAtBottom: (atBottom: boolean) => void
}

export const SnippetList = ({
  onSelect,
  snippets,
  hasNextPage,
  isFetchingNextPage,
  fetchNextPage,
  setAtBottom
}: SnippetListProps) => {
  const [selectedSnippetId, setSelectedSnippetId] = useState<number | null>(null)
  const listRef = useRef<VirtuosoHandle>(null)

  const toggleSelectedSnippet = (snippet: SnippetListSnippet | LinkedSnippet) => {
    if (selectedSnippetId === snippet.id) {
      onSelect?.()
      setSelectedSnippetId(null)
    } else {
      onSelect?.(snippet)
      setSelectedSnippetId(snippet.id)
    }
  }

  return (
    <Box data-testid="snippet-select-list" tabIndex={0} margin={{ top: '20px' }} role="listbox">
      <InfiniteList<SnippetListSnippet | LinkedSnippet>
        setRef={listRef}
        items={snippets}
        hasNextPage={hasNextPage}
        isFetchingNextPage={isFetchingNextPage}
        fetchNextPage={fetchNextPage}
        height={snippets.length > 3 ? '288px' : `${snippets.length * 56}px`}
        atBottomStateChange={setAtBottom}
        renderItem={(_, snippet) => {
          const selected = snippet.id === selectedSnippetId

          return (
            <SnippetItem
              id={snippet.id}
              onClick={() => toggleSelectedSnippet(snippet)}
              selected={selected}
              name={snippet.name}
              tasksCount={snippet.tasks_count}
            />
          )
        }}
      />
    </Box>
  )
}
