import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const VisibilityHide = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="VisibilityHide" {...props}>
    <path d="m13.121 9.879-.021.02 26.676 26.677-2.1 2.1-4.462-4.462A19.58 19.58 0 0 1 24 36.5c-8.334 0-15.45-5.184-18.334-12.5a19.818 19.818 0 0 1 7.855-9.479L11 12l2.121-2.121ZM8.937 24c2.699 5.625 8.431 9.5 15.063 9.5 2.49 0 4.853-.546 6.975-1.524l-1.84-1.841a8 8 0 0 1-11.27-11.27l-2.158-2.158A16.809 16.809 0 0 0 8.937 24ZM19 24a5 5 0 0 0 8 4l-7-7c-.628.835-1 1.874-1 3Zm.468-11.975 2.587 2.587A16.831 16.831 0 0 1 24 14.5c6.639 0 12.365 3.875 15.063 9.5a16.803 16.803 0 0 1-3.139 4.482l2.121 2.12A19.798 19.798 0 0 0 42.333 24C39.45 16.684 32.343 11.5 24 11.5c-1.56 0-3.078.182-4.532.525Z" fill="#000" /><path d="M31.982 24.54a8 8 0 0 0-8.521-8.521l8.52 8.52Z" fill="#000" />
  </StyledIcon>
))

VisibilityHide.displayName = 'VisibilityHide'

export { VisibilityHide }
