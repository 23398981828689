// @ts-nocheck
import { action, observable } from 'mobx'
import { injectable } from 'inversify'
import { container } from 'app/Config/IOC'
import { ILanguageService } from 'Helpers/Language/ILanguageService'
import { ToasterService } from 'Components/Molecules/Toaster/ToasterService'
import { Types } from 'Gateways/Service/Types'
import { IBaseResponse } from 'Gateways/Service/IBaseResponse'
import { dateOrNullIso } from 'Shared/Helpers/DateService'
import { PermissableRepository } from 'app/Shared/Repositories/PermissableRepository'

export interface IOauthClientSession {
  createdAt: Date
  updatedAt: Date
  id: number
  userId: number
  provider: string
}

@injectable()
export class UserOauthClientSessionRepository extends PermissableRepository {
  public language: ILanguageService = container.get(Types.ILanguageService)
  private toasterGateway: ToasterService = container.get(ToasterService)

  @observable
  public userOauthClientSessions: IOauthClientSession[] = []

  @observable
  public userId: number

  @action
  public setUserId = (userId: number) => {
    this.userId = userId
  }

  public preLoad = (): string => {
    return `users/${this.userId}`
  }

  @action
  public revokeClientSession = async (userId: number, clientSessionId: number) => {
    this.loading = true
    const responseDto = await this.serviceGateway.delete(
      `users/${this.userId}/user_oauth_client_sessions/${clientSessionId}`
    )
    if (responseDto.success) {
      this.toasterGateway.pop(
        this.language.get('users:edit:oauth:toaster:title'),
        this.language.get('users:edit:oauth:toaster:notification'),
        'success'
      )
    }
    this.loading = false
    return responseDto
  }
}
