// @ts-nocheck
import { injectable } from 'inversify'
import { computed, observable } from 'mobx'
import { container } from 'app/Config/IOC'
import { FilterDisclosurePanelPresenter } from 'Components/Molecules'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'
import { Types } from 'Gateways/Service/Types'
import { TaskTypesViewPresenter } from 'app/Settings/TaskTypes/TaskTypesPage/TaskTypesViewPresenter'

@injectable()
export class TaskTypesFilterPresenter {
  private language: ILanguageService = container.get(Types.ILanguageService)
  private viewPresenter: TaskTypesViewPresenter = container.get(TaskTypesViewPresenter)

  @observable
  public loadingFilters: boolean

  @observable
  public visibilityPresenter: FilterDisclosurePanelPresenter = new FilterDisclosurePanelPresenter(
    this.language.get('common:filterSection:filterGroups:Visibility:title'),
    this.language.get('common:filterSection:filterGroups:Visibility:title')
  ).withCollapsed(false)

  @observable
  public settingsPresenter: FilterDisclosurePanelPresenter = new FilterDisclosurePanelPresenter(
    this.language.get('taskTypes:filter:filterGroupSettings:title'),
    this.language.get('taskTypes:filter:filterGroupSettings:title')
  ).withCollapsed(false)

  @observable
  public otherPresenter: FilterDisclosurePanelPresenter = new FilterDisclosurePanelPresenter(
    this.language.get('taskTypes:filter:filterGroupOther:title'),
    this.language.get('taskTypes:filter:filterGroupOther:title')
  ).withCollapsed(false)

  @computed
  public get displayFilterPanels(): boolean {
    if (!this.viewPresenter.filterVM) return false

    return this.viewPresenter.filterVM.length > 0
  }
}
