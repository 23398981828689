// @ts-nocheck
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { px2rem, colors } from 'app/Styles/Variables'
import { CheckboxPresenter, Checkbox } from '.'

export type TCheckboxElement = string | { id?: number; label: string; helpText?: string }

interface ICheckboxCollection {
  header?: string
  checkboxes: TCheckboxElement[]
  checked?: (string | number)[]
  middleware: (value: boolean, checkboxElement: TCheckboxElement) => boolean
  disabled?: string[]
  allDisabled?: boolean
  allChecked?: boolean
  className?: string
}

const CheckboxWrapper = styled.div``
const Header = styled.label`
  font-size: ${px2rem(13)};
  line-height: ${px2rem(12)};
  color: ${colors.textLight};
  font-weight: 500;
`

export const CheckboxCollection = observer<ICheckboxCollection>(
  ({
    header,
    checkboxes,
    checked,
    middleware,
    disabled,
    allDisabled,
    allChecked = false,
    className
  }: ICheckboxCollection) => {
    return (
      <CheckboxWrapper className={className}>
        <Header>{header}</Header>
        {checkboxes.map((checkboxElement: TCheckboxElement, i) => {
          const label = typeof checkboxElement === 'string' ? checkboxElement : checkboxElement.label

          const checkbox: CheckboxPresenter = new CheckboxPresenter(label)
          if (checked && !allChecked) {
            checkbox.value = checked.includes(label)
          }

          if (disabled && !allDisabled) checkbox.disabled = disabled.includes(label)
          if (allDisabled) checkbox.disabled = true
          if (allChecked) checkbox.value = true
          if (typeof checkboxElement !== 'string' && checkboxElement.helpText)
            checkbox.helpText = checkboxElement.helpText

          checkbox.withMiddleware((value: boolean) => {
            middleware(value, label)
            return value
          })
          return <Checkbox presenter={checkbox} key={i} />
        })}
      </CheckboxWrapper>
    )
  }
)
