import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Successors = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Successors" {...props}>
    <path d="M30 36a6.003 6.003 0 0 1 4.5-5.811v-4.204a4.5 4.5 0 0 0-1.318-3.182l-5.828-5.827A5.972 5.972 0 0 1 24 18a5.972 5.972 0 0 1-3.354-1.024l-5.828 5.827a4.5 4.5 0 0 0-1.318 3.182v4.204a6 6 0 1 1-3 0v-4.204a7.5 7.5 0 0 1 2.197-5.303l5.956-5.957a6 6 0 1 1 10.693 0l5.957 5.957a7.5 7.5 0 0 1 2.197 5.303v4.204A6 6 0 1 1 30 36Zm6-3a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm-24 0a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm9-21a3 3 0 1 0 6 0 3 3 0 0 0-6 0Z" fill="#000" />
  </StyledIcon>
))

Successors.displayName = 'Successors'

export { Successors }
