import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { EditIntegrationActionItemPanel } from './action-items/edit-integration-action-item-panel'
import { EditIntegrationSettingPanel } from './integrations/edit-integration-setting-panel'
import { useIntegrationActionItemsQuery } from '../../../services/queries/use-integration-action-item'
import { IntegrationActionItem, IntegrationSetting } from 'main/services/queries/types'

export type EditIntegrationSidebarPanelProps = {
  integrationSetting: IntegrationSetting
  onClose: () => void
  onAfterClose: () => void
}

export const EditIntegrationSidebarPanel = ({
  integrationSetting,
  onClose,
  onAfterClose
}: EditIntegrationSidebarPanelProps) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [selectedActionItem, setSelectedActionItem] = useState<IntegrationActionItem | undefined>()
  const { data: integrationActionItems } = useIntegrationActionItemsQuery()
  const actionItemId = searchParams.get('integration_action_item_id')

  const handleActionItemSelection = (actionItem: IntegrationActionItem) => {
    setSearchParams({
      setting_id: integrationSetting.id.toString(),
      integration_action_item_id: actionItem.id.toString()
    })
  }

  const clearActionItemSelection = () => {
    setSelectedActionItem(undefined)
  }

  const handleAfterClose = () => {
    clearActionItemSelection()
    onAfterClose()
  }

  useEffect(() => {
    const actionItems = integrationActionItems || integrationSetting.integration_action_items
    const actionItem = actionItems?.find(iai => iai.id === parseInt(actionItemId || ''))

    if (!integrationActionItems && actionItem) {
      actionItem.integration_setting = integrationSetting
    }

    if (!actionItem) {
      clearActionItemSelection()
    } else {
      setSelectedActionItem(actionItem)
    }
  }, [integrationSetting, integrationActionItems, searchParams])

  return (
    <>
      {selectedActionItem ? (
        <EditIntegrationActionItemPanel
          integrationSetting={integrationSetting}
          integrationActionItem={selectedActionItem}
          onBack={() => {
            setSearchParams({ setting_id: integrationSetting.id.toString() })
          }}
          onClose={() => {
            handleAfterClose()
            onClose()
          }}
        />
      ) : integrationSetting && !actionItemId ? (
        <EditIntegrationSettingPanel
          integrationSetting={integrationSetting}
          onSelectActionItem={handleActionItemSelection}
          onClose={onClose}
        />
      ) : null}
    </>
  )
}
