import { Dispatch, SetStateAction, useMemo } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm, useFormState } from 'react-hook-form'
import * as yup from 'yup'

import { Box, Message, Modal, Select, Text, useNotify } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { FolderDeleteFolderPayload, FolderListFolder } from 'main/services/queries/types'
import { useFolderDelete } from 'main/services/queries/use-folders'

type FolderArchiveModalProps = {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  onSuccess?: () => void
  onClose?: () => void
  folderId: number
  folderName: string
  hasRunbooks: boolean
  folders?: FolderListFolder[]
}

export const FolderArchiveModal = ({
  open,
  setOpen,
  onClose,
  onSuccess,
  folderId,
  folderName,
  hasRunbooks,
  folders
}: FolderArchiveModalProps) => {
  const { t } = useLanguage('runbooks', { keyPrefix: 'folder.folderArchiveModal' })
  const { mutate, isLoading, error } = useFolderDelete(folderId)
  const notify = useNotify()

  const { handleSubmit, control, reset } = useForm<FolderDeleteFolderPayload>({
    resolver: yupResolver(
      yup.object({
        new_project_id: yup.number().when([], {
          is: () => hasRunbooks,
          then: schema => schema.required()
        })
      })
    )
  })
  const { errors } = useFormState({ control })

  const onSubmit = ({ new_project_id }: FolderDeleteFolderPayload) => {
    mutate(
      { new_project_id: new_project_id || null },
      {
        onSuccess: () => {
          onSuccess?.()
          notify.success(t('success'))
          reset()
          setOpen(false)
        },
        onError: () => {
          notify.error(t('error'))
        }
      }
    )
  }

  const handleClose = () => {
    setOpen(false)
    reset()
    onClose?.()
  }

  const folderOptions = useMemo(
    () => (!hasRunbooks ? [] : folders?.filter(folder => folder.id !== folderId)),
    [folderId, folders, hasRunbooks]
  )

  return (
    <Modal
      title={t('title')}
      open={open}
      confirmText={t('confirmText')}
      confirmIcon="trash-o"
      onClose={handleClose}
      onClickConfirm={handleSubmit(onSubmit)}
      loading={isLoading}
    >
      <Box gap="small" direction="column">
        <Message type="error" message={error?.errors} />
        <Text data-testid="archive-folder-warning">
          {t('warning')} <Text weight="bold">{folderName}?</Text>
          {hasRunbooks && <Text> {t('selectNewFolderInstruction')}</Text>}
        </Text>
        {hasRunbooks && (
          <Controller
            name="new_project_id"
            control={control}
            render={({ field: { value, onChange, ref, onBlur } }) => (
              <Select
                value={value || undefined}
                label={t('selectNewFolderLabel')}
                required
                onBlur={onBlur}
                inputRef={ref}
                hasError={!!errors.new_project_id}
                onChange={onChange}
                labelKey="name"
                valueKey="id"
                options={folderOptions}
              />
            )}
          />
        )}
      </Box>
    </Modal>
  )
}
