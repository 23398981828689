import { ReactNode } from 'react'
import { useMeasure } from 'react-use'
import styled from 'styled-components'

import { Box } from '@cutover/react-ui'

export const Items = ({ compactThreshold = 615, children }: { compactThreshold?: number; children?: ReactNode }) => {
  const [ref, { width }] = useMeasure<HTMLDivElement>()
  return (
    <Box
      className={`${width < compactThreshold ? 'sub-header-compact' : ''}`}
      ref={ref}
      height="100%"
      width="100%"
      direction="row"
      justify="between"
      gap="small"
      align="center"
    >
      {children}
    </Box>
  )
}

const ItemsContainer = styled(Box).attrs(() => ({
  direction: 'row',
  align: 'center',
  gap: 'xsmall',
  flex: false
}))``

export const LeftItems = styled(ItemsContainer).attrs(() => ({
  id: 'subheader-left-items'
}))``

export const RightItems = styled(ItemsContainer).attrs(() => ({
  id: 'subheader-right-items'
}))``

export const SubHeader = {
  Items,
  LeftItems,
  RightItems
}
