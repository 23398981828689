import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Users = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Users" {...props}>
    <path d="M33 20a7 7 0 1 0 0-14 7 7 0 0 0 0 14Zm0-3a4 4 0 1 1 0-8 4 4 0 0 1 0 8Zm0 6c6.29 0 11.45 4.84 11.959 11 .027.33.041.663.041 1a2 2 0 0 1-2 2H30.46a14.9 14.9 0 0 0-1.19-3h12.675a9.001 9.001 0 0 0-16.165-4.374 15.034 15.034 0 0 0-2.459-1.721A11.983 11.983 0 0 1 33 23Zm-10-4a7 7 0 1 1-14 0 7 7 0 0 1 14 0Zm-3 0a4 4 0 1 0-8 0 4 4 0 0 0 8 0Zm7.959 21C27.45 33.84 22.29 29 16 29S4.55 33.84 4.041 40c-.027.33-.041.663-.041 1a2 2 0 0 0 2 2h20a2 2 0 0 0 2-2c0-.337-.014-.67-.041-1Zm-3.014 0H7.055a9.001 9.001 0 0 1 17.89 0Z" fill="#000" />
  </StyledIcon>
))

Users.displayName = 'Users'

export { Users }
