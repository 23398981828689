import { memo, Suspense, useState } from 'react'

import { LoadingPanel } from '@cutover/react-ui'
import { PeoplePanel, PeoplePanelProps, PeoplePanelScreen } from './people-panel/people-panel'
import { ValidationRunbookTeam } from './people-panel/types'
import { useRightPanelTypeState } from 'main/components/layout/right-panel'
import { useAccountViewPermissions } from 'main/services/api/data-providers/account/account-data'
import {
  ActiveAccountModel,
  ActiveRunbookModel,
  ActiveRunbookVersionModel,
  RunbookTeamModel,
  StreamModel
} from 'main/data-access'

export const UserOrTeamDetailsPanel = memo(() => {
  const [{ userOrTeam, initialScreen }, { closeRightPanel }] = useRightPanelTypeState('user-team-details-edit')
  const runbookId = ActiveRunbookModel.useId()
  const runbookVersionId = ActiveRunbookVersionModel.useId()

  return (
    <>
      {(userOrTeam || initialScreen) && runbookId && runbookVersionId && (
        <Suspense fallback={<LoadingPanel />}>
          <EditUserOrTeamDetails userOrTeam={userOrTeam} onClose={closeRightPanel} initialScreen={initialScreen} />
        </Suspense>
      )}
    </>
  )
})

type EditUserOrTeamDetailsProp = {
  userOrTeam: any
  onClose: () => void
  initialScreen?: PeoplePanelScreen
}

// NOTE: connecting to existing people panel
const EditUserOrTeamDetails = memo(({ userOrTeam, onClose, initialScreen }: EditUserOrTeamDetailsProp) => {
  const accountId = ActiveAccountModel.useId()
  const { id: runbookVersionId, is_current: isCurrentVersion } = ActiveRunbookVersionModel.useGet()
  const panelScreen = initialScreen || (userOrTeam?.hasOwnProperty('team_id') ? 'team-details' : 'user-details')
  const streams = StreamModel.useGetAll()
  const runbookTeams = RunbookTeamModel.useGetAll()

  const { id, name, template_type: templateType } = ActiveRunbookModel.useGet()

  const canCreateTeam = RunbookTeamModel.useCan('create')
  const canUpdateRunbook = ActiveRunbookModel.useCan('update')
  const { viewPermissions: accountViewPermissions } = useAccountViewPermissions() // TODO: recoil?
  const canUpdateCentralTeams = !!accountViewPermissions?.teams

  const [selectedTab, setSelectedTab] = useState<string>('users')

  return (
    <PeoplePanel
      accountId={accountId}
      runbook={{ id, name, templateType } as PeoplePanelProps['runbook']}
      runbookVersionId={runbookVersionId}
      isCurrentVersion={isCurrentVersion}
      permissions={{
        canCreateTeam,
        canUpdateRunbook,
        canUpdateCentralTeams
      }}
      onTabSelect={(tab: string) => setSelectedTab(tab)}
      runbookTeams={runbookTeams as ValidationRunbookTeam[]}
      screen={panelScreen}
      selectedUser={panelScreen === 'user-details' && userOrTeam}
      selectedTeam={panelScreen === 'team-details' && userOrTeam}
      streams={streams}
      onClose={onClose}
      selectedTab={selectedTab ?? 'users'}
    />
  )
})
