import { ValueOf } from 'type-fest'

import { useNextProgressionModal, useNextProgressionModalSync } from '../hooks'

const TaskPropertyCallbackModelHandlers = {
  nextModal: useNextProgressionModal
}
type TaskPropertyCallbackHandlerKeys = keyof typeof TaskPropertyCallbackModelHandlers
type PropertyCallbackHandler<Property extends TaskPropertyCallbackHandlerKeys> = ValueOf<
  typeof TaskPropertyCallbackModelHandlers,
  Property
>

const TaskPropertyCallbackSyncModalHandlers = {
  nextModal: useNextProgressionModalSync
}
type TaskPropertyCallbackSyncHandlerKeys = keyof typeof TaskPropertyCallbackSyncModalHandlers
type PropertyCallbackSyncHandler<Property extends TaskPropertyCallbackSyncHandlerKeys> = ValueOf<
  typeof TaskPropertyCallbackSyncModalHandlers,
  Property
>

// WARNING! do not add to this model! we will be removing it after we refactor the task progression modals so
// that these functions never have to be exported. At the moment they are needed to figure out the in/out animation
// of the modals, but we should consolidate them into one modal so no animation prop is necessary
// https://cutover.atlassian.net/browse/CFE-2214
export const TaskProgressionViewModel = {
  getCallback: <Key extends TaskPropertyCallbackHandlerKeys>(key: Key) =>
    TaskPropertyCallbackModelHandlers[key]() as ReturnType<PropertyCallbackHandler<Key>>,
  getCallbackSync: <Key extends TaskPropertyCallbackSyncHandlerKeys>(key: Key) =>
    TaskPropertyCallbackSyncModalHandlers[key]() as ReturnType<PropertyCallbackSyncHandler<Key>>
}
