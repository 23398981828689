import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const ColumnDelete = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="ColumnDelete" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M30 6v3h-4.5v30H30v3H14a8 8 0 0 1-8-8V14a8 8 0 0 1 8-8h16Zm-7.5 16.5V9H14a5 5 0 0 0-5 5v8.5h13.5ZM9 25.5V34a5 5 0 0 0 5 5h8.5V25.5H9Z" fill="#000" /><path d="m43.718 28.596-2.122 2.122L37 26.12l-4.596 4.597-2.122-2.122L34.88 24l-4.596-4.596 2.12-2.122L37 21.88l4.596-4.597 2.122 2.122L39.12 24l4.596 4.596Z" fill="#000" />
  </StyledIcon>
))

ColumnDelete.displayName = 'ColumnDelete'

export { ColumnDelete }
