import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const TaskTypeBoxDashed = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="TaskTypeBoxDashed" {...props}>
    <path d="M16 8h-2a6 6 0 0 0-6 6v2.373h2V14a4 4 0 0 1 4-4h2V8Zm4 2V8h8v2h-8Zm-4 28h-2a4 4 0 0 1-4-4v-1.627H8V34a6 6 0 0 0 6 6h2v-2Zm4 2v-2h8v2h-8Zm12 0h2a6 6 0 0 0 6-6v-1.627h-2V34a4 4 0 0 1-4 4h-2v2Zm0-30h2a4 4 0 0 1 4 4v2.373h2V14a6 6 0 0 0-6-6h-2v2Z" fill="#000" />
  </StyledIcon>
))

TaskTypeBoxDashed.displayName = 'TaskTypeBoxDashed'

export { TaskTypeBoxDashed }
