import { useMutation, useQuery } from 'react-query'

import {
  InstanceSettingsFormType,
  InstanceSettingsRequestType,
  InstanceSettingsResponseType
} from './instance-settings-types'
import { apiClient_UNSTABLE as apiClient } from 'main/services/api'
import { QueryKeys } from 'main/services/queries/query-keys'

export function useInstanceSettingsQuery() {
  return useQuery<InstanceSettingsFormType, Error>(QueryKeys.InstanceSettings, async () => {
    const { data } = await apiClient.get<InstanceSettingsResponseType>('instance_settings')
    return data.instance_settings
  })
}

export function useUpdateInstanceSettingsMutation(options = {}) {
  return useMutation<InstanceSettingsFormType, Error, InstanceSettingsFormType>(
    QueryKeys.InstanceSettings,
    async settings => {
      const { instance_settings } = await apiClient.put<InstanceSettingsRequestType, InstanceSettingsResponseType>(
        'instance_settings',
        { instance_settings: settings }
      )

      return instance_settings
    },
    options
  )
}
