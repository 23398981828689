import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Code = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Code" {...props}>
    <path d="m13.582 13.102 2.121 2.121-8.778 8.778 8.778 8.779-2.12 2.12-10.9-10.899 10.9-10.9Zm14.104-4.543 2.803 1.07-10.054 29.867-2.803-1.07L27.686 8.56ZM32.29 32.78l2.12 2.12 10.9-10.899-10.9-10.9-2.12 2.122 8.777 8.778-8.778 8.779Z" fill="#000" />
  </StyledIcon>
))

Code.displayName = 'Code'

export { Code }
