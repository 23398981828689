import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const BrandProductUpdates = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 100 101" a11yTitle="BrandProductUpdates" {...props}>
    <g clipPath="url(#a)" fill="#fff"><path opacity=".5" d="M64.667 71.334H35.333v29.333h29.334V71.333Zm-35.334 0H0v29.333h29.333V71.333Zm0-35.334H0v29.334h29.333V36Z" /><path d="M100 .667H70.667V30H100V.667Z" /></g>
  </StyledIcon>
))

BrandProductUpdates.displayName = 'BrandProductUpdates'

export { BrandProductUpdates }
