// @ts-nocheck
import { container } from 'app/Config/IOC'
import { injectable } from 'inversify'
import { observable, computed, Lambda } from 'mobx'
import { AccountsPageViewPresenter } from 'app/Settings/Accounts/AccountsPage/AccountsPageViewPresenter'

@injectable()
export class AccountsPageHeadingPresenter {
  private accountsPageViewPresenter: AccountsPageViewPresenter = container.get(AccountsPageViewPresenter)

  @observable
  public disposers: Lambda[] = []

  @computed
  public get totalAccountsCount(): number {
    return this.accountsPageViewPresenter.totalAccounts
  }

  @computed
  public get totalFilteredAccounts(): number {
    return this.accountsPageViewPresenter.listVM.length
  }
}
