// @ts-nocheck
import { computed, observable, action } from 'mobx'
import { container } from 'app/Config/IOC'
import { ConfigsRepository } from 'Configs/ConfigsRepository'
import { AccordionPresenter, CheckboxPresenter } from 'Components/Molecules'
import { RadioButtonsPresenter } from 'Components/Atoms'
import { IUserPM } from 'Shared/Entities/Types/IUserPM'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'
import { Types } from 'Gateways/Service/Types'

export class UserEditSettingsPresenter {
  private configsRepository = container.get(ConfigsRepository)

  public language: ILanguageService = container.get(Types.ILanguageService)

  public accordionPresenter = new AccordionPresenter(this.language.get('users:edit:settings:title')).withLeftIconName(
    'settings'
  )

  @observable
  public loginOptionPresenter = new RadioButtonsPresenter([
    this.language.get('users:edit:settings:login:saml'),
    this.language.get('users:edit:settings:login:email'),
    this.language.get('users:edit:settings:login:none')
  ])
    .withLabel(this.language.get('users:edit:settings:login:title'))
    .withHelpText(this.language.get('users:edit:settings:login:helpText'))

  @observable
  public emailPreferencePresenter = new CheckboxPresenter(
    this.language.get('users:edit:settings:emailPreference:label')
  )

  @observable
  public htmlEmailPreferencePresenter = new CheckboxPresenter(
    this.language.get('users:edit:settings:htmlEmailPreference:label')
  )

  @observable
  public smsPreferencePresenter = new CheckboxPresenter(this.language.get('users:edit:settings:smsPreference:label'))

  @computed
  public get showSSOLoginType(): boolean {
    return this.configsRepository.oauthActive && this.configsRepository.oauthAllowSetLoginType
  }

  @computed
  public get showEmailLoginType(): boolean {
    return this.configsRepository.oauthAllowEmailLogin && this.configsRepository.oauthAllowSetLoginType
  }

  @computed
  public get showHtmlPreference(): boolean {
    if (this.emailPreferencePresenter.value) {
      return true
    }
    return false
  }

  @computed
  public get isDirty(): boolean {
    return (
      this.loginOptionPresenter.isDirty ||
      this.emailPreferencePresenter.isDirty ||
      this.htmlEmailPreferencePresenter.isDirty ||
      this.smsPreferencePresenter.isDirty
    )
  }

  @computed
  public get providerValue(): string {
    const SSOLabel = this.language.get('users:edit:settings:login:saml')
    const selectedValueLabel = this.loginOptionPresenter.selectedOption || SSOLabel

    return this.providerWithLabels[selectedValueLabel]
  }

  @computed
  public get providerWithLabels(): object {
    const SSOLabel = this.language.get('users:edit:settings:login:saml')
    const emailLabel = this.language.get('users:edit:settings:login:email')
    const naLabel = this.language.get('users:edit:settings:login:none')
    const valueLabels = {}
    valueLabels[SSOLabel] = 'saml'
    valueLabels[emailLabel] = 'email'
    valueLabels[naLabel] = 'none'
    return valueLabels
  }

  @action
  public reset = () => {
    this.loginOptionPresenter.isDirty = false
    this.emailPreferencePresenter.reset()
    this.htmlEmailPreferencePresenter.reset()
    this.smsPreferencePresenter.reset()
  }

  @action
  public closeAccordion = () => {
    this.accordionPresenter.closeAccordion()
  }

  @action
  public disableLoginOptions = () => {
    const disabledOptions = []

    if (!this.showSSOLoginType) {
      disabledOptions.push(this.language.get('users:edit:settings:login:saml'))
    }

    if (!this.showEmailLoginType) {
      disabledOptions.push(this.language.get('users:edit:settings:login:email'))
    }

    this.loginOptionPresenter.withDisabledOptions(disabledOptions)
  }

  @action
  public setEditableUser = (user: IUserPM) => {
    this.loginOptionPresenter.selectedOption = this.getLabelForProvider(user.provider)
    this.setEditableUserCommunicationPrefs(this.loginOptionPresenter.selectedOption, user)
    this.disableLoginOptions()
    this.loginOptionPresenter.withMiddleware(value => {
      this.setEditableUserCommunicationPrefs(value, user)

      return value
    })
  }

  @action
  public setEditableUserCommunicationPrefs = (selectedProvider: string, user: IUserPM) => {
    if (selectedProvider === this.language.get('users:edit:settings:login:none')) {
      this.emailPreferencePresenter.value = false
      this.htmlEmailPreferencePresenter.value = false
      this.smsPreferencePresenter.value = false
      this.setDisabledCommunicationPrefs(true)
    } else {
      this.setDisabledCommunicationPrefs(false)
      this.emailPreferencePresenter.value = user.prefEmail || false
      this.htmlEmailPreferencePresenter.value = user.htmlEmailPrefs || false
      this.smsPreferencePresenter.value = user.acceptSMS || false
    }
  }

  private getLabelForProvider = (provider: string) => {
    return this.language.get(`users:edit:settings:login:${provider}`)
  }

  public setDisabled = (disabled: boolean) => {
    this.loginOptionPresenter.withDisabled(disabled)
    this.setDisabledCommunicationPrefs(disabled)
  }

  public setDisabledCommunicationPrefs = (disabled: boolean) => {
    this.emailPreferencePresenter.withDisabled(disabled)
    this.htmlEmailPreferencePresenter.withDisabled(disabled)
    this.smsPreferencePresenter.withDisabled(disabled)
  }
}
