import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Spotlight = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Spotlight" {...props}>
    <path d="M3.998 30.253V17.747h18.205c2.946-4.072 6.905-4.86 8.78-4.988 1.502-.103 2.515 1.14 2.515 2.402v17.678c0 1.261-1.013 2.505-2.515 2.402-1.875-.128-5.834-.916-8.78-4.988H3.998Zm3-9.506v6.506h15.45a2.55 2.55 0 0 1 2.084 1.098c1.927 2.757 4.419 3.58 5.966 3.82V15.83c-1.547.24-4.039 1.063-5.966 3.82a2.55 2.55 0 0 1-2.083 1.098H6.998ZM44 22.5h-8v3h8v-3ZM35 17l6.928-4 1.5 2.598-6.928 4L35 17Zm6.928 18L35 31l1.5-2.598 6.928 4-1.5 2.598Z" fill="#000" />
  </StyledIcon>
))

Spotlight.displayName = 'Spotlight'

export { Spotlight }
