// @ts-nocheck
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { withPresenter } from 'app/Config/withPresenter'
import { TaskTypesFilterPresenter } from './TaskTypesFilterPresenter'
import { FilterDisclosurePanel } from 'Components/Molecules'
import { px2rem } from 'app/Styles/Variables'

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: auto;
  box-sizing: border-box;
`

const FilterWrapper = styled.div`
  margin: 0 0 ${px2rem(56)} ${px2rem(16)};
`

interface ITaskTypeListComponentProps {
  presenter: TaskTypesFilterPresenter
}

const TaskTypesFilterSection = observer<ITaskTypeListComponentProps>(({ presenter }) => {
  const { visibilityPresenter, settingsPresenter, otherPresenter, displayFilterPanels } = presenter

  return (
    <Wrapper>
      <FilterWrapper>
        {displayFilterPanels && (
          <>
            <FilterDisclosurePanel presenter={visibilityPresenter} />
            <FilterDisclosurePanel presenter={settingsPresenter} />
            <FilterDisclosurePanel presenter={otherPresenter} />
          </>
        )}
      </FilterWrapper>
    </Wrapper>
  )
})

export default withPresenter(TaskTypesFilterPresenter)(TaskTypesFilterSection)
