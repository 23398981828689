// @ts-nocheck
import { IServiceGateway } from './IServiceGateway'
import { injectable } from 'inversify'
import 'reflect-metadata'

@injectable()
export class FakeServiceGateway implements IServiceGateway {
  public get<T>(path, parameters): Promise<T> {
    return Promise.resolve() as any
  }

  public delete<T>(path, parameters): Promise<T> {
    return Promise.resolve() as any
  }

  public post<T>(path, request): Promise<T> {
    return Promise.resolve() as any
  }

  public put<T>(path, request): Promise<T> {
    return Promise.resolve() as any
  }

  public patch<T>(path, request): Promise<T> {
    return Promise.resolve() as any
  }
}
