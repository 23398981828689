import { useMemo, useState } from 'react'
import { sortBy } from 'lodash'

import { Filter, getFilterSelections, handleFilterChange } from 'main/components/shared/filter'
import {
  useFilterGroupCentralTeamData,
  useFilterGroupCustomFieldsData,
  useFilterGroupFolderData,
  useFilterGroupRunbookTypeData,
  useFilterGroupTemplateStatusData
} from 'main/components/shared/filter/filter-hooks'
import {
  FilterGroup,
  FilterGroupCheckbox,
  FilterOption,
  SelectedFilters
} from 'main/components/shared/filter/filter-types'
import { TemplateStatus, useLanguage } from 'main/services/hooks'
import { CustomField, FolderListFolder, RunbookTypeType } from 'main/services/queries/types'
import { CustomFieldOptionId, RunbookCentralTeam } from 'main/services/queries/use-runbooks'
import { SavedView, SavedViewGroup } from 'main/services/queries/use-saved-view-groups'

type LinkTemplateFilterProps = {
  centralTeams?: RunbookCentralTeam[]
  customFieldIds?: CustomField['id'][]
  customFieldOptionIds?: CustomFieldOptionId[]
  customFields?: CustomField[]
  folders?: FolderListFolder[]
  onChange: (selected: SelectedFilters) => void
  runbookTypes?: RunbookTypeType[]
  selected: SelectedFilters
  templateStatuses?: TemplateStatus
  savedViewGroups?: SavedViewGroup[]
}

export const LinkTemplateFilter = ({
  folders = [],
  centralTeams = [],
  runbookTypes = [],
  templateStatuses = {},
  customFields = [],
  customFieldOptionIds = [],
  customFieldIds = [],
  savedViewGroups = [],
  onChange,
  selected
}: LinkTemplateFilterProps) => {
  const { t } = useLanguage('common', { keyPrefix: 'filter' })
  const [selectedSavedView, setSelectedSavedView] = useState<string | undefined>()

  const savedViewGroup: FilterGroupCheckbox | undefined = useMemo(() => {
    const filteredSavedViews = savedViewGroups.filter(group => group.name !== '_default')
    const hasSavedViews = filteredSavedViews.find(savedView => savedView.saved_views.length > 0)
    if (!hasSavedViews) return undefined

    const savedViewOptions = filteredSavedViews.reduce<SavedView[]>((acc, group) => {
      return [...group.saved_views, ...acc]
    }, [])

    return {
      isInitiallyOpen: false,
      title: t('savedViews'),
      type: 'checkbox',
      optionDisplayLimit: 50,
      slug: 'savedView',
      options: sortBy(savedViewOptions, ['name']).map(option => ({
        value: option.query_string,
        label: option.name
      }))
    }
  }, [savedViewGroups])

  const folderGroup = useFilterGroupFolderData(
    folders,
    {
      isInitiallyOpen: false
    },
    { countable: false, canAdd: false }
  )

  const centralTeamGroup = useFilterGroupCentralTeamData(
    centralTeams,
    {
      type: 'checkbox',
      optionDisplayLimit: 10
    },
    { countable: false }
  )
  const runbookTypeGroup = useFilterGroupRunbookTypeData(runbookTypes, {
    type: 'checkbox',
    optionDisplayLimit: 10
  })
  const templateStatusGroup = useFilterGroupTemplateStatusData(templateStatuses, {
    isInitiallyOpen: false
  })

  const customFieldGroups = useFilterGroupCustomFieldsData(
    {
      customFields,
      customFieldIds,
      customFieldOptionIds,
      // TODO: add customFieldOptionCounts when count response from server is relative to template length not runbook length
      customFieldOptionCounts: []
    },
    ['runbook_add_edit', 'runbook_edit'],
    {
      date: () => ({ canGroup: false, canSpotlight: false }),
      text: () => ({ canGroup: false, canSpotlight: false }),
      select: cf => ({
        canGroup: false,
        canSpotlight: false,
        optionDisplayLimit: 10,
        options: [
          ...cf.field_options
            .filter(option => customFieldOptionIds.includes(option.id))
            .map(option => ({
              label: option.name,
              value: option.id,
              slug: 'f'
            }))
        ]
      })
    }
  )

  const filterData = useMemo(
    () =>
      [
        ...(savedViewGroup ? [savedViewGroup] : []),
        folderGroup,
        centralTeamGroup,
        runbookTypeGroup,
        templateStatusGroup,
        ...customFieldGroups
      ] as FilterGroup[],
    [savedViewGroup, folderGroup, centralTeamGroup, runbookTypeGroup, templateStatusGroup, customFieldGroups]
  )

  const handleSavedViewSelection = (option: FilterOption) => {
    const clearing = option.value === selectedSavedView
    setSelectedSavedView(clearing ? undefined : (option.value as string))
    onChange(JSON.parse(option.value as string))
  }

  const onFilterClick = (
    filter: FilterGroup,
    option?: FilterOption,
    override?: string[] | number[] | number | string
  ) => {
    if (filter.slug === 'savedView' && option) return handleSavedViewSelection(option)
    if (selectedSavedView) setSelectedSavedView(undefined)
    return onChange(handleFilterChange(selected, filter, option, override))
  }

  const getActiveCount = (selected: SelectedFilters, filter: FilterGroup): string => {
    const filterSelections = getFilterSelections(selected, filter)
    if (filterSelections === undefined) return ''
    if (Array.isArray(filterSelections)) return filterSelections.filter(s => s !== null).length.toString()
    return '1'
  }

  return (
    <Filter
      base
      withHeader={false}
      getAppliedIndicatorText={getActiveCount}
      filterData={filterData}
      onChange={onFilterClick}
      selected={{ ...selected, savedView: selectedSavedView }}
    />
  )
}
