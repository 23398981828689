import { TaskListTask } from 'main/services/queries/types'
import { createTaskFilterFunction, RunbookFilterType, TaskFilterContext } from './filters'

/**
 * Task stream filter matcher
 *  - The ids in the filters.stream array can be either top-level streams or sub-streams.
 *  - If a top-level stream is selected, all sub-streams should be included in the search.
 *  - If a sub-stream is selected, its parent is not automatically included in the search.
 *
 * context.streamIds contains an array of stream ids for top-level and sub-streams applicable
 * to the internal ids provided in filters.streams. After checking for the presence of filters.stream
 * we just check if the task.stream_id is included in the array.
 */
export const stream = createTaskFilterFunction(
  'stream',
  (task: TaskListTask, filters: RunbookFilterType, context: TaskFilterContext): boolean => {
    if (filters.stream === undefined) return false

    return context.streamIds?.includes(task.stream_id) ?? false
  }
)
