// @ts-nocheck
import { v4 } from 'uuid'
import { FieldOptionsPresenter } from './FieldOptionsPresenter'
import { DraggablePresenter } from 'Shared/Components/Atoms'
import { RemoteOptionPresenter } from './RemoteOptionPresenter'
import { defaultOptionColor } from '.'
import { RemoteOptionDraggable } from './RemoteOptionDraggable'
import { TRemoteOptionType } from './TRemoteOptionType'
import { action } from 'mobx'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'
import { container } from 'app/Config/IOC'
import { Types } from 'Gateways/Service/Types'

export class RemoteOptionsPresenter extends FieldOptionsPresenter {
  public language: ILanguageService = container.get(Types.ILanguageService)

  protected getDraggableOptionsForOrderIds = (orderedIds: string[]): { [k: string]: any }[] => {
    const draggableOptions = []
    orderedIds.map((orderedId, index) => {
      const draggableOption = this.draggableOptions.find(obj => obj.draggable.id === orderedId)

      if (draggableOption.option.hasOwnProperty('locked')) {
        draggableOption.option.locked = index === 0 ? true : false
        draggableOption.draggable = this.createDraggablePresenter(draggableOption.option)
      }

      draggableOptions.push(draggableOption)
    })

    return draggableOptions
  }

  protected createDraggablePresenter = (option: TRemoteOptionType): DraggablePresenter => {
    const remoteOptionPresenter = new RemoteOptionPresenter(this.language)
      .withGuid(option.guid)
      .withFieldOptionsPresenter(this)
      .withDisabled(this.disabled)
      .withShowLock(option.locked ? option.locked : false)
      .withDirtyCallback(this.dirtyCallback)

    remoteOptionPresenter.displayNamePresenter.value = option.displayName
    remoteOptionPresenter.checkboxPresenter.value = option.filterable

    return new DraggablePresenter()
      .withId(v4())
      .withDraggableConfig({
        presenter: remoteOptionPresenter
      })
      .withGetContent(RemoteOptionDraggable)
  }

  public withOptions? = (options: TRemoteOptionType[]) => {
    this.createDraggables(options, true)
    return this
  }

  public withOption? = (guid: string, value: string) => {
    const newOption = {
      guid,
      value,
      color: defaultOptionColor,
      showDetails: false,
      excludeFromConflicts: false,
      displayName: value,
      locked: this.draggableOptions.length === 0 ? true : false,
      filterable: false
    }
    this.createDraggable(newOption)

    return this
  }

  @action
  public setFilterable = (guid: string, value: boolean) => {
    const opt = this.findOption(guid)
    if (opt) opt.filterable = value
  }

  @action
  public setDisplayName = (guid: string, value: string) => {
    const opt = this.findOption(guid)
    if (opt) opt.displayName = value
  }
}
