// @ts-nocheck
import { observable, action } from 'mobx'
import { injectable } from 'inversify'
import { PermissableRepository } from 'app/Shared/Repositories/PermissableRepository'
import { IBaseResponse } from 'Gateways/Service/IBaseResponse'
import {
  IRunbookNewPM,
  IRunbookNewDTO,
  IFieldValuesAttributesDTO,
  IRunbookVersionsAttributesDTO,
  IRunbookDuplicateDTO,
  IEventNewPM,
  IEventNewDTO
} from 'app/Repositories/Runbook/RunbookRepositoryTypes'

@injectable()
export class RunbookRepository extends PermissableRepository {
  protected apiUrl: string = ''

  @observable
  public runbook: any = {}

  @observable
  public currentVersionId: number = null

  @observable
  public runbookTemplateType: string = null

  @observable
  public name: string = ''

  public preLoad = (params: { [key: string]: any }): string => {
    const { runbookId } = params
    const url = `runbooks/${runbookId}`
    return url
  }

  @action
  public postLoad = (rawData: IBaseResponse) => {
    this.runbook = rawData.body.runbook
    this.runbookTemplateType = this.runbook.template_type
    this.currentVersionId = this.runbook.current_version.id
    this.name = this.runbook.name
  }

  public getEventNewDTOFromEventPM = (newEvent: IEventNewPM): IEventNewDTO => {
    const { name, existingEventId, startPlanned, endPlanned } = newEvent
    const eventDto: IEventNewDTO = {
      event: {}
    }

    if (name) eventDto.event.name = name
    if (existingEventId) eventDto.event.existing_event_id = existingEventId
    if (startPlanned) eventDto.event.start_planned = startPlanned
    if (endPlanned) eventDto.event.end_planned = endPlanned

    return eventDto
  }

  public getRunbookNewDtoFromRunbookPM = (newRunbook: IRunbookNewPM): IRunbookNewDTO => {
    const {
      accountId,
      description,
      fieldValuesAttributes,
      name,
      projectId,
      runbookAdmins,
      runbookTypeId,
      startScheduled,
      status,
      timezone,
      timingMode
    } = newRunbook

    // clean up fieldValueAttributes for user_select type - transforming array of user items into array of user ids
    fieldValuesAttributes.forEach(fieldValue => {
      if (fieldValue.type === 'user_select') {
        fieldValue.value = fieldValue.value.map(userItem => userItem.id)
      }
    })

    const fieldValuesAttributesDto = fieldValuesAttributes.length
      ? fieldValuesAttributes.map((fieldValuesAttribute): IFieldValuesAttributesDTO => {
          const eachFieldValueAttributeDto: IFieldValuesAttributesDTO = {
            custom_field_id: fieldValuesAttribute.customFieldId,
            data_source_value_id: fieldValuesAttribute.dataSourceValueId,
            id: fieldValuesAttribute.id
          }
          // if optional fields exist, add to dto
          if (fieldValuesAttribute.value) {
            eachFieldValueAttributeDto.value = JSON.stringify(fieldValuesAttribute.value)
          }
          if (fieldValuesAttribute.fieldOptionId) {
            eachFieldValueAttributeDto.field_option_id = fieldValuesAttribute.fieldOptionId
          }
          return eachFieldValueAttributeDto
        })
      : []

    const rolesDto = runbookAdmins.length
      ? runbookAdmins.map(user => {
          return {
            role_type_id: 11,
            user_id: user.id,
            subject_id: user.id,
            subject_type: 'User'
          }
        })
      : []

    const runbookVersionAttributesDto: IRunbookVersionsAttributesDTO[] = [
      {
        start_scheduled: startScheduled !== null ? startScheduled : null,
        title: 'New Version'
      }
    ]
    // if unscheduled runbook (startPlanned exists), add start planned attribute
    if (newRunbook.startPlanned) {
      runbookVersionAttributesDto[0].start_planned = newRunbook.startPlanned
    }

    const runbookDto = {
      runbook: {
        account_id: accountId,
        description,
        field_values_attributes: fieldValuesAttributesDto,
        name,
        project_id: projectId,
        roles: rolesDto,
        runbook_type_id: runbookTypeId,
        runbook_versions_attributes: runbookVersionAttributesDto,
        status,
        timezone,
        timing_mode: timingMode
      },
      shift_time: true
    }

    return runbookDto
  }

  public getRunbookDuplicateDtoFromRunbookPM = (newRunbook: IRunbookNewPM) => {
    const { name, projectId, copyTasks, copyUsers, copyTeams, shiftTime } = newRunbook

    const runbookDto: IRunbookDuplicateDTO = {
      runbook: {
        name,
        copy_tasks: copyTasks,
        copy_teams: copyTeams,
        copy_users: copyUsers,
        project_id: projectId,
        shift_time: shiftTime,
        runbook_versions_attributes: [
          {
            start_scheduled: newRunbook.startScheduled,
            start_planned: newRunbook.startPlanned,
            end_scheduled: null
          }
        ]
      }
    }

    return runbookDto
  }

  @action
  public createRunbook = async (newRunbook: IRunbookNewPM, event?: IEventNewPM) => {
    let newRunbookDto = this.getRunbookNewDtoFromRunbookPM(newRunbook)

    if (this.eventHasData(event)) {
      const newEventDto = this.getEventNewDTOFromEventPM(event)
      newRunbookDto = { ...newRunbookDto, ...newEventDto }
    }

    return await this.serviceGateway.post('runbooks', newRunbookDto)
  }

  @action
  public duplicateRunbook = async (newRunbook: IRunbookNewPM, duplicatedRunbookId: number) => {
    const duplicateRunbookDto = this.getRunbookDuplicateDtoFromRunbookPM(newRunbook)
    return await this.serviceGateway.patch(`runbooks/${duplicatedRunbookId}/duplicate`, duplicateRunbookDto)
  }

  private eventHasData(event?: IEventNewPM): boolean {
    if (!event) return false
    return !!Object.keys(event).length
  }
}
