import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Redo = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Redo" {...props}>
    <path d="M42 17.504 30.686 6.19l-2.12 2.121L36.253 16H14.687c-5.523 0-10 4.477-10 10s4.477 10 10 10h3v-3h-3a7 7 0 1 1 0-14H36.26l-7.696 7.696 2.121 2.121L42 17.504Z" fill="#000" />
  </StyledIcon>
))

Redo.displayName = 'Redo'

export { Redo }
