import { DefaultValue, RecoilState, selector } from 'recoil'
import { produce } from 'immer'
import { keyBy } from 'lodash'

import { Filter } from 'main/services/queries/use-filters'
import { runbookMetaState } from './runbook'

type SavedFilter = Filter & {
  account_id?: number
  created_at: string
  updated_at: string
  resource_id: number
  runbook_id?: number
  show_dashboard: boolean
}

export const savedFilterState: RecoilState<SavedFilter[]> = selector({
  key: 'saved-filter:list',
  get: ({ get }) => {
    const runbookMeta = get(runbookMetaState)
    return runbookMeta.filters
  },
  set: ({ set, get }, newValue) => {
    if (newValue instanceof DefaultValue) return newValue

    const prevRunbookMetaState = get(runbookMetaState)
    const nextRunbookMetaState = produce(prevRunbookMetaState, draft => {
      draft.filters = newValue
    })

    set(runbookMetaState, nextRunbookMetaState)
  }
})

export const savedFilterStateLookup = selector<Record<SavedFilter['id'], SavedFilter>>({
  key: 'saved-filter:lookup',
  get: ({ get }) => {
    return keyBy(get(savedFilterState), 'id')
  }
})

export const defaultSavedFilterState = selector({
  key: 'saved-filters:default',
  get: ({ get }) => {
    return get(savedFilterState).find(filter => filter.default)
  }
})
