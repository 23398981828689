import { memo, useEffect, useRef, useState } from 'react'
import * as yup from 'yup'

import { useNotify } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { useRightPanelTypeState } from 'main/components/layout/right-panel'
import { useConnectSetting, useConnectSettingsPatch } from 'main/services/queries/use-connect-settings-query'
import { ActivateProjectModal } from '../modals/activate-project-modal'
import { ConnectDownloadsModal } from '../modals/connect-downloads-modal'
import { RenewCertificateModal } from '../modals/renew-certificate-modal'
import { queryClient } from 'main/query-client'
import { QueryKeys } from 'main/services/queries/query-keys'
import { ConnectSettingsEditHeaderMenu } from './connect-settings-edit-header-menu'
import { FormEditPanel } from 'main/components/shared/form'
import { FormType } from 'main/components/shared/form/form'
import { ConnectSettingsEditForm } from './connect-settings-edit-form'

export const ConnectSettingEditPanel = () => {
  const [{ connectSettingId }, { closeRightPanel }] = useRightPanelTypeState('connect-settings-edit')
  return (
    <>
      {connectSettingId ? (
        <ConnectSettingEdit connectSettingId={connectSettingId} onClose={closeRightPanel} key={connectSettingId} />
      ) : null}
    </>
  )
}

type ConnectSettingsEditType = {
  connectSettingId: number
  onClose: () => void
}

export type ConnectSettingEditProps = {
  mute_notifications?: boolean | undefined
  display_name: string
  notification_threshold: number
  critical_window_interval: number
}

const ConnectSettingEdit = memo(({ connectSettingId, onClose }: ConnectSettingsEditType) => {
  const { t } = useLanguage('connectSettings')
  const { data } = useConnectSetting(connectSettingId)
  const formRef = useRef<FormType<ConnectSettingEditProps>>(null)

  const [activateModalOpen, setActivateModalOpen] = useState(false)
  const [renewCertModalOpen, setRenewCertModalOpen] = useState(false)
  const [connectDownloadsModalOpen, setConnectDownloadsModalOpen] = useState(false)

  const patchMutation = useConnectSettingsPatch()
  const notify = useNotify()

  const validationSchema = yup.object({
    display_name: yup
      .string()
      .required(t('editPanel.formErrors.name.required'))
      .max(25, t('editPanel.formErrors.name.maxLength')),
    notification_threshold: yup
      .number()
      .typeError(t('editPanel.formErrors.notificationThreshold.type'))
      .required(t('editPanel.formErrors.notificationThreshold.required'))
      .min(30, t('editPanel.formErrors.notificationThreshold.minLength'))
      .max(300, t('editPanel.formErrors.notificationThreshold.maxLength')),
    critical_window_interval: yup
      .number()
      .typeError(t('editPanel.formErrors.criticalWindow.type'))
      .required(t('editPanel.formErrors.criticalWindow.required'))
      .min(1, t('editPanel.formErrors.criticalWindow.minLength'))
      .max(yup.ref('notification_threshold'), t('editPanel.formErrors.criticalWindow.maxLength')),
    mute_notifications: yup.boolean()
  })

  const handleClickActivateProject = () => {
    setActivateModalOpen(true)
  }
  const handleClickRenewCert = () => {
    setRenewCertModalOpen(true)
  }

  const handleClickConnectDownloads = () => {
    setConnectDownloadsModalOpen(true)
  }

  const handleSubmit = async (formData: ConnectSettingEditProps) => {
    patchMutation.mutate(
      { id: connectSettingId, payload: formData },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([QueryKeys.ConnectSettings])
          notify.success(t('toasters.updateSuccess'))
          onClose()
        },
        onError: () => {
          notify.error(t('toasters.updateFailure'))
        }
      }
    )
  }

  useEffect(() => {
    formRef.current?.reset({
      display_name: data?.display_name,
      notification_threshold: data?.notification_threshold,
      critical_window_interval: data?.critical_window_interval,
      mute_notifications: data?.mute_notifications
    })
  }, [data])

  return (
    <>
      <RenewCertificateModal open={renewCertModalOpen} setOpen={setRenewCertModalOpen} item={data} />
      <ConnectDownloadsModal open={connectDownloadsModalOpen} setOpen={setConnectDownloadsModalOpen} item={data} />
      <ActivateProjectModal open={activateModalOpen} setOpen={setActivateModalOpen} item={data} />

      <FormEditPanel
        ref={formRef}
        onClose={onClose}
        title={t('editPanel.headerTitle')}
        onSubmit={handleSubmit}
        schema={validationSchema}
        headerItems={[
          <ConnectSettingsEditHeaderMenu
            data={data}
            handleClickActivateProject={handleClickActivateProject}
            handleClickRenewCert={handleClickRenewCert}
            handleClickConnectDownloads={handleClickConnectDownloads}
          />
        ]}
      >
        <ConnectSettingsEditForm data={data} />
      </FormEditPanel>
    </>
  )
})
