// @ts-nocheck
import { GenericFormInputPresenter } from '@logicroom/validator'
import {
  initalizeDateWithDefaultTime,
  formatDate,
  cloneDate,
  setDate,
  getYear,
  getMonth,
  getDate,
  isDateValid
} from 'Shared/Helpers/DateService'
import { TextInputPresenter } from 'Components/Molecules'
import { observable } from 'mobx'

export class DateTimePresenter extends GenericFormInputPresenter<Date> {
  @observable
  public datePresenter: TextInputPresenter

  private format = 'iii, d MMM yyyy'
  public enableClear: boolean = false
  public onClear?: () => void
  public allowSameDay: boolean = true

  @observable
  public controlState: string = 'init'

  @observable
  public narrow: boolean = false

  public isDateRequired: boolean = false

  public filterDateValueAsString: null

  public updateFromFiltersRepository = () => null

  constructor(initialValue: Date) {
    super(initialValue)
    this.initialiseComponent(initialValue)
  }

  public clear = () => {
    this.controlState = 'initempty'
    this.isDirty = true
    this.initialiseComponent(null)

    if (this.onClear) {
      this.onClear()
    }
  }

  public reset = () => {
    this.clear()
    this.isDirty = false
  }

  public initialiseComponent = initialValue => {
    if (initialValue === null) {
      this.controlState = 'initempty'
      this.value = initalizeDateWithDefaultTime()
    } else {
      this.value = initialValue
      this.controlState = 'populated'
    }
    const initialText = initialValue ? formatDate(initialValue, this.format) : ''
    let currentLabel = this.datePresenter ? this.datePresenter.label : ''
    this.datePresenter = new TextInputPresenter(initialText)
      .withMiddleware(this.handleNewDateString)
      .withIconPrefix('calendar')

    if (this.isDateRequired) {
      this.datePresenter.isRequired()
    }

    this.datePresenter.disabled = this.disabled
    this.datePresenter.label = currentLabel
  }

  public handleTimeChange = this.onChange

  public handleDateChange = (date: Date) => {
    this.controlState = 'populated'
    const newDate = cloneDate(setDate(date, { year: getYear(date), month: getMonth(date), date: getDate(date) }))
    this.onChange(newDate)
    this.datePresenter.onChange(formatDate(newDate, this.format))
  }

  private handleNewDateString? = (dateString: string): string => {
    if (dateString.length === 0) return dateString

    if (!dateString.includes(',')) return ''

    const date = new Date(dateString)
    if (isDateValid(date) && formatDate(date, 'yyyy-MM-dd') !== formatDate(this.value, 'yyyy-MM-dd')) {
      const newDate = cloneDate(
        setDate(this.value, { year: getYear(date), month: getMonth(date), date: getDate(date) })
      )
      this.onChange(newDate)
      return formatDate(newDate, this.format)
    }
    return dateString
  }

  public withPlaceholder = (placeholder: string) => {
    this.datePresenter.placeholder = placeholder
    return this
  }

  public withLabel? = (label: string) => {
    this.datePresenter.label = label
    return this
  }

  public withClear? = () => {
    this.enableClear = true
    return this
  }

  public withOnClear = (onClear: () => void) => {
    this.onClear = onClear
    return this
  }

  public withAllowedSameDaySelection = (allowSameDay: boolean) => {
    this.allowSameDay = allowSameDay
    return this
  }

  public isDisabled = () => {
    this.disabled = true
    this.datePresenter.disabled = true
    return this
  }

  public isEnabled = () => {
    this.disabled = false
    this.datePresenter.disabled = false
    return this
  }

  public getValueInServiceFormat = () => {
    if (!this.value) {
      return null
    }

    return formatDate(this.value)
  }

  public withNarrow = narrow => {
    this.narrow = narrow
    return this
  }

  public withIsRequired = () => {
    this.isDateRequired = true
    return this
  }
}
