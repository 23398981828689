import { Button as GrommetButton } from 'grommet'
import styled from 'styled-components'

import { Box, Icon, IconProps } from '@cutover/react-ui'
import { useSidebarNavContext } from './nav-context'

export const FloatingToggleButton = () => {
  const { isLeftSidebarOpen, toggleLeftSidebar } = useSidebarNavContext()

  return (
    <ToggleButtonContainer>
      <GrommetButton
        css="border-radius: 50%"
        plain
        onClick={() => toggleLeftSidebar()}
        icon={<ToggleIcon open={isLeftSidebarOpen} />}
      />
    </ToggleButtonContainer>
  )
}

export const ToggleButtonContainer = styled(Box).attrs(() => ({
  elevation: 'large',
  round: 'full',
  height: '24px',
  width: '24px',
  background: 'bg',
  justify: 'center',
  align: 'center'
}))`
  position: absolute;
  top: 60px;
  right: -12px;
  width: 24px;
  height: 24px;
  z-index: 1;
`

const ToggleIcon = styled(Icon).attrs((props: IconProps & { open: boolean }) => ({
  icon: props.open ? 'caret-left' : 'caret-right',
  color: 'primary'
}))<{ open: boolean }>`
  opacity: 0.7;

  ${ToggleButtonContainer}:hover & {
    opacity: 1;
  }
`
