// @ts-nocheck
import { inject, injectable } from 'inversify'
import { GenericFormPresenter } from '@logicroom/validator'
import { observe, observable, computed, action, Lambda } from 'mobx'
import { container } from 'app/Config/IOC'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'
import { Types } from 'Gateways/Service/Types'
import { emailValidator } from 'Helpers/InputCustomRules'
import { UsersRepository } from 'app/Repositories/User/UsersRepository'
import { AuthenticationRepository } from 'app/Authentication/AuthenticationRepository'
import { ConfigsRepository } from 'Configs/ConfigsRepository'
import { UsersViewPresenter } from 'app/Access/Users/UsersPage/UsersViewPresenter'
import {
  ErrorAlertPresenter,
  CheckboxPresenter,
  RoleTypesPresenter,
  TextInputPresenter,
  ToasterService
} from 'Components/Molecules'
import { RadioButtonsPresenter } from 'Components/Atoms'
import { TableScrollerService } from 'Components/Organisms'
import { toggleAnimateCutoverLogo } from 'Shared/Helpers/NavLogoAnimation'
import { UserEditSettingsPresenter } from '../UsersEdit/UserEditSettingsPresenter'

@injectable()
export class UserAddPresenter {
  private authRespository = container.get(AuthenticationRepository)
  private configsRepository = container.get(ConfigsRepository)
  public usersViewPresenter: UsersViewPresenter = container.get(UsersViewPresenter)
  public language: ILanguageService = container.get(Types.ILanguageService)

  @observable
  public includeSsoId = false

  @inject(UsersRepository)
  public usersRepository = container.get(UsersRepository)

  @inject(ToasterService)
  public toasterGateway = container.get(ToasterService)

  @observable
  public typeRadioPresenter = new RadioButtonsPresenter(
    [this.language.get('users:add:individualUpload'), this.language.get('users:add:bulkUpload')],
    this.language.get('users:add:individualUpload')
  )
    .withLabel('Add new users')
    .withFocusOnLoad(true)
    .withMiddleware(option => {
      if (option === this.language.get('users:add:individualUpload')) {
        this.isIndividual = true
        this.usersViewPresenter.addModalSubmitButtonText = this.language.get('common:createButton').toUpperCase()
        this.usersViewPresenter.addModalSubmitButtonIcon = 'add'
        this.fileForUpload = null
      } else {
        this.isIndividual = false
        this.usersViewPresenter.addModalSubmitButtonText = this.language.get('common:uploadButton').toUpperCase()
        this.usersViewPresenter.addModalSubmitButtonIcon = 'upload'
      }
      return option
    })

  @observable
  public isIndividual: boolean = true

  @observable
  public errorAlertPresenter: ErrorAlertPresenter = new ErrorAlertPresenter()

  @observable
  public firstNamePresenter = new TextInputPresenter()
    .withLabel(this.language.get('users:form:firstName'))
    .isRequiredTrimWhiteSpace()

  @observable
  public lastNamePresenter = new TextInputPresenter()
    .withLabel(this.language.get('users:form:lastName'))
    .isRequiredTrimWhiteSpace()

  @observable
  public emailPresenter = new TextInputPresenter()
    .withLabel(this.language.get('users:form:email'))
    .isRequiredTrimWhiteSpace()
    .addCustomRule(emailValidator)

  @observable
  public uniqueIdPresenter = new TextInputPresenter()
    .withLabel(this.language.get('users:form:uniqueId:label'))
    .isRequiredTrimWhiteSpace(this.language.get('users:form:uniqueId:isRequired'))
    .withHelpText(this.language.get('users:form:uniqueId:helpText'))

  @observable
  public roleTypePresenter = new RoleTypesPresenter('user', false, ['create'])

  @observable
  public userEditSettingPresenter = new UserEditSettingsPresenter()

  @observable
  public loginOptionPresenter = this.userEditSettingPresenter.loginOptionPresenter.withMiddleware(value => {
    if (value === this.language.get('users:edit:settings:login:none')) {
      this.sendInvitationPresenter.value = false
      this.sendInvitationPresenter.disabled = true
    } else {
      this.sendInvitationPresenter.value = true
      this.sendInvitationPresenter.disabled = false
    }
    return value
  })

  @observable
  public sendInvitationPresenter = new CheckboxPresenter(
    this.language.get('users:form:invitation:send'),
    true
  ).withMiddleware((value: boolean) => {
    if (!value) {
      this.inviteMessagePresenter.value = ''
    }

    return value
  })

  @observable
  public inviteMessagePresenter = new TextInputPresenter()
    .withLabel(this.language.get('users:form:inviteMessage:label'))
    .withHelpText(this.language.get('users:form:inviteMessage:helpText'))
    .ofMaxLength(1600)

  @observable
  public userAddForm = new GenericFormPresenter()
    .addFormInput(this.firstNamePresenter)
    .addFormInput(this.lastNamePresenter)
    .addFormInput(this.emailPresenter)

  @observable
  public isSubmitted = false

  @observable
  public responseErrors: string[] = []

  @observable
  public postSuccessCall?: () => void

  @observable
  public cancelCallback?: () => void

  @action
  public withSsoId = (includeSsoId: boolean) => {
    this.includeSsoId = includeSsoId

    if (this.includeSsoId === true) {
      this.userAddForm.addFormInput(this.uniqueIdPresenter)
    }

    return this
  }

  @action
  public withPostSuccessCall = (call: () => void) => {
    this.postSuccessCall = call
    return this
  }

  @action
  public withCancelCallback = (call: () => void) => {
    this.cancelCallback = call
    return this
  }

  public loadConfig = async () => {
    await this.configsRepository.load()

    if (this.configsRepository.oauthUniqueId && this.configsRepository.oauthActive) {
      this.withSsoId(true)
    }
  }

  public clearDisposers = () => {
    this.disposers.forEach(disposer => disposer())
  }

  @observable
  public disposers: Lambda[] = []

  @action
  // When SSO is enabled the login option on user creation should default to SSO,
  // it is only when there is no active SSO config that it should default to email
  public setupLoginOptions = () => {
    if (this.configsRepository.oauthActive) {
      this.userEditSettingPresenter.loginOptionPresenter.selectedOption = this.language.get(
        'users:edit:settings:login:saml'
      )
    } else {
      this.userEditSettingPresenter.loginOptionPresenter.selectedOption = this.language.get(
        'users:edit:settings:login:email'
      )
    }

    this.userEditSettingPresenter.disableLoginOptions()
  }

  @action
  public setupObserve = () => {
    const autorunDisposer = observe(
      this.usersViewPresenter,
      'submitted',
      () => {
        if (this.usersViewPresenter.submitted) {
          this.submit()
        }
      },
      true
    )

    this.disposers.push(autorunDisposer)

    this.loadConfig()
    this.setupLoginOptions()
  }

  @observable
  public fileForUpload: File = null

  @action
  public handleFileForUpload = (file: File) => {
    this.fileForUpload = file
  }

  private showToasterNotification = response => {
    const { body } = response
    const { success, user } = body
    const messages = success && success.messages
    if (this.isIndividual) {
      let notification = ''
      let title = ''

      const firstMessage = messages && messages[0]
      if (firstMessage) {
        title = firstMessage.title || this.language.get('users:add:createUser:success')
        notification = firstMessage.text
      } else {
        title = this.language.get('users:edit:saveUserNotifications:success')
        notification = this.language.get('users:edit:saveUserNotifications:userUpdated', {
          interpolation: { escapeValue: true },
          name: user.name
        })
      }
      this.toasterGateway.pop(title, notification, 'success')
    } else {
      if (messages && messages.length > 0) {
        for (const message of messages) {
          this.toasterGateway.pop(message.title, message.text, 'success')
        }
      }
    }
  }

  public submit = async () => {
    toggleAnimateCutoverLogo(true)
    let response = this.isIndividual ? await this.submitIndividual() : await this.submitBulk()
    if (response.success) {
      if (this.postSuccessCall) this.postSuccessCall()
      await this.usersRepository.loadData({})
      this.showToasterNotification(response)
      container.get(TableScrollerService).doScroll()
    } else {
      this.processFailure()
      this.userAddForm.serverErrors.push(...response.errorMessages)
      this.errorAlertPresenter.setErrorMessages([...response.errorMessages])
    }
    toggleAnimateCutoverLogo(false)
    this.usersViewPresenter.submitted = false
  }

  private submitIndividual = async () => {
    this.isSubmitted = true
    this.responseErrors = []

    this.userAddForm.serverErrors = []

    if (!this.userAddForm.isValid) {
      this.firstNamePresenter.isDirty = true
      this.lastNamePresenter.isDirty = true
      this.emailPresenter.isDirty = true

      if (this.includeSsoId === true) this.uniqueIdPresenter.isDirty = true
      this.processFailure()
      return { success: false, errorMessages: [this.language.get('common:formInvalid')] }
    }
    this.usersViewPresenter.addModalSubmitButtonLoading = true
    const response = await this.authRespository.individualInvitation(
      {
        firstName: this.firstNamePresenter.value,
        lastName: this.lastNamePresenter.value,
        email: this.emailPresenter.value,
        uniqueId: this.uniqueIdPresenter.value,
        inviteMessage: this.inviteMessagePresenter.value,
        provider: this.userEditSettingPresenter.providerValue
      },
      this.roleTypePresenter.selectedRoles,
      !this.sendInvitationPresenter.value
    )
    if (response.errorMessages) this.responseErrors = response.errorMessages
    this.usersViewPresenter.addModalSubmitButtonLoading = false

    return response
  }

  private submitBulk = async () => {
    this.isSubmitted = true
    this.responseErrors = []

    this.usersViewPresenter.addModalSubmitButtonLoading = true
    const response = await this.authRespository.bulkInvitation({
      data: this.fileForUpload,
      roles: this.roleTypePresenter.selectedRoles,
      sendInvitation: this.sendInvitationPresenter.value,
      inviteMessage: this.inviteMessagePresenter.value,
      provider: this.userEditSettingPresenter.providerValue
    })

    if (response.errorMessages) {
      this.responseErrors = response.errorMessages
      this.resetForm()
    }

    this.usersViewPresenter.addModalSubmitButtonLoading = false
    return response
  }

  public cancel = () => {
    if (this.cancelCallback) this.cancelCallback()
  }

  @computed
  public get bulkUploadText(): string {
    return this.includeSsoId
      ? this.language.get('users:add:userAddBulkUpload:withSSO')
      : this.language.get('users:add:userAddBulkUpload:withoutSSO')
  }

  @computed
  public get hasResponseErrors(): boolean {
    return this.responseErrors.length > 0
  }

  private processFailure = (): boolean => {
    setTimeout(() => {
      this.usersViewPresenter.submitted = false
    }, 500)

    return false
  }

  private resetForm = () => {
    this.fileForUpload = null
    this.userAddForm.reset()
    const uploaderEl: HTMLInputElement = document.querySelector('#myFile')
    if (uploaderEl) uploaderEl.value = ''
  }
}
