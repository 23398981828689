// @ts-nocheck
import { useEffect } from 'react'
import styled from 'styled-components'
import { px2rem } from 'app/Styles/Variables'
import { observer } from 'mobx-react-lite'
import { TaskTypeFormPresenter } from './TaskTypeFormPresenter'
import { AccountVisibility } from 'Components/Organisms'
import { Checkbox, Duration, TextInput, ErrorAlert } from 'Components/Molecules'
import { RadioButtons } from 'Components/Atoms'
import { colors } from 'app/Styles/Variables'

const AdditionalSettingsHeadingWrapper = styled.div`
  font-size: ${px2rem(13)};
  line-height: ${px2rem(12)};
  color: ${colors.textLight};
  transition: none;
  font-weight: 500;
  margin-bottom: ${px2rem(2)};
`
const FieldWrapper = styled.div`
  margin-bottom: ${px2rem(16)};
`
const SettingsWrapper = styled.div`
  margin-bottom: ${px2rem(16)};
  border-bottom: ${px2rem(1)} dashed ${colors.primaryGreyHoverBackgroundColor};
`

interface ITaskTypeForm {
  presenter: TaskTypeFormPresenter
  formType?: 'add' | 'edit'
}

export const TaskTypeFormComponent = observer<ITaskTypeForm>(({ presenter, formType }) => {
  const { errorAlertPresenter, submitted, formIsValid, isAutoFinish, additionalSettingsHeading, isCommsAvailable } =
    presenter

  useEffect(() => {
    presenter.conditionalProgressionPresenter.disabled = !!presenter.linkablePresenter.value
    presenter.autoFinishPresenter.disabled = !!presenter.linkablePresenter.value
    presenter.enableEndFixedPresenter.disabled = !!presenter.linkablePresenter.value
    presenter.enableStartFixedPresenter.disabled = !!presenter.linkablePresenter.value

    if (!!presenter.linkablePresenter.value && presenter.viewModel.key !== 'snippet') {
      presenter.conditionalProgressionPresenter.value = false
      presenter.enableEndFixedPresenter.value = false
      presenter.autoFinishPresenter.value = false
      presenter.enableStartFixedPresenter.value = false
    }
  }, [presenter.linkablePresenter.value])

  useEffect(() => {
    const disableLinkable =
      presenter.conditionalProgressionPresenter.value ||
      presenter.enableEndFixedPresenter.value ||
      presenter.enableStartFixedPresenter.value

    presenter.linkablePresenter.disabled = disableLinkable
    if (disableLinkable) {
      presenter.linkablePresenter.value = false
    }
  }, [
    presenter.conditionalProgressionPresenter.value,
    presenter.enableEndFixedPresenter.value,
    presenter.enableStartFixedPresenter.value
  ])

  return (
    <form>
      {submitted && !formIsValid && <ErrorAlert presenter={errorAlertPresenter} formAlert />}
      <FieldWrapper>
        <TextInput presenter={presenter.namePresenter} parentIsFocused={formType === 'add'} formTopInputElement />
      </FieldWrapper>
      <FieldWrapper>
        <AccountVisibility presenter={presenter.visibilityPresenter} />
      </FieldWrapper>
      {!isAutoFinish && <Duration presenter={presenter.defaultDurationPresenter} />}
      <SettingsWrapper>
        <AdditionalSettingsHeadingWrapper>{additionalSettingsHeading}</AdditionalSettingsHeadingWrapper>
        <Checkbox presenter={presenter.highlightPresenter} />
        <Checkbox presenter={presenter.autoStartPresenter} />
        <Checkbox presenter={presenter.autoFinishPresenter} />
        <Checkbox presenter={presenter.conditionalProgressionPresenter} />
        <Checkbox presenter={presenter.enableStartFixedPresenter} />
        <Checkbox presenter={presenter.enableEndFixedPresenter} />
        <Checkbox presenter={presenter.linkablePresenter} />
      </SettingsWrapper>

      {isCommsAvailable && <RadioButtons presenter={presenter.commsRadioPresenter} />}
    </form>
  )
})
