import { useMutation } from 'react-query'

import { FieldValuesAttributes } from './types'
import { apiClient } from 'main/services/api'
import { RunbookMergeResponse } from '../api/data-providers/runbook-types'

type NewMergeRunbook = {
  name: string
  account_id: number
  field_values_attributes: FieldValuesAttributes[]
  project_id: number
  roles: any[]
  runbook_type_id: number
  runbook_versions_attributes: { start_scheduled: Date }[]
  timezone: string
}

export type MergeRunbooksPayload = {
  runbook_ids: number[]
  shift_time: boolean
  target_runbook_id?: number | string | null
  runbook?: NewMergeRunbook
  insertion_type?: 'sequence' | 'parallel'
  task_parent_id_to_link_to?: number
}

export const mergeRunbooks = async (payload: MergeRunbooksPayload) => {
  const { data } = await apiClient.post<MergeRunbooksPayload, RunbookMergeResponse>({
    url: 'runbooks/merge',
    data: payload
  })

  return data as RunbookMergeResponse
}

export const useMergeRunbooks = () => {
  return useMutation<RunbookMergeResponse, Error, MergeRunbooksPayload>('merge-runbooks', mergeRunbooks)
}
