// @ts-nocheck

interface IFormProps {
  onSubmit?: () => void
  children: ReactNode
}

export const Form = (props: IFormProps) => {
  const handleSubmit = event => {
    event.preventDefault()

    if (props.onSubmit) {
      props.onSubmit()
    }
  }

  return (
    <form noValidate onSubmit={handleSubmit}>
      {props.children}
    </form>
  )
}
