import { Chart } from './chart'
import { Duration } from './duration'
import { formatDuration, formatDurationDelta } from './format-duration'
import { NoDataAvailable } from './no-data-available'
import { Tile } from './tile'
import { DurationDelta, TaskDuration } from './types'

export type LongestAverageTaskDurationSingleConnectedProps = {
  name?: string
  onEvent: (payload: any) => void
  values: TaskDuration[]
}

export function LongestAverageTaskDurationSingleConnected({
  name,
  values
}: LongestAverageTaskDurationSingleConnectedProps) {
  const [data]: TaskDuration[] = values

  return (
    <Chart
      title={name}
      titleKey="dashboard:longestAverageTaskDurationSingle:title"
      render={() => {
        if (!data?.name || !data?.duration) {
          return <NoDataAvailable />
        }

        const formattedDuration = formatDuration(data.duration)

        if (!formattedDuration) {
          return <NoDataAvailable />
        }

        return (
          <LongestAverageTaskDurationSingle taskTitle={data.name} duration={formattedDuration} delta={data.delta} />
        )
      }}
    />
  )
}

export type LongestAverageTaskDurationSingleProps = {
  taskTitle: string
  duration: string
  delta?: DurationDelta
}

export function LongestAverageTaskDurationSingle({
  taskTitle,
  duration,
  delta
}: LongestAverageTaskDurationSingleProps) {
  const formattedDelta = formatDurationDelta(delta)

  return (
    <Tile title={taskTitle}>
      <Duration duration={duration} delta={formattedDelta} />
    </Tile>
  )
}
