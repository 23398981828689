// @ts-nocheck

import { observable, action } from 'mobx'
import { IFormInputGroupItem } from './IFormInputGroupItem'

export class FormInputGroupPresenter<PresenterType, DataType> {
  @observable
  public items: IFormInputGroupItem<PresenterType, DataType>[] = []

  @observable
  public createItemPresenter: (data: DataType) => PresenterType

  @observable
  public itemComponent: (props: React.PropsWithChildren<{ presenter: PresenterType }>) => React.ReactElement

  @observable
  public componentKeyProperty: string = 'id'

  constructor(
    itemComponent: (props: React.PropsWithChildren<{ presenter: PresenterType }>) => React.ReactElement,
    createItemPresenter: (data: DataType) => PresenterType
  ) {
    this.itemComponent = itemComponent
    this.createItemPresenter = createItemPresenter
  }

  @action
  public withData = (data: DataType[]) => {
    this.items = data.map(dataRow => {
      return {
        data: dataRow,
        component: this.itemComponent,
        presenter: this.createItemPresenter(dataRow)
      }
    })
    return this
  }
}
