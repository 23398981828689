import { memo, useCallback, useMemo, useRef } from 'react'
import { Virtuoso } from 'react-virtuoso'
import { useWindowSize } from 'react-use'

import { Menu, NoResourceFound, useUpdateEffect } from '@cutover/react-ui'
import { useClearAllFilterState } from 'main/recoil/data-access'
import { TaskListHeader } from './task-list-header'
import { useRootTaskCreateForm } from './task-item/task-item-create'
import { useTaskListHotkeys } from './use-task-list-hotkeys'
import { TaskListItem } from './task-item/task-list-item'
import { useFilterPanelOpenState, useRightPanelLayoutOpenState } from 'main/components/layout/layout-hooks'
import { useRightPanelTypeValue } from 'main/components/layout/right-panel'
import { ActiveRunbookVersionModel, RunbookViewModel, TaskModel } from 'main/data-access'

const TASK_LIST_HEADERS_HEIGHT = 200

// TODO: pull this out for suspense to not render the component content until tasks exist
export const TaskList = () => {
  useTaskListHotkeys()

  const runbookVersionId = ActiveRunbookVersionModel.useId()
  const clearAllFilters = useClearAllFilterState()
  const filteredTaskIds = TaskModel.useGetIds({ scope: 'filtered' })
  const { can: canCreateRootTask } = RunbookViewModel.usePermission('create:tasks')
  const rootTaskCreateForm = useRootTaskCreateForm({ taskCount: filteredTaskIds.length })

  return (
    <>
      {!canCreateRootTask && filteredTaskIds.length === 0 ? (
        <NoResourceFound context="task" clearAllFilters={clearAllFilters} />
      ) : (
        <>
          <TaskListHeader canCreateRootTask={canCreateRootTask} />
          {rootTaskCreateForm}
          <TaskListContent key={runbookVersionId} ids={filteredTaskIds} />
        </>
      )}
    </>
  )
}

export const TaskListContent = memo(
  ({ ids: filteredIds }: { ids: number[]; criticalIds?: number[]; floatLookup?: Record<number, number> }) => {
    const allTaskIds = TaskModel.useGetIds()
    const isHighlightMode = RunbookViewModel.useGet('highlightMode')
    const listIds = isHighlightMode ? allTaskIds : filteredIds
    const { height } = useWindowSize()
    const scrollSize = height - TASK_LIST_HEADERS_HEIGHT
    const { taskId: editingTaskId } = useRightPanelTypeValue('task-edit')
    const { taskId: commentsViewingTaskId } = useRightPanelTypeValue('runbook-comments')

    return (
      <>
        <TaskItemMenu />
        <Virtuoso
          style={{ height: `${scrollSize}px` }}
          totalCount={listIds.length}
          increaseViewportBy={scrollSize}
          itemContent={index => {
            const id = listIds[index]
            const previousTaskId = listIds[index - 1]
            const nextTaskId = listIds[index + 1]
            return (
              <TaskListItem
                isEditing={editingTaskId === id || commentsViewingTaskId === id}
                id={id}
                nextTaskId={nextTaskId}
                previousTaskId={previousTaskId}
                isFaded={isHighlightMode ? !filteredIds.includes(id) : undefined}
              />
            )
          }}
        />
      </>
    )
  }
)

const TaskItemMenu = memo(() => {
  const defaultRef = useRef<HTMLElement>(null)
  const { open, triggerRef, items, type, minWidth, maxWidth, maxHeight } = RunbookViewModel.useGet('menu')

  const clearMenu = RunbookViewModel.useAction('taskMenu:clear')
  const setMenuClosed = RunbookViewModel.useAction('taskMenu:close')

  const isRightPanelOpen = useRightPanelLayoutOpenState()
  const isFilterPanelOpen = useFilterPanelOpenState()

  const menuAlignment = useMemo(() => {
    if (type === 'options') {
      return 'end'
    } else {
      return 'start'
    }
  }, [type])

  useUpdateEffect(() => {
    if (open) {
      clearMenu()
      setMenuClosed()
    }
  }, [isRightPanelOpen, isFilterPanelOpen])

  const handleClose = useCallback((e: any) => {
    setMenuClosed()
    e?.reason !== 'blur' && clearMenu()
  }, [])

  return (
    <Menu
      isOpen={open}
      triggerRef={triggerRef ?? defaultRef}
      align={menuAlignment}
      items={items}
      //@ts-ignore
      onClose={handleClose}
      minWidth={minWidth}
      maxWidth={maxWidth}
      maxHeight={maxHeight}
      arrow
    />
  )
})
