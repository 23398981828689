import { selectorFamily } from 'recoil'

import { appViews } from '../apps-atoms'

export const appViewWithContext = selectorFamily({
  key: 'AppView',
  get:
    (context: string) =>
    ({ get }) => {
      return get(appViews)[context]
    }
})

export const runbookAppViewsWithOrder = selectorFamily({
  key: 'OrderedAppViews',
  get:
    (runbookId: string) =>
    ({ get }) => {
      return Object.values(get(appViews))
        .filter(obj => obj.resourceId === runbookId)
        .sort((a, b) => {
          if (a.order && b.order) {
            if (a.order < b.order) return -1
            if (a.order > b.order) return 1
            return 0
          }
          return 0
        })
    }
})
