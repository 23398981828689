import { useState } from 'react'

import { RadioboxGroup } from '@cutover/react-ui'
import { useComponentPropsAndState } from '../apps-state'
import { AppComponentNodeProps, ContentNodeOption } from '../apps-types'

type RadiosFieldNodeProps = AppComponentNodeProps & {
  label: string
  options: ContentNodeOption[]
  selected: string
  name: string
  readOnly?: boolean
  error?: boolean
}

export const RadiosFieldNode = ({ appId, resourceId, id, ...props }: RadiosFieldNodeProps) => {
  const { componentProps, setState } = useComponentPropsAndState(appId, resourceId, id, props)
  const { label, options, name, selected: initialSelected, readOnly, error } = componentProps as RadiosFieldNodeProps

  const [selectedOption, setSelectedOption] = useState<string>(initialSelected)

  const updateFieldValue = (value: string) => {
    setSelectedOption(value)
    setState({ name, value })
  }

  return (
    <RadioboxGroup
      name={name}
      options={options}
      value={selectedOption}
      label={label}
      onChange={event => updateFieldValue(event.target.value)}
      readOnly={readOnly}
      hasError={error}
    />
  )
}
