// @ts-nocheck
import { GenericFormInputPresenter } from '@logicroom/validator'
import { getDurationInDays } from 'Shared/Helpers/DateService'
import { computed } from 'mobx'

export class DurationPresenter extends GenericFormInputPresenter<number> {
  public label: string

  @computed
  public get days(): number {
    return getDurationInDays(this.value).days
  }

  @computed
  public get hours(): number {
    return getDurationInDays(this.value).hours
  }

  @computed
  public get minutes(): number {
    return getDurationInDays(this.value).minutes
  }

  @computed
  public get seconds(): number {
    return getDurationInDays(this.value).seconds
  }

  @computed
  public get daysText(): string {
    return this.days === 1 ? 'day' : 'days'
  }
  @computed
  public get hoursText(): string {
    return this.hours === 1 ? 'hour' : 'hrs'
  }
  @computed
  public get minutesText(): string {
    return this.minutes === 1 ? 'min' : 'mins'
  }
  @computed
  public get secondsText(): string {
    return this.seconds === 1 ? 'sec' : 'secs'
  }

  constructor(initialValue: number) {
    super(initialValue)
    this.withMiddleware(newValue => {
      return newValue < 0 ? 0 : newValue
    })
  }

  public handleDayChange? = (value: number) => {
    const difference = value - this.days
    this.onChange(this.value + difference * (60 * 60 * 24))
  }

  public handleHourChange? = (value: number) => {
    const difference = value - this.hours
    this.onChange(this.value + difference * (60 * 60))
  }

  public handleMinuteChange? = (value: number) => {
    const difference = value - this.minutes
    this.onChange(this.value + difference * 60)
  }

  public handleSecondChange? = (value: number) => {
    const difference = value - this.seconds
    this.onChange(this.value + difference)
  }

  public withLabel? = (label: string) => {
    this.label = label
    return this
  }
}
