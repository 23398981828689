import { useMutation, useQueryClient } from 'react-query'

import { apiClient } from 'main/services/api'
import { ApiError } from 'main/services/api/http-gateway-adapter'

export type RunbookTeamUnlinkProps = {
  runbookId: number
  runbookVersionId: number
  teamId: number
}

export const useRunbookTeamUnlink = () => {
  const queryClient = useQueryClient()

  return useMutation<unknown, ApiError, RunbookTeamUnlinkProps>(
    'runbook-team-unlink',
    async ({ runbookId, runbookVersionId, teamId }: RunbookTeamUnlinkProps) => {
      const { data } = await apiClient.patch<{}, any>({
        url: `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/runbook_teams/${teamId}/unlink`
      })

      return data
    },
    {
      onSuccess: (_, { runbookId, runbookVersionId, teamId }) => {
        queryClient.invalidateQueries(['feature', 'runbook-team', runbookId, runbookVersionId, teamId])
      }
    }
  )
}
