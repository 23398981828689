import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Superscript = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Superscript" {...props}>
    <path d="M32.213 18.216v2.528H42.98V17.91h-5.895v-.092l1.74-1.605c1.056-.919 1.87-1.707 2.443-2.365.578-.658.978-1.26 1.2-1.804a4.323 4.323 0 0 0 .334-1.648c0-.87-.224-1.636-.674-2.294-.445-.663-1.075-1.179-1.89-1.548-.814-.37-1.773-.554-2.876-.554-1.07 0-2.01.197-2.82.59-.804.388-1.43.942-1.874 1.661-.446.72-.668 1.575-.668 2.564h3.33c0-.44.084-.814.25-1.122.17-.308.407-.542.71-.703a2.171 2.171 0 0 1 1.058-.249c.398 0 .75.079 1.058.235.308.156.55.379.725.667.175.285.262.628.262 1.03 0 .36-.073.694-.22 1.002-.147.303-.36.606-.639.909-.28.298-.618.627-1.016.987l-5.305 4.645Zm-6.373-5.377 2.12 2.121-8.838 8.839 8.839 8.839-2.122 2.121L17 25.92l-8.838 8.84-2.122-2.122 8.84-8.839-8.84-8.839 2.122-2.121L17 21.678l8.84-8.84Z" fill="#000" />
  </StyledIcon>
))

Superscript.displayName = 'Superscript'

export { Superscript }
