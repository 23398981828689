import { MergeExclusive } from 'type-fest'

import { FilterBase, FilterBasePropsWithHeader } from './filter-base'
import { handleFilterChange, removeUnusedProperties } from './filter-helpers'
import { FilterGroup, FilterOptionCheckbox, SelectedFilters } from './filter-types'

export type FilterProps = MergeExclusive<
  FilterDefaultProps,
  FilterBasePropsWithHeader & {
    /** When true, returns a Filter component with none of the default functions. Useful if you need more control over onChange, onClearAll, onReverse etc */
    base: true
  }
>

type FilterDefaultProps = {
  /** returns an argument representing the search params to be sent to the server */
  onChange: (filters: SelectedFilters) => void
  /** Filter is in a layout filter panel (e.g. workspace page) */
  isLayoutFilter?: boolean
} & Pick<
  FilterBasePropsWithHeader,
  'getAppliedIndicatorText' | 'filterData' | 'selected' | 'onHierarchyOptionEditClick'
>

export const Filter = ({ base, ...props }: FilterProps) => {
  return base ? (
    <FilterBase {...(props as FilterBasePropsWithHeader)} />
  ) : (
    <FilterDefault isLayoutFilter {...(props as FilterDefaultProps)} />
  )
}

export const FilterDefault = ({ selected = {}, filterData, onChange, ...baseProps }: FilterDefaultProps) => {
  const handleTabClear = (slug: string, filter: FilterGroup) => {
    const newSelected = { ...selected }
    // @ts-ignore
    filter.customFieldId && filter.slug ? delete newSelected[filter.slug][slug] : delete newSelected[slug]
    removeUnusedProperties(newSelected)
    onChange(newSelected)
  }

  const handleReverse = (option: FilterOptionCheckbox) => {
    if (!option.slug) return

    const newSelected = { ...selected }
    newSelected[option.slug] = newSelected[option.slug] === option.value ? option.valueReversed : option.value
    onChange(newSelected)
  }

  const handleSpotlightAndGroupChange = (type: 'group' | 'spotlight', filter: FilterGroup) => {
    const selectedValue = filter.customFieldId || filter.title.toLowerCase()

    if (selected[type] && selected[type] === selectedValue) {
      const newSelected = { ...selected }
      delete newSelected[type]
      onChange(newSelected)
    } else {
      const newSelected = { ...selected, [type]: selectedValue }
      onChange(newSelected)
    }
  }

  return (
    <FilterBase
      filterData={filterData}
      onChange={(filter, option, override) => onChange(handleFilterChange(selected, filter, option, override))}
      onClearAll={() => onChange({})}
      onReverse={(_, option) => handleReverse(option)}
      onTabClear={handleTabClear}
      onSpotlightAndGroupChange={handleSpotlightAndGroupChange}
      selected={selected}
      withHeader
      {...baseProps}
    />
  )
}
