import { Placement } from '@popperjs/core'
import styled, { css, keyframes } from 'styled-components/macro'

import { Box } from '../layout/box/box'
import { Tooltip } from '../overlays/tooltip/tooltip'
import { ColorProp } from '../theme/color'
import { resolveColor } from '../theme/grommet-utils'
import { ThemeType } from '../theme/theme'

const RAG_COLOR_MAPPING: Record<RAG, ColorProp> = {
  red: 'rag-red',
  green: 'rag-green',
  amber: 'rag-amber'
}

const RAG_PULSE_COLOR_MAPPING: Record<RAG, ColorProp> = {
  red: 'rag-red-pulse',
  green: 'rag-green-pulse',
  amber: 'rag-amber-pulse'
}

type RAG = Exclude<RagIndicatorProps['status'], 'off'>

type RagIndicatorProps = {
  status: 'red' | 'amber' | 'green' | 'off'
  disableAnimation?: boolean
  tip?: string | null
  tipPlacement?: Placement
  'data-testid'?: string
  className?: string
}

export const RagIndicator = ({ status, disableAnimation = false, tip, tipPlacement, ...props }: RagIndicatorProps) => {
  return status === 'off' ? null : (
    <Box flex={false} align="center" height="16px" width="16px" overflow="visible" round="full" {...props}>
      <Tooltip content={`[${status[0].toUpperCase()}] ${tip}`} placement={tipPlacement} isDisabled={tip === undefined}>
        <AnimatedBox status={status} disableAnimation={disableAnimation} />
      </Tooltip>
    </Box>
  )
}

type AnimatedBoxProps = Omit<RagIndicatorProps, 'status'> & {
  status: 'red' | 'amber' | 'green'
}

const AnimatedBox = styled(props => <Box {...props} />).attrs((props: AnimatedBoxProps) => ({
  height: '16px',
  width: '16px',
  round: 'full',
  border: { size: '3px', color: 'bg' },
  background: RAG_COLOR_MAPPING[props.status]
}))<AnimatedBoxProps>`
  ${props => !props.disableAnimation && createAnimation(props.status, props.theme)};
`

const createRagKeyframes = (status: RAG, theme: ThemeType) => {
  return keyframes`
      0% {
        transform: scale(0.8);
        opacity: 0.7;
        box-shadow: 0 0 0 0 ${resolveColor(RAG_PULSE_COLOR_MAPPING[status], theme)};
      }
      50% {
        transform: scale(1);
        opacity: 1;
        box-shadow: 0 0 0 10px transparent;
      }
      100% {
        transform: scale(0.8);
        opacity: 0.7;
        box-shadow: 0 0 0 0 transparent;
      }
    `
}

const createAnimation = (status: RAG, theme: ThemeType) => {
  if (status !== 'green') {
    return css`
      animation: ${createRagKeyframes(status, theme)} ${status === 'red' ? '1s' : '1.5s'} infinite;
    `
  } else {
    return css`
      animation: none;
    `
  }
}
