import { useCallback, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { Box, Button, IconName, Message, Modal, TextArea, TextEditor } from '@cutover/react-ui'
import {
  RecipientMultiselect,
  RecipientType,
  TeamRecipient,
  UserRecipient
} from 'main/components/recipients-multiselect'
import { useLanguage } from 'main/services/hooks'
import { testTaskComms } from 'main/services/queries/use-task'
import { TaskComms } from 'main/services/queries/types'
import { ApiError } from 'main/services/api/http-gateway-adapter'
import { TaskEditFormType } from 'main/components/runbook/right-panels/task-edit-panel/task-edit-form'
import { ActiveAccountModel, ActiveRunbookModel, ActiveRunbookVersionModel } from 'main/data-access'

type TaskCommsSettingsModalProps = {
  canModifyComms?: boolean
  commsType: TaskComms
  loadInitialRecipients?: boolean
  onClose: () => void
  open: boolean
  recipientRunbookTeamIds: number[]
  recipientUserIds: number[]
  taskId: number
}

export const TaskCommsSettingsModal = ({
  canModifyComms,
  commsType,
  loadInitialRecipients,
  onClose,
  open,
  recipientRunbookTeamIds,
  recipientUserIds,
  taskId
}: TaskCommsSettingsModalProps) => {
  const { t } = useLanguage('tasks', { keyPrefix: 'editPanel.fields.commsSettings' })
  const [isLoading, setIsLoading] = useState(false)
  const [newMessageValue, setNewMessageValue] = useState<string | null>(null)
  const [usersTeams, setUsersTeams] = useState<RecipientType[] | undefined>(undefined)
  const [tempUsersTeams, setTempUsersTeams] = useState<RecipientType[] | undefined>(undefined)
  const [errorsModal, setErrorsModal] = useState<string[]>([])
  const [customButton, setCustomButton] = useState({
    icon: commsType === 'comms_email' ? 'mail' : 'message-sms',
    label: t('customButton')
  })
  const [newRecipientsValue, setNewRecipientsValue] = useState<
    | {
        users: (string | number)[]
        runbook_teams: number[]
      }
    | undefined
  >(undefined)
  const accountId = ActiveAccountModel.useId()
  const runbookId = ActiveRunbookModel.useId()
  const runbookVersionId = ActiveRunbookVersionModel.useId()
  const { setValue, watch } = useFormContext<TaskEditFormType>()
  const message = watch('message')

  const handleTestTask = async () => {
    setIsLoading(true)

    try {
      await testTaskComms({
        runbookId,
        runbookVersionId,
        taskId,
        payload: {
          type: commsType as 'comms_email' | 'comms_sms',
          message: newMessageValue === '<p></p>' ? null : newMessageValue ?? message ?? null
        }
      })
      setCustomButton({ icon: 'check', label: t('sent') })
      setTimeout(() => {
        setCustomButton({ icon: commsType === 'comms_email' ? 'mail' : 'message-sms', label: t('customButton') })
      }, 1500)
    } catch (e) {
      if (e instanceof ApiError) setErrorsModal(e.errors)
    }
    setIsLoading(false)
  }

  const handleConfirm = () => {
    if (
      newRecipientsValue &&
      (newRecipientsValue.users !== recipientUserIds || newRecipientsValue.runbook_teams !== recipientRunbookTeamIds)
    ) {
      if (isValid()) {
        setErrorsModal([])
      } else {
        setErrorsModal([t('invalidRecipients')])
        return
      }
      setUsersTeams(tempUsersTeams)
      setValue('recipients', newRecipientsValue, { shouldDirty: true, shouldTouch: true })
    }

    if (newMessageValue !== null && newMessageValue !== message) {
      const value = newMessageValue === '<p></p>' ? null : newMessageValue
      setValue('message', value, { shouldDirty: true, shouldTouch: true })
    }

    setNewMessageValue(null)
    onClose()
  }

  const isValid = () => {
    return !tempUsersTeams?.some(
      user => user.type === 'user' && (user.status === 'invalid' || user.status === 'not_allowed')
    )
  }

  const handleClose = () => {
    setNewMessageValue(null)
    setErrorsModal([])
    onClose()
  }

  const handleRecipientsChange = useCallback((recipients: RecipientType[] | null) => {
    const users = (recipients || []).filter(recipient => recipient.type === 'user') as UserRecipient[]
    const teams = (recipients || []).filter(recipient => recipient.type === 'team') as TeamRecipient[]
    setTempUsersTeams([...users, ...teams])
    const value = {
      users: users.map(user => user.id ?? user.handle).sort(),
      runbook_teams: teams.map(team => team.id).sort()
    }
    setNewRecipientsValue(value)
  }, [])

  return (
    <Modal
      open={open}
      onClose={handleClose}
      onClickConfirm={handleConfirm}
      title={t('title', {
        commsType: commsType === 'comms_email' ? t('email') : commsType === 'comms_call' ? t('call') : t('sms')
      })}
      confirmText={t('confirmText')}
      confirmIcon="chevron-right"
      customButton={
        (commsType === 'comms_email' || commsType === 'comms_sms') && (
          <Button
            tertiary
            icon={customButton.icon as IconName}
            label={customButton.label}
            disabled={!canModifyComms}
            loading={isLoading}
            loadingLabel={t('loading')}
            onClick={() => handleTestTask()}
          />
        )
      }
      confirmDisabled={!canModifyComms}
    >
      {errorsModal.length > 0 && (
        <Box margin={{ bottom: 'medium' }}>
          <Message type="error" message={errorsModal} />
        </Box>
      )}
      {loadInitialRecipients ? (
        <RecipientMultiselect
          getInitialRecipients
          users={recipientUserIds.sort()}
          teams={recipientRunbookTeamIds.sort()}
          accountId={accountId}
          runbookId={runbookId}
          runbookVersionId={runbookVersionId}
          disabled={!canModifyComms}
          label={t('recipients')}
          onChange={recipients => handleRecipientsChange(recipients)}
        />
      ) : (
        <RecipientMultiselect
          initialSelected={usersTeams}
          accountId={accountId}
          runbookId={runbookId}
          runbookVersionId={runbookVersionId}
          disabled={!canModifyComms}
          label={t('recipients')}
          onChange={recipients => handleRecipientsChange(recipients)}
        />
      )}
      {commsType === 'comms_email' ? (
        <TextEditor
          value={newMessageValue ?? message ?? ''}
          placeholder={t('placeholder')}
          onChange={val => setNewMessageValue(val ?? '')}
          label={t('messageLabel')}
          readOnly={!canModifyComms}
        />
      ) : (
        <TextArea
          value={newMessageValue ?? message ?? ''}
          label={commsType === 'comms_sms' ? t('smsLabel') : t('callLabel')}
          maxLength={1600}
          onChange={e => setNewMessageValue(e.target.value)}
          readOnly={!canModifyComms}
        />
      )}
    </Modal>
  )
}
