import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Phone = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Phone" {...props}>
    <path d="M25.127 34.127c.9.543 2.008 1.067 3.224 1.197 1.36.146 2.807-.22 3.943-1.356.977-.977 1.913-1.575 2.87-1.724.843-.132 2.278-.002 4.493 1.731.984.77 1.147 1.265 1.179 1.404.03.127.045.372-.204.866-.266.527-.735 1.133-1.392 1.826-.433.457-.794.8-1.174 1.164-.263.25-.535.51-.848.823-.665.666-2.63 1.37-6.52.065-3.692-1.238-8.325-4.086-13.104-8.865-4.779-4.778-7.627-9.412-8.864-13.103-1.305-3.89-.601-5.856.064-6.52.278-.278.574-.592.848-.883l.152-.16c.332-.351.652-.686.98-1.004.679-.659 1.26-1.118 1.764-1.377.461-.236.698-.226.844-.193.173.04.7.225 1.52 1.21 1.726 2.216 1.832 3.58 1.708 4.366-.147.927-.731 1.86-1.788 3.028-1.087 1.127-1.437 2.544-1.294 3.88.13 1.215.654 2.324 1.197 3.223 1.093 1.811 2.884 3.767 4.76 5.642 1.876 1.876 3.831 3.668 5.642 4.76ZM17.24 7.347C13.27 2.54 9.809 6.206 7.453 8.7a40.68 40.68 0 0 1-.78.812c-3.8 3.8-1.233 13.833 8.8 23.867 10.033 10.033 20.067 12.6 23.867 8.8.215-.216.455-.446.709-.69 2.494-2.39 6.328-6.065 1.457-9.877-5.366-4.2-9-2.1-11.333.233-1.633 1.634-4.833-.866-8.567-4.6-3.733-3.733-6.233-6.933-4.6-8.566 2.334-2.567 4.434-5.967.234-11.334Z" fill="#000" />
  </StyledIcon>
))

Phone.displayName = 'Phone'

export { Phone }
