import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const MakeTemplate = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="MakeTemplate" {...props}>
    <path d="M29 6H19v3h10V6Zm4 3h1a5 5 0 0 1 5 5v1h3v-1a8 8 0 0 0-8-8h-1v3Zm9 10h-3v10h3V19Zm0 14h-3v1a5 5 0 0 1-5 5h-1v3h1a8 8 0 0 0 8-8v-1Zm-13 9v-3H19v3h10Zm-14 0v-3h-1a5 5 0 0 1-5-5v-1H6v1a8 8 0 0 0 8 8h1Zm0-33V6h-1a8 8 0 0 0-8 8v1h3v-1a5 5 0 0 1 5-5h1Zm-3 10H3v3h9v-3Zm-9 7h9v3H3v-3Zm22.5-10.5h-3v7h-7v3h7v7h3v-7h7v-3h-7v-7Z" fill="#000" />
  </StyledIcon>
))

MakeTemplate.displayName = 'MakeTemplate'

export { MakeTemplate }
