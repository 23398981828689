import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const FormatColorFill = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="FormatColorFill" {...props}>
    <path d="m9.327 3.205 10.896 10.897A3.003 3.003 0 0 1 24 17a3 3 0 1 1-5.898-.777L7.206 5.327l2.12-2.122ZM39.28 30.833C42.05 35.74 44.526 42.5 37.536 42.5c-6.989 0-4.512-6.761-1.743-11.667A45.486 45.486 0 0 1 37.536 28c.534.8 1.146 1.773 1.744 2.833Zm-3.218 8.42c.12.08.502.247 1.474.247.973 0 1.355-.167 1.474-.246.034-.092.162-.543-.088-1.66-.251-1.117-.763-2.386-1.386-3.647-.622 1.26-1.134 2.53-1.385 3.648-.25 1.116-.122 1.567-.089 1.659Zm-.045-.04c.002-.002.016.008.035.034-.027-.02-.037-.033-.035-.034Zm3.039 0c.002.001-.008.014-.035.034.019-.026.033-.036.035-.034Z" fill="#000" /><path d="m9.686 17.343 2.647-2.646 2.121 2.121-2.646 2.646A5.001 5.001 0 0 0 11.342 26h19.386l3-3-14.85-14.85L21 6.03 37.97 23 26.658 34.314a8 8 0 0 1-11.314 0l-5.657-5.657a8 8 0 0 1 0-11.314ZM27.728 29H14.272l3.193 3.192a5 5 0 0 0 7.07 0L27.729 29Z" fill="#000" />
  </StyledIcon>
))

FormatColorFill.displayName = 'FormatColorFill'

export { FormatColorFill }
