// @ts-nocheck
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { colors, px2rem } from 'app/Styles/Variables'
import { Icon } from 'Components/Atoms'

const LeftIconContainer = styled.div`
  z-index: 2;
  padding-left: ${px2rem(16)};
  float: left;
`

const extraIconStyles = {
  float: 'left',
  marginRight: px2rem(8),
  marginTop: px2rem(12),
  borderRadius: px2rem(20),
  backgroundColor: colors.bg1,
  color: colors.textLight,
  display: 'block',
  lineHeight: px2rem(40),
  width: px2rem(40),
  fontSize: px2rem(24),
  textAlign: 'center',
  cursor: 'pointer'
}

interface IHeaderFilterIconProps {
  filterOpen: boolean
  setFilterOpen: (val: boolean) => void
}

export const HeaderFilterIcon = observer((props: IHeaderFilterIconProps) => {
  const { filterOpen, setFilterOpen } = props

  const iconStyle = filterOpen
    ? {
        ...extraIconStyles,
        color: colors.text,
        backgroundColor: colors.white
      }
    : { ...extraIconStyles }

  const iconComponent = (
    <Icon
      className="filter"
      name="filter"
      extraStyles={iconStyle}
      onClick={() => {
        setFilterOpen(!filterOpen)
      }}
    />
  )

  return (
    <>
      <LeftIconContainer filterOpen={filterOpen}>{iconComponent}</LeftIconContainer>
    </>
  )
})
