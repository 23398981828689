/* eslint-disable @typescript-eslint/explicit-member-accessibility */
// @ts-nocheck
import {
  add as addToDate,
  endOfDay,
  endOfHour,
  endOfISOWeek,
  endOfMinute,
  endOfMonth,
  endOfQuarter,
  endOfYear,
  format,
  getUnixTime,
  startOfDay,
  startOfISOWeek,
  startOfMonth,
  startOfQuarter,
  startOfYear
} from 'date-fns'

import { Item } from './timeline-item'
// TODO: should these be export from date service instead (no other direct dependency on date-fns)

/*
 * IMPORTANT! Notice the top-level typescript and eslint ignores. These exist because this
 * code is copied over from the angular omniview controller (event timeline). Only the minimum
 * changes necessary were made to remove angular lifecycle dependencies for usage here.
 */

class AxisValue {
  id: number | null = null
  name: string | null = null
  count: number = 0
  prevCount: number = 0
  totalCount: number = 0
  maxItemCount: number = 0
  prevMaxItemCount: number = 0
  expanded: boolean = false
  itemIds: number[]
  color: string
  py: number = 0
  ty: number = 0
  y: number = 0
  isDummy: boolean = false
  width: number = 0

  constructor(value: any = null) {
    this.id = value.id
    this.name = value.name || value.label
    this.count = 0
    this.prevCount = 0
    this.totalCount = 0
    this.expanded = false
    this.itemIds = []
    this.py = 0
    this.ty = 0
    this.y = 0
    this.width = 0
    this.color = value.color ? value.color : 'rgb(114,132,141)'
  }
}

class XAxisValue extends AxisValue {
  x: number = 0
  data: any

  constructor(value: any = null) {
    super(value)
    this.x = 0
    this.data = {}
  }
}

class BaseAxis {
  name: string | null = null
  dataType: string | null = null
  maxItemCount: number = 0
  prevMaxItemCount: number = 0
  custom: boolean = false
  values: any = []
  valueLookup: any = {}
  direction: string | null = null

  constructor(axisData: any = null) {
    this.name = axisData.name
    this.dataType = axisData.dataType
    this.values = []
    this.custom = axisData.custom ? true : false
    this.valueLookup = {}
  }

  find(id: any): AxisValue {
    return this.values[this.valueLookup[id]]
  }

  add(axisValue: XAxisValue): void {
    // this may be pointless as we need to re-sort at the end anyway
    if (this.values.length && axisValue.id < this.values[0].id) {
      this.values.unshift(axisValue)
    } else {
      this.values.push(axisValue)
    }
  }

  // merges an existing array with a new array
  // Should abstract into Array.prototype.mergeOrdered
  // maintains time order
  // note this is specific to a time axis, shouldnt be on Axis class
  updateValues(newAxisValues: XAxisValue[]): void {
    // this.values = newAxisValues
    var newIds = [],
      idsToRemove = []
    for (var i = 0; i < newAxisValues.length; i++) {
      newIds.push(newAxisValues[i].id)
      var existing = this.find(newAxisValues[i].id)
      // remainingIds.splice(remainingIds.indexOf(newAxisValues[i].id),1)
      if (existing) {
        existing.width = newAxisValues[i].width
      } else {
        this.add(newAxisValues[i]) // add
      }
    }

    // loop through existing, find ones that aren't in new
    for (i = 0; i < this.values.length; i++) {
      var index = newIds.indexOf(this.values[i].id)
      if (index === -1) {
        idsToRemove.push(this.values[i].id)
      }
    }

    for (i = 0; i < idsToRemove.length; i++) {
      const itemIndex = this.values.indexOf(value => value.id === idsToRemove[i])
      if (itemIndex !== -1) {
        this.values.splice(itemIndex, 1)
      }
    }

    this.values.sort((a, b) => {
      return a.id - b.id
    })

    this.buildLookup()
  }

  buildLookup(): void {
    this.valueLookup = {}
    for (var i = 0; i < this.values.length; i++) {
      this.valueLookup[this.values[i].id] = i
    }
  }

  update(): void {}
}

export class Axis extends BaseAxis {
  attribute: string = null
  valueWidth: number = null // only for X

  constructor(axisData: any, viewport: any, direction: string, isDummy: boolean = false) {
    super(axisData)
    this.direction = direction
    this.attribute = axisData.attribute
    if (axisData.values && axisData.values.length) {
      this.valueWidth = direction === 'x' ? Math.max(viewport.width / axisData.values.length + 1, 140) : null

      for (var i = 0; i < axisData.values.length; i++) {
        var value
        if (direction === 'x') {
          value = new XAxisValue(axisData.values[i])
          value.width = this.valueWidth
        } else {
          value = new AxisValue(axisData.values[i])
        }
        this.values.push(value)
      }

      if (!isDummy) {
        // add extra value for 'no value set'
        var extraValue =
          direction === 'x'
            ? new XAxisValue({ id: 0, name: 'No value set' })
            : new AxisValue({ id: 0, name: 'No value set' })
        extraValue.width = this.valueWidth
        this.values.push(extraValue)
      }

      this.buildLookup()
    }
  }

  setItemValues(itemRaw: any): any[] {
    var value
    if (this.custom && itemRaw.hasOwnProperty('custom_field_data')) {
      value = itemRaw.custom_field_data[this.attribute]
    } else if (this.attribute) {
      value = itemRaw[this.attribute] // eg could be a stream id
    }

    if (!value) {
      value = 0
    }

    return Array.isArray(value) ? value : [value]
  }

  inBounds(): boolean {
    return true
  }

  calcWidth(): number {
    return this.valueWidth * 0.9
  }

  scaleX(): number {
    return this.valueLookup[itemId] * this.valueWidth
  }
}

export class TimeAxis extends BaseAxis {
  attributeStart: string | null = null
  attributeEnd: string | null = null
  secondaryValues: any[]
  scales: any[]
  currentScaleIndex: number

  constructor(axisData: any = null) {
    super(axisData)
    this.direction = 'x'
    this.attributeStart = axisData.attributeStart
    this.attributeEnd = axisData.attributeEnd
    this.secondaryValues = []
    this.currentScaleIndex = 7
    this.scales = [
      {
        minThreshold: 38,
        maxThreshold: 100,
        minorLabelFormat: 'HH:mm',
        minorLabelShow: (): boolean => {
          return true // this.formatDate(d, "mm") === "00"
        },
        minorInterval: 'minute',
        minorStep: 15,
        showWeekend: false,
        majorLabelFormat: 'dddd D MMM YYYY',
        majorInterval: 'day',
        majorStep: 1,
        majorLabelShow: (): boolean => {
          return true
        }
      },
      {
        minThreshold: 38,
        maxThreshold: 76,
        minorLabelFormat: 'HH:mm',
        minorLabelShow: (): boolean => {
          return true // this.formatDate(d, "mm") === "00"
        },
        minorInterval: 'minute',
        minorStep: 30,
        showWeekend: false,
        majorLabelFormat: 'dddd D MMM YYYY',
        majorInterval: 'day',
        majorStep: 1,
        majorLabelShow: (): boolean => {
          return true
        }
      },
      {
        minThreshold: 38,
        maxThreshold: 76,
        minorLabelFormat: 'HH:mm',
        minorLabelShow: (): boolean => {
          return true // Number(this.formatDate(d, "HH")) % 2 === 0
        },
        minorInterval: 'hour',
        minorStep: 1,
        showWeekend: false,
        majorLabelFormat: 'dddd D MMM YYYY',
        majorInterval: 'day',
        majorStep: 1,
        majorLabelShow: (): boolean => {
          return true
        }
      },
      {
        minThreshold: 38,
        maxThreshold: 76,
        minorLabelFormat: 'HH:mm',
        minorLabelShow: (): boolean => {
          return true // ["00","06","12","18"].indexOf(this.formatDate(d, "HH")) !== -1
        },
        minorInterval: 'hour',
        minorStep: 2,
        showWeekend: false,
        majorLabelFormat: 'dddd D MMM YYYY',
        majorInterval: 'day',
        majorStep: 1,
        majorLabelShow: (): boolean => {
          return true
        }
      },
      {
        minThreshold: 38,
        maxThreshold: 62,
        minorLabelFormat: 'HH:mm',
        minorLabelShow: (): boolean => {
          return true // ["00","06","12","18"].indexOf(this.formatDate(d, "HH")) !== -1
        },
        minorInterval: 'hour',
        minorStep: 3,
        showWeekend: false,
        majorLabelFormat: 'dddd D MMM YYYY',
        majorInterval: 'day',
        majorStep: 1,
        majorLabelShow: (): boolean => {
          return true
        }
      },
      {
        minThreshold: 38,
        maxThreshold: 76,
        minorLabelFormat: 'HH:mm',
        minorLabelShow: (): boolean => {
          return true // ["00","12"].indexOf(this.formatDate(d, "HH")) !== -1
        },
        minorInterval: 'hour',
        minorStep: 6,
        showWeekend: false,
        majorLabelFormat: 'ddd D MMM YYYY',
        majorInterval: 'day',
        majorStep: 1,
        majorLabelShow: (): boolean => {
          return true
        }
      },
      {
        minThreshold: 38,
        maxThreshold: 76,
        minorLabelFormat: 'HH:mm',
        minorLabelShow: (): boolean => {
          return true // this.formatDate(d, "HH") === "00"
        },
        minorInterval: 'hour',
        minorStep: 12,
        showWeekend: true,
        majorLabelFormat: 'D MMM',
        majorInterval: 'day',
        majorStep: 1,
        majorLabelShow: (): boolean => {
          return true // Number(this.formatDate(d, "DDD")) % 2 === 0
        }
      },
      {
        minThreshold: 52,
        maxThreshold: 76,
        minorLabelFormat: 'ddd D',
        minorLabelShow: (): boolean => {
          return true
        },
        minorInterval: 'day',
        minorStep: 1,
        showWeekend: true,
        majorLabelFormat: 'MMMM YYYY',
        majorInterval: 'month',
        majorStep: 1,
        majorLabelShow: (): boolean => {
          return true
        }
      },
      {
        minThreshold: 40,
        maxThreshold: 52,
        minorLabelFormat: 'dd D',
        minorLabelShow: (): boolean => {
          return true
        },
        minorInterval: 'day',
        minorStep: 1,
        showWeekend: true,
        majorLabelFormat: 'MMMM YYYY',
        majorInterval: 'month',
        majorStep: 1,
        majorLabelShow: (): boolean => {
          return true
        }
      },
      {
        minThreshold: 20,
        maxThreshold: 40,
        minorLabelFormat: 'D',
        minorLabelShow: (): boolean => {
          return true
        },
        minorInterval: 'day',
        minorStep: 1,
        showWeekend: true,
        majorLabelFormat: 'MMMM YYYY',
        majorInterval: 'month',
        majorStep: 1,
        majorLabelShow: (): boolean => {
          return true
        }
      },
      {
        minThreshold: 12,
        maxThreshold: 20,
        minorLabelFormat: 'D',
        minorLabelShow: (d): boolean => {
          return Number(this.format(d, 'DDD')) % 2 === 0
        },
        minorInterval: 'day',
        minorStep: 1,
        showWeekend: true,
        majorLabelFormat: 'MMMM YYYY',
        majorInterval: 'month',
        majorStep: 1,
        majorLabelShow: (): boolean => {
          return true
        }
      },
      // Minor scale changes to weeks when width < 12
      {
        minThreshold: 52,
        maxThreshold: 84, // min day width of prev * 7
        minorLabelFormat: 'ddd D',
        minorLabelShow: (): boolean => {
          return true
        },
        minorInterval: 'week',
        minorStep: 1,
        showWeekend: false,
        majorLabelFormat: 'MMMM YYYY',
        majorInterval: 'month',
        majorStep: 1,
        majorLabelShow: (): boolean => {
          return true
        }
      },
      {
        minThreshold: 22,
        maxThreshold: 52,
        minorLabelFormat: 'D',
        minorLabelShow: (): boolean => {
          return true
          // return Number(d3.timeFormat("%V")(d)) % 2 === 0
        },
        minorInterval: 'week',
        minorStep: 1,
        showWeekend: false,
        majorLabelFormat: 'MMMM YYYY',
        majorInterval: 'month',
        majorStep: 1,
        majorLabelShow: (): boolean => {
          return true
        }
      },
      {
        minThreshold: 16,
        maxThreshold: 22,
        minorLabelFormat: 'D',
        minorLabelShow: (d: number): boolean => {
          return Number(this.format(d, 'w')) % 2 === 0
        },
        minorInterval: 'week',
        minorStep: 1,
        showWeekend: false,
        majorLabelFormat: 'MMM YYYY',
        majorInterval: 'month',
        majorStep: 1,
        majorLabelShow: (): boolean => {
          return true
        }
      },
      // Switch to month when week width  < 16
      {
        minThreshold: 75,
        maxThreshold: 122,
        minorLabelFormat: 'MMMM',
        minorLabelShow: (): boolean => {
          return true
        },
        minorInterval: 'month',
        minorStep: 1,
        showWeekend: false,
        majorLabelFormat: 'YYYY',
        majorInterval: 'year',
        majorStep: 1,
        majorLabelShow: (): boolean => {
          return true
        }
      },
      {
        minThreshold: 40,
        maxThreshold: 75,
        minorLabelFormat: 'MMM',
        minorLabelShow: (): boolean => {
          return true
        },
        minorInterval: 'month',
        minorStep: 1,
        showWeekend: false,
        majorLabelFormat: 'YYYY',
        majorInterval: 'year',
        majorStep: 1,
        majorLabelShow: (): boolean => {
          return true
        }
      },
      {
        minThreshold: 40,
        maxThreshold: 175,
        minorLabelFormat: '[Q]Q',
        minorLabelShow: (): boolean => {
          return true
        },
        minorInterval: 'quarter',
        minorStep: 1,
        showWeekend: false,
        majorLabelFormat: 'YYYY',
        majorInterval: 'year',
        majorStep: 1,
        majorLabelShow: (): boolean => {
          return true
        }
      }
    ]
  }

  setItemValues(itemRaw: any): any[] {
    return [itemRaw[this.attributeStart], itemRaw[this.attributeEnd]]
  }

  inBounds(item: Item, viewport: any): boolean {
    return item.xValues[1] > viewport.currentStart && item.xValues[0] < viewport.currentEnd
  }

  calcWidth(itemRaw: any, viewport: any): number {
    return ((itemRaw[this.attributeEnd] - itemRaw[this.attributeStart]) / viewport.duration) * viewport.width
  }

  scaleX(unix: number, viewport: any): number {
    return ((unix - viewport.start) / viewport.duration) * viewport.width
  }

  endOfPeriod(dateUnix: number): Date {
    var currentScale = this.scales[this.currentScaleIndex]
    return this._roundDate(dateUnix, currentScale.minorStep, currentScale.minorInterval, 'end')
  }

  // Called during pan/zoom
  initValues(viewport: any): void {
    var existingScaleIndex = this.currentScaleIndex
    var currentScale = this._calcTimelineDisplayMode(viewport.currentStart, viewport.currentEnd, viewport.width)
    if (existingScaleIndex !== this.currentScaleIndex) {
      // timeline zoom level changed, we need to reset
      this.values = []
      this.valueLookup = {}
    }
    // generate arrays of date data using the above units as intervals
    // along the lines of D3 enter/update/exit pattern
    var newIntervals = this._generateIntervals(
      viewport.currentStart,
      viewport.currentEnd,
      currentScale.minorInterval,
      currentScale.minorStep,
      viewport.currentWidth
    )
    this.updateValues(newIntervals)
    this.secondaryValues = this._generateIntervals(
      viewport.currentStart,
      viewport.currentEnd,
      currentScale.majorInterval,
      currentScale.majorStep,
      viewport.currentWidth
    )
  }

  private format(unix: number, formatCode: string): string {
    const date = new Date(unix * 1000)

    // convert moment codes to date-fns
    switch (formatCode) {
      case 'm':
        return format(date, 'm')
      case 'd':
        return format(date, 'i') // day of week
      case 'w':
        return format(date, 'w')
      case 'H':
        return format(date, 'H')
      case 'D':
        return format(date, 'd') // day of month
      case 'dd D':
        return format(date, 'EEEEEE d')
      case 'ddd D':
        return format(date, 'EEE d')
      case 'DDD':
        return format(date, 'D', { useAdditionalDayOfYearTokens: true }) // day of year
      case 'MMMM YYYY':
        return format(date, 'MMMM y')
      case 'HH:mm':
        return format(date, 'HH:mm')
      case 'D MMM':
        return format(date, 'd MMM')
      case 'ddd D MMM YYYY':
        return format(date, 'E..EEE d MMM yyyy')
      case 'dddd D MMM YYYY':
        return format(date, 'EEEE d MMM yyyy')
      case 'MMM YYYY':
        return format(date, 'MMM yyyy')
      case 'MMM':
        return format(date, 'MMM')
      case 'YYYY':
        return format(date, 'yyyy')
    }
  }

  // Generate an array of dates based on the current display mode
  private _generateIntervals(
    startUnix: number,
    endUnix: number,
    interval: string,
    step: number,
    viewportWidth: number
  ): XAxisValue[] {
    var dateArray = []
    var currentDate = this._roundDate(startUnix, step, interval)
    var stopDate = new Date(endUnix * 1000)
    var overallDuration = endUnix - startUnix
    while (currentDate <= stopDate) {
      var item = new XAxisValue({ id: getUnixTime(currentDate), name: '' })
      currentDate = addToDate(currentDate, this._addToDateJson(interval, step))
      item.width = ((getUnixTime(currentDate) - item.id) / overallDuration) * viewportWidth
      dateArray.push(item)
    }
    return dateArray
  }

  private _addToDateJson(key, step) {
    const intervalMapping = {
      minute: { minutes: step },
      hour: { hours: step },
      day: { days: step },
      week: { weeks: step },
      month: { months: step },
      quarter: { quarters: step },
      year: { years: step }
    }

    return intervalMapping[key]
  }

  private _calculateDuration(step, interval) {
    // get duration
    const baseDate = new Date()
    const endDate = addToDate(baseDate, this._addToDateJson(interval, step))
    return endDate - baseDate
  }

  // Using our timeline display definition, work out an appropriate scale based on tick size
  private _calcTimelineDisplayMode(startUnix: number, endUnix: number, width: number): any {
    var currentScale = this.scales[this.currentScaleIndex]
    if (!currentScale) {
      return 7
    }
    const minorIntervalCount =
      ((endUnix - startUnix) * 1000) / this._calculateDuration(currentScale.minorStep, currentScale.minorInterval)
    var tickWidth = width / minorIntervalCount // not always the same
    if (tickWidth < currentScale.minThreshold && this.scales.length > this.currentScaleIndex + 1) {
      // zoomin outg too squished, use bigger units
      this.currentScaleIndex += 1
      return this._calcTimelineDisplayMode(startUnix, endUnix, width)
    } else if (tickWidth > currentScale.maxThreshold && this.currentScaleIndex > 0) {
      // too squished, use bigger units
      this.currentScaleIndex -= 1
      return this._calcTimelineDisplayMode(startUnix, endUnix, width)
    }
    return currentScale
  }

  private _roundDate(dateUnix: number, step: number, interval: string, direction: string = 'start'): Date {
    var startOfPeriod
    var d = new Date(dateUnix * 1000)
    direction = direction === 'start' ? 'startOf' : 'endOf'
    if (interval === 'minute') {
      var currentMinute = format(d, 'm')
      startOfPeriod = addToDate(startOfDay(d), { minutes: Math.floor(currentMinute / step) * step })
      return direction === 'startOf' ? startOfPeriod : endOfMinute(addToDate(startOfPeriod, { minutes: step - 1 }))
    } else if (interval === 'hour') {
      var currentHour = format(d, 'H')
      startOfPeriod = addToDate(startOfDay(d), { hours: Math.floor(currentHour / step) * step })
      return direction === 'startOf' ? startOfPeriod : endOfHour(addToDate(startOfPeriod, { hours: step - 1 }))
    } else if (interval === 'day') {
      return direction === 'startOf' ? startOfDay(d) : endOfDay(d)
    } else if (interval === 'week') {
      return direction === 'startOf' ? startOfISOWeek(d) : endOfISOWeek(d)
    } else if (interval === 'month') {
      return direction === 'startOf' ? startOfMonth(d) : endOfMonth(d)
    } else if (interval === 'quarter') {
      return direction === 'startOf' ? startOfQuarter(d) : endOfQuarter(d)
    } else if (interval === 'year') {
      return direction === 'startOf' ? startOfYear(d) : endOfYear(d)
    }
  }
}
