import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const BrandOrchestrate = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 100 100" a11yTitle="BrandOrchestrate" {...props}>
    <g clipPath="url(#a)" fill="#fff"><path d="M50.038 100.028a50 50 0 0 1-50-50h29.34a20.662 20.662 0 0 0 20.66 20.66v29.34ZM50.006.06a50 50 0 0 1 50 50h-29.34a20.662 20.662 0 0 0-20.66-20.66V.06Z" opacity=".5" /><circle cx="50" cy="50" r="14.667" /></g>
  </StyledIcon>
))

BrandOrchestrate.displayName = 'BrandOrchestrate'

export { BrandOrchestrate }
