import { SubHeaderButton, SubHeaderButtonGroup } from 'main/components/layout/shared/sub-header/sub-header-button'
import { useClearFilterParam, useFilter, useSetFilterParams } from 'main/components/shared/filter/filter-provider'
import { useLanguage } from 'main/services/hooks'

const RUN_TYPES = ['rehearsal', 'live'] as const

export const RunTypeToggle = () => {
  const { t } = useLanguage('myWork', { keyPrefix: 'runTypeToggleOptions' })
  const setFilterParams = useSetFilterParams()
  const clearFilterParam = useClearFilterParam()
  const viewingStatus = useFilter<(typeof RUN_TYPES)[number][]>('run_type')

  const handleClickOption = (status?: (typeof RUN_TYPES)[number]) => {
    // clicked the single active status in toggle (or clicked all and not viewing a status); don't do anything
    if ((viewingStatus && viewingStatus[0] === status) || (!viewingStatus && !status)) {
      return
    }

    if (status && RUN_TYPES.includes(status)) {
      setFilterParams({ run_type: [status] })
    } else {
      clearFilterParam('run_type')
    }
  }

  return (
    <SubHeaderButtonGroup>
      <SubHeaderButton
        label={t('live')}
        active={viewingStatus?.[0] === 'live'}
        onClick={() => handleClickOption('live')}
      />
      <SubHeaderButton
        label={t('rehearsal')}
        active={viewingStatus?.[0] === 'rehearsal'}
        onClick={() => handleClickOption('rehearsal')}
      />
      <SubHeaderButton label={t('all')} active={!viewingStatus} onClick={() => handleClickOption()} />
    </SubHeaderButtonGroup>
  )
}
