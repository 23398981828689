import { PaintTriangle } from '../node-map-types'

export const paintTriangle = ({ ctx, x, y, size, size2, size3, lineWidth, stroke, fill }: PaintTriangle) => {
  const halfSize = size / 2

  ctx.beginPath()
  ctx.moveTo(x - halfSize, y + size2)
  ctx.lineTo(x, y - size3)
  ctx.lineTo(x + halfSize, y + size2)
  ctx.closePath()

  ctx.lineWidth = lineWidth
  ctx.strokeStyle = stroke
  ctx.stroke()

  if (fill) {
    ctx.fillStyle = fill
    ctx.fill()
  }
}
