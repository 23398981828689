// @ts-nocheck
import { observable, computed, action, reaction } from 'mobx'
import { container } from 'app/Config/IOC'
import { GenericFormPresenter } from '@logicroom/validator'
import { TextInputPresenter, SelectPresenter, TSelectOptionTypes, CheckboxPresenter } from 'Shared/Components/Molecules'
import { AccountVisibilityPresenter, FieldOptionsPresenter } from 'Shared/Components/Organisms'
import {
  CustomFieldsViewPresenter,
  ICustomFieldVM
} from 'app/Settings/CustomFields/CustomFieldsPage/CustomFieldsViewPresenter'
import { GenericPageFormPresenter } from 'Shared/Presenters/GenericPageFormPresenter'
import { IConstraint, IndicateConflicts } from 'app/Settings/CustomFields/CustomFieldRepositoryTypes'
import {
  textFieldType,
  textAreaFieldType,
  userSelectFieldType,
  endpointFieldType,
  checkboxFieldType,
  selectFieldType,
  radioboxFieldType,
  dateFieldType,
  searchableFieldType
} from 'app/Settings/CustomFields/CustomFieldFieldTypes'
import { taskEditApplyTo, taskStartApplyTo, taskEndApplyTo } from 'app/Settings/CustomFields/CustomFieldApplyTo'
import { RadioButtonsPresenter } from 'Shared/Components/Atoms/RadioButtons'
import { RemoteOptionsPresenter } from 'Shared/Components/Organisms/FieldOptions/RemoteOptionsPresenter'
import { v4 } from 'uuid'

interface IFormViewModel {
  account?: string
  disabledName: boolean
  disabledGlobal: boolean
  disabledAccount: boolean
}

export interface IRecordViewModel {
  name: string
  global: boolean
  accountId: number
  fieldType: string
  required: boolean
  applyTo: string
  constraint: IConstraint
  displayList: boolean
  displaySearch: boolean
  defaultValue: string
  default: boolean
  allowFieldCreation: boolean
  indicateConflicts: string
  remoteURL: string
  fieldOptions: string[]
  fieldRemoteKeys: string[]
}

interface IFilterOptions {
  label: string
  value?: string
  type?: ContextType
  hideOnFieldType?: string[]
  showOnContext?: string[]
  helpText?: string
}

export enum ContextType {
  Task,
  RunbookPage,
  Runbook,
  Events,
  Folder,
  Other
}

export enum AvailableFields {
  Name,
  Group,
  Visibility,
  FieldType,
  DefaultContent,
  DefaultTextAreaContent,
  FieldOptions,
  RemoteFieldOptions,
  ApplyTo,
  RemoteURL,
  Constraint,
  IndicateConflicts,
  AdditionalSettings,
  IntegrationActionItem,
  DisplayName,
  DataSource,
  DataSourceMappings
}

export enum AvailableOptions {
  RemoteKeys,
  GenericOptions
}

export class CustomFieldFormPresenter extends GenericPageFormPresenter {
  constructor() {
    super()
    this.fieldTypePresenter.withOptions(this.fieldTypes)
    this.displayTypePresenter.withOptions(this.getDisplayTypeOptions())

    this.formValidator = new GenericFormPresenter()
      .addFormInput(this.namePresenter)
      .addFormInput(this.displayNamePresenter)
      .addFormInput(this.fieldTypePresenter)
      .addFormInput(this.dataSourcePresenter)
      .addFormInput(this.displayTypePresenter)

    if (this.viewPresenter.customFieldGroups.length) {
      this.groupPresenter.withOptions(this.groups)
    } else {
      this.setUpGroupReaction()
    }
  }

  public viewPresenter: CustomFieldsViewPresenter = container.get(CustomFieldsViewPresenter)

  private additionalSettingAllowCreateLabel: string = this.language.get(
    'customFields:fields:additionalSettings:labels:allowCreation'
  )

  private additionalSettingRequiredLabel: string = this.language.get(
    'customFields:fields:additionalSettings:labels:required'
  )
  private additionalSettingTransientLabel: string = this.language.get(
    'customFields:fields:additionalSettings:labels:transient'
  )
  private additionalSettingReadOnlyLabel: string = this.language.get(
    'customFields:fields:additionalSettings:labels:readOnly'
  )
  private additionalSettingLockNameLabel: string = this.language.get(
    'customFields:fields:additionalSettings:labels:lockName'
  )
  private additionalSettingHiddenLabel: string = this.language.get(
    'customFields:fields:additionalSettings:labels:hidden'
  )
  private additionalSettingFilterLabel: string = this.language.get(
    'customFields:fields:additionalSettings:labels:filters'
  )
  private additionalSettingListLabel: string = this.language.get(
    'customFields:fields:additionalSettings:labels:listView'
  )
  private additionalSettingTableLabel: string = this.language.get(
    'customFields:fields:additionalSettings:labels:tableView'
  )

  private additionalSettingTransientHelpText: string = this.language.get(
    'customFields:fields:additionalSettings:helpText:transient'
  )

  @observable
  public viewModel: IFormViewModel = {
    disabledName: false,
    disabledGlobal: false,
    disabledAccount: false
  }

  @observable
  public visibilityAccounts: string[] = []

  @observable
  public unavailableNames: string[] = []

  @observable
  private conflictsAllowLabel = this.language.get('customFields:fields:conflicts:allow')

  @observable
  private conflictsWarnLabel = this.language.get('customFields:fields:conflicts:warn')

  @observable
  public restrictionsChecked: string[] = []

  @computed
  public get disabledRestrictions(): string[] {
    const displayType = this.getDisplayTypeOption()

    if (displayType && displayType.type === ContextType.Runbook) {
      return this.viewPresenter.runbookTypes
        ? this.viewPresenter.runbookTypes.reduce(function (filtered, runbookType) {
            if (runbookType.disabled) {
              filtered.push(runbookType.name)
            }
            return filtered
          }, [])
        : []
    } else {
      return []
    }
  }

  @observable
  public additionalSettingsChecked: string[] = []

  @observable
  public integrationActionItemsChecked: string[] = []

  @observable
  public disabledIntegrationActionItems: string[] = []

  private dirtyCallbackWrapper = () => {
    if (this.dirtyCallback) {
      this.dirtyCallback()
    }
  }

  @observable
  public namePresenter: TextInputPresenter = new TextInputPresenter()
    .withLabel(this.language.get('customFields:fields:name:label'))
    .isRequiredTrimWhiteSpace(this.language.get('customFields:fields:name:errors:required'))
    .withMiddleware((value: string) => {
      if (this.viewModelMiddleware) {
        this.viewModelMiddleware(AvailableFields.Name, value)
      }

      this.dirtyCallbackWrapper()

      return value
    })

  @observable
  public displayNamePresenter: TextInputPresenter = new TextInputPresenter()
    .withLabel(this.language.get('customFields:fields:displayName:label'))
    .withMiddleware((value: string) => {
      if (this.viewModelMiddleware) {
        this.viewModelMiddleware(AvailableFields.DisplayName, value)
      }

      this.dirtyCallbackWrapper()

      return value
    })

  public additionalSettingsMiddleware = (value: boolean, label: string) => {
    if (this.genericCheckboxMiddleware) {
      const displayType = this.displayTypePresenter.value as string
      this.genericCheckboxMiddleware(
        AvailableFields.AdditionalSettings,
        label,
        value,
        this.getTypeFromDisplayType(displayType)
      )
    }

    // Remote field should update all field options when this additional setting is set
    if (label === this.additionalSettingFilterLabel && this.fieldTypePresenter.value === 'endpoint') {
      this.remoteKeyFieldsPresenter.draggableOptions.forEach(option => {
        option.draggable.draggableConfig.presenter.checkboxPresenter.onChange(value)
      })
    }

    this.dirtyCallbackWrapper()

    return value
  }

  @observable
  public lockNameCheckboxPresenter = new CheckboxPresenter(
    this.language.get('customFields:fields:additionalSettings:labels:lockName')
  ).withMiddleware((value: string) => {
    this.additionalSettingsMiddleware(value, this.additionalSettingLockNameLabel)
    return value
  })

  @observable
  public hiddenCheckboxPresenter = new CheckboxPresenter(
    this.language.get('customFields:fields:additionalSettings:labels:hidden')
  )
    .withMiddleware((value: string) => {
      this.additionalSettingsMiddleware(value, this.additionalSettingHiddenLabel)
      this.filterCheckboxPresenter.disabled = Boolean(value)
      this.listCheckboxPresenter.disabled = Boolean(value)
      this.tableCheckboxPresenter.disabled = Boolean(value)
      this.displayTypePresenter.disabled = Boolean(value)

      return value
    })
    .withHelpText(this.language.get('customFields:fields:additionalSettings:helpText:hidden'))

  @observable
  public allowFieldCreationCheckboxPresenter = new CheckboxPresenter(
    this.language.get('customFields:fields:additionalSettings:labels:allowCreation')
  ).withMiddleware((value: string) => {
    this.additionalSettingsMiddleware(value, this.additionalSettingAllowCreateLabel)
    return value
  })

  @observable
  public listCheckboxPresenter = new CheckboxPresenter(
    this.language.get('customFields:fields:additionalSettings:labels:listView')
  ).withMiddleware((value: string) => {
    this.additionalSettingsMiddleware(value, this.additionalSettingListLabel)
    return value
  })

  @observable
  public filterCheckboxPresenter = new CheckboxPresenter(
    this.language.get('customFields:fields:additionalSettings:labels:filters')
  ).withMiddleware((value: string) => {
    this.additionalSettingsMiddleware(value, this.additionalSettingFilterLabel)
    return value
  })

  @observable
  public tableCheckboxPresenter = new CheckboxPresenter(
    this.language.get('customFields:fields:additionalSettings:labels:tableView')
  ).withMiddleware((value: string) => {
    this.additionalSettingsMiddleware(value, this.additionalSettingTableLabel)
    return value
  })

  @observable
  public requiredCheckboxPresenter = new CheckboxPresenter(
    this.language.get('customFields:fields:additionalSettings:labels:required')
  ).withMiddleware((value: string) => {
    this.additionalSettingsMiddleware(value, this.additionalSettingRequiredLabel)
    return value
  })

  @observable
  public transientCheckboxPresenter = new CheckboxPresenter(
    this.language.get('customFields:fields:additionalSettings:labels:transient')
  )
    .withMiddleware((value: string) => {
      this.additionalSettingsMiddleware(value, this.additionalSettingTransientLabel)
      return value
    })
    .withHelpText(this.language.get('customFields:fields:additionalSettings:helpText:transient'))

  @observable
  public readOnlyCheckboxPresenter = new CheckboxPresenter(
    this.language.get('customFields:fields:additionalSettings:labels:readOnly')
  ).withMiddleware((value: string) => {
    this.additionalSettingsMiddleware(value, this.additionalSettingReadOnlyLabel)
    return value
  })

  @observable
  public defaultTextPresenter: TextInputPresenter = new TextInputPresenter()
    .withLabel(this.language.get('customFields:fields:defaultContent:label'))
    .withMiddleware((value: string) => {
      if (this.viewModelMiddleware) {
        this.viewModelMiddleware(AvailableFields.DefaultContent, value)
      }

      this.dirtyCallbackWrapper()

      return value
    })

  @observable
  public visibilityPresenter: AccountVisibilityPresenter = new AccountVisibilityPresenter().withMiddleware(
    (value: string) => {
      if (this.viewModelMiddleware) {
        const typeGlobal = this.language.get('common:organisms:accountVisibility:fieldType:optionGlobal')
        const typeAccount = this.language.get('common:organisms:accountVisibility:fieldType:optionAccount')
        if (value === typeGlobal) {
          this.viewModelMiddleware(AvailableFields.Visibility, 'global')
        } else if (value === typeAccount) {
          this.viewModelMiddleware(
            AvailableFields.Visibility,
            `account:${this.visibilityPresenter.accountVisibilityPresenter.selectedOption}`
          )
        } else {
          this.viewModelMiddleware(AvailableFields.Visibility, `account:${value}`)
        }
      }

      this.dirtyCallbackWrapper()

      return value
    }
  )

  private filterOptions = (array: IFilterOptions[], fieldValue: string, select?: boolean) => {
    let options = []
    array.map(({ label, value, hideOnFieldType, showOnContext, helpText }) => {
      if (this.canIncludeFilter(fieldValue, hideOnFieldType, showOnContext)) {
        const option = this.isUndefinedOrNull(helpText) ? { label } : { label, helpText }
        select ? options.push({ value, ...option }) : options.push(option)
      }
    })
    return options
  }

  private isUndefinedOrNull = (value: any) => {
    return value === undefined || value === null
  }

  private canIncludeFilter = (fieldValue: string, hideOnFieldType?: string[], showOnContext?: string[]): boolean => {
    if (!hideOnFieldType && !showOnContext) {
      return true
    }

    let fieldTypeCheck = false
    let displayTypeCheck = false

    if (!hideOnFieldType || !hideOnFieldType.includes(fieldValue)) {
      fieldTypeCheck = true
    }

    if (!showOnContext || showOnContext.includes(this.getFieldDisplayTypePresenterValue())) {
      displayTypeCheck = true
    }

    return fieldTypeCheck && displayTypeCheck
  }

  @computed
  public get checkboxAdditionalSettings(): string[] {
    return this.filterOptions(this.additionalSettings, this.getFieldTypePresenterValue())
  }

  @observable
  public fieldTypePresenter: SelectPresenter = new SelectPresenter(this.language)
    .withLabel(this.language.get('customFields:fields:type:label'))
    .isRequired()
    .withMiddleware((value: string) => {
      this.displayTypePresenter.options = this.getDisplayTypeOptions(value)

      if (this.viewModelMiddleware) {
        this.viewModelMiddleware(AvailableFields.FieldType, value)
      }

      this.dirtyCallbackWrapper()

      return value
    })

  @observable
  public totalJsonMappings: number = 0

  @observable
  public dataSourcePresenter: SelectPresenter = new SelectPresenter(this.language)
    .withLabel(this.language.get('customFields:fields:dataSource:label'))
    .withMiddleware((value: string) => {
      if (this.viewModelMiddleware) {
        this.viewModelMiddleware(AvailableFields.DataSource, value)
      }

      this.dirtyCallbackWrapper()

      return value
    })

  @observable
  public groupPresenter: SelectPresenter = new SelectPresenter(this.language)
    .withLabel(this.language.get('customFields:fields:group:label'))
    .withMiddleware((value: string) => {
      if (this.viewModelMiddleware) {
        this.viewModelMiddleware(AvailableFields.Group, value)
      }

      this.dirtyCallbackWrapper()

      return value
    })

  // To be able to remove the old slate text editor, swapping the presenter here for the generic textInputPresenter
  // Since they should be roughly the same?
  // None of this code makes any sense & is literally the most horrifically over-complicated and confusing
  // way to do anything ever. We need to kill it all with fire and sentence everyone involved in writing it
  // to a lifetime of hard labour in a Siberian gulag

  @observable
  public textEditorPresenter: TextInputPresenter = new TextInputPresenter()
    .withLabel(this.language.get('customFields:fields:textEditorLabels:label'))
    .withMiddleware((value: string) => {
      if (this.viewModelMiddleware) {
        this.viewModelMiddleware(AvailableFields.DefaultTextAreaContent, value)
      }
      this.dirtyCallbackWrapper()
      return value
    })

  private getDisplayTypeOptions = (fieldTypeValue?: string) => {
    if (!fieldTypeValue) return this.displayTypeOptions

    return this.filterOptions(this.displayTypeOptions, fieldTypeValue, true)
  }

  @observable
  public displayTypePresenter = new SelectPresenter(this.language)
    .withLabel(this.language.get('customFields:fields:displayType:label'))
    .withMiddleware((value: string) => {
      if (this.viewModelMiddleware) {
        this.viewModelMiddleware(AvailableFields.ApplyTo, value)

        /**
         * Clear any selected restrictions when changing the applyTo type.
         * Per conversation with Pete Heard, we should not have logic here
         * to handle state changes. The logic should be moved to a respository
         * that would map restrictions to array of boolean types representing
         * our checkboxes.
         */
        if (this.restrictionsChecked.length > 0) {
          const displayType = this.displayTypePresenter.value as string
          this.restrictions.map(restriction => {
            this.genericCheckboxMiddleware(
              AvailableFields.Constraint,
              restriction,
              false,
              this.getTypeFromDisplayType(displayType)
            )
          })
          this.restrictionsChecked = []
        }
      }

      this.dirtyCallbackWrapper()

      return value
    })
    .isRequired()

  @observable
  public remoteKeyFieldsPresenter: RemoteOptionsPresenter = new RemoteOptionsPresenter()
    .withOptionChangeCallback(() => {
      if (this.viewModelOptionsMiddleware) {
        this.viewModelOptionsMiddleware(AvailableOptions.RemoteKeys, this.remoteKeyFieldsPresenter.getOptions())
      }
    })
    .withDirtyCallback(() => {
      this.dirtyCallbackWrapper()
    })
    .withLabel(this.language.get('customFields:fields:remoteOptions:label'))
    .withPlaceholder(this.language.get('customFields:fields:remoteOptions:placeholder'))

  @observable
  public remoteUrlPresenter: TextInputPresenter = new TextInputPresenter()
    .withLabel(this.language.get('customFields:fields:remoteURL:label'))
    .withMiddleware((value: string) => {
      if (this.viewModelMiddleware) {
        this.viewModelMiddleware(AvailableFields.RemoteURL, value)
      }

      this.dirtyCallbackWrapper()

      return value
    })
    .isRequired()
    .withIconPrefix('link')

  @observable
  public indicateConflictsPresenter: RadioButtonsPresenter = new RadioButtonsPresenter([
    this.conflictsAllowLabel,
    this.conflictsWarnLabel
  ])
    .withLabel(this.language.get('customFields:fields:conflicts:label'))
    .withMiddleware((option: string) => {
      if (this.viewModelMiddleware) {
        this.viewModelMiddleware(AvailableFields.IndicateConflicts, option)
      }

      this.dirtyCallbackWrapper()

      return option
    })

  @observable
  public genericOptionsPresenter: FieldOptionsPresenter = new FieldOptionsPresenter()
    .withOptionChangeCallback(() => {
      if (this.viewModelOptionsMiddleware) {
        this.viewModelOptionsMiddleware(AvailableOptions.GenericOptions, this.genericOptionsPresenter.getOptions())
      }
    })
    .withDirtyCallback(() => {
      this.dirtyCallbackWrapper()
    })

  private getDisplayTypeOption = () => {
    return this.displayTypeOptions.find(e => e.value === this.displayTypePresenter.value)
  }

  @computed
  public get restrictionHeader(): string {
    const displayType = this.getDisplayTypeOption()
    let header: string

    if (displayType) {
      switch (displayType.type) {
        case ContextType.Task:
          header = this.language.get('customFields:fields:displayRestrictions:taskTypeLabel')
          break
        case ContextType.Runbook:
          header = this.language.get('customFields:fields:displayRestrictions:runbookTypeLabel')
          break
        case ContextType.Events:
          header = this.language.get('customFields:fields:displayRestrictions:eventTypeLabel')
          break
      }
    }

    return header
  }

  @computed
  public get restrictions(): string[] {
    const { taskTypes, runbookTypes, eventTypes } = this.viewPresenter
    if (!taskTypes || !runbookTypes || !eventTypes) {
      return []
    }

    const displayType = this.getDisplayTypeOption()
    const restrictions = []
    const currentApplyTo = this.displayTypePresenter.value

    const selectedOption = this.visibilityPresenter.visibilityTypePresenter.selectedOption
    const isGlobal = selectedOption === this.language.get('common:organisms:accountVisibility:fieldType:optionGlobal')
    const selectedAccount = this.visibilityPresenter.accountVisibilityPresenter.selectedOption
    const canDisplayRestriction = (global: boolean, accountId: number) =>
      global || (!isGlobal && selectedAccount === this.viewPresenter.accountsVM[accountId])

    if (displayType) {
      switch (displayType.type) {
        case ContextType.Task:
          taskTypes.map(({ name, autoStart, autoFinish, global, accountId }) => {
            if (name.startsWith('integration_action_item_') || name.startsWith('Integrations::Apps::MountPoint')) {
              return
            }

            if (canDisplayRestriction(global, accountId)) {
              if (currentApplyTo === taskEditApplyTo) restrictions.push(name)
              if (currentApplyTo === taskStartApplyTo && !autoStart) restrictions.push(name)
              if (currentApplyTo === taskEndApplyTo && !autoFinish) restrictions.push(name)
            }
          })

          break
        case ContextType.Runbook:
          runbookTypes.map(({ name, accountId, global, disabled }) => {
            if (
              canDisplayRestriction(global, accountId) &&
              (!disabled || (disabled && this.restrictionsChecked.includes(name)))
            ) {
              restrictions.push(name)
            }
          })
          break
        case ContextType.Events:
          eventTypes.map(({ name }) => {
            restrictions.push(name)
          })
          break
      }
    }

    return restrictions
  }

  @computed
  public get integrationActionItems(): string[] {
    const { integrationActionItems } = this.viewPresenter

    if (!integrationActionItems) {
      return []
    }

    return integrationActionItems.flatMap(iai => (iai.name ? [iai.name] : []))
  }

  @computed
  public get integrationActionItemsHeader(): string {
    return this.language.get('customFields:fields:integrationActionItems:label')
  }

  private getTypeFromDisplayType = (displayType: string): ContextType => {
    const taskTester = new RegExp('^task')
    const runbookPageTester = new RegExp('^runbook_page')
    const runbookTester = new RegExp('^runbook')
    const eventsTester = new RegExp('^event')
    const folderTester = new RegExp('^project')
    let type

    if (taskTester.test(displayType)) {
      type = ContextType.Task
    } else if (runbookPageTester.test(displayType)) {
      type = ContextType.RunbookPage
    } else if (runbookTester.test(displayType)) {
      type = ContextType.Runbook
    } else if (eventsTester.test(displayType)) {
      type = ContextType.Events
    } else if (folderTester.test(displayType)) {
      type = ContextType.Folder
    } else {
      type = ContextType.Other
    }

    return type
  }

  public constraintMiddleware = (value: boolean, label: string): boolean => {
    if (this.genericCheckboxMiddleware) {
      const displayType = this.displayTypePresenter.value as string
      this.genericCheckboxMiddleware(AvailableFields.Constraint, label, value, this.getTypeFromDisplayType(displayType))
    }

    this.dirtyCallbackWrapper()

    return value
  }

  public integrationActionItemsMiddleware = (value: boolean, label: string): boolean => {
    if (this.genericCheckboxMiddleware) {
      const displayType = this.displayTypePresenter.value as string
      this.genericCheckboxMiddleware(
        AvailableFields.IntegrationActionItem,
        label,
        value,
        this.getTypeFromDisplayType(displayType)
      )
    }

    this.dirtyCallbackWrapper()

    return value
  }

  public dataSourceMappingsMiddleware = (value: boolean, label: string): boolean => {
    if (this.genericCheckboxMiddleware) {
      const displayType = this.displayTypePresenter.value as string
      this.genericCheckboxMiddleware(AvailableFields.DataSourceMappings, label, value)
    }

    this.dirtyCallbackWrapper()

    return value
  }

  @observable
  public dataSourceMappingsChecked: string[] = []

  public getJsonMappingLabels = (dataSourceId: number) => {
    if (!dataSourceId) {
      return
    }

    const dataSource = this.viewPresenter.dataSourcesVM.find(ds => {
      return ds.id === dataSourceId
    })

    if (!dataSource) {
      return
    }

    const dataSourceMappingKeys = dataSource.json_mappings
      .sort((mappingA, mappingB) => {
        return !!mappingB.primary - !!mappingA.primary
      })
      .map(mapping => {
        if (mapping.hasOwnProperty('label')) {
          return mapping.label
        } else {
          return mapping.mapping
        }
      })

    return [dataSource?.json_mappings.length, dataSourceMappingKeys]
  }

  private additionalSettings: IFilterOptions[] = [
    {
      label: this.additionalSettingAllowCreateLabel,
      hideOnFieldType: [textFieldType, textAreaFieldType, userSelectFieldType, endpointFieldType, dateFieldType]
    },
    {
      label: this.additionalSettingListLabel,
      hideOnFieldType: [textAreaFieldType]
    },
    {
      label: this.additionalSettingFilterLabel,
      hideOnFieldType: [userSelectFieldType]
    },
    {
      label: this.additionalSettingTableLabel,
      hideOnFieldType: [
        textAreaFieldType,
        userSelectFieldType,
        endpointFieldType,
        checkboxFieldType,
        selectFieldType,
        radioboxFieldType
      ],
      showOnContext: [taskEditApplyTo, taskStartApplyTo, taskEndApplyTo]
    },
    {
      label: this.additionalSettingRequiredLabel,
      hideOnFieldType: [checkboxFieldType]
    },
    {
      label: this.additionalSettingTransientLabel,
      helpText: this.additionalSettingTransientHelpText
    },
    {
      label: this.additionalSettingReadOnlyLabel
    },
    {
      label: this.additionalSettingLockNameLabel
    },
    {
      label: this.additionalSettingHiddenLabel
    }
  ]

  private getFieldTypePresenterValue = (): string => {
    const value = this.fieldTypePresenter.value
    return typeof value === 'number' ? value.toString() : value
  }

  private getFieldDisplayTypePresenterValue = (): string => {
    const value = this.displayTypePresenter.value
    return typeof value === 'number' ? value.toString() : value
  }

  @observable
  public formValidator: GenericFormPresenter

  private setUpGroupReaction = () => {
    reaction(
      () => this.viewPresenter.customFieldGroups,
      (customFieldGroups, reaction) => {
        this.groupPresenter.withOptions(this.groups)
        reaction.dispose()
      }
    )
  }

  @computed
  public get formIsValid(): boolean {
    return this.isFormValid()
  }

  public isFormValid = (): boolean => {
    return (
      this.formValidator.isValid &&
      (this.fieldTypePresenter.value !== endpointFieldType || this.remoteUrlPresenter.isValid) &&
      (!this.fieldTypePresenter.value.toString().endsWith(searchableFieldType) || this.dataSourcePresenter.isValid)
    )
  }

  @computed
  public get fieldTypes(): { label: string; value: string }[] {
    if (!Object.keys(this.viewPresenter.fieldTypesVM)) return []

    const fieldTypes = []

    Object.keys(this.viewPresenter.fieldTypesVM).forEach(slug => {
      fieldTypes.push({ label: this.viewPresenter.fieldTypesVM[slug], value: slug })
    })

    return fieldTypes
  }

  @computed
  public get dataSources(): { label: string; value: string }[] {
    if (!this.viewPresenter.dataSourcesVM) return []

    return this.viewPresenter.dataSourcesVM.map(dataSource => {
      return {
        label: dataSource.name,
        value: dataSource.id
      }
    })
  }

  @computed
  public get groups(): TSelectOptionTypes[] {
    if (!this.viewPresenter.customFieldGroups.length) return []

    return this.viewPresenter.customFieldGroups.map(group => {
      return {
        label: group.name,
        value: group.id
      }
    })
  }

  @computed
  public get canDisplayGroups(): boolean {
    if (!this.viewPresenter.customFieldGroups.length) return false
    return true
  }

  @computed
  public get displayTypeOptions(): IFilterOptions[] {
    if (!Object.keys(this.viewPresenter.contextsVM)) return []

    const contextTypes = []
    const endpointContexts = [ContextType.Runbook, ContextType.Events]

    Object.keys(this.viewPresenter.contextsVM).forEach(slug => {
      const option: IFilterOptions = {
        label: this.viewPresenter.contextsVM[slug],
        value: slug,
        type: this.getTypeFromDisplayType(slug)
      }

      if (option.type === ContextType.RunbookPage) {
        option.hideOnFieldType = [
          textFieldType,
          selectFieldType,
          radioboxFieldType,
          checkboxFieldType,
          endpointFieldType
        ]
      } else if (!endpointContexts.includes(option.type)) {
        option.hideOnFieldType = [endpointFieldType]
      }

      contextTypes.push(option)
    })

    return contextTypes
  }

  @action
  private processViewModel = () => {
    this.visibilityPresenter.withViewModel({
      type: this.viewModel.account ? 'account' : 'global',
      account: this.viewModel.account ? this.viewModel.account : null,
      disabledGlobal: this.viewModel.disabledGlobal,
      disabledAccount: this.viewModel.disabledAccount
    })

    this.namePresenter.disabled = this.viewModel.disabledName
    this.dataSourcePresenter.disabled = this.viewModel.disableDataSource
    this.fieldTypePresenter.disabled = this.viewModel.disableFieldType
  }

  @action
  public withVisibilityAccounts = (visibilityAccounts: string[]) => {
    this.visibilityAccounts = visibilityAccounts
    this.visibilityPresenter.withAccounts(this.visibilityAccounts)
    return this
  }

  @action
  public withUnavailableNames = (unavailableNames: string[]) => {
    this.unavailableNames = unavailableNames
    this.namePresenter.isUnique(
      this.unavailableNames,
      this.language.get('customFields:fields.fieldName.errors.notUnique')
    )
    return this
  }

  @action
  public withViewModel = (viewModel: IFormViewModel) => {
    this.viewModel = viewModel
    this.processViewModel()
    return this
  }

  protected triggerFieldErrors = () => {
    this.namePresenter.isDirty = true
    this.fieldTypePresenter.isDirty = true
    this.displayTypePresenter.isDirty = true

    if (this.fieldTypePresenter.value === endpointFieldType) {
      this.remoteUrlPresenter.isDirty = true
    }
  }

  // This is currently only triggered when entire viewModel is updated.
  public inflateFormWithVM = (viewModel: ICustomFieldVM) => {
    this.namePresenter.value = viewModel.name
    this.displayNamePresenter.value = viewModel.displayName
    this.fieldTypePresenter.value = viewModel.fieldType
    this.displayTypePresenter.options = this.getDisplayTypeOptions(viewModel.fieldType)
    this.displayTypePresenter.value = viewModel.applyTo
    this.dataSourcePresenter.value = viewModel.dataSourceId
    this.applyReadonly(this.viewPresenter.recordForEditReadOnly())

    if (viewModel.indicateConflicts !== undefined) {
      if (viewModel.indicateConflicts === IndicateConflicts.Allow) {
        this.indicateConflictsPresenter.selectedOption = this.conflictsAllowLabel
      } else if (viewModel.indicateConflicts === IndicateConflicts.Warn) {
        this.indicateConflictsPresenter.selectedOption = this.conflictsWarnLabel
      }
    }

    if (!viewModel.global) {
      this.viewModel.account = viewModel.accountName
    } else {
      this.viewModel.account = null
    }

    this.displayNamePresenter.required = !!viewModel?.options?.lock_name
    this.viewModel.disabledName = !!viewModel?.options?.lock_name
    this.viewModel.disableDataSource = !!viewModel?.dataSourceId
    this.viewModel.disableFieldType = viewModel?.fieldType?.endsWith('searchable')
    this.viewModel.value_key = viewModel?.value_key

    this.processViewModel()

    if (viewModel.defaultValue) {
      if (viewModel.fieldType === textFieldType) {
        this.defaultTextPresenter.value = viewModel.defaultValue
      } else {
        this.textEditorPresenter.value = viewModel.defaultValue
      }
    } else {
      // set empty string as default to avoid previous custom field value being shown
      if (viewModel.fieldType === textFieldType) {
        this.defaultTextPresenter.value = ''
      } else {
        this.textEditorPresenter.value = ''
      }
    }

    if (viewModel.fieldRemoteKeys && viewModel.fieldRemoteKeys.length > 0) {
      this.remoteKeyFieldsPresenter.withOptions(viewModel.fieldRemoteKeys)
    }

    if (viewModel.fieldOptions && viewModel.fieldOptions.length > 0) {
      this.genericOptionsPresenter.withOptions(viewModel.fieldOptions)
    }

    if (viewModel.remoteURL) {
      this.remoteUrlPresenter.value = viewModel.remoteURL
    }

    if (viewModel.constraintLabels) {
      this.restrictionsChecked = viewModel.constraintLabels
    }

    if (viewModel.integrationActionItemLabels) {
      this.integrationActionItemsChecked = viewModel.integrationActionItemLabels
    }

    if (viewModel.disabledIntegrationActionItemLabels) {
      this.disabledIntegrationActionItems = viewModel.disabledIntegrationActionItemLabels
    }

    if (viewModel.allowFieldCreation) {
      this.additionalSettingsChecked.push(this.additionalSettingAllowCreateLabel)
    }

    this.allowFieldCreationCheckboxPresenter.value = viewModel.allowFieldCreation

    this.requiredCheckboxPresenter.value = viewModel.required

    this.filterCheckboxPresenter.value = viewModel.displaySearch

    this.tableCheckboxPresenter.value = viewModel.displayTable

    this.listCheckboxPresenter.value = viewModel.displayList

    this.transientCheckboxPresenter.value = viewModel.options && viewModel.options.transient

    this.readOnlyCheckboxPresenter.value = viewModel.options && viewModel.options.readonly

    this.lockNameCheckboxPresenter.value = viewModel.options && viewModel.options.lock_name

    this.hiddenCheckboxPresenter.value = viewModel.options && viewModel.options.hidden

    if (viewModel.groupId) {
      this.groupPresenter.value = viewModel.groupId
    }

    this.autoGenerated = Boolean(viewModel.defaultIntegrationActionItemId)
  }

  @action
  public applyReadonly = (readOnly: boolean) => {
    this.readOnly = readOnly
    const isDisabled: boolean = this.autoGenerated || readOnly
    this.namePresenter.disabled = isDisabled || this.namePresenter.disabled
    this.groupPresenter.disabled = isDisabled
    this.visibilityPresenter.applyDisabledSettings(isDisabled, isDisabled)
    this.defaultTextPresenter.disabled = readOnly
    this.fieldTypePresenter.disabled = isDisabled || this.fieldTypePresenter.disabled
    this.dataSourcePresenter.disabled = isDisabled || this.dataSourcePresenter.disabled
    this.textEditorPresenter.disabled = readOnly
    this.displayTypePresenter.disabled = isDisabled || this.hiddenCheckboxPresenter.value
    this.remoteUrlPresenter.disabled = readOnly
    this.indicateConflictsPresenter.disabled = readOnly
    this.remoteKeyFieldsPresenter.disabled = readOnly
    this.genericOptionsPresenter.disabled = readOnly
    this.allowFieldCreationCheckboxPresenter.disabled = readOnly
    this.requiredCheckboxPresenter.disabled = readOnly
    this.filterCheckboxPresenter.disabled = readOnly || this.hiddenCheckboxPresenter.value
    this.tableCheckboxPresenter.disabled = readOnly || this.hiddenCheckboxPresenter.value
    this.listCheckboxPresenter.disabled = readOnly || this.hiddenCheckboxPresenter.value
    this.transientCheckboxPresenter.disabled = readOnly
    this.readOnlyCheckboxPresenter.disabled = readOnly
    this.lockNameCheckboxPresenter.disabled = readOnly
    this.hiddenCheckboxPresenter.disabled = readOnly
  }

  @action
  protected preSubmission = () => {
    this.formValidator.serverErrors = []
  }

  @action
  public reset = () => {
    this.submitted = false

    this.groupPresenter.reset()
    this.visibilityPresenter.reset()
    this.remoteUrlPresenter.reset()
    this.genericOptionsPresenter.reset()
    this.remoteKeyFieldsPresenter.reset()
    this.indicateConflictsPresenter.reset()
    this.restrictionsChecked = []
    this.integrationActionItemsChecked = []
    this.dataSourceMappingsChecked = []
    this.disabledIntegrationActionItems = []
    this.additionalSettingsChecked = []
    this.formValidator.reset()

    if (this.resetCallback) {
      this.resetCallback()
    }
  }
}
