import { Button as GrommetButton } from 'grommet'

import { Box, ColorProp, Icon, Text, Tooltip } from '@cutover/react-ui'

type SubHeaderPillProps = {
  label: string
  onClose?: () => void
  background?: ColorProp
  color?: string
  width?: string | Object
  hasTooltip?: boolean
}

export const SubHeaderPill = ({
  label,
  onClose,
  background = 'bg',
  color,
  width = 'fit-content',
  hasTooltip = false
}: SubHeaderPillProps) => {
  return (
    <Box
      background={background}
      direction="row"
      height="40px"
      width={width}
      round="large"
      justify="center"
      align="center"
      pad="small"
      gap="xxsmall"
      a11yTitle={label}
    >
      {hasTooltip ? (
        <Tooltip content={label} placement="top" truncateContent>
          <Text weight={500} color={color}>
            {label}
          </Text>
        </Tooltip>
      ) : (
        <Text weight={500} color={color}>
          {label}
        </Text>
      )}
      <GrommetButton
        plain
        icon={<Icon icon="close" size="20px" color={color} />}
        onClick={onClose}
        focusIndicator={false}
      />
    </Box>
  )
}
