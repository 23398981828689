// @ts-nocheck
import { container } from 'app/Config/IOC'
import { injectable } from 'inversify'
import { CustomFieldGroupViewPresenter } from '../CustomFieldGroupViewPresenter'
import { CustomFieldGroupFormPresenter } from '../CustomFieldGroupForm/CustomFieldGroupFormPresenter'
import { GenericPageAddPresenter } from 'Shared/Presenters/GenericPageAddPresenter'
import { observable, action } from 'mobx'

@injectable()
export class CustomFieldGroupAddPresenter extends GenericPageAddPresenter {
  protected viewPresenter: CustomFieldGroupViewPresenter = container.get(CustomFieldGroupViewPresenter)

  @observable
  public formPresenter: CustomFieldGroupFormPresenter

  @action
  public withFormPresenter = (formPresenter: CustomFieldGroupFormPresenter) => {
    this.formPresenter = formPresenter
    this.formPresenter.withOnSubmit(() => {
      this.save()
    })
    return this
  }
}
