import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Heading } from 'grommet'

import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuListItem,
  MenuListItemProps,
  SaveEditingFormModal,
  ScrollContent,
  useNotify
} from '@cutover/react-ui'
import { RunbookWidgetCollection } from 'main/components/dashboards/dashboard-widget-collection'
import { useAppliedFilters, useFilteredTasksState, useRunbookVersionUsers } from 'main/recoil/data-access'
import {
  ActiveDashboardModel,
  ActiveRunbookModel,
  ActiveRunModel,
  CommentModel,
  ConfigModel,
  CurrentUserModel,
  CustomFieldModel,
  DashboardModel,
  RunbookViewModel,
  SavedFilterModel,
  StreamModel,
  TaskModel,
  TaskTypeModel
} from 'main/data-access'
import { DashboardComponent, DashboardComponentCollection } from 'main/components/dashboards/widgets/types'
import { CustomField, Dashboard as DashboardType, FieldValue } from 'main/services/queries/types'
import { RunbookUpdatePayload, useUpdateRunbook } from 'main/services/queries/use-update-runbook'
import { useLanguage } from 'main/services/hooks'
import { useRunbookSubHeader } from '../../runbook-sub-header/use-runbook-sub-header'
import { useToggleRightPanel } from 'main/components/layout/right-panel'

export const Dashboard = () => {
  const currentUserId = CurrentUserModel.useId()
  const customFields = CustomFieldModel.useGetAll()
  const runbook = ActiveRunbookModel.useGet()
  const streams = StreamModel.useGetAll()
  const taskTypes = TaskTypeModel.useGetAll()
  const tasks = TaskModel.useGetAll()
  const { taskStageTypes: taskStages } = ConfigModel.useGet()
  const dashboard = ActiveDashboardModel.useGet()
  const pirDashboard = DashboardModel.useGetBy({ key: 'pir' })
  const filtersState = useAppliedFilters()

  const { state: commentsLoadingState, contents: featuredCommentContents } = CommentModel.useGetAllLoadable({
    scope: 'featured'
  })
  const comments = commentsLoadingState === 'hasValue' ? featuredCommentContents : []
  const filteredTasks = useFilteredTasksState()
  const users = useRunbookVersionUsers()

  const components =
    dashboard?.dashboard_components.map(component => {
      return {
        ...component,
        type: component.component_type,
        custom_fields: customFields,
        current_user: currentUserId,
        runbook,
        streams,
        task_types: taskTypes,
        tasks,
        filtered_tasks: filteredTasks,
        stage_types: taskStages,
        users,
        comments,
        filters: component.filters && JSON.parse(component.filters),
        settings: component.settings && JSON.parse(component.settings),
        task_filters: filtersState
      }
    }) || []

  const isPirDashboard = !!(pirDashboard && dashboard && dashboard?.id === pirDashboard?.id)

  const hasComponents = useMemo(
    () => components.some(component => component.component_type === 'content'),
    [components, dashboard, pirDashboard]
  )
  const showDashboardForm = isPirDashboard || hasComponents

  return showDashboardForm ? (
    <>
      {dashboard && (
        <PageForm components={components} dashboard={dashboard} isPir={isPirDashboard}>
          <ScrollContent>
            <RunbookWidgetCollection components={components} media="screen" />
          </ScrollContent>
        </PageForm>
      )}
    </>
  ) : (
    <DashboardPage>
      <ScrollContent>
        <RunbookWidgetCollection components={components} media="screen" />
      </ScrollContent>
    </DashboardPage>
  )
}

const DashboardPage = ({ children }: { children: ReactNode }) => {
  const { addSubHeaderContent, resetSubHeaderContent } = useRunbookSubHeader()

  useEffect(() => {
    addSubHeaderContent({
      right: <DashboardSubHeaderRightContent />
    })

    return resetSubHeaderContent
  }, [])

  return <>{children}</>
}

const DashboardSubHeaderRightContent = () => {
  const { t } = useLanguage('runbook', { keyPrefix: 'subHeader' })

  const toggleSchedulePanel = useToggleRightPanel('runbook-dashboard-schedule')
  const openModal = RunbookViewModel.useAction('modal:open')
  const canUpdateRunbook = ActiveRunbookModel.useCan('update')
  const { restricted: isRestrictedRunbook } = ActiveRunbookModel.useGet()
  const run = ActiveRunModel.useGet()
  const dashboardId = ActiveDashboardModel.useId()

  const showShareRunbookDashboardButton =
    canUpdateRunbook && run?.mode !== 'cancelled' && run?.mode !== 'complete' && !isRestrictedRunbook

  const menuOptions = useMemo(() => {
    return [
      showShareRunbookDashboardButton && {
        label: t('dashboardMenu.manageSchedules'),
        icon: 'scheduled-share',
        onClick: () => {
          dashboardId && toggleSchedulePanel({ dashboardId })
        },
        appendDivider: true
      },
      {
        label: t('dashboardMenu.exportTasks'),
        icon: 'download',
        onClick: () => {
          openModal({ type: 'tasks-export' })
        }
      },
      {
        label: t('dashboardMenu.downloadAsPDF'),
        icon: 'download',
        onClick: () => console.log('Download as PDF')
      }
    ].filter(Boolean) as MenuListItemProps[]
  }, [showShareRunbookDashboardButton, dashboardId, toggleSchedulePanel])

  return (
    <>
      {showShareRunbookDashboardButton && (
        <Box width={{ min: '40px' }}>
          <IconButton
            tertiary
            data-testid="sub-header-share-button"
            label={t('share')}
            onClick={() => {
              openModal({ type: 'runbook-dashboard-share' })
            }}
            icon="share"
          />
        </Box>
      )}
      {menuOptions.length > 0 && (
        <Menu
          trigger={
            <IconButton
              tertiary
              data-testid="sub-header-options-menu"
              label={t('moreOptions')}
              disableTooltip
              icon="more-vertical"
              css="z-index: 1;"
            />
          }
        >
          {menuOptions.map(item => (
            <MenuListItem
              label={item.label}
              suffixColor="text-disabled"
              key={item.label}
              icon={item.icon}
              onClick={item.onClick}
              appendDivider={item.appendDivider}
            />
          ))}
        </Menu>
      )}
    </>
  )
}

const PageForm = ({
  components,
  dashboard,
  children,
  isPir
}: {
  dashboard: DashboardType
  components: DashboardComponentCollection
  children: ReactNode
  isPir: boolean
}) => {
  const { t } = useLanguage('runbook', { keyPrefix: 'subHeader' })
  const { mutateAsync: updateRunbook } = useUpdateRunbook()
  const { addSubHeaderContent, resetSubHeaderContent } = useRunbookSubHeader()
  const notify = useNotify()
  const innerPageContainer = document.getElementById('layout-inner-page')
  const pirPublished = SavedFilterModel.useGetSavedPirFilter()

  const [isSubmitting, setIsSubmitting] = useState(false)

  const runbookId = ActiveRunbookModel.useId()
  const processRunbookUpdateResponse = ActiveRunbookModel.useOnAction('update')
  const { field_values: fieldValues } = ActiveRunbookModel.useGet()

  const defaultValues = useMemo(() => {
    return components.reduce((acc, component: DashboardComponent) => {
      const { custom_fields, type, settings } = component
      if (type !== 'content') return acc

      const customFieldId = settings.custom_field_id || {}
      const cf = custom_fields.find((field: CustomField) => field.id === customFieldId)
      const fv = fieldValues?.find((fv: FieldValue) => fv.custom_field_id === customFieldId)

      if (cf) {
        acc[cf.id] = fv?.value ? fv.value : cf.default_value ? cf.default_value.trim() : '<p></p>'
      }
      return acc
    }, {})
  }, [components, fieldValues])

  const methods = useForm({
    defaultValues
  })

  const {
    getValues,
    formState: { isDirty },
    reset
  } = methods

  const handleReset = useCallback(() => {
    setIsSubmitting(false)
    reset(defaultValues)
  }, [defaultValues])

  const handleSubmit = useCallback((fieldValues: FieldValue[]) => {
    // it's important we use this getValues callback and don't add the values as a dependency in
    // this function for performance reasons
    const formValues = getValues()
    setIsSubmitting(true)

    const fieldValueAttributes = []

    for (const [key, value] of Object.entries(formValues)) {
      const fv = fieldValues.find(fv => fv.custom_field_id === Number(key))
      fieldValueAttributes.push({
        custom_field_id: key,
        data_source_value_id: fv?.data_source_value_id ?? null,
        id: fv?.id ?? null,
        value
      })
    }

    const data = {
      runbook: {
        id: runbookId,
        field_values_attributes: fieldValueAttributes
      }
    }
    updateRunbook({ ...data } as unknown as RunbookUpdatePayload, {
      onSuccess: response => {
        notify.success(t('updateSuccess'), { title: t('updateSuccessTitle') })
        processRunbookUpdateResponse(response)
        handleReset()
      },
      onError: () => {
        handleReset()
      }
    })
  }, [])

  useEffect(() => {
    if (fieldValues) {
      handleReset()
    }
  }, [fieldValues])

  useEffect(() => {
    addSubHeaderContent({
      left: (
        <Heading as="h2" css="font-weight: 700; font-size: 20px; margin: 0; max-width: 100%; line-height: 25px;">
          {isPir ? `${dashboard.name}${!pirPublished ? ` (${t('pirDraft')})` : ''}` : dashboard.name}
        </Heading>
      ),
      right: (
        <DashboardPageSubHeaderRightContent
          isSubmitting={isSubmitting}
          isDirty={isDirty}
          onSubmit={() => handleSubmit(fieldValues)}
          onReset={handleReset}
        />
      )
    })

    return resetSubHeaderContent
  }, [isSubmitting, isDirty, dashboard.name, handleSubmit, handleReset, resetSubHeaderContent])

  return (
    <SaveEditingFormModal
      isDirty={isDirty}
      isSubmitting={isSubmitting}
      onReset={handleReset}
      onSubmit={() => handleSubmit(fieldValues)}
      ignoreWithin={innerPageContainer ?? undefined}
      render={() => {
        return <FormProvider {...methods}>{children}</FormProvider>
      }}
    />
  )
}

type DashboardPageSubHeaderRightContentProps = {
  isSubmitting: boolean
  isDirty: boolean
  onSubmit: () => void
  onReset: () => void
}

const DashboardPageSubHeaderRightContent = ({
  isSubmitting,
  isDirty,
  onSubmit,
  onReset
}: DashboardPageSubHeaderRightContentProps) => {
  const { t } = useLanguage('runbook', { keyPrefix: 'subHeader' })

  return (
    <>
      {isDirty && (
        <>
          {!isSubmitting && <Button tertiary label={t('discard')} onClick={onReset} />}
          <Button primary loading={isSubmitting} label={isSubmitting ? t('saving') : t('save')} onClick={onSubmit} />
        </>
      )}
      {/* TODO: menu should only have export tasks and download PDF.
      Add this menu when those options are implemented  */}
      {/* <RunbookSubHeaderDefaultMenu /> */}
    </>
  )
}
