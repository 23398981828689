import { MouseEvent, ReactNode, SyntheticEvent, useCallback, useId, useMemo, useState } from 'react'
import { useFocusWithin } from '@react-aria/interactions'
import { Resizable, ResizeCallbackData } from 'react-resizable'
import styled from 'styled-components/macro'

import { DISCLOSURE_PANEL_HEADER_CLASS_NAME, DisclosurePanelHeader, HEADER_HEIGHT } from './disclosure-panel-header'
import { themeColor } from '../../theme'
import { CollapsibleBox } from '../../utilities/'
import { Box } from '../box'

const MIN_EXPANDED_HEIGHT = 150
const DEFAULT_HEIGHT = 150

type ResizeCallback = (e: SyntheticEvent<Element, Event>, data: ResizeCallbackData) => void

type DisclosurePanelProps = {
  /**
   * @type {ReactNode}
   */
  children?: ReactNode
  /**
   * The default height of the disclosure.
   * @type {number}
   */
  defaultHeight?: number
  /**
   * Determines if the disclosure should be open or closed when first displayed.
   * @type {boolean}
   */
  initialOpen?: boolean
  /**
   * Title to be displayed in the heading of the disclosure.
   * @type {string}
   */
  title?: string
  // TODO: fix so disclosure panel works without resizable prop, and make optional
  /**
   * Determines if the disclosure contains a resizing handle at the bottom for vertical adjustment.
   * @type {boolean}
   */
  resizable: boolean
  /**
   * The maximum height allowed for the disclosure in px.
   * @type {Number}
   */
  maxHeight?: number
  /**
   * The minimum height allowed for the disclosure in px.
   * @type {Number}
   */
  minHeight?: number
  /**
   * Allows for a customized header to be passed in.
   */
  renderHeader?: (props: {
    isActive: boolean
    isOpen: boolean
    onClick?: (e: MouseEvent<HTMLElement>) => void
    onKeyDownCapture?: (e: React.KeyboardEvent<HTMLElement>) => void
  }) => ReactNode
}

export const DisclosurePanel = ({
  children,
  defaultHeight = 200,
  initialOpen = true,
  title,
  resizable = false,
  renderHeader,
  ...props
}: DisclosurePanelProps) => {
  const minHeight = props.minHeight ?? MIN_EXPANDED_HEIGHT
  const maxTotalHeight = props.maxHeight ?? Infinity
  const [height, setHeight] = useState(defaultHeight ?? DEFAULT_HEIGHT)
  const [maxHeight, setMaxHeight] = useState<number>(height)
  const [isActive, setIsActive] = useState(false)
  const [isResizing, setIsResizing] = useState(false)
  const [isOpen, setIsOpen] = useState(!!initialOpen)
  const id = useId()

  const { focusWithinProps } = useFocusWithin({
    onBlurWithin: () => {
      setIsActive(false)
    },
    onFocusWithin: e => {
      if (!e.target.className.includes(DISCLOSURE_PANEL_HEADER_CLASS_NAME)) {
        setIsActive(true)
      }
    }
  })

  const handleResize: ResizeCallback = useCallback((_e, { size }) => {
    setHeight(size.height)
  }, [])

  const handleResizeStart: ResizeCallback = useCallback(_e => {
    setIsResizing(true)
  }, [])

  const handleResizeStop: ResizeCallback = useCallback((_e, { size }) => {
    setMaxHeight(size.height)
    setIsResizing(false)
  }, [])

  const handleClick = () => {
    if (!isActive) {
      setIsActive(true)
    }
  }

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLElement>) => {
      // (target = element that triggered event; currentTarget = element that listens to event.)
      // if these are the same the keypress came from the diclosure header. if not it came from inside the disclosure and should be ignored.
      if ((e.key !== 'Enter' && e.key !== 'Space' && e.key !== ' ') || e.currentTarget !== e.target) return
      if (e.key === 'Space' || e.key === ' ') e.preventDefault()
      if (isOpen) {
        if (isActive) {
          setIsOpen(false)
        } else {
          e.currentTarget?.focus()
        }
      } else {
        setIsOpen(true)
        e.currentTarget?.focus()
      }
    },
    [isOpen, isActive]
  )

  const handleClickHeader = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      if (isOpen && isActive) {
        setIsOpen(false)
        e.currentTarget?.blur()
      } else {
        setIsOpen(true)
      }
    },
    [isOpen, isActive]
  )

  const content = useMemo(() => {
    const childContent = <ContentContainer>{children}</ContentContainer>
    return (
      <ScrollContainer active={isActive} isOpen={isOpen} aria-hidden={!isOpen} id={id}>
        {childContent}
      </ScrollContainer>
    )
  }, [children, isActive, isOpen])

  const trigger = useMemo(() => {
    const customHeader = renderHeader?.({
      isActive,
      isOpen,
      onKeyDownCapture: handleKeyDown,
      onClick: handleClickHeader
    })

    return (
      customHeader ?? (
        <DisclosurePanelHeader
          title={title}
          onClick={handleClickHeader}
          isOpen={isOpen}
          isActive={isActive}
          tabIndex={-1}
        />
      )
    )
  }, [renderHeader, isActive, handleClickHeader, handleKeyDown, title, isOpen])

  const collapsiblePanel = (
    <CollapsibleBox
      flex={false}
      open={isOpen}
      pad={{ horizontal: 'medium', bottom: 'medium' }}
      background="bg"
      onKeyDownCapture={handleKeyDown}
      onClick={handleClick}
      role="button"
      aria-expanded={isOpen}
      aria-label={title}
      aria-controls={id}
      data-is-active={isActive}
      {...focusWithinProps}
      round="16px"
      disableAnimation={isResizing}
      minHeight={resizable ? HEADER_HEIGHT : height}
      maxHeight={isResizing ? '100%' : maxHeight}
      overflow="hidden"
      trigger={trigger}
    >
      {content}
    </CollapsibleBox>
  )

  return (
    <>
      {resizable ? (
        <Resizable
          height={height}
          onResize={handleResize}
          onResizeStart={handleResizeStart}
          onResizeStop={handleResizeStop}
          className={isResizing ? 'dragging' : ''}
          handle={
            isOpen ? (
              <PodDragger>
                <PodDraggerHandle />
              </PodDragger>
            ) : undefined
          }
          css={`
            cursor: auto;
            position: relative;
            overflow: hidden;
            height: ${isOpen ? `${height}px` : 'auto !important'};
          `}
          axis="y"
          maxConstraints={[Infinity, maxTotalHeight]}
          minConstraints={[Infinity, minHeight]}
        >
          {collapsiblePanel}
        </Resizable>
      ) : (
        <>{collapsiblePanel}</>
      )}
    </>
  )
}

const PodDragger = styled(Box).attrs(() => ({
  flex: false
}))`
  height: 5px;
  cursor: row-resize;
  border: none;
  background: transparent;
  position: absolute;
  width: 100%;
  bottom: 0;

  &:hover {
    border-color: ${themeColor('text-light')};
  }

  .dragging & {
    border-color: ${themeColor('primary')};
  }
`

const ContentContainer = styled(Box).attrs(() => ({
  flex: undefined
}))`
  min-height: fit-content;
  display: block;
`

const ScrollContainer = styled(Box).attrs(({ active, isOpen }: { active: boolean; isOpen?: boolean; id?: string }) => ({
  overflow: active ? 'auto' : 'hidden',
  tabIndex: isOpen ? 0 : -1
}))<{ active: boolean; isOpen?: boolean }>``

const PodDraggerHandle = styled(Box).attrs(() => ({
  flex: false
}))`
  position: relative;
  z-index: 10000;
  cursor: row-resize;
  bottom: 4px;
  left: 50%;
  margin-left: -20px;
  width: 40px;
  height: 4px;
  border-bottom: 2px solid transparent;
  border-color: inherit;
  border-left: 0;
  border-right: 0;
  display: none;

  ${PodDragger}:hover &, .dragging & {
    display: block;
  }
`
