import { useLocation } from 'react-router-dom'

import {
  ActiveAccountModel,
  ActiveDashboardModel,
  ActiveRunbookModel,
  ActiveRunbookVersionModel,
  CurrentUserModel
} from 'main/data-access'
import { ShareRunbookDashboardModal } from './share-runbook-dashboard-modal'
import { browserQueryStringToServerQueryObject } from 'main/components/shared/filter/filter-params'

export const ShareRunbookDashboardModalWrapper = ({ closeModal }: { closeModal: () => void }) => {
  const dashboardId = ActiveDashboardModel.useId()
  const runbookId = ActiveRunbookModel.useId()
  const runbookVersionId = ActiveRunbookVersionModel.useId()
  const accountId = ActiveAccountModel.useId()
  const currentUserId = CurrentUserModel.useId()
  const { timezone } = ActiveRunbookModel.useGet()
  const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const location = useLocation()
  const filters = browserQueryStringToServerQueryObject({ query: location.search })

  return (
    <>
      {dashboardId && (
        <ShareRunbookDashboardModal
          onClose={closeModal}
          dashboardId={dashboardId}
          runbookId={runbookId}
          runbookVersionId={runbookVersionId}
          accountId={accountId}
          currentUserId={currentUserId as number}
          filters={{
            account_id: accountId,
            runbook_id: runbookId,
            runbook_version_id: runbookVersionId,
            dashboard_id: dashboardId,
            ...filters
          }}
          isFiltered={false}
          timezone={timezone ?? localTimezone}
          advancedOpen={false}
        />
      )}
    </>
  )
}
