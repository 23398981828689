import { CurrentUser } from 'main/services/queries/use-get-validate-token'
import { SingleModelInterfaceBase } from './types'
import {
  useGetCurrentUser,
  useGetCurrentUserCallback,
  useGetCurrentUserId,
  useSetCurrentUser,
  useUpdateFrontendUserSettings
} from '../hooks'
import { FrontendUserSettingType } from 'main/services/queries/types'

export type CurrentUserModelType = SingleModelInterfaceBase<CurrentUser> & {
  useId: () => number | undefined
  useSetCurrentUser: () => (valOrUpdater: ((currVal: CurrentUser | null) => CurrentUser) | (CurrentUser | null)) => void
  useUpdateFrontendUserSettings: () => ({ payload }: { payload: FrontendUserSettingType['data'] }) => void
}

export const CurrentUserModel: CurrentUserModelType = {
  useGet: useGetCurrentUser,
  useGetCallback: useGetCurrentUserCallback,
  useId: useGetCurrentUserId,
  useSetCurrentUser,
  useUpdateFrontendUserSettings
}
