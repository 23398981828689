// @ts-nocheck
import { injectable } from 'inversify'
import { observable, action } from 'mobx'
import { unescape } from 'lodash'
import { GenericRecordRepository } from 'app/Repositories/GenericRecordRepository'
import {
  ICustomFieldDTO,
  ICustomFieldPM,
  ICustomFieldResponse,
  ICustomFieldRecordDTO,
  IndicateConflicts,
  IFieldOptionDTO,
  IFieldRemoteKeysDTO
} from 'app/Settings/CustomFields/CustomFieldRepositoryTypes'
import { searchableFieldType } from 'app/Settings/CustomFields/CustomFieldFieldTypes'
import tinycolor2 from 'tinycolor2'
import { TFieldOptionTypes } from 'Shared/Components/Organisms/FieldOptions/TFieldOptionTypes'
import { getColorFromDTOColor } from 'Shared/Helpers/Color'
import { IBaseResponse } from 'Gateways/Service/IBaseResponse'
import { TRemoteOptionType } from 'Shared/Components/Organisms/FieldOptions/TRemoteOptionType'
import { v4 } from 'uuid'

interface ICustomFieldRequest {
  custom_field: ICustomFieldRecordDTO
  custom_field_group_id?: number
}

@injectable()
export class CustomFieldRepository extends GenericRecordRepository {
  protected recordDTO: ICustomFieldDTO

  @observable
  public recordForEdit: ICustomFieldPM = null

  protected url: string = 'custom_fields'

  constructor() {
    super('custom_field')
  }

  public preLoad = (args: { [key: string]: any }) => {
    return this.url + '/' + args.id
  }

  @action
  public postLoad = (rawData: ICustomFieldResponse) => {
    const record =
      rawData.body.custom_field || rawData.body.searchable_custom_field || rawData.body.multi_searchable_custom_field
    this.recordDTO = record
    this.selectedId = record.id
    this.dependentCustomFieldValueKeys = rawData.body.meta.dependent_custom_field_value_keys
    this.setProgrammersModel()
  }

  @action
  public setProgrammersModel = () => {
    const recordPM: ICustomFieldPM = {
      id: this.recordDTO.id,
      name: unescape(this.recordDTO.name),
      displayName: unescape(this.recordDTO.display_name),
      global: this.recordDTO.global,
      accountId: this.recordDTO.account_id,
      fieldType: this.recordDTO.field_type.slug,
      dataSourceId: this.recordDTO.data_source_id,
      required: this.recordDTO.required,
      applyTo: this.recordDTO.apply_to.slug,
      constraint: this.recordDTO.constraint ? this.getValidContraintFromDTO(this.recordDTO.constraint) : null,
      displayList: this.recordDTO.display_list,
      displaySearch: this.recordDTO.display_search,
      displayTable: this.recordDTO.display_table,
      defaultValue: this.recordDTO.default_value,
      default: this.recordDTO.default,
      archived: this.recordDTO.archived,
      allowFieldCreation: this.recordDTO.allow_field_creation,
      indicateConflicts:
        this.recordDTO.indicate_conflicts === 'warn_conflicts' ? IndicateConflicts.Warn : IndicateConflicts.Allow,
      remoteURL: this.recordDTO.remote_url,
      fieldOptions: this.getFieldOptionsFromDTO(this.recordDTO.field_options),
      fieldRemoteKeys: this.getFieldRemoteKeysFromDTO(this.recordDTO.field_remote_keys),
      used: this.recordDTO.used,
      options: this.recordDTO.options,
      defaultIntegrationActionItemId: this.recordDTO.default_integration_action_item_id,
      value_key: this.recordDTO.value_key ?? null
    }

    const mappings = [this.recordDTO.value_key, this.dependentCustomFieldValueKeys]
    recordPM.dependentCustomFieldValueKeys = [...new Set(mappings.flat())]

    if (this.recordDTO.custom_field_groups.length) {
      recordPM.groupId = this.recordDTO.custom_field_groups[0].id
    }

    this.recordForEdit = recordPM
  }

  private getValidContraintFromDTO = (constraint: string) => {
    const constraintObj = JSON.parse(constraint)

    if (!constraintObj) {
      return null
    }

    const type = Object.keys(constraintObj)[0]
    constraintObj[type] = constraintObj[type].map(stringId => parseInt(stringId, 10))
    return constraintObj
  }

  private getFieldRemoteKeysFromDTO = (fieldRemoteKeys: IFieldRemoteKeysDTO[]): TRemoteOptionType[] => {
    const remoteOptionKeys = []
    fieldRemoteKeys.forEach(fieldRemoteKey => {
      const remoteOptionKey: TRemoteOptionType = {
        guid: v4(),
        id: fieldRemoteKey.id,
        value: fieldRemoteKey.name,
        color: null,
        excludeFromConflicts: false,
        locked: fieldRemoteKey.is_primary,
        filterable: fieldRemoteKey.display_search,
        displayName: fieldRemoteKey.display_name
      }

      remoteOptionKeys.push(remoteOptionKey)
    })

    return remoteOptionKeys
  }

  private getFieldOptionsFromDTO = (fieldOptions: IFieldOptionDTO[]): TFieldOptionTypes[] => {
    const fieldOptionTypes = []
    fieldOptions.forEach(fieldOption => {
      fieldOptionTypes.push({
        guid: v4(),
        id: fieldOption.id,
        value: unescape(fieldOption.name),
        color: getColorFromDTOColor(fieldOption.color),
        excludeFromConflicts: fieldOption.conflict_exclude,
        archived: fieldOption.archived,
        order: fieldOption.order
      })
    })

    return fieldOptionTypes
  }

  public saveRecord = async (programmersModel: ICustomFieldPM): Promise<IBaseResponse> => {
    const result = await this.serviceGateway.put(
      this.url + '/' + programmersModel.id,
      this.getRequestFromProgrammersModel(programmersModel)
    )
    if (result.success) {
      this.postLoad(result as ICustomFieldResponse)
    }
    return result
  }

  public createRecord = async (programmersModel: ICustomFieldPM) => {
    return await this.serviceGateway.post(this.url, this.getRequestFromProgrammersModel(programmersModel))
  }

  public refreshCache = async (): Promise<IBaseResponse> => {
    if (!this.recordForEdit) return
    return await this.serviceGateway.post(this.url + '/' + this.recordForEdit.id + '/reload_cache')
  }

  private getRequestFromProgrammersModel = (programmersModel: ICustomFieldPM): ICustomFieldRequest => {
    const request: ICustomFieldRequest = {
      custom_field: this.getDtoFromProgrammersModel(programmersModel)
    }

    // The group id sits outside of the custom field object
    if (programmersModel.groupId) {
      request.custom_field_group_id = programmersModel.groupId
    }

    return request
  }

  private getDtoFromProgrammersModel = (programmersModel: ICustomFieldPM): ICustomFieldRecordDTO => {
    const recordDTO: ICustomFieldRecordDTO = {
      name: programmersModel.name,
      display_name: programmersModel.displayName,
      field_type: programmersModel.fieldType,
      field_options_attributes: [],
      field_remote_keys_attributes: [],
      apply_to: programmersModel.applyTo,
      account_id: programmersModel.accountId,
      global: programmersModel.global
    }

    if (programmersModel.id) {
      recordDTO.id = programmersModel.id
    }

    if (programmersModel.fieldRemoteKeys && programmersModel.fieldRemoteKeys.length > 0) {
      recordDTO.field_remote_keys_attributes = programmersModel.fieldRemoteKeys.map((remoteField, index) =>
        this.getRemoteFieldOptionForDTO(remoteField, index + 1)
      )
    } else if (programmersModel.fieldOptions && programmersModel.fieldOptions.length > 0) {
      recordDTO.field_options_attributes = programmersModel.fieldOptions.map((option, index) =>
        this.getGenericOptionDTO(option, index + 1)
      )
    }

    if (programmersModel.fieldType.endsWith(searchableFieldType) && programmersModel.dataSourceId) {
      recordDTO.data_source_id = programmersModel.dataSourceId
      if (programmersModel.dataSourceMappings.length > 0) {
        recordDTO.value_key = programmersModel.dataSourceMappings.shift()
        recordDTO.display_search = true
        recordDTO.dependent_custom_fields_attributes = programmersModel.dataSourceMappings.map(mapping => {
          return {
            name: mapping,
            value_key: mapping,
            global: recordDTO.global,
            account_id: recordDTO.account_id,
            field_type: 'text',
            apply_to: recordDTO.apply_to,
            display_search: true
          }
        })
      }
    }

    if (programmersModel.remoteURL) {
      recordDTO.remote_url = programmersModel.remoteURL
    }

    if (programmersModel.constraint) {
      if (Object.keys(programmersModel.constraint).length === 0) {
        recordDTO.constraint = null
      } else {
        recordDTO.constraint = JSON.stringify(programmersModel.constraint)
      }
    }

    // These params are only passed for new records when true. For edit we must be allowed to convert true to false
    if (programmersModel.allowFieldCreation || programmersModel.id) {
      recordDTO.allow_field_creation = programmersModel.allowFieldCreation
    }

    if (programmersModel.displayList || programmersModel.id) {
      recordDTO.display_list = programmersModel.displayList
    }

    if (programmersModel.displaySearch || programmersModel.id) {
      recordDTO.display_search = programmersModel.displaySearch
    }

    if (programmersModel.displayTable || programmersModel.id) {
      recordDTO.display_table = programmersModel.displayTable
    }

    if (programmersModel.required || programmersModel.id) {
      recordDTO.required = programmersModel.required
    }

    recordDTO.options = programmersModel.options || {}

    if (programmersModel.defaultValue) {
      recordDTO.default_value = programmersModel.defaultValue
    } else if (programmersModel.id) {
      // set to empty string as default to avoid error when deleting a previous entry
      recordDTO.default_value = ''
    }

    if (programmersModel.indicateConflicts !== undefined) {
      recordDTO.indicate_conflicts =
        programmersModel.indicateConflicts === IndicateConflicts.Allow ? 'allow_conflicts' : 'warn_conflicts'
    }

    return recordDTO
  }

  private getGenericOptionDTO = (option: TFieldOptionTypes, order: number): IFieldOptionDTO => {
    const optionDTO: IFieldOptionDTO = {
      name: option.value,
      order: order
    }

    if (option.color) {
      optionDTO.color = '#' + tinycolor2(option.color).toHex()
    }

    if (option.id) {
      optionDTO.id = option.id
    }

    if (option.hasOwnProperty('archived')) {
      optionDTO.archived = option.archived
    }

    if (option.hasOwnProperty('excludeFromConflicts')) {
      optionDTO.conflict_exclude = option.excludeFromConflicts
    }

    return optionDTO
  }

  private getRemoteFieldOptionForDTO = (option: TRemoteOptionType, order: number): IFieldRemoteKeysDTO => {
    const remoteKeyDTO: IFieldRemoteKeysDTO = {
      name: option.value,
      order: order,
      display_name: option.displayName ? option.displayName : option.value,
      is_primary: order === 1 ? true : false,
      display_search: option.filterable
    }

    if (option.id) {
      remoteKeyDTO.id = option.id
    }

    return remoteKeyDTO
  }
}
