import { Box, Modal } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { useAppsConnectionLostState } from 'main/recoil/data-access'

export const ConnectionLostModal = () => {
  const [appConnectionLostError, setAppConnectionLost] = useAppsConnectionLostState()
  const { t } = useLanguage('global')

  return (
    <Modal
      open={appConnectionLostError}
      onClose={() => setAppConnectionLost(false)}
      hasCancelButton={false}
      confirmText={t('confirmText')}
      onClickConfirm={() => window.location.reload()}
      title={t('connectionLostErrorTitle')}
      confirmIcon={'refresh'}
    >
      <Box>{t('connectionLostErrorBody')}</Box>
    </Modal>
  )
}
