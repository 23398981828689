import { useQuery } from 'react-query'

import { QueryKeys } from './query-keys'
import { SamlRoleMapping } from './types'
import { apiClient } from '../api'
import { useSetPermissions } from './use-permissions'

type SamlRoleMappingsResponse = {
  meta: {
    permissions: { [key: string]: number[] }
  }
  saml_role_mappings: SamlRoleMapping[]
}

export function useSamlRoleMappingsQuery(samlConfigurationId: string | number) {
  const setPermissions = useSetPermissions('saml-role-mappings')

  return useQuery<SamlRoleMappingsResponse, Error, SamlRoleMappingsResponse>(
    [QueryKeys.RoleMappings],
    async () => {
      const { data } = await apiClient.get<SamlRoleMappingsResponse>({
        url: 'saml_role_mappings',
        params: {
          saml_configuration_id: samlConfigurationId
        }
      })
      return data
    },
    {
      onSuccess: data => {
        setPermissions(data.meta.permissions)
      }
    }
  )
}
