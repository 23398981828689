// @ts-nocheck
import 'reflect-metadata'
import { ILanguageService } from './ILanguageService'
import i18next, { ReadCallback, i18n as II18n, TFunction } from 'i18next'
import { injectable } from 'inversify'
import { NAMESPACES } from 'config/i18n'

@injectable()
export class I18NextLanguageService implements ILanguageService {
  public i18n: II18n
  public t: TFunction

  public async mount(language: string, version?: number) {
    let useLanguage = ['en'].includes(language) ? language : 'en'
    if (Object.keys(i18next.options).length > 0) return

    const i18n = i18next.createInstance()

    const translateFn = await i18n
      // Custom back-end loading language files during webpack compilation.
      // From https://gist.github.com/SimeonC/6a738467c691eef7f21ebf96918cd95f#file-i18nextnamespacejson-ts
      .use({
        type: 'backend',
        read(language: string, namespace: string, callback: ReadCallback): void {
          import(`/src/Assets/locales/${namespace}/${language}.json`)
            .then(resources => callback(null, resources))
            .catch(error => callback(error, null))
        }
      })
      .init({
        lng: useLanguage,
        fallbackLng: 'en',
        ns: NAMESPACES,
        defaultNS: 'common',
        debug: false
      })

    this.i18n = i18n
    this.t = i18n.t

    return translateFn
  }

  public get(key: string, options?: Record<string, any>): string {
    return this.i18n.t(key, options)
  }
}
