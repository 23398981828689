import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const BrandInsurance = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 100 100" a11yTitle="BrandInsurance" {...props}>
    <g clipPath="url(#a)" fill="#fff"><path d="M100 50A50 50 0 0 0 0 50h100Z" /><path opacity=".5" d="M64.667 70.667V100H35.334V70.667z" /></g>
  </StyledIcon>
))

BrandInsurance.displayName = 'BrandInsurance'

export { BrandInsurance }
