/* eslint-disable react-hooks/rules-of-hooks */
// @ts-nocheck
import { useState, useEffect } from 'react'
import { windowSizes } from 'app/Styles/Variables'
import { closeAngularSettingsPanel } from 'app/Shared/Helpers/SPASharedStateService'

export const fluidPage = (
  editPanelOpen: boolean,
  closeEditPanel: () => void,
  checkEditPanelDirty: () => boolean,
  angularPanelOpen: boolean,
  filterClosedOnLoad?: boolean
) => {
  const filterOpenInitialState = !filterClosedOnLoad

  const [showAddButton, setShowAddButton] = useState(true)
  const [firstLoad, setFirstLoad] = useState(true)
  const [filterPanelOpen, setFilterPanelOpen] = useState(filterOpenInitialState)

  useEffect(() => {
    const iw = window.innerWidth
    setFirstLoad(false)
    if (iw < windowSizes.md || (iw > windowSizes.md && iw < windowSizes.lg && angularPanelOpen)) {
      setFilterPanelOpen(false)
    }
  }, [])

  useEffect(() => {
    const iw = window.innerWidth
    if (editPanelOpen && !firstLoad) {
      if (iw < windowSizes.xl && iw > windowSizes.lg && filterPanelOpen && angularPanelOpen) {
        closeAngularSettingsPanel()
      }
      if (iw < windowSizes.lg && angularPanelOpen) {
        closeAngularSettingsPanel()
      }
      if (iw < windowSizes.lg && filterPanelOpen) {
        setFilterPanelOpen(false)
      }
    }

    setShowAddButton(iw > windowSizes.md || !editPanelOpen)
  }, [editPanelOpen])

  useEffect(() => {
    if (angularPanelOpen && !firstLoad) {
      const iw = window.innerWidth
      if (iw < windowSizes.xl && iw > windowSizes.lg && filterPanelOpen && editPanelOpen) {
        setFilterPanelOpen(false)
      }
      if (iw < windowSizes.lg && filterPanelOpen) {
        setFilterPanelOpen(false)
      }
      if (iw < windowSizes.lg && editPanelOpen) {
        closeEditPanel()
      }
    }
  }, [angularPanelOpen])

  useEffect(() => {
    if (filterPanelOpen && !firstLoad) {
      const iw = window.innerWidth
      if (iw < windowSizes.xl && iw > windowSizes.lg && editPanelOpen && angularPanelOpen) {
        closeAngularSettingsPanel()
      }
      if (iw < windowSizes.lg && angularPanelOpen) {
        closeAngularSettingsPanel()
      }
      if (iw < windowSizes.lg && editPanelOpen) {
        closeEditPanel()
      }
    }
  }, [filterPanelOpen])

  const setFilterPanelOpenState = (open: boolean): void => {
    const iw = window.innerWidth
    if (open && iw < windowSizes.lg && checkEditPanelDirty()) return
    setFilterPanelOpen(open)
  }

  return { showAddButton, filterPanelOpen, setFilterPanelOpenState }
}
