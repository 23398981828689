import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const CaretRight = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="CaretRight" {...props}>
    <path d="M29.511 22.733a2.453 2.453 0 0 1 0 3.534l-8.358 8.043c-1.558 1.5-4.153.395-4.153-1.768V16.457c0-2.162 2.595-3.266 4.153-1.767l8.358 8.043Z" fill="#000" />
  </StyledIcon>
))

CaretRight.displayName = 'CaretRight'

export { CaretRight }
