// @ts-nocheck
import { IDraggableConfig } from './IDraggableConfig'
import { observable, action } from 'mobx'

export class DraggablePresenter {
  @observable
  public id: string

  @observable
  public getContent: (draggableConfig: IDraggableConfig) => React.ReactElement

  @observable
  public draggableConfig: IDraggableConfig

  @action
  public withId = (id: string) => {
    this.id = id
    return this
  }

  @action
  public withGetContent = (getContent: (draggableConfig: IDraggableConfig) => React.ReactElement) => {
    this.getContent = getContent
    return this
  }

  @action
  public withDraggableConfig = (draggableConfig: any) => {
    this.draggableConfig = draggableConfig
    return this
  }
}
