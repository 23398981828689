// @ts-nocheck
import { observable, action } from 'mobx'
import { TAlertState } from './TAlertState'
import { TIcons } from 'Components/Atoms'
import { TThemeMode } from 'app/Shared/Themes/TThemeModes'

export class AlertPresenter {
  @observable
  public state: TAlertState

  @observable
  public iconName?: TIcons

  @observable
  public iconPosition?: 'left'

  @observable
  public iconSize = '24px'

  @observable
  public theme: TThemeMode = 'danger'

  @action
  public withIconName?(iconName: TIcons) {
    this.iconName = iconName
    return this
  }

  @action
  public withState?(state: TAlertState) {
    this.state = state
    return this
  }

  @action
  public withTheme?(theme: TThemeMode) {
    this.theme = theme
    return this
  }
}
