import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const BrandCloudNative = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 100 101" a11yTitle="BrandCloudNative" {...props}>
    <g clipPath="url(#a)" fill="#fff"><path opacity=".5" d="m64.402 65.333-35.735 35.334h71.469L64.402 65.333Z" /><path d="M64.667 33c0 17.857-14.477 32.333-32.334 32.333C14.476 65.333 0 50.857 0 33S14.476.667 32.333.667C50.191.667 64.667 15.143 64.667 33Zm-45.67 0c0 7.366 5.97 13.337 13.336 13.337C39.7 46.337 45.67 40.366 45.67 33S39.7 19.663 32.333 19.663c-7.365 0-13.337 5.971-13.337 13.337ZM100 33c0 9.757-7.91 17.667-17.667 17.667-9.757 0-17.666-7.91-17.666-17.667 0-9.757 7.91-17.666 17.666-17.666C92.09 15.334 100 23.244 100 33Zm-24.954 0a7.287 7.287 0 1 0 14.574 0 7.287 7.287 0 0 0-14.574 0Z" /></g>
  </StyledIcon>
))

BrandCloudNative.displayName = 'BrandCloudNative'

export { BrandCloudNative }
