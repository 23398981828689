// @ts-nocheck
import { colors, px2rem } from './Variables'

const DropDownIndicator = provided => ({
  ...provided,
  display: 'none',
  cursor: 'pointer'
})

const Control = (provided, state) => {
  const multiSelectComponent = state.selectProps.multiSelect
  const iconPrefix = state.selectProps.iconPrefix
  return {
    ...provided,
    '&:hover': {
      borderColor: 'transparent',
      boxShadow: 'none'
    },
    boxShadow: 'none',
    borderColor: 'transparent',
    backgroundColor: 'transparent',
    paddingLeft: multiSelectComponent && iconPrefix ? px2rem(32) : '0'
  }
}

const Menu = provided => ({
  ...provided,
  zIndex: 3,
  border: 'none',
  boxShadow: '2px 2px 12px ' + colors.primaryGreyHoverBackgroundColor,
  marginTop: 0
})

const IndicatorSeparator = () => ({
  display: 'none'
})

const NoOptionsMessage = provided => ({
  ...provided,
  textAlign: 'left'
})

const Option = (provided, state) => ({
  ...provided,
  color: colors.textLight,
  backgroundColor: state.isFocused && colors.bgLightest,
  '&:hover': {
    color: colors.text,
    backgroundColor: 'unset',
    cursor: 'pointer'
  }
})

const SingleValue = (provided, state) => ({
  ...provided,
  color: colors.text
})

export const SelectStyle = {
  clearIndicator: provided => ({
    ...provided,
    fontSize: 12,
    color: colors.textLightest,
    position: 'relative',
    left: 10,
    cursor: 'pointer'
  }),
  dropdownIndicator: DropDownIndicator,
  container: provided => ({
    ...provided,
    width: '100%'
  }),
  control: Control,
  indicatorSeparator: IndicatorSeparator,
  noOptionsMessage: NoOptionsMessage,
  loadingMessage: provided => ({
    ...provided,
    textAlign: 'left'
  }),
  loadingIndicator: provided => ({
    display: 'none'
  }),
  menu: Menu,
  option: Option,
  singleValue: SingleValue
}

export const MultiSelectStyle = {
  dropdownIndicator: provided => ({
    ...provided,
    display: 'none',
    cursor: 'pointer'
  }),
  container: provided => ({
    ...provided,
    width: '100%'
  }),
  placeholder: provided => ({
    ...provided,
    color: colors.secondaryGrey
  }),
  control: Control,
  indicatorSeparator: IndicatorSeparator,
  noOptionsMessage: NoOptionsMessage,
  menu: provided => ({ ...Menu(provided), zIndex: 4, overflow: 'hidden' }),
  option: (provided, state) => ({ ...Option(provided, state), height: px2rem(32) })
}
