import { useMemo } from 'react'
import { differenceInMilliseconds } from 'date-fns'
import { unescape } from 'lodash'

import { DashboardComponent, DashboardStreamData } from '../../types'
import {
  Run,
  RunbookListRunbook,
  RunbookShowRunbook,
  RunbookVersion,
  StreamListStream,
  TaskListTask,
  TaskType
} from 'main/services/queries/types'
import { sort } from 'main/services/tasks/sort'

const STAGE_TYPES = ['default', 'startable', 'in-progress', 'complete'] as const
type StageType = (typeof STAGE_TYPES)[number]

// TODO: use me
// type TaskFilters = {
//   stage?: StageType
//   task_type_id?: number
// }

type UseFilteredTaskListDataProps = {
  tasks: TaskListTask[]
  streams: StreamListStream[]
  runbook: RunbookListRunbook | RunbookShowRunbook
  taskTypes: TaskType[]
  filters: DashboardComponent['filters']
  limit?: number
}

export const useTaskListData = ({
  tasks,
  streams,
  runbook,
  taskTypes,
  filters,
  limit
}: UseFilteredTaskListDataProps) => {
  return useMemo(() => {
    return getTaskListData({ tasks, streams, runbook, taskTypes, filters, limit })
  }, [tasks, filters, runbook, taskTypes, streams, limit])
}

export const getTaskListData = ({
  tasks,
  streams,
  runbook,
  taskTypes,
  filters,
  limit = 25
}: UseFilteredTaskListDataProps) => {
  let filtered = tasks
  if (filters?.hasOwnProperty('task_type_id')) {
    filtered = filtered.filter(task => task.task_type_id === filters.task_type_id)
  }
  if (filters?.hasOwnProperty('stage')) {
    filtered = filtered.filter(task => task.stage === filters.stage)
  }

  filtered = sort(filtered)

  const limitedTasks = filtered.slice(0, limit)
  return {
    ...getTaskTableFields({ tasks: limitedTasks, runbook, taskTypes, streams, filters }),
    isMore: filtered.length > limit
  }
}

export type TaskListRowData = {
  id: number
  stage: StageType
  completionType: TaskListTask['completion_type']
  runType: Run['run_type']
  versionStage: RunbookVersion['stage']
  startFixed: number
  title: {
    icon: TaskType['icon']
    color: string
    text: string
    subText: string
    hasComments: boolean
  }
  start?: {
    value: number
    started: boolean
    diff?: {
      value: number
      early: boolean
    }
  }
  finish: {
    value: number
    finished: boolean
    diff?: {
      value: number
      early: boolean
    }
  }
  duration?: {
    value: number
    diff?: {
      value: number
      early: boolean
    }
  }
}

const getTaskTableFields = ({
  tasks,
  taskTypes,
  runbook,
  streams,
  filters
}: UseFilteredTaskListDataProps): { isAutoFinish: boolean; rows: TaskListRowData[] } => {
  const isAutoFinish = filters ? getAutoFinishFromFilter(filters, taskTypes) : false

  // corresponding angular: task_table_helper_factory.js addTaskDataToVm
  const rows = tasks.map(task => {
    const isStarted = ['in-progress', 'complete'].includes(task.stage)
    const startDisplay = task.start_display
    const startFixed = task.start_fixed
    const startPlanned = task.start_latest_planned || startDisplay // or is it task.start_latest_planned || task.start_planned
    const startDiffAmount = isStarted ? differenceInMilliseconds(startPlanned, startDisplay) : undefined
    const startDiff = !startDiffAmount
      ? undefined
      : {
          value: Math.abs(startDiffAmount),
          early: startDisplay < startPlanned
        }

    const isFinished = task.stage === 'complete'
    const endDisplay = task.end_display
    const endPlanned = task.end_display - ((task.start_latest_planned || 0) + task.duration)
    const endDiffAmount = isFinished ? endPlanned : undefined
    const endDiff = !endDiffAmount
      ? undefined
      : {
          value: Math.abs(endDiffAmount),
          early: endDiffAmount < 0
        }
    const durationDisplay = differenceInMilliseconds(endDisplay, startDisplay)
    const durationDiffAmount = getDurationDiff(task) - task.duration
    const durationDiff = !durationDiffAmount
      ? undefined
      : {
          value: Math.abs(durationDiffAmount),
          early: durationDiffAmount < 0
        }

    return {
      id: task.id,
      stage: task.stage,
      completionType: task.completion_type,
      title: {
        icon: getTaskTypeIconForTask(task, taskTypes),
        color: getStreamColorForTask(task, streams),
        text: task.name,
        subText: `#${task.internal_id} Stream: ${getStreamNameForTask(task, streams)}`,
        hasComments: task.comments_count > 0
      },
      runType: runbook.current_version?.run?.run_type,
      versionStage: runbook.current_version?.stage,
      startFixed,
      start: !isAutoFinish
        ? {
            value: startDisplay,
            started: isStarted,
            diff: startDiff
          }
        : undefined,
      finish: {
        value: endDisplay,
        finished: isFinished,
        diff: endDiff
      },
      duration: !isAutoFinish
        ? {
            value: durationDisplay,
            diff: durationDiff
          }
        : undefined
    } as TaskListRowData
  })

  return {
    isAutoFinish,
    rows
  }
}

const getStreamNameForTask = (task: TaskListTask, streams: DashboardStreamData[]): string => {
  const taskStream = getTaskStream(task, streams)

  if (!taskStream) {
    return ''
  }

  return unescape(taskStream.name)
}

const getTaskTypeIconForTask = (task: TaskListTask, taskTypes: TaskType[]): TaskType['icon'] => {
  const filteredType = taskTypes.filter((taskType: TaskType) => task.task_type_id === taskType.id)

  if (filteredType.length === 0) {
    return 'icon_normal'
  }

  return filteredType[0].icon
}

const getStreamColorForTask = (task: TaskListTask, streams: DashboardStreamData[]): string => {
  const taskStream = getTaskStream(task, streams)

  if (!taskStream) {
    return ''
  }

  return taskStream.color
}

const getTaskStream = (task: TaskListTask, streams: DashboardStreamData[]): DashboardStreamData | undefined => {
  const flattenedStreams = streams.flatMap(stream => [stream, ...(stream.children ?? [])])

  return flattenedStreams.find(stream => stream.id === task.stream_id)
}

// TODO: dont use hasOwnProperty and type the filter
const getAutoFinishFromFilter = (filter: { [key: string]: string | number }, taskTypes: TaskType[]): boolean => {
  let isAutoFinish = false

  if (filter.hasOwnProperty('task_type_id')) {
    // TODO: This logic is repeated in getPrefixFromLookup
    const selectedTaskType: TaskType[] = taskTypes.filter(taskType => taskType.id === filter.task_type_id)

    isAutoFinish = selectedTaskType[0].auto_finish
  }

  return isAutoFinish
}

function getDurationDiff(task: TaskListTask) {
  if (task.stage === 'complete') {
    return task.end_display - task.start_display
  } else if (task.stage === 'in-progress') {
    return Math.max(task.end_display - task.start_display, task.duration)
  } else {
    return task.duration
  }
}
