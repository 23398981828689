// @ts-nocheck
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { Button, ButtonPresenter, ToolTip } from 'Components/Molecules'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'

const ArchiveButtonWrapper = styled.div``

interface IArchiveButton {
  tooltipMessage: string
  archiveCallback: () => void
  singleLineArchiveTooltip?: boolean
  language: ILanguageService
}

export const ArchiveButton = observer<IArchiveButton>(
  ({ tooltipMessage, archiveCallback, singleLineArchiveTooltip, language }) => {
    const archiveButtonPresenter = new ButtonPresenter('', () => {
      archiveCallback()
    })
      .withIconName('trash-o')
      .withDisplayStyle('secondary-invert')
      .withAriaLabel(language.get('common:ariaLabels:archive'))

    return (
      <>
        <ToolTip
          oneLineTooltip={singleLineArchiveTooltip}
          message={tooltipMessage}
          trigger={
            <ArchiveButtonWrapper>
              <Button presenter={archiveButtonPresenter} />
            </ArchiveButtonWrapper>
          }
          position="left center"
        />
      </>
    )
  }
)
