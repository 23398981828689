import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Merge = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Merge" {...props}>
    <path d="M16.63 32.184a6 6 0 1 1-2.426-1.766l3.803-4.974A7.495 7.495 0 0 1 19.465 24a7.495 7.495 0 0 1-1.458-1.444l-3.803-4.974a6 6 0 1 1 2.426-1.766l3.76 4.918a4.5 4.5 0 0 0 3.575 1.766H35.25V17l7 7-7 7v-5.5H23.965a4.5 4.5 0 0 0-3.575 1.767l-3.76 4.917ZM12 33a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm0-24a3 3 0 1 0 0 6 3 3 0 0 0 0-6Z" fill="#000" />
  </StyledIcon>
))

Merge.displayName = 'Merge'

export { Merge }
