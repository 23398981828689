// @ts-nocheck

import { px2rem, colors } from 'app/Styles/Variables'
import { Heading } from 'Components/Atoms'
import { THeadingRank } from 'Components/Atoms/Heading/THeadingRank'

const HeadingExtraStyles = {
  color: `${colors.white}`,
  textAlign: 'center',
  fontweight: 500,
  margin: `${px2rem(12)} ${px2rem(0)} ${px2rem(16)}`
} as CSSProperties

interface IHeadingWrapperProps {
  extraStyles?: CSSProperties
  children: ReactNode
  rank: THeadingRank
  fontWeight?: number
}

export const HeadingWrapper = (props: IHeadingWrapperProps) => (
  <Heading rank={props.rank} fontWeight={props.fontWeight} extraStyles={HeadingExtraStyles}>
    {props.children}
  </Heading>
)
