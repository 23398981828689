import { useMemo } from 'react'

import { useLanguage } from '.'
import { getTimezones } from 'main/services/timezone-util'

export type TimezoneOption = {
  label: string
  value: string
}

export const useTimezones = (
  {
    customFirstOption,
    targetDate
  }: { customFirstOption?: { id: string; name: string } | null; targetDate?: Date | null } = {
    customFirstOption: undefined
  }
) => {
  const { translate } = useLanguage()
  const timezones = useMemo(() => {
    const tzs =
      customFirstOption === null
        ? [...getTimezones(targetDate)]
        : [
            customFirstOption || {
              id: 'default',
              name: translate('dashboard:shareModal:timezoneDefault')
            },
            ...getTimezones(targetDate)
          ]
    return tzs.map(tz => ({ label: tz.name, value: tz.id }))
  }, [targetDate])
  return timezones
}
