import styled from 'styled-components/macro'

import { CheckboxGroup, CheckboxGroupProps } from '../checkbox-group'

const WEEKDAY_PICKER_OPTIONS = [
  { label: 'M', value: 1, a11yTitle: 'Monday' },
  { label: 'T', value: 2, a11yTitle: 'Tuesday' },
  { label: 'W', value: 3, a11yTitle: 'Wednesday' },
  { label: 'T', value: 4, a11yTitle: 'Thursday' },
  { label: 'F', value: 5, a11yTitle: 'Friday' },
  { label: 'S', value: 6, a11yTitle: 'Saturday' },
  { label: 'S', value: 0, a11yTitle: 'Sunday' }
]

export type WeekdayPickerProps = Omit<CheckboxGroupProps, 'direction' | 'custom' | 'options' | 'onCreateOption'>

// TODO: Add i18n support
export const WeekdayPicker = styled(CheckboxGroup).attrs(props => ({
  custom: true,
  fill: props.fill !== false,
  onCreateOption: undefined,
  options: WEEKDAY_PICKER_OPTIONS
}))<WeekdayPickerProps>``
