import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const CaretUp = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="CaretUp" {...props}>
    <path d="M22.733 17.752a2.453 2.453 0 0 1 3.534 0l8.043 8.358c1.5 1.558.395 4.153-1.768 4.153H16.457c-2.162 0-3.266-2.595-1.767-4.153l8.043-8.358Z" fill="#000" />
  </StyledIcon>
))

CaretUp.displayName = 'CaretUp'

export { CaretUp }
