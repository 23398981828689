import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const AlignRight = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="AlignRight" {...props}>
    <path d="M6 9h36v3H6V9Zm19 13.5h17v3H25v-3ZM42 36H15v3h27v-3Z" fill="#000" />
  </StyledIcon>
))

AlignRight.displayName = 'AlignRight'

export { AlignRight }
