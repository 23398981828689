import { Outlet } from 'react-router-dom'

import { AddSnippetConnector } from 'main/connectors/add-snippet-connector'
import { CreateSnippetFromRunbookConnector } from 'main/connectors/create-snippet-from-runbook-connector'
import { DuplicateSnippetsConnector } from 'main/connectors/duplicate-snippets-connector'
import { StartSnippetTaskTypeConnector } from 'main/connectors/start-snippet-task-type-connector'

/**
 * React snippet connectors for the angular page rendered by Outlet.
 * The underlying react components should be moved when the task list is converted to
 */
export const TaskListSnippetConnectors = () => {
  return (
    <>
      <AddSnippetConnector />
      <StartSnippetTaskTypeConnector />
      <DuplicateSnippetsConnector />
      <CreateSnippetFromRunbookConnector />
      <Outlet />
    </>
  )
}
