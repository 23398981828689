import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Expand = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Expand" {...props}>
    <path d="M44.01 4H28.74v3h10.15L27 18.89l2.122 2.12L41.01 9.121v10.151h3V4ZM4 44.272h15.272v-3H9.122l11.888-11.89-2.12-2.12L7 39.15V29H4v15.272Z" fill="#000" />
  </StyledIcon>
))

Expand.displayName = 'Expand'

export { Expand }
