import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const BrandCloudMigration = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 100 101" a11yTitle="BrandCloudMigration" {...props}>
    <g clipPath="url(#a)" fill="#fff"><path fillRule="evenodd" clipRule="evenodd" d="M50 71.333c19.514 0 35.333-15.819 35.333-35.333C85.333 16.486 69.514.667 50 .667 30.486.667 14.667 16.486 14.667 36c0 19.514 15.819 35.333 35.333 35.333Zm0-20.666c8.1 0 14.667-6.567 14.667-14.667S58.1 21.333 50 21.333 35.333 27.9 35.333 36 41.9 50.667 50 50.667Z" /><path d="M20.764 80 0 100.742h41.528L20.764 80Zm58.333 0-20.764 20.742h41.528L79.097 80Z" opacity=".5" /></g>
  </StyledIcon>
))

BrandCloudMigration.displayName = 'BrandCloudMigration'

export { BrandCloudMigration }
