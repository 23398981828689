import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Mail = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Mail" {...props}>
    <path d="M4 18a8 8 0 0 1 8-8h24a8 8 0 0 1 8 8v12a8 8 0 0 1-8 8H12a8 8 0 0 1-8-8V18Zm8-5a5.002 5.002 0 0 0-4.734 3.386l13.203 8.543a6.5 6.5 0 0 0 7.062 0l13.203-8.543A5.002 5.002 0 0 0 36 13H12Zm-5 6.787V30a5 5 0 0 0 5 5h24a5 5 0 0 0 5-5V19.787l-11.84 7.66a9.5 9.5 0 0 1-10.32 0L7 19.787Z" fill="#000" />
  </StyledIcon>
))

Mail.displayName = 'Mail'

export { Mail }
