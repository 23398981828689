import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const TaskTypeDecision100 = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 49 48" a11yTitle="TaskTypeDecision100" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M28.953 4.86c-2.201-3.813-7.705-3.813-9.906 0L3.775 31.312c-2.202 3.813.55 8.579 4.953 8.579h30.544c4.403 0 7.154-4.766 4.953-8.58L28.953 4.86Zm11.808 28.452c.662 1.146-.165 2.579-1.489 2.579H8.728c-1.324 0-2.151-1.433-1.49-2.58L22.512 6.86a1.719 1.719 0 0 1 2.978 0l15.27 26.452Z" fill="#000" /><path d="M0 30 15 4l2.599 1.5-15 26L0 30ZM30.7 5.25l15 26 2.6-1.5-15-26-2.6 1.5Z" fill="#000" />
  </StyledIcon>
))

TaskTypeDecision100.displayName = 'TaskTypeDecision100'

export { TaskTypeDecision100 }
