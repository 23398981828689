import { ReactNode } from 'react'
import { Grommet } from 'grommet'

import { theme as defaultTheme, GlobalStyles } from './theme'

export type UiProviderProps = {
  theme?: typeof defaultTheme
  themeMode?: 'light' | 'dark'
  fullScreen?: boolean
  enableFocusRings?: boolean
  children: ReactNode
  reactApp?: 'default' | 'legacy'
}

export const UiProvider = ({
  theme = defaultTheme,
  themeMode = 'light',
  fullScreen = false,
  enableFocusRings = false,
  children,
  reactApp = 'default'
}: UiProviderProps) => {
  return (
    <Grommet
      id={reactApp === 'default' ? 'page-root' : 'legacy-page-root'}
      css={`
        display: flex;
        overflow: ${fullScreen ? 'hidden' : undefined};
      `}
      options={{ box: { cssGap: true } }}
      theme={theme}
      themeMode={themeMode}
      full={fullScreen}
    >
      <GlobalStyles enableFocusRings={enableFocusRings} />
      {children}
    </Grommet>
  )
}
