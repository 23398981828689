import { AddSnippetModal } from './snippets/add-snippet/add-snippet-modal'
import { LinkTemplateSelectWizard } from './link-template/link-template-select/link-template-select-wizard'
import {
  TaskAbandonConfirmModal,
  TaskActionModal,
  TaskFinishConfirm,
  TaskFixedStartOverride,
  TaskOverride,
  TasksDeleteModal,
  TaskSkip
} from './task-modals'
import { TaskIntegrationAbortModal } from './task-modals/task-integration-abort-modal'
import {
  ActiveAccountModel,
  ActiveRunbookModel,
  ActiveRunbookVersionModel,
  RunbookViewModel,
  TaskModel
} from 'main/data-access'
import { TasksPasteModal } from './task-modals/tasks-paste-modal'
import { ExportTasksModal } from './export-tasks/export-tasks-modal'
import { ShareRunbookDashboardModalWrapper as ShareRunbookDashboardModal } from 'main/components/dashboards/runbook-dashboard/share-runbook-dashboard-modal-wrapper'

export const RunbookProgressionModals = () => {
  const { active: activeModal } = RunbookViewModel.useGet('modal')
  const closeModal = RunbookViewModel.useAction('modal:close')
  const removeAllSelectedIds = RunbookViewModel.useAction('selectedIds:removeAll')

  return (
    <>
      {activeModal?.type === 'snippet-add' && (
        <AddSnippetModal initiallyOpen taskId={activeModal?.id} onClose={closeModal} />
      )}
      {activeModal?.type === 'linked-runbook-add' && (
        <LinkTemplateSelectWizardRecoilWrapper onClose={closeModal} id={activeModal?.id} />
      )}
      {activeModal?.type === 'tasks-delete' && (
        <TasksDeleteModal
          taskIds={activeModal.id}
          open
          onSuccess={() => {
            removeAllSelectedIds()
            closeModal()
          }}
          onClose={() => {
            closeModal()
          }}
        />
      )}
      {activeModal?.type === 'task-action' && <TaskActionModal onClose={closeModal} id={activeModal.id} />}
      {activeModal?.type === 'task-override' && <TaskOverride onClose={closeModal} id={activeModal.id} />}
      {activeModal?.type === 'task-override-fixed-start' && (
        <TaskFixedStartOverride id={activeModal.id} onClose={closeModal} />
      )}
      {activeModal?.type === 'task-finish-confirm' && <TaskFinishConfirm onClose={closeModal} id={activeModal.id} />}
      {activeModal?.type === 'tasks-skip' && <TaskSkip onClose={closeModal} ids={activeModal.id} />}
      {activeModal?.type === 'tasks-paste' && (
        <TasksPasteModal onClose={closeModal} taskId={activeModal.taskId} taskName={activeModal.taskName} />
      )}
      {activeModal?.type === 'task-abandon-confirm' && (
        <TaskAbandonConfirmModal onClose={closeModal} id={activeModal.id} data={activeModal.data} />
      )}
      {activeModal?.type === 'integration-abort' && (
        <TaskIntegrationAbortModal open onClose={closeModal} taskId={activeModal.taskId} name={activeModal.name} />
      )}
      {activeModal?.type === 'tasks-export' && <ExportTasksModal closeModal={closeModal} />}
      {activeModal?.type === 'runbook-dashboard-share' && <ShareRunbookDashboardModal closeModal={closeModal} />}
    </>
  )
}

const LinkTemplateSelectWizardRecoilWrapper = ({ id, onClose }: { onClose: () => void; id: number }) => {
  const task = TaskModel.useGet(id)
  const { slug: accountSlug } = ActiveAccountModel.useGet()
  const runbookId = ActiveRunbookModel.useId()
  const runbookVersionId = ActiveRunbookVersionModel.useId()

  return (
    <LinkTemplateSelectWizard
      onClose={onClose}
      accountId={accountSlug}
      onSubmit={() => {}}
      task={task}
      runbookId={String(runbookId)}
      runbookVersionId={String(runbookVersionId)}
    />
  )
}
