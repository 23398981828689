import { format as formatDate, fromUnixTime, getUnixTime } from 'date-fns'

export const createDateDisplayForScheduled = ({
  startDisplay,
  first,
  previousTaskStartDisplay,
  versionStartDisplay,
  startFixed
}: {
  startDisplay: number | null
  first?: boolean
  previousTaskStartDisplay: number
  versionStartDisplay: number | null
  startFixed: number | null
}) => {
  // Note: task startDisplay is not updated for other users when runbook is scheduled.
  // We need to use versionStartDisplay to display the correct date for other users.
  // The exception is if the first task is startFixed, then we use startDisplay as
  // it is different to versionStartDisplay.
  if (first) {
    const taskDisplayDate = startFixed || startDisplay !== versionStartDisplay ? startDisplay : versionStartDisplay
    return formatDate(fromUnixTime(taskDisplayDate as number), 'd LLL HH:mm')
  }

  let date: string | undefined | null = null,
    month: string | undefined | null = null,
    time: string | undefined | null = null

  ;[date, month, time] = startDisplay
    ? formatDate(fromUnixTime(startDisplay as number), 'd LLL HH:mm').split(' ')
    : [undefined, undefined, undefined]

  // previous task date, month and time
  let pDate: string | undefined | null = null,
    pMonth: string | undefined | null = null,
    pTime: string | undefined | null = null

  ;[pDate, pMonth, pTime] = previousTaskStartDisplay
    ? formatDate(fromUnixTime(previousTaskStartDisplay as number), 'd LLL HH:mm').split(' ')
    : [undefined, undefined, undefined]

  let timeContent: string | undefined
  if (pDate === date && pMonth === month && pTime === time) {
    timeContent = undefined
  } else if (pDate === date && pMonth === month) {
    timeContent = time
  } else if (startDisplay) {
    timeContent = formatDate(fromUnixTime(startDisplay as number), 'd LLL HH:mm')
  }

  return timeContent
}

export const createDateDisplayForUnscheduled = ({
  first,
  taskStartDate, // is in seconds
  runbookStartPlanned, // also in seconds
  previousStartFixed,
  previousStartDisplay,
  dayLabel
}: {
  first?: boolean
  taskStartDate: number | null | undefined
  runbookStartPlanned: number | null | undefined
  previousStartFixed?: number | null
  previousStartDisplay: number
  dayLabel?: string
}) => {
  const previousTaskStartDate = previousStartFixed || previousStartDisplay

  if (taskStartDate && runbookStartPlanned) {
    const currentTaskDay = calculateDayNumber({ taskValue: taskStartDate, dayZeroValue: runbookStartPlanned })

    const currentTaskTimeStamp = formatDate(new Date(taskStartDate * 1000), 'HH:mm')
    const firstTaskTimeStamp = formatDate(new Date(runbookStartPlanned * 1000), 'HH:mm')

    // Note: See the comment above. To ensure that the calculated dates are correct we
    // need to use updated values for the first task and not use the taskStartDate as
    // it's not updated via websocket for other users. The exception is startFixed.
    if (first) {
      return previousStartFixed
        ? `${dayLabel} ${currentTaskDay} ${currentTaskTimeStamp}`
        : `${dayLabel} 1 ${firstTaskTimeStamp}`
    }

    if (previousTaskStartDate) {
      const previousTaskTimeStamp = previousTaskStartDate && formatDate(new Date(previousTaskStartDate * 1000), 'HH:mm')
      const previousTaskDay = calculateDayNumber({
        taskValue: previousTaskStartDate,
        dayZeroValue: runbookStartPlanned
      })
      return `${previousTaskDay !== currentTaskDay ? `${dayLabel} ${currentTaskDay} ` : ''}${
        previousTaskTimeStamp !== currentTaskTimeStamp ? currentTaskTimeStamp : ''
      }`
    }
  }
}

export const calculateDayNumber = ({ taskValue, dayZeroValue }: { taskValue: number; dayZeroValue: number }) => {
  const SECONDS_IN_DAY = 60 * 60 * 24
  return (
    Math.floor(
      (getUnixTime(new Date(taskValue * 1000)) - getUnixTime(new Date(dayZeroValue * 1000))) / SECONDS_IN_DAY
    ) + 1
  )
}
