// @ts-nocheck
import { container } from 'app/Config/IOC'
import { observable, action, computed } from 'mobx'
import { injectable } from 'inversify'

import { ErrorAlertPresenter } from 'Components/Molecules/Alert/ErrorAlertPresenter'
import { GenericFormPresenter } from '@logicroom/validator'
import { ILanguageService } from 'Helpers/Language/ILanguageService'
import { TextInputPresenter } from 'Components/Molecules/TextInput'
import { ToasterService } from 'Components/Molecules/Toaster/ToasterService'
import { Types } from 'Gateways/Service/Types'
import { UserAppTokenApplicationPresenter } from 'app/Access/Users/UsersPage/UserAppTokenApplicationPresenter'
import { UserAppTokenRepository } from 'app/Repositories/User/UserAppTokenRepository'

@injectable()
export class UserEditUserAppTokensAddPresenter {
  private userAppTokenApplicationPresenter: UserAppTokenApplicationPresenter = container.get(
    UserAppTokenApplicationPresenter
  )
  private userAppTokenRepository: UserAppTokenRepository = container.get(UserAppTokenRepository)
  public language: ILanguageService = container.get(Types.ILanguageService)
  public toasterGateway: ToasterService = container.get(ToasterService)

  @observable
  public createdToken: string

  @observable
  public isSubmitted = false

  @observable
  public errorAlertPresenter: ErrorAlertPresenter = new ErrorAlertPresenter()

  @observable
  public postContinueCallback?: () => void

  @observable
  public labelPresenter = new TextInputPresenter()
    .withLabel(this.language.get('userAppTokens:add:fieldLabel:label'))
    .isRequiredTrimWhiteSpace(this.language.get('userAppTokens:add:fieldLabel:errors:required'))
    .ofMaxLength(64)

  @observable
  public userAppTokenAddForm = new GenericFormPresenter().addFormInput(this.labelPresenter)

  @computed
  public get continued(): boolean {
    return this.userAppTokenApplicationPresenter.continued
  }

  @computed
  public get submitSucceeded(): boolean {
    return this.userAppTokenApplicationPresenter.submitSucceeded
  }

  @computed
  public get submitted(): boolean {
    return this.userAppTokenApplicationPresenter.submitted
  }

  @action
  public processContinue = () => {
    if (this.postContinueCallback) this.postContinueCallback()
    this.userAppTokenRepository.loadData()
    this.userAppTokenApplicationPresenter.reset()
  }

  @action
  public submit = async () => {
    this.isSubmitted = true
    this.userAppTokenAddForm.serverErrors = []
    this.errorAlertPresenter.setErrorMessages([])

    if (!this.userAppTokenAddForm.isValid) {
      this.labelPresenter.isDirty = true
      this.errorAlertPresenter.setErrorMessages([this.language.get('common:formInvalid')])
      return this.processFailure()
    }

    const userAppTokenDto = {
      user_app_token: {
        label: this.labelPresenter.value
      }
    }

    const response = await this.userAppTokenRepository.createToken(userAppTokenDto)

    if (!response.success) {
      if (response.errorMessages) {
        this.errorAlertPresenter.setErrorMessages(response.errorMessages)
      }

      return this.processFailure()
    }

    this.createdToken = response.body.user_app_token.token
    this.userAppTokenApplicationPresenter.setSubmitSucceeded(true)

    this.toasterGateway.pop(
      this.language.get('userAppTokens:add:toaster:title'),
      this.language.get('userAppTokens:add:toaster:notification'),
      'success'
    )
    return
  }

  private processFailure = (): boolean => {
    setTimeout(() => {
      this.userAppTokenApplicationPresenter.submitted = false
    }, 500)

    return false
  }

  @action
  public withPostContinueCall = (call: () => void) => {
    this.postContinueCallback = call
    return this
  }
}
