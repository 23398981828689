// @ts-nocheck
import { observable, computed, action } from 'mobx'
import { container } from 'app/Config/IOC'
import { AuthenticationRepository } from 'app/Authentication/AuthenticationRepository'
import { UserRepository } from 'app/Repositories/User/UserRepository'
import { ButtonPresenter, ToasterService } from 'Components/Molecules'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'
import { Types } from 'Gateways/Service/Types'

export class UserEditResendInvitePresenter {
  private userRepository = container.get(UserRepository)
  private authenticationRepository = container.get(AuthenticationRepository)
  private language: ILanguageService = container.get(Types.ILanguageService)
  private sendLabel = this.language.get('users:form:invitation:firstSend').toUpperCase()
  private reSendLabel = this.language.get('users:form:invitation:reSend').toUpperCase()
  private sentLabel = this.language.get('users:form:invitation:sent').toUpperCase()

  @observable
  public buttonPresenter = new ButtonPresenter(this.reSendLabel, () => {
    this.processInvite()
  })
    .withDisplayStyle('primary-grey')
    .withTextTransform('Uppercase')

  public toasterGateway = container.get(ToasterService)

  @computed
  public get showInvite(): boolean {
    if (this.userRepository.userForEdit && !this.noSendInvite) {
      return !!!this.userRepository.userForEdit.invitationAcceptedAt
    }

    return false
  }

  @computed
  public get noSendInvite(): boolean {
    return this.userRepository.userForEdit?.provider === 'none'
  }

  @action
  public reset = () => {
    const { userForEdit } = this.userRepository
    const invitationSentAt = userForEdit && userForEdit.invitationSentAt

    this.buttonPresenter.initialText = invitationSentAt ? this.reSendLabel : this.sendLabel
    this.buttonPresenter.disabled = this.noSendInvite
  }

  @action
  private processInvite = async () => {
    const response = await this.authenticationRepository.resendInvitation({
      id: this.userRepository.userForEdit.id,
      uniqueId: this.userRepository.userForEdit.uniqueId,
      email: this.userRepository.userForEdit.email
    })

    if (response.success) {
      this.buttonPresenter.initialText = this.sentLabel
      this.buttonPresenter.disabled = true
      const title = this.language.get('common:notification:successTitle')
      const notification = this.language.get('users:form:resendInvitationNotification:message')
      this.toasterGateway.pop(title, notification, 'success')
    }
  }
}
