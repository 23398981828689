import { useMemo, useState } from 'react'

import { Box, Checkbox, Message, Modal, Text, useNotify } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { FrontendUserSettingType } from 'main/services/queries/types'
import { updateFrontendUserSetting } from 'main/services/queries/use-frontend-user-setting'
import { useModalCheckTaskStageChanged } from './use-modal-check-task-stage-changed'
import {
  ActiveRunbookModel,
  CurrentUserModel,
  RunbookViewModel,
  TaskModel,
  TaskProgressionViewModel
} from 'main/data-access'

type TaskFinishConfirmProps = {
  id: number
  onClose: () => void
}

export const TaskFinishConfirm = ({ id, onClose }: TaskFinishConfirmProps) => {
  const { t } = useLanguage('tasks', { keyPrefix: 'taskFinishConfirmModal' })
  const getNextModalSync = TaskProgressionViewModel.getCallbackSync('nextModal')
  const progressTask = TaskModel.useAction('progress')
  const continueModal = RunbookViewModel.useAction('modal:continue')
  const [userPreferenceHideModal, setUserPreferenceHideModal] = useState(false)

  const runbook = ActiveRunbookModel.useGet()
  const currentUser = CurrentUserModel.useGet()
  const task = TaskModel.useGet(id)

  const frontEndUserSetting: FrontendUserSettingType = currentUser?.frontend_user_setting || {}
  const updateFrontendUserSettingState = CurrentUserModel.useUpdateFrontendUserSettings()
  const notify = useNotify()

  const nextModal = useMemo(
    () => getNextModalSync(id, { from: { id, type: 'task-finish-confirm' } }),
    [getNextModalSync, id]
  )

  const handleUserPreferenceUpdate = async () => {
    const payload: FrontendUserSettingType = {
      type: 'FrontendUserSetting',
      data: {}
    }
    const hiddenRunbooks = [...(frontEndUserSetting.data?.task_finish_confirm_hidden || [])]
    hiddenRunbooks.push(runbook.id)
    payload.data = { ...frontEndUserSetting.data, task_finish_confirm_hidden: hiddenRunbooks }

    if (frontEndUserSetting.id) {
      payload.id = frontEndUserSetting.id
    }

    try {
      const response = await updateFrontendUserSetting(payload)
      if (response && response?.user_setting) {
        updateFrontendUserSettingState({ payload: response.user_setting.data })
      }
    } catch {
      notify.error(t('userPreferenceError'))
    }
  }

  const {
    handleSubmit,
    handleClose,
    confirmIcon,
    confirmText,
    errors: stageChangedErrors,
    renderContent,
    hasCancelButton
  } = useModalCheckTaskStageChanged({
    task,
    onSubmit: async () => {
      nextModal ? continueModal(nextModal, { id, type: 'task-finish-confirm' }) : progressTask(id)
      if (userPreferenceHideModal) await handleUserPreferenceUpdate()
    },
    onClose,
    confirmIcon: 'chevron-right',
    confirmText: t('confirmText')
  })

  return (
    <Modal
      data-testid="modal-task-finish-confirm"
      animate={nextModal ? 'in' : true}
      title={t('title')}
      confirmText={confirmText as string}
      confirmIcon={confirmIcon}
      hasCancelButton={hasCancelButton}
      open
      key="task-finish-confirm-form"
      onClickConfirm={handleSubmit as any}
      onClose={handleClose}
    >
      {stageChangedErrors && <Message margin type="error" message={stageChangedErrors} />}
      {renderContent(
        <Box direction="column" gap="medium">
          <Text>{t('content')}</Text>
          <Checkbox label={t('doNotShowButton')} onChange={e => setUserPreferenceHideModal(e.currentTarget.checked)} />
        </Box>
      )}
    </Modal>
  )
}
