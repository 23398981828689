import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { MyCutoverPermissionsType } from '../queries/types'
import { useCurrentUser } from 'main/recoil/data-access/data-access-hooks__TEMPORARY'
import { CurrentUserModel } from 'main/data-access'

export const useHasPermissionsOrNavigateHome = (scope: MyCutoverPermissionsType) => {
  useCurrentUser() // only calling to resolve dependency on temp data access, will be resolved when we deprecate that file and/or find a separate solution for this hook
  const currentUser = CurrentUserModel.useGet()

  const navigate = useNavigate()
  const userCanViewPage = currentUser.permissions.views.mycutover[scope]

  useEffect(() => {
    if (!userCanViewPage) {
      navigate('/app/my-cutover')
    }
  }, [userCanViewPage])

  return userCanViewPage
}
