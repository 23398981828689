// @ts-nocheck
import { observable, action, computed } from 'mobx'
import { injectable } from 'inversify'
import { GenericListRepository } from 'app/Repositories/GenericListRepository'
import { IBaseResponse } from 'Gateways/Service/IBaseResponse'
import { ISortParams } from 'Components/Organisms'
import { TDownloadFormats } from 'app/Shared/Entities/Types/TDownloadFormats'

@injectable()
export class RunbookUsersRepository extends GenericListRepository {
  @computed
  protected get apiPath() {
    return `runbooks/${this.runbookId}/runbook_versions/${this.runbookVersionId}`
  }

  @computed
  protected get apiUrl() {
    return `${this.apiPath}/all_users${this.queryStringBuilder.getQueryString(
      this.offSet,
      this.sortParams,
      this.batchLoadNumber
    )}`
  }

  protected downloadApiUrl(format: string) {
    return `${this.apiPath}/all_users_${format.toLowerCase()}${this.queryStringBuilder.getQueryString(
      null,
      this.sortParams
    )}`
  }

  public batchLoadNumber: number = 40

  @observable
  public users: any = []

  @observable
  public offSet: number = 0

  @observable
  public runbookId: number

  @observable
  public runbookVersionId: number

  @observable
  public downloading = false

  @action
  public loadMore = async (offSet: number, sortParams: ISortParams) => {
    this.offSet = offSet
    this.sortParams = sortParams
    const { body } = await this.serviceGateway.get(this.apiUrl)
    const newUsers = body.runbook_users.map(userDto => {
      return this.mapUserDto(userDto)
    })
    this.users = this.users.concat(newUsers)
  }

  @action
  public preLoad = (params: { [key: string]: any } = {}): string => {
    const { runbookId, runbookVersionId } = params
    this.runbookId = runbookId ? runbookId : this.runbookId
    this.runbookVersionId = runbookVersionId ? runbookVersionId : this.runbookVersionId
    this.offSet = params && params.offSet != null ? params.offSet : 0
    if (params && params.sortParams != null) this.sortParams = params.sortParams
    return this.apiUrl
  }

  @action
  public postLoad = (rawData: IBaseResponse) => {
    this.users = rawData.body.runbook_users.map(userDto => {
      return this.mapUserDto(userDto)
    })

    this.listTotal = rawData.body.meta.total_count
    this.filteredListTotal = rawData.body.meta.total_filtered_count
  }

  @action
  public download = async (format: TDownloadFormats) => {
    if (this.downloading) return
    this.downloading = true
    const response = await this.serviceGateway.get(this.downloadApiUrl(format), {}, {}, { responseType: 'arraybuffer' })
    this.downloading = false
    if (!response.success) throw new Error('Invalid download response received')
    return response.body
  }

  private mapUserDto = userDto => {
    const user = {
      id: userDto.id,
      color: userDto.color,
      firstName: userDto.first_name,
      lastName: userDto.last_name,
      mobileNumberNormalized: userDto.mobile_number_normalized,
      email: userDto.email,
      streams: userDto.streams || [],
      runbookTeams: userDto.runbook_teams || [],
      runbookAdmin: userDto.runbook_admin,
      inAccount: userDto.in_account,
      registered: userDto.registered,
      acceptEmail: userDto.pref_email,
      acceptSMS: userDto.pref_sms
    }
    return user
  }

  @action
  public clear = () => {
    this.clearInherited()
  }
}
