import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Edit = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Edit" {...props}>
    <path d="M18.182 29.87h6.364L45.052 9.364 38.688 3 18.182 23.506v6.364Zm3-3v-2.12L38.688 7.243l2.121 2.121L23.303 26.87h-2.121Z" fill="#000" /><path d="M14 6h10v3H14a5 5 0 0 0-5 5v20a5 5 0 0 0 5 5h20a5 5 0 0 0 5-5V24h3v10a8 8 0 0 1-8 8H14a8 8 0 0 1-8-8V14a8 8 0 0 1 8-8Z" fill="#000" />
  </StyledIcon>
))

Edit.displayName = 'Edit'

export { Edit }
