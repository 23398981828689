import { ExtendedCustomField } from 'main/recoil/runbook'
import {
  useGetCustomField,
  useGetCustomFieldCallback,
  useGetCustomFieldLookup,
  useGetCustomFields,
  useGetCustomFieldsCallback,
  useGetCustomFieldsLookupCallback
} from '../hooks'
import { CollectionModelInterfaceBase } from './types'

export type CustomFieldModelType = CollectionModelInterfaceBase<ExtendedCustomField>

export const CustomFieldModel: CustomFieldModelType = {
  useGet: useGetCustomField,
  useGetCallback: useGetCustomFieldCallback,

  useGetAll: useGetCustomFields,
  useGetAllCallback: useGetCustomFieldsCallback,

  useGetLookup: useGetCustomFieldLookup,
  useGetLookupCallback: useGetCustomFieldsLookupCallback
}
