import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Conflicts = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Conflicts" {...props}>
    <path d="M23.01 7.738V23.01H7.738v-3H17.89L4 6.121 6.121 4l13.89 13.89V7.737h3ZM25 40.272V25h15.272v3h-10.15L44.01 41.89l-2.12 2.12L28 30.121v10.151h-3Zm2.064-20.699L36.715 14l1.5 2.598-9.652 5.573-1.5-2.598ZM12.5 32.475l8.855-5.112-1.5-2.598L11 29.877l1.5 2.598ZM27 13.823 28.292 9l2.898.776-1.292 4.824L27 13.823Zm-6.076 23.653 1.292-4.823-2.898-.777-1.292 4.823 2.898.777Z" fill="#000" />
  </StyledIcon>
))

Conflicts.displayName = 'Conflicts'

export { Conflicts }
