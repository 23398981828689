import { useCallback, useMemo, useState } from 'react'

import { IconButtonProps, ViewToggleIconButton } from '@cutover/react-ui'
import { useToggleFilterPanel } from 'main/components/layout/layout-hooks'

export type FilterPanelToggleButtonProps = {
  filterCount?: number
  active?: boolean
  tip?: string
  onClick: IconButtonProps['onClick']
  onKeyUp: IconButtonProps['onKeyUp']
}

export const FilterPanelToggleButton = ({ filterCount, tip }: FilterPanelToggleButtonProps) => {
  const toggleFilterPanel = useToggleFilterPanel()
  const [active, setActive] = useState(document.getElementById('page-root')?.classList.contains('filter-panel-open'))
  const handleClickToggleFilterPanel = useCallback(() => toggleFilterPanel(), [toggleFilterPanel])

  const badgeProp = useMemo(() => {
    if (!filterCount) {
      return undefined
    }
    return {
      type: 'dark' as const,
      label: filterCount,
      'data-testid': 'runbooks-filter-panel-toggle-badge'
    }
  }, [filterCount])

  return (
    <ViewToggleIconButton
      icon="filter"
      label={tip ?? 'Filter'}
      data-testid="runbooks-filter-panel-toggle"
      onKeyUp={e => {
        if (e.key === ' ' || e.key === 'Spacebar') {
          const isOpen = handleClickToggleFilterPanel()
          setActive(isOpen)
        }
      }}
      onClick={() => {
        const isOpen = handleClickToggleFilterPanel()
        setActive(isOpen)
      }}
      badge={badgeProp}
      tipPlacement="top-start"
      isActive={active}
    />
  )
}
