import { useState } from 'react'

import { Box, Select, Text } from '@cutover/react-ui'
import { Component, ComponentFilter, getFilter } from './component-data'
import { AppProviders } from 'main/context/app-providers'
import { useAccountFolders } from 'main/services/queries/use-account-folders'
import { useRunbookTypes } from 'main/services/queries/use-account-runbook-types'

export type DashboardComponentFilterByConnectedProps = {
  accountId?: number
  id?: number
  componentId?: number
  onEvent: (payload: any) => void
  components: Component[]
}

export function DashboardComponentFilterByConnected({
  accountId,
  id,
  componentId,
  components = [],
  onEvent
}: DashboardComponentFilterByConnectedProps) {
  const { resourceId, resourceType } = getFilter({ componentId: id, components })

  const handleFilterValueChange = ({ resourceType, resourceId }: ComponentFilter) => {
    onEvent({
      filter: { id, componentId, attribute: resourceType || null, value: resourceId || null }
    })
  }

  return (
    <AppProviders>
      <DashboardComponentFilterBy
        accountId={accountId}
        attribute={resourceType}
        value={resourceId}
        onChange={handleFilterValueChange}
      />
    </AppProviders>
  )
}

export type DashboardComponentFilterByProps = {
  accountId?: number
  attribute?: string
  value?: number
  onChange: (settings: ComponentFilter) => void
}

type FilterSelectOptionType = {
  value: string
  label: string
}

type FilterSelectValueType = string | number | null

export function DashboardComponentFilterBy({
  accountId,
  attribute: initialAttribute,
  value: initialValue,
  onChange
}: DashboardComponentFilterByProps) {
  const [attribute, setAttribute] = useState<string | undefined>(initialAttribute || 'none')
  const [value, setValue] = useState<number | undefined>(initialValue)

  // https://cutover.atlassian.net/browse/CFE-1554
  //Types can be simplified now and we can expect what we actually get (string).
  const handleAttributeChange = (attr?: string | number | null) => {
    const val = attr ?? null
    setValue(undefined)
    setAttribute(attr?.toString())
    onChange({ resourceType: val === 'none' ? undefined : val?.toString(), resourceId: value })
  }

  const handleValueChange = (val: number) => {
    setValue(val)
    onChange({ resourceType: attribute, resourceId: val })
  }

  return (
    <Box direction="column">
      <Select<FilterSelectOptionType, FilterSelectValueType>
        onChange={handleAttributeChange}
        label="Filter runbooks by"
        placeholder="Select a filter attribute..."
        value={attribute ?? 'none'}
        options={[
          {
            value: 'none',
            label: 'None'
          },
          {
            value: 'Project',
            label: 'Folder'
          },
          {
            value: 'RunbookType',
            label: 'Runbook Type'
          }
        ]}
      />
      {attribute === 'Project' ? (
        <FolderFilter accountId={accountId} value={value} onChange={handleValueChange} />
      ) : attribute === 'RunbookType' ? (
        <RunbookTypeFilter accountId={accountId} value={value} onChange={handleValueChange} />
      ) : null}
    </Box>
  )
}

type FilterValueProps = {
  accountId?: number
  value?: number
  onChange: (value: number) => void
}

function FolderFilter({ accountId, value, onChange }: FilterValueProps) {
  const { isLoading, isError, isSuccess, data } = useAccountFolders({ accountId })

  if (isLoading) {
    return <Text as="p">Loading...</Text>
  }

  if (isError) {
    return <Text as="p">There was an error.</Text>
  }

  if (!isSuccess) {
    return <Text as="p">Loading...</Text>
  }

  return (
    <Select
      value={value ?? ''}
      onChange={val => typeof val !== 'string' && onChange(Number(val))}
      label="Filter value"
      options={(data ?? []).map(d => ({ value: d.id, label: d.name }))}
      placeholder="Select an option..."
    />
  )
}

function RunbookTypeFilter({ accountId, value, onChange }: FilterValueProps) {
  const { isLoading, isError, isSuccess, data } = useRunbookTypes({ accountId })

  if (isLoading) {
    return <Text as="p">Loading...</Text>
  }

  if (isError) {
    return <Text as="p">There was an error.</Text>
  }

  if (!isSuccess) {
    return <Text as="p">Loading...</Text>
  }

  return (
    <Select
      value={value ?? ''}
      onChange={val => typeof val !== 'string' && onChange(Number(val))}
      label="Filter value"
      options={(data ?? []).map(d => ({ value: d.id, label: d.name }))}
      placeholder="Select an option..."
    />
  )
}
