import { Simplify } from 'type-fest'

import { RunbookShowRunbook, RunbookTypeType } from 'main/services/queries/types'
import {
  useActiveRunbookActionHandler,
  useActiveRunbookCan,
  useActiveRunbookId,
  useActiveRunbookIdCallback,
  useActiveRunbookLoadableCallback,
  useActiveRunbookLoadableValue,
  useActiveRunbookRunbookType,
  useActiveRunbookRunbookTypeCallback,
  useGetActiveRunbook,
  useGetActiveRunbookCallback
} from '../hooks'
import { ActiveModelInterfaceBase, ActiveModelInterfaceWithActions as WithActions } from './types'
import { RunbookResponseMetaPermissions } from 'main/services/api/data-providers/runbook-types'

export type ActiveRunbookModelType = Simplify<
  ActiveModelInterfaceBase<RunbookShowRunbook, RunbookResponseMetaPermissions> &
    Omit<WithActions<RunbookShowRunbook, 'Runbook', 'update' | 'merge'>, 'usePermission' | 'usePermissionCallback'> & {
      useRunbookType: () => RunbookTypeType
      useRunbookTypeCallback: () => () => Promise<RunbookTypeType>
    }
>

export const ActiveRunbookModel: ActiveRunbookModelType = {
  useGet: useGetActiveRunbook,
  useGetCallback: useGetActiveRunbookCallback,

  useGetLoadable: useActiveRunbookLoadableValue,
  useGetLoadableCallback: useActiveRunbookLoadableCallback,

  useCan: useActiveRunbookCan,

  useOnAction: useActiveRunbookActionHandler,

  useId: useActiveRunbookId,
  useIdCallback: useActiveRunbookIdCallback,

  useRunbookType: useActiveRunbookRunbookType,
  useRunbookTypeCallback: useActiveRunbookRunbookTypeCallback
}
