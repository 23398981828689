import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import { Box, Card, CardProps, FeatureIcon, Grid } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import {
  CustomFieldsGroupsForm,
  CustomFieldsGroupsFormProps
} from 'main/components/shared/custom-field-form/custom-field-groups-form'
import {
  CheckboxFieldControlled,
  DateTimePickerField,
  FolderSelectField,
  RunbookSelectField,
  TextInputField
} from 'main/components/shared/form'
import { PermittedProject } from 'main/services/queries/use-permitted-resources'
import { CreateRunbookFormType } from './create-runbook-schema'

type CreateRunbookDetailsProps = CustomFieldsGroupsFormProps & {
  folders: PermittedProject[] | undefined
  isTemplate?: boolean
}

export const CreateRunbookDetails = ({
  isTemplate = false,
  folders,
  ...customFieldsProps
}: CreateRunbookDetailsProps) => {
  const { t } = useLanguage('runbooks', { keyPrefix: 'newCreateRunbookModal' })
  const { watch, getValues, setValue, setFocus, reset } = useFormContext<CreateRunbookFormType>()

  const { accountId: accountSlug } = useParams()

  const [isCreateFromTemplate, setIsCreateFromTemplate] = useState<boolean>(true)
  const [isStartScheduled, setIsStartScheduled] = useState<boolean>(false)

  const cardOptions: CardProps[] = [
    {
      title: t('enterDetails.attributes.createOptions.createFromTemplate.title'),
      subtitle: t('enterDetails.attributes.createOptions.createFromTemplate.subTitle'),
      startComponent: <FeatureIcon size="large" icon="template" status={isCreateFromTemplate ? 'idea' : undefined} />,
      selected: isCreateFromTemplate,
      key: 1
    },
    {
      title: isTemplate
        ? t('enterDetails.attributes.createOptions.createBlank.template.title')
        : t('enterDetails.attributes.createOptions.createBlank.runbook.title'),
      subtitle: isTemplate
        ? t('enterDetails.attributes.createOptions.createBlank.template.subTitle')
        : t('enterDetails.attributes.createOptions.createBlank.runbook.subTitle'),
      startComponent: (
        <FeatureIcon size="large" icon="runbook-created" status={!isCreateFromTemplate ? 'idea' : undefined} />
      ),
      selected: !isCreateFromTemplate,
      key: 2
    }
  ]

  // used to ensure correct fields are focused
  useEffect(() => {
    const baseTemplate = getValues('base_template')
    const projectId = baseTemplate?.project?.id

    setValue('runbook.name', baseTemplate ? `${baseTemplate.name} copy` : '')

    if (!!isCreateFromTemplate) {
      if (!!baseTemplate) {
        setFocus('runbook.name')
      } else {
        setFocus('base_template')
      }
    } else {
      setFocus('runbook.name')
    }
    projectId ? setValue('runbook.project_id', projectId) : undefined
  }, [watch('base_template'), isCreateFromTemplate])

  // used to show or hide additional fields based on start scheduled
  useEffect(() => {
    if (!!getValues('runbook.runbook_versions_attributes.0.start_scheduled')) {
      setIsStartScheduled(true)
    } else {
      setIsStartScheduled(false)
    }
  }, [watch('runbook.runbook_versions_attributes.0.start_scheduled')])

  // used to reset the form when switching cards
  useEffect(() => {
    if (getValues('runbook.name') || getValues('base_template')) {
      const runbookTypeId = getValues('runbook.runbook_type_id')
      reset()
      setValue('runbook.runbook_type_id', runbookTypeId)
      setValue('_step', 2)
    }
  }, [isCreateFromTemplate])

  useEffect(() => {
    const defaultFolder = folders?.find(folder => folder.name === 'Default')
    setValue('runbook.project_id', defaultFolder ? defaultFolder.id : folders?.[0]?.id)
  }, [folders, isCreateFromTemplate])

  return (
    <Box>
      <Grid minWidth={200}>
        {cardOptions.map(option => (
          <Card onClick={() => setIsCreateFromTemplate(option.key == 1)} {...option} />
        ))}
      </Grid>

      {isCreateFromTemplate && accountSlug && (
        <RunbookSelectField<CreateRunbookFormType>
          name="base_template"
          label={t('enterDetails.attributes.template.label')}
          accountSlug={accountSlug}
          required={isCreateFromTemplate}
          params={{
            // TODO: for X runbook type only
            limit: 10,
            a: true,
            template_type: 'default',
            fields: ['id', 'name', 'project', 'approval_status']
          }}
        />
      )}

      <TextInputField<CreateRunbookFormType>
        name="runbook.name"
        required
        label={t('enterDetails.attributes.name')}
        data-testid="runbook-name-form-field"
      />

      <FolderSelectField<CreateRunbookFormType>
        label={t('enterDetails.attributes.folderSelect')}
        folders={folders ?? []}
        name="runbook.project_id"
        clearable={false}
        loading={folders === undefined}
      />

      {!isTemplate && (
        <>
          <DateTimePickerField<CreateRunbookFormType>
            fixed
            fixedWidth
            name="runbook.runbook_versions_attributes.0.start_scheduled"
            label={t('enterDetails.attributes.startScheduled')}
          />

          {isStartScheduled && (
            <>
              <DateTimePickerField<CreateRunbookFormType>
                fixed
                fixedWidth
                name="runbook.runbook_versions_attributes.0.end_scheduled"
                label={t('enterDetails.attributes.endScheduled')}
              />

              {isCreateFromTemplate && (
                <CheckboxFieldControlled<CreateRunbookFormType>
                  name="shift_time"
                  label={t('enterDetails.attributes.shiftTime.label')}
                  helpText={t('enterDetails.attributes.shiftTime.helpText')}
                />
              )}
            </>
          )}
        </>
      )}

      {/* TODO add errors={errors} prop and feed through? */}
      <CustomFieldsGroupsForm {...customFieldsProps} />
    </Box>
  )
}
