// @ts-nocheck

import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { colors, px2rem } from 'app/Styles/Variables'
import { OldPillFilterPresenter } from './OldPillFilterPresenter'
import { Icon } from 'Components/Atoms'

const Pill = styled.span`
  display: inline-block;
  background-color: #fff;
  box-sizing: border-box;
  padding: 0 ${px2rem(10)};
  border-radius: ${px2rem(20)};
  line-height: ${px2rem(40)};
  font-size: ${px2rem(14)};
  margin-left: ${px2rem(6)};
  position: relative;
  color: ${colors.textLight};
  white-space: normal;
  vertical-align: middle;
  padding-right: ${px2rem(24)};
`
interface IClickable {
  onClick?: () => void
}

const CloseButton = styled.button<IClickable>`
  display: block;
  position: absolute;
  top: ${px2rem(6)};
  right: ${px2rem(2)};
  padding: 0;
  &:hover {
    cursor: pointer;
  }
`

const IconButton = styled.button<IClickable>`
  display: block;
  position: absolute;
  top: ${px2rem(6)};
  right: ${px2rem(22)};
  padding: 0;
  &:hover {
    cursor: pointer;
  }
`

const IconDiv = styled.div`
  display: block;
  position: absolute;
  top: ${px2rem(2)};
  right: ${px2rem(22)};
  padding: 0;
`

// icon-close
interface IOldPillFilterProps {
  presenter: OldPillFilterPresenter
  customOnClose?: (filterId?: string) => void
  customIconClick?: (filterId?: string) => void
  extraStyles?: CSSProperties
  iconName?: string
}

export const OldPillFilter = observer<IOldPillFilterProps>(
  ({ customOnClose, customIconClick, extraStyles, presenter }) => {
    const { filterId, title, content, filterLevel, iconName } = presenter
    const iconSize = px2rem(16)
    const iconColor = colors.primaryGrey

    const label = filterLevel > 1 ? `Remove ${title} filters` : `Remove ${title === 'Search' ? title : content} filter`

    const displayContent = filterLevel > 1 ? '(' + filterLevel + ')' : content

    let IconRender = () => (
      <Icon name={iconName} size={iconSize} color={iconColor} extraStyles={{ width: px2rem(18) }} />
    )

    let IconContent = () =>
      customIconClick ? (
        <IconButton onClick={customClick}>
          <IconRender />
        </IconButton>
      ) : (
        <IconDiv>
          <IconRender />
        </IconDiv>
      )

    const onClose = () => {
      if (customOnClose) {
        customOnClose(filterId)
      }
    }

    const customClick = () => {
      if (customIconClick) {
        customIconClick(filterId)
      }
    }

    const CloseIconRender = () => (
      <Icon name="close" size={iconSize} color={iconColor} extraStyles={{ width: px2rem(18) }} />
    )

    return (
      <Pill style={extraStyles}>
        {title && `${title}: `} {displayContent} {iconName && <IconContent />}
        <CloseButton onClick={onClose} aria-label={label}>
          <CloseIconRender />
        </CloseButton>
      </Pill>
    )
  }
)
