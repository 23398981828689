import { useNavigate, useParams } from 'react-router-dom'
import { format } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'

import { Box, ColorProp, InfiniteList, ListItem, LoadingPanel, Message, Modal, Pill, Text } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { ActiveRunbookModel } from 'main/data-access'
import { useGetRunbookVersions } from 'main/services/queries/use-runbook-versions'
import { RunbookVersion } from 'main/services/queries/types/runbooks'
import { useRouting } from 'main/services/routing/hooks'

export const ViewHistoryModal = ({ onClose }: { onClose: () => void }) => {
  const { id: runbookId, is_template, timezone } = ActiveRunbookModel.useGet()
  const { t } = useLanguage('runbook', { keyPrefix: 'viewHistoryModal' })
  const navigate = useNavigate()
  const { accountId, runbookVersionId } = useParams()
  const { toRunbook } = useRouting()
  const { data: runbookVersions, isLoading, isError } = useGetRunbookVersions(runbookId)

  const onVersionClick = (runbookVersionId: number) => {
    navigate(toRunbook({ runbookId, versionId: runbookVersionId, accountSlug: accountId as string }))
  }

  return (
    <Modal title={is_template ? t('templateTitle') : t('title')} open onClose={onClose} hasConfirmButton={false}>
      <Text>{t('description')}</Text>
      <Box
        margin={{ top: '10px' }}
        height={isLoading || !runbookVersions ? { min: '50px' } : `${Math.min(runbookVersions.length, 5) * 40}px`}
        css="position: relative;"
      >
        {isError && <Message message={t('getVersionsError')} type="error" />}
        {!isLoading && runbookVersions && runbookVersions.length > 0 && (
          <InfiniteList<RunbookVersion>
            items={runbookVersions}
            renderItem={(index, runbookVersion) => (
              <VersionItem
                key={runbookVersion.id}
                runbookVersion={runbookVersion}
                timezone={timezone as string}
                isTemplate={is_template}
                onClick={onVersionClick}
                active={
                  (runbookVersionId && parseInt(runbookVersionId) === runbookVersion.id) ||
                  (runbookVersionId === 'current_version' && runbookVersion.is_current)
                }
              />
            )}
          />
        )}
        {isLoading && (
          <Box css="position: relative; top: 50px;">
            <LoadingPanel />
          </Box>
        )}
      </Box>
    </Modal>
  )
}

const VersionItem = ({
  runbookVersion,
  timezone,
  isTemplate,
  onClick,
  active
}: {
  runbookVersion: RunbookVersion
  timezone: string
  isTemplate: boolean
  onClick: (runbookVersionId: number) => void
  active: boolean
}) => {
  const { t } = useLanguage('runbook', { keyPrefix: 'viewHistoryModal' })
  const created = format(utcToZonedTime(runbookVersion.created_at * 1000, timezone), 'dd MMM yy HH:mm')
  const { is_current: isCurrent } = runbookVersion
  const runbookVersionStatus = isCurrent
    ? `(${t('current')})`
    : isTemplate
    ? `(v${runbookVersion.template_version})`
    : ''

  const title = `${t('tasksCount', {
    count: runbookVersion.tasks_count
  })}, ${t('streamsCount', {
    count: runbookVersion.streams_count
  })} - ${t('created')} ${created} UTC ${runbookVersionStatus}`

  const STATUS_COLOR_MAP: Record<string, ColorProp> = {
    pending: 'text-light',
    open: 'primary',
    approved: 'success',
    expired: 'warning',
    expiring: 'warning',
    rejected: 'error'
  }

  return (
    <ListItem
      size="medium"
      id={runbookVersion.id}
      title={title}
      onClick={() => onClick(runbookVersion.id)}
      active={active}
      data-testid="runbook-version-item"
      prominence={isCurrent ? 'high' : 'default'}
      endComponents={
        isTemplate ? (
          <Pill
            label={t(`templateStatus.${runbookVersion.approval_status}`) as string}
            color={STATUS_COLOR_MAP[runbookVersion.approval_status as string]}
          />
        ) : null
      }
    />
  )
}
