// @ts-nocheck
import { computed } from 'mobx'
import { inject, injectable } from 'inversify'
import { ConfigsRepository } from 'Shared/Configs/ConfigsRepository'

/**
 * Helper class to manage feature flags, set by the backend.
 */
@injectable()
export class FeatureFlagService {
  @inject(ConfigsRepository)
  private configsRepository: ConfigsRepository

  // Convenience method to retrieve all enabled features
  @computed
  public get enabledFeatures(): string[] {
    return this.configsRepository.enabledFeatures
  }

  // Returns true or false depending whether a feature is enabled or disabled.
  //
  // The parameter must be a feature flag, that is present in the
  // `AVAILABLE_FEATURES` in `app/lib/feature.rb`.
  public isEnabled(feature: string): boolean {
    return this.configsRepository.enabledFeatures.includes(feature)
  }
}
