// @ts-nocheck
import { observable, action } from 'mobx'
import { RadioButtonsPresenter } from 'Components/Atoms'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'
import { container } from 'app/Config/IOC'
import { Types } from 'Gateways/Service/Types'

type TAccountVisibilityType = 'global' | 'account'

interface IAccountVisibilityViewModel {
  type: TAccountVisibilityType
  account?: string
  disabledGlobal: boolean
  disabledAccount: boolean
}

export class AccountVisibilityPresenter {
  private dirtyCallback: () => void

  private middleware: (value: string) => void

  private language: ILanguageService = container.get(Types.ILanguageService)

  @observable
  public viewModel: IAccountVisibilityViewModel = {
    type: 'global',
    disabledGlobal: false,
    disabledAccount: false
  }

  @observable
  public accounts: string[] = []

  @observable
  public showAccounts: boolean = false

  @observable
  public dirty: boolean = false

  private defaultSelectedAccount: string

  @observable
  public visibilityTypePresenter = new RadioButtonsPresenter([
    this.language.get('common:organisms:accountVisibility:fieldType:optionGlobal'),
    this.language.get('common:organisms:accountVisibility:fieldType:optionAccount')
  ])
    .withLabel(this.language.get('common:organisms:accountVisibility:label'))
    .withMiddleware(value => {
      this.dirty = true

      if (this.dirtyCallback) {
        this.dirtyCallback()
      }

      if (this.middleware) {
        this.middleware(value)
      }

      this.viewModel.type =
        value === this.language.get('common:organisms:accountVisibility:fieldType:optionAccount') ? 'account' : 'global'
      this.showAccounts = value === this.language.get('common:organisms:accountVisibility:fieldType:optionAccount')

      if (this.viewModel.type === 'account') {
        this.viewModel.account = this.defaultSelectedAccount
      }

      return value
    })

  @observable
  public accountVisibilityPresenter: RadioButtonsPresenter

  @action
  private setAccountsVisibility = () => {
    this.defaultSelectedAccount = this.accounts[0]
    this.accountVisibilityPresenter = new RadioButtonsPresenter(this.accounts)
      .withLabel(this.language.get('common:organisms:accountVisibility:fieldAccount:label'))
      .withVertical()
      .withMiddleware(value => {
        this.dirty = true

        if (this.dirtyCallback) {
          this.dirtyCallback()
        }

        if (this.middleware) {
          this.middleware(value)
        }

        this.viewModel.account = value
        return value
      })
  }

  @action
  private setSelectedAccount = () => {
    this.accountVisibilityPresenter.selectedOption = this.viewModel.account
    this.visibilityTypePresenter.selectedOption = this.language.get(
      'common:organisms:accountVisibility:fieldType:optionAccount'
    )
    this.showAccounts = true
  }

  @action
  public withViewModel = (viewModel: IAccountVisibilityViewModel) => {
    this.viewModel = viewModel

    if (this.viewModel.account) {
      this.setSelectedAccount()
    } else {
      this.visibilityTypePresenter.selectedOption = this.language.get(
        'common:organisms:accountVisibility:fieldType:optionGlobal'
      )
      this.showAccounts = false
    }

    this.applyDisabledSettings(this.viewModel.disabledGlobal, this.viewModel.disabledAccount)

    return this
  }

  @action
  public applyDisabledSettings = (disabledGlobal: boolean, disabledAccount: boolean) => {
    if (disabledGlobal && disabledAccount) {
      this.visibilityTypePresenter.disabled = true
    } else if (disabledGlobal || disabledAccount) {
      this.visibilityTypePresenter.withDisabledOptions(this.getDisabledOptionsFromViewModel())
      this.checkNonDisabledOption()
    } else {
      this.visibilityTypePresenter.disabled = false
    }
    this.accountVisibilityPresenter.disabled = disabledAccount
  }

  private getDisabledOptionsFromViewModel = () => {
    const disabledOptions = []

    if (this.viewModel.disabledGlobal)
      disabledOptions.push(this.language.get('common:organisms:accountVisibility:fieldType:optionGlobal'))
    if (this.viewModel.disabledAccount)
      disabledOptions.push(this.language.get('common:organisms:accountVisibility:fieldType:optionAccount'))

    return disabledOptions
  }

  private checkNonDisabledOption = () => {
    if (this.viewModel.disabledGlobal)
      this.visibilityTypePresenter.selectedOption = this.language.get(
        'common:organisms:accountVisibility:fieldType:optionAccount'
      )
    if (this.viewModel.disabledAccount)
      this.visibilityTypePresenter.selectedOption = this.language.get(
        'common:organisms:accountVisibility:fieldType:optionGlobal'
      )
  }

  @action
  public withDirtyCallback = (dirtyCallback: () => void) => {
    this.dirtyCallback = dirtyCallback
    return this
  }

  @action
  public withMiddleware = (middleware: (value: string) => void) => {
    this.middleware = middleware
    return this
  }

  @action
  public withAccounts = (accounts: string[]) => {
    this.accounts = accounts.sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' }))
    this.setAccountsVisibility()
    return this
  }

  @action
  public reset = () => {
    this.dirty = false
    this.showAccounts = false
    this.visibilityTypePresenter.reset()
    this.accountVisibilityPresenter.reset()
  }
}
