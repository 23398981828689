import { format as formatDate } from 'date-fns'
import styled, { css } from 'styled-components/macro'

import { Box } from '../../layout'
import { RagIndicator } from '../../rag-indicator'
import { Text } from '../../typography'
import { breakpoints, colors } from '../../utils'

export type BannerRagStatus = 'red' | 'amber' | 'green'
type BannerType = 'runbook' | 'stream' | 'substream'

// In the future we'll need to add an onClickIndicator prop since the browser version is clickable
export type RagStatusBannerProps = {
  type: BannerType
  /** Name for stream and substream type banners */
  name?: string
  status: BannerRagStatus
  message: string
  author: string | null
  date?: number | null
}

const DATE_DISPLAY_FORMAT = 'd LLL yyyy HH:mm'

const STATUS_COLOR_MAP: Record<BannerRagStatus, { background: string; highlight: string }> = {
  red: { highlight: colors.status.red, background: colors.errorBg },
  green: { highlight: colors.status.green, background: colors.successBg },
  amber: { highlight: colors.status.amber, background: colors.warningBg }
}

export const RagStatusBanner = ({ status, message, author, date, type, name }: RagStatusBannerProps) => {
  const primaryContent = (
    <Text>
      {type === 'runbook' ? (
        <>
          <Text weight="bold">
            {/* TODO: i18n support */}
            <Text style={{ textTransform: 'capitalize' }}>{type}</Text> status {status}:{' '}
          </Text>
          {message}
        </>
      ) : (
        <>
          <Text style={{ textTransform: 'capitalize' }}>{type}</Text>: {name}
        </>
      )}
    </Text>
  )

  const secondaryContent =
    type === 'runbook' ? null : (
      <Text>
        <Text weight="bold">
          {/* TODO: i18n support */}
          Status {status}:{' '}
        </Text>
        {message}
      </Text>
    )

  return (
    <Box
      css={`
        border-radius: 8px;
        overflow: hidden;
      `}
    >
      <BannerFlexContainer type={type} status={status}>
        {/* main content */}
        <Box direction="row" gap="4px">
          {/* column 1 */}
          <Box
            direction="row"
            gap="4px"
            css={`
              padding-right: 16px;
              flex-basis: 100%;
              @media ${breakpoints.gtSm} {
                flex-basis: ${type !== 'runbook' && '33.333%'};
              }
            `}
          >
            <Box margin={{ top: '2px' }}>
              {/* if it is browser, the status indicator should have an animated box-shadow. otherwise, it should be static.
                TODO: This is hard coded as static for now as this component was created when we only used it for email,
                but should animate when its rendered on the browser dashboard.
              */}
              <RagIndicator status={status} disableAnimation />
            </Box>
            {primaryContent}
          </Box>

          {/* column 2 */}
          {type !== 'runbook' && (
            <Box
              css={`
                flex-basis: 100%;
                @media ${breakpoints.gtSm} {
                  flex-basis: 66.666%;
                }
              `}
            >
              {secondaryContent}
            </Box>
          )}
        </Box>

        {/* footer */}
        <Text color="text-light" alignSelf="end" size="13px">
          {!date && !author ? null : (
            <>
              Last updated {date && formatDate(new Date(date * 1000), DATE_DISPLAY_FORMAT)}
              {author && <> by {author}</>}
            </>
          )}
          {/* TODO: i18n support */}
        </Text>
      </BannerFlexContainer>
    </Box>
  )
}

const BannerFlexContainer = styled(Box)<Pick<RagStatusBannerProps, 'type' | 'status'>>`
  background-color: ${({ type, status }) => type === 'runbook' && STATUS_COLOR_MAP[status].background};
  width: 100%;
  padding: 16px;
  ${({ type, status }) =>
    type === 'runbook' &&
    css`
      border-right: 4px solid ${STATUS_COLOR_MAP[status].highlight};
    `}
`
