import { unescape } from 'lodash'

import { toCamelCase } from '@cutover/api'
import { usePagedList } from '@cutover/react-ui'
import { apiClient } from 'main/services/api'

export type AccountUserTeam = {
  id: number
  name: string
  color: string
  type: 'user' | 'team'
  usersCount?: number
  highlight?: boolean
  online?: boolean | null
  linked?: boolean
}

export type AccountUsersTeamsQuery = {
  accountId: number
}

export function useAccountUsersTeams(query: AccountUsersTeamsQuery) {
  const { accountId } = query
  return usePagedList<AccountUserTeam>({
    load: async ({ filterText }) => {
      // TODO refactor. Converting case is not a pattern that should be copied.
      // Favour the snake case response that is returned from the API.
      // Unfortunately we type convert this response throughout various components
      // so keeping camelCase conversion here for now otherwise things will break.
      const { data } = toCamelCase(
        await apiClient.get<{ users_teams: AccountUserTeam[] }>({
          url: `accounts/${accountId}/users_teams`,
          params: {
            query: filterText
          }
        })
      )

      return {
        items: data.usersTeams.map(item => ({ ...item, name: unescape(item.name), linked: item.highlight })),
        cursor: undefined
      }
    },
    initialItems: []
  })
}
