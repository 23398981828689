// @ts-nocheck
import { injectable } from 'inversify'
import { observable, action } from 'mobx'
import { PermissableRepository } from 'app/Shared/Repositories/PermissableRepository'
import { IBaseResponse } from 'Gateways/Service/IBaseResponse'
import { ITaskTypePM } from 'Shared/Entities/Types/TaskType/ITaskTypePM'
import { ITaskTypeNewPM } from 'Shared/Entities/Types/TaskType/ITaskTypeNewPM'
import { ITaskTypeDTO } from 'Shared/Entities/Types/TaskType/ITaskTypeDTO'

interface ITaskTypeUpdateDTO {
  task_type: {
    id: number
    name: string
    default: boolean
    archived: boolean
    global: boolean
    account_id: number
    default_duration: number
    highlight: boolean
    auto_start: boolean
    auto_finish: boolean
    conditional_progression: boolean
    enable_start_fixed: boolean
    enable_end_fixed: boolean
    linkable: boolean
  }
}

interface ITaskTypeResponse {
  success: boolean
  body: {
    task_type: ITaskTypeDTO
  }
}

interface ITaskTypeCreateDTO {
  task_type: {
    name: string
    account_id: number
    global: boolean
    default_duration: number
    highlight: boolean
    auto_start: boolean
    auto_finish: boolean
    conditional_progression: boolean
    icon: string
    enable_start_fixed: boolean
    enable_end_fixed: boolean
    linkable: boolean
  }
}

@injectable()
export class TaskTypeRepository extends PermissableRepository {
  @observable
  public selectedId: number = null

  @observable
  public taskTypeForEdit: ITaskTypePM = null

  public taskTypeForCreation: ITaskTypeNewPM = {
    name: '',
    global: true,
    accountId: null,
    defaultDuration: 3600,
    highlight: false,
    autoStart: false,
    autoFinish: false,
    conditionalProgression: false,
    enableStartFixed: false,
    enableEndFixed: false,
    linkable: false
  }

  private taskTypeDTO: ITaskTypeDTO

  constructor() {
    super('task_type')
  }

  public preLoad = (args: { [key: string]: any }): string => {
    this.selectedId = args.id
    return 'task_types/' + args.id
  }

  public postLoad = (rawData: ITaskTypeResponse) => {
    const taskType = rawData.body.task_type
    this.taskTypeDTO = taskType
    this.setProgrammersModel()
  }

  public setProgrammersModel = () => {
    this.taskTypeForEdit = {
      id: this.taskTypeDTO.id,
      name: this.taskTypeDTO.name,
      default: this.taskTypeDTO.default,
      archived: this.taskTypeDTO.archived,
      global: this.taskTypeDTO.global,
      visibilityType: this.taskTypeDTO.account_id ? 'account' : 'global',
      accountId: this.taskTypeDTO.account_id,
      defaultDuration: this.taskTypeDTO.default_duration,
      highlight: this.taskTypeDTO.highlight,
      autoStart: this.taskTypeDTO.auto_start,
      autoFinish: this.taskTypeDTO.auto_finish,
      conditionalProgression: this.taskTypeDTO.conditional_progression,
      comms: this.taskTypeDTO.comms,
      enableStartFixed: this.taskTypeDTO.enable_start_fixed,
      enableEndFixed: this.taskTypeDTO.enable_end_fixed,
      linkable: this.taskTypeDTO.linkable,
      key: this.taskTypeDTO.key
    }
  }

  public saveTaskType = async (): Promise<IBaseResponse> => {
    let taskDTO: ITaskTypeUpdateDTO = {
      task_type: {
        id: this.taskTypeForEdit.id,
        name: this.taskTypeForEdit.name,
        default: this.taskTypeForEdit.default,
        archived: this.taskTypeForEdit.archived,
        global: this.taskTypeForEdit.global,
        // Retain the account id from initial DTO if converted from account to global
        account_id:
          this.taskTypeForEdit.visibilityType === 'global'
            ? this.taskTypeDTO.account_id
            : this.taskTypeForEdit.accountId,
        default_duration: this.taskTypeForEdit.defaultDuration,
        highlight: this.taskTypeForEdit.highlight,
        auto_start: this.taskTypeForEdit.autoStart,
        auto_finish: this.taskTypeForEdit.autoFinish,
        conditional_progression: this.taskTypeForEdit.conditionalProgression,
        enable_start_fixed: this.taskTypeForEdit.enableStartFixed,
        enable_end_fixed: this.taskTypeForEdit.enableEndFixed,
        linkable: this.taskTypeForEdit.linkable
      }
    }

    const result = await this.serviceGateway.put('task_types/' + this.taskTypeForEdit.id, taskDTO)
    if (result.success) {
      this.postLoad(result as ITaskTypeResponse)
    }
    return result
  }

  public refreshTaskTypeForEdit = () => {
    this.taskTypeForEdit = JSON.parse(JSON.stringify(this.taskTypeForEdit))
  }

  public createTaskType = async (taskType: ITaskTypeNewPM): Promise<IBaseResponse> => {
    let taskTypeDto: ITaskTypeCreateDTO = {
      task_type: {
        name: taskType.name,
        global: taskType.global,
        account_id: taskType.accountId,
        default_duration: taskType.defaultDuration,
        highlight: taskType.highlight,
        auto_start: taskType.autoStart,
        auto_finish: taskType.autoFinish,
        conditional_progression: taskType.conditionalProgression,
        icon: 'icon_hexagon',
        enable_start_fixed: taskType.enableStartFixed,
        enable_end_fixed: taskType.enableEndFixed,
        linkable: taskType.linkable
      }
    }

    return await this.serviceGateway.post('task_types', taskTypeDto)
  }

  @action
  public clearTaskTypeForEdit = () => {
    this.taskTypeForEdit = null
  }

  @action
  public archive = async () => {
    this.loading = true
    const responseDto = await this.serviceGateway.delete('task_types/' + this.taskTypeForEdit.id)

    if (responseDto.success) {
      this.taskTypeForEdit = null
    }

    this.loading = false
    return responseDto
  }
}
