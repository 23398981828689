import { BarChart, BarChartData, colors } from '@cutover/react-ui'
import { MrdDashboardWidget } from '../../../widgets/account/mrd-dashboard-widget'
import { AnalyticsComponentProps, TaskCompletionUserCountData } from '../../types'
import { getTimeIntervalBarChart, useTimeIntervalBarChart } from '../use-time-interval-bar-chart'

type MonthlyActiveUsersWidgetProps = AnalyticsComponentProps<TaskCompletionUserCountData>

const CHART_DATA_ATTRIBUTE_MAPPING = { user_count: 'Users' }

export const MonthlyActiveUsersWidget = (props: MonthlyActiveUsersWidgetProps) => {
  const { media } = props

  switch (media) {
    case 'screen':
      return <BrowserWidget {...props} />
    case 'email':
      return <EmailWidget {...props} />
    case 'print':
      return <EmailWidget {...props} />
  }
}

const BrowserWidget = ({ data }: MonthlyActiveUsersWidgetProps) => {
  const chartValues = useTimeIntervalBarChart(data.values, CHART_DATA_ATTRIBUTE_MAPPING)

  return <WidgetContent name={data.name} values={chartValues} />
}

const EmailWidget = ({ data }: MonthlyActiveUsersWidgetProps) => {
  const chartValues = getTimeIntervalBarChart(data.values, CHART_DATA_ATTRIBUTE_MAPPING)

  return <WidgetContent name={data.name} values={chartValues} />
}

const WidgetContent = ({ name, values }: { name: string; values: BarChartData[] }) => {
  return (
    <MrdDashboardWidget title={name}>
      <BarChart
        data={values}
        height={300}
        colors={{ Users: colors.primary }}
        showTotals
        yAxis={{ id: 'users', label: 'Users (Distinct Count)' }}
        xAxis={{ id: 'month', label: '' }}
      />
    </MrdDashboardWidget>
  )
}
