// @ts-nocheck
import { observable, computed, action } from 'mobx'
import { TIcons } from 'Components/Atoms/Icon'
import { GenericFormInputPresenter } from '@logicroom/validator'
import { TToolTipPosition, IAlignmentPosition } from 'Components/Molecules'

export enum ItemType {
  checkBox,
  field,
  input
}

export class CheckboxPresenter extends GenericFormInputPresenter<boolean> {
  @observable
  public label: string

  @observable
  public helpText?: string

  @observable
  public helpTextIcon?: TIcons

  @observable
  public eventIconVisible: boolean = false

  @observable
  public hidden: boolean = false

  @observable
  public eventIcon: TIcons

  public eventIconText: string

  public eventIconTextPosition: TToolTipPosition = 'left center'

  public eventIconRotation: number = 0

  public eventIconEvent: () => void

  @observable
  public eventIconColor: string

  @observable
  public itemType?: ItemType

  @observable
  public oneLineTooltip: boolean = false

  @observable
  public inputPlaceHolder: string

  public filterValue

  public updateFromRepository = () => null

  @observable
  public alignmentPosition: IAlignmentPosition = 'center'

  public hasHtmlLabel: boolean = false

  public radioButtonsPresenter = null

  public pageFilter = null

  @computed
  public get showLabel() {
    return this.itemType === ItemType.field || this.itemType === ItemType.checkBox
  }

  constructor(label: string, initialValue = false) {
    super(initialValue)
    this.label = label
    this.itemType = ItemType.checkBox
  }

  public withEventIcon = (icon: TIcons) => {
    this.eventIcon = icon
    return this
  }

  public withEventIconText = (text: string) => {
    this.eventIconText = text
    return this
  }

  public withEventIconTextPosition = (position: TToolTipPosition) => {
    this.eventIconTextPosition = position
    return this
  }

  public withEventIconEvent = (event: () => void) => {
    this.eventIconEvent = event
    return this
  }

  public withEventIconColor = (color: string) => {
    this.eventIconColor = color
    return this
  }

  public withItemType = (itemTypeArg: ItemType, placeholder?: string) => {
    this.itemType = itemTypeArg
    if (itemTypeArg === ItemType.input) {
      if (!placeholder) {
        throw Error('Please provide a placeholder for a checkbox input')
      }
      this.inputPlaceHolder = placeholder
    }
    return this
  }

  public withHelpText = (text: string) => {
    this.helpText = text
    return this
  }

  public withHelpTextIcon = (icon: TIcons) => {
    this.helpTextIcon = icon
    return this
  }

  public showEventIcon = () => {
    this.eventIconVisible = true
  }

  public hideEventIcon = () => {
    this.eventIconVisible = false
  }

  public flipField = () => {
    if (this.itemType === ItemType.field) {
      this.itemType = ItemType.input
    }
  }

  public withOneLineTooltip = () => {
    this.oneLineTooltip = true
    return this
  }

  public withDisabled = (disabled: boolean) => {
    this.disabled = disabled
    return this
  }

  @action
  public withAlignmentPosition = (position: IAlignmentPosition) => {
    this.alignmentPosition = position
    return this
  }

  public withHtmlLabel = (hasHtmlLabel: boolean) => {
    this.hasHtmlLabel = hasHtmlLabel
    return this
  }
}
