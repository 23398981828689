// @ts-nocheck
import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { withPresenter } from 'app/Config/withPresenter'
import { CustomFieldGroupEditPresenter } from './CustomFieldGroupEditPresenter'
import { CustomFieldGroupFormPresenter } from 'app/Settings/CustomFieldGroups/CustomFieldGroupsPage/CustomFieldGroupForm/CustomFieldGroupFormPresenter'
import { CustomFieldGroupFormComponent } from 'app/Settings/CustomFieldGroups/CustomFieldGroupsPage/CustomFieldGroupForm/CustomFieldGroupFormComponent'
import { EditPanelFormWrapper } from 'Shared/Components/Templates'
import { Submitted } from 'Shared/Entities/Enums/Submitted'
import { IEditSectionProps } from 'Shared/Entities/IEditSectionProps'

interface ICustomFieldGroupListComponentProps extends IEditSectionProps {
  presenter: CustomFieldGroupEditPresenter
  editFormPresenter: CustomFieldGroupFormPresenter
}

const CustomFieldGroupEditSection = observer<ICustomFieldGroupListComponentProps>(
  ({
    presenter,
    editFormPresenter,
    setArchiveState,
    setArchiveResponseErrors,
    archiveState,
    archivePostSuccessCallback,
    editPostSuccessCallback
  }) => {
    const {
      title,
      archiveTooltip,
      mode,
      editPanelSaving,
      reset,
      save,
      close,
      archive,
      archiveButtonCallback,
      confirmModalPresenter,
      canArchive
    } = presenter

    presenter.withFormPresenter(editFormPresenter)
    presenter.withArchiveSetters(setArchiveState, archivePostSuccessCallback, setArchiveResponseErrors)
    presenter.withEditPostSuccessCallback(editPostSuccessCallback)

    useEffect(() => {
      if (archiveState === Submitted.SubmittedAndConfirmed) {
        archive()
      }
    }, [archiveState])

    return (
      <EditPanelFormWrapper
        heading={title}
        mode={mode}
        showArchive={canArchive}
        archiveTooltip={archiveTooltip}
        archiveCallback={archiveButtonCallback}
        singleLineArchiveTooltip={true}
        closeCallback={close}
        saveCallback={save}
        resetCallback={reset}
        editPanelSaving={editPanelSaving}
        confirmModalPresenter={confirmModalPresenter}
      >
        <CustomFieldGroupFormComponent presenter={editFormPresenter} formType="edit" />
      </EditPanelFormWrapper>
    )
  }
)

export default withPresenter(CustomFieldGroupEditPresenter)(CustomFieldGroupEditSection)
