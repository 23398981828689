import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Duplicate = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Duplicate" {...props}>
    <path d="M29 11v2h3v-2a8 8 0 0 0-8-8H11a8 8 0 0 0-8 8v13a8 8 0 0 0 8 8h2v-3h-2a5 5 0 0 1-5-5V11a5 5 0 0 1 5-5h13a5 5 0 0 1 5 5Zm3 18v-7h-3v7h-7v3h7v7h3v-7h7v-3h-7Z" fill="#000" /><path d="M45 24a8 8 0 0 0-8-8H24a8 8 0 0 0-8 8v13a8 8 0 0 0 8 8h13a8 8 0 0 0 8-8V24Zm-3 13a5 5 0 0 1-5 5H24a5 5 0 0 1-5-5V24a5 5 0 0 1 5-5h13a5 5 0 0 1 5 5v13Z" fill="#000" />
  </StyledIcon>
))

Duplicate.displayName = 'Duplicate'

export { Duplicate }
