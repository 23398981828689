import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const TaskTypeCommsDashed = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="TaskTypeCommsDashed" {...props}>
    <path d="M24 8c1.464 0 2.882.197 4.229.565l.77-1.862A18.01 18.01 0 0 0 24 6a18.01 18.01 0 0 0-5 .703l.771 1.862A16.018 16.018 0 0 1 24 8Zm-7.924 2.097a16.078 16.078 0 0 0-5.98 5.98l-1.86-.772a18.085 18.085 0 0 1 7.07-7.07l.77 1.862ZM8 24c0-1.464.197-2.882.565-4.229l-1.862-.77A18.009 18.009 0 0 0 6 24c0 1.735.245 3.412.703 5l1.862-.771A16.018 16.018 0 0 1 8 24Zm29.903 7.924a16.078 16.078 0 0 1-5.98 5.98l.772 1.86a18.084 18.084 0 0 0 7.07-7.07l-1.862-.77ZM24 40c-1.464 0-2.882-.197-4.229-.565l-.77 1.862A18.01 18.01 0 0 0 24 42c1.735 0 3.412-.245 5-.703l-.771-1.862A16.018 16.018 0 0 1 24 40Zm-13.903-8.076a16.077 16.077 0 0 0 5.98 5.98l-.772 1.86a18.085 18.085 0 0 1-7.07-7.07l1.862-.77Zm27.806-15.848a16.078 16.078 0 0 0-5.98-5.98l.772-1.86a18.085 18.085 0 0 1 7.07 7.07l-1.862.77ZM40 24c0-1.464-.197-2.882-.565-4.229l1.862-.77A18.01 18.01 0 0 1 42 24a18.01 18.01 0 0 1-.703 5l-1.862-.771A16.02 16.02 0 0 0 40 24Z" fill="#000" /><path d="M8.415 8.327A21.93 21.93 0 0 0 2 23.855a21.932 21.932 0 0 0 6.516 15.629l1.415-1.414A19.938 19.938 0 0 1 4 23.855 19.937 19.937 0 0 1 9.83 9.741L8.415 8.327ZM38.17 9.741A19.937 19.937 0 0 1 44 23.855c0 5.56-2.269 10.59-5.93 14.215l1.414 1.414A21.932 21.932 0 0 0 46 23.855a21.93 21.93 0 0 0-6.415-15.528L38.17 9.741Z" fill="#000" />
  </StyledIcon>
))

TaskTypeCommsDashed.displayName = 'TaskTypeCommsDashed'

export { TaskTypeCommsDashed }
