export const parseSearchString = (queryString: string) => {
  const parsed = parse(queryString)
  return Object.fromEntries(parsed)
}

const parse = (queryString: string) => {
  try {
    const obj = JSON.parse(queryString)
    const map = new Map()

    Object.entries(obj).forEach(([key, value]) => {
      map.set(key, value)
    })

    return map
  } catch (e) {
    console.error('Failed to parse query string:', e)
    return new Map()
  }
}
