import { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import { Box, IconButton, Text, TextArea, themeColor } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { useRunbookCommentCreate } from 'main/services/queries/use-runbook-comments'

export type ActivityFeedInputProps = {
  runbookId: number
  allowAttachments?: boolean
}

const ActivityFeedInputWrapper = styled(Box).attrs(() => ({
  pad: { right: 'large' },
  gap: 'xxsmall',
  direction: 'row'
}))`
  background-color: white;
  z-index: 4;
  max-height: 50%;
`

const IconButtonWrapper = styled(Box).attrs(() => ({
  pad: { top: 'small' }
}))`
  justify-content: flex-start;
  min-width: 32px;
`

export const ActivityFeedInput = ({ runbookId, allowAttachments = false }: ActivityFeedInputProps) => {
  const { t } = useLanguage('activities')
  const commnetInputRef = useRef<HTMLTextAreaElement | null>(null)
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const [comment, setComment] = useState('')
  const [touched, setTouched] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [file, setFile] = useState<File | undefined>()
  const commentMutation = useRunbookCommentCreate(runbookId)

  const onChangeHandler = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setComment(e.target.value)
  }

  useEffect(() => {
    if (commnetInputRef.current && touched) {
      commnetInputRef.current.focus()
    }
  }, [touched, comment])

  const onKeyDownHandler = (e: KeyboardEvent) => {
    if (e.key === 'Enter' && comment.trim() && !e.shiftKey) {
      sendComment()
    }
  }

  const handleButtonClick = () => {
    fileInputRef.current?.click()
  }

  const sendComment = async () => {
    setDisabled(true)
    setTouched(true)
    await commentMutation.mutateAsync({ comment: comment.trim(), file })
    setComment('')
    setDisabled(false)
    setFile(undefined)
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e?.target.files
    if (fileList && !!fileList.length) {
      setFile(fileList[0])

      e.target.value = ''
    }
  }

  return (
    <>
      <ActivityFeedInputWrapper>
        <TextArea
          ref={commnetInputRef}
          plain={true}
          disabled={disabled}
          data-testid="activityInput"
          placeholder={t('activities:input:comment:placeholder')}
          aria-label={t('activities:input:comment:placeholder')}
          aria-describedby="instructions"
          value={comment}
          alwaysShowPlaceholder={true}
          onChange={onChangeHandler}
          onKeyDown={onKeyDownHandler}
          css={`
            background: ${themeColor('bg-1')} !important;
          `}
          style={{
            outline: 'none',
            paddingLeft: '10px',
            boxSizing: 'border-box',
            borderRadius: `${file ? '8px 8px 0 0' : '8px'}`
          }}
        />
        {allowAttachments && (
          <IconButtonWrapper>
            {/* TODO: WIP feature, using plain input here as a temporary solution. FileInputField within the Modal will be used instead */}
            <input
              data-testid="fileInput"
              type="file"
              ref={fileInputRef}
              onChange={handleFileChange}
              style={{ display: 'none' }}
            />
            <IconButton
              data-testid="uploadFileButton"
              size="medium"
              icon="attachment"
              label={`${t('activities:feed:uploadFile')}`}
              onClick={handleButtonClick}
            />
          </IconButtonWrapper>
        )}
        <IconButtonWrapper>
          <IconButton
            primary
            disabled={!file && !comment.trim()}
            data-testid="sendCommentButton"
            size="medium"
            icon="send"
            label={`${t('activities:feed:send')}`}
            onClick={sendComment}
          />
        </IconButtonWrapper>
      </ActivityFeedInputWrapper>
      {allowAttachments && file && (
        <Box
          background={'bg-1'}
          direction={'row'}
          align={'center'}
          justify-content={'flex-start'}
          gap={'xsmall'}
          pad={{ right: '10px', left: '10px' }}
          css={`
            width: calc(100% - 96px);
            border-radius: 0 0 8px 8px;
          `}
        >
          <Text color={'primary'}>{file.name}</Text>
          <IconButton
            data-testid="removeFileSelection"
            size="small"
            icon="close"
            label={`${t('activities:feed:removeSelection')}`}
            onClick={() => setFile(undefined)}
          />
        </Box>
      )}
    </>
  )
}
