import { PaintDecision } from '../node-map-types'

export const paintDecision = ({ ctx, x, y, size, size2, size3, lineWidth, stroke, fill }: PaintDecision) => {
  const halfSize = size / 2
  const halfLineWidth = lineWidth / 2

  ctx.beginPath()
  ctx.moveTo(x - halfSize, y + size2)
  ctx.lineTo(x, y - size3)
  ctx.lineTo(x + halfSize, y + size2)
  ctx.closePath()

  ctx.lineWidth = lineWidth
  ctx.strokeStyle = stroke
  ctx.stroke()

  if (fill) {
    ctx.fillStyle = fill
    ctx.fill()
  }

  ctx.beginPath()
  ctx.moveTo(x - halfSize - 1.5, y + size2 - 3)
  ctx.lineTo(x - 3, y - size3)

  ctx.lineWidth = halfLineWidth
  ctx.strokeStyle = stroke
  ctx.stroke()

  ctx.beginPath()
  ctx.moveTo(x + 3, y - size3)
  ctx.lineTo(x + halfSize + 1.5, y + size2 - 3)

  ctx.lineWidth = halfLineWidth
  ctx.strokeStyle = stroke
  ctx.stroke()
}
