import { format } from 'date-fns'
import { useLocation } from 'react-router-dom'

import { ColumnConfig, Table, Text } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { SamlRoleMapping } from 'main/services/queries/types'
import { useSamlRoleMappingsQuery } from 'main/services/queries/use-saml-role-mappings-query'

export const RoleMappingsList = () => {
  const location = useLocation()
  const { state } = location
  const { data } = useSamlRoleMappingsQuery(state?.samlConfigurationId)
  const { t } = useLanguage('roleMappings', { keyPrefix: 'table.fields' })
  const roleMappings = data?.saml_role_mappings || []

  const columns: ColumnConfig<SamlRoleMapping>[] = [
    {
      header: t('samlRoleName'),
      property: 'saml_role_name',
      sortable: false
    },
    {
      header: t('roleTypeName'),
      property: 'role_type_name',
      sortable: false
    },
    {
      header: t('accountTitle'),
      property: 'account_title',
      sortable: false
    },
    {
      header: t('projectName'),
      property: 'project_name',
      sortable: false
    },
    {
      header: t('subProjectName'),
      property: 'sub_project_name',
      sortable: false
    },
    {
      header: t('lastUpdatedBy'),
      property: 'updater',
      sortable: false
    },
    {
      header: t('updatedAt'),
      property: 'updated_at',
      sortable: false,
      render: (roleMapping: SamlRoleMapping) => {
        return <Text>{format(new Date(roleMapping.updated_at), 'E, do MMM yyyy HH:mm')}</Text>
      }
    }
  ]

  return <Table columns={columns} data={roleMappings} />
}
