// @ts-nocheck
import { useEffect } from 'react'
import { ModalPresenter } from 'Components/Atoms'
import { Submitted } from 'Shared/Entities/Enums/Submitted'

export const addSectionHooks = (
  setUserAddModalOpenState: (boolean: boolean) => void,
  userAddModalOpenState: boolean,
  addFormPresenter?: any
) => {
  const addModalPresenter = new ModalPresenter().withOnCloseExtra(() => {
    setUserAddModalOpenState(false)
    addFormPresenter && addFormPresenter.reset()
  })

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (userAddModalOpenState === true) {
      addModalPresenter.onOpen()
    }
  }, [userAddModalOpenState])

  return { addModalPresenter }
}

export const confirmSectionHooks = (confirmModalOpenState: Submitted) => {
  const confirmModalPresenter = new ModalPresenter()

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (confirmModalOpenState === Submitted.Submitted) {
      confirmModalPresenter.onOpen()
    } else {
      confirmModalPresenter.onClose()
    }
  }, [confirmModalOpenState])

  return { confirmModalPresenter }
}
