// @ts-nocheck
import { observable, action, computed, reaction } from 'mobx'
import { container } from 'app/Config/IOC'
import { injectable } from 'inversify'
import { TaskTypesViewPresenter } from './TaskTypesViewPresenter'
import { TaskTypeListPresenter } from './TaskTypesList/TaskTypeListPresenter'
import { TaskTypeFormPresenter, IFormViewModel } from './TaskTypeForm/TaskTypeFormPresenter'
import { TaskTypesFilterPresenter } from './TaskTypesFilter/TaskTypesFilterPresenter'
import { TaskTypeEditPresenter } from './TaskTypeEdit/TaskTypeEditPresenter'
import { ITaskTypeVM } from 'Shared/Entities/Types/TaskType/ITaskTypeVM'
import { TaskTypesPageHeadingPresenter } from './TaskTypesPageHeading/TaskTypesPageHeadingPresenter'
import { CurrentPagePresenter } from 'app/Routing/CurrentPage/CurrentPagePresenter'
import { Types } from 'Gateways/Service/Types'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'

@injectable()
export class TaskTypesPagePresenter {
  @observable
  public taskTypesViewPresenter: TaskTypesViewPresenter = container.get(TaskTypesViewPresenter)

  public language: ILanguageService = container.get(Types.ILanguageService)

  @observable
  public taskTypeListPresenter: TaskTypeListPresenter = new TaskTypeListPresenter()

  @observable
  public filterSectionPresenter: TaskTypesFilterPresenter = new TaskTypesFilterPresenter()

  @observable
  public addFormPresenter: TaskTypeFormPresenter = new TaskTypeFormPresenter()

  @observable
  public editFormPresenter: TaskTypeFormPresenter = new TaskTypeFormPresenter().withDirtyCallback(() => {
    this.taskTypesViewPresenter.pageVM.editPanelDirty = true
  })

  @observable
  public taskTypesPageHeadingPresenter: TaskTypesPageHeadingPresenter = new TaskTypesPageHeadingPresenter()

  @observable
  public taskTypeEditPresenter = new TaskTypeEditPresenter()

  constructor() {
    this.setupReactions()
  }

  @computed
  public get angularJSSettingsPanelOpenState(): boolean {
    return container.get(CurrentPagePresenter).angularJSSettingsPanelOpenState
  }

  private setupReactions = () => {
    if (Object.keys(this.taskTypesViewPresenter.accountsVM).length === 0) {
      reaction(
        () => this.taskTypesViewPresenter.accountsVM,
        (accountsVm, reaction) => {
          this.setupFormPresenters()
          reaction.dispose()
        }
      )
    } else {
      this.setupFormPresenters()
    }
  }

  @computed
  public get filterPanelLoading(): boolean {
    return this.taskTypesViewPresenter.loadingFilters
  }

  @computed
  public get editPanelOpen(): boolean {
    return this.taskTypesViewPresenter.pageVM.editPanelOpen
  }

  public setEditClosed = () => {
    this.taskTypesViewPresenter.setPageVMValue('editPanelOpen', false)
  }

  private setupFormPresenters = () => {
    this.addFormPresenter.withVisibilityAccounts(this.getAccountsByName())
    this.editFormPresenter.withVisibilityAccounts(this.getAccountsByName())
  }

  @computed
  public get listVM() {
    return this.taskTypesViewPresenter.listVM
  }

  @computed
  public get taskTypeVM() {
    return this.taskTypesViewPresenter.taskTypeVM
  }

  public taskTypeVMChangeFunction = () => {
    if (this.taskTypeVM) {
      this.setFormViewModel()
    }
  }

  @action
  private setFormViewModel = () => {
    if (!this.taskTypesViewPresenter.taskTypeVM) return

    const taskTypeForEdit = this.taskTypesViewPresenter.taskTypeVM

    const formVM: IFormViewModel = {
      name: taskTypeForEdit.name,
      disabledName: this.isDisabled(taskTypeForEdit),
      disabledGlobal: this.isDisabled(taskTypeForEdit),
      disabledAccount:
        this.isDisabled(taskTypeForEdit) || Object.keys(this.taskTypesViewPresenter.accountsVM).length === 1,
      global: taskTypeForEdit.global,
      defaultDuration: taskTypeForEdit.defaultDuration,
      disabledDefaultDuration: this.isDisabled(taskTypeForEdit),
      highlight: taskTypeForEdit.highlight,
      disabledHighlight: this.isDisabled(taskTypeForEdit),
      autoStart: taskTypeForEdit.autoStart,
      disabledAutoStart: this.isDisabled(taskTypeForEdit),
      autoFinish: taskTypeForEdit.autoFinish,
      disabledAutoFinish: this.isDisabled(taskTypeForEdit),
      conditionalProgression: taskTypeForEdit.conditionalProgression,
      disabledConditionalProgression: this.isDisabled(taskTypeForEdit),
      comms: taskTypeForEdit.comms,
      enableStartFixed: taskTypeForEdit.enableStartFixed,
      disabledEnableStartFixed: this.isDisabled(taskTypeForEdit),
      linkable: taskTypeForEdit.linkable,
      disabledLinkable: this.isDisabled(taskTypeForEdit),
      enableEndFixed: taskTypeForEdit.enableEndFixed,
      disabledEnableEndFixed: this.isDisabled(taskTypeForEdit),
      key: taskTypeForEdit.key
    }

    if (taskTypeForEdit.accountId && !taskTypeForEdit.global) {
      formVM.account = this.taskTypesViewPresenter.accountsVM[taskTypeForEdit.accountId]
    }

    this.editFormPresenter.withViewModel(formVM)
  }

  @computed
  public get canCreateTaskType(): boolean {
    return this.taskTypesViewPresenter.can('create')
  }

  private isDisabled(taskTypeForEdit: ITaskTypeVM): boolean {
    return taskTypeForEdit.default || !this.taskTypesViewPresenter.can('update') || taskTypeForEdit.archived
  }

  @computed
  public get loading(): boolean {
    return this.taskTypesViewPresenter.repositoryLoading
  }

  private getAccountsByName = (): string[] => {
    return Object.values(this.taskTypesViewPresenter.accountsVM)
  }

  @computed
  public get editPanelLoading(): boolean {
    return this.taskTypesViewPresenter.pageVM.editPanelLoading
  }

  @computed
  public get archiveModalOpenState(): boolean {
    return this.taskTypesViewPresenter.pageVM.archiveModalOpenState
  }

  @action
  public resetSubmit = () => {
    this.taskTypesViewPresenter.submitted = false
  }

  public clearEditPanel = () => {
    this.taskTypesViewPresenter.clearEditPanel()
  }

  public checkEditPanelIsDirty = (): boolean => {
    return this.taskTypesViewPresenter.checkEditPanelIsDirty()
  }
}
