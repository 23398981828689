import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const TaskTypeHexagon100 = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="TaskTypeHexagon100" {...props}>
    <path d="M36.32 15.732a2 2 0 0 1 1 1.732v13.072a2 2 0 0 1-1 1.732L25 38.804a2 2 0 0 1-2 0l-11.32-6.536a2 2 0 0 1-1-1.732V17.464a2 2 0 0 1 1-1.732L23 9.196a2 2 0 0 1 2 0l11.32 6.536Zm-9.32-10a6 6 0 0 0-6 0L9.68 12.268a6 6 0 0 0-3 5.196v13.072a6 6 0 0 0 3 5.196L21 42.268a6 6 0 0 0 6 0l11.32-6.536a6 6 0 0 0 3-5.196V17.464a6 6 0 0 0-3-5.196L27 5.732Z" fill="#000" />
  </StyledIcon>
))

TaskTypeHexagon100.displayName = 'TaskTypeHexagon100'

export { TaskTypeHexagon100 }
