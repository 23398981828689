import { forwardRef, ReactNode } from 'react'
import { TextInput as GrommetTextInput, TextInputProps as GrommetTextInputProps } from 'grommet'

import { Icon, IconName } from '../../icon'
import { FormFieldLabelBoxProps } from '../internal/form-field'
import { TextInputBase } from '../internal/text-input-base'

export type TextInputProps = Omit<JSX.IntrinsicElements['input'], 'onSelect' | 'size' | 'ref' | 'css'> & {
  'data-testid'?: string
  clickable?: boolean
  hasError?: boolean
  icon?: IconName
  inlineError?: string
  label?: string
  tooltipText?: string
  truncate?: boolean
  value?: string | number
  plain?: boolean
  endComponent?: ReactNode
  labelProps?: FormFieldLabelBoxProps
  a11yTitle?: string
}

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(({ plain, icon, labelProps, ...props }, ref) => {
  // TODO: have plain prop use our own text input base CFE-1432
  return plain === true ? (
    <GrommetTextInput
      plain
      ref={ref}
      icon={icon ? <Icon color="text-light" icon={icon} /> : undefined}
      {...(props as GrommetTextInputProps)}
      a11yTitle={props.a11yTitle ?? props.label}
    />
  ) : (
    <TextInputBase
      ref={ref}
      startIcon={icon}
      {...(props as any)}
      labelProps={labelProps}
      a11yTitle={props.a11yTitle}
      plain={!!plain}
    />
  )
})
