import { memo, useEffect } from 'react'
import styled, { css } from 'styled-components'

import { Box, IconButton, media } from '@cutover/react-ui'
import { useActiveRightPanelValueType, useToggleRightPanel } from 'main/components/layout/right-panel'
import { useLanguage } from 'main/services/hooks'
import { ActiveRunbookModel, ConfigModel, RunbookViewModel } from 'main/data-access'

export const RunbookRightNavigation = memo(() => {
  const { t } = useLanguage('runbook', { keyPrefix: 'rightNavigation' })
  const isAiFeaturesEnabled = ConfigModel.useIsFeatureEnabled('ai_features')
  const runbookId = ActiveRunbookModel.useId()
  const activeRightPanelType = useActiveRightPanelValueType()

  const toggleAiSuggestions = useToggleRightPanel('ai-suggestions-list')
  const toggleRunbookEdit = useToggleRightPanel('runbook-edit')
  const togglePeoplePanel = useToggleRightPanel('user-team-details-edit', () => true)
  // open panel check function here so that it will only toggle closed when its viewing the full list of runbook comments. If it is viewing
  // a specific task's comments, switch to viewing all comments.
  const toggleCommentsPanel = useToggleRightPanel('runbook-comments', openPanel => !openPanel.taskId)
  const resetNewCommentsCount = RunbookViewModel.useAction('newCommentsCount:reset')

  const newCommentsCount = RunbookViewModel.useGet('newCommentsCount')

  useEffect(() => {
    if (activeRightPanelType === 'runbook-comments') {
      resetNewCommentsCount()
    }
  }, [activeRightPanelType])

  return (
    <NavContainer>
      <IconButton
        icon="edit"
        onClick={() => toggleRunbookEdit({ runbookId })}
        label={t('runbookDetails')}
        isActive={activeRightPanelType === 'runbook-edit' || activeRightPanelType === 'runbook-copies'}
        data-testid="runbook-edit-button"
      />
      <IconButton
        icon="users"
        onClick={() => togglePeoplePanel({ initialScreen: 'users-and-teams' })}
        label={t('usersAndTeams')}
        isActive={activeRightPanelType === 'user-team-details-edit'}
        data-testid="view-users-teams"
      />
      <IconButton
        icon="message"
        onClick={() => toggleCommentsPanel({})}
        label={
          newCommentsCount > 0 ? t('runbookCommentsNewComments', { count: newCommentsCount }) : t('runbookComments')
        }
        isActive={activeRightPanelType === 'runbook-comments'}
        {...(newCommentsCount > 0 ? { badge: { label: newCommentsCount, type: 'primary' } } : {})}
      />
      {/* TODO see https://cutover.atlassian.net/browse/CFE-446 */}
      {/* <IconButton icon="phone" onClick={handleComms} label="Ad hoc Comms" /> */}
      {isAiFeaturesEnabled && (
        <IconButton
          badge={{ color: 'feature-pink' }}
          icon="rocket"
          isActive={activeRightPanelType === 'ai-suggestions-list'}
          onClick={() => toggleAiSuggestions({ runbookId })}
          label={t('aiSuggestions')}
        />
      )}
    </NavContainer>
  )
})

const NavContainer = styled(Box)`
  flex-direction: column;
  align-items: center;
  gap: 12px;
  padding-top: 14px;
  ${media.sm(css`
    flex-direction: row;
    justify-content: center;
    gap: 32px;
  `)}
`
