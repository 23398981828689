import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const EditApps = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="EditApps" {...props}>
    <path d="M25.182 22.87h6.364L45.052 9.364 38.688 3 25.182 16.506v6.364ZM40.809 9.364 30.303 19.87h-2.121v-2.12L38.688 7.243l2.121 2.121ZM10 6a4 4 0 0 0-4 4v8a4 4 0 0 0 4 4h8a4 4 0 0 0 4-4v-8a4 4 0 0 0-4-4h-8Zm8 3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1h-8a1 1 0 0 1-1-1v-8a1 1 0 0 1 1-1h8ZM6 30a4 4 0 0 1 4-4h8a4 4 0 0 1 4 4v8a4 4 0 0 1-4 4h-8a4 4 0 0 1-4-4v-8Zm4-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-8a1 1 0 0 0-1-1h-8Zm16 1a4 4 0 0 1 4-4h8a4 4 0 0 1 4 4v8a4 4 0 0 1-4 4h-8a4 4 0 0 1-4-4v-8Zm4-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-8a1 1 0 0 0-1-1h-8Z" fill="#000" />
  </StyledIcon>
))

EditApps.displayName = 'EditApps'

export { EditApps }
