import { Text } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { RunbookListRunbook } from 'main/services/queries/types'
import { Bullet } from './bullet'

export const TasksCount = ({ runbook: { tasks_count } }: { runbook: RunbookListRunbook }) => {
  const { t } = useLanguage('runbooks')

  return (
    <>
      <Bullet />
      <Text size="small" truncate>
        {t('list.listItem.subHeader.tasksCount', { count: tasks_count })}
      </Text>
    </>
  )
}
