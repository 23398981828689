import { GlobalConfigType } from 'main/services/hooks'
import { useGetConfig, useIsFeatureEnabled } from '../hooks'
import { SingleModelInterfaceBase } from './types/single-model-interface'

type ConfigModelType = Pick<SingleModelInterfaceBase<GlobalConfigType>, 'useGet'> & {
  useIsFeatureEnabled: (key: GlobalConfigType['enabledFeatures'][number]) => boolean
}

export const ConfigModel: ConfigModelType = {
  useGet: useGetConfig,

  useIsFeatureEnabled
}
