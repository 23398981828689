import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Link = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Link" {...props}>
    <path d="m8.444 25.414 5.657-5.657 2.12 2.122-5.656 5.656a5 5 0 0 0 0 7.072l2.829 2.828a5 5 0 0 0 7.07 0l5.657-5.657 2.122 2.122-5.657 5.656a8 8 0 0 1-11.314 0l-2.828-2.828a8 8 0 0 1 0-11.314Zm28.991-12.021a5 5 0 0 1 0 7.071l-5.657 5.657 2.122 2.122 5.656-5.657a8 8 0 0 0 0-11.314l-2.828-2.828a8 8 0 0 0-11.314 0L19.757 14.1l2.122 2.122 5.657-5.657a5 5 0 0 1 7.07 0l2.83 2.828Z" fill="#000" /><path d="m31.425 14.454-16.97 16.97 2.12 2.122 16.971-16.97-2.121-2.122Z" fill="#000" />
  </StyledIcon>
))

Link.displayName = 'Link'

export { Link }
