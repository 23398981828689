import { PaintEdge } from '../node-map-types'

export const paintEdge = ({ ctx, edge, state }: PaintEdge) => {
  const { scale } = state.transform
  const { points } = edge

  // Draw float indicator if zoomed in enough
  if (edge.float && scale >= 3) {
    const point = points[points.length - 2]
    const centerX = point.x
    const centerY = point.y
    const textSize = (state.units.base * 1.25) / scale

    ctx.font = `${textSize}px Arial`
    ctx.textBaseline = 'middle'
    ctx.textAlign = 'center'
    ctx.fillStyle = ctx.strokeStyle
    ctx.fillText(`${edge.float} gap`, centerX, centerY)

    ctx.fillStyle = '#ffffff'
    ctx.beginPath()
    ctx.fillRect(centerX - textSize / 2, centerY - textSize / 2, textSize, textSize)
  }

  ctx.beginPath()
  ctx.moveTo(points[0].x, points[0].y)

  for (let pt = 1; pt < points.length - 1; pt++) {
    ctx.arcTo(points[pt].x, points[pt].y, points[pt + 1].x, points[pt + 1].y, 10)
  }

  const point = points[points.length - 1]
  ctx.lineTo(point.x, point.y)
  ctx.stroke()
}
