import { memo } from 'react'
import { useFormContext } from 'react-hook-form'

import { CheckboxesField, LoadingPanel } from '@cutover/react-ui'
import {
  CustomFieldsGroupsForm,
  CustomFieldsGroupsFormProps
} from 'main/components/shared/custom-field-form/custom-field-groups-form'
import {
  CheckboxFieldControlled,
  DateTimePickerField,
  FolderSelectField,
  TextInputField
} from 'main/components/shared/form'
import { useLanguage } from 'main/services/hooks'
import { MergeModalNewRunbookDetailsFormType } from '../merge-runbooks-modal-types'
import { PermittedProject } from 'main/services/queries/use-permitted-resources'

export type StepNewRunbookDetailsProps = CustomFieldsGroupsFormProps & {
  folders?: PermittedProject[]
  lockDestinationProject: boolean
}

export const StepNewRunbookDetails = memo(
  ({ folders, lockDestinationProject, ...customFieldsProps }: StepNewRunbookDetailsProps) => {
    const { t } = useLanguage('runbooks', { keyPrefix: 'mergeModal' })
    const {
      watch,
      formState: { errors }
    } = useFormContext<MergeModalNewRunbookDetailsFormType>()

    const startScheduled = watch('runbook.runbook_versions_attributes.0.start_scheduled')

    return !folders ? (
      <LoadingPanel contained />
    ) : (
      <>
        <TextInputField<MergeModalNewRunbookDetailsFormType>
          name="runbook.name"
          label={t('fields.newRunbookTitle.label')}
        />
        <FolderSelectField<MergeModalNewRunbookDetailsFormType>
          label={t('fields.folder.label')}
          folders={folders ?? []}
          name="runbook.project_id"
          clearable={false}
          loading={!folders}
          disabled={lockDestinationProject}
        />
        <DateTimePickerField<MergeModalNewRunbookDetailsFormType>
          fixed
          fixedWidth
          name="runbook.runbook_versions_attributes.0.start_scheduled"
          label={t('fields.startScheduled.label')}
        />
        {startScheduled && (
          <CheckboxesField label={t('fields.shiftTime.header')}>
            <CheckboxFieldControlled<MergeModalNewRunbookDetailsFormType>
              name="shift_time"
              label={t('fields.shiftTime.label')}
              helpText={t('fields.shiftTime.helpText')}
            />
          </CheckboxesField>
        )}
        <CustomFieldsGroupsForm {...customFieldsProps} errors={errors} />
      </>
    )
  }
)
