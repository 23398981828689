import { useQuery } from 'react-query'

import { apiClient } from 'main/services/api'

type Account = {
  id: number
  name: string
  slug: string
  title: string
  account_type: AccountType
}

type AccountType = {
  id: number
  name: string
  icon_type: string
}

/** @deprecated Use useAccountsQuery instead. */
export function useAccounts() {
  return useQuery<Account[], Error>(
    ['deprecated', 'accounts'],
    async () => {
      const { data } = await apiClient.get<{ accounts: Account[] }>({
        url: 'accounts/permitted',
        params: { resource: 'user' }
      })

      return data.accounts ?? []
    },
    {
      cacheTime: 0
    }
  )
}
