// @ts-nocheck
import { injectable } from 'inversify'
import 'reflect-metadata'
import { IRouterGateway } from './IRouterGateway'

@injectable()
export class FakeRouterGateway implements IRouterGateway {
  public registerRoutes() {
    return Promise.resolve() as any
  }

  public unload() {}

  public goToPath = jest.fn().mockImplementation((routeId, params, query) => {
    return Promise.resolve() as any
  })

  public lastRouteResolved = jest.fn().mockImplementation((url = '') => ({
    url
  }))
}
