import { ResponsiveBar } from '@nivo/bar'

import { colors } from '../../utils'

const FONT_SIZE = 10

type ResponsiveBarProps = PropsOf<typeof ResponsiveBar>

export const nivoTheme: ResponsiveBarProps['theme'] = {
  fontFamily: "'Inter', sans-serif",
  textColor: colors.bgDark,
  fontSize: FONT_SIZE,
  legends: {
    text: {
      fontSize: 13
    }
  },
  axis: {
    ticks: {
      line: {
        stroke: colors.bgDark
      }
    }
  }
}

// TODO: put somewhere with utility types
type PropsOf<TComponent> = TComponent extends React.ComponentType<infer P> ? P : never
