// @ts-nocheck
import { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { withPresenter } from 'app/Config/withPresenter'
import { TaskTypesPagePresenter } from './TaskTypesPagePresenter'
import {
  HeaderPanelWrapper,
  PageWrapper,
  PageWrapperInner,
  CenterMainWrapper,
  EditPanelWrapper,
  FilterPanelWrapper,
  MainPanelWrapper,
  GenericList
} from 'Components/Templates'
import { TaskTypesPageHeadingSection } from './TaskTypesPageHeading/TaskTypesPageHeadingSection'
import TaskTypesFilterSection from './TaskTypesFilter/TaskTypesFilterSection'
import TaskTypeEditSection from './TaskTypeEdit/TaskTypeEditSection'
import { TaskTypesPageModals } from './TaskTypesPageModals'
import { AddButton } from 'Components/Atoms'
import { fluidPage } from 'Shared/HooksAndPresenters/FluidPage'

interface ITaskTypePageProps {
  presenter: TaskTypesPagePresenter
}

const TaskTypesPage = observer<ITaskTypePageProps>(props => {
  const {
    taskTypeListPresenter,
    filterPanelLoading,
    editPanelOpen,
    setEditClosed,
    canCreateTaskType,
    addFormPresenter,
    editFormPresenter,
    resetSubmit,
    editPanelLoading,
    filterSectionPresenter,
    loading,
    taskTypeVM,
    taskTypeVMChangeFunction,
    clearEditPanel,
    taskTypeEditPresenter,
    archiveModalOpenState,
    angularJSSettingsPanelOpenState,
    taskTypesPageHeadingPresenter,
    checkEditPanelIsDirty,
    language
  } = props.presenter

  const { archiveModalPresenter, archiveConfirmName, cancelArchiveCallback, continueArchiveCallback, responseErrors } =
    taskTypeEditPresenter

  const [taskTypeAddModalOpenState, setTaskTypeAddModalOpenState] = useState(false)
  const { showAddButton, filterPanelOpen, setFilterPanelOpenState } = fluidPage(
    editPanelOpen,
    setEditClosed,
    checkEditPanelIsDirty,
    angularJSSettingsPanelOpenState
  )

  useEffect(() => {
    taskTypeVMChangeFunction()
  }, [taskTypeVM])

  const addModalCallback = (modalOpen: boolean) => {
    resetSubmit()
    setTaskTypeAddModalOpenState(modalOpen)
  }
  return (
    <PageWrapper>
      <PageWrapperInner>
        <HeaderPanelWrapper>
          <TaskTypesPageHeadingSection
            presenter={taskTypesPageHeadingPresenter}
            filterOpen={filterPanelOpen}
            setFilterOpen={setFilterPanelOpenState}
          />
        </HeaderPanelWrapper>
        <CenterMainWrapper>
          <FilterPanelWrapper filterPanelOpen={filterPanelOpen} loading={filterPanelLoading}>
            {filterPanelOpen && <TaskTypesFilterSection presenter={filterSectionPresenter} />}
          </FilterPanelWrapper>
          <MainPanelWrapper filterPanelOpen={filterPanelOpen} extraStyles={{ zIndex: 1 }} loading={loading}>
            <GenericList listPresenter={taskTypeListPresenter} />
            {canCreateTaskType && showAddButton && (
              <AddButton
                action={() => {
                  setTaskTypeAddModalOpenState(true)
                }}
                language={language}
              />
            )}
            <TaskTypesPageModals
              addModalOpenState={taskTypeAddModalOpenState}
              setAddModalOpenState={addModalCallback}
              addFormPresenter={addFormPresenter}
              archiveModalPresenter={archiveModalPresenter}
              archiveConfirmName={archiveConfirmName}
              cancelArchiveCallback={cancelArchiveCallback}
              archiveResponseErrors={responseErrors}
              continueArchiveCallback={continueArchiveCallback}
              archiveModalOpenState={archiveModalOpenState}
              language={language}
            />
          </MainPanelWrapper>
        </CenterMainWrapper>
      </PageWrapperInner>
      <EditPanelWrapper
        isOpen={editPanelOpen}
        loading={editPanelLoading}
        panelCloseCallback={() => {
          clearEditPanel()
        }}
      >
        <TaskTypeEditSection presenter={taskTypeEditPresenter} editFormPresenter={editFormPresenter} />
      </EditPanelWrapper>
    </PageWrapper>
  )
})

export default withPresenter(TaskTypesPagePresenter)(TaskTypesPage)
