import styled from 'styled-components/macro'

import { Box } from '../../layout'

export const ScrollContent = styled(Box)`
  padding-right: 16px;
  margin-top: 16px;
  overflow-x: hidden;
  overflow-y: auto;
`
