import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Filter = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Filter" {...props}>
    <path d="M36 23a6 6 0 1 0 0-12 6 6 0 0 0 0 12Zm0-3a3 3 0 1 1 0-6 3 3 0 0 1 0 6Zm-8-4.5H7v3h21v-3Zm14 15H20v3h22v-3ZM18 32a6 6 0 1 1-12 0 6 6 0 0 1 12 0Zm-3 0a3 3 0 1 0-6 0 3 3 0 0 0 6 0Z" fill="#000" />
  </StyledIcon>
))

Filter.displayName = 'Filter'

export { Filter }
