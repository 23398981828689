import { BaseEditor, Descendant, Element, Node } from 'slate'
import { ReactEditor } from 'slate-react'
import { HistoryEditor } from 'slate-history'
import { ListsSchema, ListType } from '@prezly/slate-lists'

import { MessageType } from '../../message'

export type CustomEditor = BaseEditor & ReactEditor & HistoryEditor

export type ParagraphElement = {
  type: 'paragraph'
  align?: string
  children: CustomText[] | LinkElement[]
}

export type LinkElement = {
  type: 'link'
  url: string
  children: Descendant[]
}

export type ImageElement = {
  type: 'image'
  src: string
  children: CustomText[]
}

export type MessageElement = {
  type: 'message'
  messageType?: MessageType
  children: Descendant[]
}

export type NumberedListElement = {
  type: 'numbered-list'
  children: Descendant[]
}

export type BlockQuoteElement = {
  type: 'quote'
  children: Descendant[]
}

export type HeadingElement = {
  type: 'heading'
  align?: string
  level?: 1 // could make number if we want to support h2, h3, in the future
  children: Descendant[]
}

export type ListItemElement = {
  type: 'list-item'
  children: Descendant[]
}

export type ListItemTextElement = {
  type: 'list-item-text'
  children: Descendant[]
}

export type BulletedListElement = {
  type: 'bulleted-list'
  children: ListItemElement[]
}

export type PreformattedTextElement = {
  type: 'code'
  children: CustomText[]
}

export type TableElement = {
  type: 'table'
  children: TableBodyElement[] | TableHeaderElement[] | TableRowElement[]
}

export type TableRowElement = {
  type: 'table-row'
  children: TableCellElement[]
}

export type TableBodyElement = {
  type: 'table-body'
  children: TableRowElement[]
}

export type TableHeaderElement = {
  type: 'table-header'
  children: TableRowElement[]
}

export type TableHeaderCellElement = {
  type: 'table-header-cell'
  children: TableCellElement[]
}

type TableCellElement = {
  type: 'table-cell'
  children: Descendant[]
}

export type Align = 'left' | 'center' | 'right' | 'justify'

export type CustomElement =
  | LinkElement
  | BlockQuoteElement
  | HeadingElement
  | ImageElement
  | TableHeaderCellElement
  | ListItemElement
  | ListItemTextElement
  | NumberedListElement
  | ParagraphElement
  | PreformattedTextElement
  | BulletedListElement
  | TableElement
  | TableHeaderElement
  | TableBodyElement
  | TableRowElement
  | TableCellElement
  | MessageElement

export type CustomElementTypes = CustomElement['type']

export type Format = 'bold' | 'italic' | 'underline' | 'strikethrough' | 'superscript' | 'subscript' | 'code' | 'status'

export type FormattedText = {
  text: string
  bold?: boolean
  italic?: boolean
  underline?: boolean
  strikethrough?: boolean
  superscript?: boolean
  subscript?: boolean
  code?: boolean
  status?: string
}

export type CustomText = FormattedText

declare module 'slate' {
  interface CustomTypes {
    Editor: BaseEditor &
      ReactEditor &
      HistoryEditor & { onKeyDown: (event: React.KeyboardEvent<HTMLDivElement>) => void }
    Element: CustomElement
    Text: CustomText
  }
}

const LIST_ITEM_TYPES: CustomElementTypes[] = ['bulleted-list', 'numbered-list']
const TEXT_ALIGN_TYPES: Align[] = ['left', 'center', 'right', 'justify']

export function isAlignType(value: CustomElementTypes | Align): value is Align {
  return TEXT_ALIGN_TYPES.includes(value as any)
}

export function isListItemType(
  value: CustomElementTypes | Align
): value is Extract<CustomElementTypes, 'bulleted-list' | 'numbered-list'> {
  return LIST_ITEM_TYPES.includes(value as any)
}

export function isAlignableElement(
  value: CustomElement
): value is Extract<CustomElement, ParagraphElement | HeadingElement> {
  return ['paragraph', 'heading'].includes(value.type)
}

export const ListSchema: ListsSchema = {
  isConvertibleToListTextNode(node: Node) {
    return Element.isElementType(node, 'paragraph')
  },
  isDefaultTextNode(node: Node) {
    return Element.isElementType(node, 'paragraph')
  },
  isListNode(node: Node, type: ListType) {
    if (type) {
      return Element.isElementType(node, type)
    }
    return Element.isElementType(node, 'numbered-list') || Element.isElementType(node, 'bulleted-list')
  },
  isListItemNode(node: Node) {
    return Element.isElementType(node, 'list-item')
  },
  isListItemTextNode(node: Node) {
    return Element.isElementType(node, 'list-item-text')
  },
  createDefaultTextNode(props = {}) {
    return { children: [{ text: '' }], ...props, type: 'paragraph' } as CustomElement
  },
  createListNode(type: ListType = ListType.UNORDERED, props = {}) {
    const nodeType = type === ListType.ORDERED ? 'numbered-list' : 'bulleted-list'
    return { children: [{ text: '' }] as Descendant[], ...props, type: nodeType } as CustomElement
  },
  createListItemNode(props = {}) {
    return { children: [{ text: '' }], ...props, type: 'list-item' }
  },
  createListItemTextNode(props = {}) {
    return { children: [{ text: '' }], ...props, type: 'list-item-text' }
  }
}
