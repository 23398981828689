// @ts-nocheck
import { observable, computed, action } from 'mobx'
import { AccordionPresenter, CheckBoxFilterPresenter } from 'Components/Molecules'
import { FiltersRepository } from 'app/Filters/FiltersRepository'
import { container } from 'app/Config/IOC'

export class FilterDisclosurePanelPresenter {
  @observable
  public filtersRepository: FiltersRepository = container.get(FiltersRepository)

  @observable
  public accordionPresenter: AccordionPresenter

  @observable
  public filterGroup: string

  public radioButtonOptions: { [key: string]: { [key: string]: string }[] }

  public defaultRadiobuttonOption: string

  public filterPanelType: string = 'checkbox'

  @computed
  public get checkboxPresenters(): CheckBoxFilterPresenter[] {
    if (this.filtersRepository.pageFilters.length === 0 || this.filterPanelType !== 'checkbox') return []

    return this.filtersRepository.pageFilters
      .filter(pageFilter => {
        return pageFilter.group === this.filterGroup
      })
      .map(filter => {
        return new CheckBoxFilterPresenter(filter.key)
      })
  }

  @computed
  public get selectedFiltersLength(): number {
    return this.filtersRepository.selectedFilters.filter(selectedFilter => {
      return selectedFilter.group === this.filterGroup
    }).length
  }

  public updateAccordionState = () => {
    if (this.selectedFiltersLength > 0) {
      this.accordionPresenter.state = 'highlight'
    } else {
      this.accordionPresenter.state = 'default'
    }
  }

  constructor(headingTitle: string, filterGroup: string) {
    this.accordionPresenter = new AccordionPresenter(headingTitle)
    this.filterGroup = filterGroup
  }

  public reset = () => this.accordionPresenter.reset()

  public withCollapsed = (collapsed: boolean) => {
    this.accordionPresenter.withCollapsed(collapsed)
    return this
  }

  @action
  public withExpandableCheckbox = (
    radioButtonOptions: { [key: string]: { [key: string]: string }[] },
    defaultRadiobuttonOption: string
  ) => {
    this.filterPanelType = 'expandableCheckbox'
    this.radioButtonOptions = radioButtonOptions
    this.defaultRadiobuttonOption = defaultRadiobuttonOption
    return this
  }

  @action
  public withCustomMultiSelect = () => {
    this.filterPanelType = 'customMultiSelect'
    return this
  }

  @action
  public withCustomSearch = () => {
    this.filterPanelType = 'customSearch'
    return this
  }

  @action
  public withDateTimeFilter = () => {
    this.filterPanelType = 'date'
    return this
  }
}
