import { i18n as Ii18n } from 'i18next'
import { KeyPrefix, Namespace, TFunction, useTranslation, UseTranslationOptions } from 'react-i18next'

/*
  This provides a hook for accessing the `t` translation function. `react-i18next` provides a well-typed
  hook `useTranslation` but we are extending it further:
    - Provide backwards compatibility for a `translate` function (vs using `t`)
    - Future work may integrate with optional string sanitization functionality

  In order to extend the return value of our custom hook and type properly we have to do some advanced
  TypeScript using generics.

  See the type definitions for react-i18next's `useTranslation` function for more.
*/

export type LanguageTranslate<N extends Namespace = 'common', TKPrefix extends KeyPrefix<N> = undefined> = TFunction<
  N,
  TKPrefix
>

export type UseLanguageResponse<N extends Namespace = 'common', TKPrefix extends KeyPrefix<N> = undefined> = {
  t: TFunction<N, TKPrefix>
  /** @deprecated use `t` instead of `translate` */
  translate: TFunction<N, TKPrefix>
  i18n: Ii18n
  ready: boolean
}

export function useLanguage<N extends Namespace = 'common', TKPrefix extends KeyPrefix<N> = undefined>(
  ns?: N | Readonly<N>,
  options?: UseTranslationOptions<TKPrefix>
): UseLanguageResponse<N, TKPrefix> {
  const { t, i18n, ready } = useTranslation(ns, options)

  return { t, translate: t, i18n, ready }
}
