// @ts-nocheck
import { observable } from 'mobx'
import { TPillContext } from './TPillContext'
import { PillPresenter } from './PillPresenter'
import { IColorHex, IColor } from 'Components/Molecules'

export class PillContextPresenter extends PillPresenter {
  @observable
  public context: TPillContext

  @observable
  public color: any

  public withContext? = context => {
    this.context = context
    return this
  }

  public withColor? = (color: IColor | IColorHex) => {
    this.color = color
    return this
  }
}
