// @ts-nocheck
import { observable, action } from 'mobx'
import { container } from 'app/Config/IOC'
import { RadioButtonsPresenter } from 'Components/Atoms'
import { Types } from 'Gateways/Service/Types'
import { ButtonPresenter } from 'Components/Molecules'
import { ILanguageService } from 'Helpers/Language/ILanguageService'

export class ExportUsersModalPresenter {
  private downloadXLSX: Function

  private downloadCSV: Function

  constructor(downloadCSV: Function, downloadXLSX: Function) {
    this.downloadCSV = downloadCSV
    this.downloadXLSX = downloadXLSX
  }

  public language: ILanguageService = container.get(Types.ILanguageService)

  @observable
  public close: Function

  @observable
  public cancelButtonPresenter = new ButtonPresenter(this.language.get('common:cancelButton'), () => {
    this.close && this.close()
    this.exportTypeRadioPresenter.selectedOption = 'CSV'
  })
    .withIconName('dnd-forwardslash')
    .withDisplayStyle('secondary')

  @action
  public exportFunction = async () => {
    this.exportButtonPresenter.withLoading(true)
    this.exportButtonPresenter
    if (this.exportTypeRadioPresenter.selectedOption === 'Excel') {
      await this.downloadXLSX()
    }
    if (this.exportTypeRadioPresenter.selectedOption === 'CSV') {
      await this.downloadCSV()
    }
    this.close && this.close()
    this.exportButtonPresenter.withLoading(false)
    this.exportTypeRadioPresenter.selectedOption = 'CSV'
  }

  @observable
  public exportButtonPresenter = new ButtonPresenter(
    this.language.get('common:exportUsersButton:static'),
    this.exportFunction
  )
    .withIconName('download')
    .withLoadingText(this.language.get('common:exportUsersButton:loading'))

  @observable
  public exportTypeRadioPresenter = new RadioButtonsPresenter(
    [this.language.get('common:exportTypes:CSV'), this.language.get('common:exportTypes:Excel')],
    this.language.get('common:exportTypes:CSV')
  ).withLabel(this.language.get('common:exportModal:radioButtonLabel'))

  @action
  public withClose = (close: Function) => {
    this.close = close
    return this
  }
}
