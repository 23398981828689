// @ts-nocheck
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { RunbookUsersPageHeadingPresenter } from './RunbookUsersPageHeadingPresenter'
import { RunbookUsersPageHeadingOptionsComponent } from './RunbookUsersPageHeadingOptionsComponent'
import { HeadingTitle } from 'Shared/Components/Templates'
import { SearchBox, PillFilter } from 'Components/Molecules'
import { buildFractionString } from 'Shared/HooksAndPresenters/HeadingSection'
import { px2rem } from 'app/Styles/Variables'

const HeaderWrapper = styled.div`
  width: 100%;
  height: 100%;
`

const TitleWrapper = styled.div`
  padding-left: ${px2rem(16)};
`

const RightContainer = styled.div`
  z-index: 2;
  position: absolute;
  padding-right: ${px2rem(50)};
  padding-left: ${px2rem(6)};
  top: 0;
  right: 0;
`

interface IRunbookUsersPageHeadingComponentProps {
  presenter: RunbookUsersPageHeadingPresenter
}

export const RunbookUsersPageHeadingSection = observer<IRunbookUsersPageHeadingComponentProps>(({ presenter }) => {
  const {
    filteredUserCount,
    totalUserCount,
    language,
    searchBoxPresenter,
    searchPillPresenter,
    optionsPresenter,
    title
  } = presenter
  const { countString } = buildFractionString(filteredUserCount, totalUserCount)

  return (
    <HeaderWrapper>
      <TitleWrapper>
        <HeadingTitle title={title} count={countString} pills={<PillFilter presenter={searchPillPresenter} />} />
      </TitleWrapper>
      <RightContainer>
        <RunbookUsersPageHeadingOptionsComponent presenter={optionsPresenter} />
        <SearchBox
          presenter={searchBoxPresenter}
          searchContent={language.get('runbookUsers:heading:searchContent')}
          language={language}
        />
      </RightContainer>
    </HeaderWrapper>
  )
})
