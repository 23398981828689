import { useCallback, useEffect, useRef } from 'react'
import { eventManager } from 'event-manager'

import { useNotify } from '@cutover/react-ui'
import { RunbookTypeCreateForm } from './runbook-type-create-form'
import { useLanguage } from 'main/services/hooks'

export function RunbookTypeCreateFormIntegration({
  onSave,
  reloadRunbookTypes,
  isGlobalUser,
  availableApprovalFlows
}: {
  onSave: () => void
  reloadRunbookTypes: () => void
  isGlobalUser: () => boolean
  availableApprovalFlows: () => []
}) {
  const { translate } = useLanguage()
  const refSubmitButton = useRef<HTMLButtonElement>(null)
  const notify = useNotify()

  // Submit the runbook type create form programatically
  // See https://github.com/react-hook-form/react-hook-form/issues/566#issuecomment-786550378 for details
  const handleModalSaved = () => {
    refSubmitButton?.current?.click()
  }

  const handleSaveComplete = useCallback(() => {
    notify.success(translate('runbookTypes:toasters:add:notification:message'), {
      title: translate('runbookTypes:toasters:add:notification:title')
    })

    reloadRunbookTypes()

    onSave()
  }, [onSave])

  useEffect(() => {
    eventManager.on('modal-saved', handleModalSaved)

    return () => {
      eventManager.off('modal-saved', handleModalSaved)
    }
  }, [])

  return (
    <RunbookTypeCreateForm
      availableApprovalFlows={availableApprovalFlows()}
      isGlobalUser={isGlobalUser}
      onSave={handleSaveComplete}
    >
      <button hidden={true} ref={refSubmitButton} type={'submit'} />
    </RunbookTypeCreateForm>
  )
}
