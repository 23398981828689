// @ts-nocheck
import { observable, observe, action } from 'mobx'
import { CheckboxPresenter } from 'Components/Molecules'
import { colors } from 'app/Styles/Variables'
import { GenericResourceFilter } from 'app/Filters/GenericResourceFilter/GenericResourceFilter'
import { GenericBooleanFilter } from 'app/Filters/GenericBooleanFilter/GenericBooleanFilter'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'
import { Types } from 'Gateways/Service/Types'
import { container } from 'app/Config/IOC'

interface IIntialBooleanFilterArgs {
  type: 'boolean' | 'resource'
  reference: string
  label: string
  resourceId?: string
  group?: string
}

export class OldCheckboxFilterPresenter {
  public language: ILanguageService = container.get(Types.ILanguageService)

  @observable
  public type: 'boolean' | 'resource'

  @observable
  public label: string

  @observable
  public inverseLabel: string

  @observable
  public checkboxPresenter: CheckboxPresenter

  @observable
  public filter: GenericResourceFilter | GenericBooleanFilter

  constructor(args: IIntialBooleanFilterArgs, setObservers: boolean = true) {
    this.checkboxPresenter = new CheckboxPresenter(args.label, false).withMiddleware(value => {
      this.filter.middlewareFunction(value)
      return value
    })

    this.label = args.label
    this.type = args.type

    if (setObservers) {
      if (args.type === 'boolean') {
        this.filter = new GenericBooleanFilter({ reference: args.reference })
        this.setObserverForBoolean()
      }

      if (args.type === 'resource') {
        this.filter = new GenericResourceFilter({ reference: args.reference, resourceId: args.resourceId })
        this.setObserverForResource(args.resourceId)
      }

      if (args.group) this.filter.group = args.group
    }
  }

  private setObserverForBoolean = () => {
    observe(
      this.filter,
      'valueInUrl',
      () => {
        this.checkboxPresenter.value = !!this.filter.valueInUrl
        this.checkboxPresenter.eventIconVisible = !!this.filter.valueInUrl
      },
      true
    )
  }

  private setObserverForResource = (resourceId: string) => {
    observe(
      this.filter,
      'valueInUrl',
      () => {
        if (this.filter.valueInUrl && this.filter.valueInUrl.value === resourceId) {
          this.checkboxPresenter.value = true
        } else {
          this.checkboxPresenter.value = false
        }
      },
      true
    )
  }

  @action
  private toggleInverseIcon = isInverse => {
    if (isInverse) {
      this.checkboxPresenter.label = this.inverseLabel
      this.checkboxPresenter.eventIconColor = colors.primary
      this.checkboxPresenter.eventIconRotation = 180
    } else {
      this.checkboxPresenter.label = this.label
      this.checkboxPresenter.eventIconColor = colors.bgLight
      this.checkboxPresenter.eventIconRotation = 0
    }
  }

  public toggleInverse = () => {
    const filter = this.filter as GenericBooleanFilter
    filter.isInverse = !filter.isInverse
    filter.refreshFilteredRoute()
    filter.onChangeCallback && filter.onChangeCallback(filter.reference)
    this.toggleInverseIcon(filter.isInverse)
  }

  public withOnChangeCallback = (callback: (reference: string, resourceId?: string) => void | Promise<void>) => {
    this.filter.withOnChangeCallback(callback)
    return this
  }

  public withCanBeInverse = ({
    inverseLabel,
    inverseTrueString = '0'
  }: {
    inverseLabel: string
    inverseTrueString?: string
  }) => {
    if (this.type === 'resource') return this

    const filter = this.filter as GenericBooleanFilter

    filter.filterCanBeInverse({ inverseTrueString })

    this.inverseLabel = inverseLabel

    this.checkboxPresenter = new CheckboxPresenter(this.checkboxPresenter.label, false)
      .withMiddleware(value => {
        if (value) this.checkboxPresenter.eventIconVisible = true
        if (!value) {
          this.checkboxPresenter.eventIconVisible = false
          filter.isInverse = false
          this.toggleInverseIcon(false)
        }
        filter.middlewareFunction(value)
        return value
      })
      .withEventIcon('swap')
      .withEventIconEvent(() => {
        this.toggleInverse()
      })
      .withEventIconText(this.language.get('components:molecules:checkboxFilter:eventIconText'))
      .withOneLineTooltip()
      .withEventIconTextPosition('top center')

    observe(
      this.filter,
      'valueInUrl',
      () => {
        this.checkboxPresenter.value = !!this.filter.valueInUrl
        this.checkboxPresenter.eventIconVisible = !!this.filter.valueInUrl
      },
      true
    )

    observe(
      filter,
      'valueInUrl',
      () => {
        if (filter.valueInUrl && filter.valueInUrl.value === inverseTrueString) {
          this.toggleInverseIcon(true)
        }
      },
      true
    )

    return this
  }

  @action
  public withFilter = (filter: GenericResourceFilter | GenericBooleanFilter) => {
    this.filter = filter

    if (filter instanceof GenericResourceFilter) {
      this.setObserverForResource(filter.resourceId)
    } else {
      this.setObserverForBoolean()
    }

    return this
  }

  public clear = () => {
    this.checkboxPresenter.value = false
    this.filter.isPopulated = false
    if (this.type === 'boolean') {
      this.checkboxPresenter.eventIconVisible = false
      const filter = this.filter as GenericBooleanFilter
      if (filter.canBeInverse) {
        this.toggleInverseIcon(false)
        filter.isInverse = false
      }
    }
  }
}
