import styled from 'styled-components/macro'

import { Box } from '../../../../layout'
import { overlayStyles } from '../../../../theme'

// TODO: get reusable radius...
export const SelectMenuDrop = styled(Box).attrs(() => ({
  background: 'bg'
}))<{ isOpen: boolean }>`
  width: 100%;
  z-index: 10000;

  ${props => props.isOpen && overlayStyles()}
  padding: 0;
`
