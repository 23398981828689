// @ts-nocheck
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'

import { Accordion } from 'Components/Molecules/Accordion'
import { AddGenericWithResultModalComponent } from 'Components/Atoms/Modal'
import { addSectionHooks } from 'Shared/HooksAndPresenters/AddEditSection'
import { Button } from 'Components/Molecules/Button'
import { colors, px2rem } from 'app/Styles/Variables'
import { container } from 'Config/IOC'
import { UserEditUserAppTokensPresenter } from './UserEditUserAppTokensPresenter'
import { UserEditOauthClientSessionsPresenter } from 'app/Access/Users/UsersPage/UsersEdit/UserEditOauthClientSessionsPresenter'
import { UserOauthClientSession } from 'Components/Organisms/UserOauthClientSession'

const ButtonWrapper = styled.div`
  margin-top: ${px2rem(12)};
`
const OauthClientSessionsWrapper = styled.div`
  margin-top: ${px2rem(12)};
`
const UserOauthClientSessionsWrapper = styled.div`
  margin-bottom: ${px2rem(9)};
  padding-bottom: ${px2rem(6)};
  border-bottom: ${px2rem(1)} dashed ${colors.primaryGreyHoverBackgroundColor};
`
const Wrapper = styled.div``

interface IUserEditOauthClientSessionsComponentProps {
  presenter: UserEditOauthClientSessionsPresenter
}

export const UserEditOauthClientSessionsSection = observer<IUserEditOauthClientSessionsComponentProps>(
  ({ presenter }) => {
    const { userOauthClientSessionPresenters, accordionPresenter, disabled, language, loading, user } = presenter

    const oauthList = userOauthClientSessionPresenters.map(oauthPresenter => {
      return (
        <UserOauthClientSessionsWrapper key={oauthPresenter.clientSession.id}>
          <UserOauthClientSession presenter={oauthPresenter} />
        </UserOauthClientSessionsWrapper>
      )
    })

    return (
      <>
        <Accordion presenter={accordionPresenter}>
          {loading ? (
            language.get('common:loading')
          ) : (
            <Wrapper>
              <OauthClientSessionsWrapper>{oauthList.length > 0 ? oauthList : null}</OauthClientSessionsWrapper>
            </Wrapper>
          )}
        </Accordion>
      </>
    )
  }
)
