// @ts-nocheck
import { useEffect } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'

import { ErrorAlert } from 'Components/Molecules/Alert/ErrorAlert'
import { TextInput } from 'Components/Molecules/TextInput'
import { UserEditUserAppTokensAddPresenter } from './UserEditUserAppTokensAddPresenter'
import { withPresenter } from 'app/Config/withPresenter'

const InfoTextWrapper = styled.p``
const TokenCodeWrapper = styled.p``
const TokenCode = styled.code``
const Wrapper = styled.div`
  overflow-y: auto;
`

interface IUserEditUserAppTokensAddComponentProps {
  postContinueCallback: () => void
  presenter: UserEditUserAppTokensAddPresenter
  submitted: boolean
}

const UserEditUserAppTokensAddSection = observer<IUserEditUserAppTokensAddComponentProps>(
  ({ presenter, postContinueCallback }) => {
    const {
      continued,
      createdToken,
      errorAlertPresenter,
      isSubmitted,
      labelPresenter,
      language,
      processContinue,
      submit,
      submitSucceeded,
      submitted,
      userAppTokenAddForm
    } = presenter

    presenter.withPostContinueCall(postContinueCallback)

    useEffect(() => {
      if (submitted) {
        submit()
      }
    }, [submitted])

    useEffect(() => {
      if (continued) {
        processContinue()
      }
    }, [continued])

    return (
      <Wrapper>
        {submitSucceeded ? (
          <>
            <InfoTextWrapper>{language.get('userAppTokens:add:postCreateInfoText')}</InfoTextWrapper>
            <TokenCodeWrapper>
              <TokenCode>{createdToken}</TokenCode>
            </TokenCodeWrapper>
          </>
        ) : (
          <form>
            {isSubmitted && errorAlertPresenter.errorMessages ? <ErrorAlert presenter={errorAlertPresenter} /> : ''}
            <TextInput presenter={labelPresenter} parentIsFocused />
          </form>
        )}
      </Wrapper>
    )
  }
)

export default withPresenter(UserEditUserAppTokensAddPresenter)(UserEditUserAppTokensAddSection)
