// @ts-nocheck
import { useState, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import { Icon } from 'Components/Atoms/Icon'
import { colors, transition, px2rem } from 'app/Styles/Variables'
import { TAccordionState } from './TAccordionState'
import { observer } from 'mobx-react-lite'
import { AccordionPresenter } from '.'

const collapseTransition = transition(undefined, 'height')

const Wrapper = styled.div`
  display: block;
  border-bottom: ${px2rem(1)} solid ${colors.bgLighter};
`

const Header = styled.div`
  position: relative;
  cursor: pointer;
  line-height: ${px2rem(56)};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: ${px2rem(18)};
  color: ${colors.textLight};
`

interface IHeadingWrapperProps {
  state: TAccordionState
}

const HeadingWrapper = styled.span`
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 400;
  text-overflow: ellipsis;
  max-width: ${px2rem(300)};
  width: 100%;
  padding-left: ${px2rem(8)};

  ${(props: IHeadingWrapperProps) => {
    if (props.state === 'error') {
      return css`
        color: ${colors.error};
      `
    }
    if (props.state === 'highlight') {
      return css`
        color: ${colors.text};
      `
    }
    if (props.state === 'default') {
      return css`
        &:hover {
          color: ${colors.text};
        }
      `
    }
  }};
`

const IconWrapper = styled.span`
  flex-grow: 0;
  padding-right: ${px2rem(8)};
  display: flex;
`

const Content = styled.div`
  overflow: hidden;
  height: auto;
  ${collapseTransition};
`

const InnerContent = styled.div`
  padding: 0 ${px2rem(4)} ${px2rem(16)};
`

const Pill = styled.span`
  position: absolute;
  right: ${px2rem(34)};
  top: ${px2rem(18)};
  color: ${colors.primaryGrey};
  border-radius: ${px2rem(12)};
  padding: 0 ${px2rem(6)};
  border: ${px2rem(1)} solid ${colors.primaryGreyBackgroundColor};
  background-color: ${colors.primaryGreyBackgroundColor};
  font-size: ${px2rem(13)};
  line-height: ${px2rem(17)};
`
interface IAccodionProps {
  presenter: AccordionPresenter
  extraStyles?: CSSProperties
  children: ReactNode
}

export const Accordion = observer<IAccodionProps>(({ presenter, extraStyles, children }) => {
  const {
    collapsed,
    heading,
    setCollapsed,
    state,
    leftIconName,
    leftIconColor,
    rightIconName,
    rightIconColor,
    counter,
    iconRightAction
  } = presenter

  const [contentInitialHeight] = useState(collapsed ? 0 : 'auto')
  const contentRef = useRef(null)
  const [isCollapsed, setIsCollapsed] = useState(collapsed)
  const [chevronRotation, setChevronRotation] = useState(collapsed ? 0 : 90)
  const [animationFrameDisposal, setAnimationFrameDisposal] = useState(null)

  const handleClick = () => {
    if (!contentRef.current) return
    const { scrollHeight } = contentRef.current
    let animationFrame
    if (!isCollapsed) {
      // if not collapsed then collapse
      contentRef.current.style.transition = ''
      animationFrame = requestAnimationFrame(() => {
        if (!contentRef.current) return
        setChevronRotation(0)
        contentRef.current.style.height = scrollHeight + 'px'
        contentRef.current.style.transition = collapseTransition
        animationFrame = requestAnimationFrame(() => {
          if (!contentRef.current) return
          contentRef.current.style.height = 0
          setIsCollapsed(true)
          setCollapsed(true)
        })
      })
      setAnimationFrameDisposal(animationFrame)
    }

    if (isCollapsed) {
      // if collapsed then expand
      contentRef.current.style.height = scrollHeight + 'px'
      setChevronRotation(90)
      const handler = () => {
        if (!contentRef.current) return
        contentRef.current.removeEventListener('transitionend', handler)
        contentRef.current.style.height = null
        setIsCollapsed(false)
        setCollapsed(false)
      }
      contentRef.current.addEventListener('transitionend', handler)
    }
  }

  useEffect(() => {
    if (collapsed !== isCollapsed) {
      handleClick()
    }
    return () => cancelAnimationFrame(animationFrameDisposal)
  }, [collapsed])

  const IconRenderLeft = () => (
    <IconWrapper>
      <Icon name={leftIconName} color={leftIconColor} size="24px" />
    </IconWrapper>
  )

  const iconRightClickHandler = iconRightAction
    ? e => {
        e.stopPropagation()
        iconRightAction()
      }
    : () => null

  const IconRenderRight = () => (
    <IconWrapper onClick={iconRightClickHandler}>
      <Icon name={rightIconName} color={rightIconColor} size="24px" />
    </IconWrapper>
  )

  const CounterRender = () => <Pill>{counter}</Pill>

  return (
    <Wrapper>
      <Header onClick={handleClick}>
        {leftIconName && <IconRenderLeft />}
        <HeadingWrapper state={state}>{heading}</HeadingWrapper>
        {rightIconName && <IconRenderRight />}
        {counter && <CounterRender />}
        <Icon name="chevron-right" rotation={chevronRotation} color={colors.secondaryGrey} size="24px" />
      </Header>
      <Content style={{ height: contentInitialHeight, overflow: collapsed ? 'hidden' : 'visible' }} ref={contentRef}>
        <InnerContent style={extraStyles}>{children}</InnerContent>
      </Content>
    </Wrapper>
  )
})
