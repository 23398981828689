export const dynamicVariablesTooltipLabelHelper = (
  input: string = '',
  translationKey: string,
  t: (key: string, options: { variables: string }) => string
) => {
  const regex = /{{(.*?)}}/g
  let matches: string[] = []
  let match: RegExpExecArray | null

  while ((match = regex.exec(input)) !== null) {
    matches.push(match[1].trim())
  }

  const joinedMatches = matches.join(', ')
  return t(translationKey, { variables: joinedMatches })
}

export const hasDynamicVariableTemplate = (input: string = '') => {
  const regex = /{{(.*?)}}/g
  return regex.test(input)
}
