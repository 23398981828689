import { ListItem, RunbookTypeIcon } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'

type SnippetItemProps = {
  id: number
  name: string
  selected?: boolean
  hasNext?: boolean
  tasksCount: number
  onClick: () => void
}

export const SnippetItem = ({ id, name, selected, tasksCount, onClick, hasNext }: SnippetItemProps) => {
  const { t } = useLanguage('runbook', { keyPrefix: 'addSnippet' })
  const subTitle = `#${id} \u2022 ${t('tasksCount', { count: tasksCount })}`
  return (
    <ListItem
      key={id}
      role="option"
      size="large"
      startComponent={<RunbookTypeIcon icon="snippet" color="feature-blue-bright" template />}
      onClick={onClick}
      a11yTitle={name}
      title={name}
      subTitle={subTitle}
      selected={selected}
      hasNext={hasNext}
    />
  )
}
