import { useEffect, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm, useFormState } from 'react-hook-form'
import * as yup from 'yup'

import { Box, Message, Modal, RadioboxGroup, Select, TextInput } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { TaskType } from 'main/services/queries/types'

type LinkTemplateBulkCreateOptionsModalProps = {
  taskTypes?: TaskType[]
  initialRunStyle?: 'sequence' | 'parallel'
  onBack: () => void
  onClose: () => void
  isLoading?: boolean
  isError?: boolean
  onSubmit: (form: BulkCreateTaskFormProps) => void
  errorMsg?: string
}

type BulkCreateTaskFormProps = {
  task_type: number
  order: 'sequence' | 'parallel'
}

export const LinkTemplateBulkCreateOptionsModal = ({
  onBack,
  onClose,
  initialRunStyle = 'sequence',
  taskTypes,
  isLoading,
  isError,
  onSubmit,
  errorMsg
}: LinkTemplateBulkCreateOptionsModalProps) => {
  const { t } = useLanguage('runbook', { keyPrefix: 'bulkCreateTaskOptionsModal' })
  const { control, handleSubmit, setValue } = useForm<BulkCreateTaskFormProps>({
    resolver: yupResolver(bulkCreateTaskOptionsSchema),
    defaultValues: {
      order: initialRunStyle
    }
  })
  const { errors } = useFormState({ control })
  const [linkableTaskTypes, setLinkableTaskTypes] = useState<TaskType[] | undefined>([])

  useEffect(() => {
    const filteredTypes = taskTypes
      ?.filter(type => type.linkable && type.key !== 'snippet')
      .sort((a, b) => a.name.localeCompare(b.name))

    if (filteredTypes && filteredTypes.length > 0) {
      setValue('task_type', filteredTypes[0].id)
      setLinkableTaskTypes(filteredTypes)
    }
  }, [taskTypes])

  const getTaskTypeName = (id: number) => {
    const taskType = taskTypes?.find(taskType => taskType.id === id)
    return taskType ? taskType.name : ''
  }

  return (
    <Modal
      title={t('heading')}
      open
      loading={isLoading}
      onClickBack={onBack}
      onClose={onClose}
      onClickConfirm={isLoading || isError ? undefined : () => handleSubmit(onSubmit)()}
      confirmIcon="arrow-forward"
      confirmText={t('submit')}
    >
      <Box direction="column">
        {isError && (
          <Message
            type="error"
            message={errorMsg || t('errorMessage')}
            css={`
              margin-bottom: 16px;
            `}
          />
        )}
        <Controller
          name="task_type"
          control={control}
          render={({ field: { onChange, value, ref } }) =>
            taskTypes?.length === 1 ? (
              <Box css="margin-bottom: -26px;">
                <TextInput
                  disabled
                  label={t('taskTypeSelectLabel')}
                  icon="link"
                  type="text"
                  value={getTaskTypeName(value)}
                />
              </Box>
            ) : (
              <Select
                onChange={onChange}
                required
                inputRef={ref}
                icon="link"
                data-testid="bulk-create-task-options-modal-task-select"
                label={t('taskTypeSelectLabel')}
                value={value}
                loading={isLoading}
                options={
                  linkableTaskTypes
                    ? linkableTaskTypes.map(taskType => ({ label: taskType.name, value: taskType.id }))
                    : []
                }
              />
            )
          }
        />
        <Controller
          name="order"
          control={control}
          defaultValue="sequence"
          render={({ field: { name, onChange, value, onBlur, ref } }) => (
            <RadioboxGroup
              name={name}
              value={value}
              onChange={onChange}
              label={t('orderRadioGroupLabel')}
              direction="row"
              hasError={!!errors.order}
              required
              onBlur={onBlur}
              ref={ref}
              options={[
                {
                  value: 'sequence',
                  label: t('sequence')
                },
                {
                  value: 'parallel',
                  label: t('parallel')
                }
              ]}
            />
          )}
        />
      </Box>
    </Modal>
  )
}

const bulkCreateTaskOptionsSchema = yup.object({
  task_type: yup.string().required(),
  order: yup.string().required()
})
