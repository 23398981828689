import { SubHeaderButton, SubHeaderButtonGroup } from 'main/components/layout/shared/sub-header/sub-header-button'
import { useClearFilterParam, useFilter, useSetFilterParams } from 'main/components/shared/filter/filter-provider'
import { useLanguage } from 'main/services/hooks'

const WORKSPACE_TEMPLATE_STATUS = ['template_approved', 'template_rejected', 'template_draft'] as const

export const TemplateStatusToggle = () => {
  const { t } = useLanguage('runbooks', { keyPrefix: 'templateStatusToggleOptions' })
  const setFilterParams = useSetFilterParams()
  const clearFilterParam = useClearFilterParam()
  const viewingStatus = useFilter<(typeof WORKSPACE_TEMPLATE_STATUS)[number][]>('ts')
  const viewingMultipleStatuses = Array.isArray(viewingStatus) && viewingStatus.length > 1

  const handleClickOption = (status?: (typeof WORKSPACE_TEMPLATE_STATUS)[number]) => {
    // clicked the single active status in toggle (or clicked all and not viewing a status); don't do anything
    if ((viewingStatus && !viewingMultipleStatuses && viewingStatus[0] === status) || (!viewingStatus && !status)) {
      return
    }

    if (!status) {
      clearFilterParam('ts')
    } else {
      setFilterParams({ ts: [status] })
    }
  }

  return (
    <SubHeaderButtonGroup>
      <SubHeaderButton label={t('all')} active={!viewingStatus} onClick={() => handleClickOption()} />
      <SubHeaderButton
        label={t('approved')}
        active={!viewingMultipleStatuses && viewingStatus?.[0] === 'template_approved'}
        onClick={() => handleClickOption('template_approved')}
      />
      <SubHeaderButton
        label={t('rejected')}
        active={!viewingMultipleStatuses && viewingStatus?.[0] === 'template_rejected'}
        onClick={() => handleClickOption('template_rejected')}
      />
      <SubHeaderButton
        label={t('draft')}
        active={!viewingMultipleStatuses && viewingStatus?.[0] === 'template_draft'}
        onClick={() => handleClickOption('template_draft')}
      />
    </SubHeaderButtonGroup>
  )
}
