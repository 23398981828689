import { BrandAnalyze } from './analyze'
import { BrandAuditAndDiscover } from './audit-and-discover'
import { BrandCalendarEvent } from './calendar-event'
import { BrandChangeExecution } from './change-execution'
import { BrandChangeGovernance } from './change-governance'
import { BrandChangePlanning } from './change-planning'
import { BrandCloudMigration } from './cloud-migration'
import { BrandCloudNative } from './cloud-native'
import { BrandCollaborationAndCommunications } from './collaboration-and-communications'
import { BrandDatacenterMigration } from './datacenter-migration'
import { BrandEnergy } from './energy'
import { BrandFailover } from './failover'
import { BrandFeatures } from './features'
import { BrandFinancialServices } from './financial-services'
import { BrandGettingStarted } from './getting-started'
import { BrandInsurance } from './insurance'
import { BrandIntegrations } from './integrations'
import { BrandIpo } from './ipo'
import { BrandMAndA } from './m-and-a'
import { BrandMail } from './mail'
import { BrandOperationalReadiness } from './operational-readiness'
import { BrandOrchestrate } from './orchestrate'
import { BrandPartnerships } from './partnerships'
import { BrandPharma } from './pharma'
import { BrandPlan } from './plan'
import { BrandPlatformImplementation } from './platform-implementation'
import { BrandProductUpdates } from './product-updates'
import { BrandRehearseTest } from './rehearse-test'
import { BrandRelease } from './release'
import { BrandResilienceTesting } from './resilience-testing'
import { BrandResilience } from './resilience'
import { BrandSecurity } from './security'
import { BrandSnippet } from './snippet'
import { BrandSocial } from './social'
import { BrandSupport } from './support'
import { BrandTechDeliveryAndRelease } from './tech-delivery-and-release'
import { BrandTechOps } from './tech-ops'
import { BrandTelco } from './telco'
import { BrandTransformation } from './transformation'
export {
  BrandAnalyze,
  BrandAuditAndDiscover,
  BrandCalendarEvent,
  BrandChangeExecution,
  BrandChangeGovernance,
  BrandChangePlanning,
  BrandCloudMigration,
  BrandCloudNative,
  BrandCollaborationAndCommunications,
  BrandDatacenterMigration,
  BrandEnergy,
  BrandFailover,
  BrandFeatures,
  BrandFinancialServices,
  BrandGettingStarted,
  BrandInsurance,
  BrandIntegrations,
  BrandIpo,
  BrandMAndA,
  BrandMail,
  BrandOperationalReadiness,
  BrandOrchestrate,
  BrandPartnerships,
  BrandPharma,
  BrandPlan,
  BrandPlatformImplementation,
  BrandProductUpdates,
  BrandRehearseTest,
  BrandRelease,
  BrandResilienceTesting,
  BrandResilience,
  BrandSecurity,
  BrandSnippet,
  BrandSocial,
  BrandSupport,
  BrandTechDeliveryAndRelease,
  BrandTechOps,
  BrandTelco,
  BrandTransformation
}
export const BRAND_ICON_NAMES = [
  'analyze',
  'audit-and-discover',
  'calendar-event',
  'change-execution',
  'change-governance',
  'change-planning',
  'cloud-migration',
  'cloud-native',
  'collaboration-and-communications',
  'datacenter-migration',
  'energy',
  'failover',
  'features',
  'financial-services',
  'getting-started',
  'insurance',
  'integrations',
  'ipo',
  'm-and-a',
  'mail',
  'operational-readiness',
  'orchestrate',
  'partnerships',
  'pharma',
  'plan',
  'platform-implementation',
  'product-updates',
  'rehearse-test',
  'release',
  'resilience-testing',
  'resilience',
  'security',
  'snippet',
  'social',
  'support',
  'tech-delivery-and-release',
  'tech-ops',
  'telco',
  'transformation'
] as const
type PropsOf<TComponent> = TComponent extends React.ComponentType<infer P> ? P : never
export type BrandIconProps = PropsOf<typeof BrandAnalyze>
export type BrandIconName = typeof BRAND_ICON_NAMES[number]
export const BRAND_ICON_NAME_MAP: Record<BrandIconName, typeof BrandAnalyze> = {
  'analyze': BrandAnalyze,
  'audit-and-discover': BrandAuditAndDiscover,
  'calendar-event': BrandCalendarEvent,
  'change-execution': BrandChangeExecution,
  'change-governance': BrandChangeGovernance,
  'change-planning': BrandChangePlanning,
  'cloud-migration': BrandCloudMigration,
  'cloud-native': BrandCloudNative,
  'collaboration-and-communications': BrandCollaborationAndCommunications,
  'datacenter-migration': BrandDatacenterMigration,
  'energy': BrandEnergy,
  'failover': BrandFailover,
  'features': BrandFeatures,
  'financial-services': BrandFinancialServices,
  'getting-started': BrandGettingStarted,
  'insurance': BrandInsurance,
  'integrations': BrandIntegrations,
  'ipo': BrandIpo,
  'm-and-a': BrandMAndA,
  'mail': BrandMail,
  'operational-readiness': BrandOperationalReadiness,
  'orchestrate': BrandOrchestrate,
  'partnerships': BrandPartnerships,
  'pharma': BrandPharma,
  'plan': BrandPlan,
  'platform-implementation': BrandPlatformImplementation,
  'product-updates': BrandProductUpdates,
  'rehearse-test': BrandRehearseTest,
  'release': BrandRelease,
  'resilience-testing': BrandResilienceTesting,
  'resilience': BrandResilience,
  'security': BrandSecurity,
  'snippet': BrandSnippet,
  'social': BrandSocial,
  'support': BrandSupport,
  'tech-delivery-and-release': BrandTechDeliveryAndRelease,
  'tech-ops': BrandTechOps,
  'telco': BrandTelco,
  'transformation': BrandTransformation
}