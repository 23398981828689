import { useMemo } from 'react'
import { groupBy, partition } from 'lodash'

import { PermittedProject } from 'main/services/queries/use-permitted-resources'
import { RunbookBaseRunbook } from 'main/services/queries/types'

export type PermittedProjectOption = PermittedProject & {
  name_hierarchy?: string
  level?: number
}

// Sort the folders into their parent/child order, alphabtical by name, but flattened
export const useFolderOptions = ({ folders }: { folders: PermittedProject[] }): PermittedProjectOption[] => {
  const [parentFolders, childFolders] = partition(folders, f => !f.parent_id)
  const sortedParentFolders = parentFolders.sort((a, b) => a.name.localeCompare(b.name))
  const groupedChildFolders = groupBy(childFolders, 'parent_id')

  return useMemo(() => {
    return sortedParentFolders.reduce((hierarchy: PermittedProjectOption[], parentFolder) => {
      hierarchy.push({ ...parentFolder, level: 1 })

      const parentName = parentFolder.name.length > 10 ? parentFolder.name.slice(0, 10) + '...' : parentFolder.name

      if (groupedChildFolders[parentFolder.id]) {
        const childFolders = groupedChildFolders[parentFolder.id].sort((a, b) => a.name.localeCompare(b.name))
        const folderOptions: PermittedProjectOption[] = childFolders.map(childFolder => ({
          ...childFolder,
          name_hierarchy: parentName + ' / ' + childFolder.name,
          level: 2
        }))
        hierarchy.push(...folderOptions)
      }

      return hierarchy
    }, [])
  }, [folders])
}

type UseFolderDefaultValueProps = {
  folders: PermittedProject[]
  runbook?: RunbookBaseRunbook
  filter?: number[]
}

export const useFolderDefaultOption = ({ folders, runbook, filter }: UseFolderDefaultValueProps) => {
  const flattenedEnabledFolders = useFolderOptions({ folders }).filter(f => !f.disabled)

  let defaultFolder: PermittedProject | undefined

  if (runbook) {
    defaultFolder = flattenedEnabledFolders.find(f => f.id === runbook.project_id)
  } else if (filter && filter.length) {
    defaultFolder = flattenedEnabledFolders.find(f => f.id === filter[0])
  }

  return defaultFolder ?? flattenedEnabledFolders[0]
}
