import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const TaskTypeDiamond100 = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="TaskTypeDiamond100" {...props}>
    <path d="M39.556 22.588a2 2 0 0 1 0 2.828L25.414 39.56a2 2 0 0 1-2.828 0L8.444 25.417a2 2 0 0 1 0-2.829L22.586 8.446a2 2 0 0 1 2.828 0l14.142 14.142ZM28.243 5.618a6 6 0 0 0-8.486 0L5.615 19.76a6 6 0 0 0 0 8.485l14.142 14.142a6 6 0 0 0 8.486 0l14.142-14.142a6 6 0 0 0 0-8.485L28.243 5.617Z" fill="#000" />
  </StyledIcon>
))

TaskTypeDiamond100.displayName = 'TaskTypeDiamond100'

export { TaskTypeDiamond100 }
