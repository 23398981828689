import { ThemeType as GrommetThemeType } from 'grommet/themes'
import { css } from 'styled-components/macro'
import { SetRequired } from 'type-fest'

import { resolveColor } from './grommet-utils'
import { ThemeType } from './theme'

type GrommetTheme = SetRequired<GrommetThemeType, 'menu'>
type GrommetMenu = Partial<GrommetTheme['menu']>

export const menu: GrommetMenu = {
  drop: {
    round: 'small',
    elevation: 'medium',
    pad: '11px'
  },
  // @ts-ignore
  extend: css`
    color: ${({ theme }: { theme: ThemeType }) => resolveColor('text-light', theme)};
    padding: 8px;
    button {
      &:hover {
        background: ${({ theme }: { theme: ThemeType }) => resolveColor('bg-1', theme)};
        color: ${({ theme }: { theme: ThemeType }) => resolveColor('text', theme)};
        svg {
          fill: ${({ theme }: { theme: ThemeType }) => resolveColor('text', theme)};
        }
      }
      border-radius: 6px;
      height: 40px;
      > div {
        padding: 0;
        align-items: center;
      }
    }
  `
}
