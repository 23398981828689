// @ts-nocheck
import styled from 'styled-components'
import { colors, windowSizes, px2rem } from 'app/Styles/Variables'

const TitleContainer = styled.h3`
  color: ${colors.text};
  font-size: ${px2rem(18)};
  font-weight: 400;
  z-index: 1;
  margin: 0;
  line-height: ${px2rem(64)};
`

const BreadcrumbContainer = styled.span`
  color: ${colors.primaryGrey};
  position: relative;
  vertical-align: middle;
`

const BreadcrumbCountContainer = styled.span`
  transition: all 0.25s cubic-bezier(0.35, 0, 0.25, 1);
  color: ${colors.secondaryGrey};
  font-size: ${px2rem(18)};
`

interface IHeadingTitleProps {
  title: string
  count?: string
  pills?: JSX.Element[] | JSX.Element
}

export const HeadingTitle = (props: IHeadingTitleProps) => {
  const { title, count, pills } = props

  return (
    <TitleContainer>
      <BreadcrumbContainer>{title}</BreadcrumbContainer>
      &nbsp;
      {count && <BreadcrumbCountContainer>{count}</BreadcrumbCountContainer>}
      &nbsp;
      {window.innerWidth > windowSizes.sm && pills && pills}
    </TitleContainer>
  )
}
