import { KeyboardEvent, ReactNode, useEffect, useRef, useState } from 'react'
import Select, { SingleValue, StylesConfig } from 'react-select'
import styled from 'styled-components/macro'

import { Input } from '../../form'
import { FormField } from '../../form/internal/form-field'
import { Icon } from '../../icon'
import { Box } from '../../layout/box/box'
import { Text } from '../../typography/text/text'

type Country = {
  name: string
  code: string
  dial: number | string
}

type CountryOption = {
  label: string
  value: string
}

export type PhoneNumberValue = {
  code: string | undefined
  number: string | undefined
}

type PhoneNumberProps = {
  label: string
  tooltipText: string
  required?: boolean
  countries: Country[]
  defaultCountryCode?: string
  hasError?: boolean
  inlineError?: string
  onChange: (value: PhoneNumberValue) => void
  value: PhoneNumberValue
}

export const PhoneNumber = ({
  label,
  tooltipText,
  required,
  countries,
  defaultCountryCode,
  hasError,
  inlineError,
  onChange,
  value
}: PhoneNumberProps) => {
  const [isCountryCodeSelectOpen, setCountryCodeSelectOpen] = useState(false)
  const [selectedCountryCodeOption, setSelectedCountryCodeOption] = useState<SingleValue<CountryOption> | undefined>()
  const inputRef = useRef<HTMLInputElement>(null)
  const countryCodeOptions = buildCountryCodeOptions(countries)

  function buildCountryCodeOptions(countries: Country[]) {
    const options = []
    for (let country of countries) {
      options.push({
        label: `(+${country.dial}) ${country.name}`,
        value: country.code
      })
    }
    return options
  }

  function findCountryOptionByCode(code: string) {
    return countryCodeOptions?.find(option => option.value === code)
  }

  const findDialFromCode = (code: string) => {
    const country = countries.find(c => c.code === code)
    return country?.dial
  }

  const toggleOpen = () => {
    setCountryCodeSelectOpen(!isCountryCodeSelectOpen)
  }

  const onSelectChange = (option: SingleValue<CountryOption>) => {
    toggleOpen()
    setSelectedCountryCodeOption(option)
    inputRef?.current?.focus()
    const phoneNumberValue = {
      code: option?.value,
      number: inputRef?.current?.value
    }
    onChange(phoneNumberValue)
  }

  const onPhoneNumberChange = () => {
    const phoneNumberValue = {
      code: selectedCountryCodeOption?.value,
      number: inputRef?.current?.value
    }
    onChange(phoneNumberValue)
  }

  const handlePhoneNumberInput = (evt: KeyboardEvent) => {
    function allowedNumberKeys(key: string) {
      return /^[0-9]$/i.test(key)
    }

    function allowedModifierKeys(code: string) {
      const allowedModifiers = ['Backspace', 'Enter', 'Tab', 'ArrowRight', 'ArrowLeft']
      return allowedModifiers.includes(code)
    }

    if (!allowedNumberKeys(evt.key) && !allowedModifierKeys(evt.code)) {
      evt.preventDefault()
    }
  }

  useEffect(() => {
    if (defaultCountryCode) {
      setSelectedCountryCodeOption(findCountryOptionByCode(defaultCountryCode))
    }
  }, [])

  useEffect(() => {
    if (value) {
      if (value.code) {
        setSelectedCountryCodeOption(findCountryOptionByCode(value.code))
      }
      if (value.number && inputRef.current) {
        inputRef.current.value = value.number
      }
    }
  }, [value])

  return (
    <FormField label={label} hasError={hasError} required={required} startIcon="phone" helpText={tooltipText}>
      <Box direction="row" gap="medium" align="center">
        <Box>
          <Dropdown
            isOpen={isCountryCodeSelectOpen}
            onClose={toggleOpen}
            target={
              <Box onClick={toggleOpen} direction="row" align="center">
                {selectedCountryCodeOption ? (
                  <>
                    <Text
                      color="text-light"
                      css={`
                        padding-left: 5px;
                        white-space: nowrap;
                        font-size: 16px;
                        line-height: 1.125rem;
                      `}
                    >
                      {selectedCountryCodeOption.value}
                    </Text>
                    <Text
                      css={`
                        padding-left: 5px;
                        white-space: nowrap;
                        font-size: 16px;
                        line-height: 1.125rem;
                      `}
                    >
                      (+{findDialFromCode(selectedCountryCodeOption.value)})
                    </Text>
                  </>
                ) : (
                  <Text
                    color="text-light"
                    css={`
                      padding-left: 5px;
                      white-space: nowrap;
                      font-size: 16px;
                      line-height: 1.125rem;
                    `}
                  >
                    Select...
                  </Text>
                )}
                <Icon icon="chevron-down" color="text-light" />
              </Box>
            }
          >
            <Select
              autoFocus
              backspaceRemovesValue={false}
              components={{ IndicatorSeparator: null }}
              controlShouldRenderValue={false}
              hideSelectedOptions={false}
              isClearable={false}
              menuIsOpen
              onChange={onSelectChange}
              options={countryCodeOptions}
              placeholder=""
              styles={selectStyles}
              tabSelectsValue={false}
              value={selectedCountryCodeOption}
              aria-label="Select Country Code"
            />
          </Dropdown>
        </Box>
        <Box css="margin-left: 16px; position: relative;">
          {Boolean(inlineError) ? (
            <InlineError color="error" size="small">
              {inlineError}
            </InlineError>
          ) : null}
          <Input
            ref={inputRef}
            type="text"
            onKeyDown={handlePhoneNumberInput}
            placeholder="Number..."
            onChange={onPhoneNumberChange}
            aria-label={tooltipText}
          />
        </Box>
      </Box>
    </FormField>
  )
}

const InlineError = styled(Text)`
  position: absolute;
  left: 0;
  white-space: nowrap;
  top: -20px;
`

const selectStyles: StylesConfig<CountryOption, false> = {
  control: provided => ({
    ...provided,
    minWidth: 240,
    '&:hover': {
      borderColor: 'transparent',
      boxShadow: 'none'
    },
    boxShadow: 'none',
    borderColor: 'transparent',
    backgroundColor: 'transparent'
  }),
  dropdownIndicator: provided => ({
    ...provided,
    display: 'none',
    cursor: 'pointer'
  }),
  menu: () => ({ boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)' })
}

type DropdownProps = {
  children: ReactNode
  isOpen: boolean
  target: ReactNode
  onClose: () => void
}

const Dropdown = ({ children, isOpen, target, onClose }: DropdownProps) => (
  <div css={{ position: 'relative' }}>
    {target}
    {isOpen ? <Menu>{children}</Menu> : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </div>
)

const Blanket = (props: JSX.IntrinsicElements['div']) => (
  <div
    css={{
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      position: 'fixed',
      zIndex: 1
    }}
    {...props}
  />
)

const Menu = (props: JSX.IntrinsicElements['div']) => {
  const shadow = 'hsla(218, 50%, 10%, 0.1)'
  return (
    <div
      css={{
        backgroundColor: 'white',
        borderRadius: 4,
        boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
        marginTop: 8,
        position: 'absolute',
        zIndex: 2
      }}
      {...props}
    />
  )
}
