// @ts-nocheck
import { IColor } from './IColor'
import { GenericFormInputPresenter } from '@logicroom/validator'
import { colors } from 'app/Styles/Variables'
import tinycolor2 from 'tinycolor2'
import { computed, reaction, observable } from 'mobx'
import { PopoverPresenter, TextInputPresenter } from 'Components/Molecules'
import { defaultColors } from './DefaultColors'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'

const primaryColor: IColor = tinycolor2(colors.primary).toRgb()

export class ColorPickerPresenter extends GenericFormInputPresenter<IColor> {
  private errorColor = {
    r: 151,
    g: 164,
    b: 170
  }

  private stringChangeMiddlware = (newValue: string): string => {
    const color = tinycolor2(newValue)
    if (color.isValid() && color.toRgbString() !== this.rgbaString) {
      this.onChange(color.toRgb())
    } else if (!color.isValid()) {
      const errorColor = tinycolor2(this.errorColor)
      this.onChange(errorColor.toRgb())
    }
    return newValue
  }

  public textPresenter = new TextInputPresenter(this.rgbaString).withMiddleware(this.stringChangeMiddlware)

  public popoverPresenter = new PopoverPresenter().withOpacity(0.0).withPosition('bottom left')

  @computed
  public get rgbaString(): string {
    const { r, g, b, a } = this.value
    return tinycolor2({ r, g, b, a }).toRgbString()
  }

  @observable
  public open = false

  @observable
  public availableColors: {
    value: string
    onClick: () => void
  }[] = defaultColors.map(value => {
    const color = tinycolor2(value).toRgb()
    return {
      value,
      onClick: () => this.onChange(color)
    }
  })

  constructor(language: ILanguageService, initialValue: IColor = primaryColor) {
    super(initialValue)

    this.popoverPresenter = new PopoverPresenter().withOpacity(0.0).withPosition('bottom left')

    this.colorReaction()
    reaction(
      () => this.value,
      () => {
        this.colorReaction()
      }
    )
  }

  private colorReaction = () => {
    this.textPresenter.onChange(this.rgbaString)
  }

  public withLabel = (label: string) => {
    this.textPresenter.label = label
    return this
  }

  public withConfigColors = (colors: string[]) => {
    this.availableColors = colors.map(value => {
      const color = tinycolor2(value).toRgb()
      return {
        value,
        onClick: () => this.onChange(color)
      }
    })
  }

  public togglePicker = () => {
    this.open = !this.open
  }
}
