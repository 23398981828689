import { ReactNode } from 'react'
import { useLocation } from 'react-router-dom'
import { usePrevious } from 'react-use'
import { useSetRecoilState } from 'recoil'

import { accountSlugUrlParamState, runbookUrlParamState, runbookVersionUrlParamState } from 'main/recoil/runbook'
import { useUpdateEffect } from 'main/services/hooks'
import { parseResourceURLParams } from 'main/services/params-util'

export type CustomNavigationEventDetail = {
  pathname: string
  previousPathname: string | undefined
}

export const NavigationEventEmitter = ({ children }: { children?: ReactNode }) => {
  const { search, pathname } = useLocation()
  const previousPathname = usePrevious(pathname)
  const setRunbookUrlParamState = useSetRecoilState(runbookUrlParamState)
  const setAccountSlugUrlParamState = useSetRecoilState(accountSlugUrlParamState)
  const setRunbookVersionUrlParamState = useSetRecoilState(runbookVersionUrlParamState)

  useUpdateEffect(() => {
    window.dispatchEvent(new CustomEvent<CustomNavigationEventDetail>('searchchanged'))
  }, [search])

  useUpdateEffect(() => {
    const { runbookId, accountSlug, runbookVersionId } = parseResourceURLParams(pathname)
    // Should do this in a transaction but that isn't working properly with firefiox due to weridness with snapshots
    setRunbookUrlParamState(runbookId)
    setAccountSlugUrlParamState(accountSlug)
    setRunbookVersionUrlParamState(runbookVersionId)

    window.dispatchEvent(
      new CustomEvent<CustomNavigationEventDetail>('pathnamechanged', { detail: { pathname, previousPathname } })
    )
  }, [pathname])

  return <>{children}</>
}
