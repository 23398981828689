import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const ListBulleted = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="ListBulleted" {...props}>
    <path d="M10 12a3 3 0 1 1 0-6 3 3 0 0 1 0 6Zm0 15a3 3 0 1 1 0-6 3 3 0 0 1 0 6ZM7 39a3 3 0 1 0 6 0 3 3 0 0 0-6 0ZM41 7.5H17v3h24v-3Zm-24 15h24v3H17v-3Zm24 15H17v3h24v-3Z" fill="#000" />
  </StyledIcon>
))

ListBulleted.displayName = 'ListBulleted'

export { ListBulleted }
