import { useRecoilCallback, useRecoilValue } from 'recoil'

import { runbookCurrentVersionState } from 'main/recoil/runbook'
import { CurrentRunbookVersionModelType } from 'main/data-access/models'

export const useGetCurrentRunbookVersion: CurrentRunbookVersionModelType['useGet'] = () =>
  useRecoilValue(runbookCurrentVersionState)

export const useGetCurrentRunbookVersionCallback: CurrentRunbookVersionModelType['useGetCallback'] = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      async () =>
        await snapshot.getPromise(runbookCurrentVersionState)
  )
