import { Dispatch, SetStateAction } from 'react'
import { useForm } from 'react-hook-form'

import { Modal, useNotify } from '@cutover/react-ui'
import { queryClient } from 'main/query-client'
import { ApiError } from 'main/services/api'
import { useLanguage } from 'main/services/hooks'
import { ConnectSetting, ConnectStatus } from 'main/services/queries/types/connect'
import { useConnectSettingsPatch } from 'main/services/queries/use-connect-settings-query'

type RenewCertificateModalType = {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  item: ConnectSetting | undefined
}

export const RenewCertificateModal = ({ open, setOpen, item }: RenewCertificateModalType) => {
  const { t } = useLanguage('connectSettings')
  const notify = useNotify()
  const patchMutation = useConnectSettingsPatch()
  const { isLoading: isPatchLoading } = patchMutation

  const onSubmitRenewCertModal = async (formData: ConnectSetting) => {
    const setting = {
      ...item,
      ...formData
    }

    methods.reset()

    const payload = {
      status: 'Active' as ConnectStatus,
      additional_operations: {
        renew_certs: {}
      }
    }

    patchMutation.mutate(
      { id: setting?.id, payload },
      {
        onSuccess: data => {
          let url = data.meta.additional_operation_results.renew_certs.url
          window.open(url)

          queryClient.invalidateQueries(['connect-settings'])

          setOpen(false)
          notify.success(t('modal.renewCert.toasters.success'))
        },
        onError: error => {
          const castedError = error as ApiError
          notify.error(castedError?.errors?.[0])
          setOpen(false)
        }
      }
    )
  }

  const methods = useForm<ConnectSetting>({
    mode: 'onChange',
    reValidateMode: 'onChange'
  })

  const handleCloseRenewCert = () => setOpen(false)

  return (
    <Modal
      open={open}
      title={t('modal.renewCert.title')}
      onClose={handleCloseRenewCert}
      onClickConfirm={methods.handleSubmit(onSubmitRenewCertModal)}
      confirmText={t('modal.renewCert.confirmText')}
      loading={isPatchLoading}
      loadingText={t('loading')}
    >
      {t('modal.renewCert.textBody')}
    </Modal>
  )
}
