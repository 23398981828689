// @ts-nocheck
import { injectable } from 'inversify'
import { observable, computed, action } from 'mobx'
import { RunbookTemplatesRepository } from 'app/Repositories/Runbooks/RunbookTemplatesRepository'
import { AccountRunbooksRepository } from 'app/Repositories/Runbooks/AccountRunbooksRepository'
import { AnchorTagPresenter } from 'Components/Molecules'
import { ModalPresenter } from 'Components/Atoms'
import { IRunbookTemplatePM } from 'Shared/Entities/Types/Runbooks/IRunbookTemplatePM'
import { IAccountRunbookPM } from 'Shared/Entities/Types/Runbooks/IAccountRunbookPM'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'
import { Types } from 'Gateways/Service/Types'
import { container } from 'app/Config/IOC'

@injectable()
export class AngularRunbookTemplateListPresenter {
  @observable
  public runbookTemplatesRepository: RunbookTemplatesRepository = container.get(RunbookTemplatesRepository)

  @observable
  public accountRunbooksRepository: AccountRunbooksRepository = container.get(AccountRunbooksRepository)

  @observable
  public searchedValue: string = null

  @observable
  public modalPresenter: ModalPresenter = null

  @observable
  public accountId: string = null

  public language: ILanguageService = container.get(Types.ILanguageService)

  @computed
  public get runbookTemplates(): IRunbookTemplatePM[] {
    return this.runbookTemplatesRepository.runbookTemplates
  }

  @computed
  public get runbookTemplatesLoading(): boolean {
    return this.runbookTemplatesRepository.loading
  }

  @computed
  public get runbookTemplatesTotal(): number {
    return this.runbookTemplatesRepository.filteredListTotal
  }

  @computed
  public get runbookTemplatesBatchLoadNumber(): number {
    return this.runbookTemplatesRepository.batchLoadNumber
  }

  @computed
  public get accountRunbooks(): IAccountRunbookPM[] {
    return this.accountRunbooksRepository.accountRunbooks
  }

  public viewRunbookTemplateStatusMapping = (runbookTemplateStatus: string) => {
    switch (runbookTemplateStatus) {
      case 'template_draft':
        return 'Draft'
      case 'template_approved':
        return 'Approved'
      case 'template_rejected':
        return 'Rejected'
    }
  }

  private redirectToRunbookList = () => {
    this.modalPresenter && this.modalPresenter.onClose()
    return window.angularJS.redirectToRunbookListPage(this.accountId, this.searchedValue)
  }

  public anchorTagPresenter = new AnchorTagPresenter('')
    .withText(this.language.get('runbooks:chooseRunbookTemplate:accountRunbookFound'))
    .withPreventDefault(true)
    .withCustomFunction(this.redirectToRunbookList)

  @action
  public withProps = ({ searchedValue, modalPresenter, accountId }) => {
    this.searchedValue = searchedValue
    this.modalPresenter = modalPresenter
    this.accountId = accountId
    return this
  }
}
