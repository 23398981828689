import { useMemo } from 'react'

import { SubHeaderSearch } from 'main/components/layout/shared/sub-header'
import { useClearFilterParam, useFilters, useSetFilterParams } from 'main/components/shared/filter/filter-provider'

export const Search = () => {
  const { filters } = useFilters()
  const initialSearch = useMemo(() => filters.q, [])
  const setFilterParams = useSetFilterParams()
  const clearFilter = useClearFilterParam()

  const handleSearch = (input?: string) => {
    if (input) {
      setFilterParams({ q: input })
    } else {
      clearFilter('q')
    }
  }

  return <SubHeaderSearch onSearch={handleSearch} initialValue={initialSearch as string} />
}
