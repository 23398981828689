import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Lock = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Lock" {...props}>
    <path d="M33 21v-7a8 8 0 0 0-8-8h-2a8 8 0 0 0-8 8v7h-1a3 3 0 0 0-3 3v15a3 3 0 0 0 3 3h20a3 3 0 0 0 3-3V24a3 3 0 0 0-3-3h-1ZM25 9a5 5 0 0 1 5 5v7H18v-7a5 5 0 0 1 5-5h2ZM14 24h20v15H14V24Z" fill="#000" />
  </StyledIcon>
))

Lock.displayName = 'Lock'

export { Lock }
