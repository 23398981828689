import { ReactNode } from 'react'
import { NAMESPACES } from 'config/i18n'
import { i18n as Ii18n } from 'i18next'
import { I18nextProvider } from 'react-i18next'

import { LoadingPage } from 'main/components/authentication/loading-page'
import { useLanguage } from 'main/services/hooks'

type LanguageProviderProps = {
  i18n: Ii18n
  children: ReactNode
}

export const LanguageProvider = (props: LanguageProviderProps) => {
  const { ready } = useLanguage(NAMESPACES)
  return ready ? <I18nextProvider i18n={props.i18n}>{props.children}</I18nextProvider> : <LoadingPage />
}
