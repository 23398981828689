import { useDeferredValue, useEffect, useState } from 'react'

export const useDeferTooltipTextTruncation = (truncation: boolean | 'tip') => {
  const [truncate, setTruncate] = useState<boolean | 'tip' | undefined>(undefined)

  useEffect(() => {
    setTruncate(truncation)
  }, [])

  return useDeferredValue(truncate)
}
