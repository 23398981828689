import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Unlock = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Unlock" {...props}>
    <path d="M35 9h-2a5 5 0 0 0-5 5v7h1a3 3 0 0 1 3 3v15a3 3 0 0 1-3 3H9a3 3 0 0 1-3-3V24a3 3 0 0 1 3-3h16v-7a8 8 0 0 1 8-8h2a8 8 0 0 1 8 8v7h-3v-7a5 5 0 0 0-5-5ZM9 24v15h20V24H9Z" fill="#000" />
  </StyledIcon>
))

Unlock.displayName = 'Unlock'

export { Unlock }
