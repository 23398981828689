// @ts-nocheck
import { useEffect, useCallback } from 'react'
import { RunbookTypesViewPresenter } from './RunbookTypesViewPresenter'
import { EditPanelArchiveModal } from 'Components/Templates'
import { Modal, ModalPresenter, GenericAddModal } from 'Components/Atoms'
import { IPageModalProps } from 'app/Shared/Entities/IPageModalProps'
import { RunbookTypeCreateFormIntegration } from 'main/components/settings/runbook-types/runbook-type-create-form-integration'
import { container } from 'app/Config/IOC'

export const RunbookTypesPageModals = (props: IPageModalProps) => {
  const {
    addModalOpenState,
    setAddModalOpenState,
    addFormPresenter,
    archiveModalPresenter,
    archiveConfirmName,
    cancelArchiveCallback,
    continueArchiveCallback,
    archiveResponseErrors,
    archiveModalOpenState,
    setAddFormViewModel,
    language
  } = props

  const runbookTypesViewPresenter = container.get(RunbookTypesViewPresenter)
  const runbookTypeAddModalPresenter = new ModalPresenter().withOnCloseExtra(() => {
    setAddModalOpenState(false)
    addFormPresenter.reset()
  })

  useEffect(() => {
    if (addModalOpenState === true) {
      setAddFormViewModel()
      runbookTypeAddModalPresenter.onOpen()
    }
  }, [addModalOpenState])

  useEffect(() => {
    if (archiveModalOpenState === true) {
      archiveModalPresenter.onOpen()
    } else {
      archiveModalPresenter.onClose()
    }
  }, [archiveModalOpenState])

  return (
    <>
      <GenericAddModal
        title={language.get('runbookTypes:addSection:title')}
        presenter={runbookTypeAddModalPresenter}
        cancelCallback={() => {
          setAddModalOpenState(false)
          addFormPresenter.reset()
        }}
        viewPresenter={runbookTypesViewPresenter}
        language={language}
      >
        <RunbookTypeCreateFormIntegration
          onSave={useCallback(() => {
            runbookTypeAddModalPresenter.onClose()
            setAddModalOpenState(false)
          }, [])}
          reloadRunbookTypes={() => runbookTypesViewPresenter.loadRunbookTypes()}
          availableApprovalFlows={() => runbookTypesViewPresenter.loadApprovalFlows()}
          isGlobalUser={() => runbookTypesViewPresenter.can('global')}
        />
      </GenericAddModal>
      <Modal presenter={archiveModalPresenter.withOnCloseExtra(cancelArchiveCallback)}>
        <EditPanelArchiveModal
          archiveConfirmName={archiveConfirmName}
          cancelCallback={cancelArchiveCallback}
          continueCallback={continueArchiveCallback}
          responseErrors={archiveResponseErrors}
          modalTitle={language.get('runbookTypes:editSection:archive:modal:title')}
          modalMessage={language.get('runbookTypes:editSection:archive:modal:message')}
        />
      </Modal>
    </>
  )
}
