// @ts-nocheck
import { injectable } from 'inversify'
import { computed, observable } from 'mobx'
import { container } from 'app/Config/IOC'
import { FilterDisclosurePanelPresenter } from 'Components/Molecules'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'
import { Types } from 'Gateways/Service/Types'
import { RunbookTypesViewPresenter } from '../RunbookTypesViewPresenter'

@injectable()
export class RunbookTypeFilterPresenter {
  private language: ILanguageService = container.get(Types.ILanguageService)
  public viewPresenter: RunbookTypesViewPresenter = container.get(RunbookTypesViewPresenter)

  @observable
  public visibilityFiltersDisclosurePanel = new FilterDisclosurePanelPresenter(
    this.language.get('common:filterSection:filterGroups:Visibility:title'),
    this.language.get('common:filterSection:filterGroups:Visibility:title')
  ).withCollapsed(false)

  @computed
  public get displayFilterPanel(): boolean {
    if (!this.viewPresenter.filterVM) return false

    return this.viewPresenter.filterVM.length > 0
  }
}
