import { useEffect, useState } from 'react'
import styled from 'styled-components'
import parse from 'html-react-parser'

import { Box, CodeEditor, Icon, Modal, Select, Text, TextInput, themeColor } from '@cutover/react-ui'
import { DebugDataKeys, IntegrationDebugData } from './types'
import { useLanguage } from 'main/services/hooks'

type IntegrationDebugModalProps = {
  open: boolean
  debugData: IntegrationDebugData
  onDebugClose: () => void
}

export const IntegrationDebugModal = ({ open = false, debugData, onDebugClose }: IntegrationDebugModalProps) => {
  const { t } = useLanguage('integrationDebug')
  const [isOpen, setIsOpen] = useState(false)
  const [tabs, setTabs] = useState<Array<string>>([])
  const [selectedTab, setSelectedTab] = useState<string | null>(null)

  const {
    auth_type,
    endpoint_failed,
    message_id,
    modal_title,
    request_body,
    request_headers,
    request_parameters,
    request_type,
    response_body,
    response_headers,
    response_status,
    url
  } = debugData

  const selectTab = (tab: string) => {
    setSelectedTab(tab)
  }

  const closeModal = () => {
    setIsOpen(false)
    onDebugClose()
  }

  useEffect(() => {
    setTabs(['request', 'response'].filter(tab => tabIsApplicable(tab, debugData)))
  }, [debugData])

  useEffect(() => {
    setSelectedTab(tabs[0])
  }, [tabs])

  useEffect(() => {
    setIsOpen(open)
  }, [open])

  return (
    <Modal open={isOpen} onClose={closeModal} hideFooter title={modal_title || t('header')}>
      {endpoint_failed && <Box>{parse(t('info'))}</Box>}
      <Box align="center" direction="row" margin={{ bottom: '16px' }}>
        {tabs.map(tab => (
          <StyledTabButton key={tab} active={selectedTab === tab} onClick={() => selectTab(tab)}>
            {t('tabs.title', { context: tab })}
          </StyledTabButton>
        ))}
        {endpoint_failed && <Icon icon="alert" color="error" />}
      </Box>

      {selectedTab === 'request' && (
        <Box direction="column">
          {auth_type && (
            <Select
              label={t('executionMethod')}
              disabled
              value={auth_type}
              onChange={() => {}}
              options={[{ label: auth_type, value: auth_type }]}
            />
          )}
          {request_type && (
            <Select
              label={t('requestType')}
              disabled
              value={request_type}
              onChange={() => {}}
              options={[{ label: request_type, value: request_type }]}
            />
          )}
          {url && <TextInput label={'URL'} value={url} readOnly />}
          {message_id && <TextInput label={'SQS Message ID'} value={message_id} readOnly />}
          {request_headers && <CodeEditorSection title={t('requestHeaders')} value={request_headers} />}
          {request_type === 'GET' && (
            <CodeEditorSection title={t('requestParameters')} value={request_parameters ?? ''} />
          )}
          {request_body && <CodeEditorSection title={t('requestBody')} value={request_body} />}
        </Box>
      )}
      {selectedTab === 'response' && (
        <Box direction="column">
          {response_status && (
            <TextInput defaultValue={response_status} label={t('statusCode')} readOnly hasError={endpoint_failed} />
          )}
          {response_headers && <CodeEditorSection title={t('responseHeaders')} value={response_headers} />}
          {response_body && <CodeEditorSection title={t('responseBody')} value={response_body} />}
        </Box>
      )}
    </Modal>
  )
}

const formatJSON = (val = '') => {
  try {
    const res = JSON.parse(val)
    return JSON.stringify(res, null, 2)
  } catch {
    return val
  }
}

const CodeEditorSection = ({ title, value }: { title: string; value: string }) => {
  return (
    <Box direction="column">
      <Text
        as="h2"
        css={`
          font-size: 13px;
          color: ${themeColor('text-light')};
        `}
      >
        {title}
      </Text>
      <CodeEditor defaultLanguage="json" value={formatJSON(value)} readOnly />
    </Box>
  )
}

const StyledTabButton = styled.button.attrs(() => ({
  type: 'button'
}))<{ active?: boolean }>`
  font-weight: 400;
  color: ${themeColor('text-light')};
  line-height: 40px;
  border-bottom: 4px solid transparent;
  ${props => props.active && `border-bottom: 4px solid #2A55C3; font-weight: 600; color: ${themeColor('primary')};`}
`

const tabIsApplicable = (tabName: string, debugData: IntegrationDebugData) => {
  switch (tabName) {
    case 'request':
      const requestKeys: DebugDataKeys[] = [
        'auth_type',
        'url',
        'request_type',
        'request_body',
        'request_headers',
        'request_parameters'
      ]
      return requestKeys.some(key => debugData[key])
    case 'response':
      const responseKeys: DebugDataKeys[] = ['response_body', 'response_headers', 'endpoint_failed']
      return responseKeys.some(key => debugData[key])
  }
}
