import { SubHeader } from 'main/components/layout/shared/sub-header'
import { useRunbookSubHeader } from './use-runbook-sub-header'

export const RunbookSubHeader = () => {
  const { subHeaderContent } = useRunbookSubHeader()

  return (
    <SubHeader.Items>
      <SubHeader.LeftItems>{subHeaderContent?.left}</SubHeader.LeftItems>
      <SubHeader.RightItems>{subHeaderContent?.right}</SubHeader.RightItems>
    </SubHeader.Items>
  )
}
