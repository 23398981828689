// @ts-nocheck
import { observable, action, toJS } from 'mobx'
import { GenericFormInputPresenter } from '@logicroom/validator'
import { TIcons } from 'Components/Atoms'
import debounce from 'debounce-promise'
import { TSelectOptionTypes } from './TSelectOptionTypes'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'

export class SelectPresenter extends GenericFormInputPresenter<string | number> {
  @observable
  public options: TSelectOptionTypes[] = []

  @observable
  public label = ''

  @observable
  public helpText: string

  @observable
  public placeholder?

  @observable
  public iconPrefix?: TIcons

  @observable
  public loading = false

  @observable
  public inlineComponent? = false

  public inModal: boolean = false

  @observable
  public asyncOptionsGetter: (inputText: string) => Promise<TSelectOptionTypes[]>

  public markAsDirty = () => {
    this.isDirty = true
  }

  @observable
  public language: ILanguageService

  constructor(language: ILanguageService, initialValue?: string | number) {
    super(initialValue)
    this.language = language
    this.placeholder = language.get('components:molecules:select:placeholderDefaultValue')
  }

  private internallyHandleAsyncOptionsRequest = async (inputText: string): Promise<TSelectOptionTypes[]> => {
    this.loading = true
    const result = await this.asyncOptionsGetter(inputText)
    this.loading = false
    return result
  }

  public handleAsyncOptionsRequest = debounce(this.internallyHandleAsyncOptionsRequest, 250)

  public withAsyncOptionsGetter = (getter: (inputText: string) => Promise<TSelectOptionTypes[]>) => {
    this.asyncOptionsGetter = getter
    return this
  }

  public withHelpText? = (helpText: string) => {
    this.helpText = helpText
    return this
  }

  public withLabel? = (label: string) => {
    this.label = label
    return this
  }

  @action
  public withOption? = (label: string, value: string | number) => {
    this.options.push({ label, value })
    return this
  }

  @action
  public withOptions? = (options: TSelectOptionTypes[]) => {
    this.options = toJS(options)
    return this
  }

  public withIcon? = (icon: TIcons) => {
    this.iconPrefix = icon
    return this
  }

  public withInModal = () => {
    this.inModal = true
    return this
  }

  public asInlineComponent? = () => {
    this.inlineComponent = true
    return this
  }

  public withDisabled = (disabled: boolean) => {
    this.disabled = disabled
    return this
  }
}
