import { Tab, TabItem } from './tab'
import { Box } from '../layout/box'
import { useRovingTabIndex } from '../utilities/use-roving-tab-index'

export type TabGroupProps = {
  items: TabItem[]
}

export const TabGroup = ({ items }: TabGroupProps) => {
  const { setToolbarElement } = useRovingTabIndex({ selector: '[role="tab"]' })

  return (
    <Box direction="row" gap="xxsmall" role="tablist" ref={setToolbarElement} flex={false}>
      {items.map(item => (
        <Tab key={item.label} {...item} />
      ))}
    </Box>
  )
}
