import { cloneElement, memo, MutableRefObject, Ref, useEffect } from 'react'
import { useFocusVisible } from '@react-aria/interactions'
import {
  ControlledMenu as ControlledReactMenu,
  MenuProps as ReactMenuProps,
  Menu as UncontrolledReactMenu
} from '@szhsin/react-menu'
import styled, { css } from 'styled-components/macro'
import { MergeExclusive } from 'type-fest'

import { useMutableCallbackRef } from '../hooks'
import { useOnScreen } from '../hooks/use-on-screen'
import { MenuListItem, MenuListItemProps } from './menu-list-item'
import { arrowMenuStyles, commonMenuStyles } from './menu-styles'
import { Box } from '../layout/box'
import { themeEdgeSize } from '../theme'

// TODO: determine if we want to expose all or a subset of the lib props...right now this takes all except menuButton.
//       We will need to wrap exports for at least some additional components from the library, e.g., Divider.
// TODO: can use prop to change default lib class name from szh or determine if can we get rid of all the CSS BEM classes completely
// TODO: document/reformat prop for positioning and default positioning

type UncontrolledMenuProps = {
  trigger: ReactMenuProps['menuButton']
}

type ControlledMenuProps = {
  items: MenuListItemProps[]
  onClose: () => void
  isOpen: boolean
  /** Must be the ref return from `useControlledMenuTrigger`. Apply that same value as the `ref` prop for the trigger component */
  triggerRef: Ref<HTMLElement>
  maxHeight?: number // Not yet applied on Uncontrolled menu
}

type CommonMenuProps = Omit<ReactMenuProps, 'menuButton'> & {
  keepOpen?: boolean
  keyPrefix?: string
  minWidth?: number
  maxWidth?: number
}

type MenuTypeProps = MergeExclusive<ControlledMenuProps, UncontrolledMenuProps>
type MenuProps = MenuTypeProps & CommonMenuProps

/**
 * A thin wrapper over the react-menu library. Please refer to the react-menu docs for more information and when looking to extend
 * and features such as sections, subheadings, dividers, etc.
 *
 * @see https://szhsin.github.io/react-menu/
 *
 * Both *controlled* and *uncontrolled* menus from the underlying react-menu library are exposed.
 *
 * *Controlled:* If `isOpen` prop is passed in, then the menu is controlled and its visibility must be determined manually.
 * Additionally, an `triggerRef` prop must be passed. This is the element that triggered the menu and its `ref` must be assigned
 * with `useMutableCallbackRef`. The controlled menu is useful when defining a single instance of a menu for multiple elements,
 * as in a list of items. The dynamic `triggerRef` is passed in to the menu to inform which element was triggered.
 *
 * *Uncontrolled:* This menu is defined in the location of its trigger. The `trigger` prop must be passed in and menu visibility
 * is determine automatically. The uncontrolled menu is useful when defining an instance of a menu associated with a trigger.
 */
export const Menu = memo((props: MenuProps) => {
  if (props.hasOwnProperty('isOpen')) {
    return <ControlledMenu {...props} />
  } else {
    return <UncontrolledMenu {...props} />
  }
})

const UncontrolledMenu = ({ trigger, keepOpen, arrow = false, minWidth, maxWidth, ...props }: MenuProps) => {
  const { isFocusVisible } = useFocusVisible()

  return (
    <UncontrolledStyledReactMenu
      $showFocusRings={isFocusVisible}
      $minWidth={minWidth}
      $maxWidth={maxWidth}
      arrow={arrow}
      {...props}
      portal
      menuButton={({ open }) => {
        // @ts-ignore
        return cloneElement(trigger, {
          'aria-haspopup': 'true',
          'aria-expanded': open
        })
      }}
      gap={8}
      boundingBoxPadding="32px"
      overflow="auto"
      // NOTE: this class info is not used currently but could be helpful for debugging.
      menuClassName={({ state, dir }) => `menu menu__${state} menu__${state}--${dir}`}
      // Set keepOpen to force the menu to stay open regardless what is set on the menuItems
      onItemClick={e => {
        if (keepOpen) {
          e.keepOpen = true
        }
      }}
    />
  )
}

const ControlledMenu = ({
  keyPrefix,
  items = [],
  keepOpen,
  triggerRef,
  onClose,
  isOpen,
  arrow = false,
  minWidth,
  maxWidth,
  maxHeight,
  ...props
}: MenuProps) => {
  const { isFocusVisible } = useFocusVisible()
  const isTriggerVisible = useOnScreen(triggerRef as MutableRefObject<HTMLElement>)

  useEffect(() => {
    if (!isTriggerVisible) {
      onClose?.()
    }
  }, [isTriggerVisible])

  return (
    <ControlledStyledReactMenu
      $showFocusRings={isFocusVisible}
      $minWidth={minWidth}
      $maxWidth={maxWidth}
      arrow={arrow}
      state={isOpen ? 'open' : 'closed'}
      onClose={onClose}
      anchorRef={triggerRef as MutableRefObject<HTMLElement>}
      portal
      boundingBoxPadding="32px"
      overflow="auto"
      {...props}
      onItemClick={e => {
        if (keepOpen) {
          e.keepOpen = true
        }
      }}
    >
      <Box
        css={`
          overflow: auto;
          padding: 4px;
          ${maxHeight &&
          css`
            max-height: ${maxHeight}px;
          `}
        `}
      >
        {items.map(({ label, a11yTitle, ...props }) => (
          <MenuListItem
            a11yTitle={a11yTitle}
            key={`${keyPrefix ? keyPrefix : 'popup-menu'}-${label}`}
            label={label}
            {...props}
          />
        ))}
      </Box>
    </ControlledStyledReactMenu>
  )
}

export function useControlledMenuTriggerRef<T>() {
  const triggerRef = useMutableCallbackRef()
  return triggerRef as Ref<T>
}

const UncontrolledStyledReactMenu = styled(UncontrolledReactMenu)<{
  $showFocusRings: boolean
  $minWidth?: number
  $maxWidth?: number
  arrow: boolean
}>`
  ${commonMenuStyles}
  ${props => props.arrow && arrowMenuStyles}
`

const ControlledStyledReactMenu = styled(ControlledReactMenu)<{
  $showFocusRings: boolean
  $minWidth?: number
  $maxWidth?: number
  arrow: boolean
}>`
  ${commonMenuStyles}
  ${props => props.arrow && arrowMenuStyles}

  .szh-menu {
    padding: ${themeEdgeSize('xxsmall')};
  }
`
