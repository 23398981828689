// @ts-nocheck
import { observable, computed } from 'mobx'
import { container } from 'app/Config/IOC'
import { AccountsPageViewPresenter } from 'app/Settings/Accounts/AccountsPage/AccountsPageViewPresenter'
import { GenericListPresenter } from 'Components/Templates'
import { ITableColumnTypes, ISortParams, TablePresenter } from 'Components/Organisms'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'
import { Types } from 'Gateways/Service/Types'

export class AccountListPresenter implements GenericListPresenter {
  @observable
  private accountsPageViewPresenter: AccountsPageViewPresenter = container.get(AccountsPageViewPresenter)

  private language: ILanguageService = container.get(Types.ILanguageService)

  @observable
  public filtersCount = 0

  @observable
  public columns: ITableColumnTypes[] = [
    {
      accessor: 'name',
      name: this.language.get('accounts:list:tableHeadings:name'),
      width: 150,
      dataType: 'string',
      sortable: true
    },
    {
      accessor: 'usersCount',
      name: this.language.get('accounts:list:tableHeadings:usersCount'),
      width: 150,
      dataType: 'hyperlink',
      sortable: true,
      hyperlinkOptions: {
        href: '',
        target: '_self',
        customHyperlinkFunction: id => {
          this.accountsPageViewPresenter.router.goToPageId('users', '', 'account_id=' + id)
        },
        dataType: 'number'
      }
    },
    {
      accessor: 'projectsCount',
      name: this.language.get('accounts:list:tableHeadings:foldersCount'),
      width: 150,
      dataType: 'number',
      sortable: true
    },
    {
      accessor: 'runbooksCount',
      name: this.language.get('accounts:list:tableHeadings:runbooksCount'),
      width: 150,
      dataType: 'number',
      sortable: true
    },
    {
      accessor: 'eventsCount',
      name: this.language.get('accounts:list:tableHeadings:eventsCount'),
      width: 150,
      dataType: 'number',
      sortable: true
    },
    {
      accessor: 'type',
      name: this.language.get('accounts:list:tableHeadings:type'),
      width: 150,
      dataType: 'string',
      sortable: true
    }
  ]

  public tableName: string = 'accounts-list'

  public context: string = 'account'

  @computed
  public get viewModel() {
    return this.accountsPageViewPresenter.listVM
  }

  @computed
  public get totalCount() {
    return this.accountsPageViewPresenter.totalFilteredAccounts
  }

  public loadRowCallback = async (accountId: number) => {
    if (this.accountsPageViewPresenter.accountVM && this.accountsPageViewPresenter.accountVM.id === accountId) {
      this.accountsPageViewPresenter.close()
    } else {
      this.accountsPageViewPresenter.loadAccount(accountId)
    }
  }

  public loadSort = (sortParams: ISortParams) => {
    this.accountsPageViewPresenter.sortParams = sortParams
    this.accountsPageViewPresenter.loadAccounts()
  }

  @computed
  public get sortParams(): ISortParams {
    return this.accountsPageViewPresenter.sortParams
  }

  @computed
  public get selectedId(): number {
    return this.accountsPageViewPresenter.accountVM ? this.accountsPageViewPresenter.accountVM.id : null
  }

  public clearAllFilters = () => {
    this.accountsPageViewPresenter.clearFilters()
  }

  @computed
  public get loading() {
    return this.accountsPageViewPresenter.repositoryLoading
  }

  @computed
  public get reloadList() {
    return this.accountsPageViewPresenter.reloadList
  }

  public checkEditPanelIsDirty = (): boolean => {
    if (this.accountsPageViewPresenter.pageVM.editPanelDirty) {
      return true
    }
    return false
  }

  @observable
  public tablePresenter: TablePresenter = new TablePresenter(
    this.viewModel,
    this.columns,
    this.tableName,
    this.totalCount
  )
    .withLoadRowCallback(this.loadRowCallback)
    .withLoadSortCallback(this.loadSort)
    .withVariableWidth()
}
