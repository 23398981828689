// @ts-nocheck
import styled from 'styled-components'
import { colors, px2rem } from 'app/Styles/Variables'
import { Icon } from 'Components/Atoms'
import { observer } from 'mobx-react-lite'
import { PillFilterPresenter, IFilterUIObject } from './PillFilterPresenter'

const ItemWrapper = styled.div`
  display: inline-block;
`

const PillWrapper = styled.span`
  display: inline-block;
  background-color: #fff;
  box-sizing: border-box;
  padding: 0 ${px2rem(12)};
  border-radius: ${px2rem(20)};
  line-height: ${px2rem(40)};
  font-size: ${px2rem(15)};
  margin-left: ${px2rem(6)};
  position: relative;
  color: ${colors.textLight};
  white-space: normal;
  vertical-align: middle;
  padding-right: ${({ canBeInverse }: { canBeInverse: boolean }) => (canBeInverse ? px2rem(48) : px2rem(24))};
`
interface IClickable {
  onClick?: () => void
}

const CloseButton = styled.button<IClickable>`
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  line-height: ${px2rem(40)};
  width: ${px2rem(24)};
  padding: 0;
  &:hover {
    cursor: pointer;
  }
`

const IconButton = styled.button<IClickable>`
  display: block;
  position: absolute;
  top: 0;
  right: ${px2rem(24)};
  line-height: ${px2rem(40)};
  padding: 0;
  &:hover {
    cursor: pointer;
  }
`

// icon-close
interface IPillFilter {
  presenter: PillFilterPresenter
}

export const PillFilter = observer(({ presenter }: IPillFilter) => {
  const { title, filterPills, closeSingleFilter, closeGroupFilter, reverseFilter, language } = presenter
  const iconSize = px2rem(16)
  const extraStyles = { width: px2rem(24), lineHeight: px2rem(40) }

  const CloseIconRender = () => <Icon name="close" size={iconSize} color={colors.textLight} extraStyles={extraStyles} />

  const InverseIcon = ({ filterKey }) => {
    const customIconClick = () => {
      reverseFilter(filterKey)
    }

    const iconColor = colors.textLight

    return (
      <IconButton onClick={customIconClick} aria-label="Filter by opposite">
        <Icon name="swap" size={iconSize} color={iconColor} extraStyles={extraStyles} />
      </IconButton>
    )
  }

  const Pill = ({ filterKey, canBeInverse, filterLevel, content, group, type }: IFilterUIObject) => {
    const onClose = () => {
      if (filterKey) closeSingleFilter(filterKey)
      if (group) closeGroupFilter(group)
    }

    const searchLabel = language.get('components:molecules:pill:searchLabel')

    const label =
      filterLevel > 1
        ? language.get('components:molecules:pill:removeFiltersLabel', {
            interpolation: { escapeValue: true },
            title
          })
        : language.get('components:molecules:pill:removeFilterLabel', {
            interpolation: { escapeValue: true },
            content: type === 'string' ? searchLabel : content
          })

    const displayContent = filterLevel > 1 ? '(' + filterLevel + ')' : content

    return (
      <PillWrapper canBeInverse={canBeInverse}>
        {title && `${title}: `} {displayContent} {canBeInverse && <InverseIcon filterKey={filterKey} />}
        <CloseButton onClick={onClose} aria-label={label}>
          <CloseIconRender />
        </CloseButton>
      </PillWrapper>
    )
  }

  return (
    <>
      {filterPills.map(filterPill => (
        <ItemWrapper key={filterPill.divKey}>
          <Pill {...filterPill} />
        </ItemWrapper>
      ))}
    </>
  )
})
