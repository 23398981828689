// @ts-nocheck
import 'reflect-metadata'
import { injectable } from 'inversify'
import { IUIHostGateway } from 'Gateways/Window/IUIHostGateway'

@injectable()
export class BrowserHostGateway implements IUIHostGateway {
  public getHostLanguage = () => {
    // @ts-ignore
    return navigator.language || navigator.userLanguage
  }
}
