import { useRecoilCallback, useRecoilValue } from 'recoil'

import { runbookTypesLookup, runbookTypesState, runbookTypeState } from 'main/recoil/runbook'
import { RunbookTypeModelType } from 'main/data-access/models'

/* -------------------------------------------------------------------------- */
/*                                     Get                                    */
/* -------------------------------------------------------------------------- */

export const useRunbookType: RunbookTypeModelType['useGet'] = identifier => useRecoilValue(runbookTypeState(identifier))

export const useRunbookTypeCallback: RunbookTypeModelType['useGetCallback'] = () => {
  return useRecoilCallback(
    ({ snapshot }) =>
      async (identifier: number) =>
        await snapshot.getPromise(runbookTypeState(identifier)),
    []
  )
}

/* -------------------------------------------------------------------------- */
/*                                   Get All                                  */
/* -------------------------------------------------------------------------- */

export const useRunbookTypes: RunbookTypeModelType['useGetAll'] = () => useRecoilValue(runbookTypesState)

export const useRunbookTypesCallback: RunbookTypeModelType['useGetAllCallback'] = () => {
  return useRecoilCallback(
    ({ snapshot }) =>
      async () =>
        await snapshot.getPromise(runbookTypesState),
    []
  )
}

/* -------------------------------------------------------------------------- */
/*                                 Get Lookup                                 */
/* -------------------------------------------------------------------------- */

export const useRunbookTypeLookup: RunbookTypeModelType['useGetLookup'] = () => useRecoilValue(runbookTypesLookup)

export const useRunbookTypeLookupCallback: RunbookTypeModelType['useGetLookupCallback'] = () => {
  return useRecoilCallback(
    ({ snapshot }) =>
      async () =>
        await snapshot.getPromise(runbookTypesLookup),
    []
  )
}
