// @ts-nocheck
import { injectable } from 'inversify'
import { computed, action, observable } from 'mobx'
import { container } from 'app/Config/IOC'
import { GenericAdminViewPresenter, ViewModelType } from 'Shared/Presenters/GenericAdminViewPresenter'
import { CustomFieldGroupRepository } from 'app/Repositories/CustomFieldGroupRepository'
import { CustomFieldGroupsRepository } from 'app/Repositories/CustomFieldGroupsRepository'
import { ISortParams } from 'Shared/Components/Organisms/Table/ISortParams'
import { ILoadParams } from 'Shared/Entities/ILoadParams'
import { ICustomFieldGroupPM } from 'Shared/Entities/Types/CustomFieldGroup/ICustomFieldGroupPM'
import { AvailableFields } from './CustomFieldGroupForm/CustomFieldGroupFormPresenter'

export interface ICustomFieldGroupVM {
  id?: number
  name: string
  default: boolean
}

@injectable()
export class CustomFieldGroupViewPresenter extends GenericAdminViewPresenter {
  // Properties
  protected routeId: string = 'customFieldGroups'
  protected recordRepository: CustomFieldGroupRepository = container.get(CustomFieldGroupRepository)
  public listRepository: CustomFieldGroupsRepository = container.get(CustomFieldGroupsRepository)

  @observable
  public addRecordVM: ICustomFieldGroupVM

  @observable
  public editRecordVM: ICustomFieldGroupVM

  @observable
  public sortParams: ISortParams

  @observable
  public reloadList = false

  // Methods
  constructor() {
    super()
    this.addRecordVM = this.getDefaultVM()
    this.sortParams = {
      accessor: 'name',
      dataType: 'string',
      sortDirection: 'asc'
    }
  }

  protected setObservers = () => {
    this.observers = [this.getRecordLoadingObserver(), this.getRecordToEditObserver()]
  }

  @action
  private updateViewModel = (type: ViewModelType, field: number, value: string) => {
    const viewModel = type === ViewModelType.Add ? this.addRecordVM : this.editRecordVM

    if (field === AvailableFields.Name) {
      viewModel.name = value
    }
  }

  public updateAddViewModel = (field: number, value: string) => {
    this.updateViewModel(ViewModelType.Add, field, value)
  }

  public updateEditViewModel = (field: number, value: string) => {
    this.updateViewModel(ViewModelType.Edit, field, value)
  }

  protected getDefaultVM = (): ICustomFieldGroupVM => {
    return {
      name: '',
      default: false
    }
  }

  protected getProgrammersModelForRecord = (recordVM: ICustomFieldGroupVM): ICustomFieldGroupPM => {
    const recordPM: ICustomFieldGroupPM = {
      name: recordVM.name
    }

    if (recordVM.id) {
      recordPM.id = recordVM.id
    }

    return recordPM
  }

  @action
  public inflateEditRecordVM = () => {
    const recordPM = this.recordRepository.recordForEdit
    if (!recordPM) return (this.editRecordVM = null)

    const recordVM: ICustomFieldGroupVM = {
      id: recordPM.id,
      name: recordPM.name,
      default: recordPM.default
    }

    this.editRecordVM = recordVM
  }

  @computed
  public get listVM(): ICustomFieldGroupPM[] {
    if (this.listRepository.customFieldGroups) {
      return this.listRepository.customFieldGroups.map(cfGroup => {
        return {
          id: cfGroup.id,
          name: cfGroup.name,
          numberOfCustomFields: cfGroup.numberOfCustomFields,
          default: cfGroup.default
        }
      })
    }

    return []
  }

  protected loadDependencies = async () => {
    await this.loadList()
  }

  @action
  public loadSort = async (sortParams: ISortParams) => {
    this.sortParams = sortParams
    await this.loadList({ offSet: 0, sortParams: this.sortParams })
  }

  public loadList = async (params?: ILoadParams) => {
    await this.listRepository.loadData(params || { offSet: 0, sortParams: this.sortParams })
  }

  @action
  public loadMoreRecords = async (offSet: number) => {
    if (this.listRepository.isBatchRequested(offSet)) return
    await this.loadList({ offSet, sortParams: this.sortParams })
  }

  public recordForEditReadOnly = () => {
    return !this.canUpdate || this.editRecordVM.default
  }

  @computed
  public get listLoading(): boolean {
    return this.listRepository.loading || this.loading
  }
}
