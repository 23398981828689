import { format, parseISO } from 'date-fns'
import { flatMap, sortBy } from 'lodash'

import {
  BarChart,
  LegacyBox as Box,
  colors,
  LegacyFlex as Flex,
  getOrdinalColorScale,
  LegacyText as Text
} from '@cutover/react-ui'
import { DashboardComponentProps } from '../../types'
import { MrdDashboardWidget } from '../mrd-dashboard-widget'
import { NoDataAvailable } from 'main/components/dashboards/widgets/account/common'

export type RunbookTypeCount = {
  name: string
  count: number
}

export type RunbookTypeCountOverTime = {
  date: string
  types: RunbookTypeCount[]
}

type RunbookCountByTypeOverTimeWidgetData = {
  name: string
  values: RunbookTypeCountOverTime[]
}

export type RunbookCountByTypeOverTimeWidgetProps = DashboardComponentProps<RunbookCountByTypeOverTimeWidgetData>

export const RunbookCountByTypeOverTimeWidget = (props: RunbookCountByTypeOverTimeWidgetProps) => {
  const { media } = props

  switch (media) {
    case 'screen':
      return <BrowserWidget {...props} />
    case 'email':
      return <EmailWidget {...props} />
    case 'print':
      return <PrintWidget {...props} />
  }
}

const BrowserWidget = ({ data }: { data: RunbookCountByTypeOverTimeWidgetData }) => <WidgetContent data={data} />

const EmailWidget = ({ data }: { data: RunbookCountByTypeOverTimeWidgetData }) => (
  <WidgetContent data={data} width={550} />
)

const PrintWidget = ({ data }: { data: RunbookCountByTypeOverTimeWidgetData }) => (
  <WidgetContent data={data} width={850} />
)

function WidgetContent({ data, width }: { data: RunbookCountByTypeOverTimeWidgetData; width?: number }) {
  const counts = data.values
  const isEmpty = counts.length === 0 || counts.every(c => c.types.length === 0)

  return (
    <MrdDashboardWidget title={data.name} fullWidth>
      {isEmpty ? <NoDataAvailable /> : <RunbookCountByTypeOverTime counts={counts} width={width} />}
    </MrdDashboardWidget>
  )
}

export type RunbookCountByTypeOverTimeProps = {
  counts: RunbookTypeCountOverTime[]
  width?: number
}

export const RunbookCountByTypeOverTime = ({ counts, width }: RunbookCountByTypeOverTimeProps) => {
  const trimDate = (date: string) => parseISO(date.substring(0, 11))

  const runbookCounts = sortBy(counts, c => trimDate(c.date)).map(c => ({
    category: {
      name: 'Date',
      value: format(trimDate(c.date), 'LLLL')
    },
    values: c.types.map(t => ({
      group: t.name,
      value: t.count
    }))
  }))

  const runbookTypeNames = Array.from(new Set(flatMap(runbookCounts, rc => rc.values).map(v => v.group)))
  const colorScale = getOrdinalColorScale(runbookTypeNames)

  return (
    <Flex flexDirection="column">
      <BarChart
        data={runbookCounts}
        height={300}
        width={width}
        padding={'30px 0 30px 50px'}
        showTotals
        stacked
        colors={colorScale}
        xAxis={{
          id: 'date',
          label: ''
        }}
        yAxis={{
          id: 'count',
          label: 'Count'
        }}
      />
      <Flex justifyContent="center">
        <Legend
          items={runbookTypeNames.map((runbookTypeName, i) => ({
            id: i.toString(),
            label: runbookTypeName,
            color: colorScale({ id: runbookTypeName })
          }))}
        />
      </Flex>
    </Flex>
  )
}

type LegendItem = {
  id: string
  label: string
  color: string
}

function Legend({ items }: { items: LegendItem[] }) {
  return (
    <Flex
      justifyContent="space-around"
      alignItems="center"
      flexWrap="wrap"
      css={`
        column-gap: 30px;
        row-gap: 10px;
      `}
    >
      {items.map(item => (
        <Flex key={item.id} gap="6px" alignItems="center">
          <Box
            css={`
              background-color: ${item.color};
              width: 16px;
              height: 16px;
              border-radius: 50%;
            `}
          />
          <Text color={colors.primaryGreyHover}>{item.label}</Text>
        </Flex>
      ))}
    </Flex>
  )
}
