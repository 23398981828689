// @ts-nocheck
import { useState, useEffect, useRef } from 'react'

import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { FixedSizeList as List } from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'
import { KeyCodeLookup } from 'app/Shared/Constants/Keycodes'
import { px2rem, colors } from 'app/Styles/Variables'

const Wrapper = styled.div`
  position: relative;
`

interface ILoadingProps {
  height: number
}

const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(props: ILoadingProps) => px2rem(props.height)};
  color: ${colors.secondary};
`

interface IListWithInfiniteLoaderProps {
  height: number
  itemSize: number
  width?: number | string
  items: any[]
  itemsTotal: number
  loadMoreItems: (startIndex: number, stopIndex: number) => Promise<any> | null
  isLoading: boolean
  className?: string
  renderListElement: (item: any, isActive?: boolean, isListFocused?: boolean) => ReactNode
  minimumBatchSize: number
  hasArrowNavigation?: boolean
  tabIndex?: number
}

export const ListWithInfiniteLoader = observer<IListWithInfiniteLoaderProps>(props => {
  const {
    height,
    itemSize,
    width = '100%',
    items,
    itemsTotal,
    loadMoreItems,
    isLoading,
    className,
    renderListElement,
    minimumBatchSize = 20,
    hasArrowNavigation = false,
    tabIndex
  } = props

  const [activeIndex, setActiveIndex] = useState(null)
  const [isFocused, setIsFocused] = useState(false)
  let isMouseDown = false

  const hasNextPage = itemsTotal > items.length
  const itemCount = hasNextPage ? items.length + 1 : items.length
  const isItemLoaded = (index: number) => !hasNextPage || index < items.length
  const threshold = minimumBatchSize - 5

  const wrapperRef = useRef(null)

  let listRef = null

  const setListRef = setRef => ref => {
    setRef(ref)
    listRef = ref
  }

  useEffect(() => {
    wrapperRef.current.addEventListener('focus', handleOnFocus)
    wrapperRef.current.addEventListener('blur', handleOnBlur)

    return () => {
      wrapperRef.current?.removeEventListener('focus', handleOnFocus)
      wrapperRef.current?.removeEventListener('blur', handleOnBlur)
    }
  }, [])

  useEffect(() => {
    if (activeIndex !== null) {
      listRef && listRef.scrollToItem && listRef.scrollToItem(activeIndex)
    }
  }, [activeIndex])

  const Row = ({ index, style }) => {
    const item = items[index]
    const isActive = index === activeIndex

    if (!item) {
      return isLoading ? (
        <Loading style={style} height={height}>
          Loading...
        </Loading>
      ) : null
    }

    return (
      <div style={style} data-index={index}>
        {renderListElement(item, isActive, isFocused)}
      </div>
    )
  }

  const handleOnMouseDown = () => {
    isMouseDown = true
  }

  const handleOnMouseUp = () => {
    isMouseDown = false
  }

  const handleOnFocus = () => {
    // Don't set active element if focus was on mousedown/click event
    if (hasArrowNavigation && !isMouseDown) {
      if (activeIndex === null) {
        setActiveIndex(0)
      }
      setIsFocused(true)
    }
  }

  const handleOnBlur = () => {
    if (hasArrowNavigation) {
      setActiveIndex(null)
      setIsFocused(false)
    }
  }

  const getActiveElement = () => {
    const activeElement = document.querySelector(`[data-index="${activeIndex}"]`)
    return activeElement && (activeElement.firstChild as HTMLElement)
  }

  const handleKeyDown = key => {
    if (hasArrowNavigation) {
      if (key.keyCode === KeyCodeLookup.down) {
        setActiveIndex((index: number) => Math.min(index + 1, items.length - 1))
      }

      if (key.keyCode === KeyCodeLookup.up) {
        setActiveIndex((index: number) => Math.max(index - 1, 0))
      }

      if (key.keyCode === KeyCodeLookup.enter) {
        const element = getActiveElement()
        element && element.click()
      }
    }
  }

  return (
    <Wrapper
      role="list"
      className={className}
      tabIndex={tabIndex}
      onMouseDown={handleOnMouseDown}
      onMouseUp={handleOnMouseUp}
      onKeyDown={handleKeyDown}
      ref={wrapperRef}
    >
      <InfiniteLoader
        isItemLoaded={isItemLoaded}
        itemCount={itemCount}
        loadMoreItems={loadMoreItems}
        minimumBatchSize={minimumBatchSize}
        threshold={threshold}
      >
        {({ onItemsRendered, ref: setRef }) => {
          return (
            <List
              height={height}
              itemCount={itemCount}
              itemSize={itemSize}
              width={width}
              onItemsRendered={onItemsRendered}
              ref={setListRef(setRef)}
            >
              {Row}
            </List>
          )
        }}
      </InfiniteLoader>
    </Wrapper>
  )
})
