import { PaintComms } from '../node-map-types'

export const paintComms = ({ ctx, x, y, size, lineWidth, stroke, fill }: PaintComms) => {
  ctx.lineWidth = lineWidth / 2
  ctx.beginPath()
  ctx.arc(x, y, size + 3, 0.75 * Math.PI, 1.25 * Math.PI, false)
  ctx.stroke()
  ctx.beginPath()
  ctx.arc(x, y, size + 3, 1.75 * Math.PI, 0.25 * Math.PI, false)
  ctx.stroke()

  ctx.beginPath()
  ctx.arc(x, y, size, 0, 2 * Math.PI, false)
  ctx.closePath()

  ctx.lineWidth = lineWidth
  ctx.strokeStyle = stroke
  ctx.stroke()

  if (fill) {
    ctx.fillStyle = fill
    ctx.fill()
  }
}
