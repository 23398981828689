import { atom, useRecoilCallback, useRecoilTransaction_UNSTABLE, useRecoilValue } from 'recoil'
import { produce } from 'immer'

import { useProcessRunbookVersionResponse } from './runbook-version-operations'
import { useProcessStreamResponse } from './stream-operations'
import { RunbookResponse } from 'main/services/api/data-providers/runbook-types'
import { useProcessRunResponse } from './run-operations'
import { useProcessRunbookResponse } from './runbook-operations'
import { useProcessRunbookPersonResponse } from './runbook-person-operations'
import { useProcessRunbookTeamResponse } from './runbook-team-operations'
import { isVersionCurrentState } from '../../runbook/models'
import { useProcessRunbookCommentsResponse } from './runbook-comments-operations'
import { useRunbookRequestsState, useTaskNotifications } from '../data-access-hooks__TEMPORARY'
import { TaskModel } from 'main/data-access'

/* ---------------------------------- State --------------------------------- */

const runbookChannelQueue = atom<RunbookResponse[]>({
  key: 'runbook-channel:queue',
  default: []
})

/* ---------------------------------- Hooks --------------------------------- */

export const useRunbookChannelQueueValue = () => useRecoilValue(runbookChannelQueue)

export const useRunbookChannelQueueProcessing = () => {
  // :!: warning: this function is for setters and callbacks only. Do NOT listen to any state here :!:
  const process = useRunbookChannelResponseProcessor()
  const { runbookRequestsValueCallback_DANGEROUS } = useRunbookRequestsState()

  const enqueue = useRecoilTransaction_UNSTABLE(({ set }) => (response: RunbookResponse) => {
    set(runbookChannelQueue, prev =>
      produce(prev, draft => {
        draft.push(response)
      })
    )
  })

  const processQueue = useRecoilTransaction_UNSTABLE(({ set }) => () => {
    set(runbookChannelQueue, msg => {
      msg.forEach(process)
      return []
    })
  })

  const enqueueOrProcess = useRecoilCallback(({ snapshot }) => async (response: RunbookResponse) => {
    const queue = await snapshot.getPromise(runbookChannelQueue)
    const { isLoading } = runbookRequestsValueCallback_DANGEROUS() // if we're at this point we know the ws has connected and its safe to call runbook endpoints

    if (isLoading || queue.length > 0) {
      enqueue(response)
    } else {
      process(response)
    }
  })

  return { enqueue, enqueueOrProcess, processQueue }
}

export const useRunbookChannelResponseProcessor = () => {
  // @ts-ignore how to handle type error best, and why aren't the other models used here? CFE-2221
  const processTaskResponse = TaskModel.useOnAction()
  // TODO: do we need a notifications model?
  const { notifyTaskAction } = useTaskNotifications()
  const processRunResponse = useProcessRunResponse()
  const processRunbookResponse = useProcessRunbookResponse()
  const processRunbookPersonResponse = useProcessRunbookPersonResponse()
  const processRunbookTeamResponse = useProcessRunbookTeamResponse()
  const processRunbookVersionResponse = useProcessRunbookVersionResponse()
  const processStreamResponse = useProcessStreamResponse()
  const processRunbookCommentsResponse = useProcessRunbookCommentsResponse()

  return useRecoilCallback(
    ({ snapshot }) =>
      async (response: RunbookResponse) => {
        const isCurrentVersion = await snapshot.getPromise(isVersionCurrentState)
        // Do not process any updates if the version being viewed is not the runbook current version,
        // unless the response meta has a runbook property, or this is a 'Runbook' type response,
        // in which case we want to update the runbook's state. Or unless a new version of runbook is created,
        // in this case we should redirect user to a new version.

        if (
          (!isCurrentVersion &&
            !(
              response?.meta.headers.request_class === 'RunbookVersion' &&
              response?.meta.headers.request_method === 'create'
            ) &&
            !response?.meta.hasOwnProperty('runbook') &&
            response?.meta.headers.request_class !== 'Runbook') ||
          !response?.meta?.headers
        )
          return

        // Do not add a newly created task to an old version
        if (
          !isCurrentVersion &&
          response?.meta.headers.request_class === 'Task' &&
          response?.meta.headers.request_method === 'create'
        )
          return

        switch (response?.meta.headers.request_class) {
          case 'Task':
            notifyTaskAction(response)
            // @ts-ignore how to handle type error best and in general for operation switches?
            processTaskResponse(response)
            break
          case 'Run':
            processRunResponse(response)
            break
          case 'Runbook':
            processRunbookResponse(response)
            break
          case 'RunbookPerson':
            processRunbookPersonResponse(response)
            break
          case 'RunbookTeam':
            processRunbookTeamResponse(response)
            break
          case 'RunbookVersion':
            processRunbookVersionResponse(response)
            break
          case 'Stream':
            processStreamResponse(response)
            break
          case 'Comment':
            processRunbookCommentsResponse(response)
            break
          default:
            return
        }
      },
    [
      processTaskResponse,
      processRunResponse,
      processRunbookResponse,
      processRunbookVersionResponse,
      processStreamResponse
    ]
  )
}
