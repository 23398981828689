import { Simplify } from 'type-fest'

import { ActiveModelInterfaceBase } from './types'
import { Dashboard } from 'main/services/queries/types'
import {
  useActiveDashboardId,
  useActiveDashboardIdCallback,
  useGetActiveDashboard,
  useGetActiveDashboardCallback,
  useGetActiveDashboardLoadable,
  useGetActiveDashboardLoadableCallback
} from '../hooks'

export type ActiveDashboardModelType = Simplify<Omit<ActiveModelInterfaceBase<Dashboard | undefined, {}>, 'useCan'>>

export const ActiveDashboardModel: ActiveDashboardModelType = {
  useGet: useGetActiveDashboard,
  useGetCallback: useGetActiveDashboardCallback,

  useGetLoadable: useGetActiveDashboardLoadable,
  useGetLoadableCallback: useGetActiveDashboardLoadableCallback,

  useId: useActiveDashboardId,
  useIdCallback: useActiveDashboardIdCallback
}
