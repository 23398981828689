// @ts-nocheck
import { observer } from 'mobx-react-lite'
import { Table } from 'Components/Organisms'
import { RoleTypeListPresenter } from './RoleTypeListPresenter'

interface IRoleTypeListComponentProps {
  presenter: RoleTypeListPresenter
}

export const RoleTypeListSection = observer<IRoleTypeListComponentProps>(props => {
  const { tablePresenter, selectedId } = props.presenter

  return (
    <>
      <Table presenter={tablePresenter} selectedId={selectedId} />
    </>
  )
})
