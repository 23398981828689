// @ts-nocheck
import 'reflect-metadata'
import { injectable } from 'inversify'

@injectable()
export class TableScrollerService {
  private scrollToTopCallback

  public registerScrollCall(scrollToTopFunc) {
    this.scrollToTopCallback = scrollToTopFunc
  }

  public doScroll() {
    if (this.scrollToTopCallback) this.scrollToTopCallback()
  }
}
