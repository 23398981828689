import { memo, MouseEvent, MouseEventHandler, ReactNode, useCallback } from 'react'
import { Heading } from 'grommet'
import styled from 'styled-components/macro'

import { Box, BoxProps } from '..'
import { Icon } from '../../icon'

export const HEADER_HEIGHT = 64

export const DISCLOSURE_PANEL_HEADER_CLASS_NAME = 'disclosure-panel-header'

// First pass concept for apps. Has significant differences from the full panel header.
export type DisclosurePanelHeaderProps = {
  title?: ReactNode
  onClick?: (e: MouseEvent<HTMLElement>) => void
  onKeyDownCapture?: (e: React.KeyboardEvent<HTMLElement>) => void
  isOpen?: boolean
  isActive?: boolean
  tabIndex?: number
  // don't love that this is the end components but easier for react to diff children than an array i think
  children?: ReactNode
}

export const DisclosurePanelHeader = memo(
  ({ title, onClick, isOpen, isActive, children, onKeyDownCapture }: DisclosurePanelHeaderProps) => {
    const color = !isOpen ? 'text-light' : isActive ? 'primary' : 'text'

    const leftSide = (
      <Box direction="row" gap="xxsmall" align="center">
        <ExpandCollapseIcon $isOpen={isOpen} color={color} aria-hidden />
        <Heading truncate level="3" css="font-size: 18px" color={color} className={DISCLOSURE_PANEL_HEADER_CLASS_NAME}>
          {title}
        </Heading>
      </Box>
    )

    const handleMouseDown: MouseEventHandler<HTMLElement> = useCallback(e => {
      // Avoid prevent default when clicking on the disclosure panel header instead of an inner element.
      const target = e.target as HTMLElement

      // Ensure className is a string attribute on HTMLElement since SVGElement className is an object.
      // Avoids error when clicking on the disclosure panel header toggle icon.
      if (typeof target.className === 'string' && target.className.includes(DISCLOSURE_PANEL_HEADER_CLASS_NAME)) {
        return
      } else {
        e.preventDefault()
      }
    }, [])

    return (
      <DisclosurePanelHeaderBox
        tabIndex={-1}
        onClick={onClick}
        onKeyDownCapture={onKeyDownCapture}
        isOpen={isOpen}
        onMouseDown={handleMouseDown}
        gap="small"
        css={'z-index: 2'}
        className={DISCLOSURE_PANEL_HEADER_CLASS_NAME}
      >
        {leftSide}
        {isOpen && children && <EndComponentsBox>{children}</EndComponentsBox>}
      </DisclosurePanelHeaderBox>
    )
  }
)

// Subcomponents

type DisclosurePanelHeaderBoxProps = BoxProps & { isOpen?: boolean }

const DisclosurePanelHeaderBox = styled(Box).attrs(() => ({
  align: 'center',
  flex: false,
  height: HEADER_HEIGHT + 'px',
  direction: 'row',
  justify: 'between',
  background: 'bg',
  tabIndex: -1,
  width: { max: '100%' }
}))<DisclosurePanelHeaderBoxProps>``

type ExpandCollapseIconProps = {
  $isOpen?: boolean
}

const ExpandCollapseIcon = styled(Icon).attrs(() => ({
  icon: 'caret-right'
}))<ExpandCollapseIconProps>`
  transition: transform 200ms ease;
  transform: rotate(${props => (props.$isOpen ? '90deg' : '0deg')});
`

const EndComponentsBox = styled(Box).attrs({
  direction: 'row',
  gap: 'xsmall',
  align: 'center',
  flex: false
})``
