import { SetRequired } from 'type-fest'

import { showToaster, ToasterProps } from '../overlays'

type NotifyOptions = Partial<Omit<ToasterProps, 'message' | 'type'>>
type RequiredTitleNotifyOptions = SetRequired<NotifyOptions, 'title'>

const CLOSE_TIMEOUT = 2000

export const useNotify = ({
  defaultSuccessMessage = 'Success',
  defaultErrorMessage = 'Error'
}: {
  defaultSuccessMessage?: string
  defaultErrorMessage?: string
} = {}) => {
  return {
    success: (message: string, { title, autoClose = CLOSE_TIMEOUT, ...restOptions }: NotifyOptions = {}) => {
      showToaster({
        title: title ?? defaultSuccessMessage,
        message,
        type: 'success',
        autoClose,
        ...restOptions
      })
    },
    error: (message: string, { title, autoClose = CLOSE_TIMEOUT, ...restOptions }: NotifyOptions = {}) => {
      showToaster({
        title: title ?? defaultErrorMessage,
        message,
        type: 'error',
        autoClose,
        ...restOptions
      })
    },
    warning: (message: string, { title, autoClose = CLOSE_TIMEOUT, ...restOptions }: RequiredTitleNotifyOptions) => {
      showToaster({
        title,
        message,
        type: 'warning',
        autoClose,
        ...restOptions
      })
    },
    info: (message: string, { title, autoClose = CLOSE_TIMEOUT, ...restOptions }: RequiredTitleNotifyOptions) => {
      showToaster({
        title,
        message,
        type: 'info',
        autoClose,
        ...restOptions
      })
    }
  }
}
