import { FieldValues } from './apps-types'
import { useAppsComponentPropsWithContextAndIdValue, useAppsFieldValuesWithContextState } from 'main/recoil/data-access'

export const useComponentProps = (appId: string, resourceId: string, id: string | undefined, props: object) => {
  const resolvedId = id || ''
  const context = `${resourceId}-${appId}`

  const componentProps = {
    ...props,
    ...(useAppsComponentPropsWithContextAndIdValue({ context, id: resolvedId }) || {})
  }

  return componentProps
}

export const useComponentPropsAndState = (appId: string, resourceId: string, id: string | undefined, props: object) => {
  const resolvedId = id || ''
  const context = `${resourceId}-${appId}`
  const [state, setState] = useAppsFieldValuesWithContextState(context)
  const componentProps = {
    ...props,
    ...(useAppsComponentPropsWithContextAndIdValue({ context, id: resolvedId }) || {})
  }

  return { componentProps, state: state as FieldValues, setState }
}
