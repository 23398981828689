import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Lightbulb = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Lightbulb" {...props}>
    <path d="M13.572 25c2.483 3.667 4.928 7.5 4.928 11h11c0-3.5 2.445-7.333 4.927-11 6.433-9.5 0-20-10.427-20C13.572 5 7.14 15.5 13.572 25Zm13.326 8h-5.796c-.34-1.328-.864-2.597-1.448-3.773-1.021-2.054-2.358-4.078-3.597-5.909-2.622-3.873-2.496-7.664-.968-10.43C16.645 10.076 19.827 8 24 8c4.173 0 7.355 2.075 8.91 4.889 1.53 2.765 1.655 6.556-.967 10.429-1.24 1.83-2.576 3.855-3.597 5.91-.584 1.175-1.109 2.444-1.448 3.772Zm2.602 6h-11v3h11v-3Z" fill="#000" />
  </StyledIcon>
))

Lightbulb.displayName = 'Lightbulb'

export { Lightbulb }
