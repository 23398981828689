import { useMemo, useState } from 'react'

import { Box, Message, Modal, RadioboxList, SanitizedString, Text } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { useModalCheckTaskStageChanged } from './use-modal-check-task-stage-changed'
import { RunbookViewModel, TaskModel, TaskProgressionViewModel } from 'main/data-access'

export const TaskOverride = ({ onClose, id }: { id: number; onClose: () => void }) => {
  const { t } = useLanguage('tasks', { keyPrefix: 'taskOverrideModal' })
  const [override, setOverride] = useState(false)
  const progressTask = TaskModel.useAction('progress')
  const getNextModalSync = TaskProgressionViewModel.getCallbackSync('nextModal')
  const continueModal = RunbookViewModel.useAction('modal:continue')

  const taskOverride = TaskModel.useGetOverride(id)
  const initialTaskOverride = useMemo(() => taskOverride, [])

  const task = TaskModel.useGet(id)
  const initialTaskStage = useMemo(() => task.stage, [])

  const nextModal = useMemo(
    () =>
      getNextModalSync(id, {
        from: { id, type: 'task-override' }
      }),
    [getNextModalSync, id]
  )

  const {
    handleSubmit,
    handleClose,
    confirmIcon,
    confirmText,
    errors: stageChangedErrors,
    renderContent,
    hasCancelButton
  } = useModalCheckTaskStageChanged({
    task,
    onSubmit: async () => {
      if (nextModal) return continueModal(nextModal, { id, type: 'task-override', context: { override } })
      return progressTask(id, { override: taskOverride?.optional ? override : true })
    },
    onClose,
    confirmIcon: 'chevron-right',
    confirmText: t('confirmText')
  })

  return (
    <Modal
      data-testid="modal-task-override"
      open
      animate={nextModal ? 'in' : true}
      onClose={handleClose}
      confirmIcon={confirmIcon}
      confirmText={confirmText as string}
      title={t(initialTaskStage === 'startable' ? 'titleStart' : 'titleFinish')}
      onClickConfirm={handleSubmit as any}
      hasCancelButton={hasCancelButton}
    >
      {stageChangedErrors && <Message margin type="error" message={stageChangedErrors} />}
      {renderContent(
        <Box>
          {initialTaskOverride?.optional ? (
            <Box>
              <SanitizedString input={t('contentOptional')} />
              <Text
                size="small"
                color="text-light"
                css={`
                  margin-top: 12px;
                `}
              >
                {t('wishToOverride')}
              </Text>
              <RadioboxList
                name="override"
                direction="row"
                options={[
                  {
                    value: 'continue',
                    label: initialTaskStage === 'startable' ? t('startWithoutOverriding') : t('finishWithoutOverriding')
                  },
                  {
                    value: 'override',
                    label: initialTaskStage === 'startable' ? t('startAndOverride') : t('finishAndOverride')
                  }
                ]}
                defaultValue="continue"
                onChange={e => {
                  setOverride((e.target.value as 'continue' | 'override') === 'override')
                }}
              />
            </Box>
          ) : (
            <Text>{t('content', { stage: initialTaskStage === 'startable' ? 'start' : 'end' })}</Text>
          )}
        </Box>
      )}
    </Modal>
  )
}
