// @ts-nocheck
import 'reflect-metadata'
import { injectable, inject } from 'inversify'
import { observable } from 'mobx'
import { IServiceGateway } from 'Gateways/Service/IServiceGateway'
import { Types } from 'Gateways/Service/Types'

@injectable()
export class SavedFiltersRepository {
  @inject(Types.IServiceGateway)
  private serviceGateway: IServiceGateway

  @observable
  public savedFilters: { id: number; key: string; value: string }[] = []

  public loadSavedFilters = async () => {
    let dto = await this.serviceGateway.get('filters?resource_type=User')
    this.savedFilters = dto.body.filters.map(filter => {
      return { id: filter.id, key: filter.name, value: this.jsonApiObjectToUrl(filter.query_string) }
    })
  }

  public clearSavedFilters = () => {
    this.savedFilters = []
  }

  public save = (filterName: string, queryForApi: any) => {
    let dto = this.urlToJsonApiObject(queryForApi)
    return this.serviceGateway.post('filters', {
      filter: {
        name: filterName,
        query_string: dto,
        resource_type: 'User',
        resource_id: null
      }
    })
  }

  public delete = async filterId => {
    await this.serviceGateway.delete('filters/' + filterId)
  }

  private jsonApiObjectToUrl(jsonFilterObject) {
    let filterObj = JSON.parse(jsonFilterObject)
    let filterKeyArray = Object.keys(filterObj)
    let queryStringParts = []

    filterKeyArray.forEach(filterKey => {
      let queryParams
      if (typeof filterObj[filterKey] === 'boolean') {
        queryParams = this.getQueryParamsForFilter(filterKey, filterObj[filterKey].toString())
      } else {
        queryParams = this.getQueryParamsForFilter(filterKey, filterObj[filterKey])
      }
      queryStringParts.push(queryParams.join('&'))
    })

    return queryStringParts.join('&').toString()
  }

  private getQueryParamsForFilter(filterKey: string, filterValue: any): string[] {
    let queryParams = []

    if (Array.isArray(filterValue)) {
      filterValue.forEach(currentValue => {
        queryParams.push(filterKey + '=' + currentValue)
      })
    } else if (filterValue === 'true') {
      queryParams.push(filterKey + '=' + 1)
    } else if (filterValue === 'false') {
      queryParams.push(filterKey + '=' + 0)
    } else {
      queryParams.push(filterKey + '=' + filterValue)
    }

    return queryParams
  }

  private urlToJsonApiObject(queryForApi) {
    let retJsonObj = {}
    queryForApi.split('&').forEach(filter => {
      let cleanedAndSplitSingleFilter = filter.replace('[]', '').split('=')
      let filterName = cleanedAndSplitSingleFilter[0]
      let filterValue = cleanedAndSplitSingleFilter[1]
      if (retJsonObj[filterName]) {
        retJsonObj[filterName] = retJsonObj[filterName].concat([parseInt(filterValue)])
      } else {
        if (isNaN(filterValue)) {
          retJsonObj[filterName] = filterValue
        } else {
          retJsonObj[filterName] = [parseInt(filterValue)]
        }
      }
    })
    return JSON.stringify(retJsonObj)
  }
}
