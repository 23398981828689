import { TaskTypeBox100 } from './box-100'
import { TaskTypeBoxDashed } from './box-dashed'
import { TaskTypeBox } from './box'
import { TaskTypeCog100 } from './cog-100'
import { TaskTypeCogDashed } from './cog-dashed'
import { TaskTypeCog } from './cog'
import { TaskTypeComms100 } from './comms-100'
import { TaskTypeCommsDashed } from './comms-dashed'
import { TaskTypeComms } from './comms'
import { TaskTypeDecision100 } from './decision-100'
import { TaskTypeDecisionDashed } from './decision-dashed'
import { TaskTypeDecision } from './decision'
import { TaskTypeDiamond100 } from './diamond-100'
import { TaskTypeDiamondDashed } from './diamond-dashed'
import { TaskTypeDiamond } from './diamond'
import { TaskTypeHexagon100 } from './hexagon-100'
import { TaskTypeHexagonDashed } from './hexagon-dashed'
import { TaskTypeHexagon } from './hexagon'
import { TaskTypeNormal100 } from './normal-100'
import { TaskTypeNormalDashed } from './normal-dashed'
import { TaskTypeNormal } from './normal'
import { TaskTypeRunbook100 } from './runbook-100'
import { TaskTypeRunbookDashed } from './runbook-dashed'
import { TaskTypeRunbook } from './runbook'
import { TaskTypeSquare100 } from './square-100'
import { TaskTypeSquareDashed } from './square-dashed'
import { TaskTypeSquare } from './square'
import { TaskTypeTriangle100 } from './triangle-100'
import { TaskTypeTriangleDashed } from './triangle-dashed'
import { TaskTypeTriangle } from './triangle'
export {
  TaskTypeBox100,
  TaskTypeBoxDashed,
  TaskTypeBox,
  TaskTypeCog100,
  TaskTypeCogDashed,
  TaskTypeCog,
  TaskTypeComms100,
  TaskTypeCommsDashed,
  TaskTypeComms,
  TaskTypeDecision100,
  TaskTypeDecisionDashed,
  TaskTypeDecision,
  TaskTypeDiamond100,
  TaskTypeDiamondDashed,
  TaskTypeDiamond,
  TaskTypeHexagon100,
  TaskTypeHexagonDashed,
  TaskTypeHexagon,
  TaskTypeNormal100,
  TaskTypeNormalDashed,
  TaskTypeNormal,
  TaskTypeRunbook100,
  TaskTypeRunbookDashed,
  TaskTypeRunbook,
  TaskTypeSquare100,
  TaskTypeSquareDashed,
  TaskTypeSquare,
  TaskTypeTriangle100,
  TaskTypeTriangleDashed,
  TaskTypeTriangle
}
export const TASK_TYPE_ICON_NAMES = [
  'box-100',
  'box-dashed',
  'box',
  'cog-100',
  'cog-dashed',
  'cog',
  'comms-100',
  'comms-dashed',
  'comms',
  'decision-100',
  'decision-dashed',
  'decision',
  'diamond-100',
  'diamond-dashed',
  'diamond',
  'hexagon-100',
  'hexagon-dashed',
  'hexagon',
  'normal-100',
  'normal-dashed',
  'normal',
  'runbook-100',
  'runbook-dashed',
  'runbook',
  'square-100',
  'square-dashed',
  'square',
  'triangle-100',
  'triangle-dashed',
  'triangle'
] as const
type PropsOf<TComponent> = TComponent extends React.ComponentType<infer P> ? P : never
export type TaskTypeIconProps = PropsOf<typeof TaskTypeBox100>
export type TaskTypeIconName = typeof TASK_TYPE_ICON_NAMES[number]
export const TASK_TYPE_ICON_NAME_MAP: Record<TaskTypeIconName, typeof TaskTypeBox100> = {
  'box-100': TaskTypeBox100,
  'box-dashed': TaskTypeBoxDashed,
  'box': TaskTypeBox,
  'cog-100': TaskTypeCog100,
  'cog-dashed': TaskTypeCogDashed,
  'cog': TaskTypeCog,
  'comms-100': TaskTypeComms100,
  'comms-dashed': TaskTypeCommsDashed,
  'comms': TaskTypeComms,
  'decision-100': TaskTypeDecision100,
  'decision-dashed': TaskTypeDecisionDashed,
  'decision': TaskTypeDecision,
  'diamond-100': TaskTypeDiamond100,
  'diamond-dashed': TaskTypeDiamondDashed,
  'diamond': TaskTypeDiamond,
  'hexagon-100': TaskTypeHexagon100,
  'hexagon-dashed': TaskTypeHexagonDashed,
  'hexagon': TaskTypeHexagon,
  'normal-100': TaskTypeNormal100,
  'normal-dashed': TaskTypeNormalDashed,
  'normal': TaskTypeNormal,
  'runbook-100': TaskTypeRunbook100,
  'runbook-dashed': TaskTypeRunbookDashed,
  'runbook': TaskTypeRunbook,
  'square-100': TaskTypeSquare100,
  'square-dashed': TaskTypeSquareDashed,
  'square': TaskTypeSquare,
  'triangle-100': TaskTypeTriangle100,
  'triangle-dashed': TaskTypeTriangleDashed,
  'triangle': TaskTypeTriangle
}