import { Dispatch, SetStateAction, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'

import { Box, Message, Modal, TextArea } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'

type CreateNewPublicCertModalProps = {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  onModalConfirm: (data: any) => void
  setCertificateFormErrors: (errors: any) => void
}

export const CreateNewPublicCertModal = ({
  open,
  setOpen,
  onModalConfirm,
  setCertificateFormErrors
}: CreateNewPublicCertModalProps) => {
  const { t } = useLanguage('samlConfigurations')
  const methods = useFormContext()
  const {
    register,
    getValues,
    formState: { errors }
  } = methods

  const closeNewCertificateForm = () => {
    methods.resetField('certificate')
    setCertificateFormErrors({})
    setOpen(false)
  }

  const formatCertificateErrors = () => {
    return errors?.certificate?.message
  }

  const isErrorFor = (property: string) => {
    return property in errors
  }

  useEffect(() => {
    setCertificateFormErrors(errors)
  }, [errors.certificate])

  return (
    <Modal
      title={t('newCertificate.title')}
      open={open}
      onClose={closeNewCertificateForm}
      onClickConfirm={() => {
        methods.trigger('certificate', { shouldFocus: true })
        !errors?.certificate && onModalConfirm(getValues('certificate'))
      }}
    >
      <Box>
        {Object.keys(errors?.certificate || {}).length != 0 && (
          <Box margin={{ bottom: 'small' }}>
            <Message message={formatCertificateErrors() as string} type="error" />
          </Box>
        )}
        <TextArea
          {...register('certificate')}
          label={t('samlConfigurations.formData.idpCertMulti.label')}
          tooltipText={t('samlConfigurations.formData.idpCertMulti.tipText')}
          hasError={isErrorFor('certificate')}
          required
        />
      </Box>
    </Modal>
  )
}
