import { createTaskFilterFunction, RunbookFilterType } from './filters'
import { TaskListTask } from 'main/services/queries/types'

export const startNotification = createTaskFilterFunction(
  'sn',
  (task: TaskListTask, filters: RunbookFilterType): boolean => {
    const isEnabled = (task: TaskListTask): boolean => {
      return !task.disable_notify
    }
    // (sn == true) => start notification disabled
    return filters.sn ? !isEnabled(task) : isEnabled(task)
  }
)
