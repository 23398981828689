// @ts-nocheck
import styled from 'styled-components'
import { withPresenter } from 'app/Config/withPresenter'
import { observer } from 'mobx-react-lite'
import { Heading } from 'Components/Atoms'
import { AlertSingle, Button, TextInput } from 'Components/Molecules'
import { SaveFilterPresenter } from './SaveFilterPresenter'

const Wrapper = styled.div`
  padding: 20px;
`

const ButtonContainer = styled.div`
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const SaveFilterComponent = observer((props: any) => {
  const { postSuccessCallback, cancelCallback, presenter } = props

  const {
    filterNamePresenter,
    submitButtonPresenter,
    cancelButtonPresenter,
    alertPresenter,
    filterAddForm,
    isSubmitted,
    saveFilterPrompt
  } = presenter

  presenter.withPostSuccessCall(postSuccessCallback)
  presenter.withCancelCallback(cancelCallback)

  const headingStyles = {
    marginBottom: '12px'
  }

  return (
    <Wrapper>
      <Heading extraStyles={headingStyles}>Save Filter</Heading>
      <form>
        <p>{saveFilterPrompt()}</p>
        {isSubmitted && !filterAddForm.isValid && <AlertSingle presenter={alertPresenter} />}
        <TextInput presenter={filterNamePresenter} parentIsFocused />
        <ButtonContainer>
          <Button presenter={cancelButtonPresenter} type={'button'} />
          <Button presenter={submitButtonPresenter} type={'submit'} />
        </ButtonContainer>
      </form>
    </Wrapper>
  )
})

export default withPresenter(SaveFilterPresenter)(SaveFilterComponent)
