import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Add = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Add" {...props}>
    <path d="M25.5 22.5V8h-3v14.5H8v3h14.5V40h3V25.5H40v-3H25.5Z" fill="#000" />
  </StyledIcon>
))

Add.displayName = 'Add'

export { Add }
