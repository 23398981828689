// @ts-nocheck
import { injectable } from 'inversify'
import { computed } from 'mobx'
import { GenericAdminViewPresenter } from './GenericAdminViewPresenter'

@injectable()
export abstract class GenericPageHeadingPresenter {
  protected abstract viewPresenter: GenericAdminViewPresenter

  @computed
  public get totalListCount(): number {
    return this.viewPresenter.listTotal
  }
}
