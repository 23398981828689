// @ts-nocheck
import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { withPresenter } from 'app/Config/withPresenter'
import { CustomFieldGroupAddPresenter } from './CustomFieldGroupAddPresenter'
import { CustomFieldGroupFormPresenter } from 'app/Settings/CustomFieldGroups/CustomFieldGroupsPage/CustomFieldGroupForm/CustomFieldGroupFormPresenter'
import { CustomFieldGroupFormComponent } from 'app/Settings/CustomFieldGroups/CustomFieldGroupsPage/CustomFieldGroupForm/CustomFieldGroupFormComponent'

const Wrapper = styled.div`
  overflow-y: auto;
`

interface ICustomFieldGroupAddComponentProps {
  postSuccessCallback: () => void
  presenter: CustomFieldGroupAddPresenter
  addFormPresenter: CustomFieldGroupFormPresenter
}

const CustomFieldGroupAddSection = observer<ICustomFieldGroupAddComponentProps>(
  ({ presenter, postSuccessCallback, addFormPresenter }) => {
    const { formSubmitted, save } = presenter

    presenter.withFormPresenter(addFormPresenter)
    presenter.withPostSuccessCall(postSuccessCallback)

    useEffect(() => {
      if (formSubmitted) {
        save()
      }
    }, [formSubmitted])

    return (
      <Wrapper>
        <CustomFieldGroupFormComponent presenter={addFormPresenter} formType="add" />
      </Wrapper>
    )
  }
)

export default withPresenter(CustomFieldGroupAddPresenter)(CustomFieldGroupAddSection)
