import { MouseEvent, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import styled from 'styled-components/macro'

import { Box, IconButton, resolveColor, Text, themeColor, useTheme } from '@cutover/react-ui'
import { useIntegrationActionItemsQuery } from '../../../services/queries/use-integration-action-item'
import { useLanguage } from 'main/services/hooks'
import { IntegrationActionItem, IntegrationSetting } from 'main/services/queries/types'
import { ConfigModel } from 'main/data-access'

interface IntegrationSettingListItemProps {
  setting: IntegrationSetting
  handleSelection: (setting: IntegrationSetting) => void
  handleActionItemSelection: (action: IntegrationActionItem) => void
}

export const IntegrationSettingListItem = ({
  setting,
  handleSelection,
  handleActionItemSelection
}: IntegrationSettingListItemProps) => {
  const theme = useTheme()
  const [searchParams] = useSearchParams()
  const selectedSettingId = searchParams.get('setting_id')
  const selectedActionItemId = searchParams.get('integration_action_item_id')

  const { cutoverApiFullPath } = ConfigModel.useGet()
  const { t } = useLanguage('integrationSettings')
  const { name, image_url, integration, integration_title } = setting
  const { data: integrationActionItems } = useIntegrationActionItemsQuery()

  const settingIntegrationActionItems = integrationActionItems
    ? integrationActionItems.filter(iai => iai.integration_setting.id === setting.id && !iai.archived)
    : setting.integration_action_items

  const [showActionItems, setShowActionItems] = useState(true)

  let imageSrc = cutoverApiFullPath || ''
  let fallbackSrc = imageSrc

  if (!Boolean(image_url)) {
    let integrationKlass = integration.split('::')
    imageSrc += `/img/integrations/${integrationKlass.pop()}.png`
  } else {
    imageSrc = image_url || ''
    fallbackSrc += '/img/integrations/Webhook.png'
  }

  return (
    <Box
      flex={false}
      css={`
        width: 100%;
        padding: 8px;
        border-radius: 8px;
        cursor: pointer;
      `}
      role="listitem"
      tabIndex={0}
      onClick={() => handleSelection(setting)}
    >
      <Box
        direction="row"
        css={`
          cursor: pointer;
          padding: 6px 4px;
          &:hover {
            background-color: ${themeColor('bg-2')};
          }
          ${(!selectedActionItemId ||
            (selectedSettingId && setting.id === parseInt(selectedSettingId) && !showActionItems)) &&
          selectedSettingId &&
          setting.id === parseInt(selectedSettingId) &&
          `background-color: ${resolveColor('bg-2', theme)};`}
        `}
      >
        <Box
          alignSelf="center"
          css={`
            margin-left: ${settingIntegrationActionItems.length > 0 ? '0' : '32px'};
          `}
        >
          {settingIntegrationActionItems.length > 0 && (
            <IconButton
              icon="caret-right"
              label={showActionItems ? t('settingsList.ariaLabelCollapse') : t('settingsList.ariaLabelExpand')}
              onClick={e => {
                setShowActionItems(!showActionItems)
                e.stopPropagation()
              }}
              css={`
                transform: ${showActionItems && 'rotate(90deg)'};
                margin-right: 4px;
              `}
            />
          )}
        </Box>
        <Box alignSelf="center">
          <IntegrationSettingImage
            src={imageSrc}
            alt={name}
            onError={(e: any) => {
              e.target.onError = null
              e.target.src = fallbackSrc
            }}
          />
        </Box>
        <Box alignSelf="center">
          <Text size="16px" weight={800} css="display: block">
            {name}
          </Text>
          <Box>
            <Text size="12px">
              {t('settingsList.type')}: {integration_title}
            </Text>
            {settingIntegrationActionItems?.length > 0 && (
              <Text
                size="12px"
                css={`
                  padding-left: 6px;
                `}
              >
                ({t('settingsList.actions', { count: settingIntegrationActionItems?.length })})
              </Text>
            )}
          </Box>
        </Box>
      </Box>
      <Box
        css={`
          margin-top: 4px;
        `}
      >
        {showActionItems &&
          settingIntegrationActionItems?.length > 0 &&
          settingIntegrationActionItems
            .sort((a, b) => (a.created_at > b.created_at ? 1 : -1))
            .map(iai => {
              return (
                <Box
                  direction="row"
                  key={iai.id}
                  css={`
                    padding: 8px 0px;
                    padding-left: 72px;
                    cursor: pointer;
                    &:hover {
                      background-color: ${themeColor('bg-2')};
                    }
                    ${selectedActionItemId &&
                    iai.id === parseInt(selectedActionItemId) &&
                    `background-color: ${resolveColor('bg-2', theme)};`}
                  `}
                  onClick={(e: MouseEvent<HTMLDivElement>) => {
                    handleActionItemSelection(iai)
                    e.stopPropagation()
                  }}
                >
                  <Box alignSelf="center">
                    <IntegrationActionItemImage src={iai.image_url || imageSrc} alt={iai.name} />
                  </Box>
                  <Box alignSelf="center">
                    <Text size="16px">{iai.name}</Text>
                  </Box>
                </Box>
              )
            })}
      </Box>
    </Box>
  )
}

export const IntegrationSettingImage = styled.img`
  width: 34px;
  margin-right: 8px;
  height: auto;
  border-radius: 50%;
`

export const IntegrationActionItemImage = styled.img`
  width: 22px;
  margin-right: 8px;
  height: auto;
  border-radius: 50%;
`
