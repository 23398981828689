// @ts-nocheck
import { useEffect } from 'react'
import { Modal, GenericAddModal } from 'Components/Atoms'
import { IPageModalProps } from 'app/Shared/Entities/IPageModalProps'
import { TaskTypesViewPresenter } from './TaskTypesViewPresenter'
import TaskTypeAddSection from './TaskTypeAdd/TaskTypeAddSection'
import { EditPanelArchiveModal } from 'Components/Templates'
import { container } from 'app/Config/IOC'
import { addSectionHooks } from 'Shared/HooksAndPresenters/AddEditSection'

export const TaskTypesPageModals = (props: IPageModalProps) => {
  const {
    addModalOpenState,
    setAddModalOpenState,
    addFormPresenter,
    archiveModalPresenter,
    archiveConfirmName,
    cancelArchiveCallback,
    continueArchiveCallback,
    archiveModalOpenState,
    archiveResponseErrors,
    language
  } = props

  const taskTypesViewPresenter = container.get(TaskTypesViewPresenter)

  const { addModalPresenter } = addSectionHooks(setAddModalOpenState, addModalOpenState, addFormPresenter)

  useEffect(() => {
    if (archiveModalOpenState === true) {
      archiveModalPresenter.onOpen()
    } else {
      archiveModalPresenter.onClose()
    }
  }, [archiveModalOpenState])

  return (
    <>
      <GenericAddModal
        title={language.get('taskTypes:add:title')}
        presenter={addModalPresenter}
        cancelCallback={() => {
          setAddModalOpenState(false)
          addFormPresenter.reset()
        }}
        viewPresenter={taskTypesViewPresenter}
        language={language}
      >
        <TaskTypeAddSection
          postSuccessCallback={() => {
            addModalPresenter.onClose()
            setAddModalOpenState(false)
          }}
          cancelCallback={() => {
            addModalPresenter.onClose()
            setAddModalOpenState(false)
          }}
          addFormPresenter={addFormPresenter}
        />
      </GenericAddModal>
      <Modal presenter={archiveModalPresenter.withOnCloseExtra(cancelArchiveCallback)}>
        <EditPanelArchiveModal
          archiveConfirmName={archiveConfirmName}
          cancelCallback={cancelArchiveCallback}
          continueCallback={continueArchiveCallback}
          responseErrors={archiveResponseErrors}
          modalTitle={language.get('taskTypes:archive:modal:title')}
          modalMessage={language.get('taskTypes:archive:modal:message')}
        />
      </Modal>
    </>
  )
}
