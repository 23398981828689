// @ts-nocheck
import { injectable } from 'inversify'
import { container } from 'app/Config/IOC'
import { observable, action } from 'mobx'
import { GenericPageAddPresenter } from 'Shared/Presenters/GenericPageAddPresenter'
import { CustomFieldFormPresenter } from 'app/Settings/CustomFields/CustomFieldsPage/CustomFieldForm/CustomFieldFormPresenter'
import { CustomFieldsViewPresenter } from 'app/Settings/CustomFields/CustomFieldsPage/CustomFieldsViewPresenter'

@injectable()
export class CustomFieldAddPresenter extends GenericPageAddPresenter {
  protected viewPresenter: CustomFieldsViewPresenter = container.get(CustomFieldsViewPresenter)

  @observable
  public formPresenter: CustomFieldFormPresenter

  @action
  public withFormPresenter = (formPresenter: CustomFieldFormPresenter) => {
    this.formPresenter = formPresenter
    return this
  }
}
