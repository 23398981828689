import { LegacyText as Text } from '@cutover/react-ui'
import { Chart } from './chart'
import { Duration, formatDuration } from './format-duration'
import { NoDataAvailable } from './no-data-available'
import { Table } from './table'
import { Tile } from './tile'

type TaskDuration = {
  name: string
  duration: Duration
  runbook_ids: number[]
}

export type LongestAverageTaskDurationsConnectedProps = {
  name?: string
  values: TaskDuration[]
  onEvent: (payload: any) => void
}

export function LongestAverageTaskDurationsConnected({
  name,
  values,
  onEvent
}: LongestAverageTaskDurationsConnectedProps) {
  const taskDurations: TaskDuration[] = values

  return (
    <Chart
      title={name}
      titleKey="dashboard:longestAverageTaskDurations:title"
      render={({ translate }) => {
        if (taskDurations.length === 0) {
          return <NoDataAvailable />
        }

        return (
          <LongestAverageTaskDurations
            taskNameTitle={translate('dashboard:longestAverageTaskDurations:taskNameTitle')}
            averageDurationTitle={translate('dashboard:longestAverageTaskDurations:averageDurationTitle')}
            taskDurations={taskDurations}
            onTaskSelected={id => {
              const runbookIds = taskDurations.find(t => t.name === id)?.runbook_ids ?? []

              if (runbookIds.length === 0) {
                return
              }

              onEvent({
                filterObject: {
                  filter: {
                    stage: ['complete'],
                    run_type: ['live'],
                    runbook_id: runbookIds
                  },
                  subFilter: {
                    key: 'runbook_id',
                    value: runbookIds
                  }
                }
              })
            }}
          />
        )
      }}
    />
  )
}

export type LongestAverageTaskDurationsProps = {
  taskNameTitle: string
  averageDurationTitle: string
  taskDurations: TaskDuration[]
  onTaskSelected?: (id: string) => void
}

export function LongestAverageTaskDurations({
  taskNameTitle,
  averageDurationTitle,
  taskDurations
}: LongestAverageTaskDurationsProps) {
  const tasks = (taskDurations ?? []).map(task => ({ ...task, id: task.name, duration: formatDuration(task.duration) }))

  return (
    <Tile variant="secondary" center={false}>
      <Table
        headers={[taskNameTitle, averageDurationTitle]}
        data={tasks}
        renderRowCells={rd => [
          <Text title={rd.name} truncate>
            {rd.name}
          </Text>,
          rd.duration
        ]}
      />
    </Tile>
  )
}
