import { useMemo } from 'react'

import { TaskModel } from 'main/data-access'

export const useUneditableTaskIds = (taskIds: number[]) => {
  const getBulkUpdatePermission = TaskModel.usePermissionCallbackSync('bulk_update')
  const tasksLookup = TaskModel.useGetLookup()

  return useMemo(() => {
    const tasks = taskIds.map(id => tasksLookup[id])

    const uneditableTaskErrors = getBulkUpdatePermission(taskIds).errors
    const uneditableIds = tasks.filter(task => !!task && uneditableTaskErrors[task.id]).map(task => task.id)

    return uneditableIds
  }, [taskIds, tasksLookup, getBulkUpdatePermission])
}
