import { keyBy } from 'lodash'
import { selector, selectorFamily } from 'recoil'

import { accountMetaState } from './account'
import { RunbookTypeType } from 'main/services/queries/types'

export const runbookTypesState = selector<RunbookTypeType[]>({
  key: 'account:runbook-types',
  get: ({ get }) => {
    return get(accountMetaState).runbook_types
  }
})

export const runbookTypesLookup = selector<Record<number, RunbookTypeType>>({
  key: 'account:runbook-type:lookup',
  get: ({ get }) => {
    const runbookTypes = get(runbookTypesState)
    return keyBy(runbookTypes, 'id')
  }
})

// TODO: reconsider naming here in the context of things that we don't usually
// get individually... this is not specific to the "runbook" (active runbook), it is just
// getting any time by its id
export const runbookTypeState = selectorFamily({
  key: 'account:runbook-type:id',
  get:
    (id: number) =>
    ({ get }) => {
      const lookup = get(runbookTypesLookup)
      return lookup[id]
    }
})
