import { Merge } from 'type-fest'

import { BrandIconName } from '@cutover/icons'
import { ChangeRequest } from './change-requests'
import { CustomFieldValue, FieldValue, FieldValuesAttributes } from './custom-fields'
import { RoleType } from './role-type'
import { User } from './users'

export type RunbookTemplateType = 'off' | 'default' | 'snippet'
export type RunbookTemplateStatus = 'template_draft' | 'template_pending' | 'template_approved' | 'template_rejected'
export type RunbookStage = 'planning' | 'active' | 'paused' | 'complete' | 'cancelled'
export type RagStatus = 'off' | 'red' | 'amber' | 'green'
export type RunbookTimingMode = 'scheduled' | 'unscheduled'
export type RunType = 'live' | 'rehearsal'
export type RunMode = 'active' | 'paused' | 'cancelled' | 'complete'
export type RunComms = 'off' | 'test' | 'on'

export type TimezoneType = {
  id: string
  name: string
}

export type RtaResult = {
  runbook_id: number
  start_actual: number
  rta_duration: string
  rta_diff: number
  rta_diff_duration: string
}

export const RTA_STATUSES = {
  pass: { name: 'Pass', colour: 'success' },
  fail: { name: 'Fail', colour: 'error' },
  pending: { name: 'Pending', colour: 'primary' },
  missing: { name: 'Not configured', colour: 'info' }
}

export type RunbookShowRunbook = RunbookBaseRunbook & {
  author_name: string
  change_requests_count: number
  current_version: RunbookVersion
  custom_field_values: CustomFieldValue[]
  description: string | null
  event: { name: string }
  linked_runbook_details?: {
    linked_resource?: RunbookListRunbook
    id?: string
  }
  project: Project
  rta_results: RtaResult[]
  settings_substreams_inherit_color: boolean
  settings_task_description_on_task_start: boolean
  settings_team_in_task_list: boolean
  settings_lock_template_copies_to_folder: boolean
  status_author_name: string | null
}

export type RunbookEditRunbook = RunbookBaseRunbook & {
  author_name: string
  change_requests_count: number
  current_version: RunbookVersion
  custom_field_values: CustomFieldValue[]
  description: string | null
  event?: { name: string }
  external_id: string | null
  filters: any[]
  linked_runbook_details?: {
    linked_resource: RunbookListRunbook
    id: string
  }
  parent_runbook_archived: boolean | null
  parent_runbook_id: number | null
  parent_runbook_name: string | null
  parent_version_id: number | null
  project: Project
  role_types: RoleType[]
  settings_substreams_inherit_color: boolean
  settings_task_description_on_task_start: boolean
  settings_team_in_task_list: boolean
  settings_lock_template_copies_to_folder: boolean
  status_author_name: string | null
}

// Used in runbook update payload
export type RunbookUpdateRunbook = {
  name?: string
  description?: string | null
  runbook_type_id?: number
  project_id?: number
  is_template?: boolean
  template_status?: string | null
  template_next_review?: number | null
  field_values_attributes?: FieldValuesAttributes[]
  rto?: number | null
  rto_source_id?: number | null
  settings_team_in_task_list?: boolean
  settings_substreams_inherit_color?: boolean
  settings_task_description_on_task_start?: boolean
  settings_lock_template_copies_to_folder?: boolean
  timezone?: string | null
  roles?: any[]
  account_id?: number
  status?: RagStatus
  status_message?: string | null
  runbook_versions_attributes: [
    {
      id?: number
      title?: string
      start_scheduled?: Date | null
      auto_start?: boolean
    }
  ]
}

export type RunbookCreateRunbook = {
  name?: string
  project_id?: number
  account_id?: number
  runbook_type_id?: number
  is_template?: boolean
  field_values_attributes: FieldValuesAttributes[]
  timezone?: string
  roles?: any[]
}

export type RunbookListRunbook = RunbookBaseRunbook & {
  account_id: number
  account_name: string
  account_slug: string
  admin: boolean
  auto_start: boolean
  completed_tasks_count: number
  completion: number
  current_template_version_id: number | null
  current_template_version_approval_status: string | null
  duration_display: number
  end_actual?: number
  end_diff: { value: string; early: boolean }
  end_display: number
  end_planned: number
  end_scheduled?: number
  event_name?: string
  external_id?: string | null
  meta?: LinkedStatus
  project_id: number
  project_name: string
  reviewers: number[]
  run_comms?: string
  run_type?: string
  runbook_admins: string
  runbook_components_count: number
  settings_lock_template_copies_to_folder?: boolean
  start_actual?: number
  start_display: number
  start_planned: number
  start_scheduled?: number
  status_author: string | null
  streams_count: number
  tasks_count: number
  template_next_review?: number
  template_status?: RunbookTemplateStatus
  updated_at: number
}

export type RunbookBaseRunbook = {
  id: number
  archived: boolean
  copies_count: number
  created_at: string | number
  current_version: RunbookVersion | null
  event_id: number | null
  field_values: FieldValue[]
  is_template: boolean
  name: string
  project_id: number
  project_parent_id: number | null
  restricted: boolean
  rto: number | null
  rto_source_id: number | null
  rta_results: RtaResult[]
  runbook_type_id: number
  runbook_version_id: number
  stage: RunbookStage
  status_message: string | null
  status_updated_at: number | null
  status: RagStatus
  template_next_review: number | null
  template_status: RunbookTemplateStatus | null
  template_type: RunbookTemplateType
  timezone: string | null
  updated_at: number
  ai_generated_description: boolean
}

export type RunbookTypeType = {
  account_id: number | null
  account_name: string
  archived: boolean
  change_request_types: ChangeRequest[]
  default: boolean
  description: string
  disabled: boolean
  dynamic: boolean
  global: boolean
  icon_color: string
  icon_name: BrandIconName
  icon?: string
  enable_rto: boolean
  id: number
  incident: boolean
  key: string
  name: string
  runbook_templates_count: number
  restrict_create_to_templates: boolean
}

export type RunbookTemplateRunbook = Merge<
  RunbookListRunbook,
  {
    is_template: true
    template_status: RunbookTemplateStatus
  }
>

type LinkedRunbookMeta = {
  actioned_by: string
  is_child: boolean
  is_parent: boolean
  linked_resource_status: string | null
  parent_archived: boolean
  parent_id: number
  parent_name: string
  task_runbook_status: string | null
}

type LinkedStatus = {
  linked_status: LinkedRunbookMeta
}

// TODO: needs to be typed properly -- current version on Template runbooks are very different than on Runbook runbooks and snippets
export type RunbookVersion = {
  id: number
  approval_status: string | null // TODO: type this
  auto_start: boolean
  cancelled_at: number | null
  completed_tasks_count: number
  completion: number
  created_at: number
  duration_diff: number | null
  duration_display: number | null
  end_actual: number | null
  end_diff: TimingDiff | null
  end_display: number | null
  end_forecast: number | null
  end_planned: number | null
  end_scheduled: number | null
  is_current: boolean
  is_editable: boolean
  name: string
  paused_at: number | null
  primary_stream_id: number
  resumed_at: number | null
  reviews?: {
    id: number
    comment?: string
    outcome?: string // TODO: type this
    reviewer: User // TODO check if this is actually the same type of user
  }[]
  reviews_required?: boolean
  rto_start_task_id: number | null
  rto_end_task_id: number | null
  rta_duration: string | null
  rta_diff: number | null
  rta_diff_duration: string | null
  run: Run | null
  runbook_components_count: number | null
  runbook_id: number
  stage: RunbookStage
  start_actual: number | null
  start_diff: TimingDiff | null
  start_display: number | null
  start_planned: number | null
  start_scheduled: number | null
  streams_count: number
  tasks_count: number
  template_version?: number
  timing_mode: RunbookTimingMode
  title: string
}

export type RunbookComponent = {
  id: number
  runbook_version_id: number
  source_runbook_id: number
  source_runbook_version_id: number
  name: string
  internal_id: number
  tasks_count: number
  unpacked_source_runbook_custom_field_attributes: CustomFieldValue[]
  source_runbook_template_type: RunbookTemplateType
  applied_to_internal_task_id: number
}

export type RunbookTeam = {
  id: number
  avatar: string | null
  color: string
  linked: boolean
  name: string
  team_id: number
  user_ids: number[]
  users_count: number
  internal_id: number
}

type StreamBaseStream = {
  id: number
  color: string
  default_task_type_id: null | number
  depth: number
  has_custom_color: boolean
  internal_id: number
  is_primary: boolean
  name: string
  parent_id: number | null
  permissions: { create_task: number[] }
  status_author_name: string | null
  status_message: string | null
  status_updated_at: number | null
  status: RagStatus
}

// Note: dashboard stream widget (legacy) still needs the tasks_count here
// So will refactor types when that is rewritten
export type StreamListStream = StreamBaseStream & {
  tasks_count?: number
  children: StreamListStream[]
}

export type StreamChangedStream = Omit<StreamListStream, 'children'>

export type StreamShowStream = StreamBaseStream & {
  role_types: RoleType[]
  tasks_count: number
  description: string | null
}

export type TimingDiff = {
  value: string | number
  early: boolean
}

export type Run = {
  id: number
  comms: RunComms
  disable_task_notify: boolean
  end_display: number
  mode: RunMode | null
  name: string | null
  run_type: RunType
  start_display: number
}

export type Project = {
  id: number
  account_id: number
  color: string
  count: number
  name: string
}

export type Favorite = {
  account_slug: string
  archived: boolean
  id: number
  is_template: boolean
  name: string
  template_type: RunbookTemplateType
}

export type UnlinkResource = {
  unlinkName: string
  isTemplate: boolean
}
