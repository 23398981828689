// @ts-nocheck
import 'reflect-metadata'
import { observable, action } from 'mobx'
import { inject, injectable } from 'inversify'
import { unescape } from 'lodash'
import { dateOrNull } from 'Shared/Helpers/DateService'
import { QueryStringBuilder } from 'app/Shared/Helpers/QueryStringBuilder'
import { TDownloadFormats } from 'app/Shared/Entities/Types/TDownloadFormats'
import { PermissableRepository } from 'app/Shared/Repositories/PermissableRepository'
import { IBaseResponse } from 'Gateways/Service/IBaseResponse'

@injectable()
export class UsersRepository extends PermissableRepository {
  @inject(QueryStringBuilder)
  private queryStringBuilder: QueryStringBuilder

  public batchLoadNumber: number = 40

  @observable
  public users: any = []

  @observable
  public usersTotal: number

  @observable
  public usersFilteredTotal: number

  @observable
  public offSet: number = 0

  @observable
  public sortParams: any = null

  @observable
  public downloading = false

  constructor() {
    super('meta')
  }

  @action
  public clear = () => {
    this.users = []
    this.usersTotal = undefined
    this.usersFilteredTotal = undefined
    this.offSet = 0
    this.sortParams = null
    this.downloading = false
  }

  @action
  public loadMore = async (offSet: number, sortParams: any) => {
    this.offSet = offSet
    this.sortParams = sortParams
    const { body } = await this.loadHelper(offSet, sortParams)
    const newUsers = body.users.map(userDto => {
      return this.mapUserDto(userDto)
    })
    this.users = this.users.concat(newUsers)
  }

  public preLoad = (args: { [key: string]: any }): string => {
    this.offSet = args && args.offSet != null ? args.offSet : 0
    if (args && args.sortParams != null) this.sortParams = args.sortParams
    const url = 'users' + this.queryStringBuilder.getQueryString(this.offSet, this.sortParams, this.batchLoadNumber)
    return url
  }

  @action
  public updateUsersWithEditedUser = userForEdit => {
    this.loading = true
    const updateUsers = this.users.map(user => {
      if (user.id !== userForEdit.id) {
        return user
      }
      const returnUser = user
      returnUser.firstName = userForEdit.firstName
      returnUser.lastName = userForEdit.lastName
      returnUser.nickName = userForEdit.nickName
      returnUser.email = userForEdit.email
      returnUser.mobileNumberNormalized = userForEdit.mobileNumberNormalized
      returnUser.lockedAt = userForEdit.lockedAt
      returnUser.uniqueId = userForEdit.uniqueId
      return returnUser
    })

    this.users = updateUsers
    this.loading = false
  }

  public download = async (format: TDownloadFormats) => {
    if (this.downloading) return
    this.downloading = true
    const downloadPath = format === 'XLSX' ? 'users/download_users_xlsx' : 'users/download_users_csv'
    const response = await this.serviceGateway.get(
      downloadPath + this.queryStringBuilder.getQueryString(this.offSet, this.sortParams, this.batchLoadNumber),
      {},
      {},
      { responseType: 'arraybuffer' }
    )
    this.downloading = false
    if (!response.success) throw new Error('Invalid download response received')
    return response.body
  }

  @action
  public postLoad = (rawData: IBaseResponse) => {
    this.usersTotal = rawData.body.meta.total_results
    this.usersFilteredTotal = rawData.body.meta.filtered_results
    this.users = rawData.body.users.map(userDto => {
      return this.mapUserDto(userDto)
    })
  }

  private mapUserDto = userDto => {
    const user = {
      id: userDto.id,
      uniqueId: unescape(userDto.unique_id),
      firstName: userDto.first_name,
      lastName: userDto.last_name,
      acceptEmail: userDto.pref_email,
      acceptSMS: userDto.pref_sms,
      email: userDto.email,
      jobTitle: userDto.job_title,
      loginCount: userDto.sign_in_count,
      mobileNumber: userDto.mobile_number,
      mobileNumberNormalized: userDto.mobile_number_normalized,
      nickName: userDto.handle,
      invitationAcceptedAt: dateOrNull(userDto.invitation_accepted_at),
      invitationSentAt: dateOrNull(userDto.invitation_sent_at),
      lastActive: dateOrNull(userDto.last_activity_at),
      lastLogin: dateOrNull(userDto.current_sign_in_at),
      lockedAt: dateOrNull(userDto.locked_at),
      passwordExpiresAt: dateOrNull(userDto.password_expires_at),
      color: userDto.color
    }
    return user
  }

  private loadHelper = async (offSet?: number, sortParams?: any) => {
    return await this.serviceGateway.get(
      'users' + this.queryStringBuilder.getQueryString(offSet, sortParams, this.batchLoadNumber)
    )
  }

  public listUsers = async query => {
    const response = await this.serviceGateway.get('users/list', { query })
    if (response.success && response.body && response.body.users) {
      return response.body.users
    } else {
      return []
    }
  }
}
