// @ts-nocheck
import { observer } from 'mobx-react-lite'
import { withPresenter } from 'app/Config/withPresenter'
import { AngularRunbooksPageModals } from './AngularRunbooksPageModals/AngularRunbooksPageModals'
import { AngularRunbooksPagePresenter } from './AngularRunbooksPagePresenter'

interface IRunbooksPageComponentProps {
  presenter: AngularRunbooksPagePresenter
}

const AngularRunbooksPageComponent = observer<IRunbooksPageComponentProps>(({ presenter }) => {
  const { runbooksPageModalsPresenter } = presenter

  return <AngularRunbooksPageModals presenter={runbooksPageModalsPresenter} />
})

export default withPresenter(AngularRunbooksPagePresenter)(AngularRunbooksPageComponent)
