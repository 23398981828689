// @ts-nocheck
import { injectable } from 'inversify'
import { container } from 'app/Config/IOC'
import { action, computed, observable } from 'mobx'
import { CurrentPagePresenter } from 'app/Routing/CurrentPage/CurrentPagePresenter'
import { GenericAdminViewPresenter, ViewModelType } from './GenericAdminViewPresenter'
import { GenericPageFormPresenter } from 'Shared/Presenters/GenericPageFormPresenter'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'
import { Types } from 'Gateways/Service/Types'
import { ToasterService } from 'Shared/Components/Molecules'
import { Submitted } from 'Shared/Entities/Enums/Submitted'
import { ModalPresenter } from 'Shared/Components/Atoms/Modal/ModalPresenter'

@injectable()
export abstract class GenericAdminPagePresenter {
  protected abstract viewPresenter: GenericAdminViewPresenter
  public abstract addFormPresenter: GenericPageFormPresenter
  public abstract editFormPresenter: GenericPageFormPresenter
  public language: ILanguageService = container.get(Types.ILanguageService)
  protected toasterService: ToasterService = container.get(ToasterService)
  public abstract launchArchiveSuccessToaster?: () => void

  public checkEditPanelIsDirty = (): boolean => {
    return this.viewPresenter.checkEditPanelIsDirty()
  }

  @observable
  public archiveModalPresenter: ModalPresenter = new ModalPresenter()

  @observable
  public archiveResponseErrors: string[] = []

  @observable
  public archiveState: Submitted

  @computed
  public get angularJSSettingsPanelOpenState(): boolean {
    return container.get(CurrentPagePresenter).angularJSSettingsPanelOpenState
  }

  @action
  public resetForm = (type: ViewModelType) => {
    const form = type === ViewModelType.Add ? this.addFormPresenter : this.editFormPresenter
    form.reset()
    this.viewPresenter.resetViewModel(ViewModelType.Add)
    this.viewPresenter.setSubmitted(false)
  }

  @computed
  public get editRecordVM() {
    return this.viewPresenter.editRecordVM
  }

  public editRecordVMChangeFunction = () => {
    this.editFormPresenter.reset()
    if (this.viewPresenter.editRecordVM) {
      this.editFormPresenter.inflateFormWithVM(this.viewPresenter.editRecordVM)
      this.editFormPresenter.applyReadonly(this.viewPresenter.recordForEditReadOnly())
    }
  }

  @action
  public resetSubmit = () => {
    this.viewPresenter.submitted = false
  }

  protected launchSuccessToaster = (successMsg: string) => {
    this.toasterService.pop(this.language.get('common:notification:successTitle'), successMsg, 'success')
  }

  @computed
  public get editOpen(): boolean {
    return this.viewPresenter.pageVM.editPanelOpen
  }

  public setEditClosed = () => {
    this.viewPresenter.setPageVMValue('editPanelOpen', false)
  }

  @computed
  public get editPanelLoading(): boolean {
    return this.viewPresenter.pageVM.editPanelLoading
  }

  public clearEditPanel = () => {
    this.viewPresenter.clearEditPanel()
  }

  @computed
  public get filterPanelLoading(): boolean {
    return this.viewPresenter.pageVM.filterPanelLoading
  }

  @computed
  public get archiveModalOpenState(): boolean {
    return this.viewPresenter.pageVM.archiveModalOpenState
  }

  @computed
  public get canCreate(): boolean {
    return this.viewPresenter.canCreate
  }

  @action
  public setArchiveState = (state: Submitted) => {
    this.archiveState = state
  }

  @action
  public setArchiveResponseErrors = (errors: string[]) => {
    this.archiveResponseErrors = errors
  }

  @action
  public resetArchive = () => {
    this.viewPresenter.pageVM.archiveModalOpenState = false
    this.archiveResponseErrors = []
    this.archiveState = Submitted.Unsubmitted
  }

  @action
  public archivePostSuccessCallback = () => {
    this.viewPresenter.pageVM.editPanelOpen = false

    if (this.launchArchiveSuccessToaster) {
      this.launchArchiveSuccessToaster()
    }

    this.resetArchive()
    this.viewPresenter.loadList()
  }
}
