import { TaskListTask } from 'main/services/queries/types'
import { createTaskFilterFunction, RunbookFilterType } from './filters'
import { TaskFilterContext } from './filter-context'

// TODO: this needs a context to remove task level iterative checks
// https://cutover.atlassian.net/browse/CFE-1138

export const user = createTaskFilterFunction(
  'user',
  (task: TaskListTask, filters: RunbookFilterType, context: TaskFilterContext): boolean => {
    // filters.user is the raw value from the url, including * and 0. Context.userId contains only user ids
    if (filters.user === undefined) return false

    const { userIds = [], userTeamIds = [] } = context

    // @ts-ignore match on *any* user
    if (filters.user[0] === '*' && task.user_ids.length > 0) {
      return true
    }

    // @ts-ignore match on no user
    if (filters.user.includes(0) && task.user_ids.length === 0) {
      return true
    }

    // match on specific user
    if (hasIntersection(task.user_ids, userIds)) {
      return true
    }

    // match on user via team
    if (hasIntersection(task.runbook_team_ids, userTeamIds)) {
      return true
    }

    return false
  }
)

const hasIntersection = (a: any[], b: any[]) => {
  return a.some(item => b.includes(item))
}
