import { MutationCache, QueryClient } from 'react-query'

export const mutationCache = new MutationCache()

export const createQueryClient = () =>
  new QueryClient({
    mutationCache,
    defaultOptions: {
      queries: {
        useErrorBoundary: false, // TODO: reinstate when react-error-boundary added
        refetchOnWindowFocus: false,
        retry: false // TODO: review with further testing
      }
    }
  })

export const queryClient = createQueryClient()
