import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const BrandResilienceTesting = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 100 100" a11yTitle="BrandResilienceTesting" {...props}>
    <g clipPath="url(#a)" fill="#fff"><path opacity=".5" d="M50 100A50 50 0 0 0 50 0v100Z" /><path d="M46.409 50.796 25.667 30.032V71.56l20.742-20.764Zm-25.667 0L0 30.032V71.56l20.742-20.764Z" /></g>
  </StyledIcon>
))

BrandResilienceTesting.displayName = 'BrandResilienceTesting'

export { BrandResilienceTesting }
