import { Dispatch, SetStateAction, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import * as yup from 'yup'

import { Box, FormWrapper, Message, Modal, RadioboxGroup, Select, useNotify } from '@cutover/react-ui'
import { LicenseKeyOutput } from '../helpers/license-key-output'
import { queryClient } from 'main/query-client'
import { ApiError } from 'main/services/api'
import { useLanguage } from 'main/services/hooks'
import { ConnectSetting, ConnectStatus } from 'main/services/queries/types/connect'
import { useConnectSettingsPatch, useFetchConnectVersions } from 'main/services/queries/use-connect-settings-query'

type AdditionalDownloadsConnectSetting = ConnectSetting & {
  download_type?: string
}

type ConnectDownloadsModalType = {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  item: AdditionalDownloadsConnectSetting | undefined
}

type DownloadPayloadType = {
  status: ConnectStatus
  additional_operations?: {
    request_new_version?: {
      connect_version: string
    }
    request_full_package?: {
      connect_version: string
    }
    generate_license_key?: {}
  }
}

export const ConnectDownloadsModal = ({ open, setOpen, item }: ConnectDownloadsModalType) => {
  const { t } = useLanguage('connectSettings')
  const notify = useNotify()
  const [formErrors, setFormErrors] = useState<{ [key: string]: { message: string } }>({})
  const patchMutation = useConnectSettingsPatch()
  const { isLoading: isPatchLoading } = patchMutation
  const { data: versions } = useFetchConnectVersions()
  const [connectJarCommand, setConnectJarCommand] = useState('')
  const downloadTypes = [
    {
      label: t('modal.additionalDownloads.radioButtons.jarOnly'),
      value: 'JAR file only'
    },
    {
      label: t('modal.additionalDownloads.radioButtons.fullPackage'),
      value: 'Setup package and license key'
    }
  ]

  const writeConnectJarCommand = (key: string, pass: string, connectVersion: string) => {
    const jarCmd =
      'java \\' +
      `\n-Dconnect.license.secret=${pass} \\` +
      '\n-Dconnect.license.key=\\' +
      `\n${key} \\` +
      `\n-jar CutoverConnect-${connectVersion}.jar`
    setConnectJarCommand(jarCmd)
  }

  const onSubmitConnectDownloadsModal = async (formData: AdditionalDownloadsConnectSetting) => {
    setFormErrors({})

    if (connectJarCommand !== '') {
      return handleCloseConnectDownloads()
    }

    const setting = {
      ...item,
      ...formData
    }

    let payload: DownloadPayloadType = { status: 'Active' as ConnectStatus }

    if (setting.download_type === 'JAR file only') {
      payload = {
        ...payload,
        additional_operations: {
          request_new_version: {
            connect_version: setting?.connect_version
          },
          generate_license_key: {}
        }
      }
    }

    if (setting.download_type === 'Setup package and license key') {
      payload = {
        ...payload,
        additional_operations: {
          request_full_package: {
            connect_version: setting?.connect_version
          },
          generate_license_key: {}
        }
      }
    }

    patchMutation.mutate(
      { id: setting?.id, payload },
      {
        onSuccess: data => {
          const results = data.meta.additional_operation_results
          const url = results?.request_new_version?.url || results?.request_full_package?.url
          window.open(url)

          if (setting.download_type === 'Setup package and license key') {
            const key = data.meta.additional_operation_results.generate_license_key.license_key
            const pass = data.meta.additional_operation_results.generate_license_key.passphrase
            writeConnectJarCommand(key, pass, setting?.connect_version)
          } else {
            handleCloseConnectDownloads()
          }

          queryClient.invalidateQueries(['connect-settings'])
          notify.success(t('modal.additionalDownloads.toasters.success'))
        },
        onError: error => {
          const castedError = error as ApiError
          notify.error(castedError?.errors?.[0])
          setOpen(false)
        }
      }
    )
  }

  const validationSchema = yup.object().shape({
    connect_version: yup.string().required(t('modal.additionalDownloads.formErrors.versionRequired')),
    download_type: yup.string().required(t('modal.additionalDownloads.formErrors.downloadTypeRequired'))
  })

  const methods = useForm<AdditionalDownloadsConnectSetting>({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      download_type: 'JAR file only',
      connect_version: ''
    }
  })

  const { control } = methods

  const handleCloseConnectDownloads = () => {
    setConnectJarCommand('')
    setOpen(false)
    setFormErrors({})
    methods.reset()
  }

  const handleErrors = (error: any) => {
    setFormErrors(error)
  }

  const formatErrors = () => {
    return Object.values(formErrors)
      .map(error => error.message)
      .join(', ')
  }

  const isErrorFor = (property: string) => {
    return property in formErrors
  }

  return (
    <Modal
      open={open}
      title={t('modal.additionalDownloads.title')}
      onClose={handleCloseConnectDownloads}
      onClickConfirm={methods.handleSubmit(onSubmitConnectDownloadsModal, handleErrors)}
      confirmText={
        connectJarCommand !== ''
          ? t('modal.additionalDownloads.continueButton')
          : t('modal.additionalDownloads.downloadButton')
      }
      loading={isPatchLoading}
      loadingText={t('loading')}
    >
      <Message message={formatErrors() as string} type="error" />
      <Box height={{ min: '200px' }}>
        {
          <FormProvider {...methods}>
            <FormWrapper>
              <Controller
                name="connect_version"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    options={versions}
                    value={value}
                    label={t('modal.additionalDownloads.versionLabel')}
                    required
                    hasError={isErrorFor('connect_version')}
                    onChange={onChange}
                    disabled={isPatchLoading || connectJarCommand !== ''}
                  />
                )}
              />
              {connectJarCommand === '' && (
                <Controller
                  name="download_type"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <RadioboxGroup
                      name={'download_type'}
                      label={t('modal.additionalDownloads.downloadTypeLabel')}
                      value={value}
                      hasError={isErrorFor('download_type')}
                      onChange={onChange}
                      direction="column"
                      disabled={isPatchLoading}
                      options={downloadTypes}
                    />
                  )}
                />
              )}
            </FormWrapper>
          </FormProvider>
        }
        {connectJarCommand !== '' && <LicenseKeyOutput command={connectJarCommand} />}
      </Box>
    </Modal>
  )
}
