import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Pause = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Pause" {...props}>
    <path d="M15 6a4 4 0 0 0-4 4v28a4 4 0 0 0 8 0V10a4 4 0 0 0-4-4Zm18 0a4 4 0 0 0-4 4v28a4 4 0 0 0 8 0V10a4 4 0 0 0-4-4Z" fill="#000" />
  </StyledIcon>
))

Pause.displayName = 'Pause'

export { Pause }
