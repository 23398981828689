// @ts-nocheck
import { inject, injectable } from 'inversify'
import { observable, action } from 'mobx'
import { unescape } from 'lodash'
import { IAccount } from 'Shared/Entities/Types/Account/IAccount'
import { IAccountRoleType } from 'Shared/Entities/Types/Account/IAccountRoleType'
import { AccountsRepository } from 'app/Repositories/Account/AccountsRepository'
import { PermissableRepository } from 'Shared/Repositories/PermissableRepository'

interface IUserDTO {
  id: number
  name: string
  handle: string
  avatar: string
  online: string
  color: string
  archived: boolean
  not_in_account: boolean
  is_admin: boolean
  role_id: number
  expired: string
  expires_at: string
}

interface IRoleTypeDTO {
  id: number
  name: string
  description: string
  users: IUserDTO[]
  context_type_id: number
  order: number
}

interface IAccountRole {
  id: number
  role_type_id: number
  resource_type: string
  subject_id: number
  subject_type: string
}

interface IAccountAddEditDTO {
  id?: number
  title: string
  description: string
  account_type_id: string
  slug: string
  roles: IAccountRole[]
  name?: string
}

interface IAccountDTO {
  account: {
    id: number
    title: string
    description: string
    role_types: IRoleTypeDTO[]
    permissions: {
      update: number[]
      archive: number[]
    }
    name: string
    slug: string
  }
}

interface IAccountResponse {
  success: boolean
  body: {
    account: IAccountDTO
  }
}

@injectable()
export class AccountRepository extends PermissableRepository {
  @inject(AccountsRepository)
  private accountsRepository: AccountsRepository

  @observable
  public accountForEdit: IAccount = null

  @observable
  public archiving: boolean = false

  @observable
  public selectedId: number = null

  constructor() {
    super('account')
  }

  public preLoad = (args: { [key: string]: any }): string => {
    return 'accounts/' + this.selectedId + '/edit'
  }

  public postLoad = (rawData: IAccountResponse) => {
    this.setAccountPm(rawData.body.account)
  }

  @action
  public loadAccount = async (accountId: number) => {
    if (this.accountForEdit && this.accountForEdit.id === accountId) return
    this.selectedId = accountId
    await this.loadData()
  }

  @action
  public clearSelectedId = () => {
    this.selectedId = null
  }

  public getDtoFromAccount = (account: IAccount) => {
    const accountDto: IAccountAddEditDTO = {
      title: account.title,
      description: account.description,
      slug: account.slug,
      account_type_id: this.accountsRepository.getAccountTypeIdByName(account.type),
      roles: []
    }

    // Optional attributes
    if (account.id) accountDto.id = account.id
    if (account.name) accountDto.name = account.name

    if (account.roleTypes) {
      account.roleTypes.forEach(roleType => {
        roleType.users.forEach(user => {
          accountDto.roles.push({
            id: user.roleId,
            role_type_id: roleType.id,
            resource_type: 'Account',
            subject_id: user.id,
            subject_type: 'User'
          })
        })
      })
    }
    return accountDto
  }

  @action
  public addAccount = async (account: IAccount) => {
    const accountDto = { account: this.getDtoFromAccount(account) }
    const responseDto = await this.serviceGateway.post('accounts', accountDto)
    window.angularJS && window.angularJS.reloadAccounts && window.angularJS.reloadAccounts()
    return responseDto
  }

  @action
  public saveAccount = async (account: IAccount) => {
    this.loading = true
    const updatedAccount = { ...account, id: this.accountForEdit.id }
    const accountDto = { account: this.getDtoFromAccount(updatedAccount) }
    const responseDto = await this.serviceGateway.put(`accounts/${updatedAccount.id}`, accountDto)

    if (responseDto.success) {
      this.setAccountPm(responseDto.body.account)
      this.accountsRepository.updateAccountsWithEditedAccount(this.accountForEdit)
      window.angularJS && window.angularJS.reloadAccounts && window.angularJS.reloadAccounts()
    }

    this.loading = false
    return responseDto
  }

  @action
  private setAccountPm = account => {
    this.accountForEdit = {
      id: account.id,
      title: unescape(account.title),
      description: unescape(account.description),
      type: account.account_type_name ? unescape(account.account_type_name) : 'Custom',
      roleTypes: [],
      permissions: account.permissions,
      name: unescape(account.name),
      slug: account.slug
    }

    account.role_types.forEach(role_type => {
      let roleType: IAccountRoleType = {
        id: role_type.id,
        contextTypeId: role_type.context_type_id,
        description: role_type.description,
        name: role_type.name,
        order: role_type.order,
        users: []
      }

      role_type.users.forEach(user => {
        roleType.users.push({
          id: user.id,
          name: user.name,
          handle: user.handle,
          avatar: user.avatar,
          online: user.online,
          color: user.color,
          archived: user.archived,
          notInAccount: user.not_in_account,
          isAdmin: user.is_admin,
          roleId: user.role_id,
          expired: user.expired,
          expiresAt: user.expires_at
        })
      })

      this.accountForEdit.roleTypes.push(roleType)
    })
  }

  @action
  public archive = async (accountIdToDelete: number) => {
    this.loading = true
    const responseDto = await this.serviceGateway.patch(`accounts/${accountIdToDelete}/archive`)

    if (responseDto.success) {
      this.accountForEdit = null
      this.accountsRepository.safeLoadAccounts(true)
      window.angularJS && window.angularJS.reloadAccounts && window.angularJS.reloadAccounts()
    }

    this.loading = false
    return responseDto
  }

  public getAccountHistory = (rawHistory: any) => {}
}
