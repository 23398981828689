// @ts-nocheck
import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { withPresenter } from 'app/Config/withPresenter'
import { RunbookUsersPagePresenter } from './RunbookUsersPagePresenter'
import { RunbookUsersPageHeadingSection } from './RunbookUsersPageHeading/RunbookUsersPageHeadingSection'
import {
  PageWrapper,
  PageWrapperInner,
  HeaderPanelWrapper,
  CenterMainWrapper,
  MainPanelWrapper,
  GenericList
} from 'Components/Templates'

interface IRunbookUsersPageComponentProps {
  presenter: RunbookUsersPagePresenter
}

const RunbookUsersPageComponent = observer<IRunbookUsersPageComponentProps>(({ presenter }) => {
  const { runbookUsersPageHeadingPresenter, runbookUserListPresenter, usersDataLoading, loadList } = presenter

  useEffect(() => {
    window.reactJS.loadRunbookUsers = loadList
    return () => {
      delete window.reactJS.loadRunbookUsers
    }
  }, [])

  return (
    <PageWrapper>
      <PageWrapperInner>
        <HeaderPanelWrapper>
          <RunbookUsersPageHeadingSection presenter={runbookUsersPageHeadingPresenter} />
        </HeaderPanelWrapper>
        <CenterMainWrapper>
          <MainPanelWrapper loading={usersDataLoading}>
            <GenericList listPresenter={runbookUserListPresenter} />
          </MainPanelWrapper>
        </CenterMainWrapper>
      </PageWrapperInner>
    </PageWrapper>
  )
})

export default withPresenter(RunbookUsersPagePresenter)(RunbookUsersPageComponent)
