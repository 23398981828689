import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const TaskTypeDiamond = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="TaskTypeDiamond" {...props}>
    <path d="m26.83 7.032 14.143 14.142a4 4 0 0 1 0 5.657L26.83 40.973a4 4 0 0 1-5.657 0L7.032 26.83a4 4 0 0 1 0-5.657L21.174 7.032a4 4 0 0 1 5.657 0Zm1.415-1.415a6 6 0 0 0-8.485 0L5.617 19.76a6 6 0 0 0 0 8.485L19.76 42.387a6 6 0 0 0 8.485 0l14.142-14.142a6 6 0 0 0 0-8.485L28.245 5.617Z" fill="#000" />
  </StyledIcon>
))

TaskTypeDiamond.displayName = 'TaskTypeDiamond'

export { TaskTypeDiamond }
