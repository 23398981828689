import { PaintMeta } from '../node-map-types'

export const paintMeta = ({ ctx, state }: PaintMeta) => {
  const { miniCanvasScale } = state

  const textSize = state.units.base / miniCanvasScale
  const viewportCoords = [4 / miniCanvasScale, 4 / miniCanvasScale]

  ctx.font = `${textSize}px Arial`
  ctx.textBaseline = 'top'
  ctx.fillStyle = '#97a4aa'
  ctx.textAlign = 'left'
  ctx.fillText(`${Math.round(state.transform.scale * 100) / 100}x`, viewportCoords[0], viewportCoords[1])
}
