import { ReactNode } from 'react'
import { PolymorphicComponent } from 'react-polymorphic-box'
import styled from 'styled-components/macro'

import { px2rem } from '../../utils'

export type LegacyBoxProps = {
  margin?: number[] | number
  padding?: number[] | number
  children?: ReactNode
  height?: number | string
  width?: number | string
}

const defaultElement = 'div'

const parseStyleValue = function (value: number[] | number) {
  if (value instanceof Array) {
    return px2rem(...value)
  }
  return px2rem(value)
}

/**
 * @deprecated Use grommet box exported as Box (https://v2.grommet.io/box)
 */
export const LegacyBox: PolymorphicComponent<LegacyBoxProps, typeof defaultElement> = styled(
  defaultElement
)<LegacyBoxProps>`
  height: ${({ height }) => height && (typeof height === 'number' ? px2rem(height) : height)};
  width: ${({ width }) => width && (typeof width === 'number' ? px2rem(width) : width)};

  ${({ margin }: LegacyBoxProps) =>
    margin &&
    `
    margin: ${parseStyleValue(margin)};
  `}
  ${({ padding }: LegacyBoxProps) =>
    padding &&
    `
    padding: ${parseStyleValue(padding)};
  `}
`
