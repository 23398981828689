import { MouseEvent } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { Box, DisclosurePanelHeader } from '@cutover/react-ui'
import { AppsComponentParser } from '../apps-component-parser'
import { ContentNode } from '../apps-types'

type PanelEditHeaderProps = {
  appId: string
  resourceId?: string
  content?: ContentNode[]
  isOpen?: boolean
  title: string
  isActive?: boolean
  onClick?: (e: MouseEvent<HTMLElement>) => void
}

export const PanelHeader = ({ appId, resourceId, content, ...props }: PanelEditHeaderProps) => {
  const { isActive } = props

  const panelHeaderContent = (
    <>
      {content?.map(node => {
        return (
          <Box flex={false} key={`${appId}-${uuidv4()}`}>
            <AppsComponentParser content={[node]} appId={appId} resourceId={resourceId} />
          </Box>
        )
      })}
    </>
  )

  return <DisclosurePanelHeader {...props}>{content && isActive && panelHeaderContent}</DisclosurePanelHeader>
}
