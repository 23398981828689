import { ResponseType } from 'axios'

import { apiClient_UNSTABLE } from './api-client-unstable'

type RequestParams = Record<string, string | string[] | number | number[] | boolean | null | undefined>

type RequestWithoutBodyOptions = {
  url: string
  params?: RequestParams
  headers?: Record<string, string>

  // These types are added for compatibility reasons only
  responseType?: ResponseType
}

type RequestWithBodyOptions<TRequestData> = RequestWithoutBodyOptions & {
  data?: TRequestData
}

/**
 * @deprecated Please use apiClient_UNSTABLE instead. This version only exists to help
 * in migrating from older apiClient instances.
 */
export const apiClientCompat = {
  get: <ResponseType extends object = {}, RequestType extends object = {}>(config: RequestWithoutBodyOptions) => {
    return apiClient_UNSTABLE.get<RequestType, { data: ResponseType }>(config.url, {
      ...config
    })
  },
  post: <RequestType extends object = {}, ResponseType extends object = {}>(
    config: RequestWithBodyOptions<RequestType>
  ) => {
    return apiClient_UNSTABLE.post<RequestType, { data: ResponseType }>(config.url, config.data, {
      ...config
    })
  },
  patch: <RequestType extends object = {}, ResponseType extends object = {}>(
    config: RequestWithBodyOptions<RequestType>
  ) => {
    return apiClient_UNSTABLE.patch<RequestType, { data: ResponseType }>(config.url, config.data, {
      ...config
    })
  },
  put: <RequestType extends object = {}, ResponseType extends object = {}>(
    config: RequestWithBodyOptions<RequestType>
  ) => {
    return apiClient_UNSTABLE.put<RequestType, { data: ResponseType }>(config.url, config.data, {
      ...config
    })
  },
  delete: <ResponseType extends object = {}, RequestType extends object = {}>(config: RequestWithoutBodyOptions) => {
    return apiClient_UNSTABLE.delete<RequestType, { data: ResponseType }>(config.url, {
      ...config
    })
  }
}
