// @ts-nocheck
import { TIcons } from 'Components/Atoms'
import { GenericFormInputPresenter } from '@logicroom/validator'
import { observable } from 'mobx'
import { TTextInputType } from './TTextInputType'
import { TToolTipPosition } from 'Components/Molecules/ToolTip/TToolTipPosition'
import { valueExistsWhenTrimmed } from 'Shared/Helpers/InputCustomRules'

export class TextInputPresenter extends GenericFormInputPresenter<string> {
  @observable
  public disabled = false

  @observable
  public label = ''

  @observable
  public required = false

  @observable
  public iconPrefix: TIcons

  @observable
  public iconPrefixColor: string

  @observable
  public helpText: string

  @observable
  public multiline = false

  @observable
  public type: TTextInputType = 'text'

  public placeholder = ''

  public maxLength: number

  public matchers: string[]

  @observable
  public helpTextToolTipPosition: TToolTipPosition = 'left center'

  public valueIsUnique = value => {
    return this.matchers.indexOf(value) === -1
  }

  constructor(initialValue = '') {
    super(initialValue)
  }

  public withIconPrefix = (iconPrefix: TIcons) => {
    this.iconPrefix = iconPrefix
    return this
  }

  public withIconPrefixColor = (color: string) => {
    this.iconPrefixColor = color
    return this
  }

  public withHelpText = (helpText: string) => {
    this.helpText = helpText
    return this
  }

  public withLabel = (label: string) => {
    this.label = label
    return this
  }

  public withPlaceholder = (placeholder: string) => {
    this.placeholder = placeholder
    return this
  }

  public withType = (type: TTextInputType) => {
    this.type = type
    return this
  }

  public isDisabled = () => {
    this.disabled = true
    return this
  }

  public isMultiline = () => {
    this.multiline = true
    return this
  }

  public ofMaxLength = (maxLength: number) => {
    this.maxLength = maxLength
    return this
  }

  public isRequiredTrimWhiteSpace = (errorMessage?: string) => {
    this.isRequired(errorMessage)
    this.addCustomRule(valueExistsWhenTrimmed, errorMessage)
    return this
  }

  public isUnique = (matchers: string[], errorMessage?: string) => {
    this.matchers = matchers
    this.addCustomRule(this.valueIsUnique, errorMessage)
  }

  public withHelpTextToolTipPosition = (position: TToolTipPosition) => {
    this.helpTextToolTipPosition = position
    return this
  }
}
