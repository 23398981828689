// @ts-nocheck
import { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { withPresenter } from 'app/Config/withPresenter'
import { CustomFieldsPagePresenter } from './CustomFieldsPagePresenter'
import {
  PageWrapper,
  PageWrapperInner,
  HeaderPanelWrapper,
  CenterMainWrapper,
  FilterPanelWrapper,
  MainPanelWrapper,
  EditPanelWrapper,
  GenericList,
  SectionNavPanelWrapper
} from 'Components/Templates'
import { CustomFieldPageHeadingSection } from './CustomFieldPageHeading/CustomFieldPageHeadingSection'
import { CustomFieldFilterSection } from './CustomFieldFilter/CustomFieldFilterSection'
import { CustomFieldsPageModals } from './CustomFieldsPageModals'
import { AddButton } from 'Components/Atoms'
import CustomFieldEditSection from './CustomFieldEdit/CustomFieldEditSection'
import { fluidPage } from 'Shared/HooksAndPresenters/FluidPage'
import { ViewModelType } from 'Shared/Presenters/GenericAdminViewPresenter'
import { Submitted } from 'Shared/Entities/Enums/Submitted'
import { CustomFieldSectionNav } from '../CustomFieldSectionNav'

interface ICustomFieldPageComponentProps {
  presenter: CustomFieldsPagePresenter
}

const CustomFieldsPageComponent = observer<ICustomFieldPageComponentProps>(({ presenter }) => {
  const {
    listSectionPresenter,
    headingSectionPresenter,
    filterSectionPresenter,
    editOpen,
    setEditClosed,
    addFormPresenter,
    editFormPresenter,
    filterPanelLoading,
    editRecordVMChangeFunction,
    editRecordVM,
    resetForm,
    angularJSSettingsPanelOpenState,
    checkEditPanelIsDirty,
    archiveResponseErrors,
    setArchiveResponseErrors,
    resetArchive,
    archiveState,
    setArchiveState,
    launchAddSuccessToaster,
    launchEditSuccessToaster,
    archiveConfirmName,
    archiveModalOpenState,
    editSubmittedState,
    setEditSubmittedState,
    archiveModalPresenter,
    archivePostSuccessCallback,
    canCreate,
    editPanelLoading,
    clearEditPanel,
    language,
    confirmMode
  } = presenter

  const [customFieldAddModalOpenState, setCustomFieldAddModalOpenState] = useState(false)
  const { showAddButton, filterPanelOpen, setFilterPanelOpenState } = fluidPage(
    editOpen,
    setEditClosed,
    checkEditPanelIsDirty,
    angularJSSettingsPanelOpenState
  )

  useEffect(() => {
    if (editRecordVM) {
      editRecordVMChangeFunction()
    }
  }, [editRecordVM])

  const addModalCallback = (modalOpen: boolean) => {
    setCustomFieldAddModalOpenState(modalOpen)
  }

  return (
    <PageWrapper>
      <PageWrapperInner>
        <HeaderPanelWrapper>
          <CustomFieldPageHeadingSection
            filterOpen={filterPanelOpen}
            setFilterOpen={setFilterPanelOpenState}
            presenter={headingSectionPresenter}
          />
        </HeaderPanelWrapper>
        <CenterMainWrapper>
          <FilterPanelWrapper filterPanelOpen={filterPanelOpen} loading={filterPanelLoading}>
            {filterPanelOpen && <CustomFieldFilterSection presenter={filterSectionPresenter} />}
          </FilterPanelWrapper>
          <MainPanelWrapper filterPanelOpen={filterPanelOpen} extraStyles={{ zIndex: 1 }}>
            <GenericList listPresenter={listSectionPresenter} />
            {showAddButton && canCreate && (
              <AddButton
                action={() => {
                  setCustomFieldAddModalOpenState(true)
                }}
                language={language}
              />
            )}

            <CustomFieldsPageModals
              addModalOpenState={customFieldAddModalOpenState}
              setAddModalOpenState={addModalCallback}
              addFormPresenter={addFormPresenter}
              addFormCancelCallback={() => {
                resetForm(ViewModelType.Add)
              }}
              archiveModalOpenState={archiveModalOpenState}
              archiveConfirmName={archiveConfirmName}
              archiveModalPresenter={archiveModalPresenter}
              cancelArchiveCallback={resetArchive}
              continueArchiveCallback={() => {
                setArchiveState(Submitted.SubmittedAndConfirmed)
              }}
              archiveResponseErrors={archiveResponseErrors}
              editSubmittedState={editSubmittedState}
              setEditSubmittedState={setEditSubmittedState}
              addPostSuccessCallback={() => {
                launchAddSuccessToaster()
                resetForm(ViewModelType.Add)
              }}
              language={language}
              confirmMode={confirmMode}
            />
          </MainPanelWrapper>
        </CenterMainWrapper>
      </PageWrapperInner>
      <EditPanelWrapper
        isOpen={editOpen}
        loading={editPanelLoading}
        withSectionNav={true}
        panelCloseCallback={() => {
          clearEditPanel()
        }}
      >
        <CustomFieldEditSection
          editFormPresenter={editFormPresenter}
          setArchiveResponseErrors={setArchiveResponseErrors}
          archiveState={archiveState}
          setArchiveState={setArchiveState}
          editPostSuccessCallback={() => {
            launchEditSuccessToaster()
          }}
          archivePostSuccessCallback={archivePostSuccessCallback}
          submittedState={editSubmittedState}
          setSubmittedState={setEditSubmittedState}
        />
      </EditPanelWrapper>
    </PageWrapper>
  )
})

export default withPresenter(CustomFieldsPagePresenter)(CustomFieldsPageComponent)
