// @ts-nocheck
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { px2rem } from 'app/Styles/Variables'
import { RunbookTypeFilterPresenter } from './RunbookTypeFilterPresenter'
import { FilterDisclosurePanel } from 'Components/Molecules'

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: auto;
  box-sizing: border-box;
`

const FilterWrapper = styled.div`
  margin: 0 0 ${px2rem(56)} ${px2rem(16)};
`

interface IRunbookTypeListComponentProps {
  presenter: RunbookTypeFilterPresenter
}

export const RunbookTypeFilterSection = observer<IRunbookTypeListComponentProps>(({ presenter }) => {
  const { visibilityFiltersDisclosurePanel, displayFilterPanel } = presenter

  return (
    <Wrapper>
      <FilterWrapper>
        {displayFilterPanel && <FilterDisclosurePanel presenter={visibilityFiltersDisclosurePanel} />}
      </FilterWrapper>
    </Wrapper>
  )
})
