import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const BrandDatacenterMigration = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 100 101" a11yTitle="BrandDatacenterMigration" {...props}>
    <g clipPath="url(#a)" fill="#fff"><path opacity=".5" fillRule="evenodd" clipRule="evenodd" d="M100 .667H35.333v64.666H100V.667Zm-32.333 47c8.1 0 14.666-6.567 14.666-14.667s-6.566-14.667-14.666-14.667C59.567 18.333 53 24.9 53 33s6.566 14.667 14.667 14.667Z" /><circle cx="14.667" cy="86" r="14.667" /></g>
  </StyledIcon>
))

BrandDatacenterMigration.displayName = 'BrandDatacenterMigration'

export { BrandDatacenterMigration }
