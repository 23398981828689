import { useState } from 'react'
import { Heading } from 'grommet'
import styled from 'styled-components'

import { Box, Button } from '@cutover/react-ui'
import { CreateNewDataSourceModal } from '../modals/create-new-data-source-modal'
import { useLanguage } from 'main/services/hooks'
import { usePermissions } from 'main/services/queries/use-permissions'

export const DataSourcesHeader = () => {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
  const { t } = useLanguage('dataSources')

  const handleClickCreate = () => {
    setIsCreateModalOpen(true)
  }

  const permissions = usePermissions('data-sources')
  const userCanCreateDataSources = permissions('create')

  return (
    <Box direction="row" width="100%">
      <Box flex="grow" justify="center">
        <DataSourcesTitle responsive={false} margin={{ left: 'xsmall', right: 'none', vertical: 'none' }} level="1">
          {t('dataSources.header.title.label')}
        </DataSourcesTitle>
      </Box>
      <Box>
        {userCanCreateDataSources && (
          <Button
            icon="add"
            primary
            label={t('dataSources.header.addButton.label')}
            onClick={handleClickCreate}
            data-testid="add-data-source-button"
          />
        )}
      </Box>
      <CreateNewDataSourceModal open={isCreateModalOpen} setOpen={setIsCreateModalOpen} />
    </Box>
  )
}

const DataSourcesTitle = styled(Heading)`
  font-size: 20px;
  line-height: 1.2;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
`
