export const textFieldType = 'text'
export const textAreaFieldType = 'textarea'
export const userSelectFieldType = 'user_select'
export const endpointFieldType = 'endpoint'
export const checkboxFieldType = 'checkboxes'
export const selectFieldType = 'select_menu'
export const radioboxFieldType = 'radiobox'
export const dateFieldType = 'datetime'
export const searchableFieldType = 'searchable'
export const multiSearchableFieldType = 'multi_searchable'
export const taskPickerFieldType = 'task_picker'
