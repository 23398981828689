// @ts-nocheck
import { action, observable, when } from 'mobx'
import { inject, injectable } from 'inversify'
import { unescape } from 'lodash'
import { IAccount } from 'app/Shared/Entities/Types/Account/IAccount'
import { IAccountEntry } from 'app/Shared/Entities/Types/Account/IAccountEntry'
import { IAccountType } from 'app/Shared/Entities/Types/Account/IAccountType'
import { IBaseResponse } from 'Gateways/Service/IBaseResponse'
import { QueryStringBuilder } from 'app/Shared/Helpers/QueryStringBuilder'
import { PermissableRepository } from 'app/Shared/Repositories/PermissableRepository'

interface IAccountDTO {
  id: number
  name: string
  account_type_name?: string
  icon_name: string
  slug: string
  title: string
  events_count: number
  projects_count: number
  runbooks_count: number
  users_count: number
}

interface IAccountTypeDTO {
  id: string
  name: string
  icon_name: string
}

@injectable()
export class AccountsRepository extends PermissableRepository {
  @inject(QueryStringBuilder)
  private queryStringBuilder: QueryStringBuilder

  constructor() {
    super('meta')
  }

  @observable
  public accountsFilteredTotal: number

  @observable
  public accountsTotal: number

  @observable
  public accounts: IAccountEntry[] = []

  @observable
  public accountTypes: IAccountType[] = []

  @observable
  public permittedAccounts: IAccountEntry[] = []

  @observable
  public loadedPermitted: string

  @observable
  public isLoadingPermitted: boolean = false

  public permittedAccountsLoaded: boolean = false

  public clearPermittedAccounts = () => {
    this.permittedAccounts = []
    this.loadedPermitted = undefined
    this.permittedAccountsLoaded = false
  }

  public safeLoadAccounts = async (reload?: boolean) => {
    if (this.accounts.length > 0 && !reload) return

    if (this.loading) {
      await when(() => this.accounts.length > 0)
    } else {
      await this.loadData()
    }
  }

  public preLoad = (args: { [key: string]: any }): string => {
    let sortParams: { [key: string]: any } = null
    if (args && args.sortParams !== null) sortParams = args.sortParams
    const url = 'accounts' + this.queryStringBuilder.getQueryString(null, sortParams)
    return url
  }

  @action
  public postLoad = (rawData: IBaseResponse) => {
    this.accounts = rawData.body.accounts.map((accountDto: IAccountDTO) => this.getAccountFromDto(accountDto))
    this.accountsTotal = rawData.body.meta.total_accounts
    this.accountsFilteredTotal = rawData.body.meta.filtered_accounts
    this.accountTypes =
      rawData.body.meta.account_types && rawData.body.meta.account_types.map(this.getAccountTypeFromDto)
  }

  public safeLoadPermittedAccounts = async ({ resource, reload }: { resource: string; reload?: boolean }) => {
    if (this.isPermittedAccountsLoaded(resource) && !reload) return

    if (this.isLoadingPermitted) {
      await when(() => this.permittedAccountsLoaded)
    } else {
      await this.loadPermittedAccounts(resource)
    }
  }

  public isPermittedAccountsLoaded(resource: string) {
    return this.permittedAccountsLoaded && this.loadedPermitted === resource
  }

  @action
  private loadPermittedAccounts = async (resource: string) => {
    this.isLoadingPermitted = true
    this.loadedPermitted = resource
    const request = 'accounts/permitted?resource=' + resource
    const { body } = await this.serviceGateway.get(request)
    this.permittedAccounts = body.accounts.map((accountDto: IAccountDTO) => {
      return this.getAccountFromDto(accountDto)
    })
    this.permittedAccountsLoaded = true
    this.isLoadingPermitted = false
  }

  private getAccountFromDto = (accountDto: IAccountDTO): IAccountEntry => {
    const account = {
      eventsCount: accountDto.events_count,
      id: accountDto.id,
      name: unescape(accountDto.name),
      projectsCount: accountDto.projects_count,
      runbooksCount: accountDto.runbooks_count,
      slug: accountDto.slug,
      title: unescape(accountDto.title),
      type: unescape(accountDto.account_type_name) || 'Custom',
      usersCount: accountDto.users_count
    }
    return account
  }

  private getAccountTypeFromDto = ({ id, name, icon_name }: IAccountTypeDTO): IAccountType => ({
    id,
    name,
    iconName: icon_name
  })

  @action
  public updateAccountsWithEditedAccount = (editedAccount: IAccount) => {
    this.loading = true
    const { title, name, slug, description, type } = editedAccount

    const updatedAccounts = this.accounts.map((account: IAccountEntry) =>
      account.id !== editedAccount.id ? account : { ...account, name, title, slug, description, type }
    )

    this.accounts = updatedAccounts
    this.loading = false
  }

  public getAccountTypeIdByName = (name: string) => {
    if (!name) return ''
    const accountType = this.accountTypes.find(accountType => accountType.name === name)
    return accountType ? accountType.id : ''
  }

  public checkAccountsLoaded = (displayType = undefined): boolean => {
    return this.accounts.length > 0 ? true : false
  }
}
