import React from 'react'
import ReactDOMClient from 'react-dom/client'
import { ReactPortalComponents } from 'react-portal-components'
import singleSpaReact from 'single-spa-react'

const reactLifecycles = singleSpaReact({
  React,
  ReactDOMClient,
  rootComponent: ReactPortalComponents,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  domElementGetter: () => document.getElementById('react-portal-components')!
})

export default reactLifecycles
