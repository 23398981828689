import { createGlobalStyle, css } from 'styled-components/macro'

import { CSSDimensionConstraints, themeColor, themeEdgeSize, themeElevation, ThemeType } from './'

export const GlobalStyles = ({ enableFocusRings = false }: { enableFocusRings?: boolean }) => {
  return (
    <>
      <BaseGlobalStyles />
      <ColorPickerGlobalStyles />
      {enableFocusRings && <FocusRingGlobalStyles />}
    </>
  )
}

const BaseGlobalStyles = createGlobalStyle`
  html {
    box-sizing: border-box;
    background-color: white;
  }
  *, *::before, *::after {
    box-sizing: inherit;
  }
  * {
    -webkit-font-smoothing: antialiased;
  }
`

/*
  The :focus-visible pseudo-class allows for differentiation of mouse and keyboard users.
  In general, :focus-visible styles will only be applied for keyboard users (text inputs should always indicate some focus).

  Browsers that don't support :focus-visible will fallback to browser defaults

  Remove the focus indicator on mouse-focus for browsers that do support :focus-visible
*/
export const FocusRingGlobalStyles = createGlobalStyle`
  @supports selector(:focus-visible) {
    *[tabindex="0"],
    *[tabindex="-1"],
    button,
    input,
    select,
    textarea,
    area,
    a {
      &:focus {
        outline: none;
      }
    }
  }
  /* Draw a noticeable focus ring for keyboard-focus on browsers that do support :focus-visible */
  *[tabindex="0"],
  *[tabindex="-1"],
  button,
  select,
  textarea,
  area,
  a {
    &:focus-visible,
    // the go- are angular directives that can be removed once angular is gone.
    &[go-checkbox].md-focused .md-icon,
    &[go-radio-group].md-focused .md-checked .md-off {
      transition: box-shadow .1s ease-in-out !important;
      outline: none;
      box-shadow: 0px 0px 0px 2px white, 0px 0px 0px 4px ${({ theme }) => themeColor('primary')({ theme })} !important;
      opacity: 1 !important;
      z-index: 1 !important;
    }
  }
  /* Remove ring from first programatically focused element in modals */
  *[aria-modal="true"] a[tabindex="-1"][aria-hidden="true"]:first-of-type {
    box-shadow: none !important;
  }
  /* Customize :focus-visible ring on click in text inputs */
  input[type="email"],
  input[type="number"],
  input[type="search"],
  input[type="tel"],
  input[type="url"],
  input[type="password"],
  input[type="text"][tabindex],
  textarea,
  textarea[tabindex],
  *[contenteditable],
  // the go- are angular directives that can be removed once angular is gone.
  [go-checkbox],
  [go-radio-group],
  .no-focus {
    &:focus-visible {
      outline: none !important;
      box-shadow: unset !important;
    }
  }
`

export const focusRingCss = css<ThemeType>`
  transition: box-shadow 0.1s ease-in-out !important;
  outline: none;
  box-shadow: 0px 0px 0px 2px white, 0px 0px 0px 4px ${({ theme }) => themeColor('primary')({ theme })} !important;
  opacity: 1 !important;
  z-index: 1 !important;
`

export const focusRingInsetCss = css<ThemeType>`
  transition: box-shadow 0.1s ease-in-out !important;
  outline: none;
  box-shadow: inset 0px 0px 0px 2px ${({ theme }) => themeColor('primary')({ theme })} !important;
  opacity: 1 !important;
  z-index: 1 !important;
`

export const focusRingOutlineCss = css`
  // NOTE: transition 'all' is needed for safari. 'border-radius 0s' prevents animating from square border shape
  transition: all 0.1s ease-in-out, border-radius 0s !important;
  // workaround for safari not showing focus rings until repaint
  transform: translate(0, 0) translate3d(0, 0, 0) !important;
  outline: 2px solid ${({ theme }) => themeColor('primary')({ theme })} !important;
  outline-offset: 2px !important;
`

export const overlayStyles = (constraints?: CSSDimensionConstraints) => css`
  border-radius: 8px;
  user-select: none;
  padding: ${themeEdgeSize('xsmall')};
  box-shadow: ${themeElevation('medium')};
  background-color: ${themeColor('menu-bg')};
  min-width: ${constraints?.minWidth};
  max-width: ${constraints?.maxWidth};
  min-height: ${constraints?.minHeight};
  max-height: ${constraints?.maxHeight};
`

// NOTE: needed to add these styles here as didn't work on prod following the standard lib setup process
const ColorPickerGlobalStyles = createGlobalStyle`
  .rcp-root {
    --rcp-background-color: #121212;
    --rcp-field-input-color: #ffffff;
    --rcp-field-input-border-color: #242424;
    --rcp-field-label-color: #808080;
  }

  .rcp {
    display: flex;
    flex-direction: column;
    background-color: var(--rcp-background-color);
    border-radius: 10px;
  }

  .rcp-body {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px 10px;
  }

  .rcp-section {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .rcp-interactive {
    width: 100%;
    height: 100%;
    user-select: none;
    touch-action: none;
  }

  .rcp-saturation {
    cursor: all-scroll;
    width: 100%;
    position: relative;
    background-image: linear-gradient(to bottom, transparent, black), linear-gradient(to right, white, transparent);
    border-radius: 10px 10px 0 0;
  }

  .rcp-saturation-cursor {
    position: absolute;
    width: 20px;
    height: 20px;
    border: 2px solid white;
    border-radius: 100%;
    box-shadow: 0 0 15px 0 rgb(0 0 0 / 0.15);
    transform: translate(-10px, -10px);
  }

  .rcp-hue {
    cursor: ew-resize;
    position: relative;
    width: 100%;
    height: 12px;
    background-image: linear-gradient(
      to right,
      rgb(255, 0, 0),
      rgb(255, 255, 0),
      rgb(0, 255, 0),
      rgb(0, 255, 255),
      rgb(0, 0, 255),
      rgb(255, 0, 255),
      rgb(255, 0, 0)
    );
    border-radius: 10px;
  }

  .rcp-hue-cursor {
    position: absolute;
    width: 20px;
    height: 20px;
    border: 2px solid white;
    border-radius: 100%;
    box-shadow: 0 0 15px 0 rgb(0 0 0 / 0.15);
    transform: translate(-10px, -4px);
  }

  .rcp-alpha {
    cursor: ew-resize;
    position: relative;
    width: 100%;
    height: 12px;
    border-radius: 10px;
  }

  .rcp-alpha-cursor {
    position: absolute;
    width: 20px;
    height: 20px;
    border: 2px solid white;
    border-radius: 100%;
    box-shadow: 0 0 15px 0 rgb(0 0 0 / 0.15);
    transform: translate(-10px, -4px);
  }

  .rcp-fields {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .rcp-fields-floor {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    gap: 10px;
  }

  .rcp-field {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .rcp-field-input {
    width: 100%;
    font-family: inherit;
    font-size: 14px;
    text-align: center;
    color: var(--rcp-field-input-color);
    background-color: transparent;
    border: 2px solid var(--rcp-field-input-border-color);
    border-radius: 5px;
    outline: none;
    padding: 5px 0;
  }

  .rcp-field-label {
    font-size: 14px;
    text-align: center;
    color: var(--rcp-field-label-color);
  }
`
