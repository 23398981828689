// @ts-nocheck
import 'reflect-metadata'
import { InlineNumber } from 'Components/Molecules'
import { TimeControlPresenter } from './TimeControlPresenter'
import { withPresenter } from 'app/Config/withPresenter'
import { getHours, getMinutes } from 'Shared/Helpers/DateService'
import { observer } from 'mobx-react-lite'
import { padStart } from 'lodash'
import styled from 'styled-components'

const TimeControlWrapper = styled.div`
  display: flex;
`

interface ITimeControlProps {
  presenter: TimeControlPresenter
  value?: Date
  handleDateChange: (date: Date) => void
  handleTimeChange: (time: Date) => void
  disabled?: boolean
  className?: string
}

const TimeControlComponent = observer<ITimeControlProps>(props => {
  const { presenter, disabled, className } = props
  const {
    value,
    hourIncreaseHandler,
    hourDecreaseHandler,
    handleHourChange,
    minuteIncreaseHandler,
    minuteDecreaseHandler,
    handleMinuteChange
  } = presenter

  return (
    <TimeControlWrapper className={className}>
      <InlineNumber
        value={padStart(getHours(value), 2, '0')}
        onUp={hourIncreaseHandler}
        onDown={hourDecreaseHandler}
        disabled={disabled}
        onChange={handleHourChange}
        maxLength={2}
        maxValue={23}
      />
      :
      <InlineNumber
        value={padStart(getMinutes(value), 2, '0')}
        onUp={minuteIncreaseHandler}
        onDown={minuteDecreaseHandler}
        disabled={disabled}
        onChange={handleMinuteChange}
        maxLength={2}
        maxValue={59}
      />
    </TimeControlWrapper>
  )
})

export const TimeControl = withPresenter(TimeControlPresenter)(TimeControlComponent)
