import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Message = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Message" {...props}>
    <path d="M19.667 33 29 40v-7h5a5 5 0 0 0 5-5V14a5 5 0 0 0-5-5H14a5 5 0 0 0-5 5v14a5 5 0 0 0 5 5h5.667ZM32 46 18.667 36H14a8 8 0 0 1-8-8V14a8 8 0 0 1 8-8h20a8 8 0 0 1 8 8v14a8 8 0 0 1-8 8h-2v10Z" fill="#000" />
  </StyledIcon>
))

Message.displayName = 'Message'

export { Message }
