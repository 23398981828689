// @ts-nocheck
import { injectable } from 'inversify'
import { container } from 'app/Config/IOC'
import { observable, computed, action } from 'mobx'
import { GenericListPresenter } from 'Shared/Components/Templates/GenericList/GenericListPresenter'
import { CustomFieldGroupViewPresenter } from '../CustomFieldGroupViewPresenter'
import { ISortParams } from 'Shared/Components/Organisms/Table/ISortParams'
import { ITableColumnTypes } from 'Shared/Components/Organisms/Table/ITableColumnTypes'
import { TablePresenter } from 'Shared/Components/Organisms/Table/TablePresenter'
import { ICustomFieldGroupPM } from 'Shared/Entities/Types/CustomFieldGroup/ICustomFieldGroupPM'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'
import { Types } from 'Gateways/Service/Types'
import { Router } from 'app/Routing/Router'

@injectable()
export class CustomFieldGroupListPresenter implements GenericListPresenter {
  private language: ILanguageService = container.get(Types.ILanguageService)

  private router: Router = container.get(Router)

  @observable
  public viewPresenter: CustomFieldGroupViewPresenter = container.get(CustomFieldGroupViewPresenter)

  @computed
  public get viewModel(): ICustomFieldGroupPM[] {
    return this.viewPresenter.listVM
  }

  @computed
  public get sortParams(): ISortParams {
    return this.viewPresenter.sortParams
  }

  @observable
  public columns: ITableColumnTypes[] = [
    {
      accessor: 'name',
      name: this.language.get('customFieldGroups:list:tableHeadings:name'),
      width: 180,
      dataType: 'string',
      sortable: true
    },
    {
      accessor: 'numberOfCustomFields',
      name: this.language.get('customFieldGroups:list:tableHeadings:customFieldCount'),
      width: 180,
      dataType: 'hyperlink',
      sortable: true,
      hyperlinkOptions: {
        href: '',
        target: '_self',
        customHyperlinkFunction: id => {
          this.router.goToPageId('customFields', '', 'custom_field_group_id=' + id)
        },
        dataType: 'number'
      }
    }
  ]

  @computed
  public get reloadList() {
    return this.viewPresenter.reloadList
  }

  @computed
  public get filtersCount(): number {
    return this.viewPresenter.listTotal
  }

  public tableName: string = 'custom-field-group-list'

  public context: string = 'custom field group'

  @computed
  public get totalCount(): number {
    return this.viewPresenter.listTotal
  }

  public loadMore = (startIndex, stopIndex) => {
    this.viewPresenter.loadMoreRecords(startIndex)
  }

  @action
  public loadRowCallback = async (customFieldGroupId: number) => {
    if (this.viewPresenter.checkEditPanelIsDirty()) return

    if (this.viewPresenter.editRecordVM && this.viewPresenter.editRecordVM.id === customFieldGroupId) {
      this.viewPresenter.pageVM.editPanelOpen = false
      return
    }

    await this.viewPresenter.loadRecord(customFieldGroupId)
  }

  public loadSort = (sortParams: ISortParams) => {
    this.viewPresenter.loadSort(sortParams)
  }

  @computed
  public get selectedId(): number {
    if (!this.viewPresenter.editRecordVM) return null
    return this.viewPresenter.editRecordVM.id
  }

  public clearAllFilters = () => {
    // Required by the base class (GenericListPresenter) despite this page not having any filters currently
  }

  @computed
  public get loading(): boolean {
    return this.viewPresenter.listLoading
  }

  @observable
  public tablePresenter = new TablePresenter(this.viewModel, this.columns, this.tableName, this.totalCount)
    .withLoadMoreCallback(this.loadMore)
    .withLoadSortCallback(this.loadSort)
    .withLoadRowCallback(this.loadRowCallback)
    .withVariableWidth()
}
