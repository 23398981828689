import { createContext, ReactNode, useEffect, useMemo } from 'react'
import { keyBy } from 'lodash'
import { useMatch, useParams } from 'react-router-dom'
import { usePrevious } from 'react-use'

import { useNotify } from '@cutover/react-ui'
import { useFilter, useFilters } from 'main/components/shared/filter/filter-provider'
import { useAccount } from 'main/services/api/data-providers/account/account-data'
import { useLanguage } from 'main/services/hooks'
import { FolderListFolder, RunbookListRunbook } from 'main/services/queries/types'
import { useTeams } from 'main/services/queries/use-teams'
import { useSetPermissions } from 'main/services/queries/use-permissions'
import { RunbooksResponseMetaFull } from 'main/services/queries/use-runbooks'
import {
  AccountRunbooksResponseMeta,
  useAccountRunbooks,
  useAccountRunbooksMetaFull
} from 'main/services/queries/use-runbooks-infinite'

type WorkspaceDataType = {
  isLoading?: boolean
  isError?: boolean
  isFetchingNextPage?: boolean
  hasNextPage?: boolean
  fetchNextPage?: () => Promise<any>
  runbooks?: RunbookListRunbook[] | null
  runbookLookup?: Record<number, RunbookListRunbook> | undefined
  runbooksMetaFull?: RunbooksResponseMetaFull | null
  runbooksMeta?: AccountRunbooksResponseMeta | null
  centralTeams?: any[] | null
  centralTeamsMeta?: any | null
  projects?: RunbooksResponseMetaFull['projects'] | undefined
  projectLookup?: Record<number, FolderListFolder> | undefined
}

export const WorkspaceDataContext = createContext<WorkspaceDataType>({
  isLoading: false,
  isError: false,
  isFetchingNextPage: false,
  hasNextPage: false
})

const RUNBOOKS_DISPLAY_TYPE = ['list', 'table', 'timeline', 'dashboard'] as const

export const WorkspaceDataProvider = ({ children }: { children: ReactNode }) => {
  const { t } = useLanguage('runbooks')
  const { filters } = useFilters()
  const notify = useNotify()
  const { accountId: accountSlug } = useParams<{ accountId: string }>()
  const previousAccountSlug = usePrevious(accountSlug)
  const { account } = useAccount()
  const setPermissions = useSetPermissions('runbooks')

  const runbookTypePage = useFilter<'snippet' | 'default' | 'off'>('template_type')
  const previousRunbookTypePage = usePrevious(runbookTypePage)

  const runbooksMatch = useMatch({ path: '/app/:accountSlug/runbooks/:display', end: false })
  const centralTeamsMatch = useMatch('app/:accountSlug/settings/teams')
  const isRunbooksDisplayMatch = RUNBOOKS_DISPLAY_TYPE.includes(
    runbooksMatch?.params?.display as (typeof RUNBOOKS_DISPLAY_TYPE)[number]
  )
  const isCentralTeamsMatch = !!centralTeamsMatch
  const isRunbooksEnabled = !!accountSlug && isRunbooksDisplayMatch
  const isCentralTeamsEnabled = !!accountSlug && isCentralTeamsMatch

  // TODO: the check below does not  not factor in changes in filter params
  // meta should be retrieved when `offset === 0` and that logic has beeen applied to
  // useAccountRunbooks getRunbooks(), which makes need for passing a meta param through app redundant
  const shouldGetFullMeta = previousAccountSlug !== accountSlug || runbookTypePage !== previousRunbookTypePage

  const {
    isFetchingNextPage: isFetchingNextPageRunbooks,
    data: runbooksData,
    hasNextPage: hasNextPageRunbooks,
    fetchNextPage: fetchNextPageRunbooks,
    isLoading: isLoadingRunbooks,
    isError: isErrorRunbooks
  } = useAccountRunbooks({
    accountSlug: accountSlug,
    params: { ...filters, meta: shouldGetFullMeta },
    options: { enabled: isRunbooksEnabled }
  })

  const {
    isFetchingNextPage: isFetchingNextPageTeams,
    data: teamsData,
    hasNextPage: hasNextPageTeams,
    fetchNextPage: fetchNextPageTeams,
    isLoading: isLoadingTeams,
    isError: isErrorTeams
  } = useTeams({
    accountId: account?.id,
    params: filters,
    options: { enabled: !!account && isCentralTeamsEnabled }
  })

  const { data: runbooksMetaFull } = useAccountRunbooksMetaFull(accountSlug)

  setPermissions(runbooksMetaFull ? runbooksMetaFull.permissions : {})

  const runbooks = useMemo(() => runbooksData?.pages.flatMap(page => page.runbooks), [runbooksData?.pages])
  const runbookLookup = useMemo(() => (runbooks ? keyBy(runbooks ?? [], 'id') : undefined), [runbooks])

  const centralTeams = useMemo(() => teamsData?.pages.flatMap(page => page.teams), [teamsData?.pages])

  const projects = runbooksMetaFull?.projects
  const projectLookup = useMemo(() => (projects ? keyBy(projects ?? [], 'id') : undefined), [projects])

  const missingIds = runbooksData?.pages?.[0]?.meta?.missing_ids

  useEffect(() => {
    if (missingIds?.length) {
      notify.warning(missingIds.join(', '), { title: t('subHeader.searchMissingRunbooksNotification') })
    }
  }, [missingIds])

  return (
    <WorkspaceDataContext.Provider
      value={{
        isLoading: isRunbooksDisplayMatch ? isLoadingRunbooks : isCentralTeamsEnabled ? isLoadingTeams : undefined,
        isError: isRunbooksDisplayMatch ? isErrorRunbooks : isCentralTeamsEnabled ? isErrorTeams : undefined,
        isFetchingNextPage: isRunbooksDisplayMatch
          ? isFetchingNextPageRunbooks
          : isCentralTeamsEnabled
          ? isFetchingNextPageTeams
          : undefined,
        hasNextPage: isRunbooksDisplayMatch
          ? hasNextPageRunbooks
          : isCentralTeamsEnabled
          ? hasNextPageTeams
          : undefined,
        fetchNextPage: isRunbooksDisplayMatch
          ? fetchNextPageRunbooks
          : isCentralTeamsEnabled
          ? fetchNextPageTeams
          : undefined,
        runbooks: isRunbooksEnabled ? runbooks : null,
        runbookLookup: isRunbooksEnabled ? runbookLookup : undefined,
        runbooksMetaFull: isRunbooksEnabled && runbooksMetaFull ? runbooksMetaFull : null,
        runbooksMeta: isRunbooksEnabled ? runbooksData?.pages?.[0]?.meta : null,
        projects,
        projectLookup,
        centralTeams: isCentralTeamsEnabled ? centralTeams : null,
        centralTeamsMeta: isCentralTeamsEnabled ? teamsData?.pages?.[0]?.meta : null
      }}
    >
      {children}
    </WorkspaceDataContext.Provider>
  )
}
