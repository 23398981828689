import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const CaretLeft = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="CaretLeft" {...props}>
    <path d="M17.752 26.267a2.453 2.453 0 0 1 0-3.534l8.358-8.043c1.558-1.5 4.153-.395 4.153 1.768v16.084c0 2.163-2.595 3.267-4.153 1.768l-8.358-8.043Z" fill="#000" />
  </StyledIcon>
))

CaretLeft.displayName = 'CaretLeft'

export { CaretLeft }
