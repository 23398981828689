import { useState } from 'react'

import { Runbook } from 'main/components/runbook/right-panels/people-panel/types'
import { ReactAngularConnector } from 'main/connectors/react-angular-connector'
import { AiSuggestionsPanelContent } from 'main/components/runbook/right-panels/ai-suggestions/ai-suggestions-panel'

type AiSuggestionsState = {
  runbook: Runbook | null
}

export const AiSuggestionsPanelConnector = () => {
  const [state, setState] = useState<AiSuggestionsState>({
    runbook: null
  })

  const handleOpenPanel = ({ runbook }: { runbook: Runbook }) => {
    setState({ runbook: runbook })
  }

  const handleClosePanel = () => {
    setState({ runbook: null })
  }

  return (
    <ReactAngularConnector
      mountEvent="open-ai-suggestions-panel"
      unmountEvent="close-ai-suggestions-panel"
      onMount={handleOpenPanel}
      onUnmount={handleClosePanel}
    >
      {state.runbook && <AiSuggestionsPanelContent runbookId={state.runbook.id} />}
    </ReactAngularConnector>
  )
}
