// @ts-nocheck
import { useState, useEffect, Fragment } from 'react'
import { v4 } from 'uuid'
import { observer } from 'mobx-react-lite'
import styled, { css } from 'styled-components'
import { CheckboxPresenter } from './CheckboxPresenter'
import { colors, px2rem } from 'app/Styles/Variables'
import { HelpText, ToolTip, CheckBoxFilterPresenter, IAlignmentPosition } from 'Components/Molecules'
import { ItemTypeComponent } from './ItemTypeComponent'
import { Icon } from 'Components/Atoms/Icon'
import { RadioButtons } from 'Components/Atoms/RadioButtons/RadioButtons'

interface IWrapperProps {
  displayRadioboxes: boolean
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: ${(props: IWrapperProps) => (props.displayRadioboxes ? 'baseline' : 'center')};
  min-height: ${px2rem(18)};
  min-width: ${px2rem(18)};
  line-height: ${px2rem(23)};
  margin-bottom: ${px2rem(4)};
  margin-left: ${px2rem(5)};
  margin-right: ${px2rem(4)};
  margin-top: ${px2rem(1)};
  padding: ${px2rem(2)} ${px2rem(0)};
`

interface ICheckboxWrapperProps {
  alignmentPosition: IAlignmentPosition
}

const CheckboxWrapper = styled.div`
  display: flex;
  cursor: pointer;
  align-items: ${(props: ICheckboxWrapperProps) => (props.alignmentPosition === 'top' ? 'flex-start' : 'center')};
`

const IconsWrapper = styled.div`
  display: flex;
`
const IconInner = styled.span`
  color: ${colors.textLight};
  cursor: pointer;
  &:hover {
    color: ${colors.text};
  }
`

interface IStyledLabelProps {
  value: boolean
  labelColor: string
  disabled: boolean
  alignmentPosition: IAlignmentPosition
}

const StyledLabel = styled.label`
  cursor: ${(props: IStyledLabelProps) => (props.disabled ? 'default' : 'pointer')};
  margin-left: ${px2rem(2)};
  color: ${(props: IStyledLabelProps) =>
    props.labelColor
      ? props.labelColor
      : props.disabled
      ? colors.textDisabled
      : props.value
      ? colors.text
      : colors.textLight};
  ${(props: IStyledLabelProps) =>
    props.alignmentPosition === 'top'
      ? css`
          position: relative;
          margin-top: -${px2rem(3)};
          margin-bottom: -${px2rem(3)};
        `
      : null}
`

const HelpContainer = styled.div`
  i {
    color: ${colors.textLight};
  }
`

interface ICheckboxProps {
  presenter: CheckboxPresenter | CheckBoxFilterPresenter
  labelColor?: string
  includeCheckedAndFocusedStyle?: boolean
  className?: string
}

const CountPillWrapper = styled.div`
  display: flex;
  align-items: center;
`

interface ICountPillProps {
  color: string
}

const CountPill = styled.span`
  background-color: ${(props: ICountPillProps) => props.color};
  border-radius: ${px2rem(12)};
  padding: ${px2rem(0)} ${px2rem(6)};
  border: ${px2rem(1)} solid ${(props: ICountPillProps) => props.color};
  line-height: ${px2rem(17)};
  font-size: ${px2rem(13)};
  display: inline-block;
  font-weight: 400;
  color: ${colors.white};
  opacity: 0.75;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
`

export const Checkbox = observer<ICheckboxProps>(
  ({ presenter, labelColor, includeCheckedAndFocusedStyle, className }) => {
    const {
      onChange,
      value,
      disabled,
      helpText,
      helpTextIcon,
      label,
      itemType,
      showLabel,
      hasHtmlLabel,
      hidden,
      eventIconVisible,
      eventIcon,
      eventIconText,
      eventIconTextPosition,
      eventIconEvent,
      eventIconColor,
      eventIconRotation,
      oneLineTooltip,
      filterValue,
      updateFromRepository,
      inputPlaceHolder,
      alignmentPosition,
      radioButtonsPresenter,
      pageFilter
    } = presenter

    if (hidden) {
      return null
    }

    const [uuidLabel] = useState(`${v4()}-${label?.replace(/\s/g, '')}`)

    useEffect(() => {
      updateFromRepository()
    }, [filterValue])

    const handleChange = () => {
      onChange(!value)
    }

    const handleCustomEvent = () => {
      if (eventIconEvent) {
        eventIconEvent()
      }
    }

    const helpTextRender = helpText ? <HelpText text={helpText} icon={helpTextIcon} /> : null
    let eventIconRender

    if (eventIcon) {
      const icon = (
        <IconInner role="button" aria-label={eventIconText} onClick={handleCustomEvent}>
          <Icon
            name={eventIcon}
            extraStyles={{
              width: '18px'
            }}
            size="18px"
            color={eventIconColor}
            rotation={eventIconRotation}
            rotationSpeed="medium"
          />
        </IconInner>
      )
      eventIconRender = eventIconText ? (
        <ToolTip
          message={eventIconText}
          trigger={icon}
          position={eventIconTextPosition}
          oneLineTooltip={oneLineTooltip}
        />
      ) : (
        icon
      )
    }

    const labelRender = hasHtmlLabel
      ? label.split('\n').map((item, index) => (
          <Fragment key={index}>
            {item}
            <br />
          </Fragment>
        ))
      : label

    const displayRadioboxes = value && radioButtonsPresenter

    const displayCountAndColorPill = pageFilter && pageFilter.hasOwnProperty('count')

    return (
      !hidden && (
        <Wrapper displayRadioboxes={displayRadioboxes} className={className}>
          <CheckboxWrapper alignmentPosition={alignmentPosition}>
            <ItemTypeComponent
              itemType={itemType}
              onChange={onChange}
              value={value}
              disabled={disabled}
              includeCheckedAndFocusedStyle={includeCheckedAndFocusedStyle}
              id={uuidLabel}
              title={label}
              placeholderText={inputPlaceHolder}
            ></ItemTypeComponent>
            {showLabel && (
              <StyledLabel
                value={value}
                labelColor={labelColor}
                onClick={handleChange}
                htmlFor={uuidLabel}
                disabled={disabled}
                alignmentPosition={alignmentPosition}
              >
                {labelRender}
              </StyledLabel>
            )}
          </CheckboxWrapper>
          {displayRadioboxes && (
            <RadioButtons
              radioEntryExtraStyles={{ margin: `${px2rem(0)} ${px2rem(24)} ${px2rem(6)} ${px2rem(6)}` }}
              presenter={radioButtonsPresenter}
            />
          )}
          <IconsWrapper>
            {eventIconVisible ? eventIconRender : ''}
            <HelpContainer>{helpTextRender}</HelpContainer>
          </IconsWrapper>
          {displayCountAndColorPill && (
            <CountPillWrapper>
              <CountPill color={presenter.pageFilter.color}>{presenter.pageFilter.count}</CountPill>
            </CountPillWrapper>
          )}
        </Wrapper>
      )
    )
  }
)
