import { useEffect, useState } from 'react'
import { eventManager } from 'event-manager'
import { flatMap } from 'lodash'
import styled from 'styled-components'

import { InfiniteList, Loader, NoResourceFound, TeamListItem } from '@cutover/react-ui'
import { Nullish } from '@cutover/utility-types'
import { SelectedTeam } from '../types'
import { RunbookTeam, useRunbookTeamsQuery } from '../use-runbook-teams'
import { LoadingPanel } from 'Shared/Components/Templates/LoadingPanel/LoadingPanel'

export type TeamListProps = {
  runbookId: number
  runbookVersionId: number
  search: Nullish<string>
  clearSearch: () => void
  setTeamsCount: (count: number) => void
  setUsersCount: (count: number) => void
  selectedTeam: SelectedTeam | undefined
  onTeamSelect: (item: SelectedTeam) => void
  templateType?: string
}

// TODO: update react-ui Loader to optionally show animated loader (as well as optional message)
// and use flexbox or margin instead of LoadingPanelStyled to position loader
const LoadingPanelStyled = styled(LoadingPanel)`
  top: 2px;
`

export function TeamList({
  runbookId,
  runbookVersionId,
  search,
  clearSearch,
  setTeamsCount,
  setUsersCount,
  selectedTeam,
  onTeamSelect
}: TeamListProps) {
  const [isRefetching, setIsRefetching] = useState<boolean>(false)
  const { isFetching, isFetchingNextPage, data, hasNextPage, fetchNextPage, refetch } = useRunbookTeamsQuery(
    {
      runbookId,
      runbookVersionId,
      offset: 0,
      limit: 20,
      search
    },
    { useErrorBoundary: true }
  )

  const refetchTeamList = async () => {
    setIsRefetching(true)
    await refetch()
    setIsRefetching(false)
  }

  useEffect(() => {
    eventManager.on('runbook-teams-users-added', refetchTeamList)
    eventManager.on('update-runbook-page-on-snippet-added', refetchTeamList)

    return () => {
      eventManager.off('runbook-teams-users-added', refetchTeamList)
      eventManager.off('update-runbook-page-on-snippet-added', refetchTeamList)
    }
  }, [])

  useEffect(() => {
    if (data) {
      const meta = data.pages[0]?.meta
      setUsersCount(meta?.filteredRunbookUsersCount)
      setTeamsCount(meta?.filteredRunbookTeamsCount)
    }
  }, [data])

  // Show spinner for the first time load
  if (isFetching && !isFetchingNextPage && !isRefetching) {
    return <LoadingPanelStyled />
  }

  if (!data) {
    return <Loader />
  }

  const items = flatMap(data.pages, r => r.runbookTeams)

  if (items.length === 0) {
    return <NoResourceFound context="team" clearAllFilters={clearSearch} />
  }

  return (
    <InfiniteList<RunbookTeam>
      items={items}
      initialIndex={selectedTeam?.index}
      hasNextPage={hasNextPage}
      fetchNextPage={fetchNextPage}
      isFetchingNextPage={isFetchingNextPage}
      renderItem={(index, team) => (
        <TeamListItem
          id={team.id}
          size="small"
          usersCount={team.userIds.length}
          name={team.name}
          linked={team.linked}
          color={team.color}
          onClick={() => onTeamSelect({ index, ...team })}
          data-testid="team-item"
        />
      )}
    />
  )
}
