// @ts-nocheck
import { observable } from 'mobx'
import { TAnchorTagDisplayStyle } from './TAnchorTagDisplayStyle'
import { TAnchorTagTargetAttributes } from './TAnchorTagTargetAttributes'
import { TIcons } from 'Components/Atoms'

export class AnchorTagPresenter {
  @observable
  public onClick: () => void

  @observable
  public href: string

  @observable
  public text?: string

  @observable
  public iconName?: TIcons

  @observable
  public displayStyle?: TAnchorTagDisplayStyle = 'anchor'

  @observable
  public target?: TAnchorTagTargetAttributes = '_blank'

  @observable
  public preventDefault?: boolean

  @observable
  public stopPropagation?: boolean

  @observable
  public customFunction?: () => void

  constructor(href: string) {
    this.href = href
  }

  public withDisplayStyle?(displayStyle: TAnchorTagDisplayStyle) {
    this.displayStyle = displayStyle
    return this
  }

  public withIconName?(iconName: TIcons) {
    this.iconName = iconName
    return this
  }

  public withText?(text: string) {
    this.text = text
    return this
  }

  public withTarget?(target: TAnchorTagTargetAttributes) {
    this.target = target
    return this
  }

  public withCustomFunction? = (customFunction: () => void) => {
    this.customFunction = customFunction
    return this
  }

  public withPreventDefault? = (preventDefualt: boolean) => {
    this.preventDefault = preventDefualt
    return this
  }

  public withStopPropagation = (stopPropagation: boolean) => {
    this.stopPropagation = stopPropagation
    return this
  }
}
