// @ts-nocheck
import { Fragment, FocusEventHandler } from 'react'
import styled from 'styled-components'
import { TDisplaySvgIcons } from 'Shared/Components/Atoms/DisplayIcon'
import { IconButton } from '../IconButton'

export interface IconSelectProps {
  iconIds?: TDisplaySvgIcons[]
  value?: string | null | undefined
  onSelect: (id: string) => void
  defaultColor: string
  selectedColor: string
  onFocus?: FocusEventHandler<HTMLDivElement>
  onBlur?: FocusEventHandler<HTMLDivElement>
}

const IconSelectWrapper = styled.div({
  display: 'flex',
  flexWrap: 'wrap'
})

export function IconSelect({
  iconIds = [],
  value,
  onSelect,
  onFocus,
  onBlur,
  defaultColor,
  selectedColor
}: IconSelectProps) {
  return (
    <IconSelectWrapper onFocus={onFocus} onBlur={onBlur}>
      {iconIds.map(id => (
        <Fragment key={id}>
          <IconButton
            id={id}
            color={id === value ? selectedColor : defaultColor}
            selected={id === value}
            onClick={e => {
              e.preventDefault()
              onSelect(id)
            }}
            type={id === value ? 'normal' : 'invert'}
          />
        </Fragment>
      ))}
    </IconSelectWrapper>
  )
}
