import { Dispatch, SetStateAction } from 'react'
import * as yup from 'yup'

import { Box, useNotify } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { queryClient } from 'main/query-client'
import { ApiError } from 'main/services/api'
import { SystemParameterPayload, useSystemParametersCreate } from 'main/services/queries/use-system-parameters-query'
import { QueryKeys } from 'main/services/queries/query-keys'
import { FormModal, TextInputField } from 'main/components/shared/form'

type CreateSystemParameterModalType = {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

export type CreateParameterModalForm = {
  name: string
  value: string
}

export const CreateNewSystemParametersModal = ({ open, setOpen }: CreateSystemParameterModalType) => {
  const { t } = useLanguage('systemParameters')
  const notify = useNotify()
  const createMutation = useSystemParametersCreate()

  const handleSubmit = async (payload: SystemParameterPayload) => {
    createMutation.mutate(
      { payload },
      {
        onSuccess: () => {
          notify.success(t('modals.createSystemParameter.successMessage'))
          setOpen(false)

          queryClient.invalidateQueries([QueryKeys.SystemParameters])
        },
        onError: error => {
          const castedError = error as ApiError
          notify.error(castedError?.errors?.[0])
          setOpen(false)
        }
      }
    )
  }

  return (
    <FormModal<CreateParameterModalForm>
      schema={yup.object({
        name: yup.string().required().max(25),
        value: yup.string().required()
      })}
      title={t('modals.createSystemParameter.title')}
      confirmText={t('modals.createSystemParameter.confirmationText')}
      confirmIcon={'add'}
      open={open}
      onClose={() => setOpen(false)}
      onSubmit={handleSubmit}
      defaultValues={{
        name: '',
        value: ''
      }}
    >
      <Box>
        <TextInputField name="name" label={t('table.columnHeaders.name')} />
        <TextInputField name="value" label={t('table.columnHeaders.value')} />
      </Box>
    </FormModal>
  )
}
