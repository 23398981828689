// @ts-nocheck
import 'reflect-metadata'
import { injectable, inject } from 'inversify'
import { FiltersRepository } from 'app/Filters/FiltersRepository'

@injectable()
export class QueryStringBuilder {
  @inject(FiltersRepository)
  private filtersRepository: FiltersRepository

  public queries: string[] = []

  private sortParamsMapping: { [key: string]: string }[] = [
    { key: 'acceptEmail', value: 'pref_email' },
    { key: 'acceptSMS', value: 'pref_sms' },
    { key: 'accountName', value: 'account_name' },
    { key: 'archived', value: 'archived' },
    { key: 'color', value: 'color' },
    { key: 'default', value: 'default' },
    { key: 'displaysOn', value: 'apply_to' },
    { key: 'dynamic', value: 'dynamic' },
    { key: 'email', value: 'email' },
    { key: 'firstName', value: 'first_name' },
    { key: 'inAccount', value: 'in_account' },
    { key: 'incident', value: 'incident' },
    { key: 'invitationAcceptedAt', value: 'invitation_accepted_at' },
    { key: 'invitationSentAt', value: 'invitation_sent_at' },
    { key: 'jobTitle', value: 'job_title' },
    { key: 'key', value: 'key' },
    { key: 'label', value: 'label' },
    { key: 'lastActive', value: 'last_active' },
    { key: 'lastLogin', value: 'last_login' },
    { key: 'lastName', value: 'last_name' },
    { key: 'lockedAt', value: 'locked_at' },
    { key: 'loginCount', value: 'sign_in_count' },
    { key: 'mobileNumberNormalized', value: 'mobile_number_normalized' },
    { key: 'name', value: 'name' },
    { key: 'nickName', value: 'handle' },
    { key: 'numberOfCustomFields', value: 'custom_field_count' },
    { key: 'passwordExpiresAt', value: 'password_expires_at' },
    { key: 'registered', value: 'registered' },
    { key: 'runbookAdmin', value: 'runbook_admin' },
    { key: 'runbookTeams', value: 'runbook_team' },
    { key: 'streams', value: 'streams' },
    { key: 'type', value: 'field_type' },
    { key: 'uniqueId', value: 'unique_id' },
    { key: 'usersCount', value: 'users_count' },
    { key: 'eventsCount', value: 'events_count' }
  ]

  public getQueryString = (offSet?: number, sortParams?: { [key: string]: any }, batchloadNumber?: number) => {
    this.queries = []
    this.buildQueryStringForFilters()
    if (offSet !== null) this.buildQueryStringForOffset(offSet, batchloadNumber)
    if (sortParams !== null && sortParams !== undefined && sortParams.accessor !== '') {
      this.queries.push(
        'order=' +
          this.sortParamsMapping.find(item => {
            return item.key === sortParams.accessor
          }).value
      )
      this.queries.push('sort_direction=' + sortParams.sortDirection.toLowerCase())
    }
    if (this.queries.length === 0) {
      return ''
    }
    return '?' + this.queries.join('&')
  }
  private buildQueryStringForFilters(): void {
    const string = this.filtersRepository.apiString
    if (string) this.queries.push(string)
    return
  }

  private buildQueryStringForOffset = (offSet: number, batchLoadNumber?: number) => {
    const loadNumber = batchLoadNumber || 20
    this.queries.push('offset=' + (offSet ? offSet : 0))
    this.queries.push('per_page=' + loadNumber)
  }

  public getCustomQueryString = (params: { [key: string]: any }): string => {
    this.queries = []
    this.buildQueryStringForFilters()
    Object.keys(params)
      .map(key => {
        if (Array.isArray(params[key])) {
          return params[key].map(attr => `${key}[]=${attr}`).join('&')
        } else {
          return `${key}=${params[key]}`
        }
      })
      .forEach(query => this.queries.push(query))
    return this.queries.join('&')
  }
}
