import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const BrandSecurity = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 100 101" a11yTitle="BrandSecurity" {...props}>
    <g clipPath="url(#a)" fill="#fff"><path d="m62.088 33.912 20.857-20.857a50 50 0 0 0-70.557 70.557l20.857-20.857a20.657 20.657 0 0 1 2.146-26.697 20.66 20.66 0 0 1 26.697-2.146Z" /><path opacity=".5" d="M16.605 87.88a49.998 49.998 0 0 0 79.59-18.08 49.999 49.999 0 0 0-8.981-52.529l-20.82 20.82A20.659 20.659 0 0 1 50 71.328a20.66 20.66 0 0 1-12.574-4.267l-20.82 20.82Z" /><circle opacity=".5" cx="50" cy="50.667" r="14.667" /></g>
  </StyledIcon>
))

BrandSecurity.displayName = 'BrandSecurity'

export { BrandSecurity }
