import { SyntheticEvent, useState } from 'react'

import { Box, LoadingPanel, Modal, Text } from '@cutover/react-ui'
import { RunbooksDashboardScheduleItem } from './runbooks-dashboard-schedule-item'
import { SchedulePanelContent } from 'main/components/dashboards/runbook-dashboard-schedule/runbook-dashboard-schedule-panel-styles'
import {
  UpdateUserSettingParams,
  UserSetting,
  UserSettingResponse,
  UserSettingSchedule,
  UserSettingsResponse
} from 'main/components/dashboards/runbook-dashboard-schedule/runbook-dashboard-schedule-types'
import { useLanguage } from 'main/services/hooks'
import { useUpdateEmailScheduleUserSetting } from 'main/services/queries/use-email-schedule-user-setting'

type RunbooksDashboardScheduleListProps = {
  onScheduleClick: (setting: UserSettingResponse, scheduleIndex: number) => void
  data: UserSettingsResponse
  onClose: () => void
  onAdd: () => void
  isLoading: boolean
  updateList: () => void
}

export const RunbooksDashboardScheduleList = ({
  onScheduleClick,
  onClose,
  onAdd,
  data,
  isLoading,
  updateList
}: RunbooksDashboardScheduleListProps) => {
  const [isDeleteScheduleModalOpen, setDeleteScheduleModalOpen] = useState(false)
  const [currentIndex, setCurrentInex] = useState<number | null>(null)
  const setting = data?.user_settings[0]

  const handleDeleteModalClose = () => {
    setDeleteScheduleModalOpen(false)
  }

  const { t } = useLanguage('dashboard')

  const handleOnDeleteClick = (event: SyntheticEvent, index: number) => {
    event.stopPropagation()
    setDeleteScheduleModalOpen(true)
    setCurrentInex(index)
  }

  const updateSchedule = useUpdateEmailScheduleUserSetting()

  const handleDeleteSchedule = () => {
    if (currentIndex !== null) {
      updateSchedule.mutate(buildScheduleEmailForPut(setting, currentIndex), {
        onSuccess: () => {
          handleDeleteModalClose()
          updateList()
        }
      })
    }
  }

  return (
    <>
      {isLoading ? (
        <LoadingPanel />
      ) : (
        <>
          <SchedulePanelContent isDirty={false} onClose={onClose} title={t('shareSchedulePanel.heading')} onAdd={onAdd}>
            {setting && setting.data.schedules.length > 0 ? (
              setting.data.schedules.map(
                (schedule, index) =>
                  schedule.frequency?.option &&
                  schedule.schedule_time &&
                  isValidScheduleForDisplay(schedule) && (
                    <Box pad={{ bottom: '16px' }} key={`${setting.id}-${index}`}>
                      <RunbooksDashboardScheduleItem
                        onClick={() => onScheduleClick(setting, index)}
                        onDeleteClick={(event: SyntheticEvent) => handleOnDeleteClick(event, index)}
                        title={schedule.frequency.name}
                        numberOfRecipients={setting.meta.recipient_count}
                        scheduleType={schedule.frequency.option}
                        scheduleTime={schedule.schedule_time}
                        timezone={setting.data.timezone}
                        days={schedule.day_options}
                        filter={schedule.filter_name}
                      />
                    </Box>
                  )
              )
            ) : (
              <Box pad="12px" css="text-align: center">
                <Text color="text-light">{t('shareSchedulePanel.noSchedules')}</Text>
              </Box>
            )}
          </SchedulePanelContent>
        </>
      )}
      <Modal
        title={t('manageScheduleModal.deleteScheduleTitle')}
        open={isDeleteScheduleModalOpen}
        onClose={handleDeleteModalClose}
        confirmText={t('common:deleteButton')}
        onClickConfirm={handleDeleteSchedule}
        loading={updateSchedule.isLoading}
      >
        <p>{t('manageScheduleModal.deleteScheduleMessage')}</p>
      </Modal>
    </>
  )
}

const buildScheduleEmailForPut = (setting: UserSetting, scheduleIndex: number): UpdateUserSettingParams => {
  const schedules = [...setting.data.schedules]
  schedules.splice(scheduleIndex, 1)

  return {
    ...setting,
    data: {
      ...setting.data,
      schedules,
      template_type: ['off']
    }
  }
}

// filter out completed / expired schedules
// TODO: maybe look into filtering completed schedules out from the back end
const isValidScheduleForDisplay = (schedule: UserSettingSchedule) => {
  return schedule?.frequency?.option === 'once'
    ? !!schedule?.schedule_time && new Date(schedule.schedule_time) > new Date()
    : !!schedule?.ends_at && new Date(schedule.ends_at) > new Date()
}
