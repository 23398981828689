// @ts-nocheck

import { observer } from 'mobx-react-lite'
import { withPresenter } from 'app/Config/withPresenter'
import { UserVerifyPagePresenter } from './UserVerifyPagePresenter'
import { LogoWrapper } from 'Components/Templates/PageWrappers/LogoWrapper' //'Components/Templates/PageWrappers/LogoWrapper'
import { HeadingWrapper } from 'Components/Templates/PageWrappers/HeadingWrapper'
import { FormWrapper } from 'Components/Templates/PageWrappers/FormWrapper'
import { NoSessionPageWrapper } from 'Components/Templates/PageWrappers/NoSessionPageWrapper'
import { Image } from 'Components/Atoms'
import { UserVerifyForm } from './UserVerifyForm/UserVerifyForm'
import { px2rem } from 'app/Styles/Variables'
import { LoadingPage } from 'Components/Templates/PageWrappers/LoadingPage'

interface IUserVerifyPageComponentProps {
  presenter: UserVerifyPagePresenter
}

const UserVerifyPage = observer<IUserVerifyPageComponentProps>(({ presenter }) => {
  const {
    userVerifyFormPresenter,
    displayClientLogoPath,
    heading,
    cutoverLoginLogoPath,
    loading,
    cutoverLogoAltText,
    clientLogoAltText
  } = presenter

  const extraStyles = {
    marginLeft: `${px2rem(30)}`
  } as CSSProperties

  return (
    <>
      {loading ? (
        <LoadingPage />
      ) : (
        <NoSessionPageWrapper>
          <LogoWrapper>
            <Image src={cutoverLoginLogoPath} alt={cutoverLogoAltText} height={72} />
          </LogoWrapper>
          <HeadingWrapper rank={1} fontWeight={300}>
            {heading}
          </HeadingWrapper>
          <FormWrapper>
            <UserVerifyForm presenter={userVerifyFormPresenter} />
          </FormWrapper>
        </NoSessionPageWrapper>
      )}
    </>
  )
})

export default withPresenter(UserVerifyPagePresenter)(UserVerifyPage)
