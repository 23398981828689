import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const TaskTypeTriangle100 = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 -2 48 48" a11yTitle="TaskTypeTriangle100" {...props}>
    <path d="M40.761 33.312c.662 1.146-.165 2.579-1.489 2.579H8.728c-1.324 0-2.15-1.433-1.49-2.58L22.512 6.86a1.719 1.719 0 0 1 2.978 0l15.272 26.452ZM28.953 4.86c-2.201-3.813-7.705-3.813-9.906 0L3.775 31.312c-2.202 3.813.55 8.579 4.953 8.579h30.544c4.403 0 7.155-4.766 4.953-8.58L28.953 4.86Z" fill="#000" />
  </StyledIcon>
))

TaskTypeTriangle100.displayName = 'TaskTypeTriangle100'

export { TaskTypeTriangle100 }
