import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Descendants = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Descendants" {...props}>
    <path d="M31.942 34.58a6 6 0 1 0 2.646-1.413l-2.53-4.747a6 6 0 0 0-5.468-10.253l-2.533-4.747a6 6 0 1 0-8.093.02l-2.498 4.74a6 6 0 1 0 2.633 1.438l2.519-4.778a6.02 6.02 0 0 0 2.793-.007l2.531 4.747A5.984 5.984 0 0 0 22 24c0 1.75.748 3.323 1.942 4.42l-2.531 4.747a6 6 0 1 0 2.646 1.413l2.532-4.747a6.014 6.014 0 0 0 2.822 0l2.531 4.747ZM36 36a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm-16 0a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm5-12a3 3 0 1 1 6 0 3 3 0 0 1-6 0ZM9 24a3 3 0 1 1 6 0 3 3 0 0 1-6 0Z" fill="#000" />
  </StyledIcon>
))

Descendants.displayName = 'Descendants'

export { Descendants }
