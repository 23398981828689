import { ReactNode } from 'react'
import { EdgeSizeType } from 'grommet/utils'

import { Box } from '@cutover/react-ui'
import { useComponentProps } from '../apps-state'
import { AppComponentNodeProps } from '../apps-types'

type EdgeType = EdgeSizeType | 'none' | string

type FlexColumnNodeProps = AppComponentNodeProps & {
  children: ReactNode
  gap: EdgeType
  pad: EdgeType
}

export const FlexColumnNode = ({ appId, resourceId, id, ...props }: FlexColumnNodeProps) => {
  const {
    children,
    gap = 'none',
    pad = 'none'
  } = useComponentProps(appId, resourceId, id, props) as FlexColumnNodeProps

  return (
    <Box direction="column" gap={gap} pad={pad} justify="between" alignContent="center" wrap={true}>
      {children}
    </Box>
  )
}
