import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const HealthCheck = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="HealthCheck" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M14 5h-3v2H6v10.5c0 5.29 3.912 9.666 9 10.394V30c0 6.627 5.373 12 12 12s12-5.373 12-12v-4.256A4.502 4.502 0 0 0 37.5 17a4.5 4.5 0 0 0-1.5 8.744V30a9 9 0 1 1-18 0v-2.106c5.088-.728 9-5.104 9-10.394V7h-5V5h-3v7h3v-2h2v7.5a7.5 7.5 0 0 1-15 0V10h2v2h3V5Zm23.5 18a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z" fill="#000" />
  </StyledIcon>
))

HealthCheck.displayName = 'HealthCheck'

export { HealthCheck }
