import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const CheckboxChecked = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="CheckboxChecked" {...props}>
    <path d="M14 6a8 8 0 0 0-8 8v20a8 8 0 0 0 8 8h20a8 8 0 0 0 8-8V14a8 8 0 0 0-8-8H14Zm.121 17.485 6.364 6.364L35.335 15l2.12 2.121-16.97 16.97L12 25.608l2.121-2.122Z" fill="#000" />
  </StyledIcon>
))

CheckboxChecked.displayName = 'CheckboxChecked'

export { CheckboxChecked }
