import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Info = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Info" {...props}>
    <path d="M22.5 35V19h3v16h-3Zm3-19v-3h-3v3h3Z" fill="#000" /><path d="M24 44c11.046 0 20-8.954 20-20S35.046 4 24 4 4 12.954 4 24s8.954 20 20 20Zm0-3c-9.389 0-17-7.611-17-17S14.611 7 24 7s17 7.611 17 17-7.611 17-17 17Z" fill="#000" />
  </StyledIcon>
))

Info.displayName = 'Info'

export { Info }
