import { FormEvent } from 'react'

import { Box, Checkbox } from '@cutover/react-ui'
import { FilterOptionCheckbox, SelectedFilter } from '../filter-types'
import { useLanguage } from 'main/services/hooks'

export const FILTER_NO_VALUE = 0
export const FILTER_ANY_VALUE = '*'

export const FilterGroupExtraOptions = ({
  onChange,
  selected,
  withNoValueOption,
  withAnyValueOption
}: {
  onChange: (e: FormEvent<HTMLInputElement>, option: FilterOptionCheckbox) => void
  selected?: SelectedFilter
  withNoValueOption?: boolean
  withAnyValueOption?: boolean
}) => {
  const { t } = useLanguage('common', { keyPrefix: 'filter' })

  return (
    <Box>
      {withNoValueOption && (
        <Checkbox
          label={t('noValueSet')}
          value={FILTER_NO_VALUE}
          checked={Array.isArray(selected) ? selected.includes(FILTER_NO_VALUE) : selected === FILTER_NO_VALUE}
          onChange={e => onChange(e, { label: t('noValueSet'), value: FILTER_NO_VALUE })}
        />
      )}
      {withAnyValueOption && (
        <Checkbox
          label={t('anyValueSet')}
          value={FILTER_ANY_VALUE}
          checked={Array.isArray(selected) ? selected.includes(FILTER_ANY_VALUE) : selected === FILTER_ANY_VALUE}
          onChange={e => onChange(e, { label: t('anyValueSet'), value: FILTER_ANY_VALUE })}
        />
      )}
    </Box>
  )
}
