// @ts-nocheck

import styled from 'styled-components'
import { transition, colors, breakpoints, px2rem } from 'app/Styles/Variables'
import { LoadingPanel } from 'Shared/Components/Templates'

const FilterPanelSection = styled.section`
  z-index: 2;
  background-color: ${colors.bg1};
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 0px;
  // to allow tool tip to be visible
  overflow-x: visible;
  ${transition('medium')}

  left: ${(props: { filterPanelOpen: boolean }) => (props.filterPanelOpen ? '0px' : `${px2rem(-320)}`)};
  width: 280px;

  @media ${breakpoints.sm} {
    width: ${(props: { filterPanelOpen: boolean }) => (props.filterPanelOpen ? '100%' : '0px')};
  }

  @media ${breakpoints.md} {
    width: ${(props: { filterPanelOpen: boolean }) => (props.filterPanelOpen ? '100%' : '0px')};
  }
`

interface IFilterPanelWrapperProps {
  filterPanelOpen: boolean
  loading?: boolean
  extraStyles?: CSSProperties
  children: ReactNode
}

export const FilterPanelWrapper = (props: IFilterPanelWrapperProps) => (
  <FilterPanelSection filterPanelOpen={props.filterPanelOpen} style={props.extraStyles}>
    {props.loading && <LoadingPanel overlay={true} />}
    {props.children}
  </FilterPanelSection>
)
