import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const ColumnAddBefore = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="ColumnAddBefore" {...props}>
    <path d="M3 22.5v3h6.5V32h3v-6.5H19v-3h-6.5V16h-3v6.5H3Z" fill="#000" /><path fillRule="evenodd" clipRule="evenodd" d="M18 6v3h4.5v30H18v3h16a8 8 0 0 0 8-8V14a8 8 0 0 0-8-8H18Zm7.5 16.5V9H34a5 5 0 0 1 5 5v8.5H25.5Zm13.5 3V34a5 5 0 0 1-5 5h-8.5V25.5H39Z" fill="#000" />
  </StyledIcon>
))

ColumnAddBefore.displayName = 'ColumnAddBefore'

export { ColumnAddBefore }
