import { memo } from 'react'

import { Icon } from '../../icon'
import { ListItem } from '../../list'
import { Box } from '../../layout'

// Add the more common/standard select item customizations here e.g., levels, groupings
// WARNING: DO NOT use this to add props here to create select items that deviate from the standard select
// list item UI. The select component allows for a separate custom render function for those cases and
// they should also be considered carefully.

type SelectListItemProps = {
  label: string
  selected?: boolean
  highlighted?: boolean
  level?: number
  disabled?: boolean
  suffix?: React.ReactNode
  prefix?: React.ReactNode
}

export const SelectListItem = memo(
  ({ label, selected, highlighted, level, disabled, suffix, prefix }: SelectListItemProps) => {
    const endComponents = selected
      ? [
          ...(suffix ? [suffix] : []),
          <Box pad={{ right: '4px' }}>
            <Icon icon="check-solid" size="small" color="primary" />
          </Box>
        ]
      : suffix
      ? [suffix]
      : undefined

    return (
      <ListItem
        size="small"
        startComponent={prefix}
        endComponents={endComponents}
        level={level}
        css="width: 100%"
        disabled={disabled}
        active={highlighted}
        selected={selected}
        title={label}
      />
    )
  }
)
