// @ts-nocheck
import { observable, action, computed } from 'mobx'
import { container } from 'app/Config/IOC'
import { ITableColumnTypes } from './ITableColumnTypes'
import { TTableSortDirection } from './TTableSortDirection'
import { ConfigsRepository } from 'Configs/ConfigsRepository'
import { TotalDataItemCounter, ISortParams } from 'Components/Organisms'
import { IDataItem } from './IDataItem'

export class TablePresenter {
  @observable
  public data: object[]

  @observable
  public configsRepository = container.get(ConfigsRepository)

  @computed
  public get oauthUniqueId() {
    return this.configsRepository.oauthUniqueId
  }

  @observable
  public batchLoadNumber: number = 20

  @observable
  public columns: ITableColumnTypes[]

  @observable
  public tableName: string

  @observable
  public sortDirection: TTableSortDirection = 'asc'

  @observable
  public isSorted: boolean = false

  @observable
  public sortedHeaders?: object = {}

  @observable
  public sortedHeader: string = ''

  @observable
  public itemStatusMap: object = {}

  @observable
  public itemLoading: number = 1

  @observable
  public itemLoaded: number = 2

  @observable
  public isPaginated: boolean = false

  @observable
  public loadMoreDataFunction: (startIndex: number, stopIndex: number) => void = () => {}

  @observable
  public setListRefFunction: (el) => void

  @observable
  private totalDataItemCounter: TotalDataItemCounter

  @observable
  public customRowFunction?: (rowId: number) => void

  @observable
  public customSortFunction?: (sortParams: ISortParams) => void

  @observable
  public customFunction?

  @observable
  public customLoadMoreItemsFunction?

  @observable
  public loadSort: (sortParams: ISortParams) => void

  @observable
  public variableWidth = false

  constructor(data: object[], columns: ITableColumnTypes[], tableName: string, totalRowCount: number) {
    this.columns = columns
    this.tableName = tableName
    this.totalDataItemCounter = new TotalDataItemCounter()
    this.initNewData(data, totalRowCount)
  }

  @action
  public initNewData(data: object[], totalRowCount: number) {
    this.itemStatusMap = {}
    this.data = data
    this.totalDataItemCounter.update(data.length, totalRowCount)
    for (let i = 0; i <= this.data.length; i++) {
      this.itemStatusMap[i] = this.itemLoaded
    }
  }

  @computed
  public get totalDataItems() {
    return this.totalDataItemCounter.count
  }

  public isItemLoaded = (index: number) => index <= this.data.length - 1

  @action
  public sortHandler = (dataItem: IDataItem, retainSortDirection = false, reloadList: boolean = true) => {
    let oldSortedHeader = this.sortedHeader
    if (oldSortedHeader === dataItem.accessor && !retainSortDirection) {
      this.sortDirection = this.sortDirection === 'asc' ? (this.sortDirection = 'desc') : (this.sortDirection = 'asc')
    } else if (retainSortDirection) {
      this.sortedHeader = dataItem.accessor
    } else {
      this.sortDirection = 'asc'
      this.sortedHeader = dataItem.accessor
    }

    if (reloadList) {
      this.loadSort({
        accessor: dataItem.accessor,
        dataType: dataItem.dataType === 'hyperlink' ? dataItem.hyperlinkOptions.dataType : dataItem.dataType,
        sortDirection: this.sortDirection
      })
    }

    this.isSorted = true
    this.sortedHeaders = {} //reset sortedHeaders hack
    this.sortedHeaders[dataItem.accessor] = this.sortDirection
  }

  public loadMoreItems = async (startIndex: number, stopIndex: number) => {
    await this.loadMoreDataFunction(startIndex, stopIndex)
  }

  @action
  public withLoadMoreCallback = (loadMoreDataFunction: (startIndex: number, stopIndex: number) => void) => {
    this.loadMoreDataFunction = loadMoreDataFunction
    return this
  }

  @action
  public withLoadSortCallback = (loadSort: (sortParams: ISortParams) => void) => {
    this.loadSort = loadSort
    return this
  }

  @action
  public withLoadRowCallback? = (customRowFunction: (rowId: number) => void) => {
    this.customRowFunction = customRowFunction
    return this
  }

  @action
  public withVariableWidth = () => {
    this.variableWidth = true
    return this
  }

  @action
  public withBatchLoadNumber = (batchLoadNumber: number) => {
    this.batchLoadNumber = batchLoadNumber
    return this
  }
}
