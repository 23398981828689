import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const TrashO = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="TrashO" {...props}>
    <path d="M21 17h-3v17h3V17Zm6 0h3v17h-3V17Z" fill="#000" /><path d="m13.968 9 3.225-3.989C18.212 3.751 19.877 3 21.654 3h4.692c1.777 0 3.442.75 4.461 2.011L34.032 9H44v3h-5v22a8 8 0 0 1-8 8H17a8 8 0 0 1-8-8V12H4V9h9.968Zm6.2-2.186L18.4 9h11.2l-1.767-2.186c-.34-.42-.895-.67-1.487-.67h-4.692c-.592 0-1.147.25-1.487.67ZM12 12v22a5 5 0 0 0 5 5h14a5 5 0 0 0 5-5V12H12Z" fill="#000" />
  </StyledIcon>
))

TrashO.displayName = 'TrashO'

export { TrashO }
