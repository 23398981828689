import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const AuditLog = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="AuditLog" {...props}>
    <path d="M28 6H6v3h22V6Zm5.5 20v5.379l3.06 3.06-2.12 2.122-3.94-3.94V26h3Z" fill="#000" /><path d="M36.803 21a12.027 12.027 0 0 1 7.081 9.325A12.11 12.11 0 0 1 43.317 36C41.67 40.66 37.225 44 32 44c-6.627 0-12-5.373-12-12 0-5.225 3.34-9.67 8-11.317A11.94 11.94 0 0 1 32 20c1.708 0 3.333.357 4.803 1Zm-8.016 2.59A9.003 9.003 0 0 0 23 32a9 9 0 1 0 13.127-8A8.95 8.95 0 0 0 32 23a8.98 8.98 0 0 0-3.213.59ZM6 14h22v3H6v-3Zm12 8H6v3h12v-3Z" fill="#000" />
  </StyledIcon>
))

AuditLog.displayName = 'AuditLog'

export { AuditLog }
