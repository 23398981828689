import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const TaskTypeHexagonDashed = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="TaskTypeHexagonDashed" {...props}>
    <path d="M39.32 20v8h2v-8h-2Zm1.82-4a6 6 0 0 0-2.82-3.732l-3.96-2.287-1 1.732L37.32 14a4 4 0 0 1 1.722 2h2.096ZM29.895 9.713l1-1.732L27 5.732a6 6 0 0 0-6 0L17.109 7.98l1 1.732L22 7.464a4 4 0 0 1 4 0l3.895 2.249Zm-15.25 1.997-1-1.731-3.966 2.289A6 6 0 0 0 6.861 16h2.096a4 4 0 0 1 1.722-2l3.966-2.29ZM8.68 28v-8h-2v8h2Zm.277 4H6.861a6 6 0 0 0 2.818 3.732l3.895 2.249 1-1.733L10.679 34a4 4 0 0 1-1.722-2Zm9.081 6.248-1 1.733L21 42.268a6 6 0 0 0 6 0l3.965-2.29-1-1.731L26 40.536a4 4 0 0 1-4 0l-3.962-2.288Zm15.392-2.001 1 1.732 3.89-2.247A6 6 0 0 0 41.14 32h-2.097a3.999 3.999 0 0 1-1.722 2l-3.892 2.247Z" fill="#000" />
  </StyledIcon>
))

TaskTypeHexagonDashed.displayName = 'TaskTypeHexagonDashed'

export { TaskTypeHexagonDashed }
