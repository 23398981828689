import { Box, Icon, Text } from '@cutover/react-ui'

export const TaskCommsStatus = ({
  condition,
  statusMessage,
  position,
  type
}: {
  condition: boolean
  statusMessage: string
  position?: 'left'
  type: 'message' | 'recipients'
}) => {
  const iconType = condition ? 'check' : 'alert'

  return (
    <Box
      flex
      direction="row"
      gap="4px"
      css={`
        float: ${position};
        margin-right: 8px;
      `}
      data-testid={`task-comms-status-${type}`}
    >
      <Icon icon={iconType} color="text-light" size="small" />
      <Text color="text-light" size="small">
        {statusMessage}
      </Text>
    </Box>
  )
}
