// @ts-nocheck
import { computed, observable, action } from 'mobx'
import { container } from 'app/Config/IOC'
import { FeatureFlagService } from 'Shared/Helpers/FeatureFlagService'
import { GenericListPresenter } from 'Components/Templates'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'
import { injectable } from 'inversify'
import { IRunbookTypeVM } from 'app/Shared/Entities/Types/RunbookType/IRunbookTypeVM'
import { ISortParams, ITableColumnTypes, TablePresenter } from 'Components/Organisms'
import { RunbookTypesViewPresenter } from 'app/Settings/RunbookTypes/RunbookTypesPage/RunbookTypesViewPresenter'
import { Types } from 'Gateways/Service/Types'

@injectable()
export class RunbookTypeListPresenter implements GenericListPresenter {
  private runbookTypesViewPresenter: RunbookTypesViewPresenter = container.get(RunbookTypesViewPresenter)
  private featureFlagService: FeatureFlagService = container.get(FeatureFlagService)

  private language: ILanguageService = container.get(Types.ILanguageService)

  private _columns: ITableColumnTypes[] = []

  @computed
  public get columns(): ITableColumnTypes[] {
    if (this._columns.length > 0) {
      return this.columns
    }

    this._columns = [
      {
        accessor: 'iconName',
        name: this.language.get('runbookTypes:list:tableHeadings:icon'),
        width: 60,
        dataType: 'svg_icon',
        sortable: false
      },
      {
        accessor: 'name',
        name: this.language.get('runbookTypes:list:tableHeadings:name'),
        width: 180,
        dataType: 'string',
        sortable: true
      },
      {
        accessor: 'accountName',
        name: this.language.get('runbookTypes:list:tableHeadings:accountName'),
        width: 180,
        dataType: 'string',
        sortable: true
      },
      {
        accessor: 'default',
        name: this.language.get('runbookTypes:list:tableHeadings:default'),
        width: 90,
        dataType: 'boolean',
        sortable: true
      }
    ]

    this._columns.push({
      accessor: 'dynamic',
      name: this.language.get('runbookTypes:list:tableHeadings:dynamic'),
      width: 90,
      dataType: 'boolean',
      sortable: true
    })

    this._columns.push({
      accessor: 'incident',
      name: this.language.get('runbookTypes:list:tableHeadings:incident'),
      width: 90,
      dataType: 'boolean',
      sortable: true
    })

    this._columns.push({
      accessor: 'disabled',
      name: this.language.get('runbookTypes:list:tableHeadings:disabled'),
      width: 90,
      dataType: 'boolean',
      sortable: false
    })

    return this._columns
  }

  public tableName: string = 'runbook-types-list'

  public context: string = 'runbook type'

  @computed
  public get viewModel(): IRunbookTypeVM[] {
    return this.runbookTypesViewPresenter.listVM
  }

  public loadSort = (sortParams: ISortParams) => {
    this.runbookTypesViewPresenter.sortParams = sortParams
    this.runbookTypesViewPresenter.loadRunbookTypes()
  }

  @computed
  public get sortParams(): ISortParams {
    return this.runbookTypesViewPresenter.sortParams
  }

  @computed
  public get selectedId(): number {
    return this.runbookTypesViewPresenter.runbookTypeVM ? this.runbookTypesViewPresenter.runbookTypeVM.id : null
  }

  @action
  public loadRowCallback = (runbookTypeId: number) => {
    if (
      this.runbookTypesViewPresenter.runbookTypeVM &&
      this.runbookTypesViewPresenter.runbookTypeVM.id === runbookTypeId
    ) {
      this.runbookTypesViewPresenter.pageVM.editPanelOpen = false
    } else {
      this.runbookTypesViewPresenter.loadRunbookType(runbookTypeId)
    }
  }

  public clearAllFilters = () => {
    this.runbookTypesViewPresenter.clearFilters()
  }

  @computed
  public get totalCount() {
    return this.runbookTypesViewPresenter.runbookTypesFilteredTotal
  }

  @computed
  public get loading() {
    return this.runbookTypesViewPresenter.repositoryLoading
  }

  @computed
  public get reloadList() {
    return this.runbookTypesViewPresenter.reloadList
  }

  @observable
  public tablePresenter = new TablePresenter(
    this.viewModel,
    this.columns,
    this.tableName,
    this.runbookTypesViewPresenter.runbookTypesFilteredTotal
  )
    .withLoadSortCallback(this.loadSort)
    .withLoadRowCallback(this.loadRowCallback)
    .withVariableWidth()

  @computed
  public get filtersCount(): number {
    return this.runbookTypesViewPresenter.filtersCount
  }
}
