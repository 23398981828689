import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const BrandPharma = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 100 100" a11yTitle="BrandPharma" {...props}>
    <path opacity=".5" d="M64.667 0H35.333v29.333h29.334V0Zm0 70.666H35.333V100h29.334V70.666ZM29.333 35.334H0v29.333h29.333V35.333Z" fill="#fff" /><path d="M100 35.334H70.667v29.333H100V35.333Z" fill="#fff" />
  </StyledIcon>
))

BrandPharma.displayName = 'BrandPharma'

export { BrandPharma }
