import { format, parseISO } from 'date-fns'
import { flatMap, sortBy } from 'lodash'

import {
  BarChart,
  LegacyBox as Box,
  colors,
  LegacyFlex as Flex,
  getOrdinalColorScale,
  LegacyText as Text
} from '@cutover/react-ui'
import { Chart } from './chart'
import { NoDataAvailable } from './no-data-available'

export type RunbookTypeCount = {
  name: string
  count: number
}

export type RunbookTypeCountOverTime = {
  date: string
  types: RunbookTypeCount[]
}

export type RunbookCountByTypeOverTimeConnectedProps = {
  onEvent: (payload: any) => void
  values: RunbookTypeCountOverTime[]
}

export function RunbookCountByTypeOverTimeConnected(props: RunbookCountByTypeOverTimeConnectedProps) {
  const counts = props.values

  return (
    <Chart
      titleKey="dashboard:runbookCountByTypeOverTime:title"
      render={() => {
        if (counts.length === 0 || counts.every(c => c.types.length === 0)) {
          return <NoDataAvailable />
        }

        return <RunbookCountByTypeOverTime counts={counts} />
      }}
    />
  )
}

export type RunbookCountByTypeOverTimeProps = {
  counts: RunbookTypeCountOverTime[]
}

export function RunbookCountByTypeOverTime({ counts }: RunbookCountByTypeOverTimeProps) {
  const trimDate = (date: string) => parseISO(date.substring(0, 11))

  const runbookCounts = sortBy(counts, c => trimDate(c.date)).map(c => ({
    category: {
      name: 'Date',
      value: format(trimDate(c.date), 'LLLL')
    },
    values: c.types.map(t => ({
      group: t.name,
      value: t.count
    }))
  }))

  const runbookTypeNames = Array.from(new Set(flatMap(runbookCounts, rc => rc.values).map(v => v.group)))
  const colorScale = getOrdinalColorScale(runbookTypeNames)

  return (
    <Flex flexDirection="column">
      <BarChart
        data={runbookCounts}
        height={300}
        padding={'30px 0 30px 30px'}
        showTotals
        stacked
        colors={colorScale}
        xAxis={{
          id: 'date',
          label: ''
        }}
        yAxis={{
          id: 'count',
          label: 'Count'
        }}
      />
      <Flex justifyContent="center">
        <Legend
          items={runbookTypeNames.map((runbookTypeName, i) => ({
            id: i.toString(),
            label: runbookTypeName,
            color: colorScale({ id: runbookTypeName })
          }))}
        />
      </Flex>
    </Flex>
  )
}

type LegendItem = {
  id: string
  label: string
  color: string
}

function Legend({ items }: { items: LegendItem[] }) {
  return (
    <Flex
      justifyContent="space-around"
      alignItems="center"
      flexWrap="wrap"
      css={`
        column-gap: 30px;
        row-gap: 10px;
      `}
    >
      {items.map(item => (
        <Flex key={item.id} gap="6px" alignItems="center">
          <Box
            css={`
              background-color: ${item.color};
              width: 16px;
              height: 16px;
              border-radius: 50%;
            `}
          />
          <Text color={colors.primaryGreyHover}>{item.label}</Text>
        </Flex>
      ))}
    </Flex>
  )
}
