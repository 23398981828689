import { memo, MouseEvent, useCallback } from 'react'
import styled from 'styled-components'
import { useLocation, useNavigate } from 'react-router-dom'

import {
  AccordionPanel,
  Box,
  IconButton,
  Pill,
  PlainTextButton,
  useDeferTooltipTextTruncation
} from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { RunbookFilterType } from 'main/services/tasks/filtering'
import {
  useAppliedFilters,
  useClearFilterState,
  useCustomFieldState,
  useFilterCount,
  useFilterLabelsState,
  useSetFilterState
} from 'main/recoil/data-access'

type AppliedFilterPillProps = {
  label: string
  tip?: string
  param: keyof RunbookFilterType
  value?: any
  filterKey?: string | number
}

export const GroupsHeader = memo(() => {
  const { t } = useLanguage('common', { keyPrefix: 'filter' })
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const filters = useAppliedFilters()
  const { f: cfFilters = {}, ...restFilters } = filters
  const filterLabels = useFilterLabelsState()
  const countSelected = useFilterCount()

  const onClickClearAll = (e: MouseEvent<any>) => {
    e.stopPropagation() // so doesn't trigger accordion open/close
    navigate({ pathname, search: '' })
  }

  return (
    <AccordionPanel
      disabled={countSelected === 0}
      label={t('filterSelection', { count: countSelected })}
      a11yTitle={t('appliedFilters')}
      aria-labelledby="group"
      suffix={countSelected > 0 && <PlainTextButton onClick={onClickClearAll}>{t('clearAll')}</PlainTextButton>}
    >
      <AppliedFiltersWrap>
        {Object.keys(restFilters).map(filterKey => (
          <Box key={filterKey} pad={{ vertical: 'xxsmall', horizontal: '2px' }}>
            <AppliedFilterPill
              param={filterKey as keyof RunbookFilterType}
              label={filterLabels[filterKey].value}
              tip={filterLabels[filterKey].tip}
              value={filters[filterKey as keyof RunbookFilterType]}
            />
          </Box>
        ))}

        {Object.keys(cfFilters).map(key => (
          <Box key={key} pad={{ vertical: 'xxsmall', horizontal: '2px' }}>
            <AppliedFilterPill
              param="f"
              filterKey={key}
              label={filterLabels['f'][key].value}
              tip={filterLabels['f'][key].tip}
            />
          </Box>
        ))}
      </AppliedFiltersWrap>
    </AccordionPanel>
  )
})

const AppliedFilterPill = ({ label, tip, param, value, filterKey }: AppliedFilterPillProps) => {
  const { t } = useLanguage('common', { keyPrefix: 'filter' })
  const resetFilter = useClearFilterState(param)
  const truncate = useDeferTooltipTextTruncation('tip')
  const [cfState, setCfState] = useCustomFieldState()
  const setValue = useSetFilterState(param)
  const handleClickReverse = useCallback(() => setValue(!value), [value, setValue])
  const isBool = typeof value === 'boolean'

  const handleFilterReset = () => {
    if (param === 'f') {
      const { [filterKey as number]: _, ...newCfState } = cfState as Record<number, string>
      setCfState(newCfState)
    } else {
      resetFilter()
    }
  }

  return (
    <Pill
      label={label}
      tip={tip}
      size="medium"
      truncate={truncate}
      suffix={
        <>
          {isBool && (
            <ReversedIcon
              reversed={value === false}
              icon="swap"
              size="small"
              tipPlacement="top"
              label={t('filterByOpposite')}
              isActive={value === false}
              onClick={handleClickReverse}
              aria-label={t('filterByOppositeLabel', { label })}
            />
          )}
          <IconButton
            tipPlacement="top"
            label="Remove filter"
            data-testid={`${label}-runbook-filter-pill-clear`}
            icon="close"
            size="small"
            onClick={handleFilterReset}
            aria-label={t('removeLabel', { label })}
          />
        </>
      }
    />
  )
}
const AppliedFiltersWrap = styled(Box)`
  flex-direction: row;
  flex-wrap: wrap;
`
const ReversedIcon = styled(IconButton)<{ reversed: boolean }>`
  transform: rotate(${({ reversed }) => (reversed ? 180 : 0)}deg);
  transition: transform 400ms cubic-bezier(0.35, 0, 0.25, 1);
`
