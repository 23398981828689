// @ts-nocheck
import { container } from 'app/Config/IOC'
import { injectable } from 'inversify'
import { computed, observable, action } from 'mobx'
import { Types } from 'Gateways/Service/Types'
import { ILanguageService } from 'Helpers/Language/ILanguageService'
import { ToasterService } from 'Components/Molecules'
import { RoutingState } from 'app/Routing/RoutingState'
import { TMode } from 'Components/Templates'
import { AccountsPageViewPresenter } from 'app/Settings/Accounts/AccountsPage/AccountsPageViewPresenter'
import { AccountFormPresenter } from 'app/Settings/Accounts/AccountsPage/AccountForm/AccountFormPresenter'
import { ModalPresenter } from 'Shared/Components/Atoms'
import { FeatureFlagService } from 'Shared/Helpers/FeatureFlagService'
import { eventManager } from 'event-manager'

@injectable()
export class AccountEditPresenter {
  public accountsPageViewPresenter: AccountsPageViewPresenter = container.get(AccountsPageViewPresenter)
  private toasterService: ToasterService = container.get(ToasterService)
  public routingState: RoutingState = container.get(RoutingState)
  private featureFlagService: FeatureFlagService = container.get(FeatureFlagService)
  private language: ILanguageService = container.get(Types.ILanguageService)

  public title: string = this.language.get('accounts:edit:panelTitle')

  public archiveTooltip: string = this.language.get('accounts:edit:archiveTooltip')

  @observable
  public editFormPresenter: AccountFormPresenter

  @observable
  public editPanelSaving: boolean = false

  @observable
  public archiveModalPresenter = new ModalPresenter()

  @observable
  public confirmModalPresenter: ModalPresenter = new ModalPresenter()

  @observable
  public responseErrors: string[] = []

  @observable
  public triggerScroll = false

  @action
  public setTriggerScroll = (trigger: boolean) => {
    this.triggerScroll = trigger
  }

  @action
  public reset = () => {
    this.accountsPageViewPresenter.pageVM.editPanelDirty = false
    this.editFormPresenter.reset()
    this.editFormPresenter.withRecordViewModel(this.accountsPageViewPresenter.accountVM)
  }

  @observable
  public archiveResponseErrors: string[] = []

  @computed
  public get accountLoaded(): boolean {
    return this.accountsPageViewPresenter.accountVM !== null
  }

  @computed
  public get selectedId(): number {
    return this.accountsPageViewPresenter.accountVM.id
  }

  @computed
  public get mode(): TMode {
    if (this.accountsPageViewPresenter.accountLoading) return 'loading'
    if (this.accountLoaded) {
      return this.accountsPageViewPresenter.pageVM.editPanelDirty ? 'panel-open-confirm' : 'panel-open'
    }
    return 'panel-closed'
  }

  @computed
  public get canArchive(): boolean {
    if (this.accountsPageViewPresenter.accountVM === null) return false

    return this.accountsPageViewPresenter.can('archive')
  }

  @action
  public closeCallback = () => {
    this.accountsPageViewPresenter.pageVM.editPanelOpen = false
  }

  @action
  public archive = async () => {
    const response = await this.accountsPageViewPresenter.archiveAccount()
    if (response.success) {
      if (this.featureFlagService.isEnabled('react_workspace')) {
        eventManager.emit('trigger-react-refetch-workspaces')
      }
      this.accountsPageViewPresenter.pageVM.archiveModalOpenState = false
      this.accountsPageViewPresenter.pageVM.editPanelOpen = false
      this.accountsPageViewPresenter.loadAccounts()
      const title = this.language.get('accounts:edit:archive:toaster:title')
      const notification = this.language.get('accounts:edit:archive:toaster:message')
      this.toasterService.pop(title, notification, 'success')
    } else {
      this.archiveResponseErrors = response.body.errors
    }
  }

  @action
  public save = async (reload?: boolean, closeOnSave: boolean = false) => {
    this.editPanelSaving = true
    const result = this.editFormPresenter.processSubmission()

    if (result.result) {
      const response = await this.accountsPageViewPresenter.saveAccount({
        id: result.recordViewModel.id,
        title: result.recordViewModel.title,
        type: result.recordViewModel.type,
        slug: result.recordViewModel.slug,
        description: result.recordViewModel.description,
        roleTypes: result.recordViewModel.roleTypes
      })

      if (!response.success) {
        if (response.errorMessages) {
          this.editFormPresenter.accountForm.serverErrors.push(...response.errorMessages)
          this.editFormPresenter.errorAlertPresenter.setErrorMessages([...response.errorMessages])
        }
        this.setTriggerScroll(true)
        this.accountsPageViewPresenter.submitted = false
      } else {
        if (this.featureFlagService.isEnabled('react_workspace')) {
          eventManager.emit('trigger-react-refetch-workspaces')
        }
        const title = this.language.get('accounts:edit:toaster:title')
        const notification = this.language.get('accounts:edit:toaster:notification')
        this.toasterService.pop(title, notification, 'success')
        this.reset()
      }
    } else {
      this.setTriggerScroll(true)
      this.accountsPageViewPresenter.submitted = false
    }
    this.editPanelSaving = false
  }

  @computed
  public get archiveConfirmName(): string {
    return this.accountsPageViewPresenter.accountVM ? this.accountsPageViewPresenter.accountVM.name : ''
  }

  @action
  public archiveButtonCallback = () => {
    this.accountsPageViewPresenter.pageVM.archiveModalOpenState = true
  }

  public continueArchiveCallback = async () => {
    await this.archive()
  }

  @action
  public cancelArchiveCallback = () => {
    this.accountsPageViewPresenter.pageVM.archiveModalOpenState = false

    if (this.archiveResponseErrors.length > 0) {
      this.archiveResponseErrors = []
    }
  }

  @action
  public withEditFormPresenter = (editFormPresenter: AccountFormPresenter) => {
    this.editFormPresenter = editFormPresenter
    return this
  }

  @computed
  public get formIsDirty(): boolean {
    if (!this.editFormPresenter) return false

    const title = this.editFormPresenter.titlePresenter.isDirty
    const shortName = this.editFormPresenter.shortNamePresenter.isDirty
    const description = this.editFormPresenter.descriptionPresenter.isDirty
    const accountType = this.editFormPresenter.accountTypePresenter.isDirty
    const roleTypes = this.editFormPresenter.roleTypesPresenter
      ? this.editFormPresenter.roleTypesPresenter.items.some(item => item.presenter.isDirty)
      : false

    return title || shortName || description || accountType || roleTypes
  }

  @action
  public setEditPanelDirty = (panelDirty: boolean) => {
    this.accountsPageViewPresenter.pageVM.editPanelDirty = panelDirty
  }
}
