import { useEffect, useState } from 'react'
import { eventManager } from 'event-manager'
import { Outlet } from 'react-router-dom'
import { isMountingOnlyMatch } from 'single-spa/react-routes'
import styled, { CSSProperties } from 'styled-components'

import { Box, BoxProps, useResponsiveContext } from '@cutover/react-ui'
import { useConnectRefreshState } from './refresh-state-connector'
import { useSidebarNavContext } from 'main/components/layout/sidebar/nav-context'
import { ConfigModel } from 'main/data-access'

export const MAIN_NAV_WIDTH = 280
export const MAIN_HEADER_HEIGHT = 72

type PositionStyles = {
  left: string | number
  right: string | number
  transition?: string
  top: string | number
  bottom: string | number
  height?: string | number
  zIndex?: CSSProperties['zIndex']
}

type ReactAppConnectorProps = {
  mainHeaderHeight?: number
  mainNavWidth?: number
}

export const ReactAppConnector = (props: ReactAppConnectorProps) => {
  const windowSize = useResponsiveContext()
  const isReactWorkspaceEnabled = ConfigModel.useIsFeatureEnabled('react_workspace')
  useConnectRefreshState(isReactWorkspaceEnabled)
  const { isLeftSidebarOpen, leftSidebarSize } = useSidebarNavContext()
  const [isMainNavOpen, setIsMainNavOpen] = useState(isReactWorkspaceEnabled ? isLeftSidebarOpen : true)
  const isLoggedOutView = props.mainNavWidth === 0
  const mainNavWidth = props.mainNavWidth ?? MAIN_NAV_WIDTH
  const mainHeaderHeight = props.mainHeaderHeight ?? MAIN_HEADER_HEIGHT
  const [styles, setStyles] = useState<PositionStyles>({
    left: isLoggedOutView ? 0 : mainNavWidth,
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 'auto'
  })
  const [rightPanelWidth, setRightPanelWidth] = useState(0)
  const [rightSubPanelWidth, setRightSubPanelWidth] = useState(0)
  const [headerHeight, setHeaderHeight] = useState(mainHeaderHeight)
  const [transitionTiming, setTransitionTiming] = useState('')
  const [isHidden, setIsHidden] = useState(false)

  useEffect(() => {
    const isMainNavFull = isReactWorkspaceEnabled
      ? leftSidebarSize === 'full'
      : ['small', 'medium'].includes(windowSize) && isMainNavOpen
    const left = isReactWorkspaceEnabled
      ? leftSidebarSize === 'hidden'
        ? 0
        : leftSidebarSize === 'small'
        ? 64
        : leftSidebarSize === 'default'
        ? mainNavWidth
        : '100%'
      : isLoggedOutView
      ? 0
      : isMainNavFull
      ? '100%'
      : isMainNavOpen
      ? mainNavWidth
      : 64

    setStyles({
      left,
      right: rightPanelWidth + rightSubPanelWidth,
      top: mainHeaderHeight,
      bottom: isHidden ? 'initial' : 0,
      height: isMainNavFull ? '0' : 'auto',
      zIndex: 'auto'
    })
  }, [isMainNavOpen, rightPanelWidth, rightSubPanelWidth, transitionTiming, headerHeight, isHidden, leftSidebarSize])

  const setInitialStyles = () => {
    // when switching from angular to react sometimes the panels persist
    // their open/closed state and therefore don't emit init/destroy events.
    // So on initial mount of this component we check the DOM only, but only for angular layout elements.
    // As soon as we move the outer page to react, this approach won't be used anymore.
    const rightSubPanel = document.querySelector('.nav-project')
    const angularMainNavPanel = document.querySelector('.nav-primary.open')
    const mountingOnlyMatch = isMountingOnlyMatch(window.location.hash)
    if (mountingOnlyMatch) setIsHidden(true)
    if (!!rightSubPanel) setRightSubPanelWidth(rightSubPanel.clientWidth)
    if (!isReactWorkspaceEnabled) {
      setIsMainNavOpen(!!angularMainNavPanel)
    }
  }

  useEffect(() => {
    setInitialStyles()

    const onRightPanelOpen = ({ width }: { width: number }) => {
      setRightPanelWidth(width)
      setTransitionTiming('cubic-bezier(0, 0, 0.25, 1)')
    }
    const onRightPanelClose = () => {
      setRightPanelWidth(0)
      setTransitionTiming('cubic-bezier(0.4, 0, 0.4, 1)')
    }
    const onRightPanelResize = ({ width }: { width: number }) => {
      setRightPanelWidth(width)
    }
    const onRightSubPanelOpen = ({ width }: { width: number }) => {
      setRightSubPanelWidth(width)
    }
    const onRightSubPanelClose = () => {
      setRightSubPanelWidth(0)
    }
    const onHeaderOpened = ({ height }: { height: number }) => {
      setHeaderHeight(height)
    }
    const onHeaderClosed = () => {
      setHeaderHeight(0)
    }
    const onAngularMainNavOpened = () => {
      setIsMainNavOpen(true)
    }
    const onAngularMainNavClosed = () => {
      setIsMainNavOpen(false)
    }
    const onRoutingEvent = ({
      target: {
        location: { hash }
      }
    }: {
      target: { location: { hash: string } }
    }) => {
      setIsHidden(isMountingOnlyMatch(hash))
    }

    eventManager.on('angular-right-panel-opened', onRightPanelOpen)
    eventManager.on('angular-right-panel-closed', onRightPanelClose)
    eventManager.on('angular-right-sub-panel-closed', onRightSubPanelClose)
    eventManager.on('angular-right-sub-panel-opened', onRightSubPanelOpen)
    eventManager.on('angular-right-panel-resize', onRightPanelResize)
    eventManager.on('angular-header-opened', onHeaderOpened)
    eventManager.on('angular-header-closed', onHeaderClosed)
    eventManager.on('angular-main-nav-opened', onAngularMainNavOpened)
    eventManager.on('angular-main-nav-closed', onAngularMainNavClosed)
    //@ts-ignore
    window.addEventListener('single-spa:before-routing-event', onRoutingEvent)

    return () => {
      eventManager.off('angular-right-panel-opened', onRightPanelOpen)
      eventManager.off('angular-right-panel-closed', onRightPanelClose)
      eventManager.off('angular-right-sub-panel-closed', onRightSubPanelClose)
      eventManager.off('angular-right-sub-panel-opened', onRightSubPanelOpen)
      eventManager.off('angular-right-panel-resize', onRightPanelResize)
      eventManager.off('angular-header-opened', onHeaderOpened)
      eventManager.off('angular-header-closed', onHeaderClosed)
      eventManager.off('angular-main-nav-opened', onAngularMainNavOpened)
      eventManager.off('angular-main-nav-closed', onAngularMainNavClosed)
      //@ts-ignore
      window.removeEventListener('single-spa:before-routing-event', onRoutingEvent)
    }
  }, [])

  return (
    <ReactContainer styles={styles}>
      <Outlet />
    </ReactContainer>
  )
}

type ReactContainerProps = BoxProps & { styles: PositionStyles }

const ReactContainer = styled(Box)<ReactContainerProps>`
  position: absolute;
  backface-visibility: hidden;
  transition: ${({ styles }) => styles.transition};
  z-index: ${({ styles }) => styles.zIndex};
  left: ${({ styles }) => (typeof styles.left === 'number' ? styles.left + 'px' : styles.left)};
  right: ${({ styles }) => (typeof styles.right === 'number' ? styles.right + 'px' : styles.right)};
  top: ${({ styles }) => (typeof styles.top === 'number' ? styles.top + 'px' : styles.top)};
  bottom: ${({ styles }) => (typeof styles.bottom === 'number' ? styles.bottom + 'px' : styles.bottom)};
  height: ${({ styles }) => (typeof styles.height === 'number' ? styles.height + 'px' : styles.height)};
  will-change: top, right, bottom, left, width, z-index;
`
