import { memo } from 'react'
import dompurify from 'dompurify'

import { Text, TextProps } from '@cutover/react-ui'

export const SanitizedString = memo(({ input, ...props }: { input: string } & Omit<TextProps, 'ref'>) => {
  const sanitize = (input: string) =>
    dompurify.sanitize(input, {
      ALLOWED_TAGS: [
        'b',
        'strong',
        'br',
        'li',
        'ul',
        'p',
        'ol',
        'i',
        'a',
        'u',
        'table',
        'thead',
        'tbody',
        'td',
        'tr',
        'span'
      ]
    })
  // Note: this is the only place in the codebase in which dangerouslySetInnerHTML is allowed and is strictly sanitized
  return <Text {...props} className="react-sanitized-string" dangerouslySetInnerHTML={{ __html: sanitize(input) }} />
})
