import { Box } from '../layout/box/box'
import { Text } from '../typography/text/text'

export type LoaderProps = {
  message?: string
}

export const Loader = ({ message = 'Loading...' }: LoaderProps) => {
  return (
    <Box justify="center" align="center">
      <Text color="text-light">{message}</Text>
    </Box>
  )
}
