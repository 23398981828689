import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Home = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Home" {...props}>
    <path d="M4 24.799 23.799 5l19.799 19.799-2.264 2.263-2.535-2.535V44h-12V32h-6v12h-12V24.527l-2.536 2.535L4 24.8Zm7.799-3.272V41h6V29h12v12h6V21.527l-12-12-12 12Z" fill="#000" />
  </StyledIcon>
))

Home.displayName = 'Home'

export { Home }
