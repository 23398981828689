// @ts-nocheck
import { observable, computed } from 'mobx'
import { FiltersRepository } from 'app/Filters/FiltersRepository'
import { container } from 'app/Config/IOC'
import { IFilterKey, IGenericFilter, TFilterType, ISelectedFilter } from 'app/Filters/FilterEntities'
import { Router } from 'app/Routing/Router'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'
import { Types } from 'Gateways/Service/Types'
import { formatDate } from 'Shared/Helpers/DateService'

type TFilterTypes = 'single' | 'group'

interface IPillFilter {
  type: TFilterTypes
  group?: string
  key?: IFilterKey
}

export interface IFilterUIObject {
  divKey: string
  filterLevel: number
  group?: string
  filterKey?: IFilterKey
  canBeInverse?: boolean
  content?: string
  type: TFilterType
}

export class PillFilterPresenter {
  @observable
  public filtersRepository: FiltersRepository = container.get(FiltersRepository)

  public language: ILanguageService = container.get(Types.ILanguageService)

  @observable
  public router: Router = container.get(Router)

  @observable
  public groupName: string

  @observable
  public key: IFilterKey

  @observable
  public title: string

  @observable
  public type: TFilterTypes

  constructor({ group, key, type }: IPillFilter) {
    this.type = type
    if (type === 'single') {
      if (!key) {
        throw new Error('A single pill filter must have a key')
      }
      this.key = key
    }
    if (type === 'group') {
      if (!group) {
        throw new Error('A group filter must have a group name')
      }
      this.groupName = group
    }
  }

  @computed
  public get filterPills(): IFilterUIObject[] {
    let selectedFilters: IFilterUIObject[] = []
    if (this.type === 'single') {
      const singleFilter = this.getSingleFilter()
      if (singleFilter) selectedFilters.push(singleFilter)
    }
    if (this.type === 'group') {
      const filters = this.getGroupFilters()
      if (filters.length > 0) selectedFilters = [...filters]
    }
    return selectedFilters
  }

  public getSingleFilter = (): IFilterUIObject => {
    const selectedFilter = this.getSelectedFilter()
    if (selectedFilter) {
      const genericFilter = this.getPageFilter()
      return this.singleFilterToUIObject(genericFilter, selectedFilter)
    }
    return null
  }

  public getGroupFilters = (): IFilterUIObject[] => {
    const selectedFilters = this.filtersRepository.selectedFilters.filter(selectedFilter => {
      return selectedFilter.group === this.groupName
    })
    if (selectedFilters.length > 0) {
      const returnFilters: IFilterUIObject[] = []
      const groupFilters = selectedFilters.filter(selectedFilters => selectedFilters.groupPills)
      const ungroupedFilters = selectedFilters.filter(selectedFilters => !selectedFilters.groupPills)

      if (groupFilters.length > 1) {
        const filter = groupFilters[0]
        returnFilters.push({
          group: filter.group,
          filterLevel: groupFilters.length,
          type: filter.type,
          divKey: `${filter.key.reference}${filter.key.resourceId && filter.key.resourceId}`
        })
      }

      if (groupFilters.length === 1) {
        const genericFilter = this.getPageFilter(selectedFilters[0].key)
        returnFilters.push(this.singleFilterToUIObject(genericFilter, selectedFilters[0]))
      }

      if (ungroupedFilters.length > 0) {
        ungroupedFilters.forEach(filter => {
          const genericFilter = this.getPageFilter(filter.key)
          returnFilters.push(this.singleFilterToUIObject(genericFilter, filter))
        })
      }

      return returnFilters
    }
    return []
  }

  private getPageFilter = (filterToMatch: IFilterKey = undefined): IGenericFilter => {
    return this.filtersRepository.pageFilters.find(pageFilter =>
      this.matchFilter(pageFilter.key, pageFilter.type, filterToMatch)
    )
  }

  private getSelectedFilter = (filterToMatch: IFilterKey = undefined): ISelectedFilter => {
    return this.filtersRepository.selectedFilters.find(selectedFilter =>
      this.matchFilter(selectedFilter.key, selectedFilter.type, filterToMatch)
    )
  }

  private matchFilter = (filterKey: IFilterKey, filterType: TFilterType, filterToMatch: IFilterKey = undefined) => {
    const key = filterToMatch || this.key
    if (filterKey.reference === key.reference) {
      if (filterType !== 'resource' || (filterType === 'resource' && filterKey.resourceId === key.resourceId)) {
        return true
      }
    }
    return false
  }

  private singleFilterToUIObject = (
    filter: IGenericFilter,
    selectedFilter: ISelectedFilter = null
  ): IFilterUIObject => {
    return {
      filterKey: filter.key,
      filterLevel: 1,
      canBeInverse: filter.canBeInverse,
      content: this.getPillContent(filter, selectedFilter),
      type: filter.type,
      divKey: `${filter.key.reference}${filter.key.resourceId && filter.key.resourceId}`
    }
  }

  private getPillContent = (genericFilter: IGenericFilter, selectedFilter: ISelectedFilter) => {
    switch (genericFilter.type) {
      case 'boolean':
        return genericFilter.canBeInverse && selectedFilter.value === 'false'
          ? genericFilter.inverseLabel
          : genericFilter.label
      case 'resource':
        return genericFilter.label
      case 'string':
        return selectedFilter.value
      case 'date':
        return this.pillContentForDateFilter(selectedFilter)
    }
  }

  private pillContentForDateFilter = (selectedFilter: ISelectedFilter) => {
    return formatDate(new Date(Number(selectedFilter.value) * 1000), 'dd MMM HH:mm')
  }

  public withTitle = (title: string): this => {
    this.title = title
    return this
  }

  public closeSingleFilter = (filterKey: IFilterKey) => {
    this.filtersRepository.toggleFilterValue(this.getPageFilter(filterKey))
    this.router.refreshFilteredRoute()
  }

  public closeGroupFilter = (group: string) => {
    const selectedFilters = this.filtersRepository.selectedFilters.filter(selectedFilter => {
      return selectedFilter.group === this.groupName
    })
    if (selectedFilters.length > 0) {
      const groupFilters = selectedFilters.filter(selectedFilters => selectedFilters.groupPills)
      groupFilters.forEach(filter => {
        this.filtersRepository.toggleFilterValue(this.getPageFilter(filter.key))
      })
    }
    this.router.refreshFilteredRoute()
  }

  public reverseFilter = (filterKey: IFilterKey) => {
    const { value } = this.getSelectedFilter(filterKey)
    const genericFilter = this.getPageFilter(filterKey)
    if (value === 'true') this.filtersRepository.toggleFilterValue(genericFilter, 'false')
    if (value === 'false') this.filtersRepository.toggleFilterValue(genericFilter, 'true')
    this.router.refreshFilteredRoute()
  }
}
