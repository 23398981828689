// @ts-nocheck
import { container } from 'app/Config/IOC'
import { injectable } from 'inversify'
import { observable, computed, action, observe } from 'mobx'
import { ISortParams } from 'Components/Organisms'
import { RunbookUsersRepository } from 'app/Repositories/RunbookUser/RunbookUsersRepository'
import { RunbookRepository } from 'app/Repositories/Runbook/RunbookRepository'
import { GenericAdminViewPresenter } from 'Shared/Presenters/GenericAdminViewPresenter'
import { FiltersRepository } from 'app/Filters/FiltersRepository'
import { IGenericFilter } from 'app/Filters/FilterEntities'
import { IRunbookUserListEntry } from 'Shared/Entities/Types/RunbookUser/IRunbookUserListEntry'
import { TDownloadFormats } from 'app/Shared/Entities/Types/TDownloadFormats'

interface ILoadRunbookUsers {
  offSet: number
  sortParams: ISortParams
}

@injectable()
export class RunbookUsersViewPresenter extends GenericAdminViewPresenter {
  public listRepository: RunbookUsersRepository = container.get(RunbookUsersRepository)
  public runbookRepository: RunbookRepository = container.get(RunbookRepository)
  public filtersRepository: FiltersRepository = container.get(FiltersRepository)

  // These properties are not implemented in this class,
  // but required by abstract class
  protected getDefaultVM: null = null
  public addRecordVM: null = null
  public editRecordVM: null = null
  public getProgrammersModelForRecord: null = null
  public inflateEditRecordVM: null = null
  public recordForEditReadOnly: null = null
  public recordRepository: null = null

  protected routeId: string = 'runbookUsers'

  @computed
  public get runbookId(): number {
    return this.routingState.currentState.params.runbookId
  }

  public runbookVersionIdFromParams: 'current_version' | number = this.routingState.currentState.params.runbookVersionId

  @computed
  public get usersDataLoading(): boolean {
    return this.loading || this.listRepository.loading
  }

  @computed
  public get batchLoadNumber(): number {
    return this.listRepository.batchLoadNumber
  }

  @computed
  public get runbookCurrentVersionId(): number {
    return this.runbookRepository.currentVersionId
  }

  @computed
  public get runbookName(): string {
    return this.runbookRepository.name
  }

  @computed
  public get runbookVersionId(): number {
    return this.runbookVersionIdFromParams === 'current_version'
      ? this.runbookCurrentVersionId
      : this.runbookVersionIdFromParams
  }

  @computed
  public get runbookTemplateType(): string {
    return this.runbookRepository.runbookTemplateType
  }

  @observable
  public sortParams: ISortParams

  constructor() {
    super()
    this.sortParams = {
      accessor: 'firstName',
      dataType: 'string',
      sortDirection: 'asc'
    }
  }

  public loadList = async (params: ILoadRunbookUsers = { offSet: 0, sortParams: this.sortParams }) => {
    await this.listRepository.loadData({
      runbookId: this.runbookId,
      runbookVersionId: this.runbookVersionId,
      ...params
    })
  }

  private loadRunbook = async () => {
    await this.runbookRepository.loadData({ runbookId: this.runbookId })
  }

  @observable
  public reloadList = false

  public loadDependencies = async () => {
    await this.loadRunbook()
    this.filtersRepository.setPageFilters(this.filters)
    await this.loadList()
  }

  public refreshList = async () => {
    await this.loadList({ offSet: 0, sortParams: this.sortParams })
  }

  public loadSort = async (sortParams: ISortParams) => {
    this.sortParams = sortParams
    await this.refreshList()
  }

  public loadMoreRunbookUsers = async (offSet: number) => {
    return this.listRepository.loadMore(offSet, this.sortParams)
  }

  public downloadRunbookUsers = async (format: TDownloadFormats) => {
    return this.listRepository.download(format)
  }

  @computed
  public get listVM(): IRunbookUserListEntry[] {
    const retData = this.listRepository.users.map((user: IRunbookUserListEntry) => {
      return {
        id: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        color: user.color,
        mobileNumberNormalized: user.mobileNumberNormalized,
        email: user.email,
        streams: user.streams.join(', '),
        runbookTeams: user.runbookTeams.join(', '),
        runbookAdmin: user.runbookAdmin,
        archived: user.archived,
        inAccount: user.inAccount,
        registered: user.registered,
        acceptEmail: user.acceptEmail,
        acceptSMS: user.acceptSMS
      }
    })
    return retData
  }

  public filters: IGenericFilter[] = [
    {
      key: { reference: 'query' },
      type: 'string'
    }
  ]

  @action
  public setObservers = () => {
    const queryObserverDisposer = observe(this.filtersRepository, 'loadingPath', () => {
      if (
        this.routingState.currentState.routeId === 'runbookUsers' &&
        !this.filtersRepository.loadingPath &&
        this.filtersRepository.pageFilters.length > 0
      ) {
        this.listRepository.loadData()
      }
    })

    this.observers.push(queryObserverDisposer)
  }
}
