import { capitalize } from 'lodash'
import styled from 'styled-components'

import { Text } from '@cutover/react-ui'
import { CurrentUserModel } from 'main/data-access'
import { useLanguage } from 'main/services/hooks'
import { RunbookListRunbook, RunbookTemplateStatus } from 'main/services/queries/types'
import { Bullet } from './bullet'

export const TemplateStatus = ({ runbook }: { runbook: RunbookListRunbook }) => {
  const { template_status, copies_count, reviewers, current_template_version_approval_status } = runbook

  return (
    <TemplateInfo
      templateStatus={template_status}
      copiesCount={copies_count}
      reviewers={reviewers}
      templateApprovalStatus={current_template_version_approval_status}
    />
  )
}

type TemplateInfoProps = {
  templateStatus: RunbookTemplateStatus
  copiesCount: RunbookListRunbook['copies_count']
  reviewers: RunbookListRunbook['reviewers']
  templateApprovalStatus: RunbookListRunbook['current_template_version_approval_status']
}

const TemplateInfo = (props: TemplateInfoProps) => {
  const { t } = useLanguage('runbooks')
  const currentUser = CurrentUserModel.useGet()
  const awaitingReview = !!props.reviewers?.length && props.templateApprovalStatus === 'open'
  const currentUserIsReviewer = !!props.reviewers?.includes(currentUser?.id)

  return (
    <>
      <Bullet />
      <TemplateText {...props} data-testid="template-status">
        {capitalize(props.templateStatus.split('_')[1])}
        {awaitingReview && (
          <>
            {` - ${t('list.awaitingTemplateReview.partialText.awaiting')} `}
            {currentUserIsReviewer && <b>{`${t('list.awaitingTemplateReview.partialText.my')} `}</b>}
            {t('list.awaitingTemplateReview.partialText.review')}
          </>
        )}
      </TemplateText>
      {!!props.copiesCount && (
        <Text size="small">{` (${t('list.listItem.subHeader.copiesCount', { count: props.copiesCount })})`}</Text>
      )}
    </>
  )
}

const TemplateText = styled(Text).attrs(({ templateStatus }: TemplateInfoProps) => ({
  size: 'small',
  color:
    templateStatus === 'template_draft'
      ? 'text-light'
      : templateStatus === 'template_pending'
      ? 'warning'
      : templateStatus === 'template_rejected'
      ? 'error'
      : 'success'
}))``
