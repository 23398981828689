// @ts-nocheck
import { injectable, postConstruct } from 'inversify'
import { observable, computed, action } from 'mobx'
import { container } from 'app/Config/IOC'
import { UserVerifyRepository } from 'app/Repositories/UserVerify/UserVerifyRepository'
import { ConfigsRepository } from 'Configs/ConfigsRepository'
import { IBaseResponse } from 'Gateways/Service/IBaseResponse'
import { IUserToVerifyVM } from 'Shared/Entities/Types/UserVerify/IUserToVerifyVM'
import { IUserToVerifyFormVM } from 'Shared/Entities/Types/UserVerify/IUserToVerifyFormVM'
import { Router } from 'app/Routing/Router'

@injectable()
export class UserVerifyViewPresenter {
  private router: Router = container.get(Router)
  private userVerifyRepository: UserVerifyRepository = container.get(UserVerifyRepository)
  private configsRepository: ConfigsRepository = container.get(ConfigsRepository)

  @observable
  public loading: boolean = false

  @observable
  public userToVerifyVM: IUserToVerifyVM

  @observable
  public viewModel: IUserToVerifyFormVM = {
    firstName: '',
    lastName: '',
    email: '',
    countryCode: '',
    mobileNumber: '',
    password: '',
    passwordConfirmation: '',
    memorableWord: ''
  }

  @postConstruct()
  public init() {
    this.load()
    this.router.registerRouteEnterCallback({ routeId: 'userVerify', callback: this.load })
  }

  public load = async () => {
    this.loading = true
    await this.userVerifyRepository.verifyUserByInviteToken()
    if (this.userVerifyRepository.userToVerify) {
      this.setViewModel()
      this.loading = false
    }
  }

  @action
  public setViewModel = () => {
    this.viewModel = this.userVerifyRepository.userToVerify
  }

  public submit = async (userToVerify): Promise<IBaseResponse> => {
    return await this.userVerifyRepository.submit(userToVerify)
  }

  @computed
  public get displayClientLogoPath(): string {
    return this.configsRepository.loginLogo
  }

  @computed
  public get inviteMessage(): string {
    return this.configsRepository.inviteMessage
  }

  @computed
  public get loginWarningMessage(): string {
    return this.configsRepository.loginWarningMessage
  }

  @computed
  public get userToVerifyProvider(): string {
    return this.userVerifyRepository.userToVerify.provider
  }

  @action
  public updateViewModel = (key: string, value: any) => {
    this.viewModel[key] = value
  }
}
