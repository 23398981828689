import { RunbookVersion } from 'main/services/queries/types'
import { useGetCurrentRunbookVersion, useGetCurrentRunbookVersionCallback } from '../hooks'
import { SingleModelInterfaceBase } from './types/single-model-interface'

export type CurrentRunbookVersionModelType = SingleModelInterfaceBase<RunbookVersion>

export const CurrentRunbookVersionModel: CurrentRunbookVersionModelType = {
  useGet: useGetCurrentRunbookVersion,
  useGetCallback: useGetCurrentRunbookVersionCallback
}
