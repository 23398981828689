import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const RunbookPaused = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="RunbookPaused" {...props}>
    <path d="M34 9H14a5 5 0 0 0-5 5v1H6v-1a8 8 0 0 1 8-8h20a8 8 0 0 1 8 8v20a8 8 0 0 1-8 8H14a8 8 0 0 1-8-8v-1h3v1a5 5 0 0 0 5 5h20a5 5 0 0 0 5-5V14a5 5 0 0 0-5-5Z" fill="#000" /><path d="M12 19H3v3h9v-3Zm-9 7h9v3H3v-3Zm15-10.5h3v17h-3v-17Zm12 0h-3v17h3v-17Z" fill="#000" />
  </StyledIcon>
))

RunbookPaused.displayName = 'RunbookPaused'

export { RunbookPaused }
