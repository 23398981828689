// @ts-nocheck
import { useEffect } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { RoleTypeAddPresenter } from './RoleTypeAddPresenter'
import { ErrorAlert, MultiSelectWithCapabilities, Select, TextInput } from 'Components/Molecules'

import { withInjection } from 'app/Shared/Helpers/InjectionHelpers'

const Wrapper = styled.div`
  overflow-y: auto;
`

const ContextWrapper = styled.div`
  padding-top: 10px;
`

interface IRoleTypesAddComponentProps {
  postSuccessCallback: () => void
  presenter: RoleTypeAddPresenter
  submitted: boolean
}

const RoleTypeAdd = observer<IRoleTypesAddComponentProps>(({ presenter, postSuccessCallback }) => {
  const {
    namePresenter,
    descriptionPresenter,
    roleTypeAddForm,
    isSubmitted,
    errorAlertPresenter,
    contextTypeNamePresenter,
    capabilitiesPresenter,
    showCapabilities,
    submitted,
    submit
  } = presenter

  presenter.withPostSuccessCall(postSuccessCallback)

  useEffect(() => {
    if (submitted) {
      submit()
    }
  }, [submitted])

  return (
    <Wrapper>
      <form>
        {isSubmitted && !roleTypeAddForm.isValid && <ErrorAlert presenter={errorAlertPresenter} />}
        <TextInput presenter={namePresenter} parentIsFocused formTopInputElement />
        <TextInput presenter={descriptionPresenter} />
        <ContextWrapper>
          <Select presenter={contextTypeNamePresenter} />
        </ContextWrapper>
        {showCapabilities && <MultiSelectWithCapabilities presenter={capabilitiesPresenter} />}
      </form>
    </Wrapper>
  )
})

export const RoleTypeAddSection = withInjection({
  presenter: RoleTypeAddPresenter
})(RoleTypeAdd)
