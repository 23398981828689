// @ts-nocheck
import { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { v4 } from 'uuid'
import styled from 'styled-components'
import { FormFieldLabel } from 'Shared/Components/Atoms/Labels/FormFieldLabel'
import { TextInput } from 'Components/Molecules'
import { FieldOptionsPresenter } from './FieldOptionsPresenter'
import { DragDrop } from 'Components/Atoms'
import { colors } from 'app/Styles/Variables'
import { RemoteOptionsPresenter } from './RemoteOptionsPresenter'
import { valueExistsWhenTrimmed } from 'Shared/Helpers/InputCustomRules'

const Wrapper = styled.div`
  position: relative;
`

interface IGenericOptionProps {
  presenter: FieldOptionsPresenter | RemoteOptionsPresenter
}

export const GenericOptions = observer<IGenericOptionProps>(props => {
  const [isFocused, setIsFocused] = useState(false)
  const { presenter } = props
  const { textPresenter, dragDropPresenter, disabled, showOptionLabel, draggables } = presenter

  const addToOptions = (value: string) => {
    const rtn = valueExistsWhenTrimmed(value) ? presenter.withOption(v4(), value) : null
    textPresenter.reset()
    return rtn
  }

  const onFocus = () => {
    setIsFocused(true)
  }

  const onBlur = () => {
    setIsFocused(false)
  }

  const color = isFocused ? colors.primary : colors.textLighter

  return (
    <Wrapper>
      {showOptionLabel && (
        <FormFieldLabel color={color} isShrunk={true} hasPrefix={true}>
          {textPresenter.label}
        </FormFieldLabel>
      )}
      <DragDrop presenter={dragDropPresenter} disabled={disabled} draggables={draggables} />
      {!disabled && (
        <TextInput
          presenter={textPresenter}
          handleEnter={addToOptions}
          hideLabel={showOptionLabel}
          onFocus={onFocus}
          onBlur={onBlur}
          formTopInputElement
        />
      )}
    </Wrapper>
  )
})
