import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Target = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Target" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M29 24a5 5 0 1 1-10 0 5 5 0 0 1 10 0Zm-3 0a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z" fill="#000" /><path fillRule="evenodd" clipRule="evenodd" d="M24 35.5c6.351 0 11.5-5.149 11.5-11.5S30.351 12.5 24 12.5 12.5 17.649 12.5 24 17.649 35.5 24 35.5Zm0-3a8.5 8.5 0 1 0 0-17 8.5 8.5 0 0 0 0 17Z" fill="#000" /><path fillRule="evenodd" clipRule="evenodd" d="M24 42c9.941 0 18-8.059 18-18S33.941 6 24 6 6 14.059 6 24s8.059 18 18 18Zm0-3c-8.284 0-15-6.716-15-15 0-8.284 6.716-15 15-15 8.284 0 15 6.716 15 15 0 8.284-6.716 15-15 15Z" fill="#000" />
  </StyledIcon>
))

Target.displayName = 'Target'

export { Target }
