import { useRecoilCallback, useRecoilValue, useRecoilValueLoadable } from 'recoil'

import {
  accountGlobalTaskTypeDefaultState,
  accountTaskTypeLookup,
  accountTaskTypes,
  getAccountTaskType
} from 'main/recoil/runbook'
import { TaskTypeModelType } from 'main/data-access/models/task-type-model'

export const useTaskType: TaskTypeModelType['useGet'] = (identifier = undefined) => {
  // @ts-ignore return undefined if identifier is undefined -- unique to TaskType due to new tasks based on previous task
  // list task which may not exist
  const taskType = useRecoilValue(getAccountTaskType(identifier))
  const defaultTaskType = useRecoilValue(accountGlobalTaskTypeDefaultState)

  return taskType ?? defaultTaskType
}

export const useTaskTypeCallback: TaskTypeModelType['useGetCallback'] = () => {
  return useRecoilCallback(
    ({ snapshot }) =>
      async (identifier = undefined) => {
        // @ts-ignore return undefined if identifier is undefined -- unique to TaskType due to new tasks based on previous task
        // list task which may not exist
        if (identifier) {
          return await snapshot.getPromise(getAccountTaskType(identifier))
        } else {
          return await snapshot.getPromise(accountGlobalTaskTypeDefaultState)
        }
      },
    []
  )
}

export const useTaskTypes: TaskTypeModelType['useGetAll'] = () => {
  return useRecoilValue(accountTaskTypes)
}

export const useTaskTypesCallback: TaskTypeModelType['useGetAllCallback'] = () => {
  return useRecoilCallback(
    ({ snapshot }) =>
      async () =>
        await snapshot.getPromise(accountTaskTypes),
    []
  )
}

export const useTaskTypeLookup: TaskTypeModelType['useGetLookup'] = () => useRecoilValue(accountTaskTypeLookup)

export const useTaskTypeLookupCallback: TaskTypeModelType['useGetLookupCallback'] = () => {
  return useRecoilCallback(
    ({ snapshot }) =>
      async () =>
        await snapshot.getPromise(accountTaskTypeLookup),
    []
  )
}

export const useTaskTypeLookupLoadable: TaskTypeModelType['useGetLookupLoadable'] = () =>
  useRecoilValueLoadable(accountTaskTypeLookup)
