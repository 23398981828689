import { UserStatus } from 'main/services/queries/types'

export function getUserStatus(user: { archived: boolean; notInAccount: boolean; status: string }): UserStatus {
  if (user.archived) {
    return 'archived'
  }

  if (user.notInAccount) {
    return 'not_in_account'
  }

  if (user.status === 'invited') {
    return 'invited'
  }

  return 'active'
}
