import { useEffect, useMemo, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'

import { Button, IconName, Modal, useNotify } from '@cutover/react-ui'
import {
  mrdSchema,
  ShareRunbookDashboardEmailForm
} from 'main/components/dashboards/runbook-dashboard/share-runbook-dashboard-email-form'
import {
  RunbookDashboardShareEmail,
  RunbookDashboardShareEmailFormType,
  ShareEmailParams
} from 'main/components/dashboards/runbook-dashboard/share-runbook-dashboard-types'
import { useSendRunbookDashboardEmailMutation } from 'main/components/dashboards/runbook-dashboard/use-send-runbook-dashboard-email'
import {
  UpdateUserSettingParams,
  UserSetting
} from 'main/components/dashboards/runbook-dashboard-schedule/runbook-dashboard-schedule-types'
import { useLanguage } from 'main/services/hooks'
import {
  CreateUserSettingParams,
  useCreateEmailScheduleUserSetting,
  useUpdateEmailScheduleUserSetting
} from 'main/services/queries/use-email-schedule-user-setting'
import { SavedView } from 'main/services/queries/use-saved-view-groups'

type ModalData = {
  dashboardId: number
  accountId: number
  currentUserId: number
  filters: ShareEmailParams
  advancedOpen?: boolean
}

type RunbooksDashboardModalProps = {
  setting?: UserSetting
  onClose: () => void
  filterData: { filters: SavedView[] }
  isFilterDataLoading: boolean
  updateList: () => void
} & ModalData

const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

export const RunbooksDashboardModal = ({
  setting,
  onClose,
  filterData,
  isFilterDataLoading,
  updateList,
  ...modalData
}: RunbooksDashboardModalProps) => {
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(Boolean(modalData.advancedOpen))
  const { t } = useLanguage()
  const notify = useNotify()

  const sendEmailMutation = useSendRunbookDashboardEmailMutation()
  const createScheduleMutation = useCreateEmailScheduleUserSetting()
  const updateScheduleMutation = useUpdateEmailScheduleUserSetting()

  const methods = useForm<RunbookDashboardShareEmailFormType>({
    mode: 'all',
    resolver: yupResolver(mrdSchema),
    context: { scheduling: showAdvancedOptions },
    defaultValues: {
      recipients: null,
      message: '',
      name: '',
      dashboardView: null,
      frequencyType: null,
      frequencyDays: null,
      startsAt: null,
      endsAt: null,
      scheduleTime: null,
      timezone: 'default'
    }
  })
  const { formState, getValues, trigger, watch, setValue } = methods
  const { isDirty, errors } = formState
  const frequencyType = watch('frequencyType')

  const handleClose = () => {
    sendEmailMutation.reset()
    setShowAdvancedOptions(false)
    onClose()
  }

  useEffect(() => {
    trigger() // triggers validation when advanced section is toggled
  }, [showAdvancedOptions])

  useEffect(() => {
    // resets the frequencyDays when changing frequency from weekly
    if (frequencyType !== 'weekly') setValue('frequencyDays', null)
    trigger()
  }, [frequencyType])

  // Since there are two submit buttons, this creates a settled handler that will have the proper
  // behavior whether sending a test or real email
  const createSettledHandler =
    ({ isSendingTest, result }: { isSendingTest: boolean; result: 'Success' | 'Error' }) =>
    () => {
      if (isSendingTest) {
        // wait 2 seconds before setting the sendEmailMutation status back to 'idle' so that we can
        // visually indicate the success/failure of sending the email within the modal content.
        setTimeout(sendEmailMutation.reset, 2000)
      } else {
        const notifyType = result === 'Success' ? 'success' : 'error'
        onClose()
        notify[notifyType](t(`dashboard:shareModal:${showAdvancedOptions ? 'schedule' : 'share'}${result}Text`), {
          title: t(`dashboard:shareModal:share${result}Title`)
        })
      }
    }

  // for sending a test we only care about the contents of the message field and we can't can't
  // submit the form in a way that validations are run so this access just the current value
  // of the message field
  const sendTest = async () => {
    if (!modalData) return
    const message = getValues('message')
    const transformed = buildSendShareEmailForPost({ form: { message }, data: modalData, test: true })

    sendEmailMutation.mutate(transformed, {
      onSuccess: createSettledHandler({ isSendingTest: true, result: 'Success' }),
      onError: createSettledHandler({ isSendingTest: true, result: 'Error' })
    })
  }

  const sendEmail = async (form: RunbookDashboardShareEmailFormType) => {
    if (!modalData) return

    const transformed = buildSendShareEmailForPost({ form, data: modalData, test: false })

    sendEmailMutation.mutate(transformed, {
      onSuccess: createSettledHandler({ isSendingTest: false, result: 'Success' }),
      onError: createSettledHandler({ isSendingTest: false, result: 'Error' })
    })
  }

  const scheduleEmail = async (form: RunbookDashboardShareEmailFormType) => {
    if (!modalData) return
    if (!filterData) return

    const onSuccess = () => {
      createSettledHandler({ result: 'Success', isSendingTest: false })()
      updateList()
    }

    const onError = createSettledHandler({ result: 'Error', isSendingTest: false })

    if (setting && setting.id) {
      const data = buildScheduleEmailForPost({ form, data: modalData, filterData, setting }) as UpdateUserSettingParams

      updateScheduleMutation.mutate(data, { onSuccess, onError })
    } else {
      const data = buildScheduleEmailForPost({ form, data: modalData, filterData })

      createScheduleMutation.mutate(data, { onSuccess, onError })
    }
  }

  const [sendingTest, setSendingTest] = useState(false)

  // Only use the sendEmailMutation progress to change the button content when sending a test, otherwise
  // the button will change icons as the modal is closing
  const status = sendEmailMutation.status
  const [testButtonIcon, testButtonText] = useMemo(() => {
    const testStatus: MutationStatus = sendingTest ? status : 'idle'
    return [
      MODAL_TEST_BUTTON_ICON[testStatus],
      t(`dashboard:shareModal:test-${MODAL_TEST_BUTTON_TEXT_KEY[testStatus]}`)
    ]
  }, [status, t])

  useEffect(() => {
    if (status === 'idle') {
      setSendingTest(false)
    }
  }, [status])

  const handleClickSendTest = () => {
    sendTest()
    setSendingTest(true)
  }

  return (
    <Modal
      open
      onClose={handleClose}
      title={showAdvancedOptions ? t('dashboard:shareModal:advancedTitle') : t('dashboard:shareModal:title')}
      confirmText={showAdvancedOptions ? t('dashboard:shareModal:advancedSubmit') : t('dashboard:shareModal:submit')}
      loading={sendEmailMutation.isLoading || createScheduleMutation.isLoading || updateScheduleMutation.isLoading}
      onClickConfirm={
        !isDirty || Object.keys(errors).length > 0
          ? undefined
          : () => methods.handleSubmit(form => (showAdvancedOptions ? scheduleEmail(form) : sendEmail(form)))()
      }
      customButton={
        showAdvancedOptions ? null : (
          <Button tertiary label={testButtonText} icon={testButtonIcon} onClick={handleClickSendTest} />
        )
      }
    >
      {modalData && (
        <ShareRunbookDashboardEmailForm
          methods={methods}
          accountId={modalData.accountId}
          showAdvancedOptions={showAdvancedOptions}
          onAdvancedOptionsClick={() => {
            setShowAdvancedOptions(!showAdvancedOptions)
          }}
          filterData={filterData}
          isFilterDataLoading={isFilterDataLoading}
          context="MRD"
        />
      )}
    </Modal>
  )
}

function buildSendShareEmailForPost({
  form,
  data,
  test
}: {
  form: Partial<RunbookDashboardShareEmailFormType>
  data: ModalData
  test: boolean
}): RunbookDashboardShareEmail & { id: number } {
  return {
    id: data.dashboardId,
    params: { ...data.filters, template_type: ['off'] },
    account_id: data.accountId,
    user_recipients: form.recipients?.users,
    team_recipients: form.recipients?.teams,
    message: form.message || '',
    test,
    timezone: localTimezone
  }
}

function buildScheduleEmailForPost({
  form,
  data,
  filterData,
  setting
}: {
  form: RunbookDashboardShareEmailFormType
  data: ModalData
  filterData: { filters: SavedView[] }
  setting?: UserSetting
}): CreateUserSettingParams | UpdateUserSettingParams {
  const frequencyNameMap: {
    [key: string]: 'Every 15 minutes' | 'Every 30 minutes' | 'Hourly' | 'Once' | 'Daily' | 'Weekly'
  } = {
    every_15_min: 'Every 15 minutes',
    every_30_min: 'Every 30 minutes',
    hourly: 'Hourly',
    once: 'Once',
    daily: 'Daily',
    weekly: 'Weekly'
  }

  let selectedFilter

  if (form.filter) {
    selectedFilter = filterData.filters.filter(filter => form.filter && filter.id === parseInt(form.filter, 10))
  }

  const newSchedule = {
    name: form.name,
    ...(form.frequencyType === 'weekly' && {
      day_options: form.frequencyDays?.map(day => Number(day))
    }),
    message: form.message || '',
    starts_at: form.frequencyType !== 'once' && form.startsAt ? form.startsAt.toISOString() : undefined,
    ends_at: form.frequencyType !== 'once' && form.endsAt ? form.endsAt.toISOString() : undefined,
    schedule_time:
      form.frequencyType === 'every_15_min' || form.frequencyType === 'every_30_min'
        ? form.startsAt?.toISOString()
        : form.scheduleTime
        ? form.scheduleTime.toISOString()
        : new Date().toISOString(),
    user_recipients: form.recipients?.users || [],
    team_recipients: form.recipients?.teams || [],
    ...(form.frequencyType && {
      frequency: {
        option: form.frequencyType,
        name: frequencyNameMap[form.frequencyType]
      }
    }),
    ...(selectedFilter && {
      filter_name: selectedFilter[0].name,
      filter_id: selectedFilter[0].id,
      filter_query_string: selectedFilter[0].query_string
    })
  }

  const schedules = setting ? [...setting.data.schedules, newSchedule] : [newSchedule]

  const settingParams: CreateUserSettingParams | UpdateUserSettingParams = {
    resource_id: data.dashboardId,
    resource_type: 'Dashboard',
    type: 'EmailScheduleUserSetting',
    user_id: data.currentUserId,
    data: {
      account_id: data.accountId,
      enabled: true,
      timezone: form.timezone === 'default' ? localTimezone : form.timezone,
      schedules,
      template_type: ['off']
    }
  }

  if (setting) {
    ;(settingParams as UpdateUserSettingParams).id = setting.id
  }

  return settingParams as CreateUserSettingParams | UpdateUserSettingParams
}

type MutationStatus = ReturnType<typeof useMutation>['status']

const MODAL_TEST_BUTTON_ICON: Record<MutationStatus, IconName> = {
  idle: 'add',
  error: 'report-problem',
  loading: 'spinner',
  success: 'check'
}

const MODAL_TEST_BUTTON_TEXT_KEY: Record<MutationStatus, string> = {
  idle: 'default',
  loading: 'sending',
  success: 'sent',
  error: 'error'
}
