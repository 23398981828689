import { useCallback } from 'react'

import {
  PaintBase,
  PaintCog,
  PaintDecision,
  PaintHexagon,
  PaintNodeEntryPoint,
  PaintRunbook,
  PaintTriangle
} from '../node-map-types'
import {
  paintBox,
  paintCircle,
  paintCog,
  paintComms,
  paintDecision,
  paintDiamond,
  paintHexagon,
  paintRunbook,
  paintSquare,
  paintTriangle
} from './'

export const useNodeMapPaintEntryPoint = () => {
  return useCallback(({ ctx, node, related, state, filterMode, isFiltered }: PaintNodeEntryPoint) => {
    const selected = state.selectedObject?.id
    const opacity = getOpacity({ node, selected, state, related, filterMode, isFiltered })
    const lineWidth = 2 * state.units.baseLineWidth
    const isSolid = node.stage === 'complete' ? true : false
    let mainColor =
      node.completionType === 'complete_skipped' || node.completionType === 'complete_abandoned'
        ? `rgb(${state.display.edgeDefaultColor})`
        : node.color
    const isActive = state.hoverObject?.id === node.id
    const isSelected = selected === node.id || state.activeObject?.id === node.id

    drawTextLabel({ ctx, node, state })
    drawFixedStartIndicator({ ctx, node, state, mainColor, opacity, lineWidth })

    const mainColorLight = mainColor.replace(')', `, ${opacity / 3})`).replace('rgb', 'rgba')
    const criticalColor = `rgba(255,173,153,${opacity})`
    mainColor = opacity < 1 ? mainColor.replace(')', `,${opacity})`).replace('rgb', 'rgba') : mainColor
    const fill = isSolid ? mainColor : 'rgb(255,255,255)'

    ctx.strokeStyle = mainColor
    ctx.fillStyle = fill
    ctx.lineWidth = lineWidth

    // When zoomed out, can render simpler shapes to improve clarity & performance
    const simplifyAt = 0.4
    if (state.transform.scale < simplifyAt) {
      const { x, y } = node
      ctx.fillStyle = mainColor
      const baseSize = state.units.circle * node.scale * node.scaleMod * (1.3 + simplifyAt - state.transform.scale)
      ctx.beginPath()
      ctx.arc(x, y, baseSize, 0, 2 * Math.PI, false)
      ctx.fill()
    } else {
      drawShape({
        ctx,
        node,
        state,
        mainColor,
        mainColorLight,
        criticalColor,
        isSelected,
        isActive,
        lineWidth,
        fill
      })
      drawStageIndicators({ ctx, node, state, mainColor })
      drawPredSuccCount({ ctx, node, state, mainColor })
    }
  }, [])
}

/* ---------------------------------- Utils --------------------------------- */

const drawShape = ({
  ctx,
  node,
  state,
  mainColor,
  mainColorLight,
  criticalColor,
  isSelected,
  isActive,
  lineWidth,
  fill
}: Pick<PaintNodeEntryPoint, 'ctx' | 'node' | 'state'> & {
  mainColor: string
  mainColorLight: string
  criticalColor: string
  isSelected: boolean
  isActive: boolean
  lineWidth: number
  fill: string
}) => {
  const { x, y } = node
  let baseSize, baseSize2, baseSize3, paintFunction
  let props: Partial<PaintBase> = { ctx, x, y, size: 0, lineWidth, stroke: mainColor, fill }

  switch (node.shape) {
    case 'icon_diamond':
      baseSize = state.units.diamond * node.scale * node.scaleMod
      paintFunction = paintDiamond
      props.size = baseSize
      break
    case 'icon_square':
      baseSize = state.units.square * node.scale * node.scaleMod
      paintFunction = paintSquare
      props.size = baseSize
      break
    case 'icon_box':
      baseSize = state.units.square * node.scale * node.scaleMod
      paintFunction = paintBox
      props.size = baseSize
      break
    case 'icon_triangle':
      baseSize = state.units.triangleBase * node.scale * node.scaleMod
      baseSize2 = state.units.triangleMid * node.scale * node.scaleMod
      baseSize3 = state.units.triangleExt * node.scale * node.scaleMod
      paintFunction = paintTriangle
      props = { ...props, size: baseSize, size2: baseSize2, size3: baseSize3 } as PaintTriangle
      break
    case 'icon_hexagon':
      baseSize = state.units.hexagonEdge * node.scale * node.scaleMod
      baseSize2 = state.units.hexagonAlt * node.scale * node.scaleMod
      paintFunction = paintHexagon
      props = { ...props, size: baseSize, size2: baseSize2 } as PaintHexagon
      break
    case 'icon_comms':
      baseSize = state.units.circle * node.scale * node.scaleMod
      paintFunction = paintComms
      props.size = baseSize
      break
    case 'icon_cog':
      baseSize = state.units.cogInnerRadius * node.scale * node.scaleMod
      baseSize2 = state.units.cogOuterRadius * node.scale * node.scaleMod
      baseSize3 = state.units.cogAngle
      paintFunction = paintCog
      props = {
        ...props,
        innerRadius: baseSize,
        outerRadius: baseSize2,
        cogAngle: baseSize3,
        state
      } as PaintCog
      break
    case 'icon_runbook':
      baseSize = state.units.square * node.scale * node.scaleMod
      paintFunction = paintRunbook
      props = { ...props, size: baseSize, dashed: node.hasLinkedTemplate } as PaintRunbook
      break
    case 'icon_decision':
      baseSize = state.units.triangleBase * node.scale * node.scaleMod
      baseSize2 = state.units.triangleMid * node.scale * node.scaleMod
      baseSize3 = state.units.triangleExt * node.scale * node.scaleMod
      paintFunction = paintDecision
      props = { ...props, size: baseSize, size2: baseSize2, size3: baseSize3 } as PaintDecision
      break
    default:
      baseSize = state.units.circle * node.scale * node.scaleMod
      paintFunction = paintCircle
      props.size = baseSize
  }

  if (isSelected || isActive) {
    // @ts-ignore
    paintFunction({ ...props, lineWidth: lineWidth * 3, stroke: isSelected ? mainColor : mainColorLight })
  } else if (node.isCritical || node.isCriticalTemp) {
    // @ts-ignore
    paintFunction({ ...props, lineWidth: lineWidth * 3, stroke: criticalColor })
  }
  // @ts-ignore
  paintFunction({ ...props })
}

const getOpacity = ({ node, selected, state, related, filterMode, isFiltered }: Omit<PaintNodeEntryPoint, 'ctx'>) => {
  let opacity = 1
  if (
    (filterMode === 'highlight' && isFiltered) ||
    selected ||
    state.activeObject ||
    node.completionType === 'complete_skipped' ||
    node.completionType === 'complete_abandoned'
  ) {
    opacity = 0.25
  }
  if (
    (state.hoverObject && state.hoverObject.id === node.id) ||
    (state.activeObject && state.activeObject.id === node.id) ||
    selected === node.id ||
    node.isCriticalTemp ||
    (related.length && related.includes(node.id.toString()))
  ) {
    opacity = 1
  } else if (selected) {
    opacity = node.highlight ? 0.5 : 0.25
  } else if (node.highlight) {
    opacity = 1
  }
  return opacity
}

const drawTextLabel = ({ ctx, node, state }: Pick<PaintNodeEntryPoint, 'ctx' | 'node' | 'state'>) => {
  const { x, y } = node
  if (state.transform.scale < 1 || node.scale !== 1) return

  const textSize = (state.units.base * 1.25) / state.transform.scale
  const mainFill = `rgba(${state.display.textColor}, 1)`
  const subFill = `rgba(${state.display.textColorLight}, 1)`
  ctx.font = `${textSize}px Inter`
  ctx.textBaseline = 'middle'
  ctx.textAlign = 'left'
  ctx.fillStyle = mainFill
  const spaceAvail = 60 - state.units.base
  const charsFitEstimate = Math.floor(spaceAvail / (textSize / 2))
  let label = node.miniLabel

  if (node.label && node.label?.length > charsFitEstimate) {
    label = `${node.miniLabel.substring(0, charsFitEstimate)}...`
  }

  if (state.transform.scale >= 3) {
    ctx.fillText(label, x + state.units.base, y - textSize * 1.2)
    ctx.fillStyle = subFill
    ctx.fillText(node.miniMetaLabel1, x + state.units.base, y)
    ctx.fillText(node.miniMetaLabel2, x + state.units.base, y + textSize * 1.2)
  } else {
    ctx.fillText(label, x + state.units.base, y - textSize * 0.6)
    ctx.fillStyle = subFill
    ctx.fillText(`#${node.internalId}`, x + state.units.base, y + textSize * 0.6)
  }
}

const drawFixedStartIndicator = ({
  ctx,
  node,
  state,
  mainColor,
  opacity,
  lineWidth
}: Pick<PaintNodeEntryPoint, 'ctx' | 'node' | 'state'> & { mainColor: string; opacity: number; lineWidth: number }) => {
  if (!node.startFixed || node.scale !== 1 || state.transform.scale <= 1) return

  const { x, y } = node
  ctx.fillStyle = mainColor.replace(')', `, ${opacity})`).replace('rgb', 'rgba')
  const baseSize = state.units.square * node.scale * node.scaleMod
  ctx.beginPath()
  ctx.rect(x - baseSize / 2, y - baseSize * 1.8, baseSize, lineWidth / 2)
  ctx.fill()
}

const drawStageIndicators = ({
  ctx,
  node,
  state,
  mainColor
}: Pick<PaintNodeEntryPoint, 'ctx' | 'node' | 'state'> & { mainColor: string }) => {
  if (node.scale !== 1 || state.transform.scale <= 1) return

  const { x, y } = node
  ctx.font = `${state.units.base * node.scaleMod}px icomoon`
  ctx.textBaseline = 'middle'
  ctx.textAlign = 'center'

  switch (node.stage) {
    case 'complete':
      ctx.fillStyle = 'rgb(255,255,255)'
      if (node.completionType === 'complete_skipped') {
        ctx.fillText('\ue9d2', x, y)
      } else if (node.completionType === 'complete_abandoned') {
        ctx.fillText('\ue997', x, y)
      } else {
        ctx.fillText('\ue99b', x, y + 1)
      }
      break
    case 'startable':
    case 'in-progress':
      ctx.fillStyle = mainColor
      ctx.fillText(node.stage === 'startable' ? '\ue998' : '\ue9b3', x, y)
      break
    default:
      break
  }
}

const drawPredSuccCount = ({
  ctx,
  node,
  state,
  mainColor
}: Pick<PaintNodeEntryPoint, 'ctx' | 'node' | 'state'> & { mainColor: string }) => {
  if (node.scale !== 1 || state.transform.scale <= 1) return
  if (!node.pidsExt && !node.sidsExt) return

  const { x, y } = node
  const baseSize = state.units.base * node.scale * node.scaleMod
  const circleParams = {
    ctx,
    size: baseSize / 5,
    lineWidth: baseSize / 20,
    stroke: '#ffffff',
    fill: mainColor
  }

  if (node.pidsExt) {
    paintCircle({ ...circleParams, x, y: y - baseSize - baseSize / 5 })
    if (state.transform.scale > 3.5) {
      ctx.font = `bold ${baseSize / 5}px Arial`
      ctx.textBaseline = 'middle'
      ctx.textAlign = 'center'
      ctx.fillStyle = '#ffffff'
      ctx.fillText(`+${node.pidsExt}`, x, y - baseSize - baseSize / 5)
    }
  }

  if (node.sidsExt) {
    paintCircle({ ...circleParams, x, y: y + baseSize + baseSize / 5 })
    if (state.transform.scale > 3.5) {
      ctx.font = `bold ${baseSize / 5}px Arial`
      ctx.textBaseline = 'middle'
      ctx.textAlign = 'center'
      ctx.fillStyle = '#ffffff'
      ctx.fillText(`+${node.sidsExt}`, x, y + baseSize + baseSize / 5)
    }
  }
}
