/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
// @ts-nocheck
import { fromUnixTime, getUnixTime } from 'date-fns'
import { cloneDeep, unescape } from 'lodash'

import { addToDate, subtractFromDate } from '@cutover/react-ui'
import { Axis, TimeAxis } from './timeline-axis'
import { Item, ItemPosition } from './timeline-item'
import { AxisDefName, FilterMode, TimelineOptions, ViewportDates } from './types'

const d3 = window.d3_5

/*
 * IMPORTANT! Notice the top-level typescript and eslint ignores. These exist because this
 * code is copied over from the angular omniview controller (event timeline). Only the minimum
 * changes necessary were made to remove angular lifecycle dependencies for usage here.
 */

export class TimelineController implements TimelineOptions {
  // inputs
  data: any[]
  axisDefs: Partial<Record<AxisDefName, AxisDef<any>>>
  dimensionX: AxisDefName
  dimensionY: AxisDefName | null

  viewEndDate: number
  viewStartDate: number
  totalStartDate: number
  totalEndDate: number

  filterMode: FilterMode

  spotlight: AxisDefName | null
  selectedStartDate: number | {}
  selectedEndDate: number | {}

  allowLinks: boolean
  enableItemStages: boolean
  // NOTE: no usages of this...
  // timeContextData: any = null

  $element: HTMLDivElement = null

  // callbacks
  onItemHover?: (args: { item: any; x: number; y: number }) => void
  onDateSelect?: (args: { startDate: number; endDate: number }) => void
  onRescale?: (args: { startDate: number; endDate: number }) => void
  onRescaleEnd?: (args: { startDate: number; endDate: number }) => void
  onSelectionChange?: (args: { ids: number[]; x: number; y: number }) => void
  onCreateLink?: (args: { links: any[] }) => void // TODO: what is a link?
  onClickLink?: (args: { type: string; id: number }) => void // TODO: what is a link?
  onViewportChange?: (args: ViewportDates) => void

  // NOTE: not used anywhere in the code.
  onRangeSelect?: (data: any) => any
  onRangeSelectEnd?: (data: any) => any

  viewport: any
  prevViewport: any

  // local variables
  private zoom: any
  private width: number = null
  private height: number = null
  private canvas: any
  private context: any = null
  private shadowCanvas: any = null
  private shadowContext: any = null
  private config: any
  private state: any
  private links: any[] = []
  private items: Item[] = []
  private visibleItemIds: number[] = []
  private itemsUpdated: number[] = []
  private itemsRemoved: number[] = []
  private itemsAdded: number[] = []
  private selectedItemIds: number[] = []
  private itemsLookup: any = {}
  private display: 'compact' | 'normal' | 'full' | 'nodes' = 'normal'

  private dimensionXData: any = null
  private dimensionYData: any = null

  constructor($element: Element, options: Partial<TimelineOptions>) {
    this.onResize = this.onResize.bind(this)
    this.$element = $element

    // canvas array
    this.viewport = {
      width: 0,
      height: 0,
      start: 0,
      end: 0,
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      currentStart: 0,
      currentEnd: 0,
      currentLeft: 0,
      currentRight: 0,
      currentTop: 0,
      currentBottom: 0,
      maxY: 0
    }

    // user configurable setings
    this.config = {
      showDuration: true,
      baseSize: 20,
      padding: 12,
      itemTotalHeight: 22,
      itemHeight: 6,
      minItemWidth: 140,
      maxChars: 20,
      style: {
        fontSizeXSmall: 12,
        fontSizeSmall: 14,
        fontSizeMed: 16,
        fontSizeLg: 18,
        textColor: 'rgb(22,22,29)',
        greyLight: 'rgb(178,192,199)',
        errorColor: 'rgb(255,51,0)',
        primaryColor: 'rgb(45,85,195)'
      },
      summaryItemHeight: 32,
      groupHeaderHeight: 24,
      groupMarginBottom: 12,
      summaryItemAlign: 'mid',
      footerHeight: 32,
      headerHeight: 72,
      timeShortcuts: [
        { id: '1W', interval: 'day', count: 7 },
        { id: '2W', interval: 'day', count: 14 },
        { id: '3W', interval: 'day', count: 21 },
        { id: '4W', interval: 'day', count: 28 },
        { id: '2M', interval: 'month', count: 2 },
        { id: '3M', interval: 'month', count: 3 },
        { id: '6M', interval: 'month', count: 6 }
        // {id: 'ALL', interval: null, count: 0}
      ]
    }

    // internal settings
    this.state = {
      transition: 1,
      initialised: false,
      debug: false,
      debugShadow: false,
      pixelRatio: window.devicePixelRatio ? window.devicePixelRatio : 1,
      colorCurrentIndex: 1,
      colorToObjMap: {},
      hoverObject: null,
      mouseDownObject: null,
      mouseDownCoords: null,
      dragging: false,
      transform: { k: 1, x: 0, y: 0 },
      selectedDate: { start: null, end: null },
      selecting: false,
      tempSelected: [],
      hoverTimeout: null,
      hoverTimeoutDuration: 0,
      rangeSelected: false,
      performanceData: {
        enabled: false,
        segments: [],
        frameCount: 0,
        frameTimes: []
      }
    }

    const nowDate = new Date()
    const startDate = options.viewStartDate ? fromUnixTime(options.viewStartDate) : nowDate

    this.data = options.data || []
    this.axisDefs = options.axisDefs || {}
    this.dimensionX = options.dimensionX ? options.dimensionX : this.axisDefs['time'] ? 'time' : undefined
    this.dimensionY = options.dimensionY
    this.viewStartDate = getUnixTime(startDate)
    this.viewEndDate = options.viewEndDate || addToDate(this.viewStartDate, { days: 28 })
    this.totalStartDate = options.totalStartDate || getUnixTime(subtractFromDate(startDate, { years: 1 }))
    this.totalEndDate = options.totalEndDate || getUnixTime(addToDate(startDate, { years: 1 }))

    this.filterMode = options.filterMode || 'default'

    this.spotlight = options.spotlight || null
    this.selectedStartDate = options.selectedStartDate || null
    this.selectedEndDate = options.selectedEndDate || null

    this.allowLinks = options.allowLinks || false
    this.enableItemStages = options.enableItemStages || false

    this.onItemHover = options.onItemHover
    this.onDateSelect = options.onDateSelect
    this.onRescale = options.onRescale
    this.onRescaleEnd = options.onRescaleEnd
    this.onSelectionChange = options.onSelectionChange
    this.onCreateLink = options.onCreateLink
    this.onClickLink = options.onClickLink
    this.onRangeSelect = options.onRangeSelect
    this.onRangeSelectEnd = options.onRangeSelectEnd
    this.onViewportChange = options.onViewportChange

    this.init()
  }

  onResize(): void {
    // TODO: may not need the clone deep
    this.prevViewport = cloneDeep(this.viewport || {})
    this.init(false)
    this.handleChange(false, false)
  }

  onChanges(changes): void {
    if (!this.state.initialised) {
      this.init()
      this.state.initialised = true
    }

    if (changes.data) {
      // second argument indicates whether animation should occur
      this.handleChange(false)
    } else if (changes.spotlight) {
      this.handleChange()
    } else if (changes.selectedStartDate || changes.selectedEndDate) {
      this.render(false)
    } else if (changes.dimensionX) {
      this.initAxisX()
      this.resetViewport()
      this.handleChange(false)
    } else if (changes.dimensionY) {
      this.initAxisY()
      this.handleChange(true)
    }
  }

  unmount() {
    this.canvas.remove()
  }

  cleanup() {
    this.onRescaleEnd = undefined
    d3.timerFlush()
  }

  deselectItems() {
    this.selectedItemIds = []
  }

  //==============================================================
  //======================= INIITIALIZERS ========================
  //==============================================================

  // sets up all the necessary drawing contexts
  private init(reset = true): void {
    if (!this.$element) return

    this.prevViewport = { ...this.viewport }

    if (reset && this.canvas) {
      this.canvas.remove()
    }

    var bounds = this.$element.getBoundingClientRect()
    this.width = bounds.width
    this.height = bounds.height

    this.zoom = d3
      .zoom()
      .scaleExtent([0.1, 10])
      .on('zoom', () => this.d3PanZoomHandler())
      .on('end', () => this.d3PanZoomHandlerEnd())

    const shouldReset = !this.canvas || reset
    if (shouldReset) {
      this.canvas = d3
        .select(this.$element)
        .append('canvas')
        .attr('id', 'tabpanel-runbooks')
        .attr('aria-labelledby', 'tab-runbooks view-toggle-timeline')
        .attr('class', 'timeline-canvas')
    }

    this.canvas
      .attr('data-testid', 'runbooks-timeline-canvas')
      .attr('width', this.width * this.state.pixelRatio)
      .attr('height', this.height * this.state.pixelRatio)
      .style('width', this.width + 'px')
      .style('height', this.height + 'px')
      .style('position', 'absolute')
      .style('border-radius', '16px 16px 0 0')
      .on('mousedown', () => this._mouseDown())
      .on('mousemove', () => this._mouseMove())
      .on('mouseleave', () => this._mouseLeave())
      .call(this.zoom)

    // Setup canvas drawing context
    this.context = this.canvas.node().getContext('2d')
    this.context.scale(this.state.pixelRatio, this.state.pixelRatio) // initial scale

    // Setup shadow canvas - a hidden mirror to detect user interaction
    this.shadowCanvas = document.createElement('canvas')
    this.shadowCanvas.width = this.width * this.state.pixelRatio
    this.shadowCanvas.height = this.height * this.state.pixelRatio
    this.shadowCanvas.style.width = this.width + 'px'
    this.shadowCanvas.style.height = this.height + 'px'
    this.shadowCanvas.style.top = this.config.headerHeight + 'px'

    if (this.state.debugShadow) {
      this.shadowCanvas.style.position = 'absolute'
      this.shadowCanvas.style.top = '0'
      this.shadowCanvas.style.left = '0'
      this.shadowCanvas.style.zIndex = '-1'
      this.$element.prepend(this.shadowCanvas)
    }

    this.shadowContext = this.shadowCanvas.getContext('2d')
    this.shadowContext.scale(this.state.pixelRatio, this.state.pixelRatio) // initial scale

    if (reset) {
      this.initAxisX()
      this.initAxisY()
    }

    let x = 0,
      y = 0,
      k = 1

    if (!this.prevViewport || !this.prevViewport.width) {
      var viewStartToDataEndDuration = this.totalEndDate - this.viewStartDate
      var viewPortDuration = this.viewEndDate - this.viewStartDate
      var viewStartDate = getUnixTime(
        subtractFromDate(new Date(this.viewStartDate * 1000), { seconds: (viewPortDuration / 1.2) * 0.1 })
      )
      this.initViewport(viewStartDate, this.viewEndDate)
      // if its only a height adjustment
    } else if (this.width === this.prevViewport.width) {
      y = this.prevViewport.currentTop * -1
      this.initViewport(this.prevViewport.currentStart, this.prevViewport.currentEnd)
      // its an adjustment that includes width
    } else {
      y = this.prevViewport.currentTop * -1
      // TODO: this seems slightly off
      const durationPerPx = this.prevViewport.currentDuration / this.prevViewport.currentWidth
      const newEnd = this.prevViewport.currentStart + this.width * durationPerPx
      this.initViewport(this.prevViewport.currentStart, newEnd)
    }

    this.transform(x, y, k)

    this.state.initialised = true
  }

  private initAxisX(): void {
    if (this.axisDefs.hasOwnProperty(this.dimensionX)) {
      if (this.axisDefs[this.dimensionX].dataType === 'time') {
        this.dimensionXData = new TimeAxis(this.axisDefs[this.dimensionX])
      } else {
        this.dimensionXData = new Axis(this.axisDefs[this.dimensionX], this.viewport, 'x')
      }
    }
  }

  private initAxisY(): void {
    this.resetPositions()
    if (!this.dimensionY) {
      // no grouping, so need to set up a dummy axis
      var dummyAxis = {
        name: 'Default',
        values: [{ id: 0, name: 'All' }]
      }
      this.dimensionYData = new Axis(dummyAxis, this.viewport, 'y', true)
      this.dimensionYData.values[0].expanded = true
      this.dimensionYData.values[0].isDummy = true
    } else if (this.axisDefs.hasOwnProperty(this.dimensionY)) {
      this.dimensionYData = new Axis(this.axisDefs[this.dimensionY], this.viewport, 'y')
    }
  }

  private initViewport(startUnix: number, endUnix: number): void {
    this.viewport.width = this.width
    this.viewport.height = this.height
    this.viewport.right = this.width
    this.viewport.bottom = this.height
    this.viewport.start = startUnix
    this.viewport.end = endUnix
    this.viewport.duration = endUnix - startUnix
    this.viewport.maxY = 0
    this.updateViewport(startUnix, endUnix)
  }

  private updateViewport(startUnix: number, endUnix: number, x: number, y: number, k: number): void {
    this.viewport.currentWidth = this.width / k
    this.viewport.currentHeight = this.height / k
    this.viewport.currentLeft = -x / k
    this.viewport.currentTop = -y / k
    this.viewport.currentRight = this.viewport.currentLeft + this.viewport.currentWidth
    this.viewport.currentBottom = this.viewport.currentTop + this.viewport.currentHeight
    this.viewport.currentStart = startUnix
    this.viewport.currentEnd = endUnix
    this.viewport.currentDuration = endUnix - startUnix
  }

  //======================= HELPERS =======================

  // NOTE: private function not called internally
  // private findMinMax(arr: any[], property: string): number[] {
  //   let min = arr[0][property],
  //     max = arr[0][property]
  //   let minIndex = 0,
  //     maxIndex = 0
  //   for (let i = 1, len = arr.length; i < len; i++) {
  //     let v = arr[i][property]
  //     if (v < min) {
  //       min = v
  //       minIndex = i
  //     }
  //     if (v > max) {
  //       max = v
  //       maxIndex = i
  //     }
  //   }
  //   return [min, minIndex, max, maxIndex]
  // }

  private findMin(arr: any[], property: string): { value: number; index: number } | null {
    if (!arr.length) {
      return null
    }
    let min = arr[0][property],
      minIndex = 0
    for (let i = 1, len = arr.length; i < len; i++) {
      let v = arr[i][property]
      if (v < min) {
        min = v
        minIndex = i
      }
    }
    return { min, minIndex, value: min, index: minIndex }
  }

  private findMax(arr: any[], property: string): { value: number; index: number } | null {
    if (!arr.length) {
      return null
    }
    let max = arr[0][property],
      maxIndex = 0
    for (let i = 1, len = arr.length; i < len; i++) {
      let v = arr[i][property]
      if (v > max) {
        max = v
        maxIndex = i
      }
    }
    return { max, maxIndex, value: max, index: maxIndex }
  }

  private genColor() {
    var ret = []
    if (this.state.colorCurrentIndex < 16777215) {
      ret.push(this.state.colorCurrentIndex & 0xff) // R
      ret.push((this.state.colorCurrentIndex & 0xff00) >> 8) // G
      ret.push((this.state.colorCurrentIndex & 0xff0000) >> 16) // B
      this.state.colorCurrentIndex += 1
    }
    return 'rgb(' + ret.join(',') + ')'
  }

  private _pickElement(x: number, y: number): any {
    if (!x || !y) {
      return
    }
    var col = this.shadowContext.getImageData(x * this.state.pixelRatio, y * this.state.pixelRatio, 1, 1).data
    var rgbString = col[3] === 255 ? 'rgb(' + col[0] + ',' + col[1] + ',' + col[2] + ')' : 'rgb(0,0,0)'
    var returnObject = this.state.colorToObjMap[rgbString]
    returnObject = returnObject ? returnObject : null
    return returnObject
  }

  // doesn't need to be part of this class
  // NOTE: private method not called internally
  // private capitalize(s: string): string {
  //   return s.charAt(0).toUpperCase() + s.slice(1)
  // }

  // doesn't need to be part of this class
  private roundedRectPath(
    x: number,
    y: number,
    w: number,
    h: number,
    r: number,
    arrowLeft: boolean = false,
    arrowRight: boolean = false
  ): string {
    var tlr = r,
      trr = r,
      brr = r,
      blr = r
    // points numbered clockwise from bot left of top left radius
    return (
      'M ' +
      x +
      ' ' +
      (tlr + y) +
      (!arrowLeft ? ' A ' + tlr + ' ' + tlr + ' 0 0 1 ' + (tlr + x) + ' ' + y : ' L ' + (tlr + x) + ' ' + y) + // arc 1 to 2
      (w > r * 2 ? ' L ' + (x + w - trr) + ' ' + y : '') + // line 2 - 3
      (!arrowRight
        ? ' A ' + trr + ' ' + trr + ' 0 0 1 ' + (x + w) + ' ' + (trr + y)
        : ' L ' + (x + w) + ' ' + (trr + y)) + // arc 3 - 4
      (h > r * 2 ? ' L ' + (x + w) + ' ' + (y + h - brr) : '') + // line 4 - 5
      (!arrowRight
        ? ' A ' + brr + ' ' + brr + ' 0 0 1 ' + (x + w - brr) + ' ' + (y + h)
        : ' L ' + (x + w - brr) + ' ' + (y + h)) +
      (w > r * 2 ? ' L ' + (x + blr) + ' ' + (h + y) : '') +
      (!arrowLeft ? ' A ' + blr + ' ' + blr + ' 0 0 1 ' + x + ' ' + (y + h - blr) : ' L ' + x + ' ' + (y + h - blr)) +
      ' Z'
    )
  }

  private isHovered(type: string, id: any): boolean {
    var compareType
    if (type === 'node') {
      compareType = ['node', 'createPredecessor', 'createSuccessor']
    } else {
      compareType = [type]
    }
    return (
      this.state.hoverObject &&
      compareType.indexOf(this.state.hoverObject.type) !== -1 &&
      this.state.hoverObject.id === id
    )
  }

  // doesn't need to be part of this class
  private isSame(object1: any, object2: any) {
    if (object1.id !== object2.id || object1.type !== object2.type) {
      return false
    }
    return true
  }

  // NOTE: private method not called internally
  // private isItemSelected(id: number): boolean {
  //   return this.selectedItemIds.indexOf(id) !== -1
  // }

  //==============================================================
  //======================= CLICK HANDLERS =======================
  //==============================================================

  private handleClick(objectType: string, objectId: any, event: any): void {
    if (objectType === 'yValue') {
      this.clickYAxisHeader(objectId)
    } else if (objectType === 'xValue') {
      this.clickXValue(objectId)
    } else if (objectType === 'node' && event.shiftKey) {
      this.clickAdditionalItem(objectId)
    } else if (objectType === 'node' && event.metaKey) {
      this.clickRelated('tree', objectId)
    } else if (objectType === 'node') {
      this.clickItem(objectId, event)
    } else if (objectType === 'createPredecessor') {
      this.clickRelated('ancestors', objectId)
    } else if (objectType === 'createSuccessor') {
      this.clickRelated('descendants', objectId)
    } else if (objectType === 'timeControl' && objectId === 'NOW') {
      this.clickNow()
    } else if (objectType === 'timeControl') {
      this.clickDuration(objectId)
    }
  }

  private clickRelated(type: string, id: number): void {
    // trigger callback
    if (this.onClickLink) {
      this.onClickLink({
        type: type,
        id: id
      })
    }
  }

  private clickItem(id: number, event: d3.event): void {
    // console.log(event.pageX, event.pageY, 'ddd')
    var existingPos = this.selectedItemIds.indexOf(id)
    this.selectedItemIds = []
    if (existingPos === -1) {
      this.selectedItemIds.push(id)
    }
    this.render(false)

    // trigger callback
    if (this.onSelectionChange) {
      this.onSelectionChange({
        ids: this.selectedItemIds,
        x: event.pageX,
        y: event.pageY
      })
    }
  }

  private clickAdditionalItem(id: number): void {
    var existingPos = this.selectedItemIds.indexOf(id)
    if (existingPos !== -1) {
      this.selectedItemIds.splice(existingPos, 1)
    } else {
      this.selectedItemIds.push(id)
    }
    this.render(false)

    // trigger callback
    if (this.onSelectionChange) {
      this.onSelectionChange({
        ids: this.selectedItemIds
      })
    }
  }

  private clickYAxisHeader(id: number): void {
    var yValue = this.dimensionYData.find(id)
    if (yValue.expanded) {
      yValue.expanded = false
      for (var i = 0, n = yValue.itemIds.length; i < n; i++) {
        var item = this.findItem(yValue.itemIds[i])
        if (!item) {
          continue
        }
        item.positions = {}
      }
    } else {
      yValue.expanded = true
    }
    // assumes positions already exist so no need for updateData
    this.generateTargetPositions() // generates all positions
    this.render(true)
  }

  private clickXValue(d): void {
    if (this.dimensionXData.dataType !== 'time') {
      return
    }
    var clickedDate = new Date(d * 1000)
    var existingStartDate = this.selectedStartDate ? new Date(this.selectedStartDate * 1000) : null
    var start, end

    if (existingStartDate && !this.state.rangeSelected) {
      if (existingStartDate < clickedDate) {
        start = getUnixTime(existingStartDate)
        end = getUnixTime(this.dimensionXData.endOfPeriod(d))
      } else {
        start = getUnixTime(clickedDate)
        end = getUnixTime(this.dimensionXData.endOfPeriod(this.selectedStartDate))
      }
      this.state.rangeSelected = true
    } else {
      // TODO only works when periods are days
      start = d
      end = getUnixTime(this.dimensionXData.endOfPeriod(d))
      this.state.rangeSelected = false
    }

    // call external callback, could open panel with date range summary etc
    if (this.onDateSelect) {
      this.onDateSelect({ startDate: start, endDate: end })
    }
  }

  // NOTE: private method not called internally
  // private download(): void {
  //   var maxHeight = this.viewport.maxY + this.config.footerHeight + this.config.summaryItemHeight

  //   // resize canvas so everything fits vertically
  //   this.canvas.attr('height', maxHeight * this.state.pixelRatio).style('height', maxHeight + 'px')

  //   this.resetY()

  //   var prevHeight = this.viewport.currentHeight
  //   var prevBottom = this.viewport.currentBottom

  //   this.viewport.currentHeight = maxHeight
  //   this.viewport.currentBottom = maxHeight
  //   // this.context.scale(this.state.pixelRatio, this.state.pixelRatio) // initial scale

  //   this.draw() // TODO user doesnt need to see this

  //   this.canvas.node().toBlob(function (blob) {
  //     saveAs(blob, 'test.png')
  //   })

  //   // return canvas to normal
  //   this.canvas
  //     .attr('height', this.viewport.height * this.state.pixelRatio)
  //     .style('height', this.viewport.height + 'px')
  //   this.viewport.currentHeight = prevHeight
  //   this.viewport.currentBottom = prevBottom
  //   // this.context.scale(this.state.pixelRatio, this.state.pixelRatio) // initial scale

  //   this.render(false)
  // }

  private clickNow(): void {
    var duration = this.viewport.currentDuration
    var newStart = getUnixTime(subtractFromDate(new Date(), { seconds: (duration / 1.2) * 0.1 }))
    this.initViewport(newStart, newStart + duration)
    this.transform()
    this.handleChange()
  }

  private _addToDateJson(key, step) {
    const intervalMapping = {
      minute: { minutes: step },
      hour: { hours: step },
      day: { days: step },
      week: { weeks: step },
      month: { months: step },
      quarter: { quarters: step },
      year: { years: step }
    }

    return intervalMapping[key]
  }

  private clickDuration(durationId: any): void {
    const durationData = this.config.timeShortcuts.find(timeShortcut => timeShortcut.id === durationId)
    if (!durationData) {
      return
    }
    var start, end
    if (durationData.id === 'ALL') {
      start = getUnixTime(new Date(this.totalStartDate * 1000))
      end = getUnixTime(new Date(this.totalEndDate * 1000))
    } else {
      start = this.viewport.currentStart
      end = getUnixTime(
        addToDate(new Date(start * 1000), this._addToDateJson(durationData.interval, durationData.count))
      )
    }

    this.initViewport(start, end)

    this.transform()

    this.handleChange()
  }

  //==============================================================
  //======================= MOUSE EVENTS =========================
  //==============================================================

  private _mouseLeave(): void {
    this.state.hoverObject = null
    this.draw()
  }

  private _mouseMove(): void {
    var hoverObject = this._pickElement(d3.event.offsetX, d3.event.offsetY)
    var needsRedraw = false
    var cursor
    // newly hovered item
    if (hoverObject && !this.state.hoverObject) {
      this.state.hoverObject = hoverObject
      // OBS-690 : disabling ability to link between runbooks via dots/circles on runbook item objects
      // cursor = ['createPredecessor', 'createSuccessor'].indexOf(hoverObject.type) !== -1 ? 'crosshair' : 'pointer'
      cursor = 'pointer'
      this.canvas.style('cursor', cursor)
      // this.state.hoverTimeout = setTimeout((event) => this.triggerTooltip(event), this.state.hoverTimeoutDuration, d3.event)
      needsRedraw = true

      // hovered item changed
    } else if (
      hoverObject &&
      this.state.hoverObject &&
      (hoverObject.id !== this.state.hoverObject.id || hoverObject.type !== this.state.hoverObject.type)
    ) {
      this.state.hoverObject = hoverObject
      // OBS-690 : disabling ability to link between runbooks via dots/circles on runbook item objects
      // cursor = ['createPredecessor', 'createSuccessor'].indexOf(hoverObject.type) !== -1 ? 'crosshair' : 'pointer'
      cursor = 'pointer'
      this.canvas.style('cursor', cursor)
      // if (this.state.hoverTimeout) {
      //   clearTimeout(this.state.hoverTimeout)
      //   this.state.hoverTimeout = null
      // }
      // this.state.hoverTimeout = setTimeout((event) => this.triggerTooltip(event), this.state.hoverTimeoutDuration, d3.event)

      needsRedraw = true

      // was hovering something, now not
    } else if (this.state.hoverObject && !hoverObject) {
      this.state.hoverObject = null
      this.canvas.style('cursor', 'url(/img/openhand.cur), default')
      // if (this.state.hoverTimeout) {
      //   clearTimeout(this.state.hoverTimeout)
      //   this.state.hoverTimeout = null
      // }
      // this.onItemHover({item: null, x: 0, y: 0})
      needsRedraw = true

      // nothing changed (same item hovered or nothing)
    } else {
      // this.canvas.style('cursor','url(/img/openhand.cur), default')

      needsRedraw = false
    }

    if (needsRedraw) {
      this.draw()
    }
  }

  private _mouseDown(): void {
    var currentObject = this._pickElement(d3.event.offsetX, d3.event.offsetY)
    this.state.mouseDownCoords = [d3.event.offsetX, d3.event.offsetY]

    if (currentObject) {
      this.state.mouseDownObject = currentObject
      this.disableD3()
    } else if (d3.event.shiftKey) {
      this.disableD3()
      this.state.selecting = true
    } else {
      this.canvas.style('cursor', 'url(/img/closedhand.cur), default')
      this.state.mouseDownObject = null
    }
  }

  // our custom drag function, used when d3 overridden
  // via mousedown on an object OR holding shift
  private _drag(): void {
    if (this.state.selecting) {
      this.canvas.style('cursor', 'crosshair')
      var x, y, xEnd, yEnd
      if (this.state.mouseDownCoords[0] > d3.event.offsetX) {
        x = d3.event.offsetX / this.state.transform.k + this.viewport.currentLeft
        xEnd = this.state.mouseDownCoords[0] / this.state.transform.k + this.viewport.currentLeft
      } else {
        x = this.state.mouseDownCoords[0] / this.state.transform.k + this.viewport.currentLeft
        xEnd = d3.event.offsetX / this.state.transform.k + this.viewport.currentLeft
      }
      if (this.state.mouseDownCoords[1] > d3.event.offsetY) {
        y = d3.event.offsetY / this.state.transform.k + this.viewport.currentTop
        yEnd = this.state.mouseDownCoords[1] / this.state.transform.k + this.viewport.currentTop
      } else {
        y = this.state.mouseDownCoords[1] / this.state.transform.k + this.viewport.currentTop
        yEnd = d3.event.offsetY / this.state.transform.k + this.viewport.currentTop
      }

      this.state.tempSelected = []
      for (var i = 0, n = this.items.length; i < n; i++) {
        var itemYValues = Object.keys(this.items[i].positions)
        for (var ii = 0, nn = itemYValues.length; ii < nn; ii++) {
          var yValuePositions = this.items[i].positions[itemYValues[ii]]
          for (var iii = 0, nnn = yValuePositions.length; iii < nnn; iii++) {
            var pos = yValuePositions[iii]
            if (this.isBeneath(pos.x, pos.y, pos.x + pos.width, pos.y + pos.height, x, y, xEnd, yEnd)) {
              this.state.tempSelected.push(this.items[i].id)
            }
          }
        }
      }
    } else if (this.state.mouseDownObject) {
      // dragging from an object
      var currentObject = this._pickElement(d3.event.offsetX, d3.event.offsetY)
      if (currentObject && !this.isSame(currentObject, this.state.mouseDownObject)) {
        // dragging from one object to another object

        this.state.hoverObject = currentObject
      } else {
        this.state.hoverObject = null
      }
    }
    this.draw()
  }

  // TODO: this currently takes x/y/k as that is what d3 spits out
  // more consistent if this took a start/end
  private transform(x: number = 0, y: number = 0, k: number = 1): void {
    // if we dont do this, d3 loses track of the transform
    var transform = d3.zoomTransform(this.canvas.node())
    transform.x = x
    transform.y = y
    transform.k = k

    var xChanged = x === this.state.transform.x ? false : true
    var kChanged = k === this.state.transform.k ? false : true

    this.state.transform.x = x
    this.state.transform.y = y
    this.state.transform.k = k

    var newDuration = this.viewport.duration / k

    var secondsMoved = (x / this.viewport.width) * newDuration
    var newStartUnix = this.viewport.start - secondsMoved
    var newEndUnix = newStartUnix + newDuration

    this.updateViewport(newStartUnix, newEndUnix, x, y, k)

    // alternative for resetTransform supported in all browsers
    this.context.setTransform(1, 0, 0, 1, 0, 0)
    this.shadowContext.setTransform(1, 0, 0, 1, 0, 0)

    this.context.translate(x * this.state.pixelRatio, y * this.state.pixelRatio)
    this.shadowContext.translate(x * this.state.pixelRatio, y * this.state.pixelRatio)

    this.context.scale(k * this.state.pixelRatio, k * this.state.pixelRatio)
    this.shadowContext.scale(k * this.state.pixelRatio, k * this.state.pixelRatio)

    // TODO only need time axis update if X changes
    if (kChanged || xChanged || x === 0) {
      if (this.dimensionXData.dataType === 'time') {
        // only need to regen xAxis if it is a time axis
        this.dimensionXData.initValues(this.viewport)
      }
    }
    // trigger callback, could load more data
    if (this.onRescale) {
      this.onRescale({
        startDate: newStartUnix,
        endDate: newEndUnix
      })
    }
  }

  private disableD3(): void {
    this.canvas.on('.zoom', null) // disable the D3 panzoom temporarily
    this.canvas.on('mousemove', () => this._drag())
    this.canvas.on('mouseup', () => this._mouseUp())
  }

  private enableD3(): void {
    this.canvas.on('mousemove', () => this._mouseMove())
    this.canvas.on('mouseup', null)
    this.canvas.call(this.zoom)
  }

  private _mouseUp(): void {
    var event = d3.event

    var currentObject = this._pickElement(event.offsetX, event.offsetY)
    var i,
      n,
      needsRedraw = false
    // reset temp selection & add to main selection
    if (this.state.tempSelected.length) {
      var changed = false
      for (i = 0, n = this.state.tempSelected.length; i < n; i++) {
        if (this.selectedItemIds.indexOf(this.state.tempSelected[i]) === -1) {
          this.selectedItemIds.push(this.state.tempSelected[i])
          changed = true
        }
      }

      // trigger callback
      if (changed && this.onSelectionChange) {
        // this.onSelectionChange({
        //   ids: this.selectedItemIds
        // })
      }
      this.state.tempSelected = []
    }

    //
    if (currentObject && this.state.mouseDownObject) {
      // if same object as source
      if (this.isSame(currentObject, this.state.mouseDownObject)) {
        // if mouse hasn't moved, this is a click
        if (this.state.mouseDownCoords && this.state.mouseDownCoords[0] === d3.event.offsetX) {
          this.handleClick(currentObject.type, currentObject.id, event)
        } else {
          // dragging an item
        }
      } else {
        // mouse down on one object, mouseup on another

        if (this.onCreateLink) {
          var linkSourceIds = this.selectedItemIds.length ? this.selectedItemIds : [this.state.mouseDownObject.id]

          var links = []
          for (i = 0; i < linkSourceIds.length; i++) {
            if (this.state.mouseDownObject.type === 'createPredecessor') {
              links.push({ source: currentObject.id, target: linkSourceIds[i] })
            } else if (this.state.mouseDownObject.type === 'createSuccessor') {
              links.push({ source: linkSourceIds[i], target: currentObject.id })
            }
          }

          this.onCreateLink({ links: links })
        }
      }
    } else if (this.state.mouseDownObject) {
      // dragging from an object to empty space
      needsRedraw = true
    } else {
      // click in empty space, not currently reachable
      // as d3 treats as a pan
      // console.log('click empty')
    }

    if (this.state.selecting) {
      this.state.selecting = false
      needsRedraw = true
    }

    // rebind d3 zoom, default mousemove & remove mouseup
    this.enableD3()
    this.state.selecting = false
    this.state.dragging = false
    this.state.mouseDownObject = null
    this.state.mouseDownCoords = null
    this.state.hoverObject = null

    if (needsRedraw) {
      this.draw()
    }
  }

  //===============================================================
  //======================= PANNING/ZOOMING =======================
  //===============================================================

  private resetY(): void {
    this.transform(this.state.transform.x, 0, this.state.transform.k)
    // this.zoom.transform(this.canvas.node(), d3.zoomIdentity);
    // this.canvas.call(this.zoom.transform, d3.zoomIdentity.translate(this.state.transform.x, 0))
  }

  private resetViewport(): void {
    this.transform(0, 0, 1)
    // this.zoom.transform(this.canvas.node(), d3.zoomIdentity);
    // this.canvas.call(this.zoom.transform, d3.zoomIdentity.translate(this.state.transform.x, 0))
  }

  private d3PanZoomHandler(): void {
    // console.log('d3PanZoomHandler')
    var t = d3.event.transform
    // var isZoom = t.k !== this.state.transform.k

    // when zooming, dont alter the vertical pos
    // var y = isZoom ? this.state.transform.y : t.y
    // var newY = Math.min(0,t.y)
    // var transform = d3.zoomTransform(this.canvas.node())
    // transform.y = newY
    this.transform(t.x, t.y, t.k)
    this.draw()
  }

  private d3PanZoomHandlerEnd(): void {
    // console.log('d3PanZoomHandlerEnd')
    this.canvas.style('cursor', 'url(/img/openhand.cur), default')

    // treat as a click if hasnt moved
    if (
      d3.event &&
      d3.event.sourceEvent &&
      this.state.mouseDownCoords &&
      this.state.mouseDownCoords[0] === d3.event.sourceEvent.offsetX &&
      this.state.mouseDownCoords[1] === d3.event.sourceEvent.offsetY
    ) {
      this.selectedItemIds = []
      this.draw()
      if (this.onSelectionChange) {
        this.onSelectionChange({
          ids: this.selectedItemIds,
          x: 0,
          y: 0
        })
      }
      if (this.onDateSelect) {
        this.onDateSelect({ startDate: null, endDate: null })
      }
      return
    }

    this.summariseGroups() // generates all totals
    // this.generateTargetPositions() // generates all positions
    //
    this.render(false)
  }

  //===============================================================
  //======================= DRAWING ===============================
  //===============================================================

  private animatePosition(itemPosition: ItemPosition, t: number): void {
    if (this.itemsAdded.indexOf(itemPosition.itemId) !== -1) {
      // node is entering the viewport
      itemPosition.transition = t
    } else if (this.itemsRemoved.indexOf(itemPosition.itemId) !== -1) {
      // node is leaving the viewport
      itemPosition.transition = 1 - t
    } else {
      if (itemPosition.entering) {
        itemPosition.transition = t
      } else if (itemPosition.leaving) {
        itemPosition.transition = 1 - t
      } else {
        itemPosition.transition = 1
      }
      itemPosition.x = (itemPosition.tx - itemPosition.px) * t + itemPosition.px
      itemPosition.y = (itemPosition.ty - itemPosition.py) * t + itemPosition.py
    }
  }

  private render(animate: boolean = false): void {
    if (this.state.performanceData.enabled) {
      this.state.performanceData.frameTimes = []
    }

    if (animate) {
      // Animate from current position towards target
      var ease = d3.easeCubic
      var timer = d3.timer(elapsed => {
        var t = Math.min(1, ease(elapsed / 400))
        this.state.transition = t // global transition amount
        for (var i = 0, n = this.dimensionYData.values.length; i < n; i++) {
          var yValue = this.dimensionYData.values[i]
          if (yValue.py > 0) {
            yValue.y = (yValue.ty - yValue.py) * t + yValue.py
          }
          if (!yValue.expanded) {
            continue
          }
          for (var ii = 0, nn = yValue.itemIds.length; ii < nn; ii++) {
            var item = this.findItem(yValue.itemIds[ii])
            if (!item) {
              continue
            }
            if (!item.positions.hasOwnProperty(yValue.id)) {
              continue
            }

            var itemPositions = item.positions[yValue.id]
            for (var iii = 0, nnn = itemPositions.length; iii < nnn; iii++) {
              this.animatePosition(item.positions[yValue.id][iii], t)
            }
          }
        }
        this.draw()

        if (t === 1) {
          // finished transition
          this.state.transition = 1
          timer.stop()
          this.renderEnd()
        }
      })
    } else {
      this.renderEnd()
    }
    // uncomment  below to debug viewport size
    // const debugView = {
    //   start: fromUnixTime(this.viewport.start),
    //   end: fromUnixTime(this.viewport.end),
    //   currentStart: fromUnixTime(this.viewport.currentStart),
    //   currentEnd: fromUnixTime(this.viewport.currentEnd)
    // }
    // console.table(debugView)
  }

  private renderEnd(): void {
    // callback after the user has finished moving the timeline
    // useful hook for loading data
    this.cleanupDataPostTransition()

    this.draw()

    this.drawHotSpots()
    this.savePrevCounts()

    // var poscount = 0
    // for (var i=0; i < this.items.length; i++) {
    //   var yValues = Object.keys(this.items[i].positions)
    //   for (var ii=0; ii < yValues.length; ii++) {
    //     var xValues = this.items[i].positions[yValues[ii]]
    //     for (var iii=0; iii < xValues.length; iii++) {
    //       poscount ++
    //     }
    //   }
    // }
    // console.log('poscount', poscount)

    if (this.state.performanceData.enabled) {
      var sum = this.state.performanceData.frameTimes.reduce(function (a, b) {
        return a + b
      }, 0)
      console.log('avg frame time', sum / this.state.performanceData.frameTimes.length)
    }

    if (this.onRescaleEnd) {
      this.onRescaleEnd({
        startDate: this.viewport.currentStart,
        endDate: this.viewport.currentEnd
      })
    }
  }

  private _clear(contextName: string): void {
    if (contextName === 'main') {
      this.context.clearRect(
        this.viewport.currentLeft,
        this.viewport.currentTop,
        this.viewport.currentWidth,
        this.viewport.currentHeight
      )
    } else if (contextName === 'shadow') {
      this.shadowContext.clearRect(
        this.viewport.currentLeft,
        this.viewport.currentTop,
        this.viewport.currentWidth,
        this.viewport.currentHeight
      )
    } else {
      this.context.clearRect(
        this.viewport.currentLeft,
        this.viewport.currentTop,
        this.viewport.currentWidth,
        this.viewport.currentHeight
      )
      this.shadowContext.clearRect(
        this.viewport.currentLeft,
        this.viewport.currentTop,
        this.viewport.currentWidth,
        this.viewport.currentHeight
      )
    }
  }

  // Draws a single frame of everything
  private draw(): void {
    var functionStartTime
    if (this.state.performanceData.enabled) {
      functionStartTime = performance.now()
    }

    // clear main canvas before starting frame
    this._clear('main')

    // Background layer - axis markers etc
    this.drawXAxisBackground()

    // Content layer - data, groups & links
    this.drawContent()

    // Top layer - fixed position labels/controls
    this.drawXAxisFooter(false)
    this.drawXFocus()
    this.drawXAxisHeader()

    // Temporary user-initiated elements
    this.drawSelection()
    this.drawTempLink() // Time only
    this.drawHoveredItemLabel()

    if (this.state.performanceData.enabled) {
      this.state.performanceData.frameTimes.push(performance.now() - functionStartTime)
      this.state.performanceData.segments.push({ name: 'draw', time: performance.now() - functionStartTime })
    }
  }

  private drawXFocus(): void {
    if (this.selectedStartDate && this.selectedEndDate) {
      var x1 = this.viewport.currentLeft
      var w1 = this.dimensionXData.scaleX(this.selectedStartDate, this.viewport) - x1
      var x2 = this.dimensionXData.scaleX(this.selectedEndDate, this.viewport)
      var w2 = this.viewport.currentRight - x2
      var h = this.viewport.currentHeight
      var y = this.viewport.currentTop
      this.drawRect(x1, y, w1, h, 'rgb(255,255,255)', 0.65)
      this.drawRect(x2, y, w2, h, 'rgb(255,255,255)', 0.65)
    }
  }

  // Only applies when xAxis is time
  private drawVerticals(isShadow: boolean = false): void {
    if (this.dimensionXData.dataType !== 'time') {
      return
    }
    var baseOpacity = 0.1
    var y = this.viewport.currentTop + this.config.headerHeight / this.state.transform.k
    var bottomMargin = (this.config.summaryItemHeight + this.config.padding * 2) / this.state.transform.k
    var height = this.viewport.currentBottom - bottomMargin - y
    for (var i = 0, n = this.items.length; i < n; i++) {
      if (this.items[i].verticalFillColor && this.dimensionXData.inBounds(this.items[i], this.viewport)) {
        var item = this.items[i]
        var x = this.dimensionXData.scaleX(item.xValues[0], this.viewport)
        if (isShadow) {
          this.drawHotSpot('node', item.id, x, y, item.width, this.config.baseSize)
        } else {
          var opacity = this.isHovered('node', item.id) ? 0.18 : 0.12
          if (this.itemsRemoved.indexOf(item.id) !== -1) {
            opacity = baseOpacity * (1 - this.state.transition)
          } else if (this.itemsAdded.indexOf(item.id) !== -1) {
            opacity = baseOpacity * this.state.transition
          }

          this.drawRoundedRect(x, y, item.width, height, this.config.baseSize / 5, item.verticalFillColor, opacity)
        }
      }
    }
  }

  private drawXAxisHotspots(): void {
    var spacing = 8 / this.state.transform.k
    for (var i = 0, n = this.dimensionXData.values.length; i < n; i++) {
      this.drawHotSpot(
        'xValue',
        this.dimensionXData.values[i].id,
        this.dimensionXData.scaleX(this.dimensionXData.values[i].id, this.viewport),
        this.viewport.currentTop + spacing * 3,
        this.dimensionXData.values[i].width,
        this.config.baseSize / this.state.transform.k
      )
    }
  }

  private drawXAxisBackground(): void {
    // this.drawRect(this.viewport.currentLeft, this.viewport.currentTop, this.viewport.currentWidth, this.viewport.currentHeight, 'rgb(255,255,255)')
    if (this.dimensionXData.dataType === 'time') {
      // draw primary divisions & shade where necesary
      var currentScale = this.dimensionXData.scales[this.dimensionXData.currentScaleIndex]

      for (var i = 0, n = this.dimensionXData.values.length; i < n; i++) {
        var xValue = this.dimensionXData.values[i]
        xValue.x = this.dimensionXData.scaleX(xValue.id, this.viewport)

        if (currentScale.showWeekend && [6, 7].indexOf(Number(this.dimensionXData.format(xValue.id, 'd'))) !== -1) {
          this.drawRect(
            xValue.x,
            this.viewport.currentTop,
            xValue.width,
            this.viewport.currentHeight,
            this.config.style.textColor,
            0.05
          )
        }
        this.drawLine(
          xValue.x,
          this.viewport.currentTop,
          xValue.x,
          this.viewport.currentBottom,
          this.config.style.textColor,
          0.08
        )
      }

      // draw secondary divisions
      for (i = 0, n = this.dimensionXData.secondaryValues.length; i < n; i++) {
        this.dimensionXData.secondaryValues[i].x = this.dimensionXData.scaleX(
          this.dimensionXData.secondaryValues[i].id,
          this.viewport
        )
        this.drawLine(
          this.dimensionXData.secondaryValues[i].x,
          this.viewport.currentTop,
          this.dimensionXData.secondaryValues[i].x,
          this.viewport.currentBottom,
          this.config.style.textColor,
          0.12,
          false,
          2
        )
      }

      // draw change freeze indicators etc
      this.drawVerticals(false)

      // draw 'now' line
      var now = this.dimensionXData.scaleX(getUnixTime(new Date()), this.viewport)
      this.drawLine(
        now,
        this.viewport.currentTop + (this.config.baseSize * 3) / this.state.transform.k,
        now,
        this.viewport.currentBottom,
        this.config.style.primaryColor,
        0.5,
        true
      )
    } else {
      // for (var i=0, n = this.dimensionXData.values.length; i < n; i++) {
      //   var xValue = this.dimensionXData.values[i]
      //   xValue.x = this.dimensionXData.scaleX(xValue.id, this.viewport)
      //   this.drawLine(xValue.x, this.viewport.currentTop, xValue.x, this.viewport.currentBottom, this.config.style.textColor, 0.08)
      // }
    }

    // draw background shadows/blends
    var shadowWidth = 64 / this.state.transform.k
    var grd = this.context.createLinearGradient(
      this.viewport.currentLeft,
      this.viewport.currentTop,
      this.viewport.currentLeft + shadowWidth,
      this.viewport.currentTop
    )
    grd.addColorStop(0, 'rgba(255,255,255,1)')
    grd.addColorStop(1, 'rgba(255,255,255,0)')
    this.drawRect(this.viewport.currentLeft, this.viewport.currentTop, shadowWidth, this.viewport.currentHeight, grd)

    grd = this.context.createLinearGradient(
      this.viewport.currentRight,
      this.viewport.currentTop,
      this.viewport.currentRight - shadowWidth,
      this.viewport.currentTop
    )
    grd.addColorStop(0, 'rgba(255,255,255,1)')
    grd.addColorStop(1, 'rgba(255,255,255,0)')
    this.drawRect(
      this.viewport.currentRight - shadowWidth,
      this.viewport.currentTop,
      shadowWidth,
      this.viewport.currentHeight,
      grd
    )

    // var topPos = this.viewport.currentBottom-this.config.summaryItemHeight - this.config.footerHeight
    // grd = this.context.createLinearGradient(0, this.viewport.currentBottom, 0, topPos-this.config.summaryItemHeight)
    // grd.addColorStop(0, "rgba(255,255,255,1)")
    // grd.addColorStop(0.65, "rgba(255,255,255,1)")
    // grd.addColorStop(1, "rgba(255,255,255,0)")
    // this.drawRect(this.viewport.currentLeft, topPos-this.config.summaryItemHeight, this.viewport.currentWidth, this.config.summaryItemHeight+this.config.footerHeight+this.config.summaryItemHeight, grd)
  }

  private drawXAxisHeader(): void {
    var color = this.config.style.textColor
    var xCenter, opacity
    var spacing = 8 / this.state.transform.k
    var bgHeight = this.config.headerHeight / this.state.transform.k
    var grd = this.context.createLinearGradient(0, this.viewport.currentTop, 0, this.viewport.currentTop + bgHeight)
    grd.addColorStop(0, 'rgba(255,255,255,1)')
    grd.addColorStop(0.7, 'rgba(255,255,255,1)')
    grd.addColorStop(1, 'rgba(255,255,255,0)')
    this.drawRect(this.viewport.currentLeft, this.viewport.currentTop, this.viewport.currentWidth, bgHeight, grd)
    var i, n

    if (this.dimensionXData.dataType === 'time') {
      var currentScale = this.dimensionXData.scales[this.dimensionXData.currentScaleIndex]

      for (i = 0, n = this.dimensionXData.values.length; i < n; i++) {
        var xValue = this.dimensionXData.values[i]
        xCenter = xValue.x + xValue.width / 2
        opacity = 0.4

        if (
          this.isHovered('xValue', xValue.id) ||
          (this.selectedStartDate && xValue.id >= this.selectedStartDate && xValue.id < this.selectedEndDate)
        ) {
          opacity = 1
          // color = this.config.style.primaryColor
        }

        if (currentScale.minorLabelShow(xValue.id)) {
          this.drawText(
            this.dimensionXData.format(xValue.id, currentScale.minorLabelFormat),
            xCenter,
            this.viewport.currentTop + spacing * 3,
            'center',
            color,
            this.config.style.fontSizeSmall / this.state.transform.k,
            false,
            opacity
          )
        }
      }

      opacity = 0.4

      for (i = 0, n = this.dimensionXData.secondaryValues.length; i < n; i++) {
        var x = this.dimensionXData.secondaryValues[i].x
        var isLastItem = i === this.dimensionXData.secondaryValues.length - 1
        var text = this.dimensionXData.format(this.dimensionXData.secondaryValues[i].id, currentScale.majorLabelFormat)
        var textWidth = (this.context.measureText(text).width + this.config.padding / 2) / this.state.transform.k
        var draw = true
        var leftPos = this.viewport.currentLeft + this.config.padding / this.state.transform.k
        // may need to hide or adjust the first one
        // console.log(text, x, leftPos)
        if (x < leftPos) {
          draw = false
          x = leftPos
          if (
            this.dimensionXData.secondaryValues.length === 1 ||
            isLastItem ||
            leftPos + textWidth <
              this.dimensionXData.scaleX(this.dimensionXData.secondaryValues[i + 1].id, this.viewport)
          ) {
            draw = true
          }
        }

        if (draw) {
          if (currentScale.majorLabelShow(this.dimensionXData.secondaryValues[i].id)) {
            this.drawText(
              text,
              x,
              this.viewport.currentTop + spacing,
              'left',
              this.config.style.textColor,
              this.config.style.fontSizeSmall / this.state.transform.k,
              false,
              opacity
            )
          }
        }
      }

      this.drawTimeControls(false)
    } else {
      // for (var i=0, n = this.dimensionXData.values.length; i < n; i++) {
      //   var xValue = this.dimensionXData.values[i]
      //   var radius = 4 / this.state.transform.k
      //   var color = xValue.color
      //   var headerHeight = 40 / this.state.transform.k
      //   var w = xValue.width*0.9
      //   xCenter = xValue.x + xValue.width/2
      //   var xLeft = xValue.x + xValue.width*0.05
      //   var y = this.viewport.currentTop+this.config.padding/this.state.transform.k
      //   opacity = 0.6
      //   if (this.isHovered('xValue', xValue.id) || (this.selectedStartDate && xValue.id >= this.selectedStartDate && xValue.id < this.selectedEndDate)) {
      //     opacity = 1
      //   }
      //   this.drawRoundedRect(xLeft, y, w, headerHeight, radius, color, 0.1)
      //   this.drawText(xValue.name, xCenter, y+headerHeight/2, "center", this.config.style.textColor, this.config.style.fontSizeSmall/this.state.transform.k, false, opacity, false, w, 2, "middle")
      // }
    }
    // var topPos = this.viewport.currentBottom-this.config.summaryItemHeight - this.config.footerHeight
    // grd = this.context.createLinearGradient(0, this.viewport.currentBottom, 0, topPos-this.config.summaryItemHeight)
    // grd.addColorStop(0, "rgba(255,255,255,1)")
    // grd.addColorStop(0.65, "rgba(255,255,255,1)")
    // grd.addColorStop(1, "rgba(255,255,255,0)")
    // this.drawRect(this.viewport.currentLeft, topPos-this.config.summaryItemHeight, this.viewport.currentWidth, this.config.summaryItemHeight+this.config.footerHeight+this.config.summaryItemHeight, grd)
  }

  private drawTimeControls(isShadow: boolean = false): void {
    var labelWidth = 42 / this.state.transform.k
    var labelHeight = this.config.baseSize / this.state.transform.k

    var timeShortcutWidth = 32 / this.state.transform.k
    var timeShortcutsWidth = this.config.timeShortcuts.length * timeShortcutWidth
    var labelYPos = this.viewport.currentTop + (this.config.baseSize * 2) / this.state.transform.k
    var textYPos = labelYPos + 5 / this.state.transform.k
    var now = this.dimensionXData.scaleX(getUnixTime(new Date()), this.viewport)
    var x = now
    var opacity = 0.4
    var padding = this.config.padding / this.state.transform.k
    var arrowLeft = false,
      arrowRight = false,
      startPos = x
    if (now - padding - labelWidth / 2 < this.viewport.currentLeft) {
      x = this.viewport.currentLeft + padding + labelWidth / 2
      arrowLeft = true
    } else if (now + padding + labelWidth / 2 > this.viewport.currentRight) {
      x = this.viewport.currentRight - padding - labelWidth / 2
      arrowRight = true
    }

    // Show the timeshorts on the left or right of 'Now'?
    if (now + padding + labelWidth / 2 + timeShortcutsWidth > this.viewport.currentRight) {
      startPos = x - timeShortcutsWidth - labelWidth / 2
    } else {
      startPos = x + padding + labelWidth / 2
    }

    var i, n
    if (isShadow) {
      this.drawHotSpot('timeControl', 'NOW', x - labelWidth / 2, labelYPos, labelWidth, labelHeight)
      for (i = 0, n = this.config.timeShortcuts.length; i < n; i++) {
        this.drawHotSpot(
          'timeControl',
          this.config.timeShortcuts[i].id,
          startPos,
          labelYPos,
          timeShortcutWidth,
          labelHeight
        )
        startPos += timeShortcutWidth
      }
    } else {
      if (this.isHovered('timeControl', 'NOW')) {
        opacity = 0.7
      }
      var path = this.roundedRectPath(
        x - labelWidth / 2,
        labelYPos,
        labelWidth,
        labelHeight,
        labelHeight / 2,
        arrowLeft,
        arrowRight
      )
      this.drawPath(path, this.config.style.primaryColor, opacity)
      // this.drawRect(x-labelWidth/2, labelYPos, labelWidth, this.config.baseSize, this.config.style.primaryColor, opacity, true)
      this.drawText(
        'NOW',
        x,
        textYPos,
        'center',
        'rgb(255,255,255)',
        this.config.style.fontSizeXSmall / this.state.transform.k,
        false,
        1
      )

      for (i = 0, n = this.config.timeShortcuts.length; i < n; i++) {
        opacity = this.isHovered('timeControl', this.config.timeShortcuts[i].id) ? 0.7 : 0.4

        this.drawText(
          this.config.timeShortcuts[i].id,
          startPos,
          textYPos,
          'left',
          this.config.style.textColor,
          this.config.style.fontSizeSmall / this.state.transform.k,
          false,
          opacity
        )
        startPos += timeShortcutWidth
      }
    }
  }

  private drawXAxisFooter(isShadow: boolean = false): void {
    // draw the total day summary graph
    var bgHeight = (this.config.summaryItemHeight + this.config.padding * 2) / this.state.transform.k
    var summaryItemHeight = this.config.summaryItemHeight / this.state.transform.k
    var summaryItemTopPos =
      this.viewport.currentBottom - summaryItemHeight - this.config.padding / this.state.transform.k
    var topPos = this.viewport.currentBottom - bgHeight // - this.config.footerHeight

    // draw white background for content to fade under
    if (!isShadow) {
      var grd = this.context.createLinearGradient(0, this.viewport.currentBottom, 0, topPos)
      grd.addColorStop(0, 'rgba(255,255,255,1)')
      grd.addColorStop(0.75, 'rgba(255,255,255,1)')
      grd.addColorStop(1, 'rgba(255,255,255,0)')
      this.drawRect(this.viewport.currentLeft, topPos, this.viewport.currentWidth, bgHeight, grd)
    }

    for (var i = 0, n = this.dimensionXData.values.length; i < n; i++) {
      var xValue = this.dimensionXData.values[i]
      if (isShadow) {
        this.drawHotSpot(
          'xValue',
          xValue.id,
          this.dimensionXData.scaleX(xValue.id, this.viewport),
          summaryItemTopPos,
          xValue.width,
          summaryItemHeight
        )
      } else {
        this.drawSummaryItem(xValue)
      }
    }
  }

  private drawSelection(): void {
    if (!this.state.selecting) {
      return
    }
    var x = this.state.mouseDownCoords[0] / this.state.transform.k + this.viewport.currentLeft
    var y = this.state.mouseDownCoords[1] / this.state.transform.k + this.viewport.currentTop
    var xEnd = d3.event.offsetX / this.state.transform.k + this.viewport.currentLeft
    var yEnd = d3.event.offsetY / this.state.transform.k + this.viewport.currentTop
    var width = xEnd - x
    var height = yEnd - y
    var color = this.config.style.textColor
    var opacity = 0.2
    this.drawRect(x, y, width, height, color, opacity, false)
  }

  private drawTempLink(): void {
    if (this.dimensionXData.dataType !== 'time') {
      return
    }
    if (!this.state.mouseDownObject || !d3.event) {
      return
    }
    if (['createPredecessor', 'createSuccessor'].indexOf(this.state.mouseDownObject.type) === -1) {
      return
    }
    var linkSourceIds = this.selectedItemIds.length ? this.selectedItemIds : [this.state.mouseDownObject.id]

    for (var i = 0; i < linkSourceIds.length; i++) {
      var item = this.findItem(linkSourceIds[i])
      if (!item) {
        continue
      }
      var itemYValues = Object.keys(item.positions)
      var xEnd = d3.event.offsetX / this.state.transform.k + this.viewport.currentLeft
      var yEnd = d3.event.offsetY / this.state.transform.k + this.viewport.currentTop
      var x1, y1, x2, y2, color, ii

      // OBS-690 : disabling ability to link between runbooks via dots/circles on runbook item objects
      // if (this.state.mouseDownObject.type === 'createPredecessor') {
      //   for (ii = 0; ii < itemYValues.length; ii++) {
      //     x1 = xEnd
      //     y1 = yEnd
      //     x2 = item.positions[itemYValues[ii]][0].x
      //     y2 = item.positions[itemYValues[ii]][0].y + this.config.itemTotalHeight - this.config.itemHeight / 2
      //     color = x2 >= x1 ? this.config.style.textColor : this.config.style.errorColor
      //     this.drawCurve(x1, y1, x2, y2, color, 0.6, true)
      //   }
      // }

      // if (this.state.mouseDownObject.type === 'createSuccessor') {
      //   for (ii = 0; ii < itemYValues.length; ii++) {
      //     x1 = item.positions[itemYValues[ii]][0].x + item.positions[itemYValues[ii]][0].width
      //     y1 = item.positions[itemYValues[ii]][0].y + this.config.itemTotalHeight - this.config.itemHeight / 2
      //     x2 = xEnd
      //     y2 = yEnd
      //     color = x2 >= x1 ? this.config.style.textColor : this.config.style.errorColor
      //     this.drawCurve(x1, y1, x2, y2, color, 0.6, true)
      //   }
      // }
    }
  }

  private drawGroupHeader(yValue: AxisValue): void {
    var leftPos = this.viewport.currentLeft + this.config.padding / this.state.transform.k
    var rightPos = this.viewport.currentRight - this.config.padding / this.state.transform.k
    var yPos = yValue.y + this.config.padding / 3
    // this.drawRect(leftPos, yValue.y, rightPos-leftPos, bgHeight, "rgb(255,255,255)", 1, false)

    var opacity = !yValue.prevCount ? this.state.transition * 0.7 : 0.7

    var rightAdditionalText = null
    if (this.isHovered('yValue', yValue.id)) {
      opacity = 1
      rightAdditionalText = 'MAX: ' + yValue.maxItemCount
    }

    if (!yValue.totalCount) {
      // nothing to see here, fade it out
      opacity = opacity * (1 - this.state.transition)
    }

    // else if (yValue.prevMaxItemCount === 0) {
    //   // entering, fade in
    //   opacity = opacity * this.state.transition
    // }

    var icon = '\ue975'
    if (yValue.expanded) {
      icon = '\ue96f'
    }
    this.drawText(
      icon,
      leftPos + 2,
      yValue.y + 2,
      'left',
      this.config.style.textColor,
      this.config.baseSize,
      true,
      opacity / 2
    )

    this.drawText(
      yValue.name,
      leftPos + this.config.padding * 2.5,
      yPos,
      'left',
      this.config.style.textColor,
      this.config.style.fontSizeLg,
      false,
      opacity
    )

    // removes denominator content: '/' + yValue.totalCount which is not meaningful with additive data loading
    var rightText = yValue.count
    this.context.font = this.config.style.fontSizeSmall + 'px Inter' // so the measuretext is accurate
    var width = this.context.measureText(rightText).width + this.config.padding

    this.drawRect(rightPos - width, yPos, width, this.config.baseSize - 2, yValue.color, opacity, true)
    this.drawText(
      rightText,
      rightPos - this.config.padding / 2,
      yPos + 3,
      'right',
      'rgb(255,255,255)',
      this.config.style.fontSizeSmall,
      false,
      1
    )
    if (rightAdditionalText) {
      this.drawText(
        rightAdditionalText,
        rightPos - width - this.config.padding / 2,
        yPos + 3,
        'right',
        this.config.style.textColor,
        this.config.style.fontSizeSmall,
        false,
        0.5
      )
    }
  }

  private drawContent(isShadow: boolean = false): void {
    this.visibleItemIds = []
    var i, ii, n, nn, xValue
    for (i = 0, n = this.dimensionYData.values.length; i < n; i++) {
      var yValue = this.dimensionYData.values[i]
      if (!yValue.itemIds.length) {
        continue
      }
      var startHeight = yValue.y + this.config.groupHeaderHeight
      // TODO if not visible, continue
      if (isShadow && !yValue.isDummy) {
        this.shadowContext.font = this.config.baseSize + 'px Inter' // for measuring
        var headerWidth =
          this.shadowContext.measureText(yValue.name).width + (this.config.padding * 2) / this.state.transform.k
        this.drawHotSpot(
          'yValue',
          yValue.id,
          this.viewport.currentLeft,
          yValue.y,
          headerWidth,
          this.config.groupHeaderHeight
        )
        for (ii = 0, nn = this.dimensionXData.values.length; ii < nn; ii++) {
          xValue = this.dimensionXData.values[ii]
          this.drawHotSpot(
            'xValue',
            xValue.id,
            this.dimensionXData.scaleX(xValue.id, this.viewport),
            startHeight,
            xValue.width,
            this.config.summaryItemHeight
          )
        }
      } else if (!yValue.isDummy) {
        this.drawGroupHeader(yValue)
        for (ii = 0, nn = this.dimensionXData.values.length; ii < nn; ii++) {
          xValue = this.dimensionXData.values[ii]
          this.drawSummaryItem(xValue, yValue)
        }
      }

      if (yValue.expanded) {
        this.drawItems(yValue, isShadow)
      }
    }

    if (!isShadow && this.dimensionXData.dataType === 'time') {
      this.drawLinks()
    }
  }

  private drawLinks(): void {
    // TODO arrow end
    this.context.globalCompositeOperation = 'destination-over'
    for (var i = 0, n = this.links.length; i < n; i++) {
      if (
        this.visibleItemIds.indexOf(this.links[i].source.id) !== -1 ||
        this.visibleItemIds.indexOf(this.links[i].target.id) !== -1
      ) {
        // draw link if source OR target visible
        this.drawLink(this.links[i].source, this.links[i].target, this.links[i].isError)
      }
    }
    this.context.globalCompositeOperation = 'source-over'
  }

  private drawLink(source: any, target: any, isError: boolean = false): void {
    var sX = source.endX
    var sY = source.endY + this.config.itemTotalHeight - this.config.itemHeight / 2
    var tX = target.startX
    var tY = target.startY + this.config.itemTotalHeight - this.config.itemHeight / 2
    var color = isError ? this.config.style.errorColor : this.config.style.textColor
    var opacity = this.isHovered('node', source.id) || this.isHovered('node', target.id) ? 0.5 : 0.15
    opacity = this.state.transition * opacity
    this.drawCurve(sX, sY, tX, tY, color, opacity, isError)
  }

  private drawSummaryItem(xValue: XAxisValue, yValue: AxisValue = null): void {
    var x = xValue.x
    var xRight = x + xValue.width
    x = x + xValue.width * 0.05

    var width = xValue.width * 0.9
    var xCenter = x + width / 2
    var leftPos = this.viewport.currentLeft + this.config.padding / this.state.transform.k
    var rightPos = this.viewport.currentRight - this.config.padding / this.state.transform.k

    if (x < leftPos) {
      width = width - (leftPos - x)
      x = leftPos
    } else if (xRight > rightPos) {
      width = width - (xRight - rightPos)
    }
    var radius = this.config.baseSize / 5 / this.state.transform.k

    var y, yCenter, yTop, height, minUnitHeight, maxCount, prevMaxCount, maxItemHeight
    var count = 0
    var prevCount = 0

    if (yValue) {
      maxCount = this.dimensionYData.maxItemCount
      prevMaxCount = this.dimensionYData.prevMaxItemCount
      maxItemHeight = this.config.summaryItemHeight
      minUnitHeight = 4

      if (xValue.data.hasOwnProperty(yValue.id)) {
        count = xValue.data[yValue.id].count
        prevCount = xValue.data[yValue.id].prevCount
      }
      yTop = yValue.y + this.config.groupHeaderHeight
      yCenter = yTop + this.config.summaryItemHeight / 2
    } else {
      // day totals bar
      maxCount = this.dimensionXData.maxItemCount
      prevMaxCount = this.dimensionXData.prevMaxItemCount
      maxItemHeight = this.config.summaryItemHeight / this.state.transform.k
      minUnitHeight = 4 / this.state.transform.k
      count = xValue.count
      prevCount = xValue.prevCount
      yTop =
        this.viewport.currentBottom - (this.config.summaryItemHeight - this.config.padding * 2) / this.state.transform.k
      yCenter =
        this.viewport.currentBottom - (this.config.summaryItemHeight / 2 + this.config.padding) / this.state.transform.k
    }

    var unitHeight = maxItemHeight / maxCount
    var prevUnitHeight = maxItemHeight / prevMaxCount

    var newHeight = count === 0 ? Math.min(unitHeight, minUnitHeight) : (count / maxCount) * maxItemHeight
    var oldHeight =
      prevCount === 0 || prevMaxCount === 0
        ? Math.min(prevUnitHeight, minUnitHeight)
        : (prevCount / prevMaxCount) * maxItemHeight
    height = oldHeight + (newHeight - oldHeight) * this.state.transition

    y = yCenter - height / 2

    // Calculate color & opacity
    var color, opacity
    color = this.config.style.textColor

    if (count === 0) {
      opacity = 0.1
    } else {
      var oldOpacity = prevCount === 0 || prevMaxCount === 0 ? 0.1 : 0.3 + (prevCount / prevMaxCount) * 0.5
      var newOpacity = 0.3 + (count / maxCount) * 0.5
      opacity = oldOpacity + (newOpacity - oldOpacity) * this.state.transition

      if (yValue) {
        color = yValue.color
      }
    }

    var drawLabel = false
    if (count > 0 && this.isHovered('xValue', xValue.id)) {
      opacity = 1
      drawLabel = true
    }

    if (yValue && yValue.id === 0) {
      opacity = opacity / 2
    }

    this.drawRoundedRect(x, y, width, height, radius, color, opacity)
    if (drawLabel) {
      this.drawLabel(xCenter, y, String(count))
    }
  }

  private drawLabel(x: number, y: number, label: string): void {
    var fontSize = this.config.style.fontSizeXSmall / this.state.transform.k
    var radius = 4 / this.state.transform.k
    var arrowSize = radius * 2
    var width = (this.context.measureText(label).width + this.config.padding / 2) / this.state.transform.k
    width = Math.max(width, radius * 2 + arrowSize * 2.5)

    var height = fontSize * 1.4
    var left = x - width / 2
    y = y - height - this.config.padding * 0.6
    var color = this.config.style.textColor
    this.drawArrowRect(left, y, width, height, radius, color, 1, 'bottom', arrowSize)
    this.drawText(
      label,
      x,
      y + height / 2,
      'center',
      'rgb(255,255,255)',
      fontSize,
      false,
      1,
      false,
      null,
      null,
      'middle'
    )
  }

  private drawItems(yValue: AxisValue, isShadow: boolean = false): void {
    for (var i = 0, n = yValue.itemIds.length; i < n; i++) {
      var item = this.findItem(yValue.itemIds[i])
      if (
        !item ||
        (item.verticalFillColor && this.dimensionXData.dataType === 'time') ||
        !item.positions.hasOwnProperty(yValue.id)
      ) {
        continue
      }
      if (!item.positions.hasOwnProperty(yValue.id)) {
        continue
      }
      var itemPositions = item.positions[yValue.id]
      for (var ii = 0, nn = itemPositions.length; ii < nn; ii++) {
        var itemPosition = item.positions[yValue.id][ii]
        if (!this.isVisible(itemPosition.x, itemPosition.y, itemPosition.displayWidth, itemPosition.height)) {
          continue
        }
        if (isShadow) {
          this.drawItemHotspot(item, itemPosition)
        } else {
          this.visibleItemIds.push(item.id)
          this.drawItem(item, itemPosition)
        }
      }
    }
  }

  private drawItemHotspot(item: Item, itemPosition: ItemPosition): void {
    this.drawHotSpot(
      'node',
      item.id,
      itemPosition.x,
      itemPosition.y,
      item.displayWidth,
      itemPosition.height + this.config.itemHeight / 2
    )
    if (this.dimensionXData.dataType === 'time') {
      // draw link hotspots
      var x = itemPosition.x - this.config.itemHeight * 2
      var y = itemPosition.y + this.config.itemTotalHeight - this.config.itemHeight
      var x2 = itemPosition.x + itemPosition.width
      var w = this.config.itemHeight * 2
      var h = this.config.itemHeight

      this.drawHotSpot('createPredecessor', item.id, x, y - h / 2, w, h * 2)
      this.drawHotSpot('createSuccessor', item.id, x2, y - h / 2, w, h * 2)
    }
  }

  private drawItemLabel(item: Item, itemPosition: ItemPosition, showFull: boolean = false): void {
    var x = itemPosition.x // * this.state.transform.k
    var textX = item.statusColor ? x + this.config.itemHeight * 2 : x
    var opacity = this.calcItemOpacity(item, itemPosition.transition)
    var color = this.config.style.textColor
    var name = item.displayName
    // var fontSize = this.state.transform.k > 1 ? this.config.style.fontSizeSmall/this.state.transform.k : this.config.style.fontSizeSmall
    var fontSize = this.config.style.fontSizeSmall

    // var charsToShow = this.config.maxChars * Math.max(this.state.transform.k,1)
    // only contract if greater than itemwidth
    var charsToShow = (item.displayWidth - item.spotlightWidth) / 7.2 // avg width per char
    charsToShow = this.dimensionXData.dataType === 'time' ? charsToShow : charsToShow - 2
    if (name.length > charsToShow && !showFull) {
      // cant do the below as overlaps other events
      // if (this.isHovered('node', item.id)) {
      //   this.context.shadowColor = "rgba(255,255,255,1)"
      //   this.context.shadowBlur = 10

      // } else {
      name = name.substr(0, charsToShow) + '...'
      // }
    }

    if (item.statusColor) {
      var w = this.config.itemHeight * 1.5
      this.drawCircle(x + w / 2, itemPosition.y + 6, w, item.statusColor, opacity * 0.8)
    }

    // this.drawText(idString, x, itemPosition.y, "left", color, this.config.style.fontSizeSmall, false, opacity/1.6)
    var bold = item.shape === 'diamond' ? true : false
    this.drawText(name, textX, itemPosition.y, 'left', color, fontSize, false, opacity, bold)
    this.context.shadowBlur = 0

    if (item.spotlight) {
      var textScaleFactor = this.config.style.fontSizeSmall / this.config.style.fontSizeXSmall
      var nameWidth = this.context.measureText(name).width + this.config.padding / 2
      var xStart = textX + nameWidth
      for (var i = 0, n = item.spotlight.length; i < n; i++) {
        var spotlightWidth =
          this.context.measureText(item.spotlight[i].name).width / textScaleFactor + this.config.padding
        this.drawRoundedRect(
          xStart,
          itemPosition.y - 1,
          spotlightWidth,
          fontSize,
          this.config.style.fontSizeSmall / 2,
          item.spotlight[i].color ? item.spotlight[i].color : color,
          opacity
        )
        this.drawText(
          item.spotlight[i].name,
          xStart + this.config.padding / 2,
          itemPosition.y + 1,
          'left',
          'rgb(255,255,255)',
          fontSize / textScaleFactor,
          false,
          1
        )
        xStart += spotlightWidth + this.config.padding / 2
      }
    }
  }

  private drawHoveredItemLabel(): void {
    // note this is not ideal since we dont know what POSITION is being hovered, just which item
    // item could show in multiple groups. so need to draw label for each position
    if (this.state.transform.k <= 0.5 || !this.state.hoverObject) {
      return
    }
    if (['node', 'createPredecessor', 'createSuccessor'].indexOf(this.state.hoverObject.type) === -1) {
      return
    }
    var item = this.findItem(this.state.hoverObject.id)
    if (!item) {
      return
    }
    var fullTextWidth =
      this.context.measureText(item.displayName).width * this.state.transform.k + this.config.padding * 2
    // this.context.shadowColor = "rgba(255,255,255,1)"
    // this.context.shadowBlur = 5
    for (var yValueId in item.positions) {
      for (var i = 0, n = item.positions[yValueId].length; i < n; i++) {
        var itemPosition = item.positions[yValueId][i]
        this.drawRect(
          itemPosition.x,
          itemPosition.y - 1,
          fullTextWidth,
          this.config.style.fontSizeSmall + 2,
          'rgb(255,255,255)',
          0.7
        )
        this.drawItemLabel(item, itemPosition, true)
      }
    }
  }

  private drawItem(item: Item, itemPosition: ItemPosition): void {
    var color = item.color || 'rgb(80,226,230)' // TODO: HACK ALERT - fix once we have a strategy for runbook colors
    var opacity = this.calcItemOpacity(item, itemPosition.transition)
    var h =
      this.state.transform.k > 0.5
        ? this.config.itemHeight
        : Math.min(3, 0.5 / this.state.transform.k) * this.config.itemHeight
    var w = itemPosition.width
    var x = itemPosition.x
    var y = itemPosition.y + this.config.itemTotalHeight - h

    if (item.shape === 'diamond') {
      // make diamonds bigger
      var newH = h * 1.8
      // var offset =
      this.drawDiamond(x - (newH - h) / 2, y - (newH - h) / 2, newH / 2, color, opacity)
    } else {
      var strokeStyle = null,
        strokeWidth = null
      if (this.enableItemStages) {
        if (item.stage === 3 || this.state.transform.k <= 0.5) {
        } else if (item.stage === 2) {
          // faded bar, optionally with progress
          strokeWidth = 3
        } else if (item.stage === 1) {
          // empty bar, solid outline
          strokeWidth = 3
        } else {
          // empty bar, dotted outline
          strokeWidth = 3
          strokeStyle = 'dotted'
        }
      }

      this.drawRoundedRect(x, y, w, h, h / 2, color, opacity, strokeWidth, strokeStyle)
      // this.drawCircle(x, y, this.config.itemHeight*1.5, color, 0.6)
    }

    // If this node is hovered, dont draw it as we will draw it later abover everything else
    if (this.state.transform.k > 0.5 && !this.isHovered('node', item.id)) {
      this.drawItemLabel(item, itemPosition)
    }

    if (this.state.transform.k >= 1 && this.dimensionXData.dataType === 'time') {
      if (item.predsNotVisible.length) {
        this.drawCircle(
          x - this.config.itemHeight,
          y + this.config.itemHeight / 2,
          this.config.itemHeight,
          this.config.style.textColor,
          0.2
        )
      }
      if (item.succsNotVisible.length) {
        this.drawCircle(
          x + w + this.config.itemHeight,
          y + this.config.itemHeight / 2,
          this.config.itemHeight,
          this.config.style.textColor,
          0.2
        )
      }
    }

    if (this.isHovered('node', item.id) && this.dimensionXData.dataType === 'time') {
      var leftColor = this.config.style.textColor
      var rightColor = this.config.style.textColor
      var leftOpacity = 0.4
      var rightOpacity = 0.4

      if (this.isHovered('createPredecessor', item.id)) {
        leftColor = this.config.style.primaryColor
        leftOpacity = 1
      }
      if (this.isHovered('createSuccessor', item.id)) {
        rightColor = this.config.style.primaryColor
        rightOpacity = 1
      }

      // OBS-690 : disabling ability to link between runbooks via dots/circles on runbook item objects
      // this.drawCircle(
      //   x - this.config.itemHeight,
      //   y + this.config.itemHeight / 2,
      //   this.config.itemHeight,
      //   leftColor,
      //   leftOpacity
      // )
      // this.drawCircle(
      //   x + w + this.config.itemHeight,
      //   y + this.config.itemHeight / 2,
      //   this.config.itemHeight,
      //   rightColor,
      //   rightOpacity
      // )
    }
  }

  // draws all the clickable hotspots on the shadow canvas
  // runs at the end of a transition as dont need every frame
  private drawHotSpots(): void {
    this._clear('shadow')
    this.state.colorToObjMap = {}
    this.drawVerticals(true)
    this.drawContent(true)
    this.drawXAxisFooter(true)
    this.drawTimeControls(true)
    this.drawXAxisHotspots()
  }

  // draws an individual clickable hotspot on the shadow canvas
  private drawHotSpot(
    type: 'node' | 'xValue' | 'timeControl' | 'yValue' | 'createPredecessor' | 'createSuccessor',
    id: any,
    x: number,
    y: number,
    width: number,
    height: number
  ): void {
    var uniqueColor = this.genColor()
    this.shadowContext.fillStyle = uniqueColor
    this.shadowContext.strokeStyle = uniqueColor
    this.state.colorToObjMap[uniqueColor] = { type: type, id: id }
    this.shadowContext.beginPath()
    this.shadowContext.rect(x, y, width, height)
    this.shadowContext.fill()
  }

  //===============================================================
  //======================= DRAWING HELPERS =======================
  //===============================================================

  private isVisible(x: number, y: number, width: number, height: number): boolean {
    if (
      y > this.viewport.currentBottom ||
      x >= this.viewport.currentRight ||
      x + width <= this.viewport.currentLeft ||
      y + height <= this.viewport.currentTop
    ) {
      return false
    }
    return true
  }

  private isBeneath(
    itemLeft: number,
    itemTop: number,
    itemRight: number,
    itemBottom: number,
    areaLeft: number,
    areaTop: number,
    areaRight: number,
    areaBottom: number
  ): boolean {
    return !(areaLeft > itemRight || areaRight < itemLeft || areaTop > itemBottom || areaBottom < itemTop)
  }

  private itemSpotlightValue(item: any): any[] {
    if (this.spotlight && this.axisDefs.hasOwnProperty(this.spotlight) && item) {
      const axis = new Axis(this.axisDefs[this.spotlight], this.viewport, 'y')
      var attribute = this.axisDefs[this.spotlight].attribute
      var spotlightItem = this.axisDefs[this.spotlight].custom
        ? this.axisDefs[this.spotlight].find(item.custom_field_data[attribute])
        : axis.find(item[attribute])
      if (spotlightItem) {
        if (Array.isArray(spotlightItem)) {
          return spotlightItem.length > 0
            ? spotlightItem.map(i => {
                return { name: unescape(i.name || i.label), color: i.color }
              })
            : null
        } else {
          return [{ name: spotlightItem.name || spotlightItem.label, color: spotlightItem.color }]
        }
      }
    }
    return null
  }

  private calcItemOpacity(item: Item, transition: number = 1): number {
    var opacity
    var defaultOpacity = this.filterMode === 'highlight' ? 0.3 : 0.7
    if (this.selectedItemIds.length && this.selectedItemIds.indexOf(item.id) !== -1) {
      opacity = 1 // selected
    } else if (this.state.tempSelected.length && this.state.tempSelected.indexOf(item.id) !== -1) {
      opacity = 1 // temporarily selected, eg while dragging a selection area
    } else if (
      this.state.hoverObject &&
      this.state.hoverObject.type === 'node' &&
      this.state.hoverObject.id === item.id
    ) {
      // if (this.state.hoverObject.id === item.id || this.directlyLinkedToItem(item, this.state.hoverObject.id)) {
      opacity = 0.9
    } else if (this.state.selecting || this.selectedItemIds.length) {
      opacity = 0.4 // not using this yet
    } else if (this.filterMode === 'highlight' && item.filterHighlight) {
      opacity = 0.9
    } else {
      opacity = defaultOpacity
    }
    return opacity * transition
  }
  //==================================================================
  //======================= DRAWING PRIMITIVES =======================
  //=================================================================

  // NOTE: private function not called internally
  // private drawArrow(x: number, y: number, length: number, direction: string, color: string, opacity: number): void {
  //   this.context.fillStyle = opacity ? color.replace(')', ', ' + opacity + ')').replace('rgb', 'rgba') : color
  //   this.context.beginPath()
  //   this.context.moveTo(x - length, y)
  //   this.context.lineTo(x + length, y)
  //   this.context.lineTo(x, y + length)
  //   this.context.closePath()
  //   this.context.fill()
  // }

  private drawCircle(x: number, y: number, r: number, color: string, opacity: number): void {
    this.context.fillStyle = opacity ? color.replace(')', ', ' + opacity + ')').replace('rgb', 'rgba') : color
    this.context.strokeStyle = 'rgb(255,255,255)'
    this.context.setLineDash([])
    this.context.beginPath()
    this.context.arc(x, y, r / 2, 0, 2 * Math.PI, false)
    this.context.closePath()
    this.context.stroke()
    this.context.fill()
  }

  private drawLine(
    x1: number,
    y1: number,
    x2: number,
    y2: number,
    color: string,
    opacity: number,
    dashed: boolean = false,
    width: number = null
  ): void {
    this.context.strokeStyle = color.replace(')', ', ' + opacity + ')').replace('rgb', 'rgba')
    this.context.lineWidth = width ? width / this.state.transform.k : 1 / this.state.transform.k
    this.context.beginPath()
    this.context.moveTo(x1, y1)
    this.context.lineTo(x2, y2)
    this.context.setLineDash([])
    if (dashed) {
      this.context.setLineDash([10, 10])
    }
    this.context.stroke()
  }

  private drawDiamond(x: number, y: number, s: number, color: string, opacity: number): void {
    this.context.fillStyle = opacity ? color.replace(')', ', ' + opacity + ')').replace('rgb', 'rgba') : color
    this.context.beginPath()
    this.context.moveTo(x, y + s)
    this.context.lineTo(x + s, y)
    this.context.lineTo(x + s * 2, y + s)
    this.context.lineTo(x + s, y + s * 2)
    // if centered
    // this.context.moveTo(x-s,y);
    // this.context.lineTo(x,y-s);
    // this.context.lineTo(x+s,y);
    // this.context.lineTo(x,y+s);
    this.context.closePath()
    this.context.fill()
  }

  private drawCurve(
    x1: number,
    y1: number,
    x2: number,
    y2: number,
    color: string,
    opacity: number,
    dashed: boolean = false
  ): void {
    var yDist = 0 // (y2-y1) /3
    var xDist = x2 <= x1 ? 20 : (x2 - x1) / 3

    var ctrlPoint1X = x1 + xDist
    var ctrlPoint1Y = y1 + yDist
    var ctrlPoint2X = x2 - xDist
    var ctrlPoint2Y = y2 - yDist
    this.context.lineWidth = 1.5 / this.state.transform.k

    this.context.strokeStyle = color.replace(')', ', ' + opacity + ')').replace('rgb', 'rgba')
    this.context.beginPath()
    this.context.moveTo(x1, y1)
    this.context.bezierCurveTo(ctrlPoint1X, ctrlPoint1Y, ctrlPoint2X, ctrlPoint2Y, x2, y2)
    this.context.setLineDash([])
    if (dashed) {
      this.context.setLineDash([8 / this.state.transform.k, 8 / this.state.transform.k])
    }
    this.context.stroke()
  }

  private drawRect(
    x: number,
    y: number,
    width: number,
    height: number,
    color: string,
    opacity: number = null,
    rounded: boolean = false
  ): void {
    this.context.fillStyle = opacity ? color.replace(')', ', ' + opacity + ')').replace('rgb', 'rgba') : color

    if (rounded) {
      // TODO remove
      this.roundRect(x, y, width, height, height / 2, this.state.transform.k)
    } else {
      this.context.fillRect(x, y, width, height)
    }
  }

  private drawArrowRect(
    x: number,
    y: number,
    width: number,
    height: number,
    radius: number,
    color: string,
    opacity: number = null,
    arrowPosition: string,
    arrowSize: number
  ): void {
    this.context.fillStyle = opacity ? color.replace(')', ', ' + opacity + ')').replace('rgb', 'rgba') : color
    this.roundRect(x, y, width, height, radius, arrowPosition, arrowSize)
  }

  private drawText(
    text: string,
    x: number,
    y: number,
    align: string,
    color: string,
    size: number = null,
    isIcon: boolean = false,
    opacity: number,
    bold: boolean = false,
    maxWidth: number = null,
    maxLines: number = null,
    vAlign: string = null
  ) {
    var fontSize = size ? size : this.config.baseSize
    var textSyle = bold ? 'bold ' : ''
    var fontFamily = isIcon ? 'icomoon' : 'Inter'
    opacity = opacity >= 0 ? opacity : 1
    this.context.font = textSyle + fontSize + 'px ' + fontFamily
    this.context.textBaseline = vAlign ? vAlign : 'top'
    this.context.fillStyle = color.replace(')', ', ' + opacity + ')').replace('rgb', 'rgba')
    this.context.textAlign = align
    this.context.fillText(text, x, y)
  }

  private roundRect(
    x: number,
    y: number,
    w: number,
    h: number,
    r: number,
    arrowPosition: string = null,
    arrowSize: number = null
  ) {
    if (w < 2 * r) {
      r = w / 2
    }
    if (h < 2 * r) {
      r = h / 2
    }
    this.context.beginPath()
    this.context.moveTo(x + r, y)
    // this.context.lineTo(x+w-r, y)
    this.context.arcTo(x + w, y, x + w, y + r, r)
    this.context.arcTo(x + w, y + h, x + w - r, y + h, r)
    if (arrowPosition === 'bottom') {
      var spaceAvail = w - 2 * r
      var arrowLength = 2 * arrowSize
      if (spaceAvail >= arrowLength) {
        // if arrow will fit
        this.context.lineTo(x + w / 2 + arrowSize, y + h)
        this.context.lineTo(x + w / 2, y + h + arrowSize)
        this.context.lineTo(x + w / 2 - arrowSize, y + h)
      }
    }
    this.context.arcTo(x, y + h, x, y + h - r, r)
    this.context.arcTo(x, y, x + r, y, r)
    this.context.closePath()
    this.context.fill()
  }

  private drawRoundedRect(
    x: number,
    y: number,
    w: number,
    h: number,
    r: number,
    color: string,
    opacity: number,
    strokeWidth: number = null,
    strokeStyle: string = null
  ) {
    if (w <= 0 || h <= 0) {
      return
    }
    if (strokeWidth) {
      // adjust coords to force an 'inner' stroke
      w = w - strokeWidth
      h = h - strokeWidth
      x = x + strokeWidth / 2
      y = y + strokeWidth / 2
    }
    if (w < 2 * r) {
      r = w / 2
    }
    if (h < 2 * r) {
      r = h / 2
    }
    color = opacity ? color.replace(')', ', ' + opacity + ')').replace('rgb', 'rgba') : color
    var path = this.roundedRectPath(x, y, w, h, r)
    var p = new Path2D(path)
    if (strokeWidth) {
      this.context.strokeStyle = color
      this.context.lineWidth = strokeWidth
      if (strokeStyle === 'dotted') {
        this.context.setLineDash([strokeWidth / 2, strokeWidth / 2])
      } else {
        this.context.setLineDash([])
      }
      this.context.stroke(p)
      this.context.fillStyle = 'rgb(255,255,255)'
      this.context.fill(p)
    } else {
      this.context.fillStyle = color
      this.context.fill(p)
    }
  }

  private drawPath(path: string, color: string, opacity: number): void {
    this.context.fillStyle = color.replace(')', ', ' + opacity + ')').replace('rgb', 'rgba')
    var p = new Path2D(path)
    this.context.fill(p)
  }

  //===============================================================
  //======================= DATA HELPERS ==========================
  //===============================================================

  private resetItemSort() {
    const orderedDataIds = this.data.map(d => d.id)

    const compareFn = (a, b) => {
      var dataIndexA = orderedDataIds.indexOf(a.id)
      var dataIndexB = orderedDataIds.indexOf(b.id)

      if (dataIndexA < dataIndexB) {
        return -1
      } else if (dataIndexA > dataIndexB) {
        return 1
      } else {
        return 0
      }
    }

    this.items.sort(compareFn)
  }

  private resetLookup(): void {
    this.resetItemSort()

    this.itemsLookup = {}
    for (var i = 0, n = this.items.length; i < n; i++) {
      this.itemsLookup[this.items[i].id] = i
    }
  }

  private rebuildItemIds(): void {
    this.resetItemSort()

    for (var i = 0, n = this.dimensionYData.values.length; i < n; i++) {
      this.dimensionYData.values[i].itemIds = []
    }
    for (i = 0, n = this.items.length; i < n; i++) {
      var item = this.items[i]
      for (var ii = 0; ii < item.yValues.length; ii++) {
        var yValue = this.dimensionYData.find(item.yValues[ii])
        if (yValue && yValue.itemIds.indexOf(item.id) === -1) {
          yValue.itemIds.push(item.id)
          // yValue.count ++
        }
      }
    }
  }

  private resetSelections(): void {
    // reset global selections
    this.itemsUpdated = []
    this.itemsAdded = []
    this.itemsRemoved = []
  }

  private resetPositions(): void {
    for (var i = 0, n = this.items.length; i < n; i++) {
      this.items[i].positions = {}
    }
  }

  // NOTE: private function not called internally
  // private resetValueItemData(): void {
  //   for (var i = 0, n = this.dimensionYData.values.length; i < n; i++) {
  //     this.dimensionYData.values[i].count = 0
  //   }
  // }

  private findItem(id: number): Item {
    return this.items[this.itemsLookup[id]]
  }

  private addItem(inputItem: any): void {
    this.itemsAdded.push(inputItem.id)
    var spotlightValue = this.itemSpotlightValue(inputItem)
    var item = new Item(inputItem, this.dimensionXData, this.dimensionYData, spotlightValue, this.viewport)
    var newIndex = this.items.push(item) - 1
    this.itemsLookup[item.id] = newIndex
  }

  private updateItem(inputItem: any): void {
    this.itemsUpdated.push(inputItem.id)
    var item = this.findItem(inputItem.id)
    if (item) {
      var spotlightValue = this.itemSpotlightValue(inputItem)
      item.update(inputItem, this.dimensionXData, this.dimensionYData, spotlightValue, this.viewport)
    }
  }

  // removes all exiting items & resets lookup
  // called at the end of a transition as removed items still
  // need to be drawn as they animate out
  private cleanupDataPostTransition(): void {
    var functionStartTime
    if (this.state.performanceData.enabled) {
      functionStartTime = performance.now()
    }
    var itemsRemoved = 0
    var yValuesContainingRemovedItems = []
    var i, ii, n, nn
    for (i = 0, n = this.items.length; i < n; i++) {
      if (itemsRemoved === this.itemsRemoved.length) {
        if (this.itemsRemoved.length > 0) {
          this.resetLookup()
        }
        break
      }
      if (this.itemsRemoved.indexOf(this.items[i].id) !== -1) {
        yValuesContainingRemovedItems = yValuesContainingRemovedItems.concat(this.items[i].yValues)
        this.items.splice(i, 1)
        itemsRemoved++
        i--
      }
    }

    // clean up removed itemIds
    for (i = 0, n = this.dimensionYData.values.length; i < n; i++) {
      if (yValuesContainingRemovedItems.indexOf(this.dimensionYData.values[i].id) === -1) {
        continue
      }
      for (ii = 0, nn = this.dimensionYData.values[i].itemIds.length; ii < nn; ii++) {
        if (this.itemsRemoved.indexOf(this.dimensionYData.values[i].itemIds[ii]) !== -1) {
          this.dimensionYData.values[i].itemIds.splice(ii, 1)
          ii--
        }
      }
    }

    // remove 'leaving' itemPositions
    for (i = 0, n = this.items.length; i < n; i++) {
      for (var yValueId in this.items[i].positions) {
        var remainingPositions = []
        for (ii = 0, nn = this.items[i].positions[yValueId].length; ii < nn; ii++) {
          if (!this.items[i].positions[yValueId][ii].leaving) {
            remainingPositions.push(this.items[i].positions[yValueId][ii])
          }
        }
        this.items[i].positions[yValueId] = remainingPositions
      }
    }

    this.resetSelections()
    if (this.state.performanceData.enabled) {
      this.state.performanceData.segments.push({ name: 'cleanup', time: performance.now() - functionStartTime })
    }
  }

  //===============================================================
  //======================= DATA PROCESSING =======================
  //===============================================================

  private handleChange(resetY: boolean = false, animate: boolean = true): void {
    var functionStartTime
    if (this.state.performanceData.enabled) {
      this.state.performanceData.segments = []
      functionStartTime = performance.now()
    }
    if (resetY) {
      this.resetY() // moves to top of viewport
    }
    this.updateData(this.data) // merges existing with new data
    this.summariseGroups() // generates all totals
    this.generateTargetPositions() // generates all positions
    this.render(animate) // draws, animating towards target pos

    if (this.state.performanceData.enabled) {
      this.state.performanceData.segments.push({ name: 'handleChange', time: performance.now() - functionStartTime })
      console.log('performance stats', this.state.performanceData.segments)
    }
  }

  // We could update existing data instead of re-adding all items,
  // but this would mean we'd have to re-sort to maintain original order
  // a true 'update' only necessary for objects storing coords for anim
  // here we can wipe & recreate the whole array & still track
  // added/updated/removed ids

  // Notes:
  // order of yValue.items DOES matter
  // Removed items stay here until transition complete
  private updateData(rawInputData: any[]): void {
    var functionStartTime
    if (this.state.performanceData.enabled) {
      functionStartTime = performance.now()
    }

    if (!rawInputData) {
      return
    }
    this.resetSelections()

    // Merge new data with existing
    this.itemsRemoved = Object.keys(this.itemsLookup).map(i => {
      return Number(i)
    })
    for (var i = 0, n = rawInputData.length; i < n; i++) {
      var index = this.itemsRemoved.indexOf(rawInputData[i].id)
      if (index !== -1) {
        this.itemsRemoved.splice(index, 1)
        this.updateItem(rawInputData[i])
      } else {
        this.addItem(rawInputData[i])
      }
    }

    // TODO below is hack until // TODO below
    this.items.sort((a, b) => {
      return a.xValues[0] - b.xValues[0]
    })
    this.resetLookup()
    this.rebuildItemIds()

    if (this.state.performanceData.enabled) {
      this.state.performanceData.segments.push({ name: 'updateData', time: performance.now() - functionStartTime })
    }
  }

  // saves the previous coords & sets target coords
  // note '0' key relates to 'no value set'
  private generateItemPositions(yValue: AxisValue, itemBaseHeight: number): number {
    var rows = []
    var rowMaxLeft: { value: number; index: number } | null = null
    var rowMinRight: { value: number; index: number } | null = null
    var selectedRowIndex,
      maxRows = 0,
      colMap = {},
      yValueItemPositions = [],
      existingYValueItemPositions = []
    var rowHeight =
      this.display === 'compact'
        ? this.config.itemHeight + this.config.padding * 0.75
        : this.config.itemTotalHeight + this.config.padding
    var i, n, item
    if (yValue.expanded) {
      for (i = 0, n = yValue.itemIds.length; i < n; i++) {
        item = this.findItem(yValue.itemIds[i])
        if (!item) {
          continue
        }
        if (this.itemsRemoved.indexOf(item.id) !== -1) {
          continue
        }
        if (item.verticalFillColor && this.dimensionXData.dataType === 'time') {
          continue
        }

        var itemPosition,
          xPosStart,
          xPosEnd,
          prevPositions = [],
          targetPositions = [],
          ii,
          nn,
          iii,
          nnn

        // if this item isn't current positioned for this yValue
        if (!item.positions.hasOwnProperty(yValue.id)) {
          item.positions[yValue.id] = []
        }

        // save prevPositions
        existingYValueItemPositions = item.positions[yValue.id]

        // clear
        item.positions[yValue.id] = [] // clear any others

        // add new ItemPositions
        if (this.dimensionXData.dataType === 'time') {
          var xValue = item.xValues[0] // start unix
          xPosStart = this.dimensionXData.scaleX(xValue, this.viewport)
          var xSpaceRequired = item.displayWidth + 20
          xPosEnd = xPosStart + xSpaceRequired

          // Work out target coords. Can only be one when xAxis = time
          rowMaxLeft = this.findMax(rows, 'minXPos') // best chance row for adding to the left (row starting with the largest x coord)
          rowMinRight = this.findMin(rows, 'maxXPos') // best chance row for adding to the right (row ending with the smallest x coord)

          const isFirstItem = !rowMaxLeft || !rowMinRight // can't actually have one without the other

          // first item or item isn't going to fit on an existing row
          if (isFirstItem || (xPosEnd >= rowMaxLeft.value && xPosStart <= rowMinRight.value)) {
            rows.push({ maxXPos: xPosStart + xSpaceRequired, minXPos: xPosStart })
            maxRows++
            selectedRowIndex = rows.length - 1
          }
          // there is space to add this and it places on the left
          else if (xPosEnd <= rowMaxLeft.value) {
            selectedRowIndex = rowMaxLeft.index
            rows[selectedRowIndex].minXPos = xPosStart
          }
          // there is space to add this and it places on the right
          else {
            selectedRowIndex = rowMinRight.index // now need to find new min
            rows[selectedRowIndex].maxXPos = xPosStart + xSpaceRequired
          }
          // single target position for a time axis
          targetPositions.push({ tx: xPosStart, ty: rowHeight * selectedRowIndex + itemBaseHeight })
        } else {
          // for (ii=0, nn = item.xValues.length; ii < nn; ii++) {
          //   var xValue = item.xValues[ii]
          //   var xPos = this.dimensionXData.scaleX(xValue, this.viewport) + item.width * 0.05
          //   if (colMap.hasOwnProperty(xValue)) {
          //     colMap[xValue] ++
          //   } else {
          //     colMap[xValue] = 0
          //   }
          //   if (colMap[xValue] > maxRows) {
          //     maxRows = colMap[xValue]
          //   }
          //   targetPositions.push({tx: xPos, ty: itemBaseHeight + rowHeight * colMap[xValue]})
          // }
        }

        // work out previou positions
        if (existingYValueItemPositions.length) {
          // item is already in this yValue, animate from previous position
          for (ii = 0, nn = existingYValueItemPositions.length; ii < nn; ii++) {
            prevPositions.push({
              px: existingYValueItemPositions[ii].x,
              py: existingYValueItemPositions[ii].y,
              entering: false,
              leaving: ii > 0
            })
          }
        } else {
          // item not yet in this yValue, animate from itemBaseHeight
          prevPositions.push({ px: xPosStart, py: itemBaseHeight, entering: true, leaving: false })
        }

        for (ii = 0, nn = targetPositions.length; ii < nn; ii++) {
          for (iii = 0, nnn = prevPositions.length; iii < nnn; iii++) {
            itemPosition = new ItemPosition(item, yValue)
            itemPosition.entering = prevPositions[iii].entering
            itemPosition.leaving = prevPositions[iii].leaving
            itemPosition.px = prevPositions[iii].px
            itemPosition.py = prevPositions[iii].py
            // x & y will be animated between px/py and tx/ty. set here in case not animated
            itemPosition.width = Math.max(item.width, this.config.itemHeight) // minimum 1px width
            itemPosition.height = this.config.itemTotalHeight
            itemPosition.tx = targetPositions[ii].tx
            itemPosition.ty = targetPositions[ii].ty
            itemPosition.x = itemPosition.tx
            itemPosition.y = itemPosition.ty
            item.positions[yValue.id].push(itemPosition)
          }
        }
      }
    } else {
      // this yValue is collapsed, so clear any ItemPositions
      for (i = 0, n = yValue.itemIds.length; i < n; i++) {
        item = this.findItem(yValue.itemIds[i])
        if (!item || !item.positions.hasOwnProperty(yValue.id)) {
          continue
        }
        item.positions[yValue.id] = []
      }
    }

    return itemBaseHeight + maxRows * rowHeight
  }

  // generates all target position data. 'render' then animates
  private generateTargetPositions() {
    var functionStartTime
    if (this.state.performanceData.enabled) {
      functionStartTime = performance.now()
    }

    // loop groups
    var currentHeight = this.config.headerHeight / this.state.transform.k

    for (var i = 0, n = this.dimensionYData.values.length; i < n; i++) {
      var yValue = this.dimensionYData.values[i]

      // BUG yValue still has itemIDs until END of transition
      if (!yValue.totalCount) {
        continue
      }
      // note - removed items still in itemIds until end of transition
      yValue.py = yValue.y
      yValue.ty = currentHeight
      yValue.y = yValue.ty

      var itemBaseHeight = yValue.isDummy
        ? currentHeight
        : yValue.y + this.config.groupHeaderHeight + this.config.summaryItemHeight + this.config.groupMarginBottom

      if (yValue.expanded) {
        // Loop through items in this group & calc pos
        currentHeight = this.generateItemPositions(yValue, itemBaseHeight)
      } else {
        currentHeight = itemBaseHeight
      }
    }

    this.viewport.maxY = currentHeight

    // generate the link data
    if (this.dimensionXData.dataType === 'time') {
      this.calcLinks()
    }

    if (this.state.performanceData.enabled) {
      this.state.performanceData.segments.push({
        name: 'generateTargetPositions',
        time: performance.now() - functionStartTime
      })
    }
  }

  // Only do this if xAxis is Time
  private calcLinks(): void {
    this.links = []
    if (!this.allowLinks) {
      return
    }
    for (var i = 0, n = this.dimensionYData.values.length; i < n; i++) {
      var yValue = this.dimensionYData.values[i]

      if (yValue.expanded) {
        for (var ii = 0, nn = yValue.itemIds.length; ii < nn; ii++) {
          var item = this.findItem(yValue.itemIds[ii])
          if (!item || this.itemsRemoved.indexOf(item.id) !== -1 || !item.positions.hasOwnProperty(yValue.id)) {
            continue
          }
          var itemPositions = item.positions[yValue.id]
          if (!itemPositions.length) {
            continue
          }
          var itemPosition = itemPositions[0] // only one for timeaxis
          if (itemPosition.leaving) {
            continue
          }
          item.predsNotVisible = []
          item.succsNotVisible = []

          // dont actually calc successors but need to work out if visible
          for (var s = 0; s < item.successorIds?.length || 0; s++) {
            var succ = this.findItem(item.successorIds[s]) // this.findItem(item.predecessorIds[p])
            if (!succ || this.itemsRemoved.indexOf(succ.id) !== -1) {
              item.succsNotVisible.push(item.successorIds[s])
            }
          }

          for (var p = 0; p < item.predecessorIds?.length || 0; p++) {
            var pred = this.findItem(item.predecessorIds[p]) // this.findItem(item.predecessorIds[p])
            if (!pred || this.itemsRemoved.indexOf(pred.id) !== -1) {
              item.predsNotVisible.push(item.predecessorIds[p])
              continue
            }

            // this predecessor could be in multiple positions. Store link to each
            for (var yValueId in pred.positions) {
              if (!pred.positions[yValueId].length) {
                continue
              }
              var predPosInThisYVal = pred.positions[yValueId][0]
              if (predPosInThisYVal.leaving) {
                continue
              }
              var endPos = predPosInThisYVal.x + predPosInThisYVal.width
              var isSourceEndAfterTgtStart = pred.xValues[1] > item.xValues[0]
              this.links.push({
                isError: isSourceEndAfterTgtStart,
                source: { id: pred.id, endX: endPos, endY: predPosInThisYVal.y },
                target: { id: item.id, startX: itemPosition.x, startY: itemPosition.y }
              })
            }
          }
        }
      }
    }
  }

  private savePrevCounts(): void {
    this.dimensionXData.prevMaxItemCount = this.dimensionXData.maxItemCount
    this.dimensionYData.prevMaxItemCount = this.dimensionYData.maxItemCount
    for (var i = 0, n = this.dimensionXData.values.length; i < n; i++) {
      var period = this.dimensionXData.values[i]
      period.prevCount = period.count
      for (var ii = 0, nn = this.dimensionYData.values.length; ii < nn; ii++) {
        var yValue = this.dimensionYData.values[ii]
        yValue.prevMaxItemCount = yValue.maxItemCount
        yValue.prevCount = yValue.count

        if (this.dimensionXData.values[i].data.hasOwnProperty(yValue.id)) {
          period.data[yValue.id].prevCount = period.data[yValue.id].count
        }
      }
    }
  }

  private resetCounts(): void {
    this.dimensionXData.maxItemCount = 0
    this.dimensionYData.maxItemCount = 0
    var i, ii, n, nn, yValue
    for (ii = 0, nn = this.dimensionYData.values.length; ii < nn; ii++) {
      yValue = this.dimensionYData.values[ii]
      yValue.maxItemCount = 0
      yValue.count = 0
      yValue.totalCount = 0
    }
    for (i = 0, n = this.dimensionXData.values.length; i < n; i++) {
      var xValue = this.dimensionXData.values[i]
      xValue.count = 0
      for (ii = 0, nn = this.dimensionYData.values.length; ii < nn; ii++) {
        yValue = this.dimensionYData.values[ii]
        if (xValue.data.hasOwnProperty(yValue.id)) {
          // already have a summary count for this date/yVal
          xValue.data[yValue.id].count = 0
        }
      }
    }
  }

  // Generates all the counts for the groupings
  private summariseGroups(): void {
    var functionStartTime
    if (this.state.performanceData.enabled) {
      functionStartTime = performance.now()
    }

    this.resetCounts()

    for (var i = 0, n = this.items.length; i < n; i++) {
      var item = this.items[i]
      var yValue
      var itemInBounds = false

      // ignore if outside viewport date bounds or marked for removal or vertical
      if (this.itemsRemoved.indexOf(item.id) !== -1) {
        continue
      }
      if (this.dimensionXData.inBounds(item, this.viewport)) {
        itemInBounds = true
      }

      // loop through the item yValues & increment total count for that yValue
      // Note this count only includes items in the visible date range
      for (var iii = 0, nnn = item.yValues.length; iii < nnn; iii++) {
        yValue = this.dimensionYData.find(item.yValues[iii])
        if (!yValue) {
          continue
        }
        if (itemInBounds) {
          yValue.count++
        }
        yValue.totalCount++
      }

      if (!itemInBounds) {
        continue
      }
      if (item.verticalFillColor) {
        continue
      }

      if (this.dimensionXData.dataType === 'time') {
        var itemStart = item.xValues[0]
        var itemEnd = item.xValues[1]
        var tracking = false
        for (var ii = 0, nn = this.dimensionXData.values.length; ii < nn; ii++) {
          var period = this.dimensionXData.values[ii]
          var nextPeriod = this.dimensionXData.values[ii + 1]

          if (
            !tracking &&
            (itemStart < period.id || (itemStart >= period.id && nextPeriod && itemStart < nextPeriod.id))
          ) {
            tracking = true
          }

          if (tracking) {
            // We're looking at a {period} which this {item} crosses
            // Increment count for each yValue this item is in
            // Eg, the same event could have 2+ 'service offerings'
            for (iii = 0, nnn = item.yValues.length; iii < nnn; iii++) {
              // make sure this yValue (eg an service offering ID) exists
              yValue = this.dimensionYData.find(item.yValues[iii])
              if (!yValue) {
                continue
              }

              // This item crosses this period (xValue) in this group (yValue). increment count
              if (!period.data.hasOwnProperty(yValue.id)) {
                period.data[yValue.id] = { count: 0, prevCount: 0 }
              }
              period.data[yValue.id].count++

              // Increment max yValue count, if exceeded
              if (period.data[yValue.id].count > yValue.maxItemCount) {
                yValue.maxItemCount = period.data[yValue.id].count
              }

              // increment max total count, if exceeded (used to calc unitHeight)
              if (period.data[yValue.id].count > this.dimensionYData.maxItemCount) {
                this.dimensionYData.maxItemCount = period.data[yValue.id].count
              }
            }

            // if not in any yValue (group), put in default group
            // if (touchesYValue) {
            // }

            // also increment count of xaxisitem itself to get totals for period
            period.count++
            if (period.count > this.dimensionXData.maxItemCount) {
              this.dimensionXData.maxItemCount = period.count
            }
          }

          if (nextPeriod && itemEnd < nextPeriod.id) {
            break
          }
        }
      } else {
        // for (var ii=0, nn = item.xValues.length; ii < nn; ii++) {
        //   var xValue = this.dimensionXData.find(item.xValues[ii])
        //   if (!xValue) { continue }
        //   xValue.count ++
        //   if (xValue.count > this.dimensionXData.maxItemCount) { this.dimensionXData.maxItemCount = xValue.count }
        //   for (var iii=0, nnn = item.yValues.length; iii < nnn; iii++) {
        //     var yValue = this.dimensionYData.find(item.yValues[iii])
        //     if (!yValue) { continue }
        //     yValue.count ++
        //     if (!xValue.data.hasOwnProperty(yValue.id)) {
        //       xValue.data[yValue.id] = {count: 0, prevCount: 0}
        //     }
        //     xValue.data[yValue.id].count ++
        //     if (xValue.data[yValue.id].count > yValue.maxItemCount) {
        //       yValue.maxItemCount = xValue.data[yValue.id].count
        //     }
        //     if (xValue.data[yValue.id].count > this.dimensionYData.maxItemCount) {
        //       this.dimensionYData.maxItemCount = xValue.data[yValue.id].count
        //     }
        //   }
        // }
      }
    }

    if (this.state.performanceData.enabled) {
      this.state.performanceData.segments.push({ name: 'summariseGroups', time: performance.now() - functionStartTime })
    }
  }
}
