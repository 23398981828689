import { memo, useCallback } from 'react'
import { sortBy } from 'lodash'

import { CheckboxGroup } from '@cutover/react-ui'
import { FilterAccordionPanel } from '../shared/filter-accordion-panel'
import { useLanguage } from 'main/services/hooks'
import { useRunbookComponentFilter, useRunbookComponentsSnippets } from 'main/recoil/data-access'

export const SnippetsGroup = memo(() => {
  const { t } = useLanguage('common', { keyPrefix: 'filter.runbook' })
  const runbookComponentSnippets = useRunbookComponentsSnippets()
  const [snippets, setSnippets] = useRunbookComponentFilter()

  const handleChange = useCallback(
    (event: any) => event && setSnippets(event.value as unknown as number[]),
    [setSnippets]
  )

  return (
    <FilterAccordionPanel label={t('snippets')} applied={!!snippets?.length}>
      <CheckboxGroup
        plain
        value={snippets || []}
        a11yTitle={t('snippets')}
        onChange={handleChange}
        options={sortBy(runbookComponentSnippets, 'name').map(snippet => ({
          value: snippet.internal_id,
          label: snippet.name,
          key: snippet.id
        }))}
      />
    </FilterAccordionPanel>
  )
})
