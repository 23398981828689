import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Attachment = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Attachment" {...props}>
    <path d="M10.918 25.664a8.25 8.25 0 0 0 11.668 11.667L35.49 24.427l2.122 2.121-12.905 12.905c-4.393 4.393-11.517 4.393-15.91 0-4.393-4.394-4.393-11.517 0-15.91L25.591 6.749a8 8 0 0 1 11.313 11.314L20.111 34.857a4.75 4.75 0 1 1-6.718-6.718l12.905-12.905 2.121 2.122L15.514 30.26a1.75 1.75 0 0 0 2.475 2.475l16.794-16.794a5 5 0 0 0-7.07-7.07L10.917 25.663Z" fill="#000" />
  </StyledIcon>
))

Attachment.displayName = 'Attachment'

export { Attachment }
