import { ReactNode } from 'react'

import { ReactAngularConnector } from 'main/connectors/react-angular-connector'

// Using children here vs putting in the content directly because the content component is
// written in old react and we can avoid importing it into the main directory.
export const RunbookUsersPageConnector = ({ children }: { children: ReactNode }) => {
  return (
    <ReactAngularConnector mountEvent="show-runbook-users" unmountEvent="hide-runbook-users">
      {children}
    </ReactAngularConnector>
  )
}
