import { useEffect, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { toSnakeCase } from '@cutover/api'
import { Box, EditPanel, IconButton, Message, useNotify } from '@cutover/react-ui'
import { IntegrationSettingForm } from './integration-setting-form'
import { buildDefaultValues, buildRequestData, buildValidationSchema } from './integration-setting-form-helper'
import { IntegrationSettingFormType } from './integration-setting-types'
import { IntegrationActionItemList } from '../integration-action-item-list'
import { IntegrationArchiveModal } from '../shared-integration-components/integration-archive-modal'
import { ApiError } from 'main/services/api/http-gateway-adapter'
import { useLanguage } from 'main/services/hooks'
import { IntegrationActionItem, IntegrationSetting } from 'main/services/queries/types'
import { useIntegrationActionItemsQuery } from 'main/services/queries/use-integration-action-item'
import {
  useIntegrationSettingArchive,
  useIntegrationSettingsUpdate
} from 'main/services/queries/use-integration-settings'

export type EditIntegrationSettingPanelProps = {
  integrationSetting: IntegrationSetting
  onSelectActionItem: (actionItem: IntegrationActionItem) => void
  onClose: () => void
}

export const EditIntegrationSettingPanel = ({
  integrationSetting,
  onSelectActionItem,
  onClose
}: EditIntegrationSettingPanelProps) => {
  const { t } = useLanguage('integrationSettings')
  const [apiError, setApiError] = useState<string | undefined>(undefined)
  const navigate = useNavigate()
  const [panelTitle, setPanelTitle] = useState('')
  const [isArchiveModalOpen, setArchiveModalOpen] = useState(false)

  const { isLoading, data: allActionItems } = useIntegrationActionItemsQuery()
  const mutation = useIntegrationSettingsUpdate(integrationSetting.id)
  const archiveSettingMutation = useIntegrationSettingArchive(integrationSetting.id)
  const defaultValues = buildDefaultValues(integrationSetting)
  const validationSchema = buildValidationSchema(integrationSetting, t)

  const integrationActionItems = allActionItems?.filter(actionItem => {
    return actionItem.integration_setting.id === integrationSetting.id && !actionItem.archived
  })

  const methods = useForm<IntegrationSettingFormType>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues
  })

  const { reset, formState } = methods
  const { dirtyFields } = formState
  const notify = useNotify()

  const submitForm = async (values: IntegrationSettingFormType) => {
    const data = buildRequestData(values, integrationSetting, dirtyFields)

    mutation.mutate(toSnakeCase(data), {
      onSuccess: () => {
        setPanelTitle(t('editPanelTitle', { title: data.name }))
        notify.success(t('form.updatedConnection.success'))
      },
      onError: error => {
        const castedError = error as unknown as ApiError
        if (castedError?.errors) {
          setApiError(castedError?.errors.join(', '))
        }
        notify.error(t('form.updatedConnection.error'))
      }
    })

    reset(values)
  }

  const handleSubmit = () => {
    methods.handleSubmit(submitForm)()
  }

  const handleArchive = async () => {
    archiveSettingMutation
      .mutateAsync()
      .then(() => {
        notify.success(t('archiveToaster.connectionConfirmation'))
        navigate('../integration_connections')
      })
      .catch(() => {
        notify.error(t('archiveToaster.connectionError'))
      })
  }

  function initForm() {
    reset(defaultValues)
  }

  useEffect(() => {
    initForm()
    setPanelTitle(t('editPanelTitle', { title: integrationSetting.name }))
  }, [integrationSetting])

  useEffect(() => {
    setApiError(undefined)
  }, [integrationSetting.id])

  return (
    <EditPanel
      title={panelTitle}
      isDirty={formState.isDirty}
      onSubmit={handleSubmit}
      onReset={initForm}
      onClose={onClose}
      headerItems={[
        <IconButton label="Archive" tipPlacement="top" icon="trash-o" onClick={() => setArchiveModalOpen(true)} />
      ]}
      isSubmitting={formState.isSubmitting}
    >
      {integrationSetting?.usage && (
        <Box margin={{ bottom: '16px' }}>
          <Message type="info" message={integrationSetting.usage} />
        </Box>
      )}
      <FormProvider {...methods}>
        <IntegrationSettingForm integration={integrationSetting} apiError={apiError} />
      </FormProvider>
      <IntegrationActionItemList
        isLoading={isLoading}
        actionItems={integrationActionItems}
        integrationSetting={integrationSetting}
        onSelectActionItem={onSelectActionItem}
      />
      <IntegrationArchiveModal
        isArchiveModalOpen={isArchiveModalOpen}
        onArchiveModalClose={() => setArchiveModalOpen(false)}
        onArchiveModalConfirm={handleArchive}
        title={integrationSetting.name}
        actionItems={integrationActionItems}
        isConnection
      />
    </EditPanel>
  )
}
