import { useEffect, useLayoutEffect, useState } from 'react'
import { differenceInDays, intervalToDuration } from 'date-fns'
import styled from 'styled-components'

import { Box, elapsedDays, Text, zeroPad } from '@cutover/react-ui'
import { useAccountMetaProperty } from 'main/recoil/data-access'
import { useLanguage } from 'main/services/hooks'
import { RunbookVersion } from 'main/services/queries/types'
import { ActiveRunbookVersionModel } from 'main/data-access'

const SECOND_DELAY = 1000
export const RunbookElapsedTime = () => {
  const { stage: runbookStage, duration_display: durationDisplay } = ActiveRunbookVersionModel.useGet()

  switch (runbookStage) {
    case 'planning':
      return <Timer stage={runbookStage} time={elapsedDays(durationDisplay as number, 'seconds')} />
    case 'paused':
    case 'complete':
      return <Timer stage={runbookStage} />
    case 'cancelled':
      return <></>
    default:
      return <RunbookElapsedTimeInner />
  }
}

const RunbookElapsedTimeInner = () => {
  const { stage: runbookStage, start_actual } = ActiveRunbookVersionModel.useGet()

  const [tabVisible, setTabVisible] = useState<boolean>(true)
  const headers = useAccountMetaProperty({ attribute: 'headers' })
  const requestOffset = headers.request_offset

  const timeInMillis = Math.floor(new Date().getTime()) + requestOffset
  const startActual = start_actual !== null ? start_actual * 1000 : 0
  // this is to mimic angular behavior.  Timer adjusts and re-appears after swithcing tabs
  const onVisibilityChange = () => {
    if (document.visibilityState === 'visible') {
      setTimeout(() => {
        setTabVisible(true)
      }, SECOND_DELAY)
    } else {
      setTabVisible(false)
    }
  }

  useLayoutEffect(() => {
    document.addEventListener('visibilitychange', onVisibilityChange)

    return () => document.removeEventListener('visibilitychange', onVisibilityChange)
  }, [])
  const [elapsedTime, setElapsedTime] = useState(
    intervalToDuration({
      start: 0,
      end: timeInMillis - startActual
    })
  )

  const days = differenceInDays(new Date(timeInMillis), new Date(startActual))

  // TODO: In Dynamic runbooks, may need to update timer based on end_forecast (Phase 2 Runbook migration)
  useEffect(() => {
    const interval = setInterval(() => {
      setElapsedTime(
        intervalToDuration({
          start: 0,
          end: timeInMillis - startActual
        })
      )
    }, SECOND_DELAY)
    return () => {
      clearInterval(interval)
    }
  }, [elapsedTime])

  return tabVisible ? (
    <Timer
      stage={runbookStage}
      time={`${days}d ${zeroPad(elapsedTime.hours)}:${zeroPad(elapsedTime.minutes)}:${zeroPad(elapsedTime.seconds)}`}
    />
  ) : (
    <Timer />
  )
}

const Timer = ({ stage, time }: { stage?: RunbookVersion['stage']; time?: string }) => {
  const { t } = useLanguage('pageHeader', { keyPrefix: 'runbook' })

  return (
    <RunbookElapsedTimeContainer data-testid="elapsed-time-container">
      <LabelText color="text-light" size="xsmall" textAlign="end">
        {stage === 'planning' ? t('duration') : stage === 'complete' || stage === 'paused' ? '' : t('elapsedTime')}
      </LabelText>
      <TimerTextContainer>
        <TimerText color="text-light" size="24px">
          {time ? time : stage ? t(stage) : ''}
        </TimerText>
      </TimerTextContainer>
    </RunbookElapsedTimeContainer>
  )
}

const TimerText = styled(Text)`
  white-space: nowrap;
`
const TimerTextContainer = styled(Box)`
  display: inline-block;
  font-feature-settings: 'tnum'; // so that each digit takes up the same amount of space
`
const LabelText = styled(Text)`
  display: none;
  position: absolute;
  top: 6px;
  padding-right: 8px;
`
const RunbookElapsedTimeContainer = styled(Box)`
  display: flex;
  align-items: flex-end;
  cursor: default;
  &:hover {
    ${LabelText} {
      display: block;
    }
  }
`
