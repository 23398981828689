// @ts-nocheck
import { observer } from 'mobx-react-lite'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import styled from 'styled-components'
import { px2rem, colors } from 'app/Styles/Variables'
import { DragDropPresenter } from './DragDropPresenter'
import { Draggable } from './Draggable'
import { DraggablePresenter } from './DraggablePresenter'

interface IDragDrop {
  presenter: DragDropPresenter
  disabled?: boolean
  draggables: DraggablePresenter[]
}

const DraggableWrapper = styled.div`
  padding-top: ${px2rem(4)};
  border-bottom: ${px2rem(1)} dashed ${colors.primaryGreyHoverBackgroundColor};
`

export const DragDrop = observer<IDragDrop>(({ presenter, disabled, draggables }) => {
  const { onDragEnd } = presenter
  presenter.withDraggables(draggables)

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {provided => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {draggables &&
              draggables.map((draggablePresenter, index) => (
                <DraggableWrapper key={draggablePresenter.id}>
                  <Draggable
                    index={index}
                    key={draggablePresenter.id + '-child'}
                    presenter={draggablePresenter}
                    disabled={disabled}
                  />
                </DraggableWrapper>
              ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
})
