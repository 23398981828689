// @ts-nocheck
import { useEffect } from 'react'
import styled from 'styled-components'
import { px2rem, breakpoints, colors } from 'app/Styles/Variables'
import { observer } from 'mobx-react-lite'
import { UserVerifyFormPresenter } from './UserVerifyFormPresenter'
import { ErrorAlert, TextInput, PhoneNumber, Button, Checkbox } from 'Components/Molecules'
import { Image } from 'Components/Atoms'
import { Message } from '@cutover/react-ui'

const FormInnerWrapper = styled.div`
  padding: ${px2rem(16)} ${px2rem(16)} ${px2rem(16)};
  border-top-left-radius: ${px2rem(8)};
  border-top-right-radius: ${px2rem(8)};
  background-color: ${colors.white};
`
const AlertWrapper = styled.div`
  padding-bottom: ${px2rem(12)};
`

const WarningCheckboxWrapper = styled.div`
  background-color: ${colors.primaryGreyBackgroundColor};
  color: ${colors.text};
  padding: ${px2rem(8)};
  font-size: ${px2rem(14)};
  line-height: ${px2rem(18)};
  margin-bottom: ${px2rem(12)};
  position: relative;
`

const ResponsiveContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media ${breakpoints.sm} {
    flex-direction: column;
  }
`

const ResponsiveInputContainer = styled.div`
  width: 100%;
  margin-right: ${px2rem(8)};

  @media ${breakpoints.sm} {
    margin-right: 0;
  }
`

const ValidatorStyled = styled.div`
  color: ${colors.error};
  line-height: 0px;
  position: absolute;
  top: 0;
  right: 0;
  font-size: ${px2rem(13)};
`
const extraStyles = {
  display: 'block',
  margin: '0 auto',
  maxHeight: '64px',
  maxWidth: '100%'
} as CSSProperties

interface IUserVerifyForm {
  presenter: UserVerifyFormPresenter
}

export const UserVerifyForm = observer<IUserVerifyForm>(({ presenter }) => {
  const {
    errorAlertPresenter,
    submitted,
    formIsValid,
    firstNamePresenter,
    lastNamePresenter,
    emailPresenter,
    mobileNumberPresenter,
    memorableWordPresenter,
    passwordPresenter,
    passwordConfirmationPresenter,
    submitButtonPresenter,
    hasResponseErrors,
    userToVerifyLoginType,
    inviteMessage,
    userToVerifyVMChangeFunction,
    userToVerifyVM,
    countryCodeValidator,
    phoneNumberValidator,
    memorableWordValidator,
    passwordValidator,
    passwordConfirmationValidator,
    toggleSubmitButtonDisabled,
    passwordMatch,
    togglePasswordConfirmationPresenter,
    showIsWarningAgreed,
    isWarningAgreedPresenter,
    isWarningAgreed,
    clientLogoPath
  } = presenter

  useEffect(() => {
    userToVerifyVMChangeFunction()
  }, [userToVerifyVM])

  useEffect(() => {
    const toogleStateEmailUser =
      formIsValid &&
      passwordMatch &&
      passwordConfirmationPresenter.isValid &&
      passwordConfirmationPresenter.value !== ''

    const toggleStateSsoUser = formIsValid && isWarningAgreed
    const toggleState = userToVerifyLoginType === 'email' ? toogleStateEmailUser : toggleStateSsoUser
    toggleSubmitButtonDisabled(!toggleState)
  }, [
    formIsValid,
    passwordMatch,
    passwordConfirmationPresenter.isValid,
    passwordConfirmationPresenter.value,
    isWarningAgreed
  ])

  useEffect(() => {
    let boolean = false
    if (!passwordMatch) boolean = passwordConfirmationPresenter.value !== '' ? true : false
    togglePasswordConfirmationPresenter(boolean)
  }, [passwordPresenter.value, passwordConfirmationPresenter.value, passwordMatch])

  const countryCodeValidationRender = <ValidatorStyled>{countryCodeValidator}</ValidatorStyled>
  const phoneNumberValidationRender = <ValidatorStyled>{phoneNumberValidator}</ValidatorStyled>
  const numberValidationComponents = [countryCodeValidationRender, phoneNumberValidationRender]
  const MemorableWordValidatorRender = <ValidatorStyled>{memorableWordValidator}</ValidatorStyled>
  const PasswordValidatorRender = <ValidatorStyled>{passwordValidator}</ValidatorStyled>
  const PasswordConfirmationValidatorRender = <ValidatorStyled>{passwordConfirmationValidator}</ValidatorStyled>

  return (
    <form>
      <FormInnerWrapper>
        {clientLogoPath && <Image src={clientLogoPath} alt="client logo" extraStyles={extraStyles} />}

        {submitted && (!formIsValid || hasResponseErrors) && (
          <AlertWrapper>
            <ErrorAlert presenter={errorAlertPresenter} />
          </AlertWrapper>
        )}
        {inviteMessage && <Message type="info" message={inviteMessage} />}
        {showIsWarningAgreed && (
          <WarningCheckboxWrapper>
            <Checkbox presenter={isWarningAgreedPresenter} />
          </WarningCheckboxWrapper>
        )}
        <>
          <ResponsiveContainer>
            <ResponsiveInputContainer>
              <TextInput presenter={firstNamePresenter} />
            </ResponsiveInputContainer>
            <ResponsiveInputContainer>
              <TextInput presenter={lastNamePresenter} />
            </ResponsiveInputContainer>
          </ResponsiveContainer>
          <TextInput presenter={emailPresenter} />
          <PhoneNumber presenter={mobileNumberPresenter} validationComponents={numberValidationComponents} />
          {mobileNumberPresenter.getNumber !== '' && (
            <TextInput presenter={memorableWordPresenter} customSuffixRender={MemorableWordValidatorRender} />
          )}
          {userToVerifyLoginType === 'email' && (
            <TextInput presenter={passwordPresenter} customSuffixRender={PasswordValidatorRender} />
          )}
          {userToVerifyLoginType === 'email' && (
            <TextInput
              presenter={passwordConfirmationPresenter}
              customSuffixRender={PasswordConfirmationValidatorRender}
            />
          )}
        </>
      </FormInnerWrapper>
      <Button presenter={submitButtonPresenter} type={'submit'} />
    </form>
  )
})
