// @ts-nocheck
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { formatDate } from 'Shared/Helpers/DateService'
import { Button } from 'Components/Molecules/Button'
import { fontSizes, px2rem } from 'app/Styles/Variables'
import { Heading } from 'Components/Atoms/Heading'
import { Modal, ModalPresenter } from 'Components/Atoms/Modal'
import { ToolTip } from 'Components/Molecules/ToolTip'
import { UserAppTokenPresenter } from './UserAppTokenPresenter'
import SanitizedString from 'Shared/Helpers/SanitizedString'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`
const ModalTextWrapper = styled.p``
const RevokeButtonWrapper = styled.div``
const AttributesWrapper = styled.div`
  flex: 1;
`
const AttributeWrapper = styled.div`
  margin-top: ${px2rem(3)};
  font-size: ${fontSizes.sm};
`
const AttributeLabel = styled.div`
  display: inline-block;
  margin-right: 0.25rem;
`
const AttributeValue = styled.div`
  display: inline;
`

// Modal

const ConfirmationWrapper = styled.div`
  padding: ${px2rem(20)};
`

const ButtonContainer = styled.div`
  padding-top: ${px2rem(20)};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

interface IUserOauthClientSessionProps {
  presenter: any
}

export const UserOauthClientSession = observer<IUserOauthClientSessionProps>(({ presenter }) => {
  const { buttonPresenter, language, clientSession, canDeleteOauthClientSession } = presenter

  const providerToUpperCase = () => {
    const provider = clientSession.provider.replace('_', ' ')
    return provider.replace(/(^\w|\s\w)/g, m => m.toUpperCase())
  }

  // Fix/adjust translations
  return (
    <Wrapper>
      <AttributesWrapper>
        <Heading rank={4}>{providerToUpperCase()}</Heading>
        <AttributeWrapper>
          <AttributeLabel>{language.get('users:edit:oauth:createdAt')}:</AttributeLabel>
          <AttributeValue>{formatDate(new Date(clientSession.created_at), 'dd MMM yyyy HH:mm')}</AttributeValue>
        </AttributeWrapper>
        <AttributeWrapper>
          <AttributeLabel>{language.get('users:edit:oauth:lastUpdated')}:</AttributeLabel>
          <AttributeValue>{formatDate(new Date(clientSession.updated_at), 'dd MMM yyyy HH:mm')}</AttributeValue>
        </AttributeWrapper>
      </AttributesWrapper>
      {canDeleteOauthClientSession && (
        <ToolTip
          message={language.get('users:edit:oauth:delete')}
          trigger={
            <RevokeButtonWrapper>
              <Button presenter={buttonPresenter} />
            </RevokeButtonWrapper>
          }
          position="left center"
        />
      )}
    </Wrapper>
  )
})
