// @ts-nocheck
import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { Button, ButtonPresenter } from 'Components/Molecules/Button'
import { Heading, ModalPresenter, Modal } from 'Components/Atoms'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'
import { px2rem } from 'app/Styles/Variables'
import { eventManager } from 'event-manager'

const ButtonContainer = styled.div`
  padding-top: ${px2rem(20)};
  padding-bottom: ${px2rem(2)};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const HeadingWrapper = styled.div`
  margin-bottom: ${px2rem(12)};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`

interface IAddGenericModalProps {
  presenter: ModalPresenter
  title: string
  cancelCallback?: () => void
  viewPresenter: any
  language: ILanguageService
}

export const GenericAddModal = observer<IAddGenericModalProps>(
  ({ presenter, title, cancelCallback, children, viewPresenter, language }) => {
    const {
      addModalSubmitButtonText,
      addModalSubmitButtonIcon,
      setSubmitted,
      addModalSubmitButtonLoading,
      addModalSubmitButtonDisabled
    } = viewPresenter
    presenter.fixedHeader = true

    const cancel = () => {
      presenter.onClose()

      if (cancelCallback) {
        cancelCallback()
      }
    }

    const submitCallback = async () => {
      setSubmitted(true)
      eventManager.emit('modal-saved')
    }

    useEffect(() => {
      submitButtonPresenter.initialText = addModalSubmitButtonText
    }, [addModalSubmitButtonText])

    useEffect(() => {
      submitButtonPresenter.iconName = addModalSubmitButtonIcon
    }, [addModalSubmitButtonIcon])

    useEffect(() => {
      submitButtonPresenter.isLoading = addModalSubmitButtonLoading
    }, [addModalSubmitButtonLoading])

    useEffect(() => {
      submitButtonPresenter.disabled = addModalSubmitButtonDisabled
    }, [addModalSubmitButtonDisabled])

    const cancelButtonPresenter = new ButtonPresenter(language.get('common:cancelButton'), cancel)
      .withDisplayStyle('secondary')
      .withIconName('dnd-forwardslash')

    const submitButtonPresenter = new ButtonPresenter(language.get('common:createButton'), () => {
      submitCallback()
    })
      .withDisplayStyle('primary')
      .withIconName('add')

    //  There are still questions as to whether this button is wanted or not therefore leaving it in the code
    //  until a final decision has been made.
    // const headerCloseButton = new ButtonPresenter('', cancel)
    //   .withIconName('close')
    //   .withAriaLabel(language.get('common:cancelButton'))
    //   .withDisplayStyle('secondary-invert')

    return (
      <Modal
        presenter={presenter.withOnCloseExtra(() => cancelCallback && cancelCallback())}
        stickyFooter
        displayMoreGradient
      >
        <HeadingWrapper>
          <Heading>{title}</Heading>
          {/* <Button presenter={headerCloseButton} /> */}
        </HeadingWrapper>
        <>{children}</>
        <footer>
          <ButtonContainer>
            <Button presenter={cancelButtonPresenter} type={'button'} shadowFocus />
            <Button dataTestId="generic-add-submit" presenter={submitButtonPresenter} type={'submit'} shadowFocus />
          </ButtonContainer>
        </footer>
      </Modal>
    )
  }
)
