import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const ArrowBack = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="ArrowBack" {...props}>
    <path d="m24.586 44-19.8-19.799 19.8-19.799 2.121 2.121L10.53 22.701H42v3H10.53l16.177 16.178L24.586 44Z" fill="#000" />
  </StyledIcon>
))

ArrowBack.displayName = 'ArrowBack'

export { ArrowBack }
