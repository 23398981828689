import { useMemo, useRef } from 'react'
import { useRecoilCallback, useRecoilValue, useRecoilValueLoadable } from 'recoil'

import {
  dashboardsLookupByKeyState,
  dashboardsLookupState,
  dashboardsState,
  dashboardState
} from 'main/recoil/runbook/models/account/dashboards'
import { DashboardModelType } from 'main/data-access/models'
import { useGetActiveRunbook, useGetActiveRunbookCallback } from './active-runbook'
import { Dashboard } from 'main/services/queries/types'

/* -------------------------------------------------------------------------- */
/*                                     Get                                    */
/* -------------------------------------------------------------------------- */

export const useGetDashboard: DashboardModelType['useGet'] = (id: number) => {
  return useRecoilValue(dashboardState(id)) as Dashboard
}

export const useGetDashboardCallback: DashboardModelType['useGetCallback'] = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      async (id: number) =>
        (await snapshot.getPromise(dashboardState(id))) as Dashboard,
    []
  )

/* -------------------------------------------------------------------------- */
/*                                   Get By                                   */
/* -------------------------------------------------------------------------- */

export const useGetDashboardBy: DashboardModelType['useGetBy'] = getBy => {
  const stabilizedGetBy = useRef(getBy).current
  const internalLookup = useRecoilValue(dashboardsLookupByKeyState)
  return internalLookup[stabilizedGetBy.key]
}

export const useGetDashboardByCallback: DashboardModelType['useGetByCallback'] = () =>
  useRecoilCallback(({ snapshot }) => async getBy => {
    const internalLookup = await snapshot.getPromise(dashboardsLookupByKeyState)
    return internalLookup[getBy.key]
  })

/* -------------------------------------------------------------------------- */
/*                                   Get All                                  */
/* -------------------------------------------------------------------------- */

export const useGetAllDashboards: DashboardModelType['useGetAll'] = options => {
  const stabilizedOptions = useRef(options).current
  const dashboards = useRecoilValue(dashboardsState)

  /* eslint-disable react-hooks/rules-of-hooks */
  if (stabilizedOptions?.scope === 'dashboard') {
    const { runbook_type_id } = useGetActiveRunbook()
    return useMemo(() => filterDashboardsByRunbook(dashboards, runbook_type_id), [dashboards, runbook_type_id])
  } else if (stabilizedOptions?.scope === 'pages') {
    return useMemo(() => filterDashboardsByPage(dashboards), [dashboards])
  }
  /* eslint-enable react-hooks/rules-of-hooks */

  return dashboards
}

export const useGetAllDashboardsCallback: DashboardModelType['useGetAllCallback'] = options => {
  const getActiveRunbook = useGetActiveRunbookCallback()

  return useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        const dashboards = await snapshot.getPromise(dashboardsState)

        if (options?.scope === 'dashboard') {
          const { runbook_type_id } = await getActiveRunbook()
          return filterDashboardsByRunbook(dashboards, runbook_type_id)
        } else if (options?.scope === 'pages') {
          return filterDashboardsByPage(dashboards)
        }

        return dashboards
      },
    []
  )
}

/* -------------------------------------------------------------------------- */
/*                                   Lookup                                   */
/* -------------------------------------------------------------------------- */

export const useGetDashboardLookup: DashboardModelType['useGetLookup'] = options => {
  const stabilizedOptions = useRef(options).current

  /* eslint-disable react-hooks/rules-of-hooks */
  if (stabilizedOptions?.keyBy === 'key') {
    return useRecoilValue(dashboardsLookupByKeyState)
  } else {
    return useRecoilValue(dashboardsLookupState)
  }
  /* eslint-enable react-hooks/rules-of-hooks */
}

export const useGetDashboardLookupCallback: DashboardModelType['useGetLookupCallback'] = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      async options => {
        if (options?.keyBy === 'key') {
          return await snapshot.getPromise(dashboardsLookupByKeyState)
        }
        return await snapshot.getPromise(dashboardsLookupState)
      },
    []
  )

export const useGetDashboardLookupLoadable: DashboardModelType['useGetLookupLoadable'] = () =>
  useRecoilValueLoadable(dashboardsLookupState)

/* --------------------------------- Helpers -------------------------------- */

const filterDashboardsByRunbook = (dashboards: Dashboard[], runbookTypeId: number) => {
  return dashboards.filter(
    dashboard =>
      dashboard.context === 'Runbook' &&
      dashboard.dashboard_type === 'dashboard' &&
      dashboard.key !== 'pir' &&
      (dashboard.default ||
        !dashboard.display_on ||
        (runbookTypeId && JSON.parse(dashboard.display_on).includes(runbookTypeId)))
  )
}

const filterDashboardsByPage = (dashboards: Dashboard[]) => {
  return dashboards.filter(dashboard => dashboard.context === 'Runbook' && dashboard.dashboard_type === 'page')
}
