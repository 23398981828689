import { ReactNode, useEffect, useRef, useState } from 'react'
import { EdgeSizeType } from 'grommet/utils'

import { Box, breakpoints } from '@cutover/react-ui'
import { CaretLeft, CaretRight } from '@cutover/icons'
import { useComponentProps } from '../apps-state'
import { AppComponentNodeProps } from '../apps-types'

type EdgeType = EdgeSizeType | 'none' | string

type FlexRowNodeProps = AppComponentNodeProps & {
  children: ReactNode
  align: 'left' | 'right' | 'fill'
  gap: EdgeType
  pad: EdgeType
  overflow: 'caret' | 'default'
}

const SCROLL_CONTAINER = 200
const SCROLL_EDGE = 5

export const FlexRowNode = ({ appId, resourceId, id, ...props }: FlexRowNodeProps) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null)
  const [canScrollLeft, setCanScrollLeft] = useState(false)
  const [canScrollRight, setCanScrollRight] = useState(false)

  const {
    children,
    align = 'fill',
    gap = 'small',
    pad = 'small',
    overflow = 'default'
  } = useComponentProps(appId, resourceId, id, props) as FlexRowNodeProps

  const scrollContent = (scrollToEnd: boolean) => {
    scrollContainerRef.current &&
      scrollContainerRef.current.scrollBy({
        left: scrollToEnd ? SCROLL_CONTAINER : -SCROLL_CONTAINER,
        behavior: 'smooth'
      })
  }

  useEffect(() => {
    handleScroll()
  }, [scrollContainerRef])

  const handleScroll = () => {
    if (scrollContainerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current
      setCanScrollRight(scrollLeft < scrollWidth - clientWidth - SCROLL_EDGE)
      setCanScrollLeft(scrollLeft > SCROLL_EDGE)
    }
  }

  const maskImage = `
  ${canScrollLeft && !canScrollRight ? 'linear-gradient(to left, black calc(100% - 30px), transparent 100%)' : ''} 
  ${canScrollLeft && canScrollRight ? 'linear-gradient(90deg, transparent, #000 5%, #000 95%, transparent 100%);' : ''}
  ${canScrollRight && !canScrollLeft ? 'linear-gradient(to right, black calc(100% - 30px), transparent 100%)' : ''}
`.trim()

  const rightCaret = () => (
    <Box direction="column" align="center" pad="xsmall" onClick={() => scrollContent(true)}>
      <CaretRight color="text-light" />
    </Box>
  )

  const leftCaret = () => (
    <Box direction="column" align="center" pad="xsmall" onClick={() => scrollContent(false)}>
      <CaretLeft color="text-light" />
    </Box>
  )

  return (
    <>
      {overflow === 'caret' && (
        <Box direction="row" align="center">
          {canScrollLeft && leftCaret()}
          <Box
            data-testid={`caret-flex-row-${id}`}
            direction="row"
            align="center"
            gap={gap}
            pad={pad}
            onScrollCapture={handleScroll}
            ref={scrollContainerRef}
            style={{ overflow: 'scroll' }}
            css={`
              mask: ${maskImage};
              scrollbar-width: none; /* Hide default scrollbar for Firefox */
              -ms-overflow-style: none; /* Hide default scrollbar for Internet Explorer and Edge */
              &::-webkit-scrollbar {
                display: none; /* Hide default scrollbar for WebKit (Chrome, Safari, etc.) */
              }
            `}
          >
            {children}
          </Box>
          {canScrollRight && rightCaret()}
        </Box>
      )}
      {overflow === 'default' && (
        <Box
          direction="row"
          align="center"
          gap={gap}
          pad={pad}
          width="100%"
          style={{ position: 'relative' }}
          css={`
            @media ${breakpoints.sm} {
              flex-flow: row wrap;
              gap: 16px;
            }
          `}
        >
          {align === 'right' && <Box css={`flex 1 1 auto !important; @media ${breakpoints.sm} { display: none; }`} />}
          {children}
          {align === 'left' && <Box css={'flex 1 1 auto !important'} />}
        </Box>
      )}
    </>
  )
}
