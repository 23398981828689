import { RunbookIntegrationsPanelConnector } from './runbook-integrations-panel-connector'
import { ConnectionLostModal } from 'main/components/error/connection-lost-modal'
import { ActivitiesPageConnector } from 'main/connectors/activities-page-connector'
import { AppsConnector, HeaderAppConnector, MobileHeaderAppConnector } from 'main/connectors/apps-connector'
import { PeoplePanelConnector } from 'main/connectors/people-panel-connector'
import { RunbookDashboardSchedulePanelConnector } from 'main/connectors/runbook-dashboard-schedule-panel-connector'
import { RunbooksDashboardPageConnector } from 'main/connectors/runbooks-dashboard-page-connector'
import { RunbooksTimelineConnector } from 'main/connectors/runbooks-timeline-connector'
import { AiSuggestionsPanelConnector } from './ai-suggestions-panel-connector'
import { ConfigModel } from 'main/data-access'

export const ReactAppConnectors = () => {
  const isReactWorkspaceEnabled = ConfigModel.useIsFeatureEnabled('react_workspace')

  return (
    <>
      <PeoplePanelConnector />
      <RunbookIntegrationsPanelConnector />
      <AiSuggestionsPanelConnector />
      <RunbookDashboardSchedulePanelConnector />
      <ActivitiesPageConnector />
      <AppsConnector />
      <HeaderAppConnector />
      <MobileHeaderAppConnector />
      {!isReactWorkspaceEnabled && (
        <>
          <RunbooksDashboardPageConnector />
          <RunbooksTimelineConnector />
        </>
      )}
      <ConnectionLostModal />
    </>
  )
}
