// @ts-nocheck
import 'reflect-metadata'
import { computed, observable, observe, action } from 'mobx'
import { IUserListEntry } from 'Shared/Entities/Types/User/IUserListEntry'
import { ConfigsRepository } from 'Configs/ConfigsRepository'
import { UserRepository } from 'app/Repositories/User/UserRepository'
import { UsersRepository } from 'app/Repositories/User/UsersRepository'
import { ColumnVisible, ITableColumnTypes, TablePresenter, ISortParams } from 'Components/Organisms'
import { container } from 'app/Config/IOC'
import { GenericListPresenter } from 'Components/Templates'
import { UsersViewPresenter } from 'app/Access/Users/UsersPage/UsersViewPresenter'
import { FiltersRepository } from 'app/Filters/FiltersRepository'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'
import { Types } from 'Gateways/Service/Types'

export class UserListPresenter implements GenericListPresenter {
  @observable
  private userRepository: UserRepository = container.get(UserRepository)

  @observable
  private usersRepository: UsersRepository = container.get(UsersRepository)

  @observable
  private configsRepository: ConfigsRepository = container.get(ConfigsRepository)

  @observable
  private usersViewPresenter: UsersViewPresenter = container.get(UsersViewPresenter)

  @observable
  public filtersRepository: FiltersRepository = container.get(FiltersRepository)

  public language: ILanguageService = container.get(Types.ILanguageService)

  @observable
  private closeEditPanelFunction = () => null

  @observable
  private checkEditPanelDirtyFunction = () => {
    return false
  }

  public tableName: string = 'user-list'

  public context: string = 'user'

  public visibleColumns(): ITableColumnTypes[] {
    return [
      {
        accessor: 'avatar',
        name: '',
        width: 60,
        dataType: 'avatar',
        sortable: false
      },
      {
        accessor: 'firstName',
        name: this.language.get('users:list:tableHeadings:firstName'),
        width: 180,
        dataType: 'string',
        sortable: true
      },
      {
        accessor: 'lastName',
        name: this.language.get('users:list:tableHeadings:lastName'),
        width: 180,
        dataType: 'string',
        sortable: true
      },
      {
        accessor: 'nickName',
        name: this.language.get('users:list:tableHeadings:nickname'),
        width: 180,
        dataType: 'string',
        sortable: true,
        render: function (rawContent) {
          return '@' + rawContent
        }
      },
      {
        accessor: 'email',
        name: this.language.get('users:list:tableHeadings:email'),
        width: 300,
        dataType: 'string',
        sortable: true
      },
      {
        accessor: 'mobileNumberNormalized',
        name: this.language.get('users:list:tableHeadings:mobileNumber'),
        width: 200,
        dataType: 'string',
        sortable: true
      },
      {
        accessor: 'uniqueId',
        name: this.language.get('users:list:tableHeadings:uniqueId'),
        width: 180,
        dataType: 'string',
        sortable: true
      },
      {
        accessor: 'jobTitle',
        name: this.language.get('users:list:tableHeadings:jobTitle'),
        width: 180,
        dataType: 'string',
        sortable: true
      },
      {
        accessor: 'loginCount',
        name: this.language.get('users:list:tableHeadings:loginCount'),
        width: 200,
        dataType: 'number',
        sortable: true
      },
      {
        accessor: 'lastActive',
        name: this.language.get('users:list:tableHeadings:lastActive'),
        width: 200,
        dataType: 'date',
        sortable: false
      },
      {
        accessor: 'lastLogin',
        name: this.language.get('users:list:tableHeadings:lastLogin'),
        width: 200,
        dataType: 'date',
        sortable: false
      },
      {
        accessor: 'invitationSentAt',
        name: this.language.get('users:list:tableHeadings:invitationSentAt'),
        width: 200,
        dataType: 'date',
        sortable: true
      },
      {
        accessor: 'invitationAcceptedAt',
        name: this.language.get('users:list:tableHeadings:invitationAcceptedAt'),
        width: 200,
        dataType: 'date',
        sortable: true
      },
      {
        accessor: 'passwordExpiresAt',
        name: this.language.get('users:list:tableHeadings:passwordExpiresAt'),
        width: 200,
        dataType: 'date',
        sortable: false
      },
      {
        accessor: 'lockedAt',
        name: this.language.get('users:list:tableHeadings:lockedAt'),
        width: 200,
        dataType: 'date',
        sortable: true
      },
      {
        accessor: 'acceptEmail',
        name: this.language.get('users:list:tableHeadings:acceptEmail'),
        width: 200,
        dataType: 'boolean',
        sortable: true
      },
      {
        accessor: 'acceptSMS',
        name: this.language.get('users:list:tableHeadings:acceptSMS'),
        width: 200,
        dataType: 'boolean',
        sortable: true
      }
    ].filter(columnHeader => {
      return ColumnVisible(this.configsRepository, columnHeader.accessor)
    })
  }

  @computed
  public get columns(): ITableColumnTypes[] {
    return this.visibleColumns()
  }

  @computed
  public get viewModel(): IUserListEntry[] {
    const retData = this.usersRepository.users.map(user => {
      return {
        id: user.id,
        uniqueId: user.uniqueId,
        firstName: user.firstName,
        lastName: user.lastName,
        nickName: user.nickName,
        email: user.email,
        mobileNumberNormalized: user.mobileNumberNormalized,
        jobTitle: user.jobTitle,
        loginCount: user.loginCount,
        lastActive: user.lastActive,
        lastLogin: user.lastLogin,
        invitationSentAt: user.invitationSentAt,
        invitationAcceptedAt: user.invitationAcceptedAt,
        passwordExpiresAt: user.passwordExpiresAt,
        lockedAt: user.lockedAt,
        acceptEmail: user.acceptEmail,
        acceptSMS: user.acceptSMS,
        color: user.color
      }
    })
    return retData
  }

  @computed
  public get selectedId(): number {
    return this.userRepository.selectedId
  }

  @computed
  public get batchLoadNumber(): number {
    return this.usersRepository.batchLoadNumber
  }

  public loadMore = (startIndex, stopIndex) => {
    this.usersRepository.loadMore(startIndex, this.usersRepository.sortParams)
  }

  public loadSort = (sortParams: ISortParams) => {
    this.usersViewPresenter.sortParams = sortParams
    this.usersViewPresenter.loadUsers()
  }

  @computed
  public get total(): number {
    return this.usersRepository.usersFilteredTotal < this.usersRepository.usersTotal
      ? this.usersRepository.usersFilteredTotal
      : this.usersRepository.usersTotal
  }

  @action
  public loadUser = async (userId: number) => {
    if (this.checkEditPanelDirtyFunction()) return

    if (this.userRepository.userForEdit && this.userRepository.userForEdit.id === userId) {
      this.userRepository.clearSelectedId()
      this.closeEditPanelFunction()
    } else {
      await this.userRepository.loadData({ userId: userId })
    }
  }

  @observable
  public tablePresenter: TablePresenter = new TablePresenter(
    this.viewModel,
    this.columns,
    this.tableName,
    this.viewModel.length
  )
    .withLoadMoreCallback(this.loadMore)
    .withLoadSortCallback(this.loadSort)
    .withLoadRowCallback(this.loadUser)
    .withBatchLoadNumber(this.batchLoadNumber)

  @observable
  public usersRepositoryObserverDisposer = null

  @action
  public load() {
    this.usersRepositoryObserverDisposer = observe(this.usersRepository, 'users', obj => {
      this.tablePresenter.initNewData(this.viewModel, this.total)
    })
  }

  public clear = () => {
    this.usersRepositoryObserverDisposer()
  }

  @action
  public withCheckEditPanelDirtyFunction = callback => {
    this.checkEditPanelDirtyFunction = callback
    return this
  }

  @action
  public withEditPanelCloseFunction = callback => {
    this.closeEditPanelFunction = callback
    return this
  }

  @computed
  public get sortParams(): ISortParams {
    return this.usersViewPresenter.sortParams
  }

  public clearAllFilters = () => {
    this.usersViewPresenter.clearFilters()
  }

  @computed
  public get loading() {
    return this.usersViewPresenter.repositoryLoading
  }

  public loadRowCallback = async (userId: number) => {
    if (this.usersViewPresenter.pageVM.editPanelDirty) {
      return
    } else if (this.usersViewPresenter.userVM && this.usersViewPresenter.userVM.id === userId) {
      this.usersViewPresenter.close()
    } else {
      this.usersViewPresenter.loadUser(userId)
    }
  }

  @computed
  public get totalCount() {
    return this.usersViewPresenter.usersFilteredTotal
  }

  @computed
  public get reloadList() {
    return this.usersViewPresenter.reloadList
  }

  @computed
  public get filtersCount(): number {
    return this.filtersRepository.selectedFilters.length
  }
}
