import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const TaskTypeNormalDashed = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="TaskTypeNormalDashed" {...props}>
    <path d="M31.924 37.903a16.078 16.078 0 0 0 5.98-5.98l1.86.772a18.084 18.084 0 0 1-7.07 7.07l-.77-1.862Zm-12.153 1.532c1.347.368 2.765.565 4.229.565s2.882-.197 4.229-.565l.77 1.861A18.007 18.007 0 0 1 24 42a18.01 18.01 0 0 1-5-.703l.771-1.862Zm-9.674-7.511a16.077 16.077 0 0 0 5.98 5.98l-.772 1.86a18.085 18.085 0 0 1-7.07-7.07l1.862-.77ZM8.565 19.771A16.017 16.017 0 0 0 8 24c0 1.464.197 2.882.565 4.229l-1.862.77A18.01 18.01 0 0 1 6 24c0-1.735.245-3.412.703-5l1.862.771Zm7.511-9.674a16.078 16.078 0 0 0-5.98 5.98l-1.86-.772a18.085 18.085 0 0 1 7.07-7.07l.77 1.862Zm12.153-1.532A16.018 16.018 0 0 0 24 8c-1.464 0-2.882.197-4.229.565l-.77-1.862A18.01 18.01 0 0 1 24 6c1.735 0 3.412.245 5 .703l-.771 1.862Zm9.674 7.511a16.078 16.078 0 0 0-5.98-5.98l.772-1.86a18.085 18.085 0 0 1 7.07 7.07l-1.862.77Zm1.532 3.695C39.803 21.118 40 22.536 40 24a16.02 16.02 0 0 1-.565 4.229l1.862.77A18.01 18.01 0 0 0 42 24a18.01 18.01 0 0 0-.703-5l-1.862.771Z" fill="#000" />
  </StyledIcon>
))

TaskTypeNormalDashed.displayName = 'TaskTypeNormalDashed'

export { TaskTypeNormalDashed }
