// @ts-nocheck
import { useEffect } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { withPresenter } from 'app/Config/withPresenter'
import { AccountAddPresenter } from './AccountAddPresenter'
import { AccountFormPresenter } from 'app/Settings/Accounts/AccountsPage/AccountForm/AccountFormPresenter'
import { AccountFormComponent } from 'app/Settings/Accounts/AccountsPage/AccountForm/AccountFormComponent'
import { px2rem } from 'app/Styles/Variables'

const Wrapper = styled.div`
  max-height: ${px2rem(450)};
`

interface IAccountAddComponentProps {
  postSuccessCallback?: () => void
  cancelCallback?: () => void
}

interface IAccountAddComponentPresenterProps {
  presenter: AccountAddPresenter
  addFormPresenter: AccountFormPresenter
}

const AccountAddSection = observer<IAccountAddComponentProps & IAccountAddComponentPresenterProps>(
  ({ postSuccessCallback, cancelCallback, presenter, addFormPresenter }) => {
    const { formSubmitted, submit } = presenter

    presenter.withPostSuccessCall(postSuccessCallback)
    presenter.withCancelCallback(cancelCallback)
    presenter.withFormPresenter(addFormPresenter)

    useEffect(() => {
      if (formSubmitted) {
        submit()
      }
    }, [formSubmitted])

    return (
      <Wrapper>
        <AccountFormComponent presenter={addFormPresenter} formType="add" />
      </Wrapper>
    )
  }
)

export default withPresenter(AccountAddPresenter)(AccountAddSection)
