import { Component, ReactNode, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { DateTimePresenter, DateTimeFilterPresenter } from '.'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { enGB } from 'date-fns/locale'
import { subtractFromDate, addToDate, cloneDate, formatDate } from 'Shared/Helpers/DateService'
import styled, { createGlobalStyle } from 'styled-components'
import { TimeControl, TextInputPresenter } from 'Components/Molecules'
import { TextInput } from 'Components/Molecules/TextInput'
import { colors } from 'app/Styles/Variables'
import { Icon } from 'Components/Atoms'

registerLocale('enGB', enGB)

const Global = createGlobalStyle`
  .react-datepicker-wrapper {
    display: block !important;
  }

  .react-datepicker {
    border: none !important;
    box-shadow: 2px 2px 12px ${colors.primaryGreyHoverBackgroundColor} !important;
    font-family: 'Roboto' !important;
    font-weight: 400 !important;
    -webkit-font-smoothing: antialiased !important;
  }

  .react-datepicker__input-container {
    display: block !important;
  }

  .react-datepicker__header {
    border: none !important;
    background-color: white !important;
  }

  .react-datepicker__triangle {
    display: none !important;
  }

  .react-datepicker__day--outside-month {
    color: ${colors.secondaryGrey} !important;
  }

  .react-datepicker__day {
    width: 34px !important;
    line-height: 22px !important;
  }

  .react-datepicker__day--today {
    background-color: ${colors.primaryGreyBackgroundColor} !important;
  }

  .react-datepicker__day--selected {
    border-radius: 0 !important;
    background-color: ${colors.primary} !important;

    &hover {
      border-radius: 0 !important;
    }
  }

  .react-datepicker__day {
    border-radius: 0 !important;
    margin: 1px !important;

    &hover {
      border-radius: 0 !important;
    }
  }
`

const DateTimeWrapper = styled.div`
  width: 100%;
`

const EmptyWrapper = styled.span`
  width: 100%;
  display: block;
  cursor: pointer;
`

const TextInputStyled = styled(TextInput)`
  margin-top: 0;
`

const ClearWrapper = styled.span`
  z-index: 1;
  cursor: pointer;
  color: ${colors.textLight};
`

interface ICustomInputProps {
  onClick?: () => void
  value?: string
  datePresenter: TextInputPresenter
  timeControl?: ReactNode
  handleKeyPress: (key: any) => void
  style?: any
  clearControl?: ReactNode
  narrow?: boolean
}

//react-datetime needs this to be a class component as it attaches a ref
class CustomInput extends Component<ICustomInputProps, {}> {
  // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
  render() {
    const { onClick, datePresenter, timeControl, handleKeyPress, clearControl, narrow } = this.props

    return (
      <div onKeyDown={handleKeyPress} role="textbox" tabIndex={0}>
        <TextInputStyled
          presenter={datePresenter}
          customSuffixRender={
            <>
              {clearControl}
              {timeControl}
            </>
          }
          inputOnClick={onClick}
          narrow={narrow}
        />
      </div>
    )
  }
}

interface IDateTimeProps {
  presenter: DateTimePresenter | DateTimeFilterPresenter
  className?: string
  maxDate?: Date
}

export const DateTime = observer<IDateTimeProps>(({ presenter, className, maxDate }) => {
  const {
    value,
    handleDateChange,
    datePresenter,
    clear,
    controlState,
    enableClear,
    disabled,
    allowSameDay,
    handleTimeChange,
    narrow,
    filterDateValueAsString,
    updateFromFiltersRepository
  } = presenter

  const dateHandler = (date: Date) => {
    handleDateChange(new Date(date))
  }

  useEffect(() => {
    updateFromFiltersRepository()
  }, [filterDateValueAsString])
  const timeControl = (
    <>
      {controlState === 'populated' && (
        <TimeControl
          value={value}
          handleDateChange={handleDateChange}
          handleTimeChange={handleTimeChange}
          disabled={disabled}
        />
      )}
    </>
  )

  const clearControl = (
    <>
      {controlState === 'populated' && (
        <ClearWrapper onClick={clear}>
          {enableClear && <Icon name="close" size="16px" extraStyles={{ opacity: 0.7 }} />}
        </ClearWrapper>
      )}
    </>
  )

  const handleKeyPress = (event: any) => {
    event.preventDefault()

    switch (event.key) {
      case 'ArrowLeft': {
        handleDateChange(subtractFromDate(value, { days: 1 }))
        break
      }
      case 'ArrowRight': {
        handleDateChange(addToDate(value, { days: 1 }))
        break
      }
      case 'ArrowUp': {
        handleDateChange(subtractFromDate(value, { weeks: 1 }))
        break
      }
      case 'ArrowDown': {
        handleDateChange(addToDate(value, { weeks: 1 }))
        break
      }
      case 'Enter': {
        const daySelected = formatDate(value, 'dd')
        handleDateChange(cloneDate(value))
        let selectedDate: HTMLElement = window.document.getElementsByClassName(
          'react-datepicker__day react-datepicker__day--0' + daySelected + ' react-datepicker__day--selected'
        )[0] as HTMLElement
        selectedDate.click()
        break
      }
    }
  }

  const datePicker = (
    <DatePicker
      selected={new Date(value)}
      onChange={dateHandler}
      disabled={disabled}
      allowSameDay={allowSameDay}
      maxDate={maxDate || null}
      customInput={
        <CustomInput
          handleKeyPress={handleKeyPress}
          datePresenter={datePresenter}
          timeControl={timeControl}
          clearControl={clearControl}
          narrow={narrow}
        />
      }
      locale="enGB"
    />
  )

  return (
    <>
      {controlState === 'initempty' && <EmptyWrapper className={className}>{datePicker}</EmptyWrapper>}
      {controlState === 'populated' && <DateTimeWrapper className={className}>{datePicker}</DateTimeWrapper>}
      <Global />
    </>
  )
})
