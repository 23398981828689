import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Folder = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Folder" {...props}>
    <path d="M9 10a1 1 0 0 1 1-1h11.5a1 1 0 0 1 1 1v1a5 5 0 0 0 5 5H38a1 1 0 0 1 1 1v17a5 5 0 0 1-5 5H14a5 5 0 0 1-5-5V10Zm-3 0v24a8 8 0 0 0 8 8h20a8 8 0 0 0 8-8V17a4 4 0 0 0-4-4H27.5a2 2 0 0 1-2-2v-1a4 4 0 0 0-4-4H10a4 4 0 0 0-4 4Z" fill="#000" />
  </StyledIcon>
))

Folder.displayName = 'Folder'

export { Folder }
