import { ThemeType as GrommetThemeType } from 'grommet/themes'
import { SetRequired } from 'type-fest'

// Exports the type and value for the grommet theme's tip key

type GrommetTheme = SetRequired<GrommetThemeType, 'tip'>
type GrommetTip = GrommetTheme['tip']

export const tip: Partial<GrommetTip> = {
  content: {
    background: 'transparent',
    elevation: 'none',
    pad: 'xsmall',
    round: '5px',
    margin: 'none',
    width: { max: '200px' }
  }
}
