// @ts-nocheck
import { observer } from 'mobx-react-lite'
import { OldPillFilterPresenter, OldPillFilter } from 'Components/Molecules'
import { px2rem } from 'app/Styles/Variables'

interface IPillFiltersProps {
  pillPresenters: OldPillFilterPresenter[]
  filterOpen: boolean
  pillClose: (pillId: string) => void
  customIconClick?: (filterId?: string) => void
}

export const PillFilters = observer(({ pillPresenters, filterOpen, pillClose, customIconClick }: IPillFiltersProps) => {
  const pillComponents = pillPresenters.map((pillPresenter: OldPillFilterPresenter, index) => {
    const { iconName } = pillPresenter
    let extraStyles = filterOpen && iconName === 'swap' ? { paddingRight: px2rem(44) } : { paddingRight: px2rem(24) }

    return (
      <OldPillFilter
        key={index}
        presenter={pillPresenter}
        customOnClose={pillClose}
        extraStyles={extraStyles}
        customIconClick={customIconClick && customIconClick}
      />
    )
  })

  return <>{pillComponents}</>
})
