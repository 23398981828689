import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const AlignLeft = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="AlignLeft" {...props}>
    <path d="M6 9h36v3H6V9Zm0 13.5h17v3H6v-3ZM33 36H6v3h27v-3Z" fill="#000" />
  </StyledIcon>
))

AlignLeft.displayName = 'AlignLeft'

export { AlignLeft }
