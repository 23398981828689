import { unescape } from 'lodash'
import { useMutation, UseMutationOptions, useQuery, useQueryClient } from 'react-query'

import { QueryKeys } from './query-keys'
import {
  FolderCreateFolder,
  FolderCreateFolderResponse,
  FolderDeleteFolderPayload,
  FolderEditFolderPayload,
  FolderEditFolderResponse,
  FolderGetFolder,
  FolderListFolder
} from './types'
import { RunbooksResponseMetaFull } from './use-runbooks'
import { apiClient, ApiError } from '../api'
import { useAccount } from '../api/data-providers/account/account-data'

type FolderCreateResponse = {
  meta: {
    permissions: { [key: string]: number[] }
  }
  project: FolderCreateFolderResponse
}

type FolderGetResponse = {
  meta: {
    permissions: { [key: string]: number[] }
  }
  project: FolderGetFolder
}

type FolderDeleteResponse = {
  meta: {
    new_project_id: number | null
  }
  project: FolderGetFolder
}

type FolderEditResponse = {
  project: FolderEditFolderResponse
}

export const useFolder = (folderId: number) => {
  return useQuery<FolderGetResponse, ApiError>(
    ['folder', folderId],
    async () => {
      const { data } = await apiClient.get<FolderGetResponse>({
        url: `projects/${folderId}`
      })

      data.project.name = unescape(data.project.name)

      return data
    },
    {
      cacheTime: 0
    }
  )
}

export const useFolderCreate = (options: UseMutationOptions<FolderCreateResponse, Error, FolderCreateFolder> = {}) => {
  const { account } = useAccount()
  const queryClient = useQueryClient()
  return useMutation<FolderCreateResponse, ApiError, FolderCreateFolder>(
    'folder-create',
    async (payload: FolderCreateFolder) => {
      const { data } = await apiClient.post({
        url: 'projects',
        data: payload
      })

      return data as FolderCreateResponse
    },
    {
      onSuccess: (data, variables, context) => {
        if (!account) return

        queryClient.setQueryData<RunbooksResponseMetaFull | undefined>(
          [account.slug, QueryKeys.RunbooksMeta],
          oldData => {
            if (!oldData) return

            return {
              ...oldData,
              projects: [
                ...oldData.projects,
                {
                  account_id: data.project.account_id,
                  color: data.project.color,
                  count: data.project.count,
                  id: data.project.id,
                  name: data.project.name,
                  parent_id: data.project.parent_id || null
                }
              ]
            }
          }
        )

        options?.onSuccess?.(data, variables, context)
      },
      onError: options?.onError,
      onMutate: options?.onMutate,
      onSettled: options?.onSettled
    }
  )
}

export const useFolderDelete = (
  folderId: number,
  options: UseMutationOptions<FolderDeleteResponse, Error, FolderDeleteFolderPayload> = {}
) => {
  const { account } = useAccount()
  const queryClient = useQueryClient()
  return useMutation<FolderDeleteResponse, ApiError, FolderDeleteFolderPayload>(
    'folder-create',
    async payload => {
      const { data } = await apiClient.patch({
        url: `projects/${folderId}/archive`,
        data: payload
      })

      return data as FolderDeleteResponse
    },
    {
      onSuccess: (response, variables, context) => {
        if (account?.id) {
          const runbooksMeta = queryClient.getQueryData([
            account.slug,
            QueryKeys.RunbooksMeta
          ]) as RunbooksResponseMetaFull

          if (!runbooksMeta) return

          // update runbooks meta to update filter panel
          const rmProjectIndex = runbooksMeta.projects.findIndex(folder => folder.id === response.project.id)
          let projects = [...runbooksMeta.projects]
          projects.splice(rmProjectIndex, 1)

          // if runbooks have been moved, update counts for new project and new+previous parent folders
          if (response.meta.new_project_id) {
            const updateRunbookIndex = projects.findIndex(folder => folder.id === response.meta.new_project_id)
            projects = updateProjectCount({
              action: 'add',
              index: updateRunbookIndex,
              projects,
              count: response.project.runbooks_count
            })

            const newParentId = projects[updateRunbookIndex].parent_id
            if (newParentId) {
              const updateNewParentIndex = projects.findIndex(folder => folder.id === newParentId)
              projects = updateProjectCount({
                action: 'add',
                index: updateNewParentIndex,
                projects,
                count: response.project.runbooks_count
              })
            }

            const existingParentId = response.project.parent_id
            if (existingParentId) {
              const updateExistingParentIndex = projects.findIndex(folder => folder.id === existingParentId)
              projects = updateProjectCount({
                action: 'subtract',
                index: updateExistingParentIndex,
                projects,
                count: response.project.runbooks_count
              })
            }
          }

          queryClient.setQueryData([account.slug, QueryKeys.RunbooksMeta], { ...runbooksMeta, projects })
        }
        options?.onSuccess?.(response, variables, context)
      },
      ...options?.onError,
      ...options?.onMutate,
      ...options?.onSettled
    }
  )
}

export const useFolderEdit = (
  id: number,
  options: UseMutationOptions<FolderEditResponse, Error, FolderEditFolderPayload> = {}
) => {
  const queryClient = useQueryClient()
  const { account } = useAccount()
  return useMutation<FolderEditResponse, ApiError, FolderEditFolderPayload>(
    'folder-edit',
    async (payload: FolderEditFolderPayload) => {
      const { data } = await apiClient.put({
        url: `projects/${id}`,
        data: { project: payload }
      })

      return data as FolderEditResponse
    },
    {
      onSuccess: (response, variables, context) => {
        response.project.name = unescape(response.project.name)

        queryClient.setQueryData(['folder', response.project.id], response)
        if (account?.id) {
          const runbooksMeta = queryClient.getQueryData([
            account.slug,
            QueryKeys.RunbooksMeta
          ]) as RunbooksResponseMetaFull

          if (!runbooksMeta) return

          // update runbooks meta to update filter panel
          const projectIndex = runbooksMeta.projects.findIndex(folder => folder.id === response.project.id)
          const originalProject = runbooksMeta.projects[projectIndex]
          let projects = [...runbooksMeta.projects]
          projects.splice(projectIndex, 1, {
            ...originalProject,
            name: response.project.name,
            parent_id: response.project.parent_id ?? null
          })

          const newParentId = response.project.parent_id
          const updateNewParentIndex = projects.findIndex(folder => folder.id === newParentId)
          if (updateNewParentIndex >= 0) {
            projects = updateProjectCount({
              action: 'add',
              index: updateNewParentIndex,
              projects,
              count: response.project.runbooks_count
            })
          }

          const oldParentIndex = projects.findIndex(f => f.id === originalProject.parent_id)
          if (oldParentIndex >= 0) {
            projects = updateProjectCount({
              action: 'subtract',
              index: oldParentIndex,
              projects,
              count: response.project.runbooks_count
            })
          }

          queryClient.setQueryData([account.slug, QueryKeys.RunbooksMeta], { ...runbooksMeta, projects })
        }
        options?.onSuccess?.(response, variables, context)
      },
      ...options?.onError,
      ...options?.onMutate,
      ...options?.onSettled
    }
  )
}

const updateProjectCount = ({
  action,
  index,
  projects,
  count
}: {
  action: 'add' | 'subtract'
  index: number
  projects: FolderListFolder[]
  count: number
}) => {
  const newProjects = [...projects]
  const newCount = action === 'add' ? newProjects[index].count + count : newProjects[index].count - count
  newProjects.splice(index, 1, {
    ...newProjects[index],
    count: newCount >= 0 ? newCount : 0 // glosses over an issue with the api that returns the count of all runbooks including templates
  })
  return newProjects
}
