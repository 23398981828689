import { IntegrationSettingsEditPanel } from './integration-settings-edit-panel'
import { IntegrationSettingsHeader } from './integration-settings-header'
import { IntegrationSettingsSubHeader } from './integration-settings-sub-header/integration-settings-sub-header'
import { Layout } from 'main/components/layout'
import { useDefaultLayout } from 'main/components/layout/layout-hooks'
import { useHasPermissionsOrNavigateHome } from 'main/services/hooks'

export const IntegrationSettingsLayout = () => {
  const userCanViewPage = useHasPermissionsOrNavigateHome('integrations')

  useDefaultLayout({
    filterPanel: false,
    subHeader: true,
    rightNav: false
  })

  return userCanViewPage ? (
    <Layout
      header={<IntegrationSettingsHeader />}
      subHeader={<IntegrationSettingsSubHeader />}
      filter={null}
      rightPanels={<IntegrationSettingsEditPanel />}
    />
  ) : null
}
