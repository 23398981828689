// @ts-nocheck
import { TSelectOptionTypes } from 'Components/Molecules'
import styled from 'styled-components'
import { colors } from 'app/Styles/Variables'
import { Icon, Image } from 'Components/Atoms'
import { IMultiSelectItemProps } from './IMultiSelectItemProps'

const SelectedWrapper = styled.div`
  padding: 9px 0 1px 0px;
  color: ${colors.primaryGrey};
  display: flex;
  align-items: center;
`

interface IClickable {
  onClick?: (option: TSelectOptionTypes) => void
}

const UserNameLabel = styled.span`
  padding: 4px 0 4px 8px;
  font-size: 16px;
  color: ${colors.primaryGreyHover};
  white-space: nowrap;
`

const UserHandleLabel = styled.span`
  padding: 4px 0 4px 8px;
  color: ${colors.primaryGrey};
  font-weight: 300;
`

const AvatarWrapper = styled.span`
  vertical-align: middle;
  border-radius: 50%;
  overflow: hidden;
  box-sizing: border-box;
  z-index: 1;
  transform: translate(0, 0);
  background-size: cover;
`

const IconWrapper = styled.a<IClickable>`
  float: right;
  padding-right: 0px;
  color: ${colors.secondaryGrey};
  &:hover {
    cursor: pointer;
    color: ${colors.black};
  }
`

const EllipsisWrapper = styled.span`
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const MultiSelectUserItem = (props: IMultiSelectItemProps) => (
  <SelectedWrapper key={props.option[props.valueProperty]}>
    <EllipsisWrapper>
      <AvatarWrapper>
        <Image src={props.option.avatar} height={24} borderRadius={'50%'} />
      </AvatarWrapper>
      <UserNameLabel>{props.option.name}</UserNameLabel>
      <UserHandleLabel>@{props.option.handle}</UserHandleLabel>
    </EllipsisWrapper>
    {!props.disabled && (
      <IconWrapper
        onClick={() => {
          props.selected ? props.deselectOption(props.option) : props.selectOption(props.option)
        }}
      >
        <Icon name={'close'} />
      </IconWrapper>
    )}
  </SelectedWrapper>
)
