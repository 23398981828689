import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Cloud = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Cloud" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M37 36v-.016a7.5 7.5 0 0 0-.042-14.97A12.171 12.171 0 0 0 37 20c0-6.627-5.373-12-12-12-5.227 0-9.673 3.342-11.319 8.005C8.306 16.173 4 20.584 4 26c0 5.523 4.477 10 10 10h23ZM14 18.997l-.225.006A7 7 0 0 0 14 33h22.652l.151-.01a4.5 4.5 0 0 0-.025-8.982l-3.066-.184.256-3.06A9 9 0 0 0 25 11a9.004 9.004 0 0 0-8.245 5.384C20.938 17.581 24 21.433 24 26h-3a7 7 0 0 0-7-7v-.003Z" fill="#000" />
  </StyledIcon>
))

Cloud.displayName = 'Cloud'

export { Cloud }
