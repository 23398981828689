import { useCallback } from 'react'

import { DisclosurePanel } from '@cutover/react-ui'
import { PanelHeader } from './panel-header'
import { ContentNode } from '../apps-types'

const PANEL_DEFAULT_HEIGHT = 300

type PanelHeaderKind = 'edit' | 'add' | 'generic'

type PanelNodeHeaderProps = {
  title: string
  kind?: PanelHeaderKind
  content?: ContentNode[]
}

type PanelNodeProps = {
  appId: string
  resourceId?: string
  children: JSX.Element
  header: PanelNodeHeaderProps
}

export const PanelNode = ({ children, appId, resourceId, header: { title, ...header } }: PanelNodeProps) => {
  const renderHeader = useCallback(
    ({ isActive, onClick, isOpen }: any) => {
      return (
        <PanelHeader
          appId={appId}
          resourceId={resourceId}
          title={title}
          isActive={isActive}
          isOpen={isOpen}
          onClick={onClick}
          {...header}
        />
      )
    },
    [appId, header, title, resourceId]
  )

  return (
    <>
      {!header.content ? (
        <DisclosurePanel title={title} defaultHeight={PANEL_DEFAULT_HEIGHT} resizable={true}>
          {children}
        </DisclosurePanel>
      ) : (
        <DisclosurePanel defaultHeight={PANEL_DEFAULT_HEIGHT} renderHeader={renderHeader} resizable={true}>
          {children}
        </DisclosurePanel>
      )}
    </>
  )
}
