import { createTaskFilterFunction, DateWithin, RunbookFilterType, TaskFilterContext } from './filters'
import { TaskListTask } from 'main/services/queries/types'

const within = (option: DateWithin): number => {
  const hour = 3600
  const day = 24 * hour
  const week = 7 * day
  const month = 30 * day
  const year = 12 * month
  let options = {
    hour,
    day,
    halfday: 12 * hour,
    week,
    month,
    '3months': 3 * month,
    year
  }
  return options[option] || 0
}

export const startWithin = createTaskFilterFunction(
  'dd',
  (task: TaskListTask, filters: RunbookFilterType, context: TaskFilterContext): boolean => {
    const now = context.now || 0
    return filters.dd !== undefined && now + within(filters.dd) > task.start_display
  }
)
