import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const ArrowDown = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="ArrowDown" {...props}>
    <path d="m44 22.414-19.799 19.8-19.799-19.8 2.121-2.121L22.701 36.47V5h3v31.47l16.178-16.177L44 22.414Z" fill="#000" />
  </StyledIcon>
))

ArrowDown.displayName = 'ArrowDown'

export { ArrowDown }
