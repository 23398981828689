import { DefaultValue, selector } from 'recoil'
import { groupBy, keyBy } from 'lodash'

import { RunbookCommentsGetResponse } from 'main/services/api/data-providers/runbook-types'
import { RunbookComment } from 'main/services/api/data-providers/runbook-types/runbook-shared-types'
import { runbookAtom } from 'main/recoil/shared/recoil-state-runbook-decorators'
import { runbookIdState, runbookVersionState } from 'main/recoil/runbook'
import { getRunbookComments as getRunbookCommentsQuery } from 'main/services/queries/use-runbook-comments'

// the underlying atom that gets updated with websocket events and is sourced
// from the async selector below. We need an async selector here because loading state
// needs to be displayed in the UI.
export const runbookCommentsResponseState_INTERNAL = runbookAtom<RunbookCommentsGetResponse | null>({
  key: 'runbook-comments:response',
  default: null
})

export const runbookCommentsRequest_INTERNAL = selector({
  key: 'runbook-comments:request',
  get: async ({ get }) => {
    const commentsState = get(runbookCommentsResponseState_INTERNAL)

    if (commentsState) {
      return commentsState
    } else {
      const runbookVersionId = get(runbookVersionState).id
      const runbookId = get(runbookIdState)

      const response = await getRunbookCommentsQuery({ runbookId, runbookVersionId })
      return response
    }
  },
  set: ({ set }, response) => {
    if (response && !(response instanceof DefaultValue)) {
      set(runbookCommentsResponseState_INTERNAL, response)
    }
  }
})

export const runbookCommentsLookup = selector<Record<number, RunbookComment>>({
  key: 'runbook-comments:lookup',
  get: ({ get }) => {
    const { comments } = get(runbookCommentsResponseState_INTERNAL) ?? {}

    return keyBy(comments || [], 'id')
  }
})

export const runbookCommentsPermissionsState = selector({
  key: 'runbook-comments:permissions',
  get: ({ get }) => {
    return get(runbookCommentsResponseState_INTERNAL)?.meta?.permissions
  }
})

export const taskCommentsLookupState = selector<Record<number, RunbookComment[]>>({
  key: 'task-comments:lookup',
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent'
  },
  get: ({ get }) => {
    const { comments } = get(runbookCommentsResponseState_INTERNAL) ?? {}
    const taskComments = comments?.filter(comment => !!comment.task_id)

    return groupBy(taskComments || [], 'task_internal_id')
  }
})
