import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const OpenNew = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="OpenNew" {...props}>
    <path d="M22 6h-8a8 8 0 0 0-8 8v20a8 8 0 0 0 8 8h20a8 8 0 0 0 8-8v-8h-3v8a5 5 0 0 1-5 5H14a5 5 0 0 1-5-5V14a5 5 0 0 1 5-5h8V6Z" fill="#000" /><path d="M26.738 6H42.01v15.272h-3v-10.15L23.121 27.01 21 24.89 36.89 9H26.737V6Z" fill="#000" />
  </StyledIcon>
))

OpenNew.displayName = 'OpenNew'

export { OpenNew }
