// @ts-nocheck
import 'reflect-metadata'
import { injectable, inject } from 'inversify'
import { observable, action } from 'mobx'
import { AuthenticationRepository } from 'app/Authentication/AuthenticationRepository'
import { Routes } from './Routes'
import { RoutingState } from './RoutingState'

@injectable()
export class RouteUpdater {
  @inject(AuthenticationRepository)
  private authenticationRepository: AuthenticationRepository

  @inject(RoutingState)
  private routingState: RoutingState

  @observable
  public routes: IRoute[] = []

  constructor() {
    this.routes = new Routes().routes
  }

  @observable
  private leaveFunctions: { routeId: string; callback: () => void }[] = []

  @observable
  private enterFunctions: { routeId: string; callback: () => void }[] = []

  @action
  public registerRouteEnterCallback = (enterCallbackObject: { routeId: string; callback: () => void }) => {
    this.enterFunctions.push(enterCallbackObject)
  }

  @action
  public registerRouteLeaveCallback = (leaveCallbackObject: { routeId: string; callback: () => void }) => {
    this.leaveFunctions.push(leaveCallbackObject)
  }

  public logoutUser = () => {
    // should use the handleErrorResponse but this creates a circular dependency
    // @ts-ignore
    window.angularJS.handleError({ status: 403, config: { url: '' } })
  }

  public canView = (routeId: string): boolean => {
    const targetRoute = this.routes.find(route => route.routeId === routeId)
    if (!targetRoute) return false
    if (!targetRoute.routeDef.permissionId) return true
    return this.authenticationRepository.canView(targetRoute.routeDef.permissionId)
  }

  public updateCurrentRoute = async (routeId: string, params?: any, query?: any, source?: string) => {
    if (!this.canView(routeId)) {
      this.logoutUser()
      return
    }

    const oldRouteId = this.routingState.currentState.routeId
    const routeChanged = oldRouteId !== routeId
    const targetRoute = this.findRoute(routeId).routeId

    if (routeChanged && oldRouteId) await this.processCallbacks(oldRouteId, this.leaveFunctions)
    if (routeChanged) await this.processCallbacks(targetRoute, this.enterFunctions)
    this.updateRouteDetails(targetRoute, params, query)
  }

  public findRoute(routeId) {
    const route = this.routes.find(route => {
      return route.routeId === routeId
    })
    return route || { routeId: 'loadingSpinner', routeDef: { path: '' } }
  }

  @action
  private updateRouteDetails = (routeId, params, query) => {
    this.routingState.currentState.routeId = routeId
    this.routingState.currentState.params = params
    this.routingState.currentState.query = query
  }

  private processCallbacks = async (routeId, outerObj) => {
    if (routeId === null) return

    if (outerObj.length > 0) {
      await outerObj.forEach(async obj => {
        if (obj.routeId === routeId || obj.routeId === '') {
          await obj.callback()
        }
      })
    }
  }
}
