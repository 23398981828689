// @ts-nocheck
import { observable, action } from 'mobx'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'
import { Types } from 'Gateways/Service/Types'
import { ButtonPresenter } from 'Components/Molecules'
import { container } from 'app/Config/IOC'

export class ModalPresenter {
  private language: ILanguageService = container.get(Types.ILanguageService)

  @observable
  public isOpen: boolean = false

  @observable
  public fixedHeader: boolean = false

  @observable
  public ariaLabelLedBy?: string

  @observable
  public maxContainerHeight?: number

  @observable
  public onCloseExtra: () => void

  @action
  public onOpen? = () => {
    this.isOpen = true
  }

  @action
  public onClose? = () => {
    this.isOpen = false

    if (this.onCloseExtra) {
      this.onCloseExtra()
    }
  }

  @observable
  public closeButtonPresenter = new ButtonPresenter('', this.onClose)
    .withIconName('close')
    .withDisplayStyle('secondary-invert')
    .withTextTransform('Lowercase')
    .withAriaLabel(this.language.get('common:ariaLabels:close'))

  @action
  public withOnCloseExtra = (onCloseExtra: () => void) => {
    this.onCloseExtra = onCloseExtra
    return this
  }

  @action
  public withMaxContainerHeight = (maxContainerHeight: number) => {
    this.maxContainerHeight = maxContainerHeight
    return this
  }
}
