// @ts-nocheck
import * as Toastr from 'toastr'
import { TToasterState } from './TToasterState'
import { injectable } from 'inversify'

@injectable()
export class ToasterService {
  public pop(title: string, messageArg: string, state: TToasterState) {
    Toastr[state](messageArg, title, {
      closeButton: true,
      debug: false,
      newestOnTop: false,
      progressBar: false,
      positionClass: 'toast-bottom-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: 300,
      hideDuration: 1000,
      timeOut: 5000,
      extendedTimeOut: 1000,
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut'
    })
  }
}
