import { ButtonHTMLAttributes, SyntheticEvent, useEffect, useState } from 'react'
import { Controller, FormProvider, useFormContext } from 'react-hook-form'
import styled, { css } from 'styled-components'

import {
  Box,
  Button,
  Checkbox,
  CodeEditor,
  Icon,
  IconButton,
  Message,
  SanitizedString,
  Text,
  TextInput,
  themeColor
} from '@cutover/react-ui'
import { CreateNewPublicCertModal } from './modals/create-new-public-cert-modal'
import { useScrollIntoView } from 'main/components/shared/hooks/use-scroll-into-view'
import { useLanguage } from 'main/services/hooks'
import { usePermissions } from 'main/services/queries/use-permissions'
import { SamlConfigurationFormDataType } from './modals/create-new-saml-config-modal'

export type SamlConfigurationsFormType = {
  samlConfiguration: SamlConfigurationFormDataType
  apiError?: string
  isDisabled?: boolean
}

type Hash = {
  [key: string]: string
}

export const SamlConfigurationForm = ({ samlConfiguration, apiError, isDisabled }: SamlConfigurationsFormType) => {
  const { t } = useLanguage('samlConfigurations')
  const methods = useFormContext<SamlConfigurationFormDataType>()
  const [formErrorMessages, setFormErrorMessages] = useState(apiError ? [apiError] : [])
  const [generalFormErrors, setGeneralFormErrors] = useState<{ [key: string]: { message: string } }>({})
  const [certificateFormErrors, setCertificateFormErrors] = useState<{ [key: string]: { message: string } }>({})
  const tabGroups: Hash = {
    general: 'General',
    certificates: 'Public Certificates'
  }

  const [selectedTab, setSelectedTab] = useState(tabGroups.general)
  const [isCreateNewCertModalOpen, setIsCreateNewCertModalOpen] = useState(false)
  const [tabsInError, setTabsInError] = useState<string[] | undefined>(undefined)
  const { ref: tabNavigationRef, scrollIntoView: scrollTabsIntoView } = useScrollIntoView()

  const permissions = usePermissions('saml-configs')
  const cutoverStaffUser = permissions('manage')

  const formatDate = (inputDate: string) => {
    if (inputDate === 'Expiry not found' || inputDate === 'N/A') {
      return inputDate
    }
    const date = new Date(inputDate)
    const day = date.getDate()
    const month = date.getMonth() + 1
    const year = date.getFullYear()
    return `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`
  }

  const {
    register,
    control,
    getValues,
    watch,
    formState: { errors, isSubmitting }
  } = methods

  const deleteCertificate = (index: number) => {
    samlConfiguration?.idp_cert_multi?.signing?.splice(index, 1)
    samlConfiguration?.certificates_with_metadata?.meta_data?.splice(index, 1)
    let certs = samlConfiguration?.idp_cert_multi?.signing
    certs = !certs || certs?.length === 0 ? [] : certs
    methods.setValue('idp_cert_multi.signing', certs as string[], {
      shouldTouch: true,
      shouldValidate: true,
      shouldDirty: true
    })
  }

  const addCertificate = (newCert: string | undefined) => {
    if (!newCert || newCert.length === 0) {
      return
    }

    setIsCreateNewCertModalOpen(false)
    if (samlConfiguration?.idp_cert_multi === null) {
      samlConfiguration.idp_cert_multi = { signing: [''] }
    } else {
      samlConfiguration?.idp_cert_multi?.signing?.push('')
    }

    samlConfiguration?.certificates_with_metadata?.meta_data?.push({
      cn: 'New Certificate',
      expiry: 'N/A'
    })

    const previousCerts = methods.getValues('idp_cert_multi.signing')
    methods.setValue('idp_cert_multi.signing', previousCerts === null ? [newCert] : previousCerts.concat(newCert))
    methods.resetField('certificate')
  }

  const isErrorFor = (property: string) => {
    return property in errors
  }

  const computeTabsInError = () => {
    setTabsInError(undefined)
    const tabs: string[] = []

    if (Object.keys(certificateFormErrors).length != 0) {
      tabs.push('certificates'.toLowerCase())
    }

    if (Object.keys(generalFormErrors).length != 0) {
      tabs.push('general'.toLowerCase())
    }
    setTabsInError(tabs)
  }

  const isTabInError = (group: string) => {
    return tabsInError?.includes(group.toLowerCase())
  }

  const formatErrors = (errors: object) => {
    return Object.values(errors)
      .map(error => error?.message)
      .join(', ')
  }

  const setActiveTabClassName = (tab: string) => {
    if (selectedTab === tab) {
      return true
    }
  }

  const selectTab = (event: SyntheticEvent, tab: string) => {
    setSelectedTab(tab)
    event.preventDefault()
  }

  const tabNavigation = () => {
    return (
      <>
        <Box
          ref={tabNavigationRef}
          direction="row"
          gap="small"
          css={`
            flex-shrink: 0;
            margin-bottom: 16px;
          `}
        >
          {Object.keys(tabGroups).map(group => {
            return (
              <StyledTabButton
                key={group}
                active={setActiveTabClassName(tabGroups[group])}
                onClick={e => selectTab(e, tabGroups[group])}
              >
                <Box gap="xsmall" direction="row" align="center">
                  <Box>{tabGroups[group]}</Box>
                  {isTabInError(group) && (
                    <Box>
                      <Icon icon="alert" color="error" />
                    </Box>
                  )}
                </Box>
              </StyledTabButton>
            )
          })}
        </Box>
      </>
    )
  }

  useEffect(() => {
    computeTabsInError()
  }, [certificateFormErrors, generalFormErrors])

  useEffect(() => {
    setFormErrorMessages(apiError ? [apiError] : [])
  }, [apiError])

  useEffect(() => {
    if (!isSubmitting) return

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { certificate, idp_cert_multi, ...generalErrors } = errors
    setGeneralFormErrors((generalErrors ?? {}) as {})
    setCertificateFormErrors((idp_cert_multi ?? {}) as {})
    scrollTabsIntoView()
  }, [isSubmitting, errors])

  useEffect(() => {
    if (!Object.keys(methods.formState.dirtyFields?.options || {}).includes('active_roles')) return

    const { options } = getValues()
    methods.setValue('options.purge_roles', options.active_roles)
  }, [methods.formState.dirtyFields?.options?.active_roles])

  useEffect(() => {}, [watch('options.purge_roles')])

  useEffect(() => {
    methods.reset(samlConfiguration)
    setGeneralFormErrors({})
    setCertificateFormErrors({})
    setSelectedTab(tabGroups.general)
    setTabsInError(undefined)
    setFormErrorMessages([])
  }, [])

  return (
    <>
      {tabNavigation()}
      <Box>
        <FormProvider {...methods}>
          {formErrorMessages && formErrorMessages.length > 0 ? (
            <Box
              css={`
                margin-bottom: 16px;
              `}
            >
              <Message message={formErrorMessages} type="error" />
            </Box>
          ) : null}
          {selectedTab == tabGroups.general && (
            <>
              <Box margin={{ vertical: 'xsmall' }} style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>
                <Message type="info" message={t('samlConfigurations.formData.info')} />
              </Box>
              {Object.keys(generalFormErrors).length != 0 && (
                <Box margin={{ top: 'small', bottom: 'small' }}>
                  <Message message={t('samlConfigurations.formData.genericErrorMessage')} type="error" />
                </Box>
              )}
              {samlConfiguration?.id && <TextInput {...register('id')} label={'Id'} disabled={true} />}
              <TextInput
                {...register('name')}
                label={t('samlConfigurations.formData.displayName.label')}
                tooltipText={t('samlConfigurations.formData.displayName.tipText')}
                hasError={isErrorFor('name')}
                inlineError={errors?.name?.message}
                disabled={isDisabled}
                required
              />

              <TextInput
                {...register('idp_sso_service_url')}
                label={t('samlConfigurations.formData.idpSsoServiceServiceUrl.label')}
                tooltipText={t('samlConfigurations.formData.idpSsoServiceServiceUrl.tipText')}
                hasError={isErrorFor('idp_sso_service_url')}
                inlineError={errors?.idp_sso_service_url?.message}
                disabled={isDisabled}
                required
              />
              {samlConfiguration?.id && (
                <TextInput
                  {...register('custom_acs_url')}
                  label={t('samlConfigurations.formData.customAcsUrl.label')}
                  tooltipText={t('samlConfigurations.formData.customAcsUrl.tipText')}
                  hasError={isErrorFor('custom_acs_url')}
                  inlineError={errors?.custom_acs_url?.message}
                  disabled={isDisabled}
                  required
                />
              )}
              {samlConfiguration?.id && (
                <TextInput
                  {...register('sp_entity_id')}
                  label={t('samlConfigurations.formData.spEntityId.label')}
                  tooltipText={t('samlConfigurations.formData.spEntityId.tipText')}
                  hasError={isErrorFor('sp_entity_id')}
                  inlineError={errors?.sp_entity_id?.message}
                  disabled={isDisabled}
                  required
                />
              )}
              <TextInput
                {...register('allowed_clock_drift')}
                label={t('samlConfigurations.formData.allowedClockDrift.label')}
                hasError={isErrorFor('allowed_clock_drift')}
                tooltipText={t('samlConfigurations.formData.allowedClockDrift.tipText')}
                inlineError={errors?.allowed_clock_drift?.message}
                disabled={isDisabled}
                required
              />

              {/* Note - hacking this styling and the boxes below until replaced with proper comp */}
              <Box
                css={`
                  flex-shrink: 0;
                  margin-bottom: 16px;
                `}
              >
                <Text
                  color="text-light"
                  css={`
                    font-size: 13px;
                    font-weight: 400;
                  `}
                >
                  {t('samlConfigurations.editPanel.main.attributeMappings.label')}
                </Text>
                <Controller
                  name={'attribute_mappings'}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <CodeEditor
                      onChange={value => onChange(value)}
                      value={typeof value === 'string' ? value : JSON.stringify(value)}
                      defaultLanguage="json"
                      resize="vertical"
                      minHeight={100}
                      readOnly={isDisabled}
                    />
                  )}
                />
              </Box>

              <Box
                css={`
                  flex-shrink: 0;
                  margin-bottom: 16px;
                `}
              >
                <Text
                  color="text-light"
                  css={`
                    font-size: 13px;
                    font-weight: 400;
                  `}
                >
                  {t('samlConfigurations.editPanel.main.additionalSettings.label')}
                </Text>
                <Checkbox
                  {...register('default')}
                  checked={Boolean(getValues('default'))}
                  label={t('samlConfigurations.formData.default.label')}
                  helpText={t('samlConfigurations.formData.default.tooltip')}
                  disabled={isDisabled}
                />
                {cutoverStaffUser && (
                  <Checkbox
                    {...register('hidden')}
                    checked={Boolean(getValues('hidden'))}
                    label={t('samlConfigurations.formData.hidden.label')}
                    helpText={t('samlConfigurations.formData.hidden.tooltip')}
                    disabled={isDisabled}
                  />
                )}
                <Checkbox
                  {...register('options.active_roles')}
                  checked={Boolean(getValues('options.active_roles'))}
                  label={t('samlConfigurations.formData.useRBAC.label')}
                  helpText={t('samlConfigurations.formData.useRBAC.tooltip')}
                  disabled={isDisabled}
                />
                <Checkbox
                  {...register('options.purge_roles')}
                  checked={Boolean(getValues('options.purge_roles'))}
                  disabled={!Boolean(getValues('options.active_roles')) || isDisabled}
                  label={t('samlConfigurations.formData.purgeRoles.label')}
                  helpText={t('samlConfigurations.formData.purgeRoles.tooltip')}
                />
              </Box>
            </>
          )}
          {selectedTab == tabGroups.certificates && (
            <Box>
              {Object.keys(certificateFormErrors).length != 0 && (
                <Box margin={{ top: 'small', bottom: 'small' }}>
                  <Message message={formatErrors(certificateFormErrors) as string} type="error" />
                </Box>
              )}
              <Box direction="column" gap="large">
                {samlConfiguration?.idp_cert_multi?.signing?.map((cert, index) => (
                  <Box key={index}>
                    <>
                      <Box
                        direction="row"
                        gap="small"
                        justify="between"
                        css={`
                          background-color: transparent;
                          transition: background-color;
                          &:hover {
                            background-color: ${themeColor('bg-2')};
                          }
                        `}
                      >
                        <SanitizedString
                          input={`<strong>${
                            samlConfiguration?.certificates_with_metadata?.meta_data?.[index]?.cn
                          }</strong> <br>
                          Expiry date: ${formatDate(
                            samlConfiguration?.certificates_with_metadata?.meta_data?.[index]?.expiry
                          )}`}
                        />
                        <IconButton
                          label={t('samlConfigurations.editPanel.certificates.deleteButton.label')}
                          icon="close"
                          tipPlacement="top"
                          disabled={isDisabled}
                          onClick={e => {
                            e.stopPropagation()
                            deleteCertificate(index)
                          }}
                        ></IconButton>
                      </Box>
                    </>
                  </Box>
                ))}
                <Button
                  label={t('samlConfigurations.editPanel.certificates.addCertButton.label')}
                  secondary
                  full
                  icon="add"
                  disabled={isDisabled}
                  onClick={() => {
                    setIsCreateNewCertModalOpen(true)
                  }}
                />
              </Box>
            </Box>
          )}
          <CreateNewPublicCertModal
            open={isCreateNewCertModalOpen}
            setOpen={setIsCreateNewCertModalOpen}
            onModalConfirm={addCertificate}
            setCertificateFormErrors={setCertificateFormErrors}
          />
        </FormProvider>
      </Box>
    </>
  )
}

const StyledTabButton = styled.button<ButtonHTMLAttributes<HTMLButtonElement> & { active?: boolean }>`
  line-height: 40px;
  ${props => css`
    border-bottom: 4px solid ${themeColor(props.active ? 'primary' : 'transparent')};
    font-weight: ${props.active ? 600 : 400};
    color: ${themeColor(props.active ? 'primary' : 'text-light')};
  `}
`
