import { RecursivePartial } from '@cutover/utility-types'

/*
  Depreceated theme for the old UI style
*/

const brandColor = '#2A55C3'
const focusColor = brandColor
const errorColor = '#D62300' //NOTE success/warning/error colours will change in dark mode too
const textColor = {
  onLight: {
    base: 'rgba(22,22,29,1)',
    light: 'rgba(22,22,29,0.62)',
    lighter: 'rgba(22,22,29,0.62)', //can remove this as we only have 3 text colours
    lightest: 'rgba(22,22,29,0.37)' // calling this one 'disabled'
  },
  // NOTE: currently not meaningful colors, just for placeholder to match new design system pattern
  onDark: {
    base: '#d8dddf',
    light: '#b9c1c6',
    lighter: '#97a4aa',
    lightest: '#57646b'
  }
}

export const colors = {
  brand: brandColor,
  focus: focusColor,
  error: errorColor,
  text: textColor
}

export type ThemeType = {
  formField: ThemeFormFieldType & {
    focus: RecursivePartial<ThemeFormFieldType>
    error: RecursivePartial<ThemeFormFieldType>
    disabled: RecursivePartial<ThemeFormFieldType>
    readonly: RecursivePartial<ThemeFormFieldType>
    helpIcon: ThemeIconType
    input: ThemeTextType & {
      placeholder: ThemeTextType
      disabled?: ThemeTextType
      readonly?: ThemeTextType
      error?: ThemeTextType
    }
  }
  timePicker: ThemeTimePickerType
}

type ThemeIconType = {
  color?: string
  size?: number // should be string
  hoverColor?: string
}

type ThemeTextType = {
  color?: string
  size?: string
  weight?: string
}

type ThemeBorderType = {
  color?: string
  width?: string
  style?: string
}

type ThemeFormFieldType = {
  border: ThemeBorderType
  label: ThemeTextType
  icon: ThemeIconType
}

type ThemeTimePickerType = {
  arrow: {
    size: number
    color: string
    hoverColor: string
  }
}

export const theme: ThemeType = {
  // below should have an outline for accessibility!!!
  // global: {
  //   focus: {
  //     border: {
  //       color: colors.focus
  //     }
  //   }
  // }
  formField: {
    border: {
      color: colors.text.onLight.lightest,
      width: '1px',
      style: 'dashed'
    },
    label: {
      color: colors.text.onLight.light
    },
    icon: {
      color: colors.text.onLight.light,
      size: 18
    },
    focus: {
      border: {
        color: colors.brand
      },
      label: {
        color: colors.brand
      },
      icon: {
        color: colors.text.onLight.light
      }
    },
    error: {
      border: {
        color: colors.error
      },
      label: {
        color: colors.error
      },
      icon: {
        color: colors.error
      }
    },
    disabled: {
      // opacity: ...?
      label: {
        color: colors.text.onLight.lighter
      },
      icon: {
        color: colors.text.onLight.lighter
      }
    },
    readonly: {
      // opacity: ...?
    },
    input: {
      color: colors.text.onLight.base,
      placeholder: {
        color: colors.text.onLight.lightest
      },
      disabled: {
        color: colors.text.onLight.light
      }
    },
    helpIcon: {
      color: colors.text.onLight.lightest,
      size: 16,
      hoverColor: colors.text.onLight.light
    }
  },
  timePicker: {
    arrow: {
      size: 26,
      color: colors.text.onLight.light,
      hoverColor: colors.text.onLight.base
    }
  }
}
