// @ts-nocheck
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { formatDate } from 'Shared/Helpers/DateService'
import { colors } from 'app/Styles/Variables'
import { UserEditHistoryPresenter } from 'app/Access/Users/UsersPage/UsersEdit/UserEditHistoryPresenter'
import { Accordion, Button } from 'Components/Molecules'

const HistoryContainer = styled.div``

const DateContainer = styled.div`
  border-bottom: 1px dashed ${colors.primaryGreyHoverBackgroundColor};
  margin-bottom: 5px;
  color: ${colors.primary};
`

const DescriptionContainer = styled.div`
  margin-bottom: 10px;
`

interface IUserEditHistoryComponentProps {
  presenter: UserEditHistoryPresenter
}

export const UserEditHistorySection = observer<IUserEditHistoryComponentProps>(({ presenter }) => {
  const { accordionPresenter, history, csvButtonPresenter } = presenter

  const historyList = history.map((historyItem, index) => {
    return (
      <HistoryContainer key={index}>
        <DateContainer>{formatDate(new Date(historyItem.date), 'dd MMM yyyy HH:mm')}</DateContainer>
        <DescriptionContainer>{historyItem.description}</DescriptionContainer>
      </HistoryContainer>
    )
  })
  return (
    <>
      <Accordion presenter={accordionPresenter}>
        {historyList}
        <Button presenter={csvButtonPresenter} />
      </Accordion>
    </>
  )
})
