import { useMutation, useQuery } from 'react-query'

import { CamelToSnakeCaseNested } from '@cutover/utility-types'
import { useSetPermissions } from './use-permissions'
import { queryClient } from 'main/query-client'
import { apiClient } from 'main/services/api'
import { QueryKeys } from 'main/services/queries/query-keys'
import { DataSource } from 'main/services/queries/types'

export type BaseDataSourceResponse = {
  meta: {
    permissions: { [key: string]: number[] }
  }
}

export type DataSourceResponse = BaseDataSourceResponse & {
  data_source?: DataSource
  json_data_source?: DataSource
}

export type RefreshDataSourceResponse = {
  total_added: number
}

export type DataSourcesResponse = {
  meta: {
    permissions: { [key: string]: number[] }
  }
  data_sources: DataSource[]
}

export type DataSourceSearchFormType = {
  customFieldId: number | undefined
  query: string | number
  parentValue?: string
  taskId?: number | null | undefined
}

export type DataSourceSearchDataType = {
  query: {
    parent_value?: string
    query_string: string | number
  }
  task_id: number | null | undefined
}

type DataSourceRefreshFormType = {
  query: string
  field_value_id: number
}

export type DataSourceRefreshRequestType = {
  customFieldId: number
  values: DataSourceRefreshFormType[]
}

export type TestResultsResponse = {
  status?: number
  body?: string
}

export function useDataSourcesQuery() {
  const setPermissions = useSetPermissions('data-sources')

  return useQuery<DataSourcesResponse, Error, DataSourcesResponse>(
    [QueryKeys.DataSources],
    async () => {
      const { data } = await apiClient.get<DataSourcesResponse>({
        url: 'data_sources'
      })
      return data
    },
    {
      onSuccess: data => {
        setPermissions(data.meta.permissions)
      }
    }
  )
}

export function useDataSource(dataSourceId: number | null) {
  const setPermissions = useSetPermissions('data-source')

  return useQuery<DataSourceResponse, Error, DataSourceResponse>(
    [QueryKeys.DataSource, dataSourceId],
    async () => {
      const { data } = await apiClient.get<DataSourceResponse>({
        url: `data_sources/${dataSourceId}`
      })

      return data as DataSourceResponse
    },
    {
      onSuccess: data => {
        setPermissions(data.meta.permissions)
      },
      enabled: !!dataSourceId
    }
  )
}

export function useSearchDataSourceMutation() {
  return useMutation<unknown, Error, DataSourceSearchFormType>(
    [QueryKeys.DataSources],
    async (values: DataSourceSearchFormType): Promise<any> => {
      const response = await apiClient.post<DataSourceSearchDataType, any>({
        url: `custom_fields/${values.customFieldId}/search_remote_data`,
        data: { query: { query_string: values.query, parent_value: values.parentValue }, task_id: values.taskId }
      })
      return response?.data
    }
  )
}

export function useRefreshDataSourceMutation() {
  return useMutation<unknown, Error, DataSourceRefreshRequestType>(
    [QueryKeys.DataSources],
    async (data: DataSourceRefreshRequestType): Promise<any> => {
      const response = await apiClient.post<Omit<DataSourceRefreshRequestType, 'customFieldId'>, any>({
        url: `custom_fields/${data.customFieldId}/refresh_remote_data`,
        data: { values: data.values }
      })
      return response?.data
    }
  )
}

export function useCreateDataSourceMutation() {
  return useMutation<DataSourceResponse, Error, CamelToSnakeCaseNested<DataSource>>(
    QueryKeys.DataSource,
    async (payload): Promise<DataSourceResponse> => {
      const { data } = await apiClient.post<{ data_source: CamelToSnakeCaseNested<DataSource> }, any>({
        url: 'data_sources',
        data: { data_source: payload }
      })

      // Due to STI pattern used in the core backend we need to check both attributes to default to `data_source`.
      data.data_source = data.data_source || data.json_data_source
      delete data.json_data_source

      return data as DataSourceResponse
    }
  )
}

export function useUpdateDataSourceMutation() {
  return useMutation<DataSourceResponse, Error, CamelToSnakeCaseNested<DataSource>>(
    QueryKeys.DataSource,
    async (payload): Promise<DataSourceResponse> => {
      const id = payload.id
      delete payload.id
      const { data } = await apiClient.patch<{ data_source: CamelToSnakeCaseNested<DataSource> }, any>({
        url: `data_sources/${id}`,
        data: { data_source: payload }
      })

      // Due to STI pattern used in the core backend we need to check both attributes to default to `data_source`.
      data.data_source = data.data_source || data.json_data_source
      delete data.json_data_source

      return data as DataSourceResponse
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKeys.DataSources)
      }
    }
  )
}

export function useTestDataSourceMutation() {
  return useMutation<TestResultsResponse, Error, any>(
    QueryKeys.DataSource,
    async (payload): Promise<TestResultsResponse> => {
      const { data } = await apiClient.get<TestResultsResponse>({
        url: `data_sources/${payload.id}/test?query_string=${payload.query.query_string}`
      })

      return data as TestResultsResponse
    }
  )
}

export function useArchiveDataSourceMutation() {
  return useMutation<DataSourceResponse, Error, { id: number }>(
    QueryKeys.DataSource,
    async ({ id }): Promise<DataSourceResponse> => {
      const { data } = await apiClient.patch<CamelToSnakeCaseNested<DataSource>, any>({
        url: `data_sources/${id}/archive`
      })

      return data as DataSourceResponse
    }
  )
}

export function useRefresheDataSourceMutation() {
  return useMutation<RefreshDataSourceResponse, Error, { id?: number; query_string: string }>(
    QueryKeys.DataSource,
    async (payload): Promise<RefreshDataSourceResponse> => {
      const id = payload.id
      delete payload.id
      const { data } = await apiClient.patch<{ query_string: string }, any>({
        url: `data_sources/${id}/refresh`,
        data: payload
      })

      return data as RefreshDataSourceResponse
    }
  )
}
