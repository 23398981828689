import { KeyboardEventHandler, ReactElement, useEffect, useRef, useState } from 'react'
import { VirtuosoHandle } from 'react-virtuoso'
import styled from 'styled-components/macro'

import { Box, Button, InfiniteList, resolveColor, SearchBar, themeElevation, useTheme } from '@cutover/react-ui'
import { KeyCodeLookup } from 'app/Shared/Constants/Keycodes'
import { useLanguage } from 'main/services/hooks'
import { SelectedTeam, SelectedUserTeam } from './../types'

export type DynamicSearchBarProps = {
  width?: number | string
  onSearch: (search: string) => void
  search: string
  minLength?: number
  placeholder: string
  items: SelectedUserTeam[] | undefined
  itemHeight?: number
  loading?: boolean
  hasNextPage?: boolean | undefined
  fetchNextPage?: () => Promise<any>
  isFetchingNextPage?: boolean
  renderItem: (item: SelectedUserTeam) => ReactElement | null
  setSelectedItem?: (item: SelectedUserTeam) => void
  initialItemCount?: number
  disabled?: boolean
  hasCreateCustomTeam?: boolean
  customTeam?: SelectedTeam
}

export function DynamicSearchBar({
  width = 400,
  onSearch,
  search,
  minLength = 3,
  placeholder,
  items,
  itemHeight = 32,
  loading,
  hasNextPage,
  fetchNextPage,
  isFetchingNextPage,
  renderItem,
  setSelectedItem,
  // initialItemCount used for test only
  initialItemCount,
  disabled,
  hasCreateCustomTeam = true,
  customTeam
}: DynamicSearchBarProps) {
  const listRef = useRef<VirtuosoHandle>(null)
  const initIndex = hasCreateCustomTeam ? -1 : 0
  const [currentItemIndex, setCurrentItemIndex] = useState<number>(initIndex - 1)
  const [isSearchCleared, setIsSearchCleared] = useState<boolean>(false)
  const [isSearchFocusSet, setIsSearchFocusSet] = useState<boolean>(false)
  const [isOpen, setIsOpen] = useState(true)
  const { t } = useLanguage('runbook', { keyPrefix: 'addUsersTeams' })

  const onSelect = (item: SelectedUserTeam) => {
    setIsOpen(false)
    setIsSearchCleared(true)
    setIsSearchFocusSet(true)
    setSelectedItem?.(item)
  }

  const addCustomTeam = () => {
    if (search && customTeam) {
      customTeam.name = search
      onSelect(customTeam)
    }
  }

  const onKeyDown: KeyboardEventHandler = event => {
    if (!search) return null

    let nextIndex: number | null = null

    if (event.keyCode === KeyCodeLookup.enter || event.key === 'Enter') {
      event.preventDefault()
      event.stopPropagation()
      if (hasCreateCustomTeam && currentItemIndex === initIndex) {
        addCustomTeam()
      } else {
        const currentItem = items?.[currentItemIndex] as SelectedUserTeam
        if (!!currentItem) {
          onSelect(currentItem)
        }
      }
    }

    if (event.keyCode === KeyCodeLookup.up || event.key === 'ArrowUp') {
      nextIndex = Math.max(initIndex, currentItemIndex - 1)
    } else if (event.keyCode === KeyCodeLookup.down || event.key === 'ArrowDown') {
      const itemsCount = items ? items.length - 1 : 0
      nextIndex = Math.min(itemsCount, currentItemIndex + 1)
    }

    if (nextIndex !== null && listRef !== null) {
      setCurrentItemIndex(nextIndex)

      listRef?.current &&
        listRef?.current.scrollIntoView({
          index: nextIndex,
          behavior: 'auto'
        })
      event.preventDefault()
    }
  }

  useEffect(() => {
    setCurrentItemIndex(initIndex - 1)
    setIsSearchCleared(false)
    setIsSearchFocusSet(false)
    if (search && !isOpen) {
      setIsOpen(true)
    }
  }, [search])

  const onInputSearch = (value: string) => {
    if (value && value.length >= minLength) {
      onSearch(value)
    } else {
      onSearch('')
    }
  }

  const theme = useTheme()
  const activeIndexColor = resolveColor('bg-2', theme)

  return (
    <Box
      css={`
        width: ${typeof width === 'number' ? width + 'px' : width};
        position: relative;
      `}
      data-cy="users-teams-search"
    >
      <Box
        css={`
          z-index: 101;
          position: relative;
        `}
      >
        <SearchBar
          onSearch={onInputSearch}
          placeholder={placeholder}
          open
          onKeyDown={onKeyDown}
          loading={loading}
          isSearchCleared={isSearchCleared}
          isFocusSet={isSearchFocusSet}
          width={width}
          disabled={disabled}
          delay={300}
        />
      </Box>
      {((hasCreateCustomTeam && search.length > 2) || (items && items.length > 0)) && isOpen && (
        <Container
          itemHeight={itemHeight}
          data-testid="dynamic-search-bar-list"
          onMouseLeave={() => setCurrentItemIndex(initIndex - 1)}
          background="bg"
        >
          {hasCreateCustomTeam && (
            <Box css="margin-bottom: 4px;">
              <Button secondary full label={t('addAsCustomTeam')} icon="add" size="medium" onClick={addCustomTeam} />
            </Box>
          )}
          {items && items.length > 0 && (
            <InfiniteList<SelectedUserTeam>
              setRef={listRef}
              items={items}
              hasNextPage={hasNextPage}
              fetchNextPage={fetchNextPage}
              isFetchingNextPage={isFetchingNextPage}
              initialItemCount={initialItemCount}
              renderItem={(index, item) => {
                return (
                  <Box
                    data-testid="dynamic-search-bar-item"
                    align="center"
                    css={`
                      background-color: ${index === currentItemIndex ? activeIndexColor : 'transparent'} !important;
                      border-radius: 8px;
                      cursor: pointer;
                    `}
                    onClick={() => onSelect(item)}
                  >
                    {renderItem(item)}
                  </Box>
                )
              }}
              height={itemHeight * Math.min(4, items.length)}
            />
          )}
        </Container>
      )}
    </Box>
  )
}

const Container = styled(Box)<{ itemHeight: number }>`
  position: absolute;
  left: 0;
  top: 48px;
  width: 100%;
  max-height: ${({ itemHeight }) => `${itemHeight * 5 + 44}px`};
  overflow: hidden;
  margin: 0;
  padding: 8px;
  outline: none;
  border-radius: 16px;
  z-index: 100;
  box-shadow: ${themeElevation('medium')};
`
