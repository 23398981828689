// @ts-nocheck
import { inject, injectable } from 'inversify'
import { computed, observable } from 'mobx'
import { UsersRepository } from 'app/Repositories/User/UsersRepository'
import { UserPageFilterPanelPresenter } from 'app/Access/Users/UsersPage/UsersPageFilterPanel/UserPageFilterPanelPresenter'
import { UserPageHeadingExportPopoverPresenter } from 'app/Access/Users/UsersPage/UsersPageHeading/UserPageHeadingExportPopoverPresenter'
import { ButtonPresenter, PillFilterPresenter, SearchBoxPresenter } from 'Components/Molecules'
import { Types } from 'Gateways/Service/Types'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'
import { container } from 'app/Config/IOC'

@injectable()
export class UserPageHeadingPresenter {
  @inject(UsersRepository)
  private usersRepository: UsersRepository

  @inject(UserPageFilterPanelPresenter)
  private userPageFilterPanelPresenter: UserPageFilterPanelPresenter

  public language: ILanguageService = container.get(Types.ILanguageService)

  @computed
  public get totalUserCount(): number {
    return this.usersRepository.usersTotal
  }

  @computed
  public get filteredUserCount(): number {
    return this.usersRepository.usersFilteredTotal
  }

  @observable
  public searchBoxPresenter = new SearchBoxPresenter()

  @observable
  public accountPillsPresenter = new PillFilterPresenter({ group: 'account', type: 'group' }).withTitle(
    this.language.get('users:filter:accountGroup:label')
  )

  @observable
  public roleTypesPillsPresenter = new PillFilterPresenter({ group: 'roleType', type: 'group' }).withTitle(
    this.language.get('users:filter:roleTypeGroup:label')
  )

  @observable
  public communicationPillsPresenter = new PillFilterPresenter({ group: 'communications', type: 'group' })

  @observable
  public accessPillsPresenter = new PillFilterPresenter({ group: 'access', type: 'group' })

  @observable
  public searchPillPresenter = new PillFilterPresenter({ type: 'single', key: { reference: 'query' } }).withTitle(
    this.language.get('users:filter:searchLabel')
  )

  @observable
  public exportPopoverPresenter = new UserPageHeadingExportPopoverPresenter()

  public clearFilters = () => {
    this.searchBoxPresenter.clear()
    this.userPageFilterPanelPresenter.clear(true, false)
  }

  @observable
  public clearFiltersButtonPresenter = new ButtonPresenter('', () => {
    this.clearFilters()
  })
    .withIconName('dnd-forwardslash')
    .withDisplayStyle('secondary-invert')
    .withAriaLabel(this.language.get('common:ariaLabels:cancel'))

  @computed
  public get pillCount() {
    return (
      this.accountPillsPresenter.filterPills.length +
      this.accessPillsPresenter.filterPills.length +
      this.communicationPillsPresenter.filterPills.length +
      this.roleTypesPillsPresenter.filterPills.length +
      this.searchPillPresenter.filterPills.length
    )
  }

  @computed
  public get containsFilters(): boolean {
    return this.pillCount > 0
  }

  @computed
  public get showClearFilterButton(): boolean {
    return this.pillCount > 1
  }
}
