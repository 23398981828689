import { useCallback } from 'react'
import { eventManager } from 'event-manager'

import { RunbooksResponseMeta } from 'main/services/queries/use-runbooks'

export function useUpdateAngularRunbooksMeta() {
  return useCallback((meta: RunbooksResponseMeta) => {
    eventManager.emit('update-runbooks-view-meta', { meta })
  }, [])
}
