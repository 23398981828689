import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Unarchive = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Unarchive" {...props}>
    <path d="M32.606 27.485 24.121 19l-.025.025-.025-.025-8.485 8.485 2.121 2.122 4.793-4.793V37h3V24.621l4.985 4.986 2.121-2.122Z" fill="#000" /><path d="M7.2 12.4A6 6 0 0 0 6 16v20a6 6 0 0 0 6 6h24a6 6 0 0 0 6-6V16a6 6 0 0 0-1.2-3.6l-3-4A6 6 0 0 0 33 6H15a6 6 0 0 0-4.8 2.4l-3 4ZM39 36a3 3 0 0 1-3 3H12a3 3 0 0 1-3-3V16.5h30V36Zm-3.6-25.8 2.475 3.3h-27.75l2.475-3.3A3 3 0 0 1 15 9h18a3 3 0 0 1 2.4 1.2Z" fill="#000" />
  </StyledIcon>
))

Unarchive.displayName = 'Unarchive'

export { Unarchive }
