import { px2rem, LegacyTableBase as Table } from '@cutover/react-ui'
import { CommentItem } from './comment-item'
import { MrdDashboardWidget } from '../../../widgets/account/mrd-dashboard-widget'
import { DashboardComponent, DashboardMediaType } from '../../types'

export type CommentsWidgetProps = {
  media: DashboardMediaType
  data: DashboardComponent
}
/**
 * Displays the Featured Comments on a Runbook Dashboard
 */
export const CommentsWidget = ({ data }: CommentsWidgetProps) => {
  const featuredComments = data.comments?.filter(comment => comment.featured)

  return featuredComments.length > 0 ? (
    <MrdDashboardWidget fullWidth title={data.name || 'Starred Comments'}>
      <Table width="100%" style={{ cursor: 'default' }}>
        <Table.Head>
          {featuredComments.map(comment => (
            <Table.Row key={comment.id} style={{ marginBottom: px2rem(20) }}>
              <CommentItem
                name={comment.author.name}
                color={comment.author.color || ''}
                content={comment.content}
                task={comment.task}
                createdAt={comment.created_at}
              />
            </Table.Row>
          ))}
        </Table.Head>
      </Table>
    </MrdDashboardWidget>
  ) : (
    <></>
  )
}
