import { getUnixTime } from 'date-fns'

import { TaskListTask } from 'main/services/queries/types'

export type TasksByStageData = {
  skipped: TaskListTask[]
  abandoned: TaskListTask[]
  completed: TaskListTask[]
  inProgress: TaskListTask[]
  startable: TaskListTask[]
  notStartable: TaskListTask[]
  late: {
    complete: TaskListTask[]
    inProgress: TaskListTask[]
    startable: TaskListTask[]
    notStartable: TaskListTask[]
  }
}

export const tasksByStage = (tasks: TaskListTask[]): TasksByStageData => {
  const initialValues: TasksByStageData = {
    skipped: [],
    abandoned: [],
    completed: [],
    inProgress: [],
    startable: [],
    notStartable: [],
    late: {
      complete: [],
      inProgress: [],
      startable: [],
      notStartable: []
    }
  }
  return tasks.reduce((counts, task) => {
    const values = counts
    const now = getUnixTime(Date.now())
    const {
      start_latest_planned: startLatestPlanned,
      duration,
      end_fixed: endFixed,
      completion_type: completionType,
      stage
    } = task

    const endPlanned = startLatestPlanned ? startLatestPlanned + duration : 0
    const latestPlannedEnd = endFixed && endFixed > endPlanned ? endFixed : endPlanned

    switch (stage) {
      case 'complete':
        switch (completionType) {
          case 'complete_abandoned':
            values.abandoned.push(task)
            break
          case 'complete_skipped':
            values.skipped.push(task)
            break
          default:
            values.completed.push(task)
            break
        }
        break
      case 'in-progress':
        if (now > latestPlannedEnd) values.late.inProgress.push(task)
        values.inProgress.push(task)
        break
      case 'startable':
        if (now + duration > latestPlannedEnd) values.late.startable.push(task)
        values.startable.push(task)
        break
      default:
        if (startLatestPlanned && now > startLatestPlanned) values.late.notStartable.push(task)
        values.notStartable.push(task)
        break
    }

    return values
  }, initialValues)
}
