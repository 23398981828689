import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const BrandOperationalReadiness = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 100 101" a11yTitle="BrandOperationalReadiness" {...props}>
    <g clipPath="url(#a)" fill="#fff"><path d="M-.025 47.333A23.287 23.287 0 0 0 16.457 40.5 23.334 23.334 0 0 0 23.284 24a23.354 23.354 0 0 0-6.827-16.5A23.307 23.307 0 0 0-.025.668v46.666Z" /><path opacity=".5" d="M49.667 100.44a49.62 49.62 0 0 0 19.006-3.785 49.663 49.663 0 0 0 16.113-10.778 49.723 49.723 0 0 0 10.767-16.13 49.765 49.765 0 0 0-10.767-54.184A49.663 49.663 0 0 0 68.673 4.785 49.62 49.62 0 0 0 49.667 1v99.44Z" /><path d="m.133 100.576 47.163.025L.108 53.413l.025 47.163Z" /></g>
  </StyledIcon>
))

BrandOperationalReadiness.displayName = 'BrandOperationalReadiness'

export { BrandOperationalReadiness }
