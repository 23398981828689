// @ts-nocheck
import { container } from 'app/Config/IOC'
import { observable, computed, action } from 'mobx'
import { saveFile } from 'Helpers/.'
import { UserRepository } from 'app/Repositories/User/UserRepository'
import { AccordionPresenter, ButtonPresenter } from 'Components/Molecules'
import { Types } from 'Gateways/Service/Types'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'

export class UserEditHistoryPresenter {
  @observable
  private userRepository: UserRepository = container.get(UserRepository)

  public language: ILanguageService = container.get(Types.ILanguageService)

  @observable
  public accordionPresenter = new AccordionPresenter(this.language.get('users:edit:history:title')).withLeftIconName(
    'history'
  )

  @computed
  public get history(): any[] {
    if (this.userRepository.userForEdit && this.userRepository.userForEdit.history.length > 0) {
      return this.userRepository.userForEdit.history
    }

    return []
  }

  @action
  public closeAccordion = () => {
    this.accordionPresenter.closeAccordion()
  }

  public downloadCsv = async () => {
    const response = await this.userRepository.downloadHistoryCSV()

    if (response) {
      saveFile(response, 'text/csv', this.userRepository.userForEdit.name + '_history.csv')
    }
  }

  public csvButtonPresenter = new ButtonPresenter(this.language.get('users:edit:history:download'), this.downloadCsv)
    .withDisplayStyle('secondary')
    .withTextTransform('Uppercase')
}
