// @ts-nocheck
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { withPresenter } from 'app/Config/withPresenter'
import { AngularRunbookTemplateListPresenter } from './AngularRunbookTemplateListPresenter'
import { AngularRunbookTemplateElement } from './AngularRunbookTemplateElement'
import { TDisplaySvgIcons } from 'Components/Atoms'
import { ListWithInfiniteLoader, AnchorTag } from 'Components/Molecules'
import { IRunbookTemplatePM } from 'Shared/Entities/Types/Runbooks/IRunbookTemplatePM'
import { px2rem, colors, fontSizes } from 'app/Styles/Variables'

const ListWithInfiniteLoaderStyled = styled(ListWithInfiniteLoader)`
  margin: 0 -${px2rem(16)};
`

const NotFound = styled.div`
  display: flex;
  justify-content: center;
  padding-top: ${px2rem(20)};
  color: ${colors.secondary};
`

const AnchorTagStyled = styled(AnchorTag)`
  display: flex;
  justify-content: center;
  padding-top: ${px2rem(20)};
  color: ${colors.primary};
  font-size: ${fontSizes.m};
  text-transform: none;
  font-weight: 400;
`

interface IRunbookTemplateListProps {
  presenter: AngularRunbookTemplateListPresenter
  runbookType: {
    id: string
    iconName: TDisplaySvgIcons
    iconColor: string
  }
  loadRunbookTemplateList: (startIndex: number, stopIndex: number) => Promise<any> | null
  openCreateRunbookFromTemplateModal: (runbookTemplate: IRunbookTemplatePM) => void
}

const AngularRunbookTemplateListComponent = observer<IRunbookTemplateListProps>(
  ({ presenter, loadRunbookTemplateList, runbookType, openCreateRunbookFromTemplateModal }) => {
    const {
      runbookTemplates,
      runbookTemplatesTotal,
      runbookTemplatesLoading,
      runbookTemplatesBatchLoadNumber,
      accountRunbooks,
      anchorTagPresenter,
      viewRunbookTemplateStatusMapping,
      language
    } = presenter

    if (!runbookTemplates || runbookTemplates.length === 0) {
      return (
        <>
          <NotFound>{language.get('runbooks:chooseRunbookTemplate:templateRunbooksNotFound')}</NotFound>
          {accountRunbooks.length !== 0 && <AnchorTagStyled presenter={anchorTagPresenter} />}
        </>
      )
    }

    const { iconName, iconColor } = runbookType

    const renderListElement = (runbookTemplate: IRunbookTemplatePM) => {
      const { id, name, copiesCount, templateStatus, tasksCount, projectName, currentTemplateVersionApprovalStatus } =
        runbookTemplate
      const onClick = () => openCreateRunbookFromTemplateModal(runbookTemplate)

      return (
        <AngularRunbookTemplateElement
          key={id}
          name={name}
          iconName={iconName}
          iconColor={iconColor}
          count={copiesCount}
          id={id}
          templateStatus={viewRunbookTemplateStatusMapping(templateStatus)}
          tasksCount={tasksCount}
          projectName={projectName}
          currentTemplateVersionApprovalStatus={currentTemplateVersionApprovalStatus}
          onClick={onClick}
        />
      )
    }

    return (
      <ListWithInfiniteLoaderStyled
        height={220}
        itemSize={64}
        items={runbookTemplates}
        itemsTotal={runbookTemplatesTotal}
        loadMoreItems={loadRunbookTemplateList}
        isLoading={runbookTemplatesLoading}
        renderListElement={renderListElement}
        minimumBatchSize={runbookTemplatesBatchLoadNumber}
      />
    )
  }
)

export const AngularRunbookTemplateList = withPresenter(AngularRunbookTemplateListPresenter)(
  AngularRunbookTemplateListComponent
)
