import { Pill } from '@cutover/react-ui'
import { useComponentProps } from '../apps-state'
import { AppComponentNodeProps } from '../apps-types'

type PillKind = 'idea' | 'info' | 'success' | 'error' | 'warning' | 'barbie'

export type PillNodeProps = AppComponentNodeProps & {
  value: string
  kind?: PillKind
}

const pillKindColor: { [K in PillKind]: string } = {
  idea: 'primary',
  info: 'text-light',
  success: 'success',
  error: 'error',
  warning: 'warning',
  barbie: '#F057ED' // custom pink for Apps
}

export const PillNode = ({ appId, resourceId, id, ...props }: PillNodeProps) => {
  const { value: label, kind } = useComponentProps(appId, resourceId, id, props) as PillNodeProps

  const color = kind ? pillKindColor[kind] : pillKindColor.info

  return <Pill label={label} color={color} />
}
