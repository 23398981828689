import { useMutation, UseMutationOptions } from 'react-query'
import { MergeExclusive } from 'type-fest'

import { apiClient } from '../api'

export type PasswordResetPayload = {
  password: string
  password_confirmation: string
  topRightLogo?: string | null // TODO: find out what this does and type correctly (Ticket CFE-227)
}

export type PasswordResetResponse = MergeExclusive<{ success: true }, { success: false; errors: string[] }>

export const usePasswordReset = (
  options: UseMutationOptions<PasswordResetResponse, { status: number; errors: string[] }, PasswordResetPayload>
) => {
  return useMutation(
    'passwordReset',
    async (payload: PasswordResetPayload) => {
      const { data } = await apiClient.put<PasswordResetPayload, PasswordResetResponse>({
        url: 'auth/password',
        data: { ...payload, topRightLogo: payload.topRightLogo ?? null }
      })

      return data as PasswordResetResponse
    },
    options
  )
}
