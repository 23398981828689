// @ts-nocheck
import { observable, action } from 'mobx'
import { AccordionPresenter, OldCheckboxFilterPresenter } from 'Components/Molecules'
import { IFilter } from '.'
import { GenericBooleanFilter } from 'app/Filters/GenericBooleanFilter/GenericBooleanFilter'
import { GenericResourceFilter } from 'app/Filters/GenericResourceFilter/GenericResourceFilter'

export interface IGenericFilterMapping {
  filters: GenericBooleanFilter[] | GenericResourceFilter[]
  referenceLabelCallback: (reference: string, inverse: boolean) => string
  resourceLabelCallback: (reference: string, resourceId: string) => string
}

export class FilterGroupPresenter<T> {
  @observable
  public accordionPresenter: AccordionPresenter

  @observable
  public callback: (reference: string, resourceId?: string) => void | Promise<void>

  constructor(heading: string, callback?: (reference: string, resourceId?: string) => void | Promise<void>) {
    this.accordionPresenter = new AccordionPresenter(heading)
    this.callback = callback
  }

  public reset = () => this.accordionPresenter.reset()

  @observable
  public filters: T[] = []

  @action
  public withFilters = (filters: IFilter[]) => {
    const localFilters = []

    filters.map(filter => {
      let filterCheckBox = this.createCheckBox(
        filter.type === 'resource' ? 'resource' : 'boolean',
        filter,
        filter.type !== 'resource'
      )
      localFilters.push(filterCheckBox)
    })

    this.filters = localFilters

    return this
  }

  private createCheckBox(typeArg, filter: IFilter, doInverse) {
    let filterCheckBox = new OldCheckboxFilterPresenter({
      type: typeArg,
      reference: filter.reference,
      label: filter.label,
      resourceId: filter.resourceId,
      group: filter.group || undefined
    }).withOnChangeCallback(this.callback)

    if (doInverse) filterCheckBox.withCanBeInverse({ inverseLabel: filter.inversedLabel })

    return filterCheckBox
  }

  @action
  public withGenericFilters = (genericFilterMapping: IGenericFilterMapping) => {
    const genericFilters = genericFilterMapping.filters
    const referenceLabelCallback = genericFilterMapping.referenceLabelCallback
    const resourceLabelCallback = genericFilterMapping.resourceLabelCallback

    let filterCheckBox: OldCheckboxFilterPresenter
    const localFilters = []

    genericFilters.forEach(filter => {
      filterCheckBox = new OldCheckboxFilterPresenter(
        {
          type: filter instanceof GenericResourceFilter ? 'resource' : 'boolean',
          reference: filter.reference,
          label:
            filter instanceof GenericResourceFilter
              ? resourceLabelCallback(filter.reference, filter.resourceId)
              : referenceLabelCallback(filter.reference, false),
          resourceId: filter instanceof GenericResourceFilter ? filter.resourceId : null,
          group: filter.group
        },
        false
      )
        .withFilter(filter)
        .withOnChangeCallback(this.callback)

      if (filter instanceof GenericBooleanFilter && filter.canBeInverse) {
        filterCheckBox.withCanBeInverse({ inverseLabel: referenceLabelCallback(filter.reference, true) })
      }

      localFilters.push(filterCheckBox)
    })

    this.filters = localFilters
    return this
  }

  public withDefaultCollapsed = (defaultCollapsed: boolean): this => {
    this.accordionPresenter.withCollapsed(defaultCollapsed)
    return this
  }
}
