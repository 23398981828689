import { useState } from 'react'
import { Heading } from 'grommet'
import { useLocation, useNavigate } from 'react-router-dom'

import { Box, Button } from '@cutover/react-ui'
import { RoleMappingsSubHeader } from './role-mappings-sub-header/role-mappings-sub-header'
import { UploadMappingsModal } from './upload-mappings-modal'
import { Layout } from 'main/components/layout'
import { useDefaultLayout } from 'main/components/layout/layout-hooks'
import { useLanguage } from 'main/services/hooks'
import { usePermissions } from 'main/services/queries/use-permissions'

export const RoleMappingsLayout = () => {
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useLanguage('roleMappings')
  const { state } = location
  const permissions = usePermissions('saml-role-mappings')
  const userCanCreateRoleMappings = permissions('create')

  useDefaultLayout({
    filterPanel: false,
    subHeader: true,
    rightNav: false
  })

  const Header = (
    <Box
      direction="row"
      css={`
        padding-top: 14px;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 14px;
      `}
    >
      <Box direction="row">
        <Button icon={'arrow-back'} tertiary onClick={() => navigate('../saml')} />
        <Heading
          css={`
            font-size: 18px;
          `}
          responsive={false}
          margin={{ left: 'xsmall', right: 'none', vertical: 'none' }}
          level={'2'}
        >
          {t('subHeader', { samlConfigId: state?.samlConfigurationId })}
        </Heading>
      </Box>
      {userCanCreateRoleMappings ? (
        <Button icon={'upload'} primary onClick={() => setModalOpen(true)} label={t('uploadButton')} />
      ) : null}
    </Box>
  )

  return (
    <Box>
      <UploadMappingsModal modalOpen={modalOpen} closeModal={() => setModalOpen(false)} />
      <Layout header={Header} subHeader={<RoleMappingsSubHeader />} />
    </Box>
  )
}
