// @ts-nocheck
import { observer } from 'mobx-react-lite'
import { RoleTypeViewPermissionPresenter } from 'app/Access/RoleTypes/RoleTypesPage/RoleTypeEdit/RoleTypeViewPermissionPresenter'
import { RadioButtons } from 'Components/Atoms'
import { CheckboxList } from 'Components/Molecules'
import styled from 'styled-components'
import { colors, px2rem } from 'app/Styles/Variables'

const Wrapper = styled.div`
  margin-top: ${px2rem(16)};
`

const LabelWrapper = styled.div`
  font-size: ${px2rem(13)};
  line-height: ${px2rem(12)};
  font-weight: 500;
  color: ${colors.textLight};
  transition: none;
  margin-top: ${px2rem(12)};
`

interface IRoleTypeViewPermissionComponentProps {
  presenter: RoleTypeViewPermissionPresenter
}

export const RoleTypeViewPermissionComponent = observer<IRoleTypeViewPermissionComponentProps>(({ presenter }) => {
  const {
    viewPermissionOptionsPresenter,
    accessManagementPresenter,
    globalViewsPresenter,
    accountViewsPresenter,
    globalViewsHeading,
    accountViewsHeading,
    show
  } = presenter

  const extraStyles = {
    borderBottom: `1px dashed ${colors.primaryGreyHoverBackgroundColor}`
  }

  return (
    <Wrapper>
      {viewPermissionOptionsPresenter && <RadioButtons presenter={viewPermissionOptionsPresenter} />}
      {show && globalViewsPresenter && (
        <>
          <LabelWrapper>{globalViewsHeading}</LabelWrapper>
          <CheckboxList presenter={globalViewsPresenter} />
        </>
      )}
      {show && accessManagementPresenter && (
        <CheckboxList presenter={accessManagementPresenter} extraStyles={extraStyles} />
      )}
      {show && accountViewsPresenter && (
        <>
          <LabelWrapper>{accountViewsHeading}</LabelWrapper>
          <CheckboxList presenter={accountViewsPresenter} extraStyles={extraStyles} />
        </>
      )}
    </Wrapper>
  )
})
