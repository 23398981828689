import { MouseEvent as ReactMouseEvent, useCallback, useEffect, useMemo, useState } from 'react'
import parse from 'html-react-parser'
import { MergeExclusive } from 'type-fest'

import { Accordion, AccordionPanel, Box, Button, IconName, Pill, Tooltip } from '@cutover/react-ui'
import { FilterGroupCheckbox, FilterGroupDate, FilterGroupSelect } from './filter-groups'
import { FilterGroupHierarchy } from './filter-groups/filter-group-hierarchy'
import { FilterGroupText } from './filter-groups/filter-group-text'
import { FilterHeader, FilterHeaderProps } from './filter-header'
import { getFilterSelections } from './filter-helpers'
import { FilterGroup, FilterOption, FilterOptionCheckbox, FilterOptionHierarchy, SelectedFilters } from './filter-types'
import { useLanguage } from 'main/services/hooks'

export type FilterBaseProps = {
  /** Filter is in a layout filter panel (e.g. workspace page) */
  isLayoutFilter?: boolean
  filterData: FilterGroup[]
  /** A function that can be used to customise the active indicator based on the group's contents */
  getAppliedIndicatorText?: (selected: SelectedFilters, filter: FilterGroup) => string | undefined
  onChange: (filter: FilterGroup, option?: FilterOption, override?: string[] | number[] | number | string) => void
  onReverse?: (filter: FilterGroup, option: FilterOptionCheckbox) => void
  onSpotlightAndGroupChange?: (type: 'group' | 'spotlight', filter: FilterGroup) => void
  //** eg. { project: [1, 2, 3], alr: 1, f: { 205: [1] } } */
  selected?: SelectedFilters
  withHeader?: boolean
  onHierarchyOptionEditClick?: (e: ReactMouseEvent, option: FilterOptionHierarchy) => void
}

export type FilterBasePropsWithHeader = MergeExclusive<
  FilterBaseProps & { withHeader: false },
  FilterBaseProps & FilterHeaderProps & { withHeader?: true }
>

export const FilterBase = ({
  isLayoutFilter = false,
  filterData,
  getAppliedIndicatorText,
  onChange,
  onClearAll,
  onSpotlightAndGroupChange,
  onReverse,
  selected = {},
  onTabClear,
  withHeader = true,
  onHierarchyOptionEditClick
}: FilterBasePropsWithHeader) => {
  const initiallyOpenIndices = useMemo(
    () =>
      filterData.reduce<number[]>((results, current, i) => {
        if (current.isInitiallyOpen) results.push(withHeader ? i + 1 : i)
        return results
      }, []),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const { t } = useLanguage('common', { keyPrefix: 'filter' })

  const [openPanelIndices, setOpenPanelIndices] = useState(initiallyOpenIndices)

  const removeHeaderAccordionIndex = (indices: number[]) => indices.filter(i => i !== 0)

  const handleAccordionClick = (indices: number[]) => {
    if (Object.keys(selected).length > 0 || !withHeader) return setOpenPanelIndices(indices)
    setOpenPanelIndices(removeHeaderAccordionIndex(indices))
  }

  const isActivePanel = useCallback(
    (filter: FilterGroup) => {
      const hasSelections = (selection: SelectedFilters) => {
        if (Array.isArray(selection)) return selection.length > 0
        return selection !== undefined
      }

      if (filter.slug) return hasSelections(getFilterSelections(selected, filter))

      if ('options' in filter)
        return [...filter.options].find(o => hasSelections(getFilterSelections(selected, filter, o)))
    },
    [selected]
  )

  useEffect(() => {
    if (Object.keys(selected).length === 0 && openPanelIndices.includes(0))
      setOpenPanelIndices(removeHeaderAccordionIndex(openPanelIndices))
  }, [selected])

  return (
    <Accordion controlled activeIndex={openPanelIndices} onActive={handleAccordionClick}>
      {withHeader && onClearAll && onTabClear && onReverse && (
        <FilterHeader
          filterData={[
            ...filterData,
            { slug: 'q', type: 'search', title: t('search') },
            { slug: 'runbook_id', type: 'runbook-id', title: t('searchRunbookIds') }
          ]}
          selected={selected}
          onTabClear={onTabClear}
          onClearAll={onClearAll}
          onReverse={onReverse}
        />
      )}
      {filterData.map(filter => (
        <AccordionPanel
          key={filter.title}
          label={parse(filter.title) as string}
          labelSuffix={
            isActivePanel(filter) && (
              <Pill
                css="margin-left: 4px; flex-shrink: 0;"
                label={getAppliedIndicatorText?.(selected, filter) || t('applied')}
                size="small"
              />
            )
          }
          suffix={
            (filter.canSpotlight || filter.canGroup) && (
              <Box direction="row" flex={{ shrink: 0 }} height="24px" gap="7px" margin={{ left: '2px' }}>
                {filter.canSpotlight && (
                  <FilterGroupSuffixIcon
                    tooltip={t('spotlightTooltip')}
                    data-testid={`${filter.title}-filter-spotlight`}
                    onClick={() => onSpotlightAndGroupChange?.('spotlight', filter)}
                    icon="spotlight"
                    isSelected={
                      !!selected.spotlight &&
                      (selected.spotlight === filter.title.toLowerCase() || selected.spotlight === filter.customFieldId)
                    }
                  />
                )}
                {filter.canGroup && (
                  <FilterGroupSuffixIcon
                    tooltip={t('groupTooltip')}
                    data-testid={`${filter.title}-filter-group`}
                    onClick={() => onSpotlightAndGroupChange?.('group', filter)}
                    icon="group-y"
                    isSelected={
                      !!selected.group &&
                      (selected.group === filter.title.toLowerCase() || selected.group === filter.customFieldId)
                    }
                  />
                )}
              </Box>
            )
          }
        >
          {filter.header}
          {(() => {
            switch (filter.type) {
              case 'hierarchy':
                return (
                  <FilterGroupHierarchy
                    isLayoutFilter={isLayoutFilter}
                    key={filter.title}
                    filter={filter}
                    onChange={selected => onChange(filter, undefined, selected)}
                    selected={selected}
                    onOptionEditClick={onHierarchyOptionEditClick}
                  />
                )
              case 'checkbox':
                return (
                  <FilterGroupCheckbox
                    key={filter.title}
                    displayLimit={filter.optionDisplayLimit || 50}
                    filter={filter}
                    onChange={option => onChange(filter, option)}
                    onReverse={option => onReverse?.(filter, option)}
                    selected={selected}
                    withAnyValueOption={filter.withAnyValueOption}
                    withNoValueOption={filter.withNoValueOption}
                  />
                )
              case 'select':
                return (
                  <FilterGroupSelect
                    key={filter.title}
                    displayLimit={filter.optionDisplayLimit}
                    expandable={filter.expandable}
                    filter={filter}
                    onChange={option => option && onChange(filter, option)}
                    selected={selected}
                    withAnyValueOption={filter.withAnyValueOption}
                    withNoValueOption={filter.withNoValueOption}
                  />
                )
              case 'date':
                return (
                  <FilterGroupDate
                    key={filter.title}
                    filter={filter}
                    onChange={option => onChange(filter, option)}
                    selected={selected}
                    withAnyValueOption={filter.withAnyValueOption}
                    withNoValueOption={filter.withNoValueOption}
                  />
                )
              case 'text':
                return (
                  <FilterGroupText
                    key={filter.title}
                    filter={filter}
                    onChange={option => onChange(filter, option)}
                    selected={selected}
                    withAnyValueOption={filter.withAnyValueOption}
                    withNoValueOption={filter.withNoValueOption}
                  />
                )
            }
          })()}
          {filter.footer}
        </AccordionPanel>
      ))}
    </Accordion>
  )
}

const FilterGroupSuffixIcon = ({
  isSelected,
  icon,
  onClick,
  tooltip,
  ...props
}: {
  isSelected?: boolean
  icon: IconName
  onClick: (e: ReactMouseEvent<HTMLElement, MouseEvent>) => void
  tooltip: string
  'data-testid'?: string
}) => (
  <Tooltip content={tooltip} placement="top">
    {/* IconButton here gives several side effects like accordion panel opens/closes on button click,
        wrong icon hover on click, nested buttons violation. Plain Button solves these issues. */}
    <Button
      as="span"
      role="button"
      plain
      iconColor={isSelected ? 'primary' : 'text-light'}
      size="medium"
      icon={icon}
      onClick={onClick}
      {...props}
    />
  </Tooltip>
)
