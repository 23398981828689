import { useState } from 'react'
import { Heading } from 'grommet'
import styled from 'styled-components'

import { Box, Button } from '@cutover/react-ui'
import { IntegrationDocumentationModal } from './integration-documentation-modal'
import { NewIntegrationConnectionModal } from './new-integration-connection-modal'
import { useLanguage } from 'main/services/hooks'

export const IntegrationSettingsHeader = () => {
  const { t } = useLanguage('integrationSettings')

  const [documentationModalOpen, setDocumentationModalOpen] = useState<boolean>(false)
  const [newIntegrationConnectionModalOpen, setNewIntegrationConnectionModalOpen] = useState<boolean>(false)

  return (
    <Box direction="row" width="100%" align="center">
      <Box flex="grow" justify="center">
        <IntegrationSettingsTitle
          responsive={false}
          margin={{ left: 'xsmall', right: 'none', vertical: 'none' }}
          level="1"
        >
          {t('mainPanelHeader')}
        </IntegrationSettingsTitle>
      </Box>
      <Box margin={{ right: 'medium' }}>
        <Button
          icon="info"
          secondary
          label={t('learnMoreButton')}
          onClick={() => setDocumentationModalOpen(true)}
          aria-label={t('ariaLabelInfo')}
        />
      </Box>
      <Box>
        <Button
          icon="add"
          primary
          label={t('createButton')}
          onClick={() => setNewIntegrationConnectionModalOpen(true)}
          aria-label={t('ariaLabelAdd')}
        />
      </Box>
      <IntegrationDocumentationModal onClose={() => setDocumentationModalOpen(false)} open={documentationModalOpen} />
      <NewIntegrationConnectionModal
        onClose={() => setNewIntegrationConnectionModalOpen(false)}
        open={newIntegrationConnectionModalOpen}
      />
    </Box>
  )
}

const IntegrationSettingsTitle = styled(Heading)`
  font-size: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
`
