import { Box } from '@cutover/react-ui'
import { CutoverLogo, PageWrap } from './login-styled'
import { PasswordForgotForm } from './password-forgot-form'
import { VerifyActiveSession } from './verify-active-session'

export const PasswordForgotPage = () => {
  return (
    <VerifyActiveSession>
      <PageWrap>
        <CutoverLogo src="/branding/cutover/cutover-logo.png" alt="Cutover" />
        <Box css="min-height: auto; padding-bottom: 30px;">
          <PasswordForgotForm />
        </Box>
      </PageWrap>
    </VerifyActiveSession>
  )
}
