import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const TaskTypeRunbook = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="TaskTypeRunbook" {...props}>
    <path d="M38 14v20a4 4 0 0 1-4 4H14a4 4 0 0 1-4-4v-2H8v2a6 6 0 0 0 6 6h20a6 6 0 0 0 6-6V14a6 6 0 0 0-6-6H14a6 6 0 0 0-6 6v2h2v-2a4 4 0 0 1 4-4h20a4 4 0 0 1 4 4ZM5 20h8v2H5v-2Zm8 6H5v2h8v-2Z" fill="#000" />
  </StyledIcon>
))

TaskTypeRunbook.displayName = 'TaskTypeRunbook'

export { TaskTypeRunbook }
