import { useContext, useState } from 'react'
import styled from 'styled-components'

import { Avatar, BarTable, Box, colors } from '@cutover/react-ui'
import { useAngularRightSidePanel } from '../../../../../services/hooks'
import { DashboardContext } from '../../../runbooks-dashboard-page'
import { DashboardMediaType } from '../../../widgets/types'
import { generateBarValues } from '../bar-table-widget-utils'
import { MrdDashboardWidget } from '../mrd-dashboard-widget'
import { getFilterParams } from '../mrd-filter-util'
import { useSetActiveRightPanelState } from 'main/components/layout/right-panel'
import { ConfigModel } from 'main/data-access'

export type RunbookCompletionComponentProps = {
  media: DashboardMediaType
  data: RunbookCompletionComponentData
}

export type RunbookCompletionComponentData = {
  id: number
  errors?: string[]
  filters?: {
    resource_type: string
    resource_id: string | null
    value: string
  } | null
  name: string
  notes?: any
  type: string
  filter_key: string
  resource_type?: string
  values: RunbookCompletionValues[]
}

export type RunbookCompletionValues = {
  total: number
  red: number
  amber: number
  green: number
  cancelled: number
  complete: number
  ontime: number
  late: number
  other: number
  id: number
  name: string
  color?: string
  parent_id?: number | null
  image: boolean
  status: string | null
  status_message: string | null
}

const SEGMENT_FILTER_VALUES: Record<string, { displayStage?: string; late?: boolean }> = {
  cancelled: { displayStage: 'cancelled', late: false },
  completed: { displayStage: 'complete' },
  in_progress: { displayStage: 'in_progress', late: false },
  late: { displayStage: 'in_progress', late: true },
  other: { displayStage: 'planning', late: false }
}

export const RunbookCompletionWidget = (props: RunbookCompletionComponentProps) => {
  const { media } = props
  switch (media) {
    case 'screen':
      return <BrowserWidget {...props} />
    case 'email':
      return <ServerSideWidget {...props} />
    case 'print':
      return <ServerSideWidget {...props} />
  }
}

const BrowserWidget = (props: RunbookCompletionComponentProps) => {
  const isReactWorkspaceEnabled = ConfigModel.useIsFeatureEnabled('react_workspace')
  return <RunbookCompletionWidgetContent {...props} reactWorkspace={isReactWorkspaceEnabled} />
}

const ServerSideWidget = (props: RunbookCompletionComponentProps) => <RunbookCompletionWidgetContent {...props} />

const RunbookCompletionWidgetContent = ({
  media,
  data: { name, errors, notes, values, filter_key, filters, resource_type },
  reactWorkspace = false
}: RunbookCompletionComponentProps & { reactWorkspace?: boolean }) => {
  const { accountId, dashboardId, filterParams } = useContext(DashboardContext)
  const groupBy = name.includes('Team') ? 'Team' : 'Other' //not optimal way to differentiate..
  const { openAngularSidePanel } = useAngularRightSidePanel()
  const { openRightPanel } = useSetActiveRightPanelState()

  let additionalFilters: { [key: string]: string | string[] } = {}

  if (filters) {
    additionalFilters =
      filters.resource_type === 'CustomField'
        ? { [`f[${filters.resource_id}]`]: filters.value }
        : filters.resource_type === 'Stage'
        ? { display_stage: [filters.value] }
        : { [filters.resource_type.toLowerCase()]: filters.value }
  }
  // builds a contentId object for displaying filtered runbooks in the side panel
  const buildContentIdObject = (id: number, ragStatus: string | null = null, displayStage?: string, late?: boolean) => {
    let subFilterKey = resource_type == 'CustomField' ? `f[${filter_key}]` : filter_key
    let subFilterValue = id

    return {
      accountId,
      dashboardId,
      template_type: 'off',
      ...getFilterParams(filterParams),
      ...{ [subFilterKey]: [subFilterValue] },
      subFilterValue: subFilterValue,
      subFilterKey: subFilterKey,
      // conditionally render key, value pairs (filters)
      display_stage: displayStage ? displayStage : undefined,
      late: late !== undefined ? (late ? 1 : 0) : undefined,
      status: ragStatus ? ragStatus : undefined,
      ...additionalFilters
    }
  }

  const openRightSidePanel = (contentId: { [key: string]: any }) => {
    if (reactWorkspace) {
      openRightPanel({ type: 'dashboard-runbook-list', params: contentId })
    } else {
      openAngularSidePanel({ content: 'filtered-changes', contentId })
    }
  }

  const handleRowClick = (id: number) => {
    const contentId = buildContentIdObject(id)
    openRightSidePanel(contentId)
  }

  const handleRowRagClick = (id: number, ragStatus: string) => {
    const contentId = buildContentIdObject(id, ragStatus)
    openRightSidePanel(contentId)
  }

  const handleRowSegmentClick = (id: number, displayStageId: string | undefined) => {
    if (displayStageId) {
      const { displayStage, late } = SEGMENT_FILTER_VALUES[displayStageId]
      const contentId = buildContentIdObject(id, null, displayStage, late)
      openRightSidePanel(contentId)
    }
  }

  return (
    <MrdDashboardWidget title={name} errors={errors} notes={notes} fullWidth>
      <BarTable
        data={{ rowData: convertValuesToRowDataFormat(values, handleRowRagClick, groupBy) }}
        media={media}
        onRowClick={handleRowClick}
        onSegmentClick={handleRowSegmentClick}
      />
    </MrdDashboardWidget>
  )
}

const convertValuesToRowDataFormat = (
  values: RunbookCompletionValues[],
  handleRowRagClick: (id: number, ragStatus: string) => void,
  groupBy: string
) => {
  return values.map(value => {
    const { name, color, parent_id, complete, cancelled, ontime, late, other, total, red, amber, green, id } = value
    const defaultBarColors = [colors.primary, colors.success, colors.warning, colors.bg4, colors.bg2]

    return {
      id,
      mainLabel: (
        <Box direction="row" align="center">
          {groupBy === 'Team' && (
            <Box margin={{ right: '4px' }}>
              <Avatar subject={{ name, color }} size="small" />
            </Box>
          )}
          {parent_id ? `\u00A0 \u00A0 \u00A0${name}` : `${name}`}
        </Box>
      ),
      rowInfo: `${total} runbooks`,
      optionalComponent: <RagStatus red={red} amber={amber} green={green} id={id} onRagClick={handleRowRagClick} />,
      values: generateBarValues({
        bars: [
          { count: complete, tooltip: 'with complete live run', displayStage: 'completed' },
          { count: ontime, tooltip: 'in live run and on time', displayStage: 'in_progress' },
          { count: late, tooltip: 'in live run and late', displayStage: 'late' },
          { count: other, tooltip: 'in planning or rehearsal', displayStage: 'other' },
          { count: cancelled, tooltip: 'with cancelled live run', displayStage: 'cancelled' }
        ],
        total,
        context: 'runbook',
        barColors: defaultBarColors,
        displayPercentBarIndex: 0
      })
    }
  })
}

// -------- RAG STATUS COMPONENT -------- //
// TODO: Move this to react-ui but where?

type RagProps = {
  status: string
  active: boolean
  currentHoverRag: string | null
}

type RagStatusProps = {
  red: number
  amber: number
  green: number
  id: number
  onRagClick: (id: number, ragStatus: string) => void
}

export const RagStatus = (props: RagStatusProps) => {
  const { red, amber, green, id, onRagClick } = props
  const [hoveredRag, setHoveredRag] = useState<string | null>(null)

  return (
    <RagStatusWrapper>
      <Rag
        status="red"
        active={!!red}
        currentHoverRag={hoveredRag}
        onMouseEnter={() => setHoveredRag('red')}
        onMouseLeave={() => setHoveredRag(null)}
        onClick={() => onRagClick(id, 'red')}
      >
        {red}
      </Rag>
      <Rag
        status="amber"
        active={!!amber}
        currentHoverRag={hoveredRag}
        onMouseEnter={() => setHoveredRag('amber')}
        onMouseLeave={() => setHoveredRag(null)}
        onClick={() => onRagClick(id, 'amber')}
      >
        {amber}
      </Rag>
      <Rag
        status="green"
        active={!!green}
        currentHoverRag={hoveredRag}
        onMouseEnter={() => setHoveredRag('green')}
        onMouseLeave={() => setHoveredRag(null)}
        onClick={() => onRagClick(id, 'green')}
      >
        {green}
      </Rag>
    </RagStatusWrapper>
  )
}

const getColor = (color: string) => {
  switch (color) {
    case 'red':
      return colors.error
    case 'amber':
      return colors.warning
    case 'green':
      return colors.success
  }
}

const Rag = styled.div`
  background-color: ${(props: RagProps) => getColor(props.status)};
  border-radius: 8px;
  width: 16px;
  height: 16px;
  font-size: 12px;
  font-weight: 400;
  color: ${colors.white};
  margin-right: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${(props: RagProps) => (props.active || props.status === props.currentHoverRag ? 0.8 : 0.2)};
  cursor: pointer;
`

const RagStatusWrapper = styled.div`
  display: flex;
  padding-left: 4px;
  flex-direction: row;
`
