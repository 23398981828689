import { selectorFamily } from 'recoil'

import { appFieldValues } from '../apps-atoms'

type TransferValue = {
  name: string
  value: string | string[]
}

export const fieldValuesWithContext = selectorFamily({
  key: 'AppValues',
  get:
    (context: string) =>
    ({ get }) => {
      return get(appFieldValues)[context]
    },
  set:
    (context: string) =>
    ({ set, get }, newValue) => {
      const appValues = get(appFieldValues)
      const { name, value } = newValue as TransferValue

      const changedAppFieldValues = { ...appValues }
      if (!changedAppFieldValues[context]) {
        changedAppFieldValues[context] = {}
      }

      const updatedAppFieldValues = {
        ...changedAppFieldValues[context],
        [name]: value
      }

      changedAppFieldValues[context] = updatedAppFieldValues
      set(appFieldValues, changedAppFieldValues)
    }
})
