import { useEffect, useReducer } from 'react'

export const useForceRerenderOnTimeout = (delay: number | undefined) => {
  const [_, forceUpdate] = useReducer(x => x + 1, 0)

  useEffect(() => {
    if (!delay) return

    let timeoutId: ReturnType<typeof setTimeout> | undefined = undefined

    /*
     * Browsers store the delay as a 32-bit signed integer internally.
     * This causes an integer overflow when using delays larger than 2,147,483,647 ms (about 24.8 days).
     * https://developer.mozilla.org/en-US/docs/Web/API/Window/setTimeout#maximum_delay_value
     * */
    if (delay > 0 && delay < 0x7fffffff) {
      timeoutId = setTimeout(() => {
        // Re-render component
        forceUpdate()
      }, delay)
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [delay])
}
