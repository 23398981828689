import { PaintCircle } from '../node-map-types'

export const paintCircle = ({ ctx, x, y, size, lineWidth, stroke, fill }: PaintCircle) => {
  ctx.beginPath()
  ctx.arc(x, y, size, 0, 2 * Math.PI, false)
  ctx.closePath()

  ctx.lineWidth = lineWidth
  ctx.strokeStyle = stroke
  ctx.stroke()

  if (fill) {
    ctx.fillStyle = fill
    ctx.fill()
  }
}
