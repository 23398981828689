import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const BrandPlan = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 100 100" a11yTitle="BrandPlan" {...props}>
    <g clipPath="url(#a)" fill="#fff"><path d="M64.667-.333V29H0V-.333h64.667Z" /><path d="M99.667 99.667V70.333H35v29.334h64.667Zm0-35.334V35H35v29.333h64.667Z" opacity=".5" /></g>
  </StyledIcon>
))

BrandPlan.displayName = 'BrandPlan'

export { BrandPlan }
