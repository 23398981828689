import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Star = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Star" {...props}>
    <path d="m23.985 5.085 4.25 9.214a4.655 4.655 0 0 0 3.679 2.673l10.077 1.195-7.45 6.89a4.655 4.655 0 0 0-1.405 4.325l1.977 9.953-8.855-4.956a4.654 4.654 0 0 0-4.547 0l-8.855 4.956 1.978-9.953a4.655 4.655 0 0 0-1.405-4.325l-7.451-6.89 10.077-1.195a4.657 4.657 0 0 0 3.68-2.673l4.25-9.214Zm1.69-3.003C25.01.64 22.96.64 22.294 2.082L17.198 13.13a1.862 1.862 0 0 1-1.471 1.069L3.645 15.63c-1.578.187-2.211 2.137-1.045 3.216l8.932 8.26a1.86 1.86 0 0 1 .562 1.73L9.724 40.77c-.31 1.559 1.348 2.764 2.735 1.988l10.616-5.943a1.862 1.862 0 0 1 1.82 0l10.615 5.943c1.387.776 3.046-.43 2.736-1.988l-2.371-11.933a1.862 1.862 0 0 1 .562-1.73l8.932-8.26c1.167-1.079.533-3.029-1.045-3.216L32.243 14.2a1.862 1.862 0 0 1-1.472-1.07L25.675 2.083Z" fill="#000" />
  </StyledIcon>
))

Star.displayName = 'Star'

export { Star }
