// @ts-nocheck
import { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import { FixedSizeList as List } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import InfiniteLoader from 'react-window-infinite-loader'
import { StyledTable } from './TableComponentStyles'
import { buildHeaderRow, buildBodyRow, setListRef } from './TableComponentHelpers'
import { TablePresenter } from './TablePresenter'

interface ITableOption {
  presenter: TablePresenter
  selectedId?: number
}

const variableWidthCheck = (variableWidth, tableWidth, calculatedTableWidth) => {
  return variableWidth && tableWidth > calculatedTableWidth
}

export const Table = observer<ITableOption>(({ presenter, selectedId = null }) => {
  const {
    data,
    columns,
    customRowFunction,
    sortHandler,
    totalDataItems,
    tableName,
    sortedHeaders,
    loadMoreItems,
    isItemLoaded,
    configsRepository,
    variableWidth,
    batchLoadNumber
  } = presenter

  const tablePadding = 30
  const loadingRows = 5

  const [listHeight, setListHeight] = useState(window.innerHeight - 209)
  const [calculatedTableWidth, setCalculatedTableWidth] = useState(1)
  const [tableItemsCount, setTableItemsCount] = useState(0)
  const infiniteLoaderRef = useRef(null)

  useEffect(() => {
    if (data.length >= totalDataItems) return setTableItemsCount(totalDataItems)
    if (data.length < totalDataItems) return setTableItemsCount(data.length + loadingRows)
  }, [data, totalDataItems])

  useEffect(() => {
    let width = 0
    columns.forEach(column => {
      width += column.width
    })
    setCalculatedTableWidth(width)
  }, [columns])

  useEffect(() => {
    const handleResize = () => {
      setListHeight(window.innerHeight - 209)
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const sortHeaderWithResetCache = args => {
    if (infiniteLoaderRef.current) {
      infiniteLoaderRef.current.resetloadMoreItemsCache()
    }
    sortHandler(args)
  }

  const Header = props => {
    const { width } = props
    return buildHeaderRow(
      columns,
      sortedHeaders,
      sortHeaderWithResetCache,
      configsRepository,
      variableWidthCheck(variableWidth, width, calculatedTableWidth),
      width,
      calculatedTableWidth
    )
  }

  const Row = props => {
    const { width } = props
    return buildBodyRow(
      props,
      data,
      columns,
      customRowFunction,
      isItemLoaded,
      configsRepository,
      selectedId,
      variableWidthCheck(variableWidth, width, calculatedTableWidth),
      width,
      calculatedTableWidth
    )
  }

  return (
    <AutoSizer>
      {({ width }) => {
        const tableDivWidth = width - tablePadding
        return (
          <StyledTable
            className={tableName}
            tableWidth={
              variableWidthCheck(variableWidth, width, calculatedTableWidth) ? tableDivWidth : calculatedTableWidth
            }
            role="table"
          >
            <Header width={tableDivWidth} />
            <InfiniteLoader
              isItemLoaded={isItemLoaded}
              itemCount={totalDataItems}
              loadMoreItems={loadMoreItems}
              ref={infiniteLoaderRef}
              minimumBatchSize={batchLoadNumber - loadingRows}
            >
              {({ onItemsRendered, ref }) => (
                <List
                  itemData={data}
                  className="table-body"
                  height={listHeight}
                  itemCount={tableItemsCount}
                  itemSize={40}
                  onItemsRendered={onItemsRendered}
                  ref={el => {
                    // @ts-ignore
                    ref(el)
                    setListRef(el)
                  }}
                  width={
                    variableWidthCheck(variableWidth, width, calculatedTableWidth)
                      ? tableDivWidth
                      : calculatedTableWidth
                  }
                >
                  {props => {
                    return Row({ ...props, width: tableDivWidth })
                  }}
                </List>
              )}
            </InfiniteLoader>
          </StyledTable>
        )
      }}
    </AutoSizer>
  )
})
