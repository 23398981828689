import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const MessageBox = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="MessageBox" {...props}>
    <path d="M25.5 13v13h-3V13h3ZM24 30a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5Z" fill="#000" /><path fillRule="evenodd" clipRule="evenodd" d="M14 6a8 8 0 0 0-8 8v20a8 8 0 0 0 8 8h20a8 8 0 0 0 8-8V14a8 8 0 0 0-8-8H14Zm20 3a5 5 0 0 1 5 5v20a5 5 0 0 1-5 5H14a5 5 0 0 1-5-5V14a5 5 0 0 1 5-5h20Z" fill="#000" />
  </StyledIcon>
))

MessageBox.displayName = 'MessageBox'

export { MessageBox }
