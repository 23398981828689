import { countBy, groupBy, mapValues, sortBy } from 'lodash'
import { selector, selectorFamily } from 'recoil'

import { hasFiltersState } from 'main/recoil/shared/filters'
import {
  getScopedCustomFields,
  runbookCustomFieldFieldValuesState,
  runbookUsedCustomFieldIdState
} from 'main/recoil/runbook/models/runbook-version/custom-fields/custom-fields'
import { customFieldOptionsLookup } from '../../account/custom-fields'
import { filteredTaskListIdsState, taskListTaskState } from '../../tasks/task-list'

// only the ones used that are currently visible in the runbook filter panel

export const runbookFilterCustomFieldsState = selector({
  key: 'custom-fields:filter',
  get: ({ get }) => {
    const possibleCustomFields = get(
      getScopedCustomFields({
        applyToSlugs: ['task_edit', 'task_start', 'task_end'],
        scopeDisplay: 'search'
      })
    )

    const usedCustomFieldIds = get(runbookUsedCustomFieldIdState)

    return sortBy(
      possibleCustomFields.filter(cf => usedCustomFieldIds.includes(cf.id)),
      'name'
    )
  }
})

export const fieldOptionCountsState = selectorFamily({
  key: 'field-option-counts:filtered',
  get:
    (customFieldId: number) =>
    ({ get }) => {
      const hasFilters = get(hasFiltersState)
      const allOptionsState = get(allOptionCountLookupState(customFieldId))

      if (!hasFilters) {
        const options = Object.values(allOptionsState)
        return sortBy(options, 'option.order') as typeof options
      }

      // field values for the filterd tasks only
      const fieldValues = get(filteredCustomFieldFieldValues(customFieldId))
      const usedOptionIds: number[] = []

      fieldValues?.forEach(fv => {
        const optIds: number[] = fv.field_option_id ? [fv.field_option_id] : fv.value ? JSON.parse(fv.value) : []
        usedOptionIds.push(...optIds)
      })

      const counts: Record<number, number> = countBy(usedOptionIds)

      const optionLookup = mapValues(allOptionsState, ({ option }) => {
        // TODO: revisit - is erroring when creating option in angular due to not updating
        // recoil state properly because we haven't added in a handler or is there a bug
        const count = option ? counts[option.id] ?? 0 : 0
        return {
          option,
          count
        }
      })

      const options = Object.values(optionLookup)
      return sortBy(options, 'option.order') as typeof options
    }
})

const allOptionCountLookupState = selectorFamily({
  key: 'field-option-counts-lookup:all',
  get:
    (customFieldId: number) =>
    ({ get }) => {
      const optionLookup = get(customFieldOptionsLookup)
      const usedOptionIds: number[] = []
      const fieldValues = get(runbookCustomFieldFieldValuesState(customFieldId))

      fieldValues?.forEach(fv => {
        const optIds: number[] = fv.field_option_id ? [fv.field_option_id] : fv.value ? JSON.parse(fv.value) : []
        usedOptionIds.push(...optIds)
      })

      const counts: Record<number, number> = countBy(usedOptionIds)

      return mapValues(counts, (v, k) => {
        return {
          option: optionLookup[Number(k)],
          count: v
        }
      })
    }
})

const filteredCustomFieldFieldValues = selectorFamily({
  key: 'custom-field:field-values',
  get:
    (customFieldId: number) =>
    ({ get }) => {
      return get(customFieldFilteredFieldValueLookup)[customFieldId]
    }
})

const customFieldFilteredFieldValueLookup = selector({
  key: 'custom-field:field-value-lookup-filtered',
  get: ({ get }) => {
    const fieldValues = get(filteredFieldValuesState)
    return groupBy(fieldValues, 'custom_field_id')
  }
})

const filteredFieldValuesState = selector({
  key: 'custom-fields:filter-used:filtered-tasks',
  get: ({ get }) => {
    const taskIds = get(filteredTaskListIdsState)
    const taskFieldValues = taskIds.flatMap(id => {
      const task = get(taskListTaskState(id))
      const fieldValues = task.field_values
      // TODO: Investigate if this is needed as part of https://cutover.atlassian.net/browse/CFE-1138
      return fieldValues.map(fv => ({ ...fv, task_id: id }))
    })

    return taskFieldValues
  }
})
