// @ts-nocheck
import { injectable } from 'inversify'
import { container } from 'app/Config/IOC'
import { observable, action } from 'mobx'
import { CustomFieldGroupViewPresenter } from 'app/Settings/CustomFieldGroups/CustomFieldGroupsPage/CustomFieldGroupViewPresenter'
import { CustomFieldGroupFormPresenter } from 'app/Settings/CustomFieldGroups/CustomFieldGroupsPage/CustomFieldGroupForm/CustomFieldGroupFormPresenter'
import { GenericPageEditPresenter } from 'Shared/Presenters/GenericPageEditPresenter'
import { ModalPresenter } from 'Components/Atoms'

@injectable()
export class CustomFieldGroupEditPresenter extends GenericPageEditPresenter {
  protected viewPresenter: CustomFieldGroupViewPresenter = container.get(CustomFieldGroupViewPresenter)

  @observable
  public editPanelSaving: boolean = false

  @observable
  public confirmModalPresenter: ModalPresenter = new ModalPresenter()

  @observable
  public formPresenter: CustomFieldGroupFormPresenter = null

  public title: string = this.language.get('customFieldGroups:edit:title')
  public archiveTooltip: string = this.language.get('customFieldGroups:edit:archiveTooltip')

  @action
  public withFormPresenter(customFieldGroupFormPresenter: CustomFieldGroupFormPresenter) {
    this.formPresenter = customFieldGroupFormPresenter
    this.formPresenter
      .withDirtyCallback(() => {
        this.setEditPanelDirty()
      })
      .withResetCallback(() => {
        this.setEditPanelClean()
      })

    return this
  }
}
