import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const RowAddAfter = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="RowAddAfter" {...props}>
    <path d="M22.5 45h3v-6.5H32v-3h-6.5V29h-3v6.5H16v3h6.5V45Z" fill="#000" /><path fillRule="evenodd" clipRule="evenodd" d="M6 30h3v-4.5h30V30h3V14a8 8 0 0 0-8-8H14a8 8 0 0 0-8 8v16Zm16.5-7.5H9V14a5 5 0 0 1 5-5h8.5v13.5Zm3-13.5H34a5 5 0 0 1 5 5v8.5H25.5V9Z" fill="#000" />
  </StyledIcon>
))

RowAddAfter.displayName = 'RowAddAfter'

export { RowAddAfter }
