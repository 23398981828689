import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react'

import { Button } from '../../button'

type Updater = Dispatch<SetStateAction<number>>

type ShowMoreButtonOptions = {
  showAll?: boolean
  step?: number
}

export const useShowMoreButton = (
  numOptions: number,
  { step = 10, showAll }: ShowMoreButtonOptions = {}
): [number, JSX.Element | null, Updater?] => {
  const [maxNumShowing, setMaxNumShowing] = useState(step)
  const hasNoButton = numOptions <= step
  const hasShowMoreButton = hasNoButton || showAll ? false : numOptions > maxNumShowing
  const hasShowLessButton = hasNoButton || showAll ? false : !hasShowMoreButton && maxNumShowing > step
  const hasShowMoreOrLessButton = hasShowMoreButton || hasShowLessButton

  const handleClickShowMore = useCallback((e: any) => {
    // need to include the prevent default to avoid the onChange handler from firing
    e.preventDefault()
    setMaxNumShowing(maxNum => maxNum + step)
  }, [])

  const handleClickShowLess = useCallback((e: any) => {
    // need to include the prevent default to avoid the onChange handler from firing
    e.preventDefault()
    setMaxNumShowing(step)
  }, [])

  const showButton = useMemo(() => {
    return (
      <Button
        tertiary
        size="medium"
        label={hasShowMoreButton ? 'Show more' : 'Show less'}
        onClick={hasShowMoreButton ? handleClickShowMore : handleClickShowLess}
        full
        onMouseDown={(event: any) => event.preventDefault()}
        onKeyDown={(event: any) => {
          if (event.key === 'Enter' || event.key === ' ') {
            return hasShowMoreButton ? handleClickShowMore(event) : handleClickShowLess(event)
          }
        }}
      />
    )
  }, [hasShowMoreButton, handleClickShowMore, handleClickShowLess])

  return [showAll ? numOptions : maxNumShowing, hasShowMoreOrLessButton ? showButton : null, setMaxNumShowing]
}
