import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Save = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Save" {...props}>
    <path d="M34 42H14a8 8 0 0 1-8-8V14a8 8 0 0 1 8-8h14.686a8 8 0 0 1 5.657 2.343l5.314 5.314A8 8 0 0 1 42 19.314V34a8 8 0 0 1-8 8ZM9 34a5 5 0 0 0 5 5v-9a4 4 0 0 1 4-4h12a4 4 0 0 1 4 4v9a5 5 0 0 0 5-5V19.314a5 5 0 0 0-1.465-3.536l-5.313-5.314A5 5 0 0 0 30 9.176V15a4 4 0 0 1-4 4h-8a4 4 0 0 1-4-4V9a5 5 0 0 0-5 5v20Zm8-25v6a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V9H17Zm14 30v-9a1 1 0 0 0-1-1H18a1 1 0 0 0-1 1v9h14Z" fill="#000" />
  </StyledIcon>
))

Save.displayName = 'Save'

export { Save }
