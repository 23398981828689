import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Email = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Email" {...props}>
    <path d="M29.377 40.132A16.98 16.98 0 0 1 24 41c-9.389 0-17-7.611-17-17S14.611 7 24 7s17 7.611 17 17a16.99 16.99 0 0 1-.808 5.195C39.832 30.317 38.739 31 37.56 31H37a3 3 0 0 1-3-3V14h-3v2.859A9.967 9.967 0 0 0 24 14c-5.523 0-10 4.477-10 10s4.477 10 10 10a9.98 9.98 0 0 0 7.564-3.458A6 6 0 0 0 37 34h1.44c1.785 0 3.364-.99 4.118-2.608l.183-.392A19.958 19.958 0 0 0 44 24c0-11.046-8.954-20-20-20S4 12.954 4 24s8.954 20 20 20c2.21 0 4.338-.359 6.326-1.021l-.949-2.847ZM31 24a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z" fill="#000" />
  </StyledIcon>
))

Email.displayName = 'Email'

export { Email }
