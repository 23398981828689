import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const BrandCalendarEvent = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 100 100" a11yTitle="BrandCalendarEvent" {...props}>
    <path fill="#fff" d="M70.667 35.333H100v29.333H70.667z" /><path opacity=".5" fill="#fff" d="M0 0h100v29.333H0z" /><path fill="#fff" d="M35.333 35.333h29.333v29.333H35.333zM0 35.333h29.333v29.333H0zm35.333 35.334h29.333V100H35.333zM0 70.667h29.333V100H0z" />
  </StyledIcon>
))

BrandCalendarEvent.displayName = 'BrandCalendarEvent'

export { BrandCalendarEvent }
