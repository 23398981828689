// @ts-nocheck
import { IStorageGateway } from './IStorageGateway'
import { injectable } from 'inversify'
import 'reflect-metadata'

@injectable()
export class FakeStorageGateway implements IStorageGateway {
  private items: {} = {}

  public getItem(key) {
    return this.items[key]
  }

  public setItem(key, value) {
    this.items[key] = value
  }

  public removeItem(key) {
    this.items[key] = undefined
  }
}
