import { useContext, useEffect, useRef } from 'react'

import { UserChannelResponse } from 'main/services/api/data-providers/user/user-channel-response-types'
import { UserChannelContext } from 'main/services/api/websocket-providers'

type WebsocketHandler = (data: UserChannelResponse | undefined) => void

export const useUserWebsocket = () => {
  const userChannelResponse = useContext(UserChannelContext)
  const handlerRef = useRef<WebsocketHandler | null>(null)

  useEffect(() => {
    handlerRef.current?.(userChannelResponse)
  }, [userChannelResponse])

  const listen = (handler: WebsocketHandler) => {
    handlerRef.current = handler
  }

  return { listen }
}
