import { Chart } from './chart'
import { Duration } from './duration'
import { formatDuration, formatDurationDelta } from './format-duration'
import { NoDataAvailable } from './no-data-available'
import { Tile } from './tile'
import { DurationDelta, TaskDuration } from './types'

export type ParticularTaskAverageDurationConnectedProps = {
  name?: string
  onEvent: (payload: any) => void
  values: TaskDuration[]
}

export function ParticularTaskAverageDurationConnected({ name, values }: ParticularTaskAverageDurationConnectedProps) {
  const [data]: TaskDuration[] = values

  return (
    <Chart
      title={name}
      titleKey="dashboard:particularTaskAverageDuration:title"
      render={() => {
        if (!data?.name || !data?.duration) {
          return <NoDataAvailable />
        }

        const formattedDuration = formatDuration(data.duration)

        if (!formattedDuration) {
          return <NoDataAvailable />
        }

        return <ParticularTaskAverageDuration taskTitle={data.name} duration={formattedDuration} delta={data.delta} />
      }}
    />
  )
}

export type ParticularTaskAverageDurationProps = {
  taskTitle: string
  duration: string
  delta?: DurationDelta
}

export function ParticularTaskAverageDuration({ taskTitle, duration, delta }: ParticularTaskAverageDurationProps) {
  const formattedDelta = formatDurationDelta(delta)

  return (
    <Tile title={taskTitle}>
      <Duration duration={duration} delta={formattedDelta} />
    </Tile>
  )
}
