import { flatMap, isEmpty, sortBy, unescape } from 'lodash'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import { getUserStatus } from './use-users-query'
import { apiClient } from 'main/services/api/api-client'
import { ApiError } from 'main/services/api/http-gateway-adapter'
import { RoleType, UserStatus } from 'main/services/queries/types'
import { RunbookTeamUpdateResponse } from 'main/services/api/data-providers/runbook-types'

export type RunbookTeam = {
  id: number
  name: string
  users: RunbookTeamUser[]
  usersCount: number
  roleTypes: RoleType[]
  linked?: boolean
  canUpdate: boolean
  canDelete: boolean
  roleTypeId: number
  teamId?: number
}

export type RunbookTeamUser = {
  id: number
  name: string
  color: string
  status: UserStatus
  online: boolean | null
}

export type RunbookTeamUserApi = {
  id: number
  name: string
  firstName: string
  lastName: string
  handle: string
  color: string
  linked: boolean
  archived?: boolean
  notInAccount?: boolean
  status: string
  online: boolean | null
}

type Meta<T, ResponseProperty extends string> = {
  meta: {
    headers: {
      request_method: string
      request_class: string
      request_user_id: number
    }
    permissions?: {
      update?: number[]
      destroy?: number[]
    }
  }
} & { [P in ResponseProperty]: T }

export type RunbookTeamApi = {
  id: number
  name: string
  users_count: number
  color: string
  linked?: boolean
  role_types: RoleType[]
  team_id?: number
}

export type RunbookTeamRoleTypeUserApi = {
  id: number
  name: string
  handle: string
  color: string
  archived: boolean
}

export type RunbookTeamRoleTypeApi = {
  id: number
  name: string
  description?: string
  users: RunbookTeamUserApi[]
}

export type RunbookTeamQuery = {
  userId: number | undefined
  runbookId: number
  runbookVersionId: number
  teamId: number
}

function toRunbookTeam(
  userId: number | undefined,
  data: RunbookTeamUpdateResponse | Meta<RunbookTeamApi, 'runbook_team'>
): RunbookTeam {
  const roleTypes = data?.runbook_team?.role_types ?? []

  if (roleTypes.length !== 1) {
    throw new Error('Expected a single role type (runbook team member) returned for a team.')
  }

  const runbookTeam = {
    id: data.runbook_team.id,
    name: unescape(data.runbook_team.name),
    users: sortBy(
      flatMap(data.runbook_team.role_types, rt => rt.users).map(user => {
        const { first_name, last_name, not_in_account, ...restUser } = user
        return {
          firstName: first_name,
          lastName: last_name,
          ...restUser,
          status: getUserStatus({
            archived: user.archived ?? false,
            notInAccount: not_in_account ?? false,
            status: user.status
          })
        }
      }),
      u => u.name
    ),
    usersCount: data.runbook_team.users_count,
    roleTypes: data.runbook_team.role_types,
    linked: data.runbook_team.linked,
    canUpdate: false,
    canDelete: false,
    roleTypeId: roleTypes[0].id,
    teamId: data.runbook_team.team_id
  }

  return runbookTeam
}

export function useRunbookTeamQuery({ userId, runbookId, runbookVersionId, teamId }: RunbookTeamQuery) {
  return useQuery<RunbookTeam, ApiError>(
    ['feature', 'runbook-team', runbookId, runbookVersionId, teamId],
    async () => {
      const { data } = await apiClient.get<Meta<RunbookTeamApi, 'runbook_team'>>({
        url: `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/runbook_teams/${teamId}`
      })

      const runbookTeam = toRunbookTeam(userId, data)

      if (data.meta?.permissions) {
        runbookTeam.canUpdate =
          userId && data.meta?.permissions?.update ? data.meta.permissions.update.includes(userId) : false
        runbookTeam.canDelete =
          userId && data.meta?.permissions?.destroy ? data.meta.permissions.destroy.includes(userId) : false
      }

      return runbookTeam
    },
    {
      cacheTime: 0,
      useErrorBoundary: true
    }
  )
}

export type RunbookTeamUpdateProps = {
  userId: number | undefined
  runbookId: number
  runbookVersionId: number
  teamId: number
  originalRunbook?: RunbookTeam
}

export type RunbookTeamUpdate = {
  name: string
  userIds?: number[]
  usersToRemoveFromRunbook?: number[]
  reassign:
    | {
        runbookTeamId: number
        selected: string
        userId: number
        usersToReassign: number[]
      }
    | undefined
    | {}
}

type RunbookTeamUpdateRoleApi = {
  role_type_id?: number
  subject_id: number
  subject_type: string
  resource_id: number
  resource_type: string
}

type RunbookTeamUpdateApi = {
  runbook_team: {
    team: {
      name: string
    }
    roles?: RunbookTeamUpdateRoleApi[]
    users_to_remove_from_runbook: number[]
  }
  reassign?:
    | {
        runbook_team_id: number
        selected: string
        user_id: number
        users_to_reassign: number[]
      }
    | undefined
    | {}
}

export function useRunbookTeamUpdate({
  userId,
  runbookId,
  runbookVersionId,
  teamId,
  originalRunbook
}: RunbookTeamUpdateProps) {
  const queryClient = useQueryClient()

  return useMutation<RunbookTeamUpdateResponse, ApiError, RunbookTeamUpdate>(
    ['runbook-update'],
    async (runbookTeamUpdate: RunbookTeamUpdate) => {
      const { userIds } = runbookTeamUpdate
      const deletedUserIds = runbookTeamUpdate.usersToRemoveFromRunbook || []

      const runbookTeamData: RunbookTeamUpdateApi = {
        runbook_team: {
          team: {
            name: unescape(runbookTeamUpdate.name)
          },
          users_to_remove_from_runbook: deletedUserIds
        }
      }

      if (!isEmpty(runbookTeamUpdate.reassign)) {
        const { selected, userId, usersToReassign, runbookTeamId } = runbookTeamUpdate.reassign

        runbookTeamData.reassign = {
          selected,
          user_id: userId,
          users_to_reassign: usersToReassign,
          runbook_team_id: runbookTeamId
        }
      }

      if (userIds) {
        runbookTeamData.runbook_team.roles = userIds.map(userId => ({
          role_type_id: originalRunbook?.roleTypeId,
          subject_id: userId,
          subject_type: 'User', // TODO: consts for subject_type, resource_type
          resource_type: 'RunbookTeam',
          resource_id: teamId
        }))
      }

      const { data } = await apiClient.put<RunbookTeamUpdateApi, RunbookTeamUpdateResponse>({
        url: `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/runbook_teams/${teamId}`,
        data: runbookTeamData
      })

      if (!data) {
        throw new Error('No data within runbook team update response.')
      }

      return data as RunbookTeamUpdateResponse
    },
    {
      onSuccess: (response: RunbookTeamUpdateResponse) => {
        const data = toRunbookTeam(userId, response)
        queryClient.setQueryData(['feature', 'runbook-team', runbookId, runbookVersionId, teamId], {
          ...data,
          canDelete: originalRunbook?.canDelete,
          canUpdate: originalRunbook?.canUpdate
        })
      }
    }
  )
}
