import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Collapse = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Collapse" {...props}>
    <path d="M27 21.01h15.272v-3h-10.15L44.01 6.121 41.889 4 30 15.89V5.737h-3V21.01Zm-5.99 6.251H5.738v3H15.89L4 42.151l2.121 2.12 11.89-11.888v10.15h3V27.261Z" fill="#000" />
  </StyledIcon>
))

Collapse.displayName = 'Collapse'

export { Collapse }
