import { ThemeType as GrommetThemeType } from 'grommet/themes'
import { Merge, SetOptional, SetRequired } from 'type-fest'

// Exports the type and value for the grommet theme's icon key

type GrommetTheme = SetRequired<GrommetThemeType, 'icon'>
type GrommetIcon = GrommetTheme['icon']

export declare type ThemeIcon = SetOptional<
  Merge<
    Omit<GrommetIcon, 'size'>,
    {
      size: {
        large: string
        medium: string
        small: string
        xsmall: string
        xxsmall: string
        xlarge: string
      }
    }
  >,
  'extend'
>

export const icon: ThemeIcon = {
  size: {
    large: '24px',
    medium: '20px',
    small: '16px',
    xsmall: '12px',
    xlarge: '40px',
    xxsmall: '8px'
  }
}
