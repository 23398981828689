// @ts-nocheck
import { injectable } from 'inversify'
import { observable, action } from 'mobx'
import { subtractFromDate, addToDate, getHours, getMinutes } from 'Shared/Helpers/DateService'

@injectable()
export class TimeControlPresenter {
  public value?: Date
  public handleDateChange: (date: Date) => void
  public handleTimeChange: (time: Date) => void

  @observable
  public hourIncreaseHandler = () => {
    this.handleDateChange(addToDate(this.value, { hours: 1 }))
  }

  @observable
  public hourDecreaseHandler = () => {
    this.handleDateChange(subtractFromDate(this.value, { hours: 1 }))
  }

  @observable
  public minuteIncreaseHandler = () => {
    this.handleDateChange(addToDate(this.value, { minutes: 1 }))
  }

  @observable
  public minuteDecreaseHandler = () => {
    this.handleDateChange(subtractFromDate(this.value, { minutes: 1 }))
  }

  @observable
  public handleHourChange = (value: number) => {
    const difference = value - getHours(this.value)
    this.handleTimeChange(addToDate(this.value, { hours: difference }))
  }

  @observable
  public handleMinuteChange = (value: number) => {
    const difference = value - getMinutes(this.value)
    this.handleTimeChange(addToDate(this.value, { minutes: difference }))
  }

  @action
  public withProps = ({ value, handleDateChange, handleTimeChange }) => {
    this.value = value
    this.handleDateChange = handleDateChange
    this.handleTimeChange = handleTimeChange
    return this
  }
}
