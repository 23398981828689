import { LegacyBox as Box, colors } from '@cutover/react-ui'
import { DashboardComponentProps } from '../../types'
import { MrdDashboardWidget } from '../mrd-dashboard-widget'
import {
  DurationDelta,
  DurationDisplay,
  formatDuration,
  formatDurationDelta,
  NoDataAvailable,
  TaskDuration
} from 'main/components/dashboards/widgets/account/common'

type AverageRunbookDurationWidgetData = {
  name: string
  values: TaskDuration[]
}

export type AverageRunbookDurationWidgetProps = DashboardComponentProps<AverageRunbookDurationWidgetData>

export const AverageRunbookDurationWidget = ({ data }: AverageRunbookDurationWidgetProps) => {
  const values: TaskDuration[] = data.values
  const task = values && values.length > 0 ? values[0] : undefined
  const formattedDuration = formatDuration(task?.duration)

  return (
    <MrdDashboardWidget title={data.name}>
      {formattedDuration ? (
        <AverageRunbookDuration duration={formattedDuration} delta={task?.delta} />
      ) : (
        <NoDataAvailable />
      )}
    </MrdDashboardWidget>
  )
}

type AverageRunbookDurationProps = {
  duration: string
  delta?: DurationDelta
}

const AverageRunbookDuration = ({ duration, delta }: AverageRunbookDurationProps) => {
  const formattedDelta = formatDurationDelta(delta)

  return (
    <Box
      css={`
        display: flex;
        align-items: center;
        justify-content: center;
        height: 140px;
        background-color: ${colors.secondaryHover};
        border-radius: 8px;
      `}
    >
      <DurationDisplay duration={duration} delta={formattedDelta} />
    </Box>
  )
}
