import { ReactEditor } from 'slate-react'
import { Editor, Path, Element as SlateElement, Transforms } from 'slate'

import { createEmptyParagraphNode, getParentBlock } from './'

const createTableNode = (rows: number, columns: number) => {
  return {
    type: 'table',
    children: [createHeaderNode(columns), createBodyNode(rows, columns)]
  }
}

const createHeaderNode = (columns: number) => {
  const headerCellNodes = []
  for (let i = 0; i < columns; i++) {
    headerCellNodes.push(createHeaderCellNode())
  }

  const headerRow = {
    type: 'table-row',
    children: headerCellNodes
  }

  return {
    type: 'table-header',
    children: [headerRow]
  }
}

const createHeaderCellNode = () => {
  return {
    type: 'table-header-cell',
    children: [{ text: '' }]
  }
}

const createBodyNode = (rows: number, columns: number) => {
  const rowNodes = []
  for (let i = 1; i < rows; i++) {
    rowNodes.push(createRowNode(columns))
  }

  return {
    type: 'table-body',
    children: rowNodes
  }
}

const createRowNode = (columns: number) => {
  const cellNodes = []
  for (let i = 0; i < columns; i++) {
    cellNodes.push(createCellNode())
  }

  return {
    type: 'table-row',
    children: cellNodes
  }
}

const createCellNode = () => {
  return {
    type: 'table-cell',
    children: [{ text: '' }]
  }
}

export const addRow = (editor: Editor, direction: 'above' | 'below') => {
  rowOperation(editor, 'add', direction)
}

export const removeRow = (editor: Editor) => {
  rowOperation(editor, 'remove')
}

const rowOperation = (editor: Editor, operation: 'add' | 'remove', direction?: 'above' | 'below') => {
  const { selection } = editor
  Transforms.collapse(editor, { edge: 'focus' })
  if (!!selection) {
    const [tableRowNode] = Editor.nodes(editor, {
      match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'table-row'
    })

    if (tableRowNode) {
      const [[table]] = Editor.nodes(editor, {
        match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'table'
      })

      const [tableHeaderNode] = Editor.nodes(editor, {
        match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'table-header'
      })

      const [tableBodyNode] = Editor.nodes(editor, {
        match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'table-body'
      })

      const [, currentRow] = tableRowNode
      const path = currentRow
      const numRows = rowLength(table as SlateElement)

      if (operation === 'add') {
        const newRowNode = createRowNode(colLength(table as SlateElement)) as SlateElement
        if (tableHeaderNode) {
          if (direction === 'below') {
            // Only can insert BELOW a table header node
            const tableBodyPath = Path.next(tableHeaderNode[1])

            if (numRows === 1) {
              // we need to also insert the table-body node
              const tableBodyNode = {
                type: 'table-body',
                children: [newRowNode]
              }
              Transforms.insertNodes(editor, tableBodyNode as SlateElement, {
                at: tableBodyPath
              })
            } else {
              // just insert a new row
              tableBodyPath.push(0)
              Transforms.insertNodes(editor, newRowNode, {
                at: tableBodyPath
              })
            }
          }
        } else {
          Transforms.insertNodes(editor, newRowNode, {
            at: direction === 'above' ? path : Path.next(path)
          })
        }
      } else {
        if (numRows === 1) {
          // If removing the last row, remove the whole table
          removeTable(editor)
        } else {
          let removeAtPath = path
          if (tableHeaderNode) {
            // If within a header row, remove the table-header too (header row can't be re-added yet)
            removeAtPath = tableHeaderNode[1]
          } else if (
            tableBodyNode &&
            SlateElement.isElement(tableBodyNode[0]) &&
            tableBodyNode[0].children.length === 1
          ) {
            // If removing the last TR within the table body, also remove the table-body elem (can be re-added)
            removeAtPath = tableBodyNode[1]
          }

          Transforms.removeNodes(editor, {
            at: removeAtPath
          })
        }
      }
    }

    ReactEditor.focus(editor)
  }
}

export const removeTable = (editor: Editor) => {
  const parent = getParentBlock(editor)
  if (parent && parent.type === 'table') {
    Transforms.removeNodes(editor, {
      at: [editor.selection?.anchor.path[0]] as Path
    })
  }

  // If nothing left now, insert an empty paragraph
  const { children } = editor
  if (!children.length) {
    Transforms.insertNodes(editor, createEmptyParagraphNode())
  }
}

export const addColumn = (editor: Editor, direction: 'left' | 'right') => {
  columnOperation(editor, 'add', direction)
}

export const removeColumn = (editor: Editor) => {
  columnOperation(editor, 'remove')
}

const columnOperation = (editor: Editor, operation: 'add' | 'remove', direction?: 'left' | 'right') => {
  const { selection } = editor
  Transforms.collapse(editor, { edge: 'focus' })
  if (!!selection) {
    const [[table]] = Editor.nodes(editor, {
      match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'table'
    })
    const numRows = rowLength(table as SlateElement)
    const numCols = colLength(table as SlateElement)

    const hasTableHeader = hasHeaderRow(table as SlateElement)
    const rows = hasTableHeader ? numRows - 1 : numRows

    const [tableCellNode] = Editor.nodes(editor, {
      match: n =>
        !Editor.isEditor(n) && SlateElement.isElement(n) && (n.type === 'table-cell' || n.type === 'table-header-cell')
    })
    if (tableCellNode) {
      const [, currentCell] = tableCellNode
      const startPath = currentCell

      // If removing last column, remove the whole table
      if (numCols === 1 && operation === 'remove') {
        removeTable(editor)
        return
      }

      if ((tableCellNode[0] as SlateElement).type === 'table-header-cell') {
        startPath[startPath.length - 3] = 1 // tbody node
      }
      startPath[startPath.length - 2] = 0 // tr node
      for (let row = 0; row < rows; row++) {
        if (operation === 'add') {
          Transforms.insertNodes(editor, createCellNode() as SlateElement, {
            at: direction === 'left' ? startPath : Path.next(startPath)
          })
        } else {
          Transforms.removeNodes(editor, {
            at: startPath
          })
        }
        startPath[startPath.length - 2]++
      }

      if (hasTableHeader) {
        startPath[startPath.length - 3] = 0 // thead node
        startPath[startPath.length - 2] = 0 // tr node
        if (operation === 'add') {
          Transforms.insertNodes(editor, createHeaderCellNode() as SlateElement, {
            at: direction === 'left' ? startPath : Path.next(startPath)
          })
        } else {
          Transforms.removeNodes(editor, {
            at: startPath
          })
        }
      }
    }
    ReactEditor.focus(editor)
  }
}

const hasHeaderRow = (table: SlateElement): boolean => {
  let result = false
  function walkTable(currentNode: any) {
    if (currentNode.children) {
      for (let node of currentNode.children) {
        if (node.type === 'table-header') {
          result = true
          break
        }
      }
    }
  }
  walkTable(table)
  return result
}

const rowLength = (table: SlateElement) => {
  let rows = 0
  function walkTable(currentNode: any) {
    if (currentNode.children) {
      for (let node of currentNode.children) {
        if (node.type === 'table-row') {
          rows++
        }
        walkTable(node)
      }
    }
  }
  walkTable(table)
  return rows
}

const colLength = (table: SlateElement) => {
  let cols = 0
  function walkTable(currentNode: any) {
    if (currentNode.children) {
      for (let node of currentNode.children) {
        if (node.type === 'table-row') {
          cols = node.children.length
          break
        } else {
          walkTable(node)
        }
      }
    }
  }
  walkTable(table)
  return cols
}

export const insertTable = (editor: Editor) => {
  const { selection } = editor
  const table = createTableNode(3, 4)

  if (!!selection) {
    // TODO: check if selection is within OL/UL, if so add table after

    Transforms.collapse(editor, { edge: 'focus' })
    const [parentNode, parentPath] = Editor.parent(editor, selection.focus?.path)

    if (editor.isVoid(parentNode as SlateElement)) {
      Transforms.insertNodes(editor, table as SlateElement, {
        at: Path.next(parentPath),
        select: true
      })
    } else {
      Transforms.insertNodes(editor, table as SlateElement, { at: [selection.anchor.path[0] + 1] })
    }
  } else {
    Transforms.insertNodes(editor, table as SlateElement)
  }
  ReactEditor.focus(editor)
}
