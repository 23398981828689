import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const TaskTypeDecisionDashed = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="TaskTypeDecisionDashed" {...props}>
    <path d="M29.789 10.307 27.22 5.86c-1.431-2.48-5.01-2.48-6.442 0l-2.606 4.513-1.732-1 2.606-4.513c2.201-3.813 7.705-3.813 9.906 0l2.568 4.447-1.732 1Zm9.731 12.856-1.731 1-6-10.392 1.732-1 6 10.392Zm2.973 9.149c1.432 2.48-.358 5.579-3.22 5.579H34v2h5.272c4.403 0 7.155-4.766 4.953-8.58l-2.704-4.683-1.732 1 2.704 4.684Zm-26.32-18.474-1.732-1-6 10.392 1.732 1 6-10.393ZM6.44 26.694l-2.665 4.618c-2.202 3.813.55 8.579 4.953 8.579H14v-2H8.728c-2.863 0-4.653-3.1-3.221-5.58l2.666-4.617-1.732-1ZM18 39.89v-2h12v2H18ZM15 4 0 30l1.732 1 15-26L15 4Zm31.134 27-15-26 1.732-1 15 26-1.732 1Z" fill="#000" />
  </StyledIcon>
))

TaskTypeDecisionDashed.displayName = 'TaskTypeDecisionDashed'

export { TaskTypeDecisionDashed }
