import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Statistics = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Statistics" {...props}>
    <path d="m44.56 12.06-2.12-2.12L30.5 21.878l-7.5-7.5-20.06 20.06 2.12 2.122L23 18.62l7.5 7.5 14.06-14.06ZM3 21h8.636l-3 3H3v-3Z" fill="#000" /><path d="m26.601 34.723-7.98-7.98 2.122-2.121 6.656 6.656 16.615-5.697.973 2.838L26.6 34.723Z" fill="#000" />
  </StyledIcon>
))

Statistics.displayName = 'Statistics'

export { Statistics }
