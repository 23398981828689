import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const BrandSupport = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 100 101" a11yTitle="BrandSupport" {...props}>
    <g clipPath="url(#a)" fill="#fff"><path d="M50 50.666h50v50H50z" /><path opacity=".5" d="M85.355 15.311a50 50 0 1 0-70.71 70.71L50 50.667l35.355-35.355Z" /></g>
  </StyledIcon>
))

BrandSupport.displayName = 'BrandSupport'

export { BrandSupport }
