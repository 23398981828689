import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const PinFilled = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="PinFilled" {...props}>
    <path d="M29.118 3.862c-1.61-.929-3.634.16-3.746 2.016l-.217 3.611-5.676 9.831c-5 .515-7.661 3.55-8.71 5.109-.84 1.25-.269 2.748.823 3.379l6.359 3.67-5.25 9.094 2.598 1.5 5.25-9.093 6.353 3.668c1.093.63 2.676.376 3.338-.977.827-1.687 2.123-5.51.07-10.097l5.675-9.831 3.02-1.994c1.55-1.024 1.481-3.322-.128-4.251l-9.76-5.635Z" fill="#000" />
  </StyledIcon>
))

PinFilled.displayName = 'PinFilled'

export { PinFilled }
