// @ts-nocheck
import {
  sub,
  add,
  set,
  format,
  isValid,
  intervalToDuration,
  getUnixTime,
  Duration as DFNSDuration,
  differenceInYears,
  differenceInMonths,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  differenceInMilliseconds,
  parseISO,
  isWithinInterval,
  Interval as DateInterval,
  fromUnixTime
} from 'date-fns'

export { areIntervalsOverlapping, endOfDay, startOfDay, isSameDay } from 'date-fns'
export const fromUnix = fromUnixTime
export const toUnix = getUnixTime
export type Duration = DFNSDuration

export type Interval = DateInterval
export const isIntervalCovered = (interval: Interval, testInterval: Interval) => {
  return isWithinInterval(testInterval.start, interval) && isWithinInterval(testInterval.end, interval)
}

// initalize date object with 00:00 time
export const initalizeDateWithDefaultTime = (): Date => {
  return setDate(new Date(), {
    hours: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0
  })
}

export function subtractFromDate(date: number, duration: Duration): number
export function subtractFromDate(date: Date, duration: Duration): Date
export function subtractFromDate(date: Date | number, duration: Duration) {
  const inputDate = typeof date === 'number' ? fromUnix(date) : date
  const resultDate = sub(inputDate, duration)
  return typeof date === 'number' ? toUnix(resultDate) : resultDate
}

export function addToDate(date: number, duration: Duration): number
export function addToDate(date: Date, duration: Duration): Date
export function addToDate(date: Date | number, duration: Duration) {
  const inputDate = typeof date === 'number' ? fromUnix(date) : date
  const resultDate = add(inputDate, duration)
  return typeof date === 'number' ? toUnix(resultDate) : resultDate
}

export const cloneDate = (date: Date) => new Date(date.getTime()) // native js Date method

export type SetOptionType = {
  year?: number
  month?: number
  date?: number
  hours?: number
  minutes?: number
  seconds?: number
  milliseconds?: number
}

export const setDate = (date: Date, options: SetOptionType) => new Date(set(date, options).getTime())

export const formatDate = (date: Date, formatString: string = "yyyy-MM-dd'T'HH:mm:ss.SSSxxx") => {
  return format(date, formatString)
}

const diffMethods = {
  differenceInYears,
  differenceInMonths,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  differenceInMilliseconds
}

type TimeUnitType = 'year' | 'month' | 'weeks' | 'days' | 'hours' | 'minutes' | 'seconds' | 'milliseconds'

export const getDifference = (date: Date, comparingDate: Date, unit: TimeUnitType) => {
  return diffMethods[`differenceIn${unit.charAt(0).toUpperCase() + unit.slice(1)}`](date, comparingDate)
}

/**
 * type Duration = Omit<globalThis.Duration, 'days', 'weeks'> & {
 *   days: 1 | 2 | 3 | 4 | 5 | 6
 *   weeks: 1 to 52
 * }
 */
export const getDuration = (seconds: number) => {
  const duration = intervalToDuration({ start: 0, end: seconds * 1000 })
  // adding duration weeks
  if (!duration.weeks) {
    duration.weeks = (duration.days / 7) | 0
    duration.days = duration.days - duration.weeks * 7
  }
  return duration
}

export const getDurationInDays = (
  seconds: number
): Pick<globalThis.Duration, 'days' | 'hours' | 'minutes' | 'seconds'> => {
  const days = Math.floor(seconds / (3600 * 24))
  const hours = Math.floor((seconds % (3600 * 24)) / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)
  const sec = Math.floor(seconds % 60)

  return { days, hours, minutes, seconds: sec }
}

export const getUtcString = (date: Date) => date.toISOString()

export const getUnix = (date: Date) => getUnixTime(date)

export const isDateValid = (date: Date) => isValid(date)

// get methods -- native Date methods
export const getYear = (date: Date) => date.getFullYear()

export const getMonth = (date: Date) => date.getMonth()

export const getDate = (date: Date) => date.getDate()

export const getHours = (date: Date) => date.getHours()

export const getMinutes = (date: Date) => date.getMinutes()

export const getSeconds = (date: Date) => date.getSeconds()

export const dateAsSeconds = (date: Date) => date.getTime() / 1000

export const dateAsMilliseconds = (date: Date) => date.getTime()

export const dateStringToSeconds = (dateString: string) => new Date(parseISO(dateString)).getTime() / 1000

// from legacy Time.ts file
export const dateOrNull = (arg): any => {
  return arg ? new Date(arg * 1000) : null
}

export const dateOrNullIso = (arg): any => {
  return arg ? new Date(parseISO(arg)).toISOString() : null
}

export const getDateInDayMonthYearFormat = (dateString: string) => {
  const date = new Date(dateString)

  const options = { year: 'numeric', month: 'short', day: 'numeric' } as const

  const dateTimeFormat = new Intl.DateTimeFormat('en-GB', options)
  return dateTimeFormat.format(date)
}
