import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Predecessors = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Predecessors" {...props}>
    <path d="M18 12a6.003 6.003 0 0 1-4.5 5.811v4.204a4.5 4.5 0 0 0 1.318 3.182l5.828 5.827A5.972 5.972 0 0 1 24 30c1.242 0 2.397.378 3.354 1.024l5.828-5.827a4.5 4.5 0 0 0 1.318-3.182V17.81a6 6 0 1 1 3 0v4.204a7.5 7.5 0 0 1-2.197 5.303l-5.956 5.957a6 6 0 1 1-10.693 0l-5.957-5.957a7.5 7.5 0 0 1-2.197-5.303V17.81A6 6 0 1 1 18 12Zm-6 3a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm24 0a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm-9 21a3 3 0 1 0-6 0 3 3 0 0 0 6 0Z" fill="#000" />
  </StyledIcon>
))

Predecessors.displayName = 'Predecessors'

export { Predecessors }
