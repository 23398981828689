import { colors } from '../../utils'

// NOTE: Nivo does not type the props for layers; we could do it manually at some point.

/** Lines for the x and y axes */
export const ChartAxesLayer = (props: any) => {
  // Couldn't figure out a way to get only the O coordinate lines back through props when disabling the grid lines via props
  const width: number = props.width
  const height: number = props.height
  const marginBottom = props.margin.bottom
  const marginTop = props.margin.top

  const xAxis = (
    <line
      stroke={colors.primaryGreyHoverBackgroundColor}
      x1={0}
      x2={width}
      y1={height - marginBottom - marginTop}
      y2={height - marginBottom - marginTop}
    />
  )
  const yAxis = (
    <line stroke={colors.primaryGreyHoverBackgroundColor} x1={0} x2={0} y1={0} y2={height - marginBottom - marginTop} />
  )

  return (
    <>
      {xAxis}
      {yAxis}
    </>
  )
}
