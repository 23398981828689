import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const UserFinish = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="UserFinish" {...props}>
    <path d="M24 16a7 7 0 1 1-14 0 7 7 0 0 1 14 0Zm-3 0a4 4 0 1 0-8 0 4 4 0 0 0 8 0Zm7.959 21C28.45 30.84 23.29 26 17 26S5.55 30.84 5.041 37c-.027.33-.041.663-.041 1a2 2 0 0 0 2 2h20a2 2 0 0 0 2-2c0-.337-.014-.67-.041-1Zm-3.014 0H8.055a9.001 9.001 0 0 1 17.89 0ZM25 24.607l2.121-2.122 5.364 5.364L43.335 17l2.12 2.121-12.97 12.97L25 24.608Z" fill="#000" />
  </StyledIcon>
))

UserFinish.displayName = 'UserFinish'

export { UserFinish }
