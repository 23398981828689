import styled from 'styled-components'

import { Box, BoxProps, resolveColor, Text } from '@cutover/react-ui'

export const RightItemActionsContainer = styled(props => <Box {...props} />).attrs(() => ({
  direction: 'row',
  flex: false,
  className: 'rb-hover-actions'
}))`
  position: absolute;
  justify-content: flex-end;
  right: 210px;
  display: none;
`

export const ItemContainer = styled(props => <Box {...props} />).attrs((props: { selected?: boolean } & BoxProps) => ({
  background: props.background ?? (props.selected ? 'bg-1' : 'bg'),
  pad: '6px',
  direction: 'row',
  width: '100%',
  justify: 'between',
  className: 'rb-list-item-container',
  round: '8px',
  gap: '12px'
}))<{ selected?: boolean } & BoxProps>`
  /* currently the container query related to this container rule is in the index.html file. Adding it to styled components right now does not work as it is stripped out. It also triggers a ts-styled error */
  container-type: inline-size;
  container-name: rb-list-item;
  position: relative;

  &:hover {
    background: ${props => resolveColor(props.selected ? 'bg-2' : 'bg-1', props.theme)};

    ${RightItemActionsContainer} {
      display: flex;
    }
  }
`

export const LeftItems = styled(props => <Box {...props} />).attrs(() => ({
  alignSelf: 'center',
  gap: '8px',
  direction: 'row',
  align: 'center',
  width: '100%'
}))``

export const LeftItemIcon = styled(props => <Box {...props} />).attrs(() => ({
  height: '40px',
  width: '40px',
  flex: false
}))``

export const LeftItemTextContainer = styled(Box)`
  overflow: visible;
  flex: 1;
`

export const LeftItemTextTitle = styled(Text).attrs(() => ({
  truncate: true
}))`
  height: 22px;
  line-height: 22px;
  span {
    line-height: 22px;
    vertical-align: top; //fixes alignment issues caused by linked RB title
  }
`

// Note: CSS overrides needed here & other elems so that a) the RB list item does not
// exceed 52px (infinite scroll needs to know this), and b) that variable content
// does not affect alignment/height. Eg a title with a pill vs one without
// So we now give 22px for the header, 16 for the subtitle, and 2px bottom margin,
// for a total of 40 - in order to make it look correct visually regardless of content
export const LeftItemTextSubtitle = styled(Text).attrs(() => ({
  truncate: true,
  color: 'text-light',
  size: 'small'
}))`
  display: flex;
  height: 16px;
  line-height: 16px;
  overflow: visible; // to allow RAG indicator anim to work properly
  span {
    line-height: 16px;
  }
  margin-bottom: 2px;
`

export const RightItems = styled(props => <Box {...props} />).attrs(() => ({
  alignSelf: 'center',
  direction: 'row',
  gap: '12px',
  align: 'center',
  flex: false
}))`
  position: relative;
`

export const RightItemText = styled(Text).attrs(() => ({
  truncate: true
}))``

export const RightItemAction = styled(props => <Box {...props} />).attrs(() => ({
  height: '40px',
  width: '40px',
  flex: false,
  round: '100%'
}))``

export const RightItemActionContainer = styled(props => <Box {...props} />).attrs(() => ({
  flex: false,
  background: 'bg-1'
}))`
  min-width: 48px;
  padding: 0 4px;
  height: 42px;

  &:first-of-type {
    padding-left: 0;
    min-width: 44px;
  }

  &:last-of-type {
    padding-right: 0;
    min-width: 44px;
  }
`

export const RightItemTextContainer = styled(props => <Box {...props} />).attrs(() => ({
  width: '175px',
  align: 'end',
  flex: false,
  className: 'rb-right-item-text'
}))``

export const RightItemIconContainer = styled(props => <Box {...props} />).attrs(() => ({
  height: '40px',
  width: '40px',
  flex: false,
  align: 'center',
  justify: 'center'
}))``

export const RightItemButtonContainer = styled(props => <Box {...props} />).attrs(() => ({
  flex: false
}))``

export const RightItemHoverToggleContent = styled(props => <Box {...props} />)`
  ${RightItemButtonContainer} {
    display: none;
  }

  ${RightItemButtonContainer} {
    ${ItemContainer}:hover & {
      display: flex;
    }
  }

  ${RightItemTextContainer} {
    ${ItemContainer}:hover & {
      display: none;
    }
  }
`
