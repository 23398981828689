import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const ClearFormat = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="ClearFormat" {...props}>
    <path d="M8 6h32v8.5h-3V9H25.5v11.461l-3-1.732V9H11v3.09l-3-1.733V6Zm-1.01 7.238-1.5 2.598 17.01 9.821V39H17v3h14v-3h-5.5V27.39l14.631 8.446 1.5-2.598-34.64-20Z" fill="#000" />
  </StyledIcon>
))

ClearFormat.displayName = 'ClearFormat'

export { ClearFormat }
