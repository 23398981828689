// @ts-nocheck
import { observer } from 'mobx-react-lite'
import { EditPanelFormWrapper } from 'Components/Templates'
import { TaskTypeEditPresenter } from './TaskTypeEditPresenter'
import { TaskTypeFormPresenter } from 'app/Settings/TaskTypes/TaskTypesPage/TaskTypeForm/TaskTypeFormPresenter'
import { TaskTypeFormComponent } from 'app/Settings/TaskTypes/TaskTypesPage/TaskTypeForm/TaskTypeFormComponent'

interface ITaskTypeListComponentProps {
  presenter: TaskTypeEditPresenter
  editFormPresenter: TaskTypeFormPresenter
}

const TaskTypeEditSection = observer<ITaskTypeListComponentProps>(({ presenter, editFormPresenter }) => {
  const {
    title,
    mode,
    closeCallback,
    save,
    reset,
    archiveTooltip,
    archiveButtonCallback,
    canArchiveTaskType,
    editPanelSaving,
    confirmModalPresenter,
    triggerScroll,
    setTriggerScroll
  } = presenter

  presenter.withTaskTypeFormPresenter(editFormPresenter)

  return (
    <EditPanelFormWrapper
      heading={title}
      mode={mode}
      showArchive={canArchiveTaskType}
      archiveTooltip={archiveTooltip}
      singleLineArchiveTooltip
      closeCallback={closeCallback}
      saveCallback={save}
      resetCallback={reset}
      archiveCallback={archiveButtonCallback}
      editPanelSaving={editPanelSaving}
      confirmModalPresenter={confirmModalPresenter}
      triggerScroll={triggerScroll}
      setTriggerScroll={setTriggerScroll}
    >
      <TaskTypeFormComponent presenter={editFormPresenter} />
    </EditPanelFormWrapper>
  )
})

export default TaskTypeEditSection
