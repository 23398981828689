// @ts-nocheck
import styled from 'styled-components'
import { px2rem } from 'app/Styles/Variables'
import { observer } from 'mobx-react-lite'
import { FormInputGroupPresenter } from './FormInputGroupPresenter'
import { GenericFormInputPresenter } from '@logicroom/validator'

const Wrapper = styled.div`
  margin-top: ${px2rem(16)};
`

interface IFormInputGroupProps {
  presenter: FormInputGroupPresenter<GenericFormInputPresenter<any>, any>
}

export const FormInputGroup = observer<IFormInputGroupProps>(props => {
  if (props && !props.presenter) return
  const { items, componentKeyProperty } = props.presenter

  const createItem = item => {
    if (!item.component) return null
    const localProps = {
      key: item.data[componentKeyProperty],
      presenter: item.presenter
    }
    return item.component(localProps)
  }

  return <Wrapper>{items.map(item => createItem(item))}</Wrapper>
})
