// @ts-nocheck
import { observable, computed } from 'mobx'
import { TIcons } from 'Components/Atoms/Icon'
import { GenericFormInputPresenter } from '@logicroom/validator'

export enum ItemType {
  checkBox,
  field,
  input
}

export class ParentChildCheckboxPresenter extends GenericFormInputPresenter<boolean> {
  @observable
  public label: string

  @observable
  public helpText?: string

  @observable
  public helpTextIcon?: TIcons

  @observable
  public parent: ParentChildCheckboxPresenter

  @observable
  public child: ParentChildCheckboxPresenter

  @observable
  public open: boolean = false

  @observable
  public editIconVisible: boolean = false

  @observable
  public expandable: boolean = false

  @observable
  public editable: boolean = false

  @observable
  public inputPlaceHolder: string

  @observable
  public itemType?: ItemType

  @computed
  public get showLabel() {
    return this.itemType === ItemType.field || this.itemType === ItemType.checkBox
  }

  @computed
  public get type() {
    return this.parent ? 'child' : 'parent'
  }

  @computed
  public get chevronRotation() {
    return this.open ? 45 : 0
  }

  @computed
  public get hasChildren() {
    return this.type === 'parent' && this.child !== null
  }

  constructor(label: string, initialValue = false) {
    super(initialValue)
    this.label = label
    this.expandable = false
    this.editable = false
    this.itemType = ItemType.checkBox
  }

  public withExpandable? = () => {
    this.expandable = true
    return this
  }

  public withEditable? = () => {
    this.editable = true
    return this
  }

  public withItemType? = (itemTypeArg: ItemType, placeholder?: string) => {
    this.itemType = itemTypeArg
    if (itemTypeArg === ItemType.input) {
      if (!placeholder) {
        throw Error('Please provide a placeholder for a checkbox input')
      }
      this.inputPlaceHolder = placeholder
    }
    return this
  }

  public withHelpText? = (text: string) => {
    this.helpText = text
    return this
  }

  public withHelpTextIcon? = (icon: TIcons) => {
    this.helpTextIcon = icon
    return this
  }

  public toggleOpenChildren = () => {
    this.open = !this.open
  }

  public showEditIcon = () => {
    this.editIconVisible = true
  }

  public hideEditIcon = () => {
    this.editIconVisible = false
  }

  public flipField = () => {
    if (this.itemType === ItemType.field) {
      this.itemType = ItemType.input
    }
  }
}
