// @ts-nocheck
import { useEffect } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { withPresenter } from 'app/Config/withPresenter'
import { AngularChooseRunbookTemplatePresenter } from './AngularChooseRunbookTemplatePresenter'
import { AngularRunbookTemplateList } from './AngularRunbookTemplateList'
import { LoadingPanel } from 'Shared/Components/Templates/LoadingPanel/LoadingPanel'
import { SearchBox, Button } from 'Components/Molecules'
import { Heading, ModalPresenter, DisplaySvgIcon, TDisplaySvgIcons, ModalBackButton } from 'Components/Atoms'
import { px2rem } from 'app/Styles/Variables'
import { Checkbox } from 'Components/Molecules'

const Wrapper = styled.div`
  padding: ${px2rem(24)} ${px2rem(32)};
`

const HeadingWrapper = styled.div`
  display: flex;
  margin-bottom: ${px2rem(14)};
  align-items: center;
`

const ModalBackButtonStyled = styled(ModalBackButton)`
  margin-right: ${px2rem(7)};
`

const DisplaySvgIconStyled = styled(DisplaySvgIcon)`
  margin-right: ${px2rem(15)};
  flex-shrink: 0;
`

const SearchBoxWrapper = styled.div`
  position: relative;
`

const RunbookTemplateListWrapper = styled.div`
  position: relative;
  height: ${px2rem(220)};
  margin: ${px2rem(12)} 0 ${px2rem(20)};
`

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

interface IChooseRunbookTemplateProps {
  presenter: AngularChooseRunbookTemplatePresenter
  runbookType: {
    id: string
    iconName: TDisplaySvgIcons
    iconColor: string
  }
  openPreviousModal: () => void
  modalPresenter: ModalPresenter
}

const AngularChooseRunbookTemplateComponent = observer<IChooseRunbookTemplateProps>(
  ({ presenter, runbookType, openPreviousModal, modalPresenter }) => {
    const {
      searchBoxPresenter,
      onlyApprovedCheckboxPresenter,
      language,
      intialRunbookTemplatesLoading,
      loadRunbookTemplateList,
      createBlankRunbookButtonPresenter,
      cancelButtonPresenter,
      openCreateRunbookFromTemplateModal,
      closeModal,
      value,
      accountId
    } = presenter

    useEffect(() => {
      const initLoadRunbookTemplateList = async () => {
        await loadRunbookTemplateList()
      }

      initLoadRunbookTemplateList()
    }, [])

    const goBack = () => {
      if (openPreviousModal) {
        openPreviousModal()
        closeModal()
      }
    }

    return (
      <Wrapper>
        <HeadingWrapper>
          {openPreviousModal && <ModalBackButtonStyled onClick={goBack} />}
          <DisplaySvgIconStyled name={runbookType.iconName} color={runbookType.iconColor} />
          <Heading theme="dark">{language.get('runbooks:chooseRunbookTemplate:heading')}</Heading>
        </HeadingWrapper>
        <SearchBoxWrapper>
          <SearchBox
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            presenter={searchBoxPresenter}
            isExpandable={false}
            hasCloseButton={false}
            placeholder={language.get('runbooks:chooseRunbookTemplate:searchPlaceholder')}
            searchContent={language.get('runbooks:chooseRunbookTemplate:searchContent')}
            language={language}
          />
        </SearchBoxWrapper>
        <Checkbox key="onlyApprovedTemplates" presenter={onlyApprovedCheckboxPresenter}></Checkbox>
        <RunbookTemplateListWrapper>
          {intialRunbookTemplatesLoading ? (
            <LoadingPanel />
          ) : (
            <AngularRunbookTemplateList
              modalPresenter={modalPresenter}
              searchedValue={value}
              runbookType={runbookType}
              openCreateRunbookFromTemplateModal={openCreateRunbookFromTemplateModal}
              loadRunbookTemplateList={loadRunbookTemplateList}
              accountId={accountId}
            />
          )}
        </RunbookTemplateListWrapper>
        <ButtonsWrapper>
          <Button presenter={cancelButtonPresenter} />
          <Button dataTestId="choose-runbook-template-blank" presenter={createBlankRunbookButtonPresenter} />
        </ButtonsWrapper>
      </Wrapper>
    )
  }
)

export const AngularChooseRunbookTemplate = withPresenter(AngularChooseRunbookTemplatePresenter)(
  AngularChooseRunbookTemplateComponent
)
