import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const FolderOpen = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="FolderOpen" {...props}>
    <path d="M10 42a4 4 0 0 1-4-4V10a4 4 0 0 1 4-4h11.5a4 4 0 0 1 4 4v1a2 2 0 0 0 2 2H38a4 4 0 0 1 4 4h-3a1 1 0 0 0-1-1H27.5a5 5 0 0 1-5-5v-1a1 1 0 0 0-1-1H10a1 1 0 0 0-1 1v21.5l2.171-8.599A4 4 0 0 1 15.017 20h27.68c2.658 0 4.576 2.544 3.846 5.099l-3.747 13.94A4 4 0 0 1 38.933 42H10Zm28.2-3a2 2 0 0 0 1.933-1.485l3.526-13.24A1 1 0 0 0 42.697 23h-27.68a1 1 0 0 0-.961.725l-3.743 14.017A1 1 0 0 0 11.28 39H38.2Z" fill="#000" />
  </StyledIcon>
))

FolderOpen.displayName = 'FolderOpen'

export { FolderOpen }
