// @ts-nocheck
import { observer } from 'mobx-react-lite'
import { RoleWithExpiryPresenter } from './RoleWithExpiryPresenter'
import styled from 'styled-components'
import { Checkbox, DateTime } from 'Components/Molecules'
import { colors, px2rem } from 'app/Styles/Variables'

const RoleContainer = styled.div`
  margin-bottom: ${(props: { showDatePicker: boolean }) => (props.showDatePicker ? px2rem(16) : 0)};
`
const CheckboxWrapper = styled.div``

const DateTimeStyled = styled(DateTime)`
  margin-left: ${px2rem(5)};
  margin-top: ${px2rem(12)};
`

interface IRoleWithExpiryProps {
  presenter: RoleWithExpiryPresenter
}

export const RoleWithExpiry = observer<IRoleWithExpiryProps>(({ presenter }) => {
  const { checkboxPresenter, dateTimePresenter, getColourForExpiryStatus, showDatePicker, showDatePickerIcon } =
    presenter

  const handleMouseHover = () => {
    if (checkboxPresenter.value) {
      checkboxPresenter.showEventIcon()
    }
  }

  const handleMouseLeave = () => {
    if (checkboxPresenter.value && !showDatePickerIcon) {
      checkboxPresenter.hideEventIcon()
    }
  }

  return (
    <RoleContainer showDatePicker={showDatePicker}>
      <CheckboxWrapper onMouseEnter={handleMouseHover} onMouseLeave={handleMouseLeave}>
        <Checkbox
          presenter={checkboxPresenter}
          labelColor={getColourForExpiryStatus() === colors.warningOrange ? colors.warningOrange : ''}
        />
      </CheckboxWrapper>
      {showDatePicker && <DateTimeStyled presenter={dateTimePresenter} />}
    </RoleContainer>
  )
})
