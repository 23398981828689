import { useEffect, useRef } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { addMinutes, isPast } from 'date-fns'
import { Controller, useForm, useFormState } from 'react-hook-form'

import {
  DateTimePicker,
  EditPanel,
  Form,
  RadioboxGroup,
  Select,
  SettableFieldType,
  TextEditor,
  TimePicker,
  WeekdayPicker
} from '@cutover/react-ui'
import { mrdSchema } from 'main/components/dashboards/runbook-dashboard/share-runbook-dashboard-email-form'
import { RunbookDashboardShareEmailFormType } from 'main/components/dashboards/runbook-dashboard/share-runbook-dashboard-types'
import {
  UpdateUserSettingParams,
  UserSetting
} from 'main/components/dashboards/runbook-dashboard-schedule/runbook-dashboard-schedule-types'
import { RecipientMultiselect, TeamRecipient, UserRecipient } from 'main/components/recipients-multiselect'
import { TimezoneSelect } from 'main/components/shared/form/timezone-select'
import { useLanguage } from 'main/services/hooks'
import { SavedView } from 'main/services/queries/use-saved-view-groups'

type RunbooksDashboardScheduleFormProps = {
  setting: UserSetting
  scheduleIndex: number
  filterData: { filters: SavedView[] } | null
  isFilterDataLoading: boolean
  accountId: number
  onBack: () => void
  onSubmit: (setting: UpdateUserSettingParams) => void
  isSubmitting: boolean
  onClose: () => void
}

export const RunbooksDashboardScheduleForm = ({
  setting,
  scheduleIndex,
  filterData,
  isFilterDataLoading,
  accountId,
  onBack,
  onSubmit,
  isSubmitting,
  onClose
}: RunbooksDashboardScheduleFormProps) => {
  const recipientMultiselectRef = useRef<{ reset: () => void }>()

  const { t } = useLanguage()
  const { control, watch, reset, handleSubmit } = useForm<RunbookDashboardShareEmailFormType>({
    mode: 'all',
    resolver: yupResolver(mrdSchema),
    context: { scheduling: true },
    defaultValues: getDefaultValues(setting, scheduleIndex)
  })
  const { isDirty, errors } = useFormState({ control })
  const textEditorRef = useRef<SettableFieldType>(null)
  const frequencyType = watch('frequencyType')
  const dashboardView = watch('dashboardView')

  useEffect(() => {
    resetForm()
  }, [setting])

  const resetForm = () => {
    const defaultValues = getDefaultValues(setting, scheduleIndex)
    reset(defaultValues)
    recipientMultiselectRef.current?.reset?.()
    textEditorRef.current?.reset?.(defaultValues['message'])
  }

  return (
    <EditPanel
      isDirty={isDirty}
      onBack={onBack}
      onReset={resetForm}
      onClose={onClose}
      onSubmit={() =>
        handleSubmit(form => onSubmit(buildScheduleEmailForPut(form, setting, scheduleIndex, filterData)))()
      }
      isSubmitting={isSubmitting}
      title={t('dashboard:shareSchedulePanel:editHeading')}
    >
      <Form>
        <Controller
          control={control}
          name="recipients"
          render={({ field: { onChange } }) => (
            <RecipientMultiselect
              getInitialRecipients
              ref={recipientMultiselectRef}
              users={setting.data.schedules[scheduleIndex].user_recipients.sort()}
              teams={setting.data.schedules[scheduleIndex].team_recipients.sort()}
              required
              hasError={!!errors.recipients}
              accountId={accountId}
              onChange={recipients => {
                const users = (recipients || []).filter(recipient => recipient.type === 'user') as UserRecipient[]
                const teams = (recipients || []).filter(recipient => recipient.type === 'team') as TeamRecipient[]
                const value = {
                  users: users.map(user => user.id || user.handle).sort(),
                  teams: teams.map(team => team.id).sort()
                }
                onChange(value)
              }}
            />
          )}
        />
        <Controller
          name="dashboardView"
          control={control}
          render={({ field: { name, onChange, value, onBlur, ref } }) => (
            <RadioboxGroup
              name={name}
              required
              label={t('dashboard:shareModal:dashboardViewLabel')}
              onChange={onChange}
              value={value ?? 'entire'}
              direction="row"
              hasError={!!errors.dashboardView}
              onBlur={onBlur}
              ref={ref}
              options={[
                { value: 'entire', label: t('dashboard:shareModal:dashboardViewOption') },
                { value: 'savedFilters', label: t('dashboard:shareModal:savedFilterOption') }
              ]}
            />
          )}
        />
        {dashboardView === 'savedFilters' && (
          <Controller
            name="filter"
            control={control}
            render={({ field: { onChange, value, ref } }) => {
              return (
                <Select
                  filterKeys={['label']}
                  data-testid="dashboard-schedule-filter-select"
                  hasError={!!errors.filter}
                  inlineError={errors.filter?.message}
                  onChange={onChange}
                  inputRef={ref}
                  label={t('dashboard:shareModal:savedFilterOption')}
                  placeholder={t('dashboard:shareModal:savedFilterOption')}
                  icon="filter"
                  value={value || ''}
                  loading={isFilterDataLoading}
                  options={
                    !!filterData
                      ? filterData.filters.map((filter: SavedView) => ({
                          label: filter.name,
                          value: filter.id.toString()
                        }))
                      : []
                  }
                />
              )
            }}
          />
        )}
        <Controller
          name="frequencyType"
          control={control}
          render={({ field: { name, onChange, value, onBlur, ref } }) => (
            <RadioboxGroup
              name={name}
              value={value ?? 'once'}
              onChange={onChange}
              label={t('dashboard:shareModal:frequencyLabel')}
              direction="column"
              hasError={!!errors.frequencyType}
              onBlur={onBlur}
              ref={ref}
              options={[
                { value: 'once', label: t('dashboard:shareModal:frequencyOptionOnce') },
                { value: 'every_15_min', label: t('dashboard:shareModal:frequencyOptionEvery15Min') },
                { value: 'every_30_min', label: t('dashboard:shareModal:frequencyOptionEvery30Min') },
                { value: 'hourly', label: t('dashboard:shareModal:frequencyOptionHourly') },
                { value: 'daily', label: t('dashboard:shareModal:frequencyOptionDaily') },
                { value: 'weekly', label: t('dashboard:shareModal:frequencyOptionWeekly') }
              ]}
              required
            />
          )}
        />
        {frequencyType === 'weekly' && (
          <Controller
            control={control}
            name="frequencyDays"
            render={({ field: { onChange, value }, formState: { errors } }) => (
              <WeekdayPicker
                required
                value={value || []}
                hasError={!!errors.frequencyDays}
                onChange={(event: any) => event && onChange(event.value)}
                label={t('dashboard:shareSchedulePanel:frequencyDayLabel')}
              />
            )}
          />
        )}
        {frequencyType === 'once' ? (
          <Controller
            control={control}
            name="scheduleTime"
            render={({ field: { onChange, value, ref, onBlur } }) => (
              <DateTimePicker
                required
                hasError={!!errors.scheduleTime}
                data-testid="runbook-dashboard-schedule-datepicker"
                value={value || null}
                onChange={val => {
                  if (!val) return onChange(val)

                  if (isPast(val)) return onChange(addMinutes(Date.now(), 1))
                  onChange(val)
                }}
                label={t('dashboard:shareSchedulePanel:timeLabel')}
                inputRef={ref}
                onBlur={onBlur}
                minDate={new Date()}
              />
            )}
          />
        ) : ['hourly', 'daily', 'weekly'].includes(frequencyType as string) ? (
          <Controller
            control={control}
            name="scheduleTime"
            render={({ field: { onChange, value, onBlur } }) => (
              <TimePicker
                required
                hasError={!!errors.scheduleTime}
                value={value || new Date()}
                onChange={val => {
                  if (!val) return onChange(val)
                  onChange(val)
                }}
                label={t('dashboard:shareSchedulePanel:timeLabel')}
                onBlur={onBlur}
                plain={false}
              />
            )}
          />
        ) : null}
        {frequencyType !== 'once' && (
          <>
            <Controller
              name="startsAt"
              control={control}
              render={({ field: { onChange, value, ref, onBlur } }) => (
                <DateTimePicker
                  required
                  value={value || null}
                  onChange={val => {
                    if (!val) return onChange(val)

                    if (isPast(val)) return onChange(addMinutes(Date.now(), 1))
                    onChange(val)
                  }}
                  hasError={!!errors.startsAt}
                  label={t('dashboard:shareSchedulePanel:startsAtLabel')}
                  data-testid="runbook-dashboard-schedule-datepicker-starts-at"
                  minDate={new Date()}
                  calendarPlacement="top-start"
                  inputRef={ref}
                  onBlur={onBlur}
                />
              )}
            />
            <Controller
              name="endsAt"
              control={control}
              render={({ field: { onChange, value, ref, onBlur } }) => (
                <DateTimePicker
                  required
                  value={value || null}
                  onChange={val => {
                    if (!val) return onChange(val)

                    if (isPast(val)) return onChange(addMinutes(Date.now(), 1))
                    onChange(val)
                  }}
                  hasError={!!errors.endsAt}
                  label={t('dashboard:shareSchedulePanel:endsAtLabel')}
                  minDate={new Date()}
                  calendarPlacement="top-start"
                  inputRef={ref}
                  onBlur={onBlur}
                />
              )}
            />
          </>
        )}
        <Controller
          control={control}
          name="timezone"
          render={({ field: { onChange, value } }) => (
            <TimezoneSelect
              required
              hasError={!!errors.timezone}
              label={t('dashboard:shareSchedulePanel:timezoneLabel')}
              onChange={onChange}
              value={value}
            />
          )}
        />
        <Controller
          name="message"
          control={control}
          render={({ field: { value, onChange } }) => {
            return (
              <TextEditor
                ref={textEditorRef}
                value={value}
                onChange={onChange}
                label={t('dashboard:shareSchedulePanel:messageLabel')}
                hasError={!!errors.message}
                inlineError={errors.message?.message?.toString()}
              />
            )
          }}
        />
      </Form>
    </EditPanel>
  )
}

const getDefaultValues = (setting: UserSetting, scheduleIndex: number) => {
  const schedule = setting.data.schedules[scheduleIndex]
  return {
    recipients: {
      users: schedule.user_recipients.sort(),
      teams: schedule.team_recipients.sort()
    },
    dashboardView: schedule.filter_name ? 'savedFilters' : 'entire',
    filter: schedule.filter_id ? schedule.filter_id.toString() : null,
    frequencyType: schedule?.frequency?.option || null,
    frequencyDays: schedule.day_options?.map(Number) ?? null,
    scheduleTime: schedule.schedule_time ? new Date(schedule.schedule_time) : null,
    startsAt: schedule.starts_at ? new Date(schedule.starts_at) : null,
    endsAt: schedule.ends_at ? new Date(schedule.ends_at) : null,
    timezone: setting.data.timezone,
    message: schedule.message || ''
  }
}

const buildScheduleEmailForPut = (
  form: RunbookDashboardShareEmailFormType,
  setting: UserSetting,
  scheduleIndex: number,
  filterData: { filters: SavedView[] } | null
): UpdateUserSettingParams => {
  const frequencyNameMap: {
    [key: string]: 'Every 15 minutes' | 'Every 30 minutes' | 'Hourly' | 'Once' | 'Daily' | 'Weekly'
  } = {
    every_15_min: 'Every 15 minutes',
    every_30_min: 'Every 30 minutes',
    hourly: 'Hourly',
    once: 'Once',
    daily: 'Daily',
    weekly: 'Weekly'
  }

  let selectedFilter

  if (form.dashboardView === 'savedFilters') {
    selectedFilter = filterData?.filters.filter(filter => form.filter && filter.id === parseInt(form.filter, 10))
  }

  const schedules = [...setting.data.schedules]

  schedules[scheduleIndex] = {
    name: form.name,
    ...(form.frequencyType === 'weekly' && {
      day_options: form.frequencyDays?.map(day => Number(day))
    }),
    message: form.message || '',
    schedule_time: form.scheduleTime ? form.scheduleTime.toISOString() : undefined,
    starts_at: form.startsAt ? form.startsAt.toISOString() : undefined,
    ends_at: form.endsAt ? form.endsAt.toISOString() : undefined,
    user_recipients: form.recipients?.users || [],
    team_recipients: form.recipients?.teams || [],
    ...(form.frequencyType && {
      frequency: {
        option: form.frequencyType,
        name: frequencyNameMap[form.frequencyType]
      }
    }),
    ...(selectedFilter && {
      filter_name: selectedFilter[0].name,
      filter_id: selectedFilter[0].id,
      filter_query_string: selectedFilter[0].query_string
    })
  }

  return {
    id: setting.id,
    resource_id: setting.resource_id,
    resource_type: 'Dashboard',
    type: 'EmailScheduleUserSetting',
    user_id: setting.user_id,
    data: {
      runbook_id: setting.data.runbook_id,
      account_id: setting.data.account_id,
      enabled: true,
      timezone: form.timezone === 'default' ? setting.data.timezone : form.timezone,
      schedules,
      template_type: ['off']
    }
  }
}
