import { useState } from 'react'
import { UseFormReturn } from 'react-hook-form'

import { Box, TabGroup, TabItem } from '@cutover/react-ui'
import { Nullable, Nullish } from '@cutover/utility-types'
import { CreatedUserListItemSelectedRole } from '@cutover/react-ui/src/list/list-item/people/user-list-item'
import { TeamList } from './team-list'
import { UserList } from './user-list'
import { SelectedTeam, SelectedUser, Stream } from '../types'
import { useLanguage } from 'main/services/hooks'

interface PeoplePanelTabsProps {
  runbookId: number
  runbookVersionId: number
  isCurrentVersion: boolean
  search: Nullish<string>
  clearSearch?: () => void
  selectedTab?: string
  onTabSelect: (id: string) => void
  selectedUser: SelectedUser | undefined
  onUserSelect: (item: SelectedUser) => void
  selectedTeam: SelectedTeam | undefined
  onTeamSelect: (item: SelectedTeam) => void
  methods: UseFormReturn<{ users: CreatedUserListItemSelectedRole[] }>
  streams?: Stream[]
  templateType?: string
}

export const PeoplePanelTabs = ({
  runbookId,
  runbookVersionId,
  isCurrentVersion,
  search,
  clearSearch = () => {},
  selectedTab,
  onTabSelect,
  selectedUser,
  onUserSelect,
  selectedTeam,
  onTeamSelect,
  methods,
  streams,
  templateType
}: PeoplePanelTabsProps) => {
  const { t } = useLanguage('runbook', { keyPrefix: 'peoplePanel' })

  const [usersCount, setUsersCount] = useState<Nullable<number>>(null)
  const [teamsCount, setTeamsCount] = useState<Nullable<number>>(null)

  const usersTabTitle = usersCount !== null ? t('users.titleWithCount', { count: usersCount }) : t('users.title')
  const teamsTabTitle = teamsCount !== null ? t('teams.titleWithCount', { count: teamsCount }) : t('teams.title')

  const tabs: TabItem[] = [
    {
      label: usersTabTitle,
      onClick: () => onTabSelect('users'),
      active: selectedTab === 'users',
      'aria-controls': 'tabpanel-users',
      id: 'tab-users'
    },
    {
      label: teamsTabTitle,
      onClick: () => onTabSelect('teams'),
      active: selectedTab === 'teams',
      'aria-controls': 'tabpanel-teams',
      id: 'tab-teams'
    }
  ]

  return (
    <Box fill pad={{ top: '8px' }}>
      <TabGroup items={tabs} />
      {selectedTab === 'users' && (
        <Box
          id="tabpanel-users"
          role="tabpanel"
          aria-hidden={selectedTab !== 'users'}
          aria-labelledby="tab-users"
          pad={{ top: '8px' }}
          css={'position: relative;'}
          fill
        >
          <UserList
            runbookId={runbookId}
            runbookVersionId={runbookVersionId}
            isCurrentVersion={isCurrentVersion}
            search={search}
            clearSearch={clearSearch}
            setUsersCount={setUsersCount}
            setTeamsCount={setTeamsCount}
            selectedUser={selectedUser}
            onUserSelect={onUserSelect}
            methods={methods}
            streams={streams}
            templateType={templateType}
          />
        </Box>
      )}
      {selectedTab === 'teams' && (
        <Box
          id="tabpanel-teams"
          role="tabpanel"
          aria-hidden={selectedTab !== 'teams'}
          aria-labelledby="tab-teams"
          pad={{ top: '8px' }}
          css={'position: relative;'}
          fill
        >
          <TeamList
            runbookId={runbookId}
            runbookVersionId={runbookVersionId}
            search={search}
            clearSearch={clearSearch}
            setUsersCount={setUsersCount}
            setTeamsCount={setTeamsCount}
            selectedTeam={selectedTeam}
            onTeamSelect={onTeamSelect}
            templateType={templateType}
          />
        </Box>
      )}
    </Box>
  )
}
