import { Pie } from '@nivo/pie'

import { LegacyBox as Box } from '../../layout'
import { colors, px2rem } from '../../utils'
import { nivoTheme } from '../shared/nivo-theme'

export type DonutChartDatum = {
  id: string | number
  label: string | number
  value: number
  color: string
  name: string | number
}

export type DonutProps = {
  data: DonutChartDatum[]
  total?: boolean
  centerValue?: string
  withKey?: boolean
  height?: number
  segmentGap?: number
  fontFamily?: string
  'data-testid'?: string
}

export const DonutChart = ({
  data,
  centerValue,
  height = 300,
  segmentGap = 1,
  fontFamily,
  'data-testid': dataTestId
}: DonutProps) => {
  return (
    <Box
      css={`
        height: ${px2rem(height)};
        width: ${px2rem(height + 150)};
        margin: auto;
        svg {
          overflow: visible;
          margin-left: -${px2rem(75)};
        }
      `}
      data-testid={dataTestId}
    >
      <Pie
        data={data}
        height={height}
        width={height + 150}
        theme={{
          fontFamily: `${fontFamily || nivoTheme?.fontFamily}`,
          textColor: colors.text,
          legends: {
            text: {
              fontSize: 16
            }
          }
        }}
        colors={({ id }) => data.find(d => d.id === id)?.color || 'blue'}
        innerRadius={0.85}
        arcLinkLabelsColor={{ from: 'color' }}
        arcLabelsTextColor={{ from: 'color', modifiers: [['darker', 2]] }}
        borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
        layers={['arcs', 'legends', centerValue ? createCenterValue(centerValue) : () => null]}
        padAngle={segmentGap}
        legends={[
          {
            anchor: 'right',
            direction: 'column',
            justify: false,
            itemsSpacing: 7,
            itemWidth: 62,
            itemHeight: 18,
            itemTextColor: '#999',
            itemDirection: 'left-to-right',
            itemOpacity: 1,
            symbolSize: 18,
            symbolShape: 'square'
          }
        ]}
      />
    </Box>
  )
}

// NOTE: Nivo does not type the props for layers; we could do it manually at some point.

/**
 * A higher order function to create the layer function in order to capture the string we want to display as
 * the label
 */
export const createCenterValue: (value: string) => (props: any) => JSX.Element = (value: string) => {
  return (props: any) => {
    return (
      <text
        x={props.centerX}
        y={props.centerY}
        textAnchor="middle"
        dominantBaseline="central"
        style={{
          fontFamily: `${nivoTheme?.fontFamily}`,
          fontSize: '24px',
          fill: '#97a4aa',
          fontWeight: 400
        }}
      >
        {value}
      </text>
    )
  }
}
