import { useMemo } from 'react'
import { groupBy, sortBy } from 'lodash'

import { runbookCompletion, tasksByStage } from '../../runbook/utils'
import { DashboardStreamData, StreamSummaryData } from '../../types'
import { TaskListTask } from 'main/services/queries/types'

export const getStreamSummaryData = (stream: DashboardStreamData, tasks: TaskListTask[]): StreamSummaryData => {
  const tasksByStream = groupBy(tasks, task => task.stream_id)
  const currentStreamTasks = tasksByStream[stream.id] || []

  if (stream.children?.length > 0) {
    stream.children.forEach(substream => {
      const substreamTasks = tasksByStream[substream.id] || []
      currentStreamTasks.push(...substreamTasks)
    })
  }
  const sortedTasks = tasksByStage(currentStreamTasks)
  const percentComplete = runbookCompletion(sortedTasks)
  const tasksByStart = sortBy(currentStreamTasks, task => task.start_display)
  const tasksByEnd = sortBy(currentStreamTasks, task => task.end_display).reverse()
  const isInProgress = (percentComplete > 0 && percentComplete < 100) || sortedTasks.inProgress.length > 0

  const startLatestPlanned =
    tasksByStream[stream.id]?.reduce(
      (val, task) =>
        val && task.start_latest_planned && (val < task?.start_latest_planned ? val : task?.start_latest_planned),
      tasksByStream[stream.id][0].start_latest_planned
    ) ?? undefined

  const endLatestPlanned = tasksByStream[stream.id]?.reduce((val, task) => {
    const taskEndLatestPlanned = val && task.start_latest_planned && task.start_latest_planned + task.duration
    return taskEndLatestPlanned && taskEndLatestPlanned > val ? taskEndLatestPlanned : val
  }, ((tasksByStream[stream.id][0].start_latest_planned && tasksByStream[stream.id][0].start_latest_planned) || 0) + tasksByStream[stream.id][0].duration)

  const children = sortStreams(
    (stream.children && stream.children.map(substream => getStreamSummaryData(substream, tasks))) || []
  )

  const end = tasksByEnd.filter(task => task.completion_type !== 'complete_skipped')[0]?.end_display
  const stage = percentComplete === 100 ? 'complete' : isInProgress ? 'in-progress' : 'default'
  const start = tasksByStart[0]?.start_display

  const tasksCount = currentStreamTasks.length
  const totalCompletedTasks = currentStreamTasks.filter(task => task.stage === 'complete').length
  const totalInProgressTasks = currentStreamTasks.filter(task => task.stage === 'in-progress').length

  // logic to aggregate parent and children for start_display, end_display, total_completed_tasks, total_in_progress_tasks
  if (children.length) {
    const {
      childrenWithTasksEarliestStartDisplay,
      childrenWithTasksLatestEndDisplay,
      childrenWithTasksTotalCompletedTasks,
      childrenWithTasksTotalInProgressTasks,
      childrenWithTasksStartLatestPlanned,
      childrenWithTasksEndLatestPlanned
    } = processChildrenValues(children)

    const totalChildrenTasksLength = childrenWithTasksTotalCompletedTasks + childrenWithTasksTotalInProgressTasks

    return {
      ...stream,
      children,
      tasks: {
        all: currentStreamTasks,
        byStage: sortedTasks
      },
      tasksCount,
      completion: tasksCount && tasksCount !== 0 ? (100 * totalCompletedTasks) / tasksCount : 0,
      stage,
      start: getStartOrEndValue(totalChildrenTasksLength, start, childrenWithTasksEarliestStartDisplay, Math.min),
      end: getStartOrEndValue(totalChildrenTasksLength, end, childrenWithTasksLatestEndDisplay, Math.max),
      startLatestPlanned: getLatestPlannedValue(startLatestPlanned, childrenWithTasksStartLatestPlanned, Math.min),
      endLatestPlanned: getLatestPlannedValue(endLatestPlanned, childrenWithTasksEndLatestPlanned, Math.max),
      totalCompletedTasks: totalCompletedTasks,
      totalInProgressTasks: totalInProgressTasks
    }
  }

  return {
    ...stream,
    children,
    tasks: {
      all: currentStreamTasks,
      byStage: sortedTasks
    },
    tasksCount,
    completion: percentComplete,
    stage,
    start,
    end,
    startLatestPlanned,
    endLatestPlanned,
    totalCompletedTasks,
    totalInProgressTasks
  }
}

export const useStreamsSummary = (streams: DashboardStreamData[], tasks: TaskListTask[]): StreamSummaryData[] => {
  return useMemo(() => streams.map(stream => getStreamSummaryData(stream, tasks)), [streams, tasks])
}

const processChildrenValues = (children: any[]) => {
  // when children are present, we need to get the following reduced data:
  // lowest start_display
  // highest end_display
  // aggregated total_completed_tasks
  // aggregated total_in_progress_tasks

  const filteredChildren = children.filter(child => child.tasksCount > 0)
  if (filteredChildren.length) {
    // start_display
    const childrenWithTasksEarliestStartDisplay = filteredChildren.reduce((earliestChildrenStartDisplay, child) => {
      if (child.start < earliestChildrenStartDisplay) {
        earliestChildrenStartDisplay = child.start
      }
      return earliestChildrenStartDisplay
    }, Infinity)

    // end_display
    const childrenWithTasksLatestEndDisplay = filteredChildren.reduce((latestChildrenEndDisplay, child) => {
      if (child.end > latestChildrenEndDisplay) {
        latestChildrenEndDisplay = child.end
      }
      return latestChildrenEndDisplay
    }, -Infinity)

    // complete task count
    const childrenWithTasksTotalCompletedTasks = filteredChildren.reduce(
      (totalCompletedTasks, child) => child.totalCompletedTasks + totalCompletedTasks,
      0
    )
    // in-progress task count
    const childrenWithTasksTotalInProgressTasks = filteredChildren.reduce(
      (totalInProgressTasks, child) => child.totalInProgressTasks + totalInProgressTasks,
      0
    )

    const childrenWithTasksStartLatestPlanned = filteredChildren.reduce((startLatestPlanned, child) => {
      if (child.startLatestPlanned) {
        return child.startLatestPlanned < startLatestPlanned ? child.startLatestPlanned : startLatestPlanned
      }
      return startLatestPlanned
    }, Infinity)

    const childrenWithTasksEndLatestPlanned = filteredChildren.reduce((endLatestPlanned, child) => {
      if (child.endLatestPlanned) {
        return child.endLatestPlanned > endLatestPlanned ? child.endLatestPlanned : endLatestPlanned
      }
      return endLatestPlanned
    }, -Infinity)

    return {
      childrenWithTasksEarliestStartDisplay,
      childrenWithTasksLatestEndDisplay,
      childrenWithTasksTotalCompletedTasks,
      childrenWithTasksTotalInProgressTasks,
      childrenWithTasksStartLatestPlanned,
      childrenWithTasksEndLatestPlanned
    }
  }

  return {
    childrenWithTasksEarliestStartDisplay: undefined,
    childrenWithTasksLatestEndDisplay: undefined,
    childrenWithTasksTotalCompletedTasks: 0,
    childrenWithTasksTotalInProgressTasks: 0,
    childrenWithTasksStartLatestPlanned: undefined,
    childrenWithTasksEndLatestPlanned: undefined
  }
}

const getStartOrEndValue = (
  totalChildrenTasksLength: number,
  startOrEnd: number,
  childrenLatestStartOrEndDisplay: number,
  comparison: (value1: number, value2: number) => number
) => {
  if (!totalChildrenTasksLength) {
    if (startOrEnd) {
      return startOrEnd
    } else {
      return childrenLatestStartOrEndDisplay
    }
  }
  return startOrEnd ? comparison(childrenLatestStartOrEndDisplay, startOrEnd) : childrenLatestStartOrEndDisplay
}

const getLatestPlannedValue = (
  parentLatestPlannedValue: number | undefined,
  childrenLatestPlannedValue: number | undefined,
  comparison: (value1: number, value2: number) => number
) => {
  if (parentLatestPlannedValue && childrenLatestPlannedValue) {
    return comparison(parentLatestPlannedValue, childrenLatestPlannedValue)
  }
  return parentLatestPlannedValue || childrenLatestPlannedValue
}

export const sortStreams = (streams: any[]) => {
  const sortedStreams = streams.sort(function (a, b) {
    const x = a.name.toLowerCase()
    const y = b.name.toLowerCase()
    if (x < y) {
      return -1
    }
    if (x > y) {
      return 1
    }
    return 0
  })
  return sortedStreams
}
