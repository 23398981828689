import { ThemeType as GrommetThemeType } from 'grommet/themes'
import { SetRequired } from 'type-fest'

type GrommetTheme = SetRequired<GrommetThemeType, 'table'>
type GrommetTable = Partial<GrommetTheme['table']>

export const table: GrommetTable = {
  row: {
    hover: {
      background: 'bg-1'
    }
  }
}
