import { useEffect } from 'react'
import { eventManager } from 'event-manager'
import { useQueryClient } from 'react-query'

import { QueryKeys } from 'main/services/queries/query-keys'

export const useConnectRefreshState = (enabled = false) => {
  const queryClient = useQueryClient()

  useEffect(() => {
    const refreshWorkspaces = () => {
      queryClient.invalidateQueries([QueryKeys.Accounts])
    }

    if (enabled) {
      eventManager.on('trigger-react-refetch-workspaces', refreshWorkspaces)
    }

    return () => {
      eventManager.off('trigger-react-refetch-workspaces', refreshWorkspaces)
    }
  }, [])
}
