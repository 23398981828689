import { RunbookDashboardSchedulePanel } from 'main/components/dashboards/runbook-dashboard-schedule/runbook-dashboard-schedule-panel'
import { useRightPanelTypeState } from 'main/components/layout/right-panel'
import { ActiveAccountModel, ActiveRunbookModel, ActiveRunbookVersionModel, CurrentUserModel } from 'main/data-access'

export const RunbookDashboardSchedulePanelWrapper = () => {
  const [{ dashboardId }, { closeRightPanel }] = useRightPanelTypeState('runbook-dashboard-schedule')
  const accountId = ActiveAccountModel.useId()
  const currentUserId = CurrentUserModel.useId()
  const runbookId = ActiveRunbookModel.useId()
  const runbookVersionId = ActiveRunbookVersionModel.useId()
  const { timezone } = ActiveRunbookModel.useGet()
  const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

  return (
    <>
      {!!accountId && !!dashboardId && !!currentUserId && (
        <RunbookDashboardSchedulePanel
          accountId={accountId.toString()}
          dashboardId={dashboardId}
          currentUserId={currentUserId}
          runbookId={runbookId}
          runbookVersionId={runbookVersionId}
          timezone={timezone ?? localTimezone}
          isFiltered={false}
          onClose={closeRightPanel}
        />
      )}
    </>
  )
}
