// @ts-nocheck
import { useState } from 'react'
import { RoleTypeEditSection } from './RoleTypeEdit/RoleTypeEditSection'
import { RoleTypeListSection } from './RoleTypeList/RoleTypeListSection'
import { RoleTypesPageFilterPanelSection } from './RoleTypesPageFilterPanel/RoleTypesPageFilterPanelSection'
import RoleTypesPageHeadingSection from './RoleTypesPageHeading/RoleTypesPageHeadingSection'
import { RoleTypesPageModals } from './RoleTypesPageModals'
import { AccessSectionNav } from 'app/Access/AccessSectionNav'
import { AddButton } from 'Components/Atoms'
import {
  HeaderPanelWrapper,
  SectionNavPanelWrapper,
  PageWrapper,
  PageWrapperInner,
  CenterMainWrapper,
  FilterPanelWrapper,
  MainPanelWrapper,
  EditPanelWrapper
} from 'Components/Templates'
import { observer } from 'mobx-react-lite'
import { RoleTypesPagePresenter } from './RoleTypesPagePresenter'
import { fluidPage } from 'Shared/HooksAndPresenters/FluidPage'
import { withInjection } from 'Helpers/InjectionHelpers'

interface IRolesPageComponentProps {
  presenter: RoleTypesPagePresenter
}

const RoleTypesPageComp = observer<IRolesPageComponentProps>(props => {
  const {
    routeUpdater,
    routingState,
    editOpen,
    closePanel,
    editLoading,
    editPanelClosedCallback,
    roleTypeEditPresenter,
    roleTypeListPresenter,
    roleTypesPageFilterPanelPresenter,
    canCreateRoleType,
    loading,
    checkEditPanelIsDirty,
    angularJSSettingsPanelOpenState,
    language
  } = props.presenter

  const { archiveModalPresenter, archiveConfirmName, cancelArchiveCallback, continueArchiveCallback, responseErrors } =
    roleTypeEditPresenter

  const [rolesAddModalOpenState, setRolesAddModalOpenState] = useState(false)

  const { showAddButton, filterPanelOpen, setFilterPanelOpenState } = fluidPage(
    editOpen,
    closePanel,
    checkEditPanelIsDirty,
    angularJSSettingsPanelOpenState
  )

  const roleTypeAddOpenHandler = () => {
    if (checkEditPanelIsDirty()) return
    setRolesAddModalOpenState(true)
  }

  return (
    <PageWrapper>
      <PageWrapperInner>
        <HeaderPanelWrapper>
          <RoleTypesPageHeadingSection filterOpen={filterPanelOpen} setFilterOpen={setFilterPanelOpenState} />
        </HeaderPanelWrapper>
        <CenterMainWrapper>
          <FilterPanelWrapper filterPanelOpen={filterPanelOpen} loading={loading}>
            {filterPanelOpen && <RoleTypesPageFilterPanelSection presenter={roleTypesPageFilterPanelPresenter} />}
          </FilterPanelWrapper>
          <MainPanelWrapper filterPanelOpen={filterPanelOpen} loading={loading}>
            <RoleTypeListSection presenter={roleTypeListPresenter} />
            {canCreateRoleType && showAddButton && (
              <AddButton
                action={() => {
                  roleTypeAddOpenHandler()
                }}
                language={language}
              />
            )}

            <RoleTypesPageModals
              rolesAddModalOpenState={rolesAddModalOpenState}
              setRolesAddModalOpenState={setRolesAddModalOpenState}
              archiveModalPresenter={archiveModalPresenter}
              archiveConfirmName={archiveConfirmName}
              cancelArchiveCallback={cancelArchiveCallback}
              continueArchiveCallback={continueArchiveCallback}
              responseErrors={responseErrors}
            />
          </MainPanelWrapper>
        </CenterMainWrapper>
      </PageWrapperInner>
      <EditPanelWrapper
        isOpen={editOpen}
        loading={editLoading}
        withSectionNav={true}
        panelCloseCallback={editPanelClosedCallback}
      >
        <RoleTypeEditSection presenter={roleTypeEditPresenter} />
      </EditPanelWrapper>
    </PageWrapper>
  )
})

export const RoleTypesPage = withInjection({
  presenter: RoleTypesPagePresenter
})(RoleTypesPageComp)
