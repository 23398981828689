// @ts-nocheck
import { observable } from 'mobx'
import { ISortParams, TablePresenter, ITableColumnTypes } from 'Components/Organisms'

export abstract class GenericListPresenter {
  public abstract viewModel: any[]

  public abstract sortParams: ISortParams

  public abstract columns: ITableColumnTypes[]

  public abstract tableName: string

  public abstract totalCount: number

  public abstract loadRowCallback?: (rowId: number) => void

  public abstract loadSort: (sortParams: ISortParams) => void

  public abstract loadMore?: (startIndex: number, stopIndex: number) => void

  public abstract selectedId?: number

  public abstract clearAllFilters?: () => void

  public abstract loading: boolean

  public abstract reloadList: boolean

  // context string is used for displaying the resource type in the noResourceFoundComponent
  public abstract context: string

  public abstract filtersCount: number

  @observable
  public abstract tablePresenter: TablePresenter
}
