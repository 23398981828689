import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const BrandTransformation = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 100 101" a11yTitle="BrandTransformation" {...props}>
    <g clipPath="url(#a)" fill="#fff"><path fillRule="evenodd" clipRule="evenodd" d="M64.667 71.333C84.18 71.333 100 55.513 100 36 100 16.486 84.18.667 64.667.667 45.153.667 29.333 16.486 29.333 36c0 19.514 15.82 35.333 35.334 35.333ZM29.333 36c0 19.514 15.82 35.333 35.334 35.333V35.89H29.334V36Z" /><path opacity=".5" d="M29.333 36H0v64.666h64.667V71.333c-19.514 0-35.334-15.82-35.334-35.333Z" /></g>
  </StyledIcon>
))

BrandTransformation.displayName = 'BrandTransformation'

export { BrandTransformation }
