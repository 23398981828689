// @ts-nocheck

import styled from 'styled-components'
import { px2rem } from 'app/Styles/Variables'
import { observer } from 'mobx-react-lite'

interface IStyledImageProps {
  height: number
  borderRadius: string
}

const StyledImage = styled.img`
  display: inline-block;
  height: ${(props: IStyledImageProps) => props.height && px2rem(props.height)};
  border-radius: ${(props: IStyledImageProps) => props.borderRadius && props.borderRadius};
`

interface IImageProps {
  src: string
  alt?: string
  height?: number
  borderRadius?: string
  extraStyles?: CSSProperties
}

export const Image = observer<IImageProps>(props => {
  const { src, alt, height, borderRadius, extraStyles } = props

  return <StyledImage src={src} alt={alt} height={height} borderRadius={borderRadius} style={extraStyles} />
})
