// @ts-nocheck
import { observable, computed } from 'mobx'
import { container } from 'app/Config/IOC'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'
import { Types } from 'Gateways/Service/Types'
import { UserRepository } from 'app/Repositories/User/UserRepository'
import { ButtonPresenter, ToasterService } from 'Components/Molecules'

export class UserEditUnlockPresenter {
  private userRepository: UserRepository = container.get(UserRepository)

  private toasterGateway: ToasterService = container.get(ToasterService)

  private language: ILanguageService = container.get(Types.ILanguageService)

  @observable
  public buttonPresenter = new ButtonPresenter(this.language.get('users:edit:unlockUser:button').toUpperCase(), () => {
    this.processUnlock()
  })
    .withDisplayStyle('primary-grey')
    .withTextTransform('Uppercase')

  public processUnlock = async () => {
    await this.userRepository.unlockUser()
    const title = this.language.get('common:notification:successTitle')
    const notification = this.language.get('users:edit:unlockUser:notification:message')
    this.toasterGateway.pop(title, notification, 'success')
  }

  @computed
  public get showUnlock(): boolean {
    if (this.userRepository.userForEdit && this.userRepository.userForEdit.provider === 'email') {
      return !!this.userRepository.userForEdit.lockedAt
    } else {
      return false
    }
  }
}
