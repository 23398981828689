// @ts-nocheck
import { observable, action, computed } from 'mobx'
import { CheckboxPresenter, ColorPickerPresenter, IColor, TextInputPresenter } from 'Components/Molecules'
import { FieldOptionsPresenter, defaultOptionColor } from '.'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'

export class OptionPresenter {
  public language: ILanguageService

  @observable
  public guid: string

  @observable
  public fieldOptionsPresenter: FieldOptionsPresenter

  @observable
  public restorable: boolean = false

  @observable
  public excludeFromConflicts: boolean = false

  @observable
  protected dirtyCallback: () => void

  @observable
  public disabled: boolean = false

  @observable
  public color: IColor = defaultOptionColor

  private stringChangeMiddlware = (newValue: string): any => {
    this.fieldOptionsPresenter.updateOptionValue(this.guid, newValue)
    return newValue
  }

  @action
  private colorChangeMiddleware = (newValue: IColor): any => {
    this.color = newValue
    this.fieldOptionsPresenter.setColor(this.guid, newValue)

    if (this.dirtyCallback) {
      this.dirtyCallback()
    }

    return newValue
  }

  protected checkboxUpdateMiddleWare = (newValue: boolean): any => {
    this.fieldOptionsPresenter.setExcludeFromConflicts(this.guid, newValue)

    if (this.dirtyCallback) {
      this.dirtyCallback()
    }

    return newValue
  }

  @action
  public withGuid = (guid: string) => {
    this.guid = guid
    return this
  }

  @action
  public withFieldOptionsPresenter = (presenter: FieldOptionsPresenter) => {
    this.fieldOptionsPresenter = presenter
    return this
  }

  @action
  public withExcludeFromConflicts = (excludeFromConflicts: boolean) => {
    this.excludeFromConflicts = excludeFromConflicts
    this.checkboxPresenter.value = excludeFromConflicts
    return this
  }

  @action
  public withDisabled = (disabled: boolean) => {
    this.disabled = disabled
    this.textPresenter.disabled = disabled
    return this
  }

  @action
  public withDirtyCallback = (dirtyCallback: () => void) => {
    this.dirtyCallback = dirtyCallback
    return this
  }

  @computed
  public get archived(): boolean {
    const option = this.fieldOptionsPresenter.findOption(this.guid)
    if (option) return option.archived
    return false
  }

  constructor(language: ILanguageService, color?: IColor) {
    this.language = language
    this.checkboxPresenter = new CheckboxPresenter(
      this.language.get('components:organisms:fieldOptions:excludeFromConflictLabel')
    ).withMiddleware(this.checkboxUpdateMiddleWare)
    this.colorPickerPresenter = new ColorPickerPresenter(language, color)
      .withMiddleware(this.colorChangeMiddleware)
      .withLabel(this.language.get('components:organisms:fieldOptions:selectColorLabel'))
  }

  @action
  public withRestorable = (restorable: boolean) => {
    this.restorable = restorable
    return this
  }

  @action
  public withColor = (color: IColor) => {
    this.color = color
    return this
  }

  public textPresenter = new TextInputPresenter().withMiddleware(this.stringChangeMiddlware)

  public colorPickerPresenter: ColorPickerPresenter

  @observable
  public checkboxPresenter: CheckboxPresenter
}
