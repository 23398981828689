import { Simplify } from 'type-fest'

import { Run, RunMode } from 'main/services/queries/types'
import {
  useActiveRunId,
  useActiveRunIdCallback,
  useCanAction,
  useGetRun,
  useGetRunCallback,
  useGetRunLoadable,
  useGetRunLoadableCallback,
  useMode,
  useModeCallback,
  useOnActionRun
} from '../hooks'
import {
  ActiveModelActionsType,
  ActiveModelInterfaceBase,
  ActiveModelInterfaceWithActions as WithActions
} from './types'
import { RunsPermissionsResponse } from 'main/services/queries/use-runbook-versions'

// There isn't always an active run, so this model's getters may return undefined
type RunModelData = Run | undefined
type RunModelActions = ActiveModelActionsType<'Run', 'create' | 'pause' | 'resume'>

export type ActiveRunModelType = Simplify<
  ActiveModelInterfaceBase<RunModelData, RunsPermissionsResponse> &
    Omit<WithActions<Run, 'Run', RunModelActions>, 'usePermission' | 'usePermissionCallback'> & {
      useMode: () => RunMode | undefined
      useModeCallback: () => () => Promise<RunMode | undefined>
    }
>

export const ActiveRunModel: ActiveRunModelType = {
  useGet: useGetRun,
  useGetCallback: useGetRunCallback,

  useGetLoadable: useGetRunLoadable,
  useGetLoadableCallback: useGetRunLoadableCallback,

  useCan: useCanAction,

  useOnAction: useOnActionRun,

  useId: useActiveRunId,
  useIdCallback: useActiveRunIdCallback,

  useMode,
  useModeCallback
}
