// @ts-nocheck
import { observable, action } from 'mobx'
import { unescape } from 'lodash'
import { injectable } from 'inversify'
import { IBaseResponse } from 'Gateways/Service/IBaseResponse'
import { ITaskTypeDTO } from 'Shared/Entities/Types/TaskType/ITaskTypeDTO'
import { IEventTypeDTO } from 'app/Shared/Entities/Types/IEventTypeDTO'
import { GenericListRepository } from 'app/Repositories/GenericListRepository'
import { IRunbookTypeDTO } from 'Shared/Entities/Types/IRunbookTypeDTO'
import { IIntegrationActionItemDTO } from 'Shared/Entities/Types/IIntegrationActionItemDTO'
import { IDataSourceDTO } from 'Shared/Entities/Types/IDataSourceDTO'

export interface ICustomFieldsDTO {
  id: number
  name: string
  default: boolean
  archived: boolean
  apply_to: {
    name: string
    slug: CustomFieldApplyToSlug
  }
  field_type: {
    name: string
    slug: CustomFieldDisplayType
  }
  account_name: string
  used: boolean
  account_id: number
  required: boolean
  display_list: boolean
  display_search: boolean
  display_table: boolean
  field_options: IFieldOptionDTO[]
  constraint: string
  allow_field_creation: boolean
  is_primary: boolean
  key_subfield_id: number
  parent_field_id: number
  subfield_ids: number[]
  display_name?: string
  data_source_id?: number
}

export type CustomFieldApplyToSlug =
  | 'event_add_edit'
  | 'event_edit'
  | 'pir_summary'
  | 'pir_value_select'
  | 'project_add_edit'
  | 'project_edit'
  | 'runbook_add_edit'
  | 'runbook_edit'
  | 'runbook_end'
  | 'runbook_page'
  | 'task_edit'
  | 'task_end'
  | 'task_start'

export type CustomFieldDisplayType =
  | 'checkboxes'
  | 'radioboxes'
  | 'select_menu'
  | 'text'
  | 'textarea'
  | 'user_select'
  | 'endpoint'
  | 'searchable'

export interface ICustomFieldPM {
  id: number
  account: string
  name: string
  default: boolean
  archived: boolean
  type: string
  typeId: CustomFieldDisplayType
  displaysOn: string
  displaysOnSlug: string
  used: boolean
  accountId: number
  required: boolean
  displayList: boolean
  displaySearch: boolean
  displayTable: boolean
  fieldOptions: IFieldOptionPM[]
  constraint?: any
  allowFieldCreation?: boolean
  isPrimary: boolean
  keySubfieldId: number
  parentFieldId: number
  subfieldIds: number[]
  displayName?: string
  dataSourceId?: number
}

interface ITaskTypesPM {
  id: number
  name: string
  autoStart: boolean
  autoFinish: boolean
  global: boolean
  accountId: number
  archived: boolean
}

interface IEventTypesPM {
  id: number
  name: string
}

interface IRunbookTypesPM {
  id: number
  name: string
  description: string
  accountId?: number
  global?: boolean
  disabled: boolean
}

interface IFieldOptionDTO {
  archived?: boolean
  color?: string
  conflict_exclude?: boolean
  custom_field_id?: number
  id?: number
  name: string
  order: number
  group_id: number[]
}
interface IFieldOptionPM {
  id?: number
  name: string
  color: string
  conflictExclude?: boolean
  order?: number
  archived?: boolean
  groupId: number[]
}

interface IIntegrationActionItemPM {
  id: number
  name: string
  on: string
}

@injectable()
export class CustomFieldsRepository extends GenericListRepository {
  protected url = 'custom_fields'

  @observable
  public customFields: ICustomFieldPM[]

  @observable
  public taskTypes: ITaskTypesPM[]

  @observable
  public eventTypes: IEventTypesPM[]

  @observable
  public runbookTypes: IRunbookTypesPM[]

  @observable
  public integrationActionItems: IIntegrationActionItemPM[]

  @observable
  public dataSources: IDataSourceDTO[]

  constructor() {
    super('meta')
  }

  @action
  public postLoad = (rawData: IBaseResponse) => {
    this.listTotal = rawData.body.meta.total_results
    this.filteredListTotal = rawData.body.meta.total_filtered_count

    if (!this.taskTypes) {
      this.processTaskTypes(rawData.body.meta.task_types)
    }

    if (!this.eventTypes) {
      this.processEventTypes(rawData.body.meta.event_types)
    }

    if (!this.runbookTypes) {
      this.processRunbookTypes(rawData.body.meta.runbook_types)
    }

    if (!this.integrationActionItems) {
      this.processIntegrationActionItems(rawData.body.meta.integration_action_items)
    }

    if (!this.dataSources) {
      this.processDataSources(rawData.body.meta.data_sources)
    }

    if (this.offSet === 0) {
      this.customFields = this.getCustomFieldsFromAPIData(rawData)
    } else {
      this.customFields = this.customFields.concat(this.getCustomFieldsFromAPIData(rawData))
    }
  }

  // expanded custom fields repo capabilities for runbooks filter
  @action
  public loadAllWithFieldOptions = async () => {
    this.loading = true
    const urlString = `${this.url}?include=field_options`
    const rawData = await this.serviceGateway.get(urlString)

    if (rawData.success) {
      this.processAllCustomFields(rawData)
    }
    this.loading = false
  }

  @action
  public processAllCustomFields = (rawData: IBaseResponse) => {
    this.customFields = this.getAllCustomFieldsFromAPIData(rawData)
  }

  private getAllCustomFieldsFromAPIData = (rawData: IBaseResponse) => {
    return rawData.body.custom_fields.map((cfTypeDto: ICustomFieldsDTO) => this.mapCustomFieldFromAPIData(cfTypeDto))
  }

  private getFieldOptionsFromDTO = (fieldOptions: IFieldOptionDTO[]): IFieldOptionPM[] => {
    if (!fieldOptions) return []

    return fieldOptions.map(fieldOption => ({
      id: fieldOption.id,
      name: fieldOption.name,
      color: fieldOption.color,
      conflictExclude: fieldOption.conflict_exclude,
      archived: fieldOption.archived,
      order: fieldOption.order,
      groupId: fieldOption.group_id
    }))
  }

  @action
  public resetTaskTypes = () => {
    this.taskTypes = null
  }

  @action
  private processTaskTypes = (taskTypesDto: ITaskTypeDTO[]) => {
    taskTypesDto.sort((taskTypeA, taskTypeB) => taskTypeA.name.localeCompare(taskTypeB.name))

    const activeTaskTypes = taskTypesDto.filter(taskType => !taskType.archived)
    this.taskTypes = activeTaskTypes.map((taskTypeDTO: ITaskTypeDTO): ITaskTypesPM => {
      return {
        id: taskTypeDTO.id,
        name: unescape(taskTypeDTO.name),
        autoStart: taskTypeDTO.auto_start,
        autoFinish: taskTypeDTO.auto_finish,
        global: taskTypeDTO.global,
        accountId: taskTypeDTO.account_id,
        archived: taskTypeDTO.archived
      }
    })
  }

  @action
  private processEventTypes = (eventTypesDto: IEventTypeDTO[]) => {
    eventTypesDto.sort((taskTypeA, taskTypeB) => taskTypeA.name.localeCompare(taskTypeB.name))

    this.eventTypes = eventTypesDto.map((eventTypeDTO: IEventTypeDTO): IEventTypesPM => {
      return {
        id: eventTypeDTO.id,
        name: unescape(eventTypeDTO.name)
      }
    })
  }

  @action
  public resetRunbookTypes = () => {
    this.runbookTypes = null
  }

  @action
  private processRunbookTypes = (runbookTypesDto: IRunbookTypeDTO[]) => {
    runbookTypesDto.sort((taskTypeA, taskTypeB) => taskTypeA.name.localeCompare(taskTypeB.name))
    this.runbookTypes = runbookTypesDto.map((runbookTypeDTO: IRunbookTypeDTO): IRunbookTypesPM => {
      return {
        id: runbookTypeDTO.id,
        name: unescape(runbookTypeDTO.name),
        description: unescape(runbookTypeDTO.description),
        accountId: runbookTypeDTO.account_id,
        global: runbookTypeDTO.global,
        disabled: runbookTypeDTO.disabled
      }
    })
  }

  @action
  private processIntegrationActionItems = (integrationActionItemsDto: IIntegrationActionItemDTO[]) => {
    this.integrationActionItems = integrationActionItemsDto
  }

  @action
  private processDataSources = (dataSourcesDto: IDataSourceDTO[]) => {
    this.dataSources = dataSourcesDto
  }

  private getCustomFieldsFromAPIData = (rawData: IBaseResponse) => {
    return rawData.body.custom_fields.map((cfTypeDto: ICustomFieldsDTO) => this.mapCustomFieldFromAPIData(cfTypeDto))
  }

  private mapCustomFieldFromAPIData = (cfTypeDto: ICustomFieldsDTO): ICustomFieldPM => {
    return {
      id: cfTypeDto.id,
      account: unescape(cfTypeDto.account_name),
      name: unescape(cfTypeDto.name),
      default: cfTypeDto.default,
      archived: cfTypeDto.archived,
      type: unescape(cfTypeDto.field_type.name),
      typeId: cfTypeDto.field_type.slug,
      displaysOn: unescape(cfTypeDto.apply_to.name),
      displaysOnSlug: cfTypeDto.apply_to.slug,
      used: cfTypeDto.used,
      accountId: cfTypeDto.account_id,
      required: cfTypeDto.required,
      displayList: cfTypeDto.display_list,
      displaySearch: cfTypeDto.display_search,
      displayTable: cfTypeDto.display_search,
      fieldOptions: this.getFieldOptionsFromDTO(cfTypeDto.field_options),
      constraint: cfTypeDto.constraint ? this.mapConstraintsData(cfTypeDto.constraint) : null,
      allowFieldCreation: cfTypeDto.allow_field_creation,
      // addition for remote custom fields
      isPrimary: cfTypeDto.is_primary,
      keySubfieldId: cfTypeDto.key_subfield_id,
      parentFieldId: cfTypeDto.parent_field_id,
      subfieldIds: cfTypeDto.subfield_ids,
      displayName: cfTypeDto.display_name
    }
  }

  private mapConstraintsData = (constraint: any) => {
    return typeof constraint === 'string' ? JSON.parse(constraint) : constraint
  }

  public getRunbookFilterRelatedCustomFields = (accountId: number, customFieldIds: number[]) => {
    if (!this.customFields) return []

    return this.customFields.filter((customField: ICustomFieldPM) => {
      const conditions =
        // if accountId or matches is null
        (customField.accountId === accountId || customField.accountId === null) &&
        // if displaySearch === true
        customField.displaySearch &&
        // if custom field is part of custom field ids from runbooks meta
        customFieldIds.includes(customField.id)
      if (conditions) return customField
    })
  }

  // returns custom fields by displays on and constraints
  public getRunbookCustomFieldsByDisplaysOn = (accountId: number, displaysOn: string, runbookTypeId?: number) => {
    if (!this.customFields) return []
    return this.customFields.filter((customField: ICustomFieldPM) => {
      const conditions =
        // if accountId or matches is null
        (customField.accountId === accountId || customField.accountId === null) &&
        // if displaySearch === true
        customField.displaysOnSlug === displaysOn &&
        // if no constraint, this is a custom field applicable to any runbook types
        (!customField.constraint ||
          // if custom field constraint exists, only matches the runbook type selected
          customField.constraint?.runbook_type_id.includes(runbookTypeId))
      return conditions
    })
  }

  @action
  public clear = () => {
    this.clearInherited()
    this.customFields = []
  }
}
