// @ts-nocheck
import color from 'tinycolor2'
import { css } from 'styled-components'

export const px2rem = (...args) => args.reduce((acc, arg) => `${acc}${arg / 16}rem `, '')

export const windowSizes = {
  sm: 600,
  md: 832,
  lg: 1152,
  xl: 1472
}

export const breakpoints = {
  /*  Media Queries based on min 320px per panel + 64px per vert nav bar */
  sm: `screen and (max-width:${windowSizes.sm - 1}px)`, //phone - single panel
  gtSm: `screen and (min-width:${windowSizes.sm}px)`,
  md: `screen and (min-width: ${windowSizes.sm}px) and (max-width: ${windowSizes.md - 1}px)`, //ipad portrait, small tablet - single panel but vert nav
  gtMd: `screen and (min-width:${windowSizes.md}px)`,
  lg: `screen and (min-width: ${windowSizes.md}px) and (max-width: ${windowSizes.lg - 1}px)`, //ipad landscape - 2 panels open at a time
  gtLg: `screen and (min-width: ${windowSizes.lg}px)`, //desktop, up to 3 panels open at a time
  xl: `screen and (min-width: ${windowSizes.lg}px) and (max-width: ${windowSizes.xl - 1}px)`,
  gtXl: `screen and (min-width: ${windowSizes.xl}px)`
}

export const fontSizes = {
  m: '16px',
  sm: '13px'
}

export const borderRadius = {
  s: '4px',
  m: '8px'
}

export const transitions = {
  mediumBezier: 'all 0.25s cubic-bezier(0.35, 0, 0.25, 1)'
}

export const transition = (speed: 'xfast' | 'fast' | 'medium' | 'slow' = 'fast', attribute: string = 'all') => {
  const speedMap = {
    xfast: 0.15,
    fast: 0.25,
    medium: 0.4,
    slow: 1
  }

  return css`
    transition: ${attribute} ${speedMap[speed]}s cubic-bezier(0.35, 0, 0.25, 1);
  `
}

//new colours (DC)
const bg1 = '#f6f6f6'
const bg2 = '#efefef'
const bg3 = '#e8e8e8'
const bg4 = '#e1e1e2'
const textDefault = 'rgba(22,22,29,1)'
const textLight = 'rgba(22,22,29,0.62)'
const textDisabled = 'rgba(22,22,29,0.37)'
//end new

/* Colors */
const primary = '#2A55C3'
const secondary = '#b6bfc4' // equiv to existing $text-lightest
const primaryHover = '#214399' //equiv to existing $primary-dark
const primaryFocus = '#ccc' //what's this
const secondaryHover = '#f9fafb' //equiv to existing $lighter-grey
const primaryLight = '#87a2e5'
const primaryLightest = '#dde4f5'
const primaryDark = '#214399'

const primaryDarker = color(primaryDark).darken(5).toString()
const cerisesRed = '#e42c64'
const goldYellow = '#ffcc00'

//See variables.scss - theres no primaryDarker. New 'secondary' style
//for the login/reset pass button on the blue background
//1B2648
const primaryGrey = '#97a4aa'
const primaryGreyShadow = color(primaryGrey).setAlpha(0.6).toString()
const primaryGreyBackgroundColor = '#eef0f1'
const primaryGreyHover = '#72848d'
const primaryGreyHoverBackgroundColor = '#d8dddf'
const secondaryGrey = '#b9c1c6'

//For alternate dev-only theme
const primaryAlt = '#9470c4'
const primaryAltDark = color(primaryAlt).darken(5).toString()
const primaryAltDarker = color(primaryAlt).darken(10).toString()
const primaryBackground = '#f9f9fb'
const errorBackgroundColor = '#ffebe6'
const successBackgroundColor = color('rgba(92, 184, 92, 0.4)').toString()
const componentBorder = '#edeef3'

const text = textDefault
const textLighter = textLight
const textLightest = textDisabled
const textFaint = textDisabled
const bg = bg4
const bgLight = bg3
const bgLighter = bg2
const bgLightest = bg1
const bgLightest3 = bg2
const bgLightest2 = bg2
const bgDark = '#999999'
const bgHighlight = '#fdfad7'
const dark = '#57646b'

const success = '#5cb85c'
const successBg = color(success).lighten(40).toString()
const successText = color(success).darken(5).toString()

const error = '#ff3300'
const info = '#2f96b4'
const warning = '#f89406'
const warningOrange = '#f90'
const white = '#fff'
const black = '#000'
const lightBlack = '#13151b'
const labelColor = '#b5b5b5'
const lightBlueGrey = '#a9b1c6'
const darkGrey = '#57646b'

export const colors = {
  bg,
  bgDark,
  bgHighlight,
  bgLight,
  bgLighter,
  bgLightest,
  bgLightest2,
  bgLightest3,
  black,
  cerisesRed,
  componentBorder,
  dark,
  error,
  errorBackgroundColor,
  goldYellow,
  info,
  labelColor,
  lightBlack,
  lightBlueGrey,
  primary,
  primaryAlt,
  primaryAltDark,
  primaryAltDarker,
  primaryBackground,
  primaryDark,
  primaryDarker,
  primaryFocus,
  primaryGrey,
  primaryGreyBackgroundColor,
  primaryGreyHover,
  primaryGreyHoverBackgroundColor,
  primaryGreyShadow,
  primaryHover,
  primaryLight,
  primaryLightest,
  secondary,
  secondaryGrey,
  secondaryHover,
  success,
  successBackgroundColor,
  successBg,
  successText,
  text,
  textFaint,
  textLighter,
  textLightest,
  darkGrey,
  warning,
  warningOrange,
  white,
  bg1,
  bg2,
  bg3,
  bg4,
  textDefault,
  textLight,
  textDisabled
}
