// @ts-nocheck
import { useState, useCallback, useEffect } from 'react'

import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { PhoneNumberPresenter } from './PhoneNumberPresenter'
import { Select, TextInput } from 'Components/Molecules'
import { colors, fontSizes, px2rem } from 'app/Styles/Variables'
import { Icon } from 'Components/Atoms'

const PhoneNumberWrapper = styled.div`
  min-width: 100px;
`

const OpenSelectButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: ${colors.textLighter};
  font-size: ${fontSizes.m};
  padding: 0;
  display: flex;
  flex-direction: row;
`

const CountryCodeContainer = styled.span`
  color: ${colors.textLighter};
  font-size: ${fontSizes.m};
`

const CountryCode = styled.span`
  color: ${(props: { disabled: boolean }) => (props.disabled ? colors.textLighter : colors.text)};

  &:hover {
    color: ${(props: { disabled: boolean }) => (props.disabled ? colors.textLighter : colors.primary)};
  }
`
interface IPhoneNumberProps {
  presenter: PhoneNumberPresenter
  validationComponents: any[]
}

export const PhoneNumber = observer<IPhoneNumberProps>(({ presenter, validationComponents }) => {
  const { numberPresenter, countryPresenter, selectedCountry, disabled } = presenter

  const [selectFocused, setSelectFocused] = useState(false)
  const [inputFocused, setInputFocused] = useState(false)
  const [showCountryValidation, setShowCountryValidation] = useState(false)
  const [showNumberValidation, setShowNumberValidation] = useState(false)

  const blurInput = () => {
    if (!selectFocused) setInputFocused(false)
  }

  const checkForValidPhoneNumber = useCallback(() => {
    if (numberPresenter.value && numberPresenter.value.length < 7) {
      setShowNumberValidation(true)
    } else {
      setShowNumberValidation(false)
    }
  }, [numberPresenter.value])

  useEffect(() => {
    checkForValidPhoneNumber()
  }, [numberPresenter.value])

  const updateOnFocus = val => {
    if (!val) {
      setTimeout(() => {
        setShowCountryValidation(false)
        setShowNumberValidation(false)
        blurInput()
      }, 200)
    }
    if (!countryPresenter.value) {
      setShowCountryValidation(true)
    }
  }

  const updateSelectFocus = (val: boolean) => {
    setSelectFocused(val)
  }

  const getValidationMessage = () => {
    if (showCountryValidation) return validationComponents[0]
    if (showNumberValidation) return validationComponents[1]
    return <></>
  }

  const onCountryChange = () => {
    setInputFocused(true)
    setSelectFocused(false)
    setShowCountryValidation(false)
  }

  const handleClick = () => {
    if (disabled) {
      return
    }
    setInputFocused(true)
    checkForValidPhoneNumber()
  }

  const openSelectContent = (
    <>
      <CountryCodeContainer>{`${selectedCountry.code}`}</CountryCodeContainer>
      &nbsp;
      <CountryCode disabled={disabled}>{`(+${selectedCountry.dial})`}</CountryCode>
    </>
  )

  const openSelectButton = (
    <OpenSelectButton
      onClick={() => {
        if (disabled) {
          return
        }
        setSelectFocused(true)
      }}
      type={'button'}
    >
      {openSelectContent}
      <Icon name={'chevron-down'} extraStyles={{ width: '18px' }} />
    </OpenSelectButton>
  )

  return (
    <PhoneNumberWrapper>
      {selectFocused ? (
        <Select
          presenter={countryPresenter}
          getFocused={updateSelectFocus}
          autoFocusOnMount={true}
          customOnChange={onCountryChange}
          notClearable={true}
          extraStyles={{ marginTop: '.55rem' }}
        />
      ) : (
        <TextInput
          presenter={numberPresenter}
          getFocused={updateOnFocus}
          customHiddenPrefixRender={disabled ? openSelectContent : openSelectButton}
          customSuffixRender={getValidationMessage()}
          inputOnClick={() => {
            handleClick()
          }}
          isShrunk={inputFocused}
          parentIsFocused={inputFocused}
          required={false}
        />
      )}
    </PhoneNumberWrapper>
  )
})
