import singleSpaAngularJS from 'single-spa-angularjs'

// @ts-ignore
const angular = window.angular

export default singleSpaAngularJS({
  angular,
  domElementGetter: () => document.getElementById('cutover-angular'),
  mainAngularModule: 'app',
  uiRouter: false,
  preserveGlobal: true,
  template: '<base-app />'
})
