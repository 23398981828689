// @ts-nocheck
import { TTableSortDirection } from './TTableSortDirection'

export function sort<T>(
  data: T[] = null, //[{}]
  onKey: string = null, // Key within object
  dataType: string = 'string', // The type of data we are sorting
  sortDir: TTableSortDirection
): T[] {
  let sortedData: T[]
  if (data) {
    switch (dataType.toLowerCase()) {
      case 'string':
        sortedData = data.sort((a, b) => compareString(a, b, onKey, sortDir.toLowerCase()))
        break
      case 'date':
      case 'duration':
        sortedData = data.sort((a, b) => compareDates(a, b, onKey, sortDir.toLowerCase()))
        break
      case 'int':
      case 'numb':
      case 'number':
        sortedData = data.sort((a, b) => compareNumber(a, b, onKey, sortDir.toLowerCase()))
        break
      case 'boolean':
        sortedData = data.sort((a, b) => compareBoolean(a, b, onKey, sortDir.toLowerCase()))
        break
      default:
        sortedData = data
        break
    }
  }
  return sortedData
}

// /* ------------------
//  * Cpoy & past from original app
//  */

// /* ------------------
//  * If we have been given a _field_name that look like item:nested_tem
//  * Then this function will reach into that nested object to fetch the data
//  */
function checkForNestedData(fieldName, rowData): any {
  var target: any = ''
  if (typeof fieldName === 'string' && fieldName.indexOf(':') !== -1) {
    let keySegments: string[] = fieldName.split(':')

    target = rowData

    for (let segment of keySegments) {
      if (typeof target === 'object' && target.hasOwnProperty(segment)) {
        target = target[segment]
      } else {
        target = -1
      }
    }
  } else {
    if (rowData.hasOwnProperty(fieldName)) {
      target = rowData[fieldName]
    }
  }

  return target
}

function compareString(a: any = {}, b: any = {}, onKey: string = null, sortType: string = null) {
  var genreA = String(checkForNestedData(onKey, a)).toLowerCase() //a[ onKey ];
  var genreB = String(checkForNestedData(onKey, b)).toLowerCase() //b[ onKey ];
  var comparison = 0

  if (sortType === 'asc') {
    comparison = genreA >= genreB ? 1 : -1
  } else if (sortType === 'desc') {
    comparison = genreA <= genreB ? 1 : -1
  }

  return comparison
}

function compareDates(a: any = {}, b: any = {}, onKey: string = null, sortType: string = null) {
  var genreA = checkForNestedData(onKey, a) //a[ onKey ];
  var genreB = checkForNestedData(onKey, b) //b[ onKey ];

  if (sortType === 'asc') {
    return genreA < genreB ? -1 : genreA > genreB ? 1 : 0
  } else if (sortType === 'desc') {
    return genreA > genreB ? -1 : genreA < genreB ? 1 : 0
  }

  return 0
}

function compareNumber(a: any = {}, b: any = {}, onKey: string = null, sortType: string = null) {
  var genreA = checkForNestedData(onKey, a) //a[ onKey ];
  var genreB = checkForNestedData(onKey, b) //b[ onKey ];

  if (sortType === 'asc') {
    return genreB - genreA
  } else if (sortType === 'desc') {
    return genreA - genreB
  }
}

function compareBoolean(a: any = {}, b: any = null, onKey: string = null, sortType: string = null) {
  var genreA = a[onKey]
  var genreB = b[onKey]

  if (sortType === 'asc') {
    return genreA === genreB ? 0 : genreA ? -1 : 1
  } else {
    return genreA === genreB ? 0 : genreA ? 1 : -1
  }
}
