export default () => (
  <div id="react-error-boundary">
    <div>
      <svg viewBox="0 0 48 48" width="72px" height="72px">
        <path d="M22.5 17.21h3v12h-3v-12Zm3 15h-3v3h3v-3Z"></path>
        <path d="M26.67 7.542c-1.187-2.056-4.154-2.056-5.34 0L4.418 36.834c-1.187 2.056.296 4.625 2.67 4.625h33.824c2.374 0 3.857-2.57 2.67-4.625L26.67 7.542Zm12.51 30.834H8.82a1 1 0 0 1-.866-1.5l15.18-26.293a1 1 0 0 1 1.732 0l15.18 26.293a1 1 0 0 1-.866 1.5Z"></path>
      </svg>
      <h1>Oops, something went wrong!</h1>
      <button onClick={() => window.location.reload()} css="height: 40px">
        <div>
          <svg aria-label="Refresh" viewBox="0 0 48 48" width="24px" height="24px">
            <path d="m34.748 9.558 3.982-3.983v9.9h-9.9l3.769-3.769c-5.851-4.103-13.978-3.54-19.206 1.687-5.857 5.858-5.857 15.356 0 21.214 5.858 5.858 15.356 5.858 21.214 0a14.988 14.988 0 0 0 4.144-13.339l2.95-.546c1.037 5.627-.621 11.654-4.973 16.006-7.03 7.03-18.427 7.03-25.456 0-7.03-7.03-7.03-18.427 0-25.456C17.674 4.87 27.7 4.3 34.748 9.558Z"></path>
          </svg>
          Refresh
        </div>
      </button>
    </div>
  </div>
)
