import { PaintRunbook } from '../node-map-types'

export const paintRunbook = ({ ctx, x, y, size, lineWidth, stroke, fill, dashed }: PaintRunbook) => {
  /*
                  ---------
          edge -> |       |
                 ---      |
  overspill  ->  ---      |
          edge -> |       |
                  ---------
                ^ left offset
   */

  const edge = size * 0.4
  const leftOffset = size * 0.1
  const leftSide = size - leftOffset
  ctx.beginPath()
  // bottom left edge + anti-clockwise from there
  ctx.moveTo(x - leftSide, y + size - edge)
  ctx.lineTo(x - leftSide, y + size)
  ctx.lineTo(x + size, y + size)
  ctx.lineTo(x + size, y - size)
  ctx.lineTo(x - leftSide, y - size)
  // top left edge
  ctx.lineTo(x - leftSide, y - size + edge)

  ctx.lineWidth = lineWidth
  ctx.strokeStyle = stroke
  ctx.stroke()

  if (fill) {
    ctx.fillStyle = fill
    ctx.fill()
  }

  const remainingSpace = size - edge
  const lineSpacing = remainingSpace / 1.75
  const overSpill = 1.5

  ctx.beginPath()
  // horizontal lines, top then bottom
  ctx.moveTo(x - size - overSpill, y + size - (edge + lineSpacing))
  ctx.lineTo(x - size + leftOffset + overSpill, y + size - (edge + lineSpacing))
  ctx.moveTo(x - size - overSpill, y - size + edge + lineSpacing)
  ctx.lineTo(x - size + leftOffset + overSpill, y - size + edge + lineSpacing)

  ctx.lineWidth = 1
  ctx.strokeStyle = stroke
  ctx.stroke()

  if (dashed) {
    // adds small lines to look like dashes
    // anticlockwise starting from bottom left
    const gapSize = 1
    ctx.beginPath()
    ctx.moveTo(x - leftSide + edge, y + size)
    ctx.lineTo(x - leftSide + edge + gapSize, y + size)
    ctx.moveTo(x + size - edge, y + size)
    ctx.lineTo(x + size - (edge + gapSize), y + size)
    ctx.moveTo(x + size, y + size - edge)
    ctx.lineTo(x + size, y + size - (edge + gapSize))
    ctx.moveTo(x + size, y - size + edge)
    ctx.lineTo(x + size, y - size + (edge + gapSize))
    ctx.moveTo(x + size - edge, y - size)
    ctx.lineTo(x + size - (edge + gapSize), y - size)
    ctx.moveTo(x - leftSide + edge, y - size)
    ctx.lineTo(x - leftSide + edge + gapSize, y - size)

    ctx.lineWidth = 2
    ctx.strokeStyle = 'rgb(255,255,255)'
    ctx.stroke()
  }
}
