// @ts-nocheck
import {
  initalizeDateWithDefaultTime,
  formatDate,
  cloneDate,
  setDate,
  getYear,
  getMonth,
  getDate,
  getHours,
  getMinutes,
  isDateValid,
  getUnix,
  addToDate
} from 'Shared/Helpers/DateService'
import { TextInputPresenter } from 'Components/Molecules'
import { FiltersRepository } from 'app/Filters/FiltersRepository'
import { observable, computed, action } from 'mobx'
import { container } from 'app/Config/IOC'
import { Router } from 'app/Routing/Router'
import { IFilterKey, ISelectedFilter, IGenericFilter, TFilterType } from 'app/Filters/FilterEntities'

export class DateTimeFilterPresenter {
  constructor(initialValue: Date, key: IFilterKey) {
    this.filterKey = key
    this.initialiseComponent(initialValue)
  }

  @observable
  public value: Date

  @observable
  public disabled: boolean = false

  @observable
  public isDirty: boolean = false

  @observable
  public filtersRepository: FiltersRepository = container.get(FiltersRepository)

  @observable
  public router: Router = container.get(Router)

  public datePresenter: TextInputPresenter

  private format = 'iii, d MMM yyyy'
  private formatToParse = 'iii MMM yyyy'
  public enableClear: boolean = false
  public onClear?: () => void
  public allowSameDay: boolean = true

  @observable
  public controlState: string

  @observable
  public narrow: boolean = false

  @observable
  private filterKey: IFilterKey

  @computed
  public get pageFilter(): IGenericFilter {
    return this.filtersRepository.pageFilters.find(filter => this.filterFinder(filter.key, filter.type))
  }

  private filterFinder = (filterKey: IFilterKey, filterType: TFilterType) => {
    return filterType === 'date' && this.filterKey.reference === filterKey.reference
  }

  @computed
  public get filterDateValueAsString(): string {
    return this.selectedFilter && this.selectedFilter.value
  }

  @computed
  public get selectedFilter(): ISelectedFilter {
    return this.filtersRepository.selectedFilters.find(filter => this.filterFinder(filter.key, filter.type))
  }

  public updateFromFiltersRepository = () => {
    const value = this.selectedFilter && this.selectedFilter.value
    if (!value && this.value) {
      this.initialiseComponent(null)
      return
    } else if (value) {
      this.value = new Date(Number(value) * 1000)
    }
    this.initialiseComponent(this.value)
  }

  public onChange = (newDateValue: Date) => {
    if (!this.disabled) {
      this.filtersRepository.toggleFilterValue(this.pageFilter, getUnix(this.value).toString())
      this.router.refreshFilteredRoute()
      this.value = newDateValue
      this.isDirty = true
    }
  }

  public handleTimeChange = this.onChange

  public handleHourChange = (value: number) => {
    const difference = value - getHours(this.value)
    this.onChange(addToDate(this.value, { hours: difference }))
  }

  public handleMinuteChange = (value: number) => {
    const difference = value - getMinutes(this.value)
    this.onChange(addToDate(this.value, { minutes: difference }))
  }

  public clear = () => {
    this.controlState = 'initempty'
    this.isDirty = true
    this.initialiseComponent(null)
    this.filtersRepository.toggleFilterValue(this.pageFilter)
    this.router.refreshFilteredRoute()

    if (this.onClear) {
      this.onClear()
    }
  }

  public reset = () => {
    this.clear()
    this.isDirty = false
  }

  public initialiseComponent = (initialValue: Date) => {
    if (initialValue === null) {
      this.controlState = 'initempty'
      this.value = initalizeDateWithDefaultTime()
    } else {
      this.value = initialValue
      this.controlState = 'populated'
    }
    const initialText = initialValue ? formatDate(initialValue, this.format) : ''
    let currentLabel = this.datePresenter ? this.datePresenter.label : ''

    this.datePresenter = new TextInputPresenter(initialText)
      .withMiddleware(this.handleNewDateString)
      .withIconPrefix('calendar')
    this.datePresenter.disabled = this.disabled
    this.datePresenter.label = currentLabel
  }

  public handleDateChange = (date: Date) => {
    this.controlState = 'populated'
    const newDate = cloneDate(setDate(date, { year: getYear(date), month: getMonth(date), date: getDate(date) }))
    this.onChange(newDate)
    this.datePresenter.onChange(formatDate(newDate, this.format))
  }

  private handleNewDateString? = (dateString: string): string => {
    if (dateString.length === 0) return dateString

    if (!dateString.includes(',')) return ''

    const date = new Date(formatDate(new Date(dateString.split(',')[1].trim()), this.formatToParse))
    if (isDateValid(date) && formatDate(date, 'yyyy-MM-dd') !== formatDate(this.value, 'yyyy-MM-dd')) {
      const newDate = cloneDate(
        setDate(this.value, { year: getYear(date), month: getMonth(date), date: getDate(date) })
      )
      this.onChange(newDate)
      return formatDate(newDate, this.format)
    }
    return dateString
  }

  public withPlaceholder = (placeholder: string) => {
    this.datePresenter.placeholder = placeholder
    return this
  }

  public withLabel? = (label: string) => {
    this.datePresenter.label = label
    return this
  }

  public withClear? = () => {
    this.enableClear = true
    return this
  }

  public withOnClear = (onClear: () => void) => {
    this.onClear = onClear
    return this
  }

  public withAllowedSameDaySelection = (allowSameDay: boolean) => {
    this.allowSameDay = allowSameDay
    return this
  }

  public withDisabled = () => {
    this.disabled = true
    this.datePresenter.disabled = true
    return this
  }

  public withEnabled = () => {
    this.disabled = false
    this.datePresenter.disabled = false
    return this
  }

  public getValueInServiceFormat = () => {
    if (!this.value) {
      return null
    }

    return formatDate(this.value)
  }

  @action
  public withNarrow = narrow => {
    this.narrow = narrow
    return this
  }
}
