import { Box, Text } from '@cutover/react-ui'
import { CustomField, FieldValue } from 'main/services/queries/types'

type ReadonlyCustomFieldProps = {
  customFields: CustomField[]
  fieldValues: FieldValue[]
}

export const ReadonlyCustomFields = ({ customFields, fieldValues }: ReadonlyCustomFieldProps) => {
  return (
    <Box direction="column" width="100%" gap="medium">
      {customFields.map(cf => {
        const fieldValue = fieldValues.find(fv => fv.custom_field_id === cf.id)
        return (
          (fieldValue?.display_value || cf.default_value) && (
            <Box key={cf.id} margin={{ vertical: 'small' }}>
              <Text>{cf.display_name}</Text>
              <Text color="text-light">{fieldValue?.display_value ?? cf.default_value}</Text>
            </Box>
          )
        )
      })}
    </Box>
  )
}
