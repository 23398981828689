import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Copy = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Copy" {...props}>
    <path d="M39 6V3h-6v3h6Zm-7 3v7h7v3h-7v7h-3v-7h-7v-3h7V9h3Zm-21 7h2v3h-2a5 5 0 0 0-5 5v13a5 5 0 0 0 5 5h13a5 5 0 0 0 5-5v-2h3v2a8 8 0 0 1-8 8H11a8 8 0 0 1-8-8V24a8 8 0 0 1 8-8Zm5-7h3v6h-3V9Zm29 0h-3v6h3V9Zm-3 11h3v6h-3v-6Zm-3 9v3h-6v-3h6Z" fill="#000" /><path d="M19 20h-3v6h3v-6Zm9-17v3h-6V3h6Zm0 29v-3h-6v3h6Z" fill="#000" />
  </StyledIcon>
))

Copy.displayName = 'Copy'

export { Copy }
