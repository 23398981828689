// @ts-nocheck
import 'reflect-metadata'
import { injectable, inject } from 'inversify'
import { observable, computed, action } from 'mobx'
import { IServiceGateway } from 'Gateways/Service/IServiceGateway'
import { IStorageGateway } from 'Gateways/Storage/IStorageGateway'
import { IBaseResponse } from 'Gateways/Service/IBaseResponse'
import { IAuthUser } from './IAuthUser'
import { IInvitation } from './IInvitation'
import { IRole } from 'Shared/Entities/Types/RoleType/IRole'
import { ISession } from './ISession'
import { getRolesForService } from 'Helpers/.'
import { Types } from 'Gateways/Service/Types'
import { IBulkInvitation } from './IBulkInvitation'

export interface IReInvite {
  id: number
  uniqueId?: string
  email: string
}

/** @deprecated AuthenticationRepository should not be used directly in code in main/ */
@injectable()
export class AuthenticationRepository {
  @inject(Types.IServiceGateway)
  public serviceGateway: IServiceGateway

  @inject(Types.IStorageGateway)
  public storageGateway: IStorageGateway

  @observable
  public user: IAuthUser = null

  @observable
  public session: ISession = null

  @observable
  public recoveringSession: boolean = false

  @observable
  public loading = false

  @computed
  public get authenticated(): boolean {
    return this.user !== null
  }

  public login = async (email: string, password: string): Promise<{ success: boolean; errorMessages?: string[] }> => {
    const response = await this.serviceGateway.post('auth/sign_in', {
      email,
      password
    })

    if (response.success) {
      const { name, id, permissions, cutover_employee } = response.body.data

      this.user = {
        email,
        name,
        id,
        permissions,
        cutoverEmployee: cutover_employee
      }
    }

    return response
  }

  public loadAuth = async () => {
    const blob = this.storageGateway.getItem('auth_headers')
    return blob ? JSON.parse(blob) : null
  }

  public setAuth = async response => {
    const { headers } = response

    const authHeaders = this.storageGateway.getItem('auth_headers')
    const oldToken = authHeaders ? JSON.parse(authHeaders) : null
    const oldTokenExpiry = (oldToken && oldToken.expiry) || 0
    const newTokenExpiry = headers['expiry']

    if (newTokenExpiry !== '' && Number(newTokenExpiry) >= Number(oldTokenExpiry)) {
      return this.storageGateway.setItem(
        'auth_headers',
        JSON.stringify({
          'access-token': headers['access-token'],
          client: headers['client'],
          expiry: headers['expiry'],
          'token-type': headers['token-type'],
          uid: headers['uid']
        })
      )
    }
  }

  public recoverSession = async () => {
    try {
      const response = await this.serviceGateway.get('auth/validate_token')
      const { name, id, email, permissions, cutover_employee } = response.body.data
      this.user = { email, name, id, permissions, cutoverEmployee: cutover_employee }
      this.session = (await this.loadAuth()) as any
    } catch (error) {
      this.clearUserSession()
    }
  }

  @action
  public clearUserSession = () => {
    this.storageGateway.removeItem('auth_headers')
    this.user = null
    this.session = null
  }

  @action
  public individualInvitation = async (invitation: IInvitation, roles: IRole[], skipInvitation: boolean) => {
    this.loading = true
    let invitationPayload = {
      first_name: invitation.firstName,
      last_name: invitation.lastName,
      input_email: invitation.email,
      skip_invitation: skipInvitation,
      provider: invitation.provider || 'email',
      unique_id: undefined,
      roles: [],
      invite_message: invitation.inviteMessage
    }

    if (invitation.uniqueId) {
      invitationPayload.unique_id = invitation.uniqueId
    }

    if (invitation.provider === 'none') {
      invitationPayload['pref_sms'] = false
      invitationPayload['pref_email'] = false
      invitationPayload['html_email_preference'] = false
    }

    invitationPayload.roles = getRolesForService(roles)

    const response = await this.serviceGateway.post('auth/invitation', {
      user: invitationPayload
    })

    this.loading = false

    return response
  }

  @action
  public resendInvitation = async (reInvite: IReInvite): Promise<IBaseResponse> => {
    this.loading = true

    const response = await this.serviceGateway.post('auth/invitation', {
      user: {
        id: reInvite.id,
        unique_id: reInvite.uniqueId || null,
        email: reInvite.email
      }
    })

    this.loading = false
    return response
  }

  public bulkInvitation = async (bulkInvite: IBulkInvitation) => {
    const fd = new FormData()
    const skipInvitation = !bulkInvite.sendInvitation
    fd.append('file', bulkInvite.data)
    fd.append('roles', JSON.stringify(getRolesForService(bulkInvite.roles)))
    fd.append('skip_invitation', skipInvitation.toString())

    if (bulkInvite.inviteMessage) {
      fd.append('invite_message', bulkInvite.inviteMessage)
    }

    if (bulkInvite.provider) {
      fd.append('provider', bulkInvite.provider)
    }

    return await this.serviceGateway.post('auth/invitation', fd, {
      'Content-Type': 'multipart/form-data'
    })
  }

  public canView = (routePermissionId: string): boolean => {
    if (this.user && this.user.permissions && this.user.permissions.views && this.user.permissions.views.mycutover) {
      return this.user.permissions.views.mycutover[routePermissionId]
    }
  }
}
