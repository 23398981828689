// @ts-nocheck
import { GenericFilter } from 'app/Filters/GenericFilter'
import { computed, observable, action } from 'mobx'

export class GenericResourceFilter extends GenericFilter {
  @observable
  public resourceId: string

  @observable
  public valueInUrl: { key: string; value: string } = null

  @observable
  public populatedUrl: string

  @observable
  public apiUrl: string

  @action
  public select = (filter: { key: string; value: string }) => {
    this.valueInUrl = filter
    this.isPopulated = true
  }

  @action
  public deselect = () => {
    this.valueInUrl = null
    this.isPopulated = false
  }

  public match = (filter: { key: string; value: string }) => {
    if (
      this.populatedUrl &&
      filter.key === this.populatedUrl.split('=')[0] &&
      filter.value === this.populatedUrl.split('=')[1]
    ) {
      return true
    }

    if (filter.key === this.reference && filter.value === this.resourceId) return true
    return false
  }

  @computed
  public get serialiseForUrl(): string {
    if (this.hideFromTheUrl) return ''
    if (this.isPopulated && this.populatedUrl) {
      return this.populatedUrl
    }
    if (this.isPopulated) {
      return `${this.reference}=${this.resourceId}`
    }
    return ''
  }

  @computed
  public get serialiseForApi(): string {
    if (this.isPopulated && this.apiUrl) {
      return this.apiUrl
    }
    if (this.isPopulated) {
      return `${this.reference}[]=${this.resourceId}`
    }
    return ''
  }

  @computed
  public get serialiseForComparitor(): string {
    if (this.isPopulated) {
      return this.resourceId
    }

    return null
  }

  public middlewareFunction = value => {
    this.onChangeCallback && this.onChangeCallback(this.reference, this.resourceId)
    this.isPopulated = !!value
    this.refreshFilteredRoute()
    return value
  }

  constructor({ reference, resourceId }: { reference: string; resourceId: string }) {
    super({ reference })

    if (!resourceId) throw new TypeError('A Resource Filter requires a resourceId')

    this.resourceId = resourceId
  }

  public compareMatch = (comparitor: string): boolean => {
    return comparitor === this.resourceId && this.isPopulated
  }

  @action
  public withPopulatedUrl = (populatedUrl: string) => {
    this.populatedUrl = populatedUrl
    return this
  }

  @action
  public withApiUrl = (apiUrl: string) => {
    this.apiUrl = apiUrl
    return this
  }
}
