import { IconButton, Menu, MenuListItem } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { ConnectSetting } from 'main/services/queries/types/connect'

export const ConnectSettingsEditHeaderMenu = ({
  data,
  handleClickActivateProject,
  handleClickRenewCert,
  handleClickConnectDownloads
}: {
  data: ConnectSetting | undefined
  handleClickActivateProject: () => void
  handleClickRenewCert: () => void
  handleClickConnectDownloads: () => void
}) => {
  const { t } = useLanguage('connectSettings')

  return (
    <Menu
      align="end"
      trigger={
        <IconButton
          icon="more-vertical"
          disabled={data?.status.toLowerCase() === 'pending'}
          label={t('editPanel.moreOptions')}
          tipPlacement="bottom"
          onClick={e => e.stopPropagation()}
        />
      }
    >
      {data?.status.toLowerCase() === 'inactive' ? (
        <MenuListItem label={t('editPanel.tooltips.activate')} icon={'play'} onClick={handleClickActivateProject} />
      ) : (
        <>
          <MenuListItem label={t('editPanel.tooltips.renewCert')} icon={'refresh'} onClick={handleClickRenewCert} />
          <MenuListItem
            label={t('editPanel.tooltips.additionalDownloads')}
            icon={'download'}
            onClick={handleClickConnectDownloads}
          />
        </>
      )}
    </Menu>
  )
}
