import { RunbookTypeType } from 'main/services/queries/types'
import {
  useRunbookType,
  useRunbookTypeCallback,
  useRunbookTypeLookup,
  useRunbookTypeLookupCallback,
  useRunbookTypes,
  useRunbookTypesCallback
} from '../hooks'
import { CollectionModelInterfaceBase, ModelCollectionAccessScope } from './types'

type RunbookTypeCollectionAccessScope = ModelCollectionAccessScope<null>

export type RunbookTypeModelType = CollectionModelInterfaceBase<RunbookTypeType, RunbookTypeCollectionAccessScope>

export const RunbookTypeModel: RunbookTypeModelType = {
  useGet: useRunbookType,
  useGetCallback: useRunbookTypeCallback,

  useGetAll: useRunbookTypes,
  useGetAllCallback: useRunbookTypesCallback,

  useGetLookup: useRunbookTypeLookup,
  useGetLookupCallback: useRunbookTypeLookupCallback
}
