import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const TaskTypeCog = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="TaskTypeCog" {...props}>
    <path d="M13.696 11.598 9.73 10.191A19.978 19.978 0 0 0 5 18.371l3.204 2.734a16.348 16.348 0 0 0 0 5.79L5 29.629c.91 3.107 2.553 5.9 4.73 8.18l3.966-1.407a16.231 16.231 0 0 0 5.012 2.898l.765 4.139a20.05 20.05 0 0 0 4.724.561c1.628 0 3.21-.194 4.724-.561l.765-4.14a16.23 16.23 0 0 0 5.012-2.897l3.967 1.407a19.979 19.979 0 0 0 4.73-8.18l-3.205-2.734c.169-.94.257-1.907.257-2.895s-.088-1.955-.257-2.895l3.204-2.734a19.979 19.979 0 0 0-4.73-8.18l-3.966 1.407A16.231 16.231 0 0 0 29.686 8.7l-.765-4.139A20.05 20.05 0 0 0 24.197 4c-1.627 0-3.21.194-4.724.561l-.765 4.14a16.23 16.23 0 0 0-5.012 2.897Zm7.392-5.458a18.253 18.253 0 0 1 3.11-.265c1.06 0 2.1.091 3.108.265l.732 3.961 1.015.364a14.358 14.358 0 0 1 4.433 2.563l.822.698 3.799-1.348a18.105 18.105 0 0 1 3.113 5.383l-3.066 2.617.19 1.059a14.464 14.464 0 0 1 0 5.126l-.19 1.06 3.066 2.616a18.105 18.105 0 0 1-3.113 5.382l-3.799-1.347-.822.697a14.358 14.358 0 0 1-4.433 2.564l-1.015.364-.732 3.96a18.256 18.256 0 0 1-6.218 0l-.732-3.96-1.015-.364a14.358 14.358 0 0 1-4.432-2.563l-.823-.698-3.799 1.347a18.105 18.105 0 0 1-3.113-5.382l3.066-2.617-.19-1.059a14.476 14.476 0 0 1 0-5.126l.19-1.06-3.066-2.616a18.105 18.105 0 0 1 3.113-5.382l3.799 1.347.823-.698a14.356 14.356 0 0 1 4.432-2.563l1.015-.364.732-3.96Z" fill="#000" />
  </StyledIcon>
))

TaskTypeCog.displayName = 'TaskTypeCog'

export { TaskTypeCog }
