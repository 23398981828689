import { parseISO } from 'date-fns'
import { format } from 'date-fns-tz'
import { sortBy } from 'lodash'

import { BarChart, colors } from '@cutover/react-ui'
import { Chart } from './chart'
import { createChartDeltas } from './chart-deltas'
import { NoDataAvailable } from './no-data-available'

type Delta = {
  duration: number
  percentage: number
}

type AverageRunbookDurationApi = {
  timestamp: string
  average_runbook_duration: number
  duration: string
  delta?: Delta
}

export type AverageRunbookDurationOverTimeConnectedProps = {
  onEvent: (payload: any) => void
  values: AverageRunbookDurationApi[]
  name?: string
}

export function AverageRunbookDurationOverTimeConnected({
  name,
  values,
  onEvent
}: AverageRunbookDurationOverTimeConnectedProps) {
  const data: AverageRunbookDurationApi[] = values

  const durations = (data ?? []).map(e => ({
    date: parseISO(e.timestamp.substring(0, 11)), // Ignore time to avoid midnight conversion to local time
    hours: e.average_runbook_duration,
    delta: e.delta
  }))

  return (
    <Chart
      title={name}
      titleKey="dashboard:averageRunbookDurationOverTime:title"
      render={({ translate }) => {
        if (durations.length === 0 || durations.every(d => d.hours === 0)) {
          return <NoDataAvailable />
        }

        return (
          <AverageRunbookDurationOverTime
            yAxisTitle={translate('dashboard:averageRunbookDurationOverTime:yAxisTitle')}
            durations={durations}
            onSelect={(date, event) => {
              onEvent({
                filterObject: {
                  filter: {
                    stage: ['complete'],
                    run_type: ['live'],
                    start_actual_month: [format(date, 'yyyy-MM-dd')]
                  },
                  subFilter: {
                    key: 'start_actual_month',
                    value: format(date, 'yyyy-MM-dd')
                  }
                },
                event
              })
            }}
          />
        )
      }}
    />
  )
}

export type RunbookDuration = {
  date: Date
  hours: number
  delta?: Delta
}

export type AverageRunbookDurationOverTimeProps = {
  yAxisTitle: string
  durations: RunbookDuration[]
  onSelect?: (date: Date, event: any) => void
}

export function AverageRunbookDurationOverTime({
  yAxisTitle,
  durations,
  onSelect
}: AverageRunbookDurationOverTimeProps) {
  const sortedDurations = sortBy(durations, e => e.date).map(e => ({
    category: {
      name: 'Date',
      date: e.date,
      delta: e.delta,
      value: format(e.date, 'LLLL')
    },
    values: [
      {
        group: 'Hours',
        value: e.hours
      }
    ]
  }))

  // BarChart doesn't currently support nivo xAxis time format or xFormat props
  // The index of the selected bar is used to look up stored date object
  return (
    <BarChart
      data={sortedDurations}
      height={400}
      padding={'30px 0 30px 30px'}
      colors={{
        Hours: colors.primary
      }}
      xAxis={{
        id: 'Date',
        label: ''
      }}
      yAxis={{
        id: 'Hours',
        label: yAxisTitle
      }}
      layers={[
        createChartDeltas(
          sortedDurations.map(d => ({
            id: d.category.value,
            delta: d.category.delta
          }))
        )
      ]}
      onSelect={(data, event) => {
        const date = sortedDurations[data.index]?.category?.date

        if (date) {
          onSelect?.(date, event)
        }
      }}
    />
  )
}
