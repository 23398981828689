import { useQuery } from 'react-query'

import { apiClient, ApiError } from 'main/services/api/api-client'
import { QueryKeys } from 'main/services/queries/query-keys'

export type DynamicFormTask = {
  name: string
  id: number | string
}

export function useRunbookTasksQuery(runbookId: string | number) {
  return useQuery<DynamicFormTask[], ApiError>(
    [QueryKeys.Runbooks, String(runbookId), QueryKeys.ChangeRequests, 'list-tasks'],
    async () => {
      const { data } = await apiClient.get<{ tasks: DynamicFormTask[] }>({
        url: `runbooks/${runbookId}/change_requests/list_tasks`
      })
      return data.tasks
    }
  )
}
