import { ChangeEventHandler, FocusEventHandler, FormEventHandler, forwardRef, useMemo, useRef, useState } from 'react'
import styled from 'styled-components/macro'
import { ColorService, IColor, ColorPicker as ReactColorPalette, useColor } from 'react-color-palette'
import { DropButton } from 'grommet'
import { useMergeRefs } from 'use-callback-ref'
import tc from 'tinycolor2'

import { TextInput, TextInputProps } from '../text-input'
import { TextInputBaseProps } from '../internal/text-input-base'
import { themeElevation } from '../../theme'
import { useUpdateEffect } from '../../utilities/use-update-effect'

const HEX_DEFAULT = '#000000'
export type ColorPickerProps = Omit<TextInputProps & TextInputBaseProps, 'value' | 'onChange'> & {
  value?: string | null
  /** The form's onChange, not the input */
  onChange?: (val?: string | null) => void
}

// The desired format to store in the database for these strings is rgb. However, we have currently both
// hex and rgb. This component accepts an initial value that is either in rgb or hex format. The form input
// always *displays* as hex. If there was no initial value OR if the initial value stored in the data will be
// rgb format. If the initial value is hex, it will stay in hex. We could change that to convert updated
// colors to rgb from hex in the future if desired.

// There is no requirement for clearable color values right now so that will need to be built in if that
// becomes desired. The input can only be cleared by manually deleting the text if it was initially empty.

export const ColorPicker = forwardRef<HTMLInputElement, ColorPickerProps>(
  ({ onChange: onChangeForm, onBlur, onFocus, value: formValue, ...props }, forwardedRef) => {
    const localRef = useRef<HTMLInputElement>(null)
    const inputRef = useMergeRefs(forwardedRef ? [forwardedRef, localRef] : [localRef])
    const initialValue = useMemo(() => formValue, [])
    const initialTc = useMemo(() => tc(initialValue ?? 'rgb(0,0,0)'), [])
    const initialFormat = useMemo(() => initialTc.getFormat(), [])
    const [localColor, setLocalColor] = useColor(toRgbString(initialTc))
    const [showPalette, setShowPalette] = useState(false)
    const [isFocused, setIsFocused] = useState(false)
    const [inputVal, setInputVal] = useState(initialValue ? initialTc.toHexString() : '')
    const [shrinkLabel, setShrinkLabel] = useState(!!inputVal)
    const showLabelShrunk = !props.readOnly && (showPalette || shrinkLabel)
    // override and always show expanded if its an empty value and readOnly
    const isReadOnlyEmpty = !formValue && props.readOnly
    const { hex: localColorHex } = localColor
    const showSwatch = !isReadOnlyEmpty && (isFocused || showPalette || !!formValue)
    const inputText = !isReadOnlyEmpty && (formValue || isFocused || showPalette) ? inputVal : ''

    // manually editing the input text
    const handleInput: FormEventHandler<HTMLInputElement> = event => {
      const tcColor = tc(event.currentTarget.value)
      const isValid = tcColor.isValid()

      if (isValid && event.currentTarget.value !== '') {
        const nextLocalColor = ColorService.convert('hex', tcColor.toHexString())
        setLocalColor(nextLocalColor)
      }

      setInputVal(event.currentTarget.value)
    }

    // changes via the color picker
    const handleLocalColorChange = (color: IColor) => {
      setLocalColor(color)
      setInputVal(color.hex)
    }

    const handleBlurInput: FocusEventHandler<HTMLInputElement> = event => {
      setInputVal(localColorHex)
      onBlur?.(event)
      setIsFocused(false)

      if (!formValue) {
        setShrinkLabel(false)
      }
    }

    const handleFocusInput: FocusEventHandler<HTMLInputElement> = event => {
      if (inputVal === '' && inputRef.current) {
        setInputVal(HEX_DEFAULT)
      }

      setIsFocused(true)
      setShrinkLabel(true)
      onFocus?.(event)
    }

    const handleChangeInput: ChangeEventHandler<HTMLInputElement> = event => {
      if (event.target.value === '' && !props.required) {
        onChangeForm?.(undefined)
        setInputVal('')
        setLocalColor(ColorService.convert('hex', HEX_DEFAULT))
      }
    }

    useUpdateEffect(() => {
      if (inputVal !== '') {
        onChangeForm?.(initialFormat === 'hex' ? localColorHex : tc(localColorHex).toRgbString())
      }
    }, [localColorHex])

    // // When we return focus to the input after selecting from the color picker, select the hex text content
    // // of the input which is consistent with tabbing over the inputs. Otherwise it just inserts the cursor
    // // at the  initial position by default.
    const focusAndSelectInput = () => {
      localRef.current?.focus()
      localRef.current?.select()
    }

    const closePicker = () => {
      setShowPalette(false)
      focusAndSelectInput()
    }

    return (
      <TextInput
        ref={inputRef as any}
        startIcon="format-color-fill"
        shrinkLabel={showLabelShrunk}
        expandLabel={isReadOnlyEmpty}
        mask={[
          { fixed: !isReadOnlyEmpty && isFocused ? '#' : '' },
          { length: [1, 6], regexp: /^[0-9A-F]{1,6}$/i, options: [] }
        ]}
        placeholder="Enter a HEX color..."
        {...props}
        value={inputText}
        onInput={handleInput}
        onChange={handleChangeInput}
        onBlur={handleBlurInput}
        onFocus={handleFocusInput}
        endComponent={
          showSwatch ? (
            <SwatchButton
              plain
              disabled={props.disabled}
              open={showPalette}
              onMouseDownCapture={event => event.preventDefault()}
              onOpen={() => setShowPalette(true)}
              dropAlign={{ right: 'right', top: 'bottom' }}
              swatchColor={localColor.hex}
              dropProps={{
                onClickOutside: () => closePicker(),
                onEsc: () => closePicker()
              }}
              dropContent={
                <PaletteWrap>
                  <ReactColorPalette
                    color={localColor}
                    onChange={handleLocalColorChange}
                    height={100}
                    hideAlpha
                    hideInput={['hsv']}
                  />
                </PaletteWrap>
              }
            />
          ) : undefined
        }
      />
    )
  }
)

const SwatchButton = styled(DropButton).attrs(() => ({ plain: true }))<{ swatchColor?: string }>`
  width: 16px;
  height: 16px;
  border-radius: 4px;
  cursor: pointer;
  background-color: ${props => props.swatchColor};
`

const PaletteWrap = styled.div`
  margin-top: 8px;
  box-shadow: none;
  .rcp-root {
    box-shadow: ${themeElevation('medium')};
  }
  .rcp-body {
    padding: 16px;
  }
  .rcp-field {
    flex-direction: row-reverse !important;
    align-items: center !important;
    gap: 8px !important;
  }
`

type RGBString = `rgb${string}`

function toRgbString(tc: tc.Instance): RGBString {
  const input = tc.getOriginalInput()

  if (!tc.isValid()) {
    console.warn('invalid color string', input)
    return 'rgb(0,0,0)'
  }

  return tc.toRgbString() as RGBString
}
