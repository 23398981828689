// @ts-nocheck
import { IServiceGateway } from 'Gateways/Service/IServiceGateway'
import { Types } from 'Gateways/Service/Types'
import { injectable, inject } from 'inversify'
import { action, observable } from 'mobx'

interface IComponentDTO {
  id: number
  order: number
  component_type: string
  settings: string
  filters?: string
  width: number
}

interface IDashboardDTO {
  id: number
  name: string
  default: boolean
  display_on?: string
  account_name: string
  account_id?: number
  global: boolean
  dashboard_type: string
  is_primary: boolean
  dashboard_components: IComponentDTO[]
}

export interface IComponentPM {
  id: number
  order: number
  type: string
  settings: string
  width: number
}

export interface IDashboardPM {
  id: number
  name: string
  global: boolean
  accountId: number
  isPrimary: boolean
  components: IComponentPM[]
}

@injectable()
export class DashboardsRepository {
  @inject(Types.IServiceGateway)
  public serviceGateway: IServiceGateway

  @observable
  public loadingPageDashboards: boolean = false

  @observable
  public pageDashboards: IDashboardPM[] = []

  @action
  public loadPageDashboards = async () => {
    this.loadingPageDashboards = true
    const rawData = await this.serviceGateway.get('dashboards?dashboard_type=page')

    if (rawData.success) {
      this.pageDashboards = this.mapPageDashboards(rawData.body.dashboards)
    }

    this.loadingPageDashboards = false
  }

  private mapPageDashboards = (rawDashboards: IDashboardDTO[]) => {
    if (rawDashboards.length === 0) return []

    const pageDashboards: IDashboardPM[] = []

    rawDashboards.forEach((dashboard: IDashboardDTO) => {
      pageDashboards.push({
        id: dashboard.id,
        name: dashboard.name,
        global: dashboard.global,
        accountId: dashboard.account_id,
        isPrimary: dashboard.is_primary,
        components: this.mapDashboardComponents(dashboard.dashboard_components)
      })
    })

    return pageDashboards
  }

  private mapDashboardComponents = (rawComponents: IComponentDTO[]): IComponentPM[] => {
    const components = []
    rawComponents.forEach((component: IComponentDTO) => {
      components.push({
        id: component.id,
        order: component.order,
        type: component.component_type,
        settings: component.settings,
        width: component.width
      })
    })

    return components
  }
}
