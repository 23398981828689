import { Text } from '@cutover/react-ui'
import * as ListItem from 'main/components/shared/runbook-list-item/runbook-list-item-layout'
import { RunbookListRunbook } from 'main/services/queries/types'
import { DurationDiff, ExternalId, ProjectName, RagStatusDisplay, TasksCount, TemplateStatus } from './sub-header'
import { useAccountRunbookTypes } from 'main/services/api/data-providers/account/account-data'

export const RunbookItemSubHeader = ({ runbook }: { runbook: RunbookListRunbook }) => {
  const { runbookTypeLookup } = useAccountRunbookTypes()
  const runbookType = runbookTypeLookup?.[runbook.runbook_type_id]
  const isIncident = runbookType?.incident

  return (
    <>
      <ListItem.LeftItemTextSubtitle>
        <RagStatusDisplay runbook={runbook} />
        <Text truncate>
          <Text size="small" color="text-light">{`#${runbook.id}`}</Text>
          {runbook.is_template && !!runbook.template_status && <TemplateStatus runbook={runbook} />}
          <ProjectName runbook={runbook} />
          {!isIncident && <DurationDiff runbook={runbook} />}
          {runbook.tasks_count > 0 && <TasksCount runbook={runbook} />}
          {runbook.external_id && <ExternalId runbook={runbook} />}
        </Text>
      </ListItem.LeftItemTextSubtitle>
    </>
  )
}
