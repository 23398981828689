import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const PlayArrow = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="PlayArrow" {...props}>
    <path d="M40.009 26.599c2-1.155 2-4.043 0-5.198L17.5 8.407c-2-1.156-4.501.288-4.501 2.598v25.99c0 2.31 2.5 3.754 4.502 2.599l22.507-12.995Z" fill="#000" />
  </StyledIcon>
))

PlayArrow.displayName = 'PlayArrow'

export { PlayArrow }
