import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const TaskList = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="TaskList" {...props}>
    <path d="m9.657 15.9 9.778-9.779L17.314 4l-7.657 7.657L6.12 8.12 4 10.243l5.657 5.656ZM17 24a6 6 0 1 1-12 0 6 6 0 0 1 12 0Zm-3 0a3 3 0 1 0-6 0 3 3 0 0 0 6 0Zm-3 20a6 6 0 1 0 0-12 6 6 0 0 0 0 12Zm0-3a3 3 0 1 1 0-6 3 3 0 0 1 0 6Zm31-18.5H20v3h22v-3Zm-22-14h22v3H20v-3Zm22 28H20v3h22v-3Z" fill="#000" />
  </StyledIcon>
))

TaskList.displayName = 'TaskList'

export { TaskList }
