import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const LikeFilled = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="LikeFilled" {...props}>
    <path d="M33.5 15.5V39h-3V13.956a25.636 25.636 0 0 1-.113-.096c-1.413-1.208-3.1-2.857-4.344-4.7a59.428 59.428 0 0 1-2.686-4.39c-1.228-2.214-4.484-2.572-6.039-.396a3.452 3.452 0 0 0-.521 2.915l2.067 7.58a.5.5 0 0 1-.483.631H8A3.5 3.5 0 0 0 4.5 19v4.34c0 .75.241 1.48.688 2.083l9.007 12.16A3.5 3.5 0 0 0 17.008 39H38.5a3.5 3.5 0 0 0 3.5-3.5V19a3.5 3.5 0 0 0-3.5-3.5h-5Z" fill="#000" />
  </StyledIcon>
))

LikeFilled.displayName = 'LikeFilled'

export { LikeFilled }
