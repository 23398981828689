// @ts-nocheck
import { IUIHostGateway } from 'Gateways/Window/IUIHostGateway'
import { injectable } from 'inversify'
import 'reflect-metadata'

@injectable()
export class FakeHostGateway implements IUIHostGateway {
  public getHostLanguage() {
    return 'en'
  }
}
