// @ts-nocheck
import { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { colors, transition, fontSizes, px2rem } from 'app/Styles/Variables'
import { RadioButtonsPresenter } from '.'
import { KeyCodeLookup } from 'app/Shared/Constants/Keycodes'
import { HelpText } from 'Shared/Components/Molecules'
import ReactHtmlParser from 'react-html-parser'

interface IRadioProps {
  isVertical?: boolean
  selected?: boolean
  disabled?: boolean
}

interface IGroupLabel {
  isFocused: boolean
  disabled?: boolean
}

interface IRadioGroup {
  isFocused: boolean
}

const RadioGroup = styled.div<IRadioGroup>`
  outline: none;
  color: ${({ isFocused }) => (isFocused ? colors.primary : colors.textLight)};
  border-bottom: ${({ isFocused }) =>
    `${px2rem(1)} dashed ${isFocused ? colors.primary : colors.primaryGreyHoverBackgroundColor}`};
`

const GroupLabel = styled.label`
  font-size: ${fontSizes.sm};
  font-weight: 500;
  color: ${(props: IGroupLabel) =>
    props.disabled ? colors.textDisabled : props.isFocused ? colors.primary : colors.textLight};
`

const RadioEntries = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: ${(props: IRadioProps) => (props.isVertical ? 'column' : 'row')};
`
interface IRadioEntryProps {
  margin?: string
}

const RadioEntryWrapper = styled.div`
  position: relative;
  width: 90%;
`

const RadioEntry = styled.div`
  display: flex;
  flex-direction: 'row';
  align-items: center;
  margin: ${(props: IRadioEntryProps) => (props.margin ? props.margin : `${px2rem(6)}`)};
  cursor: pointer;
`

const EntryLabel = styled.label`
  cursor: pointer;
  padding-left: ${px2rem(6)};
  vertical-align: middle;
  color: ${(props: IRadioProps) =>
    props.disabled ? colors.primaryGrey : props.selected ? colors.text : colors.textLight};
`

const RadioCircle = styled.div`
  width: 16px;
  height: 16px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-radius: 50%;
  border-width: ${px2rem(2)};
  border-style: solid;
  border-color: ${(props: IRadioProps) =>
    props.disabled ? colors.primaryGrey : props.selected ? colors.primary : colors.text};
`

const RadioDot = styled.div`
  box-sizing: border-box;
  background-color: ${(props: IRadioProps) => (props.disabled ? colors.primaryGrey : colors.primary)};
  border-radius: 50%;
  width: ${px2rem(16)};
  height: ${px2rem(16)};
  position: absolute;
  top: ${px2rem(-2)};
  left: ${px2rem(-2)};
  transform: ${(props: IRadioProps) => (props.selected ? 'scale(0.5)' : 'scale(0)')};
  ${transition('fast', undefined)};
`

interface ICustomOption {
  [key: string]: {
    renderOption: () => ReactNode
    renderOptionData: (isSelected: boolean) => ReactNode
  }
}

interface IRadioButtonsProps {
  presenter: RadioButtonsPresenter
  customOption?: ICustomOption
  className?: string
  radioEntryExtraStyles?: CSSProperties
}

export const RadioButtons = observer<IRadioButtonsProps>(
  ({ presenter, customOption, className, radioEntryExtraStyles }) => {
    const {
      onClick,
      options,
      selectedOption,
      label,
      isVertical,
      focusOnLoad,
      disabled,
      isOptionDisabled,
      helpText,
      helpTextToolTipPosition
    } = presenter
    const [isFocused, setIsFocused] = useState(false)
    const parsedHelpTxt = ReactHtmlParser(helpText)
    const helpTextRender = helpText ? <HelpText text={parsedHelpTxt} toolTipPosition={helpTextToolTipPosition} /> : null
    const ref = useRef()
    useEffect(() => {
      if (focusOnLoad) {
        setIsFocused(true)
        if (ref && ref.current) {
          // @ts-ignore
          ref.current.focus()
        }
      }
    }, [])

    const handleKeyDown = key => {
      if (key.defaultPrevented) return

      const actionKeys = [KeyCodeLookup.left, KeyCodeLookup.right, KeyCodeLookup.up, KeyCodeLookup.down]

      if (actionKeys.indexOf(key.keyCode) === -1) return

      const reverseKeys = [KeyCodeLookup.left, KeyCodeLookup.up]
      const orderedOptions = reverseKeys.indexOf(key.keyCode) !== -1 ? options.slice().reverse() : options

      const index = orderedOptions.indexOf(selectedOption)
      const targetOption = orderedOptions[index + 1] || orderedOptions[0]

      onClick(targetOption)
    }

    const focusHandler = () => {
      setIsFocused(true)
    }

    const blurHandler = () => {
      setIsFocused(false)
    }

    return (
      <RadioGroup
        onFocus={focusHandler}
        role="radiogroup"
        onBlur={blurHandler}
        tabIndex={0}
        onKeyDown={handleKeyDown}
        isFocused={isFocused}
        ref={ref}
        className={className}
      >
        {label && (
          <GroupLabel isFocused={isFocused} disabled={disabled}>
            {label}
          </GroupLabel>
        )}
        <RadioEntries isVertical={isVertical}>
          {options.map((option: string) => {
            const optionDisabled = isOptionDisabled(option)
            const selected = option === selectedOption
            const clickHandler = () => {
              if (optionDisabled) return
              onClick(option)
            }

            return (
              <RadioEntryWrapper key={option}>
                <RadioEntry role="radio" onClick={clickHandler} style={radioEntryExtraStyles}>
                  <RadioCircle selected={selected} disabled={optionDisabled}>
                    <RadioDot selected={selected} disabled={optionDisabled} />
                  </RadioCircle>
                  <EntryLabel selected={selected} disabled={optionDisabled}>
                    {customOption ? customOption[option]?.renderOption() : option}
                  </EntryLabel>
                </RadioEntry>
                {customOption && customOption[option]?.renderOptionData(selected)}
              </RadioEntryWrapper>
            )
          })}
          <HelpContainer>{helpTextRender}</HelpContainer>
        </RadioEntries>
      </RadioGroup>
    )
  }
)

const HelpContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`
