// @ts-nocheck
import styled from 'styled-components'
import { Button } from 'Components/Molecules'
import { ExportUsersModalPresenter } from './ExportUsersModalPresenter'
import { Heading, RadioButtons } from 'Components/Atoms'
import { px2rem } from 'app/Styles/Variables'

const Wrapper = styled.div`
  padding: ${px2rem(20)};
`

const RadioWrapper = styled.div`
  padding-top: ${px2rem(20)};
`

const ButtonContainer = styled.div`
  padding-top: ${px2rem(20)};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

interface IExportUsersModal {
  presenter: ExportUsersModalPresenter
}

export const ExportUsersModal = ({ presenter }: IExportUsersModal) => {
  const { language, cancelButtonPresenter, exportButtonPresenter, exportTypeRadioPresenter } = presenter

  return (
    <Wrapper>
      <Heading>{language.get('common:exportModal:title')}</Heading>
      <RadioWrapper>
        <RadioButtons presenter={exportTypeRadioPresenter} />
      </RadioWrapper>
      <ButtonContainer>
        <Button presenter={cancelButtonPresenter} />
        <Button presenter={exportButtonPresenter} />
      </ButtonContainer>
    </Wrapper>
  )
}
