// @ts-nocheck
import { createContext, useContext } from 'react'

const InversifyContext = createContext({ container: null })

/** @deprecated this ContainerProvider is deprecated as the container should only part of the legacy react
 * application (single-spa). Components should rely on globally available contexts instead and we will
 * remove this provider in the future. */
export const ContainerProvider = props => {
  return <InversifyContext.Provider value={{ container: props.container }}>{props.children}</InversifyContext.Provider>
}

/** @deprecated use hooks that do not rely on the container to be available */
export function useInjection(identifier, throwIfNoContainer = true) {
  const { container } = useContext(InversifyContext)

  if (!container) {
    if (throwIfNoContainer) {
      throw new Error('There is no container available, you must use ContainerProvider and set the container prop.')
    }

    return undefined
  }

  return container.get(identifier)
}

export function withInjection(identifiers) {
  return Component => {
    return props => {
      const { container } = useContext(InversifyContext)
      if (!container) {
        throw new Error('There is no container available, you must use ContainerProvider and set the container prop.')
      }

      let finalProps = { ...props }
      for (const [key, value] of Object.entries(identifiers)) {
        finalProps[key] = container.get(value)
      }

      return <Component {...finalProps} />
    }
  }
}
