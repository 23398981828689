import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const PersonAdd = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="PersonAdd" {...props}>
    <path d="M17 23a7 7 0 1 0 0-14 7 7 0 0 0 0 14Zm0-3a4 4 0 1 1 0-8 4 4 0 0 1 0 8Zm0 6c6.29 0 11.45 4.84 11.959 11 .027.33.041.663.041 1a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2c0-.337.014-.67.041-1C5.55 30.84 10.71 26 17 26Zm0 3a9.001 9.001 0 0 0-8.945 8h17.89A9 9 0 0 0 17 29Zm20-6h7v3h-7v7h-3v-7h-7v-3h7v-7h3v7Z" fill="#000" />
  </StyledIcon>
))

PersonAdd.displayName = 'PersonAdd'

export { PersonAdd }
