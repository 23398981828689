// @ts-nocheck
import { observable, action } from 'mobx'
import { TToolTipPosition } from 'Shared/Components/Molecules/ToolTip/TToolTipPosition'

export class RadioButtonsPresenter {
  @observable
  public options: string[] = []

  @observable
  public selectedOption: string

  @observable
  public label: string

  @observable
  public helpText: string

  @observable
  public helpTextToolTipPosition: TToolTipPosition = 'left center'

  @observable
  public isVertical: boolean

  @observable
  public isDirty = false

  @observable
  public focusOnLoad = false

  @observable
  public disabled = false

  @observable
  public disabledOptions: string[] = []

  @observable
  private middleware = null

  constructor(options: string[], selectedOption = options[0]) {
    this.options = options
    this.selectedOption = selectedOption
    this.isVertical = false
  }

  public isOptionDisabled = (option: string): boolean => {
    if (this.disabled) return true

    return this.disabledOptions.includes(option)
  }

  @action
  public withLabel = (label: string) => {
    this.label = label
    return this
  }

  @action
  public onClick = (option: string) => {
    if (this.disabled) return
    this.isDirty = true

    let newOption = option

    if (this.middleware) {
      newOption = this.middleware(option)
    }

    this.selectedOption = newOption
  }

  @action
  public withVertical = () => {
    this.isVertical = true
    return this
  }

  @action
  public withFocusOnLoad = (focusOnLoad: boolean) => {
    this.focusOnLoad = focusOnLoad
    return this
  }

  @action
  public withDisabled = (disabled: boolean) => {
    this.disabled = disabled
    return this
  }

  @action
  public withDisabledOptions = (disabledOptions: string[]) => {
    this.disabled = false
    this.disabledOptions = disabledOptions
    return this
  }

  @action
  public withMiddleware = middlewareFunction => {
    this.middleware = middlewareFunction
    return this
  }

  @action
  public withOptions = (options: string[]) => {
    this.options = options
    return this
  }

  @action
  public withSelectedOption = (selectedOption: string) => {
    this.selectedOption = selectedOption
    return this
  }

  @action
  public withHelpText = (helpText: string) => {
    this.helpText = helpText
    return this
  }

  @action
  public withHelpTextToolTipPosition = (position: TToolTipPosition) => {
    this.helpTextToolTipPosition = position
    return this
  }

  @action
  public reset = () => {
    this.isDirty = false
    this.selectedOption = this.options[0]
  }
}
