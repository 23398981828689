import React from 'react'
import ReactDOMClient from 'react-dom/client'
import singleSpaReact from 'single-spa-react'

import { ReactApp } from '../react-app'

export default singleSpaReact({
  React,
  ReactDOMClient,
  rootComponent: ReactApp,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  domElementGetter: () => document.getElementById('cutover-react')!
})
