import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const TaskTypeBox100 = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="TaskTypeBox100" {...props}>
    <path d="M36 32.373V34a2 2 0 0 1-2 2H14a2 2 0 0 1-2-2v-1.627H8V34a6 6 0 0 0 6 6h20a6 6 0 0 0 6-6v-1.627h-4Zm0-16h4V14a6 6 0 0 0-6-6H14a6 6 0 0 0-6 6v2.373h4V14a2 2 0 0 1 2-2h20a2 2 0 0 1 2 2v2.373Z" fill="#000" />
  </StyledIcon>
))

TaskTypeBox100.displayName = 'TaskTypeBox100'

export { TaskTypeBox100 }
