import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Streams = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Streams" {...props}>
    <path d="M34 13.811a6 6 0 1 0-3 0v10.378a6 6 0 1 0 3 0V13.811ZM32.5 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6Zm3 19a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm-14-15a6.003 6.003 0 0 1-4.5 5.811v13.378a6 6 0 1 1-3 0V20.811A6 6 0 1 1 21.5 15Zm-3 0a3 3 0 1 0-6 0 3 3 0 0 0 6 0Zm0 25a3 3 0 1 0-6 0 3 3 0 0 0 6 0Z" fill="#000" />
  </StyledIcon>
))

Streams.displayName = 'Streams'

export { Streams }
