import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const TaskTypeHexagon = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="TaskTypeHexagon" {...props}>
    <path d="M26 7.464 37.32 14a4 4 0 0 1 2 3.464v13.072a4 4 0 0 1-2 3.464L26 40.536a4 4 0 0 1-4 0L10.68 34a4 4 0 0 1-2-3.464V17.464a4 4 0 0 1 2-3.464L22 7.464a4 4 0 0 1 4 0Zm1-1.732a6 6 0 0 0-6 0L9.68 12.268a6 6 0 0 0-3 5.196v13.072a6 6 0 0 0 3 5.196L21 42.268a6 6 0 0 0 6 0l11.32-6.536a6 6 0 0 0 3-5.196V17.464a6 6 0 0 0-3-5.196L27 5.732Z" fill="#000" />
  </StyledIcon>
))

TaskTypeHexagon.displayName = 'TaskTypeHexagon'

export { TaskTypeHexagon }
