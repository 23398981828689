import { useEffect, useState } from 'react'

import { useInitialMount } from '@cutover/react-ui'
import { UserSelect, UserSelectProps } from '../form/user-select'
import { CustomFieldUser, User } from 'main/services/queries/types'

export type CustomFieldUserSelectProps = Omit<UserSelectProps, 'value' | 'onChange'> & {
  value?: number[]
  customFieldUsers?: CustomFieldUser[]
  onChange?: (value: number[]) => void
}

/**
 * A thin wrapper over user select. Custom field users have initial values that are just ids. In order to populate the
 * user select with the correct initial value, we get all the possible custom field users (all users that are assigned
 * via custom field user select form type to any runbook within an account) from the account meta and match on ids.
 */
export const CustomFieldUserSelect = ({
  value,
  customFieldUsers,
  onChange,
  ...userSelectProps
}: CustomFieldUserSelectProps) => {
  const isInitialMount = useInitialMount()
  const [usersValue, setUsersValue] = useState<User[]>(() =>
    (customFieldUsers as User[]).filter(user => value?.includes(user.id))
  )

  useEffect(() => {
    if (isInitialMount) return

    onChange?.(usersValue.map(user => user.id))
  }, [usersValue])

  return (
    <UserSelect
      {...userSelectProps}
      value={usersValue}
      onChange={(onChangeValue?: User[]) => setUsersValue(onChangeValue ?? [])}
    />
  )
}
