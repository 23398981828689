import { useState } from 'react'
import { Heading } from 'grommet'
import styled from 'styled-components'

import { Box, Button } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { CreateNewSystemParametersModal } from '../modals/create-new-system-parameter-modal'

export const SystemParametersHeader = () => {
  const [isCreateModalOpen, setCreateModalOpen] = useState(false)
  const { t } = useLanguage('systemParameters')

  const handleClickCreate = () => {
    setCreateModalOpen(true)
  }

  return (
    <Box direction="row" width="100%">
      <Box flex="grow" justify="center">
        <SystemParamsTitle responsive={false} margin={{ left: 'xsmall', right: 'none', vertical: 'none' }} level="1">
          {t('header.title')}
        </SystemParamsTitle>
      </Box>
      <Box>
        <Button icon="add" primary label={t('header.addButton.label')} onClick={handleClickCreate} />
      </Box>
      <CreateNewSystemParametersModal open={isCreateModalOpen} setOpen={setCreateModalOpen} />
    </Box>
  )
}

const SystemParamsTitle = styled(Heading)`
  font-size: 20px;
  line-height: 1.2;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
`
