import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Play = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Play" {...props}>
    <path d="M38.509 24 16 11.004v25.99h.001v.001L38.51 24.001v-.002Zm1.5-2.599c2 1.155 2 4.043 0 5.198L17.5 39.593c-2 1.155-4.501-.288-4.501-2.598v-25.99c0-2.31 2.5-3.754 4.501-2.598L40.01 21.4Z" fill="#000" />
  </StyledIcon>
))

Play.displayName = 'Play'

export { Play }
