// @ts-nocheck
import { observable, computed, action } from 'mobx'
import { container } from 'app/Config/IOC'
import { QueryStringBuilder } from 'Shared/Helpers/QueryStringBuilder'
import { PermissableRepository } from 'Shared/Repositories/PermissableRepository'
import { ISortParams } from 'Shared/Components/Organisms'

export abstract class GenericListRepository extends PermissableRepository {
  protected queryStringBuilder: QueryStringBuilder = container.get(QueryStringBuilder)

  @observable
  public sortParams: ISortParams

  @observable
  public listTotal: number

  @observable
  public filteredListTotal: number

  @observable
  public offSet: number = 0

  @observable
  public useTimeCache = false

  @observable
  public cacheExpiry: number = 300

  protected requestedBatches: number[] = []

  protected url: string = ''

  protected lastUpdatedAt: Date

  @computed
  protected get apiUrl() {
    return this.url + this.queryStringBuilder.getQueryString(this.offSet, this.sortParams)
  }

  public abstract clear: () => void

  constructor(key: string) {
    super(key)
  }

  @action
  public preLoad = (args: { [key: string]: any }): string => {
    this.offSet = args && args.offSet != null ? args.offSet : 0
    if (args && args.sortParams != null) this.sortParams = args.sortParams

    this.registerBatchRequest()
    return this.apiUrl
  }

  public isBatchRequested = (offSet: number): boolean => {
    if (!this.requestedBatches.length) {
      return false
    }

    return this.requestedBatches.includes(offSet)
  }

  protected registerBatchRequest = () => {
    // Reset loaded batches when a new load is started
    if (this.offSet === 0) {
      this.requestedBatches = []
    }

    this.requestedBatches.push(this.offSet)
  }

  @computed
  protected get shouldMakeRequest(): boolean {
    return (
      !this.useTimeCache ||
      !this.lastUpdatedAt ||
      this.lastUpdatedAt.getTime() + this.cacheExpiry * 1000 < new Date().getTime()
    )
  }

  @action
  public loadAll = async () => {
    if (!this.shouldMakeRequest) {
      return
    }

    this.loading = true
    const rawData = await this.serviceGateway.get(this.url)
    if (rawData.success) {
      this.lastUpdatedAt = new Date()

      await this.postLoad(rawData)
    }
    this.loading = false
  }

  @action
  public clearInherited = () => {
    this.clearPermissables()
    this.offSet = 0
    this.sortParams = null
  }
}
