import { generateColor } from '../node-map-utils'
import { PaintNode } from '../node-map-types'

export const paintShadowNode = ({ ctx, node, state }: PaintNode) => {
  const uniqueColor = generateColor(state)

  ctx.fillStyle = uniqueColor
  ctx.strokeStyle = uniqueColor
  state.colorToObjMap[uniqueColor] = { type: 'node', id: node.id }

  const baseSize = state.units.square * 2.5 * node.scaleMod
  ctx.beginPath()
  ctx.rect(node.x - baseSize, node.y - baseSize, baseSize * 2, baseSize * 2)
  ctx.fill()
}
