import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const FixedStart = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="FixedStart" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M20 5h4v20.417l10.593 6.908-2.186 3.35L20 27.583V5Z" fill="#000" />
  </StyledIcon>
))

FixedStart.displayName = 'FixedStart'

export { FixedStart }
