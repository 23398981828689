// @ts-nocheck
import { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { px2rem, colors } from 'app/Styles/Variables'

interface IStyledSvgProps {
  color?: string
  width: number
  height: number
}

const StyledSvg = styled.svg`
  width: ${(props: IStyledSvgProps) => px2rem(props.width)};
  height: ${(props: IStyledSvgProps) => px2rem(props.height)};
  fill: ${(props: IStyledSvgProps) => (props.color ? props.color : colors.black)};
  stroke: ${(props: IStyledSvgProps) => (props.color ? props.color : colors.black)};
`

const ObjectWrapper = styled.div`
  width: ${(props: IStyledSvgProps) => px2rem(props.width)};
  height: ${(props: IStyledSvgProps) => px2rem(props.height)};
  line-height: 0;

  svg {
    width: ${(props: IStyledSvgProps) => px2rem(props.width)};
    height: ${(props: IStyledSvgProps) => px2rem(props.height)};

    path {
      fill: ${(props: IStyledSvgProps) => (props.color ? props.color : colors.black)};
      stroke: ${(props: IStyledSvgProps) => (props.color ? props.color : colors.black)};
    }
  }
`

interface ISvgIconProps {
  path: string
  iePath: string
  width?: number
  height?: number
  color?: string
  label?: string
}

export const SvgIcon = (props: ISvgIconProps) => {
  const { path, iePath, width = 18, height = 18, color, label = 'svg icon' } = props
  const isIE = /MSIE \d|Trident.*rv:/.test(window.navigator.userAgent)

  const objectRef = useRef(null)

  useEffect(() => {
    const objectElement =
      iePath && isIE && objectRef && objectRef.current && objectRef.current.getElementsByTagName('object')[0]

    // Get svg, replace object with svg.
    const replaceObjectWithSvg = () => {
      if (objectElement) {
        // To be able to change color of svg in IE, we need to have inline svg.
        const svgElement =
          objectElement && objectElement.contentDocument && objectElement.contentDocument.getElementsByTagName('svg')[0]
        if (svgElement) {
          objectElement.parentNode.replaceChild(svgElement, objectElement)
          objectElement.removeEventListener('load', replaceObjectWithSvg)
        }
      }
    }

    if (objectElement) {
      // Use this function in case object was already loaded
      replaceObjectWithSvg()
      // Wait for object loads svg data
      objectElement.addEventListener('load', replaceObjectWithSvg)
    }

    return () => {
      if (objectElement) {
        objectElement.removeEventListener('load', replaceObjectWithSvg)
      }
    }
  }, [])

  if (!path || !iePath) {
    return null
  }

  const content = isIE ? (
    <ObjectWrapper ref={objectRef} width={width} height={height} color={color} role="img" aria-label={label}>
      <object data={iePath} type="image/svg+xml" aria-label={label} />
    </ObjectWrapper>
  ) : (
    <StyledSvg width={width} height={height} color={color} role="img" aria-label={label}>
      <use href={path} />
    </StyledSvg>
  )

  return content
}
