import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const RunbookCancel = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="RunbookCancel" {...props}>
    <path d="M14 9h20a5 5 0 0 1 5 5v20a5 5 0 0 1-5 5H14a5 5 0 0 1-5-5v-1H6v1a8 8 0 0 0 8 8h20a8 8 0 0 0 8-8V14a8 8 0 0 0-8-8H14a8 8 0 0 0-8 8v1h3v-1a5 5 0 0 1 5-5Z" fill="#000" /><path d="M3 19h9v3H3v-3Zm9 7H3v3h9v-3Zm19.071-6.95-2.121-2.12L24 21.879l-4.95-4.95-2.121 2.121 4.95 4.95-4.95 4.95 2.121 2.121 4.95-4.95 4.95 4.95 2.121-2.121-4.95-4.95 4.95-4.95Z" fill="#000" />
  </StyledIcon>
))

RunbookCancel.displayName = 'RunbookCancel'

export { RunbookCancel }
