import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Paragraph = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Paragraph" {...props}>
    <path d="M42 6H18v3h24V6ZM30 42v-3H6v3h24ZM6 17h36v3H6v-3Zm36 11H6v3h36v-3Z" fill="#000" />
  </StyledIcon>
))

Paragraph.displayName = 'Paragraph'

export { Paragraph }
