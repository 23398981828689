// @ts-nocheck
import { useState } from 'react'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { colors, px2rem } from 'app/Styles/Variables'
import { OptionPresenter } from './OptionPresenter'
import { Checkbox, ColorPicker, TextInput } from 'Components/Molecules'
import { Icon } from 'Components/Atoms'
import { OptionInputWrapper } from './OptionInputWrapper'
import { OptionDetailsWrapper } from './OptionDetailsWrapper'
import { colorToHex } from 'Shared/HooksAndPresenters/ColorToHex'

interface IWrapper {
  archived: boolean
}

const Wrapper = styled.div<IWrapper>`
  position: relative;
  width: 100%;
  ${({ archived }) => archived && 'opacity: 0.5;'}
`

const InputFlexItem = styled.div``
const DetailsItem = styled.div``

interface IOptionProps {
  presenter: OptionPresenter
}

export const Option = observer<IOptionProps>(({ presenter }) => {
  const {
    textPresenter,
    colorPickerPresenter,
    checkboxPresenter,
    fieldOptionsPresenter,
    guid,
    color,
    disabled,
    archived,
    restorable
  } = presenter

  const [iconColor, setIconColor] = useState(colors.secondaryGrey)
  const [showDetails, setShowDetails] = useState(false)
  const currentOption = fieldOptionsPresenter.findOption(guid)

  if (currentOption) {
    textPresenter.value = currentOption.value
  }

  colorToHex(color, setIconColor)

  const removeOption = () => {
    if (disabled) return
    restorable ? fieldOptionsPresenter.toggleArchiveOption(guid) : fieldOptionsPresenter.removeOption(guid)
  }

  const toggleDetails = () => {
    if (disabled) return
    setShowDetails(!showDetails)
  }

  return (
    <Wrapper archived={archived}>
      <OptionInputWrapper disabled={disabled} settingsColor={color}>
        <InputFlexItem>
          <TextInput presenter={textPresenter} extraStyles={{ marginTop: 0, borderBottom: 'none' }} />
        </InputFlexItem>
        <InputFlexItem style={{ marginRight: px2rem(-16) }}>
          <Icon name="settings" color={iconColor} onClick={toggleDetails} />
        </InputFlexItem>
        <InputFlexItem>
          {archived ? (
            <Icon name="undo" color={colors.secondaryGrey} onClick={removeOption} />
          ) : (
            <Icon name="close" color={colors.secondaryGrey} onClick={removeOption} />
          )}
        </InputFlexItem>
      </OptionInputWrapper>
      <OptionDetailsWrapper show={showDetails}>
        <DetailsItem>
          <ColorPicker presenter={colorPickerPresenter} textInputStyle={{ marginTop: px2rem(12) }} />
        </DetailsItem>
        <DetailsItem>
          <Checkbox presenter={checkboxPresenter} />
        </DetailsItem>
      </OptionDetailsWrapper>
    </Wrapper>
  )
})
