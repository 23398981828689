// @ts-nocheck
export const checkValueForAbsenceOfWhiteSpace = (value: string): boolean => {
  // checking for any kind of whitespaces
  return !/\s/.test(value)
}

export const emailValidator = (value: string): boolean => {
  // Note: In case of adaption the RegExp, please make sure to apply the changes
  // to the user.rb and login_controller.js files as well.
  const emailRegExp = new RegExp(/^[^@\s]+@([^@\s]+\.)+[^@\W]+$/)
  return emailRegExp.test(value)
}

export const valueExistsWhenTrimmed = (value: string): boolean => {
  return value.trim().length > 0
}
