// @ts-nocheck
import { IRole } from 'Shared/Entities/Types/RoleType/IRole'
import { startCase } from 'lodash'

export const getRolesForService = (roles: IRole[], userId?: number, existingRoles?: IRole[]) => {
  const apiRoles = []

  roles.forEach((role: IRole) => {
    const payloadRole = {
      role_type_id: role.roleType.id,
      resource_type: role.resourceType,
      resource_id: role.resourceId,
      subject_type: startCase(role.displayType),
      expires_at: role.expiresAt
    }

    if (existingRoles) {
      const foundExistingRoles = existingRoles.filter(existingRole => {
        if (!existingRole.resourceId && !role.resourceId) {
          return existingRole.roleType.id === role.roleType.id
        } else {
          return existingRole.roleType.id === role.roleType.id && existingRole.resourceId === role.resourceId
        }
      })

      if (foundExistingRoles.length > 0) {
        // @ts-ignore
        payloadRole.id = foundExistingRoles[0].roleId
      }
    }

    if (userId) {
      // @ts-ignore
      payloadRole.subject_id = userId
    }

    apiRoles.push(payloadRole)
  })

  return apiRoles
}

export const getRolesFromServiceRoleTypes = (roleTypes: any[]): IRole[] => {
  const serverRoles = roleTypes.filter(roleType => {
    return roleType.roles && roleType.roles.length > 0
  })

  const compiledRoles = serverRoles.map(serverRole => {
    const roleTypePm = {
      contextTypeId: serverRole.context_type_id,
      description: serverRole.description,
      enabled: true,
      id: serverRole.id,
      name: serverRole.name,
      order: serverRole.order
    }

    return serverRole.roles.map(role => {
      const expiry = role.expires_at ? new Date(role.expires_at * 1000) : null

      return {
        roleId: role.id,
        roleType: roleTypePm,
        resourceId: role.resource_id,
        displayType: 'User',
        expiresAt: expiry
      }
    })
  })

  // @ts-ignore
  return compiledRoles.flat()
}
