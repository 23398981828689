import { FocusEventHandler, KeyboardEventHandler, useEffect, useRef, useState } from 'react'
import { useClickAway } from 'react-use'
import styled from 'styled-components/macro'

import {
  LegacyBox as Box,
  colors as colorConsts,
  LegacyFlex as Flex,
  Icon,
  px2rem,
  LegacyText as Text
} from '@cutover/react-ui'
import { KeyCodeLookup } from 'app/Shared/Constants/Keycodes'

export type ColorPickerProps = {
  colors: string[]
  value?: string
  onSelect?: (value: string) => void
  onFocus?: FocusEventHandler<HTMLDivElement>
  onBlur?: FocusEventHandler<HTMLDivElement>
}

export const ColorPicker = ({ colors, value, onSelect, onBlur, onFocus }: ColorPickerProps) => {
  const [color, setColor] = useState(value)
  const [open, setOpen] = useState(false)
  const [hoveredColorIndex, setHoveredColorIndex] = useState<number | null>(null)

  const ref = useRef<HTMLDivElement>(null)

  const togglePalette = () => {
    setOpen(!open)
  }

  const selectColor = (color: string) => {
    setColor(color)
    onSelect?.(color)
  }

  useEffect(() => {
    setColor(value)
  }, [value])

  useClickAway(ref, () => closePalette())

  const closePalette = () => {
    if (hoveredColorIndex !== null) {
      setHoveredColorIndex(null)
    }

    if (open) {
      setOpen(false)
    }
  }

  const onColorPickerKeyDown: KeyboardEventHandler<HTMLDivElement> = key => {
    if (key.keyCode === KeyCodeLookup.down) {
      if (!open) {
        setOpen(true)
        setHoveredColorIndex(0)
      }
    }

    if (key.keyCode === KeyCodeLookup.up) {
      if (open) {
        setOpen(false)
      }
    }

    if (key.keyCode === KeyCodeLookup.right) {
      selectColorOnArrowNavigation('right')
    }

    if (key.keyCode === KeyCodeLookup.left) {
      selectColorOnArrowNavigation('left')
    }

    if (key.keyCode === KeyCodeLookup.enter) {
      const selectedColor = colors[hoveredColorIndex ?? 0]

      if (selectedColor) {
        selectColor(selectedColor)
      }
    }

    if (key.keyCode === KeyCodeLookup.escape) {
      const selectedColor = colors[hoveredColorIndex ?? 0]
      if (selectedColor) {
        selectColor(selectedColor)
      }
      closePalette()
    }
  }

  const selectColorOnArrowNavigation = (direction: 'left' | 'right') => {
    const index = hoveredColorIndex ?? 0
    const nextHoveredColorIndex =
      direction === 'right' ? Math.min(index + 1, colors.length - 1) : Math.max(index - 1, 0)

    setHoveredColorIndex(nextHoveredColorIndex)
  }

  return (
    <Box
      css="position: relative; display: inline-box"
      ref={ref}
      onFocus={onFocus}
      onBlur={onBlur}
      tabIndex={0}
      onKeyDown={onColorPickerKeyDown}
    >
      <Flex inline onClick={togglePalette} gap={8} css="cursor: pointer;">
        <Icon icon="format-color-fill" color={color || colorConsts.primaryFocus} />
        {color ? <Text color={colorConsts.text}>{color}</Text> : null}
      </Flex>
      {open ? (
        <ColorPalette flexWrap="wrap" data-testid="color-pallete">
          {colors.map((color, index) => (
            <Box
              key={color}
              tabIndex={0}
              onClick={() => selectColor(color)}
              css={`
                background-color: ${color};
                width: ${px2rem(16)};
                height: ${px2rem(16)};
                cursor: pointer;
                border: 2px solid ${hoveredColorIndex === index ? colorConsts.primaryGreyHover : color};
              `}
            />
          ))}
        </ColorPalette>
      ) : null}
    </Box>
  )
}

const ColorPalette = styled(Flex)`
  position: absolute;
  left: 0;
  top: ${px2rem(20)};
  background: ${colorConsts.white};
  box-shadow: 0 0.375rem 1.5rem 0 rgb(151 164 170 / 60%);
  border-radius: ${px2rem(8)};
  padding: ${px2rem(10)};
  outline: none;
  width: ${px2rem(148)};
  min-height: ${px2rem(16)};
  z-index: 999;
`
