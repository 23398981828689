import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Help = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Help" {...props}>
    <path d="M36.5 17.87c0-3.758-1.591-6.653-4.002-8.575C30.125 7.403 27.028 6.5 24 6.5c-3.029 0-6.125.903-8.498 2.795-2.41 1.922-4.002 4.817-4.002 8.574v1.5h3v-1.5c0-2.822 1.159-4.862 2.873-6.229C19.125 10.242 21.529 9.5 24 9.5s4.875.742 6.627 2.14c1.714 1.367 2.873 3.407 2.873 6.23 0 3.378-2.047 4.882-4.856 6.833l-.125.086C25.847 26.644 22.5 28.968 22.5 34v1.5h3V34c0-3.379 2.047-4.883 4.856-6.833l.125-.087c2.672-1.855 6.019-4.178 6.019-9.21ZM24 43a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z" fill="#000" />
  </StyledIcon>
))

Help.displayName = 'Help'

export { Help }
