import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const CheckboxDeselect = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="CheckboxDeselect" {...props}>
    <path d="M6 14a8 8 0 0 1 8-8h20a8 8 0 0 1 8 8v20a8 8 0 0 1-8 8H14a8 8 0 0 1-8-8V14Zm28 9H14v3h20v-3Z" fill="#000" />
  </StyledIcon>
))

CheckboxDeselect.displayName = 'CheckboxDeselect'

export { CheckboxDeselect }
