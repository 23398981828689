import { useState } from 'react'

import { Box, TextInput, UiProvider } from '@cutover/react-ui'
import { Component, getSettings } from './component-data'

export type ParticularTaskAverageDurationNameFilterConnectedProps = {
  accountId?: number
  id?: number
  componentId?: number
  onEvent: (payload: any) => void
  components: Component[]
}

type NameFilter = {
  name: string
}

export function ParticularTaskAverageDurationNameFilterConnected({
  id,
  componentId,
  components,
  onEvent
}: ParticularTaskAverageDurationNameFilterConnectedProps) {
  const settings = getSettings<NameFilter>({ componentId: id, components })

  const handleNameChange = (name: string) => {
    onEvent({
      settings: {
        id,
        componentId,
        name
      }
    })
  }

  return (
    <UiProvider>
      <ParticularTaskAverageDurationNameFilter name={settings?.name ?? ''} onChange={handleNameChange} />
    </UiProvider>
  )
}

export type ParticularTaskAverageDurationNameFilterProps = {
  name: string
  onChange: (value: string) => void
}

export function ParticularTaskAverageDurationNameFilter({
  name: initialName,
  onChange
}: ParticularTaskAverageDurationNameFilterProps) {
  const [name, setName] = useState(initialName)

  return (
    <Box flex={{ grow: 1 }}>
      <TextInput
        label="Name"
        value={name}
        onChange={e => {
          setName(e.target.value)
          onChange(e.target.value)
        }}
      />
    </Box>
  )
}
