import { ReactNode } from 'react'
import { PolymorphicComponent } from 'react-polymorphic-box'
import styled from 'styled-components/macro'

import { themeColor } from '../../theme'

export type HeadingProps = {
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  weight?: string | number
  truncate?: boolean
  color?: string
  children: ReactNode
}

const defaultElement = 'h2'

/**
 * @deprecated Use grommet heading component (https://v2.grommet.io/heading)
 */
export const Heading: PolymorphicComponent<HeadingProps, typeof defaultElement> = styled(defaultElement)<HeadingProps>`
  margin: 0;
  ${({ weight }: HeadingProps) => weight && `font-weight: ${weight};`}
  color: ${({ color }) => color ?? themeColor('text')};
  ${({ truncate }: HeadingProps) =>
    truncate &&
    `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
`
