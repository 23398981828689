import { ReactNode } from 'react'

import { Button } from '../../button'
import { SaveEditingFormModal } from '../../index'
import { Text } from '../../typography'
import { Box } from '../box'
import { RightPanel } from '../right-panel'

export type EditPanelProps = {
  loading?: boolean
  isDirty: boolean
  isError?: boolean
  title: string
  onReset: () => void
  onSubmit: () => void
  onBack?: () => void
  onClose?: () => void
  isSubmitting: boolean
  submittingText?: string
  headerItems?: ReactNode[]
  footer?: ReactNode
  children: ReactNode
}

export const EditPanel = ({
  loading,
  isDirty,
  isError,
  title,
  onReset,
  onSubmit,
  onBack,
  onClose,
  isSubmitting,
  submittingText = 'saving…',
  headerItems = [],
  footer,
  children
}: EditPanelProps) => {
  return (
    <SaveEditingFormModal
      isDirty={isDirty}
      isError={isError}
      onReset={onReset}
      isSubmitting={isSubmitting}
      onSubmit={onSubmit}
      render={({ ref }) => {
        const textItem = isSubmitting ? (
          <Text size="18px" color="text-light">
            {submittingText}
          </Text>
        ) : isDirty ? (
          <Box direction="row" gap="xsmall" flex={false}>
            <Button
              tertiary
              label="discard" // needs translate
              onClick={onReset}
            />
            <Button
              primary
              label="save" // needs translate
              onClick={onSubmit}
            />
          </Box>
        ) : null

        const items = textItem ? [textItem] : headerItems

        return (
          <RightPanel
            ref={ref}
            title={title}
            onBack={onBack}
            onClose={!textItem ? onClose : undefined}
            headerItems={items}
            loading={loading}
            footer={footer}
          >
            {children}
          </RightPanel>
        )
      }}
    />
  )
}
