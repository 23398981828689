const isObject = (value?: unknown): value is Record<string, unknown> =>
  Object.prototype.toString.call(value) === '[object Object]'

export function processRequestData({ data, property }: { data: any; property?: string }) {
  if (!data) {
    return undefined
  }

  if (property) {
    data = {
      [property]: data
    }
  }

  return data
}

export function processResponseData({ data, property }: { data: any; property?: string }) {
  if (!data) {
    return undefined
  }

  if (property) {
    if (!isObject(data)) {
      throw new Error('Unexpected property value specified for a non object response.')
    }

    data = data[property]
  }

  return data
}
