import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Share = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Share" {...props}>
    <path d="M36 18a6 6 0 1 0-5.868-4.743L16.525 20.06a6 6 0 1 0 0 7.88l13.607 6.803a6 6 0 1 0 1.343-2.683l-13.607-6.803a6.026 6.026 0 0 0 0-2.514l13.607-6.803A5.986 5.986 0 0 0 36 18Zm0-3a3 3 0 1 1 0-6 3 3 0 0 1 0 6Zm0 24a3 3 0 1 1 0-6 3 3 0 0 1 0 6ZM15 24a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" fill="#000" />
  </StyledIcon>
))

Share.displayName = 'Share'

export { Share }
