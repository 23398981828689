// @ts-nocheck
import 'reflect-metadata'
import { inject, injectable, postConstruct } from 'inversify'
import { computed, observable, action, observe } from 'mobx'
import { setReactDirty } from 'app/Shared/Helpers/SPASharedStateService'
import { Router } from 'app/Routing/Router'
import { RoutingState } from 'app/Routing/RoutingState'
import { RouteUpdater } from 'app/Routing/RouteUpdater'
import { UsersRepository } from 'app/Repositories/User/UsersRepository'
import { UserEditPresenter } from 'app/Access/Users/UsersPage/UsersEdit/UserEditPresenter'
import { UserPageFilterPanelPresenter } from 'app/Access/Users/UsersPage/UsersPageFilterPanel/UserPageFilterPanelPresenter'
import { UserListPresenter } from './UsersList/UserListPresenter'
import { container } from 'app/Config/IOC'
import { FiltersRepository } from 'app/Filters/FiltersRepository'
import { IGenericFilter } from 'app/Filters/FilterEntities'
import { CurrentPagePresenter } from 'app/Routing/CurrentPage/CurrentPagePresenter'
import { toggleAnimateCutoverLogo } from 'Shared/Helpers/NavLogoAnimation'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'
import { Types } from 'Gateways/Service/Types'

@injectable()
export class UserPagePresenter {
  @inject(UserEditPresenter)
  private userEditPresenter: UserEditPresenter

  @inject(UserPageFilterPanelPresenter)
  private userPageFilterPanelPresenter: UserPageFilterPanelPresenter

  @inject(RoutingState)
  public routingState: RoutingState

  @inject(FiltersRepository)
  private filtersRepository: FiltersRepository

  @inject(UsersRepository)
  private usersRepository: UsersRepository

  @inject(RouteUpdater)
  public routeUpdater: RouteUpdater

  public language: ILanguageService = container.get(Types.ILanguageService)

  @observable
  public usersPageListPresenter: UserListPresenter = new UserListPresenter()

  @observable
  public loading: boolean = false

  @computed
  public get editOpen(): boolean {
    return this.userEditPresenter.mode !== 'panel-closed'
  }

  @computed
  public get editLoading(): boolean {
    return this.userEditPresenter.mode === 'loading'
  }

  @computed
  public get usersDataLoading(): boolean {
    return this.usersRepository.loading || this.loading
  }

  public closePanel = () => {
    this.userEditPresenter.close()
  }

  @computed
  public get filterPanelLoading(): boolean {
    return this.userPageFilterPanelPresenter.loadingFilters
  }

  @computed
  public get angularJSSettingsPanelOpenState(): boolean {
    return container.get(CurrentPagePresenter).angularJSSettingsPanelOpenState
  }

  @postConstruct()
  public init() {
    this.load()
    this.routeUpdater.registerRouteLeaveCallback({ routeId: 'users', callback: this.clear })
    this.routeUpdater.registerRouteEnterCallback({ routeId: 'users', callback: this.load })
  }

  private load = async () => {
    toggleAnimateCutoverLogo(true)
    this.loading = true
    this.setupObservers()
    this.usersPageListPresenter
      .withCheckEditPanelDirtyFunction(this.checkEditPanelIsDirty)
      .withEditPanelCloseFunction(this.closePanel)
    await this.userPageFilterPanelPresenter.load()
    this.filtersRepository.loadSavedFilters()
    this.filtersRepository.setPageFilters(this.filters)
    container.get(Router).setCheckBeforeAction(this.checkEditPanelIsDirty)
    this.usersRepository.loadData({})
    this.usersPageListPresenter.load()
    this.userEditPresenter.load()
    toggleAnimateCutoverLogo(false)
    this.loading = false
  }

  @computed
  public get canCreateUser(): boolean {
    return this.usersRepository.can('create')
  }

  @observable
  private disposers: any[] = []

  private clear = () => {
    this.usersPageListPresenter.clear()
    this.usersRepository.clear()
    this.userEditPresenter.clear()
    this.filtersRepository.clearSavedFilters()
    this.clearDisposers()
  }

  @action
  public clearDisposers = () => {
    if (this.disposers.length === 0) return
    this.disposers.forEach(disposer => disposer())
    this.disposers = []
  }

  @action
  private setupObservers = () => {
    const queryObserverDisposer = observe(this.filtersRepository, 'loadingPath', () => {
      if (
        this.routingState.currentState.routeId === 'users' &&
        !this.filtersRepository.loadingPath &&
        this.filtersRepository.pageFilters.length > 0
      ) {
        this.usersRepository.loadData({})
      }
    })

    this.disposers.push(queryObserverDisposer)

    const formIsDirtyObserverDisposer = observe(this.userEditPresenter, 'formIsDirty', () => {
      setReactDirty(this.userEditPresenter.formIsDirty)
    })

    this.disposers.push(formIsDirtyObserverDisposer)
  }

  public checkEditPanelIsDirty = (): boolean => {
    if (this.userEditPresenter.formIsDirty) {
      return true
    }
    return false
  }

  @computed
  public get filters(): IGenericFilter[] {
    return [
      ...this.userPageFilterPanelPresenter.genericFilters,
      {
        key: { reference: 'query' },
        type: 'string'
      }
    ]
  }

  @observable
  public editPanelClosedCallback = () => {
    this.userEditPresenter.editPanelAfterCloseFunction()
  }
}
