import styled from 'styled-components'

import { duration, Text } from '@cutover/react-ui'
import { RunbookListRunbook } from 'main/services/queries/types'
import { Bullet } from './bullet'

export const DurationDiff = ({ runbook }: { runbook: RunbookListRunbook }) => {
  const { is_template, end_diff, duration_display, template_type, start_display, run_type } = runbook
  const isSnippet = template_type === 'snippet'

  const renderLiveRunbookDiff = () => (
    <>
      <Bullet />
      <DiffText early={end_diff.early} data-testid="diff-text" truncate>
        {durationDiffDisplay(end_diff)}
      </DiffText>
    </>
  )

  const renderTemplateOrSnippetDuration = () => (
    <>
      <Bullet />
      <Text size="small">{duration(duration_display)}</Text>
    </>
  )

  if (!is_template && !isSnippet && !!end_diff && run_type === 'live') {
    return renderLiveRunbookDiff()
  } else if (!!start_display && !!duration_display) {
    return renderTemplateOrSnippetDuration()
  } else {
    return null
  }
}

const durationDiffDisplay = (end_diff: RunbookListRunbook['end_diff']) =>
  end_diff.value.substring(1, end_diff.value.length - 1)

const DiffText = styled(Text).attrs((props: { early: boolean }) => ({
  size: 'small',
  color: props.early ? 'success' : 'warning'
}))<{ early: boolean }>``
