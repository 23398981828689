import { TasksByStageData } from './tasks-by-stage'

export const runbookCompletion = (tasksByStage: TasksByStageData): number => {
  let stages = Object.keys(tasksByStage) as Array<keyof TasksByStageData>

  const totalTasks = stages.reduce((acc, stage) => {
    if (stage === 'late') return acc
    return acc + tasksByStage[stage].length
  }, 0)

  const divisor = totalTasks === 0 ? 1 : totalTasks // doing 0 / 0 if the tasks array is empty will cause a NaN
  const percentComplete = Math.round(
    ((tasksByStage.completed.length + tasksByStage.skipped.length + tasksByStage.abandoned.length) / divisor) * 100
  )

  return percentComplete
}
