import { Editor, Point, Range, Transforms } from 'slate'

import { removeMarksAtSelection } from '../utils'

export const withEmptyReset = (editor: Editor) => {
  const { deleteBackward } = editor

  editor.deleteBackward = unit => {
    const { selection } = editor
    // If press backspace when cursor is at root, reset the current block & remove all marks
    if (selection && Range.isCollapsed(selection)) {
      const rootPoint = { path: [0, 0], offset: 0 }
      const isAtRoot = Point.equals(selection.anchor, rootPoint)

      if (isAtRoot) {
        Transforms.setNodes(editor, { type: 'paragraph' })
        removeMarksAtSelection(editor)
      }
    }
    deleteBackward(unit)
  }

  return editor
}
