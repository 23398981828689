/* eslint-disable react-hooks/rules-of-hooks */
// @ts-nocheck
import { useEffect, useRef } from 'react'

export const scrollToTop = (triggerScroll?: boolean, setTriggerScroll?: (boolean: boolean) => void) => {
  const scrollRef = useRef(null)
  useEffect(() => {
    if (triggerScroll && scrollRef.current) {
      try {
        window.requestAnimationFrame(() =>
          scrollRef.current.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          })
        )
        // In case scrollTo or scrollBehavior are not supported
      } catch (error) {
        scrollRef.current.scrollTop = 0
      }

      setTriggerScroll && setTriggerScroll(false)
    }
  }, [triggerScroll])

  return scrollRef
}
