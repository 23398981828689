import { atom, AtomEffect, DefaultValue, selector } from 'recoil'

import { getRunbookId } from 'main/recoil/shared/nav-utils'
import { RunbookShowRunbook, RunbookVersion } from 'main/services/queries/types'
import { getRunbook } from 'main/services/queries/use-runbook'
import { runbookTypeState } from '../account/runbook-types'
import { RunbookGetResponse } from 'main/services/api/data-providers/runbook-types'

// *******************************
// Runbook api response
// *******************************

const syncRunbookResponseEffect: AtomEffect<RunbookGetResponse> = ({ setSelf, resetSelf }) => {
  const getInitialRunbook = async () => {
    const runbookId = getRunbookId()

    if (runbookId) {
      const data = await getRunbook(runbookId)
      setSelf(data)
    } else {
      return new DefaultValue()
    }
  }

  getInitialRunbook()

  const handlePathChange = async (event: CustomEvent) => {
    const { pathname, previousPathname } = event.detail
    const previousRunbookId = getRunbookId(previousPathname)
    const runbookId = getRunbookId(pathname)

    if (runbookId && previousRunbookId === runbookId) return

    resetSelf()

    if (runbookId) {
      const data = await getRunbook(runbookId)
      // because we swallow canceled errors this types as possibly undefined. however, this never
      // gets here if the request is canceled, so we can safely assert that it is not undefined
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      setSelf(data!)
    }
  }

  const handleRefresh = async (event: any) => {
    if (event.detail.type === 'runbook') {
      getInitialRunbook()
    }
  }

  window.addEventListener('pathnamechanged', handlePathChange as any)
  window.addEventListener('refresh-data-store', handleRefresh as any)

  return () => {
    window.removeEventListener('pathnamechanged', handlePathChange as any)
    window.removeEventListener('refresh-data-store', handleRefresh as any)
  }
}

// this is what is updated, but via the runbookState selector
export const runbookResponseState_INTERNAL = atom<RunbookGetResponse>({
  key: 'runbook:runbook-response',
  effects: [syncRunbookResponseEffect]
})

// *******************************
// Runbook
// *******************************

export const runbookState = selector<RunbookShowRunbook>({
  key: 'runbook:runbook',
  get: ({ get }) => {
    const resp = get(runbookResponseState_INTERNAL)
    return resp.runbook
  },
  /// actually i dont think we should ever set runbook manually...
  set: ({ set }, newValue) => {
    if (newValue instanceof DefaultValue) {
      return newValue
    }

    set(runbookResponseState_INTERNAL, prev => ({
      meta: prev.meta,
      runbook: { ...prev.runbook, ...newValue }
    }))
  }
})

export const runbookIdState = selector({
  key: 'runbook:runbook-id',
  get: ({ get }) => {
    return get(runbookState).id
  }
})

// TODO: how to name this? it is the active runbook's type, but we don't have a
// naming convention for "active", it's just "runbook".
export const runbookRunbookTypeState = selector({
  key: 'runbook:runbook-type',
  get: ({ get }) => {
    const runbook = get(runbookState)
    return get(runbookTypeState(runbook.runbook_type_id))
  }
})

export const runbookCurrentVersionState = selector<RunbookVersion>({
  key: 'runbook:current_version',
  get: ({ get }) => {
    const runbook = get(runbookState)
    return runbook.current_version
  }
})

export const runbookPermissionsState = selector({
  key: 'runbook:permissions',
  get: ({ get }) => {
    const runbookMeta = get(runbookMetaState)
    return runbookMeta.permissions
  }
})

// *******************************
// Runbook meta
// *******************************

export const runbookMetaState = selector({
  key: 'runbook:runbook-meta',
  get: ({ get }) => {
    const resp = get(runbookResponseState_INTERNAL)
    return resp.meta
  },
  set: ({ set }, newValue) => {
    set(runbookResponseState_INTERNAL, prev => {
      if (newValue instanceof DefaultValue) {
        return newValue
      }

      return {
        ...prev,
        meta: newValue
      }
    })
  }
})
