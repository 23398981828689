import { add, Duration as DFNSDuration, fromUnixTime, getUnixTime, intervalToDuration, sub } from 'date-fns'

export type Duration = DFNSDuration

export function subtractFromDate(date: number, duration: Duration): number
export function subtractFromDate(date: Date, duration: Duration): Date
export function subtractFromDate(date: Date | number, duration: Duration) {
  const inputDate = typeof date === 'number' ? fromUnixTime(date) : date
  const resultDate = sub(inputDate, duration)
  return typeof date === 'number' ? getUnixTime(resultDate) : resultDate
}

export function addToDate(date: number, duration: Duration): number
export function addToDate(date: Date, duration: Duration): Date
export function addToDate(date: Date | number, duration: Duration) {
  const inputDate = typeof date === 'number' ? fromUnixTime(date) : date
  const resultDate = add(inputDate, duration)
  return typeof date === 'number' ? getUnixTime(resultDate) : resultDate
}

/**
 * type Duration = Omit<globalThis.Duration, 'days', 'weeks'> & {
 *   days: 1 | 2 | 3 | 4 | 5 | 6
 *   weeks: 1 to 52
 * }
 */
export const getDuration = (seconds: number) => {
  const duration = intervalToDuration({ start: 0, end: seconds * 1000 })
  // adding duration weeks
  if (!duration.weeks) {
    duration.weeks = ((duration.days ?? 0) / 7) | 0
    duration.days = (duration.days ?? 0) - duration.weeks * 7
  }
  return duration
}

export const getDurationInDays = (
  seconds: number
): Pick<globalThis.Duration, 'days' | 'hours' | 'minutes' | 'seconds'> => {
  const days = Math.floor(seconds / (3600 * 24))
  const hours = Math.floor((seconds % (3600 * 24)) / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)
  const sec = Math.floor(seconds % 60)

  return { days, hours, minutes, seconds: sec }
}
