import { cloneDeep } from 'lodash'

import { RunbookData } from './types'
import { CustomField, FieldOption } from 'main/services/queries/types/custom-fields'

type FieldOptionValue = {
  id: number | undefined
  slug: string
  label: string
  order: number
  color: string | undefined
}

export function getCustomFieldsAxisDef(customFields: CustomField[]) {
  return customFields.reduce((axisDefsObject, customField) => {
    return {
      ...axisDefsObject,
      [customField.id]: createCustomFieldAxis(customField)
    }
  }, {})
}

function createCustomFieldAxis(customField: CustomField) {
  const values = generateFieldOptionValues(customField.field_options)
  return {
    name: customField.name,
    dataType: 'discrete',
    values: values,
    attribute: customField.id,
    custom: true,
    find: (ids: (number | undefined)[] = []) => values.filter((value: FieldOptionValue) => ids.includes(value.id))
  }
}

function generateFieldOptionValues(fieldOptions: FieldOption[]) {
  if (!fieldOptions.length) {
    return []
  }
  return fieldOptions.map((fieldOption: FieldOption, idx: number) => ({
    id: fieldOption.id,
    slug: fieldOption.name,
    label: fieldOption.name,
    order: idx + 1,
    color: fieldOption.color
  }))
}

export function addCustomFieldsFilterData(
  runbooks: (RunbookData & { custom_field_data?: { [id: number]: number[] } })[],
  customFields: CustomField[]
) {
  if (!runbooks.length) return []
  const updatedRunbooks = cloneDeep(runbooks)

  const customFieldsLookup: { [id: string]: CustomField } = customFields.reduce((lookup, customField) => {
    return {
      ...lookup,
      [customField.id]: customField
    }
  }, {})

  updatedRunbooks.forEach(runbook => {
    runbook.custom_field_data = {}
    if (runbook.field_values && runbook.field_values.length > 0) {
      for (var i = 0, n = runbook.field_values.length; i < n; i++) {
        // increment counts so we know what fields/options are present in result set
        const option_id = runbook.field_values[i].field_option_id
        if (option_id) {
          runbook.custom_field_data[runbook.field_values[i].custom_field_id] = [option_id]
        } else {
          // deal with checkboxes
          if (
            runbook.field_values[i].value &&
            runbook.field_values[i].value !== '[]' &&
            customFieldsLookup[runbook.field_values[i].custom_field_id] &&
            customFieldsLookup[runbook.field_values[i].custom_field_id].field_type.slug === 'checkboxes'
          ) {
            if (customFieldsLookup[runbook.field_values[i].custom_field_id]) {
              const fieldValue = runbook.field_values[i].value
              if (fieldValue) {
                const unserialisedIds = JSON.parse(fieldValue)
                runbook.custom_field_data[runbook.field_values[i].custom_field_id] = unserialisedIds
              }
            }
          }
        }
      }
    }
    return runbook
  })
  return updatedRunbooks
}
