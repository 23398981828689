import { useCallback, useMemo } from 'react'
import { format as formatDate, fromUnixTime } from 'date-fns'
import { invert } from 'lodash'
import { useNavigate, useParams } from 'react-router-dom'

import { Box, ColumnConfig, duration, NoResourceFound, Table, Text } from '@cutover/react-ui'
import {
  useClearFilterParam,
  useClearFilterParams,
  useFilter,
  useSetFilterParams
} from 'main/components/shared/filter/filter-provider'
import { useAccountRunbookTypes } from 'main/services/api/data-providers/account/account-data'
import { useWorkspaceData } from 'main/services/api/data-providers/workspace'
import { useLanguage } from 'main/services/hooks'
import { RunbookListRunbook, RunbookTemplateType } from 'main/services/queries/types'
import { useRouting } from 'main/services/routing/hooks'

// runbook type properties to the filter value for sending to the backend to sort by
const SORT_PROPERTY_FILTER_MAP: Record<string, string> = {
  stage: 'runs.mode',
  run_type: 'runs.run_type',
  runbook_type: 'runbooks.runbook_types.name'
}

export const RunbooksTable = () => {
  // We need the opposite map to get the property name from the filter value
  const FILTER_SORT_PROPERTY_MAP = useMemo(() => invert(SORT_PROPERTY_FILTER_MAP), [])
  const { t } = useLanguage('runbooks', { keyPrefix: 'table.fields' })
  const { accountId: accountSlug } = useParams<{ accountId?: string }>()
  const navigate = useNavigate()
  const sortBy = useFilter<string>('sort_by')
  const sortDir = useFilter<'asc' | 'desc'>('sort_dir')
  const archived = useFilter<boolean>('archived')
  const { toRunbook } = useRouting()

  const templateType = useFilter<RunbookTemplateType[]>('template_type')?.[0] ?? null
  const setFilters = useSetFilterParams()
  const clearFilter = useClearFilterParam()
  const clearAll = useClearFilterParams()

  const { isFetchingNextPage, runbooks, fetchNextPage, isLoading } = useWorkspaceData()
  const { runbookTypeLookup } = useAccountRunbookTypes()

  const handleSort = useCallback(
    (sort?: { property: string; direction: 'desc' | 'asc' }) => {
      if (!sort) {
        clearFilter('sort_by')
        clearFilter('sort_dir')
      } else {
        setFilters({ sort_by: SORT_PROPERTY_FILTER_MAP[sort.property] ?? sort.property, sort_dir: sort.direction })
      }
    },
    [setFilters, clearFilter]
  )

  const handleClickRow = useCallback(({ datum }: { datum: RunbookListRunbook }) => {
    // TODO: remove next line once runbook page is on React
    localStorage.setItem('previousTargetState', 'app.root.runbooks.table')
    navigate(toRunbook({ accountSlug: accountSlug as string, runbookId: datum.id }))
  }, [])

  //Note, add 36px to width of non-sortable columns to make them balance
  const columns: ColumnConfig<RunbookListRunbook>[] = useMemo(
    () => [
      {
        property: 'id',
        header: t('id'),
        size: '30px',
        pin: true
      },
      {
        property: 'name',
        header: t('name'),
        pin: true,
        size: '260px'
      },
      {
        property: 'project_name',
        header: t('projectName'),
        size: '170px'
      },
      {
        property: 'runbook_admins',
        header: t('runbookAdmins', {
          type:
            !templateType || templateType === 'off' ? 'Runbook' : templateType === 'default' ? 'Template' : 'Snippet'
        }),
        size: '250px',
        sortable: false
      },
      {
        property: 'stage',
        header: t('stage'),
        size: '75px',
        hidden: templateType === 'default'
      },
      {
        property: 'start_scheduled',
        header: t('startScheduled'),
        render: (datum: RunbookListRunbook) =>
          datum.start_scheduled ? (
            <Text>{formatDate(fromUnixTime(datum.start_scheduled), 'dd MMM yyyy HH:mm')}</Text>
          ) : null,
        size: '120px',
        hidden: templateType === 'default'
      },
      {
        property: 'start_actual',
        header: t('startActual'),
        render: (datum: RunbookListRunbook) =>
          datum.start_actual ? <Text>{formatDate(fromUnixTime(datum.start_actual), 'dd MMM yyyy HH:mm')}</Text> : null,
        size: '120px',
        hidden: templateType === 'default'
      },
      {
        property: 'end_planned',
        header: t('endPlanned'),
        render: (datum: RunbookListRunbook) =>
          datum.end_planned ? <Text>{formatDate(fromUnixTime(datum.end_planned), 'dd MMM yyyy HH:mm')}</Text> : null,
        size: '120px',
        hidden: templateType === 'default'
      },
      {
        property: 'end_actual',
        header: t('endActual'),
        render: (datum: RunbookListRunbook) =>
          datum.end_actual ? <Text>{formatDate(fromUnixTime(datum.end_actual), 'dd MMM yyyy HH:mm')}</Text> : null,
        size: '120px',
        hidden: templateType === 'default'
      },
      {
        property: 'duration_display',
        header: t('durationDisplay'),
        render: (datum: RunbookListRunbook) => {
          const display = datum.duration_display === 0 ? null : duration(datum.duration_display, 2)
          return <Text>{display}</Text>
        },
        size: '112px',
        sortable: false,
        hidden: templateType === 'default'
      },
      {
        property: 'completion',
        header: t('completion'),
        render: (datum: RunbookListRunbook) => <Text>{datum.completion}%</Text>,
        size: '112px',
        sortable: false,
        hidden: templateType === 'default'
      },
      {
        property: 'tasks_count',
        header: t('tasksCount'),
        size: '75px'
      },
      {
        property: 'streams_count',
        header: t('streamsCount'),
        size: '75px'
      },
      {
        property: 'run_type',
        header: t('runType'),
        size: '75px',
        hidden: templateType === 'default'
      },
      {
        property: 'template_status',
        header: t('templateStatusHeader', {
          type:
            !templateType || templateType === 'off' ? 'Runbook' : templateType === 'default' ? 'Template' : 'Snippet'
        }),
        size: '75px',
        hidden: !templateType || templateType === 'off',
        sortable: false,
        render: (datum: RunbookListRunbook) => {
          if (!datum.template_status) return

          switch (datum.template_status) {
            case 'template_approved':
              return <Text>{t('templateStatus.approved')}</Text>
            case 'template_draft':
              return <Text>{t('templateStatus.draft')}</Text>
            case 'template_rejected':
              return <Text>{t('templateStatus.rejected')}</Text>
            case 'template_pending':
              return <Text>{t('templateStatus.pending')}</Text>
          }
        }
      },
      {
        property: 'template_next_review',
        header: t('templateNextReview'),
        render: (datum: RunbookListRunbook) =>
          datum.template_next_review ? (
            <Text>{formatDate(fromUnixTime(datum.template_next_review), 'dd MMM yyyy HH:mm')}</Text>
          ) : null,
        size: '120px',
        hidden: !templateType || templateType === 'off',
        sortable: false
      },
      {
        property: 'runbook_type',
        header: t('runbookType'),
        render: (datum: RunbookListRunbook) =>
          runbookTypeLookup ? <Text>{runbookTypeLookup[datum.runbook_type_id]?.name}</Text> : null,
        size: 'small'
      },
      {
        property: 'copies_count',
        header: t('copiesCount'),
        render: (datum: RunbookListRunbook) => (datum.copies_count ? <Text>{datum.copies_count}</Text> : null),
        size: '75px'
      },
      {
        property: 'updated_at',
        header: t('updatedAt'),
        render: (datum: RunbookListRunbook) =>
          datum.updated_at ? <Text>{formatDate(fromUnixTime(datum.updated_at), 'dd MMM yyyy HH:mm')}</Text> : null,
        size: '120px'
      },
      {
        property: 'status',
        header: t('status'),
        size: '100px',
        hidden: !!templateType && templateType !== 'off',
        sortable: false
      },
      {
        property: 'status_message',
        header: t('statusMessage'),
        size: '220px',
        hidden: !!templateType && templateType !== 'off',
        sortable: false
      },
      {
        property: 'status_author',
        header: t('statusAuthor'),
        size: 'small',
        hidden: !!templateType && templateType !== 'off',
        sortable: false
      },
      {
        property: 'status_updated_at',
        header: t('statusUpdatedAt'),
        render: (datum: RunbookListRunbook) =>
          datum.status_updated_at ? (
            <Text>{formatDate(fromUnixTime(datum.status_updated_at), 'dd MMM yyyy HH:mm')}</Text>
          ) : null,
        size: '168px',
        hidden: !!templateType && templateType !== 'off',
        sortable: false
      }
    ],
    [templateType]
  )

  const sort = useMemo(() => {
    return sortBy
      ? { property: FILTER_SORT_PROPERTY_MAP[sortBy] ?? sortBy, direction: sortDir ?? 'asc', external: true }
      : undefined
  }, [sortBy, sortDir])

  const tabLabelledBy =
    templateType === 'default' ? 'tab-templates' : templateType === 'snippet' ? 'tab-snippets' : 'tab-runbooks'

  return (
    <Box pad={{ right: '16px' }} id="tabpanel-runbooks" aria-labelledby={`${tabLabelledBy} view-toggle-table`}>
      {!isLoading && runbooks?.length === 0 ? (
        <NoResourceFound
          context={templateType === 'default' ? 'template' : templateType === 'snippet' ? 'snippet' : 'runbook'}
          clearAllFilters={() => clearAll()}
        />
      ) : (
        <Table
          replace
          pinnedMinWidth={700}
          onClickRow={handleClickRow}
          sort={sort}
          sortable="server"
          onSort={handleSort}
          onMore={fetchNextPage}
          isLoadingMore={isFetchingNextPage}
          isLoading={isLoading}
          columns={columns}
          data={runbooks ?? undefined}
          css={`
            tbody {
              opacity: ${archived ? 0.5 : 1};
            }
          `}
        />
      )}
    </Box>
  )
}
