import styled from 'styled-components/macro'

import { themeColor } from '../../../theme'
import { StatusPill } from '../../../pill'

export const Leaf = ({ attributes, children, leaf }: any) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>
  }

  if (leaf.code) {
    children = <StyledCode>{children}</StyledCode>
  }

  if (leaf.status) {
    children = <StatusPill children={children} status={leaf.status} />
  }

  if (leaf.italic) {
    children = <em>{children}</em>
  }

  if (leaf.underline) {
    children = <u>{children}</u>
  }

  if (leaf.superscript) {
    children = <sup>{children}</sup>
  }

  if (leaf.subscript) {
    children = <sub>{children}</sub>
  }

  if (leaf.strikethrough) {
    children = <del>{children}</del>
  }

  return <span {...attributes}>{children}</span>
}

const StyledCode = styled.code`
  padding: 0 4px;
  border-radius: 4px;
  line-height: 22px;
  display: inline-block;
  color: ${themeColor('error')};
  background-color: ${themeColor('bg-1')};
`
