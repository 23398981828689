import React from 'react'
import ReactDOMClient from 'react-dom/client'
import singleSpaReact from 'single-spa-react'

import { ReactAppLegacy } from '../react-app-legacy'
import { clearContainer, container, createContainer } from 'app/Config/IOC'
import { CurrentPagePresenter } from 'app/Routing/CurrentPage/CurrentPagePresenter'
import { bindClearReactContainer, setAngularJSSettingsPanelOpenState } from 'app/Shared/Helpers/SPASharedStateService'

bindClearReactContainer()

const lifecycles = singleSpaReact({
  React,
  ReactDOMClient,
  rootComponent: ReactAppLegacy,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  domElementGetter: () => document.getElementById('cutover-react-legacy')!
})

export default {
  bootstrap: lifecycles.bootstrap,
  mount: async (props: any) => {
    await preMount()
    await lifecycles.mount(props)
  },
  unmount: async (props: any) => {
    await postUnmount()
    await lifecycles.unmount(props)
  }
}

async function preMount() {
  await createContainer()
  await container.get(CurrentPagePresenter).bootstrap(true)

  window.angularJS.setAngularJSSettingsPanelOpenState = setAngularJSSettingsPanelOpenState
  setAngularJSSettingsPanelOpenState(window.angularJS.settingsPanelOpen)
}

async function postUnmount() {
  window.angularJS.setAngularJSSettingsPanelOpenState = (state: boolean) => {
    return null
  }
  clearContainer()
}
