import { ReactNode } from 'react'
import { PolymorphicComponent } from 'react-polymorphic-box'
import styled from 'styled-components/macro'

import { resolveColor } from '../theme'
import { px2rem } from '../utils'

type LinkProps = {
  as?: 'a' | 'button'
  children: ReactNode
  color?: string
  colorHover?: string
  underline?: 'always' | 'hover' | 'none'
  size?: number
}

const defaultElement = 'a'
const defaultHover = 'always'

/**
 * @deprecated Use grommet's Button with plain=true to construct buttons that look like links. If you want a true link (a tag), use
 * react-router's Link component instead. TODO: text style buttons should have standard design.
 */
export const Link: PolymorphicComponent<LinkProps, typeof defaultElement> = styled(defaultElement)<LinkProps>`
  background-color: transparent;
  border: none;
  color: ${({ color, theme }) => color || resolveColor('text', theme)};
  cursor: pointer;
  font-size: ${({ size }) => typeof size === 'number' && px2rem(size)};
  text-decoration: ${({ underline = defaultHover }) => underline === 'always' && 'underline'};
  text-underline-offset: 2px;
  padding: 0;

  &:hover {
    color: ${({ theme, colorHover }) => colorHover || resolveColor('text', theme)};
    text-decoration: ${({ underline = defaultHover }) => underline === 'hover' && 'underline'};
  }

  &:visited,
  &:active {
    color: ${({ color }) => color};
  }
`
