import React from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useFormContext } from 'react-hook-form'
import * as yup from 'yup'

import { toCamelCase } from '@cutover/api'
import { DateTimePicker, Select, Text, TextArea, TextInput } from '@cutover/react-ui'
import { DynamicFormField, DynamicFormFieldsConfig } from './dynamic-form-types'
import { CustomFieldOptionType } from '../../settings/integration-settings/shared-integration-components/integration-form-setting-fields'

export type DynamicFormFieldsProps = {
  elements?: DynamicFormField[]
  taskArray?: any
  customFieldOptions?: CustomFieldOptionType[]
  group?: string | unknown
}

export const DynamicFormFields = ({ elements, taskArray }: DynamicFormFieldsProps) => {
  const { formState } = useFormContext()
  const { errors } = formState

  const formFieldComponents =
    elements &&
    toCamelCase(elements).map((element: DynamicFormField) => {
      // @ts-ignore FIXME: resolve type
      const error = errors.elements?.[element.component.name]

      switch (element.type) {
        case 'input':
          const key = element.component.name
          switch (element.component.type) {
            case 'text':
              if (element.component.textArea) {
                return <TextAreaField key={key} element={element} elementKey={key} error={error} />
              } else {
                return <TextInputField key={key} element={element} elementKey={key} error={error} />
              }
            case 'select_menu':
              return <SelectMenuField key={key} element={element} elementKey={key} error={error} />
            case 'task_select_menu':
              return (
                <TaskSelectMenuField key={key} element={element} elementKey={key} error={error} taskArray={taskArray} />
              )
            case 'date':
              return <DateTimePickerField key={key} element={element} elementKey={key} error={error} />
          }
          break
        case 'section':
          switch (element.content.type) {
            case 'plain_text':
              return <TextField key={'section'} element={element} elementKey={'section'} />
          }
      }
    })

  return <React.Fragment>{formFieldComponents}</React.Fragment>
}

export type SettingFieldProps = {
  element: DynamicFormField
  elementKey: string
  error?: any
  taskArray?: any
}

const TextField = ({ element }: SettingFieldProps) => {
  return <Text as="p">{element.content.text}</Text>
}

const TextInputField = ({ element, elementKey, error }: SettingFieldProps) => {
  const { register } = useFormContext()
  return (
    <TextInput
      {...register(`${elementKey}`, { required: element.component.required })}
      label={element.component.label}
      defaultValue={element.component.default || ''}
      required={element.component.required}
      hasError={Boolean(error)}
      tooltipText={element.component.tooltip}
      maxLength={element.component.maxLength}
    />
  )
}

const TextAreaField = ({ element, elementKey, error }: SettingFieldProps) => {
  const { register } = useFormContext()
  return (
    <TextArea
      {...register(elementKey, { required: element.component.required })}
      label={element.component.label}
      rows={5}
      defaultValue={element.component.default || ''}
      required={element.component.required}
      hasError={Boolean(error)}
      tooltipText={element.component.tooltip}
      maxLength={element.component.maxLength}
    />
  )
}

const DateTimePickerField = ({ element, elementKey, error }: SettingFieldProps) => {
  const { control } = useFormContext()
  const withTimePicker = element.component.timePicker || false
  return (
    <Controller
      name={elementKey}
      control={control}
      render={({ field: { name, onChange, onBlur, value, ref } }) => (
        <DateTimePicker
          label={element.component.label}
          onChange={onChange}
          name={name}
          onBlur={onBlur}
          value={value}
          required={element.component.required}
          datePickerOnly={!withTimePicker}
          hasError={Boolean(error)}
          tooltipText={element.component.tooltip}
          inputRef={ref}
        />
      )}
    />
  )
}

const SelectMenuField = ({ element, elementKey, error }: SettingFieldProps) => {
  const { control } = useFormContext()
  const defaultValue = element.component.default || null
  const availableOptions =
    element.component.options?.map(option => ({
      label: option.name,
      value: option.value
    })) ?? []

  return (
    <Controller
      name={elementKey}
      control={control}
      render={({ field: { onChange, value, ref } }) => (
        <Select
          onChange={onChange}
          required={element.component.required}
          hasError={Boolean(error)}
          inputRef={ref}
          label={element.component.label}
          value={value || defaultValue}
          options={availableOptions}
          helpText={element.component.tooltip}
        />
      )}
    />
  )
}

const TaskSelectMenuField = ({ element, elementKey, error, taskArray }: SettingFieldProps) => {
  const { control } = useFormContext()

  return (
    <Controller
      name={elementKey}
      control={control}
      render={({ field: { onChange, value, ref } }) => (
        <Select
          onChange={onChange}
          required={element.component.required}
          hasError={Boolean(error)}
          inputRef={ref}
          label={element.component.label}
          value={value}
          options={taskArray}
          helpText={element.component.tooltip}
        />
      )}
    />
  )
}

export function generateYupSchema({ elements }: DynamicFormFieldsConfig) {
  const shapes: { [key: string]: yup.Schema } = {}
  elements &&
    toCamelCase(elements).forEach((element: DynamicFormField) => {
      switch (element.type) {
        case 'input':
          const key = element.component.name
          switch (element.component.type) {
            case 'text':
              shapes[key] = element.component.required ? yup.string().required() : yup.string()
              break
            case 'select_menu':
              shapes[key] = element.component.required ? yup.string().required() : yup.string()
              break
            case 'task_select_menu':
              shapes[key] = element.component.required ? yup.string().required() : yup.string()
              break
            case 'date':
              shapes[key] = element.component.required ? yup.date().required() : yup.date()
              break
          }
      }
    })
  return shapes && yupResolver(yup.object().shape(shapes))
}
