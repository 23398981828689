import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const CloudUpload = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="CloudUpload" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="m15.827 18.94-2.052.063A7 7 0 0 0 14 33h5v3h-5C8.477 36 4 31.523 4 26c0-5.416 4.306-9.827 9.681-9.995C15.327 11.342 19.773 8 25 8c6.627 0 12 5.373 12 12a12.248 12.248 0 0 1-.042 1.014A7.501 7.501 0 0 1 37 35.984V36h-5c-5.523 0-10-4.477-10-10v-3.636l-3.94 3.94-2.12-2.122 7.485-7.485 7.485 7.485-2.121 2.121L25 22.515V26a7 7 0 0 0 7 7h4.652l.151-.01a4.5 4.5 0 0 0-.025-8.982l-3.066-.184.256-3.06A9 9 0 0 0 25 11a9.005 9.005 0 0 0-8.49 6.003l-.683 1.936Zm7.598 2 .06.06h-.12l.06-.06Z" fill="#000" />
  </StyledIcon>
))

CloudUpload.displayName = 'CloudUpload'

export { CloudUpload }
