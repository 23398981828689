import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { useFormContext } from 'react-hook-form'

import { FormModal, SelectField } from 'main/components/shared/form'
import { ServerParams, useRunbooks } from 'main/services/queries/use-runbooks'
import {
  useValidateMerge,
  ValidateMergePayload,
  ValidateMergeResponse
} from 'main/services/queries/validate-merge-query'
import { RunbookListRunbook } from 'main/services/queries/types'
import { MergeValidationSummary } from 'main/components/shared/merge-validation-summary'
import { useMergeRunbooks } from 'main/services/queries/merge-runbooks-query'
import { RunbookMergeResponse } from 'main/services/api/data-providers/runbook-types'
import { ActiveAccountModel, ActiveRunbookModel } from 'main/data-access'
import { FormRenderProps } from 'main/components/shared/form/form'

const DEFAULT_RUNBOOKS_REQUEST_PARAMS = {
  template_type: ['off', 'default'],
  display_stage: ['planning'],
  fields: ['name', 'id', 'project_id', 'meta'],
  limit: 'none'
} as Partial<ServerParams>

const validationSchema = yup.object().shape({
  runbook_ids: yup.number().required(),
  shift_time: yup.boolean(),
  target_runbook_id: yup.number().required(),
  _step: yup.number().required().oneOf([1, 2])
})

type MergeRunbookForm = yup.InferType<typeof validationSchema>

export const MergeRunbookModal = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation('runbook', { keyPrefix: 'mergeRunbookModal' })

  const [validateMergeErrors, setValidateMergeErrors] = useState([])
  const { slug } = ActiveAccountModel.useGet()
  const runbookId = ActiveRunbookModel.useId()

  const processMergeResponse = ActiveRunbookModel.useOnAction('merge')
  const { mutateAsync: mutateValidateMergeAsync, data: validateData } = useValidateMerge()
  const { mutateAsync: mutateMergeAsync } = useMergeRunbooks()
  const {
    data,
    isLoading: isLoadingGetRunbooks,
    isError: isErrorGetRunbooks
  } = useRunbooks({
    ...DEFAULT_RUNBOOKS_REQUEST_PARAMS,
    accountId: slug
  })

  const handleClickConfirm = async (renderProps: FormRenderProps<MergeRunbookForm>) => {
    setValidateMergeErrors([])
    const formValues = renderProps.getValues()

    try {
      await mutateValidateMergeAsync({
        target_runbook_id: formValues.target_runbook_id,
        runbook_ids: [formValues.runbook_ids]
      })
    } catch (e: any) {
      setValidateMergeErrors(e.errors)
      // throwing needed so that the execution of onClickConfirm in the FormModal stops and therefore doesn't continue to the next step
      throw e
    }
  }

  const handleSubmit = async (data: ValidateMergePayload) =>
    await mutateMergeAsync({
      runbook_ids: data.runbook_ids,
      shift_time: false,
      target_runbook_id: runbookId
    })

  const handleSuccess = (data: RunbookMergeResponse) => {
    processMergeResponse(data)
    onClose()
  }

  return (
    <FormModal<MergeRunbookForm, ValidateMergePayload>
      confirmIcon={'merge'}
      confirmText={formContext => t('submitText', { context: `step${formContext.getValues('_step')}` })}
      customErrors={isErrorGetRunbooks ? [t('errorRetrievingRunbooks')] : validateMergeErrors}
      defaultValues={{ target_runbook_id: runbookId, shift_time: false, _step: 1 }}
      description={formContext => (formContext.getValues('_step') === 1 ? t('description') : null)}
      loading={isLoadingGetRunbooks}
      onClickConfirm={handleClickConfirm}
      onClose={onClose}
      onSubmit={handleSubmit}
      onSuccess={handleSuccess}
      open
      schema={validationSchema}
      steps={2}
      title={formContext => t('title', { context: `step${formContext.getValues('_step')}` })}
      transformer={data => {
        return { ...data, runbook_ids: [data.runbook_ids] }
      }}
    >
      <MergeRunbookModalContent runbooks={data?.runbooks} summaryData={validateData} />
    </FormModal>
  )
}

const MergeRunbookModalContent = ({
  runbooks,
  summaryData
}: {
  runbooks?: RunbookListRunbook[]
  summaryData?: ValidateMergeResponse
}) => {
  const { t } = useTranslation('runbook', { keyPrefix: 'mergeRunbookModal' })
  const { watch } = useFormContext()
  const currentStep = watch('_step')

  const sortedRunbookOptions = runbooks
    ?.map(rb => ({ value: rb.id, label: rb.name }))
    .sort((a, b) => a.label.localeCompare(b.label, undefined, { sensitivity: 'base' }))

  return currentStep === 1 ? (
    <SelectField
      label={t('selectRunbookFieldLabel')}
      loading={!runbooks}
      name="runbook_ids"
      options={sortedRunbookOptions}
      required
    />
  ) : summaryData ? (
    <MergeValidationSummary data={summaryData} />
  ) : null
}
