// @ts-nocheck
import { observable, action } from 'mobx'
import { container } from 'app/Config/IOC'
import { Router } from 'app/Routing/Router'

export abstract class GenericFilter {
  @observable
  public router: Router = container.get(Router)

  @action
  public refreshFilteredRoute = () => {
    if (this.hideFromTheUrl) return
    this.router.refreshFilteredRoute()
  }

  @observable
  public reference: string

  @observable
  public isPopulated: boolean

  @observable
  public group: string

  @observable
  public hideFromTheUrl = false

  public abstract select(object: { key: string; value: string }): void

  public abstract deselect(): void

  public abstract match(object: { key: string; value: string }): boolean

  public abstract compareMatch(comparitor: string): boolean

  public abstract get serialiseForUrl(): string

  public abstract get serialiseForApi(): string

  public abstract get serialiseForComparitor(): string

  @observable
  public onChangeCallback: (reference: string, resourceId?: string) => void | Promise<void>

  constructor({ reference }: { reference: string }) {
    this.reference = reference
  }

  public withOnChangeCallback = (callback: (reference: string, resourceId?: string) => void | Promise<void>) => {
    this.onChangeCallback = callback
    return this
  }

  public shouldHideFromTheUrl = () => {
    this.hideFromTheUrl = true
    return this
  }

  @action
  public withGroup = (group: string) => {
    this.group = group
    return this
  }
}
