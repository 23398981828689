// @ts-nocheck
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'

import { Accordion } from 'Components/Molecules/Accordion'
import { AddGenericWithResultModalComponent } from 'Components/Atoms/Modal'
import { addSectionHooks } from 'Shared/HooksAndPresenters/AddEditSection'
import { Button } from 'Components/Molecules/Button'
import { colors, px2rem } from 'app/Styles/Variables'
import { container } from 'app/Config/IOC'
import { UserAppTokenApplicationPresenter } from 'app/Access/Users/UsersPage/UserAppTokenApplicationPresenter'
import { UserAppToken } from 'Components/Organisms/UserAppToken'
import { UserEditUserAppTokensPresenter } from './UserEditUserAppTokensPresenter'
import UserEditUserAppTokensAddSection from './UserAppTokensAdd/UserEditUserAppTokensAddSection'

const ButtonWrapper = styled.div`
  margin-top: ${px2rem(12)};
`
const TokenListWrapper = styled.div`
  margin-top: ${px2rem(12)};
`
const UserAppTokenWrapper = styled.div`
  margin-bottom: ${px2rem(9)};
  padding-bottom: ${px2rem(6)};
  border-bottom: ${px2rem(1)} dashed ${colors.primaryGreyHoverBackgroundColor};
`
const Wrapper = styled.div``

interface IUserEditUserAppTokensComponentProps {
  presenter: UserEditUserAppTokensPresenter
}

export const UserEditUserAppTokensSection = observer<IUserEditUserAppTokensComponentProps>(({ presenter }) => {
  const {
    accordionPresenter,
    addModalOpenState,
    buttonPresenter,
    disabled,
    language,
    loading,
    setAddModalOpenState,
    userAppTokenPresenters
  } = presenter

  const tokenList = userAppTokenPresenters.map(userAppTokenPresenter => {
    return (
      <UserAppTokenWrapper key={userAppTokenPresenter.token.id}>
        <UserAppToken presenter={userAppTokenPresenter} />
      </UserAppTokenWrapper>
    )
  })

  const { addModalPresenter } = addSectionHooks(setAddModalOpenState, addModalOpenState)

  const userAppTokenApplicationPresenter = container.get(UserAppTokenApplicationPresenter)

  return (
    <>
      <Accordion presenter={accordionPresenter}>
        {loading ? (
          language.get('common:loading')
        ) : (
          <Wrapper>
            {!disabled && (
              <ButtonWrapper>
                <Button presenter={buttonPresenter} extraStyles={{ width: '100%' }} />
              </ButtonWrapper>
            )}
            <TokenListWrapper>
              {tokenList.length > 0 ? tokenList : language.get('userAppTokens:list:empty')}
            </TokenListWrapper>
          </Wrapper>
        )}
        <AddGenericWithResultModalComponent
          title={language.get('userAppTokens:add:title')}
          presenter={addModalPresenter}
          cancelCallback={() => {
            setAddModalOpenState(false)
          }}
          viewPresenter={userAppTokenApplicationPresenter}
          language={language}
        >
          <UserEditUserAppTokensAddSection
            postContinueCallback={() => {
              addModalPresenter.onClose()
              setAddModalOpenState(false)
            }}
          />
        </AddGenericWithResultModalComponent>
      </Accordion>
    </>
  )
})
