import { RunbooksSummary } from 'main/components/workspace/pages/runbooks-timeline/runbooks-summary'
import { useRightPanelTypeState } from 'main/components/layout/right-panel'

export const RunbooksTimelineSummaryPanel = () => {
  const [{ runbooks, selectedDates }, { closeRightPanel }] = useRightPanelTypeState('runbooks-timeline-summary')

  return (
    <>
      {!!runbooks && !!selectedDates && (
        <RunbooksSummary runbooks={runbooks} onClickClose={closeRightPanel} selectedDates={selectedDates} />
      )}
    </>
  )
}
