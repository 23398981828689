import { atom } from 'recoil'

import { parseResourceURLParams } from 'main/services/params-util'

export const accountSlugUrlParamState = atom<string | undefined>({
  key: 'url:account:slug',
  default: parseResourceURLParams(document.location.href.split('#')[1] ?? '').accountSlug
})

export const runbookUrlParamState = atom<number | undefined>({
  key: 'url:runbook:id',
  default: parseResourceURLParams(document.location.href.split('#')[1] ?? '').runbookId
})

export const runbookVersionUrlParamState = atom<undefined | 'current_version' | number>({
  key: 'url:runbook-version:id',
  default: parseResourceURLParams(document.location.href.split('#')[1] ?? '').runbookVersionId
})
