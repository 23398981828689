// @ts-nocheck
import { observable, computed, action } from 'mobx'
import { container } from 'app/Config/IOC'
import { ButtonPresenter, PopoverPresenter } from 'Components/Molecules'
import { RunbookTypesViewPresenter } from 'app/Settings/RunbookTypes/RunbookTypesPage/RunbookTypesViewPresenter'
import { TableScrollerService } from 'Components/Organisms'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'
import { Types } from 'Gateways/Service/Types'
import { Router } from 'app/Routing/Router'
import { RoutingState } from 'app/Routing/RoutingState'
import { FiltersRepository } from 'app/Filters/FiltersRepository'
import { IGenericFilter } from 'app/Filters/FilterEntities'

export class RunbookTypesPageMoreOptionsPopoverPresenter {
  private runbookTypesViewPresenter: RunbookTypesViewPresenter = container.get(RunbookTypesViewPresenter)

  private tableScrollerService: TableScrollerService = container.get(TableScrollerService)

  private routingState: RoutingState = container.get(RoutingState)

  private router: Router = container.get(Router)

  private filtersRepository: FiltersRepository = container.get(FiltersRepository)

  public language: ILanguageService = container.get(Types.ILanguageService)

  @observable
  public reloadClicked: boolean = false

  @observable
  public reloadDataPopoverPresenter: PopoverPresenter = new PopoverPresenter().withPosition('bottom right')

  @observable
  public popoverOpenState: boolean = false

  public currentRoutingState: { routeId: string; params: any; query: string } = this.routingState.currentState

  public setPopoverOpenState = (popoverOpenState: boolean) => {
    this.popoverOpenState = popoverOpenState
  }

  @observable
  public displayArchivedRunbookTypesPresenter: ButtonPresenter = new ButtonPresenter(
    this.language.get('common:headingSection:moreOptions:showArchivedButton'),
    () => {
      this.reloadClicked = !this.reloadClicked
      this.toggleArchiveFilter('true')
      this.tableScrollerService.doScroll()
      this.runbookTypesViewPresenter.pageVM.editPanelOpen = false
    }
  )
    .withIconName('trash-o')
    .withIconPosition('left')
    .withDisplayStyle('popover')

  @observable
  public displayUnArchivedRunbookTypesPresenter: ButtonPresenter = new ButtonPresenter(
    this.language.get('common:headingSection:moreOptions:showUnArchivedButton'),
    () => {
      this.reloadClicked = !this.reloadClicked
      this.toggleArchiveFilter('')
      this.tableScrollerService.doScroll()
      this.runbookTypesViewPresenter.pageVM.editPanelOpen = false
    }
  )
    .withIconName('undo')
    .withIconPosition('left')
    .withDisplayStyle('popover')

  @observable
  public reloadDataButtonPresenter: ButtonPresenter = new ButtonPresenter(
    this.language.get('common:headingSection:moreOptions:reloadButton'),
    async () => {
      this.reloadClicked = !this.reloadClicked
      this.router.changePageWithoutCheck(
        this.currentRoutingState.routeId,
        this.currentRoutingState.params,
        this.currentRoutingState.query
      )
      this.runbookTypesViewPresenter.loadRunbookTypes()
      this.tableScrollerService.doScroll()
    }
  )
    .withIconName('cycle')
    .withIconPosition('left')
    .withDisplayStyle('popover')

  @computed
  public get showArchivedRunbookTypes(): boolean {
    return this.runbookTypesViewPresenter.showArchivedRunbookTypes
  }

  @action
  private toggleArchiveFilter = (value: string) => {
    const archiveFilter = this.runbookTypesViewPresenter.filterVM.filter((filter: IGenericFilter) => {
      return filter.key.reference === 'archived'
    })

    if (!archiveFilter) {
      return
    }

    this.filtersRepository.toggleFilterValue(archiveFilter[0], value)
    this.router.refreshFilteredRoute()
  }
}
