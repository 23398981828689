import { useState } from 'react'
import { eventManager } from 'event-manager'

import { ReactAngularConnector } from 'main/connectors/react-angular-connector'
import { DuplicateRunbooksModal } from 'main/components/shared/runbook-duplicate/duplicate-runbooks-modal'

type DuplicateSnippetsState = {
  snippet: { id: number; name: string } | null
  runbookIds: number[] | null
}

export const DuplicateSnippetsConnector = () => {
  const [state, setState] = useState<DuplicateSnippetsState>({
    snippet: null,
    runbookIds: null
  })

  const handleOnMount = ({ snippet, runbookIds }: DuplicateSnippetsState) => {
    setState({ snippet, runbookIds })
  }

  const handleOnUnmount = () => {
    setState({ snippet: null, runbookIds: null })
  }

  return (
    <ReactAngularConnector
      mountEvent="open-duplicate-snippets-modal"
      unmountEvent="close-duplicate-snippets-modal"
      onMount={handleOnMount}
      onUnmount={handleOnUnmount}
    >
      {state.snippet ? (
        <DuplicateRunbooksModal
          snippetFromAngular={state.snippet}
          open
          runbookIds={null}
          templateType="snippet"
          context="single"
          closeModal={() => eventManager.emit('close-duplicate-snippets-modal')}
        />
      ) : state.runbookIds && state.runbookIds.length > 0 ? (
        <DuplicateRunbooksModal
          snippetFromAngular={state.snippet}
          open
          runbookIds={state.runbookIds}
          templateType="snippet"
          context={state.runbookIds.length === 1 ? 'bulk-single' : 'bulk'}
          closeModal={() => eventManager.emit('close-duplicate-snippets-modal')}
        />
      ) : null}
    </ReactAngularConnector>
  )
}
