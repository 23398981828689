import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const ScheduledShare = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="ScheduledShare" {...props}>
    <path d="M29 3v7h-3V3h3Z" fill="#000" /><path d="M39 14v20a5 5 0 0 1-5 5H14a5 5 0 0 1-5-5V14a5 5 0 0 1 5-5h1V6h-1a8 8 0 0 0-8 8v20a8 8 0 0 0 8 8h20a8 8 0 0 0 8-8V14a8 8 0 0 0-8-8h-1v3h1a5 5 0 0 1 5 5Z" fill="#000" /><path d="M22 3v7h-3V3h3Zm12 13.5a4.5 4.5 0 0 1-7.426 3.42l-5.649 3.26a4.528 4.528 0 0 1 0 1.643l5.647 3.26a4.5 4.5 0 1 1-1.498 2.6l-5.65-3.263a4.5 4.5 0 1 1 .002-6.839l5.649-3.26A4.5 4.5 0 1 1 34 16.5Zm-3 0a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0ZM18 24a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0Zm11.5 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z" fill="#000" />
  </StyledIcon>
))

ScheduledShare.displayName = 'ScheduledShare'

export { ScheduledShare }
