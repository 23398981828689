import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Snippet = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Snippet" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M27.907 9.864a4 4 0 1 0-7.814 0C20.557 11.979 19.199 15 16.09 15H9v4.517a7.5 7.5 0 1 1 0 14.967V39h7.517a7.5 7.5 0 1 1 14.967 0H39v-4.517a7.5 7.5 0 1 1 0-14.967V15h-7.091c-3.108 0-4.466-3.021-4.002-5.136Zm2.93.642a7 7 0 1 0-13.675 0c.158.718-.336 1.494-1.07 1.494H7a1 1 0 0 0-1 1v8.346c0 .942 1.419 1.553 2.329 1.308a4.5 4.5 0 1 1 0 8.692C7.419 31.101 6 31.712 6 32.654V41a1 1 0 0 0 1 1h11.346c.942 0 1.553-1.419 1.308-2.329a4.5 4.5 0 1 1 8.692 0c-.245.91.366 2.329 1.308 2.329H41a1 1 0 0 0 1-1v-8.346c0-.942-1.419-1.553-2.329-1.308A4.506 4.506 0 0 1 34 27a4.5 4.5 0 0 1 5.671-4.346c.91.245 2.329-.366 2.329-1.308V13a1 1 0 0 0-1-1h-9.091c-.735 0-1.229-.776-1.072-1.494Z" fill="#000" />
  </StyledIcon>
))

Snippet.displayName = 'Snippet'

export { Snippet }
