import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const ChangeFreeze = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="ChangeFreeze" {...props}>
    <path d="M21 6h-3v36h3V6Zm9 0h-3v36h3V24.022l7.778 7.778 2.121-2.121-4.085-4.086h8.171v-3h-8.313l4.227-4.228-2.12-2.121-7.78 7.778V6ZM10.207 16.244l-2.121 2.121 4.086 4.086H4v3h8.314l-4.228 4.228 2.121 2.121 7.778-7.778-7.778-7.778Z" fill="#000" />
  </StyledIcon>
))

ChangeFreeze.displayName = 'ChangeFreeze'

export { ChangeFreeze }
