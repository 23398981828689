import { memo, useMemo } from 'react'

import { IconButton } from '../../../../button'
import { Icon } from '../../../../icon'
import { Box } from '../../../../layout'

export type SelectEndItemsProps = {
  onClickClear?: any
  isLoading?: boolean
  disabled?: boolean
  readOnly?: boolean
  clearable?: boolean
  hasInputValue: boolean
  async?: boolean
}

export const SelectInputEndItems = memo(
  ({ disabled, readOnly, clearable, isLoading, async: isAsync, onClickClear, hasInputValue }: SelectEndItemsProps) => {
    const clearButton = useMemo(
      () => (
        <IconButton
          onKeyDownCapture={e => {
            if (e.key === 'Enter' || e.key === 'Space') {
              e.stopPropagation()
              e.preventDefault()
              e.nativeEvent.stopImmediatePropagation()
              onClickClear?.()
            }
          }}
          label="clear"
          disableTooltip
          size="small"
          icon="close"
          color="text-light"
          onClick={event => {
            event.preventDefault()
            event.stopPropagation()
            onClickClear?.()
          }}
        />
      ),
      [onClickClear]
    )

    const loadingIcon = useMemo(
      () => (
        <Box flex={false} width={{ min: '24px' }} height="24px" animation="rotateRight">
          <Icon icon="spinner" size="24px" color={disabled ? 'text-disabled' : 'text-light'} />
        </Box>
      ),
      [disabled]
    )

    const toggleIcon = (
      <Icon css="cursor: text" size="medium" icon="caret-down" color={disabled ? 'text-disabled' : 'text-light'} />
    )

    if (readOnly || disabled || (!clearable && !isLoading && !isAsync)) return toggleIcon

    return isLoading ? (
      loadingIcon
    ) : clearable ? (
      <Box direction="row" gap="xxsmall" align="center">
        {hasInputValue && clearButton}
        {!isAsync && toggleIcon}
      </Box>
    ) : (
      <>{!isAsync && toggleIcon}</>
    )
  }
)
