import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const DaySummary = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="DaySummary" {...props}>
    <path d="M29 3v9h-3V3h3Z" fill="#000" /><path d="M39 14v20a5 5 0 0 1-5 5H14a5 5 0 0 1-5-5V14a5 5 0 0 1 5-5h1V6h-1a8 8 0 0 0-8 8v20a8 8 0 0 0 8 8h20a8 8 0 0 0 8-8V14a8 8 0 0 0-8-8h-1v3h1a5 5 0 0 1 5 5Z" fill="#000" /><path d="M22 3v9h-3V3h3Zm-7 15h3v15h-3V18Zm7.5 7h3v8h-3v-8ZM33 22h-3v11h3V22Z" fill="#000" />
  </StyledIcon>
))

DaySummary.displayName = 'DaySummary'

export { DaySummary }
