import { useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'

import { LoadingPanel } from '@cutover/react-ui'
import { RunbooksDashboardModal } from './runbooks-dashboard-modal'
import { RunbooksDashboardScheduleForm } from './runbooks-dashboard-schedule-form'
import { RunbooksDashboardScheduleList } from './runbooks-dashboard-schedule-list'
import { UpdateUserSettingParams } from 'main/components/dashboards/runbook-dashboard-schedule/runbook-dashboard-schedule-types'
import { QueryKeys } from 'main/services/queries/query-keys'
import { useUpdateEmailScheduleUserSetting } from 'main/services/queries/use-email-schedule-user-setting'
import { useRunbooksDashboardSchedulesQuery } from 'main/services/queries/use-email-schedule-user-settings'
import { SavedView, useSavedViewGroups } from 'main/services/queries/use-saved-view-groups'

export type RunbooksDashboardScheduleProps = {
  accountId: number
  dashboardId: number
  currentUserId: number
  onClose: () => void
}

export const RunbooksDashboardSchedule = ({
  accountId,
  currentUserId,
  dashboardId,
  onClose
}: RunbooksDashboardScheduleProps) => {
  const queryClient = useQueryClient()
  const [editSettingId, setEditSettingId] = useState<number | null>(null)
  const [editScheduleIndex, setEditScheduleIndex] = useState<number>(0)
  const { data, isLoading, isRefetching } = useRunbooksDashboardSchedulesQuery({ dashboardId, accountId })
  const updateSchedule = useUpdateEmailScheduleUserSetting()
  const [addModalOpen, setAddModalOpen] = useState(false)
  const [filterData, setFilterData] = useState<{ filters: SavedView[] }>({ filters: [] })

  const { data: savedViewGroupData, isLoading: isSavedViewLoading } = useSavedViewGroups(accountId)
  const { dashboardId: currentPageDashboardId } = useParams<{ dashboardId?: string }>()

  useEffect(() => {
    if (savedViewGroupData) {
      const savedViews: SavedView[] = []

      savedViewGroupData.saved_view_groups.forEach(savedViewGroup => {
        const savedViewsFromGroup = savedViewGroup.saved_views.filter(savedView => {
          if (savedView.name === 'Dashboard') {
            return savedView
          }
          if (JSON.parse(savedView.query_string)._display === 'dashboard') {
            return savedView
          }
        })

        savedViews.push(...savedViewsFromGroup)
      })

      setFilterData({ filters: savedViews })
    }
  }, [savedViewGroupData])

  const updateList = () => {
    const params = {
      resource_type: 'Dashboard',
      resource_id: dashboardId,
      'data[account_id]': accountId
    }

    queryClient.refetchQueries({
      queryKey: [QueryKeys.UserSettings, params]
    })
  }

  const handleUpdate = (setting: UpdateUserSettingParams) => {
    const updatedSetting = processSettingData(setting)
    updateSchedule.mutate(updatedSetting, {
      onSuccess: () => {
        updateList()
      }
    })
  }

  const processSettingData = (setting: UpdateUserSettingParams) => {
    const updatedScheduleData = setting.data.schedules.map(schedule => {
      const freqOption = schedule?.frequency?.option
      if (freqOption === 'once') {
        return { ...schedule, starts_at: undefined, ends_at: undefined }
      } else if (freqOption === 'every_15_min' || freqOption === 'every_30_min') {
        // if 15 or 30 min freq, make sure the start_time is matching the selected date/time from starts_at
        return { ...schedule, schedule_time: schedule.starts_at }
      }
      return schedule
    })

    return { ...setting, data: { ...setting.data, schedules: updatedScheduleData } }
  }

  const handleAddModalOpen = () => {
    setAddModalOpen(true)
  }

  const handleAddModalClose = () => {
    setAddModalOpen(false)
  }

  const editSetting = data?.user_settings.find(userSetting => userSetting.id === editSettingId)

  // check if current page dashboard id has changed and close the schedule panel
  useEffect(() => {
    if (Number(currentPageDashboardId) !== dashboardId) {
      onClose()
    }
  }, [currentPageDashboardId])

  return (
    <>
      {(isLoading || isRefetching) && <LoadingPanel />}
      {editSetting && data && (
        <RunbooksDashboardScheduleForm
          onBack={() => setEditSettingId(null)}
          accountId={accountId}
          setting={editSetting}
          scheduleIndex={editScheduleIndex}
          onSubmit={handleUpdate}
          isSubmitting={updateSchedule.isLoading}
          onClose={onClose}
          filterData={filterData}
          isFilterDataLoading={isSavedViewLoading}
        />
      )}
      {data && !editSetting && (
        <RunbooksDashboardScheduleList
          onClose={onClose}
          data={data}
          isLoading={isLoading}
          onScheduleClick={(setting, scheduleIndex) => {
            setEditSettingId(setting.id)
            setEditScheduleIndex(scheduleIndex)
          }}
          onAdd={handleAddModalOpen}
          updateList={updateList}
        />
      )}
      {addModalOpen && (
        <RunbooksDashboardModal
          onClose={handleAddModalClose}
          dashboardId={dashboardId}
          accountId={accountId}
          setting={data?.user_settings[0]}
          currentUserId={currentUserId}
          filterData={filterData}
          isFilterDataLoading={isSavedViewLoading}
          updateList={updateList}
          filters={{
            account_id: String(accountId),
            dashboard_id: dashboardId
          }}
          advancedOpen={false}
        />
      )}
    </>
  )
}
