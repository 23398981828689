import { Dispatch, SetStateAction } from 'react'

import { Box, Modal, SanitizedString, useNotify } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { useArchiveDataSourceMutation } from 'main/services/queries/use-data-sources'
import { queryClient } from 'main/query-client'

type ArchiveDataSourceModalType = {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  dataSourceId: number
  dataSourceName: string
  onSubmit: () => void
}

export const ArchiveDatasourceModal = ({ open, setOpen, dataSourceId, dataSourceName }: ArchiveDataSourceModalType) => {
  const notify = useNotify()
  const { t } = useLanguage('dataSources')
  const archiveMutation = useArchiveDataSourceMutation()
  const { isLoading: isDeleteLoading } = archiveMutation

  const onSubmit = async () => {
    archiveMutation.mutate(
      { id: dataSourceId },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['data-sources'])
          notify.success(t('dataSources.modals.archiveDataSource.toasters.success'))
          closeArchiveDataSourceModal()
        },
        onError: () => {
          notify.error(t('dataSources.modals.archiveDataSource.toasters.genericError'))
        }
      }
    )
  }

  const closeArchiveDataSourceModal = () => {
    setOpen(false)
  }

  return (
    <Modal
      title={t('dataSources.modals.archiveDataSource.title')}
      open={open}
      onClose={closeArchiveDataSourceModal}
      onClickConfirm={onSubmit}
      loading={isDeleteLoading}
      confirmText="Archive"
      confirmIcon="trash-o"
      loadingText={t('loading')}
    >
      <Box>
        <SanitizedString input={t('dataSources.modals.archiveDataSource.body', { name: dataSourceName })} />
      </Box>
    </Modal>
  )
}
