// @ts-nocheck
import styled from 'styled-components'
import { IRoleTypePresenter } from './RoleTypesPresenter'
import { RoleTypesPresenter, RoleWithExpiryPresenter, RoleWithExpiry, RolePresenter, Role } from '.'
import { px2rem, colors } from 'app/Styles/Variables'
import { observer } from 'mobx-react-lite'

const Wrapper = styled.div``
const Header = styled.label`
  font-size: ${px2rem(13)};
  line-height: ${px2rem(12)};
  color: ${colors.textLight};
  font-weight: 500;
`
const RoleWrapper = styled.div``
const ChildWrapper = styled.div`
  padding-left: 20px;
`

interface IRoleTypesProps {
  presenter: RoleTypesPresenter
  displayHeader?: boolean
}

export const RoleTypes = observer<IRoleTypesProps>(({ presenter, displayHeader }) => {
  const { roleTypePresenters } = presenter
  const roles = []

  const getRoleComponentForRoleTypePresenter = (roleTypePresenter: IRoleTypePresenter) => {
    if (roleTypePresenter.type === 'expiry') {
      const roleExpiryPresenter = roleTypePresenter.presenter as RoleWithExpiryPresenter
      return <RoleWithExpiry presenter={roleExpiryPresenter} />
    } else {
      const rolePresenter = roleTypePresenter.presenter as RolePresenter
      return <Role presenter={rolePresenter} />
    }
  }

  if (roleTypePresenters && roleTypePresenters.length > 0) {
    roleTypePresenters.forEach(roleTypePresenter => {
      roles.push(
        <RoleWrapper key={roleTypePresenter.roleTypeId}>
          {getRoleComponentForRoleTypePresenter(roleTypePresenter)}
        </RoleWrapper>
      )

      // Has children and the parent is currently checked
      if (
        roleTypePresenter.children &&
        roleTypePresenter.children.length > 0 &&
        roleTypePresenter.presenter.checkboxPresenter.value === true
      ) {
        const childrenRoles = []

        roleTypePresenter.children.forEach(childRoleTypePresenter => {
          childrenRoles.push(
            <RoleWrapper key={childRoleTypePresenter.roleTypeId + ':' + childRoleTypePresenter.accountId}>
              {getRoleComponentForRoleTypePresenter(childRoleTypePresenter)}
            </RoleWrapper>
          )
        })

        roles.push(<ChildWrapper key={'child-of-' + roleTypePresenter.roleTypeId}>{childrenRoles}</ChildWrapper>)
      }
    })
  }

  return (
    <Wrapper data-testid="role-type-select-container">
      <>
        {displayHeader && <Header>Roles</Header>}
        {roles}
      </>
    </Wrapper>
  )
})
