import { MouseEvent, useState } from 'react'
import { ResponsivePie } from '@nivo/pie'

import { LegacyBox as Box } from '../../layout'
import { colors, px2rem } from '../../utils'

export type InteractiveDonutChartDatum = {
  id: string | number
  label: string | number
  value: number
  color: string
  name: string | number
}

export type InteractiveDonutProps = {
  data: InteractiveDonutChartDatum[]
  total?: boolean
  centerValue?: string
  withKey?: boolean
  height?: number
  segmentGap?: number
  hover?: boolean
  onSegmentClick?: (segmentNode: any) => void
}

export const InteractiveDonutChart = ({
  data,
  centerValue,
  height = 300,
  segmentGap = 1,
  hover,
  onSegmentClick
}: InteractiveDonutProps) => {
  const [hoverIsActive, setHoverIsActive] = useState(false)
  const handleMouseEnter = hover
    ? // @ts-ignore intentional
      (_d, event) => {
        event.currentTarget.style.cursor = 'pointer'
        setHoverIsActive(true)
      }
    : // @ts-ignore
      (_d, event: MouseEvent) => (event.currentTarget.style.cursor = 'pointer')
  const handleMouseLeave = hover ? () => setHoverIsActive(false) : () => null

  return (
    <Box
      css={`
        height: ${px2rem(height + 15)};
        width: ${px2rem(height + 75)};
        margin: auto;
        svg {
          overflow: visible;
          margin-left: -${px2rem(75)};
        }
      `}
    >
      <ResponsivePie
        data={data}
        tooltip={({
          datum: {
            value,
            color,
            data: { name }
          }
        }) => (
          <div
            style={{
              padding: 12,
              color,
              background: '#222222'
            }}
          >
            <strong>
              {name}: {value}
            </strong>
          </div>
        )}
        theme={{
          fontFamily: 'Inter, sans-serif',
          textColor: colors.text,
          legends: {
            text: {
              fontSize: 16
            }
          },
          tooltip: {
            container: {
              background: '#333'
            }
          }
        }}
        colors={({ id }) => data.find(d => d.id === id)?.color || 'blue'}
        innerRadius={0.8}
        arcLinkLabelsColor={{ from: 'color' }}
        arcLabelsTextColor={{ from: 'color', modifiers: [['darker', 2]] }}
        borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
        layers={['arcs', 'legends', centerValue ? createCenterValue(centerValue) : () => null]}
        padAngle={segmentGap}
        activeOuterRadiusOffset={hoverIsActive ? 5 : 0}
        isInteractive={hover}
        onClick={node => onSegmentClick?.(node)}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        legends={[
          {
            onClick: node => onSegmentClick?.(node),
            anchor: 'right',
            direction: 'column',
            justify: false,
            translateX: 120,
            itemsSpacing: 7,
            itemWidth: 100,
            itemHeight: 18,
            itemTextColor: '#999',
            itemDirection: 'left-to-right',
            itemOpacity: 1,
            symbolSize: 16,
            symbolShape: 'circle',
            effects: hover
              ? [
                  {
                    on: 'hover',
                    style: {
                      itemTextColor: '#57646b'
                    }
                  }
                ]
              : undefined
          }
        ]}
      />
    </Box>
  )
}

// NOTE: Nivo does not type the props for layers; we could do it manually at some point.

/**
 * A higher order function to create the layer function in order to capture the string we want to display as
 * the label
 */
const createCenterValue: (value: string) => (props: any) => JSX.Element = (value: string) => {
  return (props: any) => {
    return (
      <text
        data-testid="donut-total-count"
        x={props.centerX}
        y={props.centerY}
        textAnchor="middle"
        dominantBaseline="central"
        style={{
          fontFamily: 'Inter, sans-serif',
          fontSize: '24px',
          fill: '#97a4aa',
          fontWeight: 400
        }}
      >
        {value}
      </text>
    )
  }
}
