import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const FieldGroup = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="FieldGroup" {...props}>
    <path d="M9 4c1.792 0 3.4.786 4.5 2.031A5.986 5.986 0 0 1 18 4h4v3h-4a3 3 0 0 0-3 3v24a3 3 0 0 0 3 3h4v3h-4c-1.792 0-3.4-.786-4.5-2.031A5.985 5.985 0 0 1 9 40H5v-3h4a3 3 0 0 0 3-3V10a3 3 0 0 0-3-3H5V4h4Zm20 12c1.792 0 3.4.786 4.5 2.031A5.985 5.985 0 0 1 38 16h4v3h-4a3 3 0 0 0-3 3v16a3 3 0 0 0 3 3h4v3h-4c-1.792 0-3.4-.786-4.5-2.031A5.985 5.985 0 0 1 29 44h-4v-3h4a3 3 0 0 0 3-3V22a3 3 0 0 0-3-3h-4v-3h4Z" fill="#000" />
  </StyledIcon>
))

FieldGroup.displayName = 'FieldGroup'

export { FieldGroup }
