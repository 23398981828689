// @ts-nocheck
import { injectable, inject } from 'inversify'
import { observable, action } from 'mobx'
import { IRoleType } from 'app/Shared/Entities/Types/RoleType/IRoleType'
import { RoleTypesRepository } from './RoleTypesRepository'
import { PermissableRepository } from 'app/Shared/Repositories/PermissableRepository'
import { IBaseResponse } from 'Gateways/Service/IBaseResponse'

@injectable()
export class RoleTypeRepository extends PermissableRepository {
  @inject(RoleTypesRepository)
  private roleTypesRepository: RoleTypesRepository

  @observable
  public roleTypeForEdit: IRoleType = null

  @observable
  public archiving: boolean = false

  @observable
  public selectedId: number = null

  constructor() {
    super('role_type')
  }

  @action
  public preLoad = (args: { roleTypeId: number }): string => {
    if (this.roleTypeForEdit && this.roleTypeForEdit.id === args.roleTypeId) return
    this.selectedId = args.roleTypeId
    const requestUrl = `role_types/${args.roleTypeId}`
    return requestUrl
  }

  @action
  public postLoad = (response: IBaseResponse): void => {
    this.setRoleTypePm(response.body.role_type)
    return
  }

  @action
  public clear = () => {
    this.selectedId = null
    this.roleTypeForEdit = null
  }

  @action
  public clearSelectedId = () => {
    this.selectedId = null
  }

  @action
  public resetRoleTypeToEdit = () => {
    this.roleTypeForEdit = null
  }

  @action
  public saveRoleType = async roleType => {
    let roleTypeDto = {
      role_type: {
        context_type_name: roleType.contextTypeName,
        description: roleType.description,
        id: roleType.id,
        name: roleType.name,
        capabilities_attributes: roleType.capabilities,
        view_permission_type: roleType.viewPermissionType,
        view_permission: roleType.viewPermission,
        notify: roleType.notify,
        editable_from_context: roleType.editableFromContext
      }
    }

    const responseDto = await this.serviceGateway.put('role_types/' + this.roleTypeForEdit.id, roleTypeDto)
    if (responseDto.success) {
      const updateRoleType = {
        ...this.roleTypeForEdit,
        contextTypeName: responseDto.body.role_type.context_type_name,
        description: responseDto.body.role_type.description,
        name: responseDto.body.role_type.name,
        capabilities: responseDto.body.role_type.capabilities,
        viewPermissionType: responseDto.body.role_type.view_permission_type,
        viewPermission: responseDto.body.role_type.view_permission,
        notify: responseDto.body.role_type.notify,
        editableFromContext: responseDto.body.role_type.editable_from_context
      }
      this.setRoleTypePm(responseDto.body.role_type)
      this.roleTypesRepository.updateRoleTypesWithEditedRoleType(updateRoleType)
    }

    return responseDto
  }

  @action
  public setRoleTypePm = roleTypeDto => {
    this.roleTypeForEdit = {
      id: roleTypeDto.id || null,
      name: roleTypeDto.name || null,
      contextTypeName: roleTypeDto.context_type_name || null,
      contextTypeId: roleTypeDto.context_type_id || null,
      description: roleTypeDto.description || null,
      capabilities: roleTypeDto.capabilities || [],
      enabled: roleTypeDto.enabled,
      viewPermissionType: roleTypeDto.view_permission_type || null,
      viewPermission: roleTypeDto.view_permission || null,
      notify: roleTypeDto.notify,
      editableFromContext: roleTypeDto.editable_from_context
    }
  }

  @action
  public archive = async () => {
    this.archiving = true
    const responseDto = await this.serviceGateway.delete('role_types/' + this.roleTypeForEdit.id)
    this.archiving = false
    return responseDto
  }

  @action
  public createRoleType = async roleTypeDto => {
    return await this.serviceGateway.post('role_types', roleTypeDto)
  }
}
