// @ts-nocheck
import { useState } from 'react'
import styled from 'styled-components'
import { colors, px2rem } from 'app/Styles/Variables'
import { observer } from 'mobx-react-lite'
import { Checkbox, TextInput } from 'Components/Molecules'
import { Icon } from 'Components/Atoms'
import { RemoteOptionPresenter } from './RemoteOptionPresenter'
import { OptionInputWrapper } from './OptionInputWrapper'
import { OptionDetailsWrapper } from './OptionDetailsWrapper'

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`
const InputFlexItem = styled.div``
const DetailsItem = styled.div``

interface IRemoteOption {
  presenter: RemoteOptionPresenter
}

export const RemoteOption = observer<IRemoteOption>(({ presenter }) => {
  const { textPresenter, displayNamePresenter, checkboxPresenter, fieldOptionsPresenter, guid, disabled, showLock } =
    presenter

  const [showDetails, setShowDetails] = useState(false)
  const currentOption = fieldOptionsPresenter.findOption(guid)

  if (currentOption) {
    textPresenter.value = currentOption.value
  }

  const removeOption = () => {
    if (disabled) return
    fieldOptionsPresenter.removeOption(guid)
  }

  const toggleDetails = () => {
    if (disabled) return
    setShowDetails(!showDetails)
  }

  return (
    <Wrapper>
      <OptionInputWrapper disabled={disabled}>
        <InputFlexItem>
          <TextInput presenter={textPresenter} extraStyles={{ marginTop: 0, borderBottom: 'none' }} />
        </InputFlexItem>
        {showLock && (
          <InputFlexItem style={{ marginRight: px2rem(-16) }}>
            <Icon name="lock" color={colors.secondaryGrey} onClick={toggleDetails} />
          </InputFlexItem>
        )}
        <InputFlexItem style={{ marginRight: px2rem(-16) }}>
          <Icon name="settings" color={colors.secondaryGrey} onClick={toggleDetails} />
        </InputFlexItem>
        <InputFlexItem>
          <Icon name="close" color={colors.secondaryGrey} onClick={removeOption} />
        </InputFlexItem>
      </OptionInputWrapper>
      <OptionInputWrapper disabled={disabled}>
        <OptionDetailsWrapper show={showDetails}>
          <DetailsItem>
            <TextInput presenter={displayNamePresenter} />
          </DetailsItem>
          <DetailsItem>
            <Checkbox presenter={checkboxPresenter} />
          </DetailsItem>
        </OptionDetailsWrapper>
      </OptionInputWrapper>
    </Wrapper>
  )
})
