import { useContext, useMemo } from 'react'
import { ThemeContext } from 'grommet'
import {
  BackgroundObject as GrommetBackgroundObject,
  BackgroundType as GrommetBackgroundType,
  BorderType as GrommetBorderType,
  ColorType as GrommetColorType,
  normalizeColor
} from 'grommet/utils'
import { css } from 'styled-components/macro'
import { LiteralUnion } from 'type-fest'

import { ColorProp, ColorPropType, CustomColor } from './color'
import { darkTheme, theme, ThemeType } from './theme'
import { BackgroundObject, BackgroundType, BorderObject, BorderType, GrommetBorderObject } from './types'

export const useTheme = (): ThemeType => {
  const contextTheme = useContext(ThemeContext) as ThemeType
  const isDark = contextTheme?.dark

  return (isDark ? darkTheme : theme) as ThemeType
}

export const resolveColor = (
  color: LiteralUnion<ColorProp, string>,
  theme: ThemeType,
  opts: { default?: ColorProp; required?: boolean } = {}
): string => {
  let normalizable: GrommetColorType = {}

  if (isObjectColor(color)) {
    normalizable.light = isCustomColor(color.light) ? color.light.custom : color.light
    normalizable.dark = isCustomColor(color.dark) ? color.dark.custom : color.dark
  } else {
    normalizable = isCustomColor(color) ? color.custom : color
  }

  return normalizeColor(
    normalizable ?? (opts.default ? resolveColor(opts.default, theme) : 'primary'),
    theme,
    opts.required
  )
}

const resolveElevation = (
  size: 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge',
  theme: ThemeType
): string | undefined => {
  const themeName = theme.dark ? 'dark' : 'light'
  return theme.global.elevation[themeName]?.[size]
}

export const themeColor =
  (color: LiteralUnion<ColorProp, string>) =>
  ({ theme }: { theme: ThemeType }) =>
    resolveColor(color, theme)

export const themeElevation =
  (size: 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge') =>
  ({ theme }: { theme: ThemeType }) =>
    resolveElevation(size, theme)

export const themeEdgeSize =
  (size: keyof ThemeType['global']['edgeSize']) =>
  ({ theme }: { theme: ThemeType }) => {
    return theme.global.edgeSize[size]
  }

export const themeBreakpoint =
  (breakpoint: keyof ThemeType['global']['breakpoints'], adjustment: number = 0) =>
  ({ theme }: { theme: ThemeType }) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return theme.global.breakpoints[breakpoint]!.value! + adjustment
  }

export const media = {
  sm: (...args: any) =>
    css`
      @media screen and (max-width: ${themeBreakpoint('small', -1)}px) {
        ${args}
      }
    `,
  gtSm: (...args: any) =>
    css`
      @media screen and (min-width: ${themeBreakpoint('small')}px) {
        ${args}
      }
    `,
  md: (...args: any) =>
    css`
      @media screen and (min-width: ${themeBreakpoint('small')}px) and (max-width: ${themeBreakpoint('medium', -1)}px) {
        ${args}
      }
    `,
  gtMd: (...args: any) =>
    css`
      @media screen and (min-width: ${themeBreakpoint('medium')}px) {
        ${args}
      }
    `,
  lg: (...args: any) =>
    css`
      @media screen and (min-width: ${themeBreakpoint('medium')}px) and (max-width: ${themeBreakpoint('large', -1)}px) {
        ${args}
      }
    `,
  gtLg: (...args: any) =>
    css`
      @media screen and (min-width: ${themeBreakpoint('large')}px) {
        ${args}
      }
    `,
  xl: (...args: any) =>
    css`
      @media screen and (min-width: ${themeBreakpoint('large')}px) and (max-width: ${themeBreakpoint('xlarge', -1)}px) {
        ${args}
      }
    `,
  gtXl: (...args: any) =>
    css`
      @media screen and (min-width: ${themeBreakpoint('xlarge')}px) {
        ${args}
      }
    `
}

export function useColor<T extends ColorProp>(namedColors: T): string
export function useColor<T extends ColorProp[]>(namedColors: T): string[]
export function useColor<T extends ColorProp[] | ColorProp>(namedColors: T): string[] | string {
  const theme = useTheme()

  return useMemo(() => {
    if (Array.isArray(namedColors)) {
      return namedColors.map(color => resolveColor(color, theme))
    } else {
      return resolveColor(namedColors, theme)
    }
  }, [theme, namedColors])
}

function isCustomColor(arg: any): arg is CustomColor {
  return !!arg?.hasOwnProperty('custom')
}

function isObjectColor(arg: any): arg is Exclude<ColorProp, ColorPropType> {
  return typeof arg !== 'undefined' && typeof arg !== 'string' && !isCustomColor(arg)
}

export function transformBorderObject(
  { color, error, ...restBorder }: BorderObject,
  theme: ThemeType
): GrommetBorderObject {
  const overrides: Pick<GrommetBorderObject, 'color' | 'error'> = {}
  if (color) {
    overrides.color = resolveColor(color, theme)
  }
  if (error?.color) {
    overrides.error = {
      color: resolveColor(error.color, theme)
    }
  }

  return {
    ...restBorder,
    ...overrides
  }
}

export function transformBorderType(border: BorderType, theme: ThemeType): GrommetBorderType {
  if (typeof border === 'boolean' || typeof border === 'string') {
    return border
  } else if (Array.isArray(border)) {
    return border.map(b => transformBorderObject(b, theme))
  } else {
    return transformBorderObject(border, theme)
  }
}

export function transformBackgroundObject(
  { color, ...restBackground }: BackgroundObject,
  theme: ThemeType
): GrommetBackgroundObject {
  const overrides: Pick<GrommetBackgroundObject, 'color'> = {}

  if (color) {
    overrides.color = resolveColor(color, theme)
  }

  return {
    ...restBackground,
    ...overrides
  }
}

export function transformBackgroundType(
  background: Exclude<BackgroundType, undefined>,
  theme: ThemeType
): GrommetBackgroundType {
  if (typeof background === 'string' || (background && isCustomColor(background)) || isObjectColor(background)) {
    return resolveColor(background, theme)
  } else {
    return transformBackgroundObject(background, theme)
  }
}

export const getBreakpoint = (viewportWidth: number, theme: ThemeType) => {
  const sortedBreakpoints = Object.keys(theme.global.breakpoints).sort((a, b) => {
    const first = theme.global.breakpoints[a]
    const second = theme.global.breakpoints[b]
    if (!first) return 1
    if (!second) return -1
    if (!first.value) return 1
    if (!second.value) return -1
    return first.value - second.value
  })

  // the last breakpoint on the sorted array should have no windowWidth boundaries
  const lastBreakpoint = sortedBreakpoints[sortedBreakpoints.length - 1]
  const result = sortedBreakpoints.find(name => {
    const breakpoint = theme.global.breakpoints[name]
    return !breakpoint?.value || breakpoint.value >= viewportWidth ? name : false
  })

  return result || lastBreakpoint
}
