// @ts-nocheck
import { container } from 'app/Config/IOC'
import { observable, action, observe, computed } from 'mobx'
import { injectable } from 'inversify'
import { GenericFormPresenter } from '@logicroom/validator'
import { Types } from 'Gateways/Service/Types'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'
import {
  ErrorAlertPresenter,
  MultiSelectWithCapabilitiesPresenter,
  SelectPresenter,
  TextInputPresenter,
  ToasterService
} from 'Components/Molecules'
import { RoleTypeRepository } from 'app/Repositories/RoleType/RoleTypeRepository'
import { RoleTypesRepository } from 'app/Repositories/RoleType/RoleTypesRepository'
import { ConfigsRepository } from 'Configs/ConfigsRepository'
import { RoleTypesApplicationPresenter } from 'app/Access/RoleTypes/RoleTypesPage/RoleTypesApplicationPresenter'
import { toggleAnimateCutoverLogo } from 'Shared/Helpers/NavLogoAnimation'

@injectable()
export class RoleTypeAddPresenter {
  @observable
  private roleTypeRepository: RoleTypeRepository = container.get(RoleTypeRepository)

  @observable
  private roleTypesRepository: RoleTypesRepository = container.get(RoleTypesRepository)

  @observable
  private configsRepository: ConfigsRepository = container.get(ConfigsRepository)

  @observable
  public language: ILanguageService = container.get(Types.ILanguageService)

  @observable
  private roleTypesApplicationPresenter = container.get(RoleTypesApplicationPresenter)

  @observable
  public toasterGateway: ToasterService = container.get(ToasterService)

  @observable
  public isSubmitted = false

  @observable
  public errorAlertPresenter: ErrorAlertPresenter = new ErrorAlertPresenter()

  @observable
  public postSuccessCall?: () => void

  @observable
  public namePresenter = new TextInputPresenter()
    .withLabel(this.language.get('roleTypes:form:fieldName:label'))
    .isRequiredTrimWhiteSpace()

  @observable
  public descriptionPresenter = new TextInputPresenter().withLabel(
    this.language.get('roleTypes:form:fieldDescription:label')
  )

  @observable
  public contextTypeNamePresenter = new SelectPresenter(this.language)
    .withLabel(this.language.get('roleTypes:form:fieldContext:label'))
    .isRequired()
    .withInModal()
    .withMiddleware(value => {
      const rawValue = value as string
      const contextTypeId = parseInt(rawValue, 10)
      this.setCapabilitiesForContext(contextTypeId)
      return value
    })

  @observable
  public capabilitiesPresenter: MultiSelectWithCapabilitiesPresenter = null

  @observable
  public capabilityLabel: string = ''

  @observable
  public roleTypeAddForm = new GenericFormPresenter()
    .addFormInput(this.namePresenter)
    .addFormInput(this.descriptionPresenter)
    .addFormInput(this.contextTypeNamePresenter)

  public resourceTypes: { label: string; value: string }[] = []
  public capabilityTypes: { label: string; value: string }[] = []

  constructor() {
    observe(
      this.configsRepository,
      'resourceTypes',
      obj => {
        if (!!this.configsRepository.resourceTypes) {
          this.configsRepository.setResourceContexts()
          this.resourceTypes = this.configsRepository.getConfigAsLabelValue('resourceTypes')
          this.capabilityTypes = this.configsRepository.getConfigAsLabelValue('capabilityTypes')
          this.contextTypeNamePresenter.withOptions(this.configsRepository.getConfigAsLabelValue('resourceContexts'))
        }
      },
      true
    )
  }

  @computed
  public get submitted(): boolean {
    return this.roleTypesApplicationPresenter.submitted
  }

  @computed
  public get showCapabilities() {
    return !!this.contextTypeNamePresenter.value
  }

  public setCapabilitiesForContext = (contextTypeId: number) => {
    this.capabilitiesPresenter = new MultiSelectWithCapabilitiesPresenter(
      'resource_type_id',
      'capability_type_id',
      contextTypeId,
      this.language
    )
      .withPlaceholderText(this.language.get('roleTypes:form:fieldCapabilities:label'))
      .withOptions(this.resourceTypes)
      .withCapabilities(this.capabilityTypes)
      .withInModal()
  }

  public submit = async () => {
    this.isSubmitted = true
    toggleAnimateCutoverLogo(true)
    this.roleTypeAddForm.serverErrors = []
    this.errorAlertPresenter.setErrorMessages([])

    if (!this.roleTypeAddForm.isValid) {
      this.namePresenter.isDirty = true
      this.descriptionPresenter.isDirty = true
      this.contextTypeNamePresenter.isDirty = true
      this.errorAlertPresenter.setErrorMessages([this.language.get('common:formInvalid')])
      return this.processFailure()
    }

    // TODO: Replace with PM - CUX-485
    const roleTypeDto = {
      role_type: {
        name: this.namePresenter.value,
        description: this.descriptionPresenter.value,
        context_type_id: this.contextTypeNamePresenter.value,
        capabilities_attributes: this.capabilitiesPresenter.values,
        view_permission: []
      }
    }

    const response = await this.roleTypeRepository.createRoleType(roleTypeDto)

    if (!response.success) {
      if (Array.isArray(response.body)) {
        response.body.forEach((errorMsg: string) => {
          this.roleTypeAddForm.serverErrors.push(errorMsg)
        })
      }

      return this.processFailure()
    }

    if (this.postSuccessCall) this.postSuccessCall()
    await this.roleTypesRepository.safeLoad({ reload: true })
    this.roleTypesApplicationPresenter.submitted = false
    this.toasterGateway.pop(
      this.language.get('common:notification:successTitle'),
      this.language.get('roleTypes:add:toaster:notification'),
      'success'
    )
    toggleAnimateCutoverLogo(false)
    return
  }

  private processFailure = (): boolean => {
    setTimeout(() => {
      this.roleTypesApplicationPresenter.submitted = false
    }, 500)

    return false
  }

  @action
  public withPostSuccessCall = (call: () => void) => {
    this.postSuccessCall = call
    return this
  }
}
