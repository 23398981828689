import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const List = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="List" {...props}>
    <path d="M17 10a6 6 0 1 1-12 0 6 6 0 0 1 12 0Zm-3 0a3 3 0 1 0-6 0 3 3 0 0 0 6 0Zm3 14a6 6 0 1 1-12 0 6 6 0 0 1 12 0Zm-3 0a3 3 0 1 0-6 0 3 3 0 0 0 6 0Zm-3 20a6 6 0 1 0 0-12 6 6 0 0 0 0 12Zm0-3a3 3 0 1 1 0-6 3 3 0 0 1 0 6Zm31-18.5H20v3h22v-3Zm-22-14h22v3H20v-3Zm22 28H20v3h22v-3Z" fill="#000" />
  </StyledIcon>
))

List.displayName = 'List'

export { List }
