// @ts-nocheck
import styled from 'styled-components'
import { colors, px2rem } from 'app/Styles/Variables'
import { IColor } from 'Shared/Components/Molecules/ColorPicker/IColor'
import { isColorDefault } from 'Shared/Helpers/Color'
import tinycolor from 'tinycolor2'

interface IOptionInputWrapper {
  disabled?: boolean
  settingsColor?: IColor
}

export const OptionInputWrapper = styled.div<IOptionInputWrapper>`
  display: flex;
  align-items: baseline;
  width: 100%;

  > div:nth-of-type(1) {
    flex: 1 1 auto;
  }

  .icon-base {
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

    &:hover {
      color: ${colors.primaryGrey};
    }
  }

  .icon-settings {
    margin: 0 ${px2rem(8)};

    &:hover {
      color: ${({ settingsColor }) =>
        settingsColor && !isColorDefault(settingsColor) ? tinycolor(settingsColor).toHexString() : colors.primaryGrey};
    }
  }
`
