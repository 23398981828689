import { produce } from 'immer'
import { extend, keyBy } from 'lodash'
import { CallbackInterface, TransactionInterface_UNSTABLE } from 'recoil'

import { RunbookVersion, TaskListTask, TaskShowTask } from 'main/services/queries/types'
import {
  accountResponseState_INTERNAL,
  runbookCommentsResponseState_INTERNAL,
  runbookResponseState_INTERNAL,
  runbookVersionResponseState_INTERNAL,
  runbookViewState_INTERNAL
} from 'main/recoil/runbook'
import { activeRightPanel_INTERNAL, setChangedTasks } from 'main/recoil/data-access'
import { currentUserIdState } from 'main/recoil/current-user'
import {
  RunbookChangedCustomField,
  RunbookChangedTask,
  RunbookComment,
  RunbookVersionData
} from 'main/services/api/data-providers/runbook-types'

export const updateCurrentVersion =
  ({ set }: TransactionInterface_UNSTABLE) =>
  (newVersion: RunbookVersion) => {
    set(runbookResponseState_INTERNAL, prevRunbookResponse =>
      produce(prevRunbookResponse, draftRunbookResponse => {
        extend(draftRunbookResponse.runbook, { current_version: newVersion, runbook_version_id: newVersion.id })
      })
    )
  }

export const updateAddNewComments =
  ({ set, snapshot }: CallbackInterface) =>
  async ({ comments, requesterId }: { comments: RunbookComment[]; requesterId: number }) => {
    set(runbookCommentsResponseState_INTERNAL, previousState =>
      produce(previousState, draft => {
        if (draft) {
          draft.comments.push(...comments)
        }
      })
    )

    const activeRightPanel = await snapshot.getPromise(activeRightPanel_INTERNAL)
    const currentUserId = await snapshot.getPromise(currentUserIdState)
    // don't update the data for the new comment badge if the panel is open. This also covers not adding badges
    // for the current user when added through the panel, but comments are also added through bulk skipping, etc,
    // so the panel may not be open when we still don't want to update the new comment badge.
    if (currentUserId !== requesterId && activeRightPanel?.type !== 'runbook-comments') {
      set(runbookViewState_INTERNAL, previousState =>
        produce(previousState, draft => {
          draft.newCommentsCount += 1
        })
      )
    }
  }

export const updateAllChangedTasks =
  ({ set, get }: TransactionInterface_UNSTABLE) =>
  (changedTasks: RunbookChangedTask[], task?: TaskListTask | TaskShowTask) => {
    if (!changedTasks?.length) return

    const runbookVersionResponseState = get(runbookVersionResponseState_INTERNAL)
    const runbookComponents = runbookVersionResponseState.meta.runbook_components
    const runbookComponentLookup = keyBy(runbookComponents, 'id')

    setChangedTasks(set)({ changedTasks, task, runbookComponentLookup })
  }

export const updateCustomFieldsData =
  ({ set }: TransactionInterface_UNSTABLE) =>
  (changedCustomFields: RunbookChangedCustomField[]) => {
    set(accountResponseState_INTERNAL, prevAccountResponse =>
      produce(prevAccountResponse, draftAccountResponse => {
        changedCustomFields.forEach(changedCustomField => {
          const index = draftAccountResponse.meta.custom_fields.findIndex(cf => cf.id === changedCustomField.id)
          if (index !== -1) {
            draftAccountResponse.meta.custom_fields[index] = changedCustomField
          } else {
            draftAccountResponse.meta.custom_fields.push(changedCustomField)
          }
        })
      })
    )
  }

export const updateTasksAndVersion =
  (transactionInterface: TransactionInterface_UNSTABLE) =>
  ({
    versionData,
    changedTasks,
    task
  }: {
    versionData?: RunbookVersionData
    task?: TaskListTask | TaskShowTask
    changedTasks?: RunbookChangedTask[]
  }) => {
    if (versionData) {
      updateVersionData(transactionInterface)(versionData)
    }

    if (changedTasks) {
      updateAllChangedTasks(transactionInterface)(changedTasks, task)
    }
  }

// TODO: does not currently check data.id < RunBookActiveVersionModel.versionData.id) return which is done in angular.
// confirm this is still needed, see https://cutover.atlassian.net/browse/WIN-1296 from 3 years ago, and update if necessary.
export const updateVersionData =
  (transactionInterface: TransactionInterface_UNSTABLE) => (versionData: RunbookVersionData) => {
    const { set, get } = transactionInterface

    const runbookVersionResponseData = get(runbookVersionResponseState_INTERNAL)

    const updatedVersionData = produce(runbookVersionResponseData, draftRunbookVersionResponse => {
      extend(draftRunbookVersionResponse, {
        runbook_version: extend(draftRunbookVersionResponse.runbook_version, versionData)
      })
    })

    set(runbookVersionResponseState_INTERNAL, updatedVersionData)
    updateCurrentVersion(transactionInterface)(updatedVersionData.runbook_version)
  }
