// @ts-nocheck

import { observer } from 'mobx-react-lite'
import styled, { css } from 'styled-components'
import { Icon } from 'Components/Atoms'
import { colors, px2rem } from 'app/Styles/Variables'

interface IWrapperProps {
  disabled: boolean
}

const Wrapper = styled.span`
  position: relative;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: ${px2rem(30)};
  color: ${colors.text};
  outline: none;
  user-select: none;

  ${(props: IWrapperProps) =>
    props.disabled
      ? css`
          .arrow {
            display: none;
          }
        `
      : css`
          &:hover,
          &:focus {
            .arrow {
              display: flex;
            }
          }
        `}
`

const arrowCommon = css`
  width: ${px2rem(30)};
  height: ${px2rem(18)};
  border: none;
  display: none;
  justify-content: center;
  align-items: center;
  outline: none;
  padding: 0;
  box-sizing: border-box;
  position: absolute;
  color: ${colors.textLight};
  cursor: pointer;

  &:hover {
    color: ${colors.text};
  }
`

const IconStyled = styled(Icon)`
  display: flex;
  position: relative;
  align-items: center;
  width: ${px2rem(18)};
  height: ${px2rem(30)};
`

const TopArrow = styled.span`
  ${arrowCommon}
  top: -16px;
  ${IconStyled} {
    right: ${px2rem(1)};
  }
`

const BottomArrow = styled.span`
  ${arrowCommon}
  bottom: -16px;
  ${IconStyled} {
    left: ${px2rem(1)};
  }
`

const Input = styled.input`
  border: none;
  width: 30px;
  text-align: center;
  outline: none;
  box-sizing: border-box;
  color: transparent;
  text-shadow: 0 0 0 ${colors.dark};
  user-select: none;

  ${(props: IWrapperProps) =>
    props.disabled
      ? css`
          text-shadow: 0 0 0 ${colors.textLight};
        `
      : css`
          &:focus {
            text-shadow: 0 0 0 ${colors.primary};
          }
        `};
`

interface IInlineNumberProps {
  value: number
  disabled?: boolean
  range?: {
    min: number
    max: number
  }
  //provide an onChange handler or dedicated up/down handlers
  onChange?: (value: number) => void
  onUp?: () => void
  onDown?: () => void
  maxLength?: number
  maxValue?: number
}

export const InlineNumber = observer<IInlineNumberProps>(props => {
  const { value, onUp, onDown, onChange, range, disabled, maxLength, maxValue } = props

  const handleUp = () => {
    if (onUp) {
      onUp()
      return
    }
    const reachedMax = range && value + 1 === range.max
    const newValue = reachedMax ? range.min : value + 1
    onChange(newValue)
  }

  const handleDown = () => {
    if (onDown) {
      onDown()
      return
    }
    const reachedMin = range && value - 1 === range.min
    const newValue = reachedMin ? range.max : value - 1
    onChange(newValue)
  }

  const handleKeyDown = (event: KeyboardEvent) => {
    event.stopPropagation()
    switch (event.key) {
      case 'ArrowUp': {
        handleUp()
        return
      }
      case 'ArrowDown': {
        handleDown()
        return
      }
    }
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    let targetValue = event.target.value

    if (maxValue && parseInt(targetValue, 10) > maxValue) {
      targetValue = targetValue.slice(-1)
    }

    const value = parseInt(targetValue, 10)

    onChange(isNaN(value) ? 0 : value)
  }

  const moveCaretToTheEnd = (event: SyntheticEvent) =>
    (event.target as HTMLInputElement).setSelectionRange(maxLength, maxLength)

  return (
    <Wrapper onKeyDown={handleKeyDown} disabled={disabled}>
      <TopArrow onClick={handleUp} className="arrow">
        <IconStyled name="chevron-right" rotation={270} />
      </TopArrow>
      <Input value={value} onChange={handleChange} disabled={disabled} onClick={moveCaretToTheEnd} />
      <BottomArrow onClick={handleDown} className="arrow">
        <IconStyled name="chevron-right" rotation={90} />
      </BottomArrow>
    </Wrapper>
  )
})
