import { selector, selectorFamily } from 'recoil'
import { groupBy } from 'lodash'

import { CustomFieldApplyToSlug } from 'main/services/queries/types'
import { taskListResponseState_INTERNAL, taskListTaskState } from '../../..'
import { customFieldLookup } from '../../account/custom-fields'

export const runbookCustomFieldFieldValuesState = selectorFamily({
  key: 'custom-field:field-values',
  get:
    (customFieldId: number) =>
    ({ get }) => {
      return get(runbookCustomFieldFieldValueLookupState)[customFieldId]
    }
})

// TODO: Searchable CFs https://cutover.atlassian.net/browse/CFE-1423
export const runbookUsedCustomFieldIdState = selector({
  key: 'custom-fields:all-filter-used',
  get: ({ get }) => {
    const { tasks } = get(taskListResponseState_INTERNAL)
    // the custom field .used property does not appear to reflect whether it is used so
    // we have to compute whether it is used for the runbook based the field values for all
    // tasks, independent of the filtered state.
    return Array.from(
      new Set(
        tasks.flatMap(t => {
          return t.field_values
            .filter(fv => {
              if (fv.field_option_id) {
                return true
              } else if (fv.value) {
                try {
                  JSON.parse(fv.value)
                  const value = JSON.parse(fv.value)
                  return Array.isArray(value) ? value.length > 0 : value !== undefined && value !== null
                } catch (e) {
                  // catches on strings that aren't JSON but exist, so should filter in
                  return true
                }
              }
            })
            .map(fv => fv.custom_field_id)
        })
      )
    )
  }
})

export const getScopedCustomFields = selectorFamily({
  key: 'custom-fields:filter-used',
  get:
    ({
      applyToSlugs,
      scopeDisplay
    }: {
      applyToSlugs: CustomFieldApplyToSlug[]
      scopeDisplay?: 'list' | 'table' | 'search'
    }) =>
    ({ get }) => {
      const lookup = get(customFieldLookup)
      const cfIds = get(runbookUsedCustomFieldIdState)
      const customFields = cfIds.map(id => lookup[id])
      const forApplication = customFields.filter(cf => applyToSlugs.includes(cf.apply_to_slug) && !cf.archived)
      if (!scopeDisplay) {
        return forApplication
      } else {
        return forApplication.filter(cf => cf[`display_${scopeDisplay}`])
      }
    }
})

const runbookFieldValuesState = selector({
  key: 'custom-fields:filter-used:all-tasks',
  get: ({ get }) => {
    const { tasks } = get(taskListResponseState_INTERNAL)
    const taskFieldValues = tasks.flatMap(t => {
      const fieldValues = get(taskListTaskState(t.id)).field_values
      // Is this necessary? check in https://cutover.atlassian.net/browse/CFE-1423
      return fieldValues.map(fv => ({ ...fv, task_id: t.id }))
    })

    return taskFieldValues
  }
})

const runbookCustomFieldFieldValueLookupState = selector({
  key: 'custom-field:field-value-lookup',
  get: ({ get }) => {
    const fieldValues = get(runbookFieldValuesState)
    return groupBy(fieldValues, 'custom_field_id')
  }
})
