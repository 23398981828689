import { useCallback, useEffect, useMemo, useState } from 'react'
import { intervalToDuration } from 'date-fns'
import styled from 'styled-components/macro'

import { Box } from '../../layout'
import { ColorProp, themeColor } from '../../theme'
import { Text } from '../../typography'
import { formatTimeDiff } from '../../utilities'
import { FormField } from '../internal/form-field'
import { NumberField } from '../time-picker/number-field'

export type DurationPickerProps = {
  value: number // seconds
  onChange: (value: number) => void
  label: string
  hasError?: boolean
  disabled?: boolean
  readOnly?: boolean
  inlineError?: string
  name?: string
  labelSuffix?: string
  labelSuffixColor?: ColorProp
  diff?: {
    start: number // seconds
    end: number // seconds
  }
}

export const DurationPicker = ({
  value,
  label,
  onChange,
  disabled,
  readOnly = false,
  diff,
  labelSuffix,
  labelSuffixColor,
  ...restFormFieldProps
}: DurationPickerProps) => {
  const duration = useMemo(() => intervalToDuration({ start: 0, end: value * 1000 }), [value])
  const secondsInDay = 24 * 60 * 60
  const durationInDays = Math.floor(value / secondsInDay)
  const [days, setDays] = useState(durationInDays ?? 0)
  const [hours, setHours] = useState(duration.hours ?? 0)
  const [minutes, setMinutes] = useState(duration.minutes ?? 0)
  const [seconds, setSeconds] = useState(duration.seconds ?? 0)

  const updatedDuration = useMemo(() => {
    return days * 24 * 60 * 60 + hours * 60 * 60 + minutes * 60 + seconds
  }, [days, hours, minutes, seconds])

  const timeDiff = useMemo(() => {
    if (!diff) return {}
    return formatTimeDiff(diff)
  }, [diff?.end, diff?.start])

  const handleFieldChange = useCallback((field: string, num: number) => {
    if (num < 0) {
      return
    }

    switch (field) {
      case 'days':
        setDays(num)
        break
      case 'hours':
        if (num < 24) {
          setHours(num)
        } else {
          const extraDays = Math.floor(num / 24)
          const remainingHours = num % 24
          setDays(prevDays => prevDays + extraDays)
          setHours(remainingHours)
        }
        break
      case 'minutes':
        if (num < 60) {
          setMinutes(num)
        } else {
          const extraHours = Math.floor(num / 60)
          const remainingMinutes = num % 60
          setHours(prevHours => prevHours + extraHours)
          setMinutes(remainingMinutes)
        }
        break
      case 'seconds':
        if (num < 60) {
          setSeconds(num)
        } else {
          const extraMinutes = Math.floor(num / 60)
          const remainingSeconds = num % 60
          setMinutes(prevMinutes => prevMinutes + extraMinutes)
          setSeconds(remainingSeconds)
        }
        break
      default:
        break
    }
  }, [])

  useEffect(() => {
    onChange(updatedDuration)
  }, [updatedDuration, onChange])

  useEffect(() => {
    if (value !== updatedDuration) {
      setDays(duration.days ?? 0)
      setHours(duration.hours ?? 0)
      setMinutes(duration.minutes ?? 0)
      setSeconds(duration.seconds ?? 0)
    }
  }, [value])

  return (
    <FormField
      readOnly={readOnly}
      disabled={disabled}
      {...restFormFieldProps}
      label={label}
      labelSize="small"
      clickable={false}
      startIcon="stopwatch"
      labelSuffix={timeDiff.label ?? labelSuffix}
      labelSuffixColor={timeDiff.color ?? labelSuffixColor}
    >
      <Box direction="row" justify="end" gap="4px" fill pad={{ top: '4px', bottom: '4px' }}>
        <Box direction="row" align="center" flex="grow">
          <NumberField
            value={days}
            onChange={value => handleFieldChange('days', value)}
            label={`${label} days`}
            isDisabled={disabled}
            isReadOnly={readOnly}
            formatOptions={{ useGrouping: false }} // Dont want thousands separator, however unlikely
            fill
          />
          <StyledText disabled={disabled}>days</StyledText>
        </Box>
        <Box direction="row" align="center">
          <NumberField
            value={hours}
            onChange={value => handleFieldChange('hours', value)}
            label={`${label} hours`}
            isDisabled={disabled}
            isReadOnly={readOnly}
          />
          <StyledText disabled={disabled}>hrs</StyledText>
        </Box>
        <Box direction="row" align="center">
          <NumberField
            value={minutes}
            onChange={value => handleFieldChange('minutes', value)}
            label={`${label} minutes`}
            isDisabled={disabled}
            isReadOnly={readOnly}
          />
          <StyledText disabled={disabled}>mins</StyledText>
        </Box>
        <Box direction="row" align="center">
          <NumberField
            value={seconds}
            onChange={value => handleFieldChange('seconds', value)}
            label={`${label} seconds`}
            isDisabled={disabled}
            isReadOnly={readOnly}
          />
          <StyledText disabled={disabled}>secs</StyledText>
        </Box>
      </Box>
    </FormField>
  )
}

const StyledText = styled(Text)<{ disabled: boolean | undefined }>`
  color: ${({ disabled }) => themeColor(disabled ? 'text-disabled' : 'text-light')};
  margin-left: 2px;
`
