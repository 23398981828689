// @ts-nocheck
import { useEffect } from 'react'
import styled from 'styled-components'
import { unescape } from 'lodash'
import { Icon, DisplaySvgIcon, Image } from 'Components/Atoms'
import { Avatar } from '@cutover/react-ui'
import { AnchorTag, AnchorTagPresenter } from 'Components/Molecules'
import { ITableColumnTypes } from './ITableColumnTypes'
import { isDateValid, formatDate } from 'Shared/Helpers/DateService'
import { ColumnVisible, TableScrollerService } from 'Components/Organisms'
import { ConfigsRepository } from 'Configs/ConfigsRepository'
import { container } from 'app/Config/IOC'
import { colors, px2rem } from 'app/Styles/Variables'

const TableIcon = styled.div`
  height: ${px2rem(24)};
  width: ${px2rem(24)};
  line-height: ${px2rem(24)};
  margin: ${px2rem(8)} ${px2rem(4)} ${px2rem(8)} ${px2rem(0)};
`

const TableAnchorTag = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-height: ${px2rem(48)};
  text-decoration: underline;
`

const TextPlaceholder = styled.div`
  background-color: ${colors.primaryGreyHoverBackgroundColor};
  height: ${px2rem(4)};
  width: 50%;
  border-radius: ${px2rem(3)};
  margin-top: ${px2rem(18)};
`

let listRef = null

export const setListRef = el => {
  listRef = el
}

export const bindExecuteScroll = () => {
  if (listRef && listRef.scrollToItem) {
    container.get(TableScrollerService).registerScrollCall(() => {
      if (listRef && listRef.hasOwnProperty('scrollToItem') && typeof listRef.scrollToItem === 'function') {
        listRef.scrollToItem(0)
      }
    })
  }
}

const calculateColumnWidth = (column, variableWidth, width, calculatedTableWidth) => {
  let columnWidth = column.width

  if (variableWidth) {
    columnWidth = width * (column.width / calculatedTableWidth)
  }

  return columnWidth
}

export const buildHeaderRow = (
  columns: ITableColumnTypes[],
  sortedHeaders,
  sortHandler,
  configsRepository: ConfigsRepository,
  variableWidth: boolean,
  divWidth: number,
  calculatedTableWidth
) => {
  let headerRow = []
  let cssLeftPropertyValue = 0
  for (let columnIndex = 0; columnIndex < columns.length; columnIndex++) {
    if (!ColumnVisible(configsRepository, columns[columnIndex].accessor)) continue

    let columnWidth = calculateColumnWidth(columns[columnIndex], variableWidth, divWidth, calculatedTableWidth)

    let headerCell = buildHeaderCell(
      { ...columns[columnIndex], width: columnWidth, left: cssLeftPropertyValue },
      sortedHeaders,
      sortHandler
    )
    cssLeftPropertyValue += columnWidth
    headerRow.push(headerCell)
  }
  return (
    <div className="table-header" role="rowgroup" key="header-row">
      <div role="row">{headerRow}</div>
    </div>
  )
}

const buildHeaderCell = (dataItem, sortedHeaders, sortHandler) => {
  let styles = {
    width: dataItem.width,
    left: dataItem.left
  }
  let isSortedClassName: string

  let sortTrigger = dataItem => {
    sortHandler(dataItem)
    container.get(TableScrollerService).doScroll()
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    setTimeout(() => {
      bindExecuteScroll()
    }, 0)
  }, [])

  isSortedClassName = sortedHeaders.hasOwnProperty(dataItem.accessor)
    ? (isSortedClassName = ' sorted ' + sortedHeaders[dataItem.accessor])
    : ''

  const handleClick = e => {
    if (dataItem.sortable) sortTrigger(dataItem)
  }

  return dataItem.name ? (
    <div
      className={'cell ' + dataItem.accessor + isSortedClassName}
      style={styles}
      key={dataItem.accessor + '-key'}
      role="columnheader"
      onClick={handleClick}
      onKeyDown={handleClick}
      tabIndex={0}
    >
      <div className={'inner-content ' + dataItem.accessor}>{unescape(dataItem['name'])}</div>
      {dataItem.sortable && <Icon name="arrow-forward" />}
    </div>
  ) : null
}

export const buildBodyRow = (
  props,
  data,
  columns: ITableColumnTypes[],
  customRowFunction,
  isItemLoaded,
  configsRepository: ConfigsRepository,
  selectedId: number = null,
  variableWidth: boolean,
  divWidth: number,
  calculatedTableWidth: number
) => {
  const { index, style } = props
  let rowData = data.slice()[index]
  let bodyRow = []
  let loadingClass = ''
  let cssLeftPropertyValue = 0
  const disabledClass = rowData && rowData.disabled ? 'disabled' : ''

  for (let columnIndex = 0; columnIndex < columns.length; columnIndex++) {
    if (!ColumnVisible(configsRepository, columns[columnIndex].accessor)) continue
    if (!isItemLoaded(index)) {
      loadingClass = 'loading '
    }

    let columnWidth = calculateColumnWidth(columns[columnIndex], variableWidth, divWidth, calculatedTableWidth)

    let bodyCell = buildBodyCell(
      props,
      rowData ? rowData : {},
      { ...columns[columnIndex], width: columnWidth, left: cssLeftPropertyValue },
      loadingClass,
      columnIndex
    )
    cssLeftPropertyValue += columnWidth
    bodyRow.push(bodyCell)
  }

  let newStyle = style

  if (selectedId && rowData && rowData.id === selectedId) {
    newStyle = {
      ...style,
      backgroundColor: colors.primaryGreyBackgroundColor,
      borderRight: `solid 3px ${colors.primary}`,
      width: '99.7%'
    }
  }

  const clickHandler = () => (customRowFunction ? customRowFunction(rowData.id) : null)

  return (
    <div
      className={(index % 2 === 0 ? 'row even ' : 'row odd ') + loadingClass + disabledClass}
      style={newStyle}
      role="row"
      key={index}
      onClick={clickHandler}
      tabIndex={0}
      onKeyPress={clickHandler}
    >
      {bodyRow}
    </div>
  )
}

const buildBodyCell = (props, dataItem, columnRef, loadingClass: string, columnIndex: number) => {
  const { index } = props

  let styles = {
    width: columnRef.width + 'px',
    left: columnRef.left + 'px'
  }
  let cellContent = null
  let rawContent = dataItem.hasOwnProperty(columnRef.accessor) ? dataItem[columnRef.accessor] : ''

  if (loadingClass == 'loading') {
    if (columnRef.dataType === 'avatar') {
      cellContent = (
        <TableIcon>
          <Avatar />
        </TableIcon>
      )
    } else {
      cellContent = <TextPlaceholder />
    }
  } else if (columnRef.render && rawContent !== '') {
    cellContent = columnRef.render(rawContent)
  } else if (columnRef.dataType === 'boolean') {
    cellContent = rawContent?.toString() === 'true' ? 'Yes' : 'No'
  } else if (columnRef.dataType === 'image') {
    cellContent = <Image src={rawContent} />
  } else if (columnRef.dataType === 'svg_icon') {
    cellContent = (
      <TableIcon>
        <DisplaySvgIcon name={rawContent} color={dataItem.iconColor} size={24} />
      </TableIcon>
    )
  } else if (columnRef.dataType === 'avatar') {
    cellContent = (
      <TableIcon>
        <Avatar subject={dataItem} size="small" />
      </TableIcon>
    )
  } else if (columnRef.dataType === 'date') {
    if (rawContent !== null && isDateValid(rawContent)) {
      let date: string = formatDate(rawContent, 'dd MMM yyyy HH:mm')
      cellContent = date
    }
  } else if (columnRef.dataType === 'hyperlink') {
    const acnhorTagPresenter = new AnchorTagPresenter(columnRef.hyperlinkOptions.href + dataItem.id)
      .withText(rawContent)
      .withTarget(columnRef.target)
      .withCustomFunction(() => columnRef.hyperlinkOptions.customHyperlinkFunction(dataItem.id))
      .withPreventDefault(true)
      .withStopPropagation(true)
    cellContent = (
      <TableAnchorTag>
        <AnchorTag
          presenter={acnhorTagPresenter}
          extraStyles={{ fontWeight: 400, textDecoration: 'underline', color: '#72848d', fontSize: '17px' }}
        />
      </TableAnchorTag>
    )
  } else if (columnRef.dataType === 'hyperlinkOrString') {
    const anchorTagPresenter = new AnchorTagPresenter(columnRef.hyperlinkOptions.href + dataItem.id)
      .withText(rawContent)
      .withTarget(columnRef.target)
      .withCustomFunction(() => columnRef.hyperlinkOptions.customHyperlinkFunction(dataItem.id, dataItem.contextTypeId))
      .withPreventDefault(true)
      .withStopPropagation(true)
    if (dataItem.contextTypeId > 3) {
      cellContent = (
        <TableAnchorTag>
          <AnchorTag
            presenter={anchorTagPresenter}
            extraStyles={{ fontWeight: 400, textDecoration: 'none', color: '#72848d', fontSize: '17px' }}
          />
        </TableAnchorTag>
      )
    } else {
      cellContent = (
        <TableAnchorTag>
          <AnchorTag
            presenter={anchorTagPresenter}
            extraStyles={{ fontWeight: 400, textDecoration: 'underline', color: '#72848d', fontSize: '17px' }}
          />
        </TableAnchorTag>
      )
    }
  } else {
    cellContent = rawContent
  }

  const customCellFunctionHandler = columnRef.customCellFunction
    ? e => {
        e.stopPropagation()
        columnRef.customCellFunction(columnIndex, index)
      }
    : () => null

  return (
    <div
      className={'cell ' + columnRef.accessor}
      style={styles}
      id={`${columnRef.accessor}-${index}`}
      role="cell"
      key={columnIndex}
    >
      <div className="inner-content" onClick={customCellFunctionHandler} role="presentation">
        {cellContent}
      </div>
    </div>
  )
}
