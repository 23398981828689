import { useMutation, UseMutationOptions } from 'react-query'

import { useHeap } from '../../components/support-and-analytics/use-heap'
import { apiClient } from '../api'

type LogoutResponse = {
  success: boolean
}

export const useLogout = (options: UseMutationOptions<LogoutResponse, Error> = {}) => {
  const { resetAnalyticsUserIdentity } = useHeap()

  return useMutation<LogoutResponse, Error>(
    'logout',
    async () => {
      const { data } = await apiClient.delete({
        url: 'auth/sign_out'
      })
      resetAnalyticsUserIdentity()
      return data as LogoutResponse
    },
    options
  )
}
