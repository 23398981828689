// @ts-nocheck
import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { formatDate } from 'Shared/Helpers/DateService'
import { Button } from 'Components/Molecules/Button'
import { fontSizes, px2rem } from 'app/Styles/Variables'
import { Heading } from 'Components/Atoms/Heading'
import { Modal, ModalPresenter } from 'Components/Atoms/Modal'
import { ToolTip } from 'Components/Molecules/ToolTip'
import { UserAppTokenPresenter } from './UserAppTokenPresenter'
import SanitizedString from 'Shared/Helpers/SanitizedString'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`
const ModalTextWrapper = styled.p``
const RevokeButtonWrapper = styled.div``
const AttributesWrapper = styled.div`
  flex: 1;
`
const AttributeWrapper = styled.div`
  margin-top: ${px2rem(3)};
  font-size: ${fontSizes.sm};
`
const AttributeLabel = styled.div`
  display: inline-block;
  margin-right: 0.25rem;
`
const AttributeValue = styled.div`
  display: inline;
`

// Modal

const ConfirmationWrapper = styled.div`
  padding: ${px2rem(20)};
`

const ButtonContainer = styled.div`
  padding-top: ${px2rem(20)};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const StyledTextWrap = styled.div`
  word-break: break-all;
`

interface IUserAppTokenProps {
  presenter: UserAppTokenPresenter
}

export const UserAppToken = observer<IUserAppTokenProps>(({ presenter }) => {
  const {
    buttonPresenter,
    cancelRevokeButtonPresenter,
    canRevokeToken,
    confirmRevokeButtonPresenter,
    language,
    revokeOpenState,
    setRevokeOpenState,
    token
  } = presenter

  const revokeModalPresenter = new ModalPresenter()

  useEffect(() => {
    if (revokeOpenState) {
      revokeModalPresenter.onOpen()
    }
  }, [revokeOpenState])

  return (
    <Wrapper>
      <AttributesWrapper>
        <Heading rank={4}>
          <StyledTextWrap>{token.label}</StyledTextWrap>
        </Heading>
        <AttributeWrapper>
          <AttributeLabel>{language.get('userAppTokens:list:createdAt')}:</AttributeLabel>
          <AttributeValue>{formatDate(new Date(token.createdAt), 'dd MMM yyyy HH:mm')}</AttributeValue>
        </AttributeWrapper>
        <AttributeWrapper>
          <AttributeLabel>{language.get('userAppTokens:list:lastAccessedAt')}:</AttributeLabel>
          <AttributeValue>
            {token.lastAccessedAt
              ? formatDate(new Date(token.lastAccessedAt), 'dd MMM yyyy HH:mm')
              : language.get('userAppTokens:list:neverUsed')}
          </AttributeValue>
        </AttributeWrapper>
        {token.expiresAt && (
          <AttributeWrapper>
            <AttributeLabel>{language.get('userAppTokens:list:expiresAt')}:</AttributeLabel>
            <AttributeValue>{formatDate(new Date(token.expiresAt), 'dd MMM yyyy HH:mm')}</AttributeValue>
          </AttributeWrapper>
        )}
      </AttributesWrapper>
      {canRevokeToken && (
        <ToolTip
          message={language.get('userAppTokens:revoke:tooltip')}
          trigger={
            <RevokeButtonWrapper>
              <Button presenter={buttonPresenter} />
            </RevokeButtonWrapper>
          }
          position="left center"
        />
      )}
      <Modal presenter={revokeModalPresenter.withOnCloseExtra(() => setRevokeOpenState(false))}>
        <ConfirmationWrapper>
          <Heading>{language.get('userAppTokens:revoke:title')}</Heading>
          <ModalTextWrapper>
            <SanitizedString
              extraStyles={{ overflowWrap: 'break-word' }}
              input={language.get('userAppTokens:revoke:confirmation', {
                token: token.label
              })}
            />
          </ModalTextWrapper>
          <ButtonContainer>
            <Button presenter={cancelRevokeButtonPresenter} />
            <Button presenter={confirmRevokeButtonPresenter} />
          </ButtonContainer>
        </ConfirmationWrapper>
      </Modal>
    </Wrapper>
  )
})
