import deepmergelib from 'deepmerge'
import { isPlainObject } from 'is-plain-object'

type Nullable<T> = T | null | undefined

const overwriteMerge = (_: any[], sourceArray: any[]) => sourceArray

// Allows deep merging of multiple objects.
// Treats null/undefined as empty object.
// Overwrites destination arrays with source arrays.
// Only merges plain objects.
export function deepmerge<T>(...params: Nullable<any>[]): T {
  const merged = deepmergelib.all(
    params.map(p => p || {}),
    {
      arrayMerge: overwriteMerge,
      isMergeableObject: isPlainObject
    }
  )

  return merged as unknown as T
}
