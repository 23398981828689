// @ts-nocheck
import { AlertPresenter } from './AlertPresenter'
import { observable, action } from 'mobx'

export class AlertSinglePresenter extends AlertPresenter {
  @observable
  public message: string

  @action
  public withMessage(message: string) {
    this.message = message
    return this
  }
}
