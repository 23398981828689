// @ts-nocheck
import { injectable, postConstruct } from 'inversify'
import { action, observable, observe, computed, Lambda } from 'mobx'
import { container } from 'app/Config/IOC'
import { RoutingState } from 'app/Routing/RoutingState'
import { setReactDirty } from 'app/Shared/Helpers/SPASharedStateService'
import { AccountsRepository } from 'app/Repositories/Account/AccountsRepository'
import { RunbookTypesRepository } from 'app/Repositories/RunbookType/RunbookTypesRepository'
import { RunbookTypeRepository } from 'app/Repositories/RunbookType/RunbookTypeRepository'
import { FiltersRepository } from 'app/Filters/FiltersRepository'
import { CustomFieldsRepository } from 'app/Repositories/CustomField/CustomFieldsRepository'
import { IRunbookTypeVM } from 'Shared/Entities/Types/RunbookType/IRunbookTypeVM'
import { IPageVM } from 'app/Shared/Entities/IPageVM'
import { IBaseResponse } from 'Gateways/Service/IBaseResponse'
import { IRunbookTypeNewPM } from 'Shared/Entities/Types/RunbookType/IRunbookTypeNewPM'
import { Router } from 'app/Routing/Router'
import { ISortParams } from 'Components/Organisms'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'
import { Types } from 'Gateways/Service/Types'
import { toggleAnimateCutoverLogo } from 'Shared/Helpers/NavLogoAnimation'
import { IGenericFilter } from 'app/Filters/FilterEntities'

@injectable()
export class RunbookTypesViewPresenter {
  private accountsRepository: AccountsRepository = container.get(AccountsRepository)
  private runbookTypesRepository: RunbookTypesRepository = container.get(RunbookTypesRepository)
  private runbookTypeRepository: RunbookTypeRepository = container.get(RunbookTypeRepository)
  private filtersRepository: FiltersRepository = container.get(FiltersRepository)
  private customFieldsRepository: CustomFieldsRepository = container.get(CustomFieldsRepository)
  private routingState: RoutingState = container.get(RoutingState)
  private router: Router = container.get(Router)

  private language: ILanguageService = container.get(Types.ILanguageService)

  @observable
  public pageVM: IPageVM = {
    editPanelLoading: false,
    editPanelOpen: false,
    editPanelDirty: false,
    filterPanelLoading: true,
    archiveModalOpenState: false
  }

  @observable
  public filterVM: IGenericFilter[] = []

  @observable
  public submitted: boolean = false

  @observable
  public accountsVM: { [key: number]: string } = {}

  @observable
  public disposers: Lambda[] = []

  @observable
  public loading: boolean = false

  @observable
  public reloadList: boolean = false

  @observable
  public sortParams: ISortParams = {
    accessor: 'name',
    dataType: 'string',
    sortDirection: 'asc'
  }

  @observable
  public showArchivedRunbookTypes = false

  @postConstruct()
  public init() {
    this.load()
    this.router.registerRouteLeaveCallback({ routeId: 'runbookTypes', callback: this.clear })
    this.router.registerRouteEnterCallback({ routeId: 'runbookTypes', callback: this.load })
  }

  @action
  public load = async () => {
    this.loading = true
    toggleAnimateCutoverLogo(true)
    this.router.setCheckBeforeAction(() => {
      return this.pageVM.editPanelDirty
    })
    await this.loadAccounts()
    this.buildAccountDependencies()
    await this.loadRunbookTypes()
    this.setObservers()
    this.showArchivedRunbookTypes = this.routingState.currentState.query.includes('archived=1')
    toggleAnimateCutoverLogo(false)
    this.loading = false
  }

  @action
  private clear = () => {
    this.filterVM = []
    this.pageVM.editPanelOpen = false
    this.filtersRepository.clearPathFilters()
    this.clearDisposers()
    setTimeout(() => this.clearEditPanel(), 500)
  }

  public loadRunbookTypes = async () => {
    return await this.runbookTypesRepository.loadData({ sortParams: this.sortParams })
  }

  public loadRunbookType = (runbookId: number) => {
    if (this.checkEditPanelIsDirty()) return
    this.runbookTypeRepository.loadData({ id: runbookId })
  }

  public loadAccounts = async () => {
    await this.accountsRepository.safeLoadPermittedAccounts({ resource: 'runbook-type' })
  }

  private setObservers = () => {
    const loadingObserver = observe(this.runbookTypeRepository, 'loading', () => {
      this.pageVM.editPanelLoading = this.runbookTypeRepository.loading
    })
    const editObserver = observe(this.runbookTypeRepository, 'runbookTypeForEdit', () => {
      if (this.runbookTypeRepository.runbookTypeForEdit) {
        this.pageVM.editPanelOpen = true
      }
    })
    const accountObserver = observe(this.accountsRepository, 'permittedAccounts', () => {
      if (this.accountsRepository.permittedAccounts.length > 0) {
        this.buildAccountDependencies()
      }
    })
    const queryObserver = observe(this.routingState.currentState, 'query', () => {
      if (this.routingState.currentState.routeId === 'runbookTypes') {
        this.showArchivedRunbookTypes = this.routingState.currentState.query.includes('archived=1')
        this.runbookTypesRepository.loadData()
      }
    })

    const formIsDirtyObserverDisposer = observe(this.pageVM, 'editPanelDirty', () => {
      setReactDirty(this.pageVM.editPanelDirty)
    })

    this.disposers = [loadingObserver, editObserver, accountObserver, queryObserver, formIsDirtyObserverDisposer]
  }

  @action
  private clearDisposers = () => {
    this.disposers.forEach(disposer => disposer())
    this.disposers = []
  }

  public setPageVMValue = (key: string, value: boolean) => {
    this.pageVM[key] = value
  }

  @computed
  public get listVM(): IRunbookTypeVM[] {
    return this.runbookTypesRepository.runbookTypes
  }

  @computed
  public get runbookTypeVM(): IRunbookTypeVM {
    const runbookPM = this.runbookTypeRepository.runbookTypeForEdit
    if (!runbookPM) return null
    return runbookPM
  }

  @action
  public buildAccountDependencies = () => {
    const filters: IGenericFilter[] = []
    let accountLookup = {}

    filters.push({
      key: {
        reference: 'global',
        resourceId: 'true'
      },
      type: 'boolean',
      label: this.language.get('common:filterSection:filters:Global:title'),
      group: this.language.get('common:filterSection:filterGroups:Visibility:title'),
      urlTrueValueOverride: 'true',
      groupPills: true
    })

    this.accountsRepository.permittedAccounts.forEach(account => {
      filters.push({
        key: {
          reference: 'account_id',
          resourceId: account.id.toString()
        },
        type: 'resource',
        label: account.name,
        group: this.language.get('common:filterSection:filterGroups:Visibility:title'),
        groupPills: true
      })

      accountLookup[account.id] = account.name
    })

    filters.push({
      key: {
        reference: 'query'
      },
      type: 'string'
    })

    filters.push({
      key: {
        reference: 'archived'
      },
      type: 'boolean'
    })

    this.pageVM.filterPanelLoading = false
    this.accountsVM = accountLookup
    this.filterVM = filters

    this.filtersRepository.setPageFilters(filters)
  }

  @computed
  public get runbookTypesTotal(): number {
    return this.runbookTypesRepository.runbookTypesTotal
  }

  @computed
  public get runbookTypesFilteredTotal(): number {
    return this.runbookTypesRepository.runbookTypesFilteredTotal
  }

  public resetRunbookTypeForEdit = () => {
    this.runbookTypeRepository.setProgrammersModel()
  }

  public getSelectedFilters = (): IGenericFilter[] => {
    return this.filtersRepository.selectedFilters
  }

  public getLabelForResource = (reference: string, resourceId: string): string => {
    if (resourceId === 'true') return this.language.get('common:filterSection:filters:Global:title')
    return this.accountsVM[resourceId]
  }

  @action
  public updateRunbookTypePm = (key: string, value: any) => {
    this.runbookTypeRepository.runbookTypeForEdit[key] = value
  }

  public refreshRunbookTypePm = () => {
    this.runbookTypeRepository.refreshRunbookTypeForEdit()
  }

  public setRunbookTypeCreateProperty = (key: string, value: any) => {
    this.runbookTypeRepository.runbookTypeForCreation[key] = value
  }

  public clearFilters = () => {
    let filterReferencesToIgnore: string[]
    if (this.showArchivedRunbookTypes) filterReferencesToIgnore = ['archived']
    this.filtersRepository.clearPathFilters(filterReferencesToIgnore)
    this.router.refreshFilteredRoute()
  }

  public get runbookTypeLoading(): boolean {
    return this.runbookTypeRepository.loading
  }

  public saveRunbookType = async (): Promise<IBaseResponse> => {
    toggleAnimateCutoverLogo(true)
    const response = await this.runbookTypeRepository.saveRunbookType()
    this.customFieldsRepository.resetRunbookTypes()
    toggleAnimateCutoverLogo(false)
    return response
  }

  public createRunbookType = async (runbookType: IRunbookTypeNewPM): Promise<IBaseResponse> => {
    toggleAnimateCutoverLogo(true)
    const response = await this.runbookTypeRepository.createRunbookType(runbookType)
    this.customFieldsRepository.resetRunbookTypes()
    toggleAnimateCutoverLogo(false)
    return response
  }

  public getAccountIdFromVisibility = (account: string): number => {
    let foundAccountId: number
    for (const accountId in this.accountsVM) {
      const accountName = this.accountsVM[accountId]
      if (accountName === account) foundAccountId = parseInt(accountId, 10)
    }

    return foundAccountId
  }

  public can = (permissionType: string) => {
    if (permissionType === 'update') return this.runbookTypeRepository.can(permissionType)
    return this.runbookTypesRepository.can(permissionType)
  }

  public clearEditPanel = () => {
    this.runbookTypeRepository.clearRunbookTypeForEdit()
  }

  public checkEditPanelIsDirty = (): boolean => {
    if (this.pageVM.editPanelDirty) {
      return true
    }
    return false
  }

  public loadApprovalFlows = () => {
    return this.runbookTypesRepository.availableApprovalFlows.map(flow => {
      return { label: flow.name, value: flow.id }
    })
  }

  @computed
  public get repositoryLoading(): boolean {
    return this.runbookTypesRepository.loading || this.loading
  }
  public archiveRunbookType = async () => {
    toggleAnimateCutoverLogo(true)
    const response = await this.runbookTypeRepository.archive()
    this.customFieldsRepository.resetRunbookTypes()
    toggleAnimateCutoverLogo(false)
    return response
  }

  @action
  public setSubmitted = (val: boolean) => {
    this.submitted = val
  }

  @computed
  public get filtersCount(): number {
    return this.filtersRepository.selectedFilters.length
  }
}
