import { useEffect, useState } from 'react'
import { eventManager } from 'event-manager'
import { Controller, useForm } from 'react-hook-form'

import { Box, Message, Modal, SanitizedString, Select, Text, useNotify } from '@cutover/react-ui'
import { useRunbookTeamReplace } from './use-runbook-team-replace'
import { Team, ValidationRunbookTeam } from '../types'
import { useLanguage } from 'main/services/hooks'
import { apiClient } from 'main/services/api/api-client'

export type ReplaceTeamModalsProps = {
  isOpen: boolean
  runbookTeamName: string
  runbookTeamLinked: boolean
  runbookId: number
  accountId: number
  runbookVersionId: number
  runbookTeamId: number
  runbookTeams: ValidationRunbookTeam[]
  setRunbookTeams: (array: ValidationRunbookTeam[]) => void
  closeModal: () => void
  bypassNotification?: boolean
}

export type ReplaceTeamFormType = {
  team: Team | null
}

export function ReplaceTeamModals({
  isOpen,
  runbookTeamName,
  runbookId,
  accountId,
  runbookVersionId,
  runbookTeamId,
  runbookTeams,
  setRunbookTeams,
  closeModal,
  bypassNotification = false
}: ReplaceTeamModalsProps) {
  const { t } = useLanguage('runbook')
  const [isConfirmationStep, setIsConfirmationStep] = useState<boolean>(false)
  const [errorMessages, setErrorMessages] = useState<string[] | null>(null)
  const [team, setTeam] = useState<Team | null>(null)
  const mutation = useRunbookTeamReplace({ runbookId, runbookVersionId, runbookTeamId })
  const methods = useForm<ReplaceTeamFormType>({
    defaultValues: {}
  })
  const { watch } = methods
  const replacementTeam = watch('team')

  useEffect(() => {
    setTeam(replacementTeam)

    // check if there's a custom team with the same name
    const existingTeam = runbookTeams.find(team => team.name.toLowerCase() === replacementTeam?.name.toLowerCase())
    if (existingTeam && existingTeam.linked === false) {
      setErrorMessages([t('peoplePanel.teams.warning.customTeamUniqueness', { teamName: replacementTeam?.name })])
    } else {
      setErrorMessages(null)
    }
  }, [replacementTeam])

  const onModalClose = () => {
    setTeam(null)
    closeModal()
    setErrorMessages(null)
    setIsConfirmationStep(false)
  }

  const openConfirmationStep = () => {
    setIsConfirmationStep(true)
  }

  const onSubmit = (data: any) => {
    if (data.team) {
      const payload = {
        team: data.team
      }
      mutation.mutate(payload)
    }
  }

  const notify = useNotify()

  useEffect(() => {
    if (mutation?.isError) {
      const error = mutation?.error?.errors[0] || t('common:defaultApiError')
      setErrorMessages([error])
    }
  }, [mutation?.isError])

  useEffect(() => {
    if (mutation.isSuccess) {
      if (!bypassNotification) {
        notify.success(t('addUsersTeams.notification.success.notification'), {
          title: t('addUsersTeams.notification.success.title')
        })
      }

      onModalClose()
      // add runbook team for FE validation if the selected team wasn't on the rb
      // ^ what does that even mean
      if (team && runbookTeams.map(team => team.name.toLowerCase()).indexOf(team.name.toLowerCase()) !== -1) {
        setRunbookTeams(runbookTeams.concat({ name: team.name, linked: true }))
      }
      // updates runbookTeams for FE validation and takes user back to team list
      eventManager.emit('runbook-team-deleted', { deletedRunbookTeamName: runbookTeamName })
    }
  }, [mutation.isSuccess])

  return (
    <Modal
      title={t('peoplePanel.teams.replaceModalTitle')}
      open={isOpen}
      onClose={onModalClose}
      confirmText={t('common:continueButton')}
      confirmIcon="arrow-forward"
      //onClickConfirm={openConfirmationStep}
      onClickConfirm={isConfirmationStep ? methods.handleSubmit(onSubmit) : team ? openConfirmationStep : undefined}
    >
      <Message type="error" message={errorMessages} margin />
      {isConfirmationStep ? (
        <Text>
          <SanitizedString
            input={t('peoplePanel.teams.replaceConfirmation', {
              oldTeamName: runbookTeamName,
              newTeamName: team?.name
            })}
          />
        </Text>
      ) : (
        <Box gap="small" direction="column">
          <Controller
            name="team"
            control={methods.control}
            render={({ field: { onChange, value } }) => {
              return (
                <Select
                  options={[]}
                  loadOptionsDebounceTime={300}
                  label={t('runbook:peoplePanel:teams:selectReplacementTeam')}
                  labelKey="name"
                  icon="search"
                  valueKey="id"
                  minChars={2}
                  autoFocus
                  loadOptions={async input => {
                    const resp = await fetchTeams({ accountId, input })
                    return resp
                  }}
                  defaultValue={value}
                  onChange={onChange}
                />
              )
            }}
          />
        </Box>
      )}
    </Modal>
  )
}

const fetchTeams = async ({ accountId, input }: { accountId?: string | number; input?: string }): Promise<Team[]> => {
  // Note - why does api return teams array as 'team' instead of 'teams'
  const { data } = await apiClient.get<{ team: Team[] }>({
    url: `teams/list?query=${input}&account_id=${accountId}&sort_direction=asc&order=name`
  })

  return data.team ?? []
}
