import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Stopwatch = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Stopwatch" {...props}>
    <path d="M16 1h16v3H16V1Zm6.5 12h3v10h8v3h-11V13Z" fill="#000" /><path d="M24 42c9.941 0 18-8.059 18-18S33.941 6 24 6 6 14.059 6 24s8.059 18 18 18Zm0-3c-8.284 0-15-6.716-15-15 0-8.284 6.716-15 15-15 8.284 0 15 6.716 15 15 0 8.284-6.716 15-15 15ZM43.071 9.243 38.828 5 36 7.828l4.243 4.243 2.828-2.828Z" fill="#000" />
  </StyledIcon>
))

Stopwatch.displayName = 'Stopwatch'

export { Stopwatch }
