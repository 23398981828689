import { useCallback } from 'react'
import { matchPath, useMatch, useNavigate } from 'react-router-dom'

export function getRunbookVersionId(pathname?: string) {
  const path = pathname ?? window.document.location.hash.slice(1).split('?')[0]
  const match = matchPath('/app/:accountId/runbooks/:runbookId/:runbookVersionId/*', path)
  // if runbook id isn't a string number we're not on the right path
  if (isNaN(Number(match?.params?.runbookId))) {
    return null
  }

  // otherwise, if runbookVersionId is current_version or a string number, it is a valid runbook version id
  if (match?.params?.runbookVersionId === 'current_version' || !isNaN(Number(match?.params?.runbookVersionId))) {
    return match?.params?.runbookVersionId as string
  }
}

export function getRunbookId(pathname?: string) {
  const path = pathname ?? window.document.location.hash.slice(1).split('?')[0]
  const match = matchPath('/app/:accountId/runbooks/:runbookId/*', path)

  // if runbook id isn't a string number we're not on the right path
  if (isNaN(Number(match?.params?.runbookId))) {
    return null
  }

  return match?.params?.runbookId as string
}

const NON_ACCOUNT_SEGMENTS = ['access', 'settings', 'my-cutover']

export function getAccountSlug(pathname?: string) {
  const path = pathname ?? window.document.location.hash.slice(1).split('?')[0]
  const match = matchPath('/app/:maybeAccountId/*', path)

  const maybeAccountId = match?.params?.maybeAccountId

  if (!maybeAccountId || NON_ACCOUNT_SEGMENTS.includes(maybeAccountId)) {
    return null
  }

  return maybeAccountId as string
}

export const useNavigateToCurrentVersionAlias = () => {
  const navigate = useNavigate()
  const match = useMatch('/app/:accountSlug/runbooks/:runbookId/:runbookVersionId/tasks/:displayType')

  return useCallback(() => {
    const runbookId = match?.params.runbookId
    const accountSlug = match?.params.accountSlug

    navigate(`/app/${accountSlug}/runbooks/${runbookId}/current_version/tasks/react-list`)
  }, [])
}
