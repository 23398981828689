import axios from 'axios'
import { addDays } from 'date-fns'
import Cookies from 'js-cookie'
import { useMutation, UseMutationOptions } from 'react-query'

import { CurrentUser } from './use-get-validate-token'

export type LoginPayload = {
  email: string
  password: string
}

type LoginResponse = {
  headers: {
    'access-token': string
    'token-type': string
    expiry: string
    uid: string
    client: string
    [key: string]: string
  }
  data: {
    data: CurrentUser
  }
}

export const useLogin = (options: UseMutationOptions<CurrentUser, Error, LoginPayload>) => {
  return useMutation(
    'login',
    async (payload: LoginPayload) => {
      const {
        headers: { expiry, uid, client, ...headers },
        data: { data }
      } = await axios.post<LoginPayload, LoginResponse>('/api/auth/sign_in', payload)

      Cookies.set(
        'auth_headers',
        {
          'access-token': headers['access-token'],
          'token-type': headers['token-type'],
          expiry,
          uid,
          client
        },
        {
          path: '/',
          expires: addDays(new Date(), 9999),
          secure: false
        }
      )
      return data
    },
    options
  )
}

export const redirectToSSO = () => {
  const authUrl = `api/saml?auth_origin_url=${encodeURIComponent(window.location.href)}&omniauth_window_type=sameWindow`
  window.location.replace(authUrl)
}
