// @ts-nocheck
import { IPermissions } from 'app/Shared/Entities/Types/IPermissions'
import { inject, injectable } from 'inversify'
import { AuthenticationRepository } from 'app/Authentication/AuthenticationRepository'
import { observable, action } from 'mobx'
import { Types } from 'Gateways/Service/Types'
import { IServiceGateway } from 'Gateways/Service/IServiceGateway'
import { IBaseResponse } from 'Gateways/Service/IBaseResponse'
import { toggleAnimateCutoverLogo } from 'Shared/Helpers/NavLogoAnimation'

@injectable()
export abstract class PermissableRepository {
  @inject(AuthenticationRepository)
  private authenticationRepository: AuthenticationRepository

  @inject(Types.IServiceGateway)
  public serviceGateway: IServiceGateway

  @observable
  public loading: boolean = false

  @observable
  private permissions: IPermissions

  protected abstract preLoad: (args: { [key: string]: any }) => string
  protected abstract postLoad: (rawData: IBaseResponse) => void

  @observable
  public key: string

  constructor(key: string) {
    this.key = key
  }

  @action
  public loadData = async (params?: { [key: string]: any }) => {
    toggleAnimateCutoverLogo(true)
    this.loading = true
    const url = await this.preLoad(params)
    const rawData = await this.serviceGateway.get(url)
    if (rawData.success) {
      this.secure(rawData)
      await this.postLoad(rawData)
    }
    toggleAnimateCutoverLogo(false)
    this.loading = false
  }

  public can(property: string) {
    if (this.authenticationRepository && this.authenticationRepository.user && this.authenticationRepository.user.id)
      return (
        this.permissions &&
        this.permissions.hasOwnProperty(property) &&
        this.permissions[property].includes(this.authenticationRepository.user.id)
      )
  }

  @action
  private secure(rawData: any) {
    if (rawData?.body?.meta?.permissions) {
      this.permissions = rawData?.body?.meta?.permissions
    } else if (rawData.body && rawData.body[this.key] && rawData.body[this.key].permissions) {
      this.permissions = rawData.body[this.key].permissions
    }
  }

  @action
  public clearPermissables = () => {
    this.permissions = undefined
    this.loading = false
  }
}
