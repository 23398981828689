// @ts-nocheck
import { useEffect, useState, useRef } from 'react'

import { observer } from 'mobx-react-lite'
import { v4 } from 'uuid'
import styled, { css } from 'styled-components'
import { colors, fontSizes, px2rem } from 'app/Styles/Variables'
import { Icon } from 'Components/Atoms'
import { FormFieldLabel } from 'Shared/Components/Atoms/Labels/FormFieldLabel'
import { HelpText, TextInputPresenter } from 'Components/Molecules'
import { KeyCodeLookup } from 'app/Shared/Constants/Keycodes'

interface IWrapperProps {
  color: string
  formTopInputElement: boolean
  hideLabel?: boolean
}

const Wrapper = styled.div`
  position: relative;
  border-bottom: ${px2rem(1)} dashed ${(props: IWrapperProps) => props.color};
  margin-top: ${(props: IWrapperProps) => (props.formTopInputElement ? px2rem(12) : px2rem(24))};
`

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${(props: { customHiddenPrefixRender: boolean }) => props.customHiddenPrefixRender && 'flex-wrap: wrap;'}
`

const IconWrapper = styled.div`
  resize: none;
  width: ${px2rem(28)};
  i {
    display: flex;
    justify-content: flex-start;
    width: 100%;
  }
`

const PrefixWrapper = styled.div`
  display: flex;
`

interface IInputProps {
  disabled: boolean
  customHiddenPrefixRender: boolean
  narrow?: boolean
}

const inputStyles = css`
  font-size: ${fontSizes.m};
  color: ${(props: IInputProps) => (props.disabled ? colors.textLighter : colors.text)};
  -webkit-text-fill-color: ${(props: IInputProps) => (props.disabled ? colors.textLighter : colors.text)};
  border: none;
  outline: none;
  line-height: 24px;
  min-height: 32px;
  box-sizing: border-box;
  flex-grow: 1;
  padding-top: 4px;
  padding-bottom: 4px;
  background-color: transparent;
  width: ${(props: IInputProps) => (props.narrow ? '50%' : '100%')};
  ${(props: IInputProps) =>
    props.customHiddenPrefixRender
      ? `
    min-width: 130px;
    flex-basis: 130px;
  `
      : ''}
`

const Input = styled.input`
  ${inputStyles}
  &::placeholder {
    -webkit-text-fill-color: ${colors.primaryGrey};
    color: ${colors.primaryGrey};
  }
`

const TextArea = styled.textarea`
  ${inputStyles}
`

interface ITextInputProps {
  presenter: TextInputPresenter
  customPrefixRender?: ReactNode
  customHiddenPrefixRender?: ReactNode
  customSuffixRender?: ReactNode
  inputOnClick?: () => void
  extraStyles?: CSSProperties
  getFocused?: (focused: boolean) => void
  isShrunk?: boolean
  parentIsFocused?: boolean
  handleEnter?: (value: string) => void
  onFocus?: () => void
  onBlur?: () => void
  tabindex?: number
  narrow?: boolean
  formTopInputElement?: boolean
  hideLabel?: boolean
  autoFocus?: boolean
  className?: string
}

export const TextInput = observer<ITextInputProps>(props => {
  const {
    presenter,
    customPrefixRender,
    customHiddenPrefixRender,
    customSuffixRender,
    getFocused,
    inputOnClick,
    parentIsFocused,
    handleEnter,
    extraStyles,
    onFocus,
    onBlur,
    narrow,
    tabindex,
    formTopInputElement,
    hideLabel,
    autoFocus,
    className
  } = props

  const {
    value,
    onChange,
    label,
    placeholder,
    disabled,
    isValid,
    isDirty,
    required,
    iconPrefix,
    iconPrefixColor,
    helpText,
    type,
    multiline,
    helpTextToolTipPosition
  } = presenter

  const [hasBlurred, setHasBlurred] = useState(false)
  const [isFocused, setFocused] = useState(parentIsFocused ? parentIsFocused : false)
  const [id] = useState(v4())
  const isShrunk = props.isShrunk ? props.isShrunk : isFocused || (value && value.length > 0) || !!customPrefixRender
  const [computedPlaceholder, setComputedPlaceholder] = useState(label && !hideLabel ? '' : placeholder)

  const changeHandler = event => {
    onChange(event.target.value)
  }

  const focusHandler = () => {
    setFocused(true)
    getFocused && getFocused(true)
    computePlaceholder(false)

    if (onFocus) {
      onFocus()
    }
  }

  const blurHandler = () => {
    getFocused && getFocused(false)
    setFocused(!isFocused)
    setHasBlurred(true)
    computePlaceholder(true)

    if (onBlur) {
      onBlur()
    }
  }

  const handleKeyDown = key => {
    if (key.keyCode === KeyCodeLookup.enter && handleEnter) {
      handleEnter(value)
    }
  }

  let lineColor = isFocused ? colors.primary : colors.primaryGreyHoverBackgroundColor
  let color = isFocused ? colors.primary : colors.textLighter

  if (!isValid && (isDirty || hasBlurred)) {
    lineColor = colors.error
    color = colors.error
  }

  const hasPrefix = !!iconPrefix
  const labelRender = required && label ? `${label} *` : label
  const toolTipPosition = helpTextToolTipPosition ? helpTextToolTipPosition : 'left center'

  const helpTextRender = helpText ? <HelpText text={helpText} toolTipPosition={toolTipPosition} /> : null

  const ref = useRef(null)

  useEffect(() => {
    if (props.parentIsFocused === true) {
      ref.current.focus()
    }
  }, [parentIsFocused])

  /**
   * Compute the placeholder value in case it is used in conjunction with a label.
   * @param fromBlur true if call is made from the blur handler
   */
  const computePlaceholder = fromBlur => {
    if (label && !hideLabel) {
      setComputedPlaceholder(value.length > 0 || fromBlur ? '' : placeholder)
    }
  }

  if (multiline) {
    useEffect(() => {
      if (ref && ref.current) {
        //TODO: text-area is not expaning/show whole input. looks like ref.current.scrollHeight is set based on the wrong element (whole edit panel?)
        ref.current.style.height = '32px'
        ref.current.style.height = ref.current.scrollHeight + 'px'
        ref.current.style.maxHeight = '150px'
        ref.current.style.overflow = 'hidden'
        ref.current.style.resize = 'none'
      }
    }, [value])
  }

  const InputComponent = props => {
    return multiline ? <TextArea {...props} ref={ref} /> : <Input {...props} ref={ref} />
  }

  return (
    <Wrapper
      color={lineColor}
      style={extraStyles}
      formTopInputElement={formTopInputElement}
      hideLabel={hideLabel}
      className={className}
    >
      {!hideLabel && (
        <FormFieldLabel
          absolute={true}
          color={color}
          isShrunk={isShrunk}
          hasPrefix={hasPrefix}
          htmlFor={id}
          onClick={() => {
            if (inputOnClick) {
              inputOnClick()
            }

            ref.current.focus()
          }}
        >
          {labelRender}
        </FormFieldLabel>
      )}
      <InputWrapper customHiddenPrefixRender={!!customHiddenPrefixRender}>
        {iconPrefix && (
          <PrefixWrapper>
            <IconWrapper onClick={inputOnClick}>
              <Icon name={iconPrefix} color={iconPrefixColor || color} />
            </IconWrapper>
            {(parentIsFocused || value || isShrunk) && customHiddenPrefixRender && customHiddenPrefixRender}
          </PrefixWrapper>
        )}
        {customPrefixRender && customPrefixRender}
        {InputComponent({
          id,
          value,
          onChange: changeHandler,
          disabled,
          onFocus: focusHandler,
          onBlur: blurHandler,
          required,
          type,
          autoComplete: 'off',
          onClick: inputOnClick,
          placeholder: computedPlaceholder,
          maxLength: presenter.maxLength,
          onKeyDown: handleKeyDown,
          customHiddenPrefixRender: !!customHiddenPrefixRender,
          narrow,
          tabIndex: tabindex,
          autoFocus: autoFocus
        })}
        {customSuffixRender && customSuffixRender}
        {helpTextRender}
      </InputWrapper>
    </Wrapper>
  )
})
