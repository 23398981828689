import { Editor, Node, Point, Range, Element as SlateElement, Text, Transforms } from 'slate'

export const withTables = (editor: Editor) => {
  const { deleteFragment, deleteBackward, deleteForward, normalizeNode } = editor

  // Make sure table cells only contain text content
  editor.normalizeNode = entry => {
    const [node, path] = entry

    if (SlateElement.isElement(node) && (node.type === 'table-cell' || node.type === 'table-header-cell')) {
      // Check if cell node contains any block elements as children
      // If so, get rid of them
      if (Editor.hasBlocks(editor, node)) {
        const descendants = Node.descendants(node)
        let allText = ''
        for (const [descendantNode] of descendants) {
          if (Text.isText(descendantNode)) {
            allText = allText.concat(descendantNode.text + '\n')
          }
        }

        Transforms.removeNodes(editor, { at: path }) // Remove the TD/TH
        const text = allText.trim()
        const newNode = { type: node.type, children: [{ text: text }] } as Node
        Transforms.insertNodes(editor, newNode, { at: path })
        return
      }
    }

    normalizeNode(entry)
  }

  editor.deleteFragment = () => {
    const { selection } = editor
    if (selection && Range.isExpanded(selection)) {
      // If either anchor or focus fall within a table, prevent backspace
      const { anchor, focus } = selection

      const [tableAtAnchor] = Editor.nodes(editor, {
        at: anchor,
        match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'table'
      })

      const [tableAtFocus] = Editor.nodes(editor, {
        at: focus,
        match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'table'
      })

      if (tableAtAnchor && tableAtFocus) {
        // Both within table, but dont share same parent cell
        if (!areAnchorAndFocusInSameParentCell(editor)) {
          return
        }
      } else if (tableAtAnchor || tableAtFocus) {
        // If either within table, return since we dont want messed up delete
        return
      }
    }
    deleteFragment()
  }

  editor.deleteBackward = unit => {
    const { selection } = editor

    if (selection && Range.isCollapsed(selection)) {
      const [cell] = Editor.nodes(editor, {
        match: n =>
          !Editor.isEditor(n) &&
          SlateElement.isElement(n) &&
          (n.type === 'table-cell' || n.type === 'table-header-cell')
      })

      // Prevent pressing backspace if cursor at start of a cell
      if (cell) {
        const [, cellPath] = cell
        const start = Editor.start(editor, cellPath)

        if (Point.equals(selection.anchor, start)) {
          return
        }
      }

      // If previous sibling node is a table, move to end of last cell
      // This would normally be default behaviour but if cell is empty, would otherwise delete cell
      const [start] = Range.edges(selection)
      const beforePoint = Editor.before(editor, start, { unit: 'block' }) as Point
      if (!beforePoint) {
        // At root, exit here and call default
        deleteBackward(unit)
        return
      }
      const [currentNode] = Editor.node(editor, selection)
      const [previousNode, previousNodePath] = Editor.node(editor, beforePoint)
      const prevNodeRoot = editor.children[previousNodePath[0]]
      //If prev node is an empty table cell
      if (
        SlateElement.isElement(prevNodeRoot) &&
        prevNodeRoot.type === 'table' &&
        Text.isText(previousNode) &&
        previousNode.text === ''
      ) {
        if (Text.isText(currentNode) && currentNode.text === '') {
          // previousNode is the last empty cell of a table, if exists
          Transforms.removeNodes(editor, { at: start })
          Transforms.select(editor, previousNodePath)
        }
        return
      }
    }

    deleteBackward(unit)
  }

  editor.deleteForward = unit => {
    const { selection } = editor

    if (selection && Range.isCollapsed(selection)) {
      const [cell] = Editor.nodes(editor, {
        match: n =>
          !Editor.isEditor(n) &&
          SlateElement.isElement(n) &&
          (n.type === 'table-cell' || n.type === 'table-header-cell')
      })

      // Prevent deleteForward if cursor at end of cell
      if (cell) {
        const [, cellPath] = cell
        const end = Editor.end(editor, cellPath)

        if (Point.equals(selection.anchor, end)) {
          return
        }
      }

      // If next element is a table, block the delete forward
      const [tableAtNextElement] = Editor.nodes(editor, {
        at: Editor.after(editor, selection, { unit: 'block' }),
        match: n => SlateElement.isElement(n) && n.type === 'table'
      })
      if (tableAtNextElement) return
    }

    deleteForward(unit)
  }

  return editor
}

const areAnchorAndFocusInSameParentCell = (editor: Editor) => {
  const { selection } = editor
  if (!selection) return false

  const [start, end] = Range.edges(selection)
  const [commonAncestor] = Node.common(editor, start.path, end.path)

  if (Text.isText(commonAncestor) || (SlateElement.isElement(commonAncestor) && commonAncestor.type === 'table-cell')) {
    return true
  }
  return false
}
