import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const RunbookCreated = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="RunbookCreated" {...props}>
    <path d="M14 9h20a5 5 0 0 1 5 5v20a5 5 0 0 1-5 5H14a5 5 0 0 1-5-5v-1H6v1a8 8 0 0 0 8 8h20a8 8 0 0 0 8-8V14a8 8 0 0 0-8-8H14a8 8 0 0 0-8 8v1h3v-1a5 5 0 0 1 5-5Z" fill="#000" /><path d="M3 19h9v3H3v-3Zm0 7h9v3H3v-3Zm19.5-10.5h3v7h7v3h-7v7h-3v-7h-7v-3h7v-7Z" fill="#000" />
  </StyledIcon>
))

RunbookCreated.displayName = 'RunbookCreated'

export { RunbookCreated }
