import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const TaskTypeDecision = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="TaskTypeDecision" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M28.953 4.86c-2.201-3.813-7.705-3.813-9.906 0L3.775 31.312c-2.202 3.813.55 8.579 4.953 8.579h30.544c4.403 0 7.155-4.766 4.953-8.58L28.953 4.86Zm13.54 27.452c1.432 2.48-.358 5.579-3.22 5.579H8.727c-2.863 0-4.653-3.1-3.221-5.58L20.779 5.86c1.431-2.48 5.01-2.48 6.442 0l15.272 26.452Z" fill="#000" /><path d="M0 30 15 4l1.732 1-15 26L0 30ZM31.134 5l15 26 1.732-1-15-26-1.732 1Z" fill="#000" />
  </StyledIcon>
))

TaskTypeDecision.displayName = 'TaskTypeDecision'

export { TaskTypeDecision }
