import { createTaskFilterFunction, RunbookFilterType, TaskFilterContext } from './filters'
import { TaskListTask } from 'main/services/queries/types'

export const criticalToHere = createTaskFilterFunction(
  'critical_to_here',
  (task: TaskListTask, filters: RunbookFilterType, context: TaskFilterContext): boolean => {
    if (
      Object.prototype.hasOwnProperty.call(context, 'criticalToHereIds') &&
      context.criticalToHereIds instanceof Array
    ) {
      return context.criticalToHereIds?.includes(task.id)
    }

    return false
  }
)
