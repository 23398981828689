import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const TaskTypeSquare100 = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="TaskTypeSquare100" {...props}>
    <path d="M36 14v20a2 2 0 0 1-2 2H14a2 2 0 0 1-2-2V14a2 2 0 0 1 2-2h20a2 2 0 0 1 2 2Zm4 0a6 6 0 0 0-6-6H14a6 6 0 0 0-6 6v20a6 6 0 0 0 6 6h20a6 6 0 0 0 6-6V14Z" fill="#000" />
  </StyledIcon>
))

TaskTypeSquare100.displayName = 'TaskTypeSquare100'

export { TaskTypeSquare100 }
