import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const TaskTypeBox = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="TaskTypeBox" {...props}>
    <path d="M38 32.373V34a4 4 0 0 1-4 4H14a4 4 0 0 1-4-4v-1.627H8V34a6 6 0 0 0 6 6h20a6 6 0 0 0 6-6v-1.627h-2Zm0-16h2V14a6 6 0 0 0-6-6H14a6 6 0 0 0-6 6v2.373h2V14a4 4 0 0 1 4-4h20a4 4 0 0 1 4 4v2.373Z" fill="#000" />
  </StyledIcon>
))

TaskTypeBox.displayName = 'TaskTypeBox'

export { TaskTypeBox }
