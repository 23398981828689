import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import parse from 'html-react-parser'

import { Box, CheckboxGroup, Form, Message, Modal, Text } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { IntegrationActionItem } from 'main/services/queries/types'
import { ApiError } from 'main/services/api'

type IntegrationActionItemsBulkExportModalProps = {
  integrationActionItemsBulkExportModalOpen: boolean
  integrationActionItemsBulkExportModalClose: () => void
  exportIntegrationActionItems: (ids: number[]) => void
  integrationActionItems: IntegrationActionItem[]
  isLoading: boolean
  isError: boolean
  isSuccess: boolean
  error: ApiError | null
  reset: () => void
}

export type IntegrationActionItemsExportType = {
  id: number
  selectedIntegrationActionItemsId: number[]
}

export type IntegrationActionItemsBulkExportFormData = {
  selectedIds: number[]
}

export const IntegrationActionItemsBulkExportModal = ({
  integrationActionItemsBulkExportModalOpen,
  integrationActionItemsBulkExportModalClose,
  exportIntegrationActionItems,
  integrationActionItems,
  isLoading,
  isError,
  isSuccess,
  error,
  reset
}: IntegrationActionItemsBulkExportModalProps) => {
  const { t } = useLanguage('integrationSettings')

  const defaultValues = {
    selectedIds: []
  }

  const methods = useForm({
    defaultValues: defaultValues
  })

  const {
    handleSubmit,
    formState: { isValid }
  } = methods

  const onHandleActionItemBulkExportModalConfirm = async (data: IntegrationActionItemsBulkExportFormData) => {
    if (isValid) {
      exportIntegrationActionItems(data.selectedIds)
    }
  }

  useEffect(() => {
    if (isSuccess) {
      integrationActionItemsBulkExportModalClose()
      methods.reset(defaultValues)
    }
  }, [isSuccess])

  return (
    <Modal
      open={integrationActionItemsBulkExportModalOpen}
      onClose={() => {
        integrationActionItemsBulkExportModalClose()
        reset()
      }}
      onClickConfirm={handleSubmit(onHandleActionItemBulkExportModalConfirm)}
      title={t('actionItemsBulkExportModal.header')}
      confirmText={t('actionItemsBulkExportModal.confirmButton')}
      confirmIcon="export"
      loading={isLoading}
    >
      {isError && (
        <Box margin={{ bottom: '12px' }}>
          <Message type="error" message={error?.errors?.join(', ')} />
        </Box>
      )}
      <Box margin={{ bottom: '12px' }}>
        <Text>{parse(t('actionItemsBulkExportModal.modalMessage'))}</Text>
      </Box>
      <Form>
        <Box>
          <Controller
            name="selectedIds"
            control={methods.control}
            render={({ field: { onChange } }) => (
              <CheckboxGroup
                required
                label={t('actionItemsBulkExportModal.optionsLabel')}
                onChange={(event: any) => event && onChange(event.value)}
                options={integrationActionItems.map(iai => ({ value: iai.id, option: iai.id, label: iai.name }))}
              />
            )}
          />
        </Box>
      </Form>
    </Modal>
  )
}
