// @ts-nocheck
import { observer } from 'mobx-react-lite'
import { AlertSingle, ErrorAlertPresenter, AlertMulti } from '.'
import styled from 'styled-components'
import { px2rem } from 'app/Styles/Variables'

interface IAlertWrapper {
  formAlert: boolean
}

const AlertWrapper = styled.div<IAlertWrapper>`
  margin-bottom: ${({ formAlert }) => (formAlert ? px2rem(24) : px2rem(0))};
`

interface IErrorAlertProps {
  presenter: ErrorAlertPresenter
  formAlert?: boolean
  className?: string
}

export const ErrorAlert = observer((props: IErrorAlertProps) => {
  const { className, presenter } = props
  const { errorMessages, multiAlertPresenter, singleAlertPresenter } = presenter

  if (errorMessages.length === 0) {
    return null
  }

  return (
    <AlertWrapper formAlert={props.formAlert} className={className}>
      {errorMessages.length > 1 ? (
        <AlertMulti presenter={multiAlertPresenter} />
      ) : (
        <AlertSingle presenter={singleAlertPresenter} />
      )}
    </AlertWrapper>
  )
})
