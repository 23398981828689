import { MouseEvent } from 'react'

import { LegacyBox as Box, colors, Tooltip } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'

type Delta = {
  duration: number
  percentage: number
}

export type ChartDelta = {
  id: string
  delta?: Delta
}

export function createChartDeltas(deltas: ChartDelta[]) {
  return function ChartDeltas({ groupMode, indexBy, bars, showTooltip, hideTooltip }: any) {
    const labelMargin = 10

    return bars.map((barProps: any, i: number) => {
      const {
        data: { data, indexValue, id },
        x,
        y,
        width
      } = barProps

      const total =
        groupMode === 'stacked'
          ? Object.keys(data).reduce((a, key) => a + (key === indexBy ? 0 : data[key]), 0)
          : data[id]

      if (!total) {
        return null
      }

      const previousBarData = i > 0 ? bars[i - 1]?.data?.data : null
      const previousBarHasHours = previousBarData ? previousBarData.Hours > 0 : false

      const chartDelta = deltas.find(d => d.id === indexValue)
      const hasDelta = chartDelta?.delta && chartDelta?.delta?.percentage !== 0
      const showDelta = hasDelta && i !== 0 && previousBarHasHours

      return (
        <Box
          as="g"
          css={`
            cursor: ${showDelta ? 'pointer' : 'default'};
          `}
          key={`${indexValue}-${i}`}
          onMouseEnter={
            showDelta ? (event: MouseEvent<SVGGElement>) => showTooltip(<DeltaTooltip />, event) : undefined
          }
          onMouseLeave={showDelta ? hideTooltip : undefined}
        >
          <text
            x={x + width / 2}
            y={y - labelMargin}
            textAnchor="middle"
            dominantBaseline="middle"
            style={{ fontFamily: 'Inter, sans-serif', fontSize: '11px', fill: colors.text }}
          >
            <tspan>{total}</tspan>
            {showDelta && chartDelta?.delta ? <Delta delta={chartDelta.delta} /> : null}
          </text>
        </Box>
      )
    })
  }
}

function Delta({ delta: { percentage } }: { delta: Delta }) {
  const fixed = Math.abs(percentage * 100).toLocaleString('en', {
    useGrouping: false,
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  })

  const isPositive = percentage >= 0
  const { arrow, arrowOffset, sign, color } = {
    arrow: isPositive ? '\u25B2' : '\u25BC',
    arrowOffset: isPositive ? 0.5 : 0.8,
    sign: isPositive ? '+' : '-',
    color: isPositive ? colors.error : 'green'
  }

  return (
    <>
      <tspan fill={color} fontSize="8px" dx={4} dy={arrowOffset}>
        {arrow}
      </tspan>
      <tspan fill={color} dx={1} dy={arrowOffset * -1}>{`${sign}${fixed}%`}</tspan>
    </>
  )
}

function DeltaTooltip() {
  const { t } = useLanguage()

  return (
    <Tooltip content={t('dashboard:averageRunbookDurationOverTime:delta')} placement="top" isOpen>
      <Box
        css={`
          visibility: hidden;
          line-height: 0;
        `}
      >
        {t('dashboard:averageRunbookDurationOverTime:delta')}
      </Box>
    </Tooltip>
  )
}
