import { MyWorkHeader } from './header/header'
import { Subheader } from './sub-header/sub-header'
import { useDefaultLayout } from '../layout/layout-hooks'
import { Layout } from 'main/components/layout'

export const MyWorkLayout = () => {
  useDefaultLayout({
    filterPanel: false,
    subHeader: true,
    rightNav: false
  })

  return <Layout header={<MyWorkHeader />} subHeader={<Subheader />} filter={undefined} />
}
