import { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import { eventManager } from 'event-manager'
import { isAngularDisabled } from 'single-spa/react-routes'

type AngularReadyState = boolean

export const AngularReadyStateContext = createContext<AngularReadyState>(false)

/**
  Allow react to wait for / respond to a fully loaded angular app_controller. This is for example used by the react sidebar to make sure that events emitted by react are listened to by angular
 */
export const AngularAppControllerReadyProvider = ({ children }: { children: ReactNode }) => {
  const angularDisabled = isAngularDisabled()
  const [isAngularReady, setIsAngularReady] = useState(angularDisabled)

  useEffect(() => {
    const handleAngularReady = () => {
      setIsAngularReady(true)
    }

    eventManager.on('angular-ready', handleAngularReady)

    return () => {
      eventManager.off('angular-ready', handleAngularReady)
    }
  }, [])

  return <AngularReadyStateContext.Provider value={isAngularReady}>{children}</AngularReadyStateContext.Provider>
}

/**
  Hook to know if the angular app_controller has fully run.
 */
export const useAngularAppControllerReadyState = () => {
  const isAngularReady = useContext(AngularReadyStateContext)

  return isAngularReady
}
