// @ts-nocheck
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'
import { container } from 'app/Config/IOC'
import { Types } from 'Gateways/Service/Types'
import { colors } from 'app/Styles/Variables'
import { HeadingTitle, HeaderFilterIcon } from 'Components/Templates'
import { TaskTypesPageHeadingPresenter } from './TaskTypesPageHeadingPresenter'
import { ClearFilter, PillFilter } from 'Components/Molecules'
import { px2rem } from 'app/Styles/Variables'
import { TaskTypesPageMoreOptionsPopoverComponent } from './TaskTypesPageMoreOptionsPopoverComponent'
import { buildFractionString } from 'Shared/HooksAndPresenters/HeadingSection'

const HeaderWrapper = styled.div`
  width: 100%;
  height: 100%;
`

const RightIconContainer = styled.div`
  z-index: 2;
  position: absolute;
  padding-right: ${px2rem(50)};
  padding-left: ${px2rem(6)};
  top: 0;
  right: 0;
  background-color: ${colors.bg1};
  box-shadow: '${px2rem(-10)} ${px2rem(0)} ${px2rem(40)} ${px2rem(10)} ${colors.bg1}';
`

interface ITaskTypesPageHeadingComponentProps {
  filterOpen: boolean
  setFilterOpen: (val: boolean) => void
  presenter: TaskTypesPageHeadingPresenter
}

export const TaskTypesPageHeadingSection = observer<ITaskTypesPageHeadingComponentProps>(
  ({ presenter, filterOpen, setFilterOpen }) => {
    const {
      taskTypesPageMoreOptionsPopoverPresenter,
      taskTypesTotal,
      taskTypesFilteredTotal,
      clearFilters,
      showClearFilterButton,
      showArchivedTaskTypes,
      visibilityPillsPresenter,
      settingsPillPresenters,
      defaultPillsPresenter
    } = presenter

    const { countString } = buildFractionString(taskTypesFilteredTotal, taskTypesTotal)
    const language: ILanguageService = container.get(Types.ILanguageService)

    const headingTitle = showArchivedTaskTypes
      ? language.get('taskTypes:heading:title:archived')
      : language.get('taskTypes:heading:title:default')

    const Pills = () => {
      let settingPills = []

      settingsPillPresenters.forEach((pillPresenter, index) => {
        settingPills.push(<PillFilter presenter={pillPresenter} key={'setting-pill-' + index} />)
      })

      return (
        <>
          <PillFilter presenter={visibilityPillsPresenter} />
          {settingPills}
          <PillFilter presenter={defaultPillsPresenter} />
        </>
      )
    }

    return (
      <HeaderWrapper>
        <HeaderFilterIcon filterOpen={filterOpen} setFilterOpen={setFilterOpen} />
        <HeadingTitle title={headingTitle} count={countString} pills={Pills()} />
        <RightIconContainer>
          <ClearFilter clearFilters={clearFilters} showClearFilterButton={showClearFilterButton} language={language} />
          <TaskTypesPageMoreOptionsPopoverComponent presenter={taskTypesPageMoreOptionsPopoverPresenter} />
        </RightIconContainer>
      </HeaderWrapper>
    )
  }
)
