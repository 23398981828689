// @ts-nocheck
import { container } from 'app/Config/IOC'
import { observable, computed, action } from 'mobx'
import { GenericPageFormPresenter } from 'Shared/Presenters/GenericPageFormPresenter'
import { CustomFieldGroupViewPresenter, ICustomFieldGroupVM } from '../CustomFieldGroupViewPresenter'
import { TextInputPresenter } from 'Shared/Components/Molecules/TextInput/TextInputPresenter'
import { GenericFormPresenter } from '@logicroom/validator'

export enum AvailableFields {
  Name
}

export class CustomFieldGroupFormPresenter extends GenericPageFormPresenter {
  protected viewPresenter: CustomFieldGroupViewPresenter = container.get(CustomFieldGroupViewPresenter)

  @observable
  public namePresenter: TextInputPresenter = new TextInputPresenter()
    .withLabel(this.language.get('customFieldGroups:fields:name:label'))
    .isRequiredTrimWhiteSpace()
    .withMiddleware((value: string) => {
      if (this.viewModelMiddleware) {
        this.viewModelMiddleware(AvailableFields.Name, value)
      }

      if (this.dirtyCallback) {
        this.dirtyCallback()
      }

      return value
    })

  @observable
  public formValidator: GenericFormPresenter

  constructor() {
    super()
    this.formValidator = new GenericFormPresenter().addFormInput(this.namePresenter)
  }

  @action
  public inflateFormWithVM = (viewModel: ICustomFieldGroupVM) => {
    this.namePresenter.value = viewModel.name
  }

  @action
  public applyReadonly = (readOnly: boolean) => {
    this.readOnly = readOnly
    this.namePresenter.disabled = readOnly
  }

  @computed
  public get formIsValid(): boolean {
    return this.formValidator.isValid
  }

  public isFormValid = (): boolean => {
    return this.formIsValid
  }

  @action
  protected triggerFieldErrors = () => {
    this.namePresenter.isDirty = true
  }

  @action
  protected preSubmission = () => {
    this.formValidator.serverErrors = []
  }

  @action
  public reset = () => {
    this.submitted = false
    this.namePresenter.reset()
    this.formValidator.reset()

    if (this.resetCallback) {
      this.resetCallback()
    }
  }
}
