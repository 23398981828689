import { format, isToday, isYesterday } from 'date-fns'
import styled, { css } from 'styled-components'

import { Box, media, themeColor } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'

export const DayDivider = styled.div`
  color: ${themeColor('text-disabled')};
  align-items: center;
  display: flex;
  font-size: 12px;
  padding: 0 30px;
  background-color: ${themeColor('bg')};

  &::before,
  &::after {
    border-bottom: 2px solid ${themeColor('bg-1')};
    content: '';
    flex: 1;
  }

  ${media.sm(css`
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
  `)}
`

export const Day = styled.span`
  color: ${themeColor('text-disabled')};
  background-color: ${themeColor('bg')};
  border: 1px solid ${themeColor('bg-3')};
  border-radius: 2em;
  font-size: 12px;
  padding: 5px 10px;
  margin-bottom: -14px;
  width: 'fit-content';
`

export const ActivitySeparator = ({ date, index }: { date: string; index: number }) => {
  const { t } = useLanguage()

  const getDate = (key: string): string => {
    if (isToday(new Date(key))) return t('activities:feed:today')
    if (isYesterday(new Date(key))) return t('activities:feed:yesterday')
    return format(new Date(key), 'MMMM do, yyyy')
  }

  return (
    <Box align="center">
      <Day
        css={`
          margin-bottom: ${index === 0 ? '2px' : '-14px'};
        `}
      >
        {getDate(date)}
      </Day>
    </Box>
  )
}
