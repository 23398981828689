// @ts-nocheck

import styled from 'styled-components'
import { colors, breakpoints, px2rem } from 'app/Styles/Variables'

const SectionNavPanel = styled.nav`
  background-color: ${colors.secondaryHover};
  border-right: ${px2rem(1)} solid ${colors.primaryGreyHoverBackgroundColor};
  z-index: 5;
  position: relative;
  top: 0px;
  bottom: 0px;
  left: 0px;
  min-width: ${px2rem(64)};
  max-width: ${px2rem(64)};
  height: 100%;
  box-sizing: border-box;
  overflow: visible;

  @media ${breakpoints.sm} {
    display: none;
  }
`

interface ISectionNavPanelWrapperProps {
  extraStyles?: CSSProperties
  children: ReactNode
}

export const SectionNavPanelWrapper = (props: ISectionNavPanelWrapperProps) => (
  <SectionNavPanel style={props.extraStyles}>{props.children}</SectionNavPanel>
)
