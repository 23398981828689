// @ts-nocheck
import { observable } from 'mobx'
import { saveFile } from 'Helpers/.'
import { container } from 'app/Config/IOC'
import { UsersRepository } from 'app/Repositories/User/UsersRepository'
import { ButtonPresenter, PopoverPresenter } from 'Components/Molecules'
import { ModalPresenter } from 'Components/Atoms'
import { ExportUsersModalPresenter } from 'Components/Templates'
import { TableScrollerService } from 'Components/Organisms'
import { Types } from 'Gateways/Service/Types'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'

export class UserPageHeadingExportPopoverPresenter {
  @observable
  public language: ILanguageService = container.get(Types.ILanguageService)

  @observable
  private usersRepository: UsersRepository = container.get(UsersRepository)

  @observable
  public exportModalPresenter: ModalPresenter = new ModalPresenter()

  @observable
  public popoverOpen: boolean = false

  public openExportModal = () => {
    this.exportModalPresenter.onOpen()
  }

  public closeExportModal = () => {
    this.exportModalPresenter.onClose()
  }

  public setPopoverOpen = (popoverOpen: boolean) => {
    this.popoverOpen = popoverOpen
  }

  private downloadCSV = async () => {
    const response = await this.usersRepository.download('CSV')
    saveFile(response, 'text/csv', 'Users.csv')
  }

  private downloadXLSX = async () => {
    const response = await this.usersRepository.download('XLSX')
    saveFile(response, 'text/csv', 'Users.xlsx')
  }

  public exportUsersModalPresenter: ExportUsersModalPresenter = new ExportUsersModalPresenter(
    this.downloadCSV,
    this.downloadXLSX
  ).withClose(this.closeExportModal)

  @observable
  public exportModalOpenButton: ButtonPresenter = new ButtonPresenter(
    this.language.get('users:edit:exportUsersButton:static'),
    () => {
      this.openExportModal()
    }
  )
    .withIconName('download')
    .withIconPosition('left')
    .withDisplayStyle('popover')
    .withAriaLabel(this.language.get('users:edit:exportUsersButton:static'))

  @observable
  public downloadPopoverPresenter: PopoverPresenter = new PopoverPresenter().withPosition('bottom right')

  @observable
  public reloadClicked = false

  @observable
  public reloadDataButtonPresenter: ButtonPresenter = new ButtonPresenter(
    this.language.get('common:reloadButton'),
    async () => {
      this.reloadClicked = !this.reloadClicked
      this.usersRepository.loadData({})
      container.get(TableScrollerService).doScroll()
    }
  )
    .withIconName('cycle')
    .withIconPosition('left')
    .withDisplayStyle('popover')
    .withAriaLabel(this.language.get('common:reloadButton'))
}
