import { useMemo } from 'react'

import { colors } from '@cutover/react-ui'
import { CustomField, TaskListTask, User } from 'main/services/queries/types'

type CustomFieldLabelData = {
  backgroundColor?: string
  borderColor?: string
  text: string
}

export type TaskToCustomFieldLabelData = {
  [key: TaskListTask['id']]: CustomFieldLabelData[]
}

const DEFAULT_LABEL = {
  backgroundColor: colors.secondary,
  borderColor: 'transparent'
}

export const useDashboardTaskCustomFieldLabels = (
  tasks: TaskListTask[],
  customFields: CustomField[],
  users: Partial<User>[]
): TaskToCustomFieldLabelData => {
  return useMemo(() => computeDashboardTaskCustomFieldLabels(tasks, customFields, users), [tasks, customFields, users])
}

export const computeDashboardTaskCustomFieldLabels = (
  tasks: TaskListTask[],
  customFields: CustomField[],
  users: Partial<User>[]
): TaskToCustomFieldLabelData => {
  const initialValue: TaskToCustomFieldLabelData = {}
  return tasks.reduce((previousTaskToCFLabelMap, task) => {
    const taskFieldIds = task.field_values.map(fv => fv.custom_field_id)
    const taskCustomFields = customFields.filter(cf => taskFieldIds.includes(cf.id))
    const taskToCFLabelMap = previousTaskToCFLabelMap
    taskToCFLabelMap[task.id] = getLabels(task, taskCustomFields, users)
    return taskToCFLabelMap
  }, initialValue)
}

const getLabels = (task: TaskListTask, customFields: CustomField[], users: Partial<User>[]): CustomFieldLabelData[] => {
  const labels: CustomFieldLabelData[] = []

  task.field_values.forEach(fieldValue => {
    const customField = customFields.find(customField => customField.id === fieldValue.custom_field_id)
    if (
      !customField ||
      customField.archived ||
      !customField.display_list ||
      (!fieldValue.value && !fieldValue.field_option_id)
    )
      return

    switch (customField.field_type.slug) {
      case 'text':
        return labels.push({
          text: `${customField.name}: ${fieldValue.value}`,
          ...DEFAULT_LABEL
        })
      case 'endpoint':
        if (!customField.is_primary && !customField.parent_field_id) return
      case 'checkboxes':
        const val = fieldValue.value ? JSON.parse(fieldValue.value) : null
        if (Array.isArray(val) && val.length > 0) {
          const fieldData = customField.field_options.find(option => option.id === val[0])
          const name = customField.display_name || customField.name
          if (fieldData)
            return labels.push({
              text: `${name}: ${fieldData.name}${val.length > 1 ? ` + ${val.length - 1} more` : ''}`,
              backgroundColor: fieldData.color,
              borderColor: fieldData.color
            })
        }
        return
      case 'radiobox':
      case 'select_menu':
        if (fieldValue.field_option_id) {
          const fieldData = customField.field_options.find(option => option.id === fieldValue.field_option_id)
          if (fieldData)
            return labels.push({
              text: `${customField.display_name || customField.name}: ${fieldData.name}`,
              backgroundColor: fieldData.color,
              borderColor: fieldData.color
            })
        }
        return
      case 'user_select':
        const value = fieldValue.value ? JSON.parse(fieldValue.value) : null
        if (Array.isArray(value) && value.length > 0) {
          const user = users.find(user => user.id === value[0])
          if (user)
            return labels.push({
              text: `${customField.name}: ${user.name}${value.length > 1 ? ` + ${value.length - 1} more` : ''}`,
              ...DEFAULT_LABEL
            })
        }
        return
      case 'textarea':
        return // no label for textarea
    }
  })

  return labels
}
