import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Time = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Time" {...props}>
    <path d="M39 24c0-8.284-6.716-15-15-15-8.284 0-15 6.716-15 15 0 8.284 6.716 15 15 15 8.284 0 15-6.716 15-15Zm3 0c0 9.941-8.059 18-18 18S6 33.941 6 24 14.059 6 24 6s18 8.059 18 18ZM25.5 11.5h-3V24c0 .502.25.97.668 1.248l8.748 5.832 1.664-2.496-8.08-5.387V11.5Z" fill="#000" />
  </StyledIcon>
))

Time.displayName = 'Time'

export { Time }
