// @ts-nocheck
import { observable, computed } from 'mobx'
import { inject, injectable } from 'inversify'
import { container } from 'app/Config/IOC'
import { TableScrollerService } from 'Components/Organisms'
import { RunbookUsersViewPresenter } from './RunbookUsersViewPresenter'
import { RunbookUsersPageHeadingPresenter } from './RunbookUsersPageHeading/RunbookUsersPageHeadingPresenter'
import { RunbookUserListPresenter } from './RunbookUserList/RunbookUserListPresenter'

@injectable()
export class RunbookUsersPagePresenter {
  @inject(RunbookUsersViewPresenter)
  public runbookUsersViewPresenter: RunbookUsersViewPresenter

  @observable
  public runbookUsersPageHeadingPresenter: RunbookUsersPageHeadingPresenter = new RunbookUsersPageHeadingPresenter()

  @observable
  public runbookUserListPresenter: RunbookUserListPresenter = new RunbookUserListPresenter()

  private tableScrollerService: TableScrollerService = container.get(TableScrollerService)

  @computed
  public get usersDataLoading(): boolean {
    return this.runbookUsersViewPresenter.usersDataLoading
  }

  public loadList = async () => {
    await this.runbookUsersViewPresenter.loadList()
    this.tableScrollerService.doScroll()
  }
}
