import styled from 'styled-components/macro'

import { SpinnerIcon } from '../../icon'
import { colors } from '../../utils'
import { Box } from '../box'

type LoadingPanelProps = {
  overlay?: boolean
  backgroundColor?: string
  size?: number
  contained?: boolean
}

export const LoadingPanel = (props: LoadingPanelProps) => {
  const { overlay = false, backgroundColor = colors.white, size = 80, contained } = props

  const IconRender = () => (
    <IconWrapper data-testid="loading" size={size}>
      <SpinnerIcon color="bg-4" css="width: auto;" size="xlarge" />
    </IconWrapper>
  )

  return contained ? (
    <Box
      align="center"
      justify="center"
      fill
      css="text-align: center"
      height="100%"
      width="100%"
      background={(props.backgroundColor as any) ?? 'bg'}
    >
      <IconRender />
    </Box>
  ) : overlay ? (
    <OverlayWrapper color={backgroundColor}>
      <IconRender />
    </OverlayWrapper>
  ) : (
    <FixedWrapper color={backgroundColor}>
      <IconRender />
    </FixedWrapper>
  )
}

const OverlayWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${({ color }) => color};
  opacity: 0.6;
  text-align: center;
  z-index: 999;
`

const FixedWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${({ color }) => color};
  text-align: center;
  z-index: 999;
`

const IconWrapper = styled.div<{ size: number }>`
  position: absolute;
  top: ${({ size }) => `calc(50% - ${size / 2}px)`};
  left: 0;
  right: 0;
  z-index: 999;
  i {
    width: 100%;
  }
`
