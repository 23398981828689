import { Editor, Path, Transforms } from 'slate'
import { ReactEditor, useReadOnly, useSlateStatic } from 'slate-react'
import styled from 'styled-components/macro'

import { createEmptyParagraphNode } from '../utils'
import { IconButton } from '../../../button'

const removeImage = (editor: Editor, path: Path) => {
  Transforms.removeNodes(editor, { at: path })
  // If nothing left now, insert an empty paragraph
  const { children } = editor
  if (!children.length) {
    Transforms.insertNodes(editor, createEmptyParagraphNode())
  }
}

export const Image = ({ attributes, children, element }: any) => {
  const editor = useSlateStatic()
  const path = ReactEditor.findPath(editor, element)
  const readOnly = useReadOnly()

  return (
    <div {...attributes}>
      {children}
      <StyledImageWrapper contentEditable={false}>
        <StyledImage src={element.src} />
        {!readOnly && (
          <StyledButtonWrapper>
            <IconButton onClick={() => removeImage(editor, path)} label="Delete image" icon="delete" disableTooltip />
          </StyledButtonWrapper>
        )}
      </StyledImageWrapper>
    </div>
  )
}

const StyledImage = styled.img`
  display: block;
  max-width: 100%;
  max-height: 400px;
`

const StyledImageWrapper = styled.div`
  position: relative;
  margin: 16px 0;
`

const StyledButtonWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
`
