import { Heading } from 'grommet'
import styled from 'styled-components'

import { Box } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'

export const MyWorkHeader = () => {
  const { t } = useLanguage('myWork')

  return (
    <Box direction="row" fill justify="between" align="center">
      <MyTasksTitle responsive={false} level="1">
        {t('heading')}
      </MyTasksTitle>
    </Box>
  )
}

const MyTasksTitle = styled(Heading)`
  font-weight: 600;
  line-height: 72px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0;
  margin-right: 6px;
  font-size: 20px;
  color: #16161d;
`
