import { memo } from 'react'

import { CheckboxGroup } from '@cutover/react-ui'
import { FilterAccordionPanel } from '../shared/filter-accordion-panel'
import { camelToSnakeCase } from 'main/components/shared/filter/filter-hooks'
import { useCompletionTypeFilter } from 'main/recoil/data-access'
import { RunbookFilterType } from 'main/services/tasks/filtering'
import { useLanguage } from 'main/services/hooks'
import { ActiveRunModel, ConfigModel } from 'main/data-access'

type RunbookCompletionTypeFilterValueType = RunbookFilterType['ct']

export const CompletionGroup = memo(() => {
  const { t } = useLanguage('common', { keyPrefix: 'filter' })
  const run = ActiveRunModel.useGet()
  const { completionTypes } = ConfigModel.useGet()
  const [runbookCompletionTypes, setRunbookCompletionTypes] = useCompletionTypeFilter()

  return run ? (
    <FilterAccordionPanel label={t('completionType')} applied={!!runbookCompletionTypes?.length}>
      <CheckboxGroup
        plain
        a11yTitle={t('completionType')}
        options={Object.keys(completionTypes).map(key => ({
          value: camelToSnakeCase(key),
          label: completionTypes[key].name
        }))}
        value={runbookCompletionTypes}
        onChange={(event: any) =>
          event && setRunbookCompletionTypes(event.value as unknown as RunbookCompletionTypeFilterValueType)
        }
      />
    </FilterAccordionPanel>
  ) : null
})
