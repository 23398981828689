import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Video = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Video" {...props}>
    <path d="M33 30.143V38H12a8 8 0 0 1-8-8V10h21a8 8 0 0 1 7.999 7.858l7.838-5.6c1.324-.945 3.163.002 3.163 1.628v20.228c0 1.626-1.839 2.573-3.163 1.627L33 30.143ZM7 13v17a5 5 0 0 0 5 5h18V18a5 5 0 0 0-5-5H7Zm26 13.456 8 5.714V15.83l-8 5.714v4.912Z" fill="#000" />
  </StyledIcon>
))

Video.displayName = 'Video'

export { Video }
