import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const DndForwardslash = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="DndForwardslash" {...props}>
    <path d="M42 24c0 9.941-8.059 18-18 18S6 33.941 6 24 14.059 6 24 6s18 8.059 18 18Zm-3 0c0-3.474-1.181-6.672-3.163-9.215L14.785 35.837A14.935 14.935 0 0 0 24 39c8.284 0 15-6.716 15-15Zm-26.386 9.765 21.151-21.151A14.941 14.941 0 0 0 24 9C15.716 9 9 15.716 9 24c0 3.73 1.361 7.141 3.614 9.765Z" fill="#000" />
  </StyledIcon>
))

DndForwardslash.displayName = 'DndForwardslash'

export { DndForwardslash }
