import { ReactNode, useMemo } from 'react'

import { IconName, Text } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { TaskListTask } from 'main/services/queries/types'
import { useRefreshRunbookData } from 'main/recoil/data-access/updaters__TEMPORARY/shared-updates'
import { RunbookViewModel } from 'main/data-access'
import { FormRenderProps } from 'main/components/shared/form/form'

type UseTaskStagedChangedProps<T extends Record<string, any> = never> = {
  task: TaskListTask
  onSubmit?: (data: T) => void
  onSubmitAsync?: (data: T) => Promise<any>
  onClose?: () => void
  confirmText: ((formContext: FormRenderProps<T>) => string) | string
  confirmIcon?: IconName
}

export function useModalCheckTaskStageChanged<T extends Record<string, any> = never>({
  task,
  onSubmit,
  onSubmitAsync,
  onClose,
  confirmIcon,
  confirmText
}: UseTaskStagedChangedProps<T>) {
  const { t } = useLanguage('tasks')
  const initialTaskStage = useMemo(() => task.stage, [])
  const hasStageChanged = initialTaskStage !== task.stage
  const refreshRunbookData = useRefreshRunbookData()
  const closeModal = RunbookViewModel.useAction('modal:close')

  const renderContent = (content: ReactNode) => {
    if (hasStageChanged) return <Text>{t('unableToPerformActionMessage')}</Text>
    return content
  }

  const handleSubmitAwait = async (data: T) => {
    if (hasStageChanged) {
      refreshRunbookData()
      closeModal()
    } else {
      return await onSubmitAsync?.(data)
    }
  }

  const handleSubmit = (data: T) => {
    if (hasStageChanged) {
      refreshRunbookData()
      closeModal()
    } else {
      onSubmit?.(data)
    }
  }

  const handleClose = () => {
    if (hasStageChanged) refreshRunbookData()
    onClose?.()
  }

  return {
    handleSubmitAwait,
    handleSubmit,
    handleClose,
    confirmIcon: hasStageChanged ? null : confirmIcon,
    confirmText: hasStageChanged ? t('unableToPerformActionConfirmText') : confirmText,
    errors: hasStageChanged ? [t('unableToPerformAction')] : undefined,
    renderContent,
    hasCancelButton: !hasStageChanged
  }
}
