// @ts-nocheck
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { px2rem } from 'app/Styles/Variables'
import { withPresenter } from 'app/Config/withPresenter'
import { container } from 'app/Config/IOC'
import { Types } from 'Gateways/Service/Types'
import { ILanguageService } from 'Helpers/Language/ILanguageService'
import { buildFractionString } from 'Shared/HooksAndPresenters/HeadingSection'
import { HeadingTitle } from 'Components/Templates'
import { AccountsPageHeadingPresenter } from './AccountsPageHeadingPresenter'

const HeaderWrapper = styled.div`
  width: 100%;
  height: 100%;
`

const TitleWrapper = styled.div`
  padding-left: ${px2rem(16)};
`

interface IAccountsPageHeadingComponentProps {
  placeholder?: any
  presenter: AccountsPageHeadingPresenter
}

const AccountsPageHeadingSection = observer<IAccountsPageHeadingComponentProps>(({ presenter }) => {
  const { totalFilteredAccounts, totalAccountsCount } = presenter

  const language: ILanguageService = container.get(Types.ILanguageService)
  const { countString } = buildFractionString(totalFilteredAccounts, totalAccountsCount)

  return (
    <HeaderWrapper>
      <TitleWrapper>
        <HeadingTitle title={language.get('accounts:heading:title')} count={countString} />
      </TitleWrapper>
    </HeaderWrapper>
  )
})

export default withPresenter(AccountsPageHeadingPresenter)(AccountsPageHeadingSection)
