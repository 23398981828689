// @ts-nocheck

import styled from 'styled-components'
import { colors, breakpoints } from 'app/Styles/Variables'

const PageMainWrapper = styled.div`
  position: relative;
  background-color: ${colors.bg1};
  height: 100%;
  width: 100%;
  top: 0;
  display: flex;
  flex-direction: row;
  overflow: hidden;

  @media ${breakpoints.sm} {
    flex-direction: column;
  }
`

interface IPageWrapperProps {
  extraStyles?: CSSProperties
  children: ReactNode
}

export const PageWrapper = (props: IPageWrapperProps) => (
  <PageMainWrapper style={props.extraStyles}>{props.children}</PageMainWrapper>
)
