import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Rocket = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Rocket" {...props}>
    <path d="M34.79 16.767a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" fill="#000" /><path d="m22.808 33.89 3.535 9.193 8.485-8.485-1.204-6.823c5.547-4.49 10.795-11.657 8.983-22.168-10.512-1.813-17.68 3.436-22.168 8.982l-6.824-1.204L5.13 21.87l9.192 3.536 8.486 8.485Zm8.12-4.137.675 3.828-4.117 4.117-1.886-4.903-.02-.049a40.662 40.662 0 0 0 5.347-2.993ZM18.46 17.286a40.72 40.72 0 0 0-2.993 5.346l-.05-.02-4.902-1.885 4.117-4.116 3.828.675Zm6.613 12.378c-.572.282-1.091.519-1.537.713l-5.7-5.7c.194-.446.432-.964.714-1.537.978-1.988 2.469-4.575 4.526-7.035 3.749-4.484 9.17-8.357 16.841-7.81.548 7.673-3.325 13.093-7.808 16.842-2.461 2.057-5.048 3.548-7.036 4.527Z" fill="#000" /><path d="M16.444 40.255c-1.454 1.453-5.458 2.192-8.288 2.543-1.733.215-3.026.285-3.026.285s.07-1.293.285-3.026c.351-2.83 1.09-6.834 2.543-8.288a6 6 0 0 1 8.486 8.486Zm-2.123-2.12.001-.002a3 3 0 0 0-4.242-4.242h-.001c-.01.014-.1.12-.238.392a8.097 8.097 0 0 0-.456 1.158c-.312.974-.564 2.154-.759 3.348-.052.319-.1.634-.142.94.306-.043.621-.09.94-.142 1.194-.195 2.374-.447 3.348-.759.485-.155.87-.31 1.159-.456.271-.137.378-.227.39-.238Z" fill="#000" />
  </StyledIcon>
))

Rocket.displayName = 'Rocket'

export { Rocket }
