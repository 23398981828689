import { useRecoilCallback, useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil'

import {
  appViewWithContext,
  componentPropsWithContextAndId,
  fieldValuesWithContext,
  listItemOpenWithContextAndId,
  runbookAppViewsWithOrder
} from '../apps/selectors'
import {
  appComponentProps,
  appConnectionLost,
  appFieldValues,
  appHeaderView,
  appModal,
  appModalLoadingState,
  appViews,
  incidentTimezone
} from '../apps/apps-atoms'

/* -------------------------------------------------------------------------- */
/*                                 Comp Props                                 */
/* -------------------------------------------------------------------------- */
export const useAppsComponentPropsWithContextAndIdValue = (ctx: { context: string; id: string }) =>
  useRecoilValue(componentPropsWithContextAndId(ctx))
export const useSetAppsComponentProps = () => useSetRecoilState(appComponentProps)

/* -------------------------------------------------------------------------- */
/*                                 FieldValues                                */
/* -------------------------------------------------------------------------- */
export const useAppsFieldValuesWithContextState = (ctx: string) => useRecoilState(fieldValuesWithContext(ctx))
export const useAppsFieldValuesCallback = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        return await snapshot.getPromise(appFieldValues)
      },
    []
  )
export const useSetAppsFieldValues = () => useSetRecoilState(appFieldValues)

/* -------------------------------------------------------------------------- */
/*                                    Modal                                   */
/* -------------------------------------------------------------------------- */
export const useAppsModalValue = () => useRecoilValue(appModal)
export const useAppsModalState = () => useRecoilState(appModal)
export const useSetModalState = () => useSetRecoilState(appModal)
export const useResetAppsModalState = () => useResetRecoilState(appModal)
export const useAppsModalLoadingState = () => useRecoilState(appModalLoadingState)
export const useSetAppsModalLoadingState = () => useSetRecoilState(appModalLoadingState)

/* -------------------------------------------------------------------------- */
/*                                  Panel Apps                                */
/* -------------------------------------------------------------------------- */
export const useSetAppViews = () => useSetRecoilState(appViews)
export const useAppViewWithContextValue = (context: string) => useRecoilValue(appViewWithContext(context))
export const useRunbookAppViewsWithOrderValue = (rbId: string) => useRecoilValue(runbookAppViewsWithOrder(rbId))

/* -------------------------------------------------------------------------- */
/*                                  Header App                                */
/* -------------------------------------------------------------------------- */

export const useAppHeaderViewValue = () => useRecoilValue(appHeaderView)
export const useSetAppHeaderView = () => useSetRecoilState(appHeaderView)

/* -------------------------------------------------------------------------- */
/*                                  Timezone                                  */
/* -------------------------------------------------------------------------- */

export const useAppsTimezoneValue = () => useRecoilValue(incidentTimezone)
export const useSetAppsTimezone = () => useSetRecoilState(incidentTimezone)

/* -------------------------------------------------------------------------- */
/*                                  ListItem                                  */
/* -------------------------------------------------------------------------- */

export const useAppsListItemOpenWithContextState = (ctx: { context: string; id: string }) =>
  useRecoilState(listItemOpenWithContextAndId(ctx))

/* -------------------------------------------------------------------------- */
/*                                 Connection                                 */
/* -------------------------------------------------------------------------- */
export const useSetAppsConnectionLost = () =>
  useRecoilCallback(
    ({ set }) =>
      (state: boolean) =>
        set(appConnectionLost, state)
  )
export const useAppsConnectionLostState = () => useRecoilState(appConnectionLost)
