import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Strikethrough = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Strikethrough" {...props}>
    <path d="M31.59 15.222c-.192-1.944-1.05-3.489-2.573-4.637-1.523-1.148-3.438-1.721-5.744-1.721-1.625 0-3.057.278-4.296.835-1.227.557-2.193 1.33-2.898 2.318-.693.977-1.04 2.091-1.04 3.341 0 .92.2 1.727.597 2.42A5.83 5.83 0 0 0 17.15 19.5h-4.152c-.695-1.152-1.043-2.488-1.043-4.006 0-1.875.5-3.522 1.5-4.943 1.012-1.432 2.375-2.545 4.091-3.34C19.273 6.402 21.21 6 23.358 6c2.17 0 4.09.398 5.761 1.193 1.67.796 2.995 1.892 3.972 3.29.988 1.386 1.517 2.966 1.585 4.739h-3.085ZM42 22.5H6v3h18.309l.464.136c1.443.42 2.71.904 3.8 1.45 1.103.545 1.961 1.22 2.575 2.028.613.795.92 1.784.92 2.966 0 1.397-.392 2.625-1.176 3.681-.773 1.046-1.841 1.864-3.205 2.455-1.352.58-2.897.87-4.636.87-1.568 0-2.989-.245-4.261-.734-1.273-.5-2.307-1.227-3.103-2.181-.795-.955-1.25-2.12-1.363-3.495H11.05c.125 1.841.688 3.46 1.688 4.858 1 1.398 2.374 2.494 4.124 3.29 1.75.784 3.813 1.176 6.188 1.176 2.523 0 4.688-.432 6.494-1.295 1.807-.875 3.194-2.057 4.16-3.546.965-1.489 1.448-3.165 1.448-5.028 0-1.455-.284-2.7-.852-3.733a8.085 8.085 0 0 0-2.233-2.642c-.11-.087-.222-.173-.334-.256H42v-3Z" fill="#000" />
  </StyledIcon>
))

Strikethrough.displayName = 'Strikethrough'

export { Strikethrough }
