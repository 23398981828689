import { useContext, useEffect, useRef } from 'react'

import { RunbookResponse } from 'main/services/api/data-providers/runbook-types'
import { RunbookChannelContext } from 'main/services/api/websocket-providers'

type WebsocketHandler = (data: RunbookResponse | null) => void

/** @deprecated we are not currently subscribing to the runbook websocket channel with this
    on any runbook page. All handling is consolidated into the RunbookChannelSubscriber.
 *
 * If you need to use this hook, please pair or seek a second opinion from someone in CFE.
 * As we are in the process of building the runbook with recoil it may be that the feature
 * can be written there instead
 **/
export const useRunbookWebsocket = () => {
  const runbookChannelResponse = useContext(RunbookChannelContext)

  if (runbookChannelResponse === undefined) {
    throw new Error('You are not currently nested within an RunbookChannelProvider.')
  }

  const handlerRef = useRef<WebsocketHandler | null>(null)

  useEffect(() => {
    handlerRef.current?.(runbookChannelResponse)
  }, [runbookChannelResponse])

  const listen = (handler: WebsocketHandler) => {
    handlerRef.current = handler
  }

  return { listen }
}
