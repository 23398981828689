import { ReactNode, useEffect } from 'react'
import * as Sentry from '@sentry/react'

import { LoadingPage } from 'main/components/authentication/loading-page'
import { CurrentUser, useGetValidateToken } from 'main/services/queries/use-get-validate-token'
import { CurrentUserModel } from 'main/data-access'

type CurrentUserSetterProps = {
  children: ReactNode
  user?: CurrentUser | null
}

export const CurrentUserSetter = (props: CurrentUserSetterProps) => {
  const currentUser = CurrentUserModel.useGet()
  const setCurrentUser = CurrentUserModel.useSetCurrentUser()
  const { data, isLoading, isError } = useGetValidateToken({ enabled: !props.user })
  const user = data || props.user

  useEffect(() => {
    if (user) {
      setCurrentUser(user)

      Sentry.getCurrentScope().setUser({
        id: user.id,
        username: user.handle,
        email: user.email
      })
    } else {
      Sentry.setUser(null)
    }

    return () => {
      Sentry.setUser(null)
    }
  }, [user])

  return isLoading ? (
    <LoadingPage />
  ) : isError ? (
    <h1>Something went wrong.</h1>
  ) : currentUser ? (
    <>{props.children}</>
  ) : null
}
