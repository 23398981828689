// @ts-nocheck
import { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import styled, { css } from 'styled-components'
import { colors, breakpoints, px2rem } from 'app/Styles/Variables'
import { Icon } from 'Components/Atoms/Icon'
import { ButtonPresenter } from './ButtonPresenter'
import { TDisplayStyle } from './TDisplayStyle'

interface IStyledButtonProps {
  displayStyle: TDisplayStyle
  disabled: boolean
  hasIcon: boolean
  shadowFocus?: boolean
  hidden?: boolean
}

const primaryStyle = css`
  background-color: ${colors.primary};
  line-height: 34px;
  color: white;
  padding: 0 16px;
  border-radius: 20px;
  font-weight: 400;
  font-size: 15px;
  border: 1px solid ${colors.primary};
  &:hover {
    background-color: ${colors.primaryHover};
  }

  ${({ shadowFocus }) => shadowFocus && `&:focus {box-shadow: 0 0 ${px2rem(10)} ${px2rem(3)} ${colors.secondaryGrey};}`}

  ${(props: IStyledButtonProps) => {
    if (props.hasIcon) {
      return 'padding-left: 8px;'
    }
  }}
`

const secondaryStyle = css`
  background-color: white;
  color: ${colors.textLighter};
  border: 1px solid ${colors.secondary};
  &:hover {
    background-color: ${colors.secondaryHover};
  }
`

const primaryInvertStyle = css`
  padding: 0 4px;
  color: ${colors.primary};
  border: none;
`

const secondaryInvertStyle = css`
  padding: 0 4px;
  border: none;
  color: ${colors.textLightest};
  &:hover {
    color: ${colors.primaryGreyHover};
  }
`

const anchoredStyle = css`
  background-color: ${colors.primaryDark};
  color: white;
  width: 100%;
  line-height: 48px;
  border-radius: 0px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border: none;
  &:hover {
    background-color: ${colors.primaryDarker};
  }
`

const anchoredDisabledStyle = css`
  width: 100%;
  color: ${colors.primaryGrey};
  background-color: ${colors.primaryGreyBackgroundColor};
  border: 1px solid ${colors.primaryGreyBackgroundColor};

  line-height: 48px;
  border-radius: 0px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border: none;
  &:hover {
    background-color: ${colors.primaryGreyHoverBackgroundColor};
    cursor: not-allowed;
    color: ${colors.white};
  }
`

const primaryGreyStyle = css`
  color: ${colors.primaryGrey};
  background-color: ${colors.primaryGreyBackgroundColor};
  border: 1px solid ${colors.primaryGreyBackgroundColor};
  width: 100%;
  text-align: center;
  margin-top: 0;
  margin-left: 0;
  &:hover {
    color: ${colors.primaryGreyHover};
    background-color: ${colors.primaryGreyHoverBackgroundColor};
    border: 1px solid ${colors.primaryGreyHoverBackgroundColor};
  }
`

const disabledStyle = css`
  color: ${colors.primaryGrey};
  background-color: ${colors.primaryGreyBackgroundColor};
  border: 1px solid ${colors.primaryGreyBackgroundColor};
  cursor: not-allowed;
`

const hiddenStyle = css`
  display: none;
`

const disabledStyles = css`
  ${disabledStyle}
  &:hover {
    ${disabledStyle}
  }
`

const addButtonStyle = css`
  z-index: 5;
  position: absolute;
  bottom: 16px;
  right: 16px;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  line-height: 64px;
  text-align: center;
  box-shadow: 0 2px 4px ${colors.secondary};
  padding: 0 16px;

  &:hover {
    background-color: ${colors.primaryHover};
    box-shadow: 0 4px 16px ${colors.secondary};
  }
`

const popoverButtonStyle = css`
  color: ${colors.primaryGreyHover};
  background-color: ${colors.white};
  padding: 6px 8px;
  line-height: 24px;
  display: block;
  white-space: nowrap;
  text-align: left;

  &:hover {
    color: ${colors.text};
    background-color: ${colors.secondaryHover};
  }

  @media ${breakpoints.sm} {
    max-width: 230px;
  }
`

const StyledButton = styled.button`
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;

  ${(props: IStyledButtonProps) => {
    if (props.disabled) {
      if (props.displayStyle === 'anchored') {
        return [anchoredDisabledStyle]
      } else {
        return [primaryStyle, disabledStyles]
      }
    } else if (props.hidden) {
      return hiddenStyle
    } else {
      switch (props.displayStyle) {
        case 'primary':
          return primaryStyle
        case 'secondary':
          return [primaryStyle, secondaryStyle]
        case 'primary-invert':
          return primaryInvertStyle
        case 'secondary-invert':
          return [primaryInvertStyle, secondaryInvertStyle]
        case 'anchored':
          return [primaryStyle, anchoredStyle]
        case 'primary-grey':
          return [primaryStyle, primaryGreyStyle]
        case 'add-button':
          return [primaryStyle, addButtonStyle]
        case 'popover':
          return popoverButtonStyle
      }
    }
  }}
`

interface IStyledTextProps {
  padTextLeft: boolean
  padTextRight: boolean
}

const StyledText = styled.span`
  padding-left: ${(props: IStyledTextProps) => (props.padTextLeft ? '8px' : '0')};
  padding-right: ${(props: IStyledTextProps) => (props.padTextRight ? '8px' : '0')};
  width: 100%;
  margin-left: auto;
`

interface IButtonProps {
  presenter: ButtonPresenter
  extraStyles?: CSSProperties
  type?: 'submit' | 'reset' | 'button'
  tabindex?: number
  shadowFocus?: boolean
  dataTestId?: string
}

export const Button = observer<IButtonProps>(({ extraStyles, type, tabindex, shadowFocus, presenter, dataTestId }) => {
  const { displayStyle, onClick, text, loadingText, iconName, iconPosition, isLoading, disabled, ariaLabel, hidden } =
    presenter

  const iconSize = displayStyle === 'anchored' || text === '' ? '24px' : '18px'

  const [showIcon, setShowIcon] = useState(false)

  useEffect(() => {
    setShowIcon(!!iconName || isLoading)
  }, [iconName, isLoading])

  let IconRender = () => {
    return isLoading ? (
      <Icon name="spinner" size={iconSize} extraStyles={{ width: iconSize }} />
    ) : (
      <Icon name={iconName} size={iconSize} extraStyles={{ width: iconSize }} />
    )
  }

  const padTextRight = showIcon && iconPosition === 'right'
  const padTextLeft = showIcon && iconPosition === 'left'

  let isDisabled = isLoading || disabled

  const localClick = (event: SyntheticEvent) => {
    event.preventDefault()
    onClick()
  }

  return (
    <StyledButton
      shadowFocus={shadowFocus}
      tabIndex={tabindex}
      type={type}
      displayStyle={displayStyle}
      onClick={!isLoading ? localClick : null}
      disabled={isDisabled}
      hasIcon={iconName ? true : false}
      style={extraStyles}
      aria-label={ariaLabel}
      data-testid={dataTestId}
      hidden={hidden}
    >
      {iconPosition === 'left' && showIcon && <IconRender />}
      {text && (
        <StyledText padTextLeft={padTextLeft} padTextRight={padTextRight}>
          {isLoading ? loadingText : text}
        </StyledText>
      )}
      {iconPosition === 'right' && showIcon && <IconRender />}
    </StyledButton>
  )
})
