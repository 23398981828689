// @ts-nocheck
import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { withPresenter } from 'app/Config/withPresenter'
import { EditPanelFormWrapper } from 'Components/Templates'
import { CustomFieldEditPresenter } from './CustomFieldEditPresenter'
import { CustomFieldFormPresenter } from 'app/Settings/CustomFields/CustomFieldsPage/CustomFieldForm/CustomFieldFormPresenter'
import { CustomFieldFormSection } from 'app/Settings/CustomFields/CustomFieldsPage/CustomFieldForm/CustomFieldFormSection'
import { Submitted } from 'Shared/Entities/Enums/Submitted'
import { IEditSectionProps } from 'Shared/Entities/IEditSectionProps'

interface ICustomFieldEditComponentProps extends IEditSectionProps {
  presenter: CustomFieldEditPresenter
  editFormPresenter: CustomFieldFormPresenter
  submittedState: Submitted
  setSubmittedState: (submittedState: Submitted) => void
}

const CustomFieldEditSection = observer<ICustomFieldEditComponentProps>(
  ({
    presenter,
    editFormPresenter,
    setArchiveResponseErrors,
    archiveState,
    setArchiveState,
    editPostSuccessCallback,
    archivePostSuccessCallback,
    submittedState,
    setSubmittedState
  }) => {
    const {
      title,
      mode,
      saveConfirm,
      save,
      reset,
      close,
      canArchive,
      archiveButtonCallback,
      archiveTooltip,
      archive,
      editPanelSaving,
      showRefresh,
      refreshTooltip,
      refresh,
      confirmModalPresenter,
      triggerScroll,
      setTriggerScroll
    } = presenter

    presenter.withFormPresenter(editFormPresenter)
    presenter.withArchiveSetters(setArchiveState, archivePostSuccessCallback, setArchiveResponseErrors)
    presenter.withEditSetters(setSubmittedState, editPostSuccessCallback)

    useEffect(() => {
      if (archiveState === Submitted.SubmittedAndConfirmed) {
        archive()
      }
    }, [archiveState])

    useEffect(() => {
      if (submittedState === Submitted.SubmittedAndConfirmed) {
        save()
      }
    }, [submittedState])

    return (
      <EditPanelFormWrapper
        heading={title}
        mode={mode}
        showArchive={canArchive}
        archiveCallback={archiveButtonCallback}
        archiveTooltip={archiveTooltip}
        singleLineArchiveTooltip
        closeCallback={close}
        saveCallback={saveConfirm}
        resetCallback={reset}
        editPanelSaving={editPanelSaving}
        showRefresh={showRefresh}
        refreshTooltip={refreshTooltip}
        singleLineRefreshTooltip={true}
        refreshCallback={refresh}
        confirmModalPresenter={confirmModalPresenter}
        triggerScroll={triggerScroll}
        setTriggerScroll={setTriggerScroll}
      >
        <CustomFieldFormSection presenter={editFormPresenter} formType="edit" />
      </EditPanelFormWrapper>
    )
  }
)

export default withPresenter(CustomFieldEditPresenter)(CustomFieldEditSection)
