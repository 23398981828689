import { BarChart, BarChartData, colors } from '@cutover/react-ui'
import { MrdDashboardWidget } from '../../../widgets/account/mrd-dashboard-widget'
import { AnalyticsComponentProps, CompletedLiveRunsData } from '../../../widgets/types'
import { getTimeIntervalBarChart, useTimeIntervalBarChart } from '../use-time-interval-bar-chart'

type CompletedLiveRunsWidgetProps = AnalyticsComponentProps<CompletedLiveRunsData>

const CHART_DATA_ATTRIBUTE_MAPPING = { runbooks_count: 'Runbooks' }

export const CompletedLiveRunsWidget = (props: CompletedLiveRunsWidgetProps) => {
  const { media } = props

  switch (media) {
    case 'screen':
      return <BrowserWidget {...props} />
    case 'email':
      return <EmailWidget {...props} />
    case 'print':
      return <EmailWidget {...props} />
  }
}

const BrowserWidget = ({ data }: CompletedLiveRunsWidgetProps) => {
  const chartValues = useTimeIntervalBarChart(data.values, CHART_DATA_ATTRIBUTE_MAPPING)

  return <WidgetContent name={data.name} values={chartValues} />
}

const EmailWidget = ({ data }: CompletedLiveRunsWidgetProps) => {
  const chartValues = getTimeIntervalBarChart(data.values, CHART_DATA_ATTRIBUTE_MAPPING)

  return <WidgetContent name={data.name} values={chartValues} />
}

const WidgetContent = ({ name, values }: { name: string; values: BarChartData[] }) => {
  return (
    <MrdDashboardWidget title={name}>
      <BarChart
        data={values}
        height={300}
        colors={{ Runbooks: colors.primary }}
        yAxis={{ id: 'runbooks', label: 'Runbooks' }}
        xAxis={{ id: 'month', label: '' }}
      />
    </MrdDashboardWidget>
  )
}
