import { KeyboardEventHandler } from 'react'
import styled from 'styled-components'

import { colors, LegacyFlex as Flex, px2rem, RunbookTypeIcon, LegacyText as Text, Tooltip } from '@cutover/react-ui'
import { RunbookTypeType } from 'main/services/queries/types'

export type RunbookTypeCardProps = {
  runbookType: RunbookTypeType
  onSelect: (runbookType: RunbookTypeType) => void
}

export const RunbookTypeCard = ({ runbookType, onSelect }: RunbookTypeCardProps) => {
  const { id, name, description, icon_name, icon_color } = runbookType

  const onElementKeyDown: KeyboardEventHandler<HTMLDivElement> = event => {
    if (event.code === 'Space' || event.code === 'Enter') {
      event.preventDefault()
      onSelect(runbookType)
    }
  }

  return (
    <Card
      onKeyDown={onElementKeyDown}
      tabIndex={0}
      role="listitem"
      aria-label={name}
      onClick={() => onSelect(runbookType)}
      aria-describedby={'descriptionId' + id}
    >
      <RunbookTypeIconWrapper>
        <RunbookTypeIcon icon={icon_name} color={icon_color} />
      </RunbookTypeIconWrapper>
      <Flex flexDirection="column" css="overflow: auto;">
        <Tooltip content={name + '\n' + description} placement={'bottom'}>
          <Text
            height={'22px'}
            size={17}
            color={colors.textDefault}
            weight="bold"
            onClick={() => onSelect(runbookType)}
            truncate
          >
            {name}
          </Text>
          <Text
            id={'descriptionId' + id}
            color={colors.textLight}
            size={13}
            onClick={() => onSelect(runbookType)}
            truncate
          >
            {description}
          </Text>
        </Tooltip>
      </Flex>
    </Card>
  )
}

const Card = styled(Flex)`
  border: ${px2rem(1)} solid ${colors.bg3};
  border-radius: ${px2rem(16)};
  background-color: ${colors.white};
  cursor: pointer;
  max-width: ${px2rem(245)};
  flex: 50%;
  padding: ${px2rem(11)};
  &:hover {
    background-color: ${colors.bg1};
  }
  &:focus {
    background-color: ${colors.bg1};
  }
`

const RunbookTypeIconWrapper = styled(Flex)`
  float: left;
  padding-right: ${px2rem(12)};
`
