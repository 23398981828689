// @ts-nocheck
import { container } from 'app/Config/IOC'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { withPresenter } from 'app/Config/withPresenter'
import { HeadingTitle } from 'Shared/Components/Templates'
import { Types } from 'Gateways/Service/Types'
import { CustomFieldGroupHeadingPresenter } from './CustomFieldGroupHeadingPresenter'
import { buildFractionString } from 'Shared/HooksAndPresenters/HeadingSection'
import { px2rem } from 'app/Styles/Variables'

const HeaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin-left: ${px2rem(16)};
`

interface ICustomFieldGroupsPageHeadingComponentProps {
  presenter: CustomFieldGroupHeadingPresenter
}

const CustomFieldGroupHeadingSection = observer<ICustomFieldGroupsPageHeadingComponentProps>(({ presenter }) => {
  const { totalListCount } = presenter

  const { countString } = buildFractionString(totalListCount, totalListCount)
  const language = container.get(Types.ILanguageService)

  return (
    <HeaderWrapper>
      <HeadingTitle title={language.get('customFieldGroups:heading:title')} count={countString} />
    </HeaderWrapper>
  )
})

export default withPresenter(CustomFieldGroupHeadingPresenter)(CustomFieldGroupHeadingSection)
