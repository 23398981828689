import styled, { css } from 'styled-components/macro'

import { Box, BoxProps } from '../../layout'
// only being used temporarily in this thie file that is in line for refactor
import { resolveColor } from '../../theme'
import { TextProps } from '../../typography'
import { transition } from '../../utils'
import { createFormFieldBorder } from '../internal/form-field'

// WARNING - THIS FILE IS PARTIAL LEGACY!!!! It should be fully refactored but needs more work to get
// the autosizing textarea to work properly. Could get it to work with the old appropach but wasn't able to migrate
// it over after some time and had to move on, but would like to revisit. Please do not export or use components from
// this file they were removed as part of previous work and replaced with the components in form/internal/shared-components

export type FieldStateProps = {
  error?: boolean
  disabled?: boolean
  readOnly?: boolean
  animate?: boolean
  focused?: boolean
  required?: boolean
}

export const FieldLabel = styled.label<FieldStateProps & TextProps>`
  white-space: nowrap;
  pointer-events: none;
  line-height: unset;
  ${props => props.animate && transition('xfast')};

  ${({ error, disabled, theme }) =>
    css`
      color: ${resolveColor(error ? 'error' : disabled ? 'text-disabled' : 'text-light', theme)};
    `}

  ${({ required }) =>
    required &&
    css`
      &:after {
        padding-left: 4px;
        content: '*';
      }
    `};
`

export const FormFieldWrapper = (props: FieldStateProps & BoxProps & { animated?: boolean; withBorder?: boolean }) => {
  if (!props.animated && !props.withBorder) {
    return <>{props.children}</>
  } else {
    return (
      <Box width="100%" pad={{ top: props.animated ? '13px' : '0', bottom: props.withBorder ? '17.5px' : '0' }}>
        <FormFieldBorder width="100%" {...props}>
          {props.children}
        </FormFieldBorder>
      </Box>
    )
  }
}

// NOTE why can't this use FormFieldBorder defined in form-field-components
const FormFieldBorder = styled(props => <Box {...props} />)<
  FieldStateProps & BoxProps & { animated?: boolean; withBorder?: boolean; disabled?: boolean }
>`
  ${({ withBorder }) =>
    withBorder &&
    css`
      padding-bottom: 1.5px;
    `}
  ${({ withBorder, error, theme, disabled }) => {
    return withBorder && createFormFieldBorder(resolveColor(error ? 'error' : disabled ? 'bg-2' : 'bg-4', theme))
  }}

  &:focus,
  &:active,
  &:focus:active,
  &:focus-within,
  &:focus-within:active {
    ${({ withBorder, error, disabled, theme }) => {
      return withBorder && createFormFieldBorder(resolveColor(error ? 'error' : disabled ? 'bg-2' : 'primary', theme))
    }}
  }
`

export const FormField = styled(Box)<FieldStateProps & BoxProps & { animated?: boolean; withBorder?: boolean }>`
  font-size: 15px;
  width: 100%;
  min-height: 32px;
  justify-content: center;
  line-height: 1;
  position: relative;

  &:focus,
  &:focus-within {
    ${FieldLabel} {
      color: ${({ error, disabled, theme }) =>
        css`
          ${disabled
            ? resolveColor('text-disabled', theme)
            : error
            ? resolveColor('error', theme)
            : resolveColor('primary', theme)}
        `};
    }

  input[type='text' i] {
    padding: 0;
  }
`
