import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Heartbeat = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Heartbeat" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M30.11 13.604 24.882 35.91c-.595 2.54-4.195 2.585-4.853.06l-3.83-14.68-1.055 2.638a2.5 2.5 0 0 1-2.321 1.572H5v-3h7.485l1.493-3.735c.896-2.24 4.132-2.037 4.74.297l3.704 14.197 5.217-22.26c.592-2.526 4.164-2.59 4.846-.087l3.16 11.588H43v3h-7.736a2.5 2.5 0 0 1-2.412-1.842L30.11 13.604Z" fill="#000" />
  </StyledIcon>
))

Heartbeat.displayName = 'Heartbeat'

export { Heartbeat }
