import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Bold = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Bold" {...props}>
    <path d="M12.684 41h13.542c7.66 0 11.418-3.904 11.418-9.115 0-5.064-3.594-8.037-7.155-8.217v-.326c3.267-.768 5.848-3.055 5.848-7.122 0-4.983-3.594-8.675-10.847-8.675H12.684V41Zm6.06-5.064v-9.801h6.681c3.74 0 6.06 2.287 6.06 5.276 0 2.663-1.83 4.525-6.223 4.525h-6.518Zm0-14.163v-9.229h6.126c3.56 0 5.407 1.879 5.407 4.46 0 2.94-2.385 4.77-5.538 4.77h-5.995Z" fill="#000" />
  </StyledIcon>
))

Bold.displayName = 'Bold'

export { Bold }
