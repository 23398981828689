import { Box, ColorProp, Icon, IconName } from '@cutover/react-ui'

// TODO this logic is shared with status pill & message so make common
const STATUS_ATTR_MAP = {
  info: { fg: 'text-light', bg: 'bg-1-alpha' },
  idea: { fg: 'primary', bg: 'primary-bg-alpha' },
  success: { fg: 'success', bg: 'success-bg' },
  warning: { fg: 'warning', bg: 'warning-bg' },
  error: { fg: 'error', bg: 'error-bg' },
  highlight: { fg: 'text-light', bg: 'star-bg-alpha' }
}

const SIZE_MAP = {
  small: '24px',
  medium: '32px',
  large: '40px'
}

// TODO: this needs consolidating with other global status colours / icons
export type FeatureIconStatus = keyof typeof STATUS_ATTR_MAP

export const FeatureIcon = ({
  status,
  icon,
  size = 'medium'
}: {
  icon: IconName
  status?: FeatureIconStatus
  size?: 'small' | 'medium' | 'large'
}) => {
  return (
    <Box
      align="center"
      background={STATUS_ATTR_MAP[status ? status : 'info'].bg as ColorProp}
      round="xsmall"
      width={SIZE_MAP[size]}
      height={SIZE_MAP[size]}
      justify="center"
    >
      <Icon size={size} icon={icon} color={STATUS_ATTR_MAP[status ? status : 'info'].fg} aria-hidden />
    </Box>
  )
}
