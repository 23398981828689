// eslint-disable-next-line import/no-named-as-default
import Axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios'
import Cookies from 'js-cookie'

const portString = process.env.API_PORT ? `:${process.env.API_PORT}` : ''
const { protocol, hostname } = window.location

// Work in progress but should be used with the react runbook work. You can
// easily write your requests with standard axios configuration options, e.g.,
// to include an abort signal.

// still need to handle errors -- either catch them and use a handler or
// create another interceptor that switches on error type and does something
// NOTE: The error.response.data returns the payload that we need for the
// run validation modal when responds with a 422. It is accessed and transformed
// in a very convoluted with the stable api client, but it's as simple as sending the
// request with both the success response payload type AND the error response payload type,
// instead of the generic ApiError, and then reading the data.
// NOTE: Not all error's have a response attached. We assume this in our current api client
// and it shows console logs all the time for trying to call functions on an undefined
// error.response.

const apiClient = Axios.create({
  baseURL: `${protocol}//${hostname}${portString}/api/`,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    'Access-Control-Allow-Origin': '*',
    Accept: 'application/json',
    'Cache-Control': 'no-store, max-age=0',
    Account: '',
    'Request-Origin': 'react'
  }
})

// *****************************************
// *****************************************

// request interceptors
configureRequestAuthHeaders(apiClient)

// response interceptors
setAuth(apiClient)
setAuthFromResponseException(apiClient)

// *****************************************
// *****************************************

function configureRequestAuthHeaders(instance: AxiosInstance) {
  instance.interceptors.request.use(request => {
    const blob = getCookie('auth_headers')
    const authHeaders = blob ? JSON.parse(blob) : {}
    request.headers = {
      ...(request.headers ?? {}),
      ...authHeaders,
      browserHash: window.sessionStorage.getItem('browserHash')
    }

    return request
  })
}

function handleResponseAuth(response: AxiosResponse) {
  const { headers } = response

  const authHeaders = getCookie('auth_headers')
  const oldToken = authHeaders ? JSON.parse(authHeaders) : null
  const oldTokenExpiry = oldToken?.expiry ?? 0
  const newTokenExpiry = headers['expiry']

  if (newTokenExpiry !== '' && Number(newTokenExpiry) >= Number(oldTokenExpiry)) {
    setCookie(
      'auth_headers',
      JSON.stringify({
        'access-token': headers['access-token'],
        client: headers['client'],
        expiry: headers['expiry'],
        'token-type': headers['token-type'],
        uid: headers['uid']
      })
    )
  }

  return response
}

function setAuth(instance: AxiosInstance) {
  instance.interceptors.response.use(handleResponseAuth)
}

export type ResponseData = {
  status?: number
  success: boolean
  successMessages?: string[]
  errorMessages?: string[]
  messages?: string
  errorCode?: string
  errors?: string[]
  body?: any
  samlAuthRequest?: boolean
  code?: string
}

function setAuthFromResponseException(instance: AxiosInstance) {
  instance.interceptors.response.use(
    response => response,
    function (error: AxiosError) {
      // The request was made and the server responded with a status code that falls out of the range of 2xx
      // but there is still a response body
      if (error.response) {
        handleResponseAuth(error.response)
      }

      return Promise.reject(error)
    }
  )
}

function getCookie(key: string) {
  return document ? Cookies.get(key) || null : null
}

function setCookie(key: string, value: string) {
  return Cookies.set(key, value, { expires: 7, path: '/', secure: true, sameSite: 'strict' })
}

export const apiClient_UNSTABLE = apiClient
