import { createTaskFilterFunction, RunbookFilterType } from './filters'
import { TaskListTask } from 'main/services/queries/types'

export const fixedEnd = createTaskFilterFunction('fe', (task: TaskListTask, filters: RunbookFilterType): boolean => {
  const hasFixedEnd = (task: TaskListTask): boolean => {
    // Invalid dates generated by JS will be NaN,
    // typeof NaN is 'number' 🙀
    // NaN > 0 returns false 👍🏻
    return typeof task.end_fixed === 'number' && task.end_fixed > 0
  }
  return filters.fe ? hasFixedEnd(task) : !hasFixedEnd(task)
})
