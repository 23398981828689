// @ts-nocheck
import { injectable, inject } from 'inversify'
import { observable, action } from 'mobx'
import { unescape } from 'lodash'
import { GenericListRepository } from '../GenericListRepository'
import { IBaseResponse } from 'Gateways/Service/IBaseResponse'
import { ICentralTeamPM } from 'Shared/Entities/Types/Teams/ICentralTeamPM'
import { QueryStringBuilder } from 'Shared/Helpers/QueryStringBuilder'

interface ICentralTeamDTO {
  id: number
  name: string
  color: string
  users_count: number
  updated_at: string
}

@injectable()
export class CentralTeamsRepository extends GenericListRepository {
  constructor() {
    super('meta')
  }

  @inject(QueryStringBuilder)
  public queryStringBuilder: QueryStringBuilder

  @observable
  public centralTeams: ICentralTeamPM[] = []

  @observable
  public filteredListTotal: number = 0

  @observable
  public offSet: number = 0

  @observable
  public batchLoadNumber: number = 20

  public preLoad = (params: { [key: string]: any }): string => {
    const { accountId, runbookVersionId, offSet, value } = params
    this.offSet = offSet

    const queryParams: { [key: string]: any } = {
      account_id: accountId,
      offset: this.offSet,
      limit: this.batchLoadNumber,
      order: 'users_count',
      sort_direction: 'desc'
    }

    if (runbookVersionId) {
      queryParams.runbook_version_id = runbookVersionId
    }

    const query = value ? `&query=${value}` : ''

    return `teams/list?${this.queryStringBuilder.getCustomQueryString(queryParams)}${query}`
  }

  @action
  public postLoad = (rawData: IBaseResponse) => {
    this.filteredListTotal = rawData.body.meta.filtered_results_count
    const centralTeams = this.getCentralTeamsFromAPIData(rawData)
    this.centralTeams = this.offSet === 0 ? centralTeams : [...this.centralTeams, ...centralTeams]
  }

  private getCentralTeamsFromAPIData = (rawData: IBaseResponse): ICentralTeamPM[] =>
    rawData.body.team.map(
      (teamDto: ICentralTeamDTO): ICentralTeamPM => ({
        id: teamDto.id,
        name: unescape(teamDto.name),
        color: teamDto.color,
        usersCount: teamDto.users_count,
        updatedAt: teamDto.updated_at
      })
    )

  @action
  public clear = () => {
    this.clearInherited()
    this.centralTeams = []
  }
}
