import {
  forwardRef,
  KeyboardEvent,
  memo,
  MouseEventHandler,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { Collapsible, Keyboard, KeyboardProps, TextProps, ThemeContext } from 'grommet'
import styled, { css } from 'styled-components'

import { BrandIconName, ICON_NAMES, IconName } from '@cutover/icons'
import {
  AddGroupButton,
  Box,
  BoxProps,
  BrandIcon,
  Icon,
  Menu,
  MenuListItem,
  MenuListItemProps,
  resolveColor,
  SidebarIconButton,
  Text,
  TextInput,
  useTheme
} from '@cutover/react-ui'
import { useSidebarNavContext } from '../nav-context'

type NavItemProps = BoxProps & {
  children?: ReactNode
  compact?: boolean
  tip?: string
  isActive?: boolean
  isSelected?: boolean
  isFocusVisible?: boolean
  isOpen?: boolean
  icon?: BrandIconName | IconName
  label: string
  level?: number
  loading?: boolean
  expandable?: boolean
  suffix?: ReactNode
  // hmm how to distinguish the two types of delete? (e.g., use cases are: x for removing favorite vs menu item for deleting saved view)
  onRemove?: () => void
  onDelete?: () => void
  onClick?: () => void
  onRename?: (newName: string) => void
  moveMenuItems?: MenuListItemProps[]
  className?: string
}

export const NavItem = forwardRef<HTMLDivElement, NavItemProps>(
  (
    {
      children,
      level,
      compact,
      tip,
      label,
      icon,
      isOpen,
      isActive,
      isFocusVisible,
      isSelected,
      loading,
      suffix,
      // should expandable and removable (x on the item) be mutually exclusive?
      moveMenuItems,
      onClick,
      onRename,
      onRemove,
      onDelete,
      className
    },
    ref
  ) => {
    const theme = useTheme()
    const { view: navView } = useSidebarNavContext()
    const [showMenuIcon, setShowMenuIcon] = useState(false)
    const [showRemoveIcon, setShowRemoveIcon] = useState(false)
    const [showEditInput, setShowEditInput] = useState(false)
    const [showMoveMenu, setShowMoveMenu] = useState(false)
    const [blurred, setBlurred] = useState(false)
    const hasMenu = !!(onRename || onDelete || moveMenuItems?.length)
    const [canShowMenu, setCanShowMenu] = useState(hasMenu)
    const [isMenuOpen, setIsMenuOpen] = useState(false)

    const iconColor = resolveColor(
      isActive || isOpen
        ? 'text-on-primary'
        : icon === 'caret-right'
        ? 'text-on-primary-disabled'
        : 'text-on-primary-light',
      {
        ...theme,
        ...{ dark: false }
      }
    )

    const handleMouseEnter = () => {
      setShowMenuIcon(true)
      if (onRemove) setShowRemoveIcon(true)
    }

    const handleMouseLeave = () => {
      if (!isMenuOpen) setShowMenuIcon(false)
      setShowRemoveIcon(false)
    }

    const handleClickRename = () => {
      setShowEditInput(true)
    }

    const handleClickDelete = () => {
      onDelete?.()
    }

    const handleClickMove: MenuListItemProps['onClick'] = event => {
      setShowMoveMenu(true)
      // @ts-ignore grommet typing issue but needed (vs stopPropagation)
      event.stopImmediatePropagation()
    }

    const handleClickBack: MenuListItemProps['onClick'] = event => {
      setShowMoveMenu(false)
      // @ts-ignore grommet typing issue but needed (vs stopPropagation)
      event.stopImmediatePropagation()
    }

    const moveItems = useMemo(() => {
      return moveMenuItems?.map(item => ({
        ...item,
        onClick: (event: any) => {
          item.onClick?.(event)
          setShowMoveMenu(false)
        }
      })) as MenuListItemProps[]
    }, [moveMenuItems])

    const handleClickRemove: MouseEventHandler<HTMLDivElement> = event => {
      event.stopPropagation()
      onRemove?.()
    }

    useEffect(() => {
      setShowMoveMenu(false)
      setCanShowMenu(false)
    }, [navView])

    useEffect(() => {
      if (!canShowMenu) {
        setCanShowMenu(hasMenu)
      }
    }, [canShowMenu, hasMenu])

    const handleClick = () => {
      if (!showEditInput) onClick?.()
    }

    const handleSubmitRename = useCallback(
      (name: any) => {
        onRename?.(name)
        setBlurred(true)
      },
      [onRename]
    )

    const handleFocus = () => {
      setBlurred(false)
    }

    // NOTE this should eventually be replaced with new menuListItem
    const item = (
      <NavItemBox
        ref={ref}
        background={isActive ? 'nav-item-bg-active' : isSelected ? 'nav-item-bg-hover' : undefined}
        hoverIndicator={isActive ? undefined : 'nav-item-bg-hover'}
        round={compact ? 'full' : '8px'}
        pad="8px"
        width={compact ? '40px' : '100%'}
        height="40px"
        onClick={handleClick}
        align="center"
        direction="row"
        justify="between"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onFocus={handleFocus}
        responsive={false}
        flex={false}
        css={
          isFocusVisible
            ? `
          outline-offset: 0;
          outline: 2px solid ${resolveColor('primary', theme)}
        `
            : blurred
            ? `
          outline: none;
              `
            : undefined
        }
      >
        <Box
          direction="row"
          gap="8px"
          align="center"
          responsive={false}
          pad={{ left: level === 2 ? '32px' : undefined }}
        >
          <Box width={{ min: '24px' }} justify="center" responsive={false}>
            {icon &&
              (isBrandIconType(icon) ? (
                <BrandIcon color={iconColor} icon={icon} size="20px" css="margin-left:2px;" />
              ) : (
                <Icon
                  color={iconColor as any}
                  icon={icon}
                  size="24px"
                  css={`
                    transition: transform 0.25s cubic-bezier(0.35, 0, 0.25, 1);
                    transform: rotate(${isOpen ? '90deg' : '0deg'});
                  `}
                />
              ))}
          </Box>
          {showEditInput ? (
            <EditNameInput onSubmit={handleSubmitRename} setShowInput={setShowEditInput} name={label} level={level} />
          ) : (
            <Text
              size="15px"
              color={isActive || isOpen ? 'nav-item-text-active' : 'nav-item-text'}
              truncate="tip"
              tip={tip}
              tipPlacement="right"
              weight={isOpen || isActive ? 500 : undefined}
            >
              {label}
            </Text>
          )}
          {suffix}
        </Box>
        {showRemoveIcon && (
          <ThemeContext.Extend value={{ dark: false }}>
            <Box responsive={false} width={{ min: '24px' }} height="24px" onClick={handleClickRemove}>
              <NavItemIcon icon="close" size="large" />
            </Box>
          </ThemeContext.Extend>
        )}
        {loading && (
          <ThemeContext.Extend value={{ dark: false }}>
            <Box responsive={false} width={{ min: '24px' }} height="24px" animation="rotateRight">
              <Icon icon="spinner" size="24px" color="nav-item-text-active" />
            </Box>
          </ThemeContext.Extend>
        )}
        {showMenuIcon && canShowMenu && !showEditInput && (
          <ThemeContext.Extend value={{ dark: false }}>
            <NavItemMenu
              onClickDelete={onDelete ? handleClickDelete : undefined}
              onClickRename={onRename ? handleClickRename : undefined}
              onClickMove={moveMenuItems?.length ? handleClickMove : undefined}
              showMoveMenu={showMoveMenu}
              setShowMoveMenu={setShowMoveMenu}
              setIsMenuOpen={setIsMenuOpen}
              moveMenuItems={[
                {
                  label: 'Back',
                  icon: 'arrow-back',
                  onClick: handleClickBack
                } as MenuListItemProps,
                ...(moveItems ?? [])
              ]}
            />
          </ThemeContext.Extend>
        )}
      </NavItemBox>
    )

    return (
      <Box className={className} flex={false}>
        {item}
        <Collapsible open={isOpen}>{children}</Collapsible>
      </Box>
    )
  }
)

type NavItemMenuProps = {
  onClickRename?: MenuListItemProps['onClick']
  onClickDelete?: MenuListItemProps['onClick']
  onClickMove?: MenuListItemProps['onClick']
  moveMenuItems?: MenuListItemProps[]
  setShowMoveMenu?: (show: boolean) => void
  setIsMenuOpen?: (open: boolean) => void

  showMoveMenu?: boolean
}

function NavItemMenu({
  onClickRename,
  onClickDelete,
  onClickMove,
  moveMenuItems,
  setShowMoveMenu,
  setIsMenuOpen,
  showMoveMenu
}: NavItemMenuProps) {
  const items: MenuListItemProps[] =
    showMoveMenu && moveMenuItems?.length
      ? moveMenuItems
      : [
          ...(onClickRename
            ? [
                {
                  label: 'Rename',
                  key: 'Rename',
                  onClick: onClickRename,
                  icon: 'edit',
                  'data-testid': 'menu-option-rename'
                } as MenuListItemProps
              ]
            : []),
          ...(onClickDelete
            ? [
                {
                  label: 'Delete',
                  key: 'Delete',
                  onClick: onClickDelete,
                  icon: 'delete',
                  'data-testid': 'menu-option-delete'
                } as MenuListItemProps
              ]
            : []),
          ...(onClickMove
            ? [
                {
                  label: 'Move to...',
                  key: 'Move to...',
                  onClick: onClickMove,
                  icon: 'move',
                  'data-testid': 'menu-option-move-to'
                } as MenuListItemProps
              ]
            : [])
        ]

  return (
    <Menu
      trigger={
        <SidebarIconButton
          tertiary
          onClick={event => event.stopPropagation()}
          disableTooltip
          label="Open Menu"
          icon="more-vertical"
          data-testid="nav-item-more-options-menu-button"
        />
      }
      onMenuChange={event => {
        setIsMenuOpen?.(event.open)
        if (event.open) {
          //Normally the menu icon hides on mouseleave, but we want to keep it there if menu open
        } else {
          //This resets the 'move menu' state so next time the menu opens in default state
          setShowMoveMenu?.(false)
        }
      }}
    >
      {items.map(item => (
        <MenuListItem
          icon={item.icon}
          label={item.label}
          key={item.label}
          onClick={event => {
            item.onClick ? item.onClick(event) : undefined
            event.syntheticEvent.stopPropagation()
          }}
          data-testid={item['data-testid']}
        />
      ))}
    </Menu>
  )
}

const NavItemBox = styled(Box)`
  min-height: 40px;

  &:focus-visible {
    z-index: 1;
  }
  &:focus:not(:focus-visible) {
    outline: none;
  }
`

function isBrandIconType(icon: any): icon is BrandIconName {
  return !ICON_NAMES.includes(icon)
}

export function CreatePublicGroupInput({ onSubmit }: { onSubmit: (name: string) => void }) {
  const [groupName, setGroupName] = useState('')
  const [showInput, setShowInput] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)

  const handleClickAddGroupButton = () => {
    setShowInput(true)
  }

  const showButton = () => {
    setShowInput(false)
  }

  useEffect(() => {
    if (showInput) {
      inputRef.current?.focus()
    }
  }, [showInput])

  const handleSubmit: KeyboardProps['onEnter'] = () => {
    onSubmit(groupName)
    showButton()
  }

  const theme = useTheme()

  return (
    <Box height="40px" justify="center">
      {!showInput ? (
        <AddGroupButton
          plain
          icon={<Icon icon="add" />}
          label="Add public group"
          onClick={() => handleClickAddGroupButton()}
        />
      ) : (
        <Keyboard onEnter={handleSubmit}>
          <Box pad={{ left: '6px' }}>
            <ThemeContext.Extend
              value={{
                textInput: {
                  extend: css`
                    font-weight: normal;
                    font-size: 15px;
                    padding-left: 34px;
                    color: white;

                    ::placeholder {
                      color: ${resolveColor('nav-item-add-custom-group-button', theme)};
                    }

                    &:focus {
                      outline: none;
                    }
                  `,
                  container: {
                    extend: css`
                      display: flex;
                      align-items: center;
                      width: initial;

                      svg {
                        stroke: ${resolveColor('nav-item-text', theme)};
                        fill: ${resolveColor('nav-item-text', theme)};
                      }

                      > div {
                        position: absolute;
                        left: 0;
                        transform: translateY(-50%);
                      }
                    `
                  }
                }
              }}
            >
              <TextInput
                ref={inputRef}
                plain
                icon="add"
                onBlur={() => showButton()}
                placeholder="Enter public group title..."
                onChange={event => setGroupName(event.target.value)}
              />
            </ThemeContext.Extend>
          </Box>
        </Keyboard>
      )}
    </Box>
  )
}

type EditNameInputProps = {
  setShowInput: (show: boolean) => void
  onSubmit?: (name: string) => void
  name: string
  level?: number
}

const EditNameInput = memo(({ setShowInput, onSubmit, name: oldName }: EditNameInputProps) => {
  const [name, setName] = useState(oldName)

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSubmit?.(name)
      setShowInput(false)
    } else if (e.key === 'Escape') {
      setShowInput(false)
    } else if (e.key === ' ' || e.key === 'Space') {
      e.stopPropagation()
    }
  }

  const theme = useTheme()

  return (
    <Box height="40px" justify="center">
      <ThemeContext.Extend
        value={{
          textInput: {
            container: {
              extend: css`
                display: flex;
                align-items: center;
                width: initial;

                > div {
                  position: absolute;
                  left: 0;
                  transform: translateY(-50%);
                }

                input {
                  font-weight: normal;
                  font-size: 15px;
                  padding-left: 0;
                  margin-left: 0;
                  color: ${resolveColor('nav-item-text', theme)};
                  // re-consider this design obviously
                  &:focus {
                    outline: none;
                  }
                }
              `
            }
          }
        }}
      >
        <TextInput
          onKeyDown={handleKeyDown}
          onBlur={() => setShowInput(false)}
          autoFocus
          plain
          defaultValue={unescape(oldName)}
          onChange={event => setName(event.currentTarget.value)}
        />
      </ThemeContext.Extend>
    </Box>
  )
})

function NavItemIcon({ size, icon, hide }: { size: 'small' | 'large'; icon: IconName; hide?: boolean }) {
  const theme = useTheme()
  const color = resolveColor('text-on-primary-disabled', theme)
  const hoverColor = resolveColor('bg', theme)

  return (
    <Icon
      css={`
        visibility: ${hide ? 'hidden' : undefined};
        fill: ${resolveColor({ custom: color }, theme)} !important;
        &:hover {
          fill: ${resolveColor({ custom: hoverColor }, theme)} !important;
        }
      `}
      icon={icon}
      size={size === 'small' ? '18px' : '24px'}
    />
  )
}

export const NavItemInfoText = styled(Text).attrs(() => ({
  size: 'small',
  color: 'text-on-primary-disabled'
}))<TextProps & { level: 0 | 1 | 2 }>`
  margin-left: ${props => (props.level === 0 ? '4px' : '40px')};
  display: block;
  line-height: normal;
`

type SidebarSectionProps = {
  children: ReactNode
  heading?: string
  hidden?: boolean
}
export const SidebarSection = ({ hidden, heading, children }: SidebarSectionProps) => {
  return (
    <Box
      responsive={false}
      css={`
        display: ${hidden ? 'none' : 'block'};
        padding-bottom: 16px;
      `}
      a11yTitle={heading ? `${heading} section` : 'Sidebar section'}
    >
      {heading && (
        <Box pad={{ bottom: '7px', left: '4px' }}>
          <Text css="display: block" weight={600} size="small" color="nav-item-text">
            {heading}
          </Text>
        </Box>
      )}
      {children}
    </Box>
  )
}
