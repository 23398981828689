import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const ArrowForward = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="ArrowForward" {...props}>
    <path d="m22.414 4 19.8 19.799-19.8 19.799-2.121-2.121L36.47 25.299H5v-3h31.47L20.294 6.121 22.414 4Z" fill="#000" />
  </StyledIcon>
))

ArrowForward.displayName = 'ArrowForward'

export { ArrowForward }
