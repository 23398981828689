import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const TaskTypeDiamondDashed = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="TaskTypeDiamondDashed" {...props}>
    <path d="m7.03 18.343-1.415 1.414a6 6 0 0 0 0 8.486l1.414 1.414 1.415-1.414-1.415-1.415a4 4 0 0 1 0-5.656l1.415-1.415-1.415-1.414Zm4.242-1.414-1.414-1.414 5.657-5.657 1.414 1.414-5.657 5.657Zm16.971-8.485-1.415-1.415a4 4 0 0 0-5.656 0l-1.415 1.415-1.414-1.415 1.414-1.414a6 6 0 0 1 8.486 0l1.414 1.414-1.414 1.415Zm8.485 8.485-5.657-5.657 1.414-1.414 5.657 5.657-1.414 1.414Zm2.828 11.314 1.415-1.415a4 4 0 0 0 0-5.656l-1.415-1.415 1.415-1.414 1.414 1.414a6 6 0 0 1 0 8.486l-1.414 1.414-1.415-1.414Zm-8.485 8.485 5.657-5.657 1.414 1.414-5.657 5.657-1.414-1.414Zm-11.314 2.828 1.415 1.415a4 4 0 0 0 5.656 0l1.415-1.415 1.414 1.415-1.414 1.414a6 6 0 0 1-8.486 0l-1.414-1.414 1.414-1.415Zm-8.485-8.485 5.657 5.657-1.414 1.414-5.657-5.657 1.414-1.414Z" fill="#000" />
  </StyledIcon>
))

TaskTypeDiamondDashed.displayName = 'TaskTypeDiamondDashed'

export { TaskTypeDiamondDashed }
