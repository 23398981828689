import { createContext, ReactNode, useMemo } from 'react'
import { keyBy, unescape } from 'lodash'
import { useParams } from 'react-router-dom'

import { AccountDataType } from './account-data-types'
import { useGetAccountData } from './use-get-account-data'
import { useSetPermissions } from 'main/services/queries/use-permissions'

export const AccountDataContext = createContext<AccountDataType>({
  isLoading: false,
  isError: false,
  account: undefined,
  taskTypes: undefined,
  taskTypeLookup: undefined,
  runbookTypes: undefined,
  activeRunbookTypes: undefined,
  disabledRunbookTypes: undefined,
  runbookTypeLookup: undefined,
  customFields: undefined,
  customFieldsLookup: undefined,
  dashboards: undefined,
  dashboardLookup: undefined,
  fieldOptions: undefined,
  fieldOptionLookup: undefined,
  customFieldUsers: undefined,
  customFieldUserLookup: undefined,
  customFieldGroups: undefined,
  customFieldGroupsLookup: undefined,
  viewPermissions: undefined
})

export const AccountDataProvider = ({ children }: { children: ReactNode }) => {
  const { accountId } = useParams()
  const setPermissions = useSetPermissions('account')
  const { data, isLoading, isError } = useGetAccountData({ slug: accountId })

  const taskTypes = data?.meta?.task_types
  const taskTypeLookup = useMemo(() => (taskTypes ? keyBy(taskTypes ?? [], 'id') : undefined), [taskTypes])
  const runbookTypes = data?.meta?.runbook_types
  const activeRunbookTypes =
    runbookTypes &&
    runbookTypes.filter(runbookType => !runbookType.archived && !runbookType.disabled && runbookType.key !== 'snippet')
  const disabledRunbookTypes = runbookTypes?.filter(
    runbookType => !activeRunbookTypes?.includes(runbookType) && runbookType.key !== 'snippet'
  )
  const runbookTypeLookup = useMemo(() => (runbookTypes ? keyBy(runbookTypes ?? [], 'id') : undefined), [runbookTypes])
  const customFields = data?.meta?.custom_fields?.map(cf => ({
    ...cf,
    constraint: cf.constraint ? JSON.parse(cf.constraint) : null,
    display_name: unescape(cf.display_name)
  }))
  const customFieldsLookup = useMemo(() => (customFields ? keyBy(customFields ?? [], 'id') : undefined), [customFields])
  const dashboards = data?.meta?.dashboards?.map(dashboard => ({
    ...dashboard,
    name: unescape(dashboard.name)
  }))
  const dashboardLookup = useMemo(() => (dashboards ? keyBy(dashboards ?? [], 'id') : undefined), [dashboards])
  const customFieldGroups = data?.meta?.custom_field_groups
  const customFieldGroupsLookup = useMemo(
    () => (customFieldGroups ? keyBy(customFieldGroups ?? [], 'id') : undefined),
    [customFieldGroups]
  )

  const fieldOptions = customFields?.flatMap(customField => customField.field_options)

  const fieldOptionLookup = useMemo(() => (fieldOptions ? keyBy(fieldOptions ?? [], 'id') : undefined), [fieldOptions])

  const customFieldUsers = data?.meta?.custom_field_users
  const customFieldUserLookup = useMemo(
    () => (customFieldUsers ? keyBy(customFieldUsers ?? [], 'id') : undefined),
    [customFieldUsers]
  )

  const viewPermissions = data?.meta?.permissions?.views?.account as Record<string, boolean>

  // permissions without viewPermissions
  const permissions = Object.fromEntries(
    Object.entries(data?.meta?.permissions ?? {}).filter(([key]) => key !== 'views')
  )
  setPermissions(permissions)

  return (
    <AccountDataContext.Provider
      value={{
        isLoading,
        isError,
        account: data?.account,
        taskTypes,
        taskTypeLookup,
        runbookTypes,
        activeRunbookTypes,
        disabledRunbookTypes,
        runbookTypeLookup,
        customFields,
        customFieldsLookup,
        dashboards,
        dashboardLookup,
        fieldOptions,
        fieldOptionLookup,
        customFieldUsers,
        customFieldUserLookup,
        customFieldGroups,
        customFieldGroupsLookup,
        viewPermissions
      }}
    >
      {children}
    </AccountDataContext.Provider>
  )
}
