import { memo } from 'react'

import { AccordionPanel, Pill } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'

type FilterAccordionPanelProps = {
  label: string
  children: React.ReactNode
  applied?: boolean
}

export const FilterAccordionPanel = memo(({ label, children, applied }: FilterAccordionPanelProps) => {
  const { t } = useLanguage('common', { keyPrefix: 'filter' })

  return (
    <AccordionPanel
      label={label}
      a11yTitle={t('filterGroup')}
      labelSuffix={
        !!applied ? (
          <Pill
            css="margin-left: 4px; flex-shrink: 0;"
            // TODO: logic for when it isn't just 'applied' string
            // label={getAppliedIndicatorText?.(selected, filter) || t('applied')}
            label={t('applied')}
            size="small"
          />
        ) : undefined
      }
    >
      {children}
    </AccordionPanel>
  )
})
