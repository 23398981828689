import { Editor, Path, Range, Element as SlateElement, Transforms } from 'slate'

import { LinkPayload } from '../components/text-editor-link-modal'
import { LinkElement, ParagraphElement } from '../text-editor-types'

export const createLinkNode = (url: string, text: string): LinkElement => ({
  type: 'link',
  url: autoPrefix(url),
  children: [{ text }]
})

const autoPrefix = (url: string) => {
  const defaultPrefix = 'https://'
  if (url.includes('://')) {
    return url
  }
  return defaultPrefix + url
}

export const removeLink = (editor: Editor, opts = {}) => {
  Transforms.unwrapNodes(editor, {
    ...opts,
    match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link'
  })
}

const createParagraphNode = (children: LinkElement[]): ParagraphElement => ({
  type: 'paragraph',
  children
})

export const insertLink = (editor: Editor, data: LinkPayload) => {
  if (!data.url) return

  const { selection } = editor
  const link = createLinkNode(data.url, data.text || 'New link')

  if (!!selection) {
    const [parentNode, parentPath] = Editor.parent(editor, selection.focus?.path)

    if (SlateElement.isElement(parentNode) && parentNode.type === 'link') {
      removeLink(editor)
    }

    if (editor.isVoid(parentNode as SlateElement)) {
      Transforms.insertNodes(editor, createParagraphNode([link]), {
        at: Path.next(parentPath),
        select: true
      })
    } else if (Range.isCollapsed(selection)) {
      Transforms.insertNodes(editor, [link, { text: ' ' }], { select: true })
    } else {
      Transforms.wrapNodes(editor, link, { split: true })
      Transforms.collapse(editor, { edge: 'end' })
    }
    //ReactEditor.focus(editor) // This is not working correctly
  } else {
    Transforms.insertNodes(editor, createParagraphNode([link]))
  }
}
