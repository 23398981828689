import { selector } from 'recoil'

import { runbookVersionState } from './runbook-version'
import { Run } from 'main/services/queries/types'

// :!: caution :!: .run is undefined if not in a run
export const runState = selector<Run | undefined>({
  key: 'runbookVersion:run',
  get: ({ get }) => get(runbookVersionState).run ?? undefined
})
