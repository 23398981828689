import { useFormContext } from 'react-hook-form'

import { AccordionPanel, useUpdateEffect } from '@cutover/react-ui'
import { RadioboxGroupField } from 'main/components/shared/form'
import { TaskEditFormType } from './task-edit-form'
import { useLanguage } from 'main/services/hooks'

type TaskAdvancedSettingProps = {
  readOnly?: boolean
  iconColor?: string
}

export const TaskAdvancedSettings = ({ readOnly, iconColor }: TaskAdvancedSettingProps) => {
  const { t } = useLanguage('tasks', { keyPrefix: 'editPanel.fields.advanced' })
  const { watch, setValue } = useFormContext<TaskEditFormType>()
  const startRequirements = watch('start_requirements')
  const endRequirements = watch('end_requirements')

  useUpdateEffect(() => {
    if (startRequirements === 'all_must_start' && endRequirements === 'same_must_end')
      setValue('end_requirements', 'all_must_end')
  }, [startRequirements, endRequirements])

  return (
    <AccordionPanel label={t('accordionLabel')} iconColor={iconColor} icon="settings">
      <RadioboxGroupField<TaskEditFormType>
        name="start_requirements"
        label={t('startRequirements')}
        readOnly={readOnly}
        options={[
          { value: 'any_can_start', label: t('anyCanStart') },
          { value: 'all_must_start', label: t('everyoneMustStart') }
        ]}
      />
      <RadioboxGroupField<TaskEditFormType>
        name="end_requirements"
        label={t('endRequirements')}
        readOnly={readOnly}
        options={[
          { value: 'any_can_end', label: t('anyCanEnd') },
          { value: 'all_must_end', label: t('everyoneMustEnd') },
          { value: 'same_must_end', label: t('userStartedMustEnd'), disabled: startRequirements === 'all_must_start' }
        ]}
      />
      <RadioboxGroupField<TaskEditFormType>
        name="disable_notify"
        label={t('disableTaskStartNotification')}
        direction="row"
        readOnly={readOnly}
        options={[
          { value: 'true', label: t('yes') },
          { value: 'false', label: t('no') }
        ]}
      />
    </AccordionPanel>
  )
}
