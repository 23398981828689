import * as yup from 'yup'

import { MergeRunbooksPayload } from 'main/services/queries/merge-runbooks-query'
import { ValidateMergePayload } from 'main/services/queries/validate-merge-query'
import { customFieldValidation } from 'main/components/shared/custom-field-form'

export type MergeRunbooksForm = MergeRunbooksPayload &
  ValidateMergePayload & {
    create_type: 'new' | 'existing'
  }
export type MergeModalValidateMergeFormType = yup.InferType<ReturnType<typeof buildValidateMergeSchema>>
export const buildValidateMergeSchema = (targetType: 'new' | 'existing') => {
  return yup.object({
    target_type: yup.string().oneOf(['new', 'existing']).required().default('new'),
    runbook_ids: yup.array().of(yup.number().required()).required(),
    target_runbook: yup.object({
      id: targetType === 'existing' ? yup.number().required() : yup.number(),
      name: targetType === 'existing' ? yup.string().required() : yup.string()
    })
  })
}

export type MergeModalNewRunbookDetailsFormType = yup.InferType<ReturnType<typeof buildNewRunbookDetailsSchema>>
export const buildNewRunbookDetailsSchema = (fieldValueValidation: ReturnType<typeof customFieldValidation>) => {
  return yup.object({
    runbook_ids: yup.array().of(yup.number().required()).required(),
    task_parent_id_to_link_to: yup.number(),
    shift_time: yup.boolean().nonNullable(),
    field_values: fieldValueValidation,
    runbook: runbookSchema({ required: true })
  })
}

export type MergeModalMergeRunbooksFormType = yup.InferType<ReturnType<typeof buildMergeRunbookMergeSchema>>
export const buildMergeRunbookMergeSchema = (fieldValueValidation: ReturnType<typeof customFieldValidation>) => {
  return yup.object({
    runbook_ids: yup.array().of(yup.number().required()).required(),
    target_runbook: yup.object({
      id: yup.number(),
      name: yup.string()
    }),
    task_parent_id_to_link_to: yup.number(),
    shift_time: yup.boolean().nonNullable(),
    runbook: runbookSchema({ required: false }),
    field_values: fieldValueValidation
  })
}

function runbookSchema({ required }: { required: boolean }) {
  return yup
    .object({
      name: required ? yup.string().required() : yup.string(),
      account_id: required ? yup.number().required() : yup.number(),
      field_values_attributes: yup.array().of(
        yup.object({
          custom_field_id: yup.number().required(),
          id: yup.number().nullable(),
          value: yup.mixed(),
          field_option_id: yup.number().nullable(),
          type: yup.string(),
          remote_data_key_value: yup.string().nullable()
        })
      ),
      project_id: required ? yup.number().required() : yup.number(),
      roles: yup.array().of(yup.object({})),
      runbook_type_id: required ? yup.number().required() : yup.number(),
      runbook_versions_attributes: yup.array().of(
        yup.object({
          start_scheduled: yup.date().notRequired()
        })
      ),
      timezone: yup.string()
    })
    .notRequired()
}
