// @ts-nocheck
import 'reflect-metadata'
import { injectable } from 'inversify'
import { observable, computed } from 'mobx'
import { Routes } from './Routes'

@injectable()
export class RoutingState {
  @observable
  public currentState: { routeId: string; params: any; query: string } = { routeId: null, params: null, query: null }

  @observable
  public routes: IRoute[] = []

  constructor() {
    this.routes = new Routes().routes
  }

  @computed
  public get pathFilters() {
    let retVal: { key: string; value: string }[] = []
    if (this.currentState.query && this.currentState.query !== '') {
      retVal = this.currentState.query.split('&').map((param: string) => {
        const item = param.split('=')
        return { key: decodeURI(item[0]), value: item[1] }
      })
    }
    return retVal
  }
}
