// @ts-nocheck
import {
  CheckboxStyled,
  IOptionLabels,
  OptionWrapper,
  OptionHeader,
  OptionSubheader,
  OptionDataWrapper,
  StartTimeWrapper,
  TimeControlStyled,
  TimeIconStyled
} from './StartTimeOptionsStyled'
import { CheckboxPresenter, DateTime, DateTimePresenter } from 'Shared/Components/Molecules'

export const getStartTimeOptions = (
  labels: IOptionLabels,
  hasShiftTimeCheckbox: boolean,
  dateTimePresenter: DateTimePresenter,
  endTimePresenter: DateTimePresenter,
  startPlannedTime: Date,
  handleDateChange: (date: Date) => void,
  handleTimeChange: (time: Date) => void,
  shiftTimePresenter: CheckboxPresenter
) => {
  return {
    [labels.plannedOptionHeader]: {
      renderOption: () => (
        <OptionWrapper>
          <OptionHeader>{labels.plannedOptionHeader}</OptionHeader>
          <OptionSubheader>{labels.plannedOptionSubHeader}</OptionSubheader>
        </OptionWrapper>
      ),
      renderOptionData: (isSelected: boolean) => (
        <OptionDataWrapper isSelected={isSelected}>
          <StartTimeWrapper>
            <TimeIconStyled name="access-time" />
            {labels.defaultDateTime}
            <TimeControlStyled
              value={startPlannedTime}
              handleDateChange={handleDateChange}
              handleTimeChange={handleTimeChange}
            />
          </StartTimeWrapper>
          {hasShiftTimeCheckbox && <CheckboxStyled presenter={shiftTimePresenter} />}
        </OptionDataWrapper>
      )
    },
    [labels.startDateOptionHeader]: {
      renderOption: () => (
        <OptionWrapper>
          <OptionHeader>{labels.startDateOptionHeader}</OptionHeader>
          <OptionSubheader>{labels.startDateOptionSubHeader}</OptionSubheader>
        </OptionWrapper>
      ),
      renderOptionData: (isSelected: boolean) => {
        return (
          <>
            <OptionDataWrapper isSelected={isSelected}>
              <DateTime presenter={dateTimePresenter} />
            </OptionDataWrapper>
            <OptionDataWrapper isSelected={isSelected}>
              <OptionSubheader>{labels.endDateOptionSubHeader}</OptionSubheader>
            </OptionDataWrapper>
            <OptionDataWrapper isSelected={isSelected}>
              <DateTime presenter={endTimePresenter} />
            </OptionDataWrapper>
            <OptionDataWrapper isSelected={isSelected}>
              {hasShiftTimeCheckbox && <CheckboxStyled presenter={shiftTimePresenter} />}
            </OptionDataWrapper>
          </>
        )
      }
    }
  }
}
