import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const TaskTypeCogDashed = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="TaskTypeCogDashed" {...props}>
    <path d="M14.193 11.193a16.23 16.23 0 0 0-.497.405L9.73 10.191A19.978 19.978 0 0 0 5 18.371l3.204 2.734c-.053.296-.098.594-.135.895h1.89c.027-.189.057-.377.09-.563l.191-1.06-3.066-2.616a18.105 18.105 0 0 1 3.113-5.382l3.799 1.347.823-.698c.076-.065.154-.129.232-.193l-.948-1.642Zm15.323-.553c-.153-.06-.308-.119-.463-.175l-1.015-.364-.732-3.96a18.255 18.255 0 0 0-3.109-.266c-1.061 0-2.1.091-3.11.265l-.731 3.961-1.015.364c-.262.094-.52.196-.774.304l-.944-1.634c.355-.158.717-.303 1.085-.435l.765-4.139A20.05 20.05 0 0 1 24.197 4c1.628 0 3.21.194 4.724.561l.765 4.14c.262.093.52.194.776.3l-.946 1.64ZM38.434 22a14.252 14.252 0 0 0-.09-.563l-.19-1.06 3.066-2.616a18.105 18.105 0 0 0-3.113-5.382l-3.799 1.347-.822-.698a14.449 14.449 0 0 0-.498-.402l.945-1.637c.261.195.516.398.765.61l3.967-1.408a19.979 19.979 0 0 1 4.73 8.18l-3.205 2.734c.053.296.098.594.135.895h-1.89Zm-5.595 13.489c.22-.167.436-.34.647-.517l.822-.698 3.799 1.347a18.105 18.105 0 0 0 3.113-5.382l-3.066-2.617.19-1.059c.034-.186.064-.374.09-.563h1.891c-.037.3-.082.6-.135.895l3.204 2.734a19.979 19.979 0 0 1-4.73 8.18l-3.966-1.407c-.296.25-.602.492-.916.721l-.943-1.634ZM18.74 37.304c.199.081.399.158.601.23l1.015.365.732 3.96a18.258 18.258 0 0 0 6.218 0l.732-3.96 1.015-.364c.096-.035.192-.07.287-.107l.948 1.642c-.199.08-.4.157-.602.23l-.765 4.139a20.05 20.05 0 0 1-4.724.561 20.05 20.05 0 0 1-4.724-.561l-.765-4.14c-.309-.11-.613-.23-.913-.359l.945-1.636ZM9.96 26c.026.189.056.377.09.563l.19 1.06-3.066 2.616a18.105 18.105 0 0 0 3.113 5.382l3.799-1.347.823.697c.124.106.251.21.38.312l-.947 1.639c-.22-.168-.435-.341-.646-.52L9.73 37.809A19.978 19.978 0 0 1 5 29.629l3.204-2.734A16.217 16.217 0 0 1 8.07 26h1.89Z" fill="#000" />
  </StyledIcon>
))

TaskTypeCogDashed.displayName = 'TaskTypeCogDashed'

export { TaskTypeCogDashed }
