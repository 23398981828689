import { Simplify } from 'type-fest'

import { StreamListStream } from 'main/services/queries/types'
import {
  useCanStream,
  useGetAllStreams,
  useGetAllStreamsCallback,
  useGetStream,
  useGetStreamBy,
  useGetStreamByCallback,
  useGetStreamCallback,
  useGetStreamsLookup,
  useGetStreamsLookupCallback,
  useGetStreamsLookupLoadable,
  useOnActionStream
} from '../hooks'
import {
  CollectionModelInterfaceBase,
  ModelCollectionAccessScope,
  ModelSingleAccessBy,
  CollectionModelInterfaceWithActions as WithActions,
  CollectionModelIntefaceWithLookupLoadable as WithLookupLoadable,
  CollectionModelInterfaceWithPermissions as WithPermissions,
  CollectionModelInterfaceWithSingleAccessBy as WithSingleAccessBy
} from './types'
import { StreamsPermissionsResponse } from 'main/services/queries/use-runbook-versions'

type StreamCollectionAccessScope = ModelCollectionAccessScope<'flattened' | 'permitted'>
type StreamSingleAccessBy = ModelSingleAccessBy<Pick<StreamListStream, 'internal_id'>>

export type StreamModelType = Simplify<
  CollectionModelInterfaceBase<StreamListStream, StreamCollectionAccessScope> &
    WithSingleAccessBy<StreamListStream, StreamSingleAccessBy> &
    WithPermissions<StreamsPermissionsResponse> &
    WithLookupLoadable<StreamListStream> &
    Omit<WithActions<'Stream'>, 'usePermission' | 'usePermissionCallback'>
>

export const StreamModel: StreamModelType = {
  useGet: useGetStream,
  useGetCallback: useGetStreamCallback,

  useGetBy: useGetStreamBy,
  useGetByCallback: useGetStreamByCallback,

  useGetAll: useGetAllStreams,
  useGetAllCallback: useGetAllStreamsCallback,

  useGetLookup: useGetStreamsLookup,
  useGetLookupCallback: useGetStreamsLookupCallback,
  useGetLookupLoadable: useGetStreamsLookupLoadable,

  useCan: useCanStream,

  useOnAction: useOnActionStream
}
