import { ThemeType as GrommetThemeType } from 'grommet/themes'
import { SetRequired } from 'type-fest'

type GrommetTheme = SetRequired<GrommetThemeType, 'radioButtonGroup'>
type GrommetRadioButtonGroup = GrommetTheme['radioButtonGroup']

export const radioButtonGroup: GrommetRadioButtonGroup = {
  container: {
    gap: 'xsmall'
  }
}
