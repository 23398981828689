import { useState } from 'react'

import { RunbookDashboardSchedulePanel } from '../components/dashboards/runbook-dashboard-schedule/runbook-dashboard-schedule-panel'
import { ReactAngularConnector } from 'main/connectors/react-angular-connector'

export const RunbookDashboardSchedulePanelConnector = () => {
  const [accountId, setAccountId] = useState<string | null>(null)
  const [runbookId, setRunbookId] = useState<number | null>(null)
  const [runbookVersionId, setRunbookVersionId] = useState<number | null>(null)
  const [currentUserId, setCurrentUserId] = useState<number | null>(null)
  const [dashboardId, setDashboardId] = useState<number | null>(null)
  const [timezone, setTimezone] = useState<string | null>(null)
  const [taskIds, setTaskIds] = useState<number[] | undefined>(undefined)
  const [isFiltered, setIsFiltered] = useState(false)

  const handleOpenPanel = ({
    accountId,
    runbookId,
    runbookVersionId,
    dashboardId,
    currentUserId,
    timezone,
    taskIds,
    isFiltered
  }: {
    accountId: string
    runbookId: number
    runbookVersionId: number
    dashboardId: number
    currentUserId: number
    timezone: string
    taskIds: number[]
    isFiltered: boolean
  }) => {
    setAccountId(accountId)
    setRunbookId(runbookId)
    setDashboardId(dashboardId)
    setRunbookVersionId(runbookVersionId)
    setCurrentUserId(currentUserId)
    setTimezone(timezone)
    setTaskIds(taskIds)
    setIsFiltered(isFiltered)
  }

  return (
    <ReactAngularConnector
      mountEvent="open-runbook-dashboard-schedule"
      unmountEvent="runbook-dashboard-schedule-close"
      onMount={handleOpenPanel}
    >
      {accountId && runbookId && dashboardId && runbookVersionId && currentUserId && timezone && (
        <RunbookDashboardSchedulePanel
          accountId={accountId}
          runbookId={runbookId}
          runbookVersionId={runbookVersionId}
          dashboardId={dashboardId}
          currentUserId={currentUserId}
          timezone={timezone}
          taskIds={taskIds || []}
          isFiltered={isFiltered}
        />
      )}
    </ReactAngularConnector>
  )
}
