import { UserItemAvailableRole } from '@cutover/react-ui'

export function getAvailableRoleItems(
  withTeamMember?: boolean,
  templateType?: string,
  streams?: any[]
): UserItemAvailableRole[] {
  const roleItems: UserItemAvailableRole[] = [{ role: 'Admin' }]

  if (streams && templateType !== 'snippet') {
    roleItems.push({ role: 'Stream Editor', streams })
  }

  if (withTeamMember) {
    roleItems.push({ role: 'Team Member' })
  }

  return roleItems
}
