import { RagIndicator } from '@cutover/react-ui'
import { RunbookListRunbook } from 'main/services/queries/types'

export const RagStatusDisplay = ({ runbook }: { runbook: RunbookListRunbook }) => {
  const { status, template_type } = runbook
  const isSnippet = template_type === 'snippet'

  return !!status && !isSnippet ? <RagStatusDisplayComponent runbook={runbook} /> : null
}

const RagStatusDisplayComponent = ({ runbook: { status, status_message } }: { runbook: RunbookListRunbook }) => {
  return (
    <RagIndicator
      status={status}
      tip={status_message}
      tipPlacement="top"
      data-testid={`rag-indicator-${status}`}
      // TODO: maybe refactor into something a little more future-proof
      css={`
        display: inline-block;
        position: relative;
        top: 1px;
        margin-right: 3px;
      `}
    />
  )
}
