// @ts-nocheck
import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Table } from 'Components/Organisms'
import { NoResourceFound } from '@cutover/react-ui'
import { GenericListPresenter } from './GenericListPresenter'

interface IGenericListProps {
  listPresenter: GenericListPresenter
}

export const GenericList = observer<IGenericListProps>(({ listPresenter }) => {
  const {
    tablePresenter,
    viewModel,
    totalCount,
    sortParams,
    loading,
    clearAllFilters,
    selectedId,
    reloadList,
    context,
    filtersCount
  } = listPresenter
  useEffect(() => {
    tablePresenter.initNewData(viewModel, totalCount)
    if (
      tablePresenter.sortedHeader !== sortParams.accessor ||
      tablePresenter.sortDirection !== sortParams.sortDirection
    ) {
      tablePresenter.sortHandler({ accessor: sortParams.accessor, dataType: sortParams.dataType }, true, reloadList)
    }
  }, [viewModel, sortParams, totalCount])

  return (
    <>
      {!loading && totalCount === 0 && filtersCount > 0 ? (
        <NoResourceFound clearAllFilters={clearAllFilters} context={context} />
      ) : (
        <Table selectedId={selectedId} presenter={tablePresenter} />
      )}
    </>
  )
})
