import styled from 'styled-components/macro'

export const PageWrapperInner = styled.div`
  top: 0;
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`
