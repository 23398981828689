import { useRecoilCallback, useRecoilValue } from 'recoil'

import { accountCustomFieldUsers, customFieldUserLookup } from 'main/recoil/runbook'
import { CustomFieldUserModelType } from 'main/data-access/models'

/* -------------------------------------------------------------------------- */
/*                                     Get                                    */
/* -------------------------------------------------------------------------- */

export const useGetCustomFieldUser: CustomFieldUserModelType['useGet'] = (id: number) => {
  const lookup = useRecoilValue(customFieldUserLookup)
  return lookup?.[id]
}

export const useGetCustomFieldUserCallback: CustomFieldUserModelType['useGetCallback'] = () => {
  return useRecoilCallback(({ snapshot }) => async (id: number) => {
    const lookup = await snapshot.getPromise(customFieldUserLookup)
    return lookup?.[id]
  })
}

/* -------------------------------------------------------------------------- */
/*                                   Get All                                  */
/* -------------------------------------------------------------------------- */

export const useGetCustomFieldUsers: CustomFieldUserModelType['useGetAll'] = () =>
  useRecoilValue(accountCustomFieldUsers)

export const useGetCustomFieldUsersCallback: CustomFieldUserModelType['useGetAllCallback'] = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      () =>
        snapshot.getPromise(accountCustomFieldUsers)
  )

/* -------------------------------------------------------------------------- */
/*                                 Get Lookup                                 */
/* -------------------------------------------------------------------------- */

export const useGetCustomFieldUserLookup: CustomFieldUserModelType['useGetLookup'] = () =>
  useRecoilValue(customFieldUserLookup)

export const useGetCustomFieldUserLookupCallback: CustomFieldUserModelType['useGetLookupCallback'] = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      () =>
        snapshot.getPromise(accountCustomFieldUsers)
  )
