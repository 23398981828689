// @ts-nocheck
import { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { container } from 'app/Config/IOC'
import { Types } from 'Gateways/Service/Types'
import { CustomFieldFormPresenter } from './CustomFieldFormPresenter'
import { TextInput, Select } from 'Components/Molecules'
import { TextEditor } from '@cutover/react-ui'
import { FieldOptions } from 'Components/Organisms'
import {
  textFieldType,
  textAreaFieldType,
  selectFieldType,
  radioboxFieldType,
  checkboxFieldType,
  endpointFieldType,
  searchableFieldType,
  multiSearchableFieldType
} from 'app/Settings/CustomFields/CustomFieldFieldTypes'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'
import { RemoteOptions } from 'Shared/Components/Organisms/FieldOptions/RemoteOptions'
import { Box, Message } from '@cutover/react-ui'

interface ICustomFieldFormFieldTypeSelect {
  presenter: CustomFieldFormPresenter
  formType?: 'add' | 'edit'
}

export const CustomFieldFormFieldTypeSelect = observer<ICustomFieldFormFieldTypeSelect>(({ presenter, formType }) => {
  const {
    fieldTypePresenter,
    dataSourcePresenter,
    defaultTextPresenter,
    textEditorPresenter,
    remoteKeyFieldsPresenter,
    remoteUrlPresenter,
    genericOptionsPresenter
  } = presenter
  const [renderedComponent, setRenderedComponent] = useState(null)

  const language: ILanguageService = container.get(Types.ILanguageService)

  const WrappedTextEditor = observer(props => {
    const { value, disabled, onChange, initialValue } = props.presenter
    return (
      <TextEditor
        label={language.get('customFields:fields:textEditorLabels:label')}
        value={value}
        onChange={v => {
          onChange(v)
        }}
        readOnly={disabled}
        placeholder={language.get('customFields:fields:textEditorLabels:placeholder')}
      />
    )
  })

  // Watch for changes in edit panel's CF name
  // A hack to force it to redraw the text editor
  useEffect(() => {
    const object = customFieldComponents.find(({ value }) => value === fieldTypePresenter.value)
    setRenderedComponent(!!object && object.component)
  }, [presenter.namePresenter.value])

  useEffect(() => {
    if (fieldTypePresenter.value) {
      const object = customFieldComponents.find(({ value }) => value === fieldTypePresenter.value)
      setRenderedComponent(!!object && object.component)
    }
  }, [fieldTypePresenter.value])

  const customFieldComponents = [
    {
      value: textFieldType,
      component: <TextInput presenter={defaultTextPresenter} />
    },
    {
      value: textAreaFieldType,
      component: <WrappedTextEditor presenter={textEditorPresenter} />
    },
    {
      value: selectFieldType,
      component: (
        <>
          <FieldOptions presenter={genericOptionsPresenter} />
        </>
      )
    },
    {
      value: radioboxFieldType,
      component: (
        <>
          <FieldOptions presenter={genericOptionsPresenter} />
        </>
      )
    },
    {
      value: checkboxFieldType,
      component: (
        <>
          <FieldOptions presenter={genericOptionsPresenter} />
        </>
      )
    },
    {
      value: endpointFieldType,
      component: (
        <>
          <TextInput presenter={remoteUrlPresenter} />
          <Box margin={{ top: '16px' }}>
            <RemoteOptions presenter={remoteKeyFieldsPresenter} />
          </Box>
        </>
      )
    },
    {
      value: searchableFieldType,
      component: (
        <>
          <Select presenter={dataSourcePresenter} maxMenuHeight={120} />
        </>
      )
    },
    {
      value: multiSearchableFieldType,
      component: (
        <>
          <Select presenter={dataSourcePresenter} maxMenuHeight={120} />
        </>
      )
    }
  ]

  return (
    <>
      <Select presenter={fieldTypePresenter} maxMenuHeight={120} />
      <Box margin={{ top: '16px' }}>{renderedComponent}</Box>
    </>
  )
})
