import { Editor } from 'slate'

import { getParentBlock } from '../utils'

export const withCodeBlock = (editor: Editor) => {
  const { onKeyDown } = editor

  editor.onKeyDown = event => {
    if (event.key === 'Tab') {
      const currentBlock = getParentBlock(editor)
      const currentBlockType = currentBlock ? currentBlock.type : ''
      if (currentBlockType === 'code') {
        editor.insertText('  ')
        event.preventDefault()
        return
      }
    }
    onKeyDown?.(event)
  }

  // Note, when writing code, pressing enter should create single line break
  // Commenting this out for now since editor will mainly be used to highlight
  // code that is pasted, not actually write it, and there is no other way to
  // exit the code block with the keyboard
  // editor.insertBreak = () => {
  //   const currentBlock = getParentBlock(editor)
  //   const currentBlockType = currentBlock ? currentBlock.type : ''
  //   if (currentBlockType === 'code') {
  //     editor.insertSoftBreak()
  //     // TODO could also indent here if prev line is indented
  //     return
  //   }
  //   insertBreak()
  // }

  return editor
}
