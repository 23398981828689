import { useState } from 'react'

import { ChooseRunbookType } from 'main/components/workspace/modals/create-runbook/choose-runbook-type'
import { ReactAngularConnector } from 'main/connectors/react-angular-connector'

export const CreateRunbookConnector = () => {
  const [isMounted, setIsMounted] = useState(false)

  const handleOnMount = () => {
    setIsMounted(true)
  }

  const handleOnUnmount = () => {
    setIsMounted(false)
  }

  return (
    <ReactAngularConnector
      mountEvent="open-choose-runbook-type-modal"
      unmountEvent="close-choose-runbook-type-modal"
      onMount={handleOnMount}
      onUnmount={handleOnUnmount}
    >
      {isMounted && <ChooseRunbookType />}
    </ReactAngularConnector>
  )
}
