import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Runbook = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Runbook" {...props}>
    <path d="M14 9h20a5 5 0 0 1 5 5v20a5 5 0 0 1-5 5H14a5 5 0 0 1-5-5v-1H6v1a8 8 0 0 0 8 8h20a8 8 0 0 0 8-8V14a8 8 0 0 0-8-8H14a8 8 0 0 0-8 8v1h3v-1a5 5 0 0 1 5-5Z" fill="#000" /><path d="M3 19h9v3H3v-3Zm0 7h9v3H3v-3Zm25-2-7.5 4.33v-8.66L28 24Zm3 1.732c1.333-.77 1.333-2.694 0-3.464l-10.5-6.062c-1.333-.77-3 .192-3 1.732v12.124c0 1.54 1.667 2.502 3 1.732L31 25.732Z" fill="#000" />
  </StyledIcon>
))

Runbook.displayName = 'Runbook'

export { Runbook }
