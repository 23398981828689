import { useRef } from 'react'
import { VirtuosoHandle } from 'react-virtuoso'

import { Box, InfiniteList } from '@cutover/react-ui'
import { SnippetItem } from '../shared/snippet-info'
import { SnippetListSnippet } from 'main/services/queries/use-snippets'

type SnippetListProps = {
  snippets: SnippetListSnippet[]
  hasNextPage?: boolean
  isFetchingNextPage: boolean
  fetchNextPage: () => Promise<any>
  selectSnippet: (snippet: SnippetListSnippet) => void
  setAtBottom: (atBottom: boolean) => void
}

export const SnippetList = ({
  snippets,
  hasNextPage,
  isFetchingNextPage,
  fetchNextPage,
  selectSnippet,
  setAtBottom
}: SnippetListProps) => {
  const listRef = useRef<VirtuosoHandle>(null)

  return (
    <Box tabIndex={0} margin={{ top: '20px' }} role="listbox">
      <InfiniteList<SnippetListSnippet>
        setRef={listRef}
        items={snippets}
        hasNextPage={hasNextPage}
        isFetchingNextPage={isFetchingNextPage}
        fetchNextPage={fetchNextPage}
        height="288px"
        atBottomStateChange={setAtBottom}
        renderItem={(index, snippet) => {
          return (
            <SnippetItem
              id={snippet.id}
              hasNext
              onClick={() => selectSnippet(snippet)}
              name={snippet.name}
              tasksCount={snippet.tasks_count}
            />
          )
        }}
      />
    </Box>
  )
}
