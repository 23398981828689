import styled from 'styled-components/macro'

// TODO: where to best put this?
export type FormFieldBaseProps = {
  label?: string
  disabled?: boolean
  hasError?: boolean
  inlineError?: string
  readOnly?: boolean // TODO: needed ever?
  required?: boolean
  // TODO: replace all form field `tooltipText` props with `helpText` via this type
  helpText?: string
}

export const Form = styled.form.attrs(() => ({
  noValidate: true,
  role: 'form'
}))`
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  max-width: 100%;
  min-width: 0;
  min-height: 0;
`
