import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Close = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Close" {...props}>
    <path d="M26.121 24.456 37.79 12.789l-2.122-2.122L24 22.334 12.333 10.667 10.21 12.79l11.67 11.666-11.67 11.667 2.122 2.121L24 26.577l11.667 11.667 2.122-2.12L26.12 24.455Z" fill="#000" />
  </StyledIcon>
))

Close.displayName = 'Close'

export { Close }
