// @ts-nocheck
import { ISelectedFilter, IGenericFilter } from 'app/Filters/FilterEntities'

export class BooleanFilterHelpers {
  public getFilterValue = (genericFilter: IGenericFilter, pathFilter: { key: string; value: string }): string => {
    const { value } = pathFilter
    if (genericFilter.urlTrueValueOverride && genericFilter.urlTrueValueOverride === value) {
      return 'true'
    }
    if (!genericFilter.urlTrueValueOverride && value === '1') return 'true'
    if (value === '0') return 'false'
  }

  public getValueForUrl = (selectedFilter: IGenericFilter, newValue: string): string => {
    if (newValue === 'true') {
      if (selectedFilter.urlTrueValueOverride) {
        return selectedFilter.urlTrueValueOverride
      } else {
        return '1'
      }
    }
    if (newValue === 'false') return '0'
  }

  public serialiseForUrl = (selectedFilter: ISelectedFilter): string => {
    const { value } = selectedFilter
    if (value === 'true') {
      if (selectedFilter.urlTrueValueOverride) {
        return `${selectedFilter.key.reference}=${selectedFilter.urlTrueValueOverride}`
      } else {
        return `${selectedFilter.key.reference}=1`
      }
    }
    if (value === 'false') return `${selectedFilter.key.reference}=0`
  }

  public serialiseForApi = (selectedFilter: ISelectedFilter): string => {
    const { value } = selectedFilter
    if (value === 'true' || value === 'false') return `${selectedFilter.key.reference}=${selectedFilter.value}`
  }
}
