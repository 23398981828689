import { useEffect, useRef, useState } from 'react'

import { Box, InfiniteList } from '@cutover/react-ui'
import { usePostAppEvents } from '../apps-api'
import { AppsComponentParser } from '../apps-component-parser'
import { useComponentPropsAndState } from '../apps-state'
import { AppComponentNodeProps, ContentNode } from '../apps-types'

type ScrollNodeProps = AppComponentNodeProps & {
  content: ContentNode[]
  height?: number
}

const HEADER_HEIGHT = 74

export const ScrollNode = ({ appId, resourceId, id, meta, ...props }: ScrollNodeProps) => {
  const { componentProps, state } = useComponentPropsAndState(appId, resourceId, id, props)
  const { height, content = [] } = componentProps as ScrollNodeProps

  const postAppEvents = usePostAppEvents()
  const [previousSiblingHeight, setPreviousSiblingHeight] = useState(0)
  const contentRef = useRef<HTMLElement | null>(null)
  const [atBottom, setAtBottom] = useState(false)

  const handleNextPageResults = async () => {
    const payload = {
      app_id: appId,
      runbook_id: resourceId,
      events: [
        {
          type: 'trigger',
          on: 'scroll_down',
          id,
          meta,
          content: content.map(item => ({ id: item.id, type: item.type }))
        }
      ],
      state
    }
    postAppEvents(payload)
  }

  useEffect(() => {
    const previousSiblingNode = contentRef.current?.previousSibling as HTMLElement
    setPreviousSiblingHeight(previousSiblingNode?.offsetHeight || 0)
  }, [])

  return (
    <Box
      ref={contentRef}
      css={`
        position: ${height ? 'relative' : 'absolute'};
        height: ${height ? `${height}px` : `calc(100% - ${HEADER_HEIGHT + previousSiblingHeight}px)`};
        width: calc(100% - 30px);
        mask-image: ${atBottom ? '' : 'linear-gradient(to bottom, black calc(100% - 48px), transparent 100%)'};
      `}
    >
      <InfiniteList<ContentNode>
        atBottomStateChange={atBottom => setAtBottom(atBottom)}
        items={content}
        role="list"
        tabIndex={-1}
        hasNextPage={true}
        fetchNextPage={handleNextPageResults}
        renderItem={(_index, item) => {
          return <AppsComponentParser content={[item]} appId={appId} resourceId={resourceId} />
        }}
      />
    </Box>
  )
}
