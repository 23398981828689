import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Bolt = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Bolt" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="m26.932 8.029-13.831 17.47h9.575l-1.608 14.472L34.9 22.5h-9.575l1.608-14.471ZM25.662 4.8c1.558-1.97 4.721-.668 4.444 1.827L28.676 19.5h7.257c2.09 0 3.258 2.413 1.96 4.052L22.34 43.199c-1.559 1.969-4.722.668-4.445-1.828l1.43-12.871h-7.257c-2.09 0-3.257-2.413-1.96-4.052L25.661 4.801Z" fill="#000" />
  </StyledIcon>
))

Bolt.displayName = 'Bolt'

export { Bolt }
