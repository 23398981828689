import { saveAs } from 'file-saver'
import { useLocation } from 'react-router-dom'

import { Box, IconButton, Menu, MenuListItem, useNotify } from '@cutover/react-ui'
import { SubHeader } from 'main/components/layout/shared/sub-header'
import { useLanguage } from 'main/services/hooks'
import { useSamlRoleMappingsQuery } from 'main/services/queries/use-saml-role-mappings-query'
import { useDownloadRoleMappings } from 'main/services/queries/use-upload-role-mappings-mutation'
import { usePermissions } from 'main/services/queries/use-permissions'

export const RoleMappingsSubHeader = () => {
  const location = useLocation()
  const { state } = location
  const { t } = useLanguage('samlConfigurations')
  const { data } = useSamlRoleMappingsQuery(state?.samlConfigurationId)
  const notify = useNotify()
  const downloadRoleMappingsMutation = useDownloadRoleMappings()
  const permissions = usePermissions('saml-role-mappings')
  const userCanCreateRoleMappings = permissions('create')

  const downloadCSV = () => {
    downloadRoleMappingsMutation.mutate(state?.samlConfigurationId, {
      onSuccess: (response: string) => {
        const file = new Blob([response], { type: 'text/csv' })
        saveAs(file, 'CutoverRoleMappings.csv')
        notify.success(t('roleMappingSubheader.toasters.success'))
      },
      onError: () => {
        notify.error(t('roleMappingSubheader.toasters.failure'))
      }
    })
  }

  return (
    <SubHeader.Items>
      <SubHeader.LeftItems>
        <Box flex="grow" justify="center">
          {`${data?.saml_role_mappings.length} ${t('roleMappingSubheader.roleMappings')}`}
        </Box>
      </SubHeader.LeftItems>
      <SubHeader.RightItems>
        {userCanCreateRoleMappings ? (
          <Menu
            trigger={
              <IconButton
                tertiary
                data-testid="saml-mappings-sub-header-options-menu"
                label="More options"
                disableTooltip
                icon={'more-vertical'}
              />
            }
          >
            <MenuListItem
              label={t('roleMappingSubheader.downloadMenuItem')}
              key={'Download'}
              icon={'download'}
              onClick={downloadCSV}
            />
          </Menu>
        ) : (
          []
        )}
      </SubHeader.RightItems>
    </SubHeader.Items>
  )
}
