// @ts-nocheck
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { colors, transitions, px2rem } from 'app/Styles/Variables'
import { Icon } from 'Components/Atoms'
import { ToolTip } from 'Components/Molecules'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'

const extraRightIconStyles = {
  display: 'inline-block',
  fontSize: '24px',
  transition: transitions.mediumBezier
}

const CloseButton = styled.button`
  cursor: pointer;
  margin-top: ${px2rem(20)};
  padding: 0;
  line-height: ${px2rem(32)};
  > i {
    color: ${colors.secondaryGrey};
    &:hover {
      color: ${colors.primaryGreyHover};
    }
  }
`

interface IClearFilterProps {
  clearFilters: () => void
  showClearFilterButton: boolean
  language: ILanguageService
}

export const ClearFilter = observer<IClearFilterProps>(({ clearFilters, showClearFilterButton, language }) => {
  const ClearFilterIcon = () => <Icon name="dnd-forwardslash" extraStyles={extraRightIconStyles} />

  return (
    <>
      <ToolTip
        message={language.get('components:molecules:clearFilters:toolTip')}
        trigger={
          <CloseButton
            aria-label={language.get('components:molecules:clearFilters:ariaLabel')}
            onClick={() => {
              clearFilters()
            }}
          >
            {showClearFilterButton && <ClearFilterIcon />}
          </CloseButton>
        }
        position="bottom center"
        oneLineTooltip={true}
      />
    </>
  )
})
