import styled from 'styled-components/macro'

import { LegacyBox as Box, colors } from '@cutover/react-ui'

export const VolumeComponent = styled(Box)`
  width: 100%;
  margin-bottom: 32px;
  display: block;
  .chart {
    position: relative;
    padding-top: 40px;
    .background {
      position: absolute;
      top: 0;
      left: 160px;
      right: 0;
      bottom: 0;
      .parent-interval {
        position: absolute;
        top: 0;
        bottom: 0;
        border-left: 1px solid ${colors.bg3};
        box-sizing: border-box;
        div {
          position: absolute;
          top: 0;
          left: 4px;
          right: 0;
          color: ${colors.textLight};
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          cursor: default;
        }
        &:hover {
          cursor: pointer;
          div {
            cursor: pointer;
            color: ${colors.text};
          }
          background-color: ${colors.bg1};
        }
        &.non-clickable:hover {
          cursor: default;
          div {
            cursor: default;
            color: ${colors.textDisabled};
          }
          background-color: inherit;
        }
      }
      .interval {
        position: absolute;
        top: 18px;
        font-size: 14px;
        bottom: 0;
        border-left: 1px solid ${colors.bg2};
        box-sizing: border-box;
        text-align: left;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        div {
          position: absolute;
          top: 0;
          left: 4px;
          right: 0;
          color: ${colors.textLight};
          cursor: default;
        }
        &:hover {
          cursor: pointer;
          div {
            cursor: pointer;
            color: ${colors.text};
          }
          background-color: ${colors.bg1};
        }
        &.non-clickable:hover {
          cursor: default;
          div {
            cursor: default;
            color: ${colors.textDisabled};
          }
          background-color: inherit;
        }
      }
    }
    .attribute-row {
      height: 56px;
      padding: 4px 0
      margin-bottom: 0px;
      margin-left: 160px;
      position: relative;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;

      cursor: pointer;
      &.total {
        cursor: default;
      }
      svg {
        width: 100%;
        height: 48px;
      }
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        svg path {
          stroke: none !important;
        }
      }
      .name {
        position: absolute;
        left: -168px;
        width: 168px;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        bottom: 0;
        top:0;
        box-sizing:border-box;
        padding-left:8px;
        overflow: hidden;
        color: ${colors.text};
      }

      &:hover {
        background-color: ${colors.bg1};
        .name {
          background-color: ${colors.bg1};
        }
      }
      &.total:hover {
        background:none;
        .name { background:none; }
      }
      &.non-clickable {
        cursor: default;
        opacity: 0.7;
      }
    }
    &.fullwidth {
      .attribute-row {
        margin-left: 0;
        height: 96px;
        .name {
          display: none;
        }
        svg {
          height: 96px;
        }
      }
      .background {
        left: 0;
      }
    }
  }
`
