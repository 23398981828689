// @ts-nocheck
import { useState, useEffect } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { Icon } from 'Components/Atoms/Icon'
import ReactHtmlParser from 'react-html-parser'
import { backgroundColor, textColor, themeColors } from 'app/Shared/Themes/Variables'
import { observer } from 'mobx-react-lite'
import { px2rem } from 'app/Styles/Variables'

const Wrapper = styled.div`
  display: inline-block;
  font-size: ${px2rem(14)};
  color: ${textColor};
  background-color: ${backgroundColor};
  width: 100%;
`

const Summary = styled.div`
  padding: ${px2rem(8)};
`

const Content = styled.li`
  line-height: ${px2rem(18)};
  font-size: ${px2rem(14)};
  margin-right: ${px2rem(10)};
`

const MessageList = styled.ul`
  margin-block-start: ${px2rem(-5)};
  margin-bottom: ${px2rem(10)};
  padding-left: ${px2rem(60)};
  list-style: circle;
`

export const AlertMulti = observer((props: any) => {
  const { iconName, iconSize, messages, messageSummary, theme } = props.presenter

  const [messagesList, setMessagesList] = useState([])

  let IconRender = () => <Icon name={iconName} size={iconSize} color={themeColors[theme].color} />

  useEffect(() => {
    const messagesList = []
    messages.map(message => {
      messagesList.push(<Content key={message.key.toString()}>{ReactHtmlParser(message.message)}</Content>)
    })
    setMessagesList(messagesList)
  }, [messages])

  return (
    <ThemeProvider theme={{ mode: theme }}>
      <Wrapper>
        <Summary>
          {iconName && <IconRender />} <strong>{messageSummary}</strong>
        </Summary>
        <MessageList>{messagesList}</MessageList>
      </Wrapper>
    </ThemeProvider>
  )
})
