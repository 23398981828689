import { ReactNode, useMemo } from 'react'
import { unescape } from 'lodash'
import styled from 'styled-components/macro'
import tc from 'tinycolor2'
import { LiteralUnion } from 'type-fest'

import { useDeferTooltipTextTruncation } from '../utilities'
import { Icon, IconName } from '../icon'
import { Box } from '../layout'
import { ColorProp, resolveColor, useTheme } from '../theme'
import { Text } from '../typography'

export type PillProps = {
  size?: 'small' | 'medium'
  label: string
  tip?: string
  icon?: IconName
  color?: LiteralUnion<ColorProp, string>
  className?: string
  suffix?: ReactNode
  'data-testid'?: string
  'data-cy'?: string
  themeType?: 'dark' | 'light'
  truncate?: boolean | 'tip'
  children?: ReactNode
}

export const STATUS_ATTR_MAP = {
  info: { color: 'text-light', icon: 'info' },
  idea: { color: 'primary', icon: 'lightbulb' },
  success: { color: 'success', icon: 'check' },
  warning: { color: 'warning', icon: 'alert' },
  error: { color: 'error', icon: 'task-deleted' }
}

export const StatusPill = ({
  status = 'info',
  showIcon = false,
  children,
  size = 'medium'
}: {
  status: 'success' | 'warning' | 'error' | 'idea' | 'info'
  children: ReactNode
  showIcon?: boolean
  size?: 'small' | 'medium'
}) => {
  const color = STATUS_ATTR_MAP[status].color
  const icon = STATUS_ATTR_MAP[status].icon

  return (
    <Pill color={color} icon={(showIcon ? icon : undefined) as IconName} label="" children={children} size={size} />
  )
}

export const Pill = ({
  size = 'medium',
  className,
  label,
  tip,
  icon,
  color,
  suffix,
  truncate: userTruncate = 'tip',
  children,
  ...props
}: PillProps) => {
  const truncate = useDeferTooltipTextTruncation(userTruncate)
  const theme = useTheme()
  const themeType = props.themeType ?? (theme.dark ? 'dark' : 'light')
  const resolvedTheme = useMemo(
    () => ({
      ...theme,
      dark: themeType === 'dark'
    }),
    [theme, themeType]
  )

  const baseColor = resolveColor(color ?? 'bg-3', resolvedTheme)
  const bgColor = resolveColor('bg', resolvedTheme)
  const defaultColor = resolveColor('bg-3', resolvedTheme)
  const pillBackgroundColor = color ? tc.mix(tc(baseColor), tc(bgColor), 92).toRgbString() : defaultColor

  return (
    <PillWrapper background={pillBackgroundColor} size={size} className={className} {...props}>
      {icon && size !== 'small' && (
        <Icon icon={icon} color={color ? baseColor : 'text-light'} size="18px" css="margin: 2px 2px 2px -2px;" />
      )}
      <Text
        size={size === 'small' ? '11px' : '13px'}
        color={color ? baseColor : 'text-light'}
        weight={500}
        truncate={children ? false : truncate}
        tip={tip}
        tipPlacement="top"
        tipMaxWidth={340}
        css={`
          white-space: nowrap;
          overflow: hidden;
          line-height: ${size === 'small' ? '18px' : '22px'};
        `}
      >
        {children ? children : unescape(label)}
      </Text>
      {suffix && size !== 'small' && (
        <Box direction="row" alignSelf="start" css="margin: -1px -4px 0 2px; flex-shrink:0;">
          {suffix}
        </Box>
      )}
    </PillWrapper>
  )
}

type PillWrapperProps = {
  background: string
  size: 'small' | 'medium'
}

// Note having to force height otherwise display:flex can give unpredictable results
const PillWrapper = styled.span<PillWrapperProps>`
  min-width: 0; // Allows flex items to shrink below initial
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  border-radius: 4px;
  height: ${props => (props.size === 'small' ? '18px' : '22px')};
  padding: ${props => (props.size === 'small' ? '0 4px' : '0 6px')};
  background-color: ${props => props.background};
`
