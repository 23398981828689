// @ts-nocheck
import { observable, action } from 'mobx'
import { DraggablePresenter } from './DraggablePresenter'

export class DragDropPresenter {
  @observable
  public onDragEndCall: (result: any) => void

  @observable
  public draggables: DraggablePresenter[]

  @action
  public onDragEnd = (dragEndResult: any) => {
    if (!dragEndResult.destination) return

    const result = Array.from(this.draggables)
    const [removed] = result.splice(dragEndResult.source.index, 1)
    result.splice(dragEndResult.destination.index, 0, removed)
    this.draggables = result

    if (this.onDragEndCall) {
      this.onDragEndCall(this.draggables.map((draggable: DraggablePresenter) => draggable.id))
    }
  }

  @action
  public withOnDragEndCall = (onDragEndCall: (result: any) => void) => {
    this.onDragEndCall = onDragEndCall
    return this
  }

  @action
  public withDraggables = (draggables: DraggablePresenter[]) => {
    this.draggables = draggables
    return this
  }
}
