import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const TaskTypeTriangleDashed = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 -2 48 48" a11yTitle="TaskTypeTriangleDashed" {...props}>
    <path d="M39.272 37.89c2.863 0 4.653-3.099 3.221-5.578l-2.704-4.684 1.732-1 2.704 4.684c2.202 3.813-.55 8.579-4.953 8.579H34v-2h5.272Zm-1.483-13.727 1.732-1-6-10.392-1.732 1 6 10.392Zm-8-13.856L27.22 5.86c-1.431-2.48-5.01-2.48-6.442 0l-2.606 4.513-1.732-1 2.606-4.513c2.201-3.813 7.705-3.813 9.906 0l2.568 4.447-1.732 1ZM14.44 12.838l1.733 1-6 10.392-1.732-1 6-10.393Zm-8 13.856-2.665 4.618c-2.202 3.813.55 8.579 4.953 8.579H14v-2H8.728c-2.863 0-4.653-3.1-3.221-5.58l2.666-4.617-1.732-1ZM18 37.89v2h12v-2H18Z" fill="#000" />
  </StyledIcon>
))

TaskTypeTriangleDashed.displayName = 'TaskTypeTriangleDashed'

export { TaskTypeTriangleDashed }
