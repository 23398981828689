import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Archive = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Archive" {...props}>
    <path d="m24.07 37-8.484-8.485 2.12-2.122 4.986 4.986V19h3v12.186l4.793-4.793 2.121 2.122L24.121 37l-.025-.025-.025.025Z" fill="#000" /><path d="M6 16a6 6 0 0 1 1.2-3.6l3-4A6 6 0 0 1 15 6h18a6 6 0 0 1 4.8 2.4l3 4A6 6 0 0 1 42 16v20a6 6 0 0 1-6 6H12a6 6 0 0 1-6-6V16Zm33 .5H9V36a3 3 0 0 0 3 3h24a3 3 0 0 0 3-3V16.5Zm-1.125-3L35.4 10.2A3 3 0 0 0 33 9H15a3 3 0 0 0-2.4 1.2l-2.475 3.3h27.75Z" fill="#000" />
  </StyledIcon>
))

Archive.displayName = 'Archive'

export { Archive }
