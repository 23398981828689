import { Editor, Node, Element as SlateElement, Text, Transforms } from 'slate'
import { ReactEditor } from 'slate-react'

import { Align, CustomElement, CustomElementTypes, isAlignType, isListItemType } from '../text-editor-types'

export const getParentBlock = (editor: Editor) => {
  const { selection } = editor
  if (!selection || !selection.anchor) return false
  return editor.children[selection.anchor.path[0]] as CustomElement
}

export const getParentBlockType = (editor: Editor) => {
  const parentBlock = getParentBlock(editor)
  if (!parentBlock) {
    return
  }
  return parentBlock.type
}

export const isBlockDisabled = (editor: Editor) => {
  const { selection } = editor
  if (!selection) return false
  const parentBlock = getParentBlock(editor)
  if (parentBlock && parentBlock.type === 'table') {
    return true
  }
  return false
}

export const createEmptyParagraphNode = () => {
  return { type: 'paragraph', children: [{ text: '' }] } as Node
}

export const isNodeEmpty = (editor: Editor, node: Node, trim = false): boolean => {
  if (Text.isText(node)) {
    return trim ? node.text.trim() === '' : node.text === ''
  }

  if (isVoid(editor, node)) {
    return false
  }

  return node.children.every(child => isNodeEmpty(editor, child, trim))
}

export const isVoid = (editor: Editor, node: Node): boolean => {
  return SlateElement.isElement(node) && Editor.isVoid(editor, node)
}

export const isBlockActive = (
  editor: Editor,
  format: CustomElementTypes | Align,
  blockType = 'type',
  options?: any
) => {
  try {
    const { selection } = editor
    if (!selection) return false

    // TODO need to modularize this logic, eg so each blockType can have its own isActive func
    if (format === 'message') {
      const parentBlock = getParentBlock(editor)
      if (!parentBlock || parentBlock.type !== 'message') {
        return false
      }
      return !options || (options && options['messageType'] && parentBlock.messageType === options['messageType'])
    } else {
      const [match] = Array.from(
        Editor.nodes(editor, {
          at: Editor.unhangRange(editor, selection),
          match: (n: Node) => !Editor.isEditor(n) && SlateElement.isElement(n) && (n as any)[blockType] === format
        })
      )

      return !!match
    }
  } catch (e) {
    return false
  }
}

export const toggleBlock = (editor: Editor, format: CustomElementTypes | Align, options?: any) => {
  const isActive = isBlockActive(editor, format, isAlignType(format) ? 'align' : 'type', options)
  const isList = isListItemType(format)
  const parentBlock = getParentBlock(editor)

  // Fix bug where setting blocks inside table broke the table structure
  // In future can allow this but quick fix is to prevent these elems being added
  if (parentBlock && parentBlock.type === 'table') {
    return
  }

  Transforms.unwrapNodes(editor, {
    match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && isListItemType(n.type) && !isAlignType(format),
    split: true
  })

  let newProperties: {}
  if (isAlignType(format)) {
    newProperties = {
      align: isActive ? undefined : format
    }
  } else if (format === 'message') {
    newProperties = {
      type: isActive ? 'paragraph' : 'message',
      messageType: options?.messageType ?? 'info'
    }
  } else {
    newProperties = {
      type: isActive ? 'paragraph' : isList ? 'list-item' : format
    }
  }
  Transforms.setNodes(editor, newProperties)

  // Refocus since clicking menu outside editor loses it
  ReactEditor.focus(editor)

  if (!isActive && isList) {
    const block: CustomElement = { type: format, children: [] }
    Transforms.wrapNodes(editor, block)
  }
}
