import { createContext, ReactNode, useCallback, useContext, useState } from 'react'
import { useMatch } from 'react-router-dom'

import { ValidateMergeResponse } from 'main/services/queries/validate-merge-query'

export type MergeRunbooksStep =
  | 'choose-merge-type'
  | 'choose-new-runbook-type'
  | 'enter-new-runbook-details'
  | 'validation-summary'

export type MergeType = 'new' | 'existing'

export type MergeRunbooksContextType = {
  step: MergeRunbooksStep
  setStep: (step: MergeRunbooksStep) => void
  runbookIds: number[]
  accountId: number
  accountSlug?: string
  reset: () => void
  mergeType: MergeType
  setMergeType: (type: MergeType) => void
  mergeValidationSummary?: ValidateMergeResponse
  setMergeValidationSummary: (validation: ValidateMergeResponse) => void
}

const MergeRunbooksContext = createContext<MergeRunbooksContextType>({
  step: 'choose-merge-type',
  setStep: () => {},
  runbookIds: [],
  accountId: 0,
  reset: () => {},
  accountSlug: '',
  mergeType: 'new',
  setMergeType: () => {},
  mergeValidationSummary: undefined,
  setMergeValidationSummary: () => {}
})

type MergeRunbooksModalProviderProps = {
  runbookIds: number[]
  accountId: number
  children: ReactNode
}

export const MergeRunbooksModalProvider = ({ children, runbookIds, accountId }: MergeRunbooksModalProviderProps) => {
  const [step, setStep] = useState<MergeRunbooksStep>('choose-merge-type')
  const [mergeType, setMergeType] = useState<'new' | 'existing'>('new')
  const [mergeValidationSummary, setMergeValidationSummary] = useState<ValidateMergeResponse>()
  const match = useMatch({ path: '/app/:accountSlug/runbooks/:display', end: false })
  const accountSlug = match?.params.accountSlug

  const reset = useCallback(() => {
    setStep('choose-merge-type')
    setMergeType('new')
    setMergeValidationSummary(undefined)
  }, [])

  return (
    <MergeRunbooksContext.Provider
      value={{
        step,
        setStep,
        mergeType,
        setMergeType,
        mergeValidationSummary,
        setMergeValidationSummary,
        accountId,
        accountSlug,
        runbookIds,
        reset
      }}
    >
      {children}
    </MergeRunbooksContext.Provider>
  )
}

export const useMergeRunbooksContext = () => useContext(MergeRunbooksContext)
