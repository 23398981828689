import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const BrandChangeGovernance = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 100 101" a11yTitle="BrandChangeGovernance" {...props}>
    <g clipPath="url(#a)" fill="#fff"><path opacity=".5" d="M99.667 100.333v-47l-46.667 47h46.667ZM99.834.5h-47l47 46.666V.5Z" /><path d="M0 100.333a50 50 0 0 0 0-100v100Z" /></g>
  </StyledIcon>
))

BrandChangeGovernance.displayName = 'BrandChangeGovernance'

export { BrandChangeGovernance }
