import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const BracketMinus = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="BracketMinus" {...props}>
    <path d="M14 6h2v3h-2a5 5 0 0 0-5 5v20a5 5 0 0 0 5 5h2v3h-2a8 8 0 0 1-8-8V14a8 8 0 0 1 8-8Zm20 33h-2v3h2a8 8 0 0 0 8-8V14a8 8 0 0 0-8-8h-2v3h2a5 5 0 0 1 5 5v20a5 5 0 0 1-5 5Z" fill="#000" /><path d="M32.5 22.5h-17v3h17v-3Z" fill="#000" />
  </StyledIcon>
))

BracketMinus.displayName = 'BracketMinus'

export { BracketMinus }
