// @ts-nocheck
import styled from 'styled-components'
import { Icon } from 'Components/Atoms'
import { px2rem, colors } from 'app/Styles/Variables'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${px2rem(40)};
  height: ${px2rem(40)};
  border-radius: 50%;
  color: ${colors.textLight};
  cursor: pointer;
  &:hover {
    background-color: ${colors.bg1};
  }
`

interface IModalBackButtonProps {
  onClick: () => void
  className?: string
  dataTestId?: string
}

export const ModalBackButton = ({ onClick, className, dataTestId }: IModalBackButtonProps) => (
  <Wrapper className={className} data-testid={dataTestId} onClick={onClick}>
    <Icon name={'arrow-back'} size={`${px2rem(24)}`} />
  </Wrapper>
)
