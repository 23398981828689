import { useEffect, useState } from 'react'
import { useDebounce } from 'react-use'

import { Box, TextInput } from '@cutover/react-ui'
import { FilterGroupExtraOptions } from './filter-group-extra-options'
import { getFilterSelections } from '../filter-helpers'
import {
  FilterGroupText as FilterGroupTextType,
  FilterOptionCheckbox,
  FilterOptionText,
  SelectedFilters
} from '../filter-types'
import { useInitialMount, useLanguage } from 'main/services/hooks'

export const FilterGroupText = ({
  filter,
  onChange,
  selected = {},
  withNoValueOption,
  withAnyValueOption
}: {
  filter: FilterGroupTextType
  onChange: (option: FilterOptionText | FilterOptionCheckbox) => void
  selected?: SelectedFilters
  withNoValueOption?: boolean
  withAnyValueOption?: boolean
}) => {
  return (
    <Box>
      {filter.options.map(option => (
        <FilterGroupTextInput
          key={`${filter.title}-${option.value}`}
          filter={filter}
          selected={selected}
          onChange={onChange}
          option={option}
        />
      ))}
      {(withNoValueOption || withAnyValueOption) && filter.hasOwnProperty('slug') && (
        <FilterGroupExtraOptions
          withAnyValueOption={withAnyValueOption}
          withNoValueOption={withNoValueOption}
          selected={getFilterSelections(selected, filter)}
          onChange={(_, option) => onChange(option)}
        />
      )}
    </Box>
  )
}

const FilterGroupTextInput = ({
  filter,
  onChange,
  selected = {},
  option
}: {
  filter: FilterGroupTextType
  onChange: (option: FilterOptionText | FilterOptionCheckbox) => void
  selected?: SelectedFilters
  option: FilterOptionText
}) => {
  const selection = getFilterSelections(selected, filter, option)
  const initialValue = Array.isArray(selection) || selection === '*' || selection === 0 ? '' : (selection as string)

  const [value, setValue] = useState(initialValue)
  const [query, setQuery] = useState(initialValue)
  const [debouncedQuery, setDebouncedQuery] = useState<string | null>('')

  const { t } = useLanguage('common', { keyPrefix: 'filter' })
  const isInitialMount = useInitialMount()

  const [, cancel] = useDebounce(
    () => {
      setDebouncedQuery(query)
    },
    300,
    [query]
  )

  useEffect(() => {
    if (isInitialMount) {
      return
    }

    if (Array.isArray(selection) || selection === '*' || selection === 0) {
      cancel()
      // Clear debounced query to detect next change
      setDebouncedQuery(null)
      setValue('')
    }
  }, [selection])

  useEffect(() => {
    if (isInitialMount) {
      return
    }

    setValue(query)
  }, [query])

  useEffect(() => {
    return () => {
      cancel()
    }
  }, [])

  useEffect(() => {
    if (isInitialMount) {
      return
    }

    if (debouncedQuery !== null) {
      onChange({ ...option, value: debouncedQuery || undefined })
    }
  }, [debouncedQuery])

  return <TextInput value={value || ''} icon="search" label={t('searchFor')} onChange={e => setQuery(e.target.value)} />
}
