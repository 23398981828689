import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Globe = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Globe" {...props}>
    <path d="M24 44c11.046 0 20-8.954 20-20S35.046 4 24 4c-6.319 0-11.953 2.93-15.618 7.505A19.908 19.908 0 0 0 4 24a19.906 19.906 0 0 0 4.382 12.495C12.047 41.07 17.682 44 24 44Zm-13.14-9.213c.11-.059.223-.117.34-.175 1.13-.565 2.443-1.063 3.904-1.469.68 2.643 1.646 4.943 2.816 6.738a17.04 17.04 0 0 1-7.06-5.094Zm7.174-2.295A35.536 35.536 0 0 1 24 32c2.113 0 4.119.176 5.966.492-.425 1.623-.962 3.075-1.578 4.309C26.738 40.1 25.013 41 24 41s-2.738-.9-4.388-4.2c-.616-1.233-1.153-2.684-1.578-4.308Zm12.542-2.939A38.5 38.5 0 0 0 24 29c-2.304 0-4.516.195-6.576.553A35.761 35.761 0 0 1 17 24c0-1.956.151-3.821.424-5.553 2.06.358 4.272.553 6.576.553a38.49 38.49 0 0 0 6.576-.553C30.849 20.179 31 22.044 31 24a35.76 35.76 0 0 1-.424 5.553Zm2.934.648c.318-1.953.49-4.037.49-6.201a38.68 38.68 0 0 0-.49-6.2c2.01-.545 3.811-1.256 5.331-2.097A16.923 16.923 0 0 1 41 24c0 3.013-.784 5.843-2.159 8.297-1.52-.841-3.322-1.552-5.33-2.096Zm-.614 2.942c1.461.406 2.774.904 3.904 1.47.117.057.23.115.34.174a17.04 17.04 0 0 1-7.06 5.094c1.17-1.795 2.136-4.095 2.816-6.738Zm0-18.286c-.68-2.643-1.646-4.943-2.816-6.738a17.04 17.04 0 0 1 7.06 5.094c-.11.059-.223.117-.34.175-1.13.565-2.443 1.063-3.904 1.469Zm-2.93.65A35.537 35.537 0 0 1 24 16c-2.113 0-4.119-.177-5.966-.492.425-1.623.962-3.075 1.578-4.308C21.262 7.9 22.987 7 24 7s2.738.9 4.388 4.2c.616 1.233 1.153 2.684 1.578 4.308Zm-14.862-.65c-1.461-.406-2.775-.904-3.905-1.47a11.982 11.982 0 0 1-.34-.174A17.04 17.04 0 0 1 17.92 8.12c-1.17 1.795-2.136 4.095-2.816 6.738Zm-5.945.846c1.52.841 3.322 1.552 5.33 2.096A38.684 38.684 0 0 0 14 24c0 2.164.172 4.248.49 6.2-2.01.545-3.811 1.256-5.331 2.097A16.923 16.923 0 0 1 7 24c0-3.013.784-5.843 2.159-8.297Z" fill="#000" />
  </StyledIcon>
))

Globe.displayName = 'Globe'

export { Globe }
