import { matchPath, To } from 'react-router-dom'

const EXCLUDE_APP_SEGMENTS = ['access', 'settings', 'my-cutover']
const INCLUDE_WORKSPACE_SEGMENTS = ['runbooks', 'settings']
const EXCLUDE_RUNBOOK_SEGMENTS = ['dashboard']

type AppResourceURLParams = {
  accountSlug: undefined
  runbookId: undefined
  runbookVersionId: undefined
}

type WorkspaceResourceURLParams = {
  accountSlug: string
  runbookId: undefined
  runbookVersionId: undefined
}

type RunbookResourceURLParams = {
  accountSlug: string
  runbookId: number
  runbookVersionId: number | 'current_version'
}

type ResourceURLParams = AppResourceURLParams | WorkspaceResourceURLParams | RunbookResourceURLParams

export function parseResourceURLParams(to: To) {
  const pathname = typeof to === 'string' ? to : to.pathname ?? ''

  const accountMatchPath = matchPath('/app/:accountSlug/:accountSegment/*', pathname)
  const runbookMatch = matchPath('/app/:accountSlug/runbooks/:runbookId/:runbookVersionId/*', pathname)

  const accountSlugParam = accountMatchPath?.params.accountSlug ?? ''
  const accountPageParam = accountMatchPath?.params.accountSegment ?? ''
  const runbookParam = runbookMatch?.params.runbookId ?? ''
  const runbookVersionParam = runbookMatch?.params.runbookVersionId ?? ''

  const accountSlug = INCLUDE_WORKSPACE_SEGMENTS.includes(accountPageParam)
    ? parseResourceSegment(accountSlugParam, EXCLUDE_APP_SEGMENTS)
    : undefined
  const runbookId = parseResourceSegment(runbookParam, EXCLUDE_RUNBOOK_SEGMENTS)
  const runbookVersionId = runbookId ? parseResourceSegment(runbookVersionParam) : undefined

  return {
    accountSlug,
    runbookId,
    runbookVersionId
  } as ResourceURLParams
}

const parseResourceSegment = (id: string | undefined, excludes: string[] = []) => {
  if (!id || excludes.includes(id ?? '')) return undefined
  if (id === 'current_version') return id
  const parsed = Number(id)
  return isNaN(parsed) ? id : parsed
}
