import { useState } from 'react'

import { Box, colors, Link, Select, Text } from '@cutover/react-ui'
import { FilterGroupCheckbox } from './filter-group-checkbox'
import { getFilterSelections } from '../filter-helpers'
import {
  FilterGroupSelect as FilterGroupSelectType,
  FilterOptionCheckbox,
  FilterOptionSelect,
  SelectedFilters
} from '../filter-types'
import { useLanguage } from 'main/services/hooks'

type FilterGroupSelectProps = {
  filter: FilterGroupSelectType
  onChange: (event?: any, option?: FilterOptionSelect | FilterOptionCheckbox) => void
  selected?: SelectedFilters
  checkboxes?: FilterOptionCheckbox[]
  displayLimit?: number
  withAnyValueOption?: boolean
  withNoValueOption?: boolean
}

export const FilterGroupSelect = ({ expandable, ...props }: FilterGroupSelectProps & { expandable?: boolean }) =>
  expandable ? <FilterGroupSelectExpandable {...props} /> : <FilterGroupSelectDefault {...props} />

const FilterGroupSelectExpandable = ({
  filter,
  onChange,
  selected = {},
  displayLimit,
  withAnyValueOption,
  withNoValueOption
}: FilterGroupSelectProps) => {
  const [showAll, setShowAll] = useState(false)
  const { t } = useLanguage('common', { keyPrefix: 'filter' })

  return (
    <Box gap="small">
      {!showAll && displayLimit && filter.options.length > displayLimit ? (
        <FilterGroupSelectDefault
          filter={filter}
          selected={selected}
          onChange={onChange}
          withAnyValueOption={withAnyValueOption}
          withNoValueOption={withNoValueOption}
        />
      ) : (
        <FilterGroupCheckbox
          filter={{
            ...filter,
            type: 'checkbox'
          }}
          selected={selected}
          onChange={onChange}
          withAnyValueOption={withAnyValueOption}
          withNoValueOption={withNoValueOption}
        />
      )}
      {displayLimit && filter.options.length > displayLimit && (
        <Box width="100%" justify="center" align="center">
          <Link
            underline="none"
            colorHover={colors.primary}
            color={colors.primary}
            as="button"
            onClick={() => setShowAll(!showAll)}
            size={16}
          >
            <Text>{showAll ? t('showLess') : `${t('showAll')} (${filter.options.length})`}</Text>
          </Link>
        </Box>
      )}
    </Box>
  )
}

const FilterGroupSelectDefault = ({
  filter,
  onChange,
  selected = {},
  withAnyValueOption,
  withNoValueOption
}: FilterGroupSelectProps) => {
  const { t } = useLanguage('common', { keyPrefix: 'filter' })

  const handleSelect = (value?: string | null) => {
    onChange(filter.options.find(option => option.value === value))
  }

  const { selectedOptions, unselectedOptions } = filter.options.reduce<{
    selectedOptions: FilterOptionSelect[]
    unselectedOptions: FilterOptionSelect[]
  }>(
    (acc, option) => {
      const selection = getFilterSelections(selected, filter, option)

      if (Array.isArray(selection)) {
        acc[selection.includes(option.value) ? 'selectedOptions' : 'unselectedOptions'].push(option)
        return acc
      }

      acc[selection === option.value ? 'selectedOptions' : 'unselectedOptions'].push(option)
      return acc
    },
    {
      selectedOptions: [],
      unselectedOptions: []
    }
  )

  return (
    <Box direction="column">
      {filter.options.length > 0 && (
        <>
          {(selectedOptions.length > 0 || withAnyValueOption || withNoValueOption) && (
            <FilterGroupCheckbox
              selected={selected}
              filter={{ ...filter, type: 'checkbox', options: selectedOptions }}
              onChange={onChange}
              withAnyValueOption={withAnyValueOption}
              withNoValueOption={withNoValueOption}
            />
          )}
          <Select
            name={filter.title}
            // FIXME: need to use this for now becuase the a11yTitle isn't getting passed properly to the wrapping label when
            // the component does not have a label prop.
            data-testid={`${filter.title}-select`}
            a11yTitle={filter.title}
            onChange={handleSelect}
            // hack so that the select options don't flash the previous set when the dropdown opens because we are
            // using a single select to approximate a multiselect by changing the available options after each selection
            // and rendering what we don't pass in as options above.
            key={unselectedOptions.length}
            // So that the select input doesn't show a value after selecting an option. The selected options are shown above as checkboxes.
            optionToString={() => ''}
            placeholder={t('selectFieldLabel')}
            options={unselectedOptions}
          />
        </>
      )}
    </Box>
  )
}
