// @ts-nocheck
import { observer } from 'mobx-react-lite'
import { withPresenter } from 'app/Config/withPresenter'
import AngularRunbooksPage from './AngularRunbooksPage'
import { RunbookPageModalsPresenter } from 'app/Account/Runbooks/RunbookPage/RunbookPageModals/RunbookPageModalsPresenter'
import { AngularChooseRunbookStartTime } from 'app/Account/Runbooks/RunbooksPage/AngularRunbooksPageModals/AngularChooseRunbookStartTime/AngularChooseRunbookStartTime'

type RunbookPageComponentProps = {
  pageKey: string
}

const RunbooksPage = ({ pageKey }: RunbookPageComponentProps) => {
  return (
    <>
      <AngularRunbooksPage />
      <RunbooksPageModals />
    </>
  )
}
export default RunbooksPage

const RunbooksPageModalsComponent = observer<IRunbookPageModalsComponentProps>(({ presenter }) => {
  return (
    <>
      <AngularChooseRunbookStartTime modalPresenter={presenter.chooseRunbookStartTimeModalPresenter} />
    </>
  )
})

const RunbooksPageModals = withPresenter(RunbookPageModalsPresenter)(RunbooksPageModalsComponent)
