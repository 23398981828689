import { FilterGroup, FilterOption, SelectedFilters } from '../filter-types'

/**
 * @description Returns the selected values from the 'selected' object depending on the placement of the slug
 */
export const getFilterSelections = (selected: SelectedFilters, filter: FilterGroup, option?: FilterOption) => {
  if (filter.customFieldId && filter.slug) {
    // @ts-ignore
    return selected[filter.slug]?.[filter.customFieldId]
  }

  if (option && option.slug) {
    return selected[option.slug]
  }

  if (filter.slug) {
    return selected[filter.slug]
  }

  throw Error(
    `Filter component error: no property 'slug' found on the filter data for '${filter.title}'${
      option ? ` or the option '${option.label}'` : ''
    }`
  )
}
