import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Webhooks = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Webhooks" {...props}>
    <path d="M31.79 17.464a9 9 0 1 0-13.531 2.437l-5.263 9.115a3 3 0 0 0-1.5 5.599 3 3 0 0 0 4.098-4.099l6.7-11.606-1.298-.75a6 6 0 1 1 8.196-2.196l2.598 1.5Z" fill="#000" /><path d="M13 23a9 9 0 1 0 8.876 10.5H32.4A2.999 2.999 0 0 0 38 32a3 3 0 0 0-5.599-1.5H19V32a6 6 0 1 1-6-6v-3Z" fill="#000" /><path d="M39.504 39.84a9 9 0 0 1-12.294-3.294l2.598-1.5a6 6 0 1 0 2.196-8.196l-1.299.75-6.7-11.606a3 3 0 0 1-1.5-5.599 3 3 0 0 1 4.098 4.099l5.262 9.115c4.044-1.51 8.704.075 10.933 3.937a9 9 0 0 1-3.294 12.294Z" fill="#000" />
  </StyledIcon>
))

Webhooks.displayName = 'Webhooks'

export { Webhooks }
