// @ts-nocheck
import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { withPresenter } from 'app/Config/withPresenter'
import { TaskTypeAddPresenter } from './TaskTypeAddPresenter'
import { TaskTypeFormComponent } from 'app/Settings/TaskTypes/TaskTypesPage/TaskTypeForm/TaskTypeFormComponent'
import { TaskTypeFormPresenter } from 'app/Settings/TaskTypes/TaskTypesPage/TaskTypeForm/TaskTypeFormPresenter'

const Wrapper = styled.div`
  overflow-y: auto;
`

interface ITaskTypeAddComponentProps {
  postSuccessCallback: () => void
  presenter: TaskTypeAddPresenter
  addFormPresenter: TaskTypeFormPresenter
}

const TaskTypeAddSection = observer<ITaskTypeAddComponentProps>(
  ({ presenter, postSuccessCallback, addFormPresenter }) => {
    const { saveFunction, formSubmitted } = presenter

    presenter.withPostSuccessCall(postSuccessCallback)
    presenter.withTaskTypeFormPresenter(addFormPresenter)

    useEffect(() => {
      saveFunction()
    }, [formSubmitted])

    return (
      <Wrapper>
        <TaskTypeFormComponent presenter={addFormPresenter} formType="add" />
      </Wrapper>
    )
  }
)

export default withPresenter(TaskTypeAddPresenter)(TaskTypeAddSection)
