import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Upload = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Upload" {...props}>
    <path d="M24 6 13.201 16.799l2.121 2.121 7.178-7.177v22.47h3v-22.47l7.178 7.177 2.121-2.121L24 6Z" fill="#000" /><path d="M6 34.607v-5h3v5a5 5 0 0 0 5 5h20a5 5 0 0 0 5-5v-5h3v5a8 8 0 0 1-8 8H14a8 8 0 0 1-8-8Z" fill="#000" />
  </StyledIcon>
))

Upload.displayName = 'Upload'

export { Upload }
