// @ts-nocheck
import styled from 'styled-components'
import { Heading } from 'Components/Atoms'
import { Button } from 'Components/Molecules'
import { EditPanelDirtyConfirmModalPresenter } from './EditPanelDirtyConfirmModalPresenter'
import { breakpoints, px2rem } from 'app/Styles/Variables'

const Wrapper = styled.div`
  padding: ${px2rem(20)};
  @media ${breakpoints.sm} {
    padding-bottom: 0;
  }
`

const ButtonWrapper = styled.div`
  width: 100%;
  justify-content: space-between;
  display: flex;
  @media ${breakpoints.sm} {
    flex-wrap: wrap;
    justify-content: space-around;
  }
`

const CancelButtonWrapper = styled.div`
  @media ${breakpoints.sm} {
    margin-bottom: ${px2rem(20)};
  }
`

const ResetButtonWrapper = styled.div`
  margin-left: auto;
  @media ${breakpoints.sm} {
    margin-left: 0;
    margin-bottom: ${px2rem(20)};
  }
`

const SaveButtonWrapper = styled.div`
  margin-left: ${px2rem(20)};
  @media ${breakpoints.sm} {
    margin-left: 0;
    margin-bottom: ${px2rem(20)};
  }
`

interface IEditPanelDirtyConfirmModal {
  isSaving: boolean
  presenter: EditPanelDirtyConfirmModalPresenter
}

export const EditPanelDirtyConfirmModal = ({
  isSaving,
  presenter: { language, cancelButtonPresenter, resetButtonPresenter, saveButtonPresenter }
}: IEditPanelDirtyConfirmModal) => {
  saveButtonPresenter.disabled = isSaving

  return (
    <Wrapper>
      <Heading>{language.get('common:editDirtyModal:header')}</Heading>
      <p>{language.get('common:editDirtyModal:content')}</p>
      <ButtonWrapper>
        <CancelButtonWrapper>
          <Button presenter={cancelButtonPresenter} />
        </CancelButtonWrapper>
        <ResetButtonWrapper>
          <Button presenter={resetButtonPresenter} />
        </ResetButtonWrapper>
        <SaveButtonWrapper>
          <Button presenter={saveButtonPresenter} />
        </SaveButtonWrapper>
      </ButtonWrapper>
    </Wrapper>
  )
}
