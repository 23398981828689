import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Skipped = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Skipped" {...props}>
    <path d="M39 31V13l-6.244 6.244c-4.288-3.603-8.86-5.294-13.448-4.816-5.47.569-9.83 4.108-12.847 8.586l6.634 4.47c2.205-3.27 4.706-4.856 7.041-5.1 1.76-.182 4.107.328 6.936 2.544L21 31h18Z" fill="#000" />
  </StyledIcon>
))

Skipped.displayName = 'Skipped'

export { Skipped }
