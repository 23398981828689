// @ts-nocheck
import 'reflect-metadata'
import { observable, action, when, computed } from 'mobx'
import { inject, injectable } from 'inversify'
import { IRoleType } from 'app/Shared/Entities/Types/RoleType/IRoleType'
import { FiltersRepository } from 'app/Filters/FiltersRepository'
import { PermissableRepository } from 'app/Shared/Repositories/PermissableRepository'
import { IBaseResponse } from 'Gateways/Service/IBaseResponse'
import { ResourceFilterHelpers } from 'app/Filters/FilterHelpers/ResourceFilterHelpers'

interface IRoleTypeContext {
  contextTypeId: number
  contextTypeName: string
}

@injectable()
export class RoleTypesRepository extends PermissableRepository {
  @inject(FiltersRepository)
  private filtersRepository: FiltersRepository

  @observable
  public roleTypesTotal: number

  @computed
  public get filteredRoleTypeTotal(): number {
    return this.filteredRoleTypes.length
  }

  @observable
  public roleTypes: IRoleType[] = []

  @observable
  public loadedTypes: string

  @action
  public clearRoleData = () => {
    this.roleTypes = []
    this.loadedTypes = undefined
    this.roleTypesTotal = undefined
  }

  constructor() {
    super('meta')
  }

  @computed
  public get filteredRoleTypes(): IRoleType[] {
    const resourceHelper = new ResourceFilterHelpers()
    const selectedFilterLookup = resourceHelper.getResourceFilterLookup(this.filtersRepository.selectedFilters)

    if (Object.keys(selectedFilterLookup).length === 0) return this.roleTypes

    return this.roleTypes.filter((roleType: IRoleType) => {
      const matches: boolean[] = []

      Object.keys(selectedFilterLookup).forEach((reference: string) => {
        const property: string = this.getPropertyForFilterGroup(reference)

        if (
          property &&
          roleType.hasOwnProperty(property) &&
          selectedFilterLookup[reference].indexOf(roleType[property].toString()) !== -1
        ) {
          matches.push(true)
        } else {
          matches.push(false)
        }
      })

      if (!matches.includes(false)) return roleType
    })
  }

  public getPropertyForFilterGroup = (group: string): string => {
    if (group === 'context_type_id') return 'contextTypeId'
  }

  public safeLoad = async ({ display, reload }: { display?: string; reload?: boolean }) => {
    if (this.roleTypes.length > 0 && this.loadedTypes === display && !reload) return

    if (this.loading) {
      await when(() => this.roleTypes.length > 0)
    } else {
      this.clearRoleData()
      await this.loadData({ display })
    }
  }

  public preLoad = (args: { [key: string]: string }): string => {
    let requestUrl = 'role_types'
    if (args && args.display) {
      requestUrl += `?display=${args.display}`
      this.loadedTypes = args.display
    } else {
      this.loadedTypes = undefined
    }
    return requestUrl
  }

  @action
  public postLoad = (rawData: IBaseResponse): void => {
    this.roleTypes = rawData.body.role_types.map(roleTypeDto => {
      const roleType: IRoleType = {
        contextTypeName: roleTypeDto.context_type_name,
        contextTypeId: roleTypeDto.context_type_id,
        description: roleTypeDto.description,
        enabled: roleTypeDto.enabled,
        id: roleTypeDto.id,
        name: roleTypeDto.name,
        notify: roleTypeDto.notify,
        order: roleTypeDto.order,
        usersCount: roleTypeDto.users_count,
        viewPermissionType: roleTypeDto.view_permission_type,
        viewPermission: roleTypeDto.view_permission,
        editableFromContext: roleTypeDto.editable_from_context
      }

      return roleType
    })

    this.roleTypesTotal = this.roleTypes.length
  }

  public checkRolesLoaded = (displayType = undefined): boolean => {
    if (this.roleTypes.length > 0 && this.loadedTypes === displayType) return true
    return false
  }

  @action
  public updateRoleTypesWithEditedRoleType = roleTypeForEdit => {
    const updateRoleTypes = this.roleTypes.map(roleType => {
      if (roleType.id !== roleTypeForEdit.id) {
        return roleType
      }
      const returnRoleType = { ...roleType }
      returnRoleType.name = roleTypeForEdit.name
      returnRoleType.description = roleTypeForEdit.description
      returnRoleType.contextTypeName = roleTypeForEdit.contextTypeName
      returnRoleType.viewPermissionType = roleTypeForEdit.viewPermissionType
      returnRoleType.viewPermission = roleTypeForEdit.viewPermission
      returnRoleType.editableFromContext = roleTypeForEdit.editableFromContext
      returnRoleType.notify = roleTypeForEdit.notify

      return returnRoleType
    })

    this.roleTypes = updateRoleTypes
  }

  @computed
  public get roleTypesContext(): IRoleTypeContext[] {
    const foundContexts = []
    const roleTypesContext = []

    this.roleTypes.forEach((roleType: IRoleType) => {
      if (foundContexts.includes(roleType.contextTypeId)) return

      roleTypesContext.push({
        contextTypeId: roleType.contextTypeId,
        contextTypeName: roleType.contextTypeName
      })

      foundContexts.push(roleType.contextTypeId)
    })

    return roleTypesContext
  }

  @action
  public removeFromList = (id: number) => {
    const newList = this.roleTypes.filter(roleType => roleType.id !== id)
    this.roleTypesTotal = this.roleTypesTotal - 1
    this.roleTypes = newList
  }
}
