import { useWorkspaceData } from '../api/data-providers/workspace'
import { usePermittedResources } from '../queries/use-permitted-resources'

type UsePermittedProjectsProps = {
  accountId?: number
  enabled?: boolean
}
export const usePermittedProjects = ({ accountId, enabled = true }: UsePermittedProjectsProps) => {
  return usePermittedResources(
    {
      resource: 'runbook',
      accountId: accountId
    },
    { enabled: enabled }
  )
}

type UseWorkspacePermittedProjectsProps = UsePermittedProjectsProps
export const useWorkspacePermittedProjects = ({ accountId, enabled }: UseWorkspacePermittedProjectsProps) => {
  const { data, isLoading } = usePermittedProjects({ accountId, enabled })
  const { runbookLookup } = useWorkspaceData()

  const isAllPermitted = (runbookIds: number[]) => {
    return runbookIds.every(runbookId => {
      const runbook = runbookLookup?.[runbookId]
      return runbook && data?.projects.some(p => p.id === runbook.project_id)
    })
  }

  return {
    projects: data?.projects,
    isAllPermitted,
    isLoading
  }
}
