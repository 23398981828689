import { MouseEvent, useRef, useState } from 'react'
import { TextInput } from 'grommet'
import styled from 'styled-components/macro'

import { Box } from '../layout'
import { Button, IconButton } from '../button'
import { Icon, IconName, SpinnerIcon } from '../icon'
import { themeColor } from '../theme'

// TODO: Add loading state to input while item is being created
// TODO: capture state for if you tab from input then you can tab into the check and close buttons (accessibilty)
// TODO: Design needs to be updated - buttons positioning and actual buttons can be replaced but now that this is isolated to a single component should be easy.

export type ItemCreateInputProps = {
  addNewLabel?: string
  placeholder?: string
  onCreateItem: (value: string) => void
  onCancel?: () => void
  level?: number
  icon?: IconName
  loading?: boolean
  hasTrigger?: boolean
  initialValue?: string
  size?: 'small' | 'default'
}

export const ItemCreateInput = ({
  onCreateItem: onCreateItem,
  placeholder = 'Option label',
  addNewLabel = 'Create option',
  level = 1,
  icon,
  loading = false,
  hasTrigger = true,
  initialValue,
  onCancel,
  size = 'default'
}: ItemCreateInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [showInput, setShowInput] = useState(!hasTrigger)
  const [inputValue, setInputValue] = useState(initialValue ?? '')

  const hasInput = inputValue.trim().length > 0

  const handleKeyDownEvent = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.stopPropagation()
      performCreate()
    }
  }

  const handleBlurInput = () => {
    clearInputValue(true)
  }

  const performCreate = () => {
    if (hasInput) {
      onCreateItem?.(inputValue)
      clearInputValue()
    }
  }

  const clearInputValue = (closeInput?: boolean) => {
    setInputValue('')
    if (inputRef.current) {
      inputRef.current.value = ''
      inputRef.current.focus()
    }
    if (closeInput) {
      setShowInput(false)
      onCancel?.()
    }
  }

  const clickShowInputTrigger = (event: MouseEvent) => {
    event.preventDefault()
    setShowInput(true)
    clearInputValue()
  }

  return (
    <Box pad={{ left: level > 1 ? '26px' : undefined }} width="100%">
      {!showInput ? (
        <ItemCreateButton icon={icon ? icon : 'add'} onClick={clickShowInputTrigger} label={addNewLabel} />
      ) : (
        <ItemCreateInputField>
          <Icon icon={icon ? icon : 'add'} color="text" />
          <TextInput
            ref={inputRef}
            autoFocus
            onBlur={handleBlurInput}
            type="text"
            placeholder={placeholder}
            onKeyDown={(e: any) => handleKeyDownEvent(e)}
            value={inputValue}
            onChange={e => setInputValue(e.target.value)}
            plain
            data-testid="inline-input"
            css={`
              font-size: ${size === 'small' ? '13px' : undefined};
              padding: 7px 8px 7px 0;
              &:focus {
                outline: none;
              }
            `}
          />
          {showInput && hasInput ? (
            <>
              <IconButton
                label="Cancel"
                icon="close"
                onClick={() => clearInputValue(true)}
                size="small"
                disableTooltip
              />
              <IconButton
                primary
                label="Confirm"
                icon="check"
                onClick={performCreate}
                onMouseDown={event => {
                  // have to do this to avoid losing focus on the input field when clicking this button
                  event.preventDefault()
                }}
                size="small"
                data-testid="save-inline-input"
                disableTooltip
              />
            </>
          ) : (
            loading && <SpinnerIcon size="small" aria-hidden="true" />
          )}
        </ItemCreateInputField>
      )}
    </Box>
  )
}

const ItemCreateInputField = styled(Box).attrs({
  gap: '4px',
  direction: 'row',
  align: 'center',
  pad: { left: '4px' }
})``

const ItemCreateButton = styled(Button).attrs({
  tertiary: true,
  full: true
})`
  padding-left: 4px;
  color: ${themeColor('text-light')};
  justify-content: left;
  font-weight: 400;
  height: 32px;
  font-size: 15px;
  &:hover {
    background: none;
    color: ${themeColor('text')};
    svg {
      fill: ${themeColor('text')};
    }
  }
`
