// @ts-nocheck
import { inject, injectable, postConstruct } from 'inversify'
import { observable, action, computed } from 'mobx'
import { AccountsRepository } from 'app/Repositories/Account/AccountsRepository'
import { RoleTypesRepository } from 'app/Repositories/RoleType/RoleTypesRepository'
import { UsersRepository } from 'app/Repositories/User/UsersRepository'
import { FilterDisclosurePanelPresenter } from 'Components/Molecules'
import { RoutingState } from 'app/Routing/RoutingState'
import { sort, TableScrollerService } from 'Components/Organisms'
import { IGenericFilter } from 'app/Filters/FilterEntities'
import { FiltersRepository } from 'app/Filters/FiltersRepository'
import { Router } from 'app/Routing/Router'
import { container } from 'app/Config/IOC'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'
import { Types } from 'Gateways/Service/Types'

interface IFilterAccount {
  id: number
  name: string
}

interface IFilterRole {
  id: number
  name: string
}

@injectable()
export class UserPageFilterPanelPresenter {
  @inject(RoutingState)
  public routingState: RoutingState

  @inject(AccountsRepository)
  public accountsRepository: AccountsRepository

  @inject(RoleTypesRepository)
  public roleTypesRepository: RoleTypesRepository

  @inject(UsersRepository)
  public usersRepository: UsersRepository

  @inject(FiltersRepository)
  public filtersRepository: FiltersRepository

  public router: Router = container.get(Router)

  public language: ILanguageService = container.get(Types.ILanguageService)

  @observable
  public communicationsFiltersDisclosurePanel = new FilterDisclosurePanelPresenter(
    this.language.get('users:filter:communicationsGroup:label'),
    'communications'
  )

  @observable
  public communiciationFilters: IGenericFilter[] = [
    {
      key: { reference: 'accept_email' },
      type: 'boolean',
      label: this.language.get('users:filter:communicationsGroup:acceptEmail:label'),
      canBeInverse: true,
      inverseLabel: this.language.get('users:filter:communicationsGroup:acceptEmail:inverseLabel'),
      group: 'communications'
    },
    {
      type: 'boolean',
      key: { reference: 'accept_sms' },
      label: this.language.get('users:filter:communicationsGroup:acceptSMS:label'),
      canBeInverse: true,
      inverseLabel: this.language.get('users:filter:communicationsGroup:acceptSMS:inverseLabel'),
      group: 'communications'
    },
    {
      type: 'boolean',
      key: { reference: 'has_mobile' },
      label: this.language.get('users:filter:communicationsGroup:hasMobile:label'),
      canBeInverse: true,
      inverseLabel: this.language.get('users:filter:communicationsGroup:hasMobile:inverseLabel'),
      group: 'communications'
    }
  ]

  @observable
  public accessFiltersDisclosurePanel = new FilterDisclosurePanelPresenter(
    this.language.get('users:filter:accessGroup:label'),
    'access'
  )

  @observable
  public accessFilters: IGenericFilter[] = [
    {
      type: 'boolean',
      group: 'access',
      key: { reference: 'invitation_accepted' },
      label: this.language.get('users:filter:accessGroup:invitationAccepted:label'),
      inverseLabel: this.language.get('users:filter:accessGroup:invitationAccepted:inverseLabel'),
      canBeInverse: true
    },
    {
      type: 'boolean',
      group: 'access',
      key: { reference: 'invitation_sent' },
      label: this.language.get('users:filter:accessGroup:invitationSent:label'),
      inverseLabel: this.language.get('users:filter:accessGroup:invitationSent:inverseLabel'),
      canBeInverse: true
    },
    {
      type: 'boolean',
      group: 'access',
      key: { reference: 'locked' },
      label: this.language.get('users:filter:accessGroup:locked:label'),
      inverseLabel: this.language.get('users:filter:accessGroup:locked:inverseLabel'),
      canBeInverse: true
    },
    {
      type: 'boolean',
      group: 'access',
      key: { reference: 'no_roles' },
      label: this.language.get('users:filter:accessGroup:noRoles:label'),
      inverseLabel: this.language.get('users:filter:accessGroup:noRoles:inverseLabel'),
      canBeInverse: true
    }
  ]

  @observable
  public loadingFilters: boolean

  @postConstruct()
  public bindClearFunction() {
    this.router.registerRouteLeaveCallback({ routeId: 'users', callback: this.clear })
  }

  public clear = (reloadUserDataAfterClear = false, resetAccordions = true) => {
    this.filtersRepository.clearPathFilters()
    resetAccordions && this.accessFiltersDisclosurePanel.reset()
    resetAccordions && this.communicationsFiltersDisclosurePanel.reset()
    resetAccordions && this.accountFiltersDisclosurePanel.reset()
    resetAccordions && this.roleTypesFiltersDisclosurePanel.reset()

    // Reset filter to ensure display filter panel are destroyed when navigating to a new page
    this.accountFilters = []
    this.roleTypesFilters = []
    if (reloadUserDataAfterClear) {
      this.router.refreshFilteredRoute()
      container.get(TableScrollerService).doScroll()
    }
  }

  @action
  public load = async () => {
    this.loadingFilters = true
    await Promise.all([
      this.roleTypesRepository.safeLoad({ display: 'user' }),
      this.accountsRepository.safeLoadAccounts()
    ])
    this.buildAccountFilters()
    this.buildRoleTypesFilters()
    this.loadingFilters = false
  }

  @observable
  public accountFiltersDisclosurePanel = new FilterDisclosurePanelPresenter(
    this.language.get('users:filter:accountGroup:label'),
    'account'
  ).withCollapsed(false)

  @observable
  public accountFilters: IGenericFilter[] = []

  @action
  public buildAccountFilters = () => {
    const accounts = sort(this.accountsRepository.accounts, 'name', 'string', 'asc')
    const filters: IGenericFilter[] = accounts.map((account: IFilterAccount): IGenericFilter => {
      return {
        type: 'resource',
        group: 'account',
        key: { reference: 'account_id', resourceId: account.id.toString() },
        label: account.name,
        groupPills: true
      }
    })
    this.accountFilters = filters
  }

  @observable
  public roleTypesFiltersDisclosurePanel = new FilterDisclosurePanelPresenter(
    this.language.get('users:filter:roleTypeGroup:label'),
    'roleType'
  )

  @observable
  public roleTypesFilters: IGenericFilter[] = []

  @action
  public buildRoleTypesFilters = () => {
    const filters = this.roleTypesRepository.roleTypes.map((roleType: IFilterRole): IGenericFilter => {
      return {
        type: 'resource',
        group: 'roleType',
        label: roleType.name,
        key: { reference: 'role_type', resourceId: roleType.id.toString() },
        groupPills: true
      }
    })
    this.roleTypesFilters = filters
  }

  @computed
  public get genericFilters(): IGenericFilter[] {
    return [...this.accountFilters, ...this.roleTypesFilters, ...this.communiciationFilters, ...this.accessFilters]
  }

  @computed
  public get displayFilterPanels(): boolean {
    return this.accountFilters.length > 0 && this.roleTypesFilters.length > 0 ? true : false
  }
}
