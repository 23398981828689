import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Move = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Move" {...props}>
    <path d="M37 29h-2v3h2a8 8 0 0 0 8-8V11a8 8 0 0 0-8-8H24a8 8 0 0 0-8 8v2h3v-2a5 5 0 0 1 5-5h13a5 5 0 0 1 5 5v13a5 5 0 0 1-5 5ZM24 45a8 8 0 0 0 8-8v-5h-3v5a5 5 0 0 1-5 5H11a5 5 0 0 1-5-5V24a5 5 0 0 1 5-5h5v-3h-5a8 8 0 0 0-8 8v13a8 8 0 0 0 8 8h13Z" fill="#000" /><path d="M19 16v3h7.9L15.322 30.578l2.121 2.121L29 21.143V29h3V16H19Z" fill="#000" />
  </StyledIcon>
))

Move.displayName = 'Move'

export { Move }
