import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Underline = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Underline" {...props}>
    <path d="M30.816 24.15V7.546h3.082V24.4c0 5.22-3.903 9.048-9.893 9.048-5.991 0-9.894-3.828-9.894-9.048V7.545h3.083v16.606c0 3.728 2.56 6.413 6.81 6.413 4.251 0 6.812-2.685 6.812-6.413ZM36 37.5H12v3h24v-3Z" fill="#000" />
  </StyledIcon>
))

Underline.displayName = 'Underline'

export { Underline }
