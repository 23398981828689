import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const BookmarkFilled = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="BookmarkFilled" {...props}>
    <path d="M14 10a4 4 0 0 1 4-4h12a4 4 0 0 1 4 4v32l-10-7-10 7V10Z" fill="#000" />
  </StyledIcon>
))

BookmarkFilled.displayName = 'BookmarkFilled'

export { BookmarkFilled }
