import { useRef, useState } from 'react'
import { assignRef, useCallbackRef } from 'use-callback-ref'

/**
 * A mutable ref that can be used to assign a ref to an element that will change over time. It is useful for things
 * like overlays which need to position themselves based on the trigger element. This can work for popper driven
 * components and currently we are using it to integrate with ReactMenu.
 */
export const useMutableCallbackRef = () => {
  const ref = useRef<HTMLElement>(null)
  const [, forceUpdateCount] = useState(0)

  return useCallbackRef<HTMLElement>(ref as unknown as HTMLElement, el => {
    assignRef(ref, el)
    forceUpdateCount(count => count + 1)
  })
}
