// @ts-nocheck
import { injectable } from 'inversify'
import { container } from 'app/Config/IOC'
import { observable, computed } from 'mobx'
import { TaskTypesPageMoreOptionsPopoverPresenter } from 'app/Settings/TaskTypes/TaskTypesPage/TaskTypesPageHeading/TaskTypesPageMoreOptionsPopoverPresenter'
import { TaskTypesViewPresenter } from 'app/Settings/TaskTypes/TaskTypesPage/TaskTypesViewPresenter'
import { PillFilterPresenter } from 'Components/Molecules'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'
import { Types } from 'Gateways/Service/Types'

@injectable()
export class TaskTypesPageHeadingPresenter {
  private taskTypesViewPresenter: TaskTypesViewPresenter = container.get(TaskTypesViewPresenter)
  private language: ILanguageService = container.get(Types.ILanguageService)

  @observable
  public taskTypesPageMoreOptionsPopoverPresenter: TaskTypesPageMoreOptionsPopoverPresenter =
    new TaskTypesPageMoreOptionsPopoverPresenter()

  @computed
  public get taskTypesTotal(): number {
    return this.taskTypesViewPresenter.taskTypesTotal
  }

  @computed
  public get taskTypesFilteredTotal(): number {
    return this.taskTypesViewPresenter.taskTypesFilteredTotal
  }

  @observable
  public visibilityPillsPresenter: PillFilterPresenter = new PillFilterPresenter({
    group: this.language.get('taskTypes:filter:filterGroupVisibility:title'),
    type: 'group'
  }).withTitle(this.language.get('taskTypes:filter:filterGroupVisibility:title'))

  @computed
  public get settingsPillPresenters(): PillFilterPresenter[] {
    const pillPresenters: PillFilterPresenter[] = []
    Object.keys(this.taskTypesViewPresenter.settingsVM).forEach(key => {
      pillPresenters.push(
        new PillFilterPresenter({
          type: 'single',
          key: { reference: key }
        })
      )
    })

    return pillPresenters
  }

  @observable
  public defaultPillsPresenter: PillFilterPresenter = new PillFilterPresenter({
    type: 'single',
    key: { reference: 'default' }
  })

  @computed
  public get pillCount(): number {
    let settingsCount = 0

    this.settingsPillPresenters.forEach(presenter => {
      settingsCount = settingsCount + presenter.filterPills.length
    })

    return (
      this.visibilityPillsPresenter.filterPills.length + settingsCount + this.defaultPillsPresenter.filterPills.length
    )
  }

  @computed
  public get showClearFilterButton(): boolean {
    return this.pillCount > 1
  }

  @computed
  public get showArchivedTaskTypes(): boolean {
    return this.taskTypesViewPresenter.showArchivedTaskTypes
  }

  public clearFilters = () => {
    this.taskTypesViewPresenter.clearFilters()
  }
}
