import { useState } from 'react'
import { Heading } from 'grommet'
import styled from 'styled-components'

import { Box, Button } from '@cutover/react-ui'
import { CreateNewSamlConfigModal } from '../modals/create-new-saml-config-modal'
import { useLanguage } from 'main/services/hooks'
import { usePermissions } from 'main/services/queries/use-permissions'

export const SamlConfigurationsHeader = () => {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
  const { t } = useLanguage('samlConfigurations')
  const permissions = usePermissions('saml-configs')
  const userCanCreateSaml = permissions('create')

  const handleClickCreate = () => {
    setIsCreateModalOpen(true)
  }

  return (
    <Box direction="row" width="100%">
      <Box flex="grow" justify="center">
        <SamlTitle responsive={false} margin={{ left: 'xsmall', right: 'none', vertical: 'none' }} level="1">
          {t('samlConfigurations.header.title.label')}
        </SamlTitle>
      </Box>
      {userCanCreateSaml ? (
        <Box>
          <Button
            icon="add"
            primary
            label={t('samlConfigurations.header.addButton.label')}
            onClick={handleClickCreate}
          />
        </Box>
      ) : null}
      <CreateNewSamlConfigModal open={isCreateModalOpen} setOpen={setIsCreateModalOpen} />
    </Box>
  )
}

const SamlTitle = styled(Heading)`
  font-size: 20px;
  line-height: 1.2;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
`
