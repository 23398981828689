import { useMemo } from 'react'
import { keyBy } from 'lodash'

import {
  computeCompletionByStageData,
  TasksByStageData
} from '../widgets/runbook/completion-by-stage/use-completion-by-stage-data'
import { DashboardComponent } from 'main/components/dashboards/widgets/types'
import { TaskListTask } from 'main/services/queries/types'
import { applyFilterPipeline } from 'main/services/tasks/filtering/filter-map'
import { buildFilterContext } from 'main/services/tasks/filtering/filter-context'
import { FieldValuesByTaskAndCustomFieldIdLookup } from 'main/recoil/runbook'

type componentFilters = {
  filters?: {
    task_type_id?: number | number[]
  }
}

export const filterTaskData = (dashboardData: DashboardComponent): TaskListTask[] => {
  const fieldValuesLookup: FieldValuesByTaskAndCustomFieldIdLookup = {}
  dashboardData.tasks.forEach(task => {
    const taskId = task.id
    fieldValuesLookup[taskId] = {}

    task.field_values.forEach(fieldValue => {
      const { custom_field_id, value, field_option_id } = fieldValue
      const currentValue = fieldValuesLookup[taskId][custom_field_id] ?? []
      fieldValuesLookup[taskId][custom_field_id] = [...currentValue, { value, field_option_id }]
    })
  })

  const streamInternalIdLookup = dashboardData.streams?.length > 0 ? keyBy(dashboardData.streams, 'internal_id') : {}

  return applyFilterPipeline(
    dashboardData.tasks,
    dashboardData.task_filters,
    buildFilterContext(dashboardData.task_filters, {
      ...dashboardData,
      streamInternalIdLookup,
      field_values_lookup: fieldValuesLookup
    })
  )
}

export const useFilterTaskData = (dashboardData: DashboardComponent): TaskListTask[] => {
  return useMemo(() => filterTaskData(dashboardData), [dashboardData])
}

export const useSummaryStageComponentFilterData = (
  filteredTasks: TaskListTask[],
  filters: componentFilters['filters']
): { filteredTasks: TaskListTask[]; percentComplete: number; tasksByStage: TasksByStageData } => {
  let result = filteredTasks
  if (filters && filters.task_type_id) {
    if (Array.isArray(filters.task_type_id)) {
      // @ts-ignore
      result = result.filter(task => filters.task_type_id.includes(task.task_type_id))
    }
  }

  const { percentComplete, tasksByStage } = computeCompletionByStageData(result)
  return { filteredTasks: result, percentComplete, tasksByStage }
}
