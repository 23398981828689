// @ts-nocheck
import { TSelectOptionTypes } from 'Components/Molecules'
import styled from 'styled-components'
import { colors } from 'app/Styles/Variables'
import { Icon } from 'Components/Atoms'
import { IMultiSelectItemProps } from './IMultiSelectItemProps'

const SelectedWrapper = styled.div`
  padding-bottom: 10px;
  color: ${colors.primaryGrey};
`

interface IClickable {
  onClick?: (option: TSelectOptionTypes) => void
}

const IconAnchor = styled.a<IClickable>`
  &:hover {
    cursor: pointer;
  }
`

const IconLabel = styled.span`
  padding-left: 10px;
`

export const MultiSelectItem = (props: IMultiSelectItemProps) => (
  <SelectedWrapper key={props.option[props.valueProperty]}>
    <IconAnchor
      onClick={() => {
        props.selected ? props.deselectOption(props.option) : props.selectOption(props.option)
      }}
    >
      <Icon
        name={props.selected ? 'check_box' : 'icon_square'}
        color={props.selected ? colors.primary : colors.primaryGrey}
      />
      <IconLabel>{props.option[props.labelProperty]}</IconLabel>
    </IconAnchor>
  </SelectedWrapper>
)
