// @ts-nocheck
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { px2rem } from 'app/Styles/Variables'
import { AccountVisibilityPresenter } from './AccountVisibilityPresenter'
import { RadioButtons } from 'Components/Atoms'

const AccountRadioContainer = styled.div``

const VisibilityWrapper = styled.div`
  margin-bottom: ${px2rem(16)};
`
interface IAccountVisibilityProps {
  presenter: AccountVisibilityPresenter
}

export const AccountVisibility = observer<IAccountVisibilityProps>(({ presenter }) => {
  const { visibilityTypePresenter, accountVisibilityPresenter, showAccounts } = presenter

  return (
    <>
      <VisibilityWrapper>
        <RadioButtons presenter={visibilityTypePresenter} />
      </VisibilityWrapper>
      <AccountRadioContainer>
        {accountVisibilityPresenter && showAccounts && <RadioButtons presenter={accountVisibilityPresenter} />}
      </AccountRadioContainer>
    </>
  )
})
