// @ts-nocheck
import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { withPresenter } from 'app/Config/withPresenter'
import styled from 'styled-components'
import { px2rem, colors } from 'app/Styles/Variables'
import { DisplaySvgIcon, Heading, Icon, Modal, RadioButtons } from 'Components/Atoms'
import {
  AngularChooseRunbookStartTimePresenter,
  IRunbookPM,
  IDialogData
} from './AngularChooseRunbookStartTimePresenter'
import { Button, ButtonPresenter, CheckboxPresenter, ErrorAlert } from 'Shared/Components/Molecules'
import { getIncidentStartTimeOptions } from './incidentStartTimeHelper'
import { getStartTimeOptions } from './runbookStartTimeHelper'
import { LoadingPanel } from 'Shared/Components/Templates/LoadingPanel/LoadingPanel'

interface IContentWrapperProps {
  hasShiftTimeCheckbox: boolean
}

const Wrapper = styled.div`
  padding: ${px2rem(24)} ${px2rem(32)};
`

const HeadingWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${px2rem(8)};
`

const DisplaySvgIconStyled = styled(DisplaySvgIcon)`
  margin-right: ${px2rem(15)};
  flex-shrink: 0;
`
const BackButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${px2rem(7)};
  width: ${px2rem(40)};
  height: ${px2rem(40)};
  border-radius: 50%;
  color: ${colors.textLight};
  cursor: pointer;
  &:hover {
    background-color: ${colors.bg1};
  }
`

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const RadioButtonsStyled = styled(RadioButtons)`
  border-bottom: none;
`

const ContentWrapper = styled.div`
  min-height: ${({ hasShiftTimeCheckbox }: IContentWrapperProps) => (hasShiftTimeCheckbox ? px2rem(320) : px2rem(290))};
  padding-left: ${px2rem(16)};
  position: relative;
`

interface IChooseRunbookStartTimeProps {
  presenter: AngularChooseRunbookStartTimePresenter
  newRunbook: IRunbookPM
  runbookCustomFields: any
  dialogData: IDialogData
}

const AngularChooseRunbookStartTimeComponent = observer<IChooseRunbookStartTimeProps>(({ presenter }) => {
  const {
    language,
    dateTimePresenter,
    endTimePresenter,
    incidentDateTimePresenter,
    cancelButtonPresenter,
    radioButtonPresenter,
    openPreviousCreateRunbookModal,
    handleSubmit,
    errorAlertPresenter,
    startPlannedTime,
    handleTimeChange,
    handleDateChange,
    modalPresenter,
    loading,
    shiftTimePresenter
  } = presenter

  const [runbookType, setRunbookType] = useState(null)
  const [newRunbook, setNewRunbook] = useState(null)
  const [dialogData, setDialogData] = useState(null)
  const [runbookCustomFields, setRunbookCustomFields] = useState(null)
  const [customOptions, setCustomOptions] = useState({})

  const planningKey = language.get('runbooks:chooseRunbookStartTime:options:option1')
  const startDateKey = language.get('runbooks:chooseRunbookStartTime:options:option2')

  useEffect(() => {
    window.reactJS.openStartTimeRunbookModal = (data: any) => {
      const { runbook, customFields, dialogData, runbookType } = data

      errorAlertPresenter.reset()
      setNewRunbook(runbook)
      setRunbookCustomFields(customFields)
      setDialogData(dialogData)
      setRunbookType(runbookType)
      modalPresenter.onOpen()
    }

    return () => {
      delete window.reactJS.openStartTimeRunbookModal
    }
  }, [])

  useEffect(() => {
    const customOptions = runbookType?.isIncident
      ? getIncidentStartTimeOptions(
          {
            plannedOptionHeader: planningKey,
            plannedOptionHeaderAlt: language.get('runbooks:chooseRunbookStartTime:options:option1Incident'),
            plannedOptionSubHeader: language.get('runbooks:chooseRunbookStartTime:customOptions:option1Incident'),
            startDateOptionHeader: startDateKey,
            startDateOptionHeaderAlt: language.get('runbooks:chooseRunbookStartTime:options:option2Incident'),
            startDateOptionSubHeader: language.get('runbooks:chooseRunbookStartTime:customOptions:option2Incident'),
            shiftTimeOption: language.get('runbooks:chooseRunbookStartTime:shiftTimeOption:label')
          },
          incidentDateTimePresenter,
          newRunbook?.is_template
        )
      : getStartTimeOptions(
          {
            plannedOptionHeader: planningKey,
            plannedOptionSubHeader: language.get('runbooks:chooseRunbookStartTime:customOptions:option1'),
            startDateOptionHeader: startDateKey,
            startDateOptionSubHeader: language.get('runbooks:chooseRunbookStartTime:customOptions:option2'),
            endDateOptionSubHeader: language.get('runbooks:chooseRunbookStartTime:customOptions:option3'),
            shiftTimeOption: language.get('runbooks:chooseRunbookStartTime:shiftTimeOption:label'),
            defaultDateTime: language.get('runbooks:chooseRunbookStartTime:defaultStartTime')
          },
          hasShiftTimeCheckbox,
          dateTimePresenter,
          endTimePresenter,
          startPlannedTime,
          handleDateChange,
          handleTimeChange,
          shiftTimePresenter
        )

    setCustomOptions(customOptions)
  }, [newRunbook, runbookType, startPlannedTime])

  useEffect(() => {
    if (newRunbook?.is_template && runbookType?.isIncident) {
      radioButtonPresenter.withOptions([startDateKey])
      radioButtonPresenter.withSelectedOption(startDateKey)
    } else {
      radioButtonPresenter.withOptions([planningKey, startDateKey])
      radioButtonPresenter.withSelectedOption(planningKey)
    }
  }, [runbookType])

  const createBlankRunbookButtonPresenter = new ButtonPresenter(
    language.get('runbooks:chooseRunbookStartTime:createRunbookButton'),
    () => handleSubmit(newRunbook, runbookCustomFields, dialogData, shiftTimePresenter.value, runbookType)
  )
    .withIconName('add')
    .withDisplayStyle('primary')
    .withTextTransform('Uppercase')
    .isDisabled(loading)

  // Disable the button when submission is in progress
  useEffect(() => {
    createBlankRunbookButtonPresenter.isDisabled(loading)
  }, [loading])

  //dialogData is only passed in when creating runbook from a template
  const hasShiftTimeCheckbox = !!dialogData

  return (
    <Modal topOffset={350} customWidth={572} closeButtonHasDeepPosition presenter={modalPresenter}>
      <Wrapper>
        <HeadingWrapper>
          <BackButton>
            <Icon
              name={'arrow-back'}
              size={`${px2rem(24)}`}
              onClick={() =>
                openPreviousCreateRunbookModal(newRunbook, runbookCustomFields, dialogData, runbookType?.id)
              }
            />
          </BackButton>
          <DisplaySvgIconStyled name={runbookType?.iconName} color={runbookType?.iconColor} />
          <Heading theme="dark">{language.get('runbooks:chooseRunbookStartTime:heading')}</Heading>
        </HeadingWrapper>
        <ContentWrapper hasShiftTimeCheckbox={hasShiftTimeCheckbox}>
          {loading ? (
            <LoadingPanel />
          ) : (
            <>
              <ErrorAlert presenter={errorAlertPresenter} />
              <RadioButtonsStyled customOption={customOptions} presenter={radioButtonPresenter} />
            </>
          )}
        </ContentWrapper>
        <ButtonsWrapper>
          <Button presenter={cancelButtonPresenter} />
          <Button dataTestId="create-button-runbook-flow" presenter={createBlankRunbookButtonPresenter} />
        </ButtonsWrapper>
      </Wrapper>
    </Modal>
  )
})

export const AngularChooseRunbookStartTime = withPresenter(AngularChooseRunbookStartTimePresenter)(
  AngularChooseRunbookStartTimeComponent
)
