import { KeyboardEvent } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { FieldError, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'

import { Box, Button, Form, Message, px2rem, TextInput } from '@cutover/react-ui'
import { KeyCodeLookup } from 'app/Shared/Constants/Keycodes'
import { useLanguage } from 'main/services/hooks'
import { SnippetCreateResponseType, useSnippetCreate } from 'main/services/queries/use-snippet'

type EnterSnippetDetailsProps = {
  closeModal: () => void
  accountId: string | number
}

type EnterSnippetDetailsForm = {
  name: string
}

export const EnterSnippetDetails = ({ closeModal, accountId }: EnterSnippetDetailsProps) => {
  const { t } = useLanguage()
  const navigate = useNavigate()
  const mutation = useSnippetCreate({ accountId })

  const methods = useForm<EnterSnippetDetailsForm>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(
      yup.object().shape({
        name: yup.string().required(t('runbooks:enterSnippetDetails:fields:name:errors:required'))
      })
    )
  })

  const onFormSubmit = async ({ name }: EnterSnippetDetailsForm) => {
    await mutation.mutateAsync(
      {
        name
      },
      {
        onSuccess: (data: SnippetCreateResponseType) => {
          const id = data.id
          const currentPath = window.location.href
          const base = currentPath.split('app')[1].split('runbooks')[0]

          if (base && id) {
            // Redirect to task list
            closeModal()
            const path = `/app${base}runbooks/${id}/current_version/tasks/list`
            navigate(path)
          }
        }
      }
    )
  }

  const formErrors = methods.formState?.errors
  const formErrorMessages = Object.values(formErrors)
    ?.map(error => (error as FieldError).message)
    .filter(Boolean) as string[]

  const errors = formErrorMessages

  return (
    <Form onSubmit={methods.handleSubmit(onFormSubmit)} aria-label="form">
      <Box>
        <Message type="error" message={errors} />
        <Box
          css={`
            margin-top: ${errors.length === 0 ? 0 : px2rem(24)};
          `}
        >
          <TextInput
            {...methods.register('name')}
            label={t('runbooks:enterSnippetDetails:fields:name:label')}
            required
            hasError={!!formErrors?.name}
            autoFocus
            onKeyDown={(event: KeyboardEvent<HTMLInputElement>) => {
              if (event.keyCode === KeyCodeLookup.enter) {
                methods.handleSubmit(onFormSubmit)()
              }
            }}
          />
        </Box>
        <Box
          justify="end"
          align="center"
          gap="xsmall"
          direction="row"
          margin={{ top: 'small' }}
          pad={{ bottom: 'xsmall' }}
        >
          <Button
            label={t('common:cancelButton')}
            icon="dnd-forwardslash"
            tertiary
            onClick={closeModal}
            disabled={mutation.isLoading}
          />
          <Button
            type="submit"
            label={t('common:createButton')}
            loadingLabel={t('common:creatingText')}
            icon="add"
            primary
            disabled={mutation?.isLoading}
            loading={mutation?.isLoading}
          />
        </Box>
      </Box>
    </Form>
  )
}
