// @ts-nocheck
import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { withPresenter } from 'app/Config/withPresenter'
import { CustomFieldAddPresenter } from './CustomFieldAddPresenter'
import { CustomFieldFormPresenter } from 'app/Settings/CustomFields/CustomFieldsPage/CustomFieldForm/CustomFieldFormPresenter'
import { CustomFieldFormSection } from 'app/Settings/CustomFields/CustomFieldsPage/CustomFieldForm/CustomFieldFormSection'

const Wrapper = styled.div`
  overflow-y: auto;
`

interface ICustomFieldAddComponentProps {
  postSuccessCallback: () => void
  presenter: CustomFieldAddPresenter
  addFormPresenter: CustomFieldFormPresenter
}

const CustomFieldAddSection = observer<ICustomFieldAddComponentProps>(
  ({ presenter, postSuccessCallback, addFormPresenter }) => {
    const { formSubmitted, save } = presenter

    presenter.withFormPresenter(addFormPresenter)
    presenter.withPostSuccessCall(postSuccessCallback)

    useEffect(() => {
      if (formSubmitted) {
        save()
      }
    }, [formSubmitted])

    return (
      <Wrapper>
        <CustomFieldFormSection presenter={addFormPresenter} formType="add" />
      </Wrapper>
    )
  }
)

export default withPresenter(CustomFieldAddPresenter)(CustomFieldAddSection)
