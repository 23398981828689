import { useState } from 'react'

import { CreateSnippetModal } from 'main/components/workspace/modals/create-snippet/create-snippet-modal'
import { ReactAngularConnector } from 'main/connectors/react-angular-connector'

export const CreateSnippetConnector = () => {
  const [accountId, setAccountId] = useState<string | null>(null)

  const handleSetState = ({ accountId }: { accountId: string }) => {
    setAccountId(accountId)
  }

  return (
    <ReactAngularConnector
      mountEvent="show-create-snippet-modals"
      unmountEvent="hide-create-snippet-modals"
      onMount={handleSetState}
    >
      {accountId && <CreateSnippetModal accountId={accountId} />}
    </ReactAngularConnector>
  )
}
