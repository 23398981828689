export const KeyCodeLookup = {
  tab: 9,
  enter: 13,
  escape: 27,
  space: 32,
  left: 37,
  up: 38,
  right: 39,
  down: 40,
  leftWindowKey: 91
}
