// @ts-nocheck
import { observable } from 'mobx'

export class PillPresenter {
  @observable
  public filterId?: string

  @observable
  public title: string

  @observable
  public content: string

  public withFilterId? = filterId => {
    this.filterId = filterId
    return this
  }

  public withTitle? = title => {
    this.title = title
    return this
  }

  public withContent? = content => {
    this.content = content
    return this
  }
}
