import { useContext, useEffect, useRef } from 'react'

import { CutoverConnectChannelContext } from 'main/services/api/websocket-providers'
import { CutoverConnectChannelResponse } from 'main/services/api/data-providers/cutover-connect/cutover-connect-channel-response-types'

type WebsocketHandler = (data: CutoverConnectChannelResponse | undefined) => void

export const useCutoverConnectWebsocket = () => {
  const cutoverConnectChannelResponse = useContext(CutoverConnectChannelContext)
  const handlerRef = useRef<WebsocketHandler | null>(null)

  useEffect(() => {
    handlerRef.current?.(cutoverConnectChannelResponse)
  }, [cutoverConnectChannelResponse])

  const listen = (handler: WebsocketHandler) => {
    handlerRef.current = handler
  }

  return { listen }
}
