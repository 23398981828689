// @ts-nocheck

import styled, { keyframes, css } from 'styled-components'
import { transition } from 'app/Styles/Variables'
import { TIcons } from './TIcons'
import { observer } from 'mobx-react-lite'

interface IStyledTagProps {
  color: string
  size: string
  spinning: boolean
  rotation: number
  rotationSpeed: 'xfast' | 'fast' | 'medium' | 'slow'
  onClick?: MouseEventHandler<HTMLElement>
}

const spin = keyframes`
  0% {
    transform:rotate(0deg);
  }
  100% {
    transform:rotate(360deg);
  }
`

//TODO: Verify duplicate color attribute declarations, the first one is discarded, why is it here? Also, how is 'currentColor' evaluated?
const StyledTag = styled.i`
  color: ${(props: IStyledTagProps) => props.color};
  color: ${(props: IStyledTagProps) => (props.color ? props.color : 'currentColor')};
  font-size: ${(props: IStyledTagProps) => props.size};
  animation: ${(props: IStyledTagProps) =>
    props.spinning &&
    css`
      ${spin} 1.2s linear infinite
    `};
  vertical-align: baseline;
  display: inline-block;
  transform: rotate(${(props: IStyledTagProps) => props.rotation}deg);
  ${(props: IStyledTagProps) => {
    let speed = props.rotationSpeed || 'medium'
    return transition(speed, 'transform')
  }};
  line-height: 1;
`

interface IIconProps {
  name: TIcons
  size?: string
  color?: string
  rotation?: number
  extraStyles?: any
  rotationSpeed?: 'xfast' | 'fast' | 'medium' | 'slow'
  onClick?: MouseEventHandler<HTMLElement>
  className?: string
}

/** @deprecated do not use this component from the app folder, refactor and/or use components from react-ui instead **/
export const Icon = observer<IIconProps>(props => {
  const { name, size = '18px', color, rotation = 0, extraStyles, onClick, rotationSpeed, className } = props
  const spinning = name === 'spinner'
  let classNames = `icon-base icon-${name}`
  if (className) {
    classNames += ` ${className}`
  }

  return (
    <StyledTag
      className={classNames}
      color={color}
      size={size}
      rotation={rotation}
      spinning={spinning}
      style={extraStyles}
      onClick={onClick}
      rotationSpeed={rotationSpeed}
    />
  )
})
