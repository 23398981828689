import { useMutation } from 'react-query'

import { toCamelCase, toSnakeCase } from '@cutover/api'
import { apiClient } from 'main/services/api/api-client'
import { ApiError } from 'main/services/api/http-gateway-adapter'

type SnippetCreateProps = {
  accountId: string | number
}

type SnippetCreateRequestType = {
  name: string
  account_id: string | number
}

export type SnippetCreateResponseType = {
  id: number
}

export function useSnippetCreate({ accountId }: SnippetCreateProps) {
  return useMutation<SnippetCreateResponseType, ApiError, { name: string }>(['snippet-create'], async ({ name }) => {
    const payload = {
      name,
      accountId,
      templateType: 'snippet',
      templateStatus: 'template_draft'
    }

    const { data } = toCamelCase(
      await apiClient.post<{ runbook: SnippetCreateRequestType }, { runbook: SnippetCreateResponseType }>({
        url: 'runbooks',
        data: { runbook: toSnakeCase(payload) }
      })
    )

    return data?.runbook as SnippetCreateResponseType
  })
}
