import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Dependencies = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Dependencies" {...props}>
    <path d="M37.5 14.81a6 6 0 1 0-3 0v1.918a2.5 2.5 0 0 1-1.622 2.34l-3.965 1.487A5.993 5.993 0 0 0 24 18a5.993 5.993 0 0 0-4.913 2.555l-3.965-1.486a2.5 2.5 0 0 1-1.622-2.341v-1.917a6 6 0 1 0-3 0v1.917a5.5 5.5 0 0 0 3.569 5.15l3.964 1.486a6.063 6.063 0 0 0 0 1.271l-3.964 1.487a5.5 5.5 0 0 0-3.569 5.15v1.917a6 6 0 1 0 3 0v-1.917a2.5 2.5 0 0 1 1.622-2.34l3.965-1.487A5.993 5.993 0 0 0 24 30a5.993 5.993 0 0 0 4.913-2.555l3.965 1.486a2.5 2.5 0 0 1 1.622 2.341v1.917a6 6 0 1 0 3 0v-1.917a5.5 5.5 0 0 0-3.569-5.15l-3.964-1.486a6.065 6.065 0 0 0 0-1.271l3.964-1.487a5.5 5.5 0 0 0 3.57-5.15v-1.917ZM39 9a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm0 30a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM24 27a3 3 0 1 1 0-6 3 3 0 0 1 0 6Zm-9 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm-3-27a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z" fill="#000" />
  </StyledIcon>
))

Dependencies.displayName = 'Dependencies'

export { Dependencies }
