import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const TaskTypeSquare = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="TaskTypeSquare" {...props}>
    <path d="M38 14v20a4 4 0 0 1-4 4H14a4 4 0 0 1-4-4V14a4 4 0 0 1 4-4h20a4 4 0 0 1 4 4Zm2 0a6 6 0 0 0-6-6H14a6 6 0 0 0-6 6v20a6 6 0 0 0 6 6h20a6 6 0 0 0 6-6V14Z" fill="#000" />
  </StyledIcon>
))

TaskTypeSquare.displayName = 'TaskTypeSquare'

export { TaskTypeSquare }
