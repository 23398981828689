import { Avatar, BarTable, Box, colors } from '@cutover/react-ui'
import { DashboardMediaType } from '../../types'
import { generateBarValues } from '../bar-table-widget-utils'
import { MrdDashboardWidget } from '../mrd-dashboard-widget'

export type AttributeCompletionWidgetProps = {
  media: DashboardMediaType
  data: AttributeCompletionWidgetData
}

export type AttributeCompletionWidgetData = {
  id: number
  name: string
  errors?: string[]
  filters?: {
    resource_type: string
    resource_id: string | null
    value: string
  } | null
  notes?: any
  type: string
  complete: number
  startable: number
  in_progress: number
  not_live: number
  default: number
  values: AttributeCompletionValues[]
  runbook_total: number
  total: number
}

type AttributeCompletionValues = {
  id: number
  name: string
  color: string
  complete: number
  in_progress: number
  startable: number
  not_live: number
  default: number
  runbook_total: number
  total: number
}

export const AttributeCompletionWidget = ({ media, data }: AttributeCompletionWidgetProps) => {
  const { name, errors, notes, values, type } = data
  const attributeType = type === 'stream_completion' ? 'runbook' : 'task'
  const groupBy = name.includes('Team') ? 'Team' : 'Other' //not optimal way to differentiate..
  return (
    <MrdDashboardWidget title={name} errors={errors} notes={notes} fullWidth>
      <BarTable
        mainLabelAsComponent
        data={{ rowData: convertValuesToRowDataFormat(values, attributeType, groupBy) }}
        media={media}
      />
    </MrdDashboardWidget>
  )
}

// convert values into row data
const convertValuesToRowDataFormat = (values: AttributeCompletionValues[], attributeType: string, groupBy: string) => {
  const rowData = values.map(value => {
    const { name, complete, in_progress, startable, not_live, runbook_total, total, color, id } = value
    const runbookCountLabel = attributeType === 'task' ? ' in ' + runbook_total + ' runbooks' : ''
    const defaultBarColors = [colors.primary, colors.success, colors.successAlt, colors.textDisabled, colors.bg4]
    return {
      id,
      mainLabel: (
        <Box direction="row" align="center">
          {groupBy === 'Team' && (
            <Box margin={{ right: '4px' }}>
              <Avatar subject={{ name, color }} size="small" />
            </Box>
          )}
          {` ${name}`}
        </Box>
      ),
      rowInfo: `${total} ${attributeType}${total > 1 ? 's' : ''} ${runbookCountLabel}`, // TODO: implement language hook
      values: generateBarValues({
        bars: [
          { count: complete, tooltip: 'complete' },
          { count: in_progress, tooltip: 'in progress' },
          { count: startable, tooltip: 'startable' },
          { count: value.default, tooltip: 'not yet startable' },
          { count: not_live, tooltip: 'in planning or rehearsal' }
        ],
        total,
        context: attributeType,
        barColors: defaultBarColors,
        displayPercentBarIndex: 0
      })
    }
  })
  return rowData
}
