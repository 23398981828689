import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const CaretDown = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="CaretDown" {...props}>
    <path d="M26.267 29.511a2.453 2.453 0 0 1-3.534 0l-8.043-8.358C13.19 19.595 14.295 17 16.458 17h16.084c2.163 0 3.267 2.595 1.768 4.153l-8.043 8.358Z" fill="#000" />
  </StyledIcon>
))

CaretDown.displayName = 'CaretDown'

export { CaretDown }
