import { useMemo } from 'react'
import { getUnixTime } from 'date-fns'

import { TaskListTask } from 'main/services/queries/types'

export type TasksByStageData = {
  skipped: TaskListTask[]
  abandoned: TaskListTask[]
  completed: TaskListTask[]
  inProgress: TaskListTask[]
  startable: TaskListTask[]
  notStartable: TaskListTask[]
  late: {
    skipped: TaskListTask[]
    abandoned: TaskListTask[]
    complete: TaskListTask[]
    inProgress: TaskListTask[]
    startable: TaskListTask[]
    notStartable: TaskListTask[]
  }
}

const reduceTasks = (counts: TasksByStageData, task: TaskListTask) => {
  const values = counts
  const now = getUnixTime(Date.now())
  const {
    start_latest_planned: startLatestPlanned,
    duration,
    end_fixed: endFixed,
    completion_type: completionType,
    stage
  } = task

  const endPlanned = startLatestPlanned ? (startLatestPlanned as number) + duration : 0
  const latestPlannedEnd = endFixed && (endFixed as unknown as number) > endPlanned ? endFixed : endPlanned

  switch (stage) {
    case 'complete':
      switch (completionType) {
        case 'complete_abandoned':
          values.abandoned.push(task)
          break
        case 'complete_skipped':
          values.skipped.push(task)
          break
        default:
          values.completed.push(task)
          break
      }
      break
    case 'in-progress':
      if (now > latestPlannedEnd) values.late.inProgress.push(task)
      values.inProgress.push(task)
      break
    case 'startable':
      if (now + duration > latestPlannedEnd) values.late.startable.push(task)
      values.startable.push(task)
      break
    case 'default':
      if (startLatestPlanned && now > startLatestPlanned) values.late.notStartable.push(task)
      values.notStartable.push(task)
      break
  }

  return values
}

export const computeCompletionByStageData = (
  data: TaskListTask[]
): { tasksByStage: TasksByStageData; percentComplete: number } => {
  const tasks = data.reduce(reduceTasks, {
    skipped: [],
    abandoned: [],
    completed: [],
    inProgress: [],
    startable: [],
    notStartable: [],
    late: {
      skipped: [],
      abandoned: [],
      complete: [],
      inProgress: [],
      startable: [],
      notStartable: []
    }
  })

  const divisor = data.length || 1 // doing 0 / 0 if the data array is empty will cause a NaN
  const completion = Math.floor(
    ((tasks.completed.length + tasks.skipped.length + tasks.abandoned.length) / divisor) * 100
  )

  return {
    tasksByStage: tasks,
    percentComplete: completion
  }
}

export const useCompletionByStageData = (
  data: TaskListTask[]
): { tasksByStage: TasksByStageData; percentComplete: number } => {
  return useMemo(() => computeCompletionByStageData(data), [data])
}
