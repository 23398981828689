import { ReactNode } from 'react'
import { useMount } from 'react-use'
import { v4 as uuidv4 } from 'uuid'

import { Box, ListItem, MenuListItemProps } from '@cutover/react-ui'
import { usePostAppEvents } from '../apps-api'
import { AppsComponentParser } from '../apps-component-parser'
import { AppComponentNodeProps, ContentNode, ContentNodeOption } from '../apps-types'
import { useComponentPropsAndState } from '../apps-state'
import { useAppsListItemOpenWithContextState } from 'main/recoil/data-access'

type ListItemNodeProps = AppComponentNodeProps & {
  children: ReactNode
  title?: string
  subTitle?: string
  size: 'small' | 'medium' | 'large'
  leftContent?: ContentNode[]
  rightContent?: ContentNode[]
  menuOptions?: ContentNodeOption[]
  expandable?: boolean
  defaultOpen?: boolean
  error?: boolean
}

export const ListItemNode = ({ appId, resourceId, id, ...props }: ListItemNodeProps) => {
  const { componentProps, state } = useComponentPropsAndState(appId, resourceId, id, props)
  const {
    children,
    title,
    subTitle,
    size = 'medium',
    rightContent,
    leftContent,
    menuOptions,
    expandable = false,
    defaultOpen = false,
    error = false
  } = componentProps as ListItemNodeProps

  const context = `${resourceId}-${appId}`
  const postAppEvents = usePostAppEvents()
  const [listItemOpenState, setListItemOpenState] = useAppsListItemOpenWithContextState({ context, id: id || '' })

  const endComponents: ReactNode[] | undefined =
    rightContent &&
    rightContent.map(node => <AppsComponentParser content={[node]} appId={appId} resourceId={resourceId} />)

  const startComponents: ReactNode[] | undefined =
    leftContent &&
    leftContent.map(node => {
      return (
        <AppsComponentParser content={[node]} appId={appId} resourceId={resourceId} key={`${node.type}-${uuidv4()}`} />
      )
    })

  // Note: this is a hack to deal with the 1 place multiple elements are passed as 'startComponent'
  const hasMultipleLeftComponents = leftContent && leftContent[0].content && leftContent[0].content.length > 1

  const openInNewTab = (href: string) => {
    window.open(href, '_blank', 'noreferrer')
  }

  const handleMenuOptionClick = (option: MenuListItemProps) => {
    const selectedOption = menuOptions?.find(opt => opt.label === option.value)
    if (selectedOption) {
      if (selectedOption.href) {
        openInNewTab(selectedOption.href)
      }
      const payload = {
        app_id: appId,
        runbook_id: resourceId,
        events: [selectedOption],
        state
      }
      postAppEvents(payload)
    }
  }

  const clickableMenuOptions = menuOptions?.map(option => {
    return {
      ...option,
      onClick: handleMenuOptionClick
    }
  })

  const toggleListItemOpenState = () => {
    const open = listItemOpenState ?? false
    setListItemOpenState(!open)
  }

  useMount(() => {
    if (listItemOpenState === undefined) {
      setListItemOpenState(defaultOpen)
    }
  })

  return (
    <ListItem
      hasError={error}
      role="listitem"
      title={title}
      a11yTitle={title}
      open={listItemOpenState}
      onClick={expandable || !!children ? toggleListItemOpenState : undefined}
      subTitle={subTitle}
      menuOptions={clickableMenuOptions as unknown as MenuListItemProps[]}
      size={size}
      expandable={children ? true : expandable}
      expandableContent={children}
      prominence="high"
      startComponent={
        startComponents ? (
          <Box direction="row" gap="xsmall">
            {startComponents}
          </Box>
        ) : undefined
      }
      endComponents={endComponents}
      css={`
        // Hacks to fix incorrect structure being passed for sensitive incident modal
        .list-item-left-component {
          width: ${hasMultipleLeftComponents ? 'unset !important' : undefined};
          div {
            column-gap: 4px !important;
          }
          .text-content-wrap {
            display: none;
          }
        }
      `}
    />
  )
}
