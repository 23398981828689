import { memo } from 'react'

import { TaskEditForm } from './task-edit-form'
import { useRightPanelTypeState } from 'main/components/layout/right-panel'
import { useTaskEditInitialDataState } from 'main/recoil/data-access'

export const TaskEditPanel = memo(() => {
  const [{ taskId }, { closeRightPanel }] = useRightPanelTypeState('task-edit')
  const taskEditInitialData = useTaskEditInitialDataState(taskId ?? null)

  return (
    <>
      {taskEditInitialData && (
        <TaskEditForm key={taskEditInitialData.id} data={taskEditInitialData} onClose={closeRightPanel} />
      )}
    </>
  )
})
