// @ts-nocheck
import { injectable } from 'inversify'
import { container } from 'app/Config/IOC'
import 'reflect-metadata'
import { computed } from 'mobx'
import axios, { AxiosRequestConfig } from 'axios'
import { AuthenticationRepository } from 'app/Authentication/AuthenticationRepository'
import { IBaseResponse } from './IBaseResponse'
import { handleErrorResponse, setReactDirty } from 'app/Shared/Helpers/SPASharedStateService'
import { IServiceGateway } from './IServiceGateway'
import { formatDate } from 'Shared/Helpers/DateService'
import { Router } from 'app/Routing/Router'

@injectable()
export class HttpServiceGateway implements IServiceGateway {
  private get urlRoot(): string {
    const portString = process.env.API_PORT ? `:${process.env.API_PORT}` : ''
    const { protocol, hostname } = window.location
    return `${protocol}//${hostname}${portString}/api/`
  }

  @computed
  public get headers(): { [key: string]: string | number } {
    let headers = {
      'Content-Type': 'application/json;charset=UTF-8',
      'Access-Control-Allow-Origin': '*',
      Accept: 'application/json',
      'Cache-Control': 'no-store, max-age=0',
      clientTime: new Date().getTime(),
      Account: '',
      'Request-Origin': 'react-legacy'
    }
    return headers
  }

  public async get(
    path: string,
    params?: { [key: string]: number | string | boolean },
    extraHeaders: { [key: string]: string } = {},
    extraConfig: AxiosRequestConfig = {}
  ): Promise<IBaseResponse> {
    try {
      const { authenticationRepository, headers } = await this.applyHeadersAndGetAuthRepo(extraHeaders)
      const response = await axios.get(this.urlRoot + path, { params, headers, ...extraConfig })
      await authenticationRepository.setAuth(response)

      return {
        success: true,
        body: response.data
      }
    } catch (error) {
      return this.handleResponseException(error)
    }
  }

  public async delete(
    path: string,
    params?: { [key: string]: number | string | boolean },
    extraHeaders: { [key: string]: string } = {}
  ): Promise<IBaseResponse> {
    try {
      const { authenticationRepository, headers } = await this.applyHeadersAndGetAuthRepo(extraHeaders)
      const response = await axios.delete(this.urlRoot + path, { params, headers })
      await authenticationRepository.setAuth(response)

      return {
        success: true,
        body: response.data
      }
    } catch (error) {
      return this.handleResponseException(error)
    }
  }

  public async post(
    path: string,
    request?: object,
    extraHeaders: { [key: string]: string } = {}
  ): Promise<IBaseResponse> {
    try {
      const { authenticationRepository, headers } = await this.applyHeadersAndGetAuthRepo(extraHeaders)
      const response = await axios.post(this.urlRoot + path, request, { headers })
      await authenticationRepository.setAuth(response)

      return {
        success: true,
        body: response.data
      }
    } catch (error) {
      return this.handleResponseException(error)
    }
  }

  public async put(
    path: string,
    request?: object,
    extraHeaders: { [key: string]: string } = {}
  ): Promise<IBaseResponse> {
    try {
      const { authenticationRepository, headers } = await this.applyHeadersAndGetAuthRepo(extraHeaders)
      const response = await axios.put(this.urlRoot + path, request, { headers })
      await authenticationRepository.setAuth(response)

      return {
        success: true,
        body: response.data
      }
    } catch (error) {
      return this.handleResponseException(error)
    }
  }

  public async patch(
    path: string,
    request?: object,
    extraHeaders: { [key: string]: string } = {}
  ): Promise<IBaseResponse> {
    try {
      const { authenticationRepository, headers } = await this.applyHeadersAndGetAuthRepo(extraHeaders)
      const response = await axios.patch(this.urlRoot + path, request, { headers })
      await authenticationRepository.setAuth(response)

      return {
        success: true,
        body: response.data
      }
    } catch (error) {
      return this.handleResponseException(error)
    }
  }

  private async applyHeadersAndGetAuthRepo(extraHeaders: { [p: string]: string }) {
    const authenticationRepository = container.get(AuthenticationRepository)
    const authHeadersFromGateway: any = await authenticationRepository.loadAuth()
    const headers = { ...this.headers, ...authHeadersFromGateway, ...extraHeaders }
    return { authenticationRepository, headers }
  }

  private async handleResponseException(error) {
    const response = error.response
    const status = response.status || null

    const baseResponse = {
      status,
      success: false,
      body: response.data,
      errorMessages: null,
      successMessages: null
    }

    if (status === 401 || status === 403) {
      container.get(Router).changePageWithoutCheck('loadingSpinner')
      setReactDirty(false)
    }

    handleErrorResponse(response)

    await container.get(AuthenticationRepository).setAuth(response)

    if (response.data.hasOwnProperty('errors')) {
      if (Array.isArray(response.data.errors)) {
        baseResponse.errorMessages = response.data.errors
      } else if (typeof response.data.errors === 'string') {
        baseResponse.errorMessages = [response.data.errors]
      }
    }

    if (response.data.hasOwnProperty('success') && Array.isArray(response.data.success)) {
      baseResponse.successMessages = response.data.success
    }

    return baseResponse
  }
}
