import { useEffect, useState } from 'react'
import { eventManager } from 'event-manager'

export type SidePanelOpenState = 'opened' | 'closed'

export type UseAngularRightSidePanelProps = {
  onClosed?: () => void
  onOpened?: () => void
  trackState?: boolean
}

export function useAngularRightSidePanel({
  onClosed,
  onOpened,
  trackState = false
}: UseAngularRightSidePanelProps = {}) {
  const [sidePanelMountElement, setSidePanelMountElement] = useState<HTMLElement | undefined>(undefined)
  const [sidePanelOpenState, setSidePanelOpenState] = useState<SidePanelOpenState>('closed')

  useEffect(() => {
    const handleAngularPanelOpened = ({ elementForMount }: { elementForMount?: HTMLElement }) => {
      setSidePanelMountElement(elementForMount)
      setSidePanelOpenState('opened')
      onOpened?.()
    }
    const handleAngularPanelClosed = () => {
      setSidePanelMountElement(undefined)
      setSidePanelOpenState('closed')
      onClosed?.()
    }

    if (trackState || onOpened) {
      eventManager.on('angular-right-panel-opened', handleAngularPanelOpened)
    }

    if (trackState || onClosed) {
      eventManager.on('angular-right-panel-closed', handleAngularPanelClosed)
    }

    return () => {
      eventManager.off('angular-right-panel-closed', handleAngularPanelClosed)
      eventManager.off('angular-right-panel-opened', handleAngularPanelOpened)
    }
  }, [])

  const closeAngularSidePanel = () => {
    eventManager.emit('close-angular-right-panel')
  }

  const openAngularSidePanel = ({
    content,
    contentId
  }: {
    content?: string
    contentId?: number | { [key: string]: any }
  }) => {
    eventManager.emit('open-angular-right-panel', { content, contentId })
  }

  return {
    sidePanelMountElement,
    sidePanelOpenState,
    closeAngularSidePanel,
    openAngularSidePanel
  }
}
