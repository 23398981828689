import { createTaskFilterFunction, RunbookFilterType } from './filters'
import { TaskListTask } from 'main/services/queries/types'

export const overRunning = createTaskFilterFunction('or', (task: TaskListTask, filters: RunbookFilterType): boolean => {
  const isOverRunning = (task: TaskListTask): boolean => {
    return (
      task.end_display - task.start_display > task.duration &&
      (task.stage === 'in-progress' || task.stage === 'complete')
    )
  }
  return filters.or ? isOverRunning(task) : !isOverRunning(task)
})
