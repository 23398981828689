// @ts-nocheck
import 'reflect-metadata'
import { injectable } from 'inversify'
import { Routes } from './Routes'
import { IRouterGateway } from './IRouterGateway'
import { container } from '../Config/IOC'
import { Types } from 'Gateways/Service/Types'
import { RouteUpdater } from './RouteUpdater'

@injectable()
export class RouteRegistrar {
  private routerGateway: IRouterGateway = container.get(Types.IRouterGateway)

  private routeUpdater: RouteUpdater = container.get(RouteUpdater)

  public registerRoutes = () => {
    const routeConfig = {}
    const routes = new Routes().routes
    routes.forEach(route => {
      const def = this.routeUpdater.findRoute(route.routeId)
      routeConfig[def.routeDef.path] = {
        as: route.routeId,
        uses: async (params, query) => {
          this.routeUpdater.updateCurrentRoute(def.routeId, params, query, 'external')
        }
      }
    })
    this.routerGateway.registerRoutes(routeConfig)
  }
}
