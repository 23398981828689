import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const TaskFinished = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="TaskFinished" {...props}>
    <path d="m14 24.607 2.121-2.122 5.364 5.364L33.335 16l2.12 2.121-13.97 13.97L14 24.608Z" fill="#000" /><path d="M24 6c9.941 0 18 8.059 18 18s-8.059 18-18 18S6 33.941 6 24 14.059 6 24 6ZM9 24c0 8.284 6.716 15 15 15 8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15-8.284 0-15 6.716-15 15Z" fill="#000" />
  </StyledIcon>
))

TaskFinished.displayName = 'TaskFinished'

export { TaskFinished }
