/* eslint-disable */
import { ReactNode } from 'react'
import {
  QueryClientProvider as RQQueryClientProvider,
  QueryClientProviderProps as RQQueryClientProviderProps
} from 'react-query'
import styled from 'styled-components'
// @ts-ignore
import { ReactQueryDevtools } from 'react-query/devtools'

type QueryClientProviderProps = RQQueryClientProviderProps & {
  children: ReactNode
  /** Does not enable devtools, but can exclude them from enabling when uncommenting the devtools lines.
   * This is helpful because the RQ context is applied to both old react and new react currently which
   * would create 2 identical copies of the devtools layered on top of each other otherwise.
   * */
  withDevTools?: boolean
}

export function QueryClientProvider({
  client,
  children,
  contextSharing,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  withDevTools = true
}: QueryClientProviderProps) {
  return (
    <RQQueryClientProvider client={client} contextSharing={contextSharing}>
      {children}
      {/* NOTE: Inclusion of the devtools panel, even at the closed state, should be controlled by a dev
      environment variable. For now, to view the dev tools, uncomment the lines below. Note: The dev tools
      are automatically excluded from production builds. */}
      {/* Uncomment the lines below to show dev tools during development. */}
      {/* {withDevTools && (
        <DevToolsWrapper>
          <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
        </DevToolsWrapper>
      )} */}
    </RQQueryClientProvider>
  )
}

// Fix screwy positiong of dev tools due to our unique angular/react page structure.
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DevToolsWrapper = styled.div`
  padding-bottom: 0 !important;
`
