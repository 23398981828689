// @ts-nocheck
import { inject, injectable } from 'inversify'
import { observable } from 'mobx'
import { GenericFormPresenter } from '@logicroom/validator'
import { AlertSinglePresenter, ButtonPresenter, TextInputPresenter, ToasterService } from 'Components/Molecules'
import { TableScrollerService } from 'Components/Organisms'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'
import { Types } from 'Gateways/Service/Types'
import { FiltersRepository } from 'app/Filters/FiltersRepository'
import { container } from 'app/Config/IOC'

@injectable()
export class SaveFilterPresenter {
  @inject(FiltersRepository)
  private filtersRepository: FiltersRepository

  @inject(ToasterService)
  private toasterGateway: ToasterService

  private language: ILanguageService = container.get(Types.ILanguageService)

  @observable
  public alertPresenter = new AlertSinglePresenter().withIconName('report-problem').withState('error')

  @observable
  public filterNamePresenter = new TextInputPresenter().withLabel(this.language.get('common:nameButton')).isRequired()

  @observable
  public filterAddForm = new GenericFormPresenter().addFormInput(this.filterNamePresenter)

  @observable
  public isSubmitted = false

  public saveFilterPrompt = () => {
    return this.language.get('saveFilters:notification:saveFilterPrompt')
  }

  public postSuccessCall?: () => void
  public cancelCallback?: () => void

  public withPostSuccessCall = (call: () => void) => {
    this.postSuccessCall = call
    return this
  }

  public withCancelCallback = (call: () => void) => {
    this.cancelCallback = call
    return this
  }

  public submit = async () => {
    this.isSubmitted = true
    this.filterAddForm.serverErrors = []
    if (!this.filterAddForm.isValid) {
      this.filterNamePresenter.isDirty = true
      this.alertPresenter.message = this.language.get('common:formInvalid')
      return
    }
    this.submitButtonPresenter.isLoading = true
    const response = await this.filtersRepository.save(this.filterNamePresenter.value)
    this.submitButtonPresenter.isLoading = false

    if (response.success) {
      if (this.postSuccessCall) this.postSuccessCall()
      this.isSubmitted = true
      await this.filtersRepository.loadSavedFilters()
      container.get(TableScrollerService).doScroll()
      const title = this.language.get('common:notification:successTitle')
      const notification = this.language.get('saveFilters:notification:toasterMessage')
      this.toasterGateway.pop(title, notification, 'success')
    } else {
      this.filterAddForm.serverErrors.push(...response.errorMessages)
      this.alertPresenter.withMessage(response.errorMessages[0])
    }
  }

  public cancel = () => {
    if (this.cancelCallback) this.cancelCallback()
  }

  @observable
  public submitButtonPresenter = new ButtonPresenter(this.language.get('common:createButton'), this.submit)
    .withDisplayStyle('primary')
    .withIconName('add')
    .withTextTransform('Uppercase')

  public cancelButtonPresenter = new ButtonPresenter(this.language.get('common:cancelButton'), this.cancel)
    .withDisplayStyle('secondary')
    .withIconName('dnd-forwardslash')
    .withTextTransform('Uppercase')
}
