// @ts-nocheck
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { CustomFieldFilterPresenter } from './CustomFieldFilterPresenter'
import { px2rem } from 'app/Styles/Variables'
import { FilterDisclosurePanel } from 'Components/Molecules'

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: auto;
  box-sizing: border-box;
`

const FilterWrapper = styled.div`
  margin: 0 0 ${px2rem(56)} ${px2rem(12)};
`

interface ICustomFieldListComponentProps {
  presenter: CustomFieldFilterPresenter
}

export const CustomFieldFilterSection = observer<ICustomFieldListComponentProps>(({ presenter }) => {
  const {
    displayFilterPanels,
    visibilityFiltersDisclosurePanel,
    fieldTypeFiltersDisclosurePanel,
    contextFiltersDisclosurePanel,
    groupsFiltersDisclosurePanel,
    otherFiltersDisclosurePanel
  } = presenter

  return (
    <Wrapper>
      <FilterWrapper>
        {displayFilterPanels && (
          <>
            <FilterDisclosurePanel presenter={visibilityFiltersDisclosurePanel} />
            <FilterDisclosurePanel presenter={fieldTypeFiltersDisclosurePanel} />
            <FilterDisclosurePanel presenter={contextFiltersDisclosurePanel} />
            <FilterDisclosurePanel presenter={groupsFiltersDisclosurePanel} />
            <FilterDisclosurePanel presenter={otherFiltersDisclosurePanel} />
          </>
        )}
      </FilterWrapper>
    </Wrapper>
  )
})
