import axios from 'axios'
import { useMutation } from 'react-query'

import { ConfigModel } from 'main/data-access'

export type RequestPasswordResetPayload = {
  email: string
  fail_url: string
  redirect_url: string
}

export const useRequestPasswordReset = () => {
  const isReactLoginEnabled = ConfigModel.useIsFeatureEnabled('react_login')

  return useMutation('requestPasswordReset', async (payload: Pick<RequestPasswordResetPayload, 'email'>) => {
    const data = {
      ...payload,
      fail_url: `${window.location.origin}/#/${isReactLoginEnabled ? 'forgotpassword' : 'forgot-password'}`,
      redirect_url: `${window.location.origin}/#/${isReactLoginEnabled ? 'resetpassword' : 'reset-password'}`
    } as RequestPasswordResetPayload

    await axios.post<RequestPasswordResetPayload, {}>('/api/auth/password', data)
  })
}
