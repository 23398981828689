// @ts-nocheck
import { action, observable } from 'mobx'
import { PopupPosition } from 'reactjs-popup/dist/types'

export class PopoverPresenter {
  @observable
  public opacity = 0.1

  @observable
  public position: PopupPosition = 'right center'

  @action
  public withOpacity = (opacity: number) => {
    this.opacity = opacity
    return this
  }

  @action
  public withPosition = (position: PopupPosition) => {
    this.position = position
    return this
  }
}
