import { memo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { AccordionPanel, Box, DateTimePicker, TextInput } from '@cutover/react-ui'
import { RunbookEditFormType } from './runbook-edit-form'
import { useLanguage } from 'main/services/hooks'
import { RunbookEditRunbook } from 'main/services/queries/types'
import { useRouting } from 'main/services/routing'

export const RunbookEditOtherDetailsPanel = memo(
  ({
    runbook,
    accountSlug
  }: {
    runbook: RunbookEditRunbook
    accountSlug: string
    disabled?: boolean
    readOnly?: boolean
  }) => {
    const { t } = useLanguage('runbooks', { keyPrefix: 'fields' })
    const { watch } = useFormContext<RunbookEditFormType>()
    const { toRunbook } = useRouting()
    const navigate = useNavigate()
    const runbookTimezone = watch('runbook.timezone')
    const lowerCaseTimezone = runbookTimezone?.toLowerCase()

    return (
      <AccordionPanel label={t('otherDetails.label')} icon="info" iconColor={runbook.project.color}>
        <Box>
          {runbook.parent_runbook_id && (
            <TextInput
              readOnly // always
              clickable={!runbook.parent_runbook_archived}
              label={t('otherDetails.createdFrom.label')}
              value={
                runbook.parent_runbook_name
                  ? t(`otherDetails.createdFrom.${runbook.parent_runbook_archived ? 'valueArchived' : 'value'}`, {
                      name: runbook.parent_runbook_name
                    })
                  : undefined
              }
              onClick={() => {
                // TODO: determine if the parent is a current version, should it go to that alias or the version id?
                navigate(
                  toRunbook({
                    accountSlug,
                    runbookId: runbook.parent_runbook_id as number,
                    versionId: runbook.parent_version_id as number
                  })
                )
              }}
            />
          )}
          <TextInput
            disabled // always
            label={t('otherDetails.createdBy.label')}
            value={runbook.author_name}
          />
          <DateTimePicker
            value={new Date(runbook.created_at)}
            label={t('otherDetails.createdAt.label')}
            disabled // always
            onChange={() => {}}
            timezone={lowerCaseTimezone === 'automatic' ? null : runbookTimezone}
          />
          <DateTimePicker
            value={new Date(runbook.updated_at * 1000)}
            label={t('otherDetails.updatedAt.label')}
            disabled // always
            onChange={() => {}}
            timezone={lowerCaseTimezone === 'automatic' ? null : runbookTimezone}
          />
        </Box>
      </AccordionPanel>
    )
  }
)
