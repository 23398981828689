import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Ancestors = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Ancestors" {...props}>
    <path d="M16.058 13.42a6 6 0 1 0-2.646 1.413l2.53 4.747a6 6 0 0 0 5.469 10.253l2.532 4.747a6 6 0 1 0 8.093-.02l2.498-4.74a6 6 0 1 0-2.633-1.438l-2.519 4.778a6.02 6.02 0 0 0-2.793.007l-2.531-4.747A5.984 5.984 0 0 0 26 24c0-1.75-.748-3.323-1.942-4.42l2.531-4.747a6 6 0 1 0-2.646-1.413l-2.532 4.747a6.014 6.014 0 0 0-2.822 0l-2.531-4.747ZM12 12a3 3 0 1 1 0-6 3 3 0 0 1 0 6Zm16 0a3 3 0 1 1 0-6 3 3 0 0 1 0 6Zm-5 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm16 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" fill="#000" />
  </StyledIcon>
))

Ancestors.displayName = 'Ancestors'

export { Ancestors }
