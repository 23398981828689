// @ts-nocheck
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { withPresenter } from 'app/Config/withPresenter'
import { UserPageFilterPanelPresenter } from 'app/Access/Users/UsersPage/UsersPageFilterPanel/UserPageFilterPanelPresenter'
import { FilterDisclosurePanel } from 'Components/Molecules'
import SavedFiltersComponent from 'app/Filters/SaveFilters/SavedFiltersComponent'

interface IUserPageFilterPanelComponentProps {
  presenter: UserPageFilterPanelPresenter
}

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: auto;
  box-sizing: border-box;
`

const FilterWrapper = styled.div`
  margin: 0 0 56px 16px;
`

const UserPageFilterPanelSection = observer<IUserPageFilterPanelComponentProps>(({ presenter }) => {
  const {
    accountFiltersDisclosurePanel,
    roleTypesFiltersDisclosurePanel,
    communicationsFiltersDisclosurePanel,
    accessFiltersDisclosurePanel,
    displayFilterPanels
  } = presenter

  return (
    <Wrapper>
      <FilterWrapper>
        <SavedFiltersComponent />
        {displayFilterPanels && (
          <>
            <FilterDisclosurePanel presenter={accountFiltersDisclosurePanel} />
            <FilterDisclosurePanel presenter={roleTypesFiltersDisclosurePanel} />
            <FilterDisclosurePanel presenter={communicationsFiltersDisclosurePanel} />
            <FilterDisclosurePanel presenter={accessFiltersDisclosurePanel} />
          </>
        )}
      </FilterWrapper>
    </Wrapper>
  )
})

export default withPresenter(UserPageFilterPanelPresenter)(UserPageFilterPanelSection)
