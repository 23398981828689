import { Dispatch, SetStateAction, useState } from 'react'
import styled, { css } from 'styled-components'
import { format as formatDate, utcToZonedTime } from 'date-fns-tz'

import { Box, FavoriteStar, FeatureIcon, IconButton, IconName, media, Text, themeColor } from '@cutover/react-ui'
import { ActivityConfig, ActivityVM } from 'main/services/queries/use-activities'
import { ActivityHeader } from './activity-header'
import { ActivityDetails } from './activity-details'
import { useUpdateActivityMutation } from 'main/services/queries/use-update-activity-mutation'
import { useAppsTimezoneValue } from 'main/recoil/data-access'
import { useLanguage } from 'main/services/hooks'

type ActivityProps = {
  activity: ActivityVM
  activityConfig: ActivityConfig[]
  canUpdate: boolean
  previousActivity: ActivityVM
  index: number
  active: boolean
  setActive: (index: number | null) => void
  scrollToActivity: (index: number) => void
  setDeleteCommentActivity: Dispatch<SetStateAction<ActivityVM | undefined>>
}

export const Activity = ({
  activity,
  activityConfig,
  previousActivity,
  canUpdate,
  index,
  active,
  setActive,
  scrollToActivity,
  setDeleteCommentActivity
}: ActivityProps) => {
  const { t } = useLanguage('activities')
  const timezone = useAppsTimezoneValue()
  const mutation = useUpdateActivityMutation()
  const formattedDate = formatDate(utcToZonedTime(new Date(activity.created_at), timezone), 'HH:mm')
  const [activityIsHovered, setActivityIsHovered] = useState(false)
  const isComment = ['runbook.commented', 'task.commented'].includes(activity.key)
  const commentIsDeleted = !!activity.display?.reason
  const formattedPreviousDate = previousActivity
    ? formatDate(utcToZonedTime(new Date(previousActivity.created_at), timezone), 'HH:mm')
    : ''
  const samePreviousActivityTime = formattedDate === formattedPreviousDate
  const showDetails = !!activity.display?.message || !!activity.changes?.length || !!activity.display?.attachments

  const handleToggle = () => {
    mutation.mutate({
      activity: { ...activity, featured: !activity.featured }
    })
  }

  return (
    <ActivityStyledBox
      featured={activity.featured}
      key={activity.id}
      data-testid={`activity-${activity.id}`}
      onMouseEnter={() => setActivityIsHovered(true)}
      onMouseLeave={() => setActivityIsHovered(false)}
    >
      {!activity.grouped && (
        <ActivityHeader
          activity={activity}
          activityConfig={activityConfig}
          previousActivity={previousActivity}
          time={samePreviousActivityTime ? '' : formattedDate}
          index={index}
          active={active}
          setActive={setActive}
        />
      )}
      {showDetails && (
        <ActivityDetails
          index={index}
          scrollToActivity={(index: number) => scrollToActivity(index)}
          grouped={!!activity.grouped}
          activity={activity}
        />
      )}
      {activityIsHovered && (activity.grouped || samePreviousActivityTime) && (
        <ActivityInsightsWrapper grouped={!!activity.grouped}>
          <ActivityInsights time={formattedDate} />
        </ActivityInsightsWrapper>
      )}
      {(activityIsHovered || activity.featured) && (
        <CallToActionWrapper featured={activity.featured} grouped={!!activity.grouped}>
          {canUpdate && isComment && !commentIsDeleted && (
            <DeleteCommentButton
              tertiary
              size="medium"
              icon="delete"
              onClick={() => setDeleteCommentActivity(activity)}
              label={t('feed.deleteComment')}
              tipPlacement="top"
              data-testid={`delete-button-${activity.id}`}
            />
          )}
          <FavoriteStar
            data-testid={`activity-featured-${activity.id}`}
            isFavorite={activity.featured}
            toggleFavorite={canUpdate ? handleToggle : undefined}
          />
        </CallToActionWrapper>
      )}
    </ActivityStyledBox>
  )
}

const ActivityStyledBox = styled(Box)<{ featured?: boolean }>`
  list-style: none;
  margin-right: 16px;
  padding: 0 16px 0 8px;
  ${media.sm(css`
    padding: 0 16px;
  `)}
  background-color: ${props => (props.featured ? themeColor('star-bg') : themeColor('bg'))};

  &:hover {
    background-color: ${props => (props.featured ? themeColor('star-bg') : themeColor('bg-1'))};
  }
`

const CallToActionWrapper = styled(Box).attrs({ direction: 'row' })<{ grouped: boolean; featured?: boolean }>`
  right: 24px;
  padding-left: 8px;
  position: absolute;
  margin-top: ${props => (props.grouped ? '0' : '4px')};
  background-color: ${props => (props.featured ? themeColor('star-bg') : 'inherit')};
`
const DeleteCommentButton = styled(IconButton)`
  mix-blend-mode: luminosity; // Allows blending with highlighted background
`

const ActivityInsightsWrapper = styled(Box)<{ grouped: boolean }>`
  display: block;
  left: 8px;
  margin-top: ${props => (props.grouped ? '8px' : '12px')};
  position: absolute;
`

export const ActivityInsights = ({ time, icon, featured }: { time?: string; icon?: IconName; featured?: boolean }) => {
  return (
    <Box gap="xsmall" direction="row" align="center" css="flex: 0 0 auto;">
      <Box width="36px" align="center">
        {time && (
          <Text size="13px" color="text-light">
            {time}
          </Text>
        )}
      </Box>
      {icon && <FeatureIcon icon={icon} status={featured ? 'highlight' : 'info'} />}
    </Box>
  )
}
