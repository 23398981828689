import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const DislikeFilled = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="DislikeFilled" {...props}>
    <path d="M14.5 32.413v-23.5h3v25.045l.113.096c1.413 1.207 3.1 2.857 4.344 4.698a59.438 59.438 0 0 1 2.686 4.391c1.228 2.215 4.484 2.573 6.039.396a3.452 3.452 0 0 0 .521-2.915l-2.067-7.58a.5.5 0 0 1 .483-.63H40a3.5 3.5 0 0 0 3.5-3.5v-4.34a3.5 3.5 0 0 0-.688-2.084l-9.007-12.16a3.5 3.5 0 0 0-2.813-1.417H9.5a3.5 3.5 0 0 0-3.5 3.5v16.5a3.5 3.5 0 0 0 3.5 3.5h5Z" fill="#000" />
  </StyledIcon>
))

DislikeFilled.displayName = 'DislikeFilled'

export { DislikeFilled }
