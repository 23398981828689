import { Simplify } from 'type-fest'

import {
  useTaskType,
  useTaskTypeCallback,
  useTaskTypeLookup,
  useTaskTypeLookupCallback,
  useTaskTypeLookupLoadable,
  useTaskTypes,
  useTaskTypesCallback
} from '../hooks'
import {
  CollectionModelInterfaceBase,
  ModelCollectionAccessScope,
  CollectionModelIntefaceWithLookupLoadable as WithLookupLoadable
} from './types'
import { TaskType } from 'main/services/queries/types'

type TaskTypeCollectionAccessScope = ModelCollectionAccessScope<null>
type TaskTypeGetBy = 'id' | undefined

export type TaskTypeModelType = Simplify<
  CollectionModelInterfaceBase<TaskType, TaskTypeCollectionAccessScope, TaskTypeGetBy> & WithLookupLoadable<TaskType>
>

export const TaskTypeModel: TaskTypeModelType = {
  useGet: useTaskType,
  useGetCallback: useTaskTypeCallback,

  useGetAll: useTaskTypes,
  useGetAllCallback: useTaskTypesCallback,

  useGetLookup: useTaskTypeLookup,
  useGetLookupCallback: useTaskTypeLookupCallback,
  useGetLookupLoadable: useTaskTypeLookupLoadable
}
