import { intervalToDuration } from 'date-fns'
import { getTimezoneOffset } from 'date-fns-tz'

import { Text } from '../../typography'

// NOTE: these are similar functions to the ones in the reactApp timezone and date utils
// TODO: to be consolidated with timezone and date util functions
export const getTimezoneOffsetString = ({
  timezone,
  targetDate
}: {
  timezone: string | null
  targetDate?: Date | null
}) => {
  if (!targetDate) {
    return ''
  }
  const timezoneToOffset = timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone
  const offset = getTimezoneOffset(timezoneToOffset, new Date(targetDate))
  const negative = offset < 0
  const mod = negative ? '-' : '+'
  const offsetNumber = negative ? -1 * offset : offset
  const { hours, minutes } = getDuration(offset / 1000)
  const formatted = offsetNumber === 0 ? '00:00' : `${formatTime(hours ?? 0)}:${formatTime(minutes ?? 0)}`

  return `GMT${mod}${formatted}`
}

const formatTime = (number: number) => (number < 10 ? '0' + number : number)

const getDuration = (seconds: number) => {
  const duration = intervalToDuration({ start: 0, end: seconds * 1000 })
  if (!duration.weeks) {
    duration.weeks = ((duration.days ?? 0) / 7) | 0
    duration.days = (duration.days ?? 0) - duration.weeks * 7
  }
  return duration
}
// ------------------------------------------- end of timezone / date utils

export const createLabelWithTimezone = ({
  label,
  timezoneOffsetString,
  required
}: {
  label: string | undefined
  timezoneOffsetString: string
  required: boolean | undefined
}) => {
  return (
    <>
      {`${label} `}
      {timezoneOffsetString.length > 0 && (
        <Text weight="bold" size="small" css="line-height: 100%;">
          ({timezoneOffsetString})
        </Text>
      )}
      {`${required ? '*' : ''}`}
    </>
  )
}
