import { createTaskFilterFunction, RunbookFilterType, TaskFilterContext } from './filters'
import { TaskListTask } from 'main/services/queries/types'

export const critical = createTaskFilterFunction(
  'critical',
  (task: TaskListTask, filters: RunbookFilterType, context: TaskFilterContext): boolean => {
    // TODO: fixme use typeable code
    if (Object.prototype.hasOwnProperty.call(context, 'criticalIds') && context.criticalIds instanceof Array) {
      return filters.critical ? context.criticalIds?.includes(task.id) : !context.criticalIds?.includes(task.id)
    }
    // It's a very edge case, but is it ok to return false (=no tasks)
    // if the critical ids are not provided?
    return false
  }
)
