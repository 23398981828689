import { DefaultValue, selector, selectorFamily } from 'recoil'

import { filterSelector } from 'main/recoil/shared/filters'

const customFieldAppliedFilterLookup = selector({
  key: 'custom-fields:filter-lookup',
  get: ({ get }) => {
    const resp = get(filterSelector({ attribute: 'f' })) as unknown as Record<number, any>
    return resp
  }
})

export const customFieldFilterSelector = selectorFamily({
  key: 'filters:custom-field',
  get:
    ({ attribute: id }: { attribute: number }) =>
    ({ get }) => {
      const customFieldsFilters = get(customFieldAppliedFilterLookup)
      return customFieldsFilters?.[id]
    },
  set:
    ({ attribute: id }: { attribute: number }) =>
    ({ set }, newValue) => {
      if (newValue instanceof DefaultValue) return

      set(filterSelector({ attribute: 'f' }), (prev: any) => {
        if (
          newValue === null ||
          newValue === undefined ||
          newValue instanceof DefaultValue ||
          (Array.isArray(newValue) && newValue.length === 0)
        ) {
          const { [id]: _, ...rest } = prev ?? {}
          return rest
        }

        return {
          ...prev,
          [id]: newValue
        }
      })
    }
})
