// @ts-nocheck
import { useEffect } from 'react'
import { container } from 'app/Config/IOC'
import { IPageModalProps } from 'Shared/Entities/IPageModalProps'
import { Modal, ModalPresenter, GenericAddModal } from 'Components/Atoms'
import { EditPanelArchiveModal } from 'Components/Templates'
import AccountAddSection from './AccountAdd/AccountAddSection'
import { AccountsPageViewPresenter } from './AccountsPageViewPresenter'

export const AccountsPageModals = (props: IPageModalProps) => {
  const {
    addModalOpenState,
    setAddModalOpenState,
    addFormPresenter,
    archiveModalPresenter,
    archiveConfirmName,
    continueArchiveCallback,
    cancelArchiveCallback,
    archiveResponseErrors,
    archiveModalOpenState,
    language
  } = props

  const accountsPageViewPresenter: AccountsPageViewPresenter = container.get(AccountsPageViewPresenter)
  const accountAddModalPresenter = new ModalPresenter().withOnCloseExtra(() => {
    addFormPresenter.reset()
    setAddModalOpenState(false)
  })

  useEffect(() => {
    if (addModalOpenState === true) {
      accountAddModalPresenter.onOpen()
    }
  }, [addModalOpenState])

  useEffect(() => {
    if (archiveModalOpenState) {
      archiveModalPresenter.onOpen()
    } else {
      archiveModalPresenter.onClose()
    }
  }, [archiveModalOpenState])

  return (
    <>
      <GenericAddModal
        title={language.get('accounts:add:modalTitle')}
        presenter={accountAddModalPresenter}
        cancelCallback={() => {
          setAddModalOpenState(false)
        }}
        viewPresenter={accountsPageViewPresenter}
        language={language}
      >
        <AccountAddSection
          postSuccessCallback={() => {
            accountAddModalPresenter.onClose()
            setAddModalOpenState(false)
          }}
          addFormPresenter={addFormPresenter}
        />
      </GenericAddModal>

      <Modal presenter={archiveModalPresenter.withOnCloseExtra(cancelArchiveCallback)}>
        <EditPanelArchiveModal
          archiveConfirmName={archiveConfirmName}
          cancelCallback={cancelArchiveCallback}
          continueCallback={continueArchiveCallback}
          responseErrors={archiveResponseErrors}
          modalTitle={language.get('accounts:edit:archive:modal:title')}
          modalMessage={language.get('accounts:edit:archive:modal:message')}
        />
      </Modal>
    </>
  )
}
