// @ts-nocheck
import { createElement } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { THeadingRank } from './THeadingRank'
import { colors, px2rem } from 'app/Styles/Variables'

interface IHeadingProps {
  extraStyles?: CSSProperties
  rank?: THeadingRank
  size?: number // size in px
  children: ReactNode
  className?: string
  fontWeight?: number
  theme?: 'dark' | 'light'
}

const DEFAULT_RANK = 3

const StyledHeading = styled((props: IHeadingProps) => {
  const { rank, children, className, extraStyles } = props
  const InnerHTag = () => createElement(`h${rank || DEFAULT_RANK}`, { className, style: extraStyles }, children)
  return <InnerHTag />
})`
  color: ${(props: IHeadingProps) => (props.theme === 'dark' ? colors.black : colors.text)};
  font-weight: ${(props: IHeadingProps) => (props.fontWeight ? `${props.fontWeight}` : 600)};
  margin: 0;
  ${(props: IHeadingProps) => (props.size ? `font-size: ${px2rem(props.size)}` : null)};
`

const Wrapper = styled.div``

/** @deprecated do not use this component from the app folder, refactor and/or use components from react-ui instead **/
export const Heading = observer<IHeadingProps>(props => {
  return (
    <Wrapper>
      <StyledHeading {...props} />
    </Wrapper>
  )
})
