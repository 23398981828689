import { Box, Button, Icon, IconName, MessageType, Text, themeColor } from '@cutover/react-ui'
import { BANNER_HEIGHT } from '../../../../reactApp/src/main/components/layout/layout-constants'

const BANNER_TYPE_ICON_MAP: Record<MessageType, IconName> = {
  error: 'task-deleted',
  success: 'check',
  warning: 'alert',
  info: 'info',
  idea: 'lightbulb'
}
export type BannerProps = {
  type: MessageType
  message?: string
  linkText?: string
  linkTextSecondary?: string
  icon?: boolean
  onClick?: any
  onClickSecondary?: any
  children?: any
}

export const Banner = ({
  message,
  type,
  linkText,
  icon,
  onClick,
  linkTextSecondary,
  onClickSecondary,
  children
}: BannerProps) => {
  return (
    <Box
      data-testid="banner"
      direction="row"
      pad={{ horizontal: 'large', vertical: 'small' }}
      gap="medium"
      background={`message-${type}-bg`}
      aria-live="polite"
      role="banner"
      a11yTitle="banner"
      flex={false}
      justify="between"
      align="center"
      height={`${BANNER_HEIGHT}px`}
      css={`
        white-space: normal;
        color: ${themeColor(`message-${type}`)};
      `}
    >
      {children ? (
        <>{children}</>
      ) : (
        <>
          <Box direction="row" align="center" gap="small">
            {icon && <Icon icon={BANNER_TYPE_ICON_MAP[type]} color={`message-${type}`} />}
            <Text size="18px">{message}</Text>
          </Box>
          {(linkText || linkTextSecondary) && (
            <Box direction="row" flex="grow" justify={linkText && linkTextSecondary ? 'between' : 'end'} gap="small">
              {linkTextSecondary && (
                <Button
                  plain
                  onClick={onClickSecondary}
                  css={`
                    text-decoration: underline;
                    opacity: 0.7;
                  `}
                  a11yTitle={`${message} ${linkTextSecondary}`}
                >
                  <Text size="18px">{linkTextSecondary}</Text>
                </Button>
              )}
              {linkText && (
                <Button
                  plain
                  onClick={onClick}
                  css={`
                    text-decoration: underline;
                    opacity: 0.7;
                  `}
                  a11yTitle={`${message} ${linkText}`}
                >
                  <Text size="18px">{linkText}</Text>
                </Button>
              )}
            </Box>
          )}
        </>
      )}
    </Box>
  )
}
