// @ts-nocheck
import { inject, injectable, postConstruct } from 'inversify'
import { computed, observable, action } from 'mobx'
import { FiltersRepository } from 'app/Filters/FiltersRepository'
import { AccordionPresenter, ToasterService } from 'Components/Molecules'
import { Router } from 'app/Routing/Router'
import { RoutingState } from 'app/Routing/RoutingState'
import { RouteUpdater } from 'app/Routing/RouteUpdater'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'
import { Types } from 'Gateways/Service/Types'
import { container } from 'app/Config/IOC'

@injectable()
export class SavedFiltersPresenter {
  @inject(Router)
  public router: Router

  @inject(FiltersRepository)
  public filtersRepository: FiltersRepository

  @inject(RoutingState)
  public routingState: RoutingState

  @inject(RouteUpdater)
  public routeUpdater: RouteUpdater

  @inject(ToasterService)
  private toasterGateway: ToasterService

  public language: ILanguageService = container.get(Types.ILanguageService)

  @observable
  public isCurrentFilter: any

  @observable
  public accordionPresenter = new AccordionPresenter(this.language.get('saveFilters:accordionTitle')).withCollapsed(
    false
  )

  @action
  private clearFunction = () => {
    this.accordionPresenter.reset()
  }

  @postConstruct()
  public init() {
    this.router.registerRouteLeaveCallback({
      routeId: '',
      callback: this.clearFunction
    })
  }

  @computed
  public get filters() {
    return this.filtersRepository.savedFilters.map(savedFilter => {
      return { id: savedFilter.id, name: savedFilter.key, urlQuery: savedFilter.value }
    })
  }

  public deleteFilter = async filterId => {
    await this.filtersRepository.deleteFilter(filterId)
    const title = this.language.get('common:notification:successTitle')
    const notification = this.language.get('saveFilters:deleteSavedFilter:message')
    this.toasterGateway.pop(title, notification, 'success')
    await this.filtersRepository.loadSavedFilters()
  }
}
