import { Accordion, AccordionPanel, Badge, Box, Text } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'

type ValidateSnippetProps = {
  taskCount: number
}

export const ValidateSnippet = ({ taskCount }: ValidateSnippetProps) => {
  const { t } = useLanguage('runbook')

  return (
    <Box margin={{ bottom: '12px' }}>
      <Text>{t('createSnippetModal.description')}</Text>
      {taskCount && (
        <Accordion>
          <AccordionPanel
            label="Tasks"
            key="1"
            icon="check"
            iconColor="success"
            suffix={<Badge label={taskCount} type="light" />}
          >
            <Text>{`${t('validateSnippetModal.snippetWillContain')} ${t('validateSnippetModal.tasksCount', {
              count: taskCount
            })}`}</Text>
          </AccordionPanel>
        </Accordion>
      )}
    </Box>
  )
}
