// @ts-nocheck
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { CustomFieldPageHeadingPresenter } from './CustomFieldPageHeadingPresenter'
import { px2rem, colors } from 'app/Styles/Variables'
import { HeadingTitle, HeaderFilterIcon } from 'Components/Templates'
import { ClearFilter, PillFilter, SearchBox } from 'Components/Molecules'
import { container } from 'app/Config/IOC'
import { Types } from 'Gateways/Service/Types'
import { CustomFieldPageHeadingExtraOptionsComponent } from './CustomFieldPageHeadingExtraOptionsComponent'
import { buildFractionString } from 'Shared/HooksAndPresenters/HeadingSection'

const HeaderWrapper = styled.div`
  width: 100%;
  height: 100%;
`

const RightIconContainer = styled.div`
  z-index: 2;
  position: absolute;
  padding-right: ${px2rem(50)};
  padding-left: ${px2rem(6)};
  top: 0;
  right: 0;
  background-color: ${colors.bg1};
  box-shadow: ${px2rem(-10)} ${px2rem(0)} ${px2rem(40)} ${px2rem(10)} ${colors.bg1};
`

interface ICustomFieldPageHeadingComponentProps {
  filterOpen: boolean
  setFilterOpen: (val: boolean) => void
  presenter: CustomFieldPageHeadingPresenter
}

export const CustomFieldPageHeadingSection = observer<ICustomFieldPageHeadingComponentProps>(
  ({ presenter, filterOpen, setFilterOpen }) => {
    const {
      searchBoxPresenter,
      totalListCount,
      filteredCustomFieldCount,
      showClearFilterButton,
      clearFilters,
      extraOptionsPresenter,
      visibilityPillsPresenter,
      fieldTypePillsPresenter,
      groupPillsPresenter,
      contextPillsPresenter,
      otherPillsPresenter,
      searchPillPresenter
    } = presenter
    const { countString } = buildFractionString(filteredCustomFieldCount, totalListCount)
    const language = container.get(Types.ILanguageService)

    const { showingArchivedCustomFields } = extraOptionsPresenter

    const Pills = () => {
      return (
        <>
          <PillFilter presenter={visibilityPillsPresenter} />
          <PillFilter presenter={fieldTypePillsPresenter} />
          <PillFilter presenter={contextPillsPresenter} />
          <PillFilter presenter={groupPillsPresenter} />
          <PillFilter presenter={otherPillsPresenter} />
          <PillFilter presenter={searchPillPresenter} />
        </>
      )
    }

    return (
      <HeaderWrapper>
        <HeaderFilterIcon filterOpen={filterOpen} setFilterOpen={setFilterOpen} />
        <HeadingTitle
          title={
            showingArchivedCustomFields
              ? language.get('customFields:heading:archivedTitle')
              : language.get('customFields:heading:title')
          }
          count={countString}
          pills={Pills()}
        />
        <RightIconContainer>
          <ClearFilter clearFilters={clearFilters} showClearFilterButton={showClearFilterButton} language={language} />
          <CustomFieldPageHeadingExtraOptionsComponent presenter={extraOptionsPresenter} />
          <SearchBox
            presenter={searchBoxPresenter}
            searchContent={language.get('customFields:heading:searchContent')}
            language={language}
          />
        </RightIconContainer>
      </HeaderWrapper>
    )
  }
)
