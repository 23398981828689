import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const UserStart = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="UserStart" {...props}>
    <path d="M24 16a7 7 0 1 1-14 0 7 7 0 0 1 14 0Zm-3 0a4 4 0 1 0-8 0 4 4 0 0 0 8 0Zm7.959 21C28.45 30.84 23.29 26 17 26S5.55 30.84 5.041 37c-.027.33-.041.663-.041 1a2 2 0 0 0 2 2h20a2 2 0 0 0 2-2c0-.337-.014-.67-.041-1Zm-3.014 0H8.055a9.001 9.001 0 0 1 17.89 0ZM43 22.268c1.333.77 1.333 2.694 0 3.464l-10.5 6.062c-1.333.77-3-.192-3-1.732V17.938c0-1.54 1.667-2.502 3-1.732L43 22.268ZM32.5 19.67v8.66L40 24l-7.5-4.33Z" fill="#000" />
  </StyledIcon>
))

UserStart.displayName = 'UserStart'

export { UserStart }
