import { useMemo } from 'react'
import { format, fromUnixTime } from 'date-fns'

import { Box, Message, Modal, Text } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { useModalCheckTaskStageChanged } from './use-modal-check-task-stage-changed'
import { ActiveRunbookModel, RunbookViewModel, TaskModel, TaskProgressionViewModel } from 'main/data-access'

type TaskFixedStartOverrideProps = {
  id: number
  onClose: () => void
}

export const TaskFixedStartOverride = ({ id, onClose }: TaskFixedStartOverrideProps) => {
  const { t } = useLanguage('tasks', { keyPrefix: 'taskFixedStartOverrideModal' })
  const canUpdate = ActiveRunbookModel.useCan('update')
  const task = TaskModel.useGet(id)
  const startFixedTime = format(fromUnixTime(task.start_fixed as number), 'dd/MM/yy HH:mm')
  const closeModal = RunbookViewModel.useAction('modal:close')
  const continueModal = RunbookViewModel.useAction('modal:continue')
  const progressTask = TaskModel.useAction('progress')
  const getNextModalSync = TaskProgressionViewModel.getCallbackSync('nextModal')

  const nextModal = useMemo(
    () =>
      getNextModalSync(id, {
        from: { id, type: 'task-override-fixed-start' }
      }),
    [id, getNextModalSync]
  )

  const {
    handleSubmit,
    handleClose,
    confirmIcon,
    confirmText,
    errors: stageChangedErrors,
    renderContent,
    hasCancelButton
  } = useModalCheckTaskStageChanged({
    task,
    onSubmit: async () => {
      if (!canUpdate) return closeModal()
      return nextModal ? continueModal(nextModal, { id, type: 'task-override-fixed-start' }) : progressTask(id)
    },
    onClose,
    confirmIcon: canUpdate ? 'caret-right' : undefined,
    confirmText: t(canUpdate ? 'confirmTextAdmin' : 'confirmText')
  })

  return (
    <Modal
      data-testid="modal-task-override-start-fixed"
      open
      animate={nextModal ? 'in' : true}
      title={t('title')}
      confirmText={confirmText as string}
      confirmIcon={confirmIcon}
      onClickConfirm={handleSubmit as any}
      onClose={handleClose}
      hasCancelButton={hasCancelButton}
    >
      {stageChangedErrors && <Message margin type="error" message={stageChangedErrors} />}
      {renderContent(
        <Box>
          <Text>{t('content', { startFixedTime })}</Text>
          {!canUpdate && <Text>{t('contentNotAdmin')}</Text>}
        </Box>
      )}
    </Modal>
  )
}
