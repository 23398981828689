// @ts-nocheck
import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { breakpoints, px2rem, colors } from 'app/Styles/Variables'
import { withPresenter } from 'app/Config/withPresenter'
import { UserAddPresenter } from 'app/Access/Users/UsersPage/UsersAdd/UserAddPresenter'
import { RadioButtons } from 'Components/Atoms'
import { ErrorAlert, Checkbox, RoleTypes, TextInput } from 'Components/Molecules'

const Wrapper = styled.div``

const ResponsiveContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media ${breakpoints.sm} {
    flex-direction: column;
  }
`

const LeftInputContainer = styled.div`
  width: 100%;
  margin-right: ${px2rem(8)};

  @media ${breakpoints.sm} {
    margin-right: 0;
  }
`

const RightInputContainer = styled.div`
  width: 100%;
  margin-left: ${px2rem(8)};

  @media ${breakpoints.sm} {
    margin-left: 0;
  }
`

const RolesWrapper = styled.div`
  margin-top: ${px2rem(16)};
  border-bottom: dashed ${px2rem(1)} ${colors.primaryGreyHoverBackgroundColor};
`

const LoginOptionWrapper = styled.div`
  margin-top: ${px2rem(16)};
  border-bottom: dashed ${px2rem(1)} ${colors.primaryGreyHoverBackgroundColor};
`

const InviteWrapper = styled.div`
  margin-top: ${px2rem(16)};
  border-bottom: dashed ${px2rem(1)} ${colors.primaryGreyHoverBackgroundColor};
`

const InviteHeader = styled.label`
  font-size: ${px2rem(13)};
  font-weight: 500;
  line-height: ${px2rem(12)};
  color: ${colors.textLight};
`

interface IUserAddComponentProps {
  postSuccessCallback: () => void
  cancelCallback: () => void
  presenter: UserAddPresenter
}

const UserAddSection = observer<IUserAddComponentProps>(({ postSuccessCallback, cancelCallback, presenter }) => {
  const {
    typeRadioPresenter,
    firstNamePresenter,
    lastNamePresenter,
    emailPresenter,
    roleTypePresenter,
    loginOptionPresenter,
    sendInvitationPresenter,
    inviteMessagePresenter,
    errorAlertPresenter,
    userAddForm,
    isSubmitted,
    includeSsoId,
    uniqueIdPresenter,
    isIndividual,
    handleFileForUpload,
    bulkUploadText,
    clearDisposers,
    setupObserve,
    hasResponseErrors,
    language
  } = presenter

  presenter.withPostSuccessCall(postSuccessCallback)
  presenter.withCancelCallback(cancelCallback)

  const handleFileUpload = e => {
    if (e.target.files.length === 0) return
    handleFileForUpload(e.target.files[0])
  }

  useEffect(() => {
    setupObserve()
    return () => {
      clearDisposers()
    }
  }, [])

  return (
    <Wrapper>
      <form>
        {isSubmitted && !userAddForm.isValid && hasResponseErrors && <ErrorAlert presenter={errorAlertPresenter} />}
        <RadioButtons presenter={typeRadioPresenter} />
        {isIndividual ? (
          <>
            <ResponsiveContainer>
              <LeftInputContainer>
                <TextInput presenter={firstNamePresenter} />
              </LeftInputContainer>
              <RightInputContainer>
                <TextInput presenter={lastNamePresenter} />
              </RightInputContainer>
            </ResponsiveContainer>
            <TextInput presenter={emailPresenter} />
            {includeSsoId && <TextInput presenter={uniqueIdPresenter} />}
          </>
        ) : (
          <>
            <div>
              <p>{bulkUploadText}</p>
              <input type="file" id="myFile" onChange={e => handleFileUpload(e)} accept=".csv"></input>
            </div>
            <br />
          </>
        )}
        <RolesWrapper>
          {roleTypePresenter.loadingRoleTypes ? (
            language.get('common:loading')
          ) : (
            <RoleTypes presenter={roleTypePresenter} displayHeader />
          )}
        </RolesWrapper>
        <LoginOptionWrapper>
          <RadioButtons presenter={loginOptionPresenter}></RadioButtons>
        </LoginOptionWrapper>
        <InviteWrapper>
          <InviteHeader>Notification Settings</InviteHeader>
          <Checkbox presenter={sendInvitationPresenter} includeCheckedAndFocusedStyle={true} />
          {sendInvitationPresenter.value ? <TextInput presenter={inviteMessagePresenter} /> : null}
        </InviteWrapper>
      </form>
    </Wrapper>
  )
})

export default withPresenter(UserAddPresenter)(UserAddSection)
