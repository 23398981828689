// @ts-nocheck
import { IPermissions } from 'Shared/Entities/Types/IPermissions'
import { IRecord, IRecordDTO } from 'app/Repositories/GenericRecordRepository'
import { TFieldOptionTypes } from 'Shared/Components/Organisms/FieldOptions/TFieldOptionTypes'
import { TRemoteOptionType } from 'Shared/Components/Organisms/FieldOptions/TRemoteOptionType'

export interface ICustomFieldResponse {
  success: boolean
  body: {
    custom_field: ICustomFieldDTO
    searchable_custom_field: ICustomFieldDTO
    multi_searchable_custom_field: ICustomFieldDTO
  }
}

export interface IFieldTypeDTO {
  slug: string
  name: string
  select_type: string
}

export interface IApplyToDTO {
  slug: string
  name: string
}

export interface IFieldOptionDTO {
  archived?: boolean
  color?: string
  conflict_exclude?: boolean
  custom_field_id?: number
  id?: number
  name: string
  order: number
}

export interface IFieldRemoteKeysDTO {
  id?: number
  name: string
  is_primary: boolean
  order: number
  display_name: string
  display_search?: boolean
  field_type?: IFieldTypeDTO
  archived?: boolean
  display_list?: boolean
  apply_to?: IApplyToDTO
  required?: boolean
  parent_field_id?: number
}

export interface ICustomFieldGroupDTO {
  id: number
  name: string
  default: boolean
}

export interface ICustomFieldDTO extends IRecordDTO {
  name: string
  field_type: IFieldTypeDTO
  order: number
  archived: boolean
  required: boolean
  apply_to: IApplyToDTO
  constraint: string
  display_list: boolean
  default_value: string
  display_search: boolean
  display_table: boolean
  default: boolean
  global: boolean
  account_name: string
  account_id: number
  allow_field_creation: boolean
  permissions: IPermissions
  indicate_conflicts: string
  remote_url: string
  field_options: IFieldOptionDTO[]
  field_remote_keys: IFieldRemoteKeysDTO[]
  custom_field_groups: ICustomFieldGroupDTO[]
  used: boolean
  options: IOptions
  default_integration_action_item_id: number
  data_source_id: number
}

export enum IndicateConflicts {
  Allow,
  Warn
}

export interface IDependentFieldDTO {
  name: string
  is_primary: boolean
  field_type: string
  apply_to: string
  global: boolean
  account_id: number
}

export interface ICustomFieldRecordDTO {
  id?: number
  name: string
  value_key: string
  field_type: string
  order?: number
  archived?: boolean
  required?: boolean
  apply_to: string
  constraint?: string
  allow_field_creation?: boolean
  display_list?: boolean
  default_value?: string
  display_search?: boolean
  display_table?: boolean
  default?: boolean
  global: boolean
  account_id: number
  remote_url?: string
  field_options_attributes: IFieldOptionDTO[]
  field_remote_keys_attributes: IFieldRemoteKeysDTO[]
  indicate_conflicts?: string
  used?: boolean
  options?: IOptions
  default_integration_action_item_id: number
  data_source_id: number
  dependent_custom_fields_attributes: IDependentFieldDTO[]
}

export interface IConstraint {
  [key: string]: number[]
}

export interface IOptions {
  transient?: boolean
  readonly?: boolean
  lock_name?: boolean
}

export interface ICustomFieldPM extends IRecord {
  id?: number
  name: string
  value_key: string
  global: boolean
  accountId: number
  fieldType: string
  required: boolean
  applyTo: string
  constraint: IConstraint
  displayList: boolean
  displaySearch: boolean
  displayTable: boolean
  defaultValue: string
  default: boolean
  archived: boolean
  allowFieldCreation: boolean
  indicateConflicts?: IndicateConflicts
  remoteURL: string
  fieldOptions: TFieldOptionTypes[]
  fieldRemoteKeys: TRemoteOptionType[]
  used: boolean
  groupId?: number
  options: IOptions
  defaultIntegrationActionItemId: number
  dataSourceId: number
  dataSourceMappings: string[]
  dependentCustomFieldValueKeys: string[]
}
