import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const TaskSkipped = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="TaskSkipped" {...props}>
    <path d="M34.84 21h3.86C37.31 14.153 31.257 9 24 9 15.716 9 9 15.716 9 24H6a18 18 0 0 1 35.748-3h4.406l-5.657 5.657L34.84 21Z" fill="#000" /><path d="M24 27a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm0 15c-8.206 0-15.13-5.491-17.296-13h3.15C11.913 34.826 17.469 39 24 39c6.531 0 12.087-4.174 14.147-10h3.15C39.13 36.509 32.207 42 24 42Z" fill="#000" />
  </StyledIcon>
))

TaskSkipped.displayName = 'TaskSkipped'

export { TaskSkipped }
