import { ReactNode, useCallback } from 'react'
import { createPath, parsePath, useNavigate } from 'react-router-dom'

import { RecoilURLHashSearchSync } from './recoil-url-hash-search-sync'

export const RecoilSyncComponent = ({ children, history }: { children?: ReactNode; history?: any }) => {
  return <URLSearchSync history={history}>{children}</URLSearchSync>
}

const URLSearchSync = ({ children, history }: { children?: ReactNode; history?: any }) => {
  const navigate = useNavigate()

  const listenChangeURL = useCallback((handleUpdate: () => void) => {
    window.addEventListener('searchchanged', handleUpdate)

    return () => {
      window.removeEventListener('searchchanged', handleUpdate)
    }
  }, [])

  const replaceURL = useCallback(
    (urlString: string) => {
      const toPath = typeof urlString === 'string' ? parsePath(urlString) : urlString
      toPath.search = buildRecoilSyncURI(toPath.search ?? '')
      navigate(toPath, { replace: true })
    },
    [navigate]
  )

  const pushURL = useCallback(
    (urlString: string) => {
      const toPath = typeof urlString === 'string' ? parsePath(urlString) : urlString
      toPath.search = buildRecoilSyncURI(toPath.search ?? '')
      navigate(toPath, { replace: false })
    },
    [navigate]
  )

  const serialize = useCallback((value: any) => {
    const string = decodeURI(isEncodedCFFilterURI(value.search) ? value.search : handlePercentEncoding(value.search))
    return string.startsWith('?') ? string.substring(1) : string
  }, [])

  const deserialize = useCallback((value: string) => decodeURI(handlePercentEncoding(value)), [])

  const getURL = useCallback(() => {
    const url = history ? createPath(history.location) : window.location.href
    return decodeURI(handlePercentEncoding(url))
  }, [history])

  return (
    <RecoilURLHashSearchSync
      storeKey="search"
      serialize={serialize}
      deserialize={deserialize}
      browserInterface={{
        replaceURL,
        pushURL,
        listenChangeURL,
        getURL
      }}
    >
      {children}
    </RecoilURLHashSearchSync>
  )
}

function isEncodedCFFilterURI(str: string) {
  return str.includes('f=%7B')
}

// Encode '%' only if it's not already encoded '%25'
export function handlePercentEncoding(str: string) {
  return str.replace(/(%)(?![0-9A-Fa-f]{2})/g, (_, group) => {
    const nextTwoChars = str.substring(group + 1, group + 3)
    return /[0-9A-Fa-f]{2}/.test(nextTwoChars) ? group : '%25'
  })
}

export function encodeReservedCharacters(str: string) {
  const replacements: { [x: string]: string } = {
    '&': '%26',
    '#': '%23',
    '?': '%3F'
  }

  return str.toString().replace(/[&#?]/g, match => replacements[match])
}

export function decodeReservedCharacters(str: string) {
  const replacements: { [x: string]: string } = {
    '%26': '&',
    '%23': '#',
    '%3F': '?'
  }

  return str.toString().replace(/%26|%23|%3F/g, match => replacements[match])
}

/**
 * Builds a fully encoded recoil sync URI.
 * Handles encoding of reserved characters in 'q' and 'f' filter values.
 * @param input unencoded input
 * @returns fully encoded URI
 */
export function buildRecoilSyncURI(input: string) {
  const { modifiedURI, qContent, fContent } = replaceQAndFParams(input)
  return encodeModifiedURI(encodeURI(modifiedURI), qContent, fContent)
}

/**
 * Replaces 'q' and 'f' filter parameters for placeholders to be later replaced back with the encoded filter values.
 * The original unencoded URI is required to match the parameter start and end (the next '&' char).
 * '&' chars in filters values are encoded as '%26' at this point, not affecting the replacement.
 * @param input original URI
 * @returns the modifiedURI and 'q' and 'f' filter values
 */
function replaceQAndFParams(input: string) {
  const regexQ = /q=([^&]+)/
  const regexF = /f=([^&]+)/

  let qContent = ''
  let fContent = ''

  const modifiedURI = input
    .replace(regexQ, (_, group) => {
      qContent = decodeReservedCharacters(group)
      return 'q=__'
    })
    .replace(regexF, (_, group) => {
      fContent = decodeReservedCharacters(group)
      return 'f=__'
    })

  return { modifiedURI, qContent, fContent }
}

/**
 * Encodes 'q' and 'f' filter values and replaces them back into the placeholder locations in the encoded URI.
 * A custom encoder can be built for the 'f' filter to improve its appearance to the end user.
 * For instance, currently '{' and '}' chars are encoded as '%7B' and '%7D' and could be left unencoded.
 * @param str encoded URI with placeholder 'q' and 'f' params
 * @param q unencoded 'q' filter value
 * @param f unencoded 'f' filter value
 * @returns a fully encoded URI
 */
function encodeModifiedURI(str: string, q: string, f: string) {
  const encodedQ = encodeReservedCharacters(encodeURI(q))
  const encodedF = encodeReservedCharacters(encodeURI(f))
  const result = str.replace('q=__', `q=${encodedQ}`).replace('f=__', `f=${encodedF}`)
  return handlePercentEncoding(result)
}
