import { ThemeType as GrommetThemeType } from 'grommet/themes'
import { css } from 'styled-components/macro'
import { SetRequired } from 'type-fest'

type GrommetTheme = SetRequired<GrommetThemeType, 'checkBox'>
type GrommetCheckBox = Partial<GrommetTheme['checkBox']>

const checkboxCheckStyle = css`
  background-color: #157f25;
  border-color: #157f25;
`

export const checkBox: GrommetCheckBox = {
  border: {
    color: {
      light: 'toggle-bg'
    }
  },
  color: {
    light: 'toggle-knob'
  },
  check: {
    radius: '1px'
  },
  hover: {
    border: {
      color: undefined
    }
  },
  toggle: {
    background: { light: 'toggle-accent' },
    color: {
      light: 'toggle-knob'
    },
    size: '36px',
    knob: {
      extend: `
        top: -1px;
        box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.12),
         0px 2px 2px 0px rgba(0,0,0,0.24);
      `
    },
    extend: ({ checked }: any) => `
      height: 20px;
      padding-right: 35px;
      ${checked && checkboxCheckStyle}
    `
  },
  gap: 'xsmall',
  size: '17.5px'
}
