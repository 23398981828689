// @ts-nocheck
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { Button } from 'Components/Molecules'

const ButtonWrapper = styled.div`
  margin-top: 12px;
`

export const UserEditUnlockSection = observer((props: any) => {
  const { showUnlock, buttonPresenter } = props.presenter

  return (
    <>
      {showUnlock && (
        <ButtonWrapper>
          <Button presenter={buttonPresenter} extraStyles={{ width: '100%' }} />
        </ButtonWrapper>
      )}
    </>
  )
})
