// @ts-nocheck
import { observable } from 'mobx'
import { injectable, inject } from 'inversify'
import { PermissableRepository } from 'app/Shared/Repositories/PermissableRepository'
import { IBaseResponse } from 'Gateways/Service/IBaseResponse'
import { ITaskTypePM } from 'Shared/Entities/Types/TaskType/ITaskTypePM'
import { ITaskTypeDTO } from 'Shared/Entities/Types/TaskType/ITaskTypeDTO'
import { QueryStringBuilder } from 'app/Shared/Helpers/QueryStringBuilder'

@injectable()
export class TaskTypesRepository extends PermissableRepository {
  @inject(QueryStringBuilder)
  private queryStringBuilder: QueryStringBuilder

  @observable
  public taskTypes: ITaskTypePM[]

  @observable
  public taskTypesTotal: number

  @observable
  public taskTypesFilteredTotal: number

  constructor() {
    super('meta')
  }

  public preLoad = (args: { [key: string]: any }): string => {
    let sortParams: { [key: string]: any } = null
    if (args && args.sortParams !== null) sortParams = args.sortParams
    const url = 'task_types' + this.queryStringBuilder.getQueryString(null, sortParams)
    return url
  }

  public postLoad = (rawData: IBaseResponse) => {
    this.taskTypesTotal = rawData.body.meta.total_results
    this.taskTypesFilteredTotal = rawData.body.meta.filtered_results
    this.taskTypes = rawData.body.task_types.map((taskTypeDTO: ITaskTypeDTO): ITaskTypePM => {
      return {
        id: taskTypeDTO.id,
        name: taskTypeDTO.name,
        default: taskTypeDTO.default,
        accountName: taskTypeDTO.account_name,
        accountId: taskTypeDTO.account_id || 0,
        archived: taskTypeDTO.archived,
        visibilityType: taskTypeDTO.account_id ? 'account' : 'global',
        global: taskTypeDTO.global,
        defaultDuration: taskTypeDTO.default_duration,
        highlight: taskTypeDTO.highlight,
        autoStart: taskTypeDTO.auto_start,
        autoFinish: taskTypeDTO.auto_finish,
        conditionalProgression: taskTypeDTO.conditional_progression,
        comms: taskTypeDTO.comms,
        enableStartFixed: taskTypeDTO.enable_start_fixed,
        enableEndFixed: taskTypeDTO.enable_end_fixed,
        linkable: taskTypeDTO.linkable
      }
    })
  }
}
