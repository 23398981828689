import { ReactElement } from 'react'

import { LegacyBox as Box, colors, Heading, Tooltip } from '@cutover/react-ui'
import { LanguageTranslate, useLanguage } from 'main/services/hooks'

export type ChartRenderProps = {
  translate: LanguageTranslate
}

export type ChartProps = {
  title?: string
  titleKey?: string
  render: (props: ChartRenderProps) => ReactElement
}

export function Chart(props: ChartProps) {
  return <ChartInner {...props} />
}

function ChartInner({ titleKey, title, render }: ChartProps) {
  const { translate } = useLanguage()
  const heading = title ?? translate(titleKey ?? '')

  return (
    <Box>
      {heading ? (
        <Heading as="h3" color={colors.textLight} truncate>
          <Tooltip content={heading} placement="auto">
            {heading}
          </Tooltip>
        </Heading>
      ) : null}
      <Box css={{ margin: '12px 12px 24px 0' }}>{render({ translate })}</Box>
    </Box>
  )
}
