import { createTaskFilterFunction, RunbookFilterType, TaskFilterContext } from './filters'
import { TaskListTask } from 'main/services/queries/types'

export const runbookComponent = createTaskFilterFunction(
  'rbc',
  (task: TaskListTask, filters: RunbookFilterType, context: TaskFilterContext): boolean => {
    if (filters.rbc === undefined || task.runbook_component_id === null) return false

    return context.runbookComponentIds?.includes(task.runbook_component_id) ?? false
  }
)
