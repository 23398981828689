// @ts-nocheck
import { observable, computed, action } from 'mobx'
import { windowSizes } from 'app/Styles/Variables'
import { TIcons } from 'Components/Atoms'
import { TDisplayStyle } from './TDisplayStyle'
import { TIconPosition } from './TIconPosition'

type TTransform = 'Uppercase' | 'Lowercase' | 'Capitalize'

export class ButtonPresenter {
  @observable
  public onClick: () => void

  @observable
  public initialText: string

  @observable
  private textTransform: TTransform

  @observable
  public ariaLabel: string

  @observable
  public smallText?: string

  @computed
  public get text(): string {
    const text = this.smallText && window.innerWidth < windowSizes.sm ? this.smallText : this.initialText

    switch (this.textTransform) {
      case 'Uppercase':
        return text.toUpperCase()
      case 'Lowercase':
        return text.toLowerCase()
      case 'Capitalize':
        return text.replace(/\b\w/g, l => l.toUpperCase())
      default:
        return text
    }
  }

  @observable
  public displayStyle?: TDisplayStyle = 'primary'

  @observable
  public initialLoadingText?: string = ''

  @computed
  public get loadingText(): string {
    switch (this.textTransform) {
      case 'Uppercase':
        return this.initialLoadingText.toUpperCase()
      case 'Lowercase':
        return this.initialLoadingText.toLowerCase()
      case 'Capitalize':
        return this.initialLoadingText.replace(/\b\w/g, l => l.toUpperCase())
      default:
        return this.initialLoadingText
    }
  }

  @observable
  public iconName?: TIcons

  @observable
  public iconPosition?: TIconPosition = 'left'

  @observable
  public isLoading?: boolean = false

  @observable
  public disabled?: boolean = false

  @observable
  public hidden?: boolean = false

  constructor(text: string, onClick: () => void) {
    this.initialText = text
    this.onClick = onClick
  }

  public withDisplayStyle = (displayStyle: TDisplayStyle) => {
    this.displayStyle = displayStyle
    return this
  }

  public withLoadingText = (loadingText: string) => {
    this.initialLoadingText = loadingText
    return this
  }

  public withIconName = (iconName: TIcons) => {
    this.iconName = iconName
    return this
  }

  public withIconPosition = (iconPosition: TIconPosition) => {
    this.iconPosition = iconPosition
    return this
  }

  public withLoading = (isLoading: boolean) => {
    this.isLoading = isLoading
    return this
  }

  public isDisabled? = (disabled: boolean) => {
    this.disabled = disabled
    return this
  }

  public isHidden = (hidden: boolean) => {
    this.hidden = hidden
    return this
  }

  public withTextTransform = (tranform: TTransform) => {
    this.textTransform = tranform
    return this
  }

  public withSmallText = (smallText: string) => {
    this.smallText = smallText
    return this
  }

  @action
  public withAriaLabel = (label: string) => {
    this.ariaLabel = label
    return this
  }
}
