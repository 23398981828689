import { memo } from 'react'
import Markdown from 'markdown-to-jsx'
import { useFormContext } from 'react-hook-form'

import { Box, Message, Text } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { useMergeRunbooksContext } from '../merge-runbooks-modal-provider'
import { ValidateMergeResponse } from 'main/services/queries/validate-merge-query'
import { MergeValidationSummary } from 'main/components/shared/merge-validation-summary'

type StepMergeValidationSummaryProps = {
  errorMessage?: string[] | null
  summary?: ValidateMergeResponse
}

export const StepMergeValidationSummary = memo(({ errorMessage, summary }: StepMergeValidationSummaryProps) => {
  const { mergeType } = useMergeRunbooksContext()
  const { watch } = useFormContext()
  const { t } = useLanguage('runbooks', { keyPrefix: 'mergeModal.validate' })

  const targetType = watch('target_type')
  const targetRunbook = watch('target_runbook')
  const newRunbook = watch('runbook')

  return (
    <>
      {errorMessage?.length && (
        <Box margin={{ bottom: 'medium' }}>
          <Message type="error" message={errorMessage} data-testid="form-error-message" />
        </Box>
      )}
      <Text data-testid="merge-summary-contains-text">
        <Markdown>
          {t('contains', { name: targetType === 'existing' ? targetRunbook?.name : newRunbook?.name })}
        </Markdown>
      </Text>
      <Box>{summary ? <MergeValidationSummary isNewRunbook={mergeType === 'new'} data={summary} /> : null}</Box>
    </>
  )
})
