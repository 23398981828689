import { useEffect, useRef } from 'react'

export const useInitialMount = () => {
  const initialMount = useRef(true)

  useEffect(() => {
    if (initialMount.current) {
      initialMount.current = false
      return
    }
  }, [])

  return initialMount.current
}
