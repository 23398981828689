// @ts-nocheck
import { Icon, TIcons } from 'Components/Atoms'
import styled from 'styled-components'
import { px2rem, colors } from 'app/Styles/Variables'

interface IWrapperProps {
  color: string
  size: number
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props: IWrapperProps) => px2rem(props.size)};
  height: ${(props: IWrapperProps) => px2rem(props.size)};
  box-sizing: border-box;
  border-radius: 50%;
  background-color: ${(props: IWrapperProps) => (props.color ? props.color : colors.primary)};
`

interface IDisplayIconProps {
  name: TIcons
  color?: string
  size?: number
  iconSize?: number
  className?: string
}

export const DisplayIcon = (props: IDisplayIconProps) => {
  const { name, color, size = 40, className } = props
  const iconSize = props.iconSize || Math.round((size * 2) / 3)

  return (
    <Wrapper size={size} className={className} color={color}>
      <Icon name={name} size={px2rem(iconSize)} color={colors.white} />
    </Wrapper>
  )
}
