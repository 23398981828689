import { useRecoilCallback, useRecoilValue } from 'recoil'

import { accountCustomFieldGroupsState, customFieldGroupsLookup } from 'main/recoil/runbook'
import { CustomFieldGroupModelType } from 'main/data-access/models'

/* -------------------------------------------------------------------------- */
/*                                     Get                                    */
/* -------------------------------------------------------------------------- */

export const useGetCustomFieldGroup: CustomFieldGroupModelType['useGet'] = (id: number) => {
  const lookup = useRecoilValue(customFieldGroupsLookup)
  return lookup[id]
}

export const useGetCustomFieldGroupCallback: CustomFieldGroupModelType['useGetCallback'] = () => {
  return useRecoilCallback(({ snapshot }) => async (id: number) => {
    const lookup = await snapshot.getPromise(customFieldGroupsLookup)
    return lookup[id]
  })
}

/* -------------------------------------------------------------------------- */
/*                                   Get All                                  */
/* -------------------------------------------------------------------------- */

export const useGetCustomFieldGroups: CustomFieldGroupModelType['useGetAll'] = () =>
  useRecoilValue(accountCustomFieldGroupsState)

export const useGetCustomFieldGroupsCallback: CustomFieldGroupModelType['useGetAllCallback'] = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      () =>
        snapshot.getPromise(accountCustomFieldGroupsState)
  )

/* -------------------------------------------------------------------------- */
/*                                 Get Lookup                                 */
/* -------------------------------------------------------------------------- */

export const useGetCustomFieldGroupLookup: CustomFieldGroupModelType['useGetLookup'] = () =>
  useRecoilValue(customFieldGroupsLookup) ?? []

export const useGetCustomFieldGroupLookupCallback: CustomFieldGroupModelType['useGetLookupCallback'] = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      () =>
        snapshot.getPromise(accountCustomFieldGroupsState)
  )
