import { useState } from 'react'
import * as yup from 'yup'

import { CheckboxesField, LoadingPanel, Message } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { ApiError } from 'main/services/api'
import { TeamUpdatePayload, useTeam, useUpdateTeam } from 'main/services/queries/use-teams'
import { Account, Team } from 'main/services/queries/types'
import { useAccount } from 'main/services/api/data-providers/account/account-data'
import {
  CheckboxFieldControlled,
  ColorPickerField,
  FormEditPanel,
  TextInputField,
  UserSelectField
} from 'main/components/shared/form'
import { useRightPanelTypeState } from 'main/components/layout/right-panel'

export const CentralTeamEditPanel = () => {
  const [{ teamId }, { closeRightPanel }] = useRightPanelTypeState('central-team-edit')

  if (!teamId) return null

  return <CentralTeamEditPanelWrapper teamId={teamId} onClose={closeRightPanel} />
}

const CentralTeamEditPanelWrapper = ({ teamId, onClose }: { teamId: number; onClose: () => void }) => {
  const { data, isLoading, isError } = useTeam(teamId)
  const { account } = useAccount()

  if (!account || isError || isLoading || !data) return <LoadingPanel />
  const canEdit = !!data.meta.permissions?.['update']?.[0]

  return <CentralTeamEdit team={data.team} canEdit={canEdit} account={account} onClose={onClose} />
}

type CentralTeamEditFormType = yup.InferType<typeof validationSchema>

type CentralTeamEditProps = {
  team: Team
  canEdit: boolean
  onClose: () => void
  account: Account
}

const CentralTeamEdit = ({ team, canEdit, account, onClose }: CentralTeamEditProps) => {
  const { t } = useLanguage('centralTeams', { keyPrefix: 'editPanel' })
  const [isDecentralizing, setIsDecentralizing] = useState<boolean>(false)

  const { mutateAsync } = useUpdateTeam(team.id)

  const handleSubmit = async (data: TeamUpdatePayload) => {
    return mutateAsync(data)
  }

  const handleCloseConfirmModal = () => {
    setIsDecentralizing(false)
  }

  const onChangeDecentralize = (event: React.MouseEvent<HTMLInputElement>) => {
    // NOTE: this is a hack so we can only show the confirm modal on save if this field is unchecked
    const target = event.target as HTMLInputElement
    setIsDecentralizing(!target.checked)
  }

  // One of the error messages needs to be reformatted with its details info
  const formatErrorMessages = (error: ApiError) => {
    const errorStrings = error.errors
    const errorDetails = error.errorDetails

    return errorStrings.map((errorString, i) => {
      const details = errorDetails?.[i]

      // The runbooks key in the details is only present in this type of error...
      if (!details?.meta?.runbooks) return errorString

      // If central team is used on a runbook, and the central team name is changed to a string that is also used on that runbook, show the errors
      let displayError = t('teamNameUniqueInRunbookError')

      // Note: since central teams are specific to current workspace, we dont need to get the account_slug from each runbook
      details.meta.runbooks.forEach((runbook: any) => {
        const link = `#/app/${account.slug}/runbooks/${runbook.id}/current_version/tasks/list`
        displayError += `<br> - <a href="${link}" target="_new">${runbook.name}</a>`
      })

      return displayError
    })
  }

  return (
    <FormEditPanel<CentralTeamEditFormType, TeamUpdatePayload>
      key={team.id}
      title={t('title')}
      defaultValues={team}
      onSubmit={handleSubmit}
      onClose={onClose}
      schema={validationSchema}
      transformer={transformer}
      readOnly={!canEdit}
      disabled={!canEdit}
      formatErrorMessages={formatErrorMessages}
      successMessage={t('successMessage')}
      hasConfirmModal={isDecentralizing}
      onCloseConfirmModal={handleCloseConfirmModal}
      confirmModalTitle={t('decentralizeModal.title')}
      confirmModalDescription={t('decentralizeModal.areYouSure')}
      confirmModalButtonLabel={t('decentralizeModal.submit')}
    >
      <TextInputField<CentralTeamEditFormType> name="name" label={t('fields.name.label')} autoFocus />
      {(team.role_types || []).map((roleType, index) => {
        return (
          <UserSelectField<CentralTeamEditFormType>
            key={`role_types.${roleType.id}`}
            name={`role_types.${index}.users`}
            accountId={account.id}
            defaultValue={roleType.users}
            label={roleType.name}
            helpText={roleType.description || undefined}
            // TODO: fix without hard coding required state https://cutover.atlassian.net/browse/CFE-1578
            required={false}
          />
        )
      })}
      <ColorPickerField<CentralTeamEditFormType> name="color" label={t('fields.color.label')} />
      <CheckboxesField label={t('fields.highlight.header')}>
        <CheckboxFieldControlled<CentralTeamEditFormType>
          name="highlight"
          onClick={onChangeDecentralize}
          label={t('fields.highlight.label')}
        />
      </CheckboxesField>
      <Message message={t('infoMessage')} />
    </FormEditPanel>
  )
}

const transformer = (data: CentralTeamEditFormType): TeamUpdatePayload => {
  const { role_types, ...form } = data

  return {
    team: {
      account_id: data.account_id,
      name: data.name?.trim(),
      highlight: data.highlight,
      color: data.color,
      roles:
        role_types
          ?.map(role =>
            (role.users || []).map(user => ({
              id: user.role_id,
              resource_id: form.id,
              resource_type: 'Team',
              role_type_id: role.id,
              subject_id: user.id,
              subject_type: 'User'
            }))
          )
          .flat() ?? []
    }
  }
}

const validationSchema = yup.object({
  id: yup.number().required(),
  account_id: yup.number().required(),
  name: yup.string().required(),
  highlight: yup.bool().required(),
  color: yup.string().required(),
  role_types: yup.array().of(
    yup.object({
      id: yup.number().required(),
      users: yup.array().of(
        yup.object({
          role_id: yup.number(),
          id: yup.number().required()
        })
      )
    })
  )
})
