import { useMemo, useState } from 'react'
import styled from 'styled-components/macro'

import { LegacyBox as Box, colors, LegacyTable as Table } from '@cutover/react-ui'
import { DashboardMediaType } from '../../../widgets/types'
import { MrdDashboardWidget } from '../mrd-dashboard-widget'

export type RunbookMatrixWidgetProps = {
  media: DashboardMediaType
  data: RunbookMatrixWidgetData
}

export type RunbookMatrixWidgetData = {
  columns: MatrixColumn[]
  errors?: string[]
  filters: string | null
  id: number
  name: string
  notes?: string | null | undefined
  type: string
  width?: number
  values: RunbookMatrixValues[]
}

export type RunbookMatrixValues = {
  id: number
  is_total: boolean
  parent_id?: number
  name: string
  column_data?: any // not using this data
  columns: RunbookMatrixColumn[]
}

export type RunbookMatrixColumn = {
  id: number | string
  complete: number
  total: number
}

type MatrixColumn = {
  id: number | string
  is_total: boolean
  name: string
}

type MapTableData<T extends Record<string, unknown>> = {
  [K in keyof T]: string
}

type ColumnTypes = { [key: string]: string }

type TableColumn = { [key: string]: { label: string; renderCell?: any } }

const FIRST_COLUMN = { id: 'firstColumn', is_total: false, name: 'first' } // row headers

const ROW_DISPLAY_LIMIT = 20

export const RunbookMatrixWidget = ({ data: { name, errors, notes, values, columns } }: RunbookMatrixWidgetProps) => {
  const [showMore] = useState(false)
  const [barsOnDisplay, setBarsOnDisplay] = useState(ROW_DISPLAY_LIMIT) // initial number of rows to display.

  const { updatedColumns, columnTypes, columnMapping } = configureColumns(columns)

  const updatedValues = useMemo(
    () =>
      values.map(value => {
        const { columns, name, parent_id } = value
        const valuesWithPercentage: any = {}
        columns.forEach(({ id, complete, total }) => {
          valuesWithPercentage[columnMapping[id]] = `${toPercentageString(complete, total)}`
        })
        valuesWithPercentage[FIRST_COLUMN.id] = parent_id ? '\u00A0 \u00A0 \u00A0' + name : name
        return valuesWithPercentage
      }),
    [values]
  )

  const handleShowMoreClick = () => {
    setBarsOnDisplay(
      updatedValues.length === barsOnDisplay
        ? updatedValues.length
        : updatedValues.length > barsOnDisplay
        ? barsOnDisplay + ROW_DISPLAY_LIMIT < updatedValues.length
          ? barsOnDisplay + ROW_DISPLAY_LIMIT
          : updatedValues.length
        : updatedValues.length
    )
  }

  const dataToDisplay = useMemo(() => updatedValues.slice(0, barsOnDisplay), [barsOnDisplay, updatedValues.length])

  return (
    <MrdDashboardWidget title={name} notes={notes} errors={errors} fullWidth>
      {dataToDisplay.length > 0 && (
        <MatrixWrapper>
          <Table<MapTableData<typeof columnTypes>> data={dataToDisplay} columns={updatedColumns} />
        </MatrixWrapper>
      )}
      {dataToDisplay.length < updatedValues.length && (
        <ShowMore onClick={handleShowMoreClick} showMore={showMore}>
          Show more...
        </ShowMore>
      )}
    </MrdDashboardWidget>
  )
}

const ShowMore = styled(Box)`
  display: flex;
  justify-content: center;
  color: ${colors.textLight};
  cursor: pointer;
  &:hover {
    color: ${colors.text};
    text-decoration: underline;
  }
`

const configureColumns = (columns: MatrixColumn[]) => {
  const columnsToUpdate = [FIRST_COLUMN, ...columns]
  const columnTypes: ColumnTypes = {}
  const columnMapping: any = {}

  const updatedColumns = columnsToUpdate.reduce<TableColumn>((columns, column) => {
    const camelCaseColumnName = camelize(column.name)
    columns[camelCaseColumnName] = {
      label: column.name,
      renderCell: (cell: any) => {
        const weight = cell.row.original.firstColumn == 'Total' || cell.column.Header == 'Total' ? 'bold' : 'normal'
        return (
          <div
            style={{
              fontWeight: weight
            }}
          >
            {cell.value}
          </div>
        )
      }
    }
    columnTypes[camelCaseColumnName] = 'string'
    columnMapping[column.id] = camelCaseColumnName
    return columns
  }, {})

  updatedColumns[FIRST_COLUMN.id].label = ''

  return { updatedColumns, columnTypes, columnMapping }
}

const camelize = (string: string) => {
  return string
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => (index === 0 ? word.toLowerCase() : word.toUpperCase()))
    .replace(/\s+/g, '')
    .replace(/\./g, '_')
    .concat('Column')
}

const toPercentageString = (num: number, total: number): string =>
  total === 0 ? `0% (${num}/${total})` : `${Math.round((Number(num) / Number(total)) * 100)}% (${num}/${total})`

const MatrixWrapper = styled(Box)``
