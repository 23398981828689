import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Workspace = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Workspace" {...props}>
    <path d="M34 9H14a5 5 0 0 0-5 5v10a5 5 0 0 0 5 5h.998a15.09 15.09 0 0 0-2.837 2.788A8.003 8.003 0 0 1 6 24V14a8 8 0 0 1 8-8h20a8 8 0 0 1 8 8v10a8.003 8.003 0 0 1-6.161 7.788A15.09 15.09 0 0 0 33.002 29H34a5 5 0 0 0 5-5V14a5 5 0 0 0-5-5Z" fill="#000" /><path d="M31 19a7 7 0 1 1-14 0 7 7 0 0 1 14 0Zm-3 0a4 4 0 1 0-8 0 4 4 0 0 0 8 0Zm7.959 21C35.45 33.84 30.29 29 24 29s-11.45 4.84-11.959 11c-.027.33-.041.663-.041 1a2 2 0 0 0 2 2h20a2 2 0 0 0 2-2c0-.337-.014-.67-.041-1Zm-3.014 0h-17.89a9.001 9.001 0 0 1 17.89 0Z" fill="#000" />
  </StyledIcon>
))

Workspace.displayName = 'Workspace'

export { Workspace }
