import { ReactNode, Suspense, useEffect, useRef, useState } from 'react'
import { useUnmount } from 'react-use'

import { useWebsockets } from 'main/services/hooks'
import { LayoutLoading } from 'main/components/layout/layout-loading'
import {
  useRunbookChannelQueueProcessing,
  useRunbookChannelQueueValue
} from 'main/recoil/data-access/updaters__TEMPORARY/use-runbook-channel-response-processor'
import { useRunbookRequestsValue_DANGEROUS, useRunbookUrlParamState } from 'main/recoil/data-access'
import { CurrentUserModel } from 'main/data-access'

export const RunbookChannelSubscriber = ({ children }: { children?: ReactNode }) => {
  const currentUserId = CurrentUserModel.useId()
  const { subscribe, findExistingSubscription } = useWebsockets()
  const { enqueueOrProcess } = useRunbookChannelQueueProcessing()
  const runbookId = useRunbookUrlParamState()

  const websocketRef = useRef<ActionCable.Channel | undefined>()
  const [connected, setConnected] = useState(!!findExistingSubscription('RunbookChannel', runbookId))

  useEffect(() => {
    if (!runbookId) return

    const existingRunbookChannel = findExistingSubscription('RunbookChannel', runbookId)
    if (existingRunbookChannel) return setConnected(true)

    websocketRef.current?.unsubscribe?.()

    websocketRef.current = subscribe('RunbookChannel', runbookId, {
      connected: () => setConnected(true),
      disconnected: () => setConnected(false),
      received: response => {
        const [sameTab, sameUser] = [
          response.meta?.headers?.request_hash === window.sessionStorage.getItem('browserHash'),
          response.meta?.headers?.request_user_id === currentUserId
        ]

        if (sameTab && sameUser) return
        enqueueOrProcess(response)
      }
    })
  }, [runbookId])

  useUnmount(() => {
    websocketRef.current?.unsubscribe?.()
  })

  // IMPORTANT: only render children when certain the ws channel has connected
  return connected ? <>{children}</> : <LayoutLoading filterPanel subHeader rightNav />
}

const RunbookLoadingBoundary = ({ children }: { children: ReactNode }) => {
  const { isLoading } = useRunbookRequestsValue_DANGEROUS() // making requests at this level is safe and intentional

  if (isLoading) return <LayoutLoading filterPanel subHeader rightNav />

  return <Suspense fallback={<LayoutLoading filterPanel subHeader rightNav />}>{children}</Suspense>
}

const RunbookChannelQueueProcessor = ({ children }: { children: ReactNode }) => {
  const { processQueue } = useRunbookChannelQueueProcessing()
  const queue = useRunbookChannelQueueValue()

  useEffect(() => {
    if (queue.length) processQueue()
  }, [queue])

  return <>{children}</>
}

export const RunbookDataRequestsAndChannelSubscriber = ({
  children,
  withWebsockets
}: {
  children: ReactNode
  /** For testing only; should always be true in reality */
  withWebsockets: boolean
}) => {
  // The order of these components is very important!! The subscription to the channel must be connected before
  // any requests to the runbook endpoints are made and the queue processing must only occur when the loading
  // has finished.
  // If the channel isn't connected then messages may be missed while waiting for requests to resolve. A stale version of those
  // objects will then be returned which will lead to a crash if any subsequent messages reference missed data. (eg, task id not
  // existing)
  // If the queue is processed before the requests are resolved, those messages will not be saved in recoil state. Again, will
  // lead to a crash for the same reason.
  return withWebsockets ? (
    <RunbookChannelSubscriber>
      <RunbookLoadingBoundary>
        <RunbookChannelQueueProcessor>{children}</RunbookChannelQueueProcessor>
      </RunbookLoadingBoundary>
    </RunbookChannelSubscriber>
  ) : (
    <RunbookLoadingBoundary>
      <RunbookChannelQueueProcessor>{children}</RunbookChannelQueueProcessor>
    </RunbookLoadingBoundary>
  )
}
