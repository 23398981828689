import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const VolumeLow = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="VolumeLow" {...props}>
    <path d="M22.714 42A1.288 1.288 0 0 0 24 40.714V7.286a1.284 1.284 0 0 0-2.195-.909l-9.909 9.909h-4.61c-.71 0-1.286.576-1.286 1.286v12.857c0 .71.576 1.285 1.286 1.285h4.61l9.91 9.91c.245.245.574.376.908.376ZM13.14 28.714H9v-9.428h4.139L21 11.425v25.15l-7.861-7.86ZM34 24a8.003 8.003 0 0 1-5.337 7.546l-.998-2.83a5.002 5.002 0 0 0 .217-9.35l1.13-2.78A8.003 8.003 0 0 1 34 24Z" fill="#000" />
  </StyledIcon>
))

VolumeLow.displayName = 'VolumeLow'

export { VolumeLow }
