import { generateColor } from '../node-map-utils'
import { PaintShadowEdge } from '../node-map-types'

export const paintShadowEdge = ({ ctx, edge, edgeId, state }: PaintShadowEdge) => {
  const { points } = edge
  const uniqueColor = generateColor(state)

  ctx.strokeStyle = uniqueColor
  ctx.fillStyle = uniqueColor
  ctx.lineWidth = state.units.base / 2
  state.colorToObjMap[uniqueColor] = { type: 'edge', id: edgeId.v + ':' + edgeId.w }

  ctx.beginPath()
  ctx.moveTo(points[0].x, points[0].y)

  for (let pt = 1; pt < points.length - 1; pt++) {
    const point = points[pt]
    const nextPoint = points[pt + 1]
    ctx.arcTo(point.x, point.y, nextPoint.x, nextPoint.y, 10)
  }

  const point = points[points.length - 1]
  ctx.lineTo(point.x, point.y)
  ctx.stroke()
}
