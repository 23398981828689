import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const ColumnAddAfter = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="ColumnAddAfter" {...props}>
    <path d="M45 22.5v3h-6.5V32h-3v-6.5H29v-3h6.5V16h3v6.5H45Z" fill="#000" /><path fillRule="evenodd" clipRule="evenodd" d="M30 6v3h-4.5v30H30v3H14a8 8 0 0 1-8-8V14a8 8 0 0 1 8-8h16Zm-7.5 16.5V9H14a5 5 0 0 0-5 5v8.5h13.5ZM9 25.5V34a5 5 0 0 0 5 5h8.5V25.5H9Z" fill="#000" />
  </StyledIcon>
))

ColumnAddAfter.displayName = 'ColumnAddAfter'

export { ColumnAddAfter }
