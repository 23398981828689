import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { Box } from '@cutover/react-ui'
import { EmailForm } from './email-form'
import { CutoverLogo, PageWrap } from './login-styled'
import { SSOForm } from './sso-form'
import { VerifyActiveSession } from './verify-active-session'
import { useHeap } from 'main/components/support-and-analytics/use-heap'
import { ConfigModel } from 'main/data-access'

export type LoginPageOption = 'email' | 'sso'

export const LoginPage = () => {
  const { initializeAnonymousAnalytics } = useHeap()
  const { oauthActive, oauthAllowEmailLogin, loginWarningMessage } = ConfigModel.useGet()
  const [loginPageOption, setLoginPageOption] = useState<LoginPageOption>(oauthActive ? 'sso' : 'email')
  const [, setUrlSearchParams] = useSearchParams()

  const handleLoginPageOption = (option: LoginPageOption) => {
    setLoginPageOption(option)
    setUrlSearchParams({})
    localStorage.removeItem('resetPasswordSuccess')
  }

  useEffect(() => {
    initializeAnonymousAnalytics()
  }, [])

  return (
    <VerifyActiveSession>
      <PageWrap>
        <CutoverLogo src="/branding/cutover/cutover-logo.png" alt="Cutover" />
        <Box css="min-height: auto; padding-bottom: 30px;">
          {loginPageOption === 'email' && (
            <EmailForm loginWarningMessage={loginWarningMessage} handleLoginPageOption={handleLoginPageOption} />
          )}
          {loginPageOption === 'sso' && (
            <SSOForm
              loginWarningMessage={loginWarningMessage}
              showEmailLink={oauthAllowEmailLogin}
              handleLoginPageOption={handleLoginPageOption}
            />
          )}
        </Box>
      </PageWrap>
    </VerifyActiveSession>
  )
}
