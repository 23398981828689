import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const BrandRehearseTest = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 100 100" a11yTitle="BrandRehearseTest" {...props}>
    <g clipPath="url(#a)" fill="#fff"><circle opacity=".5" cx="14.667" cy="14.667" r="14.667" /><circle opacity=".5" cx="14.667" cy="50" r="14.667" /><circle opacity=".5" cx="14.667" cy="85.333" r="14.667" /><path d="M35.333 0H100v29.333H35.333zm0 35.333h29.333v29.333H35.333zm0 35.334H100V100H35.333z" /></g>
  </StyledIcon>
))

BrandRehearseTest.displayName = 'BrandRehearseTest'

export { BrandRehearseTest }
