export type AvatarSize = keyof typeof SIZE_MAP
export type ItemSize = keyof typeof SIZE_MAP
export type ImageSize = keyof typeof SIZE_MAP

export const SIZE_MAP = {
  large: {
    size: '40px',
    inset: '30px',
    insetOffset: '5px',
    text: '16px',
    icon: '24px',
    linked: {
      size: '20px',
      icon: '18px',
      border: '2px',
      bottom: '-5px',
      right: '-5px'
    },
    online: {
      size: '16px',
      border: '2px'
    },
    inProgress: {
      size: '10px',
      icon: '12px',
      border: '2px',
      top: '29px',
      left: '-1px'
    }
  },
  medium: {
    size: '32px',
    inset: '24px',
    insetOffset: '4px',
    text: '14px',
    icon: '20px',
    linked: {
      size: '18px',
      icon: '16px',
      bottom: '-4px',
      right: '-4px'
    },
    online: {
      size: '12px',
      border: '2px'
    },
    inProgress: {
      size: '8px',
      icon: '10px',
      border: '2px',
      top: '23px',
      left: '0.5px'
    }
  },
  small: {
    size: '24px',
    inset: '18px',
    insetOffset: '3px',
    text: '12px',
    icon: '16px',
    linked: {
      size: '12px',
      icon: '12px',
      border: '2px',
      bottom: '-3px',
      right: '-3px'
    },
    online: {
      size: '9px',
      border: '1px'
    },
    inProgress: {
      size: '6px',
      icon: '8.4px',
      border: '2px',
      top: '18px',
      left: '0px'
    }
  }
}
