import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Team = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Team" {...props}>
    <path d="M33.696 19.206a6 6 0 1 1-10.392-6 6 6 0 0 1 10.392 6Zm-2.598-1.5a3 3 0 1 0-5.196-3 3 3 0 0 0 5.196 3Zm-5.17 18.954a6 6 0 1 0 6-10.392 6 6 0 0 0-6 10.392Zm1.5-2.598a3 3 0 1 1 3-5.196 3 3 0 0 1-3 5.196Zm-7.16-7.598a6 6 0 1 1-10.392-6 6 6 0 0 1 10.392 6Zm-2.598-1.5a3 3 0 1 0-5.196-3 3 3 0 0 0 5.196 3Z" fill="#000" /><path d="M41.32 34C35.798 43.566 23.566 46.843 14 41.32 4.434 35.799 1.157 23.567 6.68 14 12.201 4.434 24.433 1.157 34 6.68c9.566 5.522 12.843 17.754 7.32 27.32Zm-2.598-1.5c4.695-8.131 1.909-18.528-6.222-23.222C24.369 4.583 13.972 7.369 9.277 15.5 4.583 23.631 7.37 34.028 15.5 38.722c8.13 4.695 18.528 1.909 23.222-6.222Z" fill="#000" />
  </StyledIcon>
))

Team.displayName = 'Team'

export { Team }
