import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Refresh = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Refresh" {...props}>
    <path d="m34.748 9.558 3.982-3.983v9.9h-9.9l3.769-3.769c-5.851-4.103-13.978-3.54-19.206 1.687-5.857 5.858-5.857 15.356 0 21.214 5.858 5.858 15.356 5.858 21.214 0a14.988 14.988 0 0 0 4.144-13.339l2.95-.546c1.037 5.627-.621 11.654-4.973 16.006-7.03 7.03-18.427 7.03-25.456 0-7.03-7.03-7.03-18.427 0-25.456C17.674 4.87 27.7 4.3 34.748 9.558Z" fill="#000" />
  </StyledIcon>
))

Refresh.displayName = 'Refresh'

export { Refresh }
