// @ts-nocheck
import 'reflect-metadata'
import { injectable } from 'inversify'
import { IStorageGateway } from './IStorageGateway'
import Cookies, { CookieAttributes } from 'js-cookie'

@injectable()
export class CookieStorageGateway implements IStorageGateway {
  private options: CookieAttributes = { expires: 7, path: '/', secure: true, sameSite: 'strict' }

  public setItem(key: string, value: string) {
    return Cookies.set(key, value, this.options)
  }

  public getItem(key: string) {
    return Cookies.get(key) || null
  }

  public removeItem(key: string) {
    return Cookies.remove(key, this.options)
  }
}
