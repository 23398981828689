import { useContext, useEffect, useRef } from 'react'
import { ResponsiveContext } from 'grommet'

import { getBreakpoint, useTheme } from '../theme'

/**
 * Use instead of using grommet's ResponsiveContext directly. There is a bug currently
 * where it returns the wrong value on initial mount. This hook fixes that.
 */
export const useResponsiveContext = () => {
  const grommetResponsiveContextValue = useContext(ResponsiveContext)
  const theme = useTheme()
  const initialMount = useRef(true)

  useEffect(() => {
    if (initialMount.current) {
      initialMount.current = false
    }
  }, [])

  if (!initialMount.current) {
    return grommetResponsiveContextValue
  } else {
    return getBreakpoint(document.body.clientWidth, theme)
  }
}
