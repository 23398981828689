import { useAccountRunbookTypes } from 'main/services/api/data-providers/account/account-data'
import { useWorkspaceData } from 'main/services/api/data-providers/workspace'
import { useLanguage } from 'main/services/hooks'
import { RunbookListRunbook, RunbookTypeType } from 'main/services/queries/types'

type UseDuplicateRunbooksValidationProps = {
  context: 'single' | 'bulk-single' | 'bulk'
  singleRunbook?: RunbookListRunbook
  runbookIds?: number[] | null
  runbookType?: RunbookTypeType
  templateType?: string
}

export const useDuplicateRunbooksValidation = ({
  context,
  singleRunbook,
  runbookIds,
  runbookType,
  templateType
}: UseDuplicateRunbooksValidationProps) => {
  const { t } = useLanguage('runbooks', { keyPrefix: 'duplicateRunbookModal' })
  const { runbooks, runbookLookup } = useWorkspaceData()
  const { disabledRunbookTypes } = useAccountRunbookTypes()
  const { runbookTypes } = useAccountRunbookTypes()
  const isSnippet = templateType === 'snippet'

  const errorRunbookIds: number[] = []

  const isSingleRunbookTemplateNotApproved =
    context === 'single' && singleRunbook?.is_template && singleRunbook.template_status !== 'template_approved'

  const isBulkRunbooksTemplateNotApproved = () => {
    return (
      context !== 'single' &&
      runbooks?.some(r => runbookIds?.includes(r.id) && r.is_template && r.template_status !== 'template_approved')
    )
  }

  const isSingleRunbookTypeDisabled = context === 'single' && !!runbookType?.disabled

  const isRunbookTypeAllowedToBeDuplicated = () => {
    return runbookIds?.map(runbookId => {
      const runbookTypeId = runbookLookup?.[runbookId]?.runbook_type_id

      if (context === 'single' || !runbookTypeId) {
        return false
      }

      if (
        isRunbookTypeDisabled(runbookTypeId) ||
        (isRunbookTypeRestrictCreateToTemplates(runbookTypeId) && !isTemplate(runbookId))
      ) {
        errorRunbookIds.push(runbookId)
        return true
      }
    })
  }

  const isRunbookTypeDisabled = (runbookTypeId: number) => {
    return disabledRunbookTypes?.some(rbtype => rbtype.id === runbookTypeId)
  }

  const isRunbookTypeRestrictCreateToTemplates = (runbookTypeId: number) => {
    const restrictCreateToTemplatesRunbookType = runbookTypes?.find(rbt => rbt.restrict_create_to_templates)

    return restrictCreateToTemplatesRunbookType?.id === runbookTypeId || false
  }

  const isTemplate = (runbookId: number) => {
    return runbookLookup?.[runbookId].is_template
  }

  const handleErrorIdsToString = () => {
    return errorRunbookIds.map(id => '#' + id).join(', ')
  }

  const handleErrors = () => {
    return [
      context !== 'single' && runbookIds && runbookIds.length > 100 && t('runbookLimitExceededError'),
      !isSnippet &&
        (isSingleRunbookTypeDisabled || (isRunbookTypeAllowedToBeDuplicated() && errorRunbookIds.length)) &&
        t('disabledRunbookTypeError', { runbookids: handleErrorIdsToString() })
    ].filter(Boolean) as string[]
  }

  const errorMessage = handleErrors()

  const warningMessage =
    !errorMessage.length && (isSingleRunbookTemplateNotApproved || isBulkRunbooksTemplateNotApproved())
      ? [t('unapprovedTemplateWarning')]
      : undefined

  return {
    errorMessage,
    warningMessage
  }
}
