import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, FormProvider, useForm } from 'react-hook-form'

import { Box, CodeEditor, colors, IconName, Modal, RadioboxGroup, Text, TextInput, useNotify } from '@cutover/react-ui'
import { SamlConfigurationFormType } from '../right-panels/saml-configurations-edit-panel'
import { samlConfigFormValidationSchema } from '../saml-config-form-validation-schema'
import { SamlConfigurationForm } from '../saml-configuration-form'
import { queryClient } from 'main/query-client'
import { ApiError } from 'main/services/api/http-gateway-adapter'
import { useLanguage } from 'main/services/hooks'
import { SamlConfiguration } from 'main/services/queries/types'
import {
  useCreateSamlConfigurationsMutation,
  useParseMetadataMutation
} from 'main/services/queries/use-saml-configurations-query'

type CreateNewSamlConfigModalType = {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

export type SamlConfigurationMetadataFormData = {
  rawText?: string
  url?: string
  files?: FileList | never[]
}

export type SamlConfigurationFormDataType = SamlConfigurationFormType &
  SamlConfigurationMetadataFormData & { _step?: number }

export const CreateNewSamlConfigModal = ({ open, setOpen }: CreateNewSamlConfigModalType) => {
  const notify = useNotify()
  const { t } = useLanguage('samlConfigurations')
  const [apiError, setApiError] = useState<string | undefined>(undefined)
  const [showSources, setShowSources] = useState<boolean>(true)
  const [selectedSource, setSelectedSource] = useState<string>('manualEntry')
  const [confirmText, setConfirmText] = useState<string>('Save')
  const [confirmIcon, setConfirmIcon] = useState<string>('add')
  const createMutation = useCreateSamlConfigurationsMutation()
  const parseMetadataMutation = useParseMetadataMutation()

  const sourcesOptions = [
    {
      value: 'manualEntry',
      label: t('newSamlConfiguration.sources.manualEntry'),
      autoFocus: true
    },
    {
      value: 'xmlUpload',
      label: t('newSamlConfiguration.sources.xmlUpload')
    },
    {
      value: 'url',
      label: t('newSamlConfiguration.sources.url')
    },
    {
      value: 'rawText',
      label: t('newSamlConfiguration.sources.rawText')
    }
  ]

  const defaultNewConfigValues = {
    _step: 1,
    custom_acs_url: '',
    allowed_clock_drift: 30,
    default: false,
    id: undefined,
    idp_cert_multi: { signing: [] },
    idp_sso_service_url: '',
    hidden: false,
    sp_entity_id: '',
    name: '',
    options: { active_roles: false, purge_roles: false },
    attribute_mappings: {},
    certificates_with_metadata: { signing: [], meta_data: [] },
    certificate: undefined
  } as unknown as SamlConfigurationFormDataType

  const [samlConfiguration, setSamlConfiguration] = useState<SamlConfigurationFormDataType>(defaultNewConfigValues)

  const validationSchema = samlConfigFormValidationSchema(t, undefined, selectedSource)

  const methods = useForm<SamlConfigurationFormDataType>({
    defaultValues: defaultNewConfigValues,
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    reValidateMode: 'onChange'
  })

  const {
    handleSubmit,
    control,
    getValues,
    register,
    reset,
    clearErrors,
    formState: { isLoading }
  } = methods

  const handleFormSubmission = (data: SamlConfigurationFormDataType) => {
    if (selectedSource === 'manualEntry') {
      onSubmitCreate(data)
    } else {
      onSubmitParseMetadata()
    }
  }

  const onSubmitCreate = (data: SamlConfiguration) => {
    createMutation.mutate(data, {
      onSuccess: () => {
        setOpen(false)
        setApiError(undefined)
        notify.success(t('newSamlConfiguration.toasters.success'))
        queryClient.invalidateQueries(['saml-configurations'])
        closeNewSamlConfigForm()
      },
      onError: (error: any) => {
        const castedError = error as ApiError
        if (castedError?.errors) {
          setApiError(castedError?.errors.join(', '))
        }
        notify.error(t('newSamlConfiguration.toasters.genericError'))
      }
    })
  }

  const onSubmitParseMetadata = () => {
    const data = getValues()
    const formData = new FormData()

    if (data.files && data.files.length > 0) {
      formData.append('metadata_file', data.files ? data.files[0] : '')
    } else if (data.rawText) {
      formData.append('metadata_text', data.rawText)
    } else if (data.url) {
      formData.append('remote_url', data.url)
    }

    parseMetadataMutation.mutate(formData, {
      onSuccess: (result: any) => {
        setShowSources(false)
        setSelectedSource('manualEntry')
        samlConfiguration.idp_sso_service_url = result.idp_sso_service_url
        samlConfiguration.idp_cert_multi.signing = result.idp_cert_multi.signing
        samlConfiguration.sp_entity_id = result.idp_entity_id
        setSamlConfiguration(samlConfiguration)
        notify.success(t('newSamlConfiguration.toasters.parsedMetadata.success'))
      },
      onError: error => {
        setShowSources(true)
        const castedError = error as ApiError
        if (castedError?.errors?.length > 0) {
          setApiError(castedError?.errors.join(', '))
        } else {
          setApiError(t('newSamlConfiguration.toasters.parsedMetadata.failure'))
        }
        notify.error(t('newSamlConfiguration.toasters.parsedMetadata.failure'))
      }
    })
  }

  const closeNewSamlConfigForm = () => {
    setOpen(false)
    setShowSources(true)
    setSamlConfiguration(defaultNewConfigValues)
    clearErrors()
    reset()
  }

  useEffect(() => {
    clearErrors()
    setConfirmText(selectedSource === 'manualEntry' ? 'Save' : 'Next')
    setConfirmIcon(selectedSource === 'manualEntry' ? 'add' : 'chevron-right')
  }, [selectedSource])

  const renderSwitch = () => {
    switch (selectedSource) {
      case 'manualEntry':
        return <SamlConfigurationForm samlConfiguration={samlConfiguration} apiError={apiError} />
      case 'xmlUpload':
        return (
          <Box>
            <input
              type="file"
              accept=".xml"
              css={`
                margin-top: 20px;
                margin-bottom: 14px;
              `}
              data-testid={'saml-config-metadata-parsing-input'}
              {...register('files')}
            />
          </Box>
        )
      case 'url':
        return <TextInput label={t('newSamlConfiguration.sources.url')} {...register('url')} />
      case 'rawText':
        return (
          <Box direction="column">
            <Text
              as="h2"
              css={`
                font-size: 13px;
                color: ${colors.textLight};
              `}
            >
              {t('newSamlConfiguration.sources.rawText')}
            </Text>
            <Controller
              name={'rawText'}
              control={control}
              defaultValue={''}
              render={({ field: { onChange, value } }) => (
                <CodeEditor
                  onChange={value => onChange(value)}
                  value={value as string | undefined}
                  defaultLanguage="xml"
                  resize="vertical"
                />
              )}
            />
          </Box>
        )
      default:
        return ''
    }
  }

  return (
    <Modal
      title={t('newSamlConfiguration.title')}
      open={open}
      onClose={closeNewSamlConfigForm}
      loadingText={t('common:savingText')}
      confirmText={confirmText}
      confirmIcon={confirmIcon as IconName}
      onClickConfirm={handleSubmit(handleFormSubmission)}
      onClickBack={
        !showSources
          ? () => {
              reset(defaultNewConfigValues)
              setShowSources(true)
            }
          : undefined
      }
      loading={isLoading}
    >
      <FormProvider {...methods}>
        <Box>
          {showSources && (
            <RadioboxGroup
              direction="row"
              name={'saml-configuration-sources'}
              options={sourcesOptions}
              value={selectedSource}
              label={t('newSamlConfiguration.sources.label')}
              onChange={event => setSelectedSource(event.target.value)}
              required
            />
          )}
          {renderSwitch()}
        </Box>
      </FormProvider>
    </Modal>
  )
}
