import { atom, AtomOptions } from 'recoil'

import { getRunbookId, getRunbookVersionId } from './nav-utils'
import { CustomNavigationEventDetail } from './recoil-sync/navigation-event-emitter'

const resetAtomOnUrlChange = ({ resetSelf }: { resetSelf: () => void }) => {
  const handlePathChange = (event: CustomEvent<CustomNavigationEventDetail>) => {
    const { pathname, previousPathname } = event.detail
    const previousRunbookVersionId = getRunbookVersionId(previousPathname)
    const runbookVersionId = getRunbookVersionId(pathname) as string
    const previousRunbookId = getRunbookId(previousPathname)
    const runbookId = getRunbookId(pathname) as string

    if (previousRunbookVersionId !== runbookVersionId || previousRunbookId !== runbookId) resetSelf()
  }
  window.addEventListener('pathnamechanged', handlePathChange as any)

  return () => {
    window.removeEventListener('pathnamechanged', handlePathChange as any)
  }
}

export const runbookAtom = <T>({ resetOnUrlChange = true, ...args }: AtomOptions<T> & { resetOnUrlChange?: boolean }) =>
  atom({
    ...args,
    effects: [...(resetOnUrlChange ? [resetAtomOnUrlChange] : []), ...(args.effects ? args.effects : [])]
  })
