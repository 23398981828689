import { memo, useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { AccordionPanel } from '@cutover/react-ui'
import { RunbookEditFormType } from './runbook-edit-form'
import { UserSelect } from '../../form/user-select'
import { useLanguage } from 'main/services/hooks'
import { RunbookEditRunbook } from 'main/services/queries/types'

export const RunbookEditRolesPanel = memo(
  ({
    runbook,
    accountId,
    disabled,
    readOnly
  }: {
    runbook: RunbookEditRunbook
    accountId: number
    disabled?: boolean
    readOnly?: boolean
  }) => {
    const { t } = useLanguage('runbooks', { keyPrefix: 'fields' })
    const { control, watch, setError, clearErrors, formState } = useFormContext<RunbookEditFormType>()

    const roleTypes = watch('runbook.role_types')
    const adminUserCount = roleTypes?.find(roleType => roleType.key === 'runbook-admin')?.users?.length ?? 0
    const hasAdminUsers = adminUserCount > 0

    useEffect(() => {
      if (!hasAdminUsers) {
        setError('runbook.role_types', {
          type: 'required',
          // TODO: how to get it to not say 'runbook.roles field must have at least 1 items' and just
          message: 'Must have at least 1 admin'
        })
      } else {
        clearErrors('runbook.role_types')
      }
    }, [clearErrors, hasAdminUsers, setError])

    return (
      <AccordionPanel
        label={t('roles.label')}
        icon="user-roles"
        iconColor={runbook.project.color}
        // are panels supposed to all have errors?
        error={!!formState.errors.runbook?.role_types}
      >
        <>
          {runbook.role_types.map((roleType, index) => {
            return (
              <Controller
                key={`role_types.${roleType.id}`}
                name={`runbook.role_types.${index}.users`}
                control={control}
                render={({ field: { onChange, value, onBlur, ref } }) => {
                  return (
                    <UserSelect
                      readOnly={readOnly} // readOnly doesn't mean anything here if it is also disabled?
                      hasError={roleType.key === 'runbook-admin' ? !!formState.errors.runbook?.role_types : undefined}
                      inlineError={
                        roleType.key === 'runbook-admin' ? formState.errors.runbook?.role_types?.message : undefined
                      }
                      disabled={disabled}
                      minChars={1}
                      accountId={accountId}
                      defaultValue={roleType.users}
                      value={value || []}
                      inputRef={ref}
                      onBlur={onBlur}
                      label={roleType.name}
                      onChange={onChange}
                      helpText={roleType.description || undefined}
                      // TODO: fix without hard coding required if possible state https://cutover.atlassian.net/browse/CFE-1578
                      required={roleType.key === 'runbook-admin'}
                    />
                  )
                }}
              />
            )
          })}
        </>
      </AccordionPanel>
    )
  }
)
