import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const BrandTelco = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 100 100" a11yTitle="BrandTelco" {...props}>
    <g clipPath="url(#a)" fill="#fff"><path d="M85.355 85.355a50 50 0 1 0-70.71 0L35.39 64.61a20.66 20.66 0 1 1 29.218 0l20.746 20.746Z" /><circle opacity=".5" cx="50" cy="50" r="14.667" /></g>
  </StyledIcon>
))

BrandTelco.displayName = 'BrandTelco'

export { BrandTelco }
