import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const TaskDeleted = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="TaskDeleted" {...props}>
    <path d="m31.071 19.05-2.121-2.12L24 21.879l-4.95-4.95-2.121 2.121 4.95 4.95-4.95 4.95 2.121 2.121 4.95-4.95 4.95 4.95 2.121-2.121-4.95-4.95 4.95-4.95Z" fill="#000" /><path d="M24 6c9.941 0 18 8.059 18 18s-8.059 18-18 18S6 33.941 6 24 14.059 6 24 6ZM9 24c0 8.284 6.716 15 15 15 8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15-8.284 0-15 6.716-15 15Z" fill="#000" />
  </StyledIcon>
))

TaskDeleted.displayName = 'TaskDeleted'

export { TaskDeleted }
