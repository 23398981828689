import styled from 'styled-components'

import { Box, Text, themeColor } from '@cutover/react-ui'

export const PageWrap = styled(Box)`
  position: absolute;
  align-items: center;
  background-color: ${themeColor('nav-bg')};
  bottom: 0;
  display: flex;
  flex-direction: column;
  left: 0;
  overflow-y: auto;
  right: 0;
  top: 0;
  padding-top: 30px;
`

export const LoggedOutHeading = styled(Text).attrs({ as: 'h1' })`
  color: ${themeColor('text-on-primary')};
  margin: 12px 0px 24px;
  text-align: center;
  font-size: 24px;
`

export const CutoverLogo = styled.img`
  height: 72px;
  margin-bottom: 4px;
`

export const FooterLinkText = styled.span`
  color: ${themeColor('nav-item-text')};
  opacity: 0.76;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
`
