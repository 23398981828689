// @ts-nocheck
import { injectable } from 'inversify'
import { observable, computed } from 'mobx'
import { container } from 'app/Config/IOC'
import { FilterDisclosurePanelPresenter } from 'Components/Molecules'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'
import { Types } from 'Gateways/Service/Types'
import { CustomFieldsViewPresenter } from 'app/Settings/CustomFields/CustomFieldsPage/CustomFieldsViewPresenter'

@injectable()
export class CustomFieldFilterPresenter {
  public language: ILanguageService = container.get(Types.ILanguageService)
  public viewPresenter: CustomFieldsViewPresenter = container.get(CustomFieldsViewPresenter)

  @observable
  public visibilityFiltersDisclosurePanel = new FilterDisclosurePanelPresenter(
    this.language.get('customFields:filters:visibility:title'),
    this.language.get('customFields:filters:visibility:title')
  ).withCollapsed(false)

  @observable
  public fieldTypeFiltersDisclosurePanel = new FilterDisclosurePanelPresenter(
    this.language.get('customFields:filters:fieldType:title'),
    this.language.get('customFields:filters:fieldType:title')
  )

  @observable
  public contextFiltersDisclosurePanel = new FilterDisclosurePanelPresenter(
    this.language.get('customFields:filters:context:title'),
    this.language.get('customFields:filters:context:title')
  )

  @observable
  public groupsFiltersDisclosurePanel = new FilterDisclosurePanelPresenter(
    this.language.get('customFields:filters:groups:title'),
    this.language.get('customFields:filters:groups:title')
  )

  @observable
  public otherFiltersDisclosurePanel = new FilterDisclosurePanelPresenter(
    this.language.get('customFields:filters:other:title'),
    this.language.get('customFields:filters:other:title')
  )

  @computed
  public get displayFilterPanels(): boolean {
    if (!this.viewPresenter.filterVM) return false

    return this.viewPresenter.filterVM.length > 0
  }
}
