import { ForwardedRef, forwardRef, ReactNode, RefObject, useCallback, useEffect, useState } from 'react'
import { DeepPartial, FieldValues } from 'react-hook-form'

import {
  Box,
  Button,
  Form as FormElement,
  Message,
  Modal,
  RightPanel,
  SaveEditingFormModal,
  Text,
  useNotify
} from '@cutover/react-ui'
import { Form, FormProps, FormRenderProps, FormType } from './form'
import { useLanguage } from 'main/services/hooks'

export type FormEditPanelProps<
  TFieldValues extends FieldValues,
  TApiValues extends Record<string, any> = TFieldValues
> = Omit<
  FormProps<TFieldValues, TApiValues> & {
    headerItems?: ReactNode[]
    footer?: ReactNode
    title: string
    hasConfirmModal?: boolean
    onCloseConfirmModal?: () => void
    confirmModalTitle?: string
    confirmModalDescription?: string
    confirmModalButtonLabel?: string
    onBack?: () => void
    onClose: () => void
  },
  'children'
> & {
  children?: ReactNode | ((props: FormRenderProps<TFieldValues>) => ReactNode)
  loading?: boolean
}

const FormEditPanelComponent = <
  TFieldValues extends FieldValues,
  TApiValues extends Record<string, any> = TFieldValues
>(
  {
    children,
    title,
    hasConfirmModal,
    onCloseConfirmModal: onCancelConfirmModal,
    confirmModalTitle,
    confirmModalDescription,
    confirmModalButtonLabel,
    onBack,
    onClose,
    headerItems = [],
    footer,
    defaultValues,
    loading,
    formElementWrapper = true,
    ...formProps
  }: FormEditPanelProps<TFieldValues, TApiValues>,
  ref?: ForwardedRef<FormType<TFieldValues>>
) => {
  const { t } = useLanguage('common')
  const [isLoading, setIsLoading] = useState(loading === undefined ? typeof defaultValues === 'function' : loading)
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false)

  const notify = useNotify()

  const formDefaultValues = useCallback(async () => {
    if (typeof defaultValues !== 'function') {
      return Promise.resolve(defaultValues)
    } else {
      try {
        const values = await (defaultValues as () => Promise<TFieldValues>)()
        setIsLoading(false)
        return values
      } catch (e) {
        notify.error(`${t('defaultApiError')} ${e}`)
      }
    }
  }, [defaultValues])

  useEffect(() => {
    if (loading !== undefined) {
      setIsLoading(loading)
    }
  }, [loading])

  return (
    <Form
      ref={ref as RefObject<FormType<TFieldValues>>}
      {...formProps}
      defaultValues={formDefaultValues as DeepPartial<TFieldValues>}
      formElementWrapper={false}
    >
      {renderProps => {
        const {
          onSubmit,
          handleSubmit,
          formState: { isSubmitting, errors, dirtyFields },
          errorMessage,
          reset
        } = renderProps

        const isDirty = Object.keys(dirtyFields).length > 0
        const content = !isLoading && (typeof children === 'function' ? children(renderProps) : children)

        const handleCloseConfirmModal = () => {
          setIsOpenConfirmModal(false)
          reset()
          onCancelConfirmModal?.()
        }

        const handleContinueConfirmModal = () => {
          handleSubmit(onSubmit)()
          setIsOpenConfirmModal(false)
        }

        const submitForm = () => {
          if (hasConfirmModal) {
            setIsOpenConfirmModal(true)
          } else {
            handleSubmit(onSubmit)()
          }
        }

        return (
          <SaveEditingFormModal
            isDirty={isDirty}
            isError={!!errors?.length}
            onReset={() => reset()}
            isSubmitting={isSubmitting}
            onSubmit={submitForm}
            render={({ ref }) => {
              return (
                <>
                  <RightPanel
                    ref={ref}
                    title={title}
                    onBack={onBack}
                    onClose={!isSubmitting && !isDirty ? onClose : undefined}
                    loading={isLoading}
                    headerItems={
                      isDirty
                        ? [
                            <ResetButton
                              isSubmitting={isSubmitting}
                              isDirty={isDirty}
                              onClick={() => reset()}
                              label={t('resetButton')}
                            />,
                            <SaveButton
                              submittingText={t('savingText')}
                              onClick={() => submitForm()}
                              isSubmitting={isSubmitting}
                              isDirty={isDirty}
                              label={t('saveButton')}
                            />
                          ]
                        : headerItems
                    }
                    footer={footer}
                  >
                    <Box gap="medium">
                      <Message type="error" message={errorMessage} data-testid="form-edit-panel-error-message" />
                      {formElementWrapper ? (
                        <FormElement onSubmit={e => e.preventDefault()}>{content}</FormElement>
                      ) : (
                        content
                      )}
                    </Box>
                  </RightPanel>
                  {isOpenConfirmModal && (
                    <ConfirmModal
                      onClickConfirm={handleContinueConfirmModal}
                      onClickClose={handleCloseConfirmModal}
                      title={confirmModalTitle ?? t('confirmModal.title')}
                      description={confirmModalDescription ?? t('confirmModal.description')}
                      buttonLabel={confirmModalButtonLabel ?? t('confirmModal.submitButtonLabel')}
                    />
                  )}
                </>
              )
            }}
          />
        )
      }}
    </Form>
  )
}

const ResetButton = ({
  onClick,
  isDirty,
  isSubmitting,
  label
}: {
  onClick: any
  isDirty: boolean
  isSubmitting: boolean
  label: string
}) => {
  return <>{isDirty && !isSubmitting && <Button tertiary disabled={isSubmitting} label={label} onClick={onClick} />}</>
}

const SaveButton = ({
  submittingText,
  onClick,
  isSubmitting,
  isDirty,
  label
}: {
  submittingText: string
  isSubmitting?: boolean
  isDirty?: boolean
  onClick?: any
  label: string
}) => {
  return (
    <>
      {isSubmitting ? (
        <Text size="18px" color="text-light">
          {submittingText}
        </Text>
      ) : isDirty ? (
        <Button primary disabled={isSubmitting} onClick={onClick} label={label} />
      ) : null}
    </>
  )
}

type ConfirmModalProps = {
  onClickConfirm: () => void
  onClickClose: () => void
  title: string
  description: string
  buttonLabel: string
}

const ConfirmModal = ({ onClickConfirm, onClickClose, title, description, buttonLabel }: ConfirmModalProps) => {
  return (
    <Modal
      open
      title={title}
      confirmText={buttonLabel}
      confirmIcon="arrow-forward"
      onClickConfirm={onClickConfirm}
      onClose={onClickClose}
    >
      <Text>{description}</Text>
    </Modal>
  )
}

export const FormEditPanel = forwardRef(FormEditPanelComponent) as typeof FormEditPanelComponent
