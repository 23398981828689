// @ts-nocheck
import { observable, action, computed } from 'mobx'
import { container } from 'app/Config/IOC'
import { v4 } from 'uuid'
import { Types } from 'Gateways/Service/Types'
import { DragDropPresenter, DraggablePresenter } from 'Components/Atoms'
import { TFieldOptionTypes } from './TFieldOptionTypes'
import { IColor, TextInputPresenter } from 'Components/Molecules'
import { OptionDraggable } from './OptionDraggable'
import { defaultOptionColor, OptionPresenter } from '.'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'

export class FieldOptionsPresenter {
  @observable
  public disabled: boolean = false

  @observable
  public draggableOptions: { [k: string]: any }[] = []

  @observable
  public dragDropPresenter = new DragDropPresenter().withOnDragEndCall((orderedIds: string[]) => {
    this.updateDraggableOptionArray(this.getDraggableOptionsForOrderIds(orderedIds))

    if (this.dirtyCallback) {
      this.dirtyCallback()
    }
  })

  protected getDraggableOptionsForOrderIds = (orderedIds: string[]): { [k: string]: any }[] => {
    const draggableOptions = []
    orderedIds.map(orderedId => {
      draggableOptions.push(this.draggableOptions.find(obj => obj.draggable.id === orderedId))
    })

    return draggableOptions
  }

  public language: ILanguageService = container.get(Types.ILanguageService)

  @observable
  private label = this.language.get('common:organisms:fieldOptions:label')

  @observable
  private placeholder = this.language.get('common:organisms:fieldOptions:placeholder')

  @observable
  private optionChangeCallback: () => void

  @observable
  protected dirtyCallback: () => void

  @action
  private updateDraggableOptionArray = (newArr: Record<string, any>[]) => {
    this.draggableOptions.length = 0
    this.draggableOptions = newArr
    if (this.optionChangeCallback) {
      this.optionChangeCallback()
    }
  }

  protected createDraggablePresenter = (option: TFieldOptionTypes): DraggablePresenter => {
    return new DraggablePresenter()
      .withId(v4())
      .withDraggableConfig({
        presenter: new OptionPresenter(this.language, option.color ? option.color : defaultOptionColor)
          .withGuid(option.guid)
          .withFieldOptionsPresenter(this)
          .withDisabled(this.disabled)
          .withRestorable(option.id ? true : false)
          .withColor(option.color ? option.color : defaultOptionColor)
          .withExcludeFromConflicts(option.excludeFromConflicts)
          .withDirtyCallback(this.dirtyCallback)
      })
      .withGetContent(OptionDraggable)
  }

  @action
  public createDraggables(options: TFieldOptionTypes[], fromLoad: boolean = false) {
    const draggableOptions = [...this.draggableOptions]
    options.map(option => {
      draggableOptions.push({
        draggable: this.createDraggablePresenter(option),
        option
      })
    })
    this.updateDraggableOptionArray(draggableOptions)

    if (!fromLoad && this.dirtyCallback) {
      this.dirtyCallback()
    }
  }

  @action
  public createDraggable(option: TFieldOptionTypes) {
    const draggableOptions = [...this.draggableOptions]
    draggableOptions.push({
      draggable: this.createDraggablePresenter(option),
      option
    })
    this.updateDraggableOptionArray(draggableOptions)

    if (this.dirtyCallback) {
      this.dirtyCallback()
    }
  }

  @computed
  public get draggables(): DraggablePresenter[] {
    const draggables = []
    this.draggableOptions.map(opt => {
      draggables.push(opt.draggable)
    })
    return draggables
  }

  public getOptions = (): TFieldOptionTypes[] => {
    const options = []
    this.draggableOptions.map(opt => {
      options.push(opt.option)
    })
    return options
  }

  public findOption = (guid: string) => {
    const found = this.draggableOptions.find(obj => obj.option.guid === guid)
    if (found) return found.option || null
    return null
  }

  public withOptions? = (options: TFieldOptionTypes[]) => {
    this.createDraggables(options, true)
    return this
  }

  public withOption? = (guid: string, value: string) => {
    const newOption = {
      guid,
      value,
      color: defaultOptionColor,
      showDetails: false
    }
    this.createDraggable(newOption)

    return this
  }

  @action
  public withOptionChangeCallback = (optionChangeCallback: () => void) => {
    this.optionChangeCallback = optionChangeCallback
    return this
  }

  @action
  public withDirtyCallback = (dirtyCallback: () => void) => {
    this.dirtyCallback = dirtyCallback
    return this
  }

  @action
  public withLabel = (label: string) => {
    this.label = label
    this.textPresenter.withLabel(label)
    return this
  }

  @action
  public withPlaceholder = (placeholder: string) => {
    this.placeholder = placeholder
    this.textPresenter.withPlaceholder(placeholder)
    return this
  }

  @action
  public withDisabled = (disabled: boolean) => {
    this.disabled = disabled
    return this
  }

  @action
  public removeOption? = (guid: string) => {
    const newOptions = this.draggableOptions.filter(obj => {
      return obj.option.guid !== guid
    })
    this.draggableOptions.length = 0
    this.draggableOptions = newOptions
    this.triggerChangedCallbacks()
    return this
  }

  public updateOptionValue = (guid: string, value: string) => {
    const opt = this.findOption(guid)
    if (opt) opt.value = value
    this.triggerChangedCallbacks()
  }

  @action
  public toggleArchiveOption = (guid: string) => {
    const opt = this.findOption(guid)
    if (opt) opt.archived = !opt.archived ? true : !opt.archived
    this.triggerChangedCallbacks()
  }

  private triggerChangedCallbacks = () => {
    if (this.optionChangeCallback) {
      this.optionChangeCallback()
    }

    if (this.dirtyCallback) {
      this.dirtyCallback()
    }
  }

  @action
  public setExcludeFromConflicts = (guid: string, value: boolean) => {
    const opt = this.findOption(guid)
    if (opt) opt.excludeFromConflicts = value
  }

  @action
  public setColor = (guid: string, value: IColor) => {
    const opt = this.findOption(guid)
    if (opt) opt.color = value
  }

  public textPresenter = new TextInputPresenter()
    .withIconPrefix('add-to-list')
    .withLabel(this.label)
    .withPlaceholder(this.placeholder)

  @action
  public reset = () => {
    this.draggableOptions = []
    this.textPresenter.reset()
  }

  @computed
  public get showOptionLabel(): boolean {
    return this.draggableOptions.length > 0
  }
}
