import { useState } from 'react'
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'

import { DateTimePicker } from '@cutover/react-ui'
import { useComponentPropsAndState } from '../apps-state'
import { AppComponentNodeProps } from '../apps-types'
import { useAppsTimezoneValue } from 'main/recoil/data-access'

type DateTimeFieldNodeProps = AppComponentNodeProps & {
  label: string
  name: string
  value?: string
  error?: boolean
  max?: string
  min?: string
}

export const DateTimeFieldNode = ({ appId, resourceId, id, ...props }: DateTimeFieldNodeProps) => {
  const { componentProps, setState } = useComponentPropsAndState(appId, resourceId, id, props)
  const { label, name, error, max, min, value: initialValue } = componentProps as DateTimeFieldNodeProps
  const timezone = useAppsTimezoneValue()
  const parseTimeString = (timeString?: string) => (timeString ? utcToZonedTime(new Date(timeString), timezone) : null)
  const [value, setValue] = useState<Date | null>(parseTimeString(initialValue))

  const handleValueChange = (value: Date | null) => {
    setValue(value)
    setState({ name, value: value ? zonedTimeToUtc(value, timezone).toISOString() : '' })
  }

  return (
    <DateTimePicker
      value={value}
      label={label ?? ''}
      onChange={value => handleValueChange(value)}
      fixed
      fixedWidth
      hasError={error}
      maxDate={parseTimeString(max) ?? undefined}
      minDate={parseTimeString(min) ?? undefined}
    />
  )
}
