import { useMemo } from 'react'
import { differenceInMilliseconds } from 'date-fns'

import { TaskListTask } from 'main/services/queries/types'

export const useTaskCompletionSummaryData = (tasks: TaskListTask[]) => {
  return useMemo(() => {
    return computeTaskCompletionSummaryData(tasks)
  }, [tasks])
}

// For SSR components (in emails), we cannot use react hooks like useMemo. To take advantage of useMemo
// for browser rendered code we separate this out so that we can use it directly as well as
// part of the hook above.
export const computeTaskCompletionSummaryData = (tasks: TaskListTask[]) => {
  const earliestStartPlanned = Math.min(...filterOutNull(tasks.map(t => t.start_latest_planned)))
  const earliestStartDisplay = Math.min(...filterOutNull(tasks.map(t => t.start_display)))
  const startDiffAmount = differenceInMilliseconds(earliestStartPlanned, earliestStartDisplay)
  const startDiff = {
    value: Math.abs(startDiffAmount),
    early: earliestStartDisplay < earliestStartPlanned
  }

  const latestEndDisplay = Math.max(...filterOutNull(tasks.map(t => t.end_display)))
  const latestEndPlanned = Math.max(...filterOutNull(latestEnds(tasks)))
  const endDiffAmount = differenceInMilliseconds(latestEndPlanned, latestEndDisplay)
  const endDiff = {
    value: Math.abs(endDiffAmount),
    early: latestEndDisplay < latestEndPlanned
  }

  const durationDisplay = differenceInMilliseconds(latestEndDisplay, earliestStartDisplay)
  const durationPlanned = differenceInMilliseconds(latestEndPlanned, earliestStartPlanned)
  const durationDiffAmount = durationDisplay - durationPlanned
  const durationDiff = {
    value: Math.abs(durationDiffAmount),
    early: durationDiffAmount < 0
  }

  // Math.min/max returns Infinity/-Infinity if given an empty list of numbers to compare.
  return {
    start: {
      planned: earliestStartPlanned === Infinity ? undefined : earliestStartPlanned,
      display: earliestStartDisplay === Infinity ? undefined : earliestStartDisplay,
      diff: earliestStartPlanned !== Infinity && earliestStartDisplay !== Infinity ? startDiff : undefined
    },
    end: {
      planned: latestEndPlanned === -Infinity ? undefined : latestEndPlanned,
      display: latestEndDisplay === -Infinity ? undefined : latestEndDisplay,
      diff: latestEndPlanned !== -Infinity && latestEndDisplay !== -Infinity ? endDiff : undefined
    },
    duration: {
      planned: isNaN(durationPlanned) ? undefined : durationPlanned,
      display: isNaN(durationDisplay) ? undefined : durationDisplay,
      diff: isNaN(durationDiff.value) ? undefined : durationDiff
    }
  }
}

function latestEnds(tasks: TaskListTask[]) {
  return tasks.map(t => {
    if (!t.start_latest_planned) {
      return t.end_fixed
    }

    const endPlanned = t.start_latest_planned + t.duration

    if (!t.end_fixed) {
      return endPlanned
    }

    return Math.max(endPlanned, t.end_fixed)
  })
}

// TODO: where could things like this live -- helpful util for type safety.
function filterOutNull<T>(ts: (T | null)[]): T[] {
  return ts.filter((t: T | null): t is T => t !== null)
}
