import { createContext, ReactNode } from 'react'
import ActionCable from 'actioncable'

const cable = ActionCable.createConsumer(createWebsocketUrl())

export const WebsocketContext = createContext<typeof cable>(cable)

export const WebsocketsProvider = ({ children }: { children: ReactNode }) => {
  return <WebsocketContext.Provider value={cable}>{children}</WebsocketContext.Provider>
}

function createWebsocketUrl() {
  const hostname = window.location.hostname
  let protocol = 'wss'
  let port = ''

  if (hostname === 'localhost') {
    protocol = 'ws'
    port = ':8080'
  }

  return `${protocol}://${hostname}${port}/cable`
}
