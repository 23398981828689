// @ts-nocheck
import { action, computed, observable, observe, Lambda } from 'mobx'

import { AccordionPresenter } from 'Components/Molecules/Accordion'
import { ButtonPresenter } from 'Components/Molecules/Button'
import { container } from 'app/Config/IOC'
import { ILanguageService } from 'Helpers/Language/ILanguageService'
import { IUserPM } from 'Shared/Entities/Types/IUserPM'
import { ToasterService } from 'Components/Molecules/Toaster/ToasterService'
import { Types } from 'Gateways/Service/Types'
import { UserAppTokenApplicationPresenter } from 'app/Access/Users/UsersPage/UserAppTokenApplicationPresenter'
import { UserAppTokenPresenter } from 'Components/Organisms/UserAppToken'
import { UserAppTokenRepository } from 'app/Repositories/User/UserAppTokenRepository'

export class UserEditUserAppTokensPresenter {
  public language: ILanguageService = container.get(Types.ILanguageService)
  private toasterGateway: ToasterService = container.get(ToasterService)

  @observable
  public accordionPresenter = new AccordionPresenter(this.language.get('userAppTokens:section:title')).withLeftIconName(
    'key'
  )

  @observable
  public addModalOpenState = false

  @observable
  public buttonPresenter = new ButtonPresenter(this.language.get('userAppTokens:section:addButton'), () =>
    this.setAddModalOpenState(true)
  )
    .withDisplayStyle('primary-grey')
    .withTextTransform('Uppercase')
    .withAriaLabel(this.language.get('userAppTokens:section:addButton'))

  @observable
  public disabled = false

  @observable
  public user: IUserPM

  @observable
  public userAppTokenApplicationPresenter: UserAppTokenApplicationPresenter = container.get(
    UserAppTokenApplicationPresenter
  )

  @observable
  public userAppTokenPresenters: UserAppTokenPresenter[] = []

  @observable
  public userAppTokenRepository: UserAppTokenRepository = container.get(UserAppTokenRepository)

  @observable
  public userAppTokenRepositoryObserverDisposer: Lambda = null

  @computed
  public get loading(): boolean {
    return this.userAppTokenRepository.loading
  }

  @action
  public load = () => {
    this.clear()
    this.userAppTokenRepositoryObserverDisposer = observe(this.userAppTokenRepository, 'userAppTokens', () => {
      this.setUserAppTokenPresenters()
    })
    this.userAppTokenRepository.loadData()
  }

  @action
  public clear = () => {
    this.userAppTokenRepositoryObserverDisposer && this.userAppTokenRepositoryObserverDisposer()
  }

  @action
  public setUser = async (user: IUserPM) => {
    this.reset()
    this.user = user

    if (user) {
      this.userAppTokenRepository.setUserId(user.id)
      this.load()
    }
  }

  @action
  public setAddModalOpenState = (state: boolean) => {
    this.addModalOpenState = state
  }

  @action
  public processRevokeToken = async (tokenId: number) => {
    await this.userAppTokenRepository.revokeToken(tokenId)
    this.load()

    this.toasterGateway.pop(
      this.language.get('userAppTokens:revoke:toaster:title'),
      this.language.get('userAppTokens:revoke:toaster:notification'),
      'success'
    )
  }

  @action
  public reset = () => {
    this.clear()
    this.addModalOpenState = false
    this.disabled = false
    this.userAppTokenPresenters = []
  }

  @action
  public closeAccordion = () => {
    this.accordionPresenter.closeAccordion()
  }

  @action
  public setDisabled = (disabled: boolean) => {
    this.disabled = disabled
  }

  @action
  private setUserAppTokenPresenters = () => {
    const userAppTokenPresenters = this.userAppTokenRepository.userAppTokens.map(token => {
      return new UserAppTokenPresenter(token, !this.disabled, this.processRevokeToken)
    })

    this.userAppTokenPresenters = userAppTokenPresenters
  }
}
