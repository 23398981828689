import { useMutation, UseMutationOptions } from 'react-query'

import { apiClient, ApiError } from 'main/services/api/api-client'

export const useRunbookRestore = ({
  options = {}
}: {
  options: UseMutationOptions<any, ApiError, { runbookId: number }>
}) => {
  return useMutation<any, ApiError, { runbookId: number }>(
    'archived-runbook-restore',
    async ({ runbookId }) => {
      await apiClient.patch({
        url: `runbooks/${runbookId}/restore`
      })
    },
    options
  )
}
