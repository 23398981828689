import { HttpMethod, RequestOptions, Response } from '@cutover/api'
import { AuthServiceGateway } from './auth-service-gateway'
import { IBaseResponse } from 'Gateways/Service/IBaseResponse'

export type ApiErrorDetails = {
  code: string
  message: string
  attribute?: string
  error?: string
  meta?: Record<string, any>
  model?: string
}

export class ApiError extends Error {
  public status: number
  public errors: string[]
  public errorDetails: ApiErrorDetails[] | undefined

  constructor(message: string, status: number, errors: string[], errorDetails?: ApiErrorDetails[] | undefined) {
    super(message)

    this.name = 'ApiError'
    this.status = status
    this.errors = errors
    this.errorDetails = errorDetails
  }

  // Returns the first validation error for 422 responses, or default error
  public validationError(defaultError: string) {
    if (this.status !== 422) {
      return defaultError
    }

    if (!this.errors || this.errors.length === 0) {
      return defaultError
    }

    return this.errors[0]
  }
}

function handleError(
  response: IBaseResponse,
  errorResponseProperty: 'errors' | null = 'errors',
  errorDetailsResponseProperty: string = 'error_details'
) {
  if (!response) {
    return
  }
  if (!response.success) {
    throw new ApiError(
      'There was an API error response.',
      response.status ?? 500,
      errorResponseProperty
        ? response.body?.[errorResponseProperty] ?? response[errorResponseProperty] ?? []
        : response.body,
      response.body?.[errorDetailsResponseProperty]
    )
  }
}

export async function httpGatewayAdapter<TData, TResponse>(
  options: RequestOptions<TData>
): Promise<Response<TResponse>> {
  const serviceGateway = new AuthServiceGateway()

  if (!options.url) {
    throw new Error('No url specified for API request.')
  }

  switch (options.method) {
    case HttpMethod.Get: {
      // @ts-ignore
      const response = await serviceGateway.get(options.url, options.params, options.headers, options.config)
      handleError(response, options.errorResponseProperty)

      return {
        data: response.body,
        status: 200
      }
    }
    case HttpMethod.Post: {
      const response = await serviceGateway.post(options.url, options.data as unknown as object, options.headers)
      handleError(response)

      return {
        data: response.body,
        status: 200
      }
    }
    case HttpMethod.Put: {
      const response = await serviceGateway.put(options.url, options.data as unknown as object)
      handleError(response)

      return {
        data: response.body,
        status: 200
      }
    }
    case HttpMethod.Patch: {
      const response = await serviceGateway.patch(options.url, options.data as unknown as object)
      handleError(response)

      return {
        data: response.body,
        status: 200
      }
    }
    case HttpMethod.Delete: {
      // @ts-ignore
      const response = await serviceGateway.delete(options.url, options.params, options.headers)
      handleError(response)

      return {
        data: response.body,
        status: 200
      }
    }
    default:
      throw new Error(`Unknown HTTP method '${options.method}'.`)
  }
}
