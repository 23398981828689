import { atom, selector, selectorFamily } from 'recoil'

import { toSnakeCase } from '@cutover/api'
import { GlobalConfigType } from 'main/services/hooks'
import { fetchConfigQuery } from 'main/services/queries/use-get-configs'

export const globalConfig = atom({
  key: 'config',
  default: selector({
    key: 'config:default',
    get: async () => {
      const config = await fetchConfigQuery()
      return {
        ...config,
        integrations: config.enabledFeatures.includes('conditional_logic')
          ? config.integrations
          : config.integrations.filter(i => i.klass !== 'Integrations::ConditionalLogic::ConditionalLogic')
      }
    }
  })
})

export const globalConfigProperty = selectorFamily<
  GlobalConfigType[keyof GlobalConfigType],
  { attribute: keyof GlobalConfigType }
>({
  key: 'config:property',
  get:
    ({ attribute }) =>
    ({ get }) => {
      return get(globalConfig)[attribute]
    }
})

// Using this to build filter labels (checking that the exist in configs)
export const convertConfigObjectKeysToSnakeCase = (originalObject: any) => {
  const snakeKeysConfig = {} as Record<string, any>
  for (const camelKey in originalObject) {
    snakeKeysConfig[toSnakeCase(camelKey)] = originalObject[camelKey]
  }
  return snakeKeysConfig
}
