import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const BrandMail = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 100 100" a11yTitle="BrandMail" {...props}>
    <g clipPath="url(#a)" fill="#fff"><path opacity=".5" d="M100 100.053v-50.72l-50 50.72h50Zm-100 0v-50.72l50 50.72H0Z" /><path d="m50.067-.067-47.1 47.1h94.201l-47.1-47.1Z" /></g>
  </StyledIcon>
))

BrandMail.displayName = 'BrandMail'

export { BrandMail }
