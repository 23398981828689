import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Table = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Table" {...props}>
    <path d="M34 6a8 8 0 0 1 8 8v20a8 8 0 0 1-8 8H14a8 8 0 0 1-8-8V14a8 8 0 0 1 8-8h20Zm-8.5 3v8H39v-3a5 5 0 0 0-5-5h-8.5Zm-3 0H14a5 5 0 0 0-5 5v3h13.5V9Zm0 11H9v8h13.5v-8Zm0 11H9v3a5 5 0 0 0 5 5h8.5v-8Zm3 8H34a5 5 0 0 0 5-5v-3H25.5v8Zm0-11H39v-8H25.5v8Z" fill="#000" />
  </StyledIcon>
))

Table.displayName = 'Table'

export { Table }
