import { useState } from 'react'
import { useParams } from 'react-router-dom'

import { Button, Modal } from '@cutover/react-ui'
import { SelectSnippet } from './select-snippet'
import { ValidateSnippet } from './validate-snippet'
import { useLanguage } from 'main/services/hooks'
import { SnippetListSnippet } from 'main/services/queries/use-snippets'

type AddSnippetProps = {
  taskId: number
  initiallyOpen?: boolean
  onClose?: () => void
}

export const AddSnippetModal = ({ taskId, initiallyOpen = false, onClose }: AddSnippetProps) => {
  const [snippet, setSnippet] = useState<SnippetListSnippet | null>(null)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(initiallyOpen)
  const [modalStep, setModalStep] = useState<'selectSnippet' | 'validateSnippet'>('selectSnippet')
  const { t } = useLanguage('runbook')
  const { accountId, runbookId } = useParams()

  const selectSnippet = (snippet: SnippetListSnippet) => {
    setSnippet(snippet)
    setModalStep('validateSnippet')
  }

  const closeModal = () => {
    // Note: because this is being called from angular, the open state & the trigger button are also managed here.
    // Hack this by doing both until we can get rid of the angular call
    setIsModalOpen(false)
    onClose?.()
  }

  const modalContent =
    modalStep === 'selectSnippet' ? (
      <>{accountId && <SelectSnippet accountId={accountId} selectSnippet={selectSnippet} />}</>
    ) : (
      <>
        {snippet && runbookId && taskId && (
          <ValidateSnippet snippetId={snippet.id} runbookId={runbookId} taskId={taskId} closeModal={closeModal} />
        )}
      </>
    )

  return (
    <>
      {!initiallyOpen && (
        <Button tertiary icon="snippet" label={t('addSnippet.addSnippetMenu')} onClick={() => setIsModalOpen(true)} />
      )}

      <Modal
        title={modalStep === 'selectSnippet' ? t('snippetListModal.heading') : snippet?.name}
        open={isModalOpen}
        onClose={closeModal}
        hideFooter
        onClickBack={modalStep === 'selectSnippet' ? undefined : () => setModalStep('selectSnippet')}
      >
        {modalContent}
      </Modal>
    </>
  )
}
