// @ts-nocheck
import { observable, action } from 'mobx'
import { saveFile } from 'Helpers/.'
import { ButtonPresenter, PopoverPresenter } from 'Components/Molecules'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'
import { container } from 'app/Config/IOC'
import { Types } from 'Gateways/Service/Types'
import { RunbookUsersViewPresenter } from '../RunbookUsersViewPresenter'
import { TableScrollerService } from 'Components/Organisms'
import { ExportUsersModalPresenter } from 'Components/Templates'
import { ModalPresenter } from 'Components/Atoms'

export class RunbookUsersPageHeadingOptionsPresenter {
  private runbookUsersViewPresenter: RunbookUsersViewPresenter = container.get(RunbookUsersViewPresenter)
  public language: ILanguageService = container.get(Types.ILanguageService)
  public tableScrollerService: TableScrollerService = container.get(TableScrollerService)

  @observable
  public optionsPopoverPresenter: PopoverPresenter = new PopoverPresenter().withPosition('bottom right')

  @observable
  public exportModalPresenter: ModalPresenter = new ModalPresenter()

  @observable
  public optionsOpen: boolean = false

  public openExportModal = () => {
    this.exportModalPresenter.onOpen()
  }

  public closeExportModal = () => {
    this.exportModalPresenter.onClose()
  }

  @action
  public setOptionsOpen = (open: boolean) => {
    this.optionsOpen = open
  }

  private downloadCSV = async () => {
    const response = await this.runbookUsersViewPresenter.downloadRunbookUsers('CSV')
    saveFile(response, 'text/csv', `${this.runbookUsersViewPresenter.runbookName}-users.csv`)
  }

  private downloadXLSX = async () => {
    const response = await this.runbookUsersViewPresenter.downloadRunbookUsers('XLSX')
    saveFile(response, 'text/csv', `${this.runbookUsersViewPresenter.runbookName}-users.xlsx`)
  }

  public exportUsersModalPresenter = new ExportUsersModalPresenter(this.downloadCSV, this.downloadXLSX).withClose(
    this.closeExportModal
  )

  @observable
  public exportModalOpenButton = new ButtonPresenter(this.language.get('common:exportUsersButton:title'), () => {
    this.openExportModal()
    this.setOptionsOpen(false)
  })
    .withIconName('download')
    .withIconPosition('left')
    .withDisplayStyle('popover')
    .withAriaLabel(this.language.get('common:exportUsersButton:title'))

  public processReload = () => {
    this.runbookUsersViewPresenter.loadList()
    this.tableScrollerService.doScroll()
    this.setOptionsOpen(false)
  }

  @observable
  public reloadButton = new ButtonPresenter(this.language.get('common:reloadButton'), this.processReload)
    .withIconName('cycle')
    .withIconPosition('left')
    .withDisplayStyle('popover')
    .withAriaLabel(this.language.get('common:reloadButton'))
}
