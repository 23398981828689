import { useEffect, useState } from 'react'
import styled, { css, keyframes } from 'styled-components'

import { colors } from '@cutover/react-ui'

type LogoLoaderProps = {
  loading: boolean
  size: number
  dark?: boolean
  delay?: number
}

export const LogoLoader = ({ loading, size, dark = false, delay = 0 }: LogoLoaderProps) => {
  const color = dark ? colors.primary : colors.white
  const [isLoading, setIsLoading] = useState(loading)

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout> | undefined = undefined

    if (!loading) {
      if (timeoutId !== undefined) clearTimeout(timeoutId)
      setIsLoading(false)
    } else {
      timeoutId = setTimeout(() => {
        setIsLoading(true)
      }, delay)
    }

    return () => {
      if (timeoutId !== undefined) clearTimeout(timeoutId)
    }
  }, [loading])

  return (
    <Logo isLoading={isLoading} size={size} color={color}>
      <path d="M113.33 187.036C92.7447 166.583 92.7447 133.377 113.33 112.924L79.4652 79.2761C61.2614 97.4033 50 122.388 50 149.98C50 205.204 95.0454 250 150.626 250C178.436 250 203.582 238.811 221.786 220.724L187.921 187.076C167.336 207.53 133.956 207.53 113.33 187.036Z" />
      <path
        opacity="0.4"
        d="M150.626 50C146.67 50 142.755 50.2406 138.92 50.6818L250 134.179C242.412 86.4949 200.797 50 150.626 50Z"
      />
      <path
        opacity="0.7"
        d="M84.3895 74.7844L124.228 104.702C144.41 93.0319 170.686 95.759 187.962 112.924C201.604 126.479 206.165 145.609 201.725 162.934L241.564 192.892C244.551 186.635 246.892 180.018 248.547 173.12L127.336 52.7271C111.15 56.537 96.4985 64.237 84.3895 74.7844Z"
      />
    </Logo>
  )
}

const loadingAnimation = keyframes`
  1% {
    opacity: .2
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: .2;
  }
`

type LogoProps = {
  isLoading: boolean
  size: number
  color: string
}

const Logo = styled.svg.attrs(({ size }: LogoProps) => ({
  fill: 'none',
  xmlns: 'http://www.w3.org/2000/svg',
  height: size,
  width: size,
  viewBox: '0 0 300 300'
}))<LogoProps>`
  path {
    fill: ${({ color }) => color};

    ${({ isLoading }) =>
      isLoading &&
      css`
        animation: ${loadingAnimation} 1.4s infinite both;
        &:nth-child(2) {
          animation-delay: 0.2s;
        }
        &:nth-child(3) {
          animation-delay: 0.4s;
        }
      `}
  }
`
