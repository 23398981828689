// @ts-nocheck
import styled from 'styled-components'
import { Icon } from 'Components/Atoms'

const IconWrapper = styled.span`
  flex: 0;
  padding: 0 4px;
`

interface IDropdownIndicatorProps {
  color?: string
  loading?: boolean
  className?: string
}

export const DropdownIndicator = (props: IDropdownIndicatorProps) => (
  <IconWrapper className={props.className}>
    {props.loading ? (
      <Icon name="spinner" color={props.color} extraStyles={{ cursor: 'pointer' }} />
    ) : (
      <Icon name="chevron-right" rotation={90} color={props.color} extraStyles={{ cursor: 'pointer' }} />
    )}
  </IconWrapper>
)
