// @ts-nocheck
import { useState } from 'react'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { v4 } from 'uuid'
import { ParentChildCheckboxPresenter } from './ParentChildCheckboxPresenter'
import { Icon } from 'Components/Atoms/Icon'
import { colors } from 'app/Styles/Variables'
import { HelpText } from 'Components/Molecules'
import { ItemTypeComponent } from './ItemTypeComponent'

interface IWrapperProps {
  type: string
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 6px;
  padding-bottom: 6px;
  width: 100%;
  margin-right: ${(props: IWrapperProps) => props.type === 'parent' && '28px'};
  padding-left: ${(props: IWrapperProps) => props.type === 'child' && '28px'};
`

interface IStyledLabelProps {
  value: boolean
}

const StyledLabel = styled.label`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;
  color: ${(props: IStyledLabelProps) => (props.value ? colors.text : colors.textLight)};
`

interface IHoverSpanProps {
  hasChildren: boolean
  type: string
}

const HoverSpan = styled.span`
  width: 100%;
  position: relative;
  display: inherit;
  opacity: ${(props: IHoverSpanProps) => {
    if (props.type === 'parent' && props.hasChildren) {
      return 1
    } else if (props.type === 'parent' && !props.hasChildren) {
      return 0.2
    } else {
      return 1
    }
  }};
  &:hover {
    opacity: 0.75;
  }
`

const chevronOuterStyle = { position: 'relative', left: -5, top: 2 }
const chevronStyle = { position: 'absolute', left: -2, top: 4, fontSize: 18 }

interface IParenChildCheckboxProps {
  presenter: ParentChildCheckboxPresenter
}

export const ParentChildCheckbox = observer<IParenChildCheckboxProps>(({ presenter }) => {
  const {
    onChange,
    value,
    helpText,
    helpTextIcon,
    label,
    toggleOpenChildren,
    chevronRotation,
    type,
    expandable,
    hasChildren,
    itemType,
    editIconVisible,
    showEditIcon,
    hideEditIcon,
    editable,
    flipField,
    showLabel,
    inputPlaceHolder
  } = presenter

  const [uuidLabel] = useState(`${v4()}-${label.replace(/\s/g, '')}`)

  const handleChange = () => {
    onChange(!value)
  }

  const helpTextRender = helpText ? <HelpText text={helpText} icon={helpTextIcon} /> : null

  const onFocus = () => null
  const onBlur = () => null

  const editIcon = (
    <span
      onMouseOver={showEditIcon}
      onMouseOut={hideEditIcon}
      style={{
        opacity: editIconVisible && editable ? 1 : 0,
        position: 'relative',
        right: type === 'child' ? 28 : 0,
        top: 1
      }}
      onFocus={onFocus}
      onBlur={onBlur}
    >
      <Icon name="new-message" size="16px" extraStyles={{ opacity: 0.7 }} />
    </span>
  )

  const clickHandler = event => {
    event.stopPropagation()
    toggleOpenChildren()
  }

  return (
    <span>
      <Wrapper type={type}>
        <StyledLabel value={value} onClick={handleChange} style={{ userSelect: 'none' }}>
          {type === 'parent' && (
            <>
              <ItemTypeComponent
                itemType={itemType}
                onChange={onChange}
                value={value}
                id={uuidLabel}
                title={label}
                placeholderText={inputPlaceHolder}
              />
              <HoverSpan
                hasChildren={hasChildren}
                type={type}
                onMouseOver={showEditIcon}
                onMouseOut={hideEditIcon}
                onClick={flipField}
              >
                {expandable === true && (
                  <div
                    style={{ position: 'relative' }}
                    onClick={clickHandler}
                    onKeyPress={clickHandler}
                    role="presentation"
                  >
                    <Icon name="border-dashed2" size="24px" extraStyles={chevronOuterStyle} />
                    <Icon name="add" size="20px" rotation={chevronRotation} extraStyles={chevronStyle} />
                  </div>
                )}
                {showLabel && (
                  <label htmlFor={uuidLabel} style={{ paddingTop: 5 }}>
                    {label}
                  </label>
                )}
              </HoverSpan>
              {showLabel && editIcon}
            </>
          )}
          {type === 'child' && presenter.parent.open && (
            <>
              <HoverSpan
                hasChildren={hasChildren}
                type={type}
                onMouseOver={showEditIcon}
                onMouseOut={hideEditIcon}
                onClick={flipField}
              >
                <ItemTypeComponent
                  itemType={itemType}
                  onChange={onChange}
                  value={value}
                  title={label}
                  id={uuidLabel}
                  placeholderText={inputPlaceHolder}
                />
                {showLabel && (
                  <label htmlFor={uuidLabel} style={{ paddingTop: 2 }}>
                    {label}
                  </label>
                )}
              </HoverSpan>
              {showLabel && editIcon}
            </>
          )}
        </StyledLabel>
        {helpTextRender}
      </Wrapper>
    </span>
  )
})
