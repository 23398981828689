import { FormEventHandler } from 'react'
import { CheckBox, CheckBoxExtendedProps } from 'grommet'
type ToggleProps = Omit<CheckBoxExtendedProps, 'label'> & {
  label?: string
  checked: boolean
  onChange?: FormEventHandler<HTMLInputElement> | undefined
}

export const Toggle = ({ label, checked, onChange, ...props }: ToggleProps) => {
  return <CheckBox {...props} label={label} checked={checked} onChange={onChange} toggle />
}
