import { Text } from '@cutover/react-ui'
import { FormModal } from 'main/components/shared/form'
import { ActiveRunbookVersionModel } from 'main/data-access'
import { useLanguage } from 'main/services/hooks'
import { convertRunbookVersionToTemplate } from 'main/services/queries/use-runbook-versions'

export const MakeTemplateModal = ({
  runbookId,
  runbookVersionId,
  onClose
}: {
  runbookId: number
  runbookVersionId: number
  onClose: () => void
}) => {
  const { t } = useLanguage('runbook', { keyPrefix: 'makeTemplateModal' })
  const processConvertRunbookVersionToTemplate = ActiveRunbookVersionModel.useOnAction('convert_to_template')

  const handleSubmit = async () => {
    const data = await convertRunbookVersionToTemplate(runbookId, runbookVersionId)
    processConvertRunbookVersionToTemplate(data)
  }

  return (
    <FormModal
      formElementWrapper={false}
      confirmText={t('submit')}
      open
      onClose={onClose}
      onSubmit={handleSubmit}
      title={t('title')}
    >
      <Text>{t('modalDescription')}</Text>
    </FormModal>
  )
}
