import { Text } from '@cutover/react-ui'
import { RunbookListRunbook } from 'main/services/queries/types'
import { Bullet } from './bullet'

export const ExternalId = ({ runbook: { external_id } }: { runbook: RunbookListRunbook }) => {
  return (
    <>
      <Bullet />
      <Text size="small" truncate>
        {external_id}
      </Text>
    </>
  )
}
