// @ts-nocheck
import styled from 'styled-components'
import { colors, px2rem } from 'app/Styles/Variables'

export const StyledTable = styled.div`
  position: absolute;
  top: ${px2rem(4)};
  left: ${px2rem(14)};
  right: ${px2rem(16)};
  bottom: 0;
  overflow: hidden;
  overflow-x: scroll;
  background-color: white;
  -webkit-overflow-scrolling: touch;
  .table-header {
    width: ${(props: { tableWidth: number }) => {
      return `${props.tableWidth}px`
    }};
    position: relative;
    border-bottom: ${px2rem(1)} solid ${colors.primaryGreyBackgroundColor};
    box-sizing: border-box;
    height: ${px2rem(40)};
    .cell {
      font-weight: bold;
      display: block;
      position: absolute;
      font-weight: 700;
      cursor: pointer;
      color: ${colors.text};
      line-height: ${px2rem(24)};
      .inner-content {
        padding: ${px2rem(8)} ${px2rem(30)} ${px2rem(8)} ${px2rem(8)};
        text-align: left;
        font-size: ${px2rem(15)};
      }
      .avatar {
        background-image: none;
      }
      .icon-arrow-forward {
        opacity: 0;
        position: absolute;
        top: 50%;
        right: ${px2rem(6)};
        margin-top: ${px2rem(-10)};
        font-size: 1.2rem;
        line-height: ${px2rem(24)};
        width: ${px2rem(20)};
        transition: opacity 0.4s cubic-bezier(0.35, 0, 0.25, 1), transform 0.4s cubic-bezier(0.35, 0, 0.25, 1);
        &.hover {
          color: ${colors.primary};
        }
      }
      &:hover {
        .icon-arrow-forward {
          opacity: 1;
        }
      }
      &.sorted {
        &:hover .icon-arrow-forward {
          opacity: 0.7;
        }
        &.asc,
        &.desc {
          color: ${colors.primary};
          .icon-arrow-forward {
            color: ${colors.primary};
            opacity: 1;
          }
        }
        &.asc .icon-arrow-forward {
          transform: rotate(-90deg);
        }
        &.desc .icon-arrow-forward {
          transform: rotate(90deg);
        }
      }
    }
    .cell:first-child {
      border-left: 0;
    }
  }

  .table-body {
    position: absolute !important;
    top: ${px2rem(40)};
    width: ${(props: { tableWidth: number }) => {
      return `${props.tableWidth}px`
    }};
    overflow: hidden !important;
    overflow-y: scroll !important;

    .row {
      cursor: pointer;
      &.even {
        background-color: ${colors.bgLightest};
      }
      &.loading {
        background-color: ${colors.bgLightest};
        color: ${colors.textLight};
      }
      &.disabled {
        .cell .inner-content {
          opacity: 0.5;
        }
      }
      &:hover {
        background-color: ${colors.bgLightest3};
      }
      .cell {
        border-left: ${px2rem(1)} solid ${colors.primaryGreyBackgroundColor};
        color: ${colors.textLight};
        display: block;
        position: absolute;
        line-height: ${px2rem(40)};
        height: ${px2rem(40)};
        .inner-content {
          padding: ${px2rem(0)} ${px2rem(8)} ${px2rem(0)} ${px2rem(13)};
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          font-size: ${px2rem(14)};
        }
      }
      .avatar {
        background-image: none;
        border-radius: 0;
      }
      .cell:first-child {
        border-left: 0;
      }
    }
  }
`
