// @ts-nocheck
import { observable, action, computed } from 'mobx'
import { injectable } from 'inversify'
import { TIcons } from 'Components/Atoms'
import { ISortParams } from 'Components/Organisms'
import { FiltersRepository } from 'app/Filters/FiltersRepository'
import { container } from 'app/Config/IOC'
import { UserRepository } from 'app/Repositories/User/UserRepository'
import { IRunbookTypeVM } from 'app/Shared/Entities/Types/RunbookType/IRunbookTypeVM'
import { UsersRepository } from 'app/Repositories/User/UsersRepository'
import { Router } from 'app/Routing/Router'

@injectable()
export class UsersViewPresenter {
  private filtersRepository: FiltersRepository = container.get(FiltersRepository)

  private userRepository: UserRepository = container.get(UserRepository)

  private usersRepository: UsersRepository = container.get(UsersRepository)

  private router: Router = container.get(Router)

  @observable
  public submitted: boolean = false

  @observable
  public addModalSubmitButtonText: string

  @observable
  public addModalSubmitButtonIcon: TIcons

  @observable
  public addModalSubmitButtonLoading: boolean = false

  @observable
  public repositoryLoading = false

  @observable
  public reloadList = false

  @action
  public setSubmitted = (val: boolean) => {
    this.submitted = val
  }

  @observable
  public sortParams: ISortParams = {
    accessor: 'name',
    dataType: 'string',
    sortDirection: 'asc'
  }

  @observable
  public pageVM = {
    editPanelOpen: false,
    editPanelDirty: false,
    editPanelClearing: false,
    editLoading: false
  }

  public clearFilters = (): void => {
    this.filtersRepository.clearPathFilters()
    this.router.refreshFilteredRoute()
  }

  @action
  public editPanelAfterCloseFunction = (): void => {
    this.userRepository.clearUserForEdit()
    this.pageVM.editPanelClearing = false
  }

  @action
  public close = (): void => {
    this.pageVM.editPanelClearing = true
    this.editPanelAfterCloseFunction()
  }

  @computed
  public get userVM(): IRunbookTypeVM {
    const userPM = this.userRepository.userForEdit
    if (!userPM) return null
    return userPM
  }

  public loadUser = async (userId: number) => {
    await this.userRepository.loadData({ id: userId })
  }

  @computed
  public get usersFilteredTotal(): number {
    return this.usersRepository.usersFilteredTotal
  }

  public loadUsers = async () => {
    return await this.usersRepository.loadData({ sortParams: this.sortParams })
  }
}
