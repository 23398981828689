import { useEffect } from 'react'

import { Box } from '@cutover/react-ui'
import { CutoverLogo, PageWrap } from './login-styled'
import { UserVerifyForm } from './user-verify-form'
import { VerifyActiveSession } from './verify-active-session'
import { useHeap } from 'main/components/support-and-analytics/use-heap'

export const UserVerifyPage = () => {
  const { initializeAnonymousAnalytics } = useHeap()

  useEffect(() => {
    initializeAnonymousAnalytics()
  }, [])

  return (
    <VerifyActiveSession>
      <PageWrap>
        <CutoverLogo src="/branding/cutover/cutover-logo.png" alt="Cutover" />
        <Box css="min-height: auto; padding-bottom: 30px;">
          <UserVerifyForm />
        </Box>
      </PageWrap>
    </VerifyActiveSession>
  )
}
