import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const TaskStarted = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="TaskStarted" {...props}>
    <path d="M32 22.268c1.333.77 1.333 2.694 0 3.464l-10.5 6.062c-1.333.77-3-.192-3-1.732V17.938c0-1.54 1.667-2.502 3-1.732L32 22.268ZM21.5 19.67v8.66L29 24l-7.5-4.33Z" fill="#000" /><path d="M24 6c9.941 0 18 8.059 18 18s-8.059 18-18 18S6 33.941 6 24 14.059 6 24 6ZM9 24c0 8.284 6.716 15 15 15 8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15-8.284 0-15 6.716-15 15Z" fill="#000" />
  </StyledIcon>
))

TaskStarted.displayName = 'TaskStarted'

export { TaskStarted }
