import { createTaskFilterFunction, RunbookFilterType, TaskFilterContext } from './filters'
import { TaskListTask } from 'main/services/queries/types'

export const query = createTaskFilterFunction(
  'q',
  (task: TaskListTask, filters: RunbookFilterType, context: TaskFilterContext): boolean => {
    if (filters.q === undefined) return false

    const query = context.query as string
    const taskIds = context.taskIds as number[] | undefined
    const internalId = task.internal_id

    if (taskIds && taskIds.includes(internalId)) {
      return true
    }

    if ((!taskIds && task.name.toLowerCase().includes(query)) || String(internalId).includes(query)) {
      return true
    }

    return false
  }
)
