import { ReactNode } from 'react'
import SkeletonComponent, { SkeletonProps, SkeletonTheme as SkeletonThemeProvider } from 'react-loading-skeleton'

import 'react-loading-skeleton/dist/skeleton.css'
import { IconButton } from '../../button'
import { Box } from '../../layout'
import { ColorPropType, resolveColor, useTheme } from '../../theme'

// NOTE: These components were created with the runbook page in mind without fully considering other
// pages. This can and should be adjusted as needed when other sections of the app are
// transitioned to recoil.

// TODO: add params necessary for proper go back action
export const PageHeadingLoader = () => {
  return (
    <SkeletonTheme background="bg">
      <Box
        height="72px"
        flex={false}
        direction="row"
        fill
        align="center"
        alignContent="center"
        gap="8px"
        margin={{ right: 'xsmall' }}
      >
        {/* TODO: add go back action here */}
        <IconButton label="back to list" onClick={() => {}} tertiary icon="arrow-back" />
        <Box width="100%" direction="row" gap="6px" fill align="center">
          {/* main icon */}
          <Skeleton height={40} width={40} />
          {/* title text */}
          <Box flex="grow" justify="center" gap="xxsmall">
            <Skeleton height={20} width="40%" />
            <Skeleton height={18} width="30%" />
          </Box>
          {/* right side content */}
          <Skeleton height={40} width={220} />
        </Box>
      </Box>
    </SkeletonTheme>
  )
}

export const PageSubHeadingLoader = () => {
  return (
    <SkeletonTheme background="bg-1">
      <Box height="64px" flex={false} direction="row" fill align="center" alignContent="center" gap="6px">
        <Skeleton height={40} width={40} circle />
        <Skeleton height={40} width={220} />
        <Box flex="grow" />
        <Skeleton height={40} width={220} />
      </Box>
    </SkeletonTheme>
  )
}

export const PageFilterPanelLoader = () => {
  return (
    <SkeletonTheme background="bg-1">
      <Box margin={{ left: 'small' }} gap="medium">
        <Box gap="small">
          <Skeleton height={48} width={'100%'} />
          <Box gap="xxsmall">
            <FilterSkeletonItemRow />
            <FilterSkeletonItemRow />
            <FilterSkeletonItemRow />
          </Box>
        </Box>
        <Box gap="small">
          <Skeleton height={48} width={'100%'} />
          <Box gap="xxsmall">
            <FilterSkeletonItemRow />
            <FilterSkeletonItemRow />
          </Box>
        </Box>
        <Skeleton height={48} width={'100%'} />
        <Skeleton height={48} width={'100%'} />
      </Box>
    </SkeletonTheme>
  )
}

const FilterSkeletonItemRow = () => {
  return <Skeleton height={22} width={'100%'} />
}

// Styled Skeleton Base Components

const SkeletonTheme = ({ children, background = 'bg' }: { children?: ReactNode; background?: ColorPropType }) => {
  const theme = useTheme()
  // only setup rifht now to support two backgrounds, but could be extended to support more
  const base: ColorPropType = background === 'bg' ? 'bg-1' : 'bg-2'
  const highlight: ColorPropType = background === 'bg' ? 'bg-2' : 'bg-3'

  return (
    <SkeletonThemeProvider baseColor={resolveColor(base, theme)} highlightColor={resolveColor(highlight, theme)}>
      {children}
    </SkeletonThemeProvider>
  )
}

const Skeleton = ({ style: customStyle, ...props }: SkeletonProps) => {
  const style = { borderRadius: '8px', ...(customStyle ?? {}) }

  return <SkeletonComponent style={style} data-test-id="skeleton-loader" {...props} />
}
