import { useCallback } from 'react'
import debounce from 'debounce-promise'

import { MultiSelect, MultiSelectProps, RenderMultiSelectOptionProps, UserListItem } from '@cutover/react-ui'
import { apiClient } from 'main/services/api/api-client'
import { User } from 'main/services/queries/types'

// NOTE: if change, change in docs
// This is the global default and is often not applicable across scenarios. please do not change this unless you know what you are doing.
const DEFAULT_MIN_CHARS = 2

// NOTE: if change, change in docs
// This is the global default and is often not applicable across scenarios. please do not change this unless you know what you are doing.
const DEFAULT_DEBOUNCE_TIME = 300

const DEFAULT_SHOW_MORE_THRESHOLD = 10

// if you don't pass in a prop for users, and you don't pass in a prop for loadUsers, you get an async user select with default load function

export type UserSelectProps = Omit<
  MultiSelectProps<User>,
  'renderOption' | 'filterKeys' | 'options' | 'loadOptions'
> & {
  users?: User[] | null
  /** pass in a custom load function (otherwise uses the account endpoint) */
  loadUsers?: (query: string) => Promise<User[]>
  /** used to construct the load endpoint for the default endpoint for async user selects. Has no impact for synchronous selects */
  accountId?: string | number
  /** min number of characters before triggering search when using the default user endpoint to load users. If suppliing custom load function, this
   * will have no impact because it is used when constructing the default load function.
   * @default 2
   */
  minChars?: number
  /** debounce time in ms for triggering search when using the default user endpoint to load users. If suppliing custom load function, this
   * will have no impact because it is used when constructing the default load function.
   * @default 300
   */
  debounceTime?: number
  draggable?: boolean
}

export const UserSelect = ({ users, loadUsers, accountId, disabled, draggable = false, ...props }: UserSelectProps) => {
  const renderOption = useCallback(
    (user: User, { onDeselect, selected, highlighted }: RenderMultiSelectOptionProps<User>) => {
      return (
        <UserListItem
          size="small"
          id={user.id}
          firstName={user.first_name ?? user.firstName}
          lastName={user.last_name ?? user.lastName}
          subtitle={user.email}
          online={user.online}
          color={user.color}
          active={highlighted}
          status={user.status}
          // Note, other endpoints we calculate 'status' based on if archived, not-in-account etc
          onClickRemove={!disabled && onDeselect ? () => onDeselect(user) : undefined}
          data-testid={`user-select-list-item-${selected ? 'selected' : 'unselected'}`}
          disabled={disabled}
          draggable={draggable}
        />
      )
    },
    [disabled]
  )

  const isAsync = users === undefined // would be null or users array if synchronous

  return (
    <MultiSelect<User>
      showMoreThreshold={DEFAULT_SHOW_MORE_THRESHOLD}
      {...props}
      options={!isAsync ? users ?? [] : undefined}
      loadOptions={
        isAsync
          ? loadUsers ??
            createFetchUsersFunction({ accountId, minChars: props.minChars, debounceTime: props.debounceTime })
          : undefined
      }
      // TODO: is adding hanle/email/name as search terms even if they don't show up in the list item a good idea?
      filterKeys={
        !isAsync ? ['first_name', 'last_name', 'handle', 'email', 'name', 'firstName', 'lastName'] : undefined
      }
      icon="user-add"
      valueKey="id"
      optionToString={user => `${user.first_name} ${user.last_name}`}
      // TODO: i18n
      placeholder={props.placeholder ?? "Start typing a user's name..."}
      renderOption={renderOption}
      disabled={disabled}
    />
  )
}

function createFetchUsersFunction({
  accountId,
  minChars = DEFAULT_MIN_CHARS,
  debounceTime = DEFAULT_DEBOUNCE_TIME
}: {
  accountId?: string | number
  minChars?: number
  debounceTime?: number
}) {
  return debounce(async (query: string): Promise<User[]> => {
    let users: User[]

    if (!query || query.length < minChars) {
      users = []
      return users
    }

    const fetchUsers = async (): Promise<User[]> => {
      const { data } = await apiClient.get<{ users: User[] }>({
        url: `users/list?query=${query}&account_id=${accountId}`
      })

      return data.users
    }

    users = await fetchUsers()

    return users
  }, debounceTime)
}
