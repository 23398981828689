import { useState } from 'react'

import { TextArea } from '@cutover/react-ui'
import { useComponentPropsAndState } from '../apps-state'
import { AppComponentNodeProps } from '../apps-types'

type TextAreaFieldNodeProps = AppComponentNodeProps & {
  value: string
  label: string
  name: string
  readOnly?: boolean
  error?: boolean
}

export const TextAreaFieldNode = ({ appId, resourceId, id, ...props }: TextAreaFieldNodeProps) => {
  const { componentProps, setState } = useComponentPropsAndState(appId, resourceId, id, props)
  const { value: initialValue, label, name, readOnly, error } = componentProps as TextAreaFieldNodeProps
  const [value, setValue] = useState(initialValue)

  const updateFieldValue = (value: string) => {
    setValue(value)
    setState({ name, value })
  }

  return (
    <TextArea
      value={value}
      label={label ?? ''}
      onChange={e => updateFieldValue(e.target.value)}
      readOnly={readOnly}
      hasError={error}
    />
  )
}
