import { CustomFieldUser } from 'main/services/queries/types'
import {
  useGetCustomFieldUser,
  useGetCustomFieldUserCallback,
  useGetCustomFieldUserLookup,
  useGetCustomFieldUserLookupCallback,
  useGetCustomFieldUsers,
  useGetCustomFieldUsersCallback
} from '../hooks'
import { CollectionModelInterfaceBase } from './types'

export type CustomFieldUserModelType = CollectionModelInterfaceBase<CustomFieldUser>

export const CustomFieldUserModel: CustomFieldUserModelType = {
  useGet: useGetCustomFieldUser,
  useGetCallback: useGetCustomFieldUserCallback,

  useGetAll: useGetCustomFieldUsers,
  useGetAllCallback: useGetCustomFieldUsersCallback,

  useGetLookup: useGetCustomFieldUserLookup,
  useGetLookupCallback: useGetCustomFieldUserLookupCallback
}
