import { DurationDelta } from './types'

export type Duration = {
  years?: number
  months?: number
  weeks?: number
  days?: number
  hours?: number
  minutes?: number
  seconds?: number
}

// Converts duration { years: 1, months: 2 } etc to '1y 2m' string
// Uses largest unit in the duration object over 0 and the following unit
// Removes any zero unit values from the formatted string
export function formatDuration(duration?: Duration): string | null {
  if (!duration) {
    return null
  }

  const keys: Array<keyof Duration> = (Object.keys(duration) as Array<keyof Duration>).filter(k => duration[k] ?? 0 > 0)
  if (keys.length === 0) {
    return null
  }

  const units: Array<keyof Duration> = ['years', 'months', 'weeks', 'days', 'hours', 'minutes', 'seconds']
  let largestUnitIndex: number | undefined = undefined

  for (const [index, unit] of units.entries()) {
    if (keys.indexOf(unit) !== -1) {
      largestUnitIndex = index
      break
    }
  }

  if (largestUnitIndex == null) {
    return null
  }

  const range: Array<keyof Duration> = units.slice(largestUnitIndex, largestUnitIndex + 2) as Array<keyof Duration>
  return range
    .reduce((fmt: string[], unit) => {
      const unitValue = keys.includes(unit) ? Math.abs(duration[unit] ?? 0) : 0

      const formattedUnitValue = unitValue.toLocaleString('en', {
        useGrouping: false,
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
      })

      if (unitValue > 0) {
        fmt.push(`${formattedUnitValue}${unit.charAt(0)}`)
      }

      return fmt
    }, [])
    .join(' ')
}

export function formatDurationDelta(delta?: DurationDelta): { duration: string; percentage: number } | null {
  if (!delta) {
    return null
  }

  const formattedDelta = formatDuration(delta.duration)
  if (!formattedDelta) {
    return null
  }

  return {
    duration: formattedDelta,
    percentage: delta.percentage
  }
}
