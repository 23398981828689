import { useState } from 'react'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { colors, fontSizes } from 'app/Styles/Variables'
import { Icon } from 'Components/Atoms'
import { DurationPresenter } from './DurationPresenter'
import { InlineNumber } from 'Components/Molecules'

interface IWrapperProps {
  color: string
}

const Wrapper = styled.div`
  position: relative;
  border-bottom: 1px dashed ${(props: IWrapperProps) => props.color};
  padding-top: 12px;
  margin-bottom: 16px;
`

interface ILabelProps {
  color: string
  isShrunk: boolean
  hasPrefix: boolean
}

const Label = styled.label`
  transition: all 0.15s cubic-bezier(0.35, 0, 0.25, 1);
  white-space: nowrap;
  position: absolute;

  color: ${(props: ILabelProps) => props.color};

  font-size: ${(props: ILabelProps) => (props.isShrunk ? fontSizes.sm : fontSizes.m)};
  font-weight: ${(props: ILabelProps) => (props.isShrunk ? '500' : '400')};

  top: ${(props: ILabelProps) => (props.isShrunk ? 0 : '18px')};
  left: ${(props: ILabelProps) => (props.hasPrefix && !props.isShrunk ? '25px' : '0')};
`

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 4px;
`

const IconWrapper = styled.span`
  padding-right: 10px;
`

const ValuesWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 2px 0;
  flex: 1;
`

const ValueEntry = styled.div`
  max-width: 80px;
  margin-left: 4px;
`

const Quantifier = styled.span`
  color: ${colors.textLight};
`

interface IDurationProps {
  presenter: DurationPresenter
}

export const Duration = observer<IDurationProps>(({ presenter }) => {
  const {
    handleDayChange,
    handleHourChange,
    handleMinuteChange,
    handleSecondChange,
    daysText,
    hoursText,
    minutesText,
    secondsText,
    days,
    hours,
    minutes,
    seconds,
    value,
    label,
    disabled,
    isValid,
    isDirty,
    required
  } = presenter

  const [isFocused, setFocused] = useState(false)

  const focusHandler = () => {
    setFocused(!isFocused)
  }

  let lineColor = isFocused ? colors.primary : colors.primaryGreyHoverBackgroundColor
  let color = isFocused ? colors.primary : colors.textLight

  if (!isValid && isDirty) {
    color = colors.error
  }

  const isShrunk = isFocused || value !== undefined
  const labelRender = required && label ? `${label} *` : label

  return (
    <Wrapper color={lineColor} onFocus={focusHandler} onBlur={focusHandler}>
      <Label color={color} isShrunk={isShrunk} hasPrefix>
        {labelRender}
      </Label>
      <InputWrapper>
        <IconWrapper>
          <Icon name="stopwatch" color={color} />
        </IconWrapper>
        <ValuesWrapper>
          <ValueEntry>
            <InlineNumber value={days} onChange={handleDayChange} disabled={disabled} />
            <Quantifier>{daysText}</Quantifier>
          </ValueEntry>
          <ValueEntry>
            <InlineNumber value={hours} onChange={handleHourChange} disabled={disabled} />
            <Quantifier>{hoursText}</Quantifier>
          </ValueEntry>
          <ValueEntry>
            <InlineNumber value={minutes} onChange={handleMinuteChange} disabled={disabled} />
            <Quantifier>{minutesText}</Quantifier>
          </ValueEntry>
          <ValueEntry>
            <InlineNumber value={seconds} onChange={handleSecondChange} disabled={disabled} />
            <Quantifier>{secondsText}</Quantifier>
          </ValueEntry>
        </ValuesWrapper>
      </InputWrapper>
    </Wrapper>
  )
})
