import { ReactNode } from 'react'
import styled from 'styled-components/macro'

import { breakpoints, colors, transition } from '../../utils'
import { LoadingPanel } from '../loading-panel'

export type MainPanelWrapperProps = {
  filterPanelOpen?: boolean
  children: ReactNode
  loading?: boolean
}

export const MainPanelWrapper = ({ filterPanelOpen, loading, children }: MainPanelWrapperProps) => (
  <MainPanelSection filterPanelOpen={filterPanelOpen}>
    {loading && <LoadingPanel overlay={true} />}
    {children}
  </MainPanelSection>
)

const MainPanelSection = styled.section<{ filterPanelOpen?: boolean }>`
  background-color: ${colors.bg1};
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  #cutover-react-legacy & {
    //DC - both old & new react use this elem in diff ways
    //normally the radius/margin lives on ContentWrapper
    //therefor had to add this hack
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    margin: 0 16px;
  }
  ${transition('medium')};
  overflow: hidden;
  z-index: 1;
  left: ${props => (props.filterPanelOpen ? '280px' : '0')};

  @media ${breakpoints.sm} {
    left: 0;
  }

  @media ${breakpoints.md} {
    left: 0;
  }
`
