// @ts-nocheck
import { action, observable } from 'mobx'

import { AccordionPresenter } from 'Components/Molecules/Accordion'
import { container } from 'app/Config/IOC'
import { ILanguageService } from 'Helpers/Language/ILanguageService'
import { IUserPM } from 'Shared/Entities/Types/IUserPM'
import { ToasterService } from 'Components/Molecules/Toaster/ToasterService'
import { Types } from 'Gateways/Service/Types'
import { UserOauthClientSessionPresenter } from 'Components/Organisms/UserOauthClientSession'
import { UserOauthClientSessionRepository } from 'app/Repositories/User/UserOauthClientSessionRepository'

export class UserEditOauthClientSessionsPresenter {
  public language: ILanguageService = container.get(Types.ILanguageService)

  @observable
  public accordionPresenter = new AccordionPresenter(this.language.get('users:edit:oauth:heading')).withLeftIconName(
    'key'
  )

  @observable
  public disabled = false

  @observable
  public user: IUserPM

  @observable
  public userOauthClientSessionRepository: UserOauthClientSessionRepository = container.get(
    UserOauthClientSessionRepository
  )

  @observable
  public userOauthClientSessionPresenters: UserOauthClientSessionPresenter[] = []

  @observable
  public canDeleteOauthClientSession: boolean

  @action
  public load = () => {
    this.setUserOauthClientSessionPresenters()
  }

  @action
  public clear = () => {
    this.userOauthClientSessionsRepositoryObserverDisposer && this.userOauthClientSessionsRepositoryObserverDisposer()
  }

  @action
  public setUser = async (user: IUserPM) => {
    this.reset()
    this.user = user

    if (user) {
      this.userOauthClientSessionRepository.setUserId(user.id)
      this.load()
    }
  }

  @action
  public reset = () => {
    this.closeAccordion()
    this.addModalOpenState = false
    this.disabled = false
    this.userOauthClientSessionPresenters = []
  }

  @action
  public closeAccordion = () => {
    this.accordionPresenter.closeAccordion()
  }

  @action
  public setDisabled = (disabled: boolean) => {
    this.disabled = disabled
  }

  @action
  public processRevokeClientSession = async (userId: number, clientSessionId: number) => {
    await this.userOauthClientSessionRepository.revokeClientSession(userId, clientSessionId)

    this.user.oauthClientSessions = this.user.oauthClientSessions.filter(session => {
      session.id !== clientSessionId
    })
  }

  @action
  private setUserOauthClientSessionPresenters = () => {
    const userOauthClientSessionPresenters = this.user.oauthClientSessions?.map(session => {
      return new UserOauthClientSessionPresenter(
        session,
        this.canDeleteOauthClientSession,
        this.processRevokeClientSession
      )
    })

    this.userOauthClientSessionPresenters = userOauthClientSessionPresenters
  }
}
