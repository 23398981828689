import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const ListNumbered = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="ListNumbered" {...props}>
    <path d="M41 7.5H17v3h24v-3Zm-24 15h24v3H17v-3Zm0 15h24v3H17v-3ZM11.179 4.773V13.5H9.074V6.741h-.051L7.07 7.935V6.11l2.152-1.338h1.956ZM7.143 28.5v-1.517l3.184-2.787c.238-.216.441-.413.61-.592.167-.182.295-.364.383-.546.088-.184.132-.385.132-.6 0-.242-.053-.448-.158-.618a1.057 1.057 0 0 0-.435-.401 1.383 1.383 0 0 0-.635-.14 1.31 1.31 0 0 0-.635.148 1.033 1.033 0 0 0-.426.422c-.1.185-.149.41-.149.674H7.016c0-.594.133-1.107.4-1.539a2.633 2.633 0 0 1 1.125-.997c.486-.236 1.05-.354 1.692-.354.662 0 1.237.111 1.726.333.488.221.866.531 1.133.929.27.395.405.853.405 1.376 0 .333-.067.662-.2.989-.134.326-.374.687-.72 1.082-.344.395-.833.868-1.466 1.42l-1.044.962v.056h3.537v1.7h-6.46Zm3.32 15.12c-.665 0-1.254-.114-1.768-.342-.512-.23-.915-.547-1.21-.95a2.341 2.341 0 0 1-.452-1.393h2.122c.008.19.07.359.183.507.114.145.269.258.465.34.196.083.419.124.669.124.25 0 .47-.044.66-.132.193-.09.344-.214.452-.37a.935.935 0 0 0 .158-.546.864.864 0 0 0-.175-.545 1.154 1.154 0 0 0-.507-.371 2.026 2.026 0 0 0-.767-.132h-.848v-1.5h.848c.258 0 .485-.043.681-.128.2-.085.354-.205.465-.358a.883.883 0 0 0 .162-.537.93.93 0 0 0-.537-.865 1.308 1.308 0 0 0-.584-.124c-.233 0-.444.041-.635.124a1.082 1.082 0 0 0-.447.35.906.906 0 0 0-.175.523H7.207c.006-.52.15-.977.435-1.372.284-.398.67-.709 1.16-.933.49-.224 1.05-.337 1.678-.337.625 0 1.175.11 1.65.328.474.22.843.518 1.107.895.264.375.396.8.396 1.275.003.491-.157.896-.481 1.214-.321.318-.734.514-1.24.588v.068c.676.08 1.186.299 1.53.657.346.358.518.805.515 1.342 0 .505-.149.954-.447 1.346-.296.39-.707.697-1.236.92-.525.222-1.13.333-1.81.333Z" fill="#000" />
  </StyledIcon>
))

ListNumbered.displayName = 'ListNumbered'

export { ListNumbered }
