import { memo } from 'react'
import { without } from 'lodash'

import { InputClearButton, TextInput } from '@cutover/react-ui'
import { AnyOrNoneControls, onlyAnyAndNone, ValueType } from './any-or-none-fields'
import { useCustomFieldFilterState } from 'main/recoil/data-access'
import { useLanguage } from 'main/services/hooks'
import { CustomFieldModel } from 'main/data-access'

export const CustomFieldTextSearchControl = memo(({ id }: { id: number }) => {
  const { t } = useLanguage('common', { keyPrefix: 'filter' })
  const customField = CustomFieldModel.useGet(id)
  const [urlVal, setUrlVal] = useCustomFieldFilterState(id)
  const urlValAsArray = urlValToArray(urlVal)
  const searchQuery = without(urlValAsArray, '*', 0)?.[0] ?? ''

  const handleSearchChange = (e: { target: { value: string } }) => {
    const nextText = e.target.value
    const otherValues = onlyAnyAndNone(urlValAsArray)

    if (nextText) {
      setUrlVal(arrayValToUrl(without([nextText, ...otherValues], '*')))
    } else {
      setUrlVal(arrayValToUrl(otherValues))
    }
  }

  const handleCheckboxChange = (val: ValueType) => setUrlVal(arrayValToUrl(val as unknown as any))

  return (
    <>
      <TextInput
        icon="search"
        placeholder={t('searchFor')}
        a11yTitle={`Search for ${customField.display_name ?? customField.name}`}
        value={searchQuery}
        endComponent={
          searchQuery ? <InputClearButton onClick={() => handleSearchChange({ target: { value: '' } })} /> : undefined
        }
        onChange={handleSearchChange}
      />
      <AnyOrNoneControls value={urlValAsArray} setValue={handleCheckboxChange} />
    </>
  )
})

export const urlValToArray = (val: number | string | (string | number)[] | undefined) => {
  if (val === undefined) return []
  if (Array.isArray(val)) return val
  return [val]
}

export const arrayValToUrl = (val: any[] = []) => {
  if (!val.length) return undefined
  if (val.length === 1) return val[0]
  return val
}
