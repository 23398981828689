import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const SwitchVertical = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="SwitchVertical" {...props}>
    <path d="M4 14.799 14.799 4l10.799 10.799-2.121 2.121-7.178-7.177v22.47h-3V9.743L6.121 16.92 4 14.8Zm39.799 17.615L33 43.214l-10.799-10.8 2.121-2.121L31.5 37.47V15h3v22.47l7.178-7.177 2.121 2.121Z" fill="#000" />
  </StyledIcon>
))

SwitchVertical.displayName = 'SwitchVertical'

export { SwitchVertical }
