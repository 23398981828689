import { useCallback } from 'react'

import {
  RunbookMergeResponse,
  RunbookResponse,
  RunbookUpdateResponse
} from 'main/services/api/data-providers/runbook-types'
import { ActiveRunbookModel } from 'main/data-access'

export const useProcessRunbookResponse = () => {
  const processRunbookMergeResponse = ActiveRunbookModel.useOnAction('merge')
  const processRunbookUpdateResponse = ActiveRunbookModel.useOnAction('update')

  return useCallback(
    (response: RunbookResponse) => {
      switch (response?.meta.headers.request_class) {
        case 'Runbook':
          switch (response.meta.headers.request_method) {
            case 'update':
              processRunbookUpdateResponse(response as RunbookUpdateResponse)
              break
            case 'merge':
              processRunbookMergeResponse(response as RunbookMergeResponse)
              break
            default:
              return
          }
      }
    },
    [processRunbookMergeResponse, processRunbookUpdateResponse]
  )
}
