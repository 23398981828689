import { useState } from 'react'

import { RunbooksTimelineContainer } from '../components/workspace/pages/runbooks-timeline/runbooks-timeline-container'
import { AxisDefName } from '../components/workspace/pages/runbooks-timeline/types'
import { ReactAngularConnector, useConnectedEvent } from 'main/connectors/react-angular-connector'
import { CustomField } from 'main/services/queries/types/custom-fields'

export const RunbooksTimelineConnector = () => {
  const [grouping, setGrouping] = useState<AxisDefName | undefined>('folder')
  const [spotlight, setSpotlight] = useState<AxisDefName | undefined>(undefined)
  const [customFieldFilterData, setCustomFieldFilterData] = useState<CustomField[]>([])

  useConnectedEvent('toggle-timeline-group', ({ group }: { group?: AxisDefName }) => {
    setGrouping(group)
  })

  useConnectedEvent('toggle-timeline-spotlight', ({ spotlight }: { spotlight?: AxisDefName }) => {
    setSpotlight(spotlight)
  })

  useConnectedEvent('custom-fields-filter-data-loaded', ({ customFields }: { customFields: CustomField[] }) => {
    setCustomFieldFilterData(customFields)
  })

  const handleUnmountTimeline = () => {
    setGrouping('folder')
    setSpotlight(undefined)
  }

  return (
    <ReactAngularConnector
      mountEvent="show-runbooks-timeline"
      unmountEvent="hide-runbooks-timeline"
      onUnmount={handleUnmountTimeline}
    >
      <RunbooksTimelineContainer customFieldFilters={customFieldFilterData} grouping={grouping} spotlight={spotlight} />
    </ReactAngularConnector>
  )
}
