import { ReactNode } from 'react'
import { AccordionPanelExtendedProps, AccordionPanel as GrommetAccordionPanel, Heading, HeadingProps } from 'grommet'
import styled, { css } from 'styled-components/macro'

import { Box, ColorProp, Icon, IconName, resolveColor } from '@cutover/react-ui'

type AccordionPanelProps = Omit<AccordionPanelExtendedProps, 'header' | 'label' | 'prefix'> & {
  active?: boolean
  avatar?: ReactNode
  error?: boolean // TODO: visual error state
  /** the icon to be rendered to the left of the label text */
  icon?: IconName | ReactNode
  /** defaults to iconColor passed into the parent Accordion */
  iconColor?: string // may come from the backend
  label: string
  secondaryLabel?: string
  truncateHeading?: boolean
  headingLevel?: number
  /** further components to be rendered next to the label */
  labelSuffix?: ReactNode
  /** further components to be rendered on the right hand side of the header */
  suffix?: ReactNode
  disabled?: boolean
}

export const IntegrationAccordionPanel = ({
  active,
  icon,
  iconColor,
  label,
  secondaryLabel,
  truncateHeading = true,
  headingLevel = 4,
  labelSuffix,
  suffix,
  children,
  disabled,
  ...restProps
}: AccordionPanelProps) => {
  const panelIcon =
    typeof icon === 'string' ? (
      <Icon icon={icon as IconName} color={iconColor as ColorProp} size="medium" />
    ) : (
      <Box css={'min-width: auto;'}>{icon}</Box>
    )

  // TODO: Fix ref ts error
  return (
    <>
      {/* @ts-ignore */}
      <StyledGrommetAccordionPanel
        {...restProps}
        disabled={disabled}
        active={active}
        label={
          <Box direction="row" align="center" justify="between" width="100%">
            <Box direction="row" gap="small" align="center" pad={{ left: 'xsmall' }}>
              {icon && panelIcon}
              <Box margin={{ vertical: 'medium' }}>
                <AccordionHeading
                  disabled={disabled}
                  truncate={truncateHeading}
                  active={active}
                  headingLevel={headingLevel}
                >
                  {label}
                </AccordionHeading>
                {secondaryLabel && (
                  <AccordionHeading
                    disabled={disabled}
                    truncate={truncateHeading}
                    active={active}
                    color="text-light"
                    headingLevel={headingLevel + 1}
                  >
                    {secondaryLabel}
                  </AccordionHeading>
                )}
              </Box>
              {labelSuffix}
            </Box>
            {suffix && <SuffixContainer>{suffix}</SuffixContainer>}
          </Box>
        }
      >
        <Box
          pad={{
            right: '4px',
            bottom: '16px',
            left: '4px'
          }}
        >
          {children}
        </Box>
      </StyledGrommetAccordionPanel>
    </>
  )
}

const StyledIcon = styled(Icon)``

export const ExpandIcon = styled(StyledIcon).attrs(() => ({
  icon: 'chevron-right',
  size: 'medium',
  color: 'text-disabled'
}))``

export const CollapseIcon = styled(StyledIcon).attrs(() => ({
  icon: 'chevron-down',
  size: 'medium',
  color: 'text-light'
}))``

const SuffixContainer = styled(props => <Box {...props} />)`
  fill: ${({ theme }) => resolveColor('text-light', theme)};
  color: ${({ theme }) => resolveColor('text-light', theme)};
  transition: all 0.25s cubic-bezier(0.35, 0, 0.25, 1);
  flex-shrink: 0;
`

const StyledHeading = styled(Heading)``

type AccordionHeadingProps = HeadingProps & {
  active?: boolean
  disabled?: boolean
}

const StyledGrommetAccordionPanel = styled(GrommetAccordionPanel)<AccordionHeadingProps>`
  ${StyledIcon},
  ${StyledHeading},
  & {
    // TODO: should find a better place to define and apply this theme-wide?
    transition: all 0.25s cubic-bezier(0.35, 0, 0.25, 1);
  }

  ${StyledIcon} {
    // if disabled don't show icons
    ${({ disabled }) =>
      disabled &&
      css`
        fill: transparent;
      `}
  }

  &:hover ${StyledHeading} {
    color: ${({ disabled, theme }) => !disabled && resolveColor('text', theme)};
  }

  &:hover ${SuffixContainer} {
    fill: ${({ theme }) => resolveColor('text', theme)};
    color: ${({ theme }) => resolveColor('text', theme)};
  }

  &:hover ${ExpandIcon} {
    fill: ${({ theme, disabled }) => !disabled && resolveColor('text', theme)};
  }
`

const AccordionHeading = styled(StyledHeading).attrs(
  ({ headingLevel, color }: { headingLevel: number; color?: ColorProp }) => ({
    level: headingLevel,
    color: color ?? 'text-light',
    responsive: false
    // TODO: we don't have 16px as a theme'd t-shirt edge size yet and it likely should be.
    // margin: { vertical: 'medium' }
  })
)<AccordionHeadingProps>`
  font-weight: ${({ active }) => (active ? 500 : 400)};
  line-height: normal;
  color: ${({ disabled, active, theme }) =>
    resolveColor(disabled ? 'text-disabled' : active ? 'text' : 'text-light', theme)};
`
