import * as yup from 'yup'
import { useFormContext } from 'react-hook-form'
import saveAs from 'file-saver'

import { CheckboxesField } from '@cutover/react-ui'
import {
  CheckboxFieldControlled,
  FormModal,
  RadioboxGroupField,
  TimezoneSelectField
} from 'main/components/shared/form'
import { ActiveRunbookModel, ActiveRunbookVersionModel, RunbookViewModel, TaskModel } from 'main/data-access'
import { exportTasks, ExportTasksPayload, ExportTasksResponse } from 'main/services/queries/use-runbook-versions'
import { useLanguage } from 'main/services/hooks'
import { getFileNameFromResponse } from 'main/services/file-name-from-response-util'

const validationSchema = yup.object({
  exclude_missing_tasks: yup.boolean(),
  format: yup.string().oneOf(['csv', 'xlsx']),
  timezone: yup.string().required(),
  type: yup.string().oneOf(['all', 'filtered', 'selected'])
})

type ExportTasksForm = yup.InferType<typeof validationSchema>

export const ExportTasksModal = ({ closeModal }: { closeModal: () => void }) => {
  const { t } = useLanguage('runbook', { keyPrefix: 'exportTasksModal' })
  const selectedTaskIds = RunbookViewModel.useGet('selectedIds')
  const filteredTaskIds = TaskModel.useGetIds({ scope: 'filtered' })
  const taskIds = TaskModel.useGetIds()
  const { timezone } = ActiveRunbookModel.useGet()
  const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const runbookId = ActiveRunbookModel.useId()
  const runbookVersionId = ActiveRunbookVersionModel.useId()

  const transformer = (data: ExportTasksForm) => {
    const { type, ...restData } = data

    return {
      ...restData,
      exclude_missing_tasks: type === 'all' ? false : data.exclude_missing_tasks,
      task_ids: type === 'selected' ? selectedTaskIds : type === 'filtered' ? filteredTaskIds : taskIds
    }
  }

  const handleSubmit = async (data: ExportTasksPayload & Pick<ExportTasksForm, 'format'>) => {
    const { format, ...restData } = data

    return await exportTasks({
      runbookId,
      runbookVersionId,
      format: format as 'csv' | 'xlsx',
      ...restData
    })
  }

  const handleSuccess = (response: ExportTasksResponse) => {
    const { headers } = response

    const file = new Blob([response.data], { type: headers['content-type'] })
    const fileName = getFileNameFromResponse(headers['content-disposition'])
    saveAs(file, fileName)
  }

  return (
    <FormModal<ExportTasksForm, ExportTasksPayload>
      title={t('title')}
      confirmIcon="download"
      confirmText={t('export')}
      onClose={closeModal}
      open
      schema={validationSchema}
      onSubmit={handleSubmit}
      onSuccess={handleSuccess}
      transformer={transformer}
      defaultValues={{
        exclude_missing_tasks: false,
        format: 'csv',
        timezone: timezone ?? localTimezone,
        type: 'all'
      }}
    >
      <Fields />
    </FormModal>
  )
}

const Fields = () => {
  const { t } = useLanguage('runbook', { keyPrefix: 'exportTasksModal' })
  const selectedTaskIds = RunbookViewModel.useGet('selectedIds')
  const filteredTaskIds = TaskModel.useGetIds({ scope: 'filtered' })
  const taskIds = TaskModel.useGetIds()

  const showRadiobox = selectedTaskIds.length || filteredTaskIds.length !== taskIds.length

  const { watch } = useFormContext<ExportTasksForm>()

  const type = watch('type')

  return (
    <>
      {showRadiobox && (
        <>
          <RadioboxGroupField<ExportTasksForm>
            options={[
              { value: 'all', label: t('fields.export.option', { count: taskIds.length }) },
              filteredTaskIds.length > 0 && {
                value: 'filtered',
                label: t('fields.export.option', { count: filteredTaskIds.length, option: 'filtered' })
              },
              selectedTaskIds.length > 0 && {
                value: 'selected',
                label: t('fields.export.option', { count: selectedTaskIds.length, option: 'selected' })
              }
            ].filter(Boolean)}
            name="type"
            label={t('export')}
            direction="row"
          />
          {type !== 'all' && (
            <CheckboxesField label={t('fields.options.label')}>
              <CheckboxFieldControlled<ExportTasksForm>
                name="exclude_missing_tasks"
                label={t('fields.options.checkbox')}
              />
            </CheckboxesField>
          )}
        </>
      )}
      <RadioboxGroupField<ExportTasksForm>
        name="format"
        label={t('fields.format.label')}
        direction="row"
        options={[
          { value: 'csv', label: t('fields.format.csv') },
          { value: 'xlsx', label: t('fields.format.excel') }
        ]}
      />
      <TimezoneSelectField<ExportTasksForm>
        name="timezone"
        css="width: 100%"
        label={t('fields.timezone.label')}
        customFirstOption={null}
        required
      />
    </>
  )
}
