import { Outlet } from 'react-router-dom'

import { ChooseStartTimeRunbookConnector } from 'main/connectors/choose-start-time-runbook-connector'
import { CreateRunbookConnector } from 'main/connectors/create-runbook-connector'
import { CreateSnippetConnector } from 'main/connectors/create-snippet-connector'

export const RunbooksPageConnectors = () => {
  return (
    <>
      <CreateSnippetConnector />
      <CreateRunbookConnector />
      <ChooseStartTimeRunbookConnector />

      <Outlet />
    </>
  )
}
