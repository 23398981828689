import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Skip = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Skip" {...props}>
    <path d="M38.7 28c-1.39-6.847-7.443-12-14.7-12-8.284 0-15 6.716-15 15H6a18 18 0 0 1 35.748-3H47.5l-7 7-7-7h5.2Z" fill="#000" /><path d="M24 34a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" fill="#000" />
  </StyledIcon>
))

Skip.displayName = 'Skip'

export { Skip }
