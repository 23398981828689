import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Unlink = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Unlink" {...props}>
    <path d="m13.757 3.559 2.898-.777 2.588 9.66-2.898.776-2.588-9.66Zm25.678 7.834a5 5 0 0 1 0 7.072l-5.657 5.656 2.122 2.122 5.656-5.657a8 8 0 0 0 0-11.314l-2.828-2.828a8 8 0 0 0-11.314 0L21.757 12.1l2.122 2.12 5.656-5.656a5 5 0 0 1 7.072 0l2.828 2.828Z" fill="#000" /><path d="m14.454 31.324 16.97-16.97 2.122 2.12-.05.051.05.05-16.97 16.97-2.122-2.12.05-.05-.05-.05Z" fill="#000" /><path d="m12.1 21.757-5.656 5.657a8 8 0 0 0 0 11.314l2.828 2.828a8 8 0 0 0 11.314 0l5.657-5.656-2.122-2.122-5.656 5.657a5 5 0 0 1-7.072 0l-2.828-2.828a5 5 0 0 1 0-7.072l5.657-5.656-2.121-2.122ZM31.588 44.46l2.898-.776-2.588-9.659-2.898.777 2.588 9.659ZM2.782 16.655l.777-2.898 9.659 2.588-.777 2.898-9.659-2.588Zm41.902 16.831.776-2.898L35.801 28l-.776 2.898 9.659 2.588Z" fill="#000" />
  </StyledIcon>
))

Unlink.displayName = 'Unlink'

export { Unlink }
