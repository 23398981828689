import { Box, FormFieldDisplay, Icon, Text } from '@cutover/react-ui'

export const RecipientMultiselectSkeleton = ({
  error,
  readonly,
  required,
  label,
  isLoading
}: {
  error?: string
  readonly?: boolean
  required?: boolean
  label: string
  isLoading?: boolean
}) => (
  <FormFieldDisplay label={label} hasError={!!error} disabled={readonly} required={required}>
    <Box pad={{ vertical: '8px' }}>
      {isLoading && (
        <Box justify="center">
          <Icon icon="spinner" />
        </Box>
      )}
      {error && <Text color="error">{error}</Text>}
    </Box>
  </FormFieldDisplay>
)
