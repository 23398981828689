import { useState } from 'react'
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  UniqueIdentifier,
  useSensor,
  useSensors
} from '@dnd-kit/core'
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { MergeExclusive } from 'type-fest'

import { ListItem, ListItemProps } from './list-item'

type SortableListProps<TData extends { [key: string]: any; id: UniqueIdentifier }> = MergeExclusive<
  {
    listItems?: (ListItemProps & { id: UniqueIdentifier })[]
    onChange: (e: DragEndEvent, order: UniqueIdentifier[]) => void
  },
  {
    listItems?: (ListItemProps & { id: UniqueIdentifier })[]
    data: TData[]
    onChange?: (data: TData[]) => void
  }
>
/** Sortable list renders list items that are draggable.
 *
 * The provided list items can be any object with list item props but MUST have an id ({id: ...}).
 *
 * **Uncontrolled (sorts your data for you)**
 * Passing a 'data' prop will reorder the list items in the UI automatically and
 *    it includes the newly ordered data as an argument to the provided onChange if you include it.
 *    Each item in 'data' must have an id that matches it's corresponding list item in the UI.
 *
 * **Controlled (returns sorted id array)**
 *  You may want to do some custom ordering logic. You will need to pass a callback for reordering list items in the UI.
 *    It MUST accept a DragEndEvent imported from '@dnd-kit/core' and a list of the newly ordered ids:
 *    ```
 *    Example:
 *
 *    const [items, setItems] = useState(props.items)
 *    function reorderItems(e: DragEndEvent, order: UniqueIdentifier[]) {
 *      // get items from ids
 *      const reorderedItems= order.map(id => itemsMap?.[id])
 *      // set state here in parent
 *      setItems(reordereditems)
 *     }
 *    <SortableList listItems={items} onChange={reorderItems} />
 *
 *    ```
 */
export function SortableList<TData extends { [key: string]: any; id: UniqueIdentifier }>({
  listItems = [],
  data,
  onChange
}: SortableListProps<TData>) {
  if (data) {
    return <UncontrolledSortableList listItems={listItems} data={data} onChange={onChange} />
  } else {
    return <ControlledSortableList listItems={listItems} onChange={onChange} />
  }
}

function UncontrolledSortableList<TData extends { [key: string]: any; id: UniqueIdentifier }>({
  listItems = [],
  data,
  onChange
}: {
  data: TData[]
  listItems: (ListItemProps & { id: UniqueIdentifier })[]
  onChange?: (data: TData[]) => void
}) {
  const [items, setItems] = useState(listItems)

  function handleDragEnd(event: DragEndEvent, order: UniqueIdentifier[]) {
    setItems(currentItems => [...currentItems].sort((a, b) => order.indexOf(a.id) - order.indexOf(b.id)))
    onChange?.(data.sort((a, b) => order.indexOf(a.id) - order.indexOf(b.id)))
  }

  return <ControlledSortableList listItems={items} onChange={handleDragEnd} />
}

function ControlledSortableList({
  listItems,
  onChange
}: {
  listItems: (ListItemProps & { id: UniqueIdentifier })[]
  onChange: (e: DragEndEvent, order: UniqueIdentifier[]) => void
}) {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  )

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event

    if (!over) return

    if (active.id !== over.id) {
      const oldIndex = listItems.findIndex(item => item.id === active.id)
      const newIndex = listItems.findIndex(item => item.id === over.id)

      return onChange(
        event,
        arrayMove(listItems, oldIndex, newIndex).map(({ id }) => id)
      )
    }
  }

  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <SortableContext items={listItems.map(({ id }) => id)} strategy={verticalListSortingStrategy}>
        {listItems.map(item => (
          <ListItem sortable key={item.id} {...item} />
        ))}
      </SortableContext>
    </DndContext>
  )
}
