export const LEFT_SIDEBAR_DEFAULT_WIDTH = 280
export const FILTER_PANEL_WIDTH = 280
export const LEFT_SIDEBAR_MIN_WIDTH = 64
export const ANGULAR_NAVIGATION_WIDTH = 280
export const HEADER_HEIGHT = 72
export const SUB_HEADER_HEIGHT = 64
export const RIGHT_PANEL_MAX_WIDTH = 800
export const RIGHT_PANEL_DEFAULT_WIDTH = 320
export const PAGE_NAV_DEFAULT_WIDTH = 64
export const PAGE_NAV_MOBILE_HEIGHT = 64
export const BANNER_HEIGHT = 64
export const BANNER_LAYOUT_HEIGHT = BANNER_HEIGHT

// TODO: should we use these interpolated? tried and made the css super hard to read...
export const CLASS_NAMES = {
  RIGHT_PANEL_OPEN: 'right-panel-open',
  LEFT_NAV_PANEL_OPEN: 'left-nav-panel-open',
  FILTER_PANEL_OPEN: 'filter-panel-open',

  FILTER_PANEL_DISABLED: 'filter-panel-disabled',
  SUB_HEADER_DISABLED: 'sub-header-disabled',
  HIDE_RIGHT_NAV: 'right-nav-disabled'
}

export const IDS = {
  PAGE_ROOT: 'page-root',
  PAGE_LAYOUT: 'layout-page-layout',
  LEFT_NAV: 'layout-left-nav',
  PAGE_WRAPPER: 'layout-page-wrapper',
  HEADER: 'layout-header',
  INNER_PAGE_WRAPPER: 'layout-inner-page-wrapper',
  SUB_HEADER: 'layout-sub-header',
  INNER_CONTENT: 'layout-inner-content',
  MAIN: 'layout-main',
  FILTER_PANEL: 'layout-filter-panel',
  INNER_PAGE: 'layout-inner-page',
  MAIN_WRAPPER: 'layout-main-wrapper',
  RIGHT_NAV: 'layout-right-nav'
}

export const id = (ident: string) => `#${ident}`
