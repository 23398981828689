import { useEffect } from 'react'
import { Controller } from 'react-hook-form'

import { Loader, RadioboxGroup, LegacyText as Text } from '@cutover/react-ui'
import { Nullish } from '@cutover/utility-types'
import { useAccounts } from './use-accounts'

type AccountListFormProps = {
  methods: any
  hasError: boolean
  defaultValue: Nullish<string>
  setValue: any
}

export function AccountListForm({ methods, setValue, defaultValue }: AccountListFormProps) {
  const { isLoading, isError, isSuccess, data: accounts } = useAccounts()

  useEffect(() => {
    if (isSuccess) {
      const firstAccountId = accounts ? (accounts.length > 0 ? accounts[0].id?.toString() : undefined) : undefined
      setValue('accountId', defaultValue ?? firstAccountId)
    }
  }, [isSuccess, setValue, defaultValue])

  if (isLoading) {
    return <Loader message="" />
  }

  if (isError) {
    return <Text>An error has occurred.</Text>
  }

  if (!isSuccess) {
    return <Loader message="" />
  }
  /* TODO:  make this a select? */
  return (
    <Controller
      name="accountId"
      control={methods.control}
      render={({ field: { name, onChange, value, onBlur, ref } }) => (
        <RadioboxGroup
          name={name}
          value={value}
          onChange={onChange}
          label="Workspace"
          direction="column"
          onBlur={onBlur}
          ref={ref}
          required
          options={(accounts ?? []).map(account => {
            return { value: account?.id?.toString(), label: account.name }
          })}
        />
      )}
    />
  )
}
