import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const TaskTypeSquareDashed = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="TaskTypeSquareDashed" {...props}>
    <path d="M16 8h-2a6 6 0 0 0-6 6v2h2v-2a4 4 0 0 1 4-4h2V8Zm4 2V8h8v2h-8Zm18 6v-2a4 4 0 0 0-4-4h-2V8h2a6 6 0 0 1 6 6v2h-2Zm0 12v-8h2v8h-2Zm-6 10h2a4 4 0 0 0 4-4v-2h2v2a6 6 0 0 1-6 6h-2v-2Zm-12 0h8v2h-8v-2Zm-10-6v2a4 4 0 0 0 4 4h2v2h-2a6 6 0 0 1-6-6v-2h2Zm0-12v8H8v-8h2Z" fill="#000" />
  </StyledIcon>
))

TaskTypeSquareDashed.displayName = 'TaskTypeSquareDashed'

export { TaskTypeSquareDashed }
