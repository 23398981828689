import { useState } from 'react'
import { Heading } from 'grommet'
import styled from 'styled-components'

import { Box, Button } from '@cutover/react-ui'
import { CreateNewProjectModal } from '../modals/create-new-project-modal'
import { useLanguage } from 'main/services/hooks'
import { useFetchSettingsPopulated } from 'main/services/queries/use-connect-settings-query'
import { usePermissions } from 'main/services/queries/use-permissions'

export const ConnectSettingsHeader = () => {
  const { t } = useLanguage('connectSettings')
  const [isCreateNewProjectModalOpen, setIsCreateNewProjectModalOpen] = useState(false)
  const permissions = usePermissions('connect-settings')
  const userCanCreateConnectSettings = permissions('create')

  const { data: instanceSettingsArePopulated } = useFetchSettingsPopulated()

  const handleClickCreate = () => {
    setIsCreateNewProjectModalOpen(true)
  }

  const shouldShowCreateButton = userCanCreateConnectSettings && !!instanceSettingsArePopulated?.populated

  return (
    <Box direction="row" width="100%">
      <Box flex="grow" justify="center">
        <ConnectTitle responsive={false} margin={{ left: 'xsmall', right: 'none', vertical: 'none' }} level="1">
          {t('header')}
        </ConnectTitle>
      </Box>
      <Box>
        {shouldShowCreateButton && (
          <Button
            icon="add"
            primary
            label={t('modal.newSetting.buttonLabel')}
            onClick={handleClickCreate}
            data-testid="add-connect-setting-button"
          />
        )}
      </Box>
      <CreateNewProjectModal open={isCreateNewProjectModalOpen} setOpen={setIsCreateNewProjectModalOpen} />
    </Box>
  )
}

const ConnectTitle = styled(Heading)`
  font-size: 20px;
  line-height: 1.2;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
`
