/* eslint-disable react-hooks/rules-of-hooks */
// @ts-nocheck
import { useState, useEffect } from 'react'

export const buildFractionString = (filteredCount: number, totalCount: number): { [key: string]: string } => {
  const [countString, setCountString] = useState(`(${filteredCount}/${totalCount})`)

  useEffect(() => {
    if (filteredCount === undefined || totalCount === undefined) {
      setCountString(null)
    } else if (filteredCount === totalCount) {
      setCountString(`(${filteredCount})`)
    } else {
      setCountString(`(${filteredCount}/${totalCount})`)
    }
  }, [filteredCount, totalCount])

  return { countString }
}

// for runbooks page filtered results- "15/150 results"
export const buildFractionStringWithExtraText = (
  filteredCount: number,
  totalCount: number,
  extraText: string
): { [key: string]: string } => {
  const [countString, setCountString] = useState(`${filteredCount}/${totalCount}${extraText}`)

  useEffect(() => {
    if (filteredCount === undefined || totalCount === undefined) {
      setCountString(null)
    } else {
      setCountString(`${filteredCount}/${totalCount}${extraText}`)
    }
  }, [filteredCount, totalCount])

  return { countString }
}
