import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const BrandFeatures = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 100 101" a11yTitle="BrandFeatures" {...props}>
    <g clipPath="url(#a)" fill="#fff"><circle opacity=".5" transform="matrix(-1 0 0 1 14.667 50.667)" r="14.667" /><circle transform="matrix(-1 0 0 1 50 15.334)" r="14.667" /><path opacity=".5" d="M0 71.334h29.333v29.333H0z" /><path d="M35.334 36.001h29.333v29.333H35.334zM70.666.667V30L100 .668H70.666Z" /><path opacity=".5" d="M29.334 30V.667L0 30h29.334Z" /></g>
  </StyledIcon>
))

BrandFeatures.displayName = 'BrandFeatures'

export { BrandFeatures }
