// @ts-nocheck
import { observable, action } from 'mobx'
import { TAccordionState } from './TAccordionState'
import { TIcons } from 'Components/Atoms'
import { colors } from 'app/Styles/Variables'

export class AccordionPresenter {
  @observable
  public collapsed: boolean = true

  @observable
  public initialCollapsed = true

  @observable
  public heading: string

  @observable
  public state?: TAccordionState = 'default'

  @observable
  public leftIconName?: TIcons

  @observable
  public leftIconColor?: string = colors.secondary

  @observable
  public rightIconName?: TIcons

  @observable
  public rightIconColor?: string = colors.secondary

  @observable
  public counter?: number

  @observable
  public iconRightAction?: () => void

  constructor(heading: string) {
    this.heading = heading
  }

  @action public setCollapsed = (collapsed: boolean) => {
    this.collapsed = collapsed
  }

  @action
  public withLeftIconName? = (name: TIcons) => {
    this.leftIconName = name
    return this
  }

  @action
  public withLeftIconColor? = (color: string) => {
    this.leftIconColor = color
    return this
  }

  @action
  public withRightIconName? = (name: TIcons) => {
    this.rightIconName = name
    return this
  }

  @action
  public withRightIconColor? = (color: string) => {
    this.rightIconColor = color
    return this
  }

  @action
  public withCounter? = (counter: number) => {
    this.counter = counter
    return this
  }

  @action
  public withIconRightAction? = (iconRightaction: () => void) => {
    this.iconRightAction = iconRightaction
    return this
  }

  @action
  public withCollapsed = (collapsed: boolean) => {
    this.collapsed = collapsed
    this.initialCollapsed = collapsed
    return this
  }

  @action
  public reset = () => {
    this.collapsed = this.initialCollapsed
  }

  @action
  public closeAccordion = () => {
    this.setCollapsed(true)
  }
}
