// @ts-nocheck
import { observer } from 'mobx-react-lite'
import { Button, ButtonPresenter } from 'Components/Molecules'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'

interface ICloseButton {
  closeCallback: () => void
  language: ILanguageService
}

export const CloseButton = observer<ICloseButton>(({ closeCallback, language }) => {
  const closeButtonPresenter = new ButtonPresenter('', closeCallback)
    .withIconName('close')
    .withDisplayStyle('secondary-invert')
    .withAriaLabel(language.get('common:ariaLabels:close'))

  return <Button presenter={closeButtonPresenter} />
})
