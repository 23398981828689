import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Blockquote = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Blockquote" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M13.358 37.934A14 14 0 0 1 8 39v-9a4.993 4.993 0 0 0 4-2c.628-.836 1-1.874 1-3H9a3 3 0 0 1-3-3V12a3 3 0 0 1 3-3h10a3 3 0 0 1 3 3v13a14 14 0 0 1-8.642 12.934ZM16 25v-3H9V12h10v13a11 11 0 0 1-8 10.583v-3.164A8.003 8.003 0 0 0 16 25Zm17.358 12.934A14 14 0 0 1 28 39v-9a4.993 4.993 0 0 0 4-2c.628-.836 1-1.874 1-3h-4a3 3 0 0 1-3-3V12a3 3 0 0 1 3-3h10a3 3 0 0 1 3 3v13a14 14 0 0 1-8.642 12.934ZM36 25v-3h-7V12h10v13a11 11 0 0 1-8 10.583v-3.164A8.003 8.003 0 0 0 36 25Z" fill="#000" />
  </StyledIcon>
))

Blockquote.displayName = 'Blockquote'

export { Blockquote }
