import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const MoreHorizontal = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="MoreHorizontal" {...props}>
    <path d="M12 24a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm15 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm15 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" fill="#000" />
  </StyledIcon>
))

MoreHorizontal.displayName = 'MoreHorizontal'

export { MoreHorizontal }
