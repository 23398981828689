import { memo } from 'react'
import { without } from 'lodash'

import { Box, Checkbox } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'

export type ValueType = (number | string)[] | undefined

export type ValueUpdaterFunction = (value?: ValueType) => ValueType

type AnyOrNonControlsProps = {
  value: ValueType
  setValue: ((value?: ValueType) => void) | ValueUpdaterFunction
}

export const AnyOrNoneControls = memo(({ value = [], setValue }: AnyOrNonControlsProps) => {
  const { t } = useLanguage('common', { keyPrefix: 'filter' })

  return (
    <Box>
      <Checkbox
        checked={hasNone(value)}
        label={t('noValueSet')}
        onClick={e => {
          e.stopPropagation()
          e.preventDefault()
        }}
        onChange={function (e) {
          e.stopPropagation()
          e.preventDefault()

          const hadNone = hasNone(value)
          const withoutNone = without(value, 0)
          let nextVal: any = [...withoutNone, ...(hadNone ? [] : [0])]

          const hadAny = isAny(value)

          if (hadAny) {
            nextVal = without(nextVal, '*')
          }

          if (nextVal.length === 0) {
            nextVal = undefined
          }

          setValue(nextVal)
        }}
      />
      <Checkbox
        checked={isAny(value)}
        label={t('anyValueSet')}
        onClick={e => {
          e.stopPropagation()
          e.preventDefault()
        }}
        onChange={function (e) {
          e.stopPropagation()
          e.preventDefault()

          const hadAny = isAny(value)
          let nextVal: any = hadAny ? [] : ['*']

          if (nextVal.length === 0) {
            nextVal = undefined
          }

          setValue(nextVal)
        }}
      />
    </Box>
  )
})

export const isAny = (value: any[] | any) => {
  return Array.isArray(value) ? value.includes('*') : value === '*'
}

export const hasNone = (value: any[] | any) => {
  return Array.isArray(value) ? value.includes(0) : value === 0
}

export const onlyAnyAndNone = (value: any[] = []) => {
  return value.filter(v => v === 0 || v === '*')
}
