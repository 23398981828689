import { memo, useCallback, useMemo } from 'react'

import { CheckboxGroup } from '@cutover/react-ui'
import { FilterAccordionPanel } from '../shared/filter-accordion-panel'
import { useRunbookLevelFilter } from 'main/recoil/data-access'
import { useLanguage } from 'main/services/hooks'
import { RunbookFilterType } from 'main/services/tasks/filtering'
import { ConfigModel } from 'main/data-access'

type RunbookLevelFilterValueType = RunbookFilterType['lv']

export const LevelGroup = memo(() => {
  const { t } = useLanguage('common', { keyPrefix: 'filter.runbook' })
  const { taskLevels } = ConfigModel.useGet()
  const [runbookLevels, setRunbookLevels] = useRunbookLevelFilter()

  const taskLevelOptions = useMemo(
    () =>
      [...taskLevels].reverse().map(level => ({
        value: level.id,
        label: level.name
      })),
    [taskLevels]
  )

  const handleChange = useCallback(
    (event: any) => event && setRunbookLevels(event.value as unknown as RunbookLevelFilterValueType),
    [setRunbookLevels]
  )

  return (
    <FilterAccordionPanel label={t('lv')} applied={!!runbookLevels?.length}>
      <CheckboxGroup
        plain
        a11yTitle={t('lv')}
        options={taskLevelOptions}
        value={runbookLevels}
        onChange={handleChange}
      />
    </FilterAccordionPanel>
  )
})
