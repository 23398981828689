import { useMutation, useQuery } from 'react-query'

import { CamelToSnakeCaseNested } from '@cutover/utility-types'
import { toCamelCase, toSnakeCase } from '@cutover/api'
import { FieldValuesAttributes, RunbookIntegration } from './types'
import { apiClient, ApiError } from 'main/services/api/api-client'
import { QueryKeys } from './query-keys'
import { RunbookEditResponse } from '../api/data-providers/runbook-types'

type RunbookIntegrationRequestSnakeCase = CamelToSnakeCaseNested<RunbookIntegrationRequest>

/**
 * @deprecated Please use the RunbookIntegrationRequestSnakeCase type and/or refactor to
 * use the snake case response from the API instead.
 */
type RunbookIntegrationRequest = {
  linkableId: number
  linkableType: 'RunbookVersion'
  integrationActionItemId: number | null
}

export type RunbookIntegrationsResponseSnakeCase = CamelToSnakeCaseNested<RunbookIntegrationsResponse>

/**
 * @deprecated Please use the RunbookIntegrationsResponseSnakeCase type and/or refactor to
 * use the snake case response from the API instead.
 */
export type RunbookIntegrationsResponse = {
  integrationLinks: RunbookIntegration[]
  meta: {
    headers: {
      request_method: string
      request_user_id: number
      request_user_name: string
      request_hash: string
      request_origin: string
      request_class: string
    }
    permissions: {
      [key: string]: number[]
    }
  }
}

export type RunbookIntegrationsQuery = {
  runbookId: number
  runbookVersionId: number
}

export type EditRunbookIntegrationPayload = {
  runbook: {
    field_values_attributes: FieldValuesAttributes[]
  }
}

type UserAction = 'fire' | 'refire'

export function useRunbookIntegrationsQuery(query: RunbookIntegrationsQuery) {
  const { runbookId, runbookVersionId } = query

  return useQuery([QueryKeys.RunbookIntegrations, runbookId], async () => {
    // TODO refactor. Converting case is not a pattern that should be copied.
    // Favour the snake case response that is returned from the API
    const { data } = toCamelCase(
      await apiClient.get<RunbookIntegrationsResponseSnakeCase>({
        url: `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/integrations`
      })
    )

    return data as unknown as RunbookIntegrationsResponse
  })
}

export function useRunbookIntegrationsCreate() {
  return useMutation<any, ApiError, RunbookIntegrationRequest>(
    ['runbook-integrations-create'],
    async (runbookIntegrationPayload: RunbookIntegrationRequest) => {
      const { data } = toCamelCase(
        await apiClient.post<
          { integration_link: RunbookIntegrationRequestSnakeCase },
          { integration_link: RunbookIntegrationRequestSnakeCase }
        >({
          url: 'integration_links',
          data: { integration_link: toSnakeCase(runbookIntegrationPayload) }
        })
      )

      return data?.integrationLink
    }
  )
}

export function useRunbookIntegrationsDestroy() {
  return useMutation<any, ApiError, any>(['runbook-integrations-destroy'], async (id: number) => {
    const { data } = await apiClient.delete({
      url: `integration_links/${id}`
    })

    const responseData = data as { integration_link: RunbookIntegration }
    responseData.integration_link.id = null
    return responseData.integration_link
  })
}

export function useRunbookIntegrationsFireEndpoint() {
  return useMutation<any, ApiError, { id: number; action: UserAction }>(
    ['runbook-integrations-fire-endpoint'],
    async ({ id, action }) => {
      const { data } = await apiClient.patch({
        url: `integration_links/${id}/fire_endpoint`,
        data: { integration_link: { action: action } }
      })

      return data
    }
  )
}

export function useRunbookIntegrationsCancelEndpoint() {
  return useMutation<any, ApiError, any>(['runbook-integrations-cancel-endpoint'], async (id: number) => {
    const { data } = await apiClient.patch({
      url: `integration_links/${id}/cancel_endpoint`
    })

    return data
  })
}

export const useUpdateRunbookFieldValues = ({ runbookId }: { runbookId: string | number }) => {
  return useMutation<RunbookEditResponse, ApiError, EditRunbookIntegrationPayload>(
    ['runbook-field-values-update'],
    async payload => {
      const response = await apiClient.patch<EditRunbookIntegrationPayload, RunbookEditResponse>({
        url: `runbooks/${runbookId}`,
        data: payload
      })

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return response.data!
    }
  )
}
