// @ts-nocheck
import { action, observable } from 'mobx'
import { injectable } from 'inversify'
import { IBaseResponse } from 'Gateways/Service/IBaseResponse'
import { dateOrNullIso } from 'Shared/Helpers/DateService'
import { PermissableRepository } from 'app/Shared/Repositories/PermissableRepository'

export interface IUserAppToken {
  createdAt: Date
  expiresAt: Date
  id: number
  label: string
  lastAccessedAt: Date
}

@injectable()
export class UserAppTokenRepository extends PermissableRepository {
  @observable
  public userAppTokens: IUserAppToken[] = []

  @observable
  public userId: number

  @action
  public setUserId = (userId: number) => {
    this.userId = userId
  }

  public preLoad = (): string => {
    return `users/${this.userId}/user_app_tokens`
  }

  @action
  public postLoad = async (rawData: IBaseResponse) => {
    this.userAppTokens = rawData.body.user_app_tokens.map(userAppTokenDto => {
      const userAppToken: IUserAppToken = {
        createdAt: dateOrNullIso(userAppTokenDto.created_at),
        expiresAt: dateOrNullIso(userAppTokenDto.expires_at),
        id: userAppTokenDto.id,
        label: userAppTokenDto.label,
        lastAccessedAt: dateOrNullIso(userAppTokenDto.last_accessed_at)
      }

      return userAppToken
    })
  }

  public createToken = async (tokenDto: { user_app_token: { label: string } }) => {
    return await this.serviceGateway.post(`users/${this.userId}/user_app_tokens`, tokenDto)
  }

  @action
  public revokeToken = async (tokenId: number) => {
    this.loading = true
    const responseDto = await this.serviceGateway.delete(`users/${this.userId}/user_app_tokens/${tokenId}`)

    if (responseDto.success) {
      const deleteIndex = this.userAppTokens.findIndex(token => token.id == tokenId)
      this.userAppTokens.splice(deleteIndex, 1)
    }
    this.loading = false
    return responseDto
  }
}
