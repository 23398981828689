// @ts-nocheck
import { observable, computed } from 'mobx'
import { container } from 'app/Config/IOC'
import { TablePresenter, ITableColumnTypes, ISortParams } from 'Components/Organisms'
import { IRunbookUserListEntry } from 'Shared/Entities/Types/RunbookUser/IRunbookUserListEntry'
import { GenericListPresenter } from 'Components/Templates'
import { RunbookUsersViewPresenter } from 'app/Account/Runbooks/RunbookPage/RunbookUsersPage/RunbookUsersViewPresenter'
import { Types } from 'Gateways/Service/Types'
import { ILanguageService } from 'Helpers/Language/ILanguageService'

export class RunbookUserListPresenter implements GenericListPresenter {
  @observable
  private runbookUsersViewPresenter: RunbookUsersViewPresenter = container.get(RunbookUsersViewPresenter)

  private language: ILanguageService = container.get(Types.ILanguageService)

  public tableName: string = 'runbook-user-list'

  public context: string = 'runbook user'

  @computed
  public get reloadList(): boolean {
    return this.runbookUsersViewPresenter.reloadList
  }
  public filtersCount: null

  @computed
  public get loading(): boolean {
    return this.runbookUsersViewPresenter.loading
  }

  @computed
  public get sortParams(): ISortParams {
    return this.runbookUsersViewPresenter.sortParams
  }

  @computed
  public get batchLoadNumber(): number {
    return this.runbookUsersViewPresenter.batchLoadNumber
  }

  @computed
  public get totalCount(): number {
    return this.runbookUsersViewPresenter.filteredListTotal
  }

  public loadSort = async (sortParams: ISortParams) => {
    await this.runbookUsersViewPresenter.loadSort(sortParams)
  }

  public loadMore = async (startIndex: number) => {
    await this.runbookUsersViewPresenter.loadMoreRunbookUsers(startIndex)
  }

  @observable
  public columns: ITableColumnTypes[] = [
    {
      accessor: 'avatar',
      name: '',
      width: 60,
      dataType: 'avatar',
      sortable: false
    },
    {
      accessor: 'firstName',
      name: this.language.get('runbookUsers:list:tableHeadings:firstName'),
      width: 180,
      dataType: 'string',
      sortable: true
    },
    {
      accessor: 'lastName',
      name: this.language.get('runbookUsers:list:tableHeadings:lastName'),
      width: 180,
      dataType: 'string',
      sortable: true
    },
    {
      accessor: 'email',
      name: this.language.get('runbookUsers:list:tableHeadings:email'),
      width: 300,
      dataType: 'string',
      sortable: true
    },
    {
      accessor: 'mobileNumberNormalized',
      name: this.language.get('runbookUsers:list:tableHeadings:mobileNumber'),
      width: 200,
      dataType: 'string',
      sortable: true
    },
    {
      accessor: 'streams',
      name: this.language.get('runbookUsers:list:tableHeadings:streamEditor'),
      width: 200,
      dataType: 'string',
      sortable: true
    },
    {
      accessor: 'runbookTeams',
      name: this.language.get('runbookUsers:list:tableHeadings:assignedTeams'),
      width: 200,
      dataType: 'string',
      sortable: true
    },
    {
      accessor: 'runbookAdmin',
      name: this.language.get('runbookUsers:list:tableHeadings:admin'),
      width: 150,
      dataType: 'boolean',
      sortable: true
    },
    {
      accessor: 'inAccount',
      name: this.language.get('runbookUsers:list:tableHeadings:inAccount'),
      width: 150,
      dataType: 'boolean',
      sortable: true
    },
    {
      accessor: 'registered',
      name: this.language.get('runbookUsers:list:tableHeadings:registered'),
      width: 150,
      dataType: 'boolean',
      sortable: true
    },
    {
      accessor: 'acceptEmail',
      name: this.language.get('runbookUsers:list:tableHeadings:acceptEmail'),
      width: 150,
      dataType: 'boolean',
      sortable: true
    },
    {
      accessor: 'acceptSMS',
      name: this.language.get('runbookUsers:list:tableHeadings:acceptSMS'),
      width: 150,
      dataType: 'boolean',
      sortable: true
    }
  ]

  @computed
  public get viewModel(): IRunbookUserListEntry[] {
    return this.runbookUsersViewPresenter.listVM
  }

  public tablePresenter: TablePresenter = new TablePresenter(
    this.viewModel,
    this.columns,
    this.tableName,
    this.totalCount
  )
    .withLoadMoreCallback(this.loadMore)
    .withLoadSortCallback(this.loadSort)
    .withBatchLoadNumber(this.batchLoadNumber)
    .withVariableWidth()
}
