import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const TaskAdded = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="TaskAdded" {...props}>
    <path d="M25.5 22.5v-7h-3v7h-7v3h7v7h3v-7h7v-3h-7Z" fill="#000" /><path d="M24 6c9.941 0 18 8.059 18 18s-8.059 18-18 18S6 33.941 6 24 14.059 6 24 6ZM9 24c0 8.284 6.716 15 15 15 8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15-8.284 0-15 6.716-15 15Z" fill="#000" />
  </StyledIcon>
))

TaskAdded.displayName = 'TaskAdded'

export { TaskAdded }
