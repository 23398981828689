import { useEffect, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm } from 'react-hook-form'

import { toSnakeCase } from '@cutover/api'
import { Modal, useNotify } from '@cutover/react-ui'
import { IntegrationActionItemForm } from './integration-action-item-form'
import {
  actionConfigSettingsVisibleAndRequired,
  buildDefaultActionItemOptions,
  buildRequestData,
  buildValidationSchema
} from './integration-action-item-form-helper'
import { IntegrationActionItemFormType, IntegrationActionItemRequestType } from './integration-action-item-types'
import { ApiError } from 'main/services/api/http-gateway-adapter'
import { useLanguage } from 'main/services/hooks'
import { IntegrationActionItem, IntegrationSetting } from 'main/services/queries/types'
import { useIntegrationActionItemCreate } from 'main/services/queries/use-integration-action-item'
import { ConfigModel } from 'main/data-access'

type IntegrationActionItemModalProps = {
  integrationSetting: IntegrationSetting
  headerLabel: string
  isOpen: boolean
  onClose: () => void
}

export const NewIntegrationActionItemModal = ({
  integrationSetting,
  headerLabel,
  isOpen,
  onClose
}: IntegrationActionItemModalProps) => {
  const { integrations } = ConfigModel.useGet()
  const { t } = useLanguage('integrationSettings')

  const [apiError, setApiError] = useState<string | undefined>(undefined)
  const [defaultIntegrationAction, setIntegrationAction] = useState<string | undefined>(undefined)

  const mutation = useIntegrationActionItemCreate()

  const currentConnection = integrations?.find(integration => integrationSetting?.integration === integration.klass)
  const availableActions = currentConnection?.actions ?? []
  const selectedActionConfig = availableActions?.find(actionItem => defaultIntegrationAction === actionItem.klass)
  const defaultValues = {
    name: '',
    image_url: '',
    integration_action: '',
    on: [],
    additional_settings: {
      auto_start: false,
      enable_start_fixed: false,
      visibility: -1
    },
    settings: {},
    options: buildDefaultActionItemOptions(null, selectedActionConfig)
  }

  const dummyActionItem = { ...defaultValues, settings: selectedActionConfig?.settings }

  let newIntegrationValidationSchema = buildValidationSchema(
    dummyActionItem as unknown as IntegrationActionItem,
    selectedActionConfig,
    t
  )

  const methods = useForm<IntegrationActionItemFormType>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(newIntegrationValidationSchema),
    defaultValues
  })

  const {
    formState: { dirtyFields },
    getValues,
    setValue,
    reset,
    watch
  } = methods

  useEffect(() => {
    for (const [key, value] of Object.entries(defaultValues.options)) {
      setValue(`options.${key}`, value)
    }
  }, [selectedActionConfig])

  useEffect(() => {
    const { integration_action: action, settings } = getValues()
    const selectedActionConfigSettings = selectedActionConfig?.settings
    actionConfigSettingsVisibleAndRequired(settings, selectedActionConfigSettings, getValues)
    setIntegrationAction(action)
  }, [watch(['integration_action', 'settings'])])

  useEffect(() => {
    for (const [key, value] of Object.entries(defaultValues.options)) {
      setValue(`options.${key}`, value)
    }
  }, [selectedActionConfig])

  const notify = useNotify()
  const onSubmit = async (values: IntegrationActionItemFormType) => {
    const data = buildRequestData(values, integrationSetting, selectedActionConfig?.settings, dirtyFields)

    mutation.mutate(toSnakeCase(data as IntegrationActionItemRequestType), {
      onSuccess: () => {
        onClose()
        notify.success(t('form.newActionItem.success'))
      },
      onError: error => {
        const castedError = error as ApiError
        if (castedError?.errors) {
          setApiError(castedError?.errors.join(', '))
        }
        notify.error(t('form.newActionItem.error'))
      }
    })
  }

  const submitDisabled = () => {
    if ((getValues('integration_action') || []).length === 0 || (getValues('on') || []).length === 0) {
      return true
    }
    return false
  }

  const initForm = () => {
    setApiError(undefined)
    onClose()
    reset()
  }

  return (
    <>
      <Modal
        open={isOpen}
        onClickConfirm={submitDisabled() ? undefined : () => methods.handleSubmit(onSubmit)()}
        onClose={onClose}
        onAfterClose={initForm}
        title={headerLabel}
        loading={mutation.isLoading}
        loadingText={t('modal.loadingText')}
        confirmText={t('modal.confirmCreateText')}
      >
        <FormProvider {...methods}>
          <IntegrationActionItemForm
            integrationSetting={integrationSetting}
            apiError={apiError}
            defaultValues={defaultValues}
          />
        </FormProvider>
      </Modal>
    </>
  )
}
