import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Dashboard = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="Dashboard" {...props}>
    <path d="M24 9a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm18 18a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm-2.3 9.7a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm-3-22.4a3 3 0 1 0-2.898-2.223l-6.754 6.754a6 6 0 1 0 2.121 2.121l6.754-6.754c.248.067.508.102.777.102ZM27 24a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM14.3 11.3a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM9 24a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm2.3 15.7a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" fill="#000" />
  </StyledIcon>
))

Dashboard.displayName = 'Dashboard'

export { Dashboard }
