import parse from 'html-react-parser'
import * as yup from 'yup'

import { Text } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { FormModal } from 'main/components/shared/form'
import { RunbookTeamDestroyResponse } from 'main/services/api/data-providers/runbook-types'

// NOTE: blank form placeholder until we deal with reassignments
type RunbookTeamDeleteFormType = yup.InferType<typeof validationSchema>

const validationSchema = yup.object({})

export const RunbookTeamDeleteModal = ({
  onClose,
  onSuccess,
  teamName,
  onSubmit
}: {
  onSubmit: () => void
  onClose?: () => void
  onSuccess?: (response: RunbookTeamDestroyResponse) => void
  teamName: string
}) => {
  const { t } = useLanguage('runbook', { keyPrefix: 'teamDelete' })

  return (
    <FormModal<RunbookTeamDeleteFormType>
      title={t('title')}
      open
      schema={validationSchema}
      confirmText={t('delete')}
      confirmIcon="delete"
      onClose={onClose}
      successMessage={t('deleteSuccess')}
      onSubmit={onSubmit}
      onSuccess={onSuccess}
    >
      <Text css="margin-bottom: 8px;">{parse(t('areYouSure', { teamName: teamName }))}</Text>
    </FormModal>
  )
}
