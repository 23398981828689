import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const ShowRedundant = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 48 48" a11yTitle="ShowRedundant" {...props}>
    <path d="M24.961 10.686A6 6 0 1 0 17.5 15.81v16.378a6 6 0 1 0 7.494 5.536l4.623-1.423a5.5 5.5 0 0 0 3.883-5.256V17.747a5.5 5.5 0 0 0-3.526-5.133l-5.013-1.928ZM22 10a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm1.884 3.486 5.013 1.928a2.5 2.5 0 0 1 1.603 2.333v13.299a2.5 2.5 0 0 1-1.765 2.39l-4.623 1.422a6.011 6.011 0 0 0-3.612-2.669V15.811a6.008 6.008 0 0 0 3.384-2.325ZM22 38a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" fill="#000" />
  </StyledIcon>
))

ShowRedundant.displayName = 'ShowRedundant'

export { ShowRedundant }
