import { Pie } from '@nivo/pie'
import styled, { keyframes } from 'styled-components/macro'

import { nivoTheme } from '../chart/shared/nivo-theme'
import { Box } from '../layout/box'
import { focusRingCss, resolveColor, ThemeType, useTheme } from '../theme'

type ProgressPieProps = {
  value: number
  active?: boolean
  withInnerText?: boolean
  padAngle?: number
  'aria-label'?: string
  'data-testid'?: string
}

export const ProgressPie = ({
  padAngle = 1,
  value,
  withInnerText = true,
  active = true,
  ...props
}: ProgressPieProps) => {
  const theme = useTheme()

  const progressData = [
    {
      id: 'in-progress',
      label: 'in-progress',
      value: value,
      color: resolveColor('primary', theme),
      name: 'in-progress'
    },
    {
      id: 'complete',
      label: 'complete',
      value: 100 - value,
      color: resolveColor('bg-3', theme),
      name: 'complete'
    }
  ]

  return (
    <Box
      css={`
        height: 40px;
        width: 40px;
        margin: auto;
        svg {
          overflow: visible;
        }
        &:focus-visible {
          ${focusRingCss}
        }
      `}
      round="full"
      role="progressbar"
      aria-valuenow={Math.round(value)}
      tabIndex={0}
      {...props}
    >
      <Pie
        data={progressData}
        height={40}
        width={40}
        colors={({ id }) => progressData.find(d => d.id === id)?.color || '#e8e8e8'}
        innerRadius={0.8}
        layers={[
          'arcs',
          withInnerText && active ? createCenterValue(`${Math.round(value)}%`, theme) : () => null,
          active ? createCircularMotion(theme) : () => null
        ]}
        isInteractive={false}
        padAngle={padAngle}
        role="presentation"
      />
    </Box>
  )
}

const createCenterValue: (value: string, theme: ThemeType) => (props: any) => JSX.Element = (
  value: string,
  theme: ThemeType
) => {
  return (props: any) => {
    return (
      <text
        x={props.centerX}
        y={props.centerY}
        textAnchor="middle"
        dominantBaseline="central"
        style={{
          fontFamily: nivoTheme?.fontFamily,
          fontSize: '13px',
          fill: resolveColor('text-light', theme),
          fontWeight: 400
        }}
      >
        {value}
      </text>
    )
  }
}

const createCircularMotion: (theme: ThemeType) => (props: any) => JSX.Element = (theme: ThemeType) => {
  return (props: any) => {
    return <Circle cx={props.centerX} cy={props.centerY} r="18.5" theme={theme} />
  }
}

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const Circle = styled.circle<{ theme: ThemeType }>`
  fill: transparent;
  stroke: ${({ theme }) => resolveColor('bg', theme)};
  stroke-width: 6px;
  stroke-dasharray: 2 115;
  animation: ${rotate} 1.5s infinite linear;
  transform-origin: 50% 50%;
`
