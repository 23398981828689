import { useNotify } from '@cutover/react-ui'
import { OptionalKeys } from '@cutover/utility-types'
import { SelectedTeam, SelectedUserTeam } from './types'
import { AccountUserTeam } from './use-account-users-teams'
import { ValidationRunbookTeam } from '../../right-panels/people-panel/types'
import { useLanguage } from 'main/services/hooks'

export const useValidation = () => {
  const { translate } = useLanguage()

  const validateTeamName = ({
    item,
    currentTeamName,
    selectedItems,
    runbookTeams
  }: {
    item: AccountUserTeam | OptionalKeys<SelectedTeam, 'id'>
    currentTeamName?: string
    selectedItems: SelectedUserTeam[]
    runbookTeams: ValidationRunbookTeam[]
  }): string | null => {
    let warningMessage = null
    const lowerCasedNewTeamName = item.name.toLowerCase()
    const existingTeam = runbookTeams.find(team => team.name.toLowerCase() === lowerCasedNewTeamName)
    const selectedTeams = selectedItems
      ? selectedItems
          ?.filter(selectedItem => selectedItem.type === 'team')
          .map(selectedItem => selectedItem.name?.toLowerCase())
      : []

    if (existingTeam) {
      if (item.highlight && existingTeam.linked === false) {
        warningMessage = translate('runbook:addUsersTeams:notification:warning:notification:teamUniqueness', {
          teamName: item.name
        })
      } else if (item.highlight && existingTeam.linked === true) {
        warningMessage = translate('runbook:addUsersTeams:notification:warning:notification:linkedTeamUniqueness')
      } else {
        warningMessage = translate('runbook:addUsersTeams:notification:warning:notification:customTeamUniqueness', {
          context: 'runbook'
        })
      }
    } else if (item.name.trim() === '') {
      warningMessage = translate('runbook:addUsersTeams:notification:warning:notification:cantBeBlank')
      // allow user to change the letter-casing on edit
    } else if (currentTeamName && currentTeamName.toLowerCase() === lowerCasedNewTeamName) {
      return warningMessage
    } else if (selectedTeams.indexOf(lowerCasedNewTeamName) !== -1) {
      if (item.highlight) {
        warningMessage = translate('runbook:addUsersTeams:notification:warning:notification:duplicatedTeamName')
      } else {
        warningMessage = translate('runbook:addUsersTeams:notification:warning:notification:customTeamUniqueness', {
          context: 'modal'
        })
      }
    }

    return warningMessage
  }

  const notify = useNotify()

  const validateUserName = ({
    item,
    selectedItems
  }: {
    item: OptionalKeys<AccountUserTeam, 'id'>
    selectedItems: SelectedUserTeam[]
  }): string | null => {
    let warningMessage = null

    if (
      selectedItems.some(
        selectedItem => selectedItem.type === 'user' && item.type === 'user' && item.id === selectedItem.id
      )
    ) {
      warningMessage = translate('runbook:addUsersTeams:notification:warning:notification:duplicatedUserName')
    }

    return warningMessage
  }

  const validateUserTeamCount = ({
    item,
    selectedItems,
    runbookTeamsCount,
    maxRunbookTeams,
    runbookUsersCount,
    maxRunbookUsers
  }: {
    item: OptionalKeys<AccountUserTeam | SelectedUserTeam, 'id'>
    selectedItems: SelectedUserTeam[]
    runbookTeamsCount?: number
    maxRunbookTeams?: number
    runbookUsersCount?: number
    maxRunbookUsers?: number
  }): string | null => {
    let warningMessage = null
    if (
      item.type === 'team' &&
      maxRunbookTeams &&
      runbookTeamsCount !== undefined &&
      selectedItems.filter(item => item.type === 'team').length + runbookTeamsCount >= maxRunbookTeams
    ) {
      warningMessage = translate('runbook:addUsersTeams:notification:warning:notification:runbookLimit', {
        context: 'teams'
      })
    } else if (
      item.type === 'user' &&
      maxRunbookUsers &&
      runbookUsersCount !== undefined &&
      selectedItems.filter(item => item.type === 'user').length + runbookUsersCount >= maxRunbookUsers
    ) {
      warningMessage = translate('runbook:addUsersTeams:notification:warning:notification:runbookLimit', {
        context: 'users'
      })
    }

    return warningMessage
  }

  const displayWarning = (warningMessage: string | null): boolean => {
    if (warningMessage) {
      notify.warning(warningMessage, { title: translate('runbook:addUsersTeams:notification:warning:title') })
      return true
    }
    return false
  }

  return { validateTeamName, validateUserName, validateUserTeamCount, displayWarning }
}
