import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const BrandIntegrations = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 100 101" a11yTitle="BrandIntegrations" {...props}>
    <g clipPath="url(#a)" fill="#fff"><path opacity=".5" d="M70.667 50.667c0 11.416-9.25 20.667-20.663 20.667-11.414 0-20.67-9.251-20.67-20.667C29.333 39.252 38.59 30 49.995 30c11.407 0 20.67 9.252 20.67 20.667Z" /><path d="M70.667 30H100L70.667.668V30ZM29.333 71.334H0l29.333 29.333V71.333Zm0-41.334V.667L0 30h29.333Zm41.334 41.334v29.333L100 71.334H70.667Z" /></g>
  </StyledIcon>
))

BrandIntegrations.displayName = 'BrandIntegrations'

export { BrandIntegrations }
