// @ts-nocheck
import styled from 'styled-components'
import { colors } from 'app/Styles/Variables'
import { TIcons, Icon } from 'Components/Atoms'
import { ToolTip } from 'Components/Molecules'
import { TToolTipPosition } from 'Components/Molecules/ToolTip/TToolTipPosition'
import { observer } from 'mobx-react-lite'

const Wrapper = styled.span``

const IconInner = styled.span`
  color: ${colors.textDisabled};
  cursor: pointer;
  &:hover {
    color: ${colors.textLight};
  }
`

interface IHelpTextProps {
  text: string
  icon?: TIcons
  toolTipPosition?: TToolTipPosition
}

export const HelpText = observer<IHelpTextProps>(props => {
  const { text, icon = 'help', toolTipPosition } = props
  return (
    <Wrapper>
      <ToolTip
        trigger={
          <IconInner>
            <Icon name={icon} size="16px" extraStyles={{ width: '16px' }} />
          </IconInner>
        }
        message={text}
        position={toolTipPosition}
      />
    </Wrapper>
  )
})
